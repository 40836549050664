import benefitsClasses from "pages/benefits/benefits.module.css";
import { Divider } from "@justworkshr/milo-core";

const OverviewTab = () => {
  return (
    <div>
      <h2 className={benefitsClasses.benefitsHeader}>What's a 401(k)?</h2>

      <p className={benefitsClasses.benefitsParagraph}>
        A 401(k) is a retirement savings plan sponsored by your employer. It
        lets you save and invest a portion of your paycheck-either before taxes
        are taken out (traditional pre-tax) or after (Roth).
      </p>

      <p className={benefitsClasses.benefitsParagraph}>
        You can decide whether you want to contribute a specific amount or
        percentage of your overall salary per paycheck. Contributions are
        automatically deducted from your paycheck and transferred to your 401(k)
        account with Empower. Just like other benefits and deductions, your
        401(k) contributions will be reflected on your paystub.
      </p>

      <Divider />

      <h2 className={benefitsClasses.benefitsHeader}>
        What are the benefits of a 401(k)?
      </h2>

      <p className={benefitsClasses.benefitsParagraph}>
        The earlier you start saving for retirement, the more you can take
        advantage of compounding growth. Your 401(k) contributions are invested
        into top mutual funds (like Vanguard, Fidelity, and American Funds) that
        earn interest. Your earned interest is then reinvested into your 401(k)
        to earn even more interest. That's right, money making money. So, what
        might feel like a small monthly investment can grow into a sizeable nest
        egg over time.
      </p>

      <Divider />

      <h2 className={benefitsClasses.benefitsHeader}>
        What types of 401(k) plans are available?
      </h2>

      <h3 className={benefitsClasses.benefitsHeader}>Traditional 401(k):</h3>

      <p className={benefitsClasses.benefitsParagraph}>
        Your contributions are made <strong>pre-tax</strong>, which reduces your
        taxable income. When you're ready to use your 401(k) during retirement,
        you'll have to pay taxes on any withdrawals.
      </p>

      <h3 className={benefitsClasses.benefitsHeader}>Roth 401(k):</h3>

      <p className={benefitsClasses.benefitsParagraph}>
        Your contributions are made after-tax and have no effect on your taxable
        income. When you're ready to use the funds in this account for
        retirement, you'll be able to withdraw without having to pay taxes or
        penalties on those contributions.
      </p>
    </div>
  );
};

export default OverviewTab;
