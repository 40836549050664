import {
  FormField,
  RadioButton,
  RadioButtonGroup,
  TextInput,
} from "@justworkshr/milo-form";
import { useFormikContext } from "formik";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { ConfigType, SchemaType } from "./config";

export default function FormFragment() {
  const { t } = useTranslation();
  const { errors, touched, handleBlur, values, setFieldValue } =
    useFormikContext<SchemaType>();

  const fieldErrorHandler = (name: keyof ConfigType["initialValues"]) =>
    errors[name] && touched[name] ? errors[name] : undefined;

  const handleBooleanChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(event.target.name, event.target.value === "true");
  };

  const handleAverageMinutesPerWeekChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue("averageMinutesPerWeek", Number(event.target.value) * 60);
  };

  return (
    <>
      <FormField
        required
        name="averageMinutesPerWeek"
        label={t("Working hours per week")}
        error={fieldErrorHandler("averageMinutesPerWeek")}
      >
        <TextInput
          required
          type="number"
          name="averageMinutesPerWeek"
          onChange={handleAverageMinutesPerWeekChange}
          onBlur={handleBlur}
          value={String(values.averageMinutesPerWeek / 60)}
        />
      </FormField>
      <FormField
        name="nlGrossPayIncludesHolidayPay"
        required
        label={t("Does gross pay include holiday pay?")}
        message={t(
          "Holiday pay is typically 8% of an employee's gross annual pay."
        )}
        error={fieldErrorHandler("nlGrossPayIncludesHolidayPay")}
      >
        <RadioButtonGroup
          name="nlGrossPayIncludesHolidayPay"
          onChange={handleBooleanChange}
          onBlur={handleBlur}
          value={String(values.nlGrossPayIncludesHolidayPay)}
        >
          <RadioButton name="yes" value="true" label={t("Yes")} />
          <RadioButton name="no" value="false" label={t("No")} />
        </RadioButtonGroup>
      </FormField>
    </>
  );
}
