export const BASE_TEAM_MANAGEMENT_PATH = "/team-management";

export const ROUTES = {
  SEPARATION_FLOW: "separation-flow",
  SEPARATION_NOTICE: "separation-notice",
  SEPARATION_REASON: "separation-reason",
  SEPARATION_NOTICE_ALL_STATE_REQUIREMENTS: "all-state-requirements",
  SEPARATION_NOTICE_STATE_REQUIREMENTS: "state-requirements",
  SEPARATION_REASON_INITIAL_INTAKE: "initial-intake",
  SEPARATION_REASON_QUESTIONNAIRE: "questionnaire",
  SEPARATION_PAY_ADDITIONAL_PAYMENTS: "additional-payments",
  SEPARATION_PAY_PAYMENT_DETAILS: "payment-details",
  SEPARATION_REVIEW: "review",
  POST_SEPARATION_QUESTIONNAIRE_DOCUMENTS:
    "/questionnaire/separation-questionnaire-documents",
};

export const prependBasePath = (route: keyof typeof ROUTES) => {
  return `${BASE_TEAM_MANAGEMENT_PATH}${ROUTES[route]}`;
};

export const NEXT_ROUTE_BY_STATUS: { [key: string]: string } = {
  incomplete: ROUTES.SEPARATION_REASON_INITIAL_INTAKE,
  intake_partially_complete: ROUTES.SEPARATION_REASON_INITIAL_INTAKE,
  intake_complete: ROUTES.SEPARATION_REASON_QUESTIONNAIRE,
  questionnaire_partially_complete: ROUTES.SEPARATION_REASON_QUESTIONNAIRE,
  questionnaire_complete: ROUTES.SEPARATION_PAY_ADDITIONAL_PAYMENTS,
  questionnaire_skipped: ROUTES.SEPARATION_PAY_ADDITIONAL_PAYMENTS,
};

export const getNextRouteByStatus: (
  status: string | undefined
) => string | undefined = (status) => {
  if (!status || !NEXT_ROUTE_BY_STATUS[status]) {
    return ROUTES.SEPARATION_REASON_INITIAL_INTAKE;
  }

  return NEXT_ROUTE_BY_STATUS[status];
};

export const ROUTE_ORDERING = {
  [ROUTES.SEPARATION_REASON_INITIAL_INTAKE]: 1,
  [ROUTES.SEPARATION_REASON_QUESTIONNAIRE]: 1,
  [ROUTES.SEPARATION_PAY_ADDITIONAL_PAYMENTS]: 2,
  [ROUTES.SEPARATION_PAY_PAYMENT_DETAILS]: 2,
  [ROUTES.SEPARATION_REVIEW]: 3,
};
