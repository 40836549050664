import {
  UpdateCategoriesMutationVariables,
  useGetCategoriesLazyQuery,
} from "types/generated/operations";
import styles from "./CategoriesPage.module.css";
import { useCategoriesStore } from "pages/expenses/store";
import { useEffect, useState, useMemo } from "react";
import { useUpdateCategories } from "pages/expenses/hooks";
import { Alert, Button } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import { prependBasePath } from "pages/expenses/constants";
import {
  CustomCategoriesCard,
  CustomCategoryDialog,
  DefaultCategoriesCard,
} from "./components";
import { CATEGORY_VISIBLE_TEXT } from "./CatagoriesPage.constants";
import { useNavigate } from "react-router-dom";
import { formatCategories } from "./CategoriesPage.utils";
import { Loading } from "pages/expenses/components";

const { CategoriesPageWrapper, CategoriesPageCardsWrapper } = styles;

export const CategoriesPage = () => {
  const navigate = useNavigate();

  const {
    setCategories,
    categories: { categories },
  } = useCategoriesStore();

  const [
    getCategories,
    { loading: isCategoriesLoading, data: categoriesData },
  ] = useGetCategoriesLazyQuery();

  const [isCustomCategoryModalOpen, setIsCustomCategoryModalOpen] =
    useState(false);

  const isCategoriesReviewed =
    categoriesData?.expenseManagement?.policy?.categoriesReviewed || false;

  const isNoCategoriesSelected =
    categories.filter((category) => category.visible).length === 0;

  const variables = useMemo(() => {
    return {
      categories: formatCategories(categories),
    };
  }, [categories]) as UpdateCategoriesMutationVariables;

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    if (
      !categories.length &&
      !isCategoriesLoading &&
      categoriesData?.expenseManagement?.categories
    ) {
      setCategories({
        categories: categoriesData.expenseManagement.categories,
      });
    }
  }, [isCategoriesLoading, categoriesData, categories, setCategories]);

  const handleCustomCategoryModal = () =>
    setIsCustomCategoryModalOpen((prevState) => !prevState);

  const { updateCategories, isUpdateCategoriesLoading } =
    useUpdateCategories(isCategoriesReviewed);

  const renderActionFooter = () => {
    return (
      <ActionFooter
        actions={[
          <Button
            type="button"
            variant="ghost"
            key="cancel-button"
            onClick={() => navigate(prependBasePath("SETTINGS"))}
            data-testid="cancel-button"
          >
            Cancel
          </Button>,
          <Button
            type="button"
            key="submit-button"
            disabled={isNoCategoriesSelected}
            loading={isUpdateCategoriesLoading}
            onClick={() => updateCategories({ variables })}
            data-testid="submit-button"
          >
            Submit
          </Button>,
        ]}
      />
    );
  };

  if (isCategoriesLoading) {
    return <Loading />;
  }

  if (!isCategoriesLoading && isNoCategoriesSelected) {
    return (
      <div className={CategoriesPageWrapper}>
        <Alert color="destructive">{CATEGORY_VISIBLE_TEXT}</Alert>
      </div>
    );
  }

  return (
    <div className={CategoriesPageWrapper}>
      <div className={CategoriesPageCardsWrapper}>
        <DefaultCategoriesCard />
        <CustomCategoriesCard
          handleCustomCategoryModal={handleCustomCategoryModal}
        />
        {renderActionFooter()}
      </div>
      <CustomCategoryDialog
        isCustomCategoryModalOpen={isCustomCategoryModalOpen}
        handleCustomCategoryModal={handleCustomCategoryModal}
      />
    </div>
  );
};

export default CategoriesPage;
