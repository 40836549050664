import { ReactElement } from "react";
import { useGetCompanyDisplayQuery } from "types/generated/operations";
import { buildWebPath } from "lib/resource-finder";
import styles from "./company-display.module.css";

const { companydisplay } = styles;

export default function CompanyDisplay(): ReactElement {
  let companyName = "";

  const { data, loading } = useGetCompanyDisplayQuery();
  if (loading || !data) {
    return <></>;
  }
  companyName = data.authenticatedMember.company.name;

  return (
    <a
      className={companydisplay}
      href={buildWebPath("/dashboard")}
      data-testid="company-display"
    >
      {companyName}
    </a>
  );
}
