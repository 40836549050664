import { FieldHookConfig, useField } from "formik";
import { CheckboxInput } from "@justworkshr/milo-form";

const PermissionCheckbox = ({
  name,
  fieldConfig,
  grantedBy,
  ...props
}: {
  name: string;
  value: string;
  label: string;
  grantedBy: string[];
  fieldConfig?: Partial<FieldHookConfig<string[]>>;
}) => {
  const [field] = useField({ name, type: "checkbox", ...fieldConfig });
  const isIndirect = grantedBy.some((permission) =>
    field.value.includes(permission)
  );
  const checked = isIndirect || field.value.includes(props.value);
  return (
    <CheckboxInput
      {...field}
      {...props}
      checked={checked}
      disabled={isIndirect}
    />
  );
};

export default PermissionCheckbox;
