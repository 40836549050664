import { TabList, Tab } from "@justworkshr/milo-core";
import { prependBasePath } from "pages/expenses/constants";
import { useNavigate } from "react-router-dom";

export const ReporterTabList: React.FC = () => {
  const navigate = useNavigate();

  const handleChange = () => {
    return navigate(prependBasePath("REQUEST_HISTORY"));
  };

  return (
    <TabList selected={0} onChange={handleChange}>
      <Tab>Request History</Tab>
    </TabList>
  );
};

export default ReporterTabList;
