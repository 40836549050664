import { ReactNode, useReducer, createContext, useContext } from "react";
import { PanelsControlAction, PanelsControlState, initialState } from "./types";
import { reducer } from "./reducer";
import Panels from "./panels";

interface PanelsControlContextValue {
  state: PanelsControlState;
  dispatch: React.Dispatch<PanelsControlAction>;
}

interface PanelsControlContextProviderProps {
  children: ReactNode;
}

const PanelsControlContext = createContext<PanelsControlContextValue>({
  state: initialState,
  dispatch: () => {
    // do nothing
  },
});

export function usePanelsControlContext() {
  const context = useContext(PanelsControlContext);
  if (context === undefined) {
    throw new Error(
      "usePanelsControlContext must be used within a PanelsControlContextProvider"
    );
  }
  return context;
}

export default function PanelsControlContextProvider({
  children,
}: PanelsControlContextProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PanelsControlContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <Panels />
      {children}
    </PanelsControlContext.Provider>
  );
}
