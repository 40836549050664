import { useMultiAction } from "pages/expenses/hooks";
import React from "react";
import { ExpenseDialog } from "../ExpenseDialog";
import { Button } from "@justworkshr/milo-core";
import { ExpenseLibrary } from "../ExpenseLibrary";
import { ExpenseLibraryExpense } from "types/Expenses";
import { Notice, NoticeTypes } from "../Notice";
import styles from "./ExpenseLibraryDialog.module.css";
import { useRequestDetail } from "pages/expenses/store";

const { ExpenseLibraryDialogContainer, ExpenseLibraryDialogWrapper } = styles;

interface ExpenseLibraryDialogProps {
  isOpen: boolean;
  onDismiss: () => void;
}

export const ExpenseLibraryDialog: React.FC<ExpenseLibraryDialogProps> = ({
  isOpen,
  onDismiss,
}) => {
  const {
    setDetachedExpenses,
    setRequestDetailExpenses,
    requestDetail: {
      request: { expenses },
      metadata: { detachedExpenses },
    },
  } = useRequestDetail();
  const multiActionProps = useMultiAction<ExpenseLibraryExpense>();

  const handleDismiss = () => {
    multiActionProps.clearItems();
    onDismiss();
  };

  const handleAdd = () => {
    const updatedExpenses = [...expenses, ...multiActionProps.selectedItems];

    if (detachedExpenses.length !== 0) {
      setDetachedExpenses((currentDetachedExpenses) => {
        const updatedDetachedExpenses = currentDetachedExpenses.filter(
          (detachedExpense) =>
            !multiActionProps.selectedItems.find(
              (selectedExpense) => selectedExpense.uuid === detachedExpense.uuid
            )
        );

        return updatedDetachedExpenses;
      });
    }

    setRequestDetailExpenses(updatedExpenses);
    multiActionProps.clearItems();
    onDismiss();
  };

  const emptyNotice = (
    <Notice type={NoticeTypes.INFO}>
      Expenses that aren’t attached to a reimbursement request live here–in the
      Expense Library.
    </Notice>
  );

  return (
    <div className={ExpenseLibraryDialogWrapper}>
      <ExpenseDialog
        isOpen={isOpen}
        onClose={handleDismiss}
        actions={[
          <Button
            type="button"
            variant="ghost"
            onClick={handleDismiss}
            key="expense-library-dialog-cancel"
          >
            Cancel
          </Button>,
          <Button
            type="button"
            onClick={handleAdd}
            key="expense-library-dialog-add-expense"
            disabled={!multiActionProps.selectedItems.length}
            data-testid="add-expense-button"
          >
            Add expense{multiActionProps.selectedItems.length > 1 && "s"}
          </Button>,
        ]}
      >
        <div className={ExpenseLibraryDialogContainer}>
          <h3>Expense library</h3>
          <ExpenseLibrary
            showMultiAction={false}
            emptyNotice={emptyNotice}
            showExpenseDetailDrawer={false}
            multiActionProps={multiActionProps}
          />
        </div>
      </ExpenseDialog>
    </div>
  );
};
