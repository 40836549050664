import { ReactElement } from "react";
import styles from "../../InternationalSolutionsPage.module.css";
import EmployeesEORImage from "../../images/employees-eor.svg?react";
import EORPriceCard from "pages/international-solutions/components/PriceCard/EORPriceCard";
import { EORFAQ } from "pages/international-solutions/components/FAQ/EORFAQ";

const EmployerOfRecord = (): ReactElement => {
  const {
    container,
    column1,
    column2,
    divWrapper,
    mdFont,
    mdGap,
    lgGap,
    lgHead,
    listWrapper,
    xsHead,
    hr,
    infoText,
    price,
    listItems,
  } = styles;

  return (
    <>
      <div className={container}>
        <div className={column1}>
          <EmployeesEORImage />
          <div className={`${divWrapper} ${lgGap}`}>
            <div className={lgHead}>Make international hiring a breeze</div>

            <div className={mdFont}>
              Easily navigate hiring international employees with a robust HR
              infrastructure built for small businesses that saves time, money,
              and effort. Onboard and manage international employees, all within
              the same platform you’ve come to love.
            </div>
            <div>
              <ul className={listWrapper}>
                <li>
                  <div className={xsHead}>Streamline hiring</div>
                  Speed up the process of hiring and onboarding in new countries
                  with simple-to-use HR tools and human support offered by a
                  single provider.
                </li>
                <li>
                  <div className={xsHead}>Stay compliant</div>
                  Ensure it’s business as usual and protect your business from
                  the risks that come with international hiring.
                </li>
                <li>
                  <div className={xsHead}>
                    Pay employees in their local currency
                  </div>
                  Effortlessly pay your team in their local currency with
                  seamless and on-time transactions.
                </li>
              </ul>
            </div>
          </div>

          <hr className={hr}></hr>
          <div className={`${divWrapper} ${mdGap}`}>
            <div className={lgHead}>Justworks EOR pricing</div>

            <div>
              <p>Fees apply for each international employee per month.</p>
              <div className={infoText}>
                <span className={price}>$599</span>/month per employee
              </div>
              <h3>Key Features</h3>
              <ul className={listItems}>
                <li>No setup fees and no minimums</li>
                <li>Pay employees in a few clicks</li>
                <li>Get international hiring expertise</li>
                <li>Provide local employee benefits</li>
              </ul>
            </div>
          </div>

          <div className={`${divWrapper} ${mdGap}`}>
            <EORFAQ />
          </div>
        </div>
        <div className={`${column2}`}>
          <EORPriceCard />
        </div>
      </div>
    </>
  );
};

export default EmployerOfRecord;
