import * as React from "react";
const SvgJustworksLogoBhmWhite = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 672 125", style: {
  enableBackground: "new 0 0 672 125"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M0,68.2l23.4-4.4c0.4,12,4.2,17.8,12.2,17.8c6.9,0,10.1-3.2,10.1-12.5V0H70v63.3c0,24.8-10.2,35-35,35 C9.4,98.3,1.9,83.3,0,68.2z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M85.2,72.9V26.4H109v46.9c0,6.7,3.2,9.4,8.4,9.4c6.9,0,11-4.2,11-14V26.4h23.8v70h-22.7V77.5h-0.6 c-3.5,16.4-11.2,20.6-21.7,20.6C92.4,98.1,85.2,90.3,85.2,72.9z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M164.3,81.5l12.8-8.7c4.2,7.9,10.9,10.9,19.6,10.9c6.6,0,9.5-1.9,9.5-5.6c0-4.2-2.8-6.4-10.7-7.9l-9.8-1.9 c-13.2-2.4-20.3-10.1-20.3-20.9c0-14.5,11.6-22.8,29.4-22.8c17.6,0,25.9,7.3,30.4,15.4l-12.5,8c-3.2-7.3-9.6-9.6-15.6-9.6 c-6.2,0-9.4,2.2-9.4,5.9c0,3.5,2.7,5.5,9.4,6.7l9.9,1.9c14.7,2.7,21.9,8.7,21.9,21.7c0,12.3-7.9,23.4-31,23.4 C181.1,98.1,169.5,91.5,164.3,81.5z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M243.3,77.5V40.4h-9.5v-14h9.5V8H267v18.4h11.5v14H267v35c0,4.6,1.9,6.4,5.3,6.4h6.2v14.6H263 C248,96.4,243.3,91.7,243.3,77.5z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "SVGID_1_", d: "M668.6,96.3H4v28h664.6V96. 3z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "SVGID_2_" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#SVGID_1_", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("g", { style: {
  clipPath: "url(#SVGID_2_)"
} }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#A66A42"
}, d: "M476.5-118.1L31.4,481.4l39.4,29.3L516-88.8L476.5-118.1z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#583218"
}, d: "M436.5-151.7l-438,589.8l41.9,31.1l438-589.7L436.5-151.7z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#5C3922"
}, d: "M402.5-188.2L-14.2,372.9l39.4,29.3l416.7-561.1L402.5-188.2z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#A66A42"
}, d: "M335.7-180.6l-366,492.7l39.4,29.3l366-492.7L335.7-180.6z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#81583D"
}, d: "M277.4-189.6L-72.2,281l41.9,31.1l349.5-470.6L277.4-189.6z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#261306"
}, d: " M237.9-218.9l-349.5,470.6l39.4,29.3l349.5-470.6L237.9-218.9z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#7C4725"
}, d: "M284.7,643.7L742.4,27.4L701.3-1.5L243.5,614.9L284.7,643.7z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#603814"
}, d: "M121.2,611.2L621.5-62.4l-41.1-28.8L80.1,582.4L121.2,611.2z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#010101"
}, d: "M96.4,560.3L581.2-92.4l-41.1-28.8L55.3,531.5L96.4,560.3z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#5C3922"
}, d: "M244.3,613.8L702.1-2.6L661-31.4L203.2,584.9L244.3,613.8z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#A66A42"
}, d: "M204,583.9L661.8-32.5l-41.1-28.8L162.9,555L204,583.9z" }))), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M286,26.4h25.8l9.6,44.1h0.6l12.3-44.1H354l11.9,44.1h0.6l10.7-44.1h18.8l-21.7,70h-20.5l-12.5-44h-0.6 l-11.9,44h-20.5L286,26.4z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M398.1,61.4c0-22.4,14.6-36.7,36.4-36.7c21.9,0,36.4,14.3,36.4,36.7s-14.6,36.7-36.4,36.7 C412.6,98.1,398.1,83.8,398.1,61.4z M445.9,61.4c0-18.1-3.9-22.8-11.5-22.8c-7.6,0-11.5,4.7-11.5,22.8s3.9,22.8,11.5,22.8 C442,84.2,445.9,79.5,445.9,61.4z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M481.4,26.4h22.4v23.3h0.6c3.3-19.2,9.8-23.7,20.2-23.7h1.3v21h-11.5c-6.9,0-9.1,2.2-9.1,10.5v38.9h-23.8V26.4z " }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M565.4,68.4l-6.8,7.6v20.5H535V0.1h23.6v56.3l24.3-30.1h21.2L581,51.6l24.6,44.8h-25.2L565.4,68.4z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M607.2,81.5l12.9-8.7c4.2,7.9,10.9,10.9,19.6,10.9c6.6,0,9.5-1.9,9.5-5.6c0-4.2-2.8-6.4-10.7-7.9l-9.8-1.9 c-13.2-2.4-20.3-10.1-20.3-20.9c0-14.5,11.6-22.8,29.4-22.8c17.6,0,25.9,7.3,30.4,15.4l-12.5,8c-3.2-7.3-9.6-9.6-15.6-9.6 c-6.2,0-9.4,2.2-9.4,5.9c0,3.5,2.7,5.5,9.4,6.7l9.9,1.9c14.7,2.7,21.9,8.7,21.9,21.7c0,12.3-7.9,23.4-31,23.4 C624,98.1,612.3,91.5,607.2,81.5z" }));
export default SvgJustworksLogoBhmWhite;
