import { Button } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import CompanyBankNotice, {
  NoticeTypes,
} from "pages/company-settings/components/CompanyBankNotice";
import DebitBlockedNextStepsInfo from "pages/company-settings/components/DebitBlockedNextStepsInfo";
import styles from "pages/company-settings/components/InitiateMicrodepositPage.module.css";
import BankAccountPageHeader from "./BankAccountPageHeader";
import { useBankForm } from "../hooks/useBankForm";

const { footer, page, notice, content } = styles;

export default function DebitBlockedErrorPage() {
  const { backRoute, returnToFormPage } = useBankForm();

  const returnToCompanyPage = () => {
    backRoute && window.location.assign(backRoute);
  };

  return (
    <div className={page}>
      <BankAccountPageHeader />
      <div className={content}>
        <div className={notice}>
          <CompanyBankNotice
            title={"We’re unable to verify the new account"}
            type={NoticeTypes.INFO}
          >
            The new account is being protected with an ACH debit block.
          </CompanyBankNotice>
        </div>
        <DebitBlockedNextStepsInfo />
        <ActionFooter
          className={footer}
          actions={[
            <Button
              data-testid="submit-button"
              type="submit"
              onClick={returnToCompanyPage}
              key="save-button"
            >
              Done
            </Button>,
          ]}
          secondary={[
            <Button
              variant="outlined"
              onClick={returnToFormPage}
              key="cancel-button"
            >
              Try another account
            </Button>,
          ]}
        />
      </div>
    </div>
  );
}
