/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useEffect, useCallback } from "react";
import { ApolloLink, useApolloClient } from "@apollo/client";
import { useFeedbackBannerStore } from "pages/expenses/store";
import { AlertTypes, DEFAULT_ERROR } from "pages/expenses/constants";

type GraphResponse = Record<string, any>;

export const useApolloLinks = () => {
  const client = useApolloClient();
  const { setFeedbackBanner } = useFeedbackBannerStore();

  const handleResponse = useCallback(
    (data: GraphResponse) => {
      if (data && ("success" in data || "error" in data)) {
        if (!data?.success || data?.error) {
          setFeedbackBanner({
            show: true,
            type: AlertTypes.ERROR,
            message: DEFAULT_ERROR,
          });
        }
      }
    },
    [setFeedbackBanner]
  );

  const onErrorLink = useMemo(
    () =>
      new ApolloLink((operation, forward) => {
        return forward(operation).map((data) => {
          const response = Object.values(data)[0];

          for (const graphResponse of Object.values(response)) {
            handleResponse(graphResponse as GraphResponse);
          }

          return data;
        });
      }),
    [handleResponse]
  );

  useEffect(() => {
    const defaultLinks = client.link;

    client.setLink(onErrorLink.concat(defaultLinks));

    return () => client.setLink(defaultLinks);
  }, [client, onErrorLink]);
};
