import { ReactElement } from "react";
import { Card } from "../../../Card";
import styles from "../../TimeOff.module.css";
import ExternalLink from "../../../ExternalLink/ExternalLink";

export default function AdditionalLeave(): ReactElement {
  return (
    <>
      <Card title="Additional Leave" cardIconName="calendar">
        <div className={styles.cardBodyContent}>
          Record time off that isn’t covered by any PTO policy in Justworks.
          <ExternalLink
            title="Record leave"
            link="https://justworkshr.formstack.com/forms/additional_leave_request"
          />
        </div>
      </Card>
    </>
  );
}
