import { ReactElement, useContext, useState } from "react";
import styles from "./DocumentsTab.module.css";
import { Spinner } from "@justworkshr/milo-core";
import { EmptyState } from "./EmptyState";
import { useGetInternationalDocumentsQuery } from "types/generated/operations";
import { useParams } from "react-router-dom";
import { InternationalContractorMemberContext } from "../../context/InternationalContractorMemberContext";
import { UploadButton } from "./UploadButton";
import { DOCUMENT_OPERATIONS } from "./constants";
import type { InternationalDocument } from "types";
import { DocumentUploadDialog } from "./DocumentUploadDialog";
import { getMemberPreferredName } from "../../utils";
import { DeleteDocumentDialog } from "./DeleteDocumentDialog";
import { AlertContext } from "pages/international-contractor-profile-page/context/AlertContext";
import { ALERT_COLOR } from "pages/international-contractor-profile-page/constants";
import { DocumentsTable } from "./DocumentsTable";

export default function DocumentsTab(): ReactElement {
  const { id } = useParams();
  const { member } = useContext(InternationalContractorMemberContext);
  const { data, error, loading, refetch } = useGetInternationalDocumentsQuery({
    variables: {
      memberUuid: id || "",
    },
    fetchPolicy: "no-cache",
  });

  const memberName = getMemberPreferredName(member);
  const documents = data?.internationalDocuments;

  const { setAlert } = useContext(AlertContext);

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [documentToEdit, setDocumentToEdit] = useState<InternationalDocument>();
  const [documentToDelete, setDocumentToDelete] =
    useState<InternationalDocument>();

  const openUploadDialog = () => setUploadDialogOpen(true);

  const closeUploadDialog = () => {
    setUploadDialogOpen(false);
    setDocumentToEdit(undefined);
  };

  const setSuccessAlert = (operation: string) => {
    let message;

    switch (operation) {
      case DOCUMENT_OPERATIONS.CREATE:
        message = "Document succesfully uploaded.";
        break;
      case DOCUMENT_OPERATIONS.UPDATE:
        message = "Document succesfully updated.";
        break;
      case DOCUMENT_OPERATIONS.DELETE:
        message = (
          <span>
            Document <strong>{documentToDelete?.name}</strong> has been deleted.
          </span>
        );
    }

    setAlert({
      color: ALERT_COLOR.ADDITIVE,
      message,
    });
  };

  const onDocumentOperationComplete = (operation: string) => {
    window.scrollTo(0, 0);
    setSuccessAlert(operation);
    refetch();
  };

  if (loading) {
    return <Spinner />;
  }

  if (!member || error) {
    return <></>;
  }

  if (!documents || documents.length === 0) {
    return (
      <>
        <EmptyState openUploadDialog={openUploadDialog} />
        {uploadDialogOpen && (
          <DocumentUploadDialog
            close={closeUploadDialog}
            onComplete={() =>
              onDocumentOperationComplete(DOCUMENT_OPERATIONS.CREATE)
            }
          />
        )}
      </>
    );
  }

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.messageContainer}>
          <h3>Documents</h3>
          <div>These documents are shared between {memberName} and Admins.</div>
        </div>

        <div className={styles.uploadButtonWrapper}>
          <UploadButton onClick={openUploadDialog} />
        </div>
      </div>

      <DocumentsTable
        documents={documents}
        onDeleteClick={setDocumentToDelete}
        onEditClick={(doc: InternationalDocument) => {
          setDocumentToEdit(doc);
          setUploadDialogOpen(true);
        }}
      />

      {uploadDialogOpen && (
        <DocumentUploadDialog
          close={closeUploadDialog}
          documentToEdit={documentToEdit}
          onComplete={() =>
            onDocumentOperationComplete(
              documentToEdit
                ? DOCUMENT_OPERATIONS.UPDATE
                : DOCUMENT_OPERATIONS.CREATE
            )
          }
        />
      )}

      {documentToDelete && (
        <DeleteDocumentDialog
          documentToDelete={documentToDelete}
          close={() => setDocumentToDelete(undefined)}
          onComplete={() =>
            onDocumentOperationComplete(DOCUMENT_OPERATIONS.DELETE)
          }
        />
      )}
    </div>
  );
}
