import { Alert } from "@justworkshr/milo-core";
import React, { ComponentProps, useMemo, useState } from "react";

export type AlertData = Omit<ComponentProps<typeof Alert>, "children"> & {
  message?: React.ReactNode;
};

export const standardAlerts: { [key: string]: AlertData } = {
  emptyDismissable: {
    message: "",
    color: undefined,
    visible: false,
    dismissible: true,
  },
  somethingWentWrong: {
    message:
      "Something went wrong. Try refreshing the page, or if the problem persists, contact support@justworks.com.",
    color: "destructive" as const,
    visible: true,
    dismissible: true,
  },
  failedToLoadIntegrations: {
    message:
      "Failed to load some integrations. If the problem persists, contact support@justworks.com.",
    color: "destructive" as const,
    visible: true,
    dismissible: true,
  },
  setupSuccess: {
    message: "Success! Justworks and {Partner} are now connected.", // {Partner} is replaced with the partner name
    color: "additive" as const,
    visible: true,
    dismissible: true,
  },
};

export const getIntegrationAlertByKey = (key: string | undefined) =>
  standardAlerts[key || "emptyDismissable"] ||
  standardAlerts["emptyDismissable"];

const replacePartnerInAlert = (alert: AlertData, partnerName?: string) => {
  const { message } = alert;
  if (!message) {
    return alert;
  }
  return {
    ...alert,
    message:
      typeof message === "string"
        ? message.replace("{Partner}", partnerName || "the partner")
        : message,
  };
};

export const useAlert = (partnerName?: string, defaultValue?: AlertData) => {
  const alertValue = replacePartnerInAlert(
    defaultValue || standardAlerts.emptyDismissable,
    partnerName
  );
  const [alert, setAlert] = useState<AlertData>(alertValue);

  return useMemo(() => {
    const resetAlert = () => setAlert(alertValue);

    return {
      alert,
      setAlert,
      resetAlert,
      alertProps: {
        children: alert.message,
        ...alert,
        onDismiss: resetAlert,
      } as ComponentProps<typeof Alert>,
    };
  }, [alert, alertValue]);
};
