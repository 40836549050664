import React from "react";
import { useRequestDetail } from "pages/expenses/store";
import styles from "../../ReimbursementSummary.module.css";
import {
  APPROVED_STATUSES,
  INITIAL_REVIEW_STATUS,
} from "pages/expenses/constants/request-status";
import { useReimbursementSummary } from "../../ReimbursementSummary.utils";

const {
  ReimbursementSummaryItemWrapper,
  ReimbursementSummaryItemApproverLabel,
} = styles;

interface ManagerReviewProps {
  isCreateFlow: boolean;
}

export const ManagerReview: React.FC<ManagerReviewProps> = React.memo(
  ({ isCreateFlow }) => {
    const {
      requestDetail: {
        request: {
          status,
          expenses,
          myRequest,
          managerApprover: requestManagerApprover,
        },
        metadata: { isApproverView, isRequestEditView },
      },
    } = useRequestDetail();

    const {
      isAdminApproverView,
      managerReviewApprover,
      hasExpensesPermission,
      managerApproversNames,
    } = useReimbursementSummary();

    const showManagerReview =
      !!expenses.length &&
      ((!!managerApproversNames[0] &&
        (isCreateFlow ||
          (status === INITIAL_REVIEW_STATUS && !isApproverView) ||
          (isRequestEditView && !isApproverView))) ||
        (status &&
          APPROVED_STATUSES.includes(status) &&
          managerReviewApprover?.friendlyFullName) ||
        (status === INITIAL_REVIEW_STATUS &&
          requestManagerApprover?.friendlyFullName &&
          isAdminApproverView));

    const renderManagerReview = () => {
      const managerApprover = managerReviewApprover?.friendlyFullName
        ? managerReviewApprover
        : requestManagerApprover;

      if (
        managerApprover?.friendlyFullName &&
        hasExpensesPermission &&
        !myRequest &&
        !isCreateFlow
      ) {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`/employee_profile/${managerApprover?.uuid}`}
          >
            {managerApprover?.friendlyFullName}
          </a>
        );
      } else if (managerApprover?.friendlyFullName) {
        return <>{managerApprover.friendlyFullName}</>;
      } else if (!isApproverView) {
        return <>{managerApproversNames[0]}</>;
      }

      return null;
    };

    if (!showManagerReview) {
      return null;
    }

    const managerReview = renderManagerReview();

    return (
      <div className={ReimbursementSummaryItemWrapper}>
        <dt>Manager review</dt>
        <dd className={ReimbursementSummaryItemApproverLabel}>
          {managerReview}
        </dd>
      </div>
    );
  }
);
