import {
  RETURNED_STATUS,
  INITIAL_REVIEW_STATUS,
  ADMIN_APPROVABLE_REQUEST_STATUSES,
} from "pages/expenses/constants";
import {
  useRequestDetail,
  useGlobalConfigurationStore,
} from "pages/expenses/store";

export const useExpenseDetailsCards = () => {
  const {
    requestDetail: {
      request: { status },
      metadata: { isApproverView, isRequestEditView },
    },
  } = useRequestDetail();

  const { permissions } = useGlobalConfigurationStore();

  const isEditableByEmployee =
    !status || (status === RETURNED_STATUS && isRequestEditView);

  const isEditableByManager =
    (isApproverView || isRequestEditView) &&
    permissions.isManager &&
    status === INITIAL_REVIEW_STATUS;

  const isEditableByAdmin =
    (isApproverView || isRequestEditView) &&
    permissions.isAdmin &&
    status &&
    ADMIN_APPROVABLE_REQUEST_STATUSES.includes(status);

  const isEditable =
    isEditableByEmployee || isEditableByManager || isEditableByAdmin;

  return {
    isEditable,
  };
};
