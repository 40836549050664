import { useEffect } from "react";
import { asyncThrow } from "@justworkshr/fe-utility-kit";
import { ForbiddenError } from "@justworkshr/milo-core";

export const LoginCallbackError = ({ error }: { error: Error }) => {
  useEffect(() => {
    asyncThrow(error.message);
  }, [error.message]);
  return <ForbiddenError />;
};
