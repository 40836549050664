import React, { ReactElement } from "react";
import styles from "./nav-group-section.module.css";

import NavItem from "../nav-item";
import { NavGroup } from "types";

const { navGroupSection } = styles;

function generateLabelId(displayText: string) {
  return `${displayText.toLowerCase().replace(/\s+/g, "-")}-heading`;
}

type NavGroupSectionParams = Omit<NavGroup, "standaloneContent">;

export default function NavGroupSection({
  groupName,
  navItems,
}: NavGroupSectionParams): ReactElement {
  const labelId = generateLabelId(groupName);
  const navItemList = navItems.map((value, index) => (
    <li key={index}>
      <NavItem {...value} />
    </li>
  ));
  return (
    <span className={navGroupSection}>
      <h3 className={styles[groupName.toLowerCase()]} id={labelId}>
        {groupName}
      </h3>
      <ul aria-labelledby={labelId}>{navItemList}</ul>
    </span>
  );
}
