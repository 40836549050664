import { RoutePermission } from "../components/PermissionsWrapper";

export const BASE_EXPENSES_PATH = "/expenses";

export const ROUTES = {
  HOME: "/",
  REQUEST_DETAILS: "/:request_uuid",
  REVIEW_REQUEST: "/:request_uuid/review",
  NEW_REQUEST: "/new",
  EDIT_REQUEST: "/edit",
  EXPENSE_LIBRARY: "/library",
  EXPENSE_DETAILS: "/expense-details",
  POST_RECEIPT: "/receipt",
  POST_CSV_REPORT: "/report",
  POST_PDF_REPORT: "/pdf_report",
  POST_ZIP_RECEIPTS: "/zip_receipts",
  ACTIVE_REQUESTS_LIST: "/review",
  REQUEST_HISTORY: "/review/history",
  SETTINGS: "/settings",
  CATEGORIES: "/settings/categories",
  SUBMISSION_REQUIREMENTS: "/settings/categories/submission-requirements",
  AUDIT_LOG: "/:request_uuid/activity",
  PROJECTS: "/settings/projects",
  LEARN: "/learn",
};

export const ROUTES_PERMISSIONS: Record<
  (typeof ROUTES)[keyof typeof ROUTES],
  RoutePermission
> = {
  HOME: {
    isExpensesEverEnabled: true,
  },
  REQUEST_DETAILS: {
    isExpensesEverEnabled: true,
  },
  NEW_REQUEST: {
    isSubmitter: true,
    isExpensesEnabled: true,
  },
  EDIT_REQUEST: {
    isExpensesEnabled: true,
  },
  EXPENSE_LIBRARY: {
    isSubmitter: true,
    isExpensesEverEnabled: true,
  },
  EXPENSE_DETAILS: {
    isExpensesEverEnabled: true,
  },
  AUDIT_LOG: {
    isExpensesEverEnabled: true,
  },
  CATEGORIES: {
    hasJoinedExpenses: true,
    isAllowedToAccessSettingsPages: true,
  },
  SUBMISSION_REQUIREMENTS: {
    hasJoinedExpenses: true,
    isAllowedToAccessSettingsPages: true,
  },
  PROJECTS: {
    hasJoinedExpenses: true,
    isAllowedToAccessSettingsPages: true,
  },
  LEARN: {
    isAllowedToAccessProductDetailsPage: true,
  },
};

export const prependBasePath = (route: keyof typeof ROUTES) => {
  if (route === "HOME") return BASE_EXPENSES_PATH;

  return `${BASE_EXPENSES_PATH}${ROUTES[route]}`;
};
