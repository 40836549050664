export const setupTimeCopy = (setupTime: string) => {
  return (
    <p>
      Estimated setup time: <strong> {setupTime} minutes</strong>{" "}
    </p>
  );
};
export const setupHelpCopy = (helpCenterLink: string) => {
  const SUPPORT_EMAIL = "support@justworks.com";
  return (
    <p>
      Need help? Visit the{" "}
      <a
        className="milo--link-secondary"
        href={helpCenterLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        Help Center
      </a>{" "}
      or drop us a line at{" "}
      <a className="milo--link-secondary" href={`mailto: ${SUPPORT_EMAIL}`}>
        {SUPPORT_EMAIL}
      </a>
      .
    </p>
  );
};
