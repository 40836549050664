import classes from "./Page.module.css";
import benefitsClasses from "pages/benefits/benefits.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, PageHeader, TabList, Tab } from "@justworkshr/milo-core";
import { buildClockworkWebPath } from "lib/resource-finder";
import { useGetUserPermissionsQuery } from "types/generated/operations";
import { PermissionsError } from "../../PermissionsError";
import CostsTab from "./CostsTab";
import EligibilityTab from "./EligibilityTab";
import FaqTab from "./FaqTab";
import OverviewTab from "./OverviewTab";
import SetupTab from "./SetupTab";
import ResourcesBox from "../resources-box";
import EmpowerLogoSidebar from "../../../images/401k/empower-logo-sidebar.png";
import EmpowerHeader from "../../../images/401k/empower-header.png";

const Page = () => {
  const { tabId } = useParams();

  const navigate = useNavigate();

  const goToTab = (tabId: string) => {
    navigate(`/benefits/setup/401k/learn/${tabId}`);
  };

  const goToLeadPage = () => {
    navigate("/benefits/setup/401k/contact/form");
  };

  const { data, loading } = useGetUserPermissionsQuery();
  if (loading) {
    return <></>;
  }

  const PERMISSION_BENEFITS = 1;
  if (!data?.currentMember?.permissions?.includes(PERMISSION_BENEFITS)) {
    return <PermissionsError />;
  }

  const tabIds = ["overview", "eligibility", "costs", "setup", "faq"];

  return (
    <div className={classes.benefits401kLearnPage}>
      <PageHeader
        title="Integrate 401(k) with Justworks"
        linkPrevious={
          <a href={buildClockworkWebPath("/benefits/offer")}>
            Back to Add Benefits
          </a>
        }
      />

      <div className="milo--grid" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <div style={{ gridColumn: "span 8" }}>
          <div className={classes.heroImage}>
            <img src={EmpowerHeader} alt="Empower Logo" />
          </div>

          <TabList
            selected={tabIds.indexOf(tabId as string)}
            onChange={(tabIndex) => goToTab(tabIds[tabIndex])}
          >
            <Tab>Overview</Tab>
            <Tab>Eligibility</Tab>
            <Tab>Costs</Tab>
            <Tab>Set up</Tab>
            <Tab>FAQ</Tab>
          </TabList>

          <div className={classes.tabContent}>
            {tabId === "overview" && <OverviewTab />}
            {tabId === "eligibility" && <EligibilityTab />}
            {tabId === "costs" && <CostsTab />}
            {tabId === "setup" && <SetupTab />}
            {tabId === "faq" && <FaqTab />}
          </div>
        </div>

        <div className={classes.sidebar} style={{ gridColumn: "span 4" }}>
          <Box
            border={{
              borderColor: "neutralSubtle",
              borderRadius: "md",
              borderWidth: "sm",
            }}
            padding="2xl"
          >
            <div className={classes.empowerLogoSmall}>
              <img src={EmpowerLogoSidebar} alt="Empower Logo" />
            </div>

            <h3 className={benefitsClasses.benefitsHeader}>
              Transfer a plan or start a new one
            </h3>
            <p className={benefitsClasses.benefitsParagraph}>
              By combining your team's assets with all of our other customer's
              assets, we can maximize the purchasing power of your business
              &ndash; helping you secure lower plan fees than you could on your
              own.
            </p>

            <h3 className={benefitsClasses.benefitsHeader}>Next steps</h3>

            <p className={benefitsClasses.benefitsParagraph}>
              Select <strong>Get started</strong> &amp; we'll ask you a few
              questions. We’ll pass that info to Empower and they'll reach out
              to get everything set up.
            </p>

            <Button
              variant="filled"
              style={{ width: "100%" }}
              onClick={() => goToLeadPage()}
            >
              Get started
            </Button>
          </Box>

          <ResourcesBox />
        </div>
      </div>
    </div>
  );
};

export default Page;
