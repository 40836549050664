import Drawer from "app-frame/header/common/drawer";
import UserMenuDrawerHeader from "./user-menu-drawer-header";
import { UserMenuMemberWithCompany } from "types";
import UserMenuDrawerContent from "./user-menu-drawer-content";
import {
  useGetUserMenuQuery,
  useGetUserNavCapabilitiesQuery,
} from "types/generated/operations";
import { usePanelsControlContext } from "app-frame/header/context/panels-control-context-provider";
import { PanelsControlActionType } from "app-frame/header/context/panels-control-context-provider/types";
import { useGetFeatureFlag } from "../../../../lib/launch-darkly";

function UserMenuDrawer() {
  const { state, dispatch } = usePanelsControlContext();
  const { userMenuDrawerShown } = state;

  const { data: userMenuData, loading: getUserMenuQueryLoading } =
    useGetUserMenuQuery();
  const {
    data: userNavCapabilitiesData,
    loading: getUserNavCapabilitiesQueryLoading,
  } = useGetUserNavCapabilitiesQuery();
  const { getFeatureFlag } = useGetFeatureFlag();

  if (
    getUserMenuQueryLoading ||
    getUserNavCapabilitiesQueryLoading ||
    !userMenuData ||
    !userNavCapabilitiesData
  ) {
    return <></>;
  }

  const companyData = userMenuData.authenticatedUser.members;
  const currentMember = userMenuData.authenticatedMember;
  const { uuid, firstName, lastName } = currentMember;
  const { hasAccessToCsTools, hasAccessToProfileView } =
    currentMember.capabilities;
  const companyId = currentMember.company.uuid;
  const companyList: UserMenuMemberWithCompany[] = [];
  companyData?.forEach((x) => {
    if (x.company.uuid !== companyId) {
      companyList.push(x);
    }
  });
  const { hasAccessToReceiveAppAlerts, hasAccessToWhatsNewProductPosts } =
    userNavCapabilitiesData.authenticatedMember.capabilities;

  const isMultiProductNavEnabled = getFeatureFlag(
    "postlogin-multinav-dropdown"
  ) as boolean;

  const drawerHeader = (
    <UserMenuDrawerHeader
      isMultiProductNavEnabled={isMultiProductNavEnabled}
      uuid={uuid}
      memberName={`${firstName} ${lastName}`}
      hasAccessToCsTools={hasAccessToCsTools}
      hasAccessToProfileView={hasAccessToProfileView}
      onClose={() => {
        dispatch({
          type: PanelsControlActionType.CLOSE_USER_MENU_DRAWER,
        });
      }}
    />
  );

  const drawerContent = (
    <UserMenuDrawerContent
      isMultiProductNavEnabled={isMultiProductNavEnabled}
      memberUuid={uuid}
      hasAccessToAlerts={hasAccessToReceiveAppAlerts}
      hasAccessToWhatsNew={hasAccessToWhatsNewProductPosts}
      hasAccessToCsTools={hasAccessToCsTools}
      hasAccessToProfileView={hasAccessToProfileView}
      otherCompaniesOfCurrentUser={companyList}
      productsAndCompanies={userMenuData.currentUserProductsAndCompanies || []}
    />
  );

  return (
    <Drawer
      name="user-menu-drawer"
      header={drawerHeader}
      onClose={() => {
        dispatch({
          type: PanelsControlActionType.CLOSE_USER_MENU_DRAWER,
        });
      }}
      open={userMenuDrawerShown}
    >
      {drawerContent}
    </Drawer>
  );
}

export default UserMenuDrawer;
