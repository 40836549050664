import { ReactElement, useEffect, useRef, useState } from "react";
import styles from "./dropdown.module.css";

const { contentContainer, helpMenu, userMenu, dropdownArrow } = styles;

interface DropdownProps {
  dropdownName: string;
  content: ReactElement | string;
  children: ReactElement | string;
  className?: string;
  open?: boolean;
  showArrow?: boolean;
  onOpenChange?: (newOpen: boolean) => void;
  onClickOutside?: () => void;
}

export default function Dropdown({
  dropdownName,
  content,
  children,
  className,
  open = false,
  showArrow = false,
  onOpenChange,
  onClickOutside,
}: DropdownProps) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [dropdownOpen, toggleDropdownOpen] = useState(open);
  const [preDropdownOpen, togglePreDropdownOpen] = useState(open);
  const linksId = `dropdown-${dropdownName.replace(/\s/g, "")}`;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        onClickOutside && onClickOutside();
      }
    };

    if (open) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [onClickOutside, open]);

  if (open != preDropdownOpen) {
    toggleDropdownOpen(open);
    togglePreDropdownOpen(open);
  }

  const handleOnClick = () => {
    onOpenChange && onOpenChange(!dropdownOpen);
    toggleDropdownOpen(!dropdownOpen);
  };

  const contentClasses = [contentContainer];
  if (dropdownName === "help menu") {
    contentClasses.push(helpMenu);
  } else if (dropdownName === "user links") {
    contentClasses.push(userMenu);
  }

  return (
    <div className={className} data-testid={dropdownName} ref={dropdownRef}>
      <div
        aria-label={
          !dropdownOpen ? `Expand ${dropdownName}` : `Close ${dropdownName}`
        }
        aria-expanded={dropdownOpen}
        aria-controls={linksId}
        onClick={handleOnClick}
      >
        {children}
      </div>
      {dropdownOpen && (
        <div
          id={linksId}
          className={contentClasses.join(" ")}
          data-testid={linksId}
        >
          {showArrow && <div className={dropdownArrow} />}
          {content}
        </div>
      )}
    </div>
  );
}
