import { MutationHookOptions } from "@apollo/client";
import { useEffect } from "react";
import {
  ReturnToEmployeeMutation,
  useReturnToEmployeeMutation,
  ReturnToEmployeeMutationVariables,
} from "types/generated/operations";

export const useReturnToEmployee = ({
  onSuccess,
  mutationOptions = {},
}: {
  onSuccess?: () => void;
  mutationOptions?: MutationHookOptions<
    ReturnToEmployeeMutation,
    ReturnToEmployeeMutationVariables
  >;
}) => {
  const [
    returnToEmployee,
    { data: returnToEmployeeData, loading: isReturnToEmployeeLoading },
  ] = useReturnToEmployeeMutation(mutationOptions);

  useEffect(() => {
    if (
      !isReturnToEmployeeLoading &&
      returnToEmployeeData?.reviewReimbursementRequests?.success &&
      returnToEmployeeData?.reviewReimbursementRequests?.updatedRequests
    ) {
      if (onSuccess) {
        onSuccess();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnToEmployeeData, isReturnToEmployeeLoading]);

  return {
    returnToEmployee,
    returnToEmployeeData,
    isReturnToEmployeeLoading,
  };
};
