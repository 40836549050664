import React from "react";

import styles from "../landing-page.module.css";
import { GetEligibleClaimsMembersByDateQuery } from "types/generated/operations";
import { SystemIcon } from "@justworkshr/milo-icons";

interface ISearchListCardProps {
  member: GetEligibleClaimsMembersByDateQuery["eligibleClaimsMembersByDate"][0];
  onClose?: () => void;
}

export default function SearchListCard({ ...props }: ISearchListCardProps) {
  const { member, onClose } = props;
  const { homeAddress } = member;

  // 55 Water St, New York, NY, 10014
  const fullAddress = `${homeAddress?.street1}, ${homeAddress?.city}, ${homeAddress?.state}, ${homeAddress?.postalCode}`;

  return (
    <div className={styles.searchListCardWrapper}>
      <div className={styles.content}>
        <span style={styles}>{member.friendlyFullName}</span>
        <p>{member.deskTitle}</p>
        <p>{fullAddress}</p>
      </div>

      {onClose && (
        <button onClick={onClose}>
          <SystemIcon iconName="close" />
        </button>
      )}
    </div>
  );
}
