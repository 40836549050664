import { IconButton } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import styles from "./Drawer.module.css";
import React from "react";

const {
  DrawerWrapper,
  DrawerTitleWrapper,
  DrawerTitleContainer,
  DrawerContentWrapper,
} = styles;

interface DrawerProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const Drawer: React.FC<DrawerProps> = React.memo(
  ({ isOpen, title, onClose, children }) => {
    if (!isOpen) {
      return null;
    }

    return (
      <div className={DrawerWrapper}>
        <div className={DrawerTitleWrapper}>
          <div className={DrawerTitleContainer}>
            <SystemIcon iconName="document" />
            <p>{title}</p>
          </div>
          <IconButton
            variant="ghost"
            iconName="close"
            onClick={onClose}
            title={`Close ${title} drawer`}
          />
        </div>
        <div className={DrawerContentWrapper}>{children}</div>
      </div>
    );
  }
);
