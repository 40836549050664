import BaseLogoIcon from "./icons/justworks-logo-11-2-20.svg?react";
import WhiteLogoIcon from "./icons/justworks-logo-white-11-2-20.svg?react";

import PrideLogoIcon from "./icons/justworks-logo-pride.svg?react";
import PrideWhiteLogoIcon from "./icons/justworks-logo-pride-white.svg?react";

import BHMLogoIcon from "./icons/justworks-logo-bhm.svg?react";
import BHMWhiteLogoIcon from "./icons/justworks-logo-bhm-white.svg?react";

export interface LogoIconProps extends React.SVGProps<SVGSVGElement> {
  color?: "black" | "white";
  variant?: "default" | "pride" | "bhm";
}

function LogoIcon({
  color = "black",
  variant = "default",
  ...otherProps
}: LogoIconProps) {
  let DisplayLogo: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  > = BaseLogoIcon;
  switch (variant) {
    case "bhm":
      if (color === "white") DisplayLogo = BHMWhiteLogoIcon;
      else DisplayLogo = BHMLogoIcon;
      break;
    case "pride":
      if (color === "white") DisplayLogo = PrideWhiteLogoIcon;
      else DisplayLogo = PrideLogoIcon;
      break;
    case "default":
    default:
      if (color === "white") DisplayLogo = WhiteLogoIcon;
      else DisplayLogo = BaseLogoIcon;
      break;
  }
  return <DisplayLogo {...otherProps} data-testid="logo" />;
}

export default LogoIcon;
