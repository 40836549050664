import {
  ConfigurationActions,
  FeedbackBannerActions,
  FeedbackDialogActions,
  ConfigurationActionTypes,
  FeedbackDialogActionTypes,
  FeedbackBannerActionTypes,
} from "./actions";
import {
  INITIAL_CONFIGURATION,
  INITIAL_FEEDBACK_BANNER,
  INITIAL_FEEDBACK_DIALOG,
} from "./constants";
import { handleConfigurationPayload } from "./utils";

export const INITIAL_GLOBAL_STORE = {
  FeedbackBanner: INITIAL_FEEDBACK_BANNER,
  FeedbackDialog: INITIAL_FEEDBACK_DIALOG,
  Configuration: INITIAL_CONFIGURATION,
};

export type GlobalStore = typeof INITIAL_GLOBAL_STORE;

export type GlobalAction =
  | FeedbackBannerActions
  | FeedbackDialogActions
  | ConfigurationActions;

export const GlobalReducer = (
  store: GlobalStore = INITIAL_GLOBAL_STORE,
  action: GlobalAction
): GlobalStore => {
  switch (action.type) {
    case FeedbackBannerActionTypes.SET_FEEDBACKBANNER:
      return {
        ...store,
        FeedbackBanner: action.payload,
      };
    case FeedbackDialogActionTypes.SET_FEEDBACKDIALOG:
      return {
        ...store,
        FeedbackDialog: action.payload,
      };
    case ConfigurationActionTypes.SET_CONFIGURATION:
      return {
        ...store,
        Configuration: {
          ...store.Configuration,
          ...handleConfigurationPayload(action.payload),
        },
      };
    case ConfigurationActionTypes.UPDATE_CONFIGURATION:
      return {
        ...store,
        Configuration: {
          ...store.Configuration,
          ...action.payload,
        },
      };
    case ConfigurationActionTypes.UPDATE_PERMISSIONS:
      return {
        ...store,
        Configuration: {
          ...store.Configuration,
          permissions: {
            ...store.Configuration.permissions,
            ...action.payload,
          },
        },
      };
    default:
      return store;
  }
};
