export enum EmailTypes {
  Home = "homeEmail",
  Work = "workEmail",
}

export enum PayBasis {
  Salary = "salary",
  Hourly = "hourly",
}

export enum ContractTerm {
  Indefinite = "indefinite",
  Fixed = "fixed",
}

export type AlertState = {
  visible: boolean;
  message: string;
  type: "additive" | "destructive";
};
