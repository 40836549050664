/* istanbul ignore file */

import React, { useRef } from "react";
import styles from "./FileInput.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";
import { mergeClassNames } from "pages/expenses/utils";

const {
  FileInputWrapper,
  FileInputChooseFileText,
  FileInputWrapperDisabled,
  FileInputUploadButtonWrapper,
} = styles;

interface FileInputProps {
  name: string;
  accept: string;
  disabled: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FileInput: React.FC<FileInputProps> = ({
  name,
  accept,
  onChange,
  disabled = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);

    if (inputRef?.current?.value) {
      inputRef.current.value = "";
    }
  };

  return (
    <div
      className={mergeClassNames([
        FileInputWrapper,
        { [FileInputWrapperDisabled]: disabled },
      ])}
    >
      <input
        multiple
        id={name}
        name={name}
        type="file"
        ref={inputRef}
        accept={accept}
        disabled={disabled}
        onChange={handleChange}
      />
      <label htmlFor={name}>
        <button
          type="button"
          aria-controls={name}
          onClick={handleClick}
          className={FileInputUploadButtonWrapper}
        >
          <div className={FileInputChooseFileText}>
            <SystemIcon
              iconName="upload"
              size="extra-small"
              color={disabled ? "disabled" : "brand"}
            />
            <span>Choose files</span>
          </div>
          or drag and drop here
        </button>
      </label>
    </div>
  );
};
