export const OFFBOARDING_PATH = `/employer-of-record/offboarding`;

export const REQUEST_SEPARATION_COPY = {
  requestSeparationDescription:
    "Request separation of an employee from your company if they are no longer with or will be leaving the company. We'll save their member information for future reference.",
  requestSeparationNote:
    "The information entered here will be used for the termination process and to administer unemployment benefits. We'll send you an email with follow-up information.",
  nextStepsPara1: (employeeName: string) => (
    <>
      We'll be in touch soon to confirm the terms of separation, last day of
      work, and next steps. Per our contract,{" "}
      <b>you may not notify {employeeName}</b> until then. As the employer of
      record, only our written notice of separation or offer of mutual
      separation are binding in the relevant country.
    </>
  ),
  nextStepsPara2:
    "The separation will only be reflected in Justworks after the separation documents have been fully executed, the employee has officially off-boarded, and they’ve received their final payment.",
};
