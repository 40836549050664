import * as Yup from "yup";

export const microdepositVerifyFormSchema = Yup.object().shape({
  deposit1: Yup.string()
    .required("Required")
    .matches(/^(0[1-9]|[1-9]\d)$/, "Invalid amount"), // only digits 01-99 inclusive
  deposit2: Yup.string()
    .required("Required")
    .matches(/^(0[1-9]|[1-9]\d)$/, "Invalid amount"), // only digits 01-99 inclusive
});
