import { Select, SelectOption } from "@justworkshr/milo-form";
import { MemberSeparationAnswerOptionRecursive } from "pages/team-management/types";
import { ReactNode } from "react";

interface SingleSelectProps extends React.ComponentPropsWithoutRef<"select"> {
  answers: MemberSeparationAnswerOptionRecursive[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string | string[] | undefined;
}

export const SingleSelectQuestion = ({
  answers,
  onChange,
  value,
}: SingleSelectProps) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      data-testid={"singleSelectQuestion"}
    >
      {answers.map(
        (
          answer: MemberSeparationAnswerOptionRecursive,
          index: number
        ): ReactNode => (
          <SelectOption key={index}>{String(answer.value)}</SelectOption>
        )
      )}
    </Select>
  );
};
