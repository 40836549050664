import { ReactElement } from "react";
import { Button } from "@justworkshr/milo-core";

export function RollbarPage(): ReactElement {
  return (
    <div>
      <Button
        onClick={() => {
          throw new Error("test");
        }}
      >
        Throw Error
      </Button>
    </div>
  );
}
