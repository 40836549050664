import { Button } from "@justworkshr/milo-core";
import styles from "./UploadButton.module.css";
import { ReactElement } from "react";

interface UploadButtonProps {
  onClick: () => void;
}

export default function UploadButton({
  onClick,
}: UploadButtonProps): ReactElement {
  return (
    <Button
      leftIcon="upload"
      variant="outlined"
      className={styles.uploadButton}
      size="sm"
      onClick={onClick}
    >
      Upload Document
    </Button>
  );
}
