import * as Yup from "yup";

export const bankAccountFormSchema = Yup.object().shape({
  routingNumber: Yup.string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must only be digits")
    .min(9, "The routing number should be 9 digits long")
    .max(9, "The routing number should be 9 digits long"),
  accountNumber: Yup.string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must only be digits")
    .min(4, "The account number cannot be less than 4 digits long")
    .max(17, "The account number cannot be more than 17 digits long"),
});
