import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { StepperProvider } from "./stepperFormHandler";
import { AdditionalPaySteps, ExchangeRateType } from "../types";
import {
  GetEoRMembersByCompanyUuidQuery,
  useGetEoRDisbursementDatesQuery,
  useGetEoRMembersByCompanyUuidQuery,
  useGetEorExchangeRatesQuery,
  useGetEorPayPeriodsQuery,
} from "types/generated/operations";
import { ApolloError } from "@apollo/client";
import { Option } from "../components/Setup/components/SetupFormGenerator";
import { formatDate } from "pages/employer-of-record/employee-profile/utils";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { getMonthsAgoISODate } from "../utils";

type MemberByCountryType = {
  [key: string]: number;
};

export interface AdditionalPayContextProps {
  currentStep?: AdditionalPaySteps;
  membersData: GetEoRMembersByCompanyUuidQuery["eorMembers"];
  disbursementDateOptions: { [key: string]: Option };
  pastPayPeriodOptions: Option[];
  loadingMemberData: boolean;
  memberFetchError?: ApolloError;
  pastPayPeriodErrors?: ApolloError;
  disbursementDateError?: ApolloError;
  errorMessage: string;
  exchangeRates: ExchangeRateType[];
  memberCountryCount: MemberByCountryType;
  selectedCountry: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  setCurrentStep: (step: AdditionalPaySteps) => void;
  setSelectedCountry: Dispatch<SetStateAction<string>>;
}

interface AdditionalPayProviderProps {
  children: ReactNode;
}

export const AdditionalPayContext =
  createContext<AdditionalPayContextProps | null>(null);

export const AdditionalPayContextProvider: FC<AdditionalPayProviderProps> = ({
  children,
}) => {
  const [currentStep, setCurrentStep] = useState<AdditionalPaySteps>("set-up");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [membersData, setMembersData] = useState<
    GetEoRMembersByCompanyUuidQuery["eorMembers"]
  >([]);
  const [memberCountryCount, setMembersCountryCount] =
    useState<MemberByCountryType>({});

  const {
    data: allMembers,
    loading: loadingMemberData,
    error: memberFetchError,
  } = useGetEoRMembersByCompanyUuidQuery();

  const { data: disbursementDates, error: disbursementDateError } =
    useGetEoRDisbursementDatesQuery({
      variables: { country: selectedCountry },
      skip: !selectedCountry,
    });

  const disbursementDateOptions: { [key: string]: Option } = {};
  disbursementDates?.upcomingPayDate.payPeriods.forEach((period) => {
    const date = formatDate(period.disbursementDate, "MM/DD/YYYY");
    const option: Option = {
      value: period.id || "",
      label: date || "",
    };
    disbursementDateOptions[period.id] = option;
  });

  const { data: pastPayPeriods, error: pastPayPeriodErrors } =
    useGetEorPayPeriodsQuery({
      variables: {
        country: selectedCountry,
        startDate: getMonthsAgoISODate(6).split("T")[0],
        endDate: new Date().toISOString().split("T")[0],
      },
      skip: !selectedCountry,
    });

  const pastPayPeriodOptions: Option[] = [];
  pastPayPeriods?.payPeriodDates.payPeriods.forEach((period) => {
    const startDate = formatDate(period.startDate, "MM/DD/YYYY");
    const endDate = formatDate(period.endDate, "MM/DD/YYYY");
    const option: Option = {
      value: `${startDate} - ${endDate}`,
      label: `${startDate} - ${endDate}`,
    };
    pastPayPeriodOptions.push(option);
  });

  const { supportedCountriesData } = useContext(SupportedCountriesContext);
  const currencies = Object.values(supportedCountriesData).flatMap((country) =>
    (country.currencies ?? []).map((currency) => currency.currencyCode)
  );

  const ratesQuery = useGetEorExchangeRatesQuery({
    variables: { source: "USD", targets: currencies, time: null },
    skip: !currencies || currencies.length === 0,
  });

  let exchangeRates: ExchangeRateType[] = [];
  if (ratesQuery.data && ratesQuery.data?.exchangeRateData?.rates) {
    exchangeRates = ratesQuery.data.exchangeRateData.rates;
  }

  useEffect(() => {
    if (!allMembers?.eorMembers) return;

    const counts: { [key: string]: number } = {};
    const filteredMembers: GetEoRMembersByCompanyUuidQuery["eorMembers"] = [];
    allMembers.eorMembers.forEach((member) => {
      if (selectedCountry && member.workCountry.includes(selectedCountry)) {
        filteredMembers.push(member);
      }
      counts[member.workCountry] = (counts[member.workCountry] || 0) + 1;
    });

    setMembersCountryCount(counts);
    setMembersData(filteredMembers);
  }, [selectedCountry, allMembers]);

  return (
    <StepperProvider>
      <AdditionalPayContext.Provider
        value={{
          currentStep,
          membersData,
          errorMessage,
          loadingMemberData,
          memberFetchError,
          disbursementDateError,
          disbursementDateOptions,
          exchangeRates,
          memberCountryCount,
          selectedCountry,
          pastPayPeriodOptions,
          pastPayPeriodErrors,
          setSelectedCountry,
          setErrorMessage,
          setCurrentStep,
        }}
      >
        {children}
      </AdditionalPayContext.Provider>
    </StepperProvider>
  );
};
