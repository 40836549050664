import { ReactElement } from "react";
import { NotificationMemberDetails } from "types";
import styles from "../alerts-notification/alerts-notification.module.css";
import { DisplayIcon } from "@justworkshr/milo-icons";

const { alertsNotification, hasAvatar, nsfIcon, nsfMessage } = styles;

export default function NsfAlertNotification({
  message,
  url,
}: {
  message: string;
  url?: string;
  member?: NotificationMemberDetails;
}): ReactElement {
  return (
    <div className={`${alertsNotification} ${hasAvatar}`}>
      <DisplayIcon
        className={nsfIcon}
        iconName={"exclamation-mark"}
        color={"red"}
      />
      <span>
        <a className={nsfMessage} href={url}>
          {message}
        </a>
      </span>
    </div>
  );
}
