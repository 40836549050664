import React, { type ChangeEvent } from "react";

import {
  FormField,
  TextInput,
  SelectOption,
  Select,
  RadioButtonGroup,
  RadioButton,
} from "@justworkshr/milo-form";
import { useTranslation } from "react-i18next";
import { getIn, useFormikContext } from "formik";
import { FieldDataType, ValidationType } from "./types";

type Props = FieldDataType & {
  className?: string | { [key: string]: string };
  optional?: boolean;
  id?: string;
  validations?: ValidationType;
  // Override the display of the `Optional` label on a field. Does not impact validations.
  showOptional?: boolean;
  format?: (value: string) => string;
  compact?: (value: string) => string;
};

const AutoFormField = (props: Props) => {
  const {
    type,
    name,
    label,
    validations,
    showOptional,
    format,
    compact,
    placeholder = "",
  } = props;
  const { t } = useTranslation();
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange: defaultHandleChange,
  } = useFormikContext();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = formatInput(e.target.value);
    defaultHandleChange(e);
  };

  const formatInput = (value: string) => {
    return format && compact ? format(compact(value)) : value;
  };

  const generateField = () => {
    switch (type) {
      case "text":
      case "number":
        return (
          <TextInput
            name={name}
            type={type}
            onChange={handleChange}
            onBlur={handleBlur}
            value={formatInput(getIn(values, name))}
            required={validations?.required.enabled}
            minLength={validations?.text?.min?.value}
            maxLength={
              validations?.text?.max?.value || validations?.text?.length?.value
            }
            placeholder={placeholder}
          />
        );
      case "select":
        return (
          <Select
            name={name}
            onChange={defaultHandleChange}
            onBlur={handleBlur}
            value={getIn(values, name)}
            required={validations?.required.enabled}
            placeholder={placeholder}
          >
            {Object.values(props.options).map((option) => (
              <SelectOption value={option.value} key={option.value}>
                {option.description}
              </SelectOption>
            ))}
          </Select>
        );
      case "group":
        return (
          <RadioButtonGroup
            name={name}
            onChange={defaultHandleChange}
            onBlur={handleBlur}
            value={getIn(values, name)}
            defaultChecked
          >
            {Object.values(props.options).map((option) => (
              <RadioButton
                value={String(option.value)}
                label={option.description}
                key={option.value}
              />
            ))}
          </RadioButtonGroup>
        );
      default:
        return null;
    }
  };

  const getError = () => {
    if (getIn(touched, name)) {
      return t(getIn(errors, name), validations);
    }
    return "";
  };

  return (
    <FormField
      name={name}
      label={t(label || "")}
      required={showOptional ? validations?.required.enabled : true}
      error={getError()}
    >
      {generateField()}
    </FormField>
  );
};

export default AutoFormField;
