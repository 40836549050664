import { PageHeader } from "@justworkshr/milo-core";
import React, { PropsWithChildren } from "react";
import { useBankForm } from "../hooks/useBankForm";

interface BankAccountPageHeaderProps {
  title?: string;
}

export const BankAccountPageHeader: React.FC<
  PropsWithChildren<BankAccountPageHeaderProps>
> = ({ title, children }) => {
  const { backRoute, isOnboarding, isLoadingOnboardingCheck } = useBankForm();

  if (isLoadingOnboardingCheck) {
    return null;
  }

  const defaultTitle = isOnboarding ? "Connect bank account" : "Edit bank info";
  const linkText = isOnboarding ? "Back to Onboarding" : "Back to Settings";

  const linkPrevious = <a href={backRoute}>{linkText}</a>;

  return (
    <PageHeader title={title || defaultTitle} linkPrevious={linkPrevious}>
      {children}
    </PageHeader>
  );
};

export default BankAccountPageHeader;
