import {
  useGetMemberSeparationQuestionnaireQuery,
  GetMemberSeparationQuestionnaireQueryVariables,
} from "types/generated/operations";
import {
  MemberSeparationQuestionQueryResult,
  MemberSeparationQuestion,
  MemberSeparationAnswerOption,
  MemberSeparationQuestionRecursive,
} from "pages/team-management/types";

export const useGetMemberSeparationQuestionnaire = (
  variables: GetMemberSeparationQuestionnaireQueryVariables
) => {
  const { terminationCode } = variables;
  const { error, loading, data } = useGetMemberSeparationQuestionnaireQuery({
    variables: {
      terminationCode: terminationCode,
    },
    skip: !terminationCode,
  });
  const subQuestionIds: string[] = [];

  const formatSubQuestion = (
    questions: MemberSeparationQuestionQueryResult,
    question: MemberSeparationQuestion
  ): MemberSeparationQuestionRecursive => {
    return {
      questionId: question.questionId,
      questionText: question.questionText,
      inputType: question.inputType,
      validations: {
        required: question.validations?.required !== false,
        minLength: question.validations?.minLength,
      },
      answers: question.answers?.map(
        (answer: MemberSeparationAnswerOption) => ({
          value: answer.value,
          subQuestions: answer.subQuestionIds?.map((subQuestionId) => {
            const subQuestion = questions?.find(
              (q) => q.questionId === subQuestionId
            );
            if (subQuestion) {
              subQuestionIds.push(subQuestionId);
              return formatSubQuestion(questions, subQuestion);
            } else {
              return null;
            }
          }),
        })
      ),
    };
  };

  const formatQuestionsAsTree = (
    questions: MemberSeparationQuestionQueryResult
  ) => {
    return questions
      ?.map((question) => formatSubQuestion(questions, question))
      .filter((question) => !subQuestionIds.includes(question.questionId));
  };

  return {
    error,
    loading,
    fullQuestionTree: formatQuestionsAsTree(
      data?.memberSeparationQuestionnaire
    ),
    fullQuestionList: data?.memberSeparationQuestionnaire,
  };
};
