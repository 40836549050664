import { ReactElement } from "react";
import styles from "./DialogHeader.module.css";
import { Alert, IconButton } from "@justworkshr/milo-core";

interface DialogHeaderProps {
  alertError?: string;
  onClose: () => void;
  showCloseButton?: boolean;
  title?: string;
}

export default function DialogHeader({
  alertError,
  onClose,
  showCloseButton = true,
  title,
}: DialogHeaderProps): ReactElement {
  return (
    <header className={styles.container}>
      {alertError && <Alert color="destructive">{alertError}</Alert>}
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>{title}</h3>
        {showCloseButton && (
          <IconButton
            iconName="close"
            onClick={onClose}
            title="close modal"
            variant="ghost"
          />
        )}
      </div>
    </header>
  );
}
