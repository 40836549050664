import production from "./environments/production-settings";
import defaultSettings from "./environments/default-settings";
import sandbox from "./environments/sandbox-settings";
import staging from "./environments/staging-settings";
import development from "./environments/development-settings";
import test from "./environments/test-settings";
import { Environment } from "../environment-finder";
import { SettingsConfig } from "./settings-type";
import { importSettings, getEnvVariable } from "./environment-variables";
import { VARIABLE_NAMES } from "./variable-constants";

const environments: Record<Environment, Partial<SettingsConfig>> = {
  production,
  sandbox,
  staging,
  development,
  test,
};

importSettings(defaultSettings, environments);

export { getEnvVariable, VARIABLE_NAMES };
