import { ReimbursementPayloadType } from "./types";

export const REIMBURSEMENT_INITIAL_VALUES: ReimbursementPayloadType = {
  expenseDetails: {
    transactionDate: "",
    distance: undefined,
    receipts: undefined,
    additionalInformation: "",
  },
  paymentSettings: {
    payPeriodId: "",
    disbursementDate: "",
    notes: "",
  },
};
