import React from "react";
import { AuditLogItem } from "../AuditLogItem";
import { AuditLogEditedType } from "types/Expenses";
import { handleParentDifference } from "../../AuditLogPage.utils";

interface EditedProps {
  name: string;
  memberName: string;
  occurredAt: string;
  differences: AuditLogEditedType["differences"];
}

export const Edited: React.FC<EditedProps> = ({
  name,
  occurredAt,
  memberName,
  differences,
}) => {
  return (
    <AuditLogItem
      author={memberName}
      occurredAt={occurredAt}
      previewText="Edited request"
    >
      <p>
        {name} was edited by {memberName}. Here's what changed:
      </p>
      {differences.map(({ type, ...props }, index) => (
        <React.Fragment key={`${type}_${index}`}>
          {handleParentDifference(props)}
        </React.Fragment>
      ))}
    </AuditLogItem>
  );
};

export default Edited;
