import { scrollToTop } from "pages/expenses/hooks";
import { Alert } from "@justworkshr/milo-core";
import { useFeedbackBannerStore } from "pages/expenses/store";
import { getAlertColor } from "./FeedbackBanner.utils";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const FeedbackBanner = () => {
  const location = useLocation();

  const {
    feedbackBanner: { show, type, message },
    dismissFeedbackBanner,
  } = useFeedbackBannerStore();

  const [showBanner, setShowBanner] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");

  if (showBanner) {
    scrollToTop();
  }

  const handleDismiss = () => {
    dismissFeedbackBanner();
    setShowBanner(false);
  };

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (show) {
      timeoutId = setTimeout(() => {
        setShowBanner(true);
        setCurrentMessage(message || "");
      });
    }

    return () => timeoutId && clearTimeout(timeoutId);
  }, [show, message]);

  useEffect(() => {
    if (showBanner && message === currentMessage) {
      handleDismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (!showBanner || !message) {
    return null;
  }

  return (
    <div id="FeedbackBanner">
      <Alert
        dismissible
        color={getAlertColor(type)}
        onDismiss={() => handleDismiss()}
        dismissIconDescription={`close ${message} alert`}
      >
        {message}
      </Alert>
    </div>
  );
};

export default FeedbackBanner;
