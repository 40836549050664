import { useNavigate } from "react-router-dom";
import { Button, Card } from "@justworkshr/milo-core";
import { SettingsTable } from "../../../../../components";
import { prependBasePath } from "pages/expenses/constants";
import { useExpensesSettings } from "pages/expenses/hooks";
import {
  PROJECTS_CARD_DESCRIPTION,
  PROJECTS_TABLE_HEADERS,
} from "./Projects.constants";
import styles from "../AdvancedSettings.module.css";

const {
  EditButtonStyle,
  ActiveLabel,
  WithoutBottomBorder,
  ProjectsTableWrapper,
} = styles;

export const Projects = () => {
  const {
    data: { projects },
  } = useExpensesSettings();
  const navigate = useNavigate();
  return (
    <Card
      className={WithoutBottomBorder}
      title="Projects"
      description={PROJECTS_CARD_DESCRIPTION}
      actions={[
        <Button
          className={EditButtonStyle}
          onClick={() => navigate(prependBasePath("PROJECTS"))}
          type="button"
          variant="ghost"
          leftIcon="edit"
          data-testid="edit-setting-button"
        >
          Edit
        </Button>,
      ]}
    >
      {projects?.length ? (
        <div className={ProjectsTableWrapper}>
          <SettingsTable headers={PROJECTS_TABLE_HEADERS}>
            {projects.map((project) => (
              <tr key={project.projectName}>
                <td>{project.projectCode}</td>
                <td>{project.projectName}</td>
                <td className={ActiveLabel}>Active</td>
              </tr>
            ))}
          </SettingsTable>
        </div>
      ) : null}
    </Card>
  );
};

export default Projects;
