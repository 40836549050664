import {
  useFeedbackBannerStore,
  useGlobalConfigurationStore,
} from "../../store";
import { AlertTypes } from "../../constants";
import { useToggleExpensesEnabledMutation } from "types/generated/operations";

export const useToggleExpensesEnabled = () => {
  const { setFeedbackBanner } = useFeedbackBannerStore();
  const { updateConfiguration, updatePermissions } =
    useGlobalConfigurationStore();

  const [toggleExpensesEnabled, { loading: isToggleExpensesEnabledLoading }] =
    useToggleExpensesEnabledMutation({
      onCompleted: (toggleExpensesEnabledData) => {
        if (
          toggleExpensesEnabledData?.updateExpensePolicy?.success &&
          toggleExpensesEnabledData?.updateExpensePolicy?.updatedPolicy
        ) {
          const isExpensesEnabled =
            toggleExpensesEnabledData.updateExpensePolicy.updatedPolicy
              ?.enabled || false;

          updateConfiguration({
            isExpensesEnabled,
            hasJoinedExpenses: true,
            isExpensesEverEnabled: true,
          });

          updatePermissions({
            isAllowedToFirstTimeSetup: false,
          });

          if (isExpensesEnabled) {
            setFeedbackBanner({
              type: AlertTypes.SUCCESS,
              show: true,
              message:
                "Great, Expenses has been turned on for all your employees.",
            });
          }
        }
      },
    });

  return {
    toggleExpensesEnabled,
    isToggleExpensesEnabledLoading,
  };
};
