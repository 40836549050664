import { ExpenseProject } from "types/Expenses";

export const parseProject = (project: ExpenseProject) => {
  return {
    uuid: project.uuid,
    visible: project.visible,
    projectName: project.projectName,
    projectCode: project.projectCode,
    displayName: project.displayName,
  };
};
