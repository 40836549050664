import {
  createContext,
  ReactNode,
  FC,
  useMemo,
  useState,
  useCallback,
  useContext,
} from "react";

interface InsightsLearnPageViewContextValue {
  hasViewedInsightsLearnPage: boolean;
  setHasViewedInsightsLearnPage(): void;
}

export const InsightsLearnPageViewContext =
  createContext<InsightsLearnPageViewContextValue>({
    hasViewedInsightsLearnPage: false,
    setHasViewedInsightsLearnPage: () => {
      // no-op
    },
  });

interface InsightsLearnPageViewContextProviderProps {
  children: ReactNode;
}

const InsightsLearnPageViewContextProvider: FC<
  InsightsLearnPageViewContextProviderProps
> = ({ children }) => {
  const localStorageIsAvailable = useMemo(() => {
    try {
      const x = "__storage_test__";
      localStorage.setItem(x, x);
      localStorage.removeItem(x);
      return true;
    } catch {
      return false;
    }
  }, []);

  const [hasViewedInsightsLearnPage, setHasViewedInsightsLearnPageBase] =
    useState(
      localStorageIsAvailable
        ? localStorage.getItem("hasViewedInsightsLearnPage") === "true"
        : false
    );

  const setHasViewedInsightsLearnPage = useCallback(() => {
    if (localStorageIsAvailable) {
      localStorage.setItem("hasViewedInsightsLearnPage", "true");
    }
    setHasViewedInsightsLearnPageBase(true);
  }, [localStorageIsAvailable]);

  return (
    <InsightsLearnPageViewContext.Provider
      value={{ hasViewedInsightsLearnPage, setHasViewedInsightsLearnPage }}
    >
      {children}
    </InsightsLearnPageViewContext.Provider>
  );
};

export default InsightsLearnPageViewContextProvider;

export const useInsightsLearnPageViewContext = () =>
  useContext(InsightsLearnPageViewContext);
