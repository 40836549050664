export const EMPTY_STATE_NOTICE_TITLE =
  "There are no reimbursement requests that were approved.";

export const EMPTY_STATE_NOTICE_COPY = "Take a minute to catch your breath.";

export const REQUEST_HISTORY_TABLE_HEADERS = [
  {
    key: "",
    label: "",
    isSortable: false,
  },
  {
    key: "EMPLOYEE",
    label: "Employee",
    isSortable: false,
  },
  {
    key: "REQUEST_NAME",
    label: "Request name",
    isSortable: false,
  },
  {
    key: "DATE",
    label: "Submission date",
    isSortable: true,
  },
  {
    key: "TOTAL_REIMBURSEMENT",
    label: "Total reimbursement",
    isSortable: false,
  },
  {
    key: "REVIEW_STATUS",
    label: "Review status",
    isSortable: false,
  },
  { key: "PAY_DATE", label: "Pay date", isSortable: true },
];
export const REQUEST_HISTORY_TABLE_HEADERS_WITH_NON_REIMBURSABLE = [
  {
    key: "",
    label: "",
    isSortable: false,
  },
  {
    key: "EMPLOYEE",
    label: "Employee",
    isSortable: false,
  },
  {
    key: "REQUEST_NAME",
    label: "Request name",
    isSortable: false,
  },
  {
    key: "DATE",
    label: "Submission date",
    isSortable: true,
  },
  {
    key: "NON_REIMBURSABLE",
    label: "Non-reimbursable",
    isSortable: false,
  },
  {
    key: "TOTAL_REIMBURSEMENT",
    label: "Total reimbursement",
    isSortable: false,
  },
  {
    key: "REVIEW_STATUS",
    label: "Review status",
    isSortable: false,
  },
  { key: "PAY_DATE", label: "Pay date", isSortable: true },
];
