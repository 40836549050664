import { useNavigate, useParams } from "react-router-dom";
import classes from "./EmployeeBlackoutPage.module.css";
import benefitsClasses from "pages/benefits/benefits.module.css";
import { Box, PageHeader, Tab, TabList } from "@justworkshr/milo-core";
import { useGetUser401kInfoQuery } from "types/generated/operations";
import { buildClockworkWebPath } from "lib/resource-finder";
import EmpowerHeader from "../../../images/401k/empower-header.png";
import OverviewTab from "./OverviewTab";
import CurrentTab from "./CurrentTab";
import FaqTab from "./FaqTab";
import ResourcesBox from "../resources-box";

const EmployeeBlackoutPage = () => {
  const { data, loading } = useGetUser401kInfoQuery();

  const ytdContributions = data?.currentMember.member401kInfo.ytdContributions;
  const currentRates =
    data?.currentMember.member401kInfo.latestActiveDeferralRates;

  const { tabId } = useParams();

  const navigate = useNavigate();

  const goToTab = (tabId: string) => {
    navigate(`/benefits/setup/401k/employee/transition/${tabId}`);
  };

  const tabIds = ["overview", "current", "faq"];

  if (loading) {
    return <></>;
  }

  return (
    <div className={classes.employeeBlackoutPage}>
      <PageHeader
        title="Coming soon: A new 401(k) provider"
        linkPrevious={
          <a href={buildClockworkWebPath("/my_benefits/retirement_planning")}>
            Back to Benefits Center
          </a>
        }
      />

      <div className="milo--grid" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <div style={{ gridColumn: "span 8" }}>
          <div className={classes.heroImage}>
            <img src={EmpowerHeader} alt="Empower Logo" />
          </div>
          <TabList
            selected={tabIds.indexOf(tabId as string)}
            onChange={(tabIndex) => goToTab(tabIds[tabIndex])}
          >
            <Tab>Overview</Tab>
            <Tab>Current</Tab>
            <Tab>FAQ</Tab>
          </TabList>

          <div className={classes.tabContent}>
            {tabId === "overview" && <OverviewTab />}
            {tabId === "current" && (
              <CurrentTab
                ytdContributions={ytdContributions}
                currentRates={currentRates}
              />
            )}
            {tabId === "faq" && <FaqTab />}
          </div>
        </div>
        <div className={classes.sidebar} style={{ gridColumn: "span 4" }}>
          <Box
            border={{
              borderColor: "neutralSubtle",
              borderRadius: "md",
              borderWidth: "sm",
            }}
            padding="2xl"
          >
            <h3 className={benefitsClasses.benefitsHeader}>Have questions?</h3>
            <p className={benefitsClasses.benefitsParagraph}>
              If you have any questions during the blackout period, you can call
              the dedicated support line at{" "}
              <a href={"tel:8555954015"} style={{ whiteSpace: "nowrap" }}>
                (855) 595-4015
              </a>{" "}
              or visit{" "}
              <a
                href="https://www.empower.com/client/justworks/transition/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Empower transition
              </a>
              .
            </p>
          </Box>
          <ResourcesBox show_transition_link={true} />
        </div>
      </div>
    </div>
  );
};

export default EmployeeBlackoutPage;
