import styles from "./RequestsListPage.module.css";
import {
  Table,
  Notice,
  TableBody,
  TableHeader,
  NoticeTypes,
  PaginationSelect,
  Loading,
} from "pages/expenses/components";
import { useState } from "react";
import {
  REQUESTS_LIST_TABLE_HEADERS,
  NO_REQUESTS_MESSAGE,
} from "./RequestsListPage.constants";
import {
  useGetManagersQuery,
  useGetRequestsListQuery,
  GetRequestsListQueryVariables,
} from "types/generated/operations";
import {
  DEFAULT_TABLE_PARAMS,
  PAGINATION_OPTIONS,
} from "pages/expenses/constants";
import { setTableQueryVariables } from "pages/expenses/utils";
import { RequestsListTableRow } from "./components";

const { RequestsListPageWrapper } = styles;

export const RequestsListPage = () => {
  const [tableParams, setTableParams] = useState(DEFAULT_TABLE_PARAMS);

  const { loading: isRequestsListPageLoading, data: requestsListPageData } =
    useGetRequestsListQuery({
      variables: setTableQueryVariables(
        tableParams
      ) as GetRequestsListQueryVariables,
    });

  const { loading: isManagersLoading, data: managersData } =
    useGetManagersQuery();

  const isLoading = isRequestsListPageLoading || isManagersLoading;
  const hasManagerApprover =
    (managersData?.expenseManagement?.managers || [])?.length > 0;
  const hasRequests =
    (requestsListPageData?.expenseManagement?.requests?.requests || [])
      ?.length > 0;

  const paginationProps = {
    options: PAGINATION_OPTIONS,
    currentPage:
      (requestsListPageData?.expenseManagement?.requests?.page || 0) + 1,
    totalResults:
      requestsListPageData?.expenseManagement?.requests?.totalResults || 0,
    resultsPerPage: tableParams.resultsPerPage,
    onPageChange: (newPageNum: number) =>
      setTableParams((prevTableParams) => ({
        ...prevTableParams,
        pageNum: newPageNum - 1,
      })),
    onSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) =>
      setTableParams((prevTableParams) => ({
        ...prevTableParams,
        resultsPerPage: parseInt(event.target.value, 10),
      })),
  };

  if (isLoading) {
    return <Loading />;
  }

  if ((!hasRequests && !isLoading) || !requestsListPageData) {
    return <Notice type={NoticeTypes.INFO}>{NO_REQUESTS_MESSAGE}</Notice>;
  }

  return (
    <div className={RequestsListPageWrapper}>
      <Table dataTestId="request-table">
        <TableHeader
          sortBy={tableParams.sortBy}
          headers={REQUESTS_LIST_TABLE_HEADERS}
          sortDirection={tableParams.sortDirection}
          onSort={({ sortBy, sortDirection }) =>
            setTableParams((prevTableParams) => ({
              ...prevTableParams,
              pageNum: 0,
              sortBy,
              sortDirection,
            }))
          }
        />
        <TableBody>
          {requestsListPageData.expenseManagement.requests.requests.map(
            (request) => {
              return (
                <RequestsListTableRow
                  request={request}
                  key={request.uuid}
                  hasManagerApprover={hasManagerApprover}
                />
              );
            }
          )}
        </TableBody>
      </Table>
      <PaginationSelect {...paginationProps} />
    </div>
  );
};

export default RequestsListPage;
