import { useCallback } from "react";

import {
  FeedbackBannerStoreType,
  INITIAL_FEEDBACK_BANNER,
} from "../../constants";
import { FeedbackBannerActionTypes } from "../../actions";
import { useExpensesSelectorStore } from "../../store";

export const useFeedbackBannerStore = () => {
  const { store: feedbackBanner, dispatch } = useExpensesSelectorStore(
    (store) => store.FeedbackBanner
  );

  const setFeedbackBanner = useCallback(
    (payload: FeedbackBannerStoreType) => {
      dispatch({
        type: FeedbackBannerActionTypes.SET_FEEDBACKBANNER,
        payload,
      });
    },
    [dispatch]
  );

  const dismissFeedbackBanner = useCallback(() => {
    dispatch({
      type: FeedbackBannerActionTypes.SET_FEEDBACKBANNER,
      payload: INITIAL_FEEDBACK_BANNER,
    });
  }, [dispatch]);

  return { feedbackBanner, setFeedbackBanner, dismissFeedbackBanner };
};
