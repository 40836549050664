import * as Yup from "yup";

const PLACEHOLDER = "_ _ /_ _ /_ _ _ _";

const validateDate = Yup.string()
  .required("Required")
  .test("is-placeholder", "Required", function (value) {
    return !value.includes(PLACEHOLDER);
  })
  .test("is-future-date", "Future dates are not allowed", function (value) {
    if (value.includes(PLACEHOLDER)) {
      return true;
    }
    const dateValue = new Date(value);
    const now = new Date();
    now.setHours(0, 0, 0, 0); // set to start of the day
    return dateValue.getTime() <= now.getTime();
  });

export const validationFormSchema = Yup.object().shape({
  missTime: Yup.string().required("This field is required"),
  paid: Yup.string().when(["missTime"], ([missTime]) => {
    if (missTime === "Yes") {
      return Yup.string().required("This field is required");
    } else {
      return Yup.string();
    }
  }),
  losingTime: Yup.string().when(["missTime"], ([missTime]) => {
    if (missTime === "Yes") {
      return validateDate;
    } else {
      return Yup.string();
    }
  }),
  returnToWorkDate: Yup.string().when(
    ["returnToWorkBoolean", "missTime"],
    ([returnToWorkBoolean, missTime], schema) => {
      if (missTime === "Yes") {
        return returnToWorkBoolean ? schema : validateDate;
      } else {
        return Yup.string();
      }
    }
  ),
  returnToWorkBoolean: Yup.boolean().nullable(),
});
