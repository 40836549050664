export const managerPermissionsTaskCardBodyCopy = (
  <p>
    In <strong>Manage employee expenses</strong>, select the managers that will
    be able to approve reimbursement requests from their direct reports.
  </p>
);

export const managerPermissionsTaskCardProTipCopy =
  "Managers can review requests first and then submit to Admins to review and \
schedule payments.";
