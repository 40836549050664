import * as Yup from "yup";
import {
  InitialIntakeFormValues,
  InvoluntaryTerminationReasonTypes,
  TerminationTypeTypes,
  VoluntaryTerminationReasonTypes,
} from "./InitialIntakeForm.types";
import {
  INVOLUNTARY_TERMINATION_REASON_OPTIONS,
  VOLUNTARY_TERMINATION_REASON_OPTIONS,
} from "./InitialIntakeForm.constants";
import {
  MemberSeparation,
  MemberSeparationAnswer,
} from "pages/team-management/types";
import {
  SaveMemberSeparationAnswersMutationFn,
  SeparationForm,
} from "types/generated/operations";
import {
  formatDateAsString,
  isoStringToDate,
} from "pages/team-management/routes/SeparationFlow/SeparationReview/SeparationReview.util";
import { isEmptyValue } from "pages/team-management/utils/utils";

export const getDateFromString: (str: string) => Date | null = (
  str: string
) => {
  try {
    const parseRaw = str.replace(
      /(\d{2})\/(\d{2})\/(\d{4})/,
      "$3-$1-$2T00:00:00Z"
    );
    const dateParts = parseRaw.split(/\D/).map((part) => parseInt(part));
    return new Date(
      dateParts[0],
      dateParts[1] - 1,
      dateParts[2],
      dateParts[3],
      dateParts[4],
      dateParts[5]
    );
  } catch (_) {
    return null;
  }
};

export const isDateMoreThan30DaysPast = (dateStr: string) => {
  if (!dateStr?.length) {
    return false;
  }

  const date = getDateFromString(dateStr);
  if (!date) {
    return false;
  }
  const lowerLimit = new Date().setDate(new Date().getDate() - 30);
  return lowerLimit > date.getTime();
};

export const initialIntakeFormSchema = (effectiveDate: string) =>
  Yup.object().shape({
    termination_date: Yup.date()
      .required("Separation date is required")
      .min(
        effectiveDate,
        `You may only make changes on or after ${formatDateAsString(
          isoStringToDate(effectiveDate)
        )}`
      ),
    final_work_date: Yup.date()
      .required("Last day worked is required")
      .max(
        Yup.ref("termination_date"),
        "The last day worked cannot be after the separation date"
      ),
    termination_type: Yup.string().required("Separation type is required"),
    termination_reason: Yup.string().required("Separation reason is required"),
  });

export const getTerminationReasonOptionsByType = (
  terminationType: string | undefined
) => {
  return terminationType === TerminationTypeTypes.VOLUNTARY
    ? VOLUNTARY_TERMINATION_REASON_OPTIONS
    : INVOLUNTARY_TERMINATION_REASON_OPTIONS || {};
};

export const getTerminationReasonByValue: (
  reasonValue: string | null | undefined
) =>
  | VoluntaryTerminationReasonTypes
  | InvoluntaryTerminationReasonTypes
  | "" = (reasonValue) => {
  let terminationReason:
    | keyof typeof VoluntaryTerminationReasonTypes
    | keyof typeof InvoluntaryTerminationReasonTypes
    | "" = "";
  [VoluntaryTerminationReasonTypes, InvoluntaryTerminationReasonTypes].some(
    (enumDef) => {
      const key = Object.keys(enumDef).find(
        (enumKey: string) =>
          enumDef[enumKey as keyof typeof enumDef] === reasonValue
      );
      if (key) {
        terminationReason = enumDef[key as keyof typeof enumDef];
        return true;
      }
    }
  );
  return terminationReason;
};

export const getInitialValues: (
  memberSeparation: MemberSeparation | undefined
) => InitialIntakeFormValues = (
  memberSeparation: MemberSeparation | undefined
) => {
  const initialValues: InitialIntakeFormValues = {
    termination_date: "",
    final_work_date: "",
    termination_type: "",
    termination_reason: "",
  };

  if (!memberSeparation) {
    return initialValues;
  }

  const { memberSeparationAnswers } = memberSeparation;
  const findAnswer: (id: string) => MemberSeparationAnswer | undefined = (
    id: string
  ) =>
    memberSeparationAnswers?.find(
      (memberSeparationAnswer) => memberSeparationAnswer?.fieldId === id
    );

  let fieldId = "termination_date";
  let record: MemberSeparationAnswer | undefined = findAnswer(fieldId);
  if (record?.values?.length) {
    initialValues.termination_date = record.values[0].valueDate || "";
  }

  fieldId = "final_work_date";
  record = findAnswer(fieldId);
  if (record?.values?.length) {
    initialValues.final_work_date = record.values[0].valueDate || "";
  }

  fieldId = "termination_type";
  record = findAnswer(fieldId);
  if (record?.values?.length) {
    const terminationType: TerminationTypeTypes =
      TerminationTypeTypes[
        record.values[0].valueTextSmall?.toUpperCase() as keyof typeof TerminationTypeTypes
      ];
    initialValues.termination_type = terminationType;
  }

  fieldId = "termination_reason";
  record = findAnswer(fieldId);
  if (record?.values?.length) {
    initialValues.termination_reason = getTerminationReasonByValue(
      record.values[0].valueTextSmall
    );
  }

  return initialValues;
};

export const buildSeparationAnswers = (values: InitialIntakeFormValues) => {
  const isInvalidDate = (value: string | undefined, valueType: string) =>
    valueType === "valueDate" && value?.toLowerCase() === "invalid date";
  const getEntry = (
    fieldId: string,
    value: string | undefined,
    valueType: string
  ) => ({
    fieldId,
    [valueType]:
      isEmptyValue(value) || isInvalidDate(value, valueType)
        ? null
        : (value as string),
  });
  const separationAnswers: {
    [key: string]: string | null;
    fieldId: string;
  }[] = [];
  const terminationDateEntry = getEntry(
    "termination_date",
    values.termination_date,
    "valueDate"
  );
  const finalWorkDateEntry = getEntry(
    "final_work_date",
    values.final_work_date,
    "valueDate"
  );
  const terminationTypeEntry = getEntry(
    "termination_type",
    values.termination_type,
    "valueTextSmall"
  );
  const terminationReasonEntry = getEntry(
    "termination_reason",
    values.termination_reason,
    "valueTextSmall"
  );

  [
    terminationDateEntry,
    finalWorkDateEntry,
    terminationTypeEntry,
    terminationReasonEntry,
  ].forEach((entry) => {
    if (entry) {
      separationAnswers.push(entry);
    }
  });

  return separationAnswers;
};

export const saveAnswers = async (
  saveMemberSeparationAnswersMutation: SaveMemberSeparationAnswersMutationFn,
  memberSeparation: MemberSeparation,
  values: InitialIntakeFormValues
) =>
  await saveMemberSeparationAnswersMutation({
    variables: {
      memberSeparationId: memberSeparation.memberSeparationId,
      separationFormType: SeparationForm.IntakeForm,
      separationAnswers: buildSeparationAnswers(values),
    },
  });

export const autoSaveAnswers = async (
  saveMemberSeparationAnswersMutation: SaveMemberSeparationAnswersMutationFn,
  memberSeparation: MemberSeparation | undefined,
  values: InitialIntakeFormValues,
  initialValues: InitialIntakeFormValues,
  lastAutoSavedValues: InitialIntakeFormValues | undefined,
  setLastAutoSavedValues: React.Dispatch<
    React.SetStateAction<InitialIntakeFormValues | undefined>
  >
) => {
  if (!memberSeparation) {
    return;
  }

  const initialValuesEqual = areIntakeFormValuesEqual(values, initialValues);
  const lastAutoSavedValuesEqual = areIntakeFormValuesEqual(
    values,
    lastAutoSavedValues
  );
  if (initialValuesEqual || lastAutoSavedValuesEqual) {
    return;
  }

  await saveMemberSeparationAnswersMutation({
    variables: {
      memberSeparationId: memberSeparation.memberSeparationId,
      separationFormType: SeparationForm.IntakeFormPartial,
      separationAnswers: buildSeparationAnswers(values),
    },
  });

  setLastAutoSavedValues(values);
};

export const areIntakeFormValuesEqual: (
  values1: InitialIntakeFormValues | undefined,
  values2: InitialIntakeFormValues | undefined
) => boolean = (values1, values2) => {
  if (!values1 && !values2) {
    return true;
  }
  if (!values1 || !values2) {
    return false;
  }
  const keys = Object.keys(values1) as Array<keyof typeof values1>;
  return keys.every((k) => values1[k] === values2[k]);
};
