import { Button } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import { Link } from "react-router-dom";
import { WAIVER_EDIT_URL } from "pages/workers-compensation/document-request/constants";

import { EmployeeOptionType } from "../../types";
import styles from "./employee-review.module.css";
import sharedStyles from "../waiver-review.module.css";

const { formBorder, formInfo, formInfoHeader, header, namesParagraph } = styles;
const { editButtonStyle, removePadding } = sharedStyles;

export const EmployeeReview = ({
  employees,
}: {
  employees: EmployeeOptionType[];
}) => {
  const names = employees.map((employee) => employee.name);
  const employeeTitle = `${employees.length} ${
    employees.length === 1 ? "employee" : "employees"
  }  selected`;

  return (
    <section className={formBorder}>
      <div className="milo--grid">
        <div className={formInfo}>
          <div className={header}>
            <h3 className={formInfoHeader}>{employeeTitle}</h3>
            <Button
              to={`${WAIVER_EDIT_URL}?currentStep=1`}
              as={Link}
              variant="ghost"
              className={removePadding}
            >
              <span className={editButtonStyle}>
                <SystemIcon iconName="edit" color="brand" />
                Edit
              </span>
            </Button>
          </div>

          <p className={namesParagraph} data-heap-redact-text={true}>
            {names.join(", ")}
          </p>
        </div>
      </div>
    </section>
  );
};
