import { PageHeader } from "@justworkshr/milo-core";
import { NsfAlertMessage, ResolutionInstructions } from "./components";
import styles from "./NsfResolutionInstructions.module.css";
import { useParams } from "react-router-dom";
import { useNsfDetails } from "pages/nsf-resolution-instructions/hooks/useNsfDetails";
import { buildClockworkWebPath } from "lib/resource-finder";

const { NsfResolutionInstructionsWrapper, NsfSubtext } = styles;

export default function NsfResolutionInstructions() {
  const { objectFlagId } = useParams();
  const safeObjectFlagId = objectFlagId ?? "";
  const data = useNsfDetails(safeObjectFlagId);

  if (data) {
    return (
      <div className={NsfResolutionInstructionsWrapper}>
        <PageHeader
          border
          linkPrevious={
            <a href={buildClockworkWebPath("/dashboard")}>Back to dashboard</a>
          }
          title="Unable to process payroll"
        >
          <div className={NsfSubtext}>
            The upcoming payroll has been suspended because we're unable to
            process the payment.
          </div>
        </PageHeader>
        <NsfAlertMessage returnCode={data.nsfDetails.returnCode} />
        <ResolutionInstructions
          invoiceAmount={data.nsfDetails.amount}
          dueDate={data.nsfDetails.dueDate}
        />
      </div>
    );
  }

  return <div></div>;
}
