import { useBankForm } from "pages/company-settings/hooks/useBankForm";
import styles from "pages/company-settings/components/InitiateMicrodepositPage.module.css";

const { accountDetailsContainer, accountDetails, errorContent, formField } =
  styles;

export default function NewAccountDetails() {
  const { formData } = useBankForm();

  return (
    <div>
      <div className={errorContent}>
        <h2>
          <b>New account details</b>
        </h2>
        <div className={accountDetailsContainer}>
          <div className={accountDetails}>
            <div className={formField}>
              <h4>Bank</h4>
              <p>{formData.bankName}</p>
            </div>
            <div className={formField}>
              <h4>Account type</h4>
              <p>{"Checking account"}</p>
            </div>
          </div>
          <div className={accountDetails}>
            <div className={formField}>
              <h4>Routing number</h4>
              <p>{formData.routingNumber}</p>
            </div>
            <div className={formField}>
              <h4>Account number</h4>
              <p>{formData.accountNumber}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
