import { FormField, Select, SelectOption } from "@justworkshr/milo-form";
import React from "react";
import { ExpenseCategory } from "types/Expenses";

interface CategoryProps {
  categories: ExpenseCategory[];
  selectedCategory: ExpenseCategory | null;
  handleCategoryChange: (categoryName: "name") => void;
}

export const Category: React.FC<CategoryProps> = React.memo(
  ({ categories, selectedCategory, handleCategoryChange }) => {
    const hasCategoriesVisible = categories.some(
      (category) => category.visible
    );

    if (!hasCategoriesVisible) return null;

    const visibleCategories = categories.filter((category) => category.visible);

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
      handleCategoryChange(event.target.value as "name");

    return (
      <FormField label="Category" required>
        <Select
          onChange={handleSelectChange}
          value={selectedCategory?.name || ""}
          data-testid="category-dropdown"
        >
          <SelectOption value="" disabled>
            Select...
          </SelectOption>
          {visibleCategories.map((category) => {
            return (
              <SelectOption
                key={category.uuid}
                value={category.name}
                data-testid="category-option"
              >
                {category.name}
              </SelectOption>
            );
          })}
        </Select>
      </FormField>
    );
  }
);
