import React from "react";
import {
  COMPLETED,
  CardStatus,
  ExpensesTaskCard,
  NOT_STARTED_REQUIRED,
} from "pages/expenses/components/ExpensesTaskCard";
import { SystemIcon } from "@justworkshr/milo-icons";
import { Button } from "@justworkshr/milo-core";
import { buildClockworkWebPath } from "lib/resource-finder";
import {
  useFeedbackBannerStore,
  useGlobalConfigurationStore,
} from "pages/expenses/store";
import { AlertTypes } from "pages/expenses/constants";
import {
  adminPermissionsTaskCardBodyCopy,
  adminPermissionsTaskCardProtipCopy,
} from "./AdminPermissionsTaskCard.constants";
import styles from "../../SettingsPage.module.css";

const { CardBody, ProTipHeading } = styles;

interface AdminPermissionsTaskCardProps {
  cardStatus: CardStatus;
}

export const AdminPermissionsTaskCard: React.FC<
  AdminPermissionsTaskCardProps
> = ({ cardStatus }) => {
  const { setFeedbackBanner } = useFeedbackBannerStore();
  const {
    permissionsAdmins,
    permissions: { isGranter },
  } = useGlobalConfigurationStore();

  const handleClick = () => {
    if (!isGranter) {
      setFeedbackBanner({
        type: AlertTypes.ERROR,
        show: true,
        message: `You need edit employee permissions. Reach out to a
              company admin like ${permissionsAdmins[0]} who can set-up
              permissions for this step.`,
      });
    } else {
      window.location.href = buildClockworkWebPath(
        "/permissions?expenses_setup=true"
      );
    }
  };

  return (
    <ExpensesTaskCard
      status={cardStatus}
      title="Admin permissions"
      activeCard={cardStatus !== COMPLETED}
    >
      <div className={CardBody}>
        {adminPermissionsTaskCardBodyCopy}
        <div className={ProTipHeading}>
          <SystemIcon iconName="arrow-right" />
          <strong>Pro tip</strong>
        </div>
        {adminPermissionsTaskCardProtipCopy}
        <Button
          onClick={handleClick}
          variant={cardStatus === NOT_STARTED_REQUIRED ? "filled" : "outlined"}
          data-testid="get-started-admin-button"
        >
          {cardStatus === COMPLETED ? "Review" : "Get started"}
        </Button>
      </div>
    </ExpensesTaskCard>
  );
};
