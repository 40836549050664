import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { buildClockworkWebPath } from "lib/resource-finder";

export default function Redirector() {
  const navigate = useNavigate();

  useEffect(() => {
    if (import.meta.env.VITE_APP_MSW_ENABLED === "true") {
      navigate("/you-are-in-local-mode");
    } else {
      window.location.href = buildClockworkWebPath("/");
    }
  }, [navigate]);

  return (
    <div data-testid="redirector">
      {import.meta.env.VITE_APP_MSW_ENABLED === "true"
        ? "Redirecting..."
        : "Redirecting to Clockwork Web Dashboard"}
    </div>
  );
}
