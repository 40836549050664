import { RadioButtonGroup } from "./../../../../../components/RadioButtonGroup/RadioButtonGroup";
import { BooleanQuestion } from "../components/SeparationQuestion/StyledComponents/BooleanQuestion/BooleanQuestion";
import { DatePickerQuestion } from "../components/SeparationQuestion/StyledComponents/DatePickerQueston/DatePickerQuestion";
import { MultiSelectQuestion } from "../components/SeparationQuestion/StyledComponents/MultiSelectQuestion/MultiSelectQuestion";
import { SingleSelectQuestion } from "../components/SeparationQuestion/StyledComponents/SingleSelectQuestion/SingleSelectQuestion";
import { TextAreaQuestion } from "../components/SeparationQuestion/StyledComponents/TextAreaQuestion/TextAreaQuestion";
import { DocumentInputQuestion } from "../components/SeparationQuestion/StyledComponents/DocumentInputQuestion/DocumentInputQuestion";
import { TimePickerQuestion } from "../components/SeparationQuestion/StyledComponents/TimePickerQueston/TimePickerQuestion";
import { TextInputQuestion } from "../components/SeparationQuestion/StyledComponents/TextInputQuestion/TextInputQuestion";

type SeparationQuestionComponent =
  | typeof RadioButtonGroup
  | typeof SingleSelectQuestion
  | typeof BooleanQuestion
  | typeof TextAreaQuestion
  | typeof TextInputQuestion
  | typeof DatePickerQuestion
  | typeof DocumentInputQuestion
  | typeof MultiSelectQuestion
  | typeof TimePickerQuestion;

interface ComponentMapType {
  [key: string]: SeparationQuestionComponent;
}

export const COMPONENT_INPUT_MAP: ComponentMapType = {
  radioButtonGroup: RadioButtonGroup, //Currently not needed
  singleSelect: SingleSelectQuestion,
  boolean: BooleanQuestion,
  textarea: TextAreaQuestion,
  textinput: TextInputQuestion,
  date: DatePickerQuestion,
  document: DocumentInputQuestion,
  multiSelect: MultiSelectQuestion,
  time: TimePickerQuestion,
};
