import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useResetScrollOnRouteChange = () => {
  const location = useLocation();
  const preserveScroll = location.state?.preserveScrollPosition;
  const pathname = location.pathname;

  useEffect(() => {
    if (preserveScroll) {
      return;
    }
    scrollTo({ top: 0, left: 0 });
  }, [pathname, preserveScroll]);
};
