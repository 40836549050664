import { FormField, TextInput } from "@justworkshr/milo-form";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import styles from "./EmailsFragment.module.css";

type FormFragment = {
  homeEmail: string;
  workEmail: string;
};

export default function EmailsFragment() {
  const { t } = useTranslation();
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<FormFragment>();

  const fieldErrorHandler = (name: "workEmail" | "homeEmail") =>
    errors[name] && touched[name] ? errors[name] : undefined;

  return (
    <div className={styles.emailsContainer}>
      <FormField
        name="homeEmail"
        label={t("Home email address")}
        required
        error={fieldErrorHandler("homeEmail")}
      >
        <TextInput
          name="homeEmail"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.homeEmail}
        />
      </FormField>
      <FormField
        name="workEmail"
        label={t("Work email address")}
        required
        error={fieldErrorHandler("workEmail")}
      >
        <TextInput
          required
          name="workEmail"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.workEmail}
        />
      </FormField>
    </div>
  );
}
