import { ROUTES } from "./pdpGeneral";

export const HEADER_REVIEW_CONTENT = {
  ltd: {
    header: "Set up long term disability insurance",
    benefitType: "long term disability insurance",
    goBackRoute: ROUTES.ltdSetupPage,
    submitSuccessRoute: ROUTES.ltdPdpPage,
  },
  std: {
    header: "Set up short term disability insurance",
    benefitType: "short term disability insurance",
    goBackRoute: ROUTES.stdSetupPage,
    submitSuccessRoute: ROUTES.stdPdpPage,
  },
  basicAndAdd: {
    header: "Set up life insurance",
    benefitType:
      "basic life and accidental death and dismemberment (AD&D) insurances",
    goBackRoute: ROUTES.basicAndADDSetupPage,
    submitSuccessRoute: ROUTES.basicAndADDPdpPage,
  },
};
