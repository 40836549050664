const MONTHS = [
  {
    value: "",
    description: "Month",
    disabled: true,
  },
  {
    value: "1",
    description: "January",
  },
  {
    value: "2",
    description: "February",
  },
  {
    value: "3",
    description: "March",
  },
  {
    value: "4",
    description: "April",
  },
  {
    value: "5",
    description: "May",
  },
  {
    value: "6",
    description: "June",
  },
  {
    value: "7",
    description: "July",
  },
  {
    value: "8",
    description: "August",
  },
  {
    value: "9",
    description: "September",
  },
  {
    value: "10",
    description: "October",
  },
  {
    value: "11",
    description: "November",
  },
  {
    value: "12",
    description: "December",
  },
];

export interface SelectOptionType {
  value: string;
  description: string;
  disabled?: boolean;
}

type DaysFn = (limit: number) => SelectOptionType[];

const setDays: DaysFn = (limit = 31) => {
  const days: SelectOptionType[] = [
    {
      value: "",
      description: "Day",
      disabled: true,
    },
  ];

  for (let index = 1; index <= limit; index++) {
    days.push({
      description: index.toString(),
      value: index.toString(),
    });
  }

  return days;
};

const years: SelectOptionType[] = [
  {
    value: "",
    description: "Year",
    disabled: true,
  },
];

const currentYear = new Date().getFullYear();
for (let index = currentYear - 5; index < currentYear + 20; index++) {
  years.push({
    value: index.toString(),
    description: index.toString(),
  });
}

const YEARS = years;

export { setDays, YEARS, MONTHS };
