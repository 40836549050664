import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Avatar, Tab, TabList } from "@justworkshr/milo-core";
import Tooltip from "@justworkshr/milo-tooltip";
import { buildClockworkWebPath } from "lib/resource-finder";
import styles from "./basic-info.module.css";
import { useGetMemberAccountSettingsQuery } from "types/generated/operations";

const {
  informationGrid,
  tabBar,
  tabText,
  basicInfoPage,
  column1,
  column2,
  card,
  cardInfo,
  cardFields,
  dataValues,
  employeeVerification,
  adminStyling,
  profile,
  profileCardInfo,
} = styles;

export default function BasicInfo(): ReactElement {
  const navigate = useNavigate();
  const { data, loading } = useGetMemberAccountSettingsQuery();
  if (loading || !data) {
    return <></>;
  }
  const { isTerminated } = data?.authenticatedMember;
  return (
    <Card
      title="Your account settings"
      actions={[
        <a key="0" href="http://nyan.cat">
          View your public profile
        </a>,
      ]}
    >
      <div className={basicInfoPage}>
        <div className={tabBar}>
          <TabList defaultSelected={0} orientation="vertical">
            <Tab className={tabText}>Basic Information</Tab>
            <Tab
              className={tabText}
              onClick={() => {
                window.location.assign(
                  buildClockworkWebPath("/account_settings/finances")
                );
              }}
            >
              Finances
            </Tab>
            <Tab className={tabText}>Security/Log in</Tab>
          </TabList>
        </div>
        <div className={informationGrid}>
          <div className={column1}>
            <Card
              className={card}
              title="General information"
              actions={[
                <a href="">
                  <Button
                    as="button"
                    color="brand"
                    leftIcon="edit"
                    size="sm"
                    variant="ghost"
                    onClick={() => {
                      navigate("/account-settings/edit-profile");
                    }}
                  >
                    Edit
                  </Button>
                </a>,
              ]}
            >
              <dl className={cardInfo}>
                <dt className={cardFields}>Full Name</dt>
                <dd className={dataValues}>John Jane Doe</dd>
                <dt className={cardFields}>Name in Directory</dt>
                <dd className={dataValues}>John Doe</dd>
                <dt className={cardFields}>Date of Birth</dt>
                <dd className={dataValues}>May 28 2024</dd>
                <dt className={cardFields}>Sex</dt>
                <dd className={dataValues}>Male</dd>
                <dt className={cardFields}>SSN</dt>
                <dd className={dataValues}>123-45-6789</dd>
              </dl>
            </Card>
            <Card
              className={card}
              title="Job information"
              actions={[
                <a>
                  <Button
                    as="button"
                    color="brand"
                    leftIcon="edit"
                    size="sm"
                    variant="ghost"
                    onClick={() => {
                      navigate("/account-settings/edit-profile");
                    }}
                  >
                    Edit
                  </Button>
                </a>,
              ]}
            >
              <dl className={cardInfo}>
                <dt className={cardFields}>Title</dt>
                <dd className={dataValues}>SWE Intern</dd>
                <dt className={cardFields}>Office</dt>
                <dd className={dataValues}>New York, NY</dd>
                <dt className={cardFields}>Start Date</dt>
                <dd className={dataValues}>May 28, 2024</dd>
                <dt className={cardFields}>Pay Basis</dt>
                <dd className={dataValues}>$XXX/year</dd>
              </dl>
              <a href="/compensation-history">View History</a>
            </Card>
          </div>
          <div className={column2}>
            <Card
              className={card}
              title="Profile"
              actions={[
                <a>
                  <Button
                    as="button"
                    color="brand"
                    leftIcon="edit"
                    size="sm"
                    variant="ghost"
                    onClick={() => {
                      navigate("/account-settings/edit-profile");
                    }}
                  >
                    Edit
                  </Button>
                </a>,
              ]}
            >
              <div className={profile}>
                <Avatar name="John Doe" />
                <dl className={profileCardInfo}>
                  <dt className={cardFields}>Birthday</dt>
                  <dd className={dataValues}>Show on Public Profile</dd>
                  <dt className={cardFields}>Pronouns</dt>
                  <dd className={dataValues}>Not Specified</dd>
                </dl>
              </div>
            </Card>
            <Card
              className={card}
              title="Contact Information"
              actions={[
                <a>
                  <Button
                    as="button"
                    color="brand"
                    leftIcon="edit"
                    size="sm"
                    variant="ghost"
                    onClick={() => {
                      navigate("/account-settings/edit-profile");
                    }}
                  >
                    Edit
                  </Button>
                </a>,
              ]}
            >
              <dl className={cardInfo}>
                <dt className={cardFields}>Home & Mailing Address</dt>
                <dd className={dataValues}>
                  123 Sesame Street Brownstone New York, NY
                </dd>
                <dt className={cardFields}>Phone</dt>
                <dd className={dataValues}>123-456-789</dd>
                <dt className={cardFields}>Email</dt>
                <dd className={dataValues}>personalemail@email.com</dd>
                <dd className={dataValues}>workemail@email.com</dd>
                <dt className={cardFields}>Emergency Contact</dt>
                <dd className={dataValues}>Not on File</dd>
              </dl>
            </Card>
            {!isTerminated && (
              <div className={employeeVerification}>
                <p>Looking for an employment verification letter?</p>
                <span>
                  Contact your{" "}
                  <span className={adminStyling}>
                    <Tooltip
                      color="inverse"
                      position="top"
                      title="Your admins:"
                    >
                      Admins
                    </Tooltip>
                  </span>
                  .
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
