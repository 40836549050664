import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import { useFeedbackBannerStore } from "pages/expenses/store";
import {
  COMPLETED,
  CardStatus,
  ExpensesTaskCard,
  NOT_STARTED_REQUIRED,
} from "pages/expenses/components/ExpensesTaskCard";
import { AlertTypes, prependBasePath } from "pages/expenses/constants";
import { ExpensesSettingsAdmins, CurrentMember } from "types/Expenses";
import {
  categoriesTaskCardBodyCopy,
  categoriesTaskCardProTipCopy,
} from "./CategoriesTaskCard.constants";
import styles from "../../SettingsPage.module.css";

const { CardBody, ProTipHeading } = styles;

interface CategoriesTaskCardProps {
  cardStatus: CardStatus;
  adminPermissionsCardStatus: CardStatus;
  expenseAdmins: ExpensesSettingsAdmins;
  currentMember: CurrentMember | undefined;
}

export const CategoriesTaskCard: React.FC<CategoriesTaskCardProps> = ({
  cardStatus,
  expenseAdmins,
  currentMember,
  adminPermissionsCardStatus,
}) => {
  const navigate = useNavigate();
  const { setFeedbackBanner } = useFeedbackBannerStore();
  const currentMemberisAdmin = expenseAdmins.find(
    (admin) => admin.uuid === currentMember?.uuid
  );
  const isCardActive =
    adminPermissionsCardStatus === COMPLETED &&
    cardStatus === NOT_STARTED_REQUIRED;

  const showContent = isCardActive || cardStatus === COMPLETED;

  const handleClick = () => {
    if (!currentMemberisAdmin) {
      setFeedbackBanner({
        show: true,
        type: AlertTypes.ERROR,
        message: `You need Manage expenses permissions. Reach out
              to an admin-like ${expenseAdmins[0]?.friendlyFullName}
              -who can set up categories for your expenses.`,
      });
    } else {
      navigate(prependBasePath("CATEGORIES"));
    }
  };

  return (
    <ExpensesTaskCard
      status={cardStatus}
      title="Categories"
      activeCard={isCardActive}
    >
      <div className={CardBody}>
        {categoriesTaskCardBodyCopy}
        {isCardActive && (
          <>
            <div className={ProTipHeading}>
              <SystemIcon iconName="arrow-right" />
              <strong>Pro tip</strong>
            </div>
            <p>{categoriesTaskCardProTipCopy}</p>
          </>
        )}
        {showContent && (
          <Button
            onClick={handleClick}
            disabled={adminPermissionsCardStatus === NOT_STARTED_REQUIRED}
            data-testid="get-started-categories-button"
          >
            {cardStatus === COMPLETED ? "Review" : "Get started"}
          </Button>
        )}
      </div>
    </ExpensesTaskCard>
  );
};

export default CategoriesTaskCard;
