import styles from "./TableBody.module.css";
import React from "react";

const { TableBodyWrapper } = styles;

interface TableBodyProps {
  children: React.ReactNode;
}

export const TableBody: React.FC<TableBodyProps> = React.memo(
  ({ children }) => {
    return <tbody className={TableBodyWrapper}>{children}</tbody>;
  }
);

export default TableBody;
