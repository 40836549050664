import { ReactElement } from "react";
import LogoIcon from "app-frame/common/LogoIcon";
import styles from "./footer.module.css";

const { footer, logoAndCopyrightWrapper, copyright, links } = styles;

export default function Footer(): ReactElement {
  const year = new Date().getFullYear();

  return (
    <div className="milo--grid">
      <footer className={footer} data-testid={footer}>
        <div className={logoAndCopyrightWrapper}>
          <LogoIcon color="black" height="16" />
          <div className={copyright}>
            Copyright &copy; 2013-{year} Justworks, Inc. and its affiliates. All
            rights reserved.
          </div>
        </div>
        <div className={links}>
          <a href={"tel:8885341711"} aria-label={"8 8 8. 5 3 4. 1 7 1 1."}>
            (888) 534-1711
          </a>
          <a
            href="https://www.justworks.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>
          <a
            href="https://justworks.com/referrals?utm_source=secure&utm_medium=footer&utm_campaign=referralprogram-secure"
            target="_blank"
            rel="noreferrer"
          >
            Referral Program
          </a>
          <a
            href="https://www.justworks.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </footer>
    </div>
  );
}
