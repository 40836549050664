import { PaymentInfoFormType } from "./types";

export const initialValues: PaymentInfoFormType = {
  paymentInfo: {
    accountNickname: "",
    accountNumber: "",
    accountType: "",
    countryCode: "",
    currency: "",
    sortCode: "",
    institutionNumber: "",
    transitNumber: "",
    clabe: "",
    bankName: "",
    iban: "",
    bankCode: "",
  },
};
