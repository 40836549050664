import { ReactElement } from "react";
import { FormField, RadioButtonGroup } from "@justworkshr/milo-form";
import MessageWithLink from "pages/employer-of-record/components/MessageWithLink/MessageWithLink";
import styles from "../../../../SetupFormGenerator.module.css";
import { RadioButtonCard } from "pages/employer-of-record/payments/components/RadioButtonCard";
import { GeneratedComponentPropWithSet, Option } from "../../../../types";
import { FormPayloadType } from "../../../../../../types";

export function RadioInput<T extends FormPayloadType = FormPayloadType>({
  field,
  accessor,
  value,
  setFieldValue,
}: GeneratedComponentPropWithSet<T>): ReactElement {
  return (
    <FormField label={field.label} required={field.required}>
      <>
        {field.message && (
          <div className={styles.description}>
            <MessageWithLink {...field.message} />
          </div>
        )}
        <RadioButtonGroup name={field.name}>
          <div className={styles.radioButtonWrapper}>
            {field.options?.map((option: Option) => (
              <RadioButtonCard
                key={option.value}
                name={field.name}
                label={option.label}
                description={option.description || ""}
                value={option.value}
                checked={value === option.value}
                onChange={(value) => setFieldValue(`setUp.${accessor}`, value)}
              />
            ))}
          </div>
        </RadioButtonGroup>
      </>
    </FormField>
  );
}
