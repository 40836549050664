import { ReactNode, createContext, useContext, useState } from "react";

type alertColorType =
  | "additive"
  | "warning"
  | "disabled"
  | "info"
  | "destructive"
  | undefined;

interface AlertContextValue {
  alertMessage: string;
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
  alertColor: alertColorType;
  setAlertColor: React.Dispatch<React.SetStateAction<alertColorType>>;
  alertShown: boolean;
  toggleAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

interface AlertContextProviderProps {
  children: ReactNode;
}

const AlertContext = createContext<AlertContextValue>({
  alertMessage: "",
  setAlertMessage: () => {
    // do nothing
  },
  alertColor: "additive",
  setAlertColor: () => {
    // do nothing
  },
  alertShown: false,
  toggleAlert: () => {
    // do nothing
  },
});

export function useAlertContext() {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error(
      "useAlertContext must be used within a AlertContextProvider"
    );
  }
  return context;
}

export default function AlertContextProvider({
  children,
}: AlertContextProviderProps) {
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState<alertColorType>("additive");
  const [alertShown, toggleAlert] = useState(false);

  return (
    <AlertContext.Provider
      value={{
        alertMessage,
        setAlertMessage,
        alertColor,
        setAlertColor,
        alertShown,
        toggleAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
}
