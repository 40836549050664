import React from "react";
import { ExpenseDialog } from "../ExpenseDialog";
import { Button } from "@justworkshr/milo-core";

interface DeleteRequestDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteRequestDialog: React.FC<DeleteRequestDialogProps> = ({
  isOpen,
  onClose,
  onDelete,
}) => {
  return (
    <ExpenseDialog
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        <Button
          type="button"
          variant="ghost"
          key="keep-request"
          onClick={onClose}
        >
          Keep Request
        </Button>,
        <Button
          type="button"
          onClick={onDelete}
          color="destructive"
          key="delete-request"
          data-testid="delete-request-dialog-button"
        >
          Delete Request
        </Button>,
      ]}
    >
      <h2>Delete this request?</h2>
      <p>
        Are you sure you want to delete this request? Any expenses you&apos;ve
        added won&apos;t be saved.
      </p>
    </ExpenseDialog>
  );
};
