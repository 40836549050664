import { ReactElement, useState } from "react";
import {
  FormField,
  TextInput,
  TextArea,
  InputMessage,
} from "@justworkshr/milo-form";
import { useGetFeatureFlag } from "lib/launch-darkly";

import { CertificateHolderEditProps } from "../waivers-of-subrogation/types";
import CertificateFileInput from "./certificate-file-input";
import styles from "./certificate-holder-edit.module.css";
import StateSelect from "./state-select";
import { useAdditionalMessage, ALL_STATES } from "../constants";

const {
  certificateHolder,
  formBorder,
  inputField,
  firstInput,
  textArea,
  header,
} = styles;

const certNameCopy: { coi: string; waiver: string } = {
  coi: "This is the name of the party requesting the COI from you",
  waiver:
    "Name and full address of the party requesting the waiver (this could be a landlord, vendor, etc.)",
};

export default function CertificateHolderEdit({
  formData,
  handleEventChange,
  errors,
  docType,
  setDocuments,
}: CertificateHolderEditProps): ReactElement {
  const {
    certName,
    certAdditionalNames,
    street1,
    street2,
    city,
    state,
    postalCode,
    additionalInformation,
  } = formData;

  const [showAdditionalMessage, setShowAdditionalMessage] = useState(false);
  const { getFeatureFlag } = useGetFeatureFlag();
  const supportingDocumentsEnabled = getFeatureFlag(
    "waiver_coi_supporting_documentation"
  );

  return (
    <div className={formBorder}>
      <div className="milo--grid">
        <div className={certificateHolder} data-testid="certificateHolderEdit">
          <h2 className={header}>Certificate details</h2>
          <hr />

          <div className={firstInput}>
            <div className={inputField}>
              <FormField
                name="certName"
                label="Certificate holder name"
                required={true}
                error={errors.certName}
                message={
                  docType === "waiver"
                    ? "This is the entity that the waiver of subrogation is in favor of and likely the party requesting the waiver."
                    : ""
                }
              >
                <>
                  <TextInput
                    name="certName"
                    onChange={handleEventChange}
                    value={certName}
                    data-heap-redact-text={true}
                  />
                  <InputMessage type="help">
                    {docType === "coi" ? certNameCopy.coi : certNameCopy.waiver}
                  </InputMessage>
                </>
              </FormField>
            </div>
          </div>

          <div className={inputField}>
            <FormField
              name="street1"
              label="Address street line 1"
              error={errors.street1}
              required={true}
            >
              <TextInput
                name="street1"
                onChange={handleEventChange}
                value={street1}
                data-heap-redact-text={true}
              />
            </FormField>
          </div>

          <div className={inputField}>
            <FormField
              name="street2"
              label="Address street line 2"
              error={errors.street2}
            >
              <TextInput
                name="street2"
                value={street2}
                onChange={handleEventChange}
                data-heap-redact-text={true}
              />
            </FormField>
          </div>

          <div className={inputField}>
            <FormField
              name="city"
              label="City"
              required={true}
              error={errors.city}
            >
              <TextInput
                name="city"
                value={city}
                onChange={handleEventChange}
              />
            </FormField>
          </div>

          <div className={inputField}>
            <FormField
              name="state"
              label="State"
              required={true}
              error={errors.state}
            >
              <StateSelect
                selectedState={state}
                handleEventChange={handleEventChange}
                stateList={ALL_STATES}
              />
            </FormField>
          </div>

          <div className={inputField}>
            <FormField
              name="postalCode"
              label="ZIP code"
              required={true}
              error={errors.postalCode}
            >
              <TextInput
                name="postalCode"
                value={postalCode}
                onChange={handleEventChange}
              />
            </FormField>
          </div>

          <div className={inputField}>
            <FormField
              name="certAdditionalNames"
              label="Additional holders"
              error={errors.certAdditionalNames}
              message={
                docType === "waiver"
                  ? "Add additional entities and vendors that should be included on this waiver."
                  : ""
              }
            >
              <>
                <TextInput
                  name="certAdditionalNames"
                  value={certAdditionalNames}
                  onChange={(e) => {
                    handleEventChange(e);
                    setShowAdditionalMessage(true);
                  }}
                  data-heap-redact-text={true}
                />
                {showAdditionalMessage && !errors.certAdditionalNames && (
                  <InputMessage type="help">
                    {useAdditionalMessage}
                  </InputMessage>
                )}
              </>
            </FormField>
          </div>

          <div className={inputField}>
            <FormField name="additionalInformation" label="Additional notes">
              <div className={textArea}>
                <TextArea
                  name="additionalInformation"
                  value={additionalInformation}
                  onChange={handleEventChange}
                />
              </div>
            </FormField>
          </div>
          {supportingDocumentsEnabled && (
            <div className={inputField}>
              <CertificateFileInput setDocuments={setDocuments} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
