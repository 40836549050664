import { Tab, TabList } from "@justworkshr/milo-core";
import { useState } from "react";
import styles from "./InfoTabs.module.css";
import {
  InfoTabsContent,
  TabsContentProps,
} from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/TabsContentTemplate";

const { tabsAndContent } = styles;

export type TabConfig = {
  title: string;
  Component: React.FC<TabsContentProps>;
};

export type TabsConfig = { [key: string]: TabConfig };

export type InfoTabsProps = {
  tabsConfig: TabsConfig | undefined;
  tabsContent: InfoTabsContent | undefined;
};

const InfoTabs: React.FC<InfoTabsProps> = ({ tabsConfig, tabsContent }) => {
  const [activeTab, setActiveTab] = useState<keyof TabsConfig>(0);

  const handleTabClick = (tabKey: keyof TabsConfig) => {
    setActiveTab(tabKey);
  };

  const ActiveTabComponent = tabsConfig && tabsConfig[activeTab]?.Component;

  return (
    <div className={tabsAndContent}>
      <TabList onChange={handleTabClick}>
        {tabsConfig &&
          Object.entries(tabsConfig).map(([key, { title }]) => (
            <Tab key={key}>{title}</Tab>
          ))}
      </TabList>
      <div>
        {ActiveTabComponent && tabsContent && (
          <ActiveTabComponent tabsContent={tabsContent} />
        )}
      </div>
    </div>
  );
};

export default InfoTabs;
