import { useCallback } from "react";

import { useSettingsSelectorStore } from "../../store";
import {
  SetCategoriesPayload,
  SetActiveCategoryPayload,
} from "../../constants";
import { CategoryActionTypes } from "../../actions";

export const useCategoriesStore = () => {
  const { store: categories, dispatch } = useSettingsSelectorStore(
    (store) => store.Categories
  );

  const setActiveCategory = useCallback(
    (payload: SetActiveCategoryPayload) => {
      dispatch({
        type: CategoryActionTypes.SET_ACTIVE_CATEGORY,
        payload,
      });
    },
    [dispatch]
  );

  const setCategories = useCallback(
    (payload: SetCategoriesPayload) => {
      dispatch({
        type: CategoryActionTypes.SET_CATEGORIES,
        payload,
      });
    },
    [dispatch]
  );

  return {
    categories,
    setCategories,
    setActiveCategory,
    activeCategory: categories.activeCategory,
  };
};
