import { Select, SelectOption } from "@justworkshr/milo-form";

type StateSelectProps = {
  selectedState: string;
  handleEventChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  stateList: { description: string; value: string; disabled?: boolean }[];
  disabled?: boolean;
  readonly?: boolean;
};

const StateSelect = ({
  selectedState,
  handleEventChange,
  stateList,
  disabled,
}: StateSelectProps): JSX.Element => {
  return (
    <Select
      value={selectedState}
      name="state"
      onChange={handleEventChange}
      disabled={disabled}
    >
      {stateList.map((state) => {
        return (
          <SelectOption
            key={state.value}
            value={state.value}
            disabled={state.disabled}
          >
            {state.description}
          </SelectOption>
        );
      })}
    </Select>
  );
};

export default StateSelect;
