import { useGetHeapInfoQuery } from "types/generated/operations";

function Heap(): JSX.Element {
  const heap = window.heap;
  const { data, loading } = useGetHeapInfoQuery();
  if (!data || loading) return <></>;
  const currentMember = data.authenticatedMember;
  if (heap && heap.loaded) {
    if (currentMember) {
      heap.identify(currentMember.uuid);
      heap.addUserProperties({
        member_uuid: currentMember.uuid,
        member_type: currentMember.memberType,
        member_status: currentMember.memberStatus,
        admin_status: currentMember.adminStatus,
        company_benefits_status: currentMember.company.companyBenefitsStatus,
        manager_status: currentMember.managerStatus,
        permissions: currentMember.permissions,
        office_uuid: currentMember.office ? currentMember.office.uuid : "",
        company_uuid: currentMember.company.uuid,
        company_plan_type: currentMember.company.companyPlanType,
      });
    }
  }
  return <span data-testid="heap-script"></span>;
}

export default Heap;
