import mergeWith from "lodash/mergeWith";
import { RequestDetailStore } from "../reducer";
import { SetRequestDetailPayload } from "../constants";

export const mergeRequestDetail = (
  currentRequestDetailStore: RequestDetailStore["RequestDetail"],
  storeUpdates: SetRequestDetailPayload
) => {
  return mergeWith(
    currentRequestDetailStore,
    storeUpdates,
    (objValue, srcValue) => {
      if (Array.isArray(objValue)) {
        return srcValue;
      }
    }
  );
};
