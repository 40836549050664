import { ReactElement, useEffect, useState } from "react";
import styles from "./incident-response.module.css";
import { Formik, useFormikContext, Form } from "formik";
import { FormikFormField } from "lib/formik/FormikFormField";
import StateSelect from "pages/workers-compensation/document-request/components/state-select";
import { ALL_STATES } from "../../constants";

import { TextInput, CheckboxInput } from "@justworkshr/milo-form";
import { IncidentResponse, PageData, ClaimFormProps } from "../../../types";
import FormContinue from "../../shared/form-continue";
import SharedForm from "../../shared/shared-form-outline";
import { validationSchema } from "./utils";
import ErrorBlockMessage from "./components/error-block-message";

const {
  incidentContainer,
  header,
  firstSection,
  secondSection,
  thirdSection,
  titleThirdSection,
  checkBoxWrap,
  optional,
  contentBar,
  verticalBar,
  contentText,
  emsContent,
  emsInput,
  addressWrap,
  cityWrap,
  disabledSelectedState,
  treatmentDetailLabel,
  stateRequired,
  errorCheckBox,
  btnContinue,
} = styles;

function FormikEffect({
  setAddressFieldNecessary,
}: {
  setAddressFieldNecessary: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { values, setValues, setFieldValue, touched } =
    useFormikContext<IncidentResponse>();
  const handleResetNoMedicalTreatment = () => {
    setValues({
      ...values,
      EMSTransport: false,
      firstAid: false,
      overnightHospitalization: false,
      medicalClinic: false,
      other: false,
      treatmentDetails: "",
      address: {
        street1: "",
        street2: "",
        city: "",
        state: "",
        postalCode: "",
      },
      phoneNumber: "",
    });
  };

  useEffect(() => {
    const checkboxNames = [
      "noMedicalTreatment",
      "firstAid",
      "medicalClinic",
      "EMSTransport",
      "overnightHospitalization",
      "other",
    ];

    const hasSomeTreatmentTouched = checkboxNames.some(
      (checkboxName) => touched[checkboxName as keyof IncidentResponse]
    );

    if (hasSomeTreatmentTouched) {
      const hasSomeTreatmentChecked = checkboxNames.some(
        (checkboxName) => values[checkboxName as keyof IncidentResponse]
      );

      setFieldValue(
        "checkBoxTreatment",
        hasSomeTreatmentChecked ? true : undefined
      );
    }
  }, [values, setFieldValue, touched]);

  useEffect(() => {
    if (values.noMedicalTreatment) {
      handleResetNoMedicalTreatment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.noMedicalTreatment]);

  useEffect(() => {
    if (
      !!values.address?.street1 ||
      !!values.address?.city ||
      !!values.address?.state ||
      !!values.address?.postalCode
    ) {
      setAddressFieldNecessary(true);
    } else {
      setAddressFieldNecessary(false);
    }
  }, [
    values.address?.street1,
    values.address?.city,
    values.address?.state,
    values.address?.postalCode,
    setAddressFieldNecessary,
  ]);

  return null; // This component doesn't render anything
}

export default function IncidentResponseForm({
  handleErrorMessage,
  submitDraft,
  setPageData,
  goForward,
  goBack,
  exitForm,
  pageData,
  currentPageNumber,
  name,
}: ClaimFormProps & {
  setPageData: (e: IncidentResponse) => void;
  submitDraft: (section: IncidentResponse, total: PageData) => void;
}): ReactElement {
  const [addressFieldNecessary, setAddressFieldNecessary] =
    useState<boolean>(false);

  const formData = pageData.incidentResponse;

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        submitDraft(values, pageData);
        setPageData(values);
        goForward();
      }}
    >
      {({ values, errors, setFieldValue, setTouched }) => {
        const touchedEvent = ({
          target,
        }: React.ChangeEvent<HTMLInputElement>) => {
          const { name, checked } = target;

          setFieldValue(name, checked);
          setTouched({ [name]: true });
        };

        return (
          <SharedForm
            handleErrorMessage={handleErrorMessage}
            currentPageNumber={currentPageNumber}
            name={name}
            key="exit-response-to-the-injury"
            exitFn={() => {
              submitDraft(values, pageData);
              exitForm();
            }}
          >
            <Form data-testid="incident-response-form">
              <FormikEffect
                setAddressFieldNecessary={setAddressFieldNecessary}
              />
              <div className={header}>
                <h1>Response to the injury</h1>
              </div>
              <div className={incidentContainer}>
                <div className={firstSection}>
                  <div className={emsContent}>
                    <div className={emsInput}>
                      <CheckboxInput
                        label="Select if EMS, police, or fire department was contacted"
                        name="wasContacted"
                        checked={values.wasContacted}
                        value="wasContacted"
                        onChange={(event) => {
                          const contacted = event.target.checked;

                          setFieldValue("wasContacted", contacted);
                          if (!contacted) {
                            setFieldValue("wasContactedDetails", "");
                          }
                        }}
                      />
                    </div>
                  </div>

                  {values.wasContacted && (
                    <div className={contentBar}>
                      <div className={verticalBar} />
                      <div className={contentText}>
                        <FormikFormField
                          label="Share which services were contacted and any report numbers given."
                          name="wasContactedDetails"
                          required
                        >
                          <TextInput name="wasContactedDetails" />
                        </FormikFormField>
                      </div>
                    </div>
                  )}
                </div>

                <div className={secondSection}>
                  <h3>What treatment was given?</h3>
                  <div className={checkBoxWrap}>
                    <CheckboxInput
                      label="No medical treatment given"
                      name="noMedicalTreatment"
                      checked={values.noMedicalTreatment}
                      value="noMedicalTreatment"
                      onChange={touchedEvent}
                    />

                    <CheckboxInput
                      label="First aid only (onsite)"
                      name="firstAid"
                      checked={values.firstAid}
                      value="firstAid"
                      disabled={values.noMedicalTreatment}
                      onChange={touchedEvent}
                    />

                    <CheckboxInput
                      label="Medical Clinic / ER"
                      name="medicalClinic"
                      checked={values.medicalClinic}
                      value="medicalClinic"
                      disabled={values.noMedicalTreatment}
                      onChange={touchedEvent}
                    />

                    <CheckboxInput
                      label="EMS Transport"
                      name="EMSTransport"
                      checked={values.EMSTransport}
                      value="EMSTransport"
                      disabled={values.noMedicalTreatment}
                      onChange={touchedEvent}
                    />

                    <CheckboxInput
                      label="Overnight hospitalization"
                      name="overnightHospitalization"
                      checked={values.overnightHospitalization}
                      value="overnightHospitalization"
                      disabled={values.noMedicalTreatment}
                      onChange={touchedEvent}
                    />

                    <CheckboxInput
                      label="Other"
                      name="other"
                      checked={values.other}
                      value="other"
                      disabled={values.noMedicalTreatment}
                      onChange={touchedEvent}
                    />
                  </div>

                  {errors.checkBoxTreatment && (
                    <div className={errorCheckBox}>
                      <ErrorBlockMessage errorText={errors.checkBoxTreatment} />
                    </div>
                  )}

                  <div className={treatmentDetailLabel}>
                    <FormikFormField
                      label="Name of service, clinic, or physician providing medical
                  treatment"
                      name="treatmentDetails"
                    >
                      <TextInput
                        name="treatmentDetails"
                        disabled={values.noMedicalTreatment}
                        value={values.treatmentDetails}
                      />
                    </FormikFormField>
                  </div>
                </div>

                <div className={thirdSection}>
                  <div className={titleThirdSection}>
                    <h3>
                      Address of service, clinic, or physician
                      <small className={optional}>Optional</small>
                    </h3>
                  </div>

                  <div className={addressWrap}>
                    <FormikFormField
                      label="Address Line 1"
                      name="address.street1"
                      required={addressFieldNecessary}
                    >
                      <TextInput
                        name="address.street1"
                        disabled={values.noMedicalTreatment}
                        required={addressFieldNecessary}
                      />
                    </FormikFormField>

                    <FormikFormField
                      label="Address Line 2"
                      name="address.street2"
                    >
                      <TextInput
                        name="address.street2"
                        disabled={values.noMedicalTreatment}
                      />
                    </FormikFormField>
                  </div>

                  <div className={cityWrap}>
                    <FormikFormField
                      label="City"
                      name="address.city"
                      required={addressFieldNecessary}
                    >
                      <TextInput
                        name="address.city"
                        disabled={values.noMedicalTreatment}
                        required={addressFieldNecessary}
                      />
                    </FormikFormField>

                    <FormikFormField
                      label="State"
                      name="address.state"
                      required={addressFieldNecessary}
                    >
                      <div
                        className={`${
                          values.noMedicalTreatment
                            ? disabledSelectedState
                            : addressFieldNecessary && !values.address.state
                            ? stateRequired
                            : ""
                        }`}
                      >
                        <StateSelect
                          selectedState={values?.address?.state || ""}
                          handleEventChange={(e) =>
                            setFieldValue("address.state", e.target.value)
                          }
                          stateList={ALL_STATES}
                          disabled={values.noMedicalTreatment}
                        />
                      </div>
                    </FormikFormField>

                    <FormikFormField
                      label="Zip"
                      name="address.postalCode"
                      required={addressFieldNecessary}
                    >
                      <TextInput
                        name="address.postalCode"
                        disabled={values.noMedicalTreatment}
                        required={addressFieldNecessary}
                      />
                    </FormikFormField>

                    <FormikFormField label="Phone Number" name="phoneNumber">
                      <TextInput
                        name="phoneNumber"
                        disabled={values.noMedicalTreatment}
                      />
                    </FormikFormField>
                  </div>
                </div>

                <div className={btnContinue}>
                  <FormContinue
                    continueKey="continue-response-to-the-injury"
                    backKey="back-response-to-the-injury"
                    backFn={() => {
                      setPageData(values);
                      goBack();
                    }}
                    alignment="space-between"
                  />
                </div>
              </div>
            </Form>
          </SharedForm>
        );
      }}
    </Formik>
  );
}
