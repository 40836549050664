import { useEffect } from "react";
import { useCreateClaimReportMutation } from "types/generated/operations";

export const useCreateClaimReport = ({
  onSuccess,
  onError,
}: {
  onSuccess: (id: string | null) => void;
  onError: (error: string) => void;
}) => {
  const [
    createClaimReport,
    { data: createClaimReportResponse, loading: createClaimReportLoading },
  ] = useCreateClaimReportMutation();

  useEffect(() => {
    if (
      !createClaimReportResponse ||
      !createClaimReportResponse.createWcClaimReport
    )
      return;

    const {
      createWcClaimReport: { success, error, draftId },
    } = createClaimReportResponse;

    if (!createClaimReportLoading) {
      if (success) {
        onSuccess(draftId || null);
      } else if (error && error.length > 0) {
        onError(error);
      }
    }
  }, [createClaimReportResponse, createClaimReportLoading, onSuccess, onError]);

  return {
    createClaimReport,
    createClaimReportResponse,
    createClaimReportLoading,
  };
};
