import { Button } from "@justworkshr/milo-core";
import { DisplayIcon } from "@justworkshr/milo-icons";

import styles from "./shared-form-outline.module.css";

interface IGridColumnValues {
  [key: number]: {
    blue: { gridColumn: string } | { display: string };
    grey: { gridColumn: string } | { display: string };
  };
}

const PROGRESS_BAR_VALUES: IGridColumnValues = {
  0: {
    blue: {
      display: "none",
    },
    grey: {
      gridColumn: "1/13",
    },
  },
  1: {
    blue: {
      gridColumn: "1/2",
    },
    grey: {
      gridColumn: "2/13",
    },
  },
  2: {
    blue: {
      gridColumn: "1/4",
    },
    grey: {
      gridColumn: "4/13",
    },
  },
  3: {
    blue: {
      gridColumn: "1/8",
    },
    grey: {
      gridColumn: "8/13",
    },
  },
  4: {
    blue: {
      gridColumn: "1/11",
    },
    grey: {
      gridColumn: "11/13",
    },
  },
  5: {
    blue: {
      gridColumn: "1/12",
    },
    grey: {
      gridColumn: "12/13",
    },
  },
  6: {
    blue: {
      gridColumn: "1/13",
    },
    grey: {
      display: "none",
    },
  },
};

const {
  formWrapper,
  header,
  headerMargin,
  tableOfContentsSection,
  progressBar,
  exitButton,
  nameSection,
  titleSection,
  headerTitles,
} = styles;

const TableOfContents = ({
  currentPageNumber,
}: {
  currentPageNumber: number;
}): React.ReactElement => {
  const titles = [
    "Getting Started",
    "Contact information",
    "About the incident",
    "Response to the injury",
    "Witnesses",
    "Work status",
    "Review",
  ];

  return (
    <section className={tableOfContentsSection}>
      <div>
        <ul style={{ listStyleType: "none" }}>
          {titles.map((item, index) => {
            return (
              <li
                key={index}
                style={{
                  fontWeight: currentPageNumber === index ? "bold" : "normal",
                  marginBottom: "20px",
                }}
              >
                <span style={{ fontWeight: "bold", paddingRight: "10px" }}>
                  {index + 1}
                </span>
                <span
                  style={{
                    borderBottom:
                      currentPageNumber === index ? "2px solid #0092F6" : "",
                  }}
                >
                  {" "}
                  {item}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

const NamelessContainer = ({
  exitFn,
}: {
  exitFn: () => void;
}): React.ReactElement => {
  return (
    <div className={headerTitles}>
      <Button
        className={exitButton}
        variant="ghost"
        leftIcon={"arrow-left"}
        size="sm"
        onClick={exitFn}
      >
        Exit
      </Button>
      <div className={titleSection}>
        <DisplayIcon iconName="crutches" color="blue" size="small" />
        <h2>New Workers{"\u2019"} Compensation Claims</h2>
      </div>
    </div>
  );
};

const NameContainer = ({
  name,
  saveExitFn,
}: {
  name: string;
  saveExitFn: () => void;
}): React.ReactElement => {
  return (
    <div className={`${headerTitles} ${headerMargin}`}>
      <Button
        className={exitButton}
        variant="ghost"
        leftIcon={"arrow-left"}
        size="sm"
        onClick={saveExitFn}
        data-heap-redact-text={true}
      >
        Save & exit
      </Button>
      <div className={nameSection}>
        <span>Workers{"\u2019"} Compensation Claims</span>
        <span style={{ fontWeight: "bold", marginLeft: "10px" }}>{name}</span>
      </div>
    </div>
  );
};

const SharedForm = ({
  currentPageNumber,
  handleErrorMessage,
  name,
  children,
  exitFn,
}: {
  currentPageNumber: number;
  handleErrorMessage?: () => JSX.Element;
  name?: string;
  children: React.ReactElement;
  exitFn: () => void;
}) => {
  const gridColumns = PROGRESS_BAR_VALUES[currentPageNumber];
  const blueStyle = {
    ...gridColumns.blue,
    background: "#0092F6",
    height: "2px",
  };
  const greyStyle = {
    ...gridColumns.grey,
    background: "#D9DCE1",
    height: "2px",
  };

  return (
    <div className="milo--grid">
      <section className={header} data-testid="shared-form-outline">
        {name ? (
          <NameContainer
            name={name}
            saveExitFn={exitFn}
            data-testid="name-container"
          />
        ) : (
          <NamelessContainer
            exitFn={exitFn}
            data-testid="name-less-container"
          />
        )}
        {handleErrorMessage && handleErrorMessage()}
        <div className={progressBar}>
          <div style={blueStyle}></div>
          <div style={greyStyle}></div>
        </div>
      </section>
      {
        <TableOfContents
          currentPageNumber={currentPageNumber}
          data-testid="table-of-contents"
        />
      }
      <div className={formWrapper}>{children}</div>
    </div>
  );
};

export default SharedForm;
