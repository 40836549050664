import AutoFormField from "./AutoFormField";
import { FieldDataType } from "./types";

type Props = {
  orderedFields: FieldDataType[];
  name?: string;
};

const AutoForm = ({ orderedFields, name }: Props) => {
  return (
    <>
      {orderedFields &&
        orderedFields.map((fieldData) => (
          <AutoFormField
            {...fieldData}
            name={name ? `${name}.${fieldData.name}` : fieldData.name}
            key={fieldData.name}
          />
        ))}
    </>
  );
};

export default AutoForm;
