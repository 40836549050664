import * as Yup from "yup";
import INFragment from "./INFragment";

export type ConfigType = {
  component: JSX.Element;
  schema: typeof schema;
  initialValues: SchemaType;
  keysToExclude?: Partial<keyof SchemaType>[];
};

export type SchemaType = Yup.InferType<typeof schema>;

const schema = Yup.object({
  workCountry: Yup.string().oneOf(["IN"]).required("Required"),
  inIsRemoteWork: Yup.boolean().required("Required"),
});

const CONFIG: ConfigType = {
  component: <INFragment />,
  schema: schema,
  initialValues: {
    workCountry: "IN",
    inIsRemoteWork: false,
  },
  keysToExclude: [],
};

export default CONFIG;
