import { useNavigate } from "react-router-dom";
import { Button } from "@justworkshr/milo-core";
import {
  ExpandableList,
  ExpandableText,
} from "pages/expenses/components/Expandable";
import {
  documentIcon,
  ON_THE_GO_COPY,
  EXPENSES_LEARN_MORE,
  MINIMIZE_ERROR_COPY,
  ADMIN_FIRST_LOOK_COPY,
  ADMIN_FIRST_LOOK_LABEL,
  STREAMLINE_WORKFLOWS_COPY,
  REQUIRED_PERMISSIONS_COPY,
  REQUIRED_PERMISSIONS_LABEL,
  LEARN_ABOUT_EXPENSES_LABEL,
  renderLearnAboutExpensesCopy,
  EXPENSES_VS_PAYMENTS_CENTER_LABEL,
  EXPENSES_VS_PAYMENTS_CENTER_REIMBURSEMENT_COPY,
} from "./ProductDetailsPage.constants";
import {
  useActivateExpenses,
  useExpensesResources,
  useGetExpensesActivation,
} from "pages/expenses/hooks";
import {
  prependBasePath,
  DEFAULT_DOWNLOAD_PROPS,
} from "pages/expenses/constants";
import styles from "./ProductDetailsPage.module.css";
import { Loading } from "pages/expenses/components";

const {
  FAQSection,
  PDPHeadline,
  ExpensesCard,
  VideoWrapper,
  ResourcesCard,
  FeatureOverviewList,
  ExpensesInfoWrapper,
  ExpensesCardCTAButton,
  FeatureOverviewSection,
  ProductDetailsPageWrapper,
  ProductDetailsPageContent,
} = styles;

export const ProductDetailsPage = () => {
  const navigate = useNavigate();
  const { loading: isGetExpensesActivationLoading, hasJoinedExpenses } =
    useGetExpensesActivation();
  const { activateExpenses, isActivateExpensesLoading } = useActivateExpenses();
  const {
    loading: isExpensesResourcesLoading,
    data: { adminOnePagerUrl, employeeOnePagerUrl },
  } = useExpensesResources();

  if (isGetExpensesActivationLoading || isExpensesResourcesLoading) {
    return <Loading />;
  }

  return (
    <div className={ProductDetailsPageWrapper}>
      <h1>Expenses</h1>
      <div className={ProductDetailsPageContent}>
        <div className={ExpensesInfoWrapper}>
          <div className={VideoWrapper}>
            <iframe
              allowFullScreen
              src="https://www.youtube.com/embed/zPWvtknky94"
              height="349px"
              width="100%"
            />
          </div>
          <h2 className={PDPHeadline}>
            Consolidate tools for your team & capture everything in Expenses
          </h2>
          <section className={FeatureOverviewSection}>
            <h3>Feature overview</h3>
            <ul className={FeatureOverviewList}>
              <li>Automated approval workflow</li>
              <li>Custom expense categories and rules</li>
              <li>Assign project codes to expenses</li>
              <li>Record expenses as non-reimbursable or billable</li>
              <li>
                Map to specific accounts with QuickBooks Online, Xero, and
                NetSuite
              </li>
            </ul>
            <h4>Streamline workflows</h4>
            <ul>
              <li>{STREAMLINE_WORKFLOWS_COPY}</li>
            </ul>
            <h4>Minimize risk of error</h4>
            <ul>
              <li>{MINIMIZE_ERROR_COPY}</li>
            </ul>
            <h4>Create expenses on the go</h4>
            <ul>
              <li>{ON_THE_GO_COPY}</li>
            </ul>
          </section>
          <section className={FAQSection}>
            <h3>FAQs</h3>
            <ExpandableList>
              <ExpandableText buttonLabel={REQUIRED_PERMISSIONS_LABEL}>
                {REQUIRED_PERMISSIONS_COPY}
              </ExpandableText>
              <ExpandableText buttonLabel={ADMIN_FIRST_LOOK_LABEL}>
                {ADMIN_FIRST_LOOK_COPY}
              </ExpandableText>
              <ExpandableText buttonLabel={LEARN_ABOUT_EXPENSES_LABEL}>
                {renderLearnAboutExpensesCopy(employeeOnePagerUrl)}
              </ExpandableText>
              <ExpandableText buttonLabel={EXPENSES_VS_PAYMENTS_CENTER_LABEL}>
                {EXPENSES_VS_PAYMENTS_CENTER_REIMBURSEMENT_COPY}
              </ExpandableText>
            </ExpandableList>
          </section>
        </div>
        <div>
          <div className={ExpensesCard}>
            <h2>Expenses</h2>
            <dl>
              <dt>Cost</dt>
              <dd>Free</dd>
              <dt>Available on</dt>
              <dd>Desktop & mobile</dd>
              <dt>Permissions required</dt>
              <dd>
                To get started, Admins must have{" "}
                <strong>Edit employee permissions</strong>.
              </dd>
            </dl>
            {hasJoinedExpenses ? (
              <Button
                className={ExpensesCardCTAButton}
                onClick={() => navigate(prependBasePath("SETTINGS"))}
                data-testid="get-started-button"
              >
                Continue set up
              </Button>
            ) : (
              <Button
                loading={isActivateExpensesLoading}
                onClick={() => activateExpenses()}
                data-testid="get-started-button"
              >
                Get started
              </Button>
            )}
          </div>
          <div className={ResourcesCard}>
            <h2>Resources</h2>
            <a {...DEFAULT_DOWNLOAD_PROPS} href={adminOnePagerUrl}>
              {documentIcon}
              <span>Expenses Product Overview</span>
            </a>
            <a {...DEFAULT_DOWNLOAD_PROPS} href={employeeOnePagerUrl}>
              {documentIcon}
              <span>Employee Guide to Expenses</span>
            </a>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={EXPENSES_LEARN_MORE}
              >
                Learn more about Expenses
              </a>{" "}
              in the Help Center.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsPage;
