import styles from "./Form.module.css";
import React from "react";

const { form } = styles;

type MethodTypes = "get" | "post" | "patch" | "put" | "delete";
type LayoutTypes = "vertical" | "horizontal";
type SpacingTypes = "medium" | "large";

export interface FormProps extends React.HTMLAttributes<HTMLFormElement> {
  children: React.ReactNode;
  className?: string;
  method?: MethodTypes;
  layout?: LayoutTypes;
  spacing?: SpacingTypes;
  dynamicLayout?: boolean;
  authenticityToken?: string;
}

function Form({
  children,
  method = "get",
  className,
  layout = "horizontal",
  spacing = "medium",
  dynamicLayout,
  authenticityToken,
  ...otherProps
}: FormProps) {
  const classes = [
    form,
    className,
    "Form",
    className,
    `layout-${layout}`,
    `spacing-${spacing}`,
    { "dynamic-layout": dynamicLayout },
  ];

  const needsMethodAttribute = ["patch", "put", "delete"].includes(method);
  const actualMethod = needsMethodAttribute ? "post" : method;

  return (
    <form method={actualMethod} className={classes.join(" ")} {...otherProps}>
      <input
        type="hidden"
        name="authenticity_token"
        value={authenticityToken || ""}
      />
      {needsMethodAttribute && (
        <input type="hidden" name="_method" value={method} />
      )}
      <section>{children}</section>
    </form>
  );
}

export default Form;
