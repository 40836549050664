import React from "react";
import styles from "./partner-description-page.module.css";
import { useState, useEffect } from "react";
import { PageHeader, TabList, Tab, Alert } from "@justworkshr/milo-core";
import { useLocation } from "react-router-dom";
import MetlifeHeroLogo from "../images/metlife-hero-image.jpg";
import { MetlifeInformationPanel } from "../components/MetlifeInformationPanel/MetlifeInformationPanel";
import { ResourceCard } from "../components/ResourceCard/ResourceCard";
import {
  OverviewTab as LtdOverviewTab,
  OptionsAndCostsTab as LtdOptionsAndCostsTab,
  FaqTab as LtdFaqTab,
} from "./ltdTabsContent";
import {
  OverviewTab as StdOverviewTab,
  OptionsAndCostsTab as StdOptionsAndCostsTab,
  FaqTab as StdFaqTab,
} from "./stdTabsContent";
import {
  OverviewTab as basicLifeandADDOverviewTab,
  OptionsAndCostsTab as basicLifeandADDOptionsAndCostsTab,
  FaqTab as basicLifeandADDFaqTab,
} from "./basicLifeAndAddTabsContent";
import {
  HEADER_INFO,
  ROUTES,
  ENROLLMENT_ALERT_CONTENT,
} from "../constants/pdpGeneral";
import { buildWebPath } from "lib/resource-finder";

const {
  headerBanner,
  leftColumn,
  rightColumn,
  heroImage,
  backtoAddBenefits,
  footer,
} = styles;

export type tabsData = {
  [key: string]: React.FC;
};

export type headerContentData = {
  [key: string]: string;
};

export type pdpProps = {
  planType: "ltd" | "std" | "basicLifeAndADD";
};

export const ProductDescriptionPage: React.FC<pdpProps> = ({ planType }) => {
  const location = useLocation();
  const isAlertVisible =
    location?.state !== null && location?.state !== undefined;
  const planCreated = location?.state?.success === true;
  const [activeTab, setActiveTab] = useState<number>(0);
  const [tabs, setTabs] = useState<tabsData>({
    overview: LtdOverviewTab,
    optionsAndCosts: LtdOptionsAndCostsTab,
    faq: LtdFaqTab,
  });
  const [setupPageRoute, setSetupPageRoute] = useState<string>(
    ROUTES.ltdSetupPage
  );
  const [alertSuccessMessage, setAlertSuccessMessage] = useState<string>(
    ENROLLMENT_ALERT_CONTENT.ltdSuccessMessage
  );
  const [headerContent, setHeaderContent] = useState<headerContentData>({
    title: HEADER_INFO.ltdTitle,
    description: HEADER_INFO.ltdDescription,
  });

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const setPageContent = (planType: string): void => {
    try {
      switch (planType) {
        case "ltd":
          setSetupPageRoute(ROUTES.ltdSetupPage);
          setAlertSuccessMessage(ENROLLMENT_ALERT_CONTENT.ltdSuccessMessage);
          setHeaderContent({
            title: HEADER_INFO.ltdTitle,
            description: HEADER_INFO.ltdDescription,
          });
          setTabs({
            overview: LtdOverviewTab,
            optionsAndCosts: LtdOptionsAndCostsTab,
            faq: LtdFaqTab,
          });
          break;
        case "std":
          setSetupPageRoute(ROUTES.stdSetupPage);
          setAlertSuccessMessage(ENROLLMENT_ALERT_CONTENT.stdSuccessMessage);
          setHeaderContent({
            title: HEADER_INFO.stdTitle,
            description: HEADER_INFO.stdDescription,
          });
          setTabs({
            overview: StdOverviewTab,
            optionsAndCosts: StdOptionsAndCostsTab,
            faq: StdFaqTab,
          });
          break;
        case "basicLifeAndADD":
          setSetupPageRoute(ROUTES.basicAndADDSetupPage);
          setAlertSuccessMessage(ENROLLMENT_ALERT_CONTENT.basicSuccessMessage);
          setHeaderContent({
            title: HEADER_INFO.basicLifeAndADDTitle,
            description: HEADER_INFO.basicLifeAndADDDescription,
          });
          setTabs({
            overview: basicLifeandADDOverviewTab,
            optionsAndCosts: basicLifeandADDOptionsAndCostsTab,
            faq: basicLifeandADDFaqTab,
          });
          break;
        default:
          throw new Error(`Unhandled case for plan type: ${planType}`);
      }
    } catch {
      window.location.assign(buildWebPath("/page404"));
    }
  };

  useEffect(() => {
    setPageContent(planType);
  }, [planType]);

  return (
    <>
      <div>
        <Alert
          color={planCreated ? "additive" : "destructive"}
          visible={isAlertVisible}
          dismissible={true}
        >
          {planCreated
            ? alertSuccessMessage
            : ENROLLMENT_ALERT_CONTENT.failureMessage}
        </Alert>
      </div>
      <div className="milo--grid">
        <div className={headerBanner}>
          <PageHeader
            title={headerContent.title}
            linkPrevious={
              <a
                href={buildWebPath("/benefits/offer")}
                className={backtoAddBenefits}
              >
                Back to Add Benefits
              </a>
            }
          >
            {headerContent.description}
          </PageHeader>
        </div>
        <div className={leftColumn}>
          <img
            height={736}
            width={204}
            className={heroImage}
            src={MetlifeHeroLogo}
            alt="Metlife hero image"
          />
          <TabList onChange={handleTabClick}>
            <Tab key="overview">Overview</Tab>
            <Tab key="options-and-costs">Options & Costs</Tab>
            <Tab key="faq">FAQ</Tab>
          </TabList>
          {activeTab === 0 && <tabs.overview />}
          {activeTab === 1 && <tabs.optionsAndCosts />}
          {activeTab === 2 && <tabs.faq />}
        </div>
        <div className={rightColumn}>
          <MetlifeInformationPanel
            route={setupPageRoute}
            planExists={planCreated}
          />
          <ResourceCard selectedBenefit={planType} />
        </div>
      </div>
      <div className={footer}></div>
    </>
  );
};
