import { Outlet } from "react-router-dom";
import { SettingsProvider } from "pages/expenses/store";
import { SettingsHeader } from "./components";

export const SettingsOutlet = () => {
  return (
    <SettingsProvider>
      <SettingsHeader />
      <Outlet />
    </SettingsProvider>
  );
};

export default SettingsOutlet;
