import { ValidationError } from "stdnum/src/exceptions";
import { ValidateReturn } from "stdnum/lib/cjs/types";

const sgTaxIdValidator = {
  name: "Singapore National Registration Identity Card",
  localName: "National Registration Identity Card",
  abbreviation: "NRIC",
  compact(input: string): string {
    return input;
  },

  format(input: string): string {
    return input;
  },

  validate(input: string): ValidateReturn {
    const weights = [2, 7, 6, 5, 4, 3, 2];
    const STCheckTable = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "Z",
      "J",
    ];
    const GFCheckTable = [
      "K",
      "L",
      "M",
      "N",
      "P",
      "S",
      "R",
      "T",
      "U",
      "W",
      "X",
    ];
    const MCheckTable = ["K", "L", "J", "N", "P", "Q", "R", "T", "U", "W", "X"];

    const checkTableMap: Record<string, string[]> = {
      S: STCheckTable,
      T: STCheckTable,
      G: GFCheckTable,
      F: GFCheckTable,
      M: MCheckTable,
    };

    if (input.length !== 9) {
      return {
        isValid: false,
        error: new ValidationError("NRIC must be 9 digits"),
      };
    }

    const firstChar = input[0];

    if (!["S", "T", "F", "G", "M"].includes(firstChar)) {
      return {
        isValid: false,
        error: new ValidationError("NRIC is invalid"),
      };
    }

    let checksum = input
      .split("")
      .slice(1, 8)
      .map((d, index) => parseInt(d) * weights[index])
      .reduce((acc, v) => acc + v, 0);

    if (["G", "T"].includes(firstChar)) {
      checksum += 4;
    } else if (["M"].includes(firstChar)) {
      checksum += 3;
    }

    const remainder = checksum % 11;
    const checkDigit = 11 - (remainder + 1);

    if (checkTableMap[firstChar][checkDigit] !== input[input.length - 1]) {
      return {
        isValid: false,
        error: new ValidationError("NRIC is invalid"),
      };
    }

    return {
      isValid: true,
      compact: input,
      isIndividual: true,
      isCompany: false,
    };
  },
};

export { sgTaxIdValidator };
