import { ReactElement } from "react";
import { Link, useParams } from "react-router-dom";
import styles from "../../SeperationReviewPage.module.css";
import { DETAILS_SECTION_COPY } from "./DetailsSection.constants";
import { MemberSeparationAnswer } from "pages/team-management/types";
import { getAnswer } from "../../SeparationReview.util";
import {
  INVOLUNTARY_TERMINATION_REASON_OPTIONS,
  VOLUNTARY_TERMINATION_REASON_OPTIONS,
} from "../../../SeparationReason/InitialIntakePage/components/InitialIntakeForm/InitialIntakeForm.constants";
import {
  InvoluntaryTerminationReasonTypes,
  TerminationTypeTypes,
  VoluntaryTerminationReasonTypes,
} from "../../../SeparationReason/InitialIntakePage/components/InitialIntakeForm/InitialIntakeForm.types";

const { section, extraSpacing, row, column, splitRow, heading, subHeading } =
  styles;

interface DetailsSectionProps {
  answers: MemberSeparationAnswer[];
}

const getFullTerminationReason = (
  reason: string | undefined | null,
  type: string | undefined | null
): string => {
  if (!reason || !type) return "";

  return type === TerminationTypeTypes.VOLUNTARY
    ? VOLUNTARY_TERMINATION_REASON_OPTIONS[
        reason as VoluntaryTerminationReasonTypes
      ]
    : INVOLUNTARY_TERMINATION_REASON_OPTIONS[
        reason as InvoluntaryTerminationReasonTypes
      ];
};

export const DetailsSection = ({
  answers,
}: DetailsSectionProps): ReactElement => {
  const { memberUuid } = useParams();

  return (
    <div className={`${section} ${extraSpacing}`}>
      <div className={splitRow}>
        <div className={heading}>{DETAILS_SECTION_COPY.title}</div>
        <div>
          <Link
            to={`/team-management/separation-flow/${memberUuid}/initial-intake`}
          >
            {DETAILS_SECTION_COPY.action}
          </Link>
        </div>
      </div>
      <div className={row}>
        <div data-testid="termination-date" className={column}>
          <div className={subHeading}>
            {DETAILS_SECTION_COPY.termination_date}
          </div>
          {getAnswer(answers, "termination_date")?.valueDate}
        </div>
        <div data-testid="final-work-date" className={column}>
          <div className={subHeading}>
            {DETAILS_SECTION_COPY.final_work_date}
          </div>
          {getAnswer(answers, "final_work_date")?.valueDate}
        </div>
        <div data-testid="termination-type" className={column}>
          <div className={subHeading}>
            {DETAILS_SECTION_COPY.termination_type}
          </div>
          {getAnswer(answers, "termination_type")?.valueTextSmall}
        </div>
      </div>
      <div className={row}>
        <div data-testid="termination-reason" className={column}>
          <div className={subHeading}>
            {DETAILS_SECTION_COPY.termination_reason}
          </div>
          {getFullTerminationReason(
            getAnswer(answers, "termination_reason")?.valueTextSmall,
            getAnswer(answers, "termination_type")?.valueTextSmall
          )}
        </div>
      </div>
    </div>
  );
};
