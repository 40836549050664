import { useEffect, useState, useCallback } from "react";
import {
  useGetActiveRequestsQuery,
  GetActiveRequestsQueryVariables,
} from "types/generated/operations";
import { ActiveRequests } from "types/Expenses";

export const useActiveRequests = (
  variables: GetActiveRequestsQueryVariables
) => {
  const [activeRequests, setActiveRequests] = useState<ActiveRequests>([]);
  const { data, loading } = useGetActiveRequestsQuery({ variables });

  useEffect(() => {
    if (!loading && data?.expenseManagement?.requests) {
      setActiveRequests(data?.expenseManagement?.requests?.requests);
    }
  }, [data, loading]);

  const resetActiveRequests = useCallback(
    () => setActiveRequests(data?.expenseManagement?.requests?.requests || []),
    [data]
  );

  return {
    loading,
    activeRequests,
    activeRequestsResponse: data?.expenseManagement?.requests,
    resetActiveRequests,
    earliestPayDate: data?.expenseManagement?.earliestPayDate,
  };
};
