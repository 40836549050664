import { ReactNode, FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, IconButton } from "@justworkshr/milo-core";
import { buildWebPath } from "lib/resource-finder";
import LogoIcon from "app-frame/common/LogoIcon";
import styles from "./fullscreen-wrapper.module.css";
import { FULL_PATHS } from "../../constants";
import { usePeopleAnalyticsCapabilities } from "../../hooks";

interface FullscreenWrapperProps {
  showDashboardNavigation?: boolean;
  backCopy: string;
  backPath: string;
  drawerContent: {
    title: string;
    description: string;
    learnMoreHref: string;
    imageSrc: string | null;
  }[];
  children: ReactNode;
}

export const FullscreenWrapper: FC<FullscreenWrapperProps> = ({
  showDashboardNavigation = false,
  backCopy,
  backPath,
  drawerContent,
  children,
}) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const { memberCompanyHasBenefits, memberCompanyHasPtoPolicies } =
    usePeopleAnalyticsCapabilities();

  useEffect(() => {
    document.body.classList.add(styles.noOverflow);

    return () => {
      document.body.classList.remove(styles.noOverflow);
    };
  }, []);

  return (
    <div className={styles.fullscreenWrapper} data-testid="fullscreen-wrapper">
      <div className={styles.header}>
        <div className={styles.logo}>
          <a href={buildWebPath("/dashboard")}>
            <LogoIcon color="white" />
          </a>
          <span>| People Analytics</span>
        </div>
        {showDashboardNavigation && (
          <div className={styles.navButtons}>
            <Button
              variant="ghost"
              size="md"
              color="inverse"
              as={Link}
              to={FULL_PATHS.WORKFORCE_DASHBOARD}
            >
              Workforce
            </Button>
            <Button
              variant="ghost"
              size="md"
              color="inverse"
              as={Link}
              to={FULL_PATHS.FINANCE_DASHBOARD}
            >
              Compensation
            </Button>
            {memberCompanyHasPtoPolicies && (
              <Button
                variant="ghost"
                size="md"
                color="inverse"
                as={Link}
                to={FULL_PATHS.PTO_DASHBOARD}
              >
                Time off
              </Button>
            )}
            {memberCompanyHasBenefits && (
              <Button
                variant="ghost"
                size="md"
                color="inverse"
                as={Link}
                to={FULL_PATHS.BENEFITS_DASHBOARD}
              >
                Health benefits
              </Button>
            )}
          </div>
        )}
        <div>
          <Button
            className={styles.learnMoreButton}
            variant="ghost"
            leftIcon="question-circle"
            size="md"
            color="inverse"
            onClick={() => setShowDrawer(true)}
          >
            Learn more
          </Button>
          <Button
            className={styles.backButton}
            variant="ghost"
            leftIcon="close"
            size="md"
            color="inverse"
            as={Link}
            to={backPath}
          >
            {backCopy}
          </Button>
        </div>
      </div>
      {children}
      <div
        className={[styles.drawer]
          .concat(showDrawer ? [styles.show] : [])
          .join(" ")}
      >
        <div className={styles.drawerHeader}>
          <h2>People Analytics Guide</h2>
          <IconButton
            variant="ghost"
            iconName="close"
            color="neutral"
            title="Close drawer"
            onClick={() => setShowDrawer(false)}
          />
        </div>
        <div className={styles.drawerContent}>
          {drawerContent.map(
            ({ title, description, learnMoreHref, imageSrc }) => {
              return (
                <section className={styles.drawerItem} key={title}>
                  {imageSrc && (
                    <a href={learnMoreHref} target="_blank" rel="noreferrer">
                      <img src={imageSrc} alt={title} />{" "}
                    </a>
                  )}
                  <h3>{title}</h3>
                  <p>{description}</p>
                  {learnMoreHref && (
                    <a href={learnMoreHref} target="_blank" rel="noreferrer">
                      Learn more
                    </a>
                  )}
                </section>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default FullscreenWrapper;
