import { ReactElement } from "react";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import Row from "../../../Row/Row";
import RequestChangesAction from "../../../RequestChangesAction/RequestChangesAction";
import { Card } from "@justworkshr/milo-core";
import { EorEmployeePersonalInfo } from "types/employer-of-record";
import BadgeTitle from "../BadgeTitle";

export default function CartaoDeCidadaoCard(): ReactElement {
  const { profileInfo } = useProfileContextData();

  const personalInfo: EorEmployeePersonalInfo =
    profileInfo?.eorEmployeeProfile.personalInfo;

  const ptCc = personalInfo?.ptCc;
  const ptCcIssDate = personalInfo?.ptCcIssDate;
  const ptCcExpDate = personalInfo?.ptCcExpDate;

  return (
    <>
      <Card
        title={
          (
            <BadgeTitle title="Cartão de Cidadão" badgeText="Portugal" />
          ) as unknown as string
        }
        actions={[<RequestChangesAction />]}
      >
        <Row label="Number" value={ptCc} required={false} />
        <Row label="Issue date" value={ptCcIssDate} required={false} />
        <Row label="Expiration date" value={ptCcExpDate} required={false} />
      </Card>
    </>
  );
}
