import { useGetLoggedInAsHeaderDataQuery } from "types/generated/operations";
import styles from "./logged-in-as-header.module.css";
import { buildClockworkWebPath } from "lib/resource-finder";

const { loggedInAsNotification, readOnly } = styles;

interface LoggedInAsHeaderDataProps {
  isReadOnly: boolean;
}

const getFullName = (
  firstName: string,
  middleName: string,
  lastName: string,
  preferredName: string
) => {
  let fullName = firstName;
  if (middleName !== "") fullName += ` ${middleName}`;
  fullName += ` ${lastName}`;
  if (preferredName !== "" && preferredName !== firstName)
    fullName += ` (${preferredName})`;
  return fullName;
};

function LoggedInAsHeader({ isReadOnly }: LoggedInAsHeaderDataProps) {
  const { data, loading } = useGetLoggedInAsHeaderDataQuery();

  if (loading || !data) return <></>;

  const {
    firstName,
    middleName,
    lastName,
    preferredName,
    userId,
    id,
    isLoggedInAs,
    company,
  } = data.authenticatedMember;

  if (!isLoggedInAs) return <></>;

  const fullName = getFullName(firstName, middleName, lastName, preferredName);
  const backToInternalPath = isReadOnly
    ? buildClockworkWebPath(
        `/back_to_internal_read_only?company_uuid=${company.uuid}`
      )
    : buildClockworkWebPath(`/back_to_internal?company_uuid=${company.uuid}`);

  return (
    <div
      className={loggedInAsNotification}
      data-testid="logged-in-as-notification"
    >
      <div>
        {isReadOnly && <span className={readOnly}>Read only</span>}
        <span data-testid="content">{` You are currently logged in as ${fullName} (M${id}, user${userId}) at ${company.name}. `}</span>
        <a role="link" href={backToInternalPath}>
          Back to internal controls
        </a>
      </div>
    </div>
  );
}

export default LoggedInAsHeader;
