import { MessageWithLinkType } from "./types";

const MessageWithLink = ({
  text,
  linkText,
  link,
}: MessageWithLinkType): JSX.Element => {
  if (link && linkText) {
    const index = text.indexOf(linkText);
    if (index !== -1) {
      const beforeLink = escapeHTML(text.substring(0, index));
      const afterLink = escapeHTML(text.substring(index + linkText.length));
      return (
        <>
          <span dangerouslySetInnerHTML={{ __html: beforeLink }} />
          <a
            href={link}
            className="milo--link-medium"
            rel="noopener noreferrer"
            target="_blank"
          >
            {linkText}
          </a>
          <span dangerouslySetInnerHTML={{ __html: afterLink }} />
        </>
      );
    }
  }

  return <span dangerouslySetInnerHTML={{ __html: text }} />;
};

export default MessageWithLink;

const escapeHTML = (input: string): string => {
  return input.replace(/[&<>"']/g, (char) => {
    switch (char) {
      case "&":
        return "&amp;";
      case "<":
        return "&lt;";
      case ">":
        return "&gt;";
      case '"':
        return "&quot;";
      case "'":
        return "&#x27;";
      default:
        return char;
    }
  });
};
