import { DeepPartial } from "pages/expenses/types";
import {
  RequestDetail,
  RequestDetailReview,
  RequestDetailExpense,
  RequestDetailReceipt,
  ExpenseLibraryExpense,
} from "types/Expenses";
import { ReimbursementRequestStatus } from "types/generated/operations";

export const INITIAL_REQUEST_DETAILS: RequestDetailsStoreType = {
  request: {
    expenses: [],
    managerApprover: null,
    member: {
      firstName: "",
      friendlyFullName: "",
    },
    myRequest: false,
    name: "",
    payment: null,
    reviews: [],
    status: null,
    submittedAt: "",
    totalAmount: 0,
    uuid: "",
  },
  reviews: {
    approved: [],
    reviewed: [],
    returned: [],
  },
  editMode: {
    editExpense: null,
    editExpenseIndex: null,
  },
  metadata: {
    customPayDate: "",
    earliestPayDate: "",
    detachedExpenses: [],
    isApproverView: false,
    isRequestEditView: false,
    isRequestUpdated: false,
    isCustomPayDateValid: true,
    receiptUuidsToRemove: null,
    isUnattachedExpenseView: true,
  },
};

export type RequestDetailsStoreType = {
  request: Omit<RequestDetail, "status"> & {
    status: ReimbursementRequestStatus | null;
  };
  reviews: {
    approved: RequestDetailReview[];
    reviewed: RequestDetailReview[];
    returned: RequestDetailReview[];
  };
  editMode: {
    editExpense: RequestDetailExpense | null;
    editExpenseIndex: number | null;
  };
  metadata: {
    customPayDate: string;
    earliestPayDate: string;
    isApproverView: boolean;
    isRequestEditView: boolean;
    isRequestUpdated: boolean;
    isCustomPayDateValid: boolean;
    detachedExpenses: RequestDetailExpense[];
    isUnattachedExpenseView: boolean;
    receiptUuidsToRemove: {
      [expenseUuid: string]: RequestDetailReceipt[];
    } | null;
  };
};

export type SetRequestDetailPayload = DeepPartial<RequestDetailsStoreType>;

export type SetRequestDetailExpensesPayload =
  | RequestDetailExpense[]
  | ExpenseLibraryExpense[];
