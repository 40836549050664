import { Button } from "@justworkshr/milo-core";
import { useDeleteReimbursementRequest } from "pages/expenses/hooks";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { DeleteRequestDialog } from "../DeleteRequestDialog";
import { useRequestDetail } from "pages/expenses/store";

export const DeleteRequestButton = () => {
  const { request_uuid } = useParams();

  const {
    requestDetail: {
      request: { uuid },
    },
  } = useRequestDetail();

  const requestUuid = uuid || request_uuid;

  const [showDeleteRequestDialog, setShowDeleteRequestDialog] = useState(false);
  const { deleteReimbursementRequest } = useDeleteReimbursementRequest();

  if (!requestUuid) {
    return null;
  }

  const handleDeleteRequestDialog = () =>
    setShowDeleteRequestDialog((prevState) => !prevState);

  const onDeleteRequest = () => {
    handleDeleteRequestDialog();

    deleteReimbursementRequest({
      variables: {
        uuids: [requestUuid],
      },
    });
  };

  return (
    <div>
      <Button
        type="button"
        leftIcon="trash"
        variant="outlined"
        color="destructive"
        onClick={handleDeleteRequestDialog}
        data-testid="delete-request-button"
      >
        Delete request
      </Button>
      {showDeleteRequestDialog && (
        <DeleteRequestDialog
          onDelete={onDeleteRequest}
          isOpen={showDeleteRequestDialog}
          onClose={handleDeleteRequestDialog}
        />
      )}
    </div>
  );
};
