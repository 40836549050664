import { FC } from "react";
import { useGetVisierFinanceMetricsQuery } from "types/generated/operations";
import { FULL_PATHS } from "../../constants";
import { usePeopleAnalyticsCapabilities } from "../../hooks";
import { formatDollars, formatMonth } from "../../utils";
import CompanyOverviewCard from "../company-overview-card";
import CompanyOverviewMetric from "../company-overview-metric";
import styles from "../company-overview-card/company-overview-card.module.css";

const FinanceMetricsCard: FC = () => {
  const { memberCompanyHasPlusAccess } = usePeopleAnalyticsCapabilities();

  const { data } = useGetVisierFinanceMetricsQuery();

  const { compensationPaidLastThreeMonths } = data?.visierFinanceMetrics || {
    compensationPaidLastThreeMonths: undefined,
  };

  const metrics = (
    <div className={styles.companyOverviewMetrics}>
      <h3 className={styles.companyOverviewMetricsHeader}>Compensation paid</h3>

      <div className={styles.metricContainer}>
        {compensationPaidLastThreeMonths && (
          <CompanyOverviewMetric
            description="Total compensation"
            metric={formatDollars(compensationPaidLastThreeMonths)}
            subtext={`${formatMonth(new Date(), -3)} - Yesterday`}
          />
        )}
      </div>
    </div>
  );

  return (
    <CompanyOverviewCard
      title="Compensation"
      insightsPath={
        memberCompanyHasPlusAccess ? FULL_PATHS.FINANCE_DASHBOARD : undefined
      }
    >
      {(() => {
        if (!data) return;

        return metrics;
      })()}
    </CompanyOverviewCard>
  );
};

export default FinanceMetricsCard;
