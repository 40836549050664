import {
  compact as originalCompact,
  format as originalFormat,
  name as originalName,
  localName as originalLocalName,
  abbreviation as originalAbbreviation,
} from "stdnum/src/gb/nino";
import { ValidateReturn } from "stdnum/lib/cjs/types";
import * as exceptions from "stdnum/src/exceptions";
import { strings } from "stdnum/src/util";

function clean(input: string): ReturnType<typeof strings.cleanUnicode> {
  return strings.cleanUnicode(input, " -.");
}

function validLength(value: string): boolean {
  // The suffix letter may be omitted, so we permit a length of 8.
  return [8, 9].includes(value.length);
}

const VALID_FORMAT_REGEX = /^([A-Z]{2})\d{6}[A-D]?$/;

// https://www.gov.uk/hmrc-internal-manuals/national-insurance-manual/nim39110
function isValidPrefix(prefix: string): boolean {
  const invalidChars = ["D", "F", "I", "Q", "U", "V"];
  const invalidSecondChar = "O";
  const invalidPrefixes = ["BG", "GB", "KN", "NK", "NT", "TN", "ZZ"];

  if (invalidChars.includes(prefix[0]) || invalidChars.includes(prefix[1])) {
    return false;
  }

  if (prefix[1] === invalidSecondChar) {
    return false;
  }

  return !invalidPrefixes.includes(prefix);
}

function validFormat(value: string): boolean {
  const matchData = value.toUpperCase().match(VALID_FORMAT_REGEX);
  const prefix = value.slice(0, 2).toUpperCase();
  return !!matchData && isValidPrefix(prefix);
}

const gbTaxIdValidator = {
  name: originalName,
  localName: originalLocalName,
  abbreviation: originalAbbreviation,

  compact(input: string): string {
    return originalCompact(input);
  },

  format(input: string): string {
    return originalFormat(input);
  },

  validate(input: string): ValidateReturn {
    const [value, error] = clean(input);

    if (error) {
      return { isValid: false, error };
    }
    if (!validLength(value)) {
      return { isValid: false, error: new exceptions.InvalidLength() };
    }
    if (!validFormat(value)) {
      return { isValid: false, error: new exceptions.InvalidFormat() };
    }

    return {
      isValid: true,
      compact: value,
      isIndividual: true,
      isCompany: false,
    };
  },
};

export { gbTaxIdValidator };
