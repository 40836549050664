import React, { useState } from "react";
import { ExpenseDialog } from "../ExpenseDialog";
import { Button } from "@justworkshr/milo-core";
import { FormField, TextArea } from "@justworkshr/milo-form";
import {
  invalidEmojiValue,
  invalidWhitespaceInputValue,
  invalidCharacterLengthValue,
} from "pages/expenses/utils";
import {
  EMOJI_ERROR,
  WHITESPACE_ERROR,
  FIVE_HUNDRED_CHARACTER_LIMIT_ERROR,
} from "pages/expenses/constants";
import styles from "./ReturnRequestDialog.module.css";

const { ReturnRequestDialogWrapper } = styles;

interface ReturnRequestDialogProps {
  isOpen: boolean;
  message: string;
  onClose: () => void;
  onReturn: (returnReason: string) => void;
}

export const ReturnRequestDialog: React.FC<ReturnRequestDialogProps> = ({
  isOpen,
  message,
  onClose,
  onReturn,
}) => {
  const [returnReason, setReturnReason] = useState({ value: "", error: "" });

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    let updates = { value, error: "" };

    if (invalidCharacterLengthValue(value, 500)) {
      updates = { value, error: FIVE_HUNDRED_CHARACTER_LIMIT_ERROR };
    } else if (invalidWhitespaceInputValue(value)) {
      updates = { value, error: WHITESPACE_ERROR };
    } else if (invalidEmojiValue(value)) {
      updates = { value, error: EMOJI_ERROR };
    }

    setReturnReason(updates);
  };

  const handleClose = () => {
    setReturnReason({ value: "", error: "" });
    onClose();
  };

  const handleReturn = () => {
    if (returnReason.value) {
      onReturn(returnReason.value);
      handleClose();
    }
  };

  return (
    <ExpenseDialog
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        <Button
          type="button"
          variant="ghost"
          key="never-mind"
          onClick={handleClose}
          data-testid="never-mind-button"
        >
          Never mind
        </Button>,
        <Button
          type="button"
          onClick={handleReturn}
          key="return-request-button"
          disabled={!returnReason.value || returnReason.error !== ""}
          data-testid="return-button"
        >
          Return to employee
        </Button>,
      ]}
    >
      <div className={ReturnRequestDialogWrapper}>
        <h2>Explain why this is being returned</h2>
        <FormField required label={message} error={returnReason.error}>
          <TextArea
            name="return-reason"
            onChange={handleChange}
            value={returnReason.value}
            placeholder="Add a message"
            data-testid="return-message-field"
          />
        </FormField>
      </div>
    </ExpenseDialog>
  );
};
