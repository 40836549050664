import { FC, ReactNode, useEffect, useState } from "react";
import { Badge, Card } from "@justworkshr/milo-core";
import styles from "./IdVerification.module.css";

interface StatusData {
  badgeRole: "positive" | "negative" | "warning";
  badgeText: "Passed" | "Unsuccessful" | "Pending";
  header: string;
  body: string | ReactNode;
}

interface Props {
  status: string;
}

const IdVerificationStatus: FC<Props> = ({ status }) => {
  const [statusData, setStatusData] = useState<StatusData | null>(null);

  useEffect(() => {
    switch (status) {
      case "approved":
        setStatusData({
          badgeRole: "positive",
          badgeText: "Passed",
          header: "Congratulations!",
          body: "Your verification is complete.",
        });
        break;
      case "declined":
        setStatusData({
          badgeRole: "negative",
          badgeText: "Unsuccessful",
          header: "We couldn’t verify your identity",
          body: (
            <>
              If you feel there was a mistake, contact{" "}
              <a href="mailto:support@justworks.com">support@justworks.com</a>
            </>
          ),
        });
        break;
      default:
        setStatusData({
          badgeRole: "warning",
          badgeText: "Pending",
          header: "We’re reviewing your identification",
          body: "When this is complete, you will receive an update.",
        });
        break;
    }
  }, [status]);

  return (
    <Card className={`${styles.card} ${styles.statusCard}`} title="">
      {statusData && (
        <>
          <Badge role={statusData.badgeRole}>{statusData.badgeText}</Badge>

          <h2 className={styles.cardTitle}>{statusData.header}</h2>

          <p className={styles.paddingTop}>{statusData.body}</p>
        </>
      )}
    </Card>
  );
};

export default IdVerificationStatus;
