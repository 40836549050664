import styles from "./Avatar.module.css";

const { avatar, hasPhoto } = styles;

interface AvatarProps {
  photoUrl?: string;
  name: string;
  height?: number;
  width?: number;
}

function Avatar({ photoUrl, name, height, width }: AvatarProps) {
  const initials = name
    .toUpperCase()
    .split(" ")
    .reduce((initials, word) => {
      return initials + word.charAt(0);
    }, "");

  const classes = [avatar];
  if (photoUrl) {
    classes.push(hasPhoto);
  }

  return (
    <figure
      className={classes.join(" ")}
      style={{
        backgroundImage: photoUrl ? `url(${photoUrl})` : "",
        width: `${width}px`,
        height: `${height}px`,
      }}
      data-dd-action-name="Click on Avatar initials"
    >
      {initials}
    </figure>
  );
}

export default Avatar;
