import React from "react";
import { Button } from "@justworkshr/milo-core";
import useProcessPmtIntegrationAction from "pages/pmt/integrations/partner-setup-page/hooks/useProcessPmtIntegrationAction";
import { AlertData } from "pages/pmt/integrations/hooks/useAlert";
import { getDefaultActionAlerts } from "../../../hooks/usePdpActionParam/utils";
import styles from "../InstanceDetailsPanel.module.css";

const { refreshButtonText } = styles;
export type RefreshButtonProps = {
  solutionSlug?: string;
  setAlertData: (alertData: AlertData) => void;
  hasPermission: boolean;
  inAlert?: boolean;
};

const RefreshButton: React.FC<RefreshButtonProps> = ({
  solutionSlug,
  setAlertData,
  hasPermission,
  inAlert,
}) => {
  const { processPmtIntegrationAction } = useProcessPmtIntegrationAction();
  const defaultRefreshAlerts = solutionSlug
    ? getDefaultActionAlerts(solutionSlug)
    : {};

  const refresh = () => {
    if (!solutionSlug) {
      return;
    }
    if (!solutionSlug) {
      return;
    }
    processPmtIntegrationAction(solutionSlug, "keep_alive").then(
      (processActionResponse) => {
        if (setAlertData) {
          if (processActionResponse?.success) {
            setAlertData(defaultRefreshAlerts.keep_alive_success);
          } else {
            setAlertData(defaultRefreshAlerts.keep_alive_failure);
          }
        }
      }
    );
  };

  return (
    <>
      <Button
        color={inAlert ? "neutral" : "destructive"}
        variant={"ghost"}
        onClick={refresh}
        disabled={!hasPermission}
      >
        <span className={refreshButtonText}>Refresh</span>
      </Button>
    </>
  );
};

export default RefreshButton;
