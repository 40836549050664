import { Navigate, Route, Routes } from "react-router-dom";
import { useGetFeatureFlag } from "lib/launch-darkly";
import { Page404 } from "pages";
import LeadFormContextProvider from "./contexts/401k/lead-form-context-provider";
import _401kEmployeeBlackoutPage from "./components/401k/employee-blackout-page";
import _401kEmployeeOverviewPage from "./components/401k/employee-overview-page";
import _401kLearnPage from "./components/401k/learn-page";
import _401kLearnPageEmployee from "./components/401k/learn-page-employee";
import _401kLeadLandingPage from "./components/401k/lead-page";
import _401kTransitionPage from "./components/401k/transition-page";
import _401kAdditionalContributionsPage from "./components/401k/additional-contributions-page";
import _401kEmployerDetailsPage from "./components/401k/employer-details-page";

export const BENEFITS_PATH = "/benefits";

export const BenefitsApp = () => {
  const { getFeatureFlag } = useGetFeatureFlag();

  return (
    <Routes>
      {getFeatureFlag("release-benefits-empower-401k") && (
        <>
          <Route
            path="/setup/401k"
            element={<Navigate to="/benefits/setup/401k/learn/overview" />}
          />
          <Route
            path="/setup/401k/learn"
            element={<Navigate to="/benefits/setup/401k/learn/overview" />}
          />
          <Route path="/setup/401k/learn/:tabId" element={<_401kLearnPage />} />
          <Route
            path="/setup/401k/contact/:stepId"
            element={
              <LeadFormContextProvider>
                <_401kLeadLandingPage />
              </LeadFormContextProvider>
            }
          />
          <Route
            path="/401k_employee/overview"
            element={<_401kEmployeeOverviewPage />}
          />
        </>
      )}
      {getFeatureFlag("release-benefits-empower-401k-transition-pdp") && (
        <>
          <Route
            path="/setup/401k/transition"
            element={<Navigate to="/benefits/setup/401k/transition/overview" />}
          />
          <Route
            path="/setup/401k/transition/:tabId"
            element={<_401kTransitionPage />}
          />
          <Route
            path="/setup/401k/employee/transition/:tabId"
            element={<_401kEmployeeBlackoutPage />}
          />
          <Route
            path="/setup/401k/employee/transition"
            element={
              <Navigate to="/benefits/setup/401k/employee/transition/overview" />
            }
          />
        </>
      )}
      {getFeatureFlag("release-benefits-empower-401-k-employee-pdp") && (
        <>
          <Route
            path="/setup/401k/employee/learn"
            element={
              <Navigate to="/benefits/setup/401k/employee/learn/overview" />
            }
          />
          <Route
            path="/setup/401k/employee/learn/:tabId"
            element={<_401kLearnPageEmployee />}
          />
          <Route
            path="/setup/401k/employee/additional-contributions"
            element={<_401kAdditionalContributionsPage />}
          />
        </>
      )}
      {getFeatureFlag("release-benefits-401-k-empower-er-pdp") && (
        <Route
          path="/401k/employer/details"
          element={<_401kEmployerDetailsPage />}
        />
      )}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};
