import { FC, useMemo } from "react";
import { Alert, Button, PageHeader, Spinner } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import { useGetEoRMemberIdVerificationStatusQuery } from "types/generated/operations";
import useOnboardingForm from "../hooks/useOnboardingForm";
import Layout from "../Layout";
import LaunchIdVerification from "./LaunchIdVerification";
import IdVerificationStatus from "./IdVerificationStatus";
import onboardingStyles from "../Onboarding.module.css";
import { useTranslation } from "react-i18next";

const state_key = "id-verification";

const IdVerification: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    memberData: { memberId },
    form: {
      submitting,
      navigatingBack,
      showErrorAlert,
      errorAlertMessage,
      onSubmit,
      onBack,
    },
    memberOnboarding: { stepNumber, redirecting, idVerificationStatus },
  } = useOnboardingForm(state_key);

  const { data: idVerificationData } = useGetEoRMemberIdVerificationStatusQuery(
    {
      variables: {
        memberId: memberId as string,
      },
      skip: !memberId,
    }
  );

  const isIdVerificationComplete = useMemo(() => {
    if (!idVerificationData?.personaIdVerificationStatus) {
      return undefined;
    }

    return !["not_started", "created"].includes(
      idVerificationData.personaIdVerificationStatus
    );
  }, [idVerificationData?.personaIdVerificationStatus]);

  const displayCard = () => {
    if (isIdVerificationComplete === undefined) {
      return <Spinner />;
    }

    return isIdVerificationComplete ? (
      <IdVerificationStatus
        status={idVerificationData?.personaIdVerificationStatus as string}
      />
    ) : (
      <LaunchIdVerification memberId={memberId || ""} onSubmit={onSubmit} />
    );
  };

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {t(errorAlertMessage) ||
            t("An error occurred while submitting your identity verification.")}
        </Alert>

        <PageHeader title={t("Verify your identity")} />

        {displayCard()}

        <ActionFooter
          className={onboardingStyles.footer}
          actions={[
            <Button
              color="brand"
              variant="filled"
              onClick={() => onSubmit({})}
              loading={submitting}
              disabled={navigatingBack || !isIdVerificationComplete}
            >
              {t("Continue")}
            </Button>,
          ]}
          secondary={[
            <Button
              type="button"
              variant="outlined"
              color="brand"
              key="back-btn"
              loading={navigatingBack}
              onClick={onBack}
              disabled={submitting}
            >
              {t("Go back")}
            </Button>,
          ]}
        />
      </>
    </Layout>
  );
};

export default IdVerification;
