export enum CountryCode {
  US = "US",
  CA = "CA",
  UK = "UK",
  ES = "ES",
  FR = "FR",
}

export enum Language {
  en = "en",
  es = "es",
  fr = "fr",
}

const language = {
  [CountryCode.US]: Language.en,
  [CountryCode.CA]: Language.en,
  [CountryCode.UK]: Language.en,
  [CountryCode.ES]: Language.es,
  [CountryCode.FR]: Language.fr,
};

const languageDisplayName = {
  [CountryCode.US]: "English",
  [CountryCode.CA]: "English",
  [CountryCode.UK]: "English",
  [CountryCode.ES]: "Español",
  [CountryCode.FR]: "Français",
};

export const getLanguage = (countryCode: CountryCode) => {
  return language[countryCode];
};

export const getLanguageDisplayName = (countryCode: CountryCode) => {
  return languageDisplayName[countryCode];
};
