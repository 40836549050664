import { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./ReviewPage.module.css";
import benefitsClasses from "pages/benefits/benefits.module.css";
import {
  Alert,
  Button,
  Card,
  Divider,
  PageHeader,
} from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import { useCreate401kPlanEnrollmentLeadMutation } from "types/generated/operations";
import { buildClockworkWebPath } from "lib/resource-finder";
import { useLeadFormContext } from "pages/benefits/contexts/401k/lead-form-context-provider";
import { useGetUserPermissionsQuery } from "types/generated/operations";
import { PermissionsError } from "../../PermissionsError";
import ResourcesBox from "../resources-box";

const ReviewPage = () => {
  const { leadForm } = useLeadFormContext();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [submitError, setSubmitError] = useState(false);
  const [create401kPlanEnrollmentLeadMutation] =
    useCreate401kPlanEnrollmentLeadMutation();

  const handleSubmit = async () => {
    const providerName = "Empower";
    const leadMetadata = JSON.stringify(leadForm);
    setSubmitError(false);
    setIsSubmitting(true);
    try {
      await create401kPlanEnrollmentLeadMutation({
        variables: {
          providerName: providerName,
          leadMetadataJson: leadMetadata,
        },
      });
      window.scrollTo(0, 0);
      navigate("/benefits/setup/401k/contact/next-steps");
    } catch (error) {
      setSubmitError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const permissionsQuery = useGetUserPermissionsQuery();
  if (permissionsQuery.loading) {
    return <></>;
  }

  const PERMISSION_BENEFITS = 1;
  if (
    !permissionsQuery.data?.currentMember?.permissions?.includes(
      PERMISSION_BENEFITS
    )
  ) {
    return <PermissionsError />;
  }

  const planTypeSentence = (planType: string | boolean | undefined) => {
    switch (planType) {
      case "TAKEOVER":
        return "Transfer my existing plan";
      case "STARTUP":
        return "Start up new plan";
      default:
        return "";
    }
  };

  const goToLeadFormPage = () => {
    window.scrollTo(0, 0);
    navigate("/benefits/setup/401k/contact/form");
  };

  return (
    <div className={classes.benefits401kReviewPage}>
      <Alert color="destructive" visible={submitError}>
        There was an issue submitting your plan details to Empower.
      </Alert>

      <PageHeader
        title="Let's get you in touch with Empower"
        linkPrevious={
          <a href={buildClockworkWebPath("/benefits/offer")}>
            Back to Add Benefits
          </a>
        }
      >
        Empower will review your information and be in touch in a few days to
        discuss plan options and next steps. And don’t worry &ndash; there is{" "}
        <strong>no obligation</strong> to enroll.
      </PageHeader>

      <div className="milo--grid" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <div style={{ gridColumn: "span 8" }}>
          <div className={classes.pageTitle}>
            <h2 className={benefitsClasses.benefitsHeader}>
              Review your plan details
            </h2>
          </div>
          <Card title="Empower contact form">
            <dl>
              <p className={benefitsClasses.benefitsParagraph}>
                <dt>
                  <strong>Company</strong>
                </dt>
                <dd>{leadForm.company_name}</dd>
              </p>
              <p className={benefitsClasses.benefitsParagraph}>
                <dt>
                  <strong>Primary contact name</strong>
                </dt>
                <dd>{leadForm.primary_contact_name}</dd>
              </p>
              <p className={benefitsClasses.benefitsParagraph}>
                <dt>
                  <strong>Primary contact email</strong>
                </dt>
                <dd>{leadForm.primary_contact_email}</dd>
              </p>
              <p className={benefitsClasses.benefitsParagraph}>
                <dt>
                  <strong>Primary contact phone number</strong>
                </dt>
                <dd>{leadForm.primary_contact_phone}</dd>
              </p>
              <Divider />
              <p className={benefitsClasses.benefitsParagraph}>
                <dt>
                  <strong>Additional email addresses</strong>
                </dt>
                <dd>{leadForm.correspondence_cc_emails}</dd>
              </p>
              <p className={benefitsClasses.benefitsParagraph}>
                <dt>
                  <strong>Additional notes</strong>
                </dt>
                <dd>{leadForm.notes}</dd>
              </p>
              <Divider />
              <p className={benefitsClasses.benefitsParagraph}>
                <dt>
                  <strong>Plan type</strong>
                </dt>
                <dd>{planTypeSentence(leadForm.plan_type)}</dd>
              </p>
              {leadForm.plan_type === "TAKEOVER" ? (
                <>
                  <p className={benefitsClasses.benefitsParagraph}>
                    <dt>
                      <strong>Current provider</strong>
                    </dt>
                    <dd>
                      {leadForm.previous_provider === "Other"
                        ? leadForm.previous_provider_other
                        : leadForm.previous_provider}
                    </dd>
                  </p>
                  <p className={benefitsClasses.benefitsParagraph}>
                    <dt>
                      <strong>Related entities</strong>
                    </dt>
                    <dd>{leadForm.has_related_entities ? "Yes" : "No"}</dd>
                  </p>
                </>
              ) : null}
            </dl>
          </Card>
          <div className={classes.setupFooter}>
            <ActionFooter
              actions={[
                <Button
                  color="brand"
                  onClick={handleSubmit}
                  key="review"
                  disabled={isSubmitting}
                >
                  <span>Submit</span>
                </Button>,
              ]}
              secondary={[
                <Button
                  variant="outlined"
                  color="brand"
                  to={""}
                  key="go_back"
                  onClick={() => goToLeadFormPage()}
                  disabled={isSubmitting}
                >
                  Back
                </Button>,
              ]}
            />
          </div>
        </div>
        <div className={classes.sidebar} style={{ gridColumn: "span 4" }}>
          <ResourcesBox />
        </div>
      </div>
    </div>
  );
};

export default ReviewPage;
