import {
  Environment,
  getEnvironmentName,
} from "../environment-finder/environment-finder";
import { FlagValues, SettingsConfig } from "./settings-type";

type EnvFlagMap = Record<Environment, Partial<SettingsConfig>>;

let flagMap: EnvFlagMap, defaultFlags: SettingsConfig;

export function importSettings(
  defaultFlagParam: SettingsConfig,
  envFlagMap: EnvFlagMap
): void {
  defaultFlags = defaultFlagParam;
  flagMap = envFlagMap;
}

export function flagIsEnabled(
  flagName: FlagValues,
  valueToCheck?: string | number
): boolean {
  const envName = getEnvironmentName();
  const currentEnvironmentFlags = flagMap[envName] || {};
  const currentEnvFlagValue = currentEnvironmentFlags[flagName];

  const flagValue =
    currentEnvFlagValue !== undefined
      ? currentEnvFlagValue
      : defaultFlags[flagName];
  return resolveFlag(flagValue, valueToCheck);
}

function resolveFlag(
  flagValue: boolean | ((valueToCheck: string | number | undefined) => boolean),
  valueToCheck: string | number | undefined
): boolean {
  return typeof flagValue === "function" ? flagValue(valueToCheck) : flagValue;
}
