import React from "react";
import { useRequestDetail } from "pages/expenses/store";
import { AdminApproversTooltip } from "pages/expenses/components/AdminApproversTooltip";
import styles from "../../ReimbursementSummary.module.css";
import {
  APPROVED_STATUS,
  INITIAL_REVIEW_STATUS,
  READY_FOR_APPROVAL_STATUS,
} from "pages/expenses/constants/request-status";
import { useReimbursementSummary } from "../../ReimbursementSummary.utils";

const {
  ReimbursementSummaryItemWrapper,
  ReimbursementSummaryItemApproverLabel,
  ReimbursementSummaryItemApproverBottomBorder,
} = styles;

interface AdminReviewProps {
  isCreateFlow: boolean;
}

export const AdminReview: React.FC<AdminReviewProps> = React.memo(
  ({ isCreateFlow }) => {
    const {
      requestDetail: {
        request: { expenses, status, myRequest },
        metadata: { isApproverView, isRequestEditView },
      },
    } = useRequestDetail();

    const {
      adminApprovers,
      isAdminApproverView,
      adminReviewApprover,
      hasExpensesPermission,
      isManagerApproverView,
      managerApproversNames,
    } = useReimbursementSummary();

    const showAdminReview =
      !!expenses.length &&
      ((!managerApproversNames[0] &&
        (isCreateFlow ||
          (status === INITIAL_REVIEW_STATUS && !isApproverView) ||
          (isRequestEditView && !isApproverView))) ||
        isManagerApproverView ||
        (!isAdminApproverView && status === READY_FOR_APPROVAL_STATUS) ||
        status === APPROVED_STATUS);

    const renderAdminReview = () => {
      if (adminApprovers?.length > 1) {
        return <AdminApproversTooltip adminApprovers={adminApprovers} />;
      }

      if (
        adminReviewApprover?.friendlyFullName ||
        adminApprovers?.length === 1
      ) {
        const adminApprover = adminReviewApprover?.friendlyFullName
          ? adminReviewApprover
          : adminApprovers[0];

        if (hasExpensesPermission && !myRequest && !isCreateFlow) {
          return (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`/employee_profile/${adminApprover?.uuid}`}
            >
              {adminApprover.friendlyFullName}
            </a>
          );
        } else {
          return <>{adminApprover.friendlyFullName}</>;
        }
      }

      return null;
    };

    if (!showAdminReview) {
      return null;
    }

    const adminReview = renderAdminReview();

    return (
      <div className={ReimbursementSummaryItemWrapper}>
        <dt>Admin review</dt>
        <dd
          className={`${ReimbursementSummaryItemApproverLabel} ${
            !!adminReviewApprover?.friendlyFullName ||
            adminApprovers?.length === 1
              ? ""
              : ReimbursementSummaryItemApproverBottomBorder
          }`}
        >
          {adminReview}
        </dd>
      </div>
    );
  }
);

export default AdminReview;
