import { useGetSeparatingMemberQuery } from "types/generated/operations";
import { Member } from "../../types";

export const useGetSeparatingMember = (memberUuid: string | undefined) => {
  const { data: memberContainer, error } = useGetSeparatingMemberQuery({
    variables: {
      memberUuid: memberUuid as string,
    },
    skip: !memberUuid,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });
  const memberFull = memberContainer?.separatingMember;
  const member: Member = {
    friendlyName: memberFull?.friendlyFullName,
    effectiveDate: memberFull?.effectiveDate,
    addressState: memberFull?.address?.state
      ? memberFull.address.state
      : undefined,
    memberType: memberFull?.memberType,
    uuid: memberUuid,
    isTerminated: !!memberFull?.isTerminated,
  };

  return { member, error };
};
