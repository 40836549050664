import {
  TerminationTypeTypes,
  InvoluntaryTerminationReasonTypes,
  VoluntaryTerminationReasonTypes,
} from "./InitialIntakeForm.types";

export const TERMINATION_TYPE_OPTIONS = {
  [TerminationTypeTypes.VOLUNTARY]: {
    label: "Voluntary",
    description: "Employee informed the company of their resignation.",
  },
  [TerminationTypeTypes.INVOLUNTARY]: {
    label: "Involuntary",
    description: "The company initiated the separation process.",
  },
};

export const INVOLUNTARY_TERMINATION_REASON_OPTIONS = {
  [InvoluntaryTerminationReasonTypes.ABSENTEEISM]:
    "Had attendance issues (absent, late, leaving early)",
  [InvoluntaryTerminationReasonTypes.ALTERCATION]:
    "Involved in a verbal or physical altercation",
  [InvoluntaryTerminationReasonTypes.DISABILITY]:
    "Unable to work due to a work-related disability",
  [InvoluntaryTerminationReasonTypes.END_OF_CONTRACT]:
    "Completed the terms of their contract",
  [InvoluntaryTerminationReasonTypes.LAYOFF]:
    "Laid off, part of reduction in force, or there was insufficient work",
  [InvoluntaryTerminationReasonTypes.PERFORMANCE]:
    "Failed to meet job requirements / inadequate performance",
  [InvoluntaryTerminationReasonTypes.PROPERTY_DAMAGE]:
    "Caused company or employee property damage at the workplace",
  [InvoluntaryTerminationReasonTypes.VIOLATION_OF_COMPANY_POLICY]:
    "Failure to comply with a Company Policy or Follow Instructions",
  [InvoluntaryTerminationReasonTypes.TRANSFER]:
    "Transfer to a related entity within Justworks",
  [InvoluntaryTerminationReasonTypes.DECEASED]: "They passed away",
};

export const VOLUNTARY_TERMINATION_REASON_OPTIONS = {
  [VoluntaryTerminationReasonTypes.OTHER_EMPLOYMENT]:
    "Seeking or have accepted other employment",
  [VoluntaryTerminationReasonTypes.HEALTH]: "Quit due to health reasons",
  [VoluntaryTerminationReasonTypes.QUIT_BEFORE_DISCHARGED]:
    "Quit in lieu of being discharged",
  [VoluntaryTerminationReasonTypes.PERSONAL_REASONS]:
    "Quit due to personal reasons",
  [VoluntaryTerminationReasonTypes.NO_RETURN_AFTER_LEAVE]:
    "Failed to return from a leave of absence",
  [VoluntaryTerminationReasonTypes.JOB_ABANDONMENT]:
    "Failed to return back to work (job abandonment)",
  [VoluntaryTerminationReasonTypes.RETIREMENT]: "Retiring from the workplace",
  [VoluntaryTerminationReasonTypes.WORKING_CONDITIONS]:
    "Quit due to working conditions",
  [VoluntaryTerminationReasonTypes.VACCINE_REQUIREMENT]:
    "Quit due to COVID-19 vaccine requirement",
  [VoluntaryTerminationReasonTypes.WORKERS_COMPENSATION_SETTLEMENT]:
    "Quit due to a workers’ compensation settlement",
  [VoluntaryTerminationReasonTypes.UNEXPLAINED_DEPARTURE]:
    "They did not inform us of a reason for leaving",
};
