import classes from "./NextStepsPage.module.css";
import benefitsClasses from "pages/benefits/benefits.module.css";
import {
  PageHeader,
  Step,
  Stepper,
  UnorderedList,
  UnorderedListItem,
} from "@justworkshr/milo-core";
import { DisplayIcon, SystemIcon } from "@justworkshr/milo-icons";
import { buildClockworkWebPath } from "lib/resource-finder";
import { useGetUserPermissionsQuery } from "types/generated/operations";
import { PermissionsError } from "../../PermissionsError";
import ResourcesBox from "../resources-box";
import clsx from "lib/styling-utils/clsx";

const NextStepsPage = () => {
  const { data, loading } = useGetUserPermissionsQuery();
  if (loading) {
    return <></>;
  }

  const PERMISSION_BENEFITS = 1;
  if (!data?.currentMember?.permissions?.includes(PERMISSION_BENEFITS)) {
    return <PermissionsError />;
  }

  return (
    <div className={classes.nextStepsPage}>
      <PageHeader
        title="Thanks for submitting with Empower 401(k)!"
        linkPrevious={
          <a href={buildClockworkWebPath("/benefits/offer")}>
            Back to Add Benefits
          </a>
        }
      ></PageHeader>
      <div className="milo--grid" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <div style={{ gridColumn: "span 8" }}>
          <h2 className={benefitsClasses.benefitsHeader}>
            Nice work, Empower will be in touch soon!
          </h2>
          <Stepper orientation="vertical">
            <Step
              stepNumber={1}
              status="complete"
              title="Submit a contact form"
              subtitle={
                (
                  <p
                    className={clsx(
                      classes.stepSubtitle,
                      benefitsClasses.benefitsParagraph
                    )}
                  >
                    First step, done!
                  </p>
                ) as unknown as string
              }
            />
            <Step
              stepNumber={2}
              status="current"
              title="Discuss your options with Empower"
              subtitle={
                (
                  <p
                    className={clsx(
                      classes.stepSubtitle,
                      benefitsClasses.benefitsParagraph
                    )}
                  >
                    A plan consultant will reach out in 2-3 days for a quick
                    intro to Empower and plan comparison.
                  </p>
                ) as unknown as string
              }
            />
            <Step
              stepNumber={3}
              status="pending"
              title="Submit a contact form"
              subtitle={
                (
                  <p
                    className={clsx(
                      classes.stepSubtitle,
                      benefitsClasses.benefitsParagraph
                    )}
                  >
                    If you’d like to move forward, Empower will help you get
                    your plan all set up. This can take about 30-60 days.
                  </p>
                ) as unknown as string
              }
            />
          </Stepper>
          <div className={classes.stepContent}>
            <p className={benefitsClasses.benefitsParagraph}>
              What the process entails:
            </p>
            <UnorderedList>
              <UnorderedListItem>
                Fill out plan evaluation survey
              </UnorderedListItem>
              <UnorderedListItem>Design your 401(k) plan</UnorderedListItem>
              <UnorderedListItem>
                Sign adoption agreement and plan documents
              </UnorderedListItem>
            </UnorderedList>
          </div>
          <div className={classes.resources}>
            <h2 className={benefitsClasses.benefitsHeader}>Resources</h2>
            <p className={benefitsClasses.benefitsParagraph}>
              Here are some resources to check out while you wait for Empower to
              reach out.
            </p>
            <div className={classes.resource}>
              <DisplayIcon iconName="money-sign" color="yellow" />
              <div className={classes.resourceText}>
                <h3 className={benefitsClasses.benefitsHeader}>
                  7 Ways Your Employees Benefit From A 401(k)
                </h3>
                <p className={benefitsClasses.benefitsParagraph}>
                  A 401(k) is an important savings opportunity for employees.
                  And the benefits of offering one are numerous.
                </p>
              </div>
              <div className={classes.resourceLink}>
                <a
                  href="https://www.justworks.com/blog/how-your-employees-benefit-from-offering-a-401k"
                  style={{ whiteSpace: "nowrap" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read guide
                </a>
                <SystemIcon iconName="arrow-right" color="brand" />
              </div>
            </div>
            <div className={classes.resource}>
              <DisplayIcon iconName="network-diagram" color="blue" />
              <div className={classes.resourceText}>
                <h3 className={benefitsClasses.benefitsHeader}>
                  Offering Employee Benefits in a Multigenerational Workplace
                </h3>
                <p className={benefitsClasses.benefitsParagraph}>
                  If you employ a multigenerational team, it’s worth considering
                  the different values of each generation when creating benefits
                  packages for your company.
                </p>
              </div>
              <div className={classes.resourceLink}>
                <a
                  href="https://www.justworks.com/blog/offering-employee-benefits-in-a-multigenerational-workplace"
                  style={{ whiteSpace: "nowrap" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read guide
                </a>
                <SystemIcon iconName="arrow-right" color="brand" />
              </div>
            </div>
            <div className={classes.resource}>
              <DisplayIcon iconName="piggy-bank-with-cross" color="green" />
              <div className={classes.resourceText}>
                <h3 className={benefitsClasses.benefitsHeader}>
                  Saving for the Future with a 401(k)
                </h3>
                <p className={benefitsClasses.benefitsParagraph}>
                  It’s time to help your employees get started. Whether you have
                  one employee or a hundred, it’s becoming much easier for small
                  businesses to provide a better 401(k).
                </p>
              </div>
              <div className={classes.resourceLink}>
                <a
                  href="https://www.justworks.com/resources/401k-saving-for-future-dl"
                  style={{ whiteSpace: "nowrap" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read guide
                </a>
                <SystemIcon iconName="arrow-right" color="brand" />
              </div>
            </div>
          </div>
        </div>
        <div style={{ gridColumn: "span 4" }}>
          <ResourcesBox />
        </div>
      </div>
    </div>
  );
};

export default NextStepsPage;
