import { SystemIcon } from "@justworkshr/milo-icons";
import { ReactElement } from "react";
import styles from "./nested-drawer-header.module.css";

const { nestedDrawerHeader } = styles;

interface NestedDrawerHeaderProps {
  icon: ReactElement;
  onClick?: React.MouseEventHandler<Element>;
  children: string;
}

function NestedDrawerHeader({
  icon,
  onClick,
  children,
}: NestedDrawerHeaderProps) {
  return (
    <div className={nestedDrawerHeader}>
      <button data-testid="secondary-drawer-backward-button" onClick={onClick}>
        <SystemIcon iconName="chevron-left" size="large" />
      </button>
      {icon}
      <span>{children}</span>
    </div>
  );
}

export default NestedDrawerHeader;
