import styles from "./SettingsTable.module.css";

const { SettingsTableWrapper } = styles;

interface SettingsTableProps {
  headers: string[];
  dataTestId?: string;
  children:
    | React.ReactElement<HTMLTableRowElement>
    | React.ReactElement<HTMLTableRowElement>[];
}

export const SettingsTable: React.FC<SettingsTableProps> = ({
  headers,
  children,
  dataTestId,
}) => {
  return (
    <table className={SettingsTableWrapper} data-testid={dataTestId}>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={`${header}_${index}`}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};
