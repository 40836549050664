import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FULL_PATHS } from "../../constants";
import { useInsightsLearnPageViewContext } from "../../contexts/insights-learn-page-view-context";
import InsightsThumbnailImage from "../../images/insights-101-thumb-640x360.jpg";
import FullscreenWrapper from "../fullscreen-wrapper";
import VisierAppIframe from "../visier-app-iframe";

interface InsightsPageProps {
  visierSrcSuffix: string | null;
}

const InsightsPage: FC<InsightsPageProps> = ({ visierSrcSuffix }) => {
  const navigate = useNavigate();
  const { hasViewedInsightsLearnPage } = useInsightsLearnPageViewContext();

  useEffect(() => {
    if (!hasViewedInsightsLearnPage) {
      navigate(FULL_PATHS.INSIGHTS_LEARN);
    }
  }, [hasViewedInsightsLearnPage, navigate]);

  const drawerContent = [
    {
      title: "Insights 101: Mastering Data Analysis",
      description:
        "Learn the basics of viewing your company’s data in Insights. Share data with teammates to communicate trends and inform decision-making.",
      learnMoreHref:
        "https://help.justworks.com/hc/en-us/articles/24692921724955",
      imageSrc: InsightsThumbnailImage,
    },
    {
      title: "Overview",
      description:
        "What is People Analytics? A brief overview of Justworks’ new insights and reporting tool.",
      learnMoreHref:
        "https://help.justworks.com/hc/en-us/articles/22463671611931",
      imageSrc: null,
    },
    {
      title: "Insights",
      description:
        "Learn how to use the Insights tool and explore some common use cases.",
      learnMoreHref:
        "https://help.justworks.com/hc/en-us/articles/22463885583771",
      imageSrc: null,
    },
    {
      title: "Filters",
      description:
        "Use filters to narrow down your searches and quickly find the data you need.",
      learnMoreHref:
        "https://help.justworks.com/hc/en-us/articles/22464519982107",
      imageSrc: null,
    },
  ];

  return (
    <div data-testid="insights-page">
      <FullscreenWrapper
        showDashboardNavigation
        backCopy="Exit"
        backPath={FULL_PATHS.COMPANY_OVERVIEW}
        drawerContent={drawerContent}
      >
        <VisierAppIframe visierSrcSuffix={visierSrcSuffix} />
      </FullscreenWrapper>
    </div>
  );
};

export default InsightsPage;
