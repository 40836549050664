import { Box, Alert, Spinner } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import styles from "./CombinedInvoice.module.css";
import { StatusBadge } from "./components/StatusBadge";
import { PaymentSection } from "./components/PaymentSection";
import { CombinedInvoiceContext } from "./contexts/CombinedInvoiceContext";
import { buildWebPath } from "lib/resource-finder";
import { useTranslation, Trans } from "react-i18next";
import { useGetEoRCombinedInvoicesQuery } from "types/generated/operations";
import { useParams } from "react-router-dom";
import { aCombinedEoRInvoice } from "test-tools/dsl/employer-of-record/";
import { InvoiceSummary } from "./components/InvoiceSummary";
import { InvoiceDetailedSummary } from "./components/InvoiceDetailedSummary";
import { CombinedInvoiceData } from "./types";

import { toCurrencyFormat } from "../utils";
import {
  parseInvoiceResponse,
  isPastCutoff,
  toBannerProcessingDateFormat,
  toProcessingDateFormat,
  localCurrencyPayoutText,
  toPaymentDateFormat,
  isPastWiringInstructionsCutoff,
} from "./CombinedInvoice.utils";

import { useState } from "react";

const {
  contentSection,
  payrollChangeAlert,
  returnLink,
  additionalPaymentInfo,
  organizationName,
  padlessGrid,
  invoiceHeader,
  summaryHeaderLeft,
  invoiceSubtext,
  processingDateHeader,
  summaryHeaderRight,
  invoiceTotalHeader,
  invoiceTotalDate,
  invoiceTotalAmount,
  mobileVisible,
  currencyPayout,
  fxRateInfo,
  estimatedBreakdownHeader,
  paymentDateEmphasis,
  debitDateText,
} = styles;

const CombinedInvoice = () => {
  const { t } = useTranslation();
  const [isBreakdownSectionExpanded, setIsBreakdownSectionExpanded] = useState<
    boolean[]
  >([]);
  let feeSectionIndex = 0;

  const { processingDate } = useParams();
  let loading = false;
  let error = undefined;
  let data = null;

  const queryData = useGetEoRCombinedInvoicesQuery({
    variables: { processingDate: processingDate || "" },
  });

  loading = queryData.loading;
  error = queryData.error;
  data = queryData.data;

  let boxContent = null;

  const errorMessage = (
    <div className={contentSection}>Sorry! We couldn't load this page.</div>
  );

  if (loading) {
    boxContent = (
      <div className={contentSection}>
        <Spinner />
      </div>
    );
  } else if (error) {
    boxContent = errorMessage;
  } else {
    const combinedInvoiceData: CombinedInvoiceData | null =
      processingDate === undefined || !data
        ? aCombinedEoRInvoice().responseData
        : parseInvoiceResponse(data);

    if (!combinedInvoiceData) {
      boxContent = errorMessage;
    } else if (combinedInvoiceData.status === "editing") {
      boxContent = (
        <div className={contentSection}>
          Sorry! This invoice is not ready to be reviewed.
        </div>
      );
    } else {
      const isProcessed = isPastCutoff(
        combinedInvoiceData.processingDate,
        "11:00:00"
      );
      const shouldHideWiringInstructions = isPastWiringInstructionsCutoff(
        combinedInvoiceData.processingDate
      );

      const shouldShowPayrollChangeAlert =
        combinedInvoiceData.pendingDisbursementOrders.length > 0 &&
        !isProcessed;

      const returnUrl = isProcessed ? "invoices" : "dashboard";
      const sanitizedInvoiceId = combinedInvoiceData.invoices[0].id.replace(
        "manual_invoice_",
        ""
      );

      const invoiceTotal = toCurrencyFormat(
        combinedInvoiceData.total,
        combinedInvoiceData.baseCurrency,
        true
      );

      const bannerProcessingDate = toBannerProcessingDateFormat(
        new Date(combinedInvoiceData.processingDate)
      );
      const uniqueLocalCurrencyCount = combinedInvoiceData.invoices.length;
      feeSectionIndex = uniqueLocalCurrencyCount;
      const paymentDate = toPaymentDateFormat(
        new Date(combinedInvoiceData.invoices[0].payPeriodRange.paymentDueDate)
      );
      const debitDate = toPaymentDateFormat(
        new Date(combinedInvoiceData.invoices[0].payPeriodRange.paymentDueDate)
      );
      const paymentDateString = isProcessed ? (
        ""
      ) : (
        <span className={paymentDateEmphasis}>{` ${t(
          "Payment will be due on "
        )}${paymentDate}.`}</span>
      );
      boxContent = (
        <div>
          {!isProcessed && shouldShowPayrollChangeAlert && (
            <div className={payrollChangeAlert}>
              <Alert color="destructive">
                {t(
                  "We detected payroll updates and are recalculating your invoice. Check back later."
                )}
              </Alert>
            </div>
          )}
          <Box padding="xl" backgroundColor="neutralSubtle">
            <a href={buildWebPath(returnUrl)} id={returnLink}>
              <SystemIcon iconName="arrow-left" color="brand" size="small" />
              <span>
                {isProcessed ? t("Back to Invoices") : t("Back to Dashboard")}
              </span>
            </a>
            {!isProcessed && (
              <div className={additionalPaymentInfo}>
                <Box padding="lg" backgroundColor="brandSubtle">
                  <Trans
                    i18nKey="invoiceBanner"
                    values={{ bannerProcessingDate }}
                  >
                    All additional payments need to be scheduled through the
                    Payment center before {bannerProcessingDate}. If you need to
                    make edits or changes to the estimated invoice, email{" "}
                    <b>international-support@justworks.com</b> before{" "}
                    <b>{bannerProcessingDate}</b> to request a change.
                  </Trans>
                </Box>
              </div>
            )}
            {isProcessed && (
              <h4 className={organizationName}>
                {t("Justworks, Inc. and affiliates")}
              </h4>
            )}
            <div className={contentSection}>
              <div className={`${padlessGrid} ${invoiceHeader}`}>
                <div className={summaryHeaderLeft}>
                  <StatusBadge
                    isProcessed={isProcessed}
                    invoiceId={sanitizedInvoiceId}
                  />
                  <h1 className={processingDateHeader}>
                    {toProcessingDateFormat(
                      new Date(combinedInvoiceData.processingDate)
                    )}
                  </h1>
                  {isProcessed && !shouldHideWiringInstructions && (
                    <h3 className={invoiceSubtext}>
                      {t(
                        "Reference the final invoice that was emailed to your company and in the Document center."
                      )}
                    </h3>
                  )}
                  {shouldHideWiringInstructions && (
                    <h3 className={debitDateText}>
                      {`${t(isProcessed ? "Debit date: " : "Debit on ")}`}
                      <b>{debitDate}</b>
                    </h3>
                  )}
                </div>
                <div className={summaryHeaderRight}>
                  <h3 className={invoiceTotalHeader}>
                    {isProcessed
                      ? t("Total invoice")
                      : t("Total estimated invoice")}
                  </h3>
                  <h2 className={invoiceTotalAmount}>{invoiceTotal}</h2>
                  <h3 className={`${mobileVisible} ${currencyPayout}`}>
                    {localCurrencyPayoutText(
                      uniqueLocalCurrencyCount,
                      t("Payout in"),
                      t("currencies")
                    )}
                  </h3>
                  {!shouldHideWiringInstructions && isProcessed && (
                    <h3 className={invoiceTotalHeader}>
                      {`${t("Pay invoice by")}: `}
                      <span className={invoiceTotalDate}>{paymentDate}</span>
                    </h3>
                  )}
                </div>
              </div>
              <PaymentSection combinedInvoiceData={combinedInvoiceData} />
              {!shouldHideWiringInstructions && (
                <p className={fxRateInfo}>
                  {t(
                    "The actual FX rate may vary from the estimate. We'll email your final invoice with adjusted FX rate and instructions to complete the wire transfer."
                  )}
                  {paymentDateString}
                </p>
              )}
              {shouldHideWiringInstructions && !isProcessed && (
                <p className={fxRateInfo}>
                  {t(
                    "The actual FX rate may vary from the estimate. View your final invoice for the adjusted FX rate."
                  )}
                </p>
              )}
            </div>
            <h2 className={estimatedBreakdownHeader}>{t("Invoice details")}</h2>
            {combinedInvoiceData.invoices.map((invoice, index) => (
              <InvoiceSummary
                invoice={invoice}
                summaryIndex={index}
                key={invoice.id}
              />
            ))}
            <InvoiceDetailedSummary
              fees={combinedInvoiceData.fees}
              baseCurrency={combinedInvoiceData.baseCurrency}
              summaryIndex={feeSectionIndex}
            />
          </Box>
        </div>
      );
    }
  }

  return (
    <CombinedInvoiceContext.Provider
      value={{
        isBreakdownSectionExpanded,
        setIsBreakdownSectionExpanded,
        feeSectionIndex,
      }}
    >
      {boxContent}
    </CombinedInvoiceContext.Provider>
  );
};

export default CombinedInvoice;
