import styles from "./ExpenseLibrary.module.css";
import React, { useState } from "react";
import {
  MultiAction,
  useMultiAction,
  useExpenseLibrary,
  useCategoriesExpenseType,
  useDeleteUnattachedExpense,
} from "pages/expenses/hooks";
import {
  EXPENSE_LIBRARY_TABLE_PARAMS,
  EXPENSE_LIBRARY_TABLE_HEADERS,
} from "./ExpenseLibrary.constants";
import { ExpenseLibraryExpense } from "types/Expenses";
import {
  useRequestDetail,
  useGlobalConfigurationStore,
} from "pages/expenses/store";
import { useNavigate } from "react-router-dom";
import { prependBasePath, PAGINATION_OPTIONS } from "pages/expenses/constants";
import { GetExpenseLibraryDocument } from "types/generated/operations";
import { setTableQueryVariables } from "pages/expenses/utils";
import { Button } from "@justworkshr/milo-core";
import { CheckboxInput } from "@justworkshr/milo-form";
import { ExpenseDetailDrawer, ExpenseLibraryTableRow } from "./components";
import { Loading } from "../Loading";
import { Table, TableBody, TableHeader } from "../Table";
import { PaginationSelect } from "../PaginationSelect";
import { RemoveExpenseDialog } from "../RemoveExpenseDialog";

const { ExpenseLibraryTableWrapper, ExpenseLibraryAllExpensesCheckbox } =
  styles;

interface ExpenseLibraryProps {
  emptyNotice: JSX.Element;
  showMultiAction: boolean;
  showExpenseDetailDrawer: boolean;
  multiActionProps: ReturnType<typeof useMultiAction<ExpenseLibraryExpense>>;
}

export const ExpenseLibrary: React.FC<ExpenseLibraryProps> = ({
  emptyNotice,
  showMultiAction,
  multiActionProps: {
    clearItems,
    selectedItems,
    handleSelectedItem,
    handleSelectedItems,
  },
  showExpenseDetailDrawer,
}) => {
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState(EXPENSE_LIBRARY_TABLE_PARAMS);
  const [showRemoveExpenseDialog, setShowRemoveExpenseDialog] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState<{
    expense: ExpenseLibraryExpense;
    expenseIndex: number;
  } | null>(null);
  const { isExpensesEnabled } = useGlobalConfigurationStore();
  const { setIsUnattachedExpenseView, setRequestDetailExpenses } =
    useRequestDetail();
  const { deleteUnattachedExpense, isDeleteUnattachedExpenseLoading } =
    useDeleteUnattachedExpense(() => clearItems());
  const { expenseLibrary, isExpenseLibraryLoading } =
    useExpenseLibrary(tableParams);

  const { isCategoryWithNonReimbursableExpenses } = useCategoriesExpenseType();

  const libraryExpenses = expenseLibrary.library.expenses;
  const hasLibraryExpenses = libraryExpenses.length > 0;
  const isAllLibraryExpensesSelected =
    libraryExpenses?.length === selectedItems.length;
  const isLoading = isExpenseLibraryLoading || isDeleteUnattachedExpenseLoading;
  const isDisabled = !isExpensesEnabled || isLoading;

  const handleCheckboxChange = () => handleSelectedItems(libraryExpenses);

  const handleRemoveExpenseDialog = () =>
    setShowRemoveExpenseDialog((prevState) => !prevState);

  const handleSelectedExpense = (
    data: {
      expense: ExpenseLibraryExpense;
      expenseIndex: number;
    } | null
  ) => {
    setSelectedExpense(data);
  };

  const handleRemoveExpense = () => {
    const selectedItemsUUIDs = selectedItems.map((item) => item.uuid);
    const uuids = selectedExpense
      ? [selectedExpense.expense.uuid]
      : selectedItemsUUIDs;

    deleteUnattachedExpense({
      variables: { uuids },
      refetchQueries: [
        {
          query: GetExpenseLibraryDocument,
          variables: {
            ...setTableQueryVariables(tableParams),
            attachedUuids: [],
            detachedUuids: [],
          },
        },
      ],
      awaitRefetchQueries: true,
    });

    handleRemoveExpenseDialog();
    setSelectedExpense(null);
  };

  const renderMultiActionButtons = (isActive: boolean) => {
    const isButtonDisabled = !isActive || isDisabled;

    return (
      <>
        <Button
          size="sm"
          type="button"
          leftIcon="trash"
          variant="outlined"
          color="destructive"
          disabled={isButtonDisabled}
          onClick={() => handleRemoveExpenseDialog()}
          data-testid="delete-expense-button"
        >
          Delete
        </Button>
        <Button
          size="sm"
          type="button"
          leftIcon="folder"
          variant="outlined"
          disabled={isButtonDisabled}
          onClick={() => {
            setRequestDetailExpenses(selectedItems);

            navigate(prependBasePath("NEW_REQUEST"));
          }}
          data-testid="attach-expense-button"
        >
          Attach to new request
        </Button>
        <Button
          size="sm"
          type="button"
          variant="outlined"
          leftIcon="plus-circle"
          onClick={() => {
            navigate(prependBasePath("EXPENSE_DETAILS"));
            setIsUnattachedExpenseView(true);
          }}
          data-testid="create-expense-button"
        >
          Create Expense
        </Button>
      </>
    );
  };

  const renderLibraryExpenses = () => {
    return (
      <TableBody>
        {libraryExpenses.map((expense, expenseIndex) => {
          const isCheckboxActive = !!selectedItems.find(
            (item) => item.uuid === expense.uuid
          );

          const handleCheckboxChange = () =>
            handleSelectedItem(expense, isCheckboxActive, (prevSelectedItems) =>
              prevSelectedItems.filter((item) => item.uuid !== expense.uuid)
            );

          return (
            <ExpenseLibraryTableRow
              expense={expense}
              key={expense.uuid}
              expenseIndex={expenseIndex}
              isCheckboxActive={isCheckboxActive}
              onExpenseChange={handleSelectedExpense}
              onCheckboxChange={handleCheckboxChange}
              isCategoryWithNonReimbursableExpenses={
                isCategoryWithNonReimbursableExpenses
              }
              showExpenseDetailDrawer={showExpenseDetailDrawer}
            />
          );
        })}
      </TableBody>
    );
  };

  const handlePaginationSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) =>
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      resultsPerPage: parseInt(event.target.value, 10),
    }));

  const handlePaginationPageChange = (newPageNum: number) =>
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      pageNum: newPageNum - 1,
    }));

  const handleSort = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string;
    sortDirection: string;
  }) =>
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      pageNum: 0,
      sortBy,
      sortDirection,
    }));

  const paginationProps = {
    options: PAGINATION_OPTIONS,
    onPageChange: handlePaginationPageChange,
    resultsPerPage: tableParams.resultsPerPage,
    onSelectChange: handlePaginationSelectChange,
    totalResults: expenseLibrary.library.totalResults,
    currentPage: (expenseLibrary.library.page || 0) + 1,
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!isLoading && !hasLibraryExpenses) {
    return emptyNotice;
  }

  return (
    <div className="ExpenseLibraryWrapper">
      <MultiAction selectedItems={selectedItems}>
        {({ isActive }) =>
          showMultiAction && renderMultiActionButtons(isActive)
        }
      </MultiAction>
      <div className={ExpenseLibraryTableWrapper}>
        <div className={ExpenseLibraryAllExpensesCheckbox}>
          <CheckboxInput
            name=""
            value=""
            onChange={handleCheckboxChange}
            checked={isAllLibraryExpensesSelected}
            aria-label={`${
              isAllLibraryExpensesSelected ? "Deselect" : "Select"
            } all expenses`}
          />
        </div>
        <Table>
          <TableHeader
            onSort={handleSort}
            sortBy={tableParams.sortBy}
            headers={EXPENSE_LIBRARY_TABLE_HEADERS}
            sortDirection={tableParams.sortDirection}
          />
          {renderLibraryExpenses()}
        </Table>
      </div>
      <PaginationSelect {...paginationProps} />
      {selectedExpense && (
        <ExpenseDetailDrawer
          expense={selectedExpense.expense}
          expenseIndex={selectedExpense.expenseIndex}
          onRemoveExpense={handleRemoveExpenseDialog}
          onSelectedExpenseChange={handleSelectedExpense}
        />
      )}
      {showRemoveExpenseDialog && (
        <RemoveExpenseDialog
          onRemove={handleRemoveExpense}
          isOpen={showRemoveExpenseDialog}
          onClose={handleRemoveExpenseDialog}
          isMultiple={selectedItems.length > 1}
        />
      )}
    </div>
  );
};

export default ExpenseLibrary;
