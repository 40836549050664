function containsIllegalChar(text: string): boolean {
  // Our database currently only supports utf8 characters that are 3 bytes or fewer
  //Unicode code points that encode to those characters end at 0xFFFF
  //https://en.wikipedia.org/wiki/UTF-8#Description
  const lastThreeByteCodePoint = 0xffff;

  for (let index = 0; index < text.length; index++) {
    const codePoint = text.codePointAt(index) || -1;
    if (codePoint > lastThreeByteCodePoint) {
      return true;
    }
  }

  return false;
}

export { containsIllegalChar };
