import styles from "./StepClosed.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";

const { stepClosed } = styles;

export const StepClosed = ({
  name,
  isPastStep,
}: {
  name: string | undefined;
  isPastStep: boolean;
}) => {
  return (
    <div className={stepClosed}>
      {isPastStep && (
        <SystemIcon iconName="check-circle-inverted" color="success" />
      )}
      {name}
    </div>
  );
};
