import { SORT } from "../Table/Table.constants";
import { DEFAULT_TABLE_PARAMS } from "pages/expenses/constants";

export const EXPENSE_LIBRARY_TABLE_HEADERS = [
  {
    key: "",
    label: "",
    isSortable: false,
  },
  {
    key: "CREATED",
    label: "Creation Date",
    isSortable: true,
  },
  {
    key: "MERCHANT",
    label: "Merchant",
    isSortable: false,
  },
  {
    key: "CATEGORY",
    label: "Category",
    isSortable: false,
  },
  {
    key: "DATE",
    label: "Transaction Date",
    isSortable: true,
  },
  {
    key: "AMOUNT",
    label: "Amount",
    isSortable: true,
  },
  {
    key: "RECEIPT",
    label: "Receipt",
    isSortable: false,
  },
];

export const EXPENSE_LIBRARY_TABLE_PARAMS = {
  ...DEFAULT_TABLE_PARAMS,
  sortBy: "CREATED",
  sortDirection: SORT.DESC,
};
