const mxPassportRegEx = /^[A-Z][0-9]{8}$/;
const mxINERegEx = /^[0-9]{10}$/;

const mxPersonalIdRegEx = new RegExp(
  `${mxPassportRegEx.source}|${mxINERegEx.source}`
);

const mxSexOptions = [
  { value: "male", description: "Male" },
  { value: "female", description: "Female" },
];

const mxCivilStatusOptions = [
  {
    value: "single",
    description: "Single",
  },
  {
    value: "married",
    description: "Married",
  },
  {
    value: "civilPartnership",
    description: "Civil Partnership",
  },
];

export { mxPersonalIdRegEx, mxSexOptions, mxCivilStatusOptions };
