import { useTranslation } from "react-i18next";
import CardAction from "../CardAction/CardAction";
import { useState } from "react";
import { Dialog } from "@justworkshr/milo-core";

export default function RequestChangesAction() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  function openDialog() {
    setIsOpen(true);
  }

  function closeDialog() {
    setIsOpen(false);
  }

  return (
    <>
      <CardAction
        onClick={openDialog}
        name={t("Request Changes")}
        icon="lock"
      />
      <Dialog
        isOpen={isOpen}
        onClose={closeDialog}
        showCloseButton
        size="sm"
        title="Want to request changes?"
      >
        Email{" "}
        <a href="mailto:international-support@justworks.com">
          international-support@justworks.com
        </a>{" "}
        to request a change to contract details and include the employee's full
        name in the request. This may impact their employment agreement.
      </Dialog>
    </>
  );
}
