import React from "react";
import { useCategoriesExpenseType } from "pages/expenses/hooks";

import {
  getTotalSubmitted,
  getTotalNonReimbursable,
  getTotalReimbursableAmount,
} from "./TotalReimbursement.utils";
import { getDollarsFromCents, mergeClassNames } from "pages/expenses/utils";
import styles from "../../ReimbursementSummary.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";
import { ExpandableText } from "pages/expenses/components/Expandable";
import { useRequestDetail } from "pages/expenses/store";
import { NOT_INCLUDED_TEXT } from "./TotalReimbursement.constants";

const {
  ReimbursementSummaryItemWrapper,
  ReimbursementSummaryItemMoneyLabel,
  ReimbursementSummaryTotalReimbursementWrapper,
  ReimbursementSummaryTotalReimbursementContainer,
  ReimbursementSummaryTotalReimbursementTotalWrapper,
  ReimbursementSummaryTotalReimbursementWrapperBorder,
  ReimbursementSummaryTotalReimbursementExpandableTextWrapper,
} = styles;

export const TotalReimbursement: React.FC = React.memo(() => {
  const {
    requestDetail: {
      request: { expenses },
    },
  } = useRequestDetail();

  const {
    isCategoriesExpenseTypeLoading,
    isCategoryWithNonReimbursableExpenses,
  } = useCategoriesExpenseType();

  const renderTotalReimbursement = () => {
    const totalReimbursableAmount = `$${getTotalReimbursableAmount(expenses)}`;

    if (isCategoryWithNonReimbursableExpenses) {
      const totalSubmitted = getDollarsFromCents(getTotalSubmitted(expenses));

      const totalNonReimbursable = getTotalNonReimbursable(expenses);

      return (
        <ExpandableText
          buttonLabel="Total reimbursement"
          buttonLabelAsRenderer={({ iconButton }) => (
            <div
              className={
                ReimbursementSummaryTotalReimbursementExpandableTextWrapper
              }
              data-testid="expense-summary-data-list"
            >
              <span style={{ fontWeight: 700 }}>
                Total reimbursement
                {iconButton}
              </span>
              <span className={ReimbursementSummaryItemMoneyLabel}>
                {totalReimbursableAmount}
              </span>
            </div>
          )}
        >
          <div className={ReimbursementSummaryTotalReimbursementTotalWrapper}>
            <div className={ReimbursementSummaryTotalReimbursementContainer}>
              <dt>Submitted</dt>
              <dd>{totalSubmitted}</dd>
            </div>
            <div
              className={
                ReimbursementSummaryTotalReimbursementExpandableTextWrapper
              }
            >
              <ExpandableText
                buttonLabelAsRenderer={({ handleClick }) => (
                  <div
                    role="button"
                    onClick={handleClick}
                    className={
                      ReimbursementSummaryTotalReimbursementExpandableTextWrapper
                    }
                  >
                    <span>
                      Non-reimbursable{" "}
                      <SystemIcon iconName="info-circle" color="brand" />
                    </span>
                    <span>
                      {totalNonReimbursable === 0
                        ? getDollarsFromCents(totalNonReimbursable)
                        : `(${getDollarsFromCents(totalNonReimbursable)})`}
                    </span>
                  </div>
                )}
              >
                {NOT_INCLUDED_TEXT}
              </ExpandableText>
            </div>
          </div>
        </ExpandableText>
      );
    }

    return (
      <>
        <dt>Total reimbursement</dt>
        <dd className={ReimbursementSummaryItemMoneyLabel}>
          {totalReimbursableAmount}
        </dd>
      </>
    );
  };

  if (isCategoriesExpenseTypeLoading) {
    return null;
  }

  return (
    <div
      className={mergeClassNames([
        ReimbursementSummaryTotalReimbursementWrapper,
        {
          [ReimbursementSummaryTotalReimbursementWrapperBorder]:
            expenses.length > 0,
        },
      ])}
    >
      <div className={ReimbursementSummaryItemWrapper}>
        <dt>Total expenses</dt>
        <dd>{expenses.length}</dd>
      </div>
      <div className={ReimbursementSummaryItemWrapper}>
        {renderTotalReimbursement()}
      </div>
    </div>
  );
});

export default TotalReimbursement;
