import styles from "pages/company-settings/components/InitiateMicrodepositPage.module.css";
import CompanyBankNotice, { NoticeTypes } from "./CompanyBankNotice";

const { notice } = styles;

type VerificationErrorNoticeProps = {
  errorType:
    | "already-on-file"
    | "prepaid-account"
    | "invalid-account"
    | "non-business-account"
    | "closed-account"
    | string;
};

export default function VerificationErrorNotice({
  errorType,
}: VerificationErrorNoticeProps) {
  const errorContent = () => {
    switch (errorType) {
      case "already-on-file":
        return "It looks like the account is already on file. Double check the account details or try another account.";
      case "prepaid-account":
        return "It looks like this is a pre-paid bank account. You’ll need to use a valid business checking or savings account.";
      case "invalid-account":
        return "It looks like the routing or account number isn’t valid. Double check that the account details entered is correct, or try using another account.";
      case "non-business-account":
        return "It looks like this isn’t a business account. To stay compliant, please use a valid business bank account for payroll processing.";
      case "closed-account":
        return "It looks like the account is closed or frozen. Double check that the account details entered is correct, or try using another account.";
      default:
        return "Try using another account, or contact our customer support team at (888) 534-1711 or support@justworks.com.";
    }
  };
  const errorTitle = () => {
    switch (errorType) {
      case "already-on-file":
        return "We’re unable to update the account";
      case "non-business-account":
        return "We cannot use a non-business bank account for payroll";
      default:
        return "We’re unable to verify the new account";
    }
  };

  return (
    <div className={notice}>
      <CompanyBankNotice title={errorTitle()} type={NoticeTypes.WARNING}>
        {errorContent()}
      </CompanyBankNotice>
    </div>
  );
}
