import { ReactElement } from "react";
import styles from "./employee-confirmation.module.css";
import { formatPhone } from "../../shared/utils";
import { buildClockworkWebPath } from "lib/resource-finder";

import { InjuryDate } from "../../../types";

const {
  reviewText,
  title4,
  boxContent,
  contactBox,
  infoCompanyContent,
  companyAddressInfo,
  note,
  infoEmployeeContent,
  employeeAddressInfo,
  firstSection,
} = styles;

export function EmployeeConfirmDetails({
  name,
  injuryDate,
}: {
  name: string;
  injuryDate: InjuryDate;
}): ReactElement {
  const {
    homeAddress,
    companyAddress,
    workEmail,
    contactPhone,
    companyName,
    adminEmail,
    adminPhone,
    uuid,
  } = injuryDate;

  return (
    <div className={firstSection}>
      <div className={reviewText}>
        <small>
          <strong>Review</strong>
        </small>
      </div>
      <h3 className={title4}>Employee and company contact information</h3>
      <div className={contactBox}>
        <div className={boxContent}>
          <h3>{name}</h3>
          <div className={infoEmployeeContent}>
            <div className={employeeAddressInfo}>
              <h4>
                <small>Home Address</small>
              </h4>
              {homeAddress?.street1}
              {homeAddress?.street2 && <br />}
              {homeAddress?.street2}
              <br />
              {homeAddress?.city}, {homeAddress?.state},{" "}
              {homeAddress?.postalCode}
            </div>
            <div className={employeeAddressInfo}>
              <h4>
                <small>Office Address</small>
              </h4>
              {companyAddress?.street1}
              {companyAddress?.street2 && <br />}
              {companyAddress?.street2}
              <br />
              {companyAddress?.city}, {companyAddress?.state},{" "}
              {companyAddress?.postalCode}
            </div>
          </div>
          <div className={infoEmployeeContent}>
            <div className={employeeAddressInfo}>
              <h4>
                <small>Employee Work Email</small>
              </h4>
              {workEmail}
            </div>
            <div className={employeeAddressInfo}>
              <h4>
                <small>Employee Personal Phone</small>
              </h4>
              {contactPhone && formatPhone(contactPhone)}
            </div>
          </div>
        </div>
        <div className={boxContent}>
          <h3>{companyName}</h3>
          <div className={infoCompanyContent}>
            <div className={companyAddressInfo}>
              <h4>
                <small>Company Admin Contact</small>
              </h4>
              {adminEmail && adminEmail}
              {adminEmail && <br />}
              {adminPhone && formatPhone(adminPhone)}
            </div>
          </div>
        </div>
      </div>
      <div className={note}>
        You can update this information in the
        <a
          href={buildClockworkWebPath(`/members/${uuid}/personal-info`)}
          target="_blank"
        >
          {" "}
          employee profile
        </a>{" "}
        or
        <a href={buildClockworkWebPath(`/company`)} target="_blank">
          {" "}
          company settings{" "}
        </a>
        if anything is incorrect.
      </div>
    </div>
  );
}
