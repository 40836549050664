import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// used for reading state passed between pages via react router (local navigation with clockface only)
// if shouldClear is true, the location state key will be cleared when it is read
// this is useful for showing an alert once and then clearing it and avoiding showing it again if the user refreshes the page
export const useClearableLocationState = <T,>(
  stateKey: string,
  shouldClear = true
): { value: T | undefined; originalValue: T | undefined } => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state;
  const locationStateValue = (locationState || {})[stateKey];
  const hasLocationStateValue = !!locationStateValue;

  useEffect(() => {
    if (shouldClear && hasLocationStateValue) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [stateKey]: omit, ...restState } = locationState; // using destructuring to remove the key
      navigate({}, { replace: true, state: restState });
    }
  }, [locationState, shouldClear, stateKey, hasLocationStateValue, navigate]);

  // we want the stale original value of locationStateValue to be returned
  // so i am disabling the eslint rule here so we do not listen to changes in locationStateValue
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const originalValue = useMemo(() => locationStateValue, []);

  return { value: locationStateValue, originalValue: originalValue };
};
