import styles from "./SubmitterOutlet.module.css";
import { Outlet } from "react-router-dom";
import { SubmitterHeader } from "./components";
import { useEffect } from "react";
import { useRequestDetail } from "pages/expenses/store";

const { SubmitterOutletWrapper } = styles;

export const SubmitterOutlet = () => {
  const { resetRequestDetails } = useRequestDetail();

  useEffect(() => {
    resetRequestDetails();
  }, [resetRequestDetails]);

  return (
    <div className={SubmitterOutletWrapper}>
      <SubmitterHeader />
      <Outlet />
    </div>
  );
};

export default SubmitterOutlet;
