import styles from "./InvitationPage.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  InviteeInfoForm,
  FormStatusAlert,
  PermissionsForm,
  FullPageSpinner,
  FullPageError,
} from "./components";
import { PageHeader, Stepper, Step } from "@justworkshr/milo-core";
import { Form, Formik } from "formik";
import { FORM_STEPS } from "./constants";
import { expandPermissions } from "../../helpers";
import { Page404 } from "pages/page-404";
import {
  FirmStatus,
  useGetCompanyDisplayQuery,
  useInviteFirmMutation,
} from "types/generated/operations";
import { FormPermissionsAlert } from "../../components";
import { datadogRum } from "@datadog/browser-rum";
import { useFirmsPageQueries } from "../FirmsPage/helpers";

const { formStepper, invitationPage } = styles;
const DEFAULT_PERMISSIONS =
  process.env.NODE_ENV == "test"
    ? ["pay_metrics", "employee_info_limited"]
    : [];

const InvitationPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const page = params.page ?? "contact";
  const formConfig = FORM_STEPS[page];
  const {
    data: companyData,
    loading: companyLoading,
    error: companyError,
  } = useGetCompanyDisplayQuery();
  const [createFirmInvitation] = useInviteFirmMutation();

  const { data: firmData, loading, error: firmError } = useFirmsPageQueries();

  if (!formConfig) {
    return <Page404 />;
  }

  if (companyLoading || loading) {
    return <FullPageSpinner />;
  }

  if (
    companyError ||
    !companyData ||
    firmError ||
    firmData?.firm?.status === FirmStatus.Active ||
    firmData?.firm?.status === FirmStatus.Pending
  ) {
    return <FullPageError />;
  }

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        firmName: "",
        permissions: DEFAULT_PERMISSIONS,
        terms: false,
      }}
      onSubmit={async (values, { setStatus }) => {
        try {
          await createFirmInvitation({
            variables: {
              companyUuid: companyData.authenticatedMember.company.uuid,
              newInvitation: {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                firmName: values.firmName,
                permissions: expandPermissions(values.permissions),
              },
            },
          });
          navigate("/firms", {
            state: {
              successMessage:
                "Great, we’ve sent the invite to your primary contact. They have 7 days to accept, but you can resend it if necessary.",
            },
          });
        } catch (err) {
          datadogRum.addError(err);
          setStatus("There was an error sending your invitation, try again.");
          scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
      }}
      validationSchema={formConfig.formSchema}
    >
      <>
        <FormStatusAlert />
        <FormPermissionsAlert />
        <div className={`${invitationPage} milo--grid`}>
          <PageHeader
            title="Invite Accounting Firm"
            children={formConfig.description}
            linkPrevious={
              <Link to="/invitation-center"> Back to Invitation Center</Link>
            }
          />
          <div className={formStepper}>
            <Stepper>
              <Step
                key={1}
                title="Primary contact information"
                status={formConfig.stepNumber === 1 ? "current" : "complete"}
                stepNumber={1}
              />
              <Step
                key={2}
                title="Permissions"
                status={formConfig.stepNumber === 2 ? "current" : "pending"}
                stepNumber={2}
              />
            </Stepper>
          </div>
          <Form>
            {
              {
                contact: <InviteeInfoForm />,
                permissions: <PermissionsForm />,
              }[page]
            }
          </Form>
        </div>
      </>
    </Formik>
  );
};

export default InvitationPage;
