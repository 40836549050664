import { VARIABLE_NAMES } from "../variable-constants";
import { SettingsConfig } from "../settings-type";

const defaultSettings: SettingsConfig = {
  [VARIABLE_NAMES.VARIABLE_TO_TEST_WITH]: "default",
  [VARIABLE_NAMES.FAVICON_COLOR]: "blue",
  [VARIABLE_NAMES.HEAP_KEY]: "4002302631",
  [VARIABLE_NAMES.JAZZHR_BASE_URL]: "https://app.env-94.resumatorlabs.net",
  [VARIABLE_NAMES.MESH_HOST]: "https://staging.justworks.com/gqlpl",
  [VARIABLE_NAMES.OKTA_CLIENT_ID]: "0oaelxle2gQgTQbf81d7", // non-prod client ID
  [VARIABLE_NAMES.OKTA_ISSUER]:
    "https://staging-login.justworks.com/oauth2/default", // non-prod issuer
};

export default defaultSettings;
