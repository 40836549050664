/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from "react";
import styles from "./PaymentDetailsPage.module.css";
import { PaymentDetailsForm } from "./components";
import { SeparationFlowContext } from "pages/team-management/contexts";
import { Button } from "@justworkshr/milo-core";
import { useNavigate, useParams } from "react-router-dom";
import {
  PaymentDetailsPageContext,
  PaymentDetailsPageContextProvider,
} from "./PaymentDetailsPageContextProvider";
import Divider from "pages/life-and-leave/enrollment/components/Divider/Divider";
import { SEPARATION_FLOW_COPY } from "../../SeparationFlow.constants";
import { MemberSeparation } from "pages/team-management/types";
import { useGetMemberSeparation } from "pages/team-management/hooks/useGetMemberSeparation";
import { ROUTES } from "pages/team-management/constants";
import { Loading } from "../../Loading/Loading";
import {
  completePaymentDetailsKeys,
  nonSeverancePaymentKeys,
} from "./PaymentDetailsPage.utils";
import { scrollToFirstError } from "../SeparationPay.formUtils";

const { FooterContainer } = styles;

const SubmitButton = () => {
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const { submitHandlers } = useContext(PaymentDetailsPageContext);
  const { paymentDetailsFormValues } = useContext(SeparationFlowContext);
  const navigate = useNavigate();
  const { memberUuid } = useParams();

  // Ensures whenever we come back to this page, we reset the submitted state
  // We use this state variable so that if we came back to this page to edit the values
  // (so it's already in a completely validated state), we wouldn't immediately proceed
  // to the next page without the user clicking the submit button again.
  useEffect(() => {
    setHasSubmitted(false);
  }, []);

  // Because we have no callback listeners to know when all the submit handlers for the various
  // payment forms have validated and executed successfully, we listen for when each one has
  // successfully added its form values to the context and then navigate to the next page.
  useEffect(() => {
    if (
      hasSubmitted &&
      paymentDetailsFormValues &&
      Object.keys(submitHandlers).length ===
        completePaymentDetailsKeys(paymentDetailsFormValues).length
    ) {
      navigate(`/team-management/separation-flow/${memberUuid}/review`);
    }
  }, [
    paymentDetailsFormValues,
    hasSubmitted,
    memberUuid,
    navigate,
    submitHandlers,
  ]);

  // When the user clicks the submit button, we need to call each payment's form
  // submit handler to validate them and add their values to the context.
  const submitAllPayments = () => {
    Object.values(submitHandlers).forEach((handler) => handler());
    setHasSubmitted(true);
    scrollToFirstError();
  };

  return (
    <Button
      data-testid={"submit-button"}
      type={"button"}
      onClick={submitAllPayments}
    >
      {SEPARATION_FLOW_COPY.next}
    </Button>
  );
};

const statusToPath = (status: string | undefined): string => {
  switch (status) {
    case "questionnaire_complete":
    case "questionnaire_skipped":
      return ROUTES.SEPARATION_PAY_ADDITIONAL_PAYMENTS;
    case "intake_complete":
      return ROUTES.SEPARATION_REASON_QUESTIONNAIRE;
    default:
      return ROUTES.SEPARATION_REASON_INITIAL_INTAKE;
  }
};

export const PaymentDetailsPage = () => {
  const navigate = useNavigate();
  const { memberUuid } = useParams();
  const {
    memberSeparation,
    loading: isMemberSeparationLoading,
    error: memberSeparationError,
  } = useGetMemberSeparation(memberUuid);

  const backUrl = `/team-management/separation-flow/${memberUuid}/${statusToPath(
    memberSeparation?.status
  )}`;

  const handleBack = () => {
    navigate(backUrl);
  };

  const {
    additionalPaymentsFormValues,
    paymentDetailsFormValues,
    setShowGlobalGenericError,
  } = useContext(SeparationFlowContext);

  useEffect(() => {
    if (memberSeparationError) {
      setShowGlobalGenericError?.(true);
    } else {
      setShowGlobalGenericError?.(false);
    }
  }, [memberSeparationError, setShowGlobalGenericError]);

  useEffect(() => {
    // If we've loaded the details around this member's separation and it turns out they
    // haven't completed the questionnaire or haven't filled out the initial payments form
    // in this session, we shouldn't be here and should navigate back to the proper point in the flow.
    if (
      !isMemberSeparationLoading &&
      !memberSeparationError &&
      (!additionalPaymentsFormValues ||
        !["questionnaire_complete", "questionnaire_skipped"].includes(
          memberSeparation?.status || ""
        ))
    ) {
      navigate(backUrl);
    }
  });

  return isMemberSeparationLoading ? (
    <Loading />
  ) : (
    <PaymentDetailsPageContextProvider>
      {paymentDetailsFormValues &&
        nonSeverancePaymentKeys(paymentDetailsFormValues).map((key, index) => (
          <div key={`PaymentDetailsFormSection-${key}`}>
            <PaymentDetailsForm paymentKey={Number(key)} key={key} />
            {index !== Object.keys(paymentDetailsFormValues).length - 1 && (
              <Divider size="3xl" />
            )}
          </div>
        ))}
      <div className={FooterContainer}>
        <Button variant="outlined" onClick={handleBack}>
          {SEPARATION_FLOW_COPY.previous}
        </Button>

        <SubmitButton />
      </div>
    </PaymentDetailsPageContextProvider>
  );
};
