import { ReactElement } from "react";

import styles from "../../InternationalSolutionsPage.module.css";
import { Accordion, AccordionPanel } from "@justworkshr/milo-core";

export const EORFAQ = (): ReactElement => {
  const { componentWrapper, mdHead } = styles;
  return (
    <div className={componentWrapper}>
      <div className={mdHead}>FAQs</div>
      <div className={componentWrapper}>
        <Accordion>
          <AccordionPanel isOpen title="What is an EOR?">
            An employer of record (EOR) company or provider helps companies hire
            employees directly with a local entity and put them on local
            payroll, and administers localized benefits on your behalf — all
            without the need to set up a local entity. The benefit of an EOR is
            that it simplifies international hiring, enabling companies to hire
            around the world without needing to navigate through local
          </AccordionPanel>
          <AccordionPanel isOpen title="How do Justworks’ EOR services work?">
            Our EOR functions like a strategic partner that has a team of local
            legal and HR experts who help make international hiring simpler.
            When companies partner with us, the company retains the ability to
            make the core hiring decisions, and manages employees' day-to-day
            activities, while we handle HR admin, payroll and core compliance
            topics.
          </AccordionPanel>
          <AccordionPanel
            isOpen
            title="What is the difference between EOR and International Contractor Payments?"
          >
            <div>
              Justworks' global EOR services enables companies to hire, pay, and
              provide benefits to employees based around the world.
            </div>
            <br></br>
            <div>
              Justworks' International Contractor Payments allows companies to
              pay international contractors in 40+ countries, in their preferred
              currency.
            </div>
          </AccordionPanel>
        </Accordion>
      </div>
    </div>
  );
};
