import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { buildWebPath } from "lib/resource-finder";
import { SUBPATHS } from "../constants";
import { getMassPayNavRoute } from "../utils";

const usePayStepNavigation = () => {
  const navigate = useNavigate();
  const params = useParams();
  const formType = params.formType || "";

  const navigateToStep = useCallback(
    (state: string) => {
      switch (state) {
        case "set-up":
          navigate(getMassPayNavRoute(formType, SUBPATHS.SET_UP));
          break;
        case "payment-details":
          navigate(getMassPayNavRoute(formType, SUBPATHS.PAYMENT_DETAILS));
          break;
        case "review":
          navigate(getMassPayNavRoute(formType, SUBPATHS.REVIEW));
          break;
        default:
          window.location.replace(buildWebPath("/"));
          break;
      }
    },
    [navigate, formType]
  );

  return { navigateToStep };
};

export default usePayStepNavigation;
