import { ReactElement, useContext } from "react";
import { Alert } from "@justworkshr/milo-core";
import { AdditionalPayContext } from "../../contexts/additionalPayContext";
import { ERROR_MESSAGE } from "./constants";

const PaymentsAlerts = (): ReactElement | null => {
  const additionalPayContext = useContext(AdditionalPayContext);
  const fetchError =
    additionalPayContext?.memberFetchError ||
    additionalPayContext?.disbursementDateError;

  let errorMessage = null;
  const validationError = additionalPayContext?.errorMessage;

  if (validationError) {
    errorMessage = validationError;
  } else if (fetchError) {
    errorMessage = ERROR_MESSAGE;
  }

  return errorMessage ? (
    <Alert color="destructive">{errorMessage}</Alert>
  ) : null;
};

export default PaymentsAlerts;
