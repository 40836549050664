import benefitsClasses from "pages/benefits/benefits.module.css";

const CostsTab = () => {
  return (
    <div>
      <h2 className={benefitsClasses.benefitsHeader}>
        Big savings for your small business
      </h2>

      <p className={benefitsClasses.benefitsParagraph}>
        When your company joins our MEP, your small business gets a big business
        rate and more competitive fees for your employees than you would on a
        single employer plan.
      </p>

      <p className={benefitsClasses.benefitsParagraph}>
        There are <strong>no start-up fees</strong> and{" "}
        <strong>no monthly fees</strong> for employers. If you currently offer a
        401(k), Empower will not charge fees to roll your plan into the MEP
        &ndash; though your previous provider may charge one.
      </p>

      <h3 className={benefitsClasses.benefitsHeader}>Employee cost</h3>

      <p className={benefitsClasses.benefitsParagraph}>
        Each employee is charged an annual fee of $86 &ndash; which is broken
        out into equal quarterly payments and debited directly from their
        account balance.
      </p>

      <p className={benefitsClasses.benefitsParagraph}>
        Account balances may be subject to additional fees depending on the
        investments selected and actions taken such as distributions and
        rollovers. Empower will provide a detailed disclosure of all possible
        fees.
      </p>
    </div>
  );
};

export default CostsTab;
