const WC_DOC_REQUEST_BASE_URL = "/document-request";

// Waiver Urls
const WAIVER_BASE_URL = "/waivers";
const WAIVER_EDIT_RELATIVE_URL = "/edit";
const WAIVER_REVIEW_RELATIVE_URL = "/review";
const WAIVER_SUBMIT_RELATIVE_URL = "/submit";
const WAIVER_EDIT_URL =
  WC_DOC_REQUEST_BASE_URL + WAIVER_BASE_URL + WAIVER_EDIT_RELATIVE_URL;
const WAIVER_REVIEW_URL =
  WC_DOC_REQUEST_BASE_URL + WAIVER_BASE_URL + WAIVER_REVIEW_RELATIVE_URL;
const WAIVER_SUBMIT_URL =
  WC_DOC_REQUEST_BASE_URL + WAIVER_BASE_URL + WAIVER_SUBMIT_RELATIVE_URL;

// COI Urls
const COI_BASE_URL = "/coi";
const COI_EDIT_RELATIVE_URL = "/edit";
const COI_REVIEW_RELATIVE_URL = "/review";
const COI_SUBMIT_RELATIVE_URL = "/submit";
const COI_EDIT_URL =
  WC_DOC_REQUEST_BASE_URL + COI_BASE_URL + COI_EDIT_RELATIVE_URL;
const COI_REVIEW_URL =
  WC_DOC_REQUEST_BASE_URL + COI_BASE_URL + COI_REVIEW_RELATIVE_URL;
const COI_SUBMIT_URL =
  WC_DOC_REQUEST_BASE_URL + COI_BASE_URL + COI_SUBMIT_RELATIVE_URL;

const ALL_STATES = [
  { description: "Select a state", value: "", disabled: true },
  { description: "Alabama", value: "AL" },
  { description: "Alaska", value: "AK" },
  { description: "Arizona", value: "AZ" },
  { description: "Arkansas", value: "AR" },
  { description: "California", value: "CA" },
  { description: "Colorado", value: "CO" },
  { description: "Connecticut", value: "CT" },
  { description: "Delaware", value: "DE" },
  { description: "Florida", value: "FL" },
  { description: "Georgia", value: "GA" },
  { description: "Hawaii", value: "HI" },
  { description: "Idaho", value: "ID" },
  { description: "Illinois", value: "IL" },
  { description: "Indiana", value: "IN" },
  { description: "Iowa", value: "IA" },
  { description: "Kansas", value: "KS" },
  { description: "Kentucky", value: "KY" },
  { description: "Louisiana", value: "LA" },
  { description: "Maine", value: "ME" },
  { description: "Maryland", value: "MD" },
  { description: "Massachusetts", value: "MA" },
  { description: "Michigan", value: "MI" },
  { description: "Minnesota", value: "MN" },
  { description: "Mississippi", value: "MS" },
  { description: "Missouri", value: "MO" },
  { description: "Montana", value: "MT" },
  { description: "Nebraska", value: "NE" },
  { description: "Nevada", value: "NV" },
  { description: "New Hampshire", value: "NH" },
  { description: "New Jersey", value: "NJ" },
  { description: "New Mexico", value: "NM" },
  { description: "New York", value: "NY" },
  { description: "North Carolina", value: "NC" },
  { description: "North Dakota", value: "ND" },
  { description: "Ohio", value: "OH" },
  { description: "Oklahoma", value: "OK" },
  { description: "Oregon", value: "OR" },
  { description: "Pennsylvania", value: "PA" },
  { description: "Rhode Island", value: "RI" },
  { description: "South Carolina", value: "SC" },
  { description: "South Dakota", value: "SD" },
  { description: "Tennessee", value: "TN" },
  { description: "Texas", value: "TX" },
  { description: "Utah", value: "UT" },
  { description: "Vermont", value: "VT" },
  { description: "Virginia", value: "VA" },
  { description: "Washington", value: "WA" },
  { description: "Washington, D.C.", value: "DC" },
  { description: "West Virginia", value: "WV" },
  { description: "Wisconsin", value: "WI" },
  { description: "Wyoming", value: "WY" },
];

const MONOPOLISTIC_STATES = ["ND", "OH", "WA", "WY"];

const VALID_CERTIFICATE_STATES = ALL_STATES.filter((state) => {
  return !MONOPOLISTIC_STATES.includes(state.value);
});

const VALID_MEMBER_TYPES = [
  "EMPLOYEE_FULL_TIME",
  "EMPLOYEE_PART_TIME",
  "OWNER_FULL_TIME",
  "OWNER",
  "PAID_INTERN",
];

enum PrioritySettings {
  STANDARD = "STANDARD",
  URGENT = "URGENT",
}

const initialAddressState = {
  street1: "",
  street2: "",
  city: "",
  state: "",
  postalCode: "",
};

const initialCertificateState = {
  // Address
  ...initialAddressState,
  // Cert Details
  certName: "",
  certAdditionalNames: "",
  additionalInformation: "",
};

const inititalWaiverState = {
  jobDuties: "",
  jobName: "",
  jobNumber: "",
  dateStart: new Date(),
  dateEnd: new Date(),
  priority: PrioritySettings.STANDARD,
  employees: [],
  ...initialCertificateState,
};

const initialAddressErrors = {
  street1: "",
  city: "",
  state: "",
  postalCode: "",
};

const initialCertificateErrors = {
  ...initialAddressErrors,
  certName: "",
};

const initialWaiverErrors = {
  jobDuties: "",
  dateStart: "",
  dateEnd: "",
  employees: "",
  ...initialCertificateErrors,
};

const initialWaiverLocation = {
  ...initialAddressState,
  errors: initialAddressErrors,
};

const addressErrorText = {
  street1: "You missed this one.",
  city: "You missed this one.",
  postalCode: "You missed this one.",
  state: "You missed this one.",
};

const certificateErrorText = {
  ...addressErrorText,
  certName: "You missed this one.",
};

const certificateSizes = {
  certName: 191,
  street1: 50,
  street2: 50,
  city: 50,
  certAdditionalNames: 191,
};

const waiverSizes = {
  ...certificateSizes,
  jobName: 191,
  jobNumber: 191,
};

const errorText = {
  ...addressErrorText,
  jobDuties: "You missed this one.",
  dateStart: "Please provide a job start date",
  dateEnd: "Please provide a job end date",
  employees: "Please provide at least one employee",
  certName: "You missed this one.",
};

const validationErrors = {
  postalCode: "Please provide a valid 5 digit zip code",
};

const useAdditionalMessage =
  "For more space, please use the Additional notes field.";

enum InsuranceDocument {
  Coi = "COI",
  Waiver = "WAIVER",
}

const ZIP_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export {
  ALL_STATES,
  COI_BASE_URL,
  COI_EDIT_RELATIVE_URL,
  COI_EDIT_URL,
  COI_REVIEW_RELATIVE_URL,
  COI_REVIEW_URL,
  COI_SUBMIT_RELATIVE_URL,
  COI_SUBMIT_URL,
  VALID_CERTIFICATE_STATES,
  VALID_MEMBER_TYPES,
  WAIVER_BASE_URL,
  WAIVER_EDIT_RELATIVE_URL,
  WAIVER_EDIT_URL,
  WAIVER_REVIEW_RELATIVE_URL,
  WAIVER_REVIEW_URL,
  WAIVER_SUBMIT_RELATIVE_URL,
  WAIVER_SUBMIT_URL,
  WC_DOC_REQUEST_BASE_URL,
  ZIP_REGEX,
  addressErrorText,
  certificateErrorText,
  certificateSizes,
  errorText,
  initialAddressErrors,
  initialAddressState,
  initialCertificateErrors,
  initialCertificateState,
  initialWaiverErrors,
  initialWaiverLocation,
  inititalWaiverState,
  InsuranceDocument,
  PrioritySettings,
  useAdditionalMessage,
  validationErrors,
  waiverSizes,
};
