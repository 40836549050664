import { GetConfigurationQuery } from "types/generated/operations";
import {
  ConfigurationStoreType,
  ConfigurationActionsUpdateConfigurationPayload,
} from "../constants";

export enum ConfigurationActionTypes {
  SET_CONFIGURATION = "SET_CONFIGURATION",
  UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS",
  UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION",
}

export type ConfigurationActions =
  | {
      type: ConfigurationActionTypes.SET_CONFIGURATION;
      payload: GetConfigurationQuery;
    }
  | {
      type: ConfigurationActionTypes.UPDATE_PERMISSIONS;
      payload: Partial<ConfigurationStoreType["permissions"]>;
    }
  | {
      type: ConfigurationActionTypes.UPDATE_CONFIGURATION;
      payload: ConfigurationActionsUpdateConfigurationPayload;
    };
