import { Alert, Button, Card, PageHeader } from "@justworkshr/milo-core";
import {
  ActionFooter,
  Form,
  FormField,
  RadioButton,
  RadioButtonGroup,
  Select,
  SelectOption,
  TextInput,
} from "@justworkshr/milo-form";
import { Formik, type FormikProps, type FormikValues } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { stdnum } from "stdnum";
import * as Yup from "yup";
import useOnboardingForm from "../../../hooks/useOnboardingForm";
import Layout from "../../../Layout";
import onboardingStyles from "../../../Onboarding.module.css";
import { NATIONALITIES } from "../../constants";
import styles from "../../ProfileInfo.module.css";
import { compactTaxId } from "../../taxIdUtils";
import { ProfileInfoFormTypeMX as ProfileInfoFormType } from "../../types";
import BaseFormFragment, {
  initialValues as baseInitialValues,
  schema as baseSchema,
} from "../base/BaseFormFragment";
import {
  mxCivilStatusOptions,
  mxPersonalIdRegEx,
  mxSexOptions,
} from "./constants.mx";
import IdentifierNumberFormFragment, {
  initialValues as identifierNumberInitialValues,
  schema as identifierNumberSchema,
} from "../identifier-input/IdentifierNumberFormFragment";
const { inputField } = styles;

const state_key = "profile-info";

const ProfileInfoMX: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    memberData: { workCountry },
    form: { submitting, showErrorAlert, errorAlertMessage, onSubmit },
    memberOnboarding: {
      stepNumber,
      redirecting,
      profileInfo,
      idVerificationStatus,
    },
  } = useOnboardingForm(state_key);

  const onFormSubmit = (values: FormikValues) => {
    // Compose date and identifier values.
    const profileInfoDateOfBirth =
      values.profileInfo.dateOfBirth.year +
      "-" +
      values.profileInfo.dateOfBirth.month +
      "-" +
      values.profileInfo.dateOfBirth.day;

    const profileInfoIdentifierNumber = compactTaxId(
      workCountry,
      values.profileInfo.identifierNumber
    );

    onSubmit({
      profileInfo: {
        ...values.profileInfo,
        dateOfBirth: profileInfoDateOfBirth,
        identifierNumber: profileInfoIdentifierNumber,
        confirmIdentifierNumber: undefined,
      },
    });
  };

  /* MX specific fields */
  const schemaMX = Yup.object({
    nationality: Yup.string().required("This field is required."),
    mxSex: Yup.string().required("This field is required."),
    mxCivilStatus: Yup.string().required("This field is required."),
    mxPersonalId: Yup.string()
      .required("This field is required.")
      .matches(mxPersonalIdRegEx, t("Must be a valid Personal ID.")),
    mxNationalId: Yup.string()
      .required("This field is required.")
      .test(
        "match-curp-regex",
        t("Must be a valid National ID."),
        (value) => stdnum.MX.curp.validate(value).isValid
      ),
    mxWorkPermitId: Yup.string()
      .test("check-nationality", "This field is required.", (value, ctx) =>
        ctx.parent.nationality === "MX" ? true : Boolean(value?.length)
      )
      .length(13, t("Must be a valid Work Permit ID.")),
    mxSocialSecurityNumber: Yup.string()
      .required("This field is required. ")
      .length(11, t("Must be a valid Social Security Number.")),
    mxHasInfonavitLoan: Yup.boolean().required("This field is required."),
    mxHasFonacotLoan: Yup.boolean().required("This field is required. "),
  });
  const formSchema = Yup.object().shape({
    profileInfo: schemaMX
      .concat(baseSchema(t))
      .concat(identifierNumberSchema(t, "MX")),
  });
  type SchemaType = Yup.InferType<typeof schemaMX>;
  type FormType = {
    profileInfo: SchemaType;
  };
  const initialValuesMX: FormType = {
    profileInfo: {
      nationality: "",
      mxSex: "",
      mxCivilStatus: "",
      mxPersonalId: "",
      mxNationalId: "",
      mxWorkPermitId: "",
      mxSocialSecurityNumber: "",
      mxHasInfonavitLoan: false,
      mxHasFonacotLoan: false,
    },
  };

  /* END MX specific fields */

  const getInitialValues = (): ProfileInfoFormType => {
    const initialValues = {
      profileInfo: {
        ...baseInitialValues.profileInfo,
        ...identifierNumberInitialValues.profileInfo,
      },
    };

    const defaultInitialValues = profileInfo || initialValues;

    return {
      profileInfo: {
        ...initialValuesMX.profileInfo,
        ...defaultInitialValues.profileInfo,
      },
    };
  };

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {errorAlertMessage ||
            t("An error occurred while submitting your profile information.")}
        </Alert>

        <PageHeader title="Complete your profile" />

        <Formik
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validationSchema={formSchema}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            setFieldValue,
            touched,
            values,
          }: FormikProps<ProfileInfoFormType>) => {
            const handleBooleanChange = (
              e: React.ChangeEvent<HTMLInputElement>
            ) => {
              setFieldValue(e.target.name, e.target.value === "true");
            };

            const fieldErrorHandler = (
              name: keyof ProfileInfoFormType["profileInfo"]
            ) => {
              if (!errors.profileInfo || !touched.profileInfo) return;
              if (errors.profileInfo[name] && touched.profileInfo[name]) {
                return errors.profileInfo[name]?.toString();
              }
            };

            return (
              <Form onSubmit={handleSubmit}>
                <Card title={t("Personal information")}>
                  <BaseFormFragment />

                  {/* <!-- MX specific fields --> */}
                  <div className={inputField}>
                    <FormField
                      required
                      name="profileInfo.nationality"
                      label={t("Nationality")}
                      error={fieldErrorHandler("nationality")}
                    >
                      <Select
                        name="profileInfo.nationality"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={String(values.profileInfo.nationality)}
                        placeholder={
                          values.profileInfo.nationality.length
                            ? undefined
                            : t("Select Nationality")
                        }
                      >
                        {NATIONALITIES.map((option) => (
                          <SelectOption value={option.value} key={option.value}>
                            {option.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="profileInfo.mxSex"
                      label={t("Sex")}
                      error={fieldErrorHandler("mxSex")}
                    >
                      <Select
                        name="profileInfo.mxSex"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={String(values.profileInfo.mxSex)}
                        placeholder={
                          values.profileInfo.mxSex.length
                            ? undefined
                            : t("Select Sex")
                        }
                      >
                        {mxSexOptions.map((option) => (
                          <SelectOption value={option.value} key={option.value}>
                            {option.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="profileInfo.mxCivilStatus"
                      label={t("Civil Status")}
                      error={fieldErrorHandler("mxCivilStatus")}
                    >
                      <Select
                        name="profileInfo.mxCivilStatus"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={String(values.profileInfo.mxCivilStatus)}
                        placeholder={
                          values.profileInfo.mxCivilStatus.length
                            ? undefined
                            : t("Select Civil Status")
                        }
                      >
                        {mxCivilStatusOptions.map((option) => (
                          <SelectOption value={option.value} key={option.value}>
                            {option.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="profileInfo.mxPersonalId"
                      label={t("ID Number (INE/Passport)")}
                      error={fieldErrorHandler("mxPersonalId")}
                    >
                      <TextInput
                        name="profileInfo.mxPersonalId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.profileInfo.mxPersonalId)}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="profileInfo.mxNationalId"
                      label={t("National ID Number (CURP)")}
                      error={fieldErrorHandler("mxNationalId")}
                    >
                      <TextInput
                        name="profileInfo.mxNationalId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.profileInfo.mxNationalId)}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required={values.profileInfo.nationality !== "MX"}
                      name="profileInfo.mxWorkPermitId"
                      label={t("Work Permit ID")}
                      error={fieldErrorHandler("mxWorkPermitId")}
                    >
                      <TextInput
                        name="profileInfo.mxWorkPermitId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.profileInfo.mxWorkPermitId)}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="profileInfo.mxSocialSecurityNumber"
                      label={t("Social Security Number")}
                      error={fieldErrorHandler("mxSocialSecurityNumber")}
                    >
                      <TextInput
                        name="profileInfo.mxSocialSecurityNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(
                          values.profileInfo.mxSocialSecurityNumber
                        )}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="profileInfo.mxHasInfonavitLoan"
                      label={t("Do you have an Infonavit loan?")}
                      error={fieldErrorHandler("mxHasInfonavitLoan")}
                    >
                      <RadioButtonGroup
                        name="profileInfo.mxHasInfonavitLoan"
                        onChange={handleBooleanChange}
                        onBlur={handleBlur}
                        value={String(values.profileInfo.mxHasInfonavitLoan)}
                      >
                        <RadioButton value="true" label={t("Yes")} />
                        <RadioButton value="false" label={t("No")} />
                      </RadioButtonGroup>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="profileInfo.mxHasFonacotLoan"
                      label="Do you have a Fonacot loan?"
                      error={fieldErrorHandler("mxHasFonacotLoan")}
                    >
                      <RadioButtonGroup
                        name="profileInfo.mxHasFonacotLoan"
                        onChange={handleBooleanChange}
                        onBlur={handleBlur}
                        value={String(values.profileInfo.mxHasFonacotLoan)}
                      >
                        <RadioButton value="true" label={t("Yes")} />
                        <RadioButton value="false" label={t("No")} />
                      </RadioButtonGroup>
                    </FormField>
                  </div>

                  {/* <!-- END MX specific fields --> */}

                  <IdentifierNumberFormFragment workCountry="MX" />
                </Card>

                <ActionFooter
                  className={onboardingStyles.footer}
                  actions={[
                    <Button
                      color="brand"
                      key="submit-btn"
                      loading={submitting}
                      type="submit"
                    >
                      {t("Save & continue")}
                    </Button>,
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </Layout>
  );
};

export default ProfileInfoMX;
