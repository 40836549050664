import React from "react";
import { PermissionsError } from "pages/expenses/components";
import { useGlobalConfigurationStore } from "pages/expenses/store";

interface RequestDetailPagePermissionsWrapperProps {
  children: JSX.Element;
}

export const RequestDetailPagePermissionsWrapper: React.FC<
  RequestDetailPagePermissionsWrapperProps
> = ({ children }) => {
  const {
    permissions: { isAdmin, isReporter, isManager },
  } = useGlobalConfigurationStore();

  if (isAdmin || isReporter || isManager) {
    return children;
  }

  return <PermissionsError />;
};
