import benefitsClasses from "pages/benefits/benefits.module.css";
import { Divider } from "@justworkshr/milo-core";

const EligibilityTab = () => {
  return (
    <div>
      <h2 className={benefitsClasses.benefitsHeader}>Eligibility</h2>

      <p className={benefitsClasses.benefitsParagraph}>
        All full-time and part-time employees, interns, paid, and unpaid owners
        are eligible. There are no minimum service requirements. Any
        contractors, vendors, or employees (regardless of working class) under a
        labor union are ineligible. Your employer can set additional eligibility
        requirements.
      </p>

      <h2 className={benefitsClasses.benefitsHeader}>Enrollment timing</h2>

      <p className={benefitsClasses.benefitsParagraph}>
        Your company will set up guidelines on when you can participate in the
        plan. But, you can enroll 30 days before that official eligibility date.
        Reach out to your company admin for specific dates.
      </p>

      <Divider />

      <h3>Cost</h3>

      <p className={benefitsClasses.benefitsParagraph}>
        Each employee is charged an annual fee of $86-which is broken out into
        equal quarterly payments and debited directly from their account
        balance.
      </p>

      <p className={benefitsClasses.benefitsParagraph}>
        Account balances may be subject to additional fees depending on the
        investments selected and actions taken such as distributions and
        rollovers. Empower will provide a detailed disclosure of all possible
        fees.
      </p>
    </div>
  );
};

export default EligibilityTab;
