import { ReactElement } from "react";
import { PageHeader } from "@justworkshr/milo-core";
import { buildWebPath } from "lib/resource-finder";
import styles from "./invitation.module.css";
import { Formik } from "formik";
import {
  invitationFormInitialValues,
  invitationFormSchema,
} from "./InvitationForm/invitation-form-utils";
import InvitationForm from "./InvitationForm/InvitationForm";

const { invitation, subheader } = styles;

export default function Invitation(): ReactElement {
  const onSubmit = (allValues: typeof invitationFormInitialValues) => {
    console.log(allValues);
  };

  return (
    <div className={`${invitation} milo--grid`}>
      <div>
        <PageHeader title="Add a person or vendor" />
      </div>

      <div className={subheader}>
        <p>
          Please add your employee's or vendor's information below. You will be
          able to send invitations to them at a later date.
        </p>
        <p>
          Expanding outside of the U.S.?{" "}
          <a href={buildWebPath("/international/contractor/invite")}>
            Add international contractors
          </a>
        </p>
      </div>

      <Formik
        initialValues={invitationFormInitialValues}
        validationSchema={invitationFormSchema}
        onSubmit={onSubmit}
      >
        {InvitationForm}
      </Formik>
    </div>
  );
}
