import { ReactElement } from "react";
import { Formik, Form } from "formik";
import { FormikFormField } from "lib/formik/FormikFormField";
import { PageData, WitnessInformation, ClaimFormProps } from "../../../types";
import { RadioButton, RadioButtonGroup } from "@justworkshr/milo-form";
import FormContinue from "../../shared/form-continue";
import styles from "./witnesses.module.css";
import SharedForm from "../../shared/shared-form-outline";
import WitnessesFormComponent from "./components/witnesses-form";
import { witnessesSchema } from "../../validation-schema";

const {
  witnessesForm,
  header,
  witnessesWrapper,
  formRow,
  formHr,
  formContinue,
} = styles;

export default function WitnessesForm({
  handleErrorMessage,
  pageData,
  setPageData,
  submitDraft,
  exitForm,
  goBack,
  goForward,
  currentPageNumber,
  name,
}: ClaimFormProps & {
  setPageData: (e: WitnessInformation) => void;
  submitDraft: (section: WitnessInformation, total: PageData) => void;
}): ReactElement {
  const formData = pageData.witnessInformation;
  return (
    <Formik
      initialValues={formData}
      onSubmit={(values) => {
        submitDraft(values, pageData);
        setPageData(values);
        goForward();
      }}
      validationSchema={witnessesSchema}
    >
      {({ values }) => (
        <SharedForm
          handleErrorMessage={handleErrorMessage}
          currentPageNumber={currentPageNumber}
          name={name}
          key="exit-witnesses"
          exitFn={() => {
            submitDraft(values, pageData);
            exitForm();
          }}
        >
          <Form data-testid="witnesses-form">
            <div className={header}>
              <h1>Witnesses</h1>
            </div>
            <div className={witnessesWrapper}>
              <div className={witnessesForm}>
                <div className={formRow}>
                  <FormikFormField
                    label="Were there witnesses to the incident?"
                    name="witnessesExist"
                    required
                  >
                    <RadioButtonGroup
                      name="witnessesExists"
                      value={values.witnessesExist}
                    >
                      <RadioButton label="Yes" value="Yes" />
                      <RadioButton label="No" value="No" />
                    </RadioButtonGroup>
                  </FormikFormField>
                </div>

                <WitnessesFormComponent formData={formData} values={values} />

                <hr className={formHr} />

                <div className={formContinue}>
                  <FormContinue
                    continueKey="continue-witnesses"
                    backKey="back-witnesses"
                    backFn={() => {
                      setPageData(values);
                      goBack();
                    }}
                    alignment="space-between"
                  />
                </div>
              </div>
            </div>
          </Form>
        </SharedForm>
      )}
    </Formik>
  );
}
