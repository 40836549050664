/* istanbul ignore file */

import { Button, IconButton } from "@justworkshr/milo-core";
import React, { useEffect, useRef, useState } from "react";
import { RequestDetailReceipt } from "types/Expenses";
import { Drawer } from "../Drawer";
import styles from "./ReceiptDrawer.module.css";

interface ReceiptDrawerProps {
  receipt: RequestDetailReceipt;
  onReceiptChange: (receipt: RequestDetailReceipt | null) => void;
}

const {
  ReceiptDrawerImage,
  ReceiptDrawerWrapper,
  ReceiptDrawerButtons,
  ReceiptDrawerGrabbing,
  ReceiptDrawerImageWrapper,
} = styles;

const scalesAsc = [0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.5];
const scalesDesc = [3.5, 3, 2.75, 2.5, 2.25, 2, 1.75, 1.5, 1.25, 1, 0.75];

export const ReceiptDrawer: React.FC<ReceiptDrawerProps> = React.memo(
  ({ receipt, onReceiptChange }) => {
    const receiptPreview = useRef<HTMLIFrameElement | HTMLImageElement>(null);
    const receiptPreviewContainer = useRef<HTMLDivElement>(null);
    const isPDF = receipt?.filename?.search(/.pdf/i) !== -1;
    const [grabbing, setGrabbing] = useState(false);
    const [objectCoordinates, setObjectCoordinates] = useState({
      x: 0,
      y: 0,
      scale: 1,
    });
    const [grabCoordinates, setGrabCoordinates] = useState({
      x: 0,
      y: 0,
      scale: 1,
    });

    const limitNewCoord = ({
      scale,
      newValue,
      objectDimension,
      containerDimension,
    }: {
      scale: number;
      newValue: number;
      objectDimension: number;
      containerDimension: number;
    }) => {
      const viewMargin = 20;
      const scaledObjectDimension = objectDimension * scale;
      const scaledObjectMargin = (objectDimension - scaledObjectDimension) / 2;
      const minValue = viewMargin - scaledObjectDimension - scaledObjectMargin;
      const maxValue = containerDimension - viewMargin - scaledObjectMargin;

      return Math.min(Math.max(newValue, minValue), maxValue);
    };

    const createCoordinateLimiter = (
      objectRef: React.RefObject<HTMLIFrameElement | HTMLImageElement>,
      containerRef: React.RefObject<HTMLDivElement>
    ) => {
      return ({ x, y, scale }: { x: number; y: number; scale: number }) => {
        if (!objectRef.current || !containerRef.current) return;

        return {
          x: limitNewCoord({
            scale,
            newValue: x,
            objectDimension: objectRef.current.offsetWidth,
            containerDimension: containerRef.current.clientWidth,
          }),
          y: limitNewCoord({
            scale,
            newValue: y,
            objectDimension: objectRef.current.offsetHeight,
            containerDimension: containerRef.current.clientHeight,
          }),
          scale,
        };
      };
    };

    const handleZoomOut = () => {
      const newScale =
        scalesDesc.find((value) => value < objectCoordinates.scale) ||
        scalesAsc[0];

      setObjectCoordinates({
        ...objectCoordinates,
        scale: newScale,
      });
    };

    const handleZoomIn = () => {
      const newScale =
        scalesAsc.find((value) => value > objectCoordinates.scale) ||
        scalesDesc[0];

      setObjectCoordinates({
        ...objectCoordinates,
        scale: newScale,
      });
    };

    const handleZoomToFit = () => {
      if (!receiptPreviewContainer.current || !receiptPreview.current) return;

      setObjectCoordinates({
        x:
          (receiptPreviewContainer.current.offsetWidth -
            receiptPreview.current.offsetWidth) /
          2,
        y:
          (receiptPreviewContainer.current.offsetHeight -
            receiptPreview.current.offsetHeight) /
          2,
        scale: 1,
      });
    };

    const handleMouseDown = (event: React.MouseEvent) => {
      event.preventDefault();

      setGrabCoordinates({
        x: event.clientX - objectCoordinates.x,
        y: event.clientY - objectCoordinates.y,
        scale: objectCoordinates.scale,
      });

      setGrabbing(true);
    };

    const handleMouseUp = () => {
      if (!grabbing) return;

      setGrabbing(false);
    };

    const handleMouseMove = (event: React.MouseEvent) => {
      event.persist();

      if (!grabbing) return;

      setObjectCoordinates((prevState) => {
        const coordinateLimiter = createCoordinateLimiter(
          receiptPreview,
          receiptPreviewContainer
        );

        const updatedProps = coordinateLimiter({
          ...prevState,
          x: event.clientX - grabCoordinates.x,
          y: event.clientY - grabCoordinates.y,
        });

        if (updatedProps) {
          return updatedProps;
        }

        return prevState;
      });
    };

    useEffect(() => {
      if (receipt?.url) {
        handleZoomToFit();
      }
    }, [receipt]);

    const drawerContent = (
      <div ref={receiptPreviewContainer} className={ReceiptDrawerImageWrapper}>
        {isPDF ? (
          <iframe
            title={receipt?.filename}
            itemType="application/pdf"
            className={ReceiptDrawerImage}
            src={`${receipt?.url}#toolbar=0&border=0&zoom=Fit`}
            style={{
              transform: `scale(${objectCoordinates.scale})`,
            }}
            ref={receiptPreview as React.RefObject<HTMLIFrameElement>}
          />
        ) : (
          <img
            src={receipt?.url}
            alt={receipt?.filename}
            onMouseUp={handleMouseUp}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            className={
              grabbing
                ? `${ReceiptDrawerImage} ${ReceiptDrawerGrabbing}`
                : ReceiptDrawerImage
            }
            ref={receiptPreview as React.RefObject<HTMLImageElement>}
            style={{
              transform: `translate(${objectCoordinates.x}px, ${objectCoordinates.y}px) scale(${objectCoordinates.scale})`,
            }}
          />
        )}
      </div>
    );

    const drawerButtons = (
      <div className={ReceiptDrawerButtons}>
        <Button
          size="sm"
          variant="outlined"
          leftIcon="download"
          onClick={() => {
            window.open(receipt.url, "_blank", "noopener,noreferrer");
          }}
        >
          Download
        </Button>
        <IconButton
          size="sm"
          variant="ghost"
          title="Zoom out"
          iconName="minus"
          onClick={handleZoomOut}
        />
        <Button size="md" variant="outlined" onClick={handleZoomToFit}>
          {`${objectCoordinates.scale * 100 - 50}%`}
        </Button>
        <IconButton
          size="sm"
          title="Zoom in"
          iconName="plus"
          variant="ghost"
          onClick={handleZoomIn}
        />
        <Button size="sm" variant="outlined" onClick={handleZoomToFit}>
          Zoom to fit
        </Button>
      </div>
    );

    return (
      <Drawer
        title="Receipt"
        isOpen={!!receipt}
        onClose={() => onReceiptChange(null)}
      >
        <div className={ReceiptDrawerWrapper}>
          {drawerContent}
          {drawerButtons}
        </div>
      </Drawer>
    );
  }
);
