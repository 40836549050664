import { ReactElement, useContext, useState } from "react";
import styles from "./Comission.module.css";
import { FieldConfig, SetupFormGenerator } from "../../SetupFormGenerator";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { ComissionPayloadType } from "../types";
import useCountryConfigFetcher from "../hooks/useCountryConfigFetcher";
import { EmployeePayType } from "pages/employer-of-record/payments/constants";

export const Comission = (): ReactElement => {
  const additionalPayContext = useContext(AdditionalPayContext);

  const [paymentSettingsFields, setPaymentSettingsFields] = useState<
    FieldConfig<ComissionPayloadType>[]
  >([]);
  const [taxFormFields, setTaxFormFields] = useState<
    FieldConfig<ComissionPayloadType>[]
  >([]);

  useCountryConfigFetcher<ComissionPayloadType>(
    EmployeePayType.COMISSION,
    additionalPayContext,
    setPaymentSettingsFields,
    setTaxFormFields
  );

  return (
    <>
      {taxFormFields.length > 0 && (
        <div className={styles.card}>
          <SetupFormGenerator<ComissionPayloadType>
            title={`${EmployeePayType.COMISSION} details`}
            fields={taxFormFields}
            formikFieldName="taxes"
          />
        </div>
      )}

      {paymentSettingsFields.length > 0 && (
        <div className={styles.card}>
          <SetupFormGenerator<ComissionPayloadType>
            title="Payment settings"
            fields={paymentSettingsFields}
            formikFieldName="paymentSettings"
          />
        </div>
      )}
    </>
  );
};
