import { Box } from "@justworkshr/milo-core";
import benefitsClasses from "pages/benefits/benefits.module.css";
import styles from "./CurrentTab.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";

interface YtdContributions {
  additional: number;
  slavic: number;
  manual: number;
}

interface DeferralRates {
  salaryAftertaxAmount?: number | null;
  salaryAftertaxPercent?: number | null;
  salaryPretaxAmount?: number | null;
  salaryPretaxPercent?: number | null;
  supplementalAftertaxAmount?: number | null;
  supplementalAftertaxPercent?: number | null;
  supplementalPretaxAmount?: number | null;
  supplementalPretaxPercent?: number | null;
}

interface CurrentTabProps {
  ytdContributions?: YtdContributions;
  currentRates?: DeferralRates;
}

const formatCentsToDollars = (cents: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(cents / 100.0);
};

const CurrentTab = ({ ytdContributions, currentRates }: CurrentTabProps) => {
  const currentYear = new Date().getFullYear();

  return (
    <div>
      <h2 className={benefitsClasses.benefitsHeader}>
        Your current account info
      </h2>
      <p className={benefitsClasses.benefitsParagraph}>
        These are your current contribution types and contribution rates at
        Slavic401k and will be applied to your new account at Empower. Once the
        blackout period ends, we’ll add the latest information from Empower.
      </p>
      <div className={styles.currentInfoBox}>
        <Box
          padding={"lg"}
          border={{
            borderColor: "neutralSubtle",
            borderWidth: "sm",
            borderRadius: "md",
          }}
        >
          <div className={styles.infoTitle}>
            {currentYear} year-to-date (YTD) contributions
          </div>
          <p>
            Contributions will continue throughout the blackout period. Once the
            blackout period ends, we’ll update with the latest YTD information
            from Empower.
          </p>
          <div className={styles.table}>
            <div className={styles.tableHeaders}>Account</div>
            <div className={styles.tableHeaders}>Your contributions</div>
            <div className={styles.tableData}>Pre-Justworks</div>
            <div className={styles.tableData}>
              <div className={styles.additionalCell}>
                <div>
                  {formatCentsToDollars(ytdContributions?.additional || 0)}
                </div>
                <div className={styles.editLink}>
                  <a
                    href="/cf/benefits/setup/401k/employee/additional-contributions"
                    style={{ display: "flex" }}
                  >
                    <SystemIcon iconName="edit" color="brand" />
                    Edit
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.tableData}>External provider</div>
            <div className={styles.tableData}>
              {formatCentsToDollars(ytdContributions?.manual || 0)}
            </div>
            <div className={styles.tableData}>Slavic401k</div>
            <div className={styles.tableData}>
              {formatCentsToDollars(ytdContributions?.slavic || 0)}
            </div>
          </div>
        </Box>
      </div>
      <div className={styles.currentInfoBox}>
        <Box
          padding={"lg"}
          border={{
            borderColor: "neutralSubtle",
            borderWidth: "sm",
            borderRadius: "md",
          }}
        >
          <div className={styles.infoTitle}>Contribution rates</div>
          <p>
            Once the blackout period ends, you’ll be able to make changes to
            your contribution rates in Empower.
          </p>
          <div className={styles.table}>
            <div className={styles.tableHeaders}>Payment type</div>
            <div className={styles.tableHeaders}>Rate (per paycheck)</div>
            <div className={styles.tableData}>Salary</div>
            <div className={styles.tableData}>
              {currentRates?.salaryPretaxAmount ? (
                <div>
                  {formatCentsToDollars(currentRates.salaryPretaxAmount)}{" "}
                  pre-tax
                </div>
              ) : null}
              {currentRates?.salaryPretaxPercent ? (
                <div>{currentRates.salaryPretaxPercent}% pre-tax</div>
              ) : null}
              {currentRates?.salaryAftertaxAmount ? (
                <div>
                  {formatCentsToDollars(currentRates.salaryAftertaxAmount)}{" "}
                  after-tax
                </div>
              ) : null}
              {currentRates?.salaryAftertaxPercent ? (
                <div>{currentRates.salaryAftertaxPercent}% after-tax</div>
              ) : null}
            </div>
            <div className={styles.tableData}>
              Bonuses, commissions, and other{" "}
              <span style={{ whiteSpace: "nowrap" }}>supplemental pay</span>
            </div>
            <div className={styles.tableData}>
              {currentRates?.supplementalPretaxAmount ? (
                <div>
                  {formatCentsToDollars(currentRates.supplementalPretaxAmount)}{" "}
                  pre-tax
                </div>
              ) : null}
              {currentRates?.supplementalPretaxPercent ? (
                <div>{currentRates.supplementalPretaxPercent}% pre-tax</div>
              ) : null}
              {currentRates?.supplementalAftertaxAmount ? (
                <div>
                  {formatCentsToDollars(
                    currentRates.supplementalAftertaxAmount
                  )}{" "}
                  after-tax
                </div>
              ) : null}
              {currentRates?.supplementalAftertaxPercent ? (
                <div>{currentRates.supplementalAftertaxPercent}% after-tax</div>
              ) : null}
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default CurrentTab;
