import { useState } from "react";
import { IconButton } from "@justworkshr/milo-core";
import { PermissionCheckbox } from "../PermissionCheckbox";
import styles from "./Permission.module.css";

const { permissionContainer, permissionContent, capabilitiesList } = styles;

interface PermissionProps {
  permissionKey: string;
  label: string;
  capabilities: string[];
  grantedBy: string[];
}

const Permission = ({
  permissionKey,
  label,
  capabilities,
  grantedBy,
}: PermissionProps) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div data-testid={permissionKey}>
      <div className={permissionContainer}>
        <PermissionCheckbox
          grantedBy={grantedBy}
          value={permissionKey}
          name="permissions"
          label={label}
        />
        <IconButton
          title={expanded ? "Collapse" : "Expand"}
          iconName={expanded ? "chevron-up" : "chevron-down"}
          color="inverse"
          onClick={() => setExpanded((expanded) => !expanded)}
        />
      </div>
      {expanded && (
        <div className={permissionContent}>
          Able to:
          <ul className={capabilitiesList}>
            {capabilities.map((capability, index) => (
              <li key={index} data-testid="permission-capability">
                {capability}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Permission;
