import React, { ComponentProps, ReactNode } from "react";
import styles from "./ImageCard.module.css";
import { Box, BoxBorder } from "@justworkshr/milo-core";
import clsx, { clsxArg } from "lib/styling-utils/clsx";

const { imageCardHeader, contentWrapper, childrenWrapper, imageWrapper } =
  styles;

type ImageCardProps = {
  className?: clsxArg;
  image?: string | ReactNode;
  header: string;
} & ComponentProps<typeof Box>;

const boxBorder: BoxBorder = {
  borderWidth: "sm",
  borderColor: "neutralSubtle",
  borderRadius: "md",
};

const ImageCard = ({ header, image, children, ...props }: ImageCardProps) => (
  // Styles for this compoonent can be overriden by targeting the static classNames via external CSS selectors
  <div className="ImageCard">
    <Box padding="2xl" border={boxBorder} {...props}>
      <div className={clsx("ImageCardContentWrapper", contentWrapper)}>
        <h3 className={imageCardHeader}>{header}</h3>
        <div
          className={clsx("ImageCardContentChildrenWrapper", childrenWrapper)}
        >
          {children}
        </div>
        <div className={clsx("ImageCardContentImageWrapper", imageWrapper)}>
          {typeof image == "string" ? <img src={image} /> : image}
        </div>
      </div>
    </Box>
  </div>
);

export default ImageCard;
