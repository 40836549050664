import { useGetEoRSupportedCountriesQuery } from "../../../types/generated/operations";
import type { ApolloError } from "@apollo/client";
import { createContext, FC, ReactNode } from "react";

export type SupportedCountriesData = Record<string, SupportedCountriesDataType>;

interface SupportedCountriesContextType {
  loading: boolean;
  error?: ApolloError;
  supportedCountriesData: SupportedCountriesData;
  getCountryNameByCode(code: string | undefined | null): string;
  getSubdivisionNameByCode(
    countryCode: string | undefined | null,
    subdivisionCode: string | undefined | null
  ): string;
  getCurrencySymbol(code: string | undefined | null): string;
  getSupportedProvinces(code: string | undefined | null): SubdivisionType[];
  getDefaultCurrency(code: string | undefined | null): string;
}

const initialContext: SupportedCountriesContextType = {
  loading: true,
  supportedCountriesData: {},
  getCountryNameByCode: () => "",
  getSubdivisionNameByCode: () => "",
  getCurrencySymbol: () => "",
  getSupportedProvinces: () => [],
  getDefaultCurrency: () => "",
};

export const SupportedCountriesContext =
  createContext<SupportedCountriesContextType>(initialContext);

interface SupportedCountriesContextProviderProps {
  children: ReactNode;
}

export type CurrencyType = {
  currencyCode: string;
  subunitToUnit: number;
  symbol: string;
};

type SubdivisionType = {
  code: string;
  name: string;
};

export type SupportedCountriesDataType = {
  code: string;
  commonName?: string;
  currencies?: CurrencyType[];
  emojiFlag?: string;
  subdivisions?: SubdivisionType[];
};

export const SupportedCountriesContextProvider: FC<
  SupportedCountriesContextProviderProps
> = ({ children }) => {
  const { loading, error, data } = useGetEoRSupportedCountriesQuery();

  const supportedCountriesData: { [key: string]: SupportedCountriesDataType } =
    Object.fromEntries(
      data?.eorSupportedCountries.map((country) => [
        country.code,
        { ...country.config, code: country.code },
      ]) || []
    );

  const getCountryNameByCode = (code: string | null | undefined): string => {
    if (code == null) return "Unknown Country";
    return supportedCountriesData[code || ""]?.commonName || "";
  };

  const getCurrencySymbol = (code: string | null | undefined): string => {
    if (code == null) return "";
    const currencies = supportedCountriesData[code]?.currencies;
    return currencies != null ? currencies[0].symbol : "";
  };

  const getSupportedProvinces = (
    code: string | null | undefined
  ): SubdivisionType[] => {
    if (code == null) return [];
    const subdivisions = supportedCountriesData[code]?.subdivisions;
    return subdivisions || [];
  };

  const getSubdivisionNameByCode = (
    countryCode: string | null | undefined,
    subdivisionCode: string | null | undefined
  ): string => {
    if (countryCode == null || subdivisionCode == null) return "";
    const subdivisionName = getSupportedProvinces(countryCode)?.find(
      (value) => value.code === subdivisionCode
    )?.name;
    return subdivisionName || "";
  };

  // right now each country only has 1 supported currency, so we will just take the first one
  const getDefaultCurrency = (code: string | null | undefined) => {
    if (code == null) return "";
    const currencies = supportedCountriesData[code]?.currencies;
    return currencies != undefined && currencies.length > 0
      ? currencies[0].currencyCode
      : "";
  };

  return (
    <SupportedCountriesContext.Provider
      value={{
        loading,
        error,
        supportedCountriesData,
        getCountryNameByCode,
        getSubdivisionNameByCode,
        getCurrencySymbol,
        getSupportedProvinces,
        getDefaultCurrency,
      }}
    >
      {children}
    </SupportedCountriesContext.Provider>
  );
};
