import { Alert, PageHeader, Spinner } from "@justworkshr/milo-core";
import React, { ReactElement } from "react";
import { Link, useParams } from "react-router-dom";
import styles from "./partner-description-page.module.css";
import { Page404 } from "../../../page-404";
import { integrationConfigs } from "pages/pmt/integrations/configs/config";
import { IntegrationsConfig } from "types/IntegrationConfig";
import usePdpPageData from "./hooks/usePdpPageData";
import ResourceCard from "./components/ResourceCard/ResourceCard";
import InstanceDetailsPanel from "./components/InstanceDetailsPanel/InstanceDetailsPanel";
import InfoTabs from "./components/InfoTabs/InfoTabs";
import {
  findAuthError,
  findExpiringIntegration,
  findAutoDisabledIntegration,
  parseConnectionStatusFromString,
  pdpPermissionText,
} from "./utils";
import { useResetScrollOnRouteChange } from "../hooks/useResetScrollOnRouteChange";
import { getIntegrationAlertByKey, useAlert } from "../hooks/useAlert";
import { FLAG_NAMES, flagIsEnabled } from "lib/environment";
import {
  companyAdminsWithPermissionCheck,
  listAdminPermissions,
  userPermissionCheck,
} from "../integration-marketplace/utils";
import { PERMISSION_NAME_MAP } from "../integration-marketplace/constants";
import { useClearableLocationState } from "../hooks/useClearableLocationState";
import { isPmtIntegrationDisabled } from "../utils";
import ErrorPage from "../partner-setup-page/components/ErrorPage/ErrorPage";
import { usePdpActionParam } from "./hooks/usePdpActionParam";

const {
  partnerDescriptionPageBody,
  loadingSpinner,
  headerBanner,
  leftColumn,
  rightColumn,
  heroImage,
  errorBanner,
} = styles;

export type pdpProps = {
  integration: ReturnType<typeof usePdpPageData>;
  config: IntegrationsConfig;
};

export const PdpPageRenderer: React.FC<pdpProps> = ({
  integration,
  config,
}) => {
  const pdpConfig = config?.copy.pdp;

  useResetScrollOnRouteChange();

  const { originalValue: navStateAlert } =
    useClearableLocationState<string>("alert");
  const { alertProps, setAlert } = useAlert(
    config?.copy?.marketplaceCard?.integrationName,
    getIntegrationAlertByKey(navStateAlert)
  );

  const pmtIntegrationsEnabled = flagIsEnabled(
    FLAG_NAMES.PMT_INTEGRATIONS,
    integration?.companyUuid
  );
  const integrationEnabled = !isPmtIntegrationDisabled(
    integration?.integrationName,
    integration?.companyUuid
  );
  const requiredPermissions =
    config?.copy?.marketplaceCard?.requiredPermissions;
  const hasPermission: boolean = userPermissionCheck(
    requiredPermissions,
    integration?.memberPermissions
  );
  const adminsWithPermission = companyAdminsWithPermissionCheck(
    requiredPermissions,
    integration?.companyAdmins
  );
  const firstAdminName =
    adminsWithPermission.length > 0
      ? adminsWithPermission[0]
      : "an admin at your organization";
  const noticeAdminPermission = listAdminPermissions(
    requiredPermissions,
    PERMISSION_NAME_MAP
  );
  const permissionNotice = pdpPermissionText(
    noticeAdminPermission,
    firstAdminName
  );

  const hasAuthError =
    !integration?.loading && findAuthError(integration?.instancesByKey);

  const integrationAutoDisabled =
    !integration?.loading &&
    findAutoDisabledIntegration(integration?.instancesByKey);

  const { actionLoading, action } = usePdpActionParam(
    window.location.href,
    config?.copy?.marketplaceCard?.slug,
    setAlert
  );

  const refreshFromEmail = action == "keep_alive";

  const integrationExpiring: boolean | undefined =
    !refreshFromEmail &&
    !integration?.loading &&
    findExpiringIntegration(integration?.instancesByKey);

  return (
    <div id={"PartnerDescriptionPage"} className={partnerDescriptionPageBody}>
      <Alert {...alertProps} />

      {(integration?.loading || actionLoading) && (
        <div className={loadingSpinner}>
          <Spinner aria-label="loading" />
        </div>
      )}
      {!integration?.loading &&
        (integration?.error === "Solution Error" ||
          integration?.error === "Integration Error") && (
          <div className="milo--grid">
            <div className={headerBanner}>
              <PageHeader
                title={`${config?.copy.marketplaceCard.integrationName}`}
                linkPrevious={
                  <Link to={`/integration-marketplace`}>
                    Back to integrations
                  </Link>
                }
              >
                {pdpConfig?.headerDescription}
              </PageHeader>
            </div>
            <div className={errorBanner}>
              <ErrorPage error={integration.error} />
            </div>
          </div>
        )}
      {!integration?.loading && integration?.error == "Error" && <Page404 />}
      {(!pmtIntegrationsEnabled || !integrationEnabled) &&
        !integration?.loading &&
        !integration?.error && <Page404 />}
      {pmtIntegrationsEnabled &&
        integrationEnabled &&
        !integration?.loading &&
        !actionLoading &&
        !integration?.error && (
          <div className="milo--grid">
            <div className={headerBanner}>
              <PageHeader
                title={integration?.integrationName || "Partner Name"}
                linkPrevious={
                  <Link to={`/integration-marketplace`}>
                    Back to integrations
                  </Link>
                }
              >
                {pdpConfig?.headerDescription}
              </PageHeader>
            </div>
            <div className={leftColumn}>
              <img
                className={heroImage}
                src={pdpConfig?.heroImageUrl}
                alt="partner hero image"
              />
              <InfoTabs
                tabsConfig={pdpConfig?.infoTabsConfig}
                tabsContent={pdpConfig?.infoTabsContent}
              />
            </div>
            <div className={rightColumn}>
              <InstanceDetailsPanel
                config={pdpConfig}
                solutionSlug={integration?.solution?.data?.name || ""}
                partnerName={integration?.integrationName}
                companyName={integration?.companyName}
                connectionStatus={parseConnectionStatusFromString(
                  integration?.status
                )}
                lastActivityDate={integration?.lastActivityDate}
                setAlertData={setAlert}
                hasPermission={hasPermission}
                permissionNotice={permissionNotice}
                hasAuthError={hasAuthError}
                integrationAutoDisabled={integrationAutoDisabled}
                integrationExpiring={integrationExpiring}
              />
              {pdpConfig?.resourceCard?.links.length !== 0 && (
                <ResourceCard
                  header={"Resources"}
                  links={pdpConfig?.resourceCard.links}
                />
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default function PartnerDescriptionPage(): ReactElement {
  const { solutionSlug = "" } = useParams();
  const config = integrationConfigs[solutionSlug];
  const integrationData = usePdpPageData(
    solutionSlug,
    config?.copy.setup.instanceKeys
  );

  return <PdpPageRenderer integration={integrationData} config={config} />;
}
