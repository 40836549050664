export enum BonusFrequency {
  ONCE = "once",
  MORETHANONCE = "more than once",
}

export enum BonusType {
  DISCRETIONARY = "discretionary",
  NONDISCRETIONARY = "non-discretionary",
}

export enum NonDiscretionaryType {
  PERFORMANCE = "performance",
  TIME = "time",
}
