import styles from "./ExpenseDialog.module.css";
import { Dialog, ButtonProps, ButtonType } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import React from "react";

const {
  ExpenseDialogWrapper,
  ExpenseDialogContainer,
  ExpenseDialogActionFooter,
} = styles;

interface ExpenseDialogProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  actions:
    | React.ReactElement<ButtonProps<ButtonType>>
    | Array<React.ReactElement<ButtonProps<ButtonType>>>;
}

export const ExpenseDialog: React.FC<ExpenseDialogProps> = ({
  isOpen,
  actions,
  onClose,
  children,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={ExpenseDialogWrapper}>
      <Dialog showCloseButton onClose={onClose} isOpen={isOpen}>
        <div className={ExpenseDialogContainer}>{children}</div>
        <ActionFooter className={ExpenseDialogActionFooter} actions={actions} />
      </Dialog>
    </div>
  );
};
