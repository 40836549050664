import { HIDDEN } from "pages/expenses/constants";
import { useGetExpenseTypeQuery } from "types/generated/operations";

export const useCategoriesExpenseType = () => {
  const { loading, data } = useGetExpenseTypeQuery();
  const categories = data?.expenseManagement?.categories || [];

  const isCategoryWithNonReimbursableExpenses = categories?.some(
    (category) => category.expenseType !== HIDDEN
  );

  return {
    isCategoryWithNonReimbursableExpenses,
    isCategoriesExpenseTypeLoading: loading,
  };
};
