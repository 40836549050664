import styles from "./InviteeInfoForm.module.css";
import { Button } from "@justworkshr/milo-core";
import { FormField, TextInput, ActionFooter } from "@justworkshr/milo-form";
import { Link, useNavigate } from "react-router-dom";

import { useFormikContext } from "formik";
import { InvitationFormType } from "../../types";
import { FORM_STEPS } from "../../constants";

const { inviteeInfoForm } = styles;

const InviteeInfoForm = () => {
  const { values, touched, errors, handleBlur, handleChange, setTouched } =
    useFormikContext<InvitationFormType>();
  const navigate = useNavigate();
  const formConfig = FORM_STEPS.contact;

  const handleInviteeInfoFormSubmit = () => {
    setTouched({
      ...touched,
      ...formConfig.requiredFields,
    }).then((errors) => {
      if (JSON.stringify(errors) === "{}") navigate("permissions");
    });
  };

  return (
    <>
      <div className={`${inviteeInfoForm} milo--grid`}>
        <h2>Personal Information </h2>
        <FormField
          name="firstName"
          label="First name"
          required={true}
          error={touched.firstName ? errors.firstName : ""}
        >
          <TextInput
            name="firstName"
            onChange={handleChange}
            value={values.firstName}
            required={true}
            onBlur={handleBlur}
          />
        </FormField>
        <FormField
          name="lastName"
          label="Last name"
          required={true}
          error={touched.lastName ? errors.lastName : ""}
        >
          <TextInput
            name="lastName"
            onChange={handleChange}
            value={values.lastName}
            required={true}
            onBlur={handleBlur}
          />
        </FormField>
        <FormField
          name="email"
          label="Email"
          required={true}
          error={touched.email ? errors.email : ""}
        >
          <TextInput
            name="email"
            onChange={handleChange}
            value={values.email}
            required={true}
            onBlur={handleBlur}
          />
        </FormField>
        <FormField
          name="firmName"
          label="Firm name"
          required={true}
          error={touched.firmName ? errors.firmName : ""}
        >
          <TextInput
            name="firmName"
            onChange={handleChange}
            value={values.firmName}
            required={true}
            onBlur={handleBlur}
          />
        </FormField>
      </div>
      <ActionFooter
        actions={[
          <Button
            variant="outlined"
            as={Link}
            to={"/invitation-center"}
            key="cancel-btn"
          >
            Cancel
          </Button>,
          <Button
            data-testid="submit-button"
            onClick={handleInviteeInfoFormSubmit}
            key="continue-btn"
          >
            Continue
          </Button>,
        ]}
      />
    </>
  );
};

export default InviteeInfoForm;
