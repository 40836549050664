import { FormField, Label, TextInput } from "@justworkshr/milo-form";
import { FormikProps } from "formik";
import IntlPhoneNumberInput from "pages/employer-of-record/IntlPhoneNumberInput";
import { EditableProfileInfoFormType } from "../types";
import CollapsibleCard from "./CollapsibleCard";
import { useTranslation } from "react-i18next";
import styles from "./EditContactInformation.module.css";
import { Divider } from "@justworkshr/milo-core";
import PhoneNumbersFragment from "pages/employer-of-record/onboarding/contact-info/PhoneNumbersFragment";
import EmailsFragment from "./EmailsFragment";

type Props = {
  open: boolean;
  toggleOpen: () => void;
  workCountry: string;
} & FormikProps<EditableProfileInfoFormType>;

export default function EditContactInformation(props: Props) {
  const { t } = useTranslation();

  const { workCountry, open, toggleOpen } = props;
  const { values, touched, errors, handleChange, handleBlur } = props;

  return (
    <CollapsibleCard
      open={open}
      title="Contact Information"
      onClick={toggleOpen}
    >
      <div className={styles.title}>Contact</div>

      <div>
        <Label>Phone number</Label>
        <PhoneNumbersFragment workCountry={workCountry} />
        <EmailsFragment />
      </div>

      <Divider />

      <div className={styles.title}>Emergency contact</div>
      <FormField
        name="contactInfo.emergencyContact.firstName"
        label={t("First Name")}
        required={true}
        error={
          touched?.contactInfo?.emergencyContact?.firstName
            ? errors?.contactInfo?.emergencyContact?.firstName
            : ""
        }
      >
        <TextInput
          name="contactInfo.emergencyContact.firstName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.contactInfo.emergencyContact.firstName}
        />
      </FormField>

      <FormField
        name="contactInfo.emergencyContact.lastName"
        label={t("Last Name")}
        required={true}
        error={
          touched?.contactInfo?.emergencyContact?.lastName
            ? errors?.contactInfo?.emergencyContact?.lastName
            : ""
        }
      >
        <TextInput
          name="contactInfo.emergencyContact.lastName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.contactInfo.emergencyContact.lastName}
        />
      </FormField>

      <FormField
        name="contactInfo.emergencyContact.relationship"
        label={t("Relationship")}
        required={true}
        error={
          touched?.contactInfo?.emergencyContact?.relationship
            ? errors?.contactInfo?.emergencyContact?.relationship
            : ""
        }
      >
        <TextInput
          name="contactInfo.emergencyContact.relationship"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.contactInfo.emergencyContact.relationship}
        />
      </FormField>
      <FormField
        label={t("Phone Number")}
        required={true}
        error={
          touched?.contactInfo?.emergencyContact?.phoneNumber
            ? errors?.contactInfo?.emergencyContact?.phoneNumber
            : ""
        }
      >
        <IntlPhoneNumberInput
          id="emergencyContact.phoneNumber"
          name="contactInfo.emergencyContact.phoneNumber"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.contactInfo.emergencyContact.phoneNumber}
          countryCode={workCountry}
        />
      </FormField>
    </CollapsibleCard>
  );
}
