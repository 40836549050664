import { useState } from "react";
import { INTERNATIONAL_UPLOAD_DOCUMENT } from "pages/international-contractor-profile-page/constants";
import { fetchClient } from "@justworkshr/fe-utility-kit";
import { buildClockworkWebPath } from "lib/resource-finder";
import {
  FileRequest,
  FileResponse,
} from "pages/international-contractor-profile-page/types/documents";

interface UseDocumentUploadProps {
  maxSize: number;
  accept: string[];
  acceptErrorMessage: string;
  maxSizeErrorMessage: string;
  onSuccess?: (file: FileResponse) => void;
}

export const useDocumentUpload = ({
  accept,
  maxSize,
  onSuccess,
  acceptErrorMessage,
  maxSizeErrorMessage,
}: UseDocumentUploadProps) => {
  const [errors, setErrors] = useState<{ [fileName: string]: string[] }>({});
  const [loading, setLoading] = useState(false);

  const resetErrors = () => setErrors({});

  const handleError = (file: File, message: string) => {
    const fileName = file.name;

    setErrors((currentErrors) => {
      if (currentErrors[fileName]) {
        return {
          ...currentErrors,
          [fileName]: Array.from(
            new Set([...currentErrors[fileName], message])
          ),
        };
      }

      return { ...currentErrors, [fileName]: [message] };
    });
  };

  const dismissError = (filename: string) => {
    setErrors((currentErrors) => {
      const updatedErrors = { ...currentErrors };
      delete updatedErrors[filename];

      return updatedErrors;
    });
  };

  const validateFile = (file: File) => {
    let hasValidationError = false;

    if (!accept.includes(file.type)) {
      hasValidationError = true;
      handleError(file, acceptErrorMessage);
    }

    if (file.size > maxSize) {
      hasValidationError = true;
      handleError(file, maxSizeErrorMessage);
    }

    return hasValidationError;
  };

  const uploadDocument = async (payload: FileRequest) => {
    try {
      const hasValidationErrors = validateFile(payload.file);

      if (hasValidationErrors) {
        return;
      }

      const formData = new FormData();
      formData.append("member_id", payload.member_id);
      formData.append("file", payload.file);
      formData.append("category", payload.category);
      formData.append("name", payload.name);
      formData.append(
        "signature_required",
        payload.signature_required.toString()
      );
      formData.append(
        "reminders_set",
        (payload.reminders_set || false).toString()
      );
      formData.append(
        "document_member_assignments",
        JSON.stringify(payload.document_member_assignments)
      );

      setLoading(true);

      const request = await fetchClient.post(
        buildClockworkWebPath(INTERNATIONAL_UPLOAD_DOCUMENT),
        {
          body: formData,
          headers: {
            Accept: "multipart/form-data",
          },
          cache: "no-cache",
        }
      );

      const response = await request.json();

      setLoading(false);
      onSuccess?.(response);
    } catch {
      setLoading(false);
      handleError(
        payload.file,
        "There was an error with your upload. Please try again."
      );
    }
  };

  return {
    errors,
    loading,
    resetErrors,
    dismissError,
    uploadDocument,
  };
};
