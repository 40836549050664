import { ComissionPayloadType } from "../../../types";
import { ComissionFrequency } from "../../constants";

export const COMISSION_INITIAL_VALUES: ComissionPayloadType = {
  taxes: {
    cadence: ComissionFrequency.REGULAR,
  },
  paymentSettings: {
    payPeriodId: "",
    disbursementDate: "",
    notes: "",
  },
};
