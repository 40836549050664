import { Alert } from "@justworkshr/milo-core";
import { useFormikContext } from "formik";

const FormStatusAlert = () => {
  const { status, setStatus } = useFormikContext();

  const handleDismiss = () => {
    setStatus(undefined);
  };

  return (
    <Alert
      onDismiss={handleDismiss}
      color="destructive"
      visible={!!status}
      dismissible
    >
      {status}
    </Alert>
  );
};

export default FormStatusAlert;
