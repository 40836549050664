import { SetExpenseLibraryPayload } from "../constants";

export enum ExpenseLibraryActionTypes {
  SET_EXPENSE_LIBRARY = "SET_EXPENSE_LIBRARY",
  UPDATE_EXCLUDED_LIBRARY_EXPENSES = "UPDATE_EXCLUDED_LIBRARY_EXPENSES",
}

export type ExpenseLibraryActions =
  | {
      type: ExpenseLibraryActionTypes.SET_EXPENSE_LIBRARY;
      payload: SetExpenseLibraryPayload;
    }
  | {
      type: ExpenseLibraryActionTypes.UPDATE_EXCLUDED_LIBRARY_EXPENSES;
      payload: string[];
    };
