import styles from "./stdTabsContentTemplate.module.css";
import faq_styles from "./faqTabContent.module.css";
import Divider from "../components/Divider/Divider";
import Notice from "../components/Notice/Notice";
import { NoncontributoryOptionsTable } from "../components/NoncontributoryOptionsTable/NoncontributoryOptionsTable";
import {
  OVERVIEW_CONTENT,
  OPTIONS_AND_COSTS_CONTENT,
  FAQ_CONTENT,
} from "../constants/stdTabContent";
import { LINKS } from "../constants/pdpGeneral";
import { Link } from "react-router-dom";

const {
  headerText,
  subHeader,
  contentSection,
  infoTabsListItem,
  summaryText,
  detailText,
  headerTextOptionsAndCosts,
  contentAndTableDivision,
} = styles;

const { faqContentSection, faqDetailText } = faq_styles;

export const OverviewTab: React.FC = () => {
  return (
    <div>
      <div className={contentSection}>
        <div className={headerText}>{OVERVIEW_CONTENT.header.title}</div>
        <div className={subHeader}>
          {OVERVIEW_CONTENT.header.headerInfo}
          <br></br>
          <br></br>
          {OVERVIEW_CONTENT.header.headerSubInfo}
        </div>
      </div>
      <Divider />
      <section>
        <div className={contentSection}>
          {Object.values(OVERVIEW_CONTENT.body).map(
            (item: { title: string; info: string; id: number }) => (
              <div key={item.id} className={infoTabsListItem}>
                <div className={summaryText}>{item.title}</div>
                <div className={detailText}>{item.info}</div>
              </div>
            )
          )}
        </div>
      </section>
    </div>
  );
};

export const OptionsAndCostsTab: React.FC = () => {
  return (
    <div>
      <div className={contentSection}>
        <div className={headerText}>
          {OPTIONS_AND_COSTS_CONTENT.header.title}
        </div>
        <div className={subHeader}>
          {OPTIONS_AND_COSTS_CONTENT.header.headerInfo}
        </div>
      </div>
      <Divider />
      <section>
        <div className={contentSection}>
          <div className={headerTextOptionsAndCosts}>
            {OPTIONS_AND_COSTS_CONTENT.bodyTitle}
          </div>

          {Object.values(OPTIONS_AND_COSTS_CONTENT.body).map(
            (item: { subtitle: string; info: string; id: number }) => (
              <div key={item.id} className={infoTabsListItem}>
                <div className={detailText}>
                  <strong>{item.subtitle}</strong>
                  <br></br>
                  {item.info}
                </div>
              </div>
            )
          )}
        </div>
        <div className={contentAndTableDivision}></div>
      </section>
      <NoncontributoryOptionsTable
        costTableInfo={OPTIONS_AND_COSTS_CONTENT.costTableInfo}
      />
      <Notice isStd={true} content={OPTIONS_AND_COSTS_CONTENT.noticeContent} />
    </div>
  );
};

export const FaqTab: React.FC = () => {
  return (
    <div>
      <section>
        <div className={faqContentSection}>
          {Object.values(FAQ_CONTENT).map(
            (item: { title: string; info: string; id: number }) => (
              <div key={item.id} className={infoTabsListItem}>
                <div className={faqDetailText}>
                  <strong>{item.title}</strong>
                  <br></br>
                  {item.info}
                  {item.id === 5 && (
                    <>
                      {" "}
                      Check out our{" "}
                      <Link
                        to={LINKS.helpCenter}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="milo--link-secondary"
                      >
                        help center
                      </Link>{" "}
                      for more information on specific state's guidelines.{" "}
                    </>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </section>
    </div>
  );
};
