import { ReactElement, useContext } from "react";
import styles from "./PaymentDetailsTable.module.css";
import { useStepperContext } from "pages/employer-of-record/payments/contexts/stepperFormHandler";
import {
  Table,
  TableBody,
  TableHeader,
} from "pages/employer-of-record/components";
import {
  getActiveCurrency,
  hasWorkPeriod,
  mergeMembersAndPayments,
} from "pages/employer-of-record/payments/utils";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { Avatar } from "@justworkshr/milo-core";
import { Link } from "react-router-dom";
import { buildClockworkWebPath } from "lib/resource-finder";
import { toCurrencyFormat } from "pages/employer-of-record/utils";
import {
  getExchangeRate,
  getEstimatedValue,
} from "pages/employer-of-record/payments/utils";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";

const PaymentDetailsTable = (): ReactElement | null => {
  const { formData } = useStepperContext();
  const additionalPayContext = useContext(AdditionalPayContext);

  const { supportedCountriesData } = useContext(SupportedCountriesContext);
  const countryInfo =
    supportedCountriesData[additionalPayContext?.selectedCountry || ""];
  const currencyData = getActiveCurrency(countryInfo.currencies);
  const currency = currencyData?.currencyCode || "";

  const includeWorkPeriod = hasWorkPeriod(formData.setup);
  const payPerMember = mergeMembersAndPayments(
    additionalPayContext?.membersData || [],
    formData.paymentDetails
  );

  const exchangeRate = getExchangeRate(
    currency,
    additionalPayContext?.exchangeRates
  );

  const tableHeaders = [
    {
      key: "PAYEE",
      label: "Payee",
      isSortable: false,
    },
    {
      key: "GROSSPAY",
      label: "Gross pay",
      isSortable: false,
    },
    ...(includeWorkPeriod
      ? [
          {
            key: "WORKPERIOD",
            label: "Work period",
            isSortable: false,
          },
        ]
      : []),
  ];

  if (!payPerMember) {
    return null;
  }

  return (
    <Table>
      <TableHeader headers={tableHeaders} />

      <TableBody>
        {payPerMember
          .filter((payment) => payment.grossAmount)
          .map((payment, index) => (
            <tr key={index}>
              <td className={styles.td}>
                {" "}
                <div className={styles.nameWrapper}>
                  <Avatar name={payment.fullName} size="sm"></Avatar>
                  <div className={styles.breakSpace}>
                    <Link
                      to={buildClockworkWebPath(
                        `members/${payment.memberUuid}`
                      )}
                      target="_blank"
                    >
                      {payment.fullName}
                    </Link>
                    <div className={styles.countryName}>
                      {countryInfo?.commonName}
                    </div>
                  </div>
                </div>
              </td>
              <td className={styles.td}>
                {toCurrencyFormat(
                  Number(payment.grossAmount),
                  currency || "",
                  true
                )}
                {exchangeRate && (
                  <div className={styles.estimatedValue}>
                    {getEstimatedValue(payment.grossAmount, exchangeRate)}
                  </div>
                )}
              </td>
              {includeWorkPeriod && (
                <>
                  <td className={`${styles.td}  ${styles.breakSpace} `}>
                    {payment.workPeriodStart}-{payment.workPeriodEnd}
                  </td>
                </>
              )}
            </tr>
          ))}
      </TableBody>
    </Table>
  );
};

export default PaymentDetailsTable;
