import React from "react";
import styles from "../../ExpenseDetailsPage.module.css";
import { isFormFieldHidden } from "../../ExpenseDetailsPage.utils";
import { ExpenseCategory } from "types/Expenses";
import {
  FormField,
  RadioButton,
  RadioButtonGroup,
} from "@justworkshr/milo-form";
import {
  REIMBURSABLE_VALUE,
  NON_REIMBURSABLE_VALUE,
} from "./ExpenseType.constants";

const { ExpenseDetailsPageRadioWrapper } = styles;

const EXPENSE_TYPE_KEY = "expenseType";

interface ExpenseTypeProps {
  expenseType: {
    value: string;
    error: string;
  };
  handleFormValue: ({
    key,
    value,
  }: {
    key: typeof EXPENSE_TYPE_KEY;
    value: string;
  }) => void;
  selectedCategory: ExpenseCategory | null;
}

export const ExpenseType: React.FC<ExpenseTypeProps> = React.memo(
  ({ selectedCategory, handleFormValue, expenseType }) => {
    if (
      !selectedCategory ||
      isFormFieldHidden(EXPENSE_TYPE_KEY, selectedCategory)
    ) {
      return null;
    }

    const handleExpenseTypeChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      handleFormValue({
        key: EXPENSE_TYPE_KEY,
        value: event.target.value,
      });
    };

    return (
      <FormField required label="Expense type">
        <div className={ExpenseDetailsPageRadioWrapper}>
          <RadioButtonGroup
            name="Expense type"
            value={expenseType.value}
            onChange={handleExpenseTypeChange}
          >
            <RadioButton value="false" label={NON_REIMBURSABLE_VALUE} />
            <RadioButton value="true" label={REIMBURSABLE_VALUE} />
          </RadioButtonGroup>
        </div>
      </FormField>
    );
  }
);
