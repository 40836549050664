import React from "react";
import styles from "./ExistingDocuments.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";

type DocumentListType = {
  addFileDocumentMapping: (fileName: string, documentId: string) => void;
  getDocumentIdByFileName: (fileName: string) => string | undefined;
  removeFileDocumentMapping: (fileName: string) => void;
  clearFileDocumentMapping: () => void;
  fileDocumentMap: Record<string, string>;
  addExistingDocumentMap: (fileName: string, documentId: string) => void;
  removeExistingDocumentMapping: (fileName: string) => void;
  existingDocumentMap: Record<string, string>;
};

interface ExistingDocumentsProps {
  documentList: DocumentListType;
  removeDocument: (title: string) => void;
}

const { fileInfo, fileName, closeButton, fileListDisplay } = styles;

export const ExistingDocuments: React.FC<ExistingDocumentsProps> = ({
  documentList,
  removeDocument,
}) => {
  if (Object.keys(documentList.existingDocumentMap).length === 0) {
    return null;
  }

  return (
    <div id="TeamManagementExistingDocument">
      <div>
        <div className={fileListDisplay}>
          {Object.entries(documentList.existingDocumentMap).map(
            ([key, value]) => (
              <div key={key} className={fileInfo}>
                <span className={fileName}>{key}</span>

                <button
                  aria-label={`remove ${value}`}
                  type="button"
                  className={closeButton}
                  onClick={() => removeDocument(key)}
                >
                  <SystemIcon iconName="close" size="small" color={"neutral"} />
                </button>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};
