import { SystemIcon } from "@justworkshr/milo-icons";
import styles from "./side-menu-button.module.css";
import { usePanelsControlContext } from "app-frame/header/context/panels-control-context-provider";
import { PanelsControlActionType } from "app-frame/header/context/panels-control-context-provider/types";

const { sideMenuButton } = styles;

function SideMenuButton() {
  const { dispatch } = usePanelsControlContext();

  return (
    <button
      className={sideMenuButton}
      onClick={() =>
        dispatch({ type: PanelsControlActionType.OPEN_SIDE_MENU_DRAWER })
      }
      data-testid="side-menu-button"
    >
      <SystemIcon color="neutral" size="large" iconName="menu" />
    </button>
  );
}

export default SideMenuButton;
