import { EMPLOYER_OF_RECORD_PATH } from "pages";
import { ONBOARDING_PATH } from "../routes";

export const PROFILE_INFO_PATH = "/profile-info";
export const CONTACT_INFO_PATH = "/contact-info";
export const ID_VERIFICATION_PATH = "/id-verification";
export const PAYMENT_INFO_PATH = "/payment-info";
export const TAX_INFO_PATH = "/tax-info";

export { default as ProfileInfo } from "./profile-info";
export { default as ContactInfo } from "./contact-info";
export { default as IdVerification } from "./id-verification";
export { default as PaymentInfo } from "./payment-info";
export { default as TaxInfo } from "./tax-info";

export const buildEoROnboardingPath = (path: string) => {
  return `/focus${EMPLOYER_OF_RECORD_PATH}${ONBOARDING_PATH}${path}`;
};
