import React from "react";
import { Link } from "react-router-dom";
import { CheckboxInput } from "@justworkshr/milo-form";
import { formatDate, getDollarsFromCents } from "pages/expenses/utils";
import {
  renderPayDate,
  renderHistoryStatusBadge,
} from "./RequestHistoryTableRow.utils";
import { ReimbursementRequestForHistory } from "../../RequestHistoryPage.types";

interface RequestHistoryTableRowProps {
  request: ReimbursementRequestForHistory;
  permissions: {
    isAdmin: boolean;
    isManager: boolean;
    isAdminAndManager: boolean;
    isReporter: boolean;
  };
  isCheckboxActive: boolean;
  handleCheckboxChange: (uuid: string) => void;
  showNonReimbursableColumn?: boolean;
}

export const RequestHistoryTableRow: React.FC<RequestHistoryTableRowProps> = ({
  request,
  permissions,
  isCheckboxActive,
  handleCheckboxChange,
  showNonReimbursableColumn = false,
}) => {
  const hasMultipleExpenses = request.expenses.length > 1;

  return (
    <tr>
      {permissions.isReporter && (
        <td>
          <CheckboxInput
            name={request.name}
            value={request.uuid}
            checked={isCheckboxActive}
            onChange={() => handleCheckboxChange(request.uuid)}
            data-testid="request-checkbox"
          />
        </td>
      )}
      <td>{request.member.friendlyFullName}</td>
      <td>
        <Link
          to={`/expenses/${request.uuid}/review`}
          data-testid="expense-request-link"
        >
          {request.name}
        </Link>
        {hasMultipleExpenses && ` (${request.expenses.length})`}
      </td>
      <td>{formatDate(request.submittedAt)}</td>
      {showNonReimbursableColumn && (
        <td>{getDollarsFromCents(request.totalNonReimbursableAmount)}</td>
      )}
      <td>{getDollarsFromCents(request.totalReimbursableAmount)}</td>
      <td data-testid="expense-request-status">
        {renderHistoryStatusBadge(request, permissions)}
      </td>
      <td>{renderPayDate(request)}</td>
    </tr>
  );
};

export default RequestHistoryTableRow;
