import { ReactElement } from "react";
import { Formik, Form } from "formik";

import { TextInput } from "@justworkshr/milo-form";
import { FormikFormField } from "lib/formik/FormikFormField";

import styles from "./employee-confirmation.module.css";
import { EmployeeConfirmDetails } from "./employee-confirmation-details";

import FormContinue from "../../shared/form-continue";
import SharedForm from "../../shared/shared-form-outline";
import { EmployeeConfirmation, PageData, ClaimFormProps } from "../../../types";

import { validationSchema } from "./utils";

const { mainTitle, btnSection, employerPolicyNumber, secondSection } = styles;

export default function EmployeeConfirmationForm({
  submitDraft,
  setPageData,
  pageData,
  goForward,
  goBack,
  exitForm,
  currentPageNumber,
  name,
}: ClaimFormProps & {
  setPageData: (e: EmployeeConfirmation) => void;
  submitDraft: (section: EmployeeConfirmation, total: PageData) => void;
}): ReactElement {
  const { personalInformation, injuryDate } = pageData;

  return (
    <Formik
      initialValues={personalInformation}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        submitDraft(values, pageData);
        setPageData(values);
        goForward();
      }}
    >
      {({ values }) => (
        <SharedForm
          currentPageNumber={currentPageNumber}
          name={name}
          key="exit-contact-information"
          exitFn={() => {
            submitDraft(values, pageData);
            exitForm();
          }}
        >
          <>
            <h2 className={mainTitle}>Contact Information</h2>
            <EmployeeConfirmDetails name={name} injuryDate={injuryDate} />
            <Form data-testid="employee-confirmation-form">
              <div className={secondSection}>
                <div className={employerPolicyNumber}>
                  <FormikFormField
                    label="Employee personal email address"
                    name="homeEmail"
                    message="This is the email address where the injured employee will receive updates about their claim. Try to avoid using a work email to ensure correspondence is received."
                    required
                  >
                    <TextInput name="homeEmail" />
                  </FormikFormField>
                </div>
                <div className={employerPolicyNumber}>
                  <FormikFormField
                    label="Employer policy number"
                    name="policyNumber"
                    message="You can get this policy number from Posting Notices or Certificates of Insurance you’ve requested under this policy."
                  >
                    <TextInput name="policyNumber" />
                  </FormikFormField>
                </div>
                <div className={btnSection}>
                  <FormContinue
                    continueKey="continue-contact-information"
                    backKey="back-contact-information"
                    backFn={goBack}
                    textContinue="Confirm & Continue"
                    alignment="space-between"
                  />
                </div>
              </div>
            </Form>
          </>
        </SharedForm>
      )}
    </Formik>
  );
}
