import * as Yup from "yup";
import {
  getTotalGrossAmount,
  hasWorkPeriod,
  validateOffCycleRanges,
} from "../../utils";
import { PaymentDetailsType } from "../Setup/types";
import { formatDate } from "pages/employer-of-record/employee-profile/utils";
import { EmployeePayType } from "../../constants";

const dateFormatRegx =
  /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

const paymentDetailsSchema = Yup.object().shape({
  grossAmount: Yup.string()
    .nullable()
    .test("is-number", "This field must contain only numbers", (value) => {
      if (value) {
        return !isNaN(Number(value));
      }
      return true;
    })
    .test("is-greater-than-1", "Amount must be greater than zero", (value) => {
      if (value) {
        const number = parseFloat(value);
        return !isNaN(number) && number >= 1;
      }
      return true;
    }),
  workPeriodStart: Yup.string().when(
    ["grossAmount"],
    (params, schema, { context, parent }) => {
      const { workStartDate, workEndDate } =
        context.validationData?.members.find(
          (member: { id: string }) => member.id === parent.memberId
        ) || {};

      let message = `Work period needs to fall within their contract term of ${formatDate(
        workStartDate,
        "MM/DD/YY"
      )} - ${formatDate(workEndDate, "MM/DD/YY")}`;

      if (!workEndDate) {
        message = `Work period start needs to fall on or after ${formatDate(
          workStartDate,
          "MM/DD/YY"
        )}`;
      }

      return params[0] && hasWorkPeriod(context.setUp)
        ? schema
            .required("Work period start is required")
            .test("is-after-contract-start", message, function (value) {
              if (!value) {
                return true;
              }

              if (context.setUp.type === EmployeePayType.OFF_CYCLE) {
                const { workPeriodEnd } = parent;
                return validateOffCycleRanges(workStartDate, workPeriodEnd);
              }

              return (
                new Date(value).getTime() >= new Date(workStartDate).getTime()
              );
            })
            .matches(dateFormatRegx, "Dates should be entered as MM/DD/YYYY")
        : schema;
    }
  ),
  workPeriodEnd: Yup.string().when(
    ["grossAmount", "workPeriodStart"],
    (params, schema, { context, parent }) => {
      const { workStartDate, workEndDate } =
        context.validationData?.members.find(
          (member: { id: string }) => member.id === parent.memberId
        ) || {};
      return params[0] && hasWorkPeriod(context.setUp)
        ? schema
            .required("Work period end is required")
            .matches(dateFormatRegx, "Dates should be entered as MM/DD/YYYY")
            .test(
              "is-after-start",
              "Select an end date that's after your selected start date",
              function (value) {
                return (
                  new Date(value).getTime() > new Date(params[1]).getTime()
                );
              }
            )
            .test(
              "is-before-contract-end",
              `Work period needs to fall within their contract term of ${formatDate(
                workStartDate,
                "MM/DD/YY"
              )} - ${formatDate(workEndDate, "MM/DD/YY")}`,
              function (value) {
                if (!workEndDate) {
                  return true;
                }

                return (
                  new Date(value).getTime() < new Date(workEndDate).getTime()
                );
              }
            )
        : schema;
    }
  ),
});

export const paymentValidationSchema = Yup.array()
  .of(paymentDetailsSchema)
  .when(["setUp.payload.taxes.bonusType"], ([], tBonusType, schema) => {
    const context = schema.context;

    if (hasWorkPeriod(context.setUp)) {
      return Yup.array()
        .of(paymentDetailsSchema)
        .test(
          "at-least-one-gross-amount",
          "Enter a payment amount to continue.",
          function (value) {
            const grossAmount = getTotalGrossAmount(
              value as PaymentDetailsType[]
            );
            return (Number(grossAmount) || 0) > 0;
          }
        );
    }

    return Yup.array()
      .of(paymentDetailsSchema)
      .test(
        "at-least-one-gross-amount",
        "Enter a payment amount to continue.",
        function (value) {
          const grossAmount = getTotalGrossAmount(
            value as PaymentDetailsType[]
          );
          return (Number(grossAmount) || 0) > 0;
        }
      );
  });
