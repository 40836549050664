import { StepperHelpers } from "pages/pmt/integrations/partner-setup-page/hooks/useStepper";
import { StepClosed } from "./StepClosed/StepClosed";
import Collapsible from "../Collapsible";
import { IntegrationsConfig } from "types/IntegrationConfig";

type StepComponent = React.FC<
  StepperHelpers & {
    oAuthSuccess?: boolean;
  }
>;

export const Step = ({
  name,
  stepsConfig,
  doneName,
  stepperHelpers,
  oAuthSuccess,
}: {
  name: string;
  stepsConfig: IntegrationsConfig["copy"]["setup"]["steps"];
  doneName?: string;
  stepperHelpers?: StepperHelpers;
  stepIndex?: number;
  oAuthSuccess?: boolean;
}) => {
  if (!stepperHelpers) return null;
  const { currentStep, stepIndex } = stepperHelpers;
  const isPastStep = (stepIndex ? stepIndex : 0) < currentStep;
  const isCurrentStep = stepIndex === currentStep;
  const StepComponent: StepComponent = stepsConfig && stepsConfig[currentStep];

  return (
    <>
      <Collapsible open={!isCurrentStep}>
        <StepClosed
          name={isPastStep ? doneName : name}
          isPastStep={isPastStep}
        />
      </Collapsible>
      {isCurrentStep && (
        <Collapsible open={isCurrentStep}>
          <StepComponent {...stepperHelpers} oAuthSuccess={oAuthSuccess} />
        </Collapsible>
      )}
    </>
  );
};
