import React, { useEffect } from "react";
import styles from "./InstanceDetailsPanel.module.css";
import SetupButton from "./SetupButton/SetupButton";
import Notice from "../Notice/Notice";
import { AlertData } from "../../../hooks/useAlert";
import { integrationConfigs } from "pages/pmt/integrations/configs/config";
import { DisconnectedPanelLegalInfoSharing } from "./DisconnectedPanelContentLegalInfo";

const { instanceDetailsPanelContent } = styles;

export type DisconnectedPanelContentProps = {
  solutionSlug: string;
  partnerName: string | undefined;
  companyName: string | undefined;
  LegalInfo?: typeof DisconnectedPanelLegalInfoSharing;
  hasPermission: boolean;
  permissionNotice: JSX.Element;
  setAlertData: (alertData: AlertData) => void;
  integrationAutoDisabled?: boolean;
};

const DisconnectedPanelContent: React.FC<DisconnectedPanelContentProps> = ({
  solutionSlug,
  partnerName,
  companyName,
  hasPermission,
  permissionNotice,
  setAlertData,
  integrationAutoDisabled,
}) => {
  const pdpConfig = integrationConfigs[solutionSlug]["copy"]["pdp"];
  const LegalInfoComponent =
    pdpConfig.LegalInfo || DisconnectedPanelLegalInfoSharing;
  useEffect(() => {
    if (
      integrationAutoDisabled &&
      pdpConfig?.expiringOrDisabledAlertData?.integrationDisabled
    ) {
      setAlertData(pdpConfig?.expiringOrDisabledAlertData?.integrationDisabled);
    }
  }, [
    setAlertData,
    integrationAutoDisabled,
    pdpConfig?.expiringOrDisabledAlertData?.integrationDisabled,
  ]);

  const customSetupButton =
    pdpConfig.instanceDetailsPanelConfig?.customSetupButton;

  return (
    <div className={instanceDetailsPanelContent}>
      {hasPermission ? (
        <LegalInfoComponent
          companyName={companyName}
          partnerName={partnerName}
        />
      ) : (
        <Notice>{permissionNotice}</Notice>
      )}
      {customSetupButton || (
        <SetupButton
          solutionSlug={solutionSlug}
          hasPermission={hasPermission}
        />
      )}
    </div>
  );
};

export default DisconnectedPanelContent;
