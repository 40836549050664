import {
  AlertTypes,
  APPROVED_STATUS,
  prependBasePath,
  EARLIEST_PAY_DATE_APPROVAL_ERROR,
  READY_FOR_APPROVAL_STATUS,
} from "pages/expenses/constants";
import { useFeedbackBannerStore, useRequestDetail } from "pages/expenses/store";
import { formatDate } from "pages/expenses/utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetPayDateLazyQuery,
  useApproveReimbursementRequestMutation,
  ApproveReimbursementRequestMutationVariables,
} from "types/generated/operations";

export const useApproveReimbursementRequest = () => {
  const navigate = useNavigate();
  const { setFeedbackBanner } = useFeedbackBannerStore();
  const {
    resetRequestDetails,
    requestDetail: {
      metadata: { customPayDate },
    },
    setCustomPayDate,
  } = useRequestDetail();

  const [
    getEarliestPayDate,
    { loading: isPayDateLoading, data: payDayDateData },
  ] = useGetPayDateLazyQuery();

  const [
    approveReimbursementRequest,
    {
      data: approveReimbursementRequestData,
      loading: isApproveReimbursementRequestLoading,
    },
  ] = useApproveReimbursementRequestMutation();

  useEffect(() => {
    if (
      !isPayDateLoading &&
      !isApproveReimbursementRequestLoading &&
      approveReimbursementRequestData?.reviewReimbursementRequests?.success &&
      payDayDateData?.expenseManagement?.earliestPayDate
    ) {
      const updatedRequest =
        approveReimbursementRequestData?.reviewReimbursementRequests
          ?.updatedRequests?.[0];

      if (updatedRequest) {
        const {
          name,
          status,
          totalReimbursableAmount,
          totalNonReimbursableAmount,
        } = updatedRequest;

        if (status === APPROVED_STATUS) {
          const payDate = customPayDate
            ? customPayDate
            : formatDate(payDayDateData.expenseManagement.earliestPayDate);

          const isApprovedRequestNonReimbursable =
            totalNonReimbursableAmount !== 0 && totalReimbursableAmount === 0;

          if (isApprovedRequestNonReimbursable) {
            setFeedbackBanner({
              show: true,
              type: AlertTypes.SUCCESS,
              message: `${name} was approved.`,
            });
          } else {
            setFeedbackBanner({
              show: true,
              type: AlertTypes.SUCCESS,
              message: `${name} was approved and scheduled to be paid on ${payDate}`,
            });
          }
        } else if (status === READY_FOR_APPROVAL_STATUS) {
          setFeedbackBanner({
            type: AlertTypes.SUCCESS,
            show: true,
            message: `${name} was approved and will be sent
            for final approval.`,
          });
        }
      }

      navigate(prependBasePath("ACTIVE_REQUESTS_LIST"));

      resetRequestDetails();
    } else if (
      !approveReimbursementRequestData?.reviewReimbursementRequests?.success &&
      approveReimbursementRequestData?.reviewReimbursementRequests?.error?.includes(
        "is earlier than"
      )
    ) {
      setFeedbackBanner({
        show: true,
        type: AlertTypes.ERROR,
        message: EARLIEST_PAY_DATE_APPROVAL_ERROR,
      });

      if (payDayDateData?.expenseManagement?.earliestPayDate) {
        setCustomPayDate(
          formatDate(payDayDateData.expenseManagement.earliestPayDate)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    payDayDateData,
    isPayDateLoading,
    approveReimbursementRequestData,
    isApproveReimbursementRequestLoading,
  ]);

  const handleApproveReimbursementRequest = (
    variables: ApproveReimbursementRequestMutationVariables
  ) => {
    getEarliestPayDate();

    return approveReimbursementRequest({ variables });
  };

  return {
    handleApproveReimbursementRequest,
    isApproveReimbursementRequestLoading,
  };
};
