import * as Yup from "yup";

export const noEmojiTest = Yup.string()
  .max(500, "Less than 500 characters, please.")
  .test({
    name: "isNotEmojiTest",
    test: (value: string | undefined) => {
      if (!value) return true;
      return !/\p{Extended_Pictographic}/gu.test(value);
    },
    message: "No emoji, sorry 😔",
  });
