import React from "react";
import { Card } from "@justworkshr/milo-core";
import AddressForm from "../../components/InternationalAddressForm/AddressForm";
import { useTranslation } from "react-i18next";

type Props = {
  handleBlur: (event: React.FocusEvent) => void;
  handleChange: (event: React.ChangeEvent) => void;
};

const HomeAddressCard = ({ handleBlur, handleChange }: Props) => {
  const { t } = useTranslation();
  const formPath = "homeAddress";

  return (
    <div>
      <Card title={t("Home Address")}>
        <AddressForm
          handleBlur={handleBlur}
          handleChange={handleChange}
          formPath={formPath}
        />
      </Card>
    </div>
  );
};

export default HomeAddressCard;
