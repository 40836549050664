import { InitialIntakeFormValues } from "../../InitialIntakeForm.types";
import { Dispatch, useEffect } from "react";
import { useFormikContext } from "formik";
import { FormikFormField } from "../../../../../../../../../../lib/formik/FormikFormField";
import { Select, SelectOption } from "@justworkshr/milo-form";
import { getTerminationReasonOptionsByType } from "../../InitialIntakeForm.utils";

interface TerminationReasonFieldProps {
  lastTerminationType: string | undefined;
  setLastTerminationType: Dispatch<string>;
  loading: boolean;
}

export const TerminationReasonField: React.FC<TerminationReasonFieldProps> = ({
  lastTerminationType,
  setLastTerminationType,
  loading,
}) => {
  const {
    values: { termination_type, termination_reason },
    setFieldValue,
    isSubmitting,
  } = useFormikContext<InitialIntakeFormValues>();

  const sortedTerminationReasons = () => {
    return Object.entries(
      getTerminationReasonOptionsByType(termination_type)
    ).sort((a, b) => {
      return a[1].localeCompare(b[1]);
    });
  };

  useEffect(() => {
    if (!isSubmitting && !loading) {
      if (
        termination_type?.trim()?.length &&
        lastTerminationType !== termination_type
      ) {
        setFieldValue("termination_reason", sortedTerminationReasons()[0][0]);
      }
      setLastTerminationType(termination_type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, loading, termination_type, lastTerminationType]);

  if (!termination_type?.length) {
    return null;
  }

  return (
    <div>
      <FormikFormField label="Reason" name="termination_reason" required>
        <Select
          name="termination_reason"
          value={termination_reason}
          disabled={!termination_type}
          data-testid="termination_reason"
        >
          {sortedTerminationReasons().map(([key, value]) => {
            return (
              <SelectOption value={key} key={key}>
                {value}
              </SelectOption>
            );
          })}
        </Select>
      </FormikFormField>
    </div>
  );
};
