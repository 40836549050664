import React, {
  ChangeEvent,
  ReactElement,
  useId,
  useReducer,
  useRef,
  forwardRef,
} from "react";
import { Spinner } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import { truncateFile } from "./utils";
import styles from "./FileInput.module.css";
import reducer from "./reducer";
import {
  initialState,
  FileInputStateActionType,
  FileInputProps,
} from "./types";
import FileInfo from "./FileInfo";
import useMountedEffect from "./utils/useMountedEffect";

const {
  fileInput,
  dragActiveContainer,
  uploadButtonContainer,
  removeButtonContainer,
  processingContainer,
  hiddenInputElement,
  chooseFileText,
  fileNameText,
  removeFileText,
  disabledInput,
  fileListDisplay,
} = styles;

const FileInput = forwardRef(
  (
    {
      accept,
      onFileChange,
      onFileRemove,
      disabled = false,
      multiple = false,
      id,
      fileErrorMapping,
      ...otherProps
    }: FileInputProps,
    ref
  ): ReactElement => {
    const [{ dragActive, processingFile, file }, dispatch] = useReducer(
      reducer,
      initialState
    );
    useMountedEffect(() => {
      if (onFileChange !== undefined) onFileChange(file);
    }, [file]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const inputId = id ?? useId();

    const inputRef = useRef<HTMLInputElement>(null);
    const clickInput = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void => {
      e.preventDefault();
      e.stopPropagation();
      if (inputRef.current !== null) {
        inputRef.current.click();
      }
    };

    const removeFile = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      fileToRemove: File
    ): void => {
      if (disabled) return;
      if (onFileRemove !== undefined) onFileRemove(fileToRemove.name);
      dispatch({
        type: FileInputStateActionType.REMOVE_FILE,
        fileName: fileToRemove.name,
      });
    };

    const handleSingleFileChange = (fileToAdd: File): void => {
      dispatch({
        type: FileInputStateActionType.PROCESS_FILE,
        file: [fileToAdd],
      });
      dispatch({
        type: FileInputStateActionType.COMPLETE_PROCESSING,
        file: [fileToAdd],
      });
    };

    const handleMultipleFileChange = (filesToAdd: File[]): void => {
      dispatch({
        type: FileInputStateActionType.PROCESS_MULTIPLE_FILES,
        file: filesToAdd,
      });
      dispatch({
        type: FileInputStateActionType.COMPLETE_MULTIPLE_PROCESSING,
        file: filesToAdd,
      });
    };

    const handleDrag = (e: React.DragEvent<HTMLDivElement>): void => {
      e.preventDefault();
      e.stopPropagation();
      if (disabled) return;
      if (e.type === "dragenter" || e.type === "dragover") {
        dispatch({ type: FileInputStateActionType.FILE_HOVER });
      } else if (e.type === "dragleave") {
        dispatch({ type: FileInputStateActionType.FILE_UNHOVER });
      }
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
      e.preventDefault();
      e.stopPropagation();
      if (disabled) return;
      if (e.dataTransfer.files[0] !== undefined) {
        if (multiple)
          handleMultipleFileChange(Array.from(e.dataTransfer.files));
        else handleSingleFileChange(e.dataTransfer.files[0]);
      }
    };

    const handleClickChange = (e: ChangeEvent<HTMLInputElement>): void => {
      e.preventDefault();
      if (disabled) return;
      if (e.target.files?.[0] !== undefined) {
        if (multiple) handleMultipleFileChange(Array.from(e.target.files));
        else handleSingleFileChange(e.target.files[0]);
      }
    };

    const fileProcessedDisplay = (
      <button
        onClick={(e) => removeFile(e, file?.[0])}
        className={removeButtonContainer}
        type="button"
        disabled={disabled}
        ref={ref as React.LegacyRef<HTMLButtonElement>}
      >
        <div className={fileNameText}>
          <SystemIcon iconName="document" color="disabled" />
          {truncateFile(file?.[0]?.name ?? "")}
        </div>
        <div className={removeFileText}>
          <SystemIcon
            iconName="trash"
            color={disabled ? "disabled" : "error"}
          />
          Remove file
        </div>
      </button>
    );

    const neutralDisplay = (
      <>
        <input
          type="file"
          id={inputId}
          name={inputId}
          data-testid="milo--file-input-upload"
          className={hiddenInputElement}
          multiple={multiple}
          accept={accept}
          onChange={handleClickChange}
          disabled={disabled}
          ref={inputRef}
        />
        <label htmlFor={inputId}>
          <button
            className={uploadButtonContainer}
            aria-controls={inputId}
            type="button"
            onClick={clickInput}
          >
            <div className={chooseFileText}>
              <SystemIcon
                iconName="upload"
                color={disabled ? "disabled" : "brand"}
                size="extra-small"
              />
              {multiple ? "Choose files" : "Choose a file"}
            </div>
            or drag and drop {multiple ? "" : "one"} here
          </button>
        </label>
      </>
    );

    const processingDisplay = (
      <>
        <div className={processingContainer}>
          <Spinner color={disabled ? "disabled" : "brand"} />
          Checking file...
        </div>
      </>
    );

    let labelContent = <></>;
    if (processingFile) labelContent = processingDisplay;
    else if (file !== undefined && file.length > 0 && !multiple)
      labelContent = fileProcessedDisplay;
    else labelContent = neutralDisplay;

    let classNames = fileInput;
    if (disabled) classNames = [fileInput, disabledInput].join(" ");
    else if (dragActive)
      classNames = [fileInput, dragActiveContainer].join(" ");

    let fileList = <></>;
    if (multiple && Array.isArray(file)) {
      fileList = (
        <div className={fileListDisplay}>
          {file.map((f, index) => (
            <FileInfo
              file={f}
              key={index}
              disabled={disabled}
              errors={
                fileErrorMapping !== undefined
                  ? fileErrorMapping[f.name] ?? []
                  : []
              }
              onClick={removeFile}
            />
          ))}
        </div>
      );
    }

    return (
      <div {...otherProps}>
        <div
          className={classNames}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          {labelContent}
        </div>
        {fileList}
      </div>
    );
  }
);

FileInput.displayName = "FileInput";

export default FileInput;
