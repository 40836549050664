import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  homeEmail: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  policyNumber: Yup.number().typeError(
    "Employer policy number must be a number"
  ),
});
