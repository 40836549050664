import { Button } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import React, { ReactNode, useState, useRef } from "react";
import styles from "./collapsible.module.css";

interface IProps {
  children: ReactNode;
  className?: string;
  open?: boolean;
  title: string;
  id?: string;
}

const { expander, content } = styles;

const Collapsible: React.FC<IProps> = ({
  className,
  open,
  children,
  title,
  id,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const childrenRef = useRef<HTMLDivElement>(null);
  const childrenHeight = isOpen ? childrenRef.current?.scrollHeight : 0;

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={className} id={id}>
      <h2 className={expander}>
        <Button type="button" variant="ghost" onClick={handleFilterOpening}>
          <span>{title}</span>
          {!isOpen ? (
            <div>
              Expand <SystemIcon iconName="chevron-down" color="brand" />
            </div>
          ) : (
            <div>
              Collapse <SystemIcon iconName="chevron-up" color="brand" />
            </div>
          )}
        </Button>
      </h2>
      <div className={`${content} ${isOpen ? styles.open : styles.closed}`}>
        <div ref={childrenRef} style={{ height: childrenHeight }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Collapsible;
