import React, { useContext } from "react";
import styles from "./SeparationReasonHeader.module.css";
import { PageHeader } from "@justworkshr/milo-core";
import { buildWebPath } from "lib/resource-finder";
import { SeparationFlowContext } from "pages/team-management/contexts";

const { separationReasonHeader } = styles;

export const SeparationReasonHeader = () => {
  const { member } = useContext(SeparationFlowContext);
  const memberUuid = member?.uuid;

  if (!member?.friendlyName) {
    return null;
  }

  return (
    <PageHeader
      title={`Separation of ${member.friendlyName}`}
      linkPrevious={
        <a href={buildWebPath(`/members/${memberUuid}/personal-info`)}>
          Back to member view
        </a>
      }
      className={separationReasonHeader}
    >
      Separate an employee from your company if they are no longer with or will
      be leaving the company. We'll save their member information for future
      reference.
      <br />
      <br />
      <strong>Note:</strong> This information entered here will only be used to
      administer Unemployment and COBRA benefits. We'll send you an email with
      follow-up information.
    </PageHeader>
  );
};
