import { Button } from "@justworkshr/milo-core";
import {
  CountryCode,
  getLanguageDisplayName,
} from "app/internationalization/locales";
import styles from "../LanguagePicker.module.css";

interface LanguageItemRowProps {
  countryCode: CountryCode;
  onClick: () => void;
}
export const LanguageItemRow = ({
  countryCode,
  onClick,
}: LanguageItemRowProps) => {
  const displayName = getLanguageDisplayName(countryCode);
  return (
    <div
      className={styles.languageBox}
      data-testid={`dropdown_button_${countryCode}`}
    >
      <Button
        variant="ghost"
        type="button"
        className={styles.optionButton}
        size="sm"
        onClick={onClick}
      >
        <span>{displayName}</span>
        <span className={styles.countryCode}>{countryCode.toUpperCase()}</span>
      </Button>
    </div>
  );
};
