// Rather than importing another library, we just copy the source code of clsx
// This avoids security issues with importing libraries from npm / another developer
// And means we don't have to worry about version updates and incompatibile dependencies

// Original source (JS): https://github.com/lukeed/clsx/blob/master/src/index.js

export type clsxArg =
  | string
  | number
  | boolean
  | undefined
  | null
  | clsxArg[]
  | { [key: string]: string | number | boolean | undefined | null };

function toVal(mix: clsxArg): string {
  let k: string | number | undefined;
  let y: string | undefined;
  let str = "";

  if (typeof mix === "string" || typeof mix === "number") {
    str += mix;
  } else if (typeof mix === "object") {
    if (Array.isArray(mix)) {
      for (k = 0; k < mix.length; k++) {
        if (mix[k]) {
          if ((y = toVal(mix[k]))) {
            str && (str += " ");
            str += y;
          }
        }
      }
    } else {
      for (k in mix) {
        if (mix && mix[k]) {
          str && (str += " ");
          str += k;
        }
      }
    }
  }

  return str;
}

export function clsx(...args: clsxArg[]): string {
  let i = 0;
  let tmp: clsxArg;
  let x: string | undefined;
  let str = "";
  while (i < args.length) {
    if ((tmp = args[i++])) {
      if ((x = toVal(tmp))) {
        str && (str += " ");
        str += x;
      }
    }
  }
  return str;
}

export default clsx;
