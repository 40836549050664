import type { StepProps } from "../Step";
import type { EoROnboardingStates } from "types";

export type StepperStatus = StepProps["status"];

type EoROnboardingStep = {
  key: EoROnboardingStates;
  title: string;
  status: (
    step: number,
    currentStep: number,
    status?: StepperStatus
  ) => StepperStatus;
};

const calcStepStatus = (
  step: number,
  currentStep: number,
  status?: StepperStatus
) => {
  if (status && step !== currentStep) return status;

  if (step < currentStep) {
    return "complete";
  } else if (step === currentStep) {
    return "current";
  } else {
    return "pending";
  }
};

export const EoROnboardingSteps: EoROnboardingStep[] = [
  {
    key: "profile-info",
    title: "Your profile",
    status: calcStepStatus,
  },
  {
    key: "contact-info",
    title: "Contact info",
    status: calcStepStatus,
  },
  {
    key: "id-verification",
    title: "Verification",
    status: calcStepStatus,
  },
  {
    key: "payment-info",
    title: "Payments",
    status: calcStepStatus,
  },
  {
    key: "tax-info",
    title: "Tax info",
    status: calcStepStatus,
  },
];

export enum CountryCode {
  AF = "AF", //"Afghanistan",
  AX = "AX", //"Aland Islands",
  AL = "AL", //"Albania",
  DZ = "DZ", //"Algeria",
  AS = "AS", //"American Samoa",
  AD = "AD", //"Andorra",
  AO = "AO", //"Angola",
  AI = "AI", //"Anguilla",
  AQ = "AQ", //"Antarctica",
  AG = "AG", //"Antigua And Barbuda",
  AR = "AR", //"Argentina",
  AM = "AM", //"Armenia",
  AW = "AW", //"Aruba",
  AU = "AU", //"Australia",
  AT = "AT", //"Austria",
  AZ = "AZ", //"Azerbaijan",
  BS = "BS", //"Bahamas",
  BH = "BH", //"Bahrain",
  BD = "BD", //"Bangladesh",
  BB = "BB", //"Barbados",
  BY = "BY", //"Belarus",
  BE = "BE", //"Belgium",
  BZ = "BZ", //"Belize",
  BJ = "BJ", //"Benin",
  BM = "BM", //"Bermuda",
  BT = "BT", //"Bhutan",
  BO = "BO", //"Bolivia",
  BA = "BA", //"Bosnia And Herzegovina",
  BW = "BW", //"Botswana",
  BV = "BV", //"Bouvet Island",
  BR = "BR", //"Brazil",
  IO = "IO", //"British Indian Ocean Territory",
  BN = "BN", //"Brunei Darussalam",
  BG = "BG", //"Bulgaria",
  BF = "BF", //"Burkina Faso",
  BI = "BI", //"Burundi",
  KH = "KH", //"Cambodia",
  CM = "CM", //"Cameroon",
  CA = "CA", //"Canada",
  CV = "CV", //"Cape Verde",
  KY = "KY", //"Cayman Islands",
  CF = "CF", //"Central African Republic",
  TD = "TD", //"Chad",
  CL = "CL", //"Chile",
  CN = "CN", //"China",
  CX = "CX", //"Christmas Island",
  CC = "CC", //"Cocos (Keeling) Islands",
  CO = "CO", //"Colombia",
  KM = "KM", //"Comoros",
  CG = "CG", //"Congo",
  CD = "CD", //"Congo, Democratic Republic",
  CK = "CK", //"Cook Islands",
  CR = "CR", //"Costa Rica",
  CI = "CI", //'Cote D"Ivoire',
  HR = "HR", //"Croatia",
  CU = "CU", //"Cuba",
  CY = "CY", //"Cyprus",
  CZ = "CZ", //"Czech Republic",
  DK = "DK", //"Denmark",
  DJ = "DJ", //"Djibouti",
  DM = "DM", //"Dominica",
  DO = "DO", //"Dominican Republic",
  EC = "EC", //"Ecuador",
  EG = "EG", //"Egypt",
  SV = "SV", //"El Salvador",
  GQ = "GQ", //"Equatorial Guinea",
  ER = "ER", //"Eritrea",
  EE = "EE", //"Estonia",
  ET = "ET", //"Ethiopia",
  FK = "FK", //"Falkland Islands (Malvinas)",
  FO = "FO", //"Faroe Islands",
  FJ = "FJ", //"Fiji",
  FI = "FI", //"Finland",
  FR = "FR", //"France",
  GF = "GF", //"French Guiana",
  PF = "PF", //"French Polynesia",
  TF = "TF", //"French Southern Territories",
  GA = "GA", //"Gabon",
  GM = "GM", //"Gambia",
  GE = "GE", //"Georgia",
  DE = "DE", //"Germany",
  GH = "GH", //"Ghana",
  GI = "GI", //"Gibraltar",
  GR = "GR", //"Greece",
  GL = "GL", //"Greenland",
  GD = "GD", //"Grenada",
  GP = "GP", //"Guadeloupe",
  GU = "GU", //"Guam",
  GT = "GT", //"Guatemala",
  GG = "GG", //"Guernsey",
  GN = "GN", //"Guinea",
  GW = "GW", //"Guinea-Bissau",
  GY = "GY", //"Guyana",
  HT = "HT", //"Haiti",
  HM = "HM", //"Heard Island & Mcdonald Islands",
  VA = "VA", //"Holy See (Vatican City State)",
  HN = "HN", //"Honduras",
  HK = "HK", //"Hong Kong",
  HU = "HU", //"Hungary",
  IS = "IS", //"Iceland",
  IN = "IN", //"India",
  ID = "ID", //"Indonesia",
  IR = "IR", //"Iran, Islamic Republic Of",
  IQ = "IQ", //"Iraq",
  IE = "IE", //"Ireland",
  IM = "IM", //"Isle Of Man",
  IL = "IL", //"Israel",
  IT = "IT", //"Italy",
  JM = "JM", //"Jamaica",
  JP = "JP", //"Japan",
  JE = "JE", //"Jersey",
  JO = "JO", //"Jordan",
  KZ = "KZ", //"Kazakhstan",
  KE = "KE", //"Kenya",
  KI = "KI", //"Kiribati",
  KR = "KR", //"Korea",
  KP = "KP", //"North Korea",
  KW = "KW", //"Kuwait",
  KG = "KG", //"Kyrgyzstan",
  LA = "LA", //'Lao People"s Democratic Republic',
  LV = "LV", //"Latvia",
  LB = "LB", //"Lebanon",
  LS = "LS", //"Lesotho",
  LR = "LR", //"Liberia",
  LY = "LY", //"Libyan Arab Jamahiriya",
  LI = "LI", //"Liechtenstein",
  LT = "LT", //"Lithuania",
  LU = "LU", //"Luxembourg",
  MO = "MO", //"Macao",
  MK = "MK", //"Macedonia",
  MG = "MG", //"Madagascar",
  MW = "MW", //"Malawi",
  MY = "MY", //"Malaysia",
  MV = "MV", //"Maldives",
  ML = "ML", //"Mali",
  MT = "MT", //"Malta",
  MH = "MH", //"Marshall Islands",
  MQ = "MQ", //"Martinique",
  MR = "MR", //"Mauritania",
  MU = "MU", //"Mauritius",
  YT = "YT", //"Mayotte",
  MX = "MX", //"Mexico",
  FM = "FM", //"Micronesia, Federated States Of",
  MD = "MD", //"Moldova",
  MC = "MC", //"Monaco",
  MN = "MN", //"Mongolia",
  ME = "ME", //"Montenegro",
  MS = "MS", //"Montserrat",
  MA = "MA", //"Morocco",
  MZ = "MZ", //"Mozambique",
  MM = "MM", //"Myanmar",
  NA = "NA", //"Namibia",
  NR = "NR", //"Nauru",
  NP = "NP", //"Nepal",
  NL = "NL", //"Netherlands",
  AN = "AN", //"Netherlands Antilles",
  NC = "NC", //"New Caledonia",
  NZ = "NZ", //"New Zealand",
  NI = "NI", //"Nicaragua",
  NE = "NE", //"Niger",
  NG = "NG", //"Nigeria",
  NU = "NU", //"Niue",
  NF = "NF", //"Norfolk Island",
  MP = "MP", //"Northern Mariana Islands",
  NO = "NO", //"Norway",
  OM = "OM", //"Oman",
  PK = "PK", //"Pakistan",
  PW = "PW", //"Palau",
  PS = "PS", //"Palestinian Territory, Occupied",
  PA = "PA", //"Panama",
  PG = "PG", //"Papua New Guinea",
  PY = "PY", //"Paraguay",
  PE = "PE", //"Peru",
  PH = "PH", //"Philippines",
  PN = "PN", //"Pitcairn",
  PL = "PL", //"Poland",
  PT = "PT", //"Portugal",
  PR = "PR", //"Puerto Rico",
  QA = "QA", //"Qatar",
  RE = "RE", //"Reunion",
  RO = "RO", //"Romania",
  RU = "RU", //"Russian Federation",
  RW = "RW", //"Rwanda",
  BL = "BL", //"Saint Barthelemy",
  SH = "SH", //"Saint Helena",
  KN = "KN", //"Saint Kitts And Nevis",
  LC = "LC", //"Saint Lucia",
  MF = "MF", //"Saint Martin",
  PM = "PM", //"Saint Pierre And Miquelon",
  VC = "VC", //"Saint Vincent And Grenadines",
  WS = "WS", //"Samoa",
  SM = "SM", //"San Marino",
  ST = "ST", //"Sao Tome And Principe",
  SA = "SA", //"Saudi Arabia",
  SN = "SN", //"Senegal",
  RS = "RS", //"Serbia",
  SC = "SC", //"Seychelles",
  SL = "SL", //"Sierra Leone",
  SG = "SG", //"Singapore",
  SK = "SK", //"Slovakia",
  SI = "SI", //"Slovenia",
  SB = "SB", //"Solomon Islands",
  SO = "SO", //"Somalia",
  ZA = "ZA", //"South Africa",
  GS = "GS", //"South Georgia And Sandwich Isl.",
  ES = "ES", //"Spain",
  LK = "LK", //"Sri Lanka",
  SD = "SD", //"Sudan",
  SR = "SR", //"Suriname",
  SJ = "SJ", //"Svalbard And Jan Mayen",
  SZ = "SZ", //"Swaziland",
  SE = "SE", //"Sweden",
  CH = "CH", //"Switzerland",
  SY = "SY", //"Syrian Arab Republic",
  TW = "TW", //"Taiwan",
  TJ = "TJ", //"Tajikistan",
  TZ = "TZ", //"Tanzania",
  TH = "TH", //"Thailand",
  TL = "TL", //"Timor-Leste",
  TG = "TG", //"Togo",
  TK = "TK", //"Tokelau",
  TO = "TO", //"Tonga",
  TT = "TT", //"Trinidad And Tobago",
  TN = "TN", //"Tunisia",
  TR = "TR", //"Turkey",
  TM = "TM", //"Turkmenistan",
  TC = "TC", //"Turks And Caicos Islands",
  TV = "TV", //"Tuvalu",
  UG = "UG", //"Uganda",
  UA = "UA", //"Ukraine",
  AE = "AE", //"United Arab Emirates",
  GB = "GB", //"United Kingdom",
  US = "US", //"United States",
  UM = "UM", //"United States Outlying Islands",
  UY = "UY", //"Uruguay",
  UZ = "UZ", //"Uzbekistan",
  VU = "VU", //"Vanuatu",
  VE = "VE", //"Venezuela",
  VN = "VN", //"Vietnam",
  VG = "VG", //"Virgin Islands, British",
  VI = "VI", //"Virgin Islands, U.S.",
  WF = "WF", //"Wallis And Futuna",
  EH = "EH", //"Western Sahara",
  YE = "YE", //"Yemen",
  ZM = "ZM", //"Zambia",
  ZW = "ZW", //"Zimbabwe",
}

/*
To pass translation parser check:
https://github.com/i18next/i18next-parser?tab=readme-ov-file#caveats

t('Canada')
t('Alberta')
t('British Columbia')
t('Ontario')

t('United Kingdom')
t('England')
t('Northern Ireland')
t('Wales')
t('Scotland')
*/
