import ImageCard from "pages/pmt/integrations/partner-setup-page/components/ImageCard/ImageCard";
import { Button } from "@justworkshr/milo-core";
import styles from "./LeverSetup.module.css";
import { StepperHelpers } from "pages/pmt/integrations/partner-setup-page/hooks/useStepper";
import { useState } from "react";
import { StatusMessage } from "./utils";
import useSetupPageData from "pages/pmt/integrations/partner-setup-page/hooks/useSetupPageData";
import useProcessIntegrationAction, {
  ActionData,
} from "../../../partner-setup-page/hooks/useProcessPmtIntegrationAction";
import { leverConfig } from "../config";
import { DisplayIcon } from "@justworkshr/milo-icons";
const { wrapper, bold, cardText } = styles;

type LeverSetupStepOneProps = StepperHelpers & {
  processPmtIntegrationAction: (
    integrationName: string,
    action: string
  ) => Promise<
    | {
        success: boolean;
        actionData: ActionData | null;
      }
    | undefined
  >;
  loading: boolean;
};

export const LeverSetupStepOneRenderer: React.FC<LeverSetupStepOneProps> = ({
  processPmtIntegrationAction,
  loading,
  ...stepperHelpers
}) => {
  const { canGoToNextStep, goToNextStep } = stepperHelpers;
  const { refetch } = useSetupPageData(
    "lever",
    leverConfig.copy.setup.instanceKeys,
    1,
    { skip: true }
  );
  const [integrationSetupFailed, setIntegrationSetupFailed] = useState(false);

  const handleGetStarted = () => {
    setIntegrationSetupFailed(false);
    processPmtIntegrationAction("lever", "create").then(
      (processActionResponse) => {
        /* refresh is getting updated pdp data after the integration is created - this is needed because
        apollo is smart and uses uuids of objects in the cache to keep things updated across components
        but since we don't have the uuid before 'create' we need to make this call, which will return a integration with a uiud
        and then apollo will be smart enough to link these action calls, the pdp data and setup data in the apollo cache
        so all the rest of the steps will automatically update the linked cached data
        so if you leave this page at any step and go back to the setup page will auto update */
        refetch();
        if (processActionResponse?.success && canGoToNextStep) {
          goToNextStep();
        } else {
          setIntegrationSetupFailed(true);
        }
      }
    );
  };

  return (
    <ImageCard
      header="Let’s get you up and running"
      image={
        <DisplayIcon iconName="hand-waving" color="blueYellow" size="large" />
      }
    >
      <div className={wrapper}>
        <span className={cardText}>
          Before we get started, make sure you have permissions to{" "}
          <span className={bold}>
            Manage API credentials and manage integrations
          </span>{" "}
          in Lever. This is usually granted to Super Admins.
        </span>
        <Button onClick={handleGetStarted} loading={loading}>
          Get started
        </Button>
        {integrationSetupFailed && (
          <StatusMessage
            integrationSetupSuccess={!integrationSetupFailed}
            currentStep={1}
          />
        )}
      </div>
    </ImageCard>
  );
};

const LeverSetupStepOne: React.FC<StepperHelpers> = (
  stepperHelpers: StepperHelpers
) => {
  const { processPmtIntegrationAction, loading } =
    useProcessIntegrationAction();
  return (
    <LeverSetupStepOneRenderer
      processPmtIntegrationAction={processPmtIntegrationAction}
      loading={loading}
      {...stepperHelpers}
    />
  );
};

export default LeverSetupStepOne;
