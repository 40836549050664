import { SystemIcon } from "@justworkshr/milo-icons";
import styles from "./FieldError.module.css";

const { error, errorContent } = styles;

interface FormErrorProps {
  message: string;
}

const FieldError = ({ message }: FormErrorProps) => {
  return (
    <div className={error}>
      <SystemIcon iconName="error" color="error" />
      <div className={errorContent}>{message}</div>
    </div>
  );
};

export default FieldError;
