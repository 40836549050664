import { ReactElement } from "react";
import { SystemIcon } from "@justworkshr/milo-icons";
import Tooltip from "@justworkshr/milo-tooltip";
import styles from "./EstimatedRateRow.module.css";

type Props = {
  exchangeRate: number;
};

export const EstimatedRateRow = ({ exchangeRate }: Props): ReactElement => {
  return (
    <div className={styles.summaryRow}>
      <div className={styles.rowLabel}>
        Estimated rate{" "}
        <Tooltip
          position="top"
          title="Exchange rates (FX) are constantly fluctuating. The rate displayed may differ from the rate at the time this payment is processed and will be reflected in your invoice."
        >
          <SystemIcon iconName="info-circle" color="brand" size="medium" />
        </Tooltip>
      </div>
      <div className={styles.rowValue}>{exchangeRate}</div>
    </div>
  );
};
