import { SupportedCountriesDataType } from "pages/employer-of-record/contexts/supportedCountriesContext";

export const getSortedCountries = (
  countryList: SupportedCountriesDataType[],
  memberByCountryCount: { [key: string]: number }
): SupportedCountriesDataType[] => {
  // Sort countries alphabetically A-Z by commonName and push countries without members to the bottom
  return countryList.sort((a, b) => {
    const memberCountA = memberByCountryCount[a.code] ?? 0;
    const memberCountB = memberByCountryCount[b.code] ?? 0;

    // If one country has members and the other doesn't, push the one without members to the bottom
    if (memberCountA === 0 && memberCountB !== 0) {
      return 1;
    }
    if (memberCountB === 0 && memberCountA !== 0) {
      return -1;
    }
    const countryNameA = a.commonName || "";
    const countryNameB = b.commonName || "";
    return countryNameA.localeCompare(countryNameB);
  });
};
