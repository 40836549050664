import { FLAG_NAMES } from "../flag-constants";
import { SettingsConfig } from "../settings-type";

const defaultSettings: SettingsConfig = {
  [FLAG_NAMES.DATADOG_RUM_ENABLED]: false,
  [FLAG_NAMES.FLAG_TO_TEST_WITH]: false,
  [FLAG_NAMES.INVITATION_PAGE_REWRITE]: false,
  [FLAG_NAMES.EMPLOYER_OF_RECORD]: true,
  [FLAG_NAMES.JAZZHR_ENABLED]: true,
  [FLAG_NAMES.LEVER_ENABLED]: true,
  [FLAG_NAMES.PMT_INTEGRATIONS]: true,
  [FLAG_NAMES.INTERNATIONAL_CONTRACTOR_DOCUMENTS]: false,
  [FLAG_NAMES.ENABLE_I18N_DEBUG_MODE]: false,
  [FLAG_NAMES.ENABLE_MESH]: false,
  [FLAG_NAMES.ENABLE_PKCE]: () => {
    const enablePkce = localStorage.getItem("enablePkce");
    return enablePkce === "true";
  },
};

export default defaultSettings;
