import { IntegrationsConfig } from "types/IntegrationConfig";
import { HR_PERMISSIONS } from "pages/pmt/integrations/integration-marketplace/constants";
import jazzHrLogo from "pages/pmt/integrations/images/integration-partner-logos/jazzhr-logo.svg";
import jazzHrHeroImage from "../../images/hero-images/jazzhr-hero.svg";
import jazzHrHorizontalLogo from "../../images/horizontal-logos/jazzhr-horizontal.svg";
import {
  DataAndPermissionsTab,
  OverviewTab,
  PostSetupTab,
} from "../../partner-description-page/components/TabsContentTemplate/TabsContentTemplate";
import {
  inlineLinkWithIconFormatter,
  justworksPermissionsCopy,
} from "../../partner-description-page/components/TabsContentTemplate/constants";
import postSetupImage1 from "../../images/info-tabs-images/jazzhr/pending-hires-alert.svg";
import postSetupImage2 from "../../images/info-tabs-images/jazzhr/pending-hires-table.svg";
import JazzHRSetupStepOne from "./setup-steps/JazzHRSetupStepOne";
import JazzHRSetupStepTwo from "./setup-steps/JazzHRSetupStepTwo";
import JazzHRSetupStepThree from "./setup-steps/JazzHRSetupStepThree";
import { getStepForJazzhr } from "./setup-steps/utils";
import { JAZZHR_BASE_URL } from "./constants";
import ResetButton from "../../partner-setup-page/components/ResetButton/ResetButton";
import SetupButton from "../../partner-description-page/components/InstanceDetailsPanel/SetupButton/SetupButton";
import React from "react";
import styles from "pages/pmt/integrations/partner-description-page/components/InstanceDetailsPanel/InstanceDetailsPanel.module.css";
import { DisconnectedPanelLegalInfoReceiving } from "../../partner-description-page/components/InstanceDetailsPanel/DisconnectedPanelContentLegalInfo";

const { integrationAlert, alertText } = styles;

export const jazzHrConfig: IntegrationsConfig = {
  numAllowedInstances: 1,
  copy: {
    marketplaceCard: {
      logoUrl: jazzHrLogo,
      integrationName: "JazzHR",
      integrationDescription:
        "Import new hires from JazzHR right into Justworks.",
      integrationCategory: "Hiring",
      requiredPermissions: HR_PERMISSIONS,
      slug: "jazzhr",
      pdpUrl: "/cf/integrations/jazzhr",
    },

    pdp: {
      headerDescription:
        "JazzHR is a user-friendly applicant tracking system that automates, simplifies, and streamlines hiring for small businesses across all industries.",
      heroImageUrl: jazzHrHeroImage,
      horizontalLogoUrl: jazzHrHorizontalLogo,
      LegalInfo: DisconnectedPanelLegalInfoReceiving,
      resourceCard: {
        links: [
          {
            title: "JazzHR in the Help Center",
            href: "https://help.justworks.com/hc/en-us/articles/21893381640731-JazzHR-Integration",
            systemIconName: "external-link",
            external: true,
          },
          {
            title: "Learn more about JazzHR",
            href: "https://www.justworks.com/marketplace/jazzhr",
            systemIconName: "external-link",
            external: true,
          },
        ],
      },
      infoTabsConfig: {
        0: {
          title: "Overview",
          Component: OverviewTab,
        },
        1: {
          title: "Data & permissions",
          Component: DataAndPermissionsTab,
        },
        2: {
          title: "Using the integration",
          Component: PostSetupTab,
        },
      },
      infoTabsContent: {
        overviewTab: {
          header: "JazzHR + Justworks",
          subheader:
            "With the JazzHR and Justworks integration, you can import new hire information and add them directly to Justworks as employees or contractors.",
          list: [
            {
              summary: "Save time adding new hires into Justworks",
              detail:
                "New hires are great–until you remember all the things you need to take care of. Connecting to JazzHR means one less task to manage.",
            },
            {
              summary: "Reduce errors from manually inputting data",
              detail:
                "The new hire's info is already in JazzHR. No need to retype it and risk potential mistakes.",
            },
            {
              summary: "One-time setup",
              detail:
                "You only need to set up the integration once to work with every candidate moved into a full-time or part-time Hired disposition.",
            },
          ],
        },
        dataAndPermissionsTab: {
          dataSharedSection: {
            header: "What data will Justworks receive from JazzHR?",
            sharedDataGroups: [
              {
                header:
                  "Whenever a candidate is moved into a full-time or part-time Hired disposition, we’ll automatically pull in the following data:",
                sharedData: [
                  "Member type",
                  "First name",
                  "Last name",
                  "Title",
                  "Home email",
                  "Start date",
                ],
              },
            ],
          },
          permissionsSection: {
            header: "What permissions will you need for this integration?",
            list: [
              {
                summary: "In Justworks",
                detail: (
                  <div>
                    Admins need{" "}
                    <strong>{justworksPermissionsCopy(HR_PERMISSIONS)}</strong>{" "}
                    permissions.
                  </div>
                ),
              },
              {
                summary: "In JazzHR",
                detail: (
                  <div>
                    {" "}
                    JazzHR users need to be in a{" "}
                    <strong>{"Super Administrator"}</strong>
                    {", "}
                    <strong>{"Recruiting Administrator"}</strong>
                    {", or "}
                    <strong>{"Developer"}</strong> role to set up the
                    integration.
                  </div>
                ),
              },
            ],
          },
        },
        postSetupTab: {
          header: "How do I use this integration?",
          list: [
            {
              caption: (
                <p>
                  Whenever a candidate is moved into a full-time or part-time
                  Hired disposition, we’ll email all admins with{" "}
                  <strong>{justworksPermissionsCopy(HR_PERMISSIONS)}</strong>{" "}
                  permissions, and share an alert on your Justworks dashboard.
                </p>
              ),
              image: postSetupImage1,
            },
            {
              caption: (
                <p>
                  After selecting <strong>Review</strong>, you’ll see all
                  pending hires who are ready to be invited to Justworks.
                </p>
              ),
              image: postSetupImage2,
            },
            {
              caption: (
                <p>
                  Selecting <strong>Review & Invite</strong> will prompt you to
                  create a new member which will be pre-populated with the
                  candidate’s info from JazzHR. You’ll also be able to make any
                  edits before inviting the candidate to join your team on
                  Justworks.
                </p>
              ),
            },
          ],
        },
      },
      disconnectModalContent: {
        title: "Disconnect JazzHR integration",
        body: (
          <div>
            <p>
              Once integration is disconnected, we won’t import pending hires
              information from JazzHR to Justworks anymore.
            </p>
            <br />
            <p>
              After disconnecting,{" "}
              {inlineLinkWithIconFormatter(
                "you can double-check on",
                `${JAZZHR_BASE_URL}/app/settings/integrations`,
                "JazzHR’s integrations page"
              )}
              , find Justworks in the list of integrations and select{" "}
              <strong>Disconnect.</strong>
            </p>
          </div>
        ),
        disconnectButtonText: "Disconnect integration",
      },
      allowedPdpActions: ["keep_alive"],
      expiringOrDisabledAlertData: {
        integrationExpiring: {
          message1: "Your integration will expire in ",
          message2: " days. To keep your integration active, refresh it now.",
          buttonType: "refresh",
          color: "new" as const,
          visible: true,
          dismissible: false,
        },
        integrationDisabled: {
          message: (
            <div className={integrationAlert}>
              <span className={alertText}>
                Your integration expired. Please set up a new integration.
              </span>
              <span>
                <SetupButton
                  solutionSlug={"jazzhr"}
                  hasPermission={true}
                  inAlert={true}
                />
              </span>
            </div>
          ),
          color: "new" as const,
          visible: true,
          dismissible: false,
        },
      },
    },
    setup: {
      rightHeaderButton: ResetButton,
      rightHeaderButtonProps: {
        integrationSlug: "jazzhr",
        allowedResetSteps: [1, 2],
      },
      estimatedSetupTime: "5",
      helpCenterLink:
        "https://help.justworks.com/hc/en-us/articles/21893381640731-JazzHR-Integration",
      stepperConfig: {
        setup: {
          name: "Setup Started",
          doneName: "Setup started",
        },
        create: {
          name: "Create Webhook",
          doneName: "Webhook created",
        },
        test: {
          name: "Test the integration",
        },
      },
      steps: [JazzHRSetupStepOne, JazzHRSetupStepTwo, JazzHRSetupStepThree],
      instanceKeys: [
        "warn_unused",
        "disable_unused",
        "verify_candidate_hired",
        "keep_alive",
      ],
      getCurrentStepFunction: getStepForJazzhr,
    },
  },
};
