import React from "react";
import { useState, useEffect, useCallback } from "react";
import ImageCard from "pages/pmt/integrations/partner-setup-page/components/ImageCard/ImageCard";
import styles from "./JazzHRSetup.module.css";
import { StatusMessage } from "./utils";
import { StepperHelpers } from "pages/pmt/integrations/partner-setup-page/hooks/useStepper";
import { Button, Spinner } from "@justworkshr/milo-core";
import Notice from "pages/pmt/integrations/partner-description-page/components/Notice/Notice";
import { inlineLinkWithIconFormatter } from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/constants";
import { JAZZHR_BASE_URL } from "../constants";
import useProcessPmtIntegrationAction, {
  Integration,
  ActionData,
} from "../../../partner-setup-page/hooks/useProcessPmtIntegrationAction";
import CopyButton from "../../../../common/CopyButton";

const {
  wrapper,
  stepTwoCard,
  stepTwoList,
  spinnerSpacing,
  noticeContainer,
  copyHeader,
  clientSecretBox,
  clientSecretShow,
  clientSecretCtas,
  stepTwoFailed,
} = styles;

type JazzHRSetupStepTwoProps = StepperHelpers & {
  processPmtIntegrationAction: (
    integrationName: string,
    action: string
  ) => Promise<{
    success: boolean;
    actionData: ActionData | null;
    integration: Integration | null;
  }>;
  loading: boolean;
};

export const JazzHRSetupStepTwoRenderer: React.FC<JazzHRSetupStepTwoProps> = ({
  processPmtIntegrationAction,
  loading,
  ...stepperHelpers
}) => {
  const [integrationSetupFailed, setIntegrationSetupFailed] = useState(false);
  const [showClientSecret, setShowClientSecret] = useState(false);
  const [clientData, setClientData] = useState({ id: "", key: "" });
  const COPY_NOTICE_CLIENT_SECRET_HIDE = "****** ****** ******";

  const generateSecretKey = useCallback(
    () =>
      processPmtIntegrationAction("jazzhr", "generate_secret_key").then(
        (processActionResponse) => {
          if (processActionResponse.success) {
            setIntegrationSetupFailed(false);
            setClientData({
              id: processActionResponse.integration?.uuid || "",
              key: processActionResponse.actionData?.secret_key || "",
            });
          } else {
            setIntegrationSetupFailed(true);
          }
        }
      ),
    [processPmtIntegrationAction]
  );

  useEffect(() => {
    generateSecretKey();
  }, [generateSecretKey]);

  return (
    <div className={stepTwoCard}>
      <ImageCard header="Create webhook">
        {loading && (
          <span className={spinnerSpacing}>
            <Spinner aria-label="loading" />
          </span>
        )}
        {!loading && (
          <>
            <div className={wrapper}>
              <ol className={stepTwoList}>
                <li>
                  {inlineLinkWithIconFormatter(
                    "Visit",
                    `${JAZZHR_BASE_URL}/app/settings/integrations`,
                    "JazzHR integrations"
                  )}
                </li>
              </ol>
              <div className={noticeContainer}>
                <Notice>
                  Trouble with the link? In JazzHR, go to Settings {">"}{" "}
                  Integrations
                </Notice>
              </div>
              <ol className={stepTwoList} start={2}>
                <li>
                  Find Justworks in the list of integrations and select{" "}
                  <strong>Connect.</strong> A new window will open.
                </li>
                <li>
                  Here’s the data you’ll need to enter in the new window. Just
                  copy and paste into the corresponding fields, save the changes
                  and come back here.
                </li>
              </ol>
              <div className={noticeContainer}>
                <div className={copyHeader}>Client ID</div>
                <Notice copyNotice={true}>
                  <div>{clientData.id}</div>
                  <CopyButton
                    copyText={clientData.id}
                    disabled={integrationSetupFailed}
                  />
                </Notice>
                <div className={clientSecretBox}>
                  <div className={copyHeader}>Client Secret</div>
                  <Notice copyNotice={true}>
                    <div className={clientSecretShow}>
                      {showClientSecret
                        ? clientData.key
                        : COPY_NOTICE_CLIENT_SECRET_HIDE}
                    </div>
                    <div className={clientSecretCtas}>
                      <Button
                        variant="ghost"
                        type="button"
                        onClick={() => setShowClientSecret(!showClientSecret)}
                        disabled={integrationSetupFailed}
                      >
                        {showClientSecret ? "Hide" : "Show"}
                      </Button>
                      <CopyButton
                        copyText={clientData.key}
                        disabled={integrationSetupFailed}
                      />
                    </div>
                  </Notice>
                  {integrationSetupFailed && (
                    <div className={stepTwoFailed}>
                      <StatusMessage
                        integrationSetupSuccess={!integrationSetupFailed}
                        currentStep={2}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!integrationSetupFailed && (
              <Button onClick={stepperHelpers.goToNextStep}>Next</Button>
            )}
            {integrationSetupFailed && (
              <Button onClick={generateSecretKey}>Try again</Button>
            )}
          </>
        )}
      </ImageCard>
    </div>
  );
};

const JazzHRSetupStepTwo: React.FC<StepperHelpers> = (
  stepperHelpers: StepperHelpers
) => {
  const { processPmtIntegrationAction, loading } =
    useProcessPmtIntegrationAction();
  return (
    <JazzHRSetupStepTwoRenderer
      processPmtIntegrationAction={processPmtIntegrationAction}
      loading={loading}
      {...stepperHelpers}
    />
  );
};

export default JazzHRSetupStepTwo;
