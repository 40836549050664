import classes from "./PlanDetails.module.css";
import { useState } from "react";
import { Button } from "@justworkshr/milo-core";
import { buildClockworkWebPath } from "lib/resource-finder";
import moment from "moment";

import { GetCompany401kDetailsQuery } from "types/generated/operations";

const formatCentsToDollars = (cents: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(cents / 100.0);
};

type SortDir = "asc" | "desc";

const PlanDetails = ({
  plan,
}: {
  plan: GetCompany401kDetailsQuery["currentMember"]["company"]["company401kPlan"];
}) => {
  const [sortDir, setSortDir] = useState<SortDir>("asc");
  const reverseSortDir = () => {
    setSortDir((dir) => (dir === "asc" ? "desc" : "asc"));
  };

  if (!plan) {
    return null;
  }

  const tier1Rate =
    plan.discretionaryTier1Rate || plan.safeHarborTier1Rate || 0;
  const tier1Limit =
    plan.discretionaryTier1Limit || plan.safeHarborTier1Limit || 0;
  const tier2Rate =
    plan.discretionaryTier2Rate || plan.safeHarborTier2Rate || 0;
  const tier2Limit =
    plan.discretionaryTier2Limit || plan.safeHarborTier2Limit || 0;

  const companyMatch = [];

  if (tier1Rate || tier2Rate) {
    companyMatch.push(`${tier1Rate}% of the first ${tier1Limit}`);
    if (tier2Rate) {
      companyMatch.push(`${tier2Rate}% of the next ${tier2Limit}`);
    }
  }

  if (plan.safeHarborNonelective) {
    companyMatch.push(`${plan.safeHarborNonelectivePercent}% non-elective`);
  }

  if (companyMatch.length === 0) {
    companyMatch.push("None");
  }

  const details = [...plan.memberDetails];
  details.sort((memberA, memberB) => {
    if (memberA.fullName > memberB.fullName) return 1;
    if (memberB.fullName < memberA.fullName) return -1;
    return 0;
  });

  if (sortDir === "desc") {
    details.reverse();
  }

  return (
    <div className={classes.PlanDetails}>
      <h2>Plan details</h2>

      <table>
        <thead>
          <tr>
            <th>Total employees enrolled</th>
            <th>Match amount</th>
            <th>Plan start</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {details.length} employee{details.length === 1 ? "" : "s"}
            </td>
            <td>
              {companyMatch.map((part, i, matches) => {
                if (i === matches.length - 1) {
                  return <p key={part}>{part}</p>;
                } else {
                  return <p key={part}>{part}; </p>;
                }
              })}
            </td>
            <td>{moment(plan.startDate).format("MM/DD/YYYY")}</td>
          </tr>
        </tbody>
      </table>

      <h2>Employees enrolled</h2>

      <table>
        <thead>
          <tr>
            <th>
              <Button
                as="a"
                variant="ghost"
                rightIcon={sortDir === "asc" ? "arrow-up" : "arrow-down"}
                onClick={() => reverseSortDir()}
              >
                Name
              </Button>
            </th>
            <th>Employee pays</th>
            <th>Company pays</th>
          </tr>
        </thead>
        <tbody>
          {details.map((member) => {
            let semi;
            const memberDescription = [];

            if (member.pretaxAmount) {
              semi = member.aftertaxAmount ? ";" : "";
              memberDescription.push(
                <p key="pretax">
                  {formatCentsToDollars(member.pretaxAmount)}/mo pre-tax{semi}
                </p>
              );
            }

            if (member.aftertaxAmount) {
              memberDescription.push(
                <p key="aftertax">
                  {formatCentsToDollars(member.aftertaxAmount)}/mo after-tax
                </p>
              );
            }

            const companyDescription = [];

            if (member.companyMatchAmount) {
              semi = member.companyNonelectiveAmount ? ";" : "";
              companyDescription.push(
                <p key="match">
                  {formatCentsToDollars(member.companyMatchAmount)}/mo matching
                  {semi}
                </p>
              );
            }

            if (member.companyNonelectiveAmount) {
              companyDescription.push(
                <p key="nonelective">
                  {formatCentsToDollars(member.companyNonelectiveAmount)}{" "}
                  nonelective
                </p>
              );
            }

            return (
              <tr key={member.uuid}>
                <td>
                  <a href={buildClockworkWebPath(`/member/${member.uuid}`)}>
                    {member.fullName}
                  </a>
                </td>
                <td>{memberDescription}</td>
                <td>{companyDescription}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PlanDetails;
