import { WaiverJobLocation } from "../../types";
import styles from "./location-review.module.css";

const { addressInfoHeader, locationMargin, addressInfoText } = styles;

const formatLocation = ({
  street1,
  street2,
  city,
  state,
  postalCode,
}: WaiverJobLocation) => {
  const formattedStreet2 = street2.length > 0 ? `${street2}, ` : "";

  return `${street1}, ${formattedStreet2}${city}, ${state}, ${postalCode}`;
};

export const JobAddressReview = ({
  location,
  locationNumber,
}: {
  location: WaiverJobLocation;
  locationNumber: number;
}) => {
  return (
    <div className={locationMargin}>
      <h3 className={addressInfoHeader}>Job Location {locationNumber}</h3>
      <p className={addressInfoText} data-heap-redact-text={true}>
        {formatLocation(location)}
      </p>
    </div>
  );
};
