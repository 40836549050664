import { Button, PageHeader } from "@justworkshr/milo-core";
import { DisplayIcon, SystemIcon } from "@justworkshr/milo-icons";
import { ReactElement } from "react";
import styles from "./about-page.module.css";
import { Link } from "react-router-dom";

const {
  grid,
  titleSection,
  blueBackground,
  gridSection,
  borderSection,
  borderSectionTitle,
  tableOfContents,
  ellipseDiv,
  arrowTitle,
  arrowDescription,
  helpDiv,
} = styles;

export default function AboutClaims(): ReactElement {
  return (
    <>
      <div className={blueBackground} />
      <div className="milo--grid">
        <section className={`${titleSection}`}>
          <PageHeader
            border={false}
            title={"Guide to workers' compensation claims"}
            linkPrevious={
              <Link to="/workers-compensation-claims">{"Back to claims"}</Link>
            }
          />{" "}
        </section>
        <div className={grid}>
          <section className={`${gridSection} ${borderSection}`}>
            <div className={borderSectionTitle}>
              <DisplayIcon
                color="blue"
                iconName="exclamation-mark"
                size="small"
              />
              <h2>Important</h2>
            </div>
            <p>
              Should the incident or accident be serious in nature, dial 911
              immediately.
            </p>
            <p>
              Should the incident be non-life threatening, have the injured
              party seek medical attention with an Urgent Care or designated
              medical provider.
            </p>
            <p>
              When the injured employee arrives at a medical provider or
              service, the employee should not use their standard health
              insurance. Instead, for first services in response to an incident,
              the employee should present the Optum card they receive after the
              Workers
              {"\u2019"} Compensation Claim has been submitted on their behalf.
            </p>
            <p>
              As an HR administrator for an employee, this means it is important
              to fill out an incident report as quickly as possible so that you
              can receive the Optum card for your employee.
            </p>
          </section>
          <section className={`${tableOfContents}`}>
            <ul>
              <li>Important</li>
              <li>About Workers' Compensation</li>
              <li>Submitting a report</li>
              <li>After you submit a report</li>
            </ul>
          </section>
          <section className={gridSection}>
            <h2>About Workers' Compensation</h2>
            <p>
              In the event that your employee is involved in an incident
              occurring in the course of their work, Workers{"\u2019"}{" "}
              Compensation seeks to help the employee find benefits applicable
              to their unique scenario and to support the business in properly
              supporting their employee.
            </p>
            <p>
              Workers{"\u2019"} Compensation aims to help return impacted
              employees to work in either a modified or regular duty in a
              successful manner. Loss of productivity can be a costly expense
              for employers. Workers
              {"\u2019"}
              Compensation programs help to provide benefits and care to the
              employee and allow them to return to work as soon as it is
              medically appropriate.
            </p>
          </section>
          <section className={gridSection}>
            <h2> Submitting an incident report</h2>
            <p>
              When an incident occurs at work, always fill out a report so we
              can process a claim for your impacted employee - even if they were
              not in need of medical attention at the time of the incident.
            </p>
            <p>
              We will ask you for thorough information about what happened, what
              care was received, and who was contacted during the incident. Be
              sure to fill out a report as soon as possible after an incident.
            </p>
            <Link to={"/workers-compensation-claims"}>
              <Button
                as="button"
                color="brand"
                rightIcon="arrow-right"
                size="md"
                variant="outlined"
              >
                Report an incident
              </Button>
            </Link>
          </section>
          <section className={gridSection}>
            <h2>After you submit a report</h2>
            <div>
              <div className={arrowTitle}>
                <div className={ellipseDiv}>
                  <SystemIcon
                    iconName="arrow-right"
                    size="extra-small"
                    color="inverse"
                  />
                </div>
                <h3>An Optum health card will be emailed to your employee</h3>
              </div>{" "}
              <div className={arrowDescription}>
                <p>
                  This card is to be used for the initial treatment and first
                  prescription fills in response to this incident. Your employee
                  should not use their standard health insurance card at this
                  time, as applicable care in response to this incident will be
                  handled uniquely by Optum at first.
                </p>
              </div>
            </div>
            <div>
              <div className={arrowTitle}>
                <div className={ellipseDiv}>
                  <SystemIcon
                    iconName="arrow-right"
                    size="extra-small"
                    color="inverse"
                  />
                </div>
                <h3>
                  You will receive an email confirmation of this claim with
                  guidance on how to assist your employee.
                </h3>
              </div>
              <div className={arrowDescription}>
                <p>
                  Your employee can get medical care in this time. Provide us
                  with more information or updates on their healthcare needs
                  through customer support.
                </p>
              </div>
            </div>
            <div>
              <div className={arrowTitle}>
                <div className={ellipseDiv}>
                  <SystemIcon
                    iconName="arrow-right"
                    size="extra-small"
                    color="inverse"
                  />
                </div>
                <h3>
                  The claim is being set up with the insurance carrier, and
                  additional information will be sent to your employee when
                  ready.
                </h3>
              </div>
              <div className={arrowDescription}>
                <p>
                  The insurance carrier will communicate directly with your
                  employee once they have set up and initiated this claim.
                </p>
              </div>
            </div>
          </section>
          <section className={gridSection}>
            <div className={helpDiv}>
              <p>
                For all things related to Workers{"\u2019"} Compensation, and
                frequently asked questions, see our Help Center article.
              </p>
              <Button
                as={"a"}
                href="https://help.justworks.com/hc/en-us/articles/360004473472-Workers-Compensation-Insurance"
                variant="outlined"
                size="md"
              >
                Help center
              </Button>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
