import { BonusPayloadType } from "../../../types";
import { BonusFrequency, BonusType } from "../../constants";

export const BONUS_INITIAL_VALUES: BonusPayloadType = {
  taxes: {
    frequency: BonusFrequency.ONCE,
    bonusType: BonusType.DISCRETIONARY,
    nonDiscretionaryType: undefined,
    reason: undefined,
  },
  paymentSettings: {
    payPeriodId: "",
    disbursementDate: "",
    deliveryMethod: "electronic",
    notes: "",
  },
};
