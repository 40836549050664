import { fetchClient } from "@justworkshr/fe-utility-kit";
import { useMutation } from "@tanstack/react-query";
import { buildClockworkWebPath } from "lib/resource-finder";

interface GenerateLinkTokenResponse {
  link_session_token: string;
  expiration?: string;
}

export const useGenerateLinkToken = () => {
  const { mutateAsync, isPending } = useMutation<
    GenerateLinkTokenResponse,
    Error
  >({
    mutationFn: async () => {
      const response = await fetchClient.post(
        buildClockworkWebPath("/company/bank_account/generate_link_token")
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    onError: () => {
      throw new Error("Error fetching link token");
    },
  });

  return { mutateAsync, isPending };
};
