import { greenhouseConfig } from "./greenhouse/config";
import { netsuiteConfig } from "./netsuite/config";
import { xeroConfig } from "./xero/config";
import { quickbooksConfig } from "./quickbooks/config";
import { leverConfig } from "./lever/config";
import { IntegrationsConfig } from "types/IntegrationConfig";
import { jazzHrConfig } from "./jazzhr/config";
import { sageIntacctConfig } from "./sage-intacct/config";
import { rampConfig } from "./ramp/config";
import { brexConfig } from "./brex/config";
import { cartaConfig } from "./carta/config";
import { fifteenfiveConfig } from "./fifteenfive/config";
import { vantaConfig } from "./vanta/config";
import { trainualConfig } from "./trainual/config";
import { cultureAmpConfig } from "./culture-amp/config";

export const integrationConfigs: { [key: string]: IntegrationsConfig } = {
  [greenhouseConfig.copy.marketplaceCard.slug]: greenhouseConfig,
  [netsuiteConfig.copy.marketplaceCard.slug]: netsuiteConfig,
  [xeroConfig.copy.marketplaceCard.slug]: xeroConfig,
  [quickbooksConfig.copy.marketplaceCard.slug]: quickbooksConfig,
  [leverConfig.copy.marketplaceCard.slug]: leverConfig,
  [jazzHrConfig.copy.marketplaceCard.slug]: jazzHrConfig,
  [sageIntacctConfig.copy.marketplaceCard.slug]: sageIntacctConfig,
  [rampConfig.copy.marketplaceCard.slug]: rampConfig,
  [brexConfig.copy.marketplaceCard.slug]: brexConfig,
  [cartaConfig.copy.marketplaceCard.slug]: cartaConfig,
  [fifteenfiveConfig.copy.marketplaceCard.slug]: fifteenfiveConfig,
  [vantaConfig.copy.marketplaceCard.slug]: vantaConfig,
  [trainualConfig.copy.marketplaceCard.slug]: trainualConfig,
  [cultureAmpConfig.copy.marketplaceCard.slug]: cultureAmpConfig,
};
