import { RequestDetailExpense } from "types/Expenses";
import {
  BASE_EXPENSES_PATH,
  DEFAULT_TABLE_PARAMS,
  EXPENSE_INPUT_FIELD_NAMES,
} from "../constants";
import {
  ExpenseInput,
  ExpenseCategoryFieldSetting,
} from "types/generated/operations";
import { ExpenseDetailExpense } from "../types";

export const mergeClassNames = (
  classNames: Array<
    string | null | number | undefined | { [key: string]: boolean }
  >
) => {
  const activeClassNames = [];

  for (const className of classNames) {
    const isObjectType = typeof className === "object";

    if (className && isObjectType && Object.keys(className || {}).length) {
      Object.entries(className).forEach(([cName, isActive]) => {
        if (isActive) activeClassNames.push(cName);
      });
    } else if (className && !isObjectType) {
      activeClassNames.push(className);
    }
  }

  return activeClassNames.join(" ");
};

export const getRoute = (pathname: string) =>
  pathname.split(BASE_EXPENSES_PATH)[1];

export const capitalize = (text: string) =>
  `${text[0].toUpperCase()}${text.slice(1)}`;

export const setTableQueryVariables = (
  tableParams: typeof DEFAULT_TABLE_PARAMS
) => {
  const options = {
    ...DEFAULT_TABLE_PARAMS,
    ...tableParams,
  };

  return {
    resultsPerPage: options.resultsPerPage,
    pageNum: options.pageNum,
    sortParams: [
      {
        by: options.sortBy, // eslint-disable-line id-length
        direction: options.sortDirection.toUpperCase(),
      },
    ],
  };
};

export const getDollarsFromCents = (cents: number) => {
  return Number(cents / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const formatDate = (dateString: string) => {
  // dateString is in the format 2023-09-25
  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    return "";
  }

  const [year, month, day] = dateString.split("-");

  return `${month}/${day}/${year}`;
};

export const formatApiDate = (dateString: string) => {
  // dateString is in the format 09/25/2023
  if (!/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
    return "";
  }

  const [month, day, year] = dateString.split("/");

  return `${year}-${month}-${day}`;
};

export const handleDate = (dateString: string | null | undefined) => {
  if (!dateString) return "";

  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    return formatDate(dateString);
  }

  return dateString;
};

export const isEarliestPayDateOrAfter = (
  datePickerDate: string,
  earliestPayDate: string
) => {
  return (
    new Date(datePickerDate).getTime() >= new Date(earliestPayDate).getTime()
  );
};

export const parseExpenseForCreation = (
  expense: ExpenseDetailExpense | RequestDetailExpense
): ExpenseInput => {
  const updatedExpense: ExpenseInput = {};

  if (expense.category.attendees !== ExpenseCategoryFieldSetting.Hidden)
    updatedExpense.attendees = expense.attendees;

  if (expense.category.description !== ExpenseCategoryFieldSetting.Hidden)
    updatedExpense.description = expense.description;

  if (expense.category.merchant !== ExpenseCategoryFieldSetting.Hidden)
    updatedExpense.merchant = expense.merchant;

  if (expense.category.billableToClient !== ExpenseCategoryFieldSetting.Hidden)
    updatedExpense.isBillableToClient = expense.isBillableToClient;

  if (expense.category.expenseType !== ExpenseCategoryFieldSetting.Hidden)
    updatedExpense.expenseType = expense.expenseType;

  if (expense.category.receipt !== ExpenseCategoryFieldSetting.Hidden) {
    updatedExpense.receiptUuids = expense.receipts.map(
      (receipt) => receipt.uuid
    ) as string[];
  }

  return {
    ...updatedExpense,
    amount: expense.amount,
    projectUuid: expense.project?.uuid,
    categoryUuid: expense.category.uuid,
    transactionDate: expense.transactionDate,
    ...(expense?.uuid && { uuid: expense.uuid }),
  };
};

export const formatExpensesForSubmission = (expenses: ExpenseInput[]) =>
  expenses.map((expense) =>
    EXPENSE_INPUT_FIELD_NAMES.reduce<ExpenseInput>((expenseInput, key) => {
      const expenseInputKey = key as keyof typeof expense;
      const isUuidKey = expenseInputKey === "uuid";
      const isMerchantKey = expenseInputKey === "merchant";
      const isAttendeesKey = expenseInputKey === "attendees";
      const isDescriptionKey = expenseInputKey === "description";
      const isTransactionDateKey = expenseInputKey === "transactionDate";
      let value = expense[expenseInputKey];
      const isExistingExpense = isUuidKey && value;

      if (typeof value === "string") {
        if (isTransactionDateKey && value.includes("/")) {
          const [month, day, year] = value.split("/");

          value = `${year}-${month}-${day}`;
        }
      }

      if (isExistingExpense && isMerchantKey && value === "") {
        expenseInput["removeMerchant"] = true;
      } else if (isExistingExpense && isDescriptionKey && value === "") {
        expenseInput["removeDescription"] = true;
      } else if (isExistingExpense && isAttendeesKey && value === "") {
        expenseInput["removeAttendees"] = true;
      }

      if (value === null || value === "") {
        value = undefined;
      }

      return {
        ...expenseInput,
        [expenseInputKey]: value,
      };
    }, {})
  );
