import invoiceStyles from "../../../../CombinedInvoice.module.css";
import styles from "./DetailedSummaryCategory.module.css";
import { FC } from "react";
import { toCurrencyFormat } from "../../../../../utils";
import { useTranslation } from "react-i18next";
import {
  Category,
  CombinedInvoiceFee,
} from "pages/employer-of-record/combined-invoice/types";
import { DetailedSummaryEntry } from "../DetailedSummaryEntry";

const { padlessGrid, contentSection } = invoiceStyles;
const { sectionHeader, totalHeader, totalLabel, total } = styles;

interface DetailedSummaryCategoryProps {
  categories: Category[] | CombinedInvoiceFee[];
  currency: string;
  summaryIndex: number;
  categoryTotal: number;
}

export const DetailedSummaryCategory: FC<DetailedSummaryCategoryProps> = ({
  categories,
  currency,
  summaryIndex,
  categoryTotal,
}) => {
  const { t } = useTranslation();
  return (
    <div className={contentSection}>
      <div className={padlessGrid}>
        <div className={sectionHeader}>Types</div>
        <div className={totalHeader}>{t("Amount")}</div>
      </div>
      {categories.map((category) => (
        <DetailedSummaryEntry
          category={category}
          currency={currency}
          summaryIndex={summaryIndex}
          key={`${category.description}:${currency}:${summaryIndex} w`}
        />
      ))}
      <div className={padlessGrid}>
        <div className={totalLabel}>{t("Total Payments")}</div>
        <div className={total}>
          {toCurrencyFormat(categoryTotal, currency, true)}
        </div>
      </div>
    </div>
  );
};
