import { ReactElement, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import styles from "../../SeperationReviewPage.module.css";
import { ADDITIONAL_PAYMENTS_DETAILS_SECTION_COPY } from "./AdditionalPaymentsSectionDetails.constants";
import { SeparationFlowContext } from "pages/team-management/contexts";
import {
  BonusTypes,
  PaymentTypes,
} from "../../../SeparationPay/PaymentDetailsPage/components/PaymentDetailsForm/BonusTypeInputRow/BonusTypeInputRow.types";
import { PAYMENT_DETAILS_DEDUCTION_SETTINGS_OPTIONS } from "../../../SeparationPay/PaymentDetailsPage/components/PaymentDetailsForm/DeductionsSettingInputRow/DeductionsSettingInputRow.constants";
import {
  DeductionsSettingTypes,
  PayGroupTypes,
  TaxMethodTypes,
} from "../../../SeparationPay/PaymentDetailsPage/components/PaymentDetailsForm/TaxMethodInputRow/TaxMethodInputRow.types";
import {
  BONUS_TYPE_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
} from "../../../SeparationPay/PaymentDetailsPage/components/PaymentDetailsForm/BonusTypeInputRow/BonusTypeInputRow.constants";
import {
  PAYMENT_DETAILS_TAX_METHOD_OPTIONS,
  PAY_GROUP_OPTIONS,
} from "../../../SeparationPay/PaymentDetailsPage/components/PaymentDetailsForm/TaxMethodInputRow/TaxMethodInputRow.constants";
import {
  ADDITIONAL_PAYMENTS_PAYMENT_METHOD_OPTIONS,
  ADDITIONAL_PAYMENTS_PAYMENT_TYPE_OPTIONS,
  ADDITIONAL_PAYMENTS_PAY_FREQUENCY_OPTIONS,
} from "../../../SeparationPay/AdditionalPaymentsPage/AdditionalPaymentsPage.constants";
import {
  PayFrequencyTypes,
  PaymentMethodType,
  PaymentTypeTypes,
} from "../../../SeparationPay/AdditionalPaymentsPage/AdditionalPaymentsPage.types";
import { castAmountToNumber } from "../../../SeparationPay";

const {
  section,
  innerSection,
  row,
  column,
  splitRow,
  heading,
  spaced,
  subHeading,
  details,
} = styles;

export const AdditionalPaymentsDetailsSection = (): ReactElement => {
  const { memberUuid } = useParams();
  const { additionalPaymentsFormValues, paymentDetailsFormValues } = useContext(
    SeparationFlowContext
  );

  const paymentDetailRows =
    paymentDetailsFormValues &&
    additionalPaymentsFormValues &&
    Object.keys(paymentDetailsFormValues).map((key) => {
      const paymentBasicInfo = additionalPaymentsFormValues[Number(key)];
      const paymentDetails = paymentDetailsFormValues[Number(key)];

      return (
        <div data-testid={`payment-${key}`} className={innerSection} key={key}>
          <div
            data-testid={`payment-${key}-amount`}
            className={`${row} ${subHeading}`}
          >
            <div className={column}>
              {
                ADDITIONAL_PAYMENTS_PAYMENT_TYPE_OPTIONS[
                  paymentBasicInfo.type as PaymentTypeTypes
                ]
              }
            </div>
            <div className={column}>
              $
              {(
                castAmountToNumber(paymentBasicInfo.amount) *
                (Number(paymentBasicInfo.number_of_payments) || 1)
              ).toFixed(2)}
            </div>
          </div>

          <div
            data-testid={`payment-${key}-pay_date`}
            className={`${row} ${details}`}
          >
            <div className={column}>Initial payment date</div>
            <div className={column}>{paymentBasicInfo.pay_date}</div>
          </div>

          {paymentBasicInfo.repeat_payment === "yes" && (
            <>
              <div
                data-testid={`payment-${key}-pay_frequency`}
                className={`${row} ${details}`}
              >
                <div className={column}>Pay frequency</div>
                <div className={column}>
                  {
                    ADDITIONAL_PAYMENTS_PAY_FREQUENCY_OPTIONS[
                      paymentBasicInfo.pay_frequency as PayFrequencyTypes
                    ]
                  }
                </div>
              </div>
              <div
                data-testid={`payment-${key}-number_of_payments`}
                className={`${row} ${details}`}
              >
                <div className={column}>Number of payments</div>
                <div className={column}>
                  {paymentBasicInfo.number_of_payments}
                </div>
              </div>
            </>
          )}

          {paymentBasicInfo.payment_method && (
            <div
              data-testid={`payment-${key}-payment_method`}
              className={`${row} ${details}`}
            >
              <div className={column}>Payment method</div>
              <div className={column}>
                {
                  ADDITIONAL_PAYMENTS_PAYMENT_METHOD_OPTIONS[
                    paymentBasicInfo.payment_method as PaymentMethodType
                  ].label
                }
              </div>
            </div>
          )}

          {paymentBasicInfo.notes && (
            <div
              data-testid={`payment-${key}-notes`}
              className={`${row} ${details}`}
            >
              <div className={column}>Notes</div>
              <div className={column}>{paymentBasicInfo.notes}</div>
            </div>
          )}

          {paymentDetails.work_start_date && (
            <div
              data-testid={`payment-${key}-work_start_date`}
              className={`${row} ${details}`}
            >
              <div className={column}>Applicable work period</div>
              <div
                className={column}
              >{`${paymentDetails.work_start_date} - ${paymentDetails.work_end_date}`}</div>
            </div>
          )}

          {paymentDetails.bonus_type && (
            <div
              data-testid={`payment-${key}-bonus_type`}
              className={`${row} ${details}`}
            >
              <div className={column}>Bonus type</div>
              <div className={column}>
                {
                  BONUS_TYPE_OPTIONS[paymentDetails.bonus_type as BonusTypes]
                    .label
                }
              </div>
            </div>
          )}

          {paymentDetails.is_net_pay && (
            <div
              data-testid={`payment-${key}-is_net_pay`}
              className={`${row} ${details}`}
            >
              <div className={column}>Payment type</div>
              <div className={column}>
                {
                  PAYMENT_TYPE_OPTIONS[
                    paymentDetails.is_net_pay as PaymentTypes
                  ].label
                }
              </div>
            </div>
          )}

          {paymentDetails.pay_group && (
            <div
              data-testid={`payment-${key}-pay_group`}
              className={`${row} ${details}`}
            >
              <div className={column}>Bonus pay frequency</div>
              <div className={column}>
                {PAY_GROUP_OPTIONS[paymentDetails.pay_group as PayGroupTypes]}
              </div>
            </div>
          )}

          {paymentDetails.tax_method && (
            <div
              data-testid={`payment-${key}-tax_method`}
              className={`${row} ${details}`}
            >
              <div className={column}>Federal tax rate</div>
              <div className={column}>
                {
                  PAYMENT_DETAILS_TAX_METHOD_OPTIONS[
                    paymentDetails.tax_method as TaxMethodTypes
                  ].label
                }
              </div>
            </div>
          )}

          {paymentDetails.deductions_setting && (
            <div
              data-testid={`payment-${key}-deductions_setting`}
              className={`${row} ${details}`}
            >
              <div className={column}>Benefit deductions</div>
              <div className={column}>
                {
                  PAYMENT_DETAILS_DEDUCTION_SETTINGS_OPTIONS[
                    paymentDetails.deductions_setting as DeductionsSettingTypes
                  ].label
                }
              </div>
            </div>
          )}
        </div>
      );
    });

  return (
    <div data-testid="additional-payments-details-section" className={section}>
      <div className={splitRow}>
        <div className={`${heading} ${spaced}`}>
          {ADDITIONAL_PAYMENTS_DETAILS_SECTION_COPY.title}
        </div>
        <div>
          <Link
            to={`/team-management/separation-flow/${memberUuid}/additional-payments`}
          >
            {ADDITIONAL_PAYMENTS_DETAILS_SECTION_COPY.action}
          </Link>
        </div>
      </div>

      {paymentDetailRows}
    </div>
  );
};
