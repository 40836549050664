import { buildCsToolsPath, buildWebPath } from "lib/resource-finder";
import { useGetCompanyDisplayQuery } from "types/generated/operations";
import styles from "./user-menu-drawer-header.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";
import useAccountSettingsLink from "../../useAccountSettingsLink";

const {
  userMenuDrawerHeader,
  userName,
  companyDisplay,
  userLinks,
  closeButton,
} = styles;

interface UserMenuDrawerHeaderProps {
  isMultiProductNavEnabled: boolean;
  uuid: string;
  memberName: string;
  hasAccessToCsTools: boolean;
  hasAccessToProfileView: boolean;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
}

function UserMenuDrawerHeader({
  isMultiProductNavEnabled = false,
  uuid,
  memberName,
  hasAccessToCsTools,
  hasAccessToProfileView,
  onClose,
}: UserMenuDrawerHeaderProps) {
  const accountSettingsLink = useAccountSettingsLink();
  const { data, loading } = useGetCompanyDisplayQuery();
  if (loading || !data) {
    return <></>;
  }

  const companyName = data.authenticatedMember.company.name;

  return (
    <div className={`milo--root ${userMenuDrawerHeader}`}>
      <div>
        <div className={userName}>{memberName}</div>
        <div
          className={`${companyDisplay} ${
            isMultiProductNavEnabled ? styles.noPadding : ""
          }`}
        >
          {companyName}
        </div>

        {!isMultiProductNavEnabled && (
          <ul className={userLinks}>
            {hasAccessToProfileView && (
              <li>
                <a href={buildWebPath(`employee_profile/${uuid}`)}>
                  View profile
                </a>
              </li>
            )}
            <li>
              <a href={accountSettingsLink}>Account settings</a>
            </li>
            {hasAccessToCsTools && (
              <li>
                <a href={buildCsToolsPath()}>Internal controls</a>
              </li>
            )}
          </ul>
        )}
      </div>

      <button className={closeButton} onClick={onClose}>
        <SystemIcon iconName="close" size="large" />
      </button>
    </div>
  );
}

export default UserMenuDrawerHeader;
