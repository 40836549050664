import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { BASE_TEAM_MANAGEMENT_PATH, ROUTES } from "./constants";
import {
  AllStateRequirementsPage,
  InitialIntakePage,
  QuestionnairePage,
  StateSpecificRequirementsPage,
} from "./routes/SeparationFlow";
import { SeparationReasonOutlet } from "./outlets/SeparationReasonOutlet";
import { Page404 } from "pages";
import { SeparationReviewPage } from "./routes/SeparationFlow/SeparationReview/SeparationReviewPage";
import { AdditionalPaymentsPage } from "./routes/SeparationFlow/SeparationPay/AdditionalPaymentsPage";
import { PaymentDetailsPage } from "./routes/SeparationFlow/SeparationPay/PaymentDetailsPage";
import { useEffect } from "react";

export const TeamManagementApp = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Routes>
      <Route
        path={`${ROUTES.SEPARATION_FLOW}/:memberUuid`}
        element={<SeparationReasonOutlet />}
      >
        <Route
          path={ROUTES.SEPARATION_REASON_INITIAL_INTAKE}
          element={<InitialIntakePage />}
        />
        <Route
          path={ROUTES.SEPARATION_REASON_QUESTIONNAIRE}
          element={<QuestionnairePage />}
        />
        <Route
          path={ROUTES.SEPARATION_PAY_ADDITIONAL_PAYMENTS}
          element={<AdditionalPaymentsPage />}
        />
        <Route
          path={ROUTES.SEPARATION_PAY_PAYMENT_DETAILS}
          element={<PaymentDetailsPage />}
        />
        <Route
          path={ROUTES.SEPARATION_REVIEW}
          element={<SeparationReviewPage />}
        />
        <Route
          index
          element={
            <Navigate
              to={`${ROUTES.SEPARATION_REASON_INITIAL_INTAKE}?redirect=true`}
              replace
            />
          }
        />
      </Route>
      <Route path={ROUTES.SEPARATION_NOTICE}>
        <Route
          path={`${ROUTES.SEPARATION_NOTICE_STATE_REQUIREMENTS}/:memberUuid`}
          element={<StateSpecificRequirementsPage />}
        />
        <Route
          path={ROUTES.SEPARATION_NOTICE_ALL_STATE_REQUIREMENTS}
          element={<AllStateRequirementsPage />}
        />
        <Route
          index
          element={
            <Navigate
              to={ROUTES.SEPARATION_NOTICE_ALL_STATE_REQUIREMENTS}
              replace
            />
          }
        />
      </Route>
      <Route
        index
        element={<Navigate to={BASE_TEAM_MANAGEMENT_PATH} replace />}
      />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default TeamManagementApp;
