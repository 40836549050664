import { FC } from "react";
import { Alert } from "@justworkshr/milo-core";
import { useGetFeatureFlag } from "lib/launch-darkly";

const isDate = (string: string) => {
  return !isNaN(Date.parse(string));
};

const MaintenanceAlert: FC = () => {
  const { getFeatureFlag } = useGetFeatureFlag();
  const maintenanceFlagValue = getFeatureFlag(
    "people_analytics_planned_maintenance"
  ).toString();

  // The maintenanceFlagValue flag is a string, and will either be blank, "true", or a date.
  // If it is "false", the component returns null.
  // If it is "true", the component renders copy that says maintenance is currently active.
  // If it is a date, the component renders copy that says maintenance is scheduled for that date.
  return maintenanceFlagValue === "true" || isDate(maintenanceFlagValue) ? (
    <Alert
      color="new"
      dismissIconDescription="close alert"
      dismissible
      data-testid="maintenance-alert"
    >
      {maintenanceFlagValue === "true"
        ? "People Analytics will be unavailable for a few hours while we work to make your experience even better."
        : `People Analytics will be unavailable for a few hours beginning 5pm ET on ${maintenanceFlagValue} for scheduled maintenance.`}
    </Alert>
  ) : null;
};

export default MaintenanceAlert;
