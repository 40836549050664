export const EXPENSES_TURNED_OFF_TITLE = "Expenses are turned off";

export const EXPENSES_TURNED_OFF_COPY = (
  <p>
    Head to <strong>Settings</strong> to turn on Expenses so your team can
    submit reimbursement requests.
  </p>
);

export const NO_ACTIVE_REQUESTS_TITLE =
  "There are no reimbursement requests to approve.";

export const NO_ACTIVE_REQUESTS_COPY = <>Take a minute to catch your breath.</>;

export const ACTIVE_REQUESTS_TABLE_HEADERS = [
  {
    key: "",
    label: "",
    isSortable: false,
  },
  {
    key: "EMPLOYEE",
    label: "Employee",
    isSortable: false,
  },
  {
    key: "REQUEST_NAME",
    label: "Request name",
    isSortable: false,
  },
  {
    key: "DATE",
    label: "Submission",
    isSortable: true,
  },
  {
    key: "TOTAL_REIMBURSEMENT",
    label: "Total reimbursement",
    isSortable: false,
  },
  {
    key: "REVIEW_STATUS",
    label: "Review status",
    isSortable: false,
  },
];

export const ACTIVE_REQUESTS_TABLE_WITH_NON_REIMBURSABLE_HEADERS = [
  {
    key: "",
    label: "",
    isSortable: false,
  },
  {
    key: "EMPLOYEE",
    label: "Employee",
    isSortable: false,
  },
  {
    key: "REQUEST_NAME",
    label: "Request name",
    isSortable: false,
  },
  {
    key: "DATE",
    label: "Submission",
    isSortable: true,
  },
  {
    key: "NON_REIMBURSABLE",
    label: "Non-reimbursable",
    isSortable: false,
  },
  {
    key: "TOTAL_REIMBURSEMENT",
    label: "Total reimbursement",
    isSortable: false,
  },
  {
    key: "REVIEW_STATUS",
    label: "Review status",
    isSortable: false,
  },
];
