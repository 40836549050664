import { TabListTypes } from "./SettingsHeader.types";
import { RequestDetailsTabList } from "./components";
import { AdminTabList } from "pages/expenses/components";

export const getTabList = (type?: TabListTypes) => {
  switch (type) {
    case TabListTypes.ADMIN:
      return <AdminTabList />;
    case TabListTypes.REQUEST_DETAILS:
      return <RequestDetailsTabList />;
    case TabListTypes.REVIEWER_REQUEST_DETAILS:
      return <RequestDetailsTabList isReviewer />;
    default:
      return null;
  }
};
