import {
  FormField,
  RadioButton,
  RadioButtonGroup,
} from "@justworkshr/milo-form";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { ConfigType, SchemaType } from "./config";
import { ChangeEvent } from "react";

export default function FormFragment() {
  const { t } = useTranslation();
  const { errors, touched, handleBlur, setFieldValue, values } =
    useFormikContext<SchemaType>();

  const handleBooleanChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(event.target.name, event.target.value === "true");
  };

  const fieldErrorHandler = (name: keyof ConfigType["initialValues"]) =>
    errors[name] && touched[name] ? errors[name] : undefined;

  return (
    <>
      <FormField
        name="inIsRemoteWork"
        required
        label={t("Remote Work")}
        error={fieldErrorHandler("inIsRemoteWork")}
        message={t(
          "Please confirm if this employee is working remotely. For compliance reasons, we can only support employees who are working remotely at this point in time."
        )}
      >
        <RadioButtonGroup
          name="inIsRemoteWork"
          onChange={handleBooleanChange}
          onBlur={handleBlur}
          value={String(values.inIsRemoteWork)}
        >
          <RadioButton name="yes" value="true" label={t("Yes")} />
          <RadioButton name="no" value="false" label={t("No")} />
        </RadioButtonGroup>
      </FormField>
    </>
  );
}
