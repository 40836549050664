import { ReactElement, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import SharedForm from "../../shared/shared-form-outline";
import { FormikFormField } from "lib/formik/FormikFormField";
import { InjuryDate, MemberData, MemberType } from "../../../types";
import {
  DateInput,
  Select,
  SelectOption,
  TextInput,
} from "@justworkshr/milo-form";
import { SystemIcon } from "@justworkshr/milo-icons";
import FormContinue from "../../shared/form-continue";
import { landingPageSchema } from "../../validation-schema";
import styles from "./landing-page.module.css";
import SearchList from "./components/search-list";
import { useGetEligibleClaimsMembersByDateLazyQuery } from "types/generated/operations";
import SearchListCard from "./components/search-list-card";
import { Button } from "@justworkshr/milo-core";
import { Link } from "react-router-dom";
const { landingPageForm, header } = styles;

export default function LandingPageComponent({
  currentPageNumber,
  formData,
  goForward,
  exitForm,
  setData,
}: {
  currentPageNumber: number;
  setData: (e: InjuryDate) => void;
  goForward: () => void;
  exitForm: () => void;
  formData: InjuryDate;
}): ReactElement {
  const [getMembers, { data, loading: loadingMembers }] =
    useGetEligibleClaimsMembersByDateLazyQuery();

  const [selectedMember, setSelectedMember] = useState<MemberType>();

  useEffect(() => {
    const initialSelectedMember = data?.eligibleClaimsMembersByDate.find(
      (member) => {
        return member.friendlyFullName === formData.memberName;
      }
    );

    if (initialSelectedMember) {
      setSelectedMember(initialSelectedMember);
    }
  }, [data?.eligibleClaimsMembersByDate, formData.memberName]);

  function handleSelectMember(member: MemberType) {
    setSelectedMember(member);
  }

  function isValidDate(date: string) {
    return !isNaN(new Date(date).getTime());
  }

  function getMemberData(member: MemberType): MemberData {
    const { homeAddress, company } = member;
    const { primaryAddress, name: companyName } = company;

    return {
      adminEmail: "",
      adminPhone: member.company.primaryPhoneNumber,
      companyAddress: {
        city: primaryAddress?.city || "",
        postalCode: primaryAddress?.postalCode || "",
        state: primaryAddress?.state || "",
        street1: primaryAddress?.street1 || "",
        street2: primaryAddress?.street2 || "",
      },
      companyName,
      homeAddress: {
        city: homeAddress?.city || "",
        postalCode: homeAddress?.postalCode || "",
        state: homeAddress?.state || "",
        street1: homeAddress?.street1 || "",
        street2: homeAddress?.street2 || "",
      },
      id: member.id,
      uuid: member.uuid,
      memberName: member.friendlyFullName,
      workEmail: member.emailWork,
      contactPhone: member.contactPhone,
      title: member.deskTitle,
    };
  }

  async function handleInjuryDateChange(value: string) {
    if (value && isValidDate(value)) {
      getMembers({
        variables: {
          injuryDate: new Date(value).toISOString().split("T")[0],
        },
      });
    }
  }

  return (
    <Formik
      initialValues={formData}
      enableReinitialize
      validationSchema={landingPageSchema}
      onSubmit={(values) => {
        setData(values);
        goForward();
      }}
    >
      {({ values, setFieldValue, setValues }) => (
        <SharedForm
          currentPageNumber={currentPageNumber}
          exitFn={exitForm}
          key="exit-incident-page"
        >
          <div className={styles.landingPageWrapper}>
            <div className={header}>
              <p>
                You can exit this form anytime - your progress will be
                automatically saved as a draft.
              </p>
              <p>
                Before you begin, gather as much information as you can about
                this claim from your impacted employee. You can see our guide to
                claims to learn the process and requirements of a Workers
                {"\u2019"} Compensation Claim.
              </p>
              <p>
                <Link target="_blank" to="/workers-compensation-claims/about">
                  <Button size="sm" variant="ghost" rightIcon="external-link">
                    Guide to claims
                  </Button>
                </Link>
              </p>
              <div />
            </div>
            <h2>The Incident</h2>
            <div className={landingPageForm}>
              <Form data-testid="landing-page-form">
                <div className={styles.formWrapper}>
                  <div className={styles.formRow}>
                    <FormikFormField label="Date" name="date" required>
                      <DateInput
                        name="date"
                        onAccept={(injuryDate) => {
                          handleInjuryDateChange(injuryDate);
                          setFieldValue("date", injuryDate);
                        }}
                      />
                    </FormikFormField>

                    <FormikFormField label="Hour" name="hour">
                      <Select id="hour" name="hour">
                        <SelectOption value="" disabled>
                          00
                        </SelectOption>
                        {Array.from(new Array(24)).map((_, index: number) => {
                          const hour = index + 1;
                          const hourType = hour > 12 ? "PM" : "AM";
                          const hourDisplay = hour > 12 ? hour - 12 : hour;
                          return (
                            <SelectOption key={hour} value={hour}>
                              {`${hourDisplay
                                .toString()
                                .padStart(2, "0")} ${hourType}`}
                            </SelectOption>
                          );
                        })}
                      </Select>
                    </FormikFormField>

                    <FormikFormField label="Minutes" name="minute">
                      <Select id="minute" name="minute">
                        <SelectOption value="" disabled>
                          00
                        </SelectOption>
                        {Array.from(new Array(60)).map((_, index: number) => {
                          const min = index;
                          return (
                            <SelectOption key={min} value={min}>
                              {`${min.toString().padStart(2, "0")}`}
                            </SelectOption>
                          );
                        })}
                      </Select>
                    </FormikFormField>
                  </div>

                  {!selectedMember?.friendlyFullName ? (
                    <div
                      className={`${styles.searchInputWrapper} ${styles.formRow}`}
                    >
                      <FormikFormField
                        label="Who was impacted?"
                        name="memberName"
                        required
                      >
                        <TextInput
                          name="memberName"
                          placeholder="Search employee name"
                          disabled={!isValidDate(values.date || "")}
                        />
                      </FormikFormField>

                      <SystemIcon
                        className={styles.searchInputIcon}
                        iconName="search"
                        size="medium"
                      />

                      {isValidDate(values.date || "") && values.memberName && (
                        <SearchList
                          members={
                            data?.eligibleClaimsMembersByDate?.filter(
                              (member) =>
                                member.friendlyFullName
                                  .toLowerCase()
                                  .includes(values.memberName.toLowerCase())
                            ) || []
                          }
                          onSelectItem={(member) => {
                            const memberData = getMemberData(member);
                            setValues({
                              ...values,
                              ...memberData,
                            });
                            handleSelectMember(member);
                          }}
                          loading={loadingMembers}
                        />
                      )}
                    </div>
                  ) : (
                    <div className={styles.selectedMemberWrapper}>
                      <span>Who was injured in the accident?</span>

                      <SearchListCard
                        member={selectedMember}
                        onClose={() => setSelectedMember(undefined)}
                      />
                    </div>
                  )}

                  <FormContinue
                    continueKey="continue-incident-page"
                    backKey="back-incident-page"
                    backFn={exitForm}
                    shouldDisable={!(values.uuid || values.id)}
                    textBack="Cancel"
                    alignment="space-between"
                  />
                </div>
              </Form>
            </div>
          </div>
        </SharedForm>
      )}
    </Formik>
  );
}
