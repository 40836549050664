import {
  PanelsControlAction,
  PanelsControlState,
  PanelsControlActionType,
  initialState,
} from "./types";

export function reducer(
  state: PanelsControlState,
  action: PanelsControlAction
): PanelsControlState {
  switch (action.type) {
    case PanelsControlActionType.OPEN_USER_MENU_DRAWER:
      return {
        ...initialState,
        userMenuDrawerShown: true,
        userMenuDropdownShown: true,
      };
    case PanelsControlActionType.CLOSE_USER_MENU_DRAWER:
      return {
        ...initialState,
      };
    case PanelsControlActionType.OPEN_USER_MENU_DROPDOWN:
      return {
        ...initialState,
        userMenuDrawerShown: true,
        userMenuDropdownShown: true,
      };
    case PanelsControlActionType.CLOSE_USER_MENU_DROPDOWN:
      return {
        ...initialState,
      };
    case PanelsControlActionType.OPEN_ALERTS_DRAWER:
      return {
        ...initialState,
        userMenuDrawerShown: true,
        alertsDrawerShown: true,
      };
    case PanelsControlActionType.CLOSE_ALERTS_DRAWER_FROM_MOBILE:
      return {
        ...initialState,
        userMenuDrawerShown: true,
        userMenuDropdownShown: true,
      };
    case PanelsControlActionType.CLOSE_ALERTS_DRAWER_FROM_TABLET_OR_DESKTOP:
      return {
        ...initialState,
      };
    case PanelsControlActionType.OPEN_WHATS_NEW_DRAWER:
      return {
        ...initialState,
        userMenuDrawerShown: true,
        whatsNewDrawerShown: true,
      };
    case PanelsControlActionType.CLOSE_WHATS_NEW_DRAWER_FROM_MOBILE:
      return {
        ...initialState,
        userMenuDrawerShown: true,
        userMenuDropdownShown: true,
      };
    case PanelsControlActionType.CLOSE_WHATS_NEW_DRAWER_FROM_TABLET_OR_DESKTOP:
      return {
        ...initialState,
      };
    case PanelsControlActionType.OPEN_ADVANCED_HELP_DRAWER:
      return {
        ...initialState,
        userMenuDrawerShown: true,
        advancedHelpDrawerShown: true,
        advancedHelpDropdownShown: true,
      };
    case PanelsControlActionType.CLOSE_ADVANCED_HELP_DRAWER:
      return {
        ...initialState,
        userMenuDrawerShown: true,
        userMenuDropdownShown: true,
      };
    case PanelsControlActionType.OPEN_ADVANCED_HELP_DROPDOWN:
      return {
        ...initialState,
        userMenuDrawerShown: true,
        advancedHelpDrawerShown: true,
        advancedHelpDropdownShown: true,
      };
    case PanelsControlActionType.CLOSE_ADVANCED_HELP_DROPDOWN:
      return {
        ...initialState,
      };
    case PanelsControlActionType.OPEN_FORETHOUGHT_HELP_CHAT:
      return {
        ...initialState,
      };
    case PanelsControlActionType.OPEN_SWITCH_COMPANIES_DRAWER:
      return {
        ...initialState,
        userMenuDrawerShown: true,
        userMenuDropdownShown: true,
        switchCompaniesDrawerShown: true,
      };
    case PanelsControlActionType.CLOSE_SWITCH_COMPANIES_DRAWER:
      return {
        ...initialState,
        userMenuDrawerShown: true,
        userMenuDropdownShown: true,
      };
    case PanelsControlActionType.OPEN_SIDE_MENU_DRAWER:
      return {
        ...initialState,
        sideMenuDrawerShown: true,
      };
    case PanelsControlActionType.CLOSE_SIDE_MENU_DRAWER:
      return {
        ...initialState,
      };
    default:
      return initialState;
  }
}
