import { ReactElement, useState, useRef, useCallback } from "react";

import { Alert } from "@justworkshr/milo-core";

import LandingPageForm from "../components/landing-page";
import EmployeeConfirmationForm from "../components/employee-confirmation";
import WitnessesForm from "../components/witnesses";
import SubmittedPage from "../components/submitted-page";
import IncidentResponseForm from "../components/incident-response";
import ReviewPageForm from "../components/review-page";
import { useCreateClaimReport } from "./hooks/useCreateClaimReport";
import { ClaimReport } from "types/generated/operations";
import { initialPageData } from "../constants";
import {
  EmployeeConfirmation,
  FormIncident,
  InjuryDate,
  PageData,
  WorkStatus,
  IncidentResponse,
  WitnessInformation,
  AdminDetails,
} from "../../types";

import { formatStateToApi } from "./format-api";
import styles from "./claims-form-container.module.css";
import IncidentInformationForm from "../components/incident-information-form";
import WorkStatusPage from "../components/work-status-page";

const { modalWrapper } = styles;

export default function ClaimsFormContainer({
  closeModal,
  adminData,
  preloadedData,
  loadedStep,
  selectedDraftId,
}: {
  closeModal: () => void;
  adminData: AdminDetails;
  preloadedData: PageData | undefined;
  loadedStep: number;
  selectedDraftId: string;
}): ReactElement {
  // State Variables
  const [pageData, setPageData] = useState<PageData>(
    preloadedData || initialPageData
  );
  const [currentPage, setCurrentPage] = useState(loadedStep);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const recentSubmission = useRef<ClaimReport | null>();
  const [draftId, setDraftId] = useState<string>(selectedDraftId || "");

  const goBack = () => setCurrentPage((prev) => prev - 1);
  const goForward = () => setCurrentPage((prev) => prev + 1);

  // API Logic
  const { createClaimReport, createClaimReportLoading } = useCreateClaimReport({
    onSuccess: useCallback((draftId: string | null) => {
      if (draftId) {
        setDraftId(draftId);
      } else {
        goForward();
        setErrorMessage("");
      }
    }, []),
    onError: useCallback((err: string) => {
      setErrorMessage(err);
    }, []),
  });

  const submitCompleteApi = (data: PageData) => {
    const variables = {
      complete: true,
      data: formatStateToApi(data, adminData),
      draftId: draftId,
    };

    createClaimReport({ variables });
  };

  const submitDraftApi = async (data: PageData) => {
    const formattedData = formatStateToApi(data, adminData);
    if (JSON.stringify(formattedData) === JSON.stringify(recentSubmission))
      return;

    const variables = {
      complete: false,
      data: formattedData,
      draftId: draftId,
    };

    createClaimReport({ variables });
    recentSubmission.current = formattedData;
  };

  const submitDraftWrapper = (
    title: string,
    slice:
      | EmployeeConfirmation
      | IncidentResponse
      | InjuryDate
      | FormIncident
      | WitnessInformation
      | WorkStatus,
    total: PageData
  ) => {
    const submitDraft = {
      ...total,
      [title]: slice,
    };
    submitDraftApi(submitDraft);
  };

  const handleErrorMessage = () => {
    return errorMessage.length > 0 ? (
      <Alert
        color="destructive"
        dismissible
        dismissIconDescription="close alert"
        visible
        onDismiss={() => setErrorMessage("")}
      >
        {errorMessage}
      </Alert>
    ) : (
      <></>
    );
  };
  // Imported Components and Object

  const sharedProps = {
    goForward,
    goBack,
    exitForm: closeModal,
    currentPageNumber: currentPage,
    name: pageData.injuryDate.memberName,
    pageData,
    handleErrorMessage,
  };

  const landingPageForm = (
    <LandingPageForm
      currentPageNumber={currentPage}
      formData={pageData.injuryDate}
      goForward={goForward}
      exitForm={closeModal}
      setData={async (data: InjuryDate) => {
        await setPageData((prev) => ({ ...prev, injuryDate: data }));
      }}
    />
  );

  const employeeForm = (
    <EmployeeConfirmationForm
      setPageData={(data: EmployeeConfirmation) => {
        setPageData((prev) => ({ ...prev, personalInformation: data }));
      }}
      submitDraft={(section: EmployeeConfirmation, total: PageData) =>
        submitDraftWrapper("personalInformation", section, total)
      }
      {...sharedProps}
    />
  );

  const incidentForm = (
    <IncidentInformationForm
      setPageData={(data: FormIncident) => {
        setPageData((prev) => ({ ...prev, incidentInformation: data }));
      }}
      submitDraft={(section: FormIncident, total: PageData) =>
        submitDraftWrapper("incidentInformation", section, total)
      }
      {...sharedProps}
    />
  );

  const incidentResponseForm = (
    <IncidentResponseForm
      setPageData={(data: IncidentResponse) => {
        setPageData((prev) => ({ ...prev, incidentResponse: data }));
      }}
      submitDraft={(section: IncidentResponse, total: PageData) =>
        submitDraftWrapper("incidentResponse", section, total)
      }
      {...sharedProps}
    />
  );

  const witnessesForm = (
    <WitnessesForm
      setPageData={(data: WitnessInformation) => {
        setPageData((prev) => ({ ...prev, witnessInformation: data }));
      }}
      submitDraft={(section: WitnessInformation, total: PageData) =>
        submitDraftWrapper("witnessInformation", section, total)
      }
      {...sharedProps}
    />
  );

  const workStatusForm = (
    <WorkStatusPage
      setPageData={(data: WorkStatus) => {
        setPageData((prev) => ({ ...prev, workStatus: data }));
      }}
      submitDraft={(section: WorkStatus, total: PageData) =>
        submitDraftWrapper("workStatus", section, total)
      }
      {...sharedProps}
    />
  );

  const reviewPage = (
    <ReviewPageForm
      submitComplete={async (data: PageData) => {
        await submitCompleteApi(data);
      }}
      loading={createClaimReportLoading}
      {...sharedProps}
    />
  );

  const submittedPage = (
    <SubmittedPage
      onClick={closeModal}
      memberName={pageData.injuryDate.memberName}
    />
  );

  // Array that contains pages. Index of this array
  // is used to determine which page to show (aka currentPage).
  const pages = [
    landingPageForm,
    employeeForm,
    incidentForm,
    incidentResponseForm,
    witnessesForm,
    workStatusForm,
    reviewPage,
    submittedPage,
  ];

  // End: Imported Components and Object

  // Render method
  return <div className={modalWrapper}>{pages[currentPage]}</div>;
}
