import { ReactElement } from "react";
import { UserMenuMemberWithCompany, UserProductsAndCompanies } from "types";
import { buildWebPath, buildCsToolsPath } from "lib/resource-finder";
import styles from "./user-links.module.css";
import { useSwitchCompaniesMutation } from "types/generated/operations";
import { asyncThrow } from "@justworkshr/fe-utility-kit";
import { pkceIsEnabled, oktaAuth } from "app/okta";
import useAccountSettingsLink from "../useAccountSettingsLink";
import { useGetFeatureFlag } from "../../../../lib/launch-darkly";
import UserLink from "../user-link/user-link";

const { userLinksContainer, linksSection, userLinks, userLink, buttonLink } =
  styles;

let companyList: JSX.Element[];

interface UserLinksProps {
  uuid: string;
  csTools?: boolean;
  profileView?: boolean;
  otherCompaniesOfCurrentUser?: UserMenuMemberWithCompany[];
  productsAndCompanies?: UserProductsAndCompanies[];
}

export default function UserLinks({
  uuid,
  csTools,
  profileView,
  otherCompaniesOfCurrentUser,
  productsAndCompanies,
}: UserLinksProps): ReactElement {
  const { getFeatureFlag } = useGetFeatureFlag();
  const isMultiProductNavEnabled = getFeatureFlag(
    "postlogin-multinav-dropdown"
  );

  const [switchCompanies] = useSwitchCompaniesMutation();
  const accountSettingsLink = useAccountSettingsLink();

  if (isMultiProductNavEnabled) {
    return (
      <ul className={userLinksContainer} data-testid="user-links">
        <div className={linksSection}>
          {profileView && (
            <UserLink
              href={buildWebPath(`employee_profile/${uuid}`)}
              iconName="person"
              text="View profile"
            />
          )}

          <UserLink
            href={accountSettingsLink}
            iconName="gear"
            text="Account settings"
          />

          {csTools && (
            <UserLink
              href={buildCsToolsPath()}
              iconName="internal-controls"
              text="Internal controls"
            />
          )}
        </div>

        {productsAndCompanies && productsAndCompanies.length > 1 && (
          <div className={linksSection}>
            {productsAndCompanies.map((productCompany) => (
              <UserLink
                href={productCompany.redirectUrl}
                {...(productCompany.isSelected ? { iconName: "home" } : {})}
                text={productCompany.companyName}
                subText={productCompany.product}
                dimColor={!productCompany.isSelected}
                key={productCompany.companyCanonicalId}
              />
            ))}
          </div>
        )}

        <div className={linksSection}>
          <UserLink
            href={buildWebPath("/logout")}
            iconName="logout"
            text="Log out"
          />
        </div>
      </ul>
    );
  } else {
    let internalControls = <></>;

    if (csTools) {
      internalControls = (
        <a className={userLink} href={buildCsToolsPath()}>
          <span>Internal controls</span>
        </a>
      );
    }

    const handleSwitchCompanies = async (targetCompanyUuid: string) => {
      try {
        const response = await switchCompanies({
          variables: {
            targetCompanyUuid,
          },
        });
        if (response.data?.switchCompanies?.success) {
          const path = buildWebPath("/dashboard");
          window.location.assign(path);
        }
      } catch {
        // TODO: Better to show an error notification
        asyncThrow("Failed to switch companies");
      }
    };

    if (otherCompaniesOfCurrentUser) {
      companyList = otherCompaniesOfCurrentUser.map((element) => (
        <button
          key={element.company.uuid}
          className={[userLink, buttonLink].join(" ")}
          onClick={() => handleSwitchCompanies(element.company.uuid)}
        >
          <span>{element.company.name}</span>
        </button>
      ));
    }

    return (
      <ul title="User links" className={userLinks} data-testid="user-links">
        {profileView ? (
          <a
            className={userLink}
            href={buildWebPath(`employee_profile/${uuid}`)}
          >
            <span>View profile</span>
          </a>
        ) : (
          ""
        )}
        <a className={userLink} href={accountSettingsLink}>
          <span>Account settings</span>
        </a>
        {internalControls}
        {companyList}
        <button
          className={[userLink, buttonLink].join(" ")}
          onClick={async () => {
            if (pkceIsEnabled()) {
              return oktaAuth.signOut();
            }
            window.location.href = buildWebPath("/logout");
          }}
        >
          <span>Logout</span>
        </button>
      </ul>
    );
  }
}
