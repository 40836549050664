import { Badge } from "@justworkshr/milo-core";

export const CANCELED_STATUS_BADGE = <Badge role="neutral">Canceled</Badge>;

export const ADMIN_REVIEW_BADGE = <Badge role="warning">Admin Review</Badge>;

export const RETURNED_BADGE = <Badge role="negative">Returned</Badge>;

export const MANAGER_REVIEW_BADGE = (
  <Badge role="warning">Manager Review</Badge>
);
