import { ReactNode, FC, useState } from "react";
import { Button } from "@justworkshr/milo-core";
import styles from "./collapsible-panel.module.css";

interface CollapsiblePanelProps {
  title: string;
  children: ReactNode;
}

export const CollapsiblePanel: FC<CollapsiblePanelProps> = ({
  title,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <section
      className={styles.collapsiblePanel}
      data-testid="collapsible-panel"
    >
      <Button
        className={styles.collapsiblePanelTitle}
        variant="ghost"
        color="neutral"
        leftIcon={isOpen ? "chevron-up" : "chevron-down"}
        onClick={toggleIsOpen}
      >
        <h3>{title}</h3>
      </Button>
      <div
        aria-hidden={!isOpen}
        className={[
          styles.collapsiblePanelContent,
          isOpen ? styles.open : styles.closed,
        ].join(" ")}
      >
        {children}
      </div>
    </section>
  );
};

export default CollapsiblePanel;
