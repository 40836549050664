import { buildClockworkWebPath } from "lib/resource-finder";
import styles from "./LegacyTabList.module.css";
const { tabList, tab, activeTab, tabLink } = styles;

const LegacyTabList = () => (
  <div className={tabList}>
    <div className={tab}>
      <a className={tabLink} href={buildClockworkWebPath("/members/employees")}>
        Employees
      </a>
    </div>
    <div className={tab}>
      <a
        className={tabLink}
        href={buildClockworkWebPath("/members/contractors")}
      >
        Contractors
      </a>
    </div>
    <div className={tab}>
      <a className={tabLink} href={buildClockworkWebPath("/members/vendors")}>
        Vendors
      </a>
    </div>
    <div className={`${tab} ${activeTab}`}>Third-Party</div>
  </div>
);

export default LegacyTabList;
