import { SUBPATHS } from "../../constants";

// TO DO: Fetch from server
export const ACTIONS_LINKS = {
  CONTRACTORS_LIST: "/members/contractors",
  MAKE_PAYMENT: "/international/contractor/send_payment",
  VIEW_PAYMENTS: "/masspay/activity_list",
};

export const TABS = {
  [SUBPATHS.PERSONAL_INFO]: 0,
  [SUBPATHS.DOCUMENTS]: 1,
};
