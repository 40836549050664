import React from "react";

interface TableCellProps {
  children?: React.ReactNode;
  className?: string;
}

export const TableCell: React.FC<TableCellProps> = React.memo(
  ({ children, className }) => {
    return <td className={className}>{children}</td>;
  }
);
