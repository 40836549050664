import styles from "pages/company-settings/components/ReverseWireAuthTables.module.css";

const { table, row, leftCell, leftEnd, rightCell, rightEnd } = styles;

export default function ReverseWireAuthWfTable() {
  return (
    <table className={table}>
      <tr className={row}>
        <td className={leftCell}>
          <b>Upon receipt of instruction from</b>
        </td>
        <td className={rightCell}>
          <b>Wells Fargo</b>
        </td>
      </tr>
      <tr className={row}>
        <td className={leftCell}>Representative name</td>
        <td className={rightCell}>Wells Fargo</td>
      </tr>
      <tr className={row}>
        <td className={leftCell}>Representative address</td>
        <td className={rightCell}>
          464 California Street, San Francisco, CA 94104
        </td>
      </tr>
      <tr className={row}>
        <td className={leftCell}>Representative ABA number</td>
        <td className={rightCell}>121000248</td>
      </tr>
      <tr className={row}>
        <th className={leftCell}>
          <b>Authorization to credit</b>
        </th>
        <th className={rightCell}>
          <b>Wells Fargo</b>
        </th>
      </tr>
      <tr className={row}>
        <td className={leftCell}>Account number on representative’s books</td>
        <td className={rightCell}>4027383009</td>
      </tr>
      <tr className={row}>
        <td className={leftEnd}>Account or third party name</td>
        <td className={rightEnd}>Justworks Employment Group LLC</td>
      </tr>
    </table>
  );
}
