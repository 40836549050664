import classes from "./TransitionPage.module.css";
import benefitsClasses from "pages/benefits/benefits.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { Box, PageHeader, Tab, TabList } from "@justworkshr/milo-core";
import { useGetUserPermissionsQuery } from "types/generated/operations";
import { buildClockworkWebPath } from "lib/resource-finder";
import { PermissionsError } from "../../PermissionsError";
import OverviewTab from "./OverviewTab";
import FaqTab from "./FaqTab";
import EmpowerHeader from "../../../images/401k/empower-header.png";
import ResourcesBox from "../resources-box";

const TransitionPage = () => {
  const { tabId } = useParams();

  const navigate = useNavigate();

  const { data, loading } = useGetUserPermissionsQuery();
  if (loading) {
    return <></>;
  }

  const PERMISSION_BENEFITS = 1;
  if (!data?.currentMember?.permissions?.includes(PERMISSION_BENEFITS)) {
    return <PermissionsError />;
  }

  const goToTab = (tabId: string) => {
    navigate(`/benefits/setup/401k/transition/${tabId}`);
  };

  const tabIds = ["overview", "faq"];

  return (
    <div className={classes.benefits401kTransitionPage}>
      <PageHeader
        title="Coming soon: A new 401(k) provider"
        linkPrevious={
          <a href={buildClockworkWebPath("/benefits/view")}>
            Back to Benefits Center
          </a>
        }
      />

      <div className="milo--grid" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <div style={{ gridColumn: "span 8" }}>
          <div className={classes.heroImage}>
            <img src={EmpowerHeader} alt="Empower Logo" />
          </div>

          <TabList
            selected={tabIds.indexOf(tabId as string)}
            onChange={(tabIndex) => goToTab(tabIds[tabIndex])}
          >
            <Tab>Empower</Tab>
            <Tab>FAQ</Tab>
          </TabList>
          <div className={classes.tabContent}>
            {tabId === "overview" && <OverviewTab />}
            {tabId === "faq" && <FaqTab />}
          </div>
        </div>

        <div className={classes.sidebar} style={{ gridColumn: "span 4" }}>
          <Box
            border={{
              borderColor: "neutralSubtle",
              borderRadius: "md",
              borderWidth: "sm",
            }}
            padding="2xl"
          >
            <h3 className={benefitsClasses.benefitsHeader}>Have questions?</h3>
            <p className={benefitsClasses.benefitsParagraph}>
              If you have any questions during the blackout period, you can call
              the dedicated support line at{" "}
              {<a href="tel:8555954015">(855) 595-4015</a>} or visit{" "}
              {
                <a
                  href="https://www.empower.com/client/justworks/transition/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Empower Transition
                </a>
              }
              .
            </p>
          </Box>
          <ResourcesBox show_transition_link={true} />
        </div>
      </div>
    </div>
  );
};

export default TransitionPage;
