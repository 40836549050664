import { IntegrationsConfig } from "types/IntegrationConfig";
import { HR_PERMISSIONS } from "pages/pmt/integrations/integration-marketplace/constants";
import heroImage from "../../images/hero-images/carta-hero.svg";
import logo from "../../images/integration-partner-logos/carta-logo.svg";
import horizontalLogo from "../../images/horizontal-logos/carta-horizontal.svg";
import {
  DataAndPermissionsTab,
  OverviewTab,
} from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/TabsContentTemplate";
import {
  justworksPermissionsCopy,
  partnerPermissionsCopy,
} from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/constants";
import ExternalLinkButton from "../../partner-description-page/components/InstanceDetailsPanel/ExternalLinkButton/ExternalLinkButton";
import React from "react";
import { genDisconnectedPanelLegalInfoSharing } from "../../partner-description-page/components/InstanceDetailsPanel/DisconnectedPanelContentLegalInfo";

export const cartaConfig: IntegrationsConfig = {
  numAllowedInstances: 1,
  copy: {
    marketplaceCard: {
      logoUrl: logo,
      integrationName: "Carta",
      integrationDescription:
        "Automatically sync employee information from Justworks to Carta",
      integrationCategory: "Equity management",
      requiredPermissions: HR_PERMISSIONS,
      slug: "merge__carta_v1",
      pdpUrl: "/cf/integrations/merge__carta_v1",
    },
    pdp: {
      headerDescription:
        "From first check to IPO, over 40,000 companies use Carta to fundraise, issue equity, and stay compliant.",
      heroImageUrl: heroImage,
      horizontalLogoUrl: horizontalLogo,
      LegalInfo: genDisconnectedPanelLegalInfoSharing(
        <>
          Go to Carta <strong>Settings{">"}Integrations</strong> to get started.
        </>
      ),
      instanceDetailsPanelConfig: {
        hideLastActive: true,
        customDisconnectButton: (
          <ExternalLinkButton
            url="https://carta.com/"
            copy="Disconnect Integration"
            variant="outlined"
          />
        ),
        customSetupButton: (
          <ExternalLinkButton url="https://carta.com/" copy="Get Started" />
        ),
      },
      resourceCard: {
        links: [
          {
            title: "Carta in the Help Center",
            href: "https://help.justworks.com/hc/en-us/articles/27712644167579",
            systemIconName: "external-link",
            external: true,
          },
          {
            title: "Learn more about Carta",
            href: "https://carta.com/",
            systemIconName: "external-link",
            external: true,
          },
        ],
      },
      infoTabsConfig: {
        0: {
          title: "Overview",
          Component: OverviewTab,
        },
        1: {
          title: "Data & permissions",
          Component: DataAndPermissionsTab,
        },
      },
      infoTabsContent: {
        overviewTab: {
          header: "Carta + Justworks",
          subheader:
            "The Carta integration enables you to view salary and equity data in one place. It also streamlines HR processes, allowing you to track stakeholder changes, automatically sync records, and unlock the power of Carta Total Compensation.",
          list: [
            {
              summary: "Eliminate double entry",
              detail:
                "Save time with a single source of truth for employee information like terminations or address changes.",
            },
            {
              summary: "Simplify reconciliation",
              detail:
                "Justworks data syncs to Carta daily. If there’s a discrepancy between payroll and equity data, Carta will flag it and make it easy for you to reconcile.",
            },
            {
              summary: "Calculate taxes with ease",
              detail:
                "Easily understand employees’ tax impact with salary, location, and equity information in one place.",
            },
          ],
        },
        dataAndPermissionsTab: {
          dataSharedSection: {
            header: "What employee information will we share with Carta?",
            sharedDataGroups: [
              {
                header:
                  "While the integration is connected, Carta will automatically pull in the following data from Justworks:",
                sharedData: [
                  "First Name",
                  "Last Name",
                  "Work Email",
                  "Date of Birth",
                  "Home address",
                  "Work address",
                  "Job Title",
                  "Employment Type",
                  "Start Date",
                  "Termination Date",
                  "Compensation amounts",
                  "Compensation currency",
                ],
              },
            ],
          },
          permissionsSection: {
            header: "What permissions will you need for this integration?",
            list: [
              {
                summary: "In Justworks",
                detail: (
                  <div>
                    Admins need{" "}
                    <strong>{justworksPermissionsCopy(HR_PERMISSIONS)}</strong>{" "}
                    permissions.
                  </div>
                ),
              },
              {
                summary: "In Carta",
                detail: partnerPermissionsCopy("Carta", [
                  "Make changes to company account information",
                ]),
              },
            ],
          },
        },
      },
      disconnectModalContent: {
        title: "Disconnect from Carta",
        body: <span></span>,
        disconnectButtonText: "Disconnect",
      },
      allowedPdpActions: [],
    },
    setup: {
      estimatedSetupTime: "5",
      helpCenterLink:
        "https://help.justworks.com/hc/en-us/sections/360001292171-Account-Administration",
      stepperConfig: {},
      steps: [],
      instanceKeys: [],
      getCurrentStepFunction: () => 0,
    },
  },
};
