import {
  useState,
  useRef,
  useEffect,
  PropsWithChildren,
  useCallback,
} from "react";
import styles from "./collapsible.module.css";

const { collapsibleOpenContainer, collapsibleClosedContainer } = styles;

const Collapsible: React.FC<PropsWithChildren<{ open: boolean }>> = ({
  children,
  open,
}) => {
  const [height, setHeight] = useState("0px");
  const [showContent, setShowContent] = useState(false);
  const innerRef = useRef(null);
  const prevHeight = useRef(height);

  const updateHeight = useCallback(
    (val: string) => {
      prevHeight.current = height;
      setHeight(val);
    },
    [height, setHeight]
  );

  useEffect(() => {
    if (prevHeight.current === "auto" && height !== "auto") {
      setTimeout(() => updateHeight("0px"), 1);
    }
  }, [height, showContent, updateHeight]);

  useEffect(() => {
    setShowContent(open);
    if (innerRef?.current) {
      const element = innerRef.current as HTMLElement;
      updateHeight(open ? `${element.scrollHeight}px` : "0px");
    }
  }, [open, updateHeight]);

  const updateAfterTransition = () => {
    if (showContent) {
      updateHeight("auto");
    }
  };

  const collapsibleStyles = {
    "--height-collapsible": height,
    "--show-collapsible": showContent ? 1 : 0,
  } as React.CSSProperties;

  return (
    <div
      className={open ? collapsibleOpenContainer : collapsibleClosedContainer}
      style={collapsibleStyles}
      ref={innerRef}
      onTransitionEnd={() => updateAfterTransition()}
    >
      {children}
    </div>
  );
};

export default Collapsible;
