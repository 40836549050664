import { ReactElement } from "react";
import styles from "../../ContactInformation/ContactInformation.module.css";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import { formatPhoneNumber } from "pages/employer-of-record/employee-profile/utils";
import Row from "pages/employer-of-record/employee-profile/components/Row/Row";

export default function EmergencyContact(): ReactElement {
  const { profileInfo } = useProfileContextData();
  const emergencyContact =
    profileInfo?.eorEmployeeProfile.personalInfo?.emergencyContact;
  const workCountry: string =
    profileInfo?.eorEmployeeProfile?.employment?.workCountry || "";
  const phoneNumber = formatPhoneNumber(
    emergencyContact?.phoneNumber?.value,
    workCountry
  );

  const hasEmergencyContact =
    emergencyContact?.firstName &&
    emergencyContact?.lastName &&
    emergencyContact?.relationship;

  return (
    <>
      <Row label="Emergency contact" required={true}>
        {hasEmergencyContact ? (
          <div className={styles.boxWrapper}>
            <Row
              boldValue={true}
              value={`${emergencyContact?.firstName} ${emergencyContact?.lastName}`}
              tag={emergencyContact?.relationship || ""}
            ></Row>
            <Row
              value={phoneNumber}
              tag={emergencyContact?.phoneNumber?.type || ""}
            ></Row>
          </div>
        ) : undefined}
      </Row>
    </>
  );
}
