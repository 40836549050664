import { Spinner } from "@justworkshr/milo-core";
import { Page404 } from "pages/page-404";
import { ProfileInfoProvider } from "../contexts/ProfileInfoContext";
import Profile from "./Profile";
import styles from "./Profile.module.css";
import { TABNAMES } from "../constants";

type AuthorizedProfilePageProps = {
  loading: boolean;
  hasAccessToAdminProfileView?: boolean;
};

export default function AuthorizedProfilePage({
  loading,
  hasAccessToAdminProfileView,
}: AuthorizedProfilePageProps) {
  if (loading)
    return (
      <div className={styles.spinner}>
        <Spinner aria-label="loading" />
      </div>
    );

  if (!hasAccessToAdminProfileView) return <Page404 />;

  return (
    <ProfileInfoProvider>
      <Profile tabs={TABNAMES} />
    </ProfileInfoProvider>
  );
}
