import {
  FLAG_NAMES,
  VARIABLE_NAMES,
  flagIsEnabled,
  getEnvVariable,
} from "lib/environment";

let graphqlHost = flagIsEnabled(FLAG_NAMES.ENABLE_MESH)
  ? getEnvVariable(VARIABLE_NAMES.MESH_HOST)
  : import.meta.env.VITE_APP_GRAPHQL_HOST;

let clockworkwebHost = import.meta.env.VITE_APP_CLOCKWORK_WEB_HOST;
let staticAssetHost = import.meta.env.VITE_APP_CLOCKWORK_STATIC_ASSET_HOST;
const isPureLocal = import.meta.env.VITE_APP_MSW_ENABLED === "true";
const isProduction = window.location.hostname === "secure.justworks.com";
const isStagingReadOnly =
  window.location.hostname === "staging-read-only.justworks.com";
const isSandboxReadOnly =
  window.location.hostname === "sandbox-read-only.justworks.com";

function getHostFromPageHost(): string {
  const { port, protocol, hostname } = window.location;
  const portToUse = port ? `:${port}` : "";
  return `${protocol}//${hostname}${portToUse}`;
}

function buildPath(base: string, path: string): string {
  const normalizeSlashPath = path.replace("//", "/");
  const cleanPath = normalizeSlashPath.startsWith("/")
    ? normalizeSlashPath
    : `/${normalizeSlashPath}`;
  return `${base}${cleanPath}`;
}

function buildGraphQLPath(path: string): string {
  if (!graphqlHost || isPureLocal) {
    graphqlHost = getHostFromPageHost();
  }
  return buildPath(graphqlHost, path);
}

function buildWebPath(path: string): string {
  return path.includes("/cf/") ? path : buildClockworkWebPath(path);
}

function buildClockworkWebPath(path: string): string {
  if (!clockworkwebHost || isPureLocal) {
    clockworkwebHost = getHostFromPageHost();
  }
  return buildPath(clockworkwebHost, path);
}

function buildStaticAssetPath(path: string): string {
  if (!staticAssetHost || isPureLocal) {
    staticAssetHost = getHostFromPageHost();
  }
  return buildPath(staticAssetHost, `/${path}`);
}

function buildCsToolsPath(): string {
  if (isProduction) {
    return "https://cstools.justworks.com/internal";
  } else if (isStagingReadOnly) {
    return "https://staging.justworks.com/internal";
  } else if (isSandboxReadOnly) {
    return "https://sandbox.justworks.com/internal";
  } else {
    return buildWebPath("/internal");
  }
}

export {
  buildWebPath,
  buildCsToolsPath,
  buildGraphQLPath,
  buildStaticAssetPath,
  buildClockworkWebPath,
};
