import { ValidationError } from "stdnum/src/exceptions";
import { ValidateReturn } from "stdnum/lib/cjs/types";

const myTaxIdValidator = {
  name: "Tax Identification Number",
  localName: "Nombor Cukai Pendapatan",
  abbreviation: "TIN",

  compact(input: string): string {
    return input.replace(/[^0-9a-z]/gi, "").toUpperCase();
  },

  format(input: string): string {
    return this.prefix(input) + " " + this.digits(input);
  },

  validate(input: string): ValidateReturn {
    const prefixes = ["IG", "SG", "OG"];

    if (!prefixes.includes(this.prefix(input))) {
      return {
        isValid: false,
        error: new ValidationError("TIN is invalid"),
      };
    }

    if (!/^\d+$/.test(this.digits(input))) {
      return {
        isValid: false,
        error: new ValidationError("TIN is invalid"),
      };
    }

    if (this.digits(input).length > 11) {
      return {
        isValid: false,
        error: new ValidationError("TIN is invalid"),
      };
    }

    return {
      isValid: true,
      compact: this.compact(input),
      isIndividual: true,
      isCompany: false,
    };
  },

  prefix(input: string): string {
    return this.compact(input).slice(0, 2);
  },

  digits(input: string): string {
    return this.compact(input).slice(2);
  },
};

export { myTaxIdValidator };
