import { FC } from "react";
import { TextInput } from "@justworkshr/milo-form";
import { SystemIcon } from "@justworkshr/milo-icons";
import * as DatetimeModule from "react-datetime";
import { DatetimepickerProps } from "react-datetime";
import "./Time.css";
import styles from "./TimePicker.module.css";
import { formatTimePickerTime } from "./TimePicker.utils";

const Datetime: FC<DatetimepickerProps> = (() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let value = DatetimeModule as any;
  while ("default" in value) value = value.default;
  return value;
})();

const { TimePickerWrapper, TimePickerInputWrapper, TimePickerClockIcon } =
  styles;

interface TimePickerProps {
  id: string;
  name: string;
  value?: string | undefined;
  disabled?: boolean;
  placeholder?: string;
  isValidDate?: (currentDate: Date) => boolean;
  handleChange: (dateValue: string) => void;
  className?: string;
  "data-testid"?: string;
}

export const TimePicker: FC<TimePickerProps> = ({
  id,
  name,
  value,
  handleChange,
  placeholder,
  isValidDate,
  disabled = false,
  className,
  "data-testid": dataTestId,
}) => {
  return (
    <div
      id="TeamManagementTimePicker"
      className={`${TimePickerWrapper}${className && " " + className}`}
    >
      <Datetime
        data-testid={dataTestId}
        closeOnSelect
        value={value}
        timeFormat="hh:mm A"
        dateFormat={false}
        isValidDate={isValidDate}
        onChange={(date) => handleChange(formatTimePickerTime(date.toString()))}
        timeConstraints={{ minutes: { min: 0, max: 60, step: 15 } }}
        renderInput={(props, openCalendar) => (
          <div className={TimePickerInputWrapper}>
            <TextInput
              {...props}
              id={id}
              name={name}
              autoComplete="off"
              disabled={disabled}
              placeholder={placeholder}
              required={false}
              type="text"
            />
            <SystemIcon
              size="medium"
              iconName="clock"
              className={TimePickerClockIcon}
              onClick={() => {
                if (!disabled) {
                  openCalendar();
                }
              }}
            />
          </div>
        )}
      />
    </div>
  );
};
