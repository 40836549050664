import { buildWebPath } from "lib/resource-finder";

export default function useNavigateToBankAccountEditing() {
  return () =>
    window.location.assign(
      // This URL will redirect to the reauthentication page if the user has not already reauthenticated.
      // If the user has already reauthenticated, this URL will redirect to the clockface bank account edit page.
      buildWebPath("/account_settings/edit_bank_accounts")
    );
}
