import { ReactNode, FC } from "react";
import { Link } from "react-router-dom";
import { Button, Spinner } from "@justworkshr/milo-core";
import styles from "./company-overview-card.module.css";
import { DisplayIcon } from "@justworkshr/milo-icons";

interface CompanyOverviewCardProps {
  title: string;
  insightsPath?: string;
  children?: ReactNode;
}

const CompanyOverviewCard: FC<CompanyOverviewCardProps> = ({
  title,
  insightsPath,
  children,
}) => {
  const getIcon = () => {
    switch (title) {
      case "Workforce":
        return <DisplayIcon iconName="arm" color="yellow" size="small" />;
      case "Compensation":
        return <DisplayIcon iconName="money-sign" color="green" size="small" />;
      case "Time off":
        return <DisplayIcon iconName="palm-tree" color="red" size="small" />;
      case "Health benefits":
        return <DisplayIcon iconName="heart-in-circle" size="small" />;
    }
  };

  return (
    <div
      data-testid="company-overview-card"
      className={styles.companyOverviewCard}
    >
      <div className={styles.companyOverviewCardHeader}>
        <h2>
          {getIcon()}
          {title}
        </h2>
        {insightsPath ? (
          <Button
            variant="ghost"
            rightIcon="arrow-right"
            size="md"
            color="brand"
            as={Link}
            to={insightsPath}
          >
            More insights
          </Button>
        ) : undefined}
      </div>

      {children ? (
        children
      ) : (
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default CompanyOverviewCard;
