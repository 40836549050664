import {
  INITIAL_REVIEW_STATUS,
  ADMIN_APPROVABLE_REQUEST_STATUSES,
} from "pages/expenses/constants/request-status";
import { useApprovers } from "pages/expenses/hooks";
import {
  useRequestDetail,
  useGlobalConfigurationStore,
} from "pages/expenses/store";
import { CATEGORY_STATUSES } from "pages/expenses/constants";
import { parseExpenseForCreation } from "pages/expenses/utils";
import { RequestDetailExpense, RequestDetailReceipt } from "types/Expenses";
import { ExpenseInput } from "types/generated/operations";

export const useReimbursementSummary = () => {
  const {
    requestDetail: {
      request: { status },
      reviews: { approved, reviewed },
      metadata: { isApproverView },
    },
  } = useRequestDetail();

  const { permissions } = useGlobalConfigurationStore();
  const { adminApprovers, managerApprovers } = useApprovers();

  const managerReviewApprover = reviewed?.[0]?.member;
  const adminReviewApprover = approved?.[0]?.member;
  const hasExpensesPermission =
    permissions.isAdmin || permissions.isManager || permissions.isReporter;
  const managerApproversNames = managerApprovers.map(
    (manager) => manager.friendlyFullName
  );

  const isAdminApproverView =
    isApproverView &&
    permissions.isAdmin &&
    status &&
    ADMIN_APPROVABLE_REQUEST_STATUSES.includes(status);

  const isManagerApproverView =
    isApproverView &&
    permissions.isManager &&
    !permissions.isAdminAndManager &&
    status === INITIAL_REVIEW_STATUS;

  return {
    adminApprovers,
    isAdminApproverView,
    adminReviewApprover,
    isManagerApproverView,
    hasExpensesPermission,
    managerReviewApprover,
    managerApproversNames,
  };
};

export const parseExpensesForCreation = (
  currentExpenses: RequestDetailExpense[],
  {
    detachedExpenses,
    receiptUuidsToRemove,
  }: {
    detachedExpenses: RequestDetailExpense[];
    receiptUuidsToRemove: {
      [expenseUuid: string]: RequestDetailReceipt[];
    } | null;
  }
): ExpenseInput[] => {
  const detachedExpensesUuids = detachedExpenses.map((expense) => expense.uuid);

  return [...currentExpenses, ...detachedExpenses].map((expense) => {
    const parsedExpense = parseExpenseForCreation(expense);

    if (expense.category.receipt !== CATEGORY_STATUSES.HIDDEN) {
      if (receiptUuidsToRemove && expense?.uuid) {
        const receiptsToRemove = receiptUuidsToRemove[expense.uuid];

        if (receiptsToRemove) {
          parsedExpense.receiptUuidsToRemove = receiptsToRemove.map(
            (receipt) => receipt.uuid
          );
        }
      }
    }

    if (detachedExpensesUuids) {
      parsedExpense.detach = detachedExpensesUuids.includes(expense.uuid);
    }

    return parsedExpense;
  });
};
