import { useGetExpensesActivationQuery } from "types/generated/operations";

export const useGetExpensesActivation = () => {
  const { error, loading, data } = useGetExpensesActivationQuery();

  const hasJoinedExpenses =
    data?.currentMember?.company?.companySetting?.value === "true";

  return {
    error,
    loading,
    hasJoinedExpenses,
  };
};
