import styles from "./EmployeeOverviewPage.module.css";
import currentTabStyles from "../employee-blackout-page/CurrentTab.module.css";
import benefitsClasses from "pages/benefits/benefits.module.css";
import { Box, Button, PageHeader } from "@justworkshr/milo-core";
import { useGetUser401kInfoQuery } from "types/generated/operations";
import { buildClockworkWebPath } from "lib/resource-finder";
import EmpowerLogoSidebar from "../../../images/401k/empower-logo-sidebar.png";
import ResourcesBox from "../resources-box";
import { SystemIcon } from "@justworkshr/milo-icons";

const formatCentsToDollars = (cents: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(cents / 100.0);
};

const ssoLink =
  "https://login.justworks.com/home/justworks-ciam_empower_1/0oaddy2hh3m8mapGp697/alnddyv70lgGrXfkv697";

const EmployeeOverviewPage = () => {
  const { data, loading } = useGetUser401kInfoQuery();

  const ytdContributions = data?.currentMember.member401kInfo.ytdContributions;
  const currentRates =
    data?.currentMember.member401kInfo.latestActiveDeferralRates;

  if (loading) {
    return <></>;
  }

  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.employeeOverviewPage}>
      <PageHeader
        title="401(k) with Empower"
        border={false}
        linkPrevious={
          <a href={buildClockworkWebPath("/my_benefits/retirement_planning")}>
            Back to Benefits Center
          </a>
        }
      />
      <div className="milo--grid" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <div className={styles.infoBoxes} style={{ gridColumn: "span 8" }}>
          <div className={currentTabStyles.currentInfoBox}>
            <Box
              padding={"lg"}
              border={{
                borderColor: "neutralSubtle",
                borderWidth: "sm",
                borderRadius: "md",
              }}
            >
              <div className={currentTabStyles.infoTitle}>
                {currentYear} year-to-date (YTD) contributions
              </div>
              <p>
                Year-to-date contributions are the total amount of contributions
                you’ve deferred to any 401(k).
              </p>
              <div className={currentTabStyles.table}>
                <div className={currentTabStyles.tableHeaders}>Account</div>
                <div className={currentTabStyles.tableHeaders}>
                  Your contributions
                </div>
                <div className={currentTabStyles.tableData}>Pre-Justworks</div>
                <div className={currentTabStyles.tableData}>
                  <div className={currentTabStyles.additionalCell}>
                    <div>
                      {formatCentsToDollars(ytdContributions?.additional || 0)}
                    </div>
                    <div className={currentTabStyles.editLink}>
                      <a
                        href="/cf/benefits/setup/401k/employee/additional-contributions"
                        style={{ display: "flex" }}
                      >
                        <SystemIcon iconName="edit" color="brand" />
                        Edit
                      </a>
                    </div>
                  </div>
                </div>
                <div className={currentTabStyles.tableData}>
                  External provider
                </div>
                <div className={currentTabStyles.tableData}>
                  {formatCentsToDollars(ytdContributions?.manual || 0)}
                </div>
                <div className={currentTabStyles.tableData}>Empower</div>
                <div className={currentTabStyles.tableData}>
                  {formatCentsToDollars(ytdContributions?.slavic || 0)}
                </div>
              </div>
            </Box>
          </div>
          <div className={currentTabStyles.currentInfoBox}>
            <Box
              padding={"lg"}
              border={{
                borderColor: "neutralSubtle",
                borderWidth: "sm",
                borderRadius: "md",
              }}
            >
              <div className={currentTabStyles.infoTitle}>
                Contribution rates
              </div>
              <p>
                Changes to your contribution rates must be made 6 business days
                before you receive a payment, otherwise they will go into effect
                for the following payroll.
              </p>
              <div className={currentTabStyles.table}>
                <div className={currentTabStyles.tableHeaders}>
                  Payment type
                </div>
                <div className={currentTabStyles.tableHeaders}>
                  Rate (per paycheck)
                </div>
                <div className={currentTabStyles.tableData}>Salary</div>
                <div className={currentTabStyles.tableData}>
                  {currentRates?.salaryPretaxAmount ? (
                    <div>
                      {formatCentsToDollars(currentRates.salaryPretaxAmount)}{" "}
                      pre-tax
                    </div>
                  ) : null}
                  {currentRates?.salaryPretaxPercent ? (
                    <div>{currentRates.salaryPretaxPercent}% pre-tax</div>
                  ) : null}
                  {currentRates?.salaryAftertaxAmount ? (
                    <div>
                      {formatCentsToDollars(currentRates.salaryAftertaxAmount)}{" "}
                      after-tax
                    </div>
                  ) : null}
                  {currentRates?.salaryAftertaxPercent ? (
                    <div>{currentRates.salaryAftertaxPercent}% after-tax</div>
                  ) : null}
                </div>
                <div className={currentTabStyles.tableData}>
                  Bonuses, commissions, and other{" "}
                  <span style={{ whiteSpace: "nowrap" }}>supplemental pay</span>
                </div>
                <div className={currentTabStyles.tableData}>
                  {currentRates?.supplementalPretaxAmount ? (
                    <div>
                      {formatCentsToDollars(
                        currentRates.supplementalPretaxAmount
                      )}{" "}
                      pre-tax
                    </div>
                  ) : null}
                  {currentRates?.supplementalPretaxPercent ? (
                    <div>{currentRates.supplementalPretaxPercent}% pre-tax</div>
                  ) : null}
                  {currentRates?.supplementalAftertaxAmount ? (
                    <div>
                      {formatCentsToDollars(
                        currentRates.supplementalAftertaxAmount
                      )}{" "}
                      after-tax
                    </div>
                  ) : null}
                  {currentRates?.supplementalAftertaxPercent ? (
                    <div>
                      {currentRates.supplementalAftertaxPercent}% after-tax
                    </div>
                  ) : null}
                </div>
              </div>
            </Box>
          </div>
        </div>

        <div className={styles.sidebar} style={{ gridColumn: "span 4" }}>
          <Box
            border={{
              borderColor: "neutralSubtle",
              borderRadius: "md",
              borderWidth: "sm",
            }}
            padding="2xl"
          >
            <div className={styles.logo}>
              <img src={EmpowerLogoSidebar} alt="Empower Logo" />
            </div>
            <h3 className={benefitsClasses.benefitsHeader}>
              Need to edit your account?
            </h3>
            <p className={benefitsClasses.benefitsParagraph}>
              Head over to Empower to make any changes to your deferrals and
              check up to date balances.
            </p>
            <Button
              variant="filled"
              style={{ width: "100%" }}
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href={ssoLink}
              className={styles.loginButton}
              data-dd-action-name="Click Empower SSO Link"
            >
              Login to Empower
            </Button>
          </Box>
          <ResourcesBox />
        </div>
      </div>
    </div>
  );
};

export default EmployeeOverviewPage;
