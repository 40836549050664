import { datadogRum } from "@datadog/browser-rum";
import { getEnvironmentName } from "lib/environment";

export type VisierEvent = "call" | "trigger" | "error" | "debug" | "session";

export const logVisierEvent = (logData: {
  eventType: VisierEvent;
  message: string;
  currentMemberUuid: string;
}) => {
  if (getEnvironmentName() !== "production") {
    console.log(
      {
        call: "CALL VISIER FUNCTION: ",
        trigger: "TRIGGER VISIER EVENT: ",
        error: "RECEIVE VISIER ERROR MESSAGE: ",
        debug: "RECEIVE VISIER DEBUG MESSAGE: ",
        session: "RECEIVE VISIER SESSION MESSAGE: ",
      }[logData.eventType],
      logData.message
    );
  } else if (
    logData.eventType === "error" ||
    logData.eventType === "debug" ||
    logData.eventType === "session"
  ) {
    datadogRum.addAction("Visier Message", logData);
  }
};
