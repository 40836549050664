import ImageCard from "pages/pmt/integrations/partner-setup-page/components/ImageCard/ImageCard";
import { Button, Spinner } from "@justworkshr/milo-core";
import styles from "./JazzHRSetup.module.css";
import { StepperHelpers } from "pages/pmt/integrations/partner-setup-page/hooks/useStepper";
import { useState, useEffect } from "react";
import React from "react";
import { StatusMessage } from "./utils";
import useSetupPageData from "../../../partner-setup-page/hooks/useSetupPageData";
import { Link } from "react-router-dom";

const {
  wrapper,
  spinnerSpacing,
  retryButton,
  backButton,
  testIntegrationButtons,
  finishButton,
  finishButtonText,
} = styles;

export const JazzHRSetupStepThreeRenderer: React.FC<StepperHelpers> = ({
  ...stepperHelpers
}) => {
  const { goToNextStep } = stepperHelpers;
  const { loading, instancesByKey, refetch } = useSetupPageData(
    "jazzhr",
    ["verify_candidate_hired"],
    1
  );
  const [integrationSetupSuccess, setIntegrationSetupSuccess] = useState(false);

  useEffect(() => {
    if (!loading && instancesByKey) {
      const instance = instancesByKey[0].instances[0];
      if (instance && instance.status === "success") {
        setIntegrationSetupSuccess(true);
      }
    } else {
      setIntegrationSetupSuccess(false);
    }
  }, [goToNextStep, loading, instancesByKey]);

  return (
    <ImageCard header="Testing the integration">
      <div className={wrapper}>
        <span>We're checking to make sure everything is working properly.</span>
        {loading && (
          <span className={spinnerSpacing}>
            <Spinner aria-label="loading" />
          </span>
        )}
        {!loading && (
          <>
            {!integrationSetupSuccess && (
              <div className={testIntegrationButtons}>
                <Button
                  type="button"
                  className={retryButton}
                  onClick={() => refetch()}
                >
                  Retry
                </Button>
                <Button
                  variant="outlined"
                  type="button"
                  className={backButton}
                  onClick={stepperHelpers.goToPreviousStep}
                >
                  Back to create webhook
                </Button>
              </div>
            )}
            <StatusMessage
              integrationSetupSuccess={integrationSetupSuccess}
              currentStep={3}
            />
            {integrationSetupSuccess && (
              <Button
                className={finishButton}
                as={Link}
                to={`/integrations/jazzhr`}
                state={{ alert: "setupSuccess" }}
              >
                <span className={finishButtonText}>Finish setup</span>
              </Button>
            )}
          </>
        )}
      </div>
    </ImageCard>
  );
};

const JazzHRSetupStepThree: React.FC<StepperHelpers> = (
  stepperHelpers: StepperHelpers
) => {
  return <JazzHRSetupStepThreeRenderer {...stepperHelpers} />;
};

export default JazzHRSetupStepThree;
