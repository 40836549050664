import { AlertTypes, prependBasePath } from "pages/expenses/constants";
import { useFeedbackBannerStore } from "pages/expenses/store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateUnattachedExpenseMutation } from "types/generated/operations";

export const useCreateUnattachedExpense = () => {
  const navigate = useNavigate();
  const { setFeedbackBanner } = useFeedbackBannerStore();

  const [
    createUnattachedExpense,
    {
      data: createUnattachedExpenseData,
      loading: createUnattachedExpenseLoading,
    },
  ] = useCreateUnattachedExpenseMutation();

  useEffect(() => {
    if (
      !createUnattachedExpenseLoading &&
      createUnattachedExpenseData?.createUnattachedExpense?.success
    ) {
      setFeedbackBanner({
        type: AlertTypes.SUCCESS,
        show: true,
        message: `Your expense was successfully created.`,
      });

      navigate(prependBasePath("EXPENSE_LIBRARY"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUnattachedExpenseLoading, createUnattachedExpenseData]);

  return {
    createUnattachedExpense,
  };
};
