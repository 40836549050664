export enum TerminationTypeTypes {
  VOLUNTARY = "Voluntary",
  INVOLUNTARY = "Involuntary",
}
export enum InvoluntaryTerminationReasonTypes {
  ABSENTEEISM = "IAT",
  VIOLATION_OF_COMPANY_POLICY = "IVP",
  PERFORMANCE = "IJP",
  PROPERTY_DAMAGE = "IPD",
  ALTERCATION = "IVA",
  DISABILITY = "IDD",
  END_OF_CONTRACT = "ICT",
  LAYOFF = "ILO",
  TRANSFER = "ITRE",
  DECEASED = "IED",
}

export enum VoluntaryTerminationReasonTypes {
  OTHER_EMPLOYMENT = "VSE",
  HEALTH = "VHR",
  QUIT_BEFORE_DISCHARGED = "VRD",
  PERSONAL_REASONS = "VPR",
  NO_RETURN_AFTER_LEAVE = "VFRL",
  JOB_ABANDONMENT = "VJA",
  RETIREMENT = "VRET",
  WORKING_CONDITIONS = "VWC",
  VACCINE_REQUIREMENT = "VCV",
  WORKERS_COMPENSATION_SETTLEMENT = "VWCS",
  UNEXPLAINED_DEPARTURE = "VDK",
}

export interface InitialIntakeFormValues {
  termination_date: string;
  final_work_date: string;
  termination_type: TerminationTypeTypes | "";
  termination_reason:
    | InvoluntaryTerminationReasonTypes
    | VoluntaryTerminationReasonTypes
    | "";
}
