import { FormField, Select, SelectOption } from "@justworkshr/milo-form";
import React from "react";
import { ExpenseProject } from "types/Expenses";

interface ProjectProps {
  projects: ExpenseProject[];
  selectedProject: Partial<ExpenseProject> | null;
  handleProjectChange: (projectName: "projectName") => void;
}

export const Project: React.FC<ProjectProps> = React.memo(
  ({ projects, selectedProject, handleProjectChange }) => {
    const hasProjectsVisible = projects.some((project) => project.visible);

    if (!hasProjectsVisible) return null;

    const visibleProjects = projects.filter((project) => project.visible);

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
      handleProjectChange(event.target.value as "projectName");

    return (
      <FormField label="Project">
        <Select
          onChange={handleSelectChange}
          value={selectedProject?.projectName || ""}
          data-testid="project-dropdown"
        >
          <SelectOption value="" disabled>
            Select...
          </SelectOption>
          {visibleProjects.map((project) => {
            const displayName = project?.displayName || project?.projectName;

            return (
              <SelectOption
                key={project.uuid}
                value={project.projectName}
                data-testid="project-option"
              >
                {displayName}
              </SelectOption>
            );
          })}
        </Select>
      </FormField>
    );
  }
);
