import * as Yup from "yup";
import {
  renderApplicableWorkPeriodInput,
  renderTaxesAndDeductionsSection,
  renderTaxMethodInput,
} from "./AdditionalPaymentsPage";
import {
  isBonusPayment,
  isSeverancePayment,
  renderPaymentMethodInput,
} from "../SeparationPay/AdditionalPaymentsPage/components/AdditionalPaymentsForm/AdditionalPaymentsForm.helpers";
import { TaxMethodTypes } from "./PaymentDetailsPage/components/PaymentDetailsForm/TaxMethodInputRow/TaxMethodInputRow.types";

export interface AdditionalPaymentsFormValues {
  amount: string;
  notes?: string;
  number_of_payments: string;
  pay_date: string;
  pay_frequency: string;
  payment_method?: string;
  repeat_payment: "yes" | "no";
  type: string;
  termination_date: Date;
  earliest_pay_date: Date;
}

export interface PaymentDetailsFormValues {
  type: string;
  work_start_date?: string;
  work_end_date?: string;
  bonus_type?: string;
  is_net_pay?: string;
  pay_group?: string;
  tax_method?: string;
  deductions_setting?: string;
}

export const DOLLAR_NOTATION_REGEX =
  /^\$?(\d{1,3}(,\d{3})*|(\d+))(\.\d{1,2})?$/;

export const castAmountToNumber = (amount: string) => {
  return Number(amount.replace(/[^0-9.-]+/g, ""));
};

export const AdditionalPaymentsSchema = Yup.object().shape({
  amount: Yup.string()
    .required("Required")
    .test("is-valid-amount", "Invalid dollar amount.", function (value) {
      if (!DOLLAR_NOTATION_REGEX.test(value)) {
        return this.createError({
          message: "Invalid dollar amount.",
        });
      }

      if (castAmountToNumber(value) <= 0) {
        return this.createError({
          message: "Amount must be greater than 0.",
        });
      }

      return true;
    }),
  termination_date: Yup.date(),
  pay_date: Yup.date()
    .required("Required")
    .test("is-valid-paydate", "Invalid date", function (value) {
      const type = this.resolve(Yup.ref("type")) as string;
      const isGreaterThanTerminationDate =
        value > (this.resolve(Yup.ref("termination_date")) as Date);
      const isGreaterThanOrEqualToPayDate =
        value >= (this.resolve(Yup.ref("earliest_pay_date")) as Date);
      const isBusinessDay = this.resolve(Yup.ref("is_business_day")) as boolean;
      const nextBusinessDay = this.resolve(
        Yup.ref("next_business_day")
      ) as string;
      const previousBusinessDay = this.resolve(
        Yup.ref("previous_business_day")
      ) as string;

      if (!isGreaterThanOrEqualToPayDate) {
        return this.createError({
          message: "Date must be on or after the next payroll date",
        });
      }

      if (type && isSeverancePayment(type) && !isGreaterThanTerminationDate) {
        return this.createError({
          message: "Date must be after the termination date",
        });
      }

      if (!isBusinessDay) {
        return this.createError({
          message: `Date must be a business day. The next business day is ${nextBusinessDay}, the previous business day is ${previousBusinessDay}`,
        });
      }

      return true;
    }),
  type: Yup.string().required("Required"),
  payment_method: Yup.string().when("type", {
    is: (type: string) => renderPaymentMethodInput(type),
    then: () => Yup.string().required("Required"),
  }),
  number_of_payments: Yup.number().when("repeat_payment", {
    is: (repeat_payment: string) => repeat_payment === "yes",
    then: () =>
      Yup.number()
        .required("Required")
        .typeError("Must be a number")
        .moreThan(0, "Number of payments must be greater than 0.")
        .lessThan(
          11,
          "Head to the payments center to schedule more than 10 payments."
        ),
  }),
  pay_frequency: Yup.string().when("repeat_payment", {
    is: (repeat_payment: string) => repeat_payment === "yes",
    then: () => Yup.string().required("Required"),
  }),
});

export const PaymentDetailsSchema = Yup.object().shape({
  work_start_date: Yup.date()
    .typeError("Invalid date")
    .when("type", {
      is: (type: string) => renderApplicableWorkPeriodInput(type),
      then: () => Yup.date().required("Required"),
    }),
  work_end_date: Yup.date()
    .typeError("Invalid date")
    .when("type", ([type]: string[], schema) =>
      renderApplicableWorkPeriodInput(type)
        ? schema.required("Required")
        : schema
    )
    .when("work_start_date", ([work_start_date]: string[], schema) =>
      work_start_date
        ? schema.min(Yup.ref("work_start_date"), "Must be after start date")
        : schema
    ),
  bonus_type: Yup.string().when("type", ([type]: string[], schema) =>
    isBonusPayment(type) ? schema.required("Required") : schema
  ),
  is_net_pay: Yup.string().when("type", ([type]: string[], schema) =>
    isBonusPayment(type) ? schema.required("Required") : schema
  ),
  pay_group: Yup.string().when("tax_method", {
    is: (tax_method: string) => tax_method === TaxMethodTypes.CUMULATIVE,
    then: () => Yup.string().required("Required"),
  }),
  tax_method: Yup.string().when("type", {
    is: (type: string) => renderTaxMethodInput(type),
    then: () => Yup.string().required("Required"),
  }),
  deductions_setting: Yup.string().when("type", {
    is: (type: string) => renderTaxesAndDeductionsSection(type),
    then: () => Yup.string().required("Required"),
  }),
});

export const scrollToFirstError = () => {
  setTimeout(() => {
    const errorContainer = document.querySelector("div[role=alert]");
    if (errorContainer) {
      const formFieldContainer = errorContainer.parentNode
        ?.parentNode as Element;

      formFieldContainer?.scrollIntoView &&
        formFieldContainer.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
    }
  }, 0);
};
