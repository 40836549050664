import { ReactElement, MouseEvent } from "react";
import Button from "app-frame/common/Button";
import Avatar from "app-frame/common/Avatar";
import { buildWebPath } from "lib/resource-finder";
import { AccountManager } from "types";
import styles from "./help-content.module.css";
import ChatBubbles from "./icons/chat-bubbles.svg?react";
import GenericAvatar from "./icons/avatar-color-blue.svg?react";
import { usePanelsControlContext } from "app-frame/header/context/panels-control-context-provider";
import { PanelsControlActionType } from "app-frame/header/context/panels-control-context-provider/types";

const {
  helpcontent,
  infobox,
  offline,
  resourcesButton,
  accountManagerChatButton,
} = styles;

export interface HelpContentProps {
  chatDisabled?: boolean;
  accountManager: AccountManager;
}

export default function HelpContent({
  chatDisabled,
  accountManager,
}: HelpContentProps): ReactElement {
  const { dispatch } = usePanelsControlContext();

  const chatLink = function (event: MouseEvent<HTMLElement>) {
    event.preventDefault();
    if (window.Forethought) {
      window.Forethought("widget", "open");
    }
    dispatch({ type: PanelsControlActionType.OPEN_FORETHOUGHT_HELP_CHAT });
  };
  const hasAccountManager = accountManager?.isDesignatedAccountManager;
  const resourcesLink = (
    <Button
      type={"link"}
      href={buildWebPath("/help")}
      className={resourcesButton}
    >
      Browse Resources
    </Button>
  );

  const justworksContact = hasAccountManager ? (
    <section className={infobox}>
      <h4>Your Justworks contact</h4>
      {accountManager.primaryPhotoUrl || accountManager.secondaryPhotoUrl ? (
        <Avatar
          name={accountManager.name || ""}
          width={40}
          height={40}
          photoUrl={
            accountManager.secondaryPhotoUrl
              ? accountManager.secondaryPhotoUrl
              : accountManager.primaryPhotoUrl
              ? accountManager.primaryPhotoUrl
              : undefined
          }
        />
      ) : (
        <GenericAvatar />
      )}
      <strong data-dd-action-name="Click on Account Manager name">
        {accountManager.name}
      </strong>
      <a
        href={`mailto:${accountManager.emailExternal}`}
        data-dd-action-name="Click on Account Manager email"
      >
        {accountManager.emailExternal}
      </a>
      <p data-dd-action-name="Click on Account Manager phone number">
        {accountManager.phone}
      </p>
    </section>
  ) : (
    <></>
  );

  const chatOnline =
    !hasAccountManager && !chatDisabled ? (
      <section className={infobox}>
        <h4>Contact Us</h4>
        <ChatBubbles />
        <strong>Support and live chat</strong>
        <Button mode={"text"} onClick={chatLink}>
          Launch support
        </Button>
      </section>
    ) : (
      <></>
    );

  const chatOffline =
    !hasAccountManager && chatDisabled ? (
      <section className={infobox}>
        <h4>Contact Us</h4>
        <ChatBubbles />
        <strong className={offline}>Chat is offline</strong>
        <a href="mailto:support@justworks.com">support@justworks.com</a>
        <p>(888) 534-1711</p>
      </section>
    ) : (
      <></>
    );

  const accountManagerChat = hasAccountManager ? (
    <Button
      mode={"secondary"}
      onClick={chatLink}
      className={accountManagerChatButton}
    >
      Support and Live Chat
    </Button>
  ) : (
    <></>
  );

  return (
    <div className={helpcontent}>
      <h2>Need help?</h2>
      <p>Quickly find answers to your product, compliance, and HR questions.</p>
      {resourcesLink}
      <p>How else can we help?</p>
      {justworksContact}
      {chatOnline}
      {chatOffline}
      {accountManagerChat}
    </div>
  );
}
