import { buildClockworkWebPath } from "lib/resource-finder";
import { useGetFeatureFlagQuery } from "types/generated/operations";

export const useFesk = () => {
  const { data } = useGetFeatureFlagQuery({
    variables: { flag: "expenses-fesk" },
  });

  const isFeskEnabled = data?.featureFlag?.enabled;

  if (isFeskEnabled) {
    const redirectPath = location.pathname.split("/cf")[1];
    window.location.href = buildClockworkWebPath(redirectPath);
  }

  return isFeskEnabled;
};
