import React from "react";
import { FormField } from "@justworkshr/milo-form";
import styles from "../../ExpenseDetailsPage.module.css";
import { DatePicker, formatDatePickerDate } from "pages/expenses/components";
import { isFutureDate } from "../../ExpenseDetailsPage.utils";
import {
  TRANSACTION_DATE_FORMAT_ERROR,
  TRANSACTION_DATE_FUTURE_DATE_ERROR,
} from "../../ExpenseDetailsPage.constants";

const { ExpenseDetailsPageTransactionDateWrapper } = styles;

const TRANSACTIONDATE_KEY = "transactionDate";

interface TransactionDateProps {
  transactionDate: {
    value: string;
    error: string;
  };
  handleFormValue: ({
    key,
    value,
  }: {
    key: typeof TRANSACTIONDATE_KEY;
    value: string;
  }) => void;
  handleFormError: ({
    key,
    error,
  }: {
    key: typeof TRANSACTIONDATE_KEY;
    error: string;
  }) => void;
}

export const TransactionDate: React.FC<TransactionDateProps> = React.memo(
  ({ transactionDate, handleFormValue, handleFormError }) => {
    const handleIsValidDate = (currentDate: string) => {
      const formattedDate = formatDatePickerDate(currentDate);

      if (isFutureDate(formattedDate)) {
        return false;
      }

      return true;
    };

    const handleTransactionDateChange = (dateValue: string) => {
      if (isFutureDate(dateValue)) {
        handleFormError({
          key: TRANSACTIONDATE_KEY,
          error: TRANSACTION_DATE_FUTURE_DATE_ERROR,
        });
      } else if (!/^\d{2}\/\d{2}\/\d{4}$/.test(dateValue)) {
        handleFormError({
          key: TRANSACTIONDATE_KEY,
          error: TRANSACTION_DATE_FORMAT_ERROR,
        });
      } else {
        handleFormError({
          key: TRANSACTIONDATE_KEY,
          error: "",
        });
      }

      handleFormValue({ key: TRANSACTIONDATE_KEY, value: dateValue });
    };

    return (
      <div className={ExpenseDetailsPageTransactionDateWrapper}>
        <FormField
          required
          label="Transaction date"
          name={TRANSACTIONDATE_KEY}
          error={transactionDate.error}
        >
          <DatePicker
            id={TRANSACTIONDATE_KEY}
            name={TRANSACTIONDATE_KEY}
            value={transactionDate.value}
            isValidDate={handleIsValidDate}
            onChange={handleTransactionDateChange}
          />
        </FormField>
      </div>
    );
  }
);
