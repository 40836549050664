import React from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { TabList, Tab } from "@justworkshr/milo-core";
import { BASE_EXPENSES_PATH, ROUTES } from "pages/expenses/constants";
import { getSelectedRequestDetailsTab } from "./RequestDetailsTabList.utils";

interface RequestDetailsTabListProps {
  isReviewer?: boolean;
}

export const RequestDetailsTabList: React.FC<RequestDetailsTabListProps> = ({
  isReviewer = false,
}) => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const selected = getSelectedRequestDetailsTab(pathname);

  const requestDetailsUrl = isReviewer
    ? ROUTES.REVIEW_REQUEST.replace(":request_uuid", uuid as string)
    : uuid;

  const handleChange = (index: number) => {
    if (index === 0) {
      return navigate(`${BASE_EXPENSES_PATH}/${requestDetailsUrl}`);
    } else {
      return navigate(
        `${BASE_EXPENSES_PATH}/${ROUTES.AUDIT_LOG.replace(
          ":request_uuid",
          uuid as string
        )}`
      );
    }
  };

  return (
    <TabList selected={selected} onChange={handleChange}>
      <Tab data-testid="request-detail">Request Detail</Tab>
      <Tab data-testid="view-details">Activity</Tab>
    </TabList>
  );
};

export default RequestDetailsTabList;
