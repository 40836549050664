import { IntegrationsConfig } from "types/IntegrationConfig";
import { HR_PERMISSIONS } from "pages/pmt/integrations/integration-marketplace/constants";
import heroImage from "../../images/hero-images/culture-amp-hero.svg";

import logo from "pages/pmt/integrations/images/integration-partner-logos/culture-amp-logo.svg";
import horizontalLogo from "../../images/horizontal-logos/culture-amp-horizontal.svg";
import {
  DataAndPermissionsTab,
  OverviewTab,
} from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/TabsContentTemplate";
import {
  justworksPermissionsCopy,
  partnerPermissionsCopy,
} from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/constants";
import ExternalLinkButton from "../../partner-description-page/components/InstanceDetailsPanel/ExternalLinkButton/ExternalLinkButton";
import React from "react";
import { genDisconnectedPanelLegalInfoSharing } from "../../partner-description-page/components/InstanceDetailsPanel/DisconnectedPanelContentLegalInfo";

export const cultureAmpConfig: IntegrationsConfig = {
  numAllowedInstances: 1,
  copy: {
    marketplaceCard: {
      logoUrl: logo,
      integrationName: "Culture Amp",
      integrationDescription:
        "Automatically sync employee information from Justworks to Culture Amp",
      integrationCategory: "Performance management",
      requiredPermissions: HR_PERMISSIONS,
      slug: "merge__culture_amp_v1",
      pdpUrl: "/cf/integrations/merge__culture_amp_v1",
    },
    pdp: {
      headerDescription:
        "Get trusted data, tools, and partnership on one platform to make the best people decisions to improve employee engagement, performance, and development.",
      heroImageUrl: heroImage,
      horizontalLogoUrl: horizontalLogo,
      LegalInfo: genDisconnectedPanelLegalInfoSharing(
        <>
          Go to Culture Amp <strong>Settings{">"}Data Integrations</strong> to
          get started.
        </>
      ),
      instanceDetailsPanelConfig: {
        hideLastActive: true,
        customDisconnectButton: (
          <ExternalLinkButton
            url="https://www.cultureamp.com/"
            copy="Disconnect Integration"
            variant="outlined"
          />
        ),
        customSetupButton: (
          <ExternalLinkButton
            url="https://www.cultureamp.com/"
            copy="Get Started"
          />
        ),
      },
      resourceCard: {
        links: [
          {
            title: "Culture Amp in the Help Center",
            href: "https://help.justworks.com/hc/en-us/articles/27712930708763",
            systemIconName: "external-link",
            external: true,
          },
          {
            title: "Learn more about Culture Amp",
            href: "https://www.cultureamp.com/",
            systemIconName: "external-link",
            external: true,
          },
        ],
      },
      infoTabsConfig: {
        0: {
          title: "Overview",
          Component: OverviewTab,
        },
        1: {
          title: "Data & permissions",
          Component: DataAndPermissionsTab,
        },
      },
      infoTabsContent: {
        overviewTab: {
          header: "Culture Amp + Justworks",
          subheader:
            "Import employee data directly from Justworks to Culture Amp. The setup process is straightforward and can be completed quickly without technical support.",
          list: [
            {
              summary: "Automated account management",
              detail:
                "Manage employee information in Justworks, while this integration seamlessly provisions and deprovisions users to their Culture Amp accounts.",
            },
            {
              summary: "Daily data-syncing",
              detail:
                "Eliminate manual work and save time. Data syncs from Justworks to Culture Amp every 24 hours, ensuring accurate, up-to-date information.",
            },
          ],
        },
        dataAndPermissionsTab: {
          dataSharedSection: {
            header: "What employee information will we share with Culture Amp?",
            sharedDataGroups: [
              {
                header:
                  "While the integration is connected, Culture Amp will automatically pull in the following data from Justworks:",
                sharedData: [
                  "First Name",
                  "Preferred Name",
                  "Last Name",
                  "Work Email",
                  "Phone Number",
                  "Home location",
                  "Work Location",
                  "Manager",
                  "Start date",
                  "Employment status",
                  "Termination date",
                  "Remote data",
                  "Department",
                  "Gender",
                  "Ethnicity",
                  "Date of birth",
                ],
              },
            ],
          },
          permissionsSection: {
            header: "What permissions will you need for this integration?",
            list: [
              {
                summary: "In Justworks",
                detail: (
                  <div>
                    Admins need{" "}
                    <strong>{justworksPermissionsCopy(HR_PERMISSIONS)}</strong>{" "}
                    permissions.
                  </div>
                ),
              },
              {
                summary: "In Culture Amp",
                detail: partnerPermissionsCopy("Culture Amp", [
                  "Account admin",
                ]),
              },
            ],
          },
        },
      },
      disconnectModalContent: {
        title: "Disconnect from Culture Amp",
        body: <span></span>,
        disconnectButtonText: "Disconnect",
      },
      allowedPdpActions: [],
    },
    setup: {
      estimatedSetupTime: "5",
      helpCenterLink:
        "https://help.justworks.com/hc/en-us/sections/360001292171-Account-Administration",
      stepperConfig: {},
      steps: [],
      instanceKeys: [],
      getCurrentStepFunction: () => 0,
    },
  },
};
