import styles from "../../CategoriesPage.module.css";
import { useCategoriesStore } from "pages/expenses/store";

import { ExpenseCategory } from "types/Expenses";
import { Button } from "@justworkshr/milo-core";
import { CheckboxInput } from "@justworkshr/milo-form";
import { prependBasePath } from "pages/expenses/constants";
import { useNavigate } from "react-router-dom";

const {
  CheckboxInputWrapper,
  CategoriesCardRowLastColumn,
  CategoriesCardRowModifyButtonsWrapper,
} = styles;

interface CategoriesCardRowProps {
  category: ExpenseCategory;
  handleCustomCategoryModal?: () => void;
}

export const CategoriesCardRow: React.FC<CategoriesCardRowProps> = ({
  category,
  handleCustomCategoryModal,
}) => {
  const navigate = useNavigate();

  const {
    categories: { categories },
    setCategories,
    setActiveCategory,
  } = useCategoriesStore();

  const handleCategoryChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const updatedCategories = categories.map((prevCategory) => {
      return prevCategory.name === target.name
        ? { ...prevCategory, visible: target.checked }
        : prevCategory;
    });

    setCategories({ categories: updatedCategories });
  };

  const handleCategoryDelete = () => {
    const updatedCategories = categories.filter(
      (prevCategory) => prevCategory.name !== category.name
    );

    setCategories({ categories: updatedCategories });
  };

  const handleCategoryNameEdit = () => {
    if (handleCustomCategoryModal) {
      handleCustomCategoryModal();
    }

    setActiveCategory({ activeCategory: category });
  };

  const renderModifyCategoryButtons = () => {
    const showAdditionalModifyButtons = category.custom && category.uuid === "";

    return (
      <div className={CategoriesCardRowModifyButtonsWrapper}>
        {showAdditionalModifyButtons && (
          <>
            <Button
              type="button"
              variant="ghost"
              leftIcon="trash"
              color="destructive"
              disabled={!category.visible}
              onClick={handleCategoryDelete}
              data-testid="delete-custom-category-button"
            >
              Delete
            </Button>
            <Button
              type="button"
              variant="ghost"
              leftIcon="edit"
              disabled={!category.visible}
              onClick={handleCategoryNameEdit}
              data-testid="edit-custom-category-name-button"
            >
              Edit name
            </Button>
          </>
        )}
        <Button
          type="button"
          variant="ghost"
          leftIcon="edit"
          disabled={!category.visible}
          onClick={() => {
            setActiveCategory({ activeCategory: category });
            navigate(prependBasePath("SUBMISSION_REQUIREMENTS"));
          }}
          data-testid="edit-rules-button"
        >
          Edit rules
        </Button>
      </div>
    );
  };

  return (
    <tr>
      <td className={CheckboxInputWrapper}>
        <CheckboxInput
          id={category.name}
          name={category.name}
          value={category.name}
          checked={category.visible}
          onChange={handleCategoryChange}
          data-testid="category-checkbox"
        />
      </td>
      <td>{category.name}</td>
      <td className={CategoriesCardRowLastColumn}>
        {renderModifyCategoryButtons()}
      </td>
    </tr>
  );
};

export default CategoriesCardRow;
