import {
  SetProjectsPayload,
  SetActiveProjectPayload,
} from "../constants/projects";

export enum ProjectsActionTypes {
  SET_PROJECTS = "SET_PROJECTS",
  SET_ACTIVE_PROJECT = "SET_ACTIVE_PROJECT",
}

export type ProjectsActions =
  | {
      type: ProjectsActionTypes.SET_PROJECTS;
      payload: SetProjectsPayload;
    }
  | {
      type: ProjectsActionTypes.SET_ACTIVE_PROJECT;
      payload: SetActiveProjectPayload;
    };
