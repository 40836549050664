import { ReactElement } from "react";
import styles from "../waiver-edit.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";

interface WaiverEditProps {
  currentStep: number;
  steps: string[];
}

const { formNavigation, highlighted } = styles;

export default function WaiverEditStepper({
  currentStep,
  steps,
}: WaiverEditProps): ReactElement {
  return (
    <div className={formNavigation}>
      {steps.map((step, index) => (
        <>
          {currentStep > index && (
            <SystemIcon iconName="check" color="success" />
          )}
          {currentStep <= index && index !== 0 && (
            <SystemIcon iconName="chevron-right" />
          )}
          <p className={`${index === currentStep ? highlighted : ""}`}>
            {step}
          </p>
        </>
      ))}
    </div>
  );
}
