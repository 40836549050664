import { usePlaidLink, PlaidLinkError } from "react-plaid-link";
import { useState, useEffect } from "react";
import { useGenerateLinkToken } from "./useGenerateLinkToken";

interface UsePlaidModalProps {
  onSubmit: (publicToken: string) => void;
  onError?: (error: Error | PlaidLinkError) => void;
}

const usePlaidModal = ({ onSubmit, onError }: UsePlaidModalProps) => {
  const [plaidError, setPlaidError] = useState<Error | null>(null);
  const { mutateAsync, isPending } = useGenerateLinkToken();
  const [token, setToken] = useState<string | null>(null);

  const { open, ready } = usePlaidLink({
    token: token,
    onSuccess: (publicToken: string) => {
      onSubmit(publicToken);
    },
    onExit: (err) => {
      if (err != null && onError) {
        onError(err);
      }
      setToken(null);
    },
  });

  const openPlaidModal = async () => {
    try {
      const data = await mutateAsync();
      setToken(data?.link_session_token);
    } catch (err) {
      window.scrollTo(0, 0);
      setPlaidError(err as Error);
    }
  };

  useEffect(() => {
    if (token && ready) {
      open();
    }
  }, [token, ready, open]);

  return { openPlaidModal, isPlaidModalLoading: isPending, plaidError };
};

export default usePlaidModal;
