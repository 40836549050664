import { Button } from "@justworkshr/milo-core";
import { prependBasePath } from "pages/expenses/constants";
import { useRequestDetail } from "pages/expenses/store";
import { useNavigate } from "react-router-dom";
import { RequestDetailExpense } from "types/Expenses";
import { useExpenseDetailsCards } from "../../ExpenseDetailsCards.utils";

interface EditButtonProps {
  expenseIndex: number;
  expense: RequestDetailExpense;
}

export const EditButton: React.FC<EditButtonProps> = ({
  expense,
  expenseIndex,
}) => {
  const navigate = useNavigate();
  const { setRequestDetail } = useRequestDetail();
  const { isEditable } = useExpenseDetailsCards();

  if (!isEditable) {
    return null;
  }

  const handleEdit = () => {
    setRequestDetail({
      editMode: {
        editExpense: expense,
        editExpenseIndex: expenseIndex,
      },
      metadata: {
        isRequestEditView: true,
        isUnattachedExpenseView: false,
      },
    });

    navigate(prependBasePath("EXPENSE_DETAILS"));
  };

  return (
    <Button
      type="button"
      variant="ghost"
      leftIcon="edit"
      onClick={handleEdit}
      data-testid="edit-button"
    >
      Edit
    </Button>
  );
};

export default EditButton;
