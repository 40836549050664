import styles from "./basicLifeAndAddTabsContentTemplate.module.css";
import faq_styles from "./faqTabContent.module.css";
import Divider from "../components/Divider/Divider";
import { SystemIcon } from "@justworkshr/milo-icons";
import { Link } from "react-router-dom";
import {
  OVERVIEW_CONTENT,
  OPTIONS_AND_COSTS_CONTENT,
  FAQ_CONTENT,
} from "../constants/basicLifeAndAddTabContent";
import { LINKS } from "../constants/pdpGeneral";

const {
  headerText,
  subHeader,
  contentSection,
  infoTabsListItem,
  summaryText,
  detailText,
  tableContainer,
  arrowPoints,
  subHeaderText,
  optionsAndCostsHeaderText,
  contentAndTableDivision,
  tableRowLight,
} = styles;

const { faqContentSection, faqDetailText, listText } = faq_styles;

export const OverviewTab: React.FC = () => {
  return (
    <div>
      <div className={contentSection}>
        <div className={headerText}>{OVERVIEW_CONTENT.header.title}</div>
        <div className={subHeader}>{OVERVIEW_CONTENT.header.headerInfo}</div>
        <div className={subHeader}>
          {OVERVIEW_CONTENT.header.headerSubInfo}
          {Object.values(OVERVIEW_CONTENT.header.headerList).map(
            (item: { title: string; info: string; id: number }) => (
              <div key={item.id} className={arrowPoints}>
                <div>
                  <SystemIcon iconName="arrow-right" color="brand-subtle" />
                </div>
                <div>
                  {" "}
                  <strong>{item.title}</strong>
                  {item.info}
                </div>
              </div>
            )
          )}
        </div>
        <div className={subHeaderText}>
          {OVERVIEW_CONTENT.header.supplementalLifeOptionsTitle}
        </div>
        <div className={subHeader}>
          {OVERVIEW_CONTENT.header.supplementalLifeOptionsInfo}
        </div>
      </div>
      <Divider />
      <section>
        <div className={contentSection}>
          {Object.values(OVERVIEW_CONTENT.body).map(
            (item: {
              title: string;
              info: string;
              learnMore?: string;
              id: number;
            }) => (
              <div key={item.id} className={infoTabsListItem}>
                <div className={summaryText}>{item.title}</div>
                <div className={detailText}>
                  {item.info}
                  {item.id === 2 && (
                    <Link
                      to={LINKS.metlifeAdvantages}
                      target="_blank"
                      rel="noopener noreferrer"
                      key="help-center"
                      className="milo--link-secondary"
                    >
                      {item.learnMore}
                    </Link>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </section>
    </div>
  );
};

export const OptionsAndCostsTab: React.FC = () => {
  return (
    <div>
      <div className={contentSection}>
        <div className={headerText}>
          {OPTIONS_AND_COSTS_CONTENT.header.title}
        </div>
        <div className={subHeader}>
          {OPTIONS_AND_COSTS_CONTENT.header.headerInfo}
        </div>
        <div className={subHeader}>
          {OPTIONS_AND_COSTS_CONTENT.header.headerSubInfo}
        </div>
      </div>
      <Divider />
      <section>
        <div className={contentSection}>
          <div className={optionsAndCostsHeaderText}>
            {OPTIONS_AND_COSTS_CONTENT.bodyTitle}
          </div>
          <div
            key={OPTIONS_AND_COSTS_CONTENT.body.employer_paid_content.id}
            className={infoTabsListItem}
          >
            <div className={detailText}>
              <strong>
                {OPTIONS_AND_COSTS_CONTENT.body.employer_paid_content.subtitle}
              </strong>
              <br></br>
              {OPTIONS_AND_COSTS_CONTENT.body.employer_paid_content.info}
            </div>
          </div>
        </div>
        <div className={contentAndTableDivision}></div>
      </section>

      <table className={tableContainer}>
        <thead>
          <tr>
            <td>
              <strong>Coverage amount</strong>
            </td>
            <td>
              <strong>Costs</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          {/* TODO: map over OPTIONS_AND_COSTS_CONTENT hash to simplify*/}
          <tr className={tableRowLight}>
            <td>
              {
                OPTIONS_AND_COSTS_CONTENT.costTableInfo.coverageAmountOne
                  .coverageAmount
              }
            </td>
            <td>
              {OPTIONS_AND_COSTS_CONTENT.costTableInfo.coverageAmountOne.costs}
            </td>
          </tr>
          <tr>
            <td>
              {
                OPTIONS_AND_COSTS_CONTENT.costTableInfo.coverageAmountTwo
                  .coverageAmount
              }
            </td>
            <td>
              {OPTIONS_AND_COSTS_CONTENT.costTableInfo.coverageAmountTwo.costs}
            </td>
          </tr>
          <tr className={tableRowLight}>
            <td>
              {
                OPTIONS_AND_COSTS_CONTENT.costTableInfo.coverageAmountThree
                  .coverageAmount
              }
            </td>
            <td>
              {
                OPTIONS_AND_COSTS_CONTENT.costTableInfo.coverageAmountThree
                  .costs
              }
            </td>
          </tr>
          <tr>
            <td>
              {
                OPTIONS_AND_COSTS_CONTENT.costTableInfo.coverageAmountFour
                  .coverageAmount
              }
            </td>
            <td>
              {OPTIONS_AND_COSTS_CONTENT.costTableInfo.coverageAmountFour.costs}
            </td>
          </tr>
          <tr className={tableRowLight}>
            <td>
              {
                OPTIONS_AND_COSTS_CONTENT.costTableInfo.coverageAmountFive
                  .coverageAmount
              }
            </td>
            <td>
              {OPTIONS_AND_COSTS_CONTENT.costTableInfo.coverageAmountFive.costs}
            </td>
          </tr>
        </tbody>
      </table>
      <section>
        <div className={contentAndTableDivision}></div>
        <div className={contentSection}>
          <div
            key={OPTIONS_AND_COSTS_CONTENT.body.employee_paid_content.id}
            className={infoTabsListItem}
          >
            <div className={detailText}>
              <strong>
                {OPTIONS_AND_COSTS_CONTENT.body.employee_paid_content.subtitle}
              </strong>
              <br></br>
              {OPTIONS_AND_COSTS_CONTENT.body.employee_paid_content.info}
              <Link
                to={LINKS.suppLifeResourcePage}
                target="_blank"
                rel="noopener noreferrer"
                key="learn-more"
                className="milo--link-secondary"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export const FaqTab: React.FC = () => {
  return (
    <div>
      <section>
        <div className={faqContentSection}>
          {Object.values(FAQ_CONTENT).map(
            (item: {
              title: string;
              info: string;
              subInfo?: {
                subtitleOne: {
                  id: number;
                  info: string;
                };
                subtitleTwo: {
                  id: number;
                  info: string;
                };
                subtitleThree: {
                  id: number;
                  info: string;
                };
              };
              id: number;
            }) => (
              <div key={item.id} className={infoTabsListItem}>
                <div className={faqDetailText}>
                  <strong>{item.title}</strong>
                  <br></br>
                  {item.info}
                  {item.id === 2 && (
                    <div className={listText}>
                      <ul>
                        <li>{item.subInfo?.subtitleOne.info}</li>
                        <li>{item.subInfo?.subtitleTwo.info}</li>
                        <li>{item.subInfo?.subtitleThree.info}</li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </section>
    </div>
  );
};
