import { useState } from "react";
import { Button } from "@justworkshr/milo-core";
import styles from "./NewExpenseButtons.module.css";
import { useNavigate } from "react-router-dom";
import { prependBasePath } from "pages/expenses/constants";
import { useRequestDetail } from "pages/expenses/store";
import { ExpenseLibraryDialog } from "pages/expenses/components";

const { NewExpenseButtonsWrapper } = styles;

export const NewExpenseButtons = () => {
  const navigate = useNavigate();
  const [showExpenseLibraryDialog, setShowExpenseLibraryDialog] =
    useState(false);

  const { setIsUnattachedExpenseView } = useRequestDetail();

  const handleExpenseLibraryDialog = () => {
    setShowExpenseLibraryDialog((p) => !p);
  };

  return (
    <div>
      <div className={NewExpenseButtonsWrapper}>
        <Button
          type="button"
          variant="outlined"
          leftIcon="plus-circle"
          onClick={handleExpenseLibraryDialog}
          data-testid="attach-expense-button"
        >
          Attach from expense library
        </Button>
        <Button
          type="button"
          variant="outlined"
          leftIcon="plus-circle"
          onClick={() => {
            setIsUnattachedExpenseView(false);
            navigate(prependBasePath("EXPENSE_DETAILS"));
          }}
          data-testid="add-new-expense-button"
        >
          Add new expense
        </Button>
      </div>
      {showExpenseLibraryDialog && (
        <ExpenseLibraryDialog
          isOpen={showExpenseLibraryDialog}
          onDismiss={handleExpenseLibraryDialog}
        />
      )}
    </div>
  );
};
