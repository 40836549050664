import { Button, PageHeader, Spinner } from "@justworkshr/milo-core";
import { buildWebPath } from "lib/resource-finder";
import Collapsible from "./collapsible";
import {
  useFirmsPageQuery,
  useGetAdvisorDashFeatureFlagQuery,
  useGetCompanyDisplayQuery,
  useGetIsEmployerOfRecordEnabledQuery,
  useGetCompanyInfoQuery,
  FirmStatus,
  CompanyPlan,
} from "types/generated/operations";
import { FLAG_NAMES, flagIsEnabled } from "lib/environment";
import { INVITE_PATH } from "pages/employer-of-record/invite";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import styles from "./invitation-center.module.css";
import { canManageFirms } from "../firms/helpers";

const { invitationCenter, collapsible, item, header, fullPageSpinner } = styles;

export default function International() {
  const { hash } = useLocation();
  const { t } = useTranslation();
  const { data: eorEnabledData, loading: eorEnabledLoading } =
    useGetIsEmployerOfRecordEnabledQuery();

  const {
    data: companyData,
    loading: companyLoading,
    error: companyError,
  } = useGetCompanyDisplayQuery();

  const companyUuid = companyData?.authenticatedMember?.company?.uuid ?? "";

  // need to evaluate feature flags with a mutli-context of company and user kinds.
  // DO NOT REUSE THIS QUERY
  // TODO: See https://justworks.atlassian.net/browse/FRONT-389 for technical-debt fix
  const {
    data: advisorDashFeatureFlagData,
    loading: advisorDashFeatureFlagLoading,
  } = useGetAdvisorDashFeatureFlagQuery();

  const { data: firmData, loading: firmLoading } = useFirmsPageQuery({
    skip: companyLoading || !!companyError,
    variables: { companyUuid },
  });
  const canManageFirm = canManageFirms(
    firmData?.authenticatedMember?.permissions
  );

  const { firm } = firmData || {};

  if (hash) {
    const element = document.querySelector(hash);
    if (element) {
      element.scrollIntoView();
    }
  }

  const { data: companyInfoData, loading: companyInfoLoading } =
    useGetCompanyInfoQuery();

  const INVITE_EOR_PATH = `/employer-of-record${INVITE_PATH}`;

  if (
    (eorEnabledLoading && advisorDashFeatureFlagLoading) ||
    companyInfoLoading
  )
    return (
      <div className={fullPageSpinner}>
        <Spinner />
      </div>
    );

  const isEmployerOfRecordEnabled =
    eorEnabledData?.authenticatedMember.company.companySetting?.value ===
      "true" && flagIsEnabled(FLAG_NAMES.EMPLOYER_OF_RECORD);

  const canAddThirdPartyAccountant =
    advisorDashFeatureFlagData?.featureFlag?.enabled &&
    canManageFirm &&
    firm?.status !== FirmStatus.Active;

  const isInternationalStandalone =
    companyInfoData?.authenticatedMember?.company?.companyPlanType ===
    CompanyPlan.InternationalStandalone;

  return (
    <div id="InvitationCenterApp">
      <div className={`${invitationCenter} milo--grid`}>
        <PageHeader
          className={header}
          title="Invitation center"
          linkPrevious={
            <a href={buildWebPath("/members/employees")}>Back to manage</a>
          }
        />
        <Collapsible
          className={collapsible}
          title="Add employees"
          open={!hash || hash === "#employees"}
          id="employees"
        >
          {!isInternationalStandalone && (
            <div className={item}>
              <div>
                <h3>U.S. full-time (W-2)</h3>
                <p>
                  Someone who will be employed by your company on a full-time
                  basis and lives in the United States.
                </p>
              </div>
              <Button
                as="a"
                href={buildWebPath(
                  "/invitation?invitation_member_type=employee_full_time"
                )}
                variant="outlined"
              >
                Add employee
              </Button>
            </div>
          )}
          {!isInternationalStandalone && (
            <div className={item}>
              <div>
                <h3>U.S. part-time (W-2) </h3>
                <p>
                  Someone who will be employed by your company on a part-time
                  basis and lives in the United States.
                </p>
              </div>
              <Button
                as="a"
                href={buildWebPath(
                  "/invitation?invitation_member_type=employee_part_time"
                )}
                variant="outlined"
              >
                Add employee
              </Button>
            </div>
          )}
          {!isInternationalStandalone && (
            <div className={item}>
              <div>
                <h3>U.S. paid intern (W-2)</h3>
                <p>
                  A full-time or part-time, hourly, non-exempt employee who is
                  being paid at least the minimum wage rate, overtime pay for
                  overtime hours worked, and lives in the United States.
                </p>
              </div>
              <Button
                as="a"
                href={buildWebPath(
                  "/invitation?invitation_member_type=paid_intern"
                )}
                variant="outlined"
              >
                Add intern
              </Button>
            </div>
          )}
          <div className={item}>
            <div>
              <h3>Outside the U.S. (limited access)</h3>
              <p>
                Someone living and/or working outside of the United States who
                will be able to access documents, the company directory,
                calendar, and time off but can not be paid and does not qualify
                for benefits.
              </p>
            </div>
            <Button
              as="a"
              href={buildWebPath(
                "/invitation?invitation_member_type=employee_international"
              )}
              variant="outlined"
            >
              Add employee
            </Button>
          </div>
          {isEmployerOfRecordEnabled && (
            <div
              className={`${item} ${
                hash === "#employees" ? styles.intlFocus : ""
              }`}
            >
              <div>
                <h3>{t(`EOR Employee`)}</h3>
                <p>
                  {t(
                    "Use Justworks as an employer of record to hire international workers on your behalf."
                  )}
                </p>
              </div>
              <Button as={Link} to={INVITE_EOR_PATH} variant="outlined">
                {t("Add employee")}
              </Button>
            </div>
          )}
        </Collapsible>
        <Collapsible
          className={collapsible}
          title="Add contractors or vendors"
          id="contractors-or-vendors"
          open={hash === "#contractors-or-vendors"}
        >
          {!isInternationalStandalone && (
            <div className={item}>
              <div>
                <h3>U.S. contractor or consultant (1099)</h3>
                <p>
                  Someone who provides services to your company, but is not
                  employed on an ongoing basis. They generally use their own
                  equipment, work on their own schedule, and lives in the United
                  States.
                </p>
              </div>
              <Button
                as="a"
                href={buildWebPath(
                  "/invitation?invitation_member_type=contractor"
                )}
                variant="outlined"
              >
                Add contractor
              </Button>
            </div>
          )}
          {!isInternationalStandalone && (
            <div className={item}>
              <div>
                <h3>U.S. vendor (1099)</h3>
                <p>
                  A company that provides goods or services to your company and
                  has their own EIN.
                </p>
              </div>
              <Button
                as="a"
                href={buildWebPath(
                  "/invitation?invitation_member_type=vendor_1099"
                )}
                variant="outlined"
              >
                Add vendor
              </Button>
            </div>
          )}
          <div
            className={`${item} ${
              hash === "#contractors-or-vendors" ? styles.intlFocus : ""
            }`}
          >
            <div>
              <h3>International Contractors</h3>{" "}
              <p>
                Someone who provides services to your company, but is not
                employed by your company. They generally use their own
                equipment, work on their own schedule, and live outside the
                United States.
              </p>
              <a
                href={buildWebPath("/international_contractors")}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            </div>
            <Button
              as="a"
              href={buildWebPath("/international/contractor/invite")}
              variant="outlined"
            >
              Add contractor
            </Button>
          </div>
        </Collapsible>
        {!isInternationalStandalone && (
          <Collapsible
            className={collapsible}
            title="Add owners or partners"
            id="owners-or-partners"
            open={hash === "#owners-or-partners"}
          >
            <div className={item}>
              <div>
                <h3>Paid</h3>
                <p>Owners or partners who will be included in payroll.</p>
              </div>
              <Button
                as="a"
                href={buildWebPath(
                  "/invitation?invitation_member_type=owner_full_time"
                )}
                variant="outlined"
              >
                Add owner
              </Button>
            </div>
            <div className={item}>
              <div>
                <h3>Unpaid</h3>
                <p>
                  Owners or partners who will not be included in your company's
                  payroll.
                </p>
              </div>
              <Button
                as="a"
                href={buildWebPath("/invitation?invitation_member_type=owner")}
                variant="outlined"
              >
                Add vendor
              </Button>
            </div>
          </Collapsible>
        )}
        <Collapsible
          className={collapsible}
          title="Add third party or temp"
          id="third-party-or-temp"
          open={hash === "#third-party-or-temp"}
        >
          {canAddThirdPartyAccountant ? (
            <>
              <div className={item}>
                <div>
                  <h3>Accountant or Accounting firm</h3>
                  <p>
                    When you need to grant access to an individual accountant or
                    an accounting firm with multiple team members.
                  </p>
                </div>
                <Button
                  disabled={firm?.status === FirmStatus.Pending}
                  loading={firmLoading || advisorDashFeatureFlagLoading}
                  as={Link}
                  to={"/firms/invitation/new"}
                  variant="outlined"
                >
                  {firm?.status === FirmStatus.Pending
                    ? "Invitation pending"
                    : "Add accountant"}
                </Button>
              </div>
              <div className={item}>
                <div>
                  <h3>Third-party admin</h3>
                  <p>
                    This could be an HR professional, legal counsel, or someone
                    else who needs admin access to your company but isn’t paid
                    through Justworks. They will be added to the company
                    directory and do not count towards your seat fees.
                  </p>
                </div>
                <Button
                  as="a"
                  href={buildWebPath(
                    "/invitation?invitation_member_type=vendor"
                  )}
                  variant="outlined"
                >
                  Add third-party
                </Button>
              </div>
            </>
          ) : (
            <div className={item}>
              <div>
                <h3>3rd-party admin or accountant</h3>
                <p>
                  Someone who will have access to Justworks or be included in
                  your company's directory, but is not paid through Justworks.
                </p>
              </div>
              <Button
                as="a"
                href={buildWebPath("/invitation?invitation_member_type=vendor")}
                variant="outlined"
              >
                Add 3rd-party
              </Button>
            </div>
          )}
          {!isInternationalStandalone && (
            <div className={item}>
              <div>
                <h3>Temp</h3>
                <p>
                  Someone who is contracted through an external agency and paid
                  through the third-party employment agency not Justworks.
                </p>
              </div>
              <Button
                as="a"
                href={buildWebPath("/invitation?invitation_member_type=temp")}
                variant="outlined"
              >
                Add temp
              </Button>
            </div>
          )}
        </Collapsible>
      </div>
    </div>
  );
}
