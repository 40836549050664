import {
  CheckboxInput,
  FileInput,
  Form,
  FormField,
  Select,
  SelectOption,
  TextInput,
} from "@justworkshr/milo-form";
import { InternationalContractorMemberContext } from "../../../../context/InternationalContractorMemberContext";
import { ReactElement, useContext } from "react";
import styles from "./DocumentUploadForm.module.css";
import { useFormikContext } from "formik";
import { getMemberPreferredName } from "../../../../utils";
import { CATEGORIES, ERRORS, MAX_FILE_SIZE } from "../constants";
import { DocumentUploadFormType } from "../types";

interface DocumentUploadFormProps {
  fileError: string;
  isEdit?: boolean;
  setFileError: (value: string) => void;
}

export default function DocumentUploadForm({
  fileError,
  isEdit,
  setFileError,
}: DocumentUploadFormProps): ReactElement {
  const { member } = useContext(InternationalContractorMemberContext);

  const memberName = getMemberPreferredName(member);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = useFormikContext<DocumentUploadFormType>();

  const showSignatureField = !isEdit || (isEdit && values.signatureRequired);

  const readAsDataURL = async (file: File): Promise<string | undefined> => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onerror = reject;
      fr.onload = () => {
        resolve(fr.result?.toString());
      };
      fr.readAsDataURL(file);
    });
  };

  const onSelectFile = async (
    file: File | File[] | undefined
  ): Promise<void> => {
    let selectedFile: File | undefined = undefined;
    if (file && Array.isArray(file)) {
      if (file.length > 0) {
        selectedFile = file[0];
      } else if (file instanceof File) {
        selectedFile = file;
      }
    }
    const processedFile =
      selectedFile !== undefined
        ? await readAsDataURL(selectedFile)
        : undefined;

    if (selectedFile && processedFile) {
      setFieldValue("file", {
        dataUrl: processedFile,
        file: selectedFile,
      });
    }
  };

  const validateAndSelectFile = (file?: File | File[]) => {
    let selectedFile: File | undefined = undefined;
    if (file && Array.isArray(file)) {
      if (file.length > 0) {
        selectedFile = file[0];
      } else if (file instanceof File) {
        selectedFile = file;
      }
    }

    // Don't validate on load
    if (!values.file && !selectedFile) {
      return setFileError(
        fileError === ERRORS.FILE_TOO_BIG ? ERRORS.EMPTY_FILE : ""
      );
    }

    if (!selectedFile) {
      setFieldValue("file", undefined);
      setFileError(ERRORS.EMPTY_FILE);
      return;
    }

    if (selectedFile && selectedFile.size > MAX_FILE_SIZE) {
      return setFileError(ERRORS.FILE_TOO_BIG);
    }

    setFileError("");

    onSelectFile(file);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      className={`${styles.container} ${
        isEdit ? styles.editDocument : styles.createDocument
      }`}
    >
      {!isEdit && (
        <div className={`${styles.section} ${styles.noLabel}`}>
          <FormField
            aria-labelledby="file-input"
            name="file"
            required
            error={fileError}
          >
            <FileInput
              onFileChange={(file) => {
                validateAndSelectFile(file);
              }}
              accept=".pdf"
            />
          </FormField>
        </div>
      )}

      <div className={`${styles.section} ${styles.textInput}`}>
        <FormField
          label="Document name"
          name="name"
          required
          error={touched.name ? errors.name : ""}
        >
          <TextInput
            required
            name="name"
            value={values.name}
            onChange={(e) => {
              handleChange(e);
            }}
            onBlur={handleBlur}
            aria-label="name"
          />
        </FormField>
      </div>

      <div className={styles.section}>
        <FormField
          label="Category"
          name="category"
          required
          error={touched.category ? errors.category : ""}
        >
          <Select
            name="category"
            required
            value={values.category}
            onChange={handleChange}
            onBlur={handleBlur}
            aria-label="category"
            placeholder="Select..."
            style={{ color: values.category ? "inherit" : "#A9ADB5" }}
          >
            {CATEGORIES.map((category) => (
              <SelectOption key={category.value} value={category.value}>
                {category.description}
              </SelectOption>
            ))}
          </Select>
        </FormField>
      </div>

      {showSignatureField && (
        <div className={styles.section}>
          <FormField label="Request signature" name="signatureRequired">
            <div>
              <p className={styles.fieldDescription}>
                People can acknowledge they received this document by signing
                their name. This can’t be changed after the document has been
                uploaded.
              </p>
              <div className={styles.checkboxFieldWrapper}>
                <CheckboxInput
                  name="signatureRequired"
                  label="Request signature"
                  value={values.signatureRequired ? 1 : 0}
                  checked={values.signatureRequired}
                  disabled={isEdit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  aria-label="signatureRequired"
                />
              </div>
            </div>
          </FormField>
        </div>
      )}

      {values.signatureRequired && (
        <div className={styles.section}>
          <FormField label="Reminders" name="remindersSet">
            <div className={styles.checkboxFieldWrapper}>
              <CheckboxInput
                name="remindersSet"
                label="Send a reminder every 7 days, up to 3 times"
                value={values.remindersSet ? 1 : 0}
                checked={values.remindersSet}
                disabled={isEdit}
                onChange={handleChange}
                onBlur={handleBlur}
                aria-label="remindersSet"
              />
            </div>
          </FormField>
        </div>
      )}

      <div className={styles.section}>
        <FormField label="Access" name="access">
          <div>
            <p className={`${styles.fieldDescription} ${styles.access}`}>
              This document is shared with admins who have document level
              permissions.
            </p>
            <div className={styles.checkboxFieldWrapper}>
              <CheckboxInput
                name="memberAccess"
                label={`Share with ${memberName}`}
                value={values.memberAccess ? 1 : 0}
                checked={values.signatureRequired || values.memberAccess}
                disabled={values.signatureRequired}
                onChange={handleChange}
                onBlur={handleBlur}
                aria-label="memberAccess"
              />
            </div>
          </div>
        </FormField>
      </div>
    </Form>
  );
}
