import React, { useState } from "react";
import styles from "../InstanceDetailsPanel.module.css";
import { Button, Dialog } from "@justworkshr/milo-core";
import { Link } from "react-router-dom";
import useProcessPmtIntegrationAction from "pages/pmt/integrations/partner-setup-page/hooks/useProcessPmtIntegrationAction";
import { integrationConfigs } from "pages/pmt/integrations/configs/config";
import { AlertData } from "pages/pmt/integrations/hooks/useAlert";
import { supportLink } from "../utils";

const { detailsPanelButtons, connectedButtonText } = styles;

export type DisconnectButtonProps = {
  solutionSlug: string | undefined;
  setAlertData?: (alertData: AlertData) => void;
  hasPermission?: boolean;
  inAlert?: boolean;
};

const getDefaultDisconnectAlerts = (
  solutionSlug: string | undefined
): { [key: string]: AlertData } => {
  const integrationName = solutionSlug
    ? integrationConfigs[solutionSlug].copy?.marketplaceCard.integrationName
    : "the integration";

  return {
    disconnectSuccess: {
      message: `Justworks and ${integrationName} are no longer connected.`,
      color: "additive" as const,
      visible: true,
      dismissible: true,
    },
    disconnectFailure: {
      message: (
        <>
          We weren’t able to disconnect from {integrationName}. Try
          disconnecting again or email support at {supportLink}
        </>
      ),
      color: "destructive" as const,
      visible: true,
      dismissible: true,
    },
  };
};

const DisconnectButton: React.FC<DisconnectButtonProps> = ({
  solutionSlug,
  setAlertData,
  hasPermission,
  inAlert,
}) => {
  const { processPmtIntegrationAction, loading } =
    useProcessPmtIntegrationAction();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeDialog = () => setIsDialogOpen(false);
  const disconnectModalContent =
    solutionSlug &&
    integrationConfigs[solutionSlug].copy.pdp.disconnectModalContent;
  // could support custom disconnect alerts via integration config in the future
  const defaultDisconnectAlerts = getDefaultDisconnectAlerts(solutionSlug);

  const disable = () => {
    if (!solutionSlug) {
      return;
    }
    processPmtIntegrationAction(solutionSlug, "disable").then(
      (processActionResponse) => {
        if (setAlertData) {
          if (processActionResponse?.success) {
            setAlertData(defaultDisconnectAlerts.disconnectSuccess);
          } else {
            closeDialog();
            setAlertData(defaultDisconnectAlerts.disconnectFailure);
          }
        }
      }
    );
  };

  return (
    <>
      <Button
        className={detailsPanelButtons}
        leftIcon={inAlert ? undefined : "minus-circle"}
        color={inAlert ? "neutral" : "destructive"}
        variant={"ghost"}
        onClick={() => setIsDialogOpen(true)}
        loading={isDialogOpen}
        disabled={!hasPermission || isDialogOpen}
      >
        <span className={connectedButtonText}>Disconnect</span>
      </Button>
      <Dialog
        title={disconnectModalContent && disconnectModalContent?.title}
        isOpen={isDialogOpen}
        onClose={closeDialog}
        showCloseButton={true}
        primaryButton={
          <Button
            onClick={disable}
            variant="filled"
            color="destructive"
            as={Link}
            to={`/integrations/${solutionSlug}`}
            loading={loading}
            disabled={!solutionSlug || loading}
          >
            {disconnectModalContent &&
              disconnectModalContent?.disconnectButtonText}
          </Button>
        }
        secondaryButton={
          <Button variant="ghost" onClick={closeDialog}>
            Cancel
          </Button>
        }
      >
        {disconnectModalContent && disconnectModalContent?.body}
      </Dialog>
    </>
  );
};

export default DisconnectButton;
