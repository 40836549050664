import { StepperStatus, AdditionalPayStepsType } from "./types";

const calcStepStatus = (
  step: number,
  currentStep: number,
  status?: StepperStatus
) => {
  if (status && step !== currentStep) return status;

  if (step < currentStep) {
    return "complete";
  } else if (step === currentStep) {
    return "current";
  } else {
    return "pending";
  }
};

export const AdditionalPayStepStructure: AdditionalPayStepsType[] = [
  {
    key: "set-up",
    title: "Set Up",
    status: calcStepStatus,
  },
  {
    key: "payment-details",
    title: "Payment details",
    status: calcStepStatus,
  },
  {
    key: "review",
    title: "Review",
    status: calcStepStatus,
  },
];

export const CLOCKWORK_WEB_PATH = {
  PAYMENTCENTER: "/masspay",
};

export const ADDITIONAL_PAY_FORM_ROUTES = {
  EMPLOYEE_PAY: "employee-pay",
  REIMBURSEMENT: "expense-reimbursement",
  TAXABLEBENEFITSANDALLOWANCES: "benefits-and-allowances",
};

export const SUBPATHS = {
  SET_UP: "set-up",
  PAYMENT_DETAILS: "payment-details",
  REVIEW: "review",
};

export const ESTIMATION_BASE_CURRENCY = "USD";

export enum EmployeePayType {
  BONUS = "Bonus",
  COMISSION = "Commission",
  OFF_CYCLE = "Offcycle",
  RETROACTIVE = "Retroactive",
}

export enum ReimbursementPayType {
  REIMBURSEMENT = "Reimbursement",
}

export enum BenefitsAndAllowancesType {
  BENEFITSANDALLOWANCES = "BenefitsAndAllowances",
}

export type FormPayType =
  | EmployeePayType
  | ReimbursementPayType
  | BenefitsAndAllowancesType
  | "";
