import { ExpenseCategoryFieldSetting } from "types/generated/operations";
import { INITIAL_SETTINGS_STORE, SettingsStore } from "../reducer";
import { SettingsStoreContext } from "../store";
import { DeepPartial } from "pages/expenses/types";

export const ACTIVE_TEST_PROJECT = {
  uuid: "",
  projectCode: "1234",
  projectName: "Test Project",
  visible: true,
  displayName: "1234 - Test Project",
};

export const TRAVEL_TEST_CATEGORY = {
  uuid: "12345",
  name: "Travel",
  visible: true,
  custom: false,
  merchant: ExpenseCategoryFieldSetting.Hidden,
  description: ExpenseCategoryFieldSetting.Hidden,
  receipt: ExpenseCategoryFieldSetting.Required,
  attendees: ExpenseCategoryFieldSetting.Hidden,
  billableToClient: ExpenseCategoryFieldSetting.Hidden,
  expenseType: ExpenseCategoryFieldSetting.Hidden,
};

export const CUSTOM_TEST_CATEGORY = {
  uuid: "",
  name: "Custom Category",
  visible: true,
  custom: true,
  merchant: ExpenseCategoryFieldSetting.Hidden,
  description: ExpenseCategoryFieldSetting.Hidden,
  receipt: ExpenseCategoryFieldSetting.Required,
  attendees: ExpenseCategoryFieldSetting.Hidden,
  billableToClient: ExpenseCategoryFieldSetting.Hidden,
  expenseType: ExpenseCategoryFieldSetting.Hidden,
};

export const MockedSettingsProvider: React.FC<{
  providerProps?: DeepPartial<SettingsStore>;
  children: React.ReactElement;
}> = ({ children, providerProps }) => {
  const store = Object.assign({}, INITIAL_SETTINGS_STORE, providerProps);

  return (
    <SettingsStoreContext.Provider
      value={{
        store,
        dispatch: () => null,
      }}
    >
      {children}
    </SettingsStoreContext.Provider>
  );
};
