import React from "react";
import styles from "./DecoratedTextInput.module.css";

const { Prefix, DecoratedTextInputWrapper } = styles;

interface DecoratedTextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  prefix?: string;
}

export const DecoratedTextInput: React.FC<DecoratedTextInputProps> = ({
  className,
  prefix,
  ...otherProps
}) => {
  const mergedClassNames = [DecoratedTextInputWrapper, className].join(" ");

  return (
    <div className={mergedClassNames}>
      {prefix && <span className={Prefix}>{prefix}</span>}
      <input {...otherProps} />
    </div>
  );
};

export default DecoratedTextInput;
