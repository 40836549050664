// Copied from pages/expenses/components/PermissionsError

import { Button } from "@justworkshr/milo-core";
import styles from "./PermissionsError.module.css";
import { buildClockworkWebPath } from "lib/resource-finder";
import { createPortal } from "react-dom";

const { PermissionsErrorWrapper, PermissionsErrorContainer } = styles;

const JUSTWORKS_SUPPORT_EMAIL = "support@justworks.com";
const CLOCKWORK_WEB_PATH = buildClockworkWebPath("/");
const CONTACT_US_EMAIL_LINK = `mailto:${JUSTWORKS_SUPPORT_EMAIL}`;

export const PermissionsError = () => {
  return createPortal(
    <div className={PermissionsErrorWrapper}>
      <div className={PermissionsErrorContainer}>
        <h1>You don't have permission to do that.</h1>
        <p>
          Please make sure you're logged into the correct account. If you think
          you're seeing this message in error please feel free to{" "}
          <a href={CONTACT_US_EMAIL_LINK}>contact us.</a>
        </p>
        <Button variant="filled" as="a" href={CLOCKWORK_WEB_PATH}>
          Back to Justworks
        </Button>
      </div>
    </div>,
    document.body
  );
};
