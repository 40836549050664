import React from "react";
import { COMPONENT_INPUT_MAP } from "pages/team-management/constants";
import { FormField } from "@justworkshr/milo-form";
import { Field, useFormikContext } from "formik";
import {
  MemberSeparationAnswerOptionRecursive,
  MemberSeparationQuestionRecursive,
  MemberSeparationValidations,
} from "pages/team-management/types";
import styles from "./SeparationQuestion.module.css";

const { subQuestion } = styles;

type StyledComponentType = React.ComponentType<{
  questionId: string;
  questionText: string;
  inputType: string;
  answers: MemberSeparationAnswerOptionRecursive[] | null | undefined;
  value: string | string[] | null | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validations?: MemberSeparationValidations;
}>;

export interface SeparationQuestionProps {
  question: MemberSeparationQuestionRecursive;
  isSubQuestion?: boolean;
  handleFormChange: (
    questionId: string,
    value: string | number | (string | number)[] | undefined
  ) => void;
}

const showSubQuestion: (
  questionId: string,
  answer: MemberSeparationAnswerOptionRecursive,
  values: { [key: string]: string | string[] }
) => boolean = (questionId, answer, values) => {
  if (Array.isArray(values[questionId]) && answer.value) {
    return values[questionId].includes(String(answer.value));
  }

  return values[questionId]?.toString() === answer.value;
};

export const SeparationQuestion: React.FC<SeparationQuestionProps> = ({
  question,
  isSubQuestion = false,
  handleFormChange,
}) => {
  const { questionId, questionText, inputType, validations, answers } =
    question;
  const StyledComponent = COMPONENT_INPUT_MAP[inputType] as StyledComponentType;
  const { values, errors, touched } = useFormikContext<{
    [key: string]: string;
  }>();
  const { setFieldValue } = useFormikContext();

  const renderSubQuestions = (
    subQuestion: MemberSeparationQuestionRecursive
  ) => {
    return (
      <SeparationQuestion
        question={subQuestion}
        key={subQuestion.questionId}
        isSubQuestion={true}
        handleFormChange={handleFormChange}
      />
    );
  };

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | string
      | number
      | (string | number)[]
      | undefined
  ) => {
    const value: string | number | (string | number)[] | undefined = (() => {
      if ((event as React.ChangeEvent<HTMLInputElement>).type) {
        return (event as React.ChangeEvent<HTMLInputElement>).target.value;
      } else if (
        typeof event === "string" ||
        typeof event === "number" ||
        Array.isArray(event)
      ) {
        return event;
      }
    })();

    setFieldValue(questionId, value);

    handleFormChange(questionId, value);
  };

  return Object.keys(values).length === 0 ? null : (
    <div
      className={isSubQuestion ? subQuestion : ""}
      data-testid={isSubQuestion ? "subQuestionTestId" : "parentQuestionTestId"}
    >
      <FormField
        label={questionText}
        name={questionId}
        required={!!validations?.required}
        key={questionId}
        error={touched[questionId] ? errors[questionId] : ""}
      >
        <Field name={questionId} key={questionId}>
          {() => (
            <div>
              <StyledComponent
                questionId={questionId}
                questionText={questionText}
                inputType={inputType}
                answers={answers}
                value={values[questionId]}
                onChange={handleChange}
                validations={validations}
              />
            </div>
          )}
        </Field>
      </FormField>
      {answers &&
        answers.map((answer: MemberSeparationAnswerOptionRecursive) => {
          return answer.subQuestions?.map(
            (subQuestion: MemberSeparationQuestionRecursive | null) => {
              return (
                subQuestion &&
                showSubQuestion(questionId, answer, values) &&
                renderSubQuestions(subQuestion)
              );
            }
          );
        })}
    </div>
  );
};
