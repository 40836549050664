import * as Yup from "yup";
import MXFragment from "./MXFragment";

export type ConfigType = {
  component: JSX.Element;
  schema: typeof schema;
  initialValues: SchemaType;
  keysToExclude?: Partial<keyof SchemaType>[];
};

export type SchemaType = Yup.InferType<typeof schema>;

const schema = Yup.object({
  workCountry: Yup.string().oneOf(["MX"]).required("Required"),
  mxChristmasBonusDays: Yup.number()
    .required("Required")
    .min(15, "Must be 15 or greater."),
  mxVacationPremiumPercentage: Yup.number()
    .required("Required")
    .min(25, "Must be 25 or greater."),
  mxVacationDays: Yup.number()
    .required("Required")
    .min(12, "Must be 12 or greater."),
  mxHasGroceryAllowanceCard: Yup.string()
    .oneOf(["true", "false"], "Invalid selection")
    .required("Required"),
  mxMonthlyGroceryAllowance: Yup.number()
    .nullable()
    .when("mxHasGroceryAllowanceCard", (mxHasGroceryAllowanceCard, schema) => {
      return String(mxHasGroceryAllowanceCard) === "true"
        ? schema
            .nonNullable()
            .required("Required")
            .min(0, "Must be 0 or greater.")
        : schema;
    }),
});

const CONFIG: ConfigType = {
  component: <MXFragment />,
  schema: schema,
  initialValues: {
    workCountry: "MX",
    mxChristmasBonusDays: 15,
    mxHasGroceryAllowanceCard: "false",
    mxMonthlyGroceryAllowance: null,
    mxVacationPremiumPercentage: 25,
    mxVacationDays: 12,
  },
  keysToExclude: ["mxHasGroceryAllowanceCard"],
};

export default CONFIG;
