export const OVERVIEW_CONTENT = {
  header: {
    title: "What are basic life and AD&D insurances?",
    headerInfo:
      "Basic life and AD&D insurances are company-paid policies that guarantee a payout to your employees’ beneficiaries if an employee is significantly injured or passes away. Basic coverage can ease employees’ concerns about the financial impact on their loved ones in case of their death or injury.",
    headerSubInfo:
      "While employees appreciate the peace of mind that comes with basic life and AD&D insurances, there are tangible benefits for your company as well, including:",
    headerList: {
      talent: {
        id: 0,
        title: "Attract and retain talent",
        info: "— Stay competitive in the job market by doing everything you can to take care of your employees.",
      },
      savings: {
        id: 1,
        title: "Tax savings",
        info: "— Not only is this benefit inexpensive, but the cost of policies up to $50,000 are tax deductible for your business.",
      },
    },
    supplementalLifeOptionsTitle: "Supplemental life insurance options",
    supplementalLifeOptionsInfo:
      "If your company decides not to offer basic life insurance, Justworks also offers supplemental life insurance that your employees can buy on their own.",
  },
  body: {
    simpleSetup: {
      id: 0,
      title: "Simple set up",
      info: "Let us know the size of the policy you’d like to offer and we’ll take care of the rest.",
    },
    automatedBilling: {
      id: 1,
      title: "Automated billing",
      info: "Depending on the option you choose, your company or enrolled employees will be billed automatically for coverage. New eligible employees will be added to the policy when they’re hired or  become eligible.",
    },
    additionalServices: {
      id: 2,
      title: "Additional services available",
      info: "In addition to life insurance, MetLife offers access to meaningful services such as funeral and estate planning, will preparation, grief counseling and more at no additional cost. ",
      learnMore: "Learn more about what is included from MetLife.",
    },
  },
};

export const OPTIONS_AND_COSTS_CONTENT = {
  header: {
    title: "What can I offer my employees?",
    headerInfo:
      "Empower your team with reassurance of basic life insurance, inclusive of accidental death & dismemberment (AD&D), designed to safeguard your employees and their loved ones.",
    headerSubInfo:
      "Extend your safety net even further by choosing our supplemental life offering that is completely employee-paid coverage that employees can continue even when they leave your company. Opting in not only grants your employees access to competitive rates but also expands coverage to safeguard not just their own lives but those of their spouses or partners. Beyond mere benefits, it's a commitment to fostering peace of mind.",
  },
  bodyTitle: "What does it cost?",
  body: {
    employer_paid_content: {
      id: 0,
      subtitle: "Basic life insurance and AD&D (employer paid)",
      info: "Our current basic life (inclusive of AD&D) insurance offerings are fully employer paid and sold as a bundled product at a rate of $0.063 per $1,000 of coverage per employee per month. Companies can choose from one of the coverage options listed below. The coverage level you select will automatically apply to all owners and employees working 30 or more hours per week.",
    },
    employee_paid_content: {
      id: 1,
      subtitle: "Supplemental life insurance (employee paid)",
      info: "As an employer, you can add on supplemental life insurance on top of basic life insurance coverage. Our supplemental life and dependent insurance offerings are fully employee paid. Prices vary based on the employee’s age and amount of benefit. The spousal policy rates are based on the insured’s age and increase as they age, even when enrolled. ",
    },
  },
  costTableInfo: {
    coverageAmountOne: {
      optionId: "option_1",
      coverageAmount: "$10,000 coverage",
      amount: "1000000",
      costs: "$0.63/month per employee",
      planType: "life_add",
    },
    coverageAmountTwo: {
      optionId: "option_2",
      coverageAmount: "$25,000 coverage",
      amount: "2500000",
      costs: "$1.58/month per employee",
      planType: "life_add",
    },
    coverageAmountThree: {
      optionId: "option_3",
      coverageAmount: "$50,000 coverage",
      amount: "5000000",
      costs: "$3.15/month per employee",
      planType: "life_add",
    },
    coverageAmountFour: {
      optionId: "option_4",
      coverageAmount: "1 x salary (up to $100k coverage)",
      amount: "1",
      costs: "Up to $6.30/month per employee",
      planType: "life_add",
    },
    coverageAmountFive: {
      optionId: "option_5",
      coverageAmount: "2 x salary (up to $200k coverage)",
      amount: "2",
      costs: "Up to $12.60/month per employee",
      planType: "life_add",
    },
    coverageAmountSix: {
      optionId: "option_6",
      coverageAmount:
        "None. I don’t want to offer basic life insurance and AD&D to my employees",
    },
  },
  supplementaryLifeEnrollment: {
    optionOne: {
      optionId: "option_1",
      coverageAmount: "Various options based on your employees",
      costs: "$0.00. This policy is employee funded",
      option: "Yes",
      planType: "supp_life",
      amount: null,
    },
    optionTwo: {
      optionId: "option_2",
      option: "No",
    },
  },
};

export const FAQ_CONTENT = {
  employeeUse: {
    id: 0,
    title:
      "What can my employees use basic life, AD&D and supplemental life insurances for?",
    info: "Basic life & supplemental life pays for accidental loss of life. AD&D pays for loss of limb, speech, hearing or sight, & paralysis.",
  },
  eligibleEmployees: {
    id: 1,
    title: "What kind of employees are eligible for these benefits?",
    info: "Full time employees, part-time employees who work more than 30 hours, and paid owners are all eligible for both voluntary (employee paid) and non-contributory (employer paid) life insurance policies. Unpaid owners are only eligible for non-contributory life insurance policies.",
  },
  healthStatement: {
    id: 2,
    title:
      "When would my employees need to submit a Statement of Health to Metlife for supplemental life?",
    info: "Members need to submit a Statement of Health when they:",
    subInfo: {
      subtitleOne: {
        id: 0,
        info: "Are past the 30-day enrollment period.",
      },
      subtitleTwo: {
        id: 1,
        info: "Request coverage amounts during their initial 30-day enrollment that exceed $150,000 for the employee or $25,000 for the spouse.",
      },
      subtitleThree: {
        id: 2,
        info: "Want to increase their coverage by any amount and are outside of the enrollment window.",
      },
    },
  },
  employeeBeneficiary: {
    id: 3,
    title: "How do my employees add beneficiaries?",
    info: "Beneficiaries can be added at any time, directly from the employee's benefit center.",
  },
  employeeClaims: {
    id: 4,
    title: "How do my employees file claims?",
    info: "Employees are able to file online, right from their benefits center.",
  },
};
