import React, { ReactElement, useContext } from "react";
import FileInput from "../../../FileInput";
import { useDocumentUploader } from "../../../../hooks/useDocumentUploader/useDocumentUploader";
import { useParams } from "react-router-dom";
import { QuestionnaireContext } from "pages/team-management/contexts/QuestionnaireContextProvider";

type DocumentListType = {
  addFileDocumentMapping: (fileName: string, documentId: string) => void;
  getDocumentIdByFileName: (fileName: string) => string | undefined;
  removeFileDocumentMapping: (fileName: string) => void;
  clearFileDocumentMapping: () => void;
  fileDocumentMap: Record<string, string>;
};

interface DocumentUploaderProps {
  documentList: DocumentListType;
}

const maxSize = 10 * 1024 * 1024; // 10MB
const accept = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
  "application/msword", // DOC
  "application/pdf", // PDF
  "image/jpeg", // JPEG
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
  "application/vnd.ms-excel", // XLS
  "message/rfc822", // EML
  "text/plain", // TXT
  "text/rtf", // RTF
];
export const DocumentUploader = ({
  documentList,
}: DocumentUploaderProps): ReactElement => {
  const { memberUuid } = useParams();
  const { incrementDocLoadingCounter, decrementDocLoadingCounter } =
    useContext(QuestionnaireContext);
  const { errors, loading, uploadDocument } = useDocumentUploader({
    accept,
    maxSize,
    memberUuid,
    incrementDocLoadingCounter,
    decrementDocLoadingCounter,
  });

  const isDisabled = loading;

  const handleFileChange = async (files?: File | File[] | undefined) => {
    if (!files) {
      console.warn("No files selected.");
      return;
    }

    const fileList = Array.isArray(files) ? files : [files];

    if (fileList.length === 0) {
      console.warn("No files selected.");
      return;
    }

    const uploadedDocumentPromises = fileList.map(async (file) => {
      const uploadedDocument = await uploadDocument(file);
      return {
        fileName: file.name,
        document: uploadedDocument,
      };
    });

    const uploadedDocuments = await Promise.all(uploadedDocumentPromises);

    uploadedDocuments.forEach(({ fileName, document }) => {
      if (document == undefined) {
        return null;
      }
      documentList.addFileDocumentMapping(fileName, document.id);
    });
  };

  return (
    <div>
      <FileInput
        disabled={isDisabled}
        accept={accept.join(",")}
        onFileChange={handleFileChange}
        multiple={true}
        onFileRemove={documentList.removeFileDocumentMapping}
        fileErrorMapping={errors}
      />
    </div>
  );
};
