import { RequestDetailExpense } from "types/Expenses";
import {
  SetRequestDetailPayload,
  SetRequestDetailExpensesPayload,
} from "../constants";

export enum RequestDetailActionTypes {
  SET_REQUEST_DETAIL = "SET_REQUEST_DETAIL",
  SET_CUSTOM_PAYDATE = "SET_CUSTOM_PAYDATE",
  RESET_REQUEST_DETAILS = "RESET_REQUEST_DETAILS",
  SET_IS_UNATTACHED_VIEW = "SET_IS_UNATTACHED_VIEW",
  SET_REQUEST_DETAIL_EXPENSES = "SET_REQUEST_DETAIL_EXPENSES",
  SET_IS_CUSTOM_PAYDATE_VALID = "SET_IS_CUSTOM_PAYDATE_VALID",
  SET_REQUEST_DETAILS_DETACHED_EXPENSES = "SET_REQUEST_DETAILS_DETACHED_EXPENSES",
}

export type RequestDetailActions =
  | {
      type: RequestDetailActionTypes.SET_REQUEST_DETAIL;
      payload: SetRequestDetailPayload;
    }
  | {
      type: RequestDetailActionTypes.SET_IS_UNATTACHED_VIEW;
      payload: boolean;
    }
  | {
      type: RequestDetailActionTypes.SET_REQUEST_DETAIL_EXPENSES;
      payload: SetRequestDetailExpensesPayload;
    }
  | {
      type: RequestDetailActionTypes.RESET_REQUEST_DETAILS;
    }
  | {
      type: RequestDetailActionTypes.SET_CUSTOM_PAYDATE;
      payload: string;
    }
  | {
      type: RequestDetailActionTypes.SET_IS_CUSTOM_PAYDATE_VALID;
      payload: boolean;
    }
  | {
      type: RequestDetailActionTypes.SET_REQUEST_DETAILS_DETACHED_EXPENSES;
      payload: RequestDetailExpense[];
    };
