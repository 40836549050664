import { InsuranceDocument } from "./constants";
import { RequestForm, WaiverJobLocation } from "./waivers-of-subrogation/types";
import {
  CreateInsuranceDocumentRequestMutationFn,
  InsuranceDocumentPriority,
  InsuranceDocumentRequest,
  WaiverInput,
} from "types/generated/operations";

function formatJobLocations(locations: WaiverJobLocation[]) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return locations.map(({ errors, ...keepAttrs }) => keepAttrs);
}

export function createDocumentRequest(
  createInsuranceDocumentRequestMutation: CreateInsuranceDocumentRequestMutationFn,
  formData: RequestForm
) {
  const jobDuties = formData.jobDuties || "";
  const jobName = formData.jobName || "";
  const effectiveDate = formData.dateStart?.toString() || "";
  const employees = formData.employees || [{ name: "", uuid: "" }];
  const endDate = formData.dateEnd?.toString() || "";
  const jobNumber = formData.jobNumber || "";
  const priority =
    formData.priority === "STANDARD"
      ? InsuranceDocumentPriority.Standard
      : InsuranceDocumentPriority.Urgent;

  const waiver: WaiverInput = {
    employees: employees,
    jobDuties: jobDuties,
    jobName: jobName,
    jobNumber: jobNumber,
    effectiveDate: effectiveDate,
    endDate: endDate,
    priority: priority,
    jobLocations:
      (formData.jobLocations && formatJobLocations(formData.jobLocations)) ||
      [],
  };

  const request: InsuranceDocumentRequest = {
    address: {
      street1: formData.street1,
      street2: formData.street2,
      city: formData.city,
      state: formData.state,
      postalCode: formData.postalCode,
    },
    certData: {
      primaryName: formData.certName,
      additionalInfo: formData.additionalInformation,
      additionalNames: formData.certAdditionalNames,
    },
    waiver: waiver.jobDuties.length > 0 ? waiver : null,
    type:
      jobDuties.length > 0 ? InsuranceDocument.Waiver : InsuranceDocument.Coi,
    documents: formData.documents,
  };

  return createInsuranceDocumentRequestMutation({
    variables: {
      request: request,
    },
  });
}
