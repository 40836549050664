import { ReviewerTabListTypes } from "./ReviewerHeader.constants";
import { ManagerTabList, ReporterTabList } from "./components";
import { AdminTabList } from "pages/expenses/components";

export const getReviewerTabList = (type?: ReviewerTabListTypes) => {
  switch (type) {
    case ReviewerTabListTypes.ADMIN:
      return <AdminTabList />;
    case ReviewerTabListTypes.MANAGER:
      return <ManagerTabList />;
    case ReviewerTabListTypes.REPORTER:
      return <ReporterTabList />;
    default:
      return null;
  }
};
