const LARGEST_DOLLAR_AMOUNT = 21000000.0;

export const isNumber = (value: number | string) => {
  const isString = typeof value === "string";

  if (isString) {
    const NUMBER_REGEX = new RegExp(/^-?[0-9.,]+$/);

    return NUMBER_REGEX.test(value);
  }

  return typeof value === "number";
};

export const isPositive = (value: number | string) => {
  const isString = typeof value === "string";

  if (isString) {
    return parseFloat(value) > 0;
  }

  return value > 0;
};

export const isNotNegative = (value: number | string) => {
  const isString = typeof value === "string";

  if (isString) {
    return parseFloat(value) >= 0;
  }

  return value >= 0;
};

export const isNotTooLargeDollars = (value: number | string) => {
  const isString = typeof value === "string";

  if (isString) {
    return Number(value.replaceAll(",", "")) <= LARGEST_DOLLAR_AMOUNT;
  }

  return value <= LARGEST_DOLLAR_AMOUNT;
};

export const isCurrencyFormat = (value: number | string) => {
  const isString = typeof value === "string";

  if (isString) {
    const CURRENCY_REGEX = new RegExp(
      /^\$?\-?([1-9]{1}[0-9]{0,2}(?:,?\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\-?\$?([1-9]{1}\d{0,2}(?:,?\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))$|^\(\$?([1-9]{1}\d{0,2}(?:,?\d{3})*(\.\d{0,2})?|[1-9]{1}\d{0,}(\.\d{0,2})?|0(\.\d{0,2})?|(\.\d{1,2}))\)$/
    );

    return CURRENCY_REGEX.test(value);
  }

  return false;
};

export const invalidStringInputValue = (
  value: string,
  characterLimit: number
) => {
  return (
    value.length > characterLimit || /\p{Extended_Pictographic}/u.test(value)
  );
};

export const invalidWhitespaceInputValue = (value: string) => {
  return value.length > 0 && !/\S/.test(value);
};

export const invalidCharacterLengthValue = (
  value: string,
  characterLimit: number
) => value.length > characterLimit;

export const invalidEmojiValue = (value: string) =>
  /\p{Extended_Pictographic}/u.test(value);

export const useValidators = <T>(rules: Array<(value: T) => boolean>) => {
  const validateRules = (value: T) => {
    return rules.every((rule) => rule(value));
  };

  return { validateRules };
};
