import React from "react";
import { CheckboxInput } from "@justworkshr/milo-form";
import { formatDate, getDollarsFromCents } from "pages/expenses/utils";
import { ReimbursementRequest } from "types/Expenses";
import { renderReviewStatusBadge } from "./ActiveRequestsTableRow.utils";
import { ReviewerPermissions } from "../../ActiveRequestsPage.types";
import { Link } from "react-router-dom";

interface ActiveRequestsTableRowProps {
  request: ReimbursementRequest;
  reviewerPermissions: ReviewerPermissions;
  isCheckboxActive: boolean;
  handleCheckboxChange: (uuid: string) => void;
  showNonReimbursableColumn: boolean;
}

export const ActiveRequestsTableRow: React.FC<ActiveRequestsTableRowProps> = ({
  request,
  isCheckboxActive,
  handleCheckboxChange,
  reviewerPermissions,
  showNonReimbursableColumn = false,
}) => {
  const hasMultipleExpenses = request.expenses.length > 1;

  return (
    <tr>
      <td>
        <CheckboxInput
          name={request.name}
          value={request.uuid}
          checked={isCheckboxActive}
          onChange={() => handleCheckboxChange(request.uuid)}
          data-testid="request-checkbox"
        />
      </td>
      <td>{request.member.friendlyFullName}</td>
      <td>
        <Link
          to={`/expenses/${request.uuid}/review`}
          data-testid="request-expense-link"
        >
          {request.name}
        </Link>
        {hasMultipleExpenses && ` (${request.expenses.length})`}
      </td>
      <td>{formatDate(request.submittedAt)}</td>
      {showNonReimbursableColumn && (
        <td>{getDollarsFromCents(request.totalNonReimbursableAmount)}</td>
      )}
      <td>{getDollarsFromCents(request.totalReimbursableAmount)}</td>
      <td>{renderReviewStatusBadge(request, reviewerPermissions)}</td>
    </tr>
  );
};

export default ActiveRequestsTableRow;
