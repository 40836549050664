import stlyes from "./MultiAction.module.css";
import React from "react";

const {
  MultiActionContainer,
  MultiActionActiveText,
  MultiActionSelectedText,
  MultiActionButtonsWrapper,
} = stlyes;

interface MultiActionProps<T> {
  selectedItems: T[];
  children: ({ isActive }: { isActive: boolean }) => React.ReactNode;
}

function MultiAction<T>({ selectedItems, children }: MultiActionProps<T>) {
  const isActive = selectedItems.length !== 0;
  const className = isActive ? MultiActionActiveText : MultiActionSelectedText;

  return (
    <div id="MultiActionWrapper">
      <div className={MultiActionContainer}>
        <div className={className}>{selectedItems.length} selected</div>
        <div className={MultiActionButtonsWrapper}>
          {children({ isActive })}
        </div>
      </div>
    </div>
  );
}

export default MultiAction;
