import * as Yup from "yup";

interface FormStep {
  stepNumber: number;
  description: string;
  formSchema: Yup.Schema;
  requiredFields: Record<string, boolean>;
}

export const FORM_STEPS: Record<string, FormStep> = {
  contact: {
    stepNumber: 1,
    description: `Add your primary contact to manage individual access within the firm.`,
    formSchema: Yup.object().shape({
      email: Yup.string()
        .email("This field must be a valid email")
        .required("This field is required"),
      firstName: Yup.string().min(1).required("This field is required"),
      lastName: Yup.string().min(1).required("This field is required"),
      firmName: Yup.string().min(1).required("This field is required"),
      message: Yup.string().max(10000),
    }),
    requiredFields: {
      email: true,
      firstName: true,
      lastName: true,
      firmName: true,
    },
  },
  permissions: {
    stepNumber: 2,
    description:
      'Connect with a firm by inviting an accountant there below. Once they accept the invite, they’ll be able to share access with their colleagues. They won’t be able to share access with anyone outside the firm unless they’re granted "Edit employee permissions".',
    formSchema: Yup.object().shape({
      permissions: Yup.array()
        .of(Yup.string())
        .min(
          1,
          "You'll need to select the permissions for your primary contact."
        ),
      terms: Yup.boolean()
        .required()
        .oneOf([true], "Be sure to accept the agreement to continue"),
    }),
    requiredFields: {},
  },
};
