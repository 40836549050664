import { FormEvent, ReactNode, createContext, useState } from "react";

interface PaymentDetailsPageContextProviderProps {
  children: ReactNode;
}

type SubmitHandler = (e?: FormEvent<HTMLFormElement>) => void;

interface PaymentDetailsPageContextValues {
  submitHandlers: { [key: string]: SubmitHandler };
  setSubmitHandlers?: (submitHandlers: {
    [key: string]: SubmitHandler;
  }) => void;
}

export const PaymentDetailsPageContext =
  createContext<PaymentDetailsPageContextValues>({
    submitHandlers: {},
  });

export const PaymentDetailsPageContextProvider = ({
  children,
}: PaymentDetailsPageContextProviderProps) => {
  const [submitHandlers, setSubmitHandlers] = useState<{
    [key: string]: SubmitHandler;
  }>({});

  return (
    <PaymentDetailsPageContext.Provider
      value={{ submitHandlers, setSubmitHandlers }}
    >
      {children}
    </PaymentDetailsPageContext.Provider>
  );
};
