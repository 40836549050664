import { Alert, Button, Card, PageHeader } from "@justworkshr/milo-core";
import {
  ActionFooter,
  Form,
  FormField,
  Select,
  SelectOption,
} from "@justworkshr/milo-form";
import { Formik, type FormikProps, type FormikValues } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Layout from "../../../Layout";
import onboardingStyles from "../../../Onboarding.module.css";
import useOnboardingForm from "../../../hooks/useOnboardingForm";
import styles from "../../ProfileInfo.module.css";
import { NATIONALITIES } from "../../constants";
import { compactTaxId } from "../../taxIdUtils";
import type { ProfileInfoFormTypeIE as ProfileInfoFormType } from "../../types";
import BaseFormFragment, {
  initialValues as baseInitialValues,
  schema as baseSchema,
} from "../base/BaseFormFragment";
import IdentifierNumberFormFragment, {
  initialValues as identifierNumberInitialValues,
  schema as identifierNumberSchema,
} from "../identifier-input/IdentifierNumberFormFragment";

const { inputField } = styles;
const STATE_KEY = "profile-info";

export default function ProfileInfoPT() {
  const { t } = useTranslation();
  const {
    loading,
    memberData: { workCountry },
    form: { submitting, showErrorAlert, errorAlertMessage, onSubmit },
    memberOnboarding: {
      stepNumber,
      redirecting,
      profileInfo,
      idVerificationStatus,
    },
  } = useOnboardingForm(STATE_KEY);

  const onFormSubmit = (values: FormikValues) => {
    // Compose date and identifier values.
    const profileInfoDateOfBirth =
      values.profileInfo.dateOfBirth.year +
      "-" +
      values.profileInfo.dateOfBirth.month +
      "-" +
      values.profileInfo.dateOfBirth.day;

    const profileInfoIdentifierNumber = compactTaxId(
      workCountry,
      values.profileInfo.identifierNumber
    );

    onSubmit({
      profileInfo: {
        ...values.profileInfo,
        confirmIdentifierNumber: undefined,
        dateOfBirth: profileInfoDateOfBirth,
        identifierNumber: profileInfoIdentifierNumber,
      },
    });
  };

  const profileInfoSchema = Yup.object({
    nationality: Yup.string()
      .oneOf(NATIONALITIES.map((nationality) => nationality.value))
      .required("Required."),
    ieMaritalStatus: Yup.string()
      .oneOf(["single", "married", "civilPartnership"])
      .required("Required."),
  });

  const initialValuesIE = {
    profileInfo: {
      nationality: "",
      ieMaritalStatus: "",
    },
  };

  const getInitialValues = (): ProfileInfoFormType => {
    const initialValues = {
      profileInfo: {
        ...baseInitialValues.profileInfo,
        ...identifierNumberInitialValues.profileInfo,
      },
    };

    const defaultInitialValues = profileInfo || initialValues;
    const mergedValues: ProfileInfoFormType = {
      profileInfo: {
        ...initialValuesIE.profileInfo,
        ...defaultInitialValues.profileInfo,
      },
    };

    return mergedValues;
  };

  const schema = Yup.object({
    profileInfo: profileInfoSchema
      .concat(baseSchema(t))
      .concat(identifierNumberSchema(t, "IE")),
  });

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {errorAlertMessage ||
            t("An error occurred while submitting your profile information.")}
        </Alert>

        <PageHeader title={t("Complete your profile")} />

        <Formik
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validationSchema={schema}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            touched,
            values,
          }: FormikProps<ProfileInfoFormType>) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Card title={t("Personal information")}>
                  <BaseFormFragment />

                  {/* IE specific fields */}
                  <div className={inputField}>
                    <FormField
                      name="profileInfo.nationality"
                      required
                      label={t("Nationality")}
                      error={
                        touched?.profileInfo?.nationality
                          ? errors?.profileInfo?.nationality
                          : ""
                      }
                    >
                      <Select
                        name="profileInfo.nationality"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.profileInfo.nationality}
                        placeholder="Select Nationality..."
                      >
                        {NATIONALITIES.map((country) => (
                          <SelectOption
                            value={country?.value}
                            key={country?.value}
                          >
                            {country?.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      name="profileInfo.ieMaritalStatus"
                      required
                      label={t("Marital Status")}
                      error={
                        touched?.profileInfo?.ieMaritalStatus
                          ? errors?.profileInfo?.ieMaritalStatus
                          : ""
                      }
                    >
                      <Select
                        name="profileInfo.ieMaritalStatus"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.profileInfo.ieMaritalStatus}
                        placeholder="Select marital status..."
                      >
                        <SelectOption value="single" key="single">
                          Single
                        </SelectOption>
                        <SelectOption value="married" key="married">
                          Married
                        </SelectOption>
                        <SelectOption
                          value="civilPartnership"
                          key="civilPartnership"
                        >
                          Civil Partnership
                        </SelectOption>
                      </Select>
                    </FormField>
                  </div>

                  {/* END IE specific fields */}

                  <IdentifierNumberFormFragment workCountry="IE" />
                </Card>

                <ActionFooter
                  className={onboardingStyles.footer}
                  actions={[
                    <Button
                      color="brand"
                      key="submit-btn"
                      loading={submitting}
                      type="submit"
                    >
                      {t("Save & continue")}
                    </Button>,
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </Layout>
  );
}
