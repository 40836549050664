import { ReactElement, useState, createRef, ChangeEvent } from "react";
import styles from "./search.module.css";
import SearchResultList from "./search-result-list";
import React from "react";
import { memberSearch } from "./member-search";
import { useGetUserInfoQuery } from "types/generated/operations";
import { GetUserInfoQueryCompanyMember } from "types";
import { buildWebPath } from "lib/resource-finder";

const { search, showResults } = styles;
const ARROW_DOWN = "ArrowDown";
const ARROW_UP = "ArrowUp";

export default function Search(this: HTMLDivElement): ReactElement {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showResultList, setResultListVisibility] = useState(false);
  const [term, setTerm] = useState("");
  const [isMouseInResultList, setIsMouseInResultList] = useState(false);

  const { data, loading } = useGetUserInfoQuery();

  //TODO: Handle logged out and dataless case
  //TODO: Use capabilities api to determine if this should appear, not whether we get members

  if (loading) return <></>;
  const resultOutput: GetUserInfoQueryCompanyMember[] = !data
    ? []
    : memberSearch(
        data.currentMember.company.directoryMembersWithoutInviteStatusCheck,
        term
      );
  const resultsList = createRef<HTMLUListElement>();
  const numResults = resultOutput.length;
  const searchResultList = (
    <SearchResultList
      resultOutput={resultOutput}
      selectedIndex={selectedIndex}
      term={term}
      resultsListRef={resultsList}
      shouldShow={showResultList}
      setIsMouseInResultList={setIsMouseInResultList}
      setSelectedIndex={setSelectedIndex}
    />
  );

  function navigateResults({ key }: React.KeyboardEvent): void {
    if (key !== ARROW_DOWN && key !== ARROW_UP) return;
    const boundary = key === ARROW_DOWN ? numResults - 1 : 0;
    const positionChange = key === ARROW_DOWN ? 1 : -1;

    if (selectedIndex === boundary) return;
    setSelectedIndex(selectedIndex + positionChange);
    scrollToSelected();
  }

  function scrollToSelected(): void {
    if (!resultsList.current) return;
    const listItem = resultsList.current.firstElementChild;
    if (listItem) {
      const height = parseInt(window.getComputedStyle(listItem).height);
      const padding = parseInt(window.getComputedStyle(listItem).padding);
      const border = parseInt(window.getComputedStyle(listItem).borderBottom);
      const boxHeight = height + padding * 2 + border;
      const pos = (selectedIndex - 1) * boxHeight;
      resultsList.current.scrollTop = pos;
    }
  }

  function onChange({ target }: ChangeEvent<HTMLInputElement>): void {
    setTerm(target.value || "");
    setResultListVisibility(!!target.value);
    setSelectedIndex(0);
  }

  function onBlur(): void {
    if (!isMouseInResultList) {
      setResultListVisibility(false);
    }
  }

  function onClick(): void {
    setResultListVisibility(!!term);
  }

  function onKeyEnter({ key }: React.KeyboardEvent): void {
    if (key === "Enter" && showResultList && resultOutput.length > 0) {
      window.location.assign(
        buildWebPath(`/members/${resultOutput[selectedIndex].uuid}`)
      );
    }
  }

  return (
    <div
      className={`${search} ${showResultList ? showResults : ""}`}
      onKeyDown={navigateResults}
    >
      <input
        type="text"
        placeholder="Find people..."
        aria-label="Find people..."
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        onKeyDown={onKeyEnter}
      ></input>
      {searchResultList}
    </div>
  );
}
