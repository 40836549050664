import { Link } from "react-router-dom";
import { prependBasePath } from "pages/expenses/constants";
import styles from "./SetupHeader.module.css";
import { PageHeader } from "@justworkshr/milo-core";

const { SetupHeaderWrapper, SetupHeaderContainer } = styles;

export const SetupHeader = () => {
  return (
    <div className={SetupHeaderWrapper}>
      <PageHeader
        border={false}
        title="Set up expenses"
        className={SetupHeaderContainer}
        linkPrevious={
          <Link
            to={prependBasePath("LEARN")}
            data-testid="back-to-product-detail"
          >
            Back to product detail
          </Link>
        }
        data-testid="get-started-button"
      />
    </div>
  );
};
