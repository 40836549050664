import { OPTIONAL, REQUIRED, HIDDEN } from "./general";

export const CATEGORY_STATUSES = {
  HIDDEN,
  OPTIONAL,
  REQUIRED,
};

export const ATTENDEES_CUSTOM_DESCRIPTION =
  "If this is a meal expense, attendees is required per IRS guidelines.";

export const BILLABLE_TO_CLIENT_DESCRIPTION =
  "Allow the employee to select if an expense is billable or non-billable to a client.";

export const ATTENDEES_FIELD_NAME = "attendees";
export const BILLABLE_TO_CLIENT_FIELD_NAME = "billableToClient";
export const EXPENSE_TYPE_FIELD_NAME = "expenseType";
export const RECEIPT_FIELD_NAME = "receipt";
export const MERCHANT_FIELD_NAME = "merchant";
export const DESCRIPTION_FIELD_NAME = "description";

export const CATEGORIES_FIELD_NAMES = [
  "name",
  "visible",
  RECEIPT_FIELD_NAME,
  MERCHANT_FIELD_NAME,
  ATTENDEES_FIELD_NAME,
  DESCRIPTION_FIELD_NAME,
  EXPENSE_TYPE_FIELD_NAME,
  BILLABLE_TO_CLIENT_FIELD_NAME,
];

export const ADDITIONAL_CATEGORIES_FIELD_NAMES = [
  RECEIPT_FIELD_NAME,
  MERCHANT_FIELD_NAME,
  ATTENDEES_FIELD_NAME,
  DESCRIPTION_FIELD_NAME,
  EXPENSE_TYPE_FIELD_NAME,
  BILLABLE_TO_CLIENT_FIELD_NAME,
];

export const ADDITION_FIELD_NAMES_DISABLED_OPTIONAL = [
  EXPENSE_TYPE_FIELD_NAME,
  BILLABLE_TO_CLIENT_FIELD_NAME,
];

export const MEALS_AND_ENTERTAINMENT_CATEGORY_NAME = "Meals & entertainment";

export const CATEGORY_FIELD_OPTIONS_OPTIONAL_DISABLED = [
  { value: OPTIONAL, label: "Optional", disabled: true },
  { value: REQUIRED, label: "Required", disabled: false },
];

export const CATEGORY_FIELD_OPTIONS = [
  { value: OPTIONAL, label: "Optional" },
  { value: REQUIRED, label: "Required" },
];

export const EXPENSE_REIMBURSABLE_LABEL = "Reimbursable";

export const EXPENSE_REIMBURSABLE_TYPE = "REIMBURSABLE";

export const EXPENSE_NON_REIMBURSABLE_TYPE = "NON_REIMBURSABLE";

export const EXPENSE_NON_REIMBURSABLE_LABEL = "Non-reimbursable";

export const BILLABLE_VALUE = "Billable";

export const NON_BILLABLE_VALUE = "Non-billable";
