import benefitsClasses from "pages/benefits/benefits.module.css";
import {
  Divider,
  UnorderedList,
  UnorderedListItem,
} from "@justworkshr/milo-core";

const SetupTab = () => {
  return (
    <div>
      <h2 className={benefitsClasses.benefitsHeader}>Here's what to expect</h2>

      <p className={benefitsClasses.benefitsParagraph}>
        After you select <strong>Get started</strong>, we'll ask you a few
        questions about your company and whether you want to set up a new 401(k)
        or transfer an existing plan to Empower. We'll pass that info to Empower
        and they'll reach out to get everything set up.
      </p>

      <p className={benefitsClasses.benefitsParagraph}>
        It takes around 45 calendar days to get set up from the date we receive
        your completed documentation. Start dates are offered the first of each
        month. Read the{" "}
        <a
          href="https://s3.amazonaws.com/jw-marketing-site-assets/401k/empower-timeline.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          Implementation Timeline
        </a>{" "}
        for a more detailed breakdown.
      </p>

      <Divider />

      <h3 className={benefitsClasses.benefitsHeader}>Employee enrollment</h3>

      <p className={benefitsClasses.benefitsParagraph}>
        Eligible employees will receive enrollment instructions and the required
        plan notices around the time the plan becomes effective. They'll also be
        invited to a virtual enrollment meeting with an Empower Retirement
        Education Counselor. Topics include:
      </p>

      <UnorderedList>
        <UnorderedListItem>
          How to enroll and who to contact for help
        </UnorderedListItem>
        <UnorderedListItem>How much does it take to retire?</UnorderedListItem>
        <UnorderedListItem>
          The different ways to add value to your account
        </UnorderedListItem>
        <UnorderedListItem>How Pre-tax contributions work</UnorderedListItem>
        <UnorderedListItem>How Roth contributions work</UnorderedListItem>
        <UnorderedListItem>
          Explanation of compound earnings and taxation
        </UnorderedListItem>
        <UnorderedListItem>Rolling over</UnorderedListItem>
        <UnorderedListItem>What type of investor are you?</UnorderedListItem>
      </UnorderedList>
    </div>
  );
};

export default SetupTab;
