import { Link } from "react-router-dom";
import styles from "./Button.module.css";
import Spinner from "../Spinner";

const {
  button,
  buttonContent,
  buttonIcon,
  buttonIconBeforeText,
  buttonIconAfterText,
  isHidden,
  isLoading,
  isDisabled,
  ...buttonTypes
} = styles;

type ModeTypes = "primary" | "secondary" | "frameless" | "text";
type SizeTypes = "default" | "large" | "small";
type ColorTypes = "blue" | "green" | "red" | "gray";
type ButtonTypes = "link" | "reset" | "submit" | "button" | "react-router-link";

interface IconProps {
  figure: React.ReactNode;
  afterText?: boolean;
  iconOnly?: boolean;
}

interface ButtonProps extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  mode?: ModeTypes;
  color?: ColorTypes;
  type?: ButtonTypes;
  size?: SizeTypes;
  children: React.ReactNode;
  href?: string;
  to?: string | object | ((event: React.MouseEvent<HTMLElement>) => void);
  loading?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  target?: string;
  icon?: IconProps;
}

function Button({
  className,
  mode = "primary",
  color = "blue",
  type = "submit",
  size = "default",
  children,
  href,
  to,
  loading = false,
  disabled = false,
  onClick,
  target,
  icon,
  ...otherProps
}: ButtonProps) {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      event.preventDefault();
      return;
    } else if (onClick) {
      onClick(event);
    } else {
      return true;
    }
  };

  const childrenContent =
    icon && icon.iconOnly ? (
      <span className={isHidden}>{children}</span>
    ) : (
      children
    );

  const content = (
    <div className={buttonContent}>
      {icon && !icon.afterText && (
        <figure className={[buttonIcon, buttonIconBeforeText].join(" ")}>
          {icon.figure}
        </figure>
      )}
      {childrenContent}
      {icon && icon.afterText && (
        <figure className={[buttonIcon, buttonIconAfterText].join(" ")}>
          {icon.figure}
        </figure>
      )}
    </div>
  );

  const classes = [
    button,
    className,
    buttonTypes[mode],
    buttonTypes[type],
    buttonTypes[size],
    buttonTypes[color],
  ];
  if (loading) {
    classes.push(isLoading);
  }
  if (disabled) {
    classes.push(isDisabled);
  }

  let spinner: React.ReactNode;
  if (loading) {
    spinner = <Spinner className={buttonTypes["spinner"]} />;
  }

  if (type === "link") {
    if (to) {
      throw new Error(
        "<Button> must be TYPES.REACT_ROUTER_LINK for `to` prop to work."
      );
    }
    return (
      <a
        role="button"
        href={href || "#"}
        aria-disabled={loading || disabled}
        onClick={handleClick}
        className={classes.join(" ")}
        target={target}
        rel={target === "_blank" ? "noopener noreferrer" : ""}
        aria-label={loading ? "Loading" : undefined}
      >
        {content}
        {spinner}
      </a>
    );
  } else if (type === "react-router-link") {
    return (
      <Link
        to={to || "#"}
        aria-disabled={loading || disabled}
        className={classes.join(" ")}
        onClick={handleClick}
        aria-label={loading ? "Loading" : undefined}
      >
        {content}
        {spinner}
      </Link>
    );
  }
  if (to) {
    throw new Error(
      "<Button> must be TYPES.REACT_ROUTER_LINK for `to` prop to work."
    );
  }
  if (target) {
    throw new Error("<Button> must be TYPES.LINK for `target` prop to work.");
  }
  if (href) {
    throw new Error(
      "<Button> must be TYPES.LINK or TYPES.REACT_ROUTER_LINK for `href` prop to work."
    );
  }
  return (
    <button
      disabled={loading || disabled}
      type={type}
      onClick={handleClick}
      className={classes.join(" ")}
      aria-label={loading ? "Loading" : undefined}
      {...otherProps}
    >
      {content}
      {spinner}
    </button>
  );
}

export default Button;
