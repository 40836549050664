import { ReactElement } from "react";
import { SystemIcon, SystemIconProps } from "@justworkshr/milo-icons";
import styles from "./user-link.module.css";

interface UserLinkProps {
  href: string;
  iconName?: SystemIconProps["iconName"];
  text: string;
  subText?: string;
  dimColor?: boolean;
}

export default function UserLink({
  href,
  iconName,
  text,
  subText,
  dimColor = false,
}: UserLinkProps): ReactElement {
  return (
    <a href={href} className={styles.link}>
      <div className={styles.iconContainer}>
        {iconName && (
          <SystemIcon
            iconName={iconName}
            size="small"
            color="brand"
            className={`${styles.userLinkIconNew}`}
          />
        )}
      </div>

      <div className={`${styles.text} ${dimColor ? styles.dimColor : ""}`}>
        <strong>{text}</strong>

        {subText && (
          <>
            <br />
            <span>{subText}</span>
          </>
        )}
      </div>
    </a>
  );
}
