import { OnboardingContextProvider } from "./contexts/onboardingContext";
import Skeleton from "./skeleton";
import { Route, Routes } from "react-router-dom";
import {
  PROFILE_INFO_PATH,
  ProfileInfo,
  CONTACT_INFO_PATH,
  ContactInfo,
  ID_VERIFICATION_PATH,
  IdVerification,
  PAYMENT_INFO_PATH,
  PaymentInfo,
  TAX_INFO_PATH,
  TaxInfo,
} from "./routes";

const Onboarding = () => (
  <OnboardingContextProvider>
    <Routes>
      <Route path={PROFILE_INFO_PATH} element={<ProfileInfo />} />
      <Route path={CONTACT_INFO_PATH} element={<ContactInfo />} />
      <Route path={ID_VERIFICATION_PATH} element={<IdVerification />} />
      <Route path={PAYMENT_INFO_PATH} element={<PaymentInfo />} />
      <Route path={TAX_INFO_PATH} element={<TaxInfo />} />
      <Route path="/" element={<Skeleton />} />
    </Routes>
  </OnboardingContextProvider>
);

export default Onboarding;
