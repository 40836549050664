export const SUBPATHS = {
  PERSONAL_INFO: "personal-info",
  JOB_INFORMATION: "job-information",
  FINANCES: "finances",
  TIME_OFF: "time-off",
  DOCUMENTS: "documents",
  EDIT: "edit",
};

export const CLOCKWORK_WEB_PATH = {
  MEMBERS_LIST: "/members/employees",
};

export const TABNAMES: Record<string, string> = {
  [SUBPATHS.PERSONAL_INFO]: "Personal Information",
  [SUBPATHS.JOB_INFORMATION]: "Job Information",
  [SUBPATHS.FINANCES]: "Finances",
  [SUBPATHS.TIME_OFF]: "Time Off",
  [SUBPATHS.DOCUMENTS]: "Documents",
};
