export const formatDollars = (numberString: string) => {
  const number = parseFloat(numberString);
  return "$" + number.toLocaleString("en-US", { maximumFractionDigits: 0 });
};

export const formatDayCount = (numberString: string) => {
  const number = parseFloat(numberString);
  return number.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

export const formatMonth = (currentDate: Date, delta: number = 0) => {
  const pastDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + delta,
    1
  );

  const dateFormatOptions: Intl.DateTimeFormatOptions = {
    month: "short",
    year: "numeric",
  };

  return Intl.DateTimeFormat("en-US", dateFormatOptions).format(pastDate);
};
