import { AlertData } from "../../../hooks/useAlert";
import { integrationConfigs } from "../../../configs/config";
import { Button } from "@justworkshr/milo-core";
import styles from "./actionAlerts.module.css";

const { integrationAlert, alertText } = styles;

//can add alerts for other actions as needed - assumed to follow naming structure {action_name}_success/{action_name}_failure
export const getDefaultActionAlerts = (
  solutionSlug: string
): { [key: string]: AlertData } => {
  const integrationName =
    integrationConfigs[solutionSlug].copy?.marketplaceCard.integrationName;

  return {
    keep_alive_success: {
      message: `Success! Your Justworks and ${integrationName} integration is refreshed.`,
      color: "additive" as const,
      visible: true,
      dismissible: true,
    },
    keep_alive_failure: {
      message: (
        <div className={integrationAlert}>
          <span className={alertText}>
            We couldn’t refresh your {integrationName} integration. Please try
            again.
          </span>
          <span>
            <Button
              color={"neutral"}
              variant={"ghost"}
              //can only access this button from pdp so location should always be the pdp page
              onClick={() =>
                (window.location.href =
                  window.location.href + "?action=keep_alive")
              }
            >
              Refresh Integration
            </Button>
          </span>
        </div>
      ),
      color: "destructive" as const,
      visible: true,
      dismissible: true,
    },
  };
};
