import styles from "./BankAccountDescription.module.css";

function accountDisplayName({
  bankName,
  accountNickname,
}: {
  bankName?: string;
  accountNickname?: string;
}) {
  if (bankName && accountNickname) return `${bankName} · ${accountNickname}`;
  if (bankName) return bankName;
  if (accountNickname) return accountNickname;

  return undefined;
}

type Props = {
  bankName?: string;
  accountNickname?: string;
  accountType?: string;
  defaultDisplayName: string;
};

export default function BankAccountDescription(props: Props) {
  const { bankName, accountNickname, accountType, defaultDisplayName } = props;
  const displayName =
    accountDisplayName({ bankName, accountNickname }) ?? defaultDisplayName;

  return (
    <div className={styles.container}>
      <div className={styles.primaryText}>{displayName}</div>
      <div className={styles.secondaryText}>{accountType ?? ""}</div>
    </div>
  );
}
