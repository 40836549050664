import SideMenuDrawer from "app-frame/header/side-menu/side-menu-drawer";
import { usePanelsControlContext } from "..";
import UserMenuDrawer from "app-frame/header/user-menu/user-menu-drawer";
import AlertContextProvider from "app-frame/header/alerts/context/alert-context-provider";
import AlertsDrawer from "app-frame/header/alerts/alerts-drawer";
import WhatsNewDrawer from "app-frame/header/whats-new/whats-new-drawer";
import HelpDrawer from "app-frame/header/help/help-drawer";
import SwitchCompaniesDrawer from "app-frame/header/switch-companies/switch-companies-drawer";
import styles from "./panels.module.css";
import {
  ViewType,
  useViewContext,
} from "app-frame/header/context/view-context-provider";
import { useEffect } from "react";

const { panelsContainer } = styles;

function Panels() {
  const { view } = useViewContext();
  const { state } = usePanelsControlContext();
  const {
    sideMenuDrawerShown,
    userMenuDrawerShown,
    alertsDrawerShown,
    whatsNewDrawerShown,
  } = state;

  useEffect(() => {
    if (view === ViewType.MOBILE) {
      if (userMenuDrawerShown || sideMenuDrawerShown) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    } else {
      if (alertsDrawerShown || whatsNewDrawerShown) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }
  }, [
    view,
    alertsDrawerShown,
    whatsNewDrawerShown,
    userMenuDrawerShown,
    sideMenuDrawerShown,
  ]);

  /* Alerts and What's New drawers are reused for all views */
  return (
    <div className={panelsContainer}>
      {view === ViewType.MOBILE && (
        <>
          <UserMenuDrawer />
          <SideMenuDrawer />
          <HelpDrawer />
          <SwitchCompaniesDrawer />
        </>
      )}
      <AlertContextProvider>
        <AlertsDrawer />
      </AlertContextProvider>
      <WhatsNewDrawer />
    </div>
  );
}

export default Panels;
