import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "pages/company-settings/components/InitiateMicrodepositPage.module.css";
import { Alert, Button } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import TermsAndConditionsCopy from "pages/company-settings/components/TermsAndConditionsCopy";
import { useBankForm } from "pages/company-settings/hooks/useBankForm";
import {
  useSubmitBankAccount,
  ProcessedBankResponseError,
} from "pages/company-settings/hooks/useSubmitBankAccount";
import BankAccountPageHeader from "./BankAccountPageHeader";

const { content, termsFooter, page } = styles;

export default function BankAccountConfirmationPage() {
  const { formData, returnToFormPage } = useBankForm();
  const navigate = useNavigate();
  const { mutate, error, isPending, isSuccess } = useSubmitBankAccount();
  const bankResponseError = error as ProcessedBankResponseError | null;

  useEffect(() => {
    if (
      !formData.accountNumber &&
      !formData.routingNumber &&
      !formData.bankName
    ) {
      return navigate("/company-settings/edit-bank-account");
    }
  }, [navigate, bankResponseError, formData]);

  const handleSubmit = () => {
    mutate(formData);
  };

  return (
    <div id="EditAccountTermsConditions">
      {!!bankResponseError && (
        <Alert color="destructive">
          Something went wrong. Please try submitting again.
        </Alert>
      )}
      <div className={page}>
        <div>
          <BankAccountPageHeader>
            Add the details for the new business bank account that you’d like to
            use. We securely verify your account information with GIACT, a
            trusted security industry leader.
          </BankAccountPageHeader>
        </div>
        <div className={content}>
          <TermsAndConditionsCopy />
        </div>
        <div className={termsFooter}>
          <ActionFooter
            secondary={
              <Button
                variant="outlined"
                onClick={returnToFormPage}
                key="cancel-button"
              >
                Go back
              </Button>
            }
            actions={[
              <Button
                data-testid="submit-button"
                type="submit"
                onClick={handleSubmit}
                // there is a small delay in when the response finalizes and the page redirects
                // so the button is still pressable for a split second after isPending goes back to false
                loading={isPending || isSuccess}
                key="save-button"
              >
                Agree and submit
              </Button>,
            ]}
          />
        </div>
      </div>
    </div>
  );
}
