import { ReactElement } from "react";
import { FormField } from "@justworkshr/milo-form";
import MessageWithLink from "pages/employer-of-record/components/MessageWithLink/MessageWithLink";
import {
  DatePicker,
  formatDatePickerDate,
  formatDateToISO8601,
} from "pages/employer-of-record/components";
import styles from "../../../../SetupFormGenerator.module.css";
import { isFutureDate } from "pages/employer-of-record/payments/utils";
import { GeneratedComponentPropWithSet } from "../../../../types";
import { FormPayloadType } from "../../../../../../types";

export function DateInput<T extends FormPayloadType = FormPayloadType>({
  field,
  accessor,
  touchedField,
  errorField,
  value,
  setFieldValue,
  handleBlur,
}: GeneratedComponentPropWithSet<T>): ReactElement {
  const disableFutureDate = ["transactionDate"];

  const handleIsValidDate = (
    currentDate: string,
    fieldName: string
  ): boolean => {
    const formattedDate = formatDatePickerDate(currentDate);
    if (isFutureDate(formattedDate) && disableFutureDate.includes(fieldName)) {
      return false;
    }
    return true;
  };

  return (
    <div className={`${errorField && touchedField ? styles.error : ""}`}>
      <FormField
        label={field.label}
        required={field.required}
        error={errorField && touchedField ? errorField : ""}
      >
        <>
          {field.message && (
            <div className={styles.description}>
              <MessageWithLink {...field.message} />
            </div>
          )}

          <DatePicker
            id={field.label}
            name={`setUp.${accessor}`}
            value={value}
            onBlur={handleBlur}
            isValidDate={(currentDate: string) =>
              handleIsValidDate(currentDate, field.name)
            }
            onChange={(dateValue: string) => {
              const formattedDate = formatDateToISO8601(dateValue);
              setFieldValue(`setUp.${accessor}`, formattedDate);
            }}
          />
        </>
      </FormField>
    </div>
  );
}
