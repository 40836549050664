import { ReactElement, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useGetFeatureFlag } from "lib/launch-darkly";

import { WaiverEdit } from "./waiver-edit";
import { WaiverReview } from "./waiver-review";

import {
  WAIVER_EDIT_RELATIVE_URL,
  WAIVER_REVIEW_RELATIVE_URL,
  WAIVER_SUBMIT_RELATIVE_URL,
  WAIVER_SUBMIT_URL,
  WAIVER_EDIT_URL,
  inititalWaiverState,
  initialWaiverLocation,
} from "../constants";
import RequestSubmitted from "./request-submitted";

import {
  WaiverJobLocation,
  FormData,
  SelectedEmployeesType,
  RequestForm,
} from "./types";
import { Page404 } from "pages/page-404";

import { useCreateInsuranceDocumentRequestMutation } from "types/generated/operations";
import { createDocumentRequest } from "../api";

export default function WaiversContainer(): ReactElement {
  const [formData, setFormData] = useState<FormData>(inititalWaiverState);
  const [waiverAddresses, setWaiverAddresses] = useState<WaiverJobLocation[]>([
    initialWaiverLocation,
  ]);
  const [supportingDocuments, setSupportingDocuments] = useState<
    {
      dataUrl: string | undefined;
      file: File | undefined;
    }[]
  >([]);
  const [submitError, setSubmitError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [createInsuranceDocumentRequestMutation] =
    useCreateInsuranceDocumentRequestMutation();

  const navigate = useNavigate();
  const { getFeatureFlag } = useGetFeatureFlag();
  const supportingDocumentsEnabled = getFeatureFlag(
    "waiver_coi_supporting_documentation"
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const employees: SelectedEmployeesType[] = formData.employees.map(
      ({ uuid, name }) => {
        return {
          uuid,
          name,
        };
      }
    );
    const formatted: RequestForm = {
      ...formData,
      employees,
      jobLocations: waiverAddresses,
    };

    if (supportingDocumentsEnabled && supportingDocuments.length > 0) {
      const documents = supportingDocuments.map((document) => {
        return {
          contentType: document.file?.type || "",
          originalFilename: document.file?.name || "",
          blobFile: document.dataUrl || "",
        };
      });

      formatted.documents = documents;
    }

    try {
      setIsLoading(true);
      const result = await createDocumentRequest(
        createInsuranceDocumentRequestMutation,
        formatted
      );
      if (result) {
        if (result.data?.createInsuranceDocumentRequest?.success === false) {
          const error = result.data.createInsuranceDocumentRequest.error;
          const setError = error === undefined || error === null ? "" : error;
          setSubmitError(setError);
          setIsLoading(false);
          navigate(WAIVER_EDIT_URL);
        } else {
          setIsLoading(false);
          setFormData({
            ...formData,
            ...inititalWaiverState,
          });
          setWaiverAddresses([initialWaiverLocation]);
          navigate(WAIVER_SUBMIT_URL);
        }
      }
    } catch (error: unknown) {
      const errorMessage =
        "Sorry! Looks like there was an issue completing this task. You can refresh your browser and try again. If you continue experiencing issues, reach out to our customer support team.";
      setSubmitError(errorMessage);
      setIsLoading(false);
    }
    return;
  };

  return (
    <div data-testid="waiverContainer">
      <Routes>
        <Route
          path={WAIVER_EDIT_RELATIVE_URL}
          element={
            <WaiverEdit
              formData={formData}
              waiverAddresses={waiverAddresses}
              setWaiverAddresses={setWaiverAddresses}
              setFormData={setFormData}
              submitError={submitError}
              setDocuments={setSupportingDocuments}
            />
          }
        />
        <Route
          path={WAIVER_REVIEW_RELATIVE_URL}
          element={
            <WaiverReview
              formData={formData}
              jobLocations={waiverAddresses}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              submitError={submitError}
              supportingDocuments={supportingDocuments}
            />
          }
        />

        <Route
          path={WAIVER_SUBMIT_RELATIVE_URL}
          element={<RequestSubmitted />}
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </div>
  );
}
