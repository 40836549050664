import styles from "./BankAccountAttribute.module.css";

type Props = {
  label: string;
  value: string;
};

export default function BankAccountAttribute({ label, value }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
}
