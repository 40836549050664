import { createContext, FC, ReactNode, useState } from "react";

export type AlertType = {
  color:
    | "additive"
    | "destructive"
    | "new"
    | "warning"
    | "disabled"
    | "info"
    | undefined;
  message: ReactNode;
};

interface AlertContextType {
  alert: AlertType | null;
  setAlert: (value: AlertType | null) => void;
  clearAlert: () => void;
}

export const AlertContext = createContext<AlertContextType>({
  alert: null,
  setAlert: () => {},
  clearAlert: () => {},
});

interface AlertContextProviderProps {
  children: ReactNode;
}

export const AlertContextProvider: FC<AlertContextProviderProps> = ({
  children,
}) => {
  const [alert, setAlert] = useState<AlertType | null>(null);

  return (
    <AlertContext.Provider
      value={{
        alert,
        setAlert,
        clearAlert: () => setAlert(null),
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};
