import { FormikFormField } from "lib/formik/FormikFormField";
import { useFormikContext } from "formik";
import { AdditionalPaymentsFormValues } from "../../../../SeparationPay.formUtils";
import styles from "../../../../SeparationPay.module.css";
import {
  RadioButton,
  RadioButtonGroup,
} from "pages/team-management/components";
import {
  BONUS_TYPE_INPUT_ROW_COPY,
  BONUS_TYPE_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
} from "./BonusTypeInputRow.constants";

const { RowContainer, HeaderContainer, SubHeadingMd, InputRowSubheader } =
  styles;

export const BonusTypeInputRow = () => {
  const { setFieldValue } = useFormikContext<AdditionalPaymentsFormValues>();

  return (
    <div>
      <div className={HeaderContainer}>
        <div className={SubHeadingMd}>Bonus type</div>
        <div className={InputRowSubheader}>
          Read more about the different{" "}
          <a href="https://help.justworks.com/hc/en-us/articles/360059186731">
            types of bonuses and examples
          </a>{" "}
          and read the{" "}
          <a href="https://www.dol.gov/agencies/whd/fact-sheets/56c-bonuses">
            Dept. of Labor Fact Sheet on Bonuses under the FLSA
          </a>
          . Be sure to also check applicable state law, which may have different
          requirements.
          <br />
          <br />
          Learn more about overtime requirements for{" "}
          <a href="https://www.dir.ca.gov/dlse/faq_overtime.htm">
            flat sum bonuses in California
          </a>
          .
        </div>
      </div>

      <div className={RowContainer}>
        <FormikFormField
          name="bonus_type"
          label={BONUS_TYPE_INPUT_ROW_COPY.bonus_type}
          required={true}
        >
          <RadioButtonGroup
            name="bonus_type"
            data-testid="bonus_type"
            onChange={(option) => {
              setFieldValue("bonus_type", option);
            }}
            variant="framed"
            orientation="horizontal"
          >
            {Object.entries(BONUS_TYPE_OPTIONS).map(([key, value]) => {
              return (
                <RadioButton
                  data-testid={`bonus_type__${key}`}
                  key={key}
                  value={key}
                  label={value.label}
                  description={value.description}
                />
              );
            })}
          </RadioButtonGroup>
        </FormikFormField>
      </div>

      <div className={RowContainer}>
        <FormikFormField
          name="is_net_pay"
          label={BONUS_TYPE_INPUT_ROW_COPY.is_net_pay}
          message={BONUS_TYPE_INPUT_ROW_COPY.is_net_pay_message}
          required={true}
        >
          <RadioButtonGroup
            name="is_net_pay"
            data-testid="is_net_pay"
            onChange={(option) => {
              setFieldValue("is_net_pay", option);
            }}
            variant="framed"
            orientation="horizontal"
          >
            {Object.entries(PAYMENT_TYPE_OPTIONS).map(([key, value]) => {
              return (
                <RadioButton
                  data-testid={`is_net_pay__${key}`}
                  key={key}
                  value={key}
                  label={value.label}
                  description={value.description}
                />
              );
            })}
          </RadioButtonGroup>
        </FormikFormField>
      </div>
    </div>
  );
};
