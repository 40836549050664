import { ReactElement } from "react";
import { ProductPosts } from "types";
import WhatsNewNotification from "../whats-new-notification/whats-new-notification";
import styles from "./whats-new-inbox.module.css";

const {
  zeroState,
  endIcon,
  endItem,
  endText,
  listItem,
  listItemWithImage,
  whatsNewList,
} = styles;

interface WhatsNewInboxTypes {
  productPosts: ProductPosts | null | undefined;
}

export default function WhatsNewInbox(
  WhatsNewInbox: WhatsNewInboxTypes
): ReactElement {
  if (WhatsNewInbox.productPosts?.posts?.length === 0) {
    return (
      <div className={zeroState}>
        <h5>Get your news here.</h5>
        <p>
          Justworks will post all product updates that are relevant to you here,
          so you always know what's going on. Want to know more about all of our
          updates? <br /> <br />
          <a
            href="https://updates.justworks.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Check out our changelog
          </a>
        </p>
      </div>
    );
  }

  const generateEndItem = () => {
    return (
      <div className={endItem}>
        <span className={endIcon} />
        <p className={endText}>
          You're all caught up! Looking for more reading?{" "}
          <a
            href="https://updates.justworks.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Check out our changelog
          </a>
        </p>
      </div>
    );
  };

  const whatsNewPosts = WhatsNewInbox.productPosts?.posts?.map((item) => {
    return (
      <li
        key={item.title}
        className={item.image ? listItemWithImage : listItem}
        data-testid="whats-new-notification"
      >
        <WhatsNewNotification
          key={item.title}
          category={item.category}
          date={item.date}
          description={item.description}
          title={item.title}
          seen={item.seen}
          url={item.url || undefined}
          image={item.image || undefined}
          call_to_action={item.callToAction || undefined}
        />
      </li>
    );
  });

  return (
    <ul className={whatsNewList}>
      {whatsNewPosts}
      <li key="end-item" className={listItem}>
        {generateEndItem()}
      </li>
    </ul>
  );
}
