export enum TaxMethodTypes {
  FLAT = "flat",
  CUMULATIVE = "cumulative",
}

export enum DeductionsSettingTypes {
  ALL = "all",
  ONLY_401K = "401k",
  NONE = "none",
}

export enum PayGroupTypes {
  MONTHLY = "monthly",
  QUARTERLY = "quarterly",
}
