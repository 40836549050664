import { useState } from "react";
import WaiverPrioritySelect from "./waiver-priority-select";
import { InsuranceDocumentPriority } from "types/generated/operations";
import styles from "./waiver-priority.module.css";

const { selectContainer, formBorder, switchCustom, slider, switchBox } = styles;

const options = [
  {
    title: "Stay on the standard timeline",
    description: "Expected for most requests.",
    value: InsuranceDocumentPriority.Standard,
  },
  {
    title: "Expedite this request",
    description: "Select only if this is an urgent need.",
    value: InsuranceDocumentPriority.Urgent,
  },
];

type WaiverPriorityProps = {
  priority: string;
  setPriority: (value: string) => void;
};

export default function WaiverPriority({
  priority,
  setPriority,
}: WaiverPriorityProps): JSX.Element {
  const [showPriority, setShowPriority] = useState(false);
  const [selected, setSelected] = useState(priority);

  function updateSelected(priorityLevel: string) {
    setSelected(priorityLevel);
    setPriority(priorityLevel);
  }

  return (
    <section className={formBorder} data-testid="waiverPrioritySection">
      <h3>Waivers are processed in 3 - 5 business days.</h3>
      <p>
        Estimated turnaround times are dependent on correct and accurate
        information. We'll reach out if we need any revisions.
      </p>
      <div className={switchBox}>
        <label className={switchCustom}>
          <input
            type="checkbox"
            onChange={() => setShowPriority((prev) => !prev)}
          />
          <span className={slider} />
        </label>
        <span>I may need this waiver expedited</span>
      </div>
      {showPriority && (
        <div className={selectContainer}>
          {options.map(({ title, description, value }) => {
            return (
              <WaiverPrioritySelect
                title={title}
                value={value}
                description={description}
                isSelected={selected === value}
                setSelected={updateSelected}
                key={value}
              />
            );
          })}
        </div>
      )}
    </section>
  );
}
