import {
  GetTransactionsPageDataQuery,
  TransactionTypeEnum,
  useGetTransactionsPageDataQuery,
} from "types/generated/operations";

export interface Transaction {
  id: string;
  type: string;
  date: Date;
  description: string;
  descriptionLink?: string | null;
  amount: number;
}

export interface BankAccount {
  accountTail: string;
  accountType: string;
  bankName: string;
  verified: boolean;
}

export interface Wallet {
  id: string;
  customerId: string | null | undefined;
  ownerName: string;
  amount: number;
}

const transactionTypeDescription: Record<TransactionTypeEnum, string> = {
  [TransactionTypeEnum.Collection]: "Invoice collection",
  [TransactionTypeEnum.Disbursement]: "Invoice disbursement",
  [TransactionTypeEnum.ExternalTransfer]: "External transfer",
  [TransactionTypeEnum.InternalFunding]: "Internal funding",
  [TransactionTypeEnum.NonWalletTransaction]: "Non-Wallet Transaction",
  [TransactionTypeEnum.Refund]: "Invoice refund",
  [TransactionTypeEnum.Unknown]: "Unknown",
};

const mapTransaction = (
  transaction: GetTransactionsPageDataQuery["transactions"][0]
): Transaction => {
  return {
    ...transaction,
    date: transaction.date ? new Date(transaction.date) : new Date(),
    type: mapTransactionType(transaction.type),
  };
};

const mapBankAccount = (
  bankAccount?: GetTransactionsPageDataQuery["companyBankAccount"]
): BankAccount | null => {
  if (!bankAccount) {
    return null;
  }

  return {
    accountTail: bankAccount.accountTail || "unknown",
    accountType: bankAccount.accountType || "unknown",
    bankName: bankAccount.bankName || "unknown",
    verified: bankAccount.verified || false,
  };
};

const mapWallet = (
  wallet?: GetTransactionsPageDataQuery["companyWallet"]
): Wallet | null => {
  if (!wallet) {
    return null;
  }

  return {
    id: wallet.id,
    customerId: wallet.customerId || "unknown",
    ownerName: wallet.ownerName || "unknown",
    amount: wallet.balanceAmount?.value || 0,
  };
};

const mapTransactionType = (type: TransactionTypeEnum): string =>
  transactionTypeDescription[type] || "Unknown";

const mapTransactions = (
  data?: GetTransactionsPageDataQuery["transactions"]
): Transaction[] => {
  return (data || []).map(mapTransaction);
};

export const useTransactionsPageData = () => {
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  const { data, ...rest } = useGetTransactionsPageDataQuery({
    variables: { startDate: threeMonthsAgo.toISOString().substring(0, 10) },
  });

  return {
    transactions: mapTransactions(data?.transactions),
    bankAccount: mapBankAccount(data?.companyBankAccount),
    wallet: mapWallet(data?.companyWallet),
    data,
    ...rest,
  };
};
