import * as Yup from "yup";
import { noEmojiTest } from "../../validations";

export const expensePayloadSchema = Yup.object().shape({
  expenseDetails: Yup.object().shape({
    distance: Yup.lazy((_, { context }) => {
      if (context.setUp.expenseType === "Mileage") {
        return Yup.string()
          .required("Distance is required")
          .test(
            "is-number",
            "This field must contain only numbers",
            (value) => {
              if (value) {
                const numberWithoutCommas = value.replace(/,/g, "");
                return !isNaN(Number(numberWithoutCommas));
              }
              return true;
            }
          );
      }

      return Yup.object();
    }),
    transactionDate: Yup.string().required("Transaction date is required"),

    receipts: Yup.array()
      .required("Receipt is required")
      .min(1, "Receipt is required"),
    additionalInformation: noEmojiTest.required(
      "Additional information is required"
    ),
  }),
  paymentSettings: Yup.object().shape({
    payPeriodId: Yup.string().required("Pay date is required"),
    notes: noEmojiTest,
  }),
});
