import React from "react";
import { AuditLogItem } from "../AuditLogItem";
import { handleParentDifference } from "../../AuditLogPage.utils";
import { AuditLogPaymentResubmittedType } from "types/Expenses";

interface ResubmittedProps {
  name: string;
  occurredAt: string;
  memberName: string;
  nextMemberName: string;
  differences: AuditLogPaymentResubmittedType["differences"];
}

export const Resubmitted: React.FC<ResubmittedProps> = ({
  name,
  memberName,
  occurredAt,
  nextMemberName,
  differences,
}) => {
  return (
    <AuditLogItem
      author={memberName}
      occurredAt={occurredAt}
      previewText="Resubmitted request"
    >
      <p>
        {name} was resubmitted to {nextMemberName}. Here's what changed:
      </p>
      {differences.map(({ type, ...props }, index) => (
        <React.Fragment key={`${type}_${index}`}>
          {handleParentDifference(props)}
        </React.Fragment>
      ))}
    </AuditLogItem>
  );
};
export default Resubmitted;
