import { FormField } from "@justworkshr/milo-form";
import {
  DatePicker,
  formatDatePickerDate,
} from "pages/expenses/components/DatePicker";
import { useRequestDetail } from "pages/expenses/store";
import {
  formatDate,
  validatePayDate,
  isEarliestPayDateOrAfter,
  PayDateValidationResponse,
} from "pages/expenses/utils";
import { useEffect, useState } from "react";
import { useGetPayDateQuery } from "types/generated/operations";
import styles from "../../ReimbursementSummary.module.css";
import { TOO_EARLY_TOO_TRANSFER_ERROR } from "./PayDatePicker.constants";

const { ReimbursementSummaryItemPayDateWrapper } = styles;

export const PayDatePicker = () => {
  const { loading: isPayDateLoading, data: payDayDateData } =
    useGetPayDateQuery();

  const {
    requestDetail: {
      metadata: { customPayDate },
    },
    setCustomPayDate,
    setIsCustomPayDateValid,
  } = useRequestDetail();

  const [payDateError, setPayDateError] = useState("");
  const [isPayDatePickerDisabled, setIsPayDatePickerDisabled] = useState(false);

  const earliestPayDate = formatDate(
    payDayDateData?.expenseManagement?.earliestPayDate || ""
  );

  const handlePayDateResponse = (response: PayDateValidationResponse) => {
    if (!isEarliestPayDateOrAfter(customPayDate, earliestPayDate)) {
      setIsCustomPayDateValid(false);
      setPayDateError(TOO_EARLY_TOO_TRANSFER_ERROR);
    } else if (!response?.data?.payDateValid && response.message) {
      setIsCustomPayDateValid(false);
      setPayDateError(response.message);
    } else {
      setIsCustomPayDateValid(true);
      setPayDateError("");
    }
  };

  const handleCustomPayDateChange = async (dateValue: string) => {
    setCustomPayDate(dateValue);

    setIsPayDatePickerDisabled(true);
    const response = await validatePayDate(dateValue);
    setIsPayDatePickerDisabled(false);
    handlePayDateResponse(response);
  };

  useEffect(() => {
    if (!isPayDateLoading && earliestPayDate) {
      setCustomPayDate(earliestPayDate);
    }
  }, [isPayDateLoading, earliestPayDate, setCustomPayDate]);

  const handleIsValidDate = (currentDate: string) => {
    const formattedDate = formatDatePickerDate(currentDate);

    return isEarliestPayDateOrAfter(formattedDate, earliestPayDate);
  };

  if (isPayDateLoading) {
    return null;
  }

  return (
    <div className={ReimbursementSummaryItemPayDateWrapper}>
      <FormField aria-labelledby="_" required={false} error={payDateError}>
        <DatePicker
          id="customPayDate"
          name="customPayDate"
          value={customPayDate}
          isValidDate={handleIsValidDate}
          disabled={isPayDatePickerDisabled}
          onChange={handleCustomPayDateChange}
        />
      </FormField>
    </div>
  );
};

export default PayDatePicker;
