import { TimePicker } from "../../../../../../../../components/TimePicker/TimePicker";
import styles from "./TimePickerQuestion.module.css";

const { TimeQuestion } = styles;

interface TimePickerProps {
  questionId: string;
  value: string;
  onChange: (value: string) => void;
}

export const TimePickerQuestion = ({
  questionId,
  value,
  onChange,
}: TimePickerProps) => {
  return (
    <TimePicker
      id={questionId}
      name={questionId}
      value={value}
      handleChange={onChange}
      className={TimeQuestion}
    />
  );
};
