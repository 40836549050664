import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { flagIsEnabled, FLAG_NAMES } from "lib/environment";
import { EoROnboarding } from "./onboarding";
import { ONBOARDING_PATH } from "./routes";
import { EoRMemberContextProvider } from "./contexts/eorMemberContext";
import { SupportedCountriesContextProvider } from "./contexts/supportedCountriesContext";

const EmployerOfRecordFrameless = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <EoRMemberContextProvider>
      <SupportedCountriesContextProvider>
        <Routes>
          {flagIsEnabled(FLAG_NAMES.EMPLOYER_OF_RECORD) && (
            <Route path={`${ONBOARDING_PATH}/*`} element={<EoROnboarding />} />
          )}
        </Routes>
      </SupportedCountriesContextProvider>
    </EoRMemberContextProvider>
  );
};

export default EmployerOfRecordFrameless;
