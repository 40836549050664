import * as Yup from "yup";

export const ERRORS = {
  GENERIC_ERROR_MESSAGE: "Something went wrong. Please try again.",
  FILE_TOO_BIG:
    "This file exceeds the limit of 20MB. Please upload a smaller file.",
  EMPTY_FILE: "Don't forget to attach your document.",
  EMPTY_NAME: "Everything needs a name, even documents.",
  EMPTY_CATEGORY: "Don't forget to select a category.",
};

export const defaultValues = {
  file: null,
  name: "",
  category: "",
  signatureRequired: false,
  remindersSet: false,
  memberAccess: false,
};

export const requiredFields = {
  file: true,
  name: true,
  category: true,
};

export const MAX_FILE_SIZE = 20000000;

export const formSchema = Yup.object().shape({
  file: Yup.mixed(),
  name: Yup.string().required(ERRORS.EMPTY_NAME),
  category: Yup.string().required(ERRORS.EMPTY_CATEGORY),
  signatureRequired: Yup.boolean(),
  remindersSet: Yup.boolean(),
  memberAccess: Yup.boolean(),
});

export const CATEGORIES = [
  {
    value: "contracts",
    description: "Contract",
  },
  {
    value: "handbook",
    description: "Employee Handbook",
  },
  {
    value: "offer_letter",
    description: "Offer Letter",
  },
  {
    value: "performance_review",
    description: "Performance Review",
  },
  {
    value: "policy",
    description: "Policy",
  },
  {
    value: "stock_option_document",
    description: "Stock Option Document",
  },
  {
    value: "other",
    description: "Other",
  },
  {
    value: "compliance",
    description: "Compliance document",
  },
  {
    value: "i9",
    description: "Past I-9s",
  },
  {
    value: "sui",
    description: "State Unemployment Document",
  },
  {
    value: "compensation_letter",
    description: "Compensation Change Notification Letter",
  },
];

export const GENERIC_ERROR_MESSAGE = "Something went wrong. Please try again.";
