import {
  FileInputState,
  FileInputAction,
  FileInputStateActionType,
} from "./types";

const addFilesWithNoDupes = (stateFiles: File[], newFiles: File[]): File[] => {
  const newFileNames = newFiles.map((file) => file.name);
  const uniqueStateFiles = stateFiles.filter(
    (file) => !newFileNames.includes(file.name)
  );
  return [...uniqueStateFiles, ...newFiles];
};

function reducer(
  state: FileInputState,
  action: FileInputAction
): FileInputState {
  switch (action.type) {
    case FileInputStateActionType.FILE_HOVER:
      return { ...state, dragActive: true };
    case FileInputStateActionType.FILE_UNHOVER:
      return { ...state, dragActive: false };
    case FileInputStateActionType.PROCESS_FILE:
      return {
        ...state,
        dragActive: false,
        processingFile: true,
        file: action.file ?? [],
      };
    case FileInputStateActionType.COMPLETE_PROCESSING:
      return {
        ...state,
        processingFile: false,
        file: action.file ?? [],
      };
    case FileInputStateActionType.PROCESS_MULTIPLE_FILES:
      return {
        ...state,
        dragActive: false,
        processingFile: true,
        file: addFilesWithNoDupes(state.file, action.file ?? []),
      };
    case FileInputStateActionType.COMPLETE_MULTIPLE_PROCESSING:
      return {
        ...state,
        processingFile: false,
      };
    case FileInputStateActionType.REMOVE_FILE:
      return {
        ...state,
        file: state.file?.filter((file) => file.name !== action.fileName),
      };
    default:
      return state;
  }
}

export default reducer;
