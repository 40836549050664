import { useContext, useEffect, useState } from "react";
import styles from "../SeparationPay.module.css";
import { AdditionalPaymentsForm } from "./components";
import { SeparationFlowContext } from "pages/team-management/contexts";
import { Button } from "@justworkshr/milo-core";
import { useNavigate, useParams } from "react-router-dom";
import { PreambleForm } from "./components/PreambleForm";
import {
  AdditionalPaymentsPageContext,
  AdditionalPaymentsPageContextProvider,
} from "./AdditionalPaymentsPageContextProvider";
import { SEPARATION_FLOW_COPY } from "../../SeparationFlow.constants";
import { useGetMemberSeparation } from "pages/team-management/hooks/useGetMemberSeparation";
import { Loading } from "../../Loading/Loading";
import { ROUTES } from "pages/team-management/constants";
import { getInitialValues } from "../../SeparationReason/InitialIntakePage/components/InitialIntakeForm/InitialIntakeForm.utils";
import { nonSeverancePaymentKeys } from "../PaymentDetailsPage/PaymentDetailsPage.utils";
import { scrollToFirstError } from "../SeparationPay.formUtils";
import { AlertMessage } from "../../../../components/AlertMessage";
import { isoStringToDate } from "../../SeparationReview/SeparationReview.util";
import {
  ELIGIBLE_MEMBER_TYPES,
  NO_PERMISSION_COPY,
  RECIPIENT_NOT_ELIGIBLE_COPY,
} from "./AdditionalPaymentsPage.constants";

const { FooterContainer } = styles;

const SubmitButton = () => {
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const { submitHandlers } = useContext(AdditionalPaymentsPageContext);
  const {
    additionalPaymentsFormValues,
    setAdditionalPaymentsFormValues,
    hasAdditionalPayments,
    isKnownAmount,
  } = useContext(SeparationFlowContext);
  const navigate = useNavigate();
  const { memberUuid } = useParams();

  // Ensures whenever we come back to this page, we reset the submitted state
  // We use this state variable so that if we came back to this page to edit the values
  // (so it's already in a completely validated state), we wouldn't immediately proceed
  // to the next page without the user clicking the submit button again.
  useEffect(() => {
    setHasSubmitted(false);
  }, []);

  // Because we have no callback listeners to know when all the submit handlers for the various
  // payment forms have validated and executed successfully, we listen for when each one has
  // successfully added it's form values to the context and then navigate to the next page.
  useEffect(() => {
    if (
      hasSubmitted &&
      additionalPaymentsFormValues &&
      Object.keys(submitHandlers).length ===
        Object.keys(additionalPaymentsFormValues).length
    ) {
      if (nonSeverancePaymentKeys(additionalPaymentsFormValues).length === 0) {
        navigate(`/team-management/separation-flow/${memberUuid}/review`);
      } else {
        navigate(
          `/team-management/separation-flow/${memberUuid}/payment-details`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalPaymentsFormValues, hasSubmitted]);

  // When the user clicks the submit button, we need to call each payment's form
  // submit handler to validate them and add their values to the context.
  const submitAllPayments = () => {
    // Reset the values currently stored in the context so we only add
    // the latest values on this page after being validated.
    setAdditionalPaymentsFormValues?.([]);

    if (!hasAdditionalPayments || !isKnownAmount) {
      navigate(`/team-management/separation-flow/${memberUuid}/review`);
      return;
    }

    Object.values(submitHandlers).forEach((handler) => handler());
    setHasSubmitted(true);
    scrollToFirstError();
  };

  return (
    <Button
      type={"button"}
      data-testid="submit-button"
      onClick={submitAllPayments}
    >
      {SEPARATION_FLOW_COPY.next}
    </Button>
  );
};

const statusToPath = (status: string | undefined): string => {
  switch (status) {
    case "questionnaire_complete":
    case "questionnaire_partially_complete":
    case "intake_complete":
      return ROUTES.SEPARATION_REASON_QUESTIONNAIRE;
    default:
      return ROUTES.SEPARATION_REASON_INITIAL_INTAKE;
  }
};

export const AdditionalPaymentsPage = () => {
  const {
    canMakePayments,
    additionalPaymentsFormValues,
    hasAdditionalPayments,
    setHasAdditionalPayments,
    isKnownAmount,
    member,
    setShowGlobalGenericError,
  } = useContext(SeparationFlowContext);
  const navigate = useNavigate();
  const { memberUuid } = useParams();
  const {
    memberSeparation,
    loading: isMemberSeparationLoading,
    error: memberSeparationError,
  } = useGetMemberSeparation(memberUuid);

  const backUrl = `/team-management/separation-flow/${memberUuid}/${statusToPath(
    memberSeparation?.status
  )}`;

  const [paymentKeys, setPaymentKeys] = useState<number[]>(
    additionalPaymentsFormValues
      ? Object.keys(additionalPaymentsFormValues)
          .map((key) => Number(key))
          .sort()
      : [0]
  );

  useEffect(() => {
    if (memberSeparationError) {
      setShowGlobalGenericError?.(true);
    } else {
      setShowGlobalGenericError?.(false);
    }
  }, [memberSeparationError, setShowGlobalGenericError]);

  useEffect(() => {
    if (
      !canMakePayments ||
      !ELIGIBLE_MEMBER_TYPES.includes(member?.memberType || "")
    ) {
      setHasAdditionalPayments && setHasAdditionalPayments(false);
    }
  }, [canMakePayments, member?.memberType, setHasAdditionalPayments]);

  // Whenever this page is loaded, be sure to initialize paymentKeys, if empty for the
  // default first payment form we show.
  useEffect(() => {
    if (!paymentKeys?.length) {
      setPaymentKeys([0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    navigate(backUrl);
  };

  useEffect(() => {
    // If we've loaded the details around this member's separation and it turns out they
    // haven't completed the questionnaire or validly skipped it, we shouldn't be here and
    // should navigate back to the proper point in the flow.
    if (
      !isMemberSeparationLoading &&
      !memberSeparationError &&
      !["questionnaire_complete", "questionnaire_skipped"].includes(
        memberSeparation?.status || ""
      )
    ) {
      navigate(backUrl);
    }
  });

  const { termination_date: terminationDate } =
    getInitialValues(memberSeparation);

  const skipAdditionalPaymentsCopy = () => {
    if (!canMakePayments) {
      return NO_PERMISSION_COPY;
    } else if (!ELIGIBLE_MEMBER_TYPES.includes(member?.memberType || "")) {
      return RECIPIENT_NOT_ELIGIBLE_COPY;
    }
    return "";
  };

  return isMemberSeparationLoading ? (
    <Loading />
  ) : (
    <AdditionalPaymentsPageContextProvider>
      {!skipAdditionalPaymentsCopy() ? (
        <>
          <PreambleForm />
          {memberSeparation &&
            hasAdditionalPayments &&
            isKnownAmount &&
            paymentKeys.map((paymentKey) => (
              <AdditionalPaymentsForm
                paymentKey={paymentKey}
                paymentKeys={paymentKeys}
                setPaymentKeys={setPaymentKeys}
                key={paymentKey}
                terminationDate={new Date(terminationDate)}
                earliestPayDate={isoStringToDate(
                  memberSeparation.earliestPayDate
                )}
              />
            ))}
        </>
      ) : (
        <AlertMessage>
          <h3>
            <strong>{skipAdditionalPaymentsCopy()}</strong>
          </h3>
        </AlertMessage>
      )}
      <div className={FooterContainer}>
        <Button variant="outlined" onClick={handleBack}>
          {SEPARATION_FLOW_COPY.previous}
        </Button>

        <SubmitButton />
      </div>
    </AdditionalPaymentsPageContextProvider>
  );
};
