import React, { createContext, ReactNode, useContext, useState } from "react";

export interface QuestionnaireContextType {
  docLoadingCounter: number;
  incrementDocLoadingCounter?: () => void;
  decrementDocLoadingCounter?: () => void;
}

interface QuestionnaireContextProviderProps {
  children: ReactNode;
}

const QuestionnaireContext = createContext<QuestionnaireContextType>({
  docLoadingCounter: 0,
});

export const useQuestionnaireContext = () => useContext(QuestionnaireContext);

const QuestionnaireContextProvider = ({
  children,
}: QuestionnaireContextProviderProps) => {
  const [docLoadingCounter, setDocLoadingCounter] = useState<number>(0);

  const incrementDocLoadingCounter = () => {
    setDocLoadingCounter((prevCounter) => prevCounter + 1);
  };

  const decrementDocLoadingCounter = () => {
    setDocLoadingCounter((prevCounter) => Math.max(0, prevCounter - 1));
  };

  const contextValue: QuestionnaireContextType = {
    docLoadingCounter,
    incrementDocLoadingCounter,
    decrementDocLoadingCounter,
  };

  return (
    <QuestionnaireContext.Provider value={contextValue}>
      {children}
    </QuestionnaireContext.Provider>
  );
};

export { QuestionnaireContext, QuestionnaireContextProvider };
