import {
  EMOJI_ERROR,
  WHITESPACE_ERROR,
  ONE_HUNDRED_CHARACTER_LIMIT_ERROR,
} from "pages/expenses/constants";
import {
  invalidEmojiValue,
  invalidCharacterLengthValue,
  invalidWhitespaceInputValue,
} from "pages/expenses/utils";
import {
  DUPLICATE_PROJECT_CODE_ERROR,
  DUPLICATE_PROJECT_NAME_ERROR,
} from "./ProjectsPage.constants";
import { ExpenseProject } from "types/Expenses";
import { parseProject } from "pages/expenses/store/SettingsStore/utils/projects.utils";

export const validateProjectName = (
  name: string,
  projects: ExpenseProject[]
) => {
  if (invalidWhitespaceInputValue(name)) {
    return WHITESPACE_ERROR;
  } else if (invalidCharacterLengthValue(name, 100)) {
    return ONE_HUNDRED_CHARACTER_LIMIT_ERROR;
  } else if (invalidEmojiValue(name)) {
    return EMOJI_ERROR;
  } else if (
    projects
      .map((project) => project.projectName.trim().toLowerCase())
      .includes(name.trim().toLowerCase())
  ) {
    return DUPLICATE_PROJECT_NAME_ERROR;
  } else {
    return "";
  }
};

export const validateProjectCode = (
  code: string,
  projects: ExpenseProject[]
) => {
  if (invalidWhitespaceInputValue(code)) {
    return WHITESPACE_ERROR;
  } else if (invalidCharacterLengthValue(code, 100)) {
    return ONE_HUNDRED_CHARACTER_LIMIT_ERROR;
  } else if (invalidEmojiValue(code)) {
    return EMOJI_ERROR;
  } else if (
    code.length &&
    projects.map((project) => project.projectCode?.trim()).includes(code.trim())
  ) {
    return DUPLICATE_PROJECT_CODE_ERROR;
  } else {
    return "";
  }
};

export const formatProjectUpdates = (projects: ExpenseProject[]) =>
  projects.map((project) => {
    return Object.entries(project).reduce<
      Record<keyof ExpenseProject, string | boolean>
    >((acc, data) => {
      const output = { ...acc };
      const [key, value] = data;
      const projectKey = key as keyof ExpenseProject;
      const isDisplayName = projectKey === "displayName";

      if (
        !isDisplayName &&
        value !== null &&
        value !== undefined &&
        value !== ""
      ) {
        output[projectKey] = value;
      }

      return output;
    }, {} as Record<keyof ExpenseProject, string | boolean>);
  });

export const hasProjectsUpdated = (
  storeProjects: ExpenseProject[],
  apiProjects: ExpenseProject[]
) => {
  const parsedApiProjects = apiProjects.map(parseProject);

  return JSON.stringify(storeProjects) !== JSON.stringify(parsedApiProjects);
};
