import { useParams } from "react-router-dom";
import {
  Edited,
  Approved,
  Returned,
  Reviewed,
  Submitted,
  Resubmitted,
  PaymentCanceled,
  PaymentReceived,
} from "./components/AuditLogTypes";
import { getDateFromTimestamp } from "./AuditLogPage.utils";
import { AuditLogTimelineLine } from "./components";
import { Loading } from "pages/expenses/components";
import { useAuditLog, useApprovers } from "pages/expenses/hooks";
import { intersectionBy } from "lodash";
import { AuditLogAudits } from "types/Expenses";
import styles from "./AuditLogPage.module.css";

const {
  AuditLogContainer,
  AuditLogPageWrapper,
  AuditLogTimelineItemDate,
  AuditLogTimelineItemWrapper,
  AuditLogTimelineItemContainer,
} = styles;

export const AuditLogPage = () => {
  const { request_uuid } = useParams<{ request_uuid: string }>();
  const { isAuditLogLoading, auditLogData, requestName } =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useAuditLog(request_uuid!);

  const { adminApprovers, managerApprovers } = useApprovers();

  const renderTimeLineComponents = (data: AuditLogAudits) => {
    const sharedProps = {
      isNextMemberAnAdmin:
        intersectionBy(adminApprovers, data.nextMembers, "uuid").length !== 0,
      isNextMemberAManager:
        intersectionBy(managerApprovers, data.nextMembers, "uuid").length !== 0,
      numAdmins: adminApprovers.length,
      memberName: data?.member?.friendlyFullName,
      hasManyNextMembers: data?.nextMembers?.length > 1,
      nextMemberName: data.nextMembers?.[0]?.friendlyFullName,
    };

    switch (data.__typename) {
      case "Submitted":
        return (
          <Submitted
            name={data.name}
            expenses={data.expenses}
            occurredAt={data.occurredAt}
            totalAmount={data.totalAmount}
            numAdmins={sharedProps.numAdmins}
            memberName={sharedProps.memberName}
            nextMemberName={sharedProps.nextMemberName}
            isNextMemberAnAdmin={sharedProps.isNextMemberAnAdmin}
            isNextMemberAManager={sharedProps.isNextMemberAManager}
          />
        );
      case "Returned":
        return (
          <Returned
            name={data.name}
            reason={data.reason}
            occurredAt={data.occurredAt}
            memberName={sharedProps.memberName}
          />
        );
      case "Resubmitted":
        return (
          <Resubmitted
            name={data.name}
            occurredAt={data.occurredAt}
            differences={data.differences}
            memberName={sharedProps.memberName}
            nextMemberName={sharedProps.nextMemberName}
          />
        );
      case "Edited":
        return (
          <Edited
            name={data.name}
            occurredAt={data.occurredAt}
            differences={data.differences}
            memberName={sharedProps.memberName}
          />
        );
      case "Reviewed":
        return (
          <Reviewed
            name={data.name}
            occurredAt={data.occurredAt}
            totalAmount={data.totalAmount}
            numExpenses={data.numExpenses}
            memberName={sharedProps.memberName}
            nextMemberName={sharedProps.nextMemberName}
            hasManyNextMembers={sharedProps.hasManyNextMembers}
          />
        );
      case "Approved":
        return (
          <Approved
            occurredAt={data.occurredAt}
            totalAmount={data.totalAmount}
            memberName={sharedProps.memberName}
            estimatedPayDate={data.estimatedPayDate}
          />
        );
      case "PaymentCanceled":
        return (
          <PaymentCanceled
            occurredAt={data.occurredAt}
            memberName={sharedProps.memberName}
          />
        );
      case "PaymentReceived":
        return (
          <PaymentReceived
            name={requestName}
            payment={data.payment}
            occurredAt={data.occurredAt}
            memberName={sharedProps.memberName}
          />
        );

      default:
        return null;
    }
  };

  if (isAuditLogLoading) {
    return <Loading />;
  }

  return (
    <div className={AuditLogPageWrapper}>
      <h2>{requestName}</h2>
      <div className={AuditLogContainer}>
        {Object.entries(auditLogData).map(([date, audits], primaryIndex) => {
          return (
            <div
              key={`${date}_${primaryIndex}`}
              className={AuditLogTimelineItemWrapper}
              data-testid="audit-log-wrapper"
            >
              <span className={AuditLogTimelineItemDate}>
                {getDateFromTimestamp(date)}
              </span>
              {audits.map(({ type, ...props }, secondaryIndex) => {
                const isFirstAudit = primaryIndex === 0 && secondaryIndex === 0;
                const isLastAudit =
                  primaryIndex !== 0 && secondaryIndex === audits.length - 1;

                return (
                  <div
                    key={`${type}_${primaryIndex}_${secondaryIndex}`}
                    className={AuditLogTimelineItemContainer}
                    data-testid="audit-log-list"
                  >
                    <AuditLogTimelineLine
                      isLastAudit={isLastAudit}
                      isFirstAudit={isFirstAudit}
                    />
                    {renderTimeLineComponents(props)}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AuditLogPage;
