import { Spinner, IconButton } from "@justworkshr/milo-core";
import { FormField } from "@justworkshr/milo-form";
import { SystemIcon } from "@justworkshr/milo-icons";
import { FileResponse, useReceiptUpload } from "pages/expenses/hooks";
import React, { useState } from "react";
import { ExpenseLibraryExpenseReceipt } from "types/Expenses";
import { FileInput } from "../FileInput";
import styles from "./ReceiptUpload.module.css";

const {
  FileInfo,
  FileName,
  FileInfoWrapper,
  FileInfoContainer,
  FileInputErrorsList,
  ReceiptUploadStatusWrapper,
} = styles;

interface ReceiptUploadProps {
  isRequired: boolean;
  receipts: ExpenseLibraryExpenseReceipt[];
  handleFileInputChange: (file: FileResponse) => void;
  handleReceiptDelete: (receipt: ExpenseLibraryExpenseReceipt) => void;
}

const maxSize = 10 * 1024 * 1024; // 10MB
const accept = ["image/png", "image/jpeg", "application/pdf"];

export const ReceiptUpload: React.FC<ReceiptUploadProps> = React.memo(
  ({ receipts, isRequired, handleFileInputChange, handleReceiptDelete }) => {
    const [currentReceipt, setCurrentReceipt] = useState<File | null>(null);

    const { errors, loading, uploadReceipt, dismissError } = useReceiptUpload({
      accept,
      maxSize,
      acceptErrorMessage: "Only JPEG, PNG, or PDFs",
      maxSizeErrorMessage: "That file is too big.",
      onSuccess(uploadedReceipt) {
        setCurrentReceipt(null);
        handleFileInputChange(uploadedReceipt);
      },
    });

    const hasErrors = Object.keys(errors).length !== 0;
    const isDisabled = loading || hasErrors;

    const handleCancel = (filename: string) => {
      dismissError(filename);

      setCurrentReceipt(null);
    };

    const handleFileChange = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (event?.target?.files) {
        for (const file of Array.from(event.target.files)) {
          setCurrentReceipt(file);
          await uploadReceipt(file);
        }
      }
    };

    const renderFileInput = () => {
      return (
        <FileInput
          name="receipt"
          disabled={isDisabled}
          accept={accept.join(",")}
          onChange={handleFileChange}
        />
      );
    };

    const renderUploadStatus = () => {
      if (!loading) {
        return null;
      }

      return (
        <div className={ReceiptUploadStatusWrapper}>
          <Spinner />
          <strong>Your file is uploading</strong>
          <p>{currentReceipt?.name}</p>
        </div>
      );
    };

    const renderFileErrors = () => {
      if (!hasErrors) {
        return null;
      }

      const renderErrors = (errors: string[]) => (
        <ul className={FileInputErrorsList}>
          {errors.map((error, secondaryIndex) => (
            <li key={`${error}_${secondaryIndex}`}>
              <SystemIcon iconName="warning" color="warning" />
              {error}
            </li>
          ))}
        </ul>
      );

      return (
        <div className={FileInfoWrapper}>
          {Object.entries(errors).map(([filename, errors], primaryIndex) => {
            return (
              <div key={`${filename}_${primaryIndex}`} className={FileInfo}>
                <div className={FileInfoContainer}>
                  <p className={FileName}>{filename}</p>
                  <IconButton
                    size="sm"
                    as="button"
                    type="button"
                    variant="ghost"
                    color="neutral"
                    iconName="close"
                    title={`Cancel ${filename} upload`}
                    onClick={() => handleCancel(filename)}
                  />
                </div>
                {renderErrors(errors)}
              </div>
            );
          })}
        </div>
      );
    };

    const renderCurrentReceipts = () => {
      if (receipts.length === 0) {
        return null;
      }

      return (
        <div className={FileInfoWrapper}>
          {receipts.map((receipt) => {
            return (
              <div key={receipt.uuid} className={FileInfo}>
                <div className={FileInfoContainer}>
                  <p className={FileName}>{receipt.filename}</p>
                  <IconButton
                    size="sm"
                    as="button"
                    type="button"
                    variant="ghost"
                    color="neutral"
                    iconName="close"
                    title={`Remove ${receipt.filename}`}
                    onClick={() => handleReceiptDelete(receipt)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      );
    };

    return (
      <FormField
        label="Receipt"
        required={isRequired}
        message="Include as many files as necessary. We can accept JPEG, PNG, or PDF up to 10 MB each."
      >
        <div data-testid="receipt-input">
          {renderFileInput()}
          {renderUploadStatus()}
          {renderFileErrors()}
          {renderCurrentReceipts()}
        </div>
      </FormField>
    );
  }
);
