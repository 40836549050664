import { RadioButton } from "@justworkshr/milo-form";

import styles from "./waiver-priority.module.css";

const { selectRadioBox, selected, selectContent } = styles;

type WaiverPrioritySelectProps = {
  title: string;
  description: string;
  value: string;
  isSelected: boolean;
  setSelected: (level: string) => void;
};

export default function WaiverPrioritySelect({
  title,
  description,
  value,
  isSelected,
  setSelected,
}: WaiverPrioritySelectProps) {
  return (
    <button
      className={`${selectRadioBox} ${isSelected ? selected : ""}`}
      onClick={() => setSelected(value)}
      type="button"
    >
      <div className={selectContent}>
        <RadioButton label={title} value={value} checked={isSelected} />
        <p>{description}</p>
      </div>
    </button>
  );
}
