import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { PageHeader, Button, Alert } from "@justworkshr/milo-core";
import { Form, ActionFooter } from "@justworkshr/milo-form";
import { SystemIcon } from "@justworkshr/milo-icons";
import { COI_EDIT_URL } from "pages/workers-compensation/document-request/constants";
import { CertficateHolderReview } from "pages/workers-compensation/document-request/components";
import { ReviewDataProps } from "../types";
import styles from "./certificate-review.module.css";

const {
  reviewForm,
  formNavigation,
  visited,
  highlighted,
  headerContainer,
  backButton,
  reviewHeader,
} = styles;
export default function CertificateReview({
  formData,
  handleSubmit,
  isLoading,
  submitError,
  supportingDocuments,
}: ReviewDataProps): ReactElement {
  return (
    <div className="milo--grid">
      <div className={reviewForm} data-testid="certificateReview">
        <div className={headerContainer}>
          <Button
            variant="ghost"
            as={Link}
            to={COI_EDIT_URL}
            className={backButton}
          >
            Back
          </Button>
          <PageHeader
            title="Review"
            children="Double check all of the information is correct."
            className={reviewHeader}
          ></PageHeader>
        </div>
        <Alert color="destructive" visible={submitError.length > 0}>
          {submitError}
        </Alert>
        <div className={formNavigation}>
          <SystemIcon iconName="check" color="success" />
          <p className={visited}>Certificate Details</p>
          <SystemIcon iconName="chevron-right" />
          <p className={highlighted}>Review</p>
        </div>
        <hr />
        <CertficateHolderReview
          formData={formData}
          supportingDocuments={supportingDocuments}
          requestType="coi"
        />
        <Form onSubmit={handleSubmit}>
          <ActionFooter
            actions={[
              <Button
                variant="ghost"
                as={Link}
                key="BackCertificateReview"
                to={COI_EDIT_URL}
              >
                Back
              </Button>,
              <Button
                variant="filled"
                key="SubmitCertificateReview"
                type="submit"
                loading={isLoading}
              >
                Submit
              </Button>,
            ]}
          />
        </Form>
      </div>
    </div>
  );
}
