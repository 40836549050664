import { PageData } from "../types";

// Form Objects

const ALL_STATES = [
  { description: "Select a state", value: "", disabled: true },
  { description: "Alabama", value: "AL" },
  { description: "Alaska", value: "AK" },
  { description: "Arizona", value: "AZ" },
  { description: "Arkansas", value: "AR" },
  { description: "California", value: "CA" },
  { description: "Colorado", value: "CO" },
  { description: "Connecticut", value: "CT" },
  { description: "Delaware", value: "DE" },
  { description: "Florida", value: "FL" },
  { description: "Georgia", value: "GA" },
  { description: "Hawaii", value: "HI" },
  { description: "Idaho", value: "ID" },
  { description: "Illinois", value: "IL" },
  { description: "Indiana", value: "IN" },
  { description: "Iowa", value: "IA" },
  { description: "Kansas", value: "KS" },
  { description: "Kentucky", value: "KY" },
  { description: "Louisiana", value: "LA" },
  { description: "Maine", value: "ME" },
  { description: "Maryland", value: "MD" },
  { description: "Massachusetts", value: "MA" },
  { description: "Michigan", value: "MI" },
  { description: "Minnesota", value: "MN" },
  { description: "Mississippi", value: "MS" },
  { description: "Missouri", value: "MO" },
  { description: "Montana", value: "MT" },
  { description: "Nebraska", value: "NE" },
  { description: "Nevada", value: "NV" },
  { description: "New Hampshire", value: "NH" },
  { description: "New Jersey", value: "NJ" },
  { description: "New Mexico", value: "NM" },
  { description: "New York", value: "NY" },
  { description: "North Carolina", value: "NC" },
  { description: "North Dakota", value: "ND" },
  { description: "Ohio", value: "OH" },
  { description: "Oklahoma", value: "OK" },
  { description: "Oregon", value: "OR" },
  { description: "Pennsylvania", value: "PA" },
  { description: "Rhode Island", value: "RI" },
  { description: "South Carolina", value: "SC" },
  { description: "South Dakota", value: "SD" },
  { description: "Tennessee", value: "TN" },
  { description: "Texas", value: "TX" },
  { description: "Utah", value: "UT" },
  { description: "Vermont", value: "VT" },
  { description: "Virginia", value: "VA" },
  { description: "Washington", value: "WA" },
  { description: "Washington, D.C.", value: "DC" },
  { description: "West Virginia", value: "WV" },
  { description: "Wisconsin", value: "WI" },
  { description: "Wyoming", value: "WY" },
];

// Helper Objects

const initialAddressInfo = {
  street1: "",
  street2: "",
  postalCode: "",
  city: "",
  state: "",
};

// Start: PAGE OBJECTS
// This is the initial structure, broken out by page
// and what is either submitted to or received from the API on that page

const injuryDate = {
  number: null,
  uuid: "",
  hour: "",
  minute: "",
  title: "",
  memberName: "",
  homeAddress: initialAddressInfo,
  workEmail: "",
  contactPhone: "",
  companyName: "",
  companyAddress: initialAddressInfo,
  adminEmail: "",
  adminPhone: "",
};

const personalInformation = {
  homeEmail: "",
  siteCode: "",
  policyNumber: "",
};

const incidentInformation = {
  fatality: false,
  oshaNumber: "",
  incidentDescription: "",
  address: initialAddressInfo,
  injuryBodyPart: "",
  bodyPartDescription: "",
  safeGuard: "",
  safeGuardList: "",
};

const witnessInformation = {
  witnessesExist: "No",
  witnessList: [
    {
      name: "",
      contact: "",
      notes: "",
    },
  ],
};

const incidentResponse = {
  wasContacted: false,
  wasContactedDetails: "",
  noMedicalTreatment: false,
  firstAid: false,
  medicalClinic: false,
  EMSTransport: false,
  overnightHospitalization: false,
  other: false,
  treatmentDetails: "",
  phoneNumber: "",
  address: initialAddressInfo,
};

const workStatus = {
  missTime: "",
  paid: "",
  losingTime: "",
  returnToWorkDate: "",
  returnToWorkBoolean: false,
};

// End: PAGE OBJECTS

// This is the initial structure of the page data object

const initialPageData: PageData = {
  injuryDate,
  personalInformation,
  incidentInformation,
  incidentResponse,
  witnessInformation,
  workStatus,
};

// Start: MOCK DATA

const MOCK_ADDRESS = {
  street1: "1234 Test Drive",
  street2: "Apt 2B",
  postalCode: "94597",
  city: "Newport",
  state: "California",
};

const MOCK_CREATE_CLAIM_REPORT_DATA = {
  claim: {
    accidentLocation: MOCK_ADDRESS,
    adminEmail: "fakeemail@gmail.com",
    adminPhone: "555-555-5555",
    adminUuid: "a2sdf-sdkjhdsf-34n45j-456gs",
    affectedBodyPart: "Head",
    clinicOrProviderName: "Sam's Famous Clinic",
    companyName: "Dangerous Jobs Inc.",
    companyUuid: "sd45h-df4sfg-34n45j-456gs",
    emergencyResponseContacted: true,
    emergencyResponseDetails: "Called 911",
    employerPolicyNumber: "112304",
    estimatedAccidentDateTime: "01-20-2020",
    fatality: false,
    incidentDescription: "Slipped on a banana peel",
    injuryDescription: "Concussion",
    memberName: "George Orwell",
    memberUuid: "s20dsf-n4j2d-456gs-df4sfg",
    oshaNumber: "8775309",
    safeguardsUsed: true,
    describedSafeguards: "Hard hat",
    treatmentFacilityAddress: MOCK_ADDRESS,
    treatmentGiven: "First Aid",
    treatmentFacilityPhone: "555-123-4567",
  },
  claimant: {
    homeAddress: MOCK_ADDRESS,
    personalEmail: "samisgreat@aol.netscape",
    phoneNumber: "555-401-5555", // SHOULD BE PERSONAL PHONE
    workAddress: MOCK_ADDRESS,
  },
  witnesses: [],
  workStatus: {
    endTimeOut: "01-25-2020",
    startTimeOut: "01-20-2020",
    paid: true,
  },
};

// End: MOCK DATA

export {
  MOCK_CREATE_CLAIM_REPORT_DATA,
  ALL_STATES,
  injuryDate,
  personalInformation,
  incidentInformation,
  initialPageData,
  incidentResponse,
  witnessInformation,
  workStatus,
};
