import React from "react";
import { FormField } from "@justworkshr/milo-form";
import { DecoratedTextInput } from "pages/expenses/components";
import { AMOUNT_ERROR } from "../../ExpenseDetailsPage.constants";
import { formatNumberWithCommas } from "../../ExpenseDetailsPage.utils";
import {
  isNumber,
  isPositive,
  isNotNegative,
  useValidators,
  isCurrencyFormat,
  isNotTooLargeDollars,
} from "pages/expenses/utils";

const AMOUNT_KEY = "amount";

interface AmountProps {
  amount: {
    value: string;
    error: string;
  };
  handleFormValue: ({
    key,
    value,
  }: {
    key: typeof AMOUNT_KEY;
    value: string;
  }) => void;
  handleFormError: ({
    key,
    error,
  }: {
    key: typeof AMOUNT_KEY;
    error: string;
  }) => void;
}

export const Amount: React.FC<AmountProps> = React.memo(
  ({ amount, handleFormValue, handleFormError }) => {
    const { validateRules } = useValidators<string>([
      isNumber,
      isPositive,
      isNotNegative,
      isCurrencyFormat,
      isNotTooLargeDollars,
    ]);

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const isValidAmount = validateRules(
        formatNumberWithCommas(event.target.value)
      );

      if (!isValidAmount) {
        handleFormError({
          key: AMOUNT_KEY,
          error: AMOUNT_ERROR,
        });
      } else {
        handleFormError({
          key: AMOUNT_KEY,
          error: "",
        });
      }

      handleFormValue({ key: AMOUNT_KEY, value: event.target.value });
    };

    return (
      <FormField
        required
        name="amount"
        label="Amount"
        error={amount.error}
        message="Enter amount in USD"
      >
        <DecoratedTextInput
          prefix="$"
          type="text"
          name="amount"
          value={amount.value}
          onChange={handleAmountChange}
          onBlur={(event) => {
            if (event.target.value) {
              handleFormValue({
                key: AMOUNT_KEY,
                value: formatNumberWithCommas(event.target.value),
              });
            }
          }}
          data-testid="amount-input"
        />
      </FormField>
    );
  }
);
