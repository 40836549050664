import { Box } from "@justworkshr/milo-core";
import { SystemIcon, SystemIconProps } from "@justworkshr/milo-icons";
import { buildWebPath } from "lib/resource-finder";
import { Link } from "react-router-dom";
import styles from "./resourceCard.module.css";

const { resourceCardHeader, resourceCardContent, resourceCardLinks } = styles;

export type ResourceLink = {
  title: string;
  href: string;
  systemIconName: SystemIconProps["iconName"];
  external?: boolean;
};

export type ResourceCardProps = {
  header: string;
  links: ResourceLink[] | undefined;
};

const ResourceCard: React.FC<ResourceCardProps> = ({ header, links }) => {
  return (
    <Box
      padding="2xl"
      border={{
        borderWidth: "sm",
        borderColor: "neutralSubtle",
        borderRadius: "md",
      }}
    >
      <div className="milo--root">
        <div className={resourceCardHeader}>{header}</div>
        <div className={resourceCardContent}>
          {links?.map(({ title, href, systemIconName, external }) => {
            const to = external ? href : buildWebPath(href);
            return (
              <Link
                className={resourceCardLinks}
                to={to}
                key={to}
                target="_blank"
                rel="noopener noreferrer"
              >
                {title}
                <SystemIcon iconName={systemIconName} color="brand" />
              </Link>
            );
          })}
        </div>
      </div>
    </Box>
  );
};

export default ResourceCard;
