import { PaymentInfoAutoFormConfig } from "pages/employer-of-record/onboarding/payment-info/PaymentInfoAutoForm/payment-info-autoform-config";
import { PaymentInfoAutoFormFields } from "pages/employer-of-record/onboarding/payment-info/PaymentInfoAutoForm/payment-info-autoform-fields";
import { GetEoREmployeeProfileQuery } from "types/generated/operations";
import styles from "./BankAccountAttributes.module.css";
import BankAccountField from "./BankAccountAttribute";

export const IGNORED_FIELDS = [
  PaymentInfoAutoFormFields.accountType,
  PaymentInfoAutoFormFields.accountNickname,
];

type BankAccount =
  GetEoREmployeeProfileQuery["eorEmployeeProfile"]["bankAccount"];
type BankAccountData = NonNullable<BankAccount>["data"];

type Props = {
  workCountry: string;
  bankAccountData: NonNullable<BankAccountData>;
};

export default function BankAccountAttributes(props: Props) {
  const { workCountry, bankAccountData } = props;
  const fields = PaymentInfoAutoFormConfig[workCountry];
  const ignoredFieldNames = IGNORED_FIELDS.map(({ name }) => name);
  const cardBodyFields = fields.filter(
    ({ name }) => !ignoredFieldNames.includes(name)
  );

  const numberOfColumns = cardBodyFields.length > 2 ? 2 : 1;
  const middleIndex = Math.ceil((numberOfColumns + 1) / 2);
  const leftColumnFields =
    numberOfColumns === 2
      ? cardBodyFields.slice(0, middleIndex)
      : cardBodyFields;
  const rightColumnFields =
    numberOfColumns === 2 ? cardBodyFields.slice(middleIndex) : [];

  return (
    <div className={styles.accountAttributes}>
      <div className={styles.column}>
        {leftColumnFields.map(({ label, name }) => (
          <BankAccountField
            key={name}
            label={label ?? ""}
            value={bankAccountData[name as keyof BankAccountData]}
          />
        ))}
      </div>
      <div className={styles.column}>
        {rightColumnFields.map(({ label, name }) => (
          <BankAccountField
            key={name}
            label={label ?? ""}
            value={bankAccountData[name as keyof BankAccountData]}
          />
        ))}
      </div>
    </div>
  );
}
