export enum YesOrNoTypes {
  YES = "yes",
  NO = "no",
}
export enum PaymentTypeTypes {
  BONUS = "bonus",
  SEVERANCE = "severance",
  SUPPLEMENTAL = "supplemental",
  COMMISSION = "commission",
}

export enum PaymentMethodType {
  DIRECT_DEPOSIT = "eft",
  CHECK = "manual",
}

export enum PayFrequencyTypes {
  WEEKLY = "weekly",
  BIWEEKLY = "biweekly",
  MONTHLY = "monthly",
}
