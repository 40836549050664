import { LoginCallback } from "@okta/okta-react";
import { Spinner } from "@justworkshr/milo-core";
import { LoginCallbackError } from "./login-callback-error";

export const LoginCallbackPage = () => {
  return (
    <LoginCallback
      loadingElement={<Spinner />}
      errorComponent={LoginCallbackError}
    />
  );
};

export default LoginCallbackPage;
