import { useNavigate } from "react-router-dom";
import { buildClockworkWebPath } from "lib/resource-finder";
import { Button, Card } from "@justworkshr/milo-core";
import {
  SetupHeader,
  PermissionsTable,
  AdvancedSettings,
  ExpensesResources,
} from "./components";
import { Loading, SettingsTable, ToggleSwitch } from "../../components";
import { useExpensesSettings, useToggleExpensesEnabled } from "../../hooks";
import {
  useGlobalConfigurationStore,
  useSettingsConfigurationStore,
} from "pages/expenses/store";
import { prependBasePath } from "pages/expenses/constants";
import {
  CategoriesTaskCard,
  AdminPermissionsTaskCard,
  ManagerPermissionsTaskCard,
} from "./components";
import {
  EXPENSES_ENABLED_COPY,
  EXPENSES_DISABLED_COPY,
  EXPENSES_ENABLED_CARD_TITLE,
  EXPENSES_DISABLED_CARD_TITLE,
} from "./SettingsPage.constants";
import styles from "./SettingsPage.module.css";
import { useEffect } from "react";

const {
  EditButtonStyle,
  SettingsTableCard,
  SettingsPageContent,
  SettingsTableCardWrapper,
} = styles;

export const SettingsPage = () => {
  const {
    data: {
      statuses,
      managers,
      categories,
      expenseAdmins,
      reportsAdmins,
      currentMember,
    },
    refetchExpensesSettings,
    isExpensesSettingsLoading,
  } = useExpensesSettings();
  const navigate = useNavigate();
  const { isExpensesEnabled } = useGlobalConfigurationStore();
  const {
    settingsConfiguration: { initialSetupComplete },
  } = useSettingsConfigurationStore();

  useEffect(() => {
    refetchExpensesSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { toggleExpensesEnabled, isToggleExpensesEnabledLoading } =
    useToggleExpensesEnabled();

  const onToggleChange = (enabled: boolean) =>
    toggleExpensesEnabled({
      variables: {
        enabled,
      },
    });

  const renderBeforeSetup = () => {
    return (
      <div>
        <SetupHeader />
        <div className={SettingsTableCardWrapper}>
          <AdminPermissionsTaskCard cardStatus={statuses["adminPermissions"]} />
          <ManagerPermissionsTaskCard
            cardStatus={statuses["managerPermissions"]}
            adminPermissionsCardStatus={statuses["adminPermissions"]}
          />
          <CategoriesTaskCard
            expenseAdmins={expenseAdmins}
            currentMember={currentMember}
            cardStatus={statuses["categories"]}
            adminPermissionsCardStatus={statuses["adminPermissions"]}
          />
        </div>
      </div>
    );
  };

  const renderAfterSetup = () => {
    return (
      <div className={SettingsPageContent}>
        <Card
          title={
            isExpensesEnabled
              ? EXPENSES_ENABLED_CARD_TITLE
              : EXPENSES_DISABLED_CARD_TITLE
          }
          actions={[
            <ToggleSwitch
              checked={isExpensesEnabled}
              onToggleChange={onToggleChange}
              disabled={isToggleExpensesEnabledLoading}
            />,
          ]}
          description={
            isExpensesEnabled ? EXPENSES_ENABLED_COPY : EXPENSES_DISABLED_COPY
          }
        >
          <ExpensesResources />
        </Card>
        <Card
          className={SettingsTableCard}
          title="Permissions"
          actions={[
            <Button
              className={EditButtonStyle}
              onClick={() => {
                window.location.href = buildClockworkWebPath("/permissions");
              }}
              type="button"
              variant="ghost"
              leftIcon="edit"
              data-testid="edit-setting-button"
            >
              Edit
            </Button>,
          ]}
        >
          <PermissionsTable
            managers={managers}
            expenseAdmins={expenseAdmins}
            reportsAdmins={reportsAdmins}
          />
        </Card>
        <Card
          className={SettingsTableCard}
          title="Expense Categories"
          actions={[
            <Button
              className={EditButtonStyle}
              onClick={() => navigate(prependBasePath("CATEGORIES"))}
              type="button"
              variant="ghost"
              leftIcon="edit"
              data-testid="edit-setting-button"
            >
              Edit
            </Button>,
          ]}
        >
          <SettingsTable
            headers={["Active category"]}
            dataTestId="categories-table"
          >
            {categories.map((category) => {
              return (
                <tr key={category.name}>
                  <td>{category.name}</td>
                </tr>
              );
            })}
          </SettingsTable>
        </Card>
        <AdvancedSettings />
      </div>
    );
  };

  const renderSetup = () => {
    if (isExpensesSettingsLoading) {
      return <Loading />;
    }

    if (!initialSetupComplete) {
      return renderBeforeSetup();
    }

    return renderAfterSetup();
  };

  return <div className="SettingsPage">{renderSetup()}</div>;
};

export default SettingsPage;
