export const INITIAL_CONFIGURATION: ConfigurationStoreType = {
  hasJoinedExpenses: false, // companySetting.value
  isExpensesEnabled: false, // policy.enabled
  isExpensesEverEnabled: false, // policy.everEnabled
  expenseAdmins: [],
  permissionsAdmins: [],
  permissions: {
    isAdmin: false,
    isGranter: false,
    isManager: false,
    isReporter: false,
    isSubmitter: false,
    isAdminAndManager: false,
    isAllowedToFirstTimeSetup: false,
    isAllowedToAccessReviewPage: false,
    isAllowedToAccessSettingsPages: false,
    isRequiredToViewProductDetailsPage: false,
    isAllowedToAccessReviewHistoryPage: false,
    isAllowedToAccessProductDetailsPage: false,
  },
};

export type ConfigurationStoreType = {
  hasJoinedExpenses: boolean;
  isExpensesEnabled: boolean;
  isExpensesEverEnabled: boolean;
  expenseAdmins: string[];
  permissionsAdmins: string[];
  permissions: {
    isAdmin: boolean;
    isManager: boolean;
    isGranter: boolean;
    isReporter: boolean;
    isSubmitter: boolean;
    isAdminAndManager: boolean;
    isAllowedToFirstTimeSetup: boolean;
    isAllowedToAccessReviewPage: boolean;
    isAllowedToAccessSettingsPages: boolean;
    isAllowedToAccessReviewHistoryPage: boolean;
    isRequiredToViewProductDetailsPage: boolean;
    isAllowedToAccessProductDetailsPage: boolean;
  };
};

export type ConfigurationActionsUpdateConfigurationPayload = Partial<
  Pick<
    ConfigurationStoreType,
    "hasJoinedExpenses" | "isExpensesEnabled" | "isExpensesEverEnabled"
  >
>;
