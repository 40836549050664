import { useCallback } from "react";
import { useRequestDetailSelectorStore } from "../../store";
import { ExpenseLibraryActionTypes } from "../../actions";
import { SetExpenseLibraryPayload } from "../../constants";

export const useExpenseLibraryStore = () => {
  const {
    store: { ExpenseLibrary: expenseLibrary, RequestDetail: requestDetail },
    dispatch,
  } = useRequestDetailSelectorStore((store) => store);

  const setExpenseLibrary = useCallback(
    (payload: SetExpenseLibraryPayload) =>
      dispatch({
        type: ExpenseLibraryActionTypes.SET_EXPENSE_LIBRARY,
        payload,
      }),
    [dispatch]
  );

  const getAttachedExpenseUUIDs = useCallback(() => {
    const currentAttachedExpenseUUIDs = requestDetail.request.expenses.map(
      (expense) => expense?.uuid
    );

    return Array.from(new Set([...currentAttachedExpenseUUIDs]));
  }, [requestDetail.request.expenses]);

  const getDetachedExpenseUUIDs = useCallback(() => {
    return requestDetail.metadata.detachedExpenses.map(
      (expense) => expense.uuid
    );
  }, [requestDetail.metadata.detachedExpenses]);

  return {
    expenseLibrary,
    setExpenseLibrary,
    getAttachedExpenseUUIDs,
    getDetachedExpenseUUIDs,
  };
};
