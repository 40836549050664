import { FormEvent, ReactNode, createContext, useState } from "react";

interface AdditionalPaymentsPageContextProviderProps {
  children: ReactNode;
}

type SubmitHandler = (e?: FormEvent<HTMLFormElement>) => void;

interface AdditionalPaymentsPageContextValues {
  submitHandlers: { [key: string]: SubmitHandler };
  setSubmitHandlers?: (submitHandlers: {
    [key: string]: SubmitHandler;
  }) => void;
  isAdditionalPaymentsFormDirty: { [key: number]: boolean };
  setIsAdditionalPaymentsFormDirty?: (isFormDirty: {
    [key: number]: boolean;
  }) => void;
}

export const AdditionalPaymentsPageContext =
  createContext<AdditionalPaymentsPageContextValues>({
    submitHandlers: {},
    isAdditionalPaymentsFormDirty: {},
  });

export const AdditionalPaymentsPageContextProvider = ({
  children,
}: AdditionalPaymentsPageContextProviderProps) => {
  const [submitHandlers, setSubmitHandlers] = useState<{
    [key: string]: SubmitHandler;
  }>({});
  const [isAdditionalPaymentsFormDirty, setIsAdditionalPaymentsFormDirty] =
    useState<{ [key: number]: boolean }>({});

  return (
    <AdditionalPaymentsPageContext.Provider
      value={{
        submitHandlers,
        setSubmitHandlers,
        isAdditionalPaymentsFormDirty,
        setIsAdditionalPaymentsFormDirty,
      }}
    >
      {children}
    </AdditionalPaymentsPageContext.Provider>
  );
};
