import { ReactElement, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { EditDataProps } from "../types";
import { CertificateHolderEdit } from "../../components";
import { PageHeader, Button, Alert } from "@justworkshr/milo-core";
import { Form, ActionFooter } from "@justworkshr/milo-form";
import { SystemIcon } from "@justworkshr/milo-icons";
import {
  WC_DOC_REQUEST_BASE_URL,
  COI_REVIEW_URL,
  certificateErrorText,
  certificateSizes,
  initialCertificateErrors,
  ZIP_REGEX,
  validationErrors,
  useAdditionalMessage,
} from "pages/workers-compensation/document-request/constants";

import styles from "./certificate-edit.module.css";

const {
  certificateForm,
  highlighted,
  formNavigation,
  footer,
  reviewButton,
  formInput,
} = styles;

export default function CertificateOfInsuranceEdit({
  formData,
  setFormData,
  submitError,
  setDocuments,
}: EditDataProps): ReactElement {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);

  const [errors, setErrors] = useState({ ...initialCertificateErrors });

  const handleErrorState = (name: string, value: string | string[]) => {
    if (!certificateErrorText[name as keyof typeof certificateErrorText])
      return;

    if (value.length <= 0) {
      setErrors((prev) => {
        return {
          ...prev,
          [name]:
            certificateErrorText[name as keyof typeof certificateErrorText],
        };
      });
    } else {
      setErrors((prev) => {
        return { ...prev, [name]: undefined };
      });
    }
  };

  const handleEventChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ): void => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      return { ...prevData, [name]: value };
    });

    handleErrorState(name, value);
  };

  const validateForm = () => {
    let isValid = true;
    // Reset Errors to clear out any previous errors
    setErrors({ ...initialCertificateErrors });

    for (const key in formData) {
      if (
        certificateErrorText[key as keyof typeof certificateErrorText] &&
        formData[key as keyof typeof certificateErrorText] === ""
      ) {
        isValid = false;

        setErrors((prev) => ({
          ...prev,
          [key as keyof typeof certificateErrorText]:
            certificateErrorText[key as keyof typeof certificateErrorText],
        }));
      }

      if (
        certificateSizes[key as keyof typeof certificateSizes] &&
        formData[key as keyof typeof formData].length >
          certificateSizes[key as keyof typeof certificateSizes]
      ) {
        isValid = false;

        setErrors((prev) => ({
          ...prev,
          [key as keyof typeof formData]: useAdditionalMessage,
        }));
      }
    }

    if (formData.postalCode !== "" && !ZIP_REGEX.test(formData.postalCode)) {
      isValid = false;
      setErrors((prev) => ({
        ...prev,
        postalCode: validationErrors.postalCode,
      }));
    }

    return isValid;
  };

  const handleEditSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (validateForm()) {
      navigate(COI_REVIEW_URL);
    } else {
      setShowAlert(true);
    }
  };

  return (
    <div className="milo--grid">
      <div className={certificateForm} data-testid="certificateEditForm">
        <PageHeader
          title="Certificate of Insurance"
          linkPrevious={<Link to={WC_DOC_REQUEST_BASE_URL}>Back</Link>}
        >
          A certificate of insurance (COI) provides proof that your business is
          insured. It typically summarizes the key aspects and conditions of the
          policy&ndash;for example, the policy&apos;s effective date, type of
          coverage, and other important details.
        </PageHeader>
        <Alert color="destructive" visible={submitError.length > 0}>
          {submitError}
        </Alert>
        <div className={formNavigation}>
          <p className={highlighted}>Certificate Details</p>
          <SystemIcon iconName="chevron-right" />
          <p>Review</p>
        </div>
        <hr />
        <div className={formInput}>
          <Form onSubmit={handleEditSubmit}>
            <CertificateHolderEdit
              formData={formData}
              errors={errors}
              handleEventChange={handleEventChange}
              setDocuments={setDocuments}
              docType="coi"
            />

            <Alert color="destructive" visible={showAlert}>
              Please complete all required fields.
            </Alert>
            <ActionFooter
              className={footer}
              actions={[
                <Button
                  variant="ghost"
                  key="BackCertificateEdit"
                  as={Link}
                  to={WC_DOC_REQUEST_BASE_URL}
                >
                  Back
                </Button>,
                <Button
                  variant="filled"
                  key="ContinueCertificateEdit"
                  className={reviewButton}
                  type="submit"
                >
                  Review
                </Button>,
              ]}
            />
          </Form>
        </div>
      </div>
    </div>
  );
}
