import React from "react";
import { AuditLogItem } from "../AuditLogItem";
import { formatDate, getDollarsFromCents } from "pages/expenses/utils";

interface ApprovedProps {
  occurredAt: string;
  memberName: string;
  totalAmount: number;
  estimatedPayDate: string;
}

export const Approved: React.FC<ApprovedProps> = ({
  occurredAt,
  totalAmount,
  memberName,
  estimatedPayDate,
}) => {
  return (
    <AuditLogItem
      author={memberName}
      occurredAt={occurredAt}
      previewText="Request approved and the payment has been scheduled"
    >
      {memberName} approved the request. A payment for{" "}
      {getDollarsFromCents(totalAmount)} has been scheduled and should be
      received around {formatDate(estimatedPayDate)}.
    </AuditLogItem>
  );
};

export default Approved;
