import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./app-routes";
import { VARIABLE_NAMES, getEnvVariable } from "lib/environment";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ApolloProvider } from "@apollo/client";
import { getApolloClient } from "app/apollo/apollo";
import { OktaProvider } from "app/okta";

const queryClient = new QueryClient();

function App(): JSX.Element {
  const basename = import.meta.env.VITE_APP_APP_ROUTE_PREFIX;

  useEffect(() => {
    const favicon = document.getElementById("favicon") as HTMLLinkElement;
    const faviconColor = getEnvVariable(VARIABLE_NAMES.FAVICON_COLOR);
    const faviconPath = `/static/favicons/favicon_${faviconColor}.ico`;
    favicon.href = faviconPath;

    if (window.location.pathname === "/") {
      window.location.replace(`/${import.meta.env.VITE_APP_APP_ROUTE_PREFIX}`);
    }
  }, []);

  return (
    <BrowserRouter basename={basename}>
      <QueryClientProvider client={queryClient}>
        <OktaProvider>
          <ApolloProvider client={getApolloClient()}>
            <AppRoutes />
          </ApolloProvider>
        </OktaProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
