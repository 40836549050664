import React, { useState } from "react";
import classes from "./LeadPage.module.css";
import benefitsClasses from "pages/benefits/benefits.module.css";
import { useGetCompanyAdminInfoQuery } from "types/generated/operations";
import { useLeadFormContext } from "../../../contexts/401k/lead-form-context-provider";
import { useNavigate } from "react-router-dom";
import { PermissionsError } from "../../PermissionsError";
import { Button, PageHeader } from "@justworkshr/milo-core";
import { buildClockworkWebPath } from "lib/resource-finder";
import {
  ActionFooter,
  Form,
  FormField,
  PhoneInput,
  RadioButton,
  RadioButtonGroup,
  Select,
  SelectOption,
  TextArea,
  TextInput,
} from "@justworkshr/milo-form";
import ResourcesBox from "../resources-box";

interface FormErrors {
  correspondence_cc_emails: string[];
}

const LeadPage = () => {
  const { leadForm, setLeadForm } = useLeadFormContext();
  const { data: adminInfo, loading: adminInfoLoading } =
    useGetCompanyAdminInfoQuery();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<FormErrors>({} as FormErrors);

  if (adminInfoLoading) {
    return <></>;
  }

  const PERMISSION_BENEFITS = 1;
  if (!adminInfo?.currentMember?.permissions?.includes(PERMISSION_BENEFITS)) {
    return <PermissionsError />;
  }

  const goBack = () => {
    navigate("/benefits/setup/401k/learn/overview");
  };

  const companyName = adminInfo?.currentMember?.company?.name || "";
  const primaryContactName = adminInfo?.currentMember?.friendlyFullName || "";
  const primaryContactEmail = adminInfo?.currentMember?.emailWork || "";
  const primaryContactPhone =
    adminInfo?.currentMember?.phoneOffice ||
    adminInfo?.currentMember?.phoneCell ||
    adminInfo?.currentMember?.phoneHome ||
    "";

  const formIsValid = () => {
    const errors: FormErrors = {
      correspondence_cc_emails: [],
    };
    const emailPattern = /^.+@.+\..+$/;
    const ccEmailsValue = leadForm.correspondence_cc_emails as
      | string
      | undefined;
    const ccEmails = (ccEmailsValue?.split(",") || []).map((email: string) =>
      email.trim()
    );
    ccEmails.forEach((email) => {
      if (!emailPattern.test(email)) {
        errors.correspondence_cc_emails.push(
          `"${email}" is not a valid email address.`
        );
      }
    });
    setFormErrors(errors);
    return Object.values(errors).every((group) => group.length === 0);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formIsValid()) return;
    setLeadForm((leadForm) => ({
      ...leadForm,
      company_name: companyName,
      primary_contact_name: primaryContactName,
      primary_contact_email: primaryContactEmail,
      primary_contact_phone: primaryContactPhone,
    }));
    window.scrollTo(0, 0);
    navigate("/benefits/setup/401k/contact/review");
  };

  const setFormField = (key: string, value: string | boolean | undefined) => {
    setLeadForm((leadForm) => {
      return {
        ...leadForm,
        [key]: value,
      };
    });
  };

  const getRelatedEntitiesValue = (value: boolean | string | undefined) => {
    switch (value) {
      case true:
        return "yes";
      case false:
        return "no";
      default:
        return "";
    }
  };

  return (
    <div className={classes.empowerForm}>
      <PageHeader
        title="Lets get you in touch with Empower"
        linkPrevious={
          <a href={buildClockworkWebPath("/benefits/offer")}>
            Back to Add Benefits
          </a>
        }
      >
        Empower will review your information and be in touch in a few days to
        discuss plan options and next steps. And don’t worry &ndash; there is{" "}
        <strong>no obligation</strong> to enroll.
      </PageHeader>

      <div className="milo--grid" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <div style={{ gridColumn: "span 8" }}>
          <div className={classes.headerDescription}>
            <h2 className={benefitsClasses.benefitsHeader}>
              Contact Information
            </h2>
            <p className={benefitsClasses.benefitsParagraph}>
              We've added your existing contact info. If you need to make any
              updates, head over to your{" "}
              {adminInfo?.currentMember?.uuid ? (
                <a
                  href={buildClockworkWebPath(
                    `/employee_profile/${adminInfo.currentMember.uuid}`
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  profile settings
                </a>
              ) : (
                "profile settings"
              )}
              .
            </p>
          </div>
          <Form onSubmit={handleSubmit}>
            <div className={classes.halfSize}>
              <FormField label="Company" required={true}>
                <TextInput
                  name="company_name"
                  value={companyName}
                  disabled
                  required
                />
              </FormField>
              <FormField label="Primary contact name" required={true}>
                <TextInput
                  name="primary_contact_name"
                  value={primaryContactName}
                  disabled
                  required
                />
              </FormField>
            </div>
            <div className={classes.halfSize}>
              <FormField label="Primary contact email address" required={true}>
                <TextInput
                  name="primary_contact_email"
                  value={primaryContactEmail}
                  disabled
                  required
                />
              </FormField>
              <FormField label="Primary contact phone number" required={true}>
                <PhoneInput
                  name="primary_contact_phone"
                  value={primaryContactPhone}
                  disabled
                  required
                />
              </FormField>
            </div>
            <div className={classes.headerDescription}>
              <h2 className={benefitsClasses.benefitsHeader}>
                Additional information
              </h2>
              <p className={benefitsClasses.benefitsParagraph}>
                Include any admins or decision makers that should be part of the
                process.
              </p>
            </div>
            <div className={classes.fullSize}>
              <FormField
                label="Additional email addresses"
                message="Separate email addresses using a comma"
                error={formErrors.correspondence_cc_emails}
              >
                <TextInput
                  name="correspondence_cc_emails"
                  value={String(leadForm.correspondence_cc_emails || "")}
                  onChange={(event) =>
                    setFormField("correspondence_cc_emails", event.target.value)
                  }
                />
              </FormField>
              <FormField label="Additional notes" required={false}>
                <TextArea
                  name="notes"
                  value={String(leadForm.notes || "")}
                  onChange={(event) =>
                    setFormField("notes", event.target.value)
                  }
                />
              </FormField>

              <h2
                className={benefitsClasses.benefitsHeader}
                id="benefits-header"
              >
                Do you want to set up a new plan or transfer an existing one?
              </h2>

              <div className={classes.hideFormFieldLabel}>
                <FormField aria-labelledby="benefits-header" required>
                  <RadioButtonGroup
                    name="plan_type"
                    value={String(leadForm.plan_type || "")}
                    onChange={(event) =>
                      setFormField("plan_type", event.target.value)
                    }
                    defaultValue="STARTUP"
                  >
                    <RadioButton
                      id="start_up"
                      name="plan_type"
                      label="Set up a new plan"
                      value="STARTUP"
                    />
                    <RadioButton
                      id="takeover"
                      name="plan_type"
                      label="Transfer my existing plan"
                      value="TAKEOVER"
                    />
                  </RadioButtonGroup>
                </FormField>
              </div>

              {leadForm.plan_type === "TAKEOVER" ? (
                <>
                  <FormField label="Current provider" required>
                    <Select
                      name="previous_provider"
                      value={String(leadForm.previous_provider || "")}
                      onChange={(event) =>
                        setFormField("previous_provider", event.target.value)
                      }
                      required
                      placeholder="Select..."
                    >
                      <SelectOption>ADP</SelectOption>
                      <SelectOption>Betterment</SelectOption>
                      <SelectOption>Empower</SelectOption>
                      <SelectOption>Fidelity</SelectOption>
                      <SelectOption>Guideline</SelectOption>
                      <SelectOption>Human Interest</SelectOption>
                      <SelectOption>Paychex</SelectOption>
                      <SelectOption>Principal</SelectOption>
                      <SelectOption>Slavic401k</SelectOption>
                      <SelectOption>TriNet</SelectOption>
                      <SelectOption>Vanguard</SelectOption>
                      <SelectOption>Voya</SelectOption>
                      <SelectOption>Other</SelectOption>
                    </Select>
                  </FormField>
                  {leadForm.previous_provider === "Other" ? (
                    <>
                      <FormField label="Other provider" required>
                        <TextInput
                          name="previous_provider_other"
                          value={String(leadForm.previous_provider_other || "")}
                          onChange={(event) =>
                            setFormField(
                              "previous_provider_other",
                              event.target.value
                            )
                          }
                          required
                        />
                      </FormField>
                    </>
                  ) : null}
                  <FormField
                    label="Does your plan include multiple related entities?"
                    required
                  >
                    <RadioButtonGroup
                      name="has_related_entities"
                      value={getRelatedEntitiesValue(
                        leadForm.has_related_entities
                      )}
                      onChange={(event) =>
                        setFormField(
                          "has_related_entities",
                          event.target.value === "yes"
                        )
                      }
                    >
                      <RadioButton id="yes" label="Yes" value="yes" />
                      <RadioButton id="no" label="No" value="no" />
                    </RadioButtonGroup>
                  </FormField>
                </>
              ) : null}

              <ActionFooter
                actions={[
                  <Button
                    color="brand"
                    type="submit"
                    disabled={adminInfoLoading}
                    key="review"
                  >
                    Review
                  </Button>,
                ]}
                secondary={[
                  <Button
                    variant="outlined"
                    key="back"
                    onClick={() => {
                      goBack();
                    }}
                  >
                    Back
                  </Button>,
                ]}
              />
            </div>
          </Form>
        </div>

        <div style={{ gridColumn: "span 4" }}>
          <ResourcesBox />
        </div>
      </div>
    </div>
  );
};

export default LeadPage;
