export const SUBMITTER_HEADER_DESCRIPTION = (
  <p>
    Submit reimbursement requests to your manager when you pay out-of-pocket for
    valid, non-taxable business expenses. Requests must be submitted in a
    reasonable time frame-generally within 60 days after an expense is paid or
    incurred.{" "}
    <a
      rel="noopener noreferrer"
      target="_blank"
      href="https://help.justworks.com/hc/en-us/articles/17042398631323-Expense-Submissions-for-Employees-Desktop-"
    >
      Learn more about Expenses{" "}
    </a>
    in the Help Center.
  </p>
);
