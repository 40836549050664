import React from "react";
import { SystemIcon } from "@justworkshr/milo-icons";

export type PermissionLockProps = {
  adminsWithPermission: string[];
};
const PermissionLock: React.FC<PermissionLockProps> = () => {
  return <SystemIcon iconName="lock" color="neutral-subtle" />;
};
export default PermissionLock;
