import styles from "./SubmissionRequirementsPage.module.css";
import { prependBasePath } from "pages/expenses/constants";
import { useCategoriesStore } from "pages/expenses/store";
import { useNavigate } from "react-router-dom";
import { ExpenseCategory } from "types/Expenses";
import { AdditionalFields, DefaultFields } from "./components";
import { Button } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import { useEffect } from "react";

const {
  SubmissionRequirementsPageWrapper,
  SubmissionRequirementsCardsWrapper,
} = styles;

export const SubmissionRequirementsPage = () => {
  const navigate = useNavigate();

  const {
    categories: { activeCategory, categories },
    setCategories,
    setActiveCategory,
  } = useCategoriesStore();

  useEffect(() => {
    if (!activeCategory) {
      navigate(prependBasePath("CATEGORIES"));
    }
  }, [activeCategory, navigate]);

  const handleCancel = () => {
    setActiveCategory({ activeCategory: null });
    navigate(prependBasePath("CATEGORIES"));
  };

  const handleDone = () => {
    const updatedCategories = categories.map((currentCategory) => {
      return currentCategory.name === activeCategory?.name
        ? activeCategory
        : currentCategory;
    }) as ExpenseCategory[];

    setCategories({
      categories: updatedCategories,
    });

    setActiveCategory({ activeCategory: null });

    navigate(prependBasePath("CATEGORIES"));
  };

  return (
    <div className={SubmissionRequirementsPageWrapper}>
      <div className={SubmissionRequirementsCardsWrapper}>
        <DefaultFields />
        <AdditionalFields />
        <ActionFooter
          actions={[
            <Button
              type="button"
              variant="ghost"
              key="cancel-button"
              onClick={handleCancel}
              data-testid="cancel-button"
            >
              Cancel
            </Button>,
            <Button
              type="button"
              key="done-button"
              onClick={handleDone}
              data-testid="continue-button"
            >
              Continue
            </Button>,
          ]}
        />
      </div>
    </div>
  );
};

export default SubmissionRequirementsPage;
