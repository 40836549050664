import React from "react";
import { useState, MouseEvent } from "react";
import { Dialog, Button } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import styles from "./notice.module.css";
import { LINKS } from "../../constants/pdpGeneral";

const {
  notice,
  noticeHeadline,
  warningIcon,
  noticeHeadlineText,
  noticeOutline,
} = styles;

type NoticeProps = {
  isStd: boolean;
  content: {
    title: string;
    info: string;
    learnMore?: string;
  };
};

const Notice: React.FC<NoticeProps> = ({ isStd, content }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeDialog = () => setIsDialogOpen(false);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setIsDialogOpen(true);
  };

  return (
    <div className={notice}>
      {isStd ? (
        <>
          <section>
            <div className={noticeOutline}>
              <div className={noticeHeadline}>
                <div>
                  <SystemIcon iconName="warning" className={warningIcon} />
                </div>
                <div className={noticeHeadlineText}>{content.title}</div>
              </div>
              <p>
                Before enrolling in Short Term Disability, it is essential to
                recognize that employees in certain states{" "}
                <a
                  href=""
                  onClick={handleClick}
                  className="milo--link-secondary"
                >
                  have statutory disability leave programs{" "}
                </a>
                that may offer a richer benefit than our short term disability
                offering.
              </p>
            </div>
          </section>
          <Dialog
            isOpen={isDialogOpen}
            onClose={closeDialog}
            primaryButton={<Button onClick={closeDialog}>Got it</Button>}
            title="Keep in mind"
          >
            <p>
              Certain states including California, Washington, Massachusetts,
              New Jersey, Connecticut, and Rhode Island have established
              statutory disability leave provisions. Disability insurance
              carriers often require claimants to file a claim with their state
              program before before filing with a third-party carrier.
            </p>
            <br></br>
            <p>
              We strongly encourage you to see if your state has a statutory
              disability leave policy and any potential implications of these
              benefits.
            </p>
            <br></br>
            <p>
              We also recommend consulting with an HR professional,
              contact&nbsp;
              <a
                href={LINKS.resourcePages.std[0].resourcePage}
                target="_blank"
                rel="noopener noreferrer"
                className="milo--link-secondary"
              >
                Customer Support{" "}
              </a>
              , or read about{" "}
              <a
                href={LINKS.stdStateLeave}
                target="_blank"
                rel="noopener noreferrer"
                className="milo--link-secondary"
              >
                Short Term Disability and State Leave{" "}
              </a>{" "}
              in the Help Center.
            </p>
          </Dialog>
        </>
      ) : (
        <section>
          <div className={noticeOutline}>
            <div className={noticeHeadline}>
              <div>
                <SystemIcon iconName="warning" className={warningIcon} />
              </div>
              <div className={noticeHeadlineText}>{content.title}</div>
            </div>
            <p>{content.info}</p>
          </div>
        </section>
      )}
    </div>
  );
};

export default Notice;
