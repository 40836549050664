import { getRoute } from "pages/expenses/utils";

export const getSelectedRequestDetailTab = (
  pathname: string,
  isReviewer: boolean | undefined
) => {
  const route = getRoute(pathname);

  if (route?.includes("activity")) return 1;
  if (isReviewer && route?.includes("review")) return 0;

  return 0;
};

export const replaceRequestUuid = (pathname: string, requestUuid: string) =>
  pathname.replace(":request_uuid", requestUuid);
