import styles from "./BankAccount.module.css";
import { DisplayIcon, SystemIcon } from "@justworkshr/milo-icons";
import { SkeletonText } from "@justworkshr/milo-core";

const {
  bankAccountContainer,
  bankAccountIcon,
  bankAccountInfo,
  bankAccountHeader,
  bankAccountSkeletonHeader,
  bankAccountSkeletonSubheader,
  bankAccountStatusContainer,
  bankAccountStatus,
} = styles;

interface BankAccountProps {
  bankName: string;
  accountNumberMasked: string;
  verificationStatus: boolean;
}

export const BankAccountSkeleton = () => {
  return (
    <div className={bankAccountContainer}>
      <DisplayIcon iconName="local-taxes" className={bankAccountIcon} />
      <div className={bankAccountInfo}>
        <div className={bankAccountSkeletonHeader}>
          <SkeletonText size="sm" />
        </div>
        <div className={bankAccountSkeletonSubheader}>
          <SkeletonText size="xs" />
        </div>
        <div className={bankAccountSkeletonSubheader}>
          <SkeletonText size="2xs" />
        </div>
      </div>
    </div>
  );
};

export default function BankAccount({
  bankName,
  accountNumberMasked,
  verificationStatus,
}: BankAccountProps) {
  const verificationStatusCopy = verificationStatus
    ? "Securely verified"
    : "Account Pending";

  return (
    <div className={bankAccountContainer}>
      <DisplayIcon
        iconName="local-taxes"
        color={verificationStatus ? "blueGreen" : "gray"}
        className={bankAccountIcon}
      />
      <div className={bankAccountInfo}>
        <p className={bankAccountHeader}>{bankName}</p>
        <p>Account number : (...{accountNumberMasked})</p>
        <div className={bankAccountStatusContainer}>
          <SystemIcon
            iconName={
              verificationStatus ? "check-circle-inverted" : "minus-circle"
            }
            color={verificationStatus ? "success" : "warning"}
            size="extra-small"
          />
          <p className={bankAccountStatus}>{verificationStatusCopy}</p>
        </div>
      </div>
    </div>
  );
}
