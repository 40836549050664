import * as Yup from "yup";

export const invitationFormInitialValues = {
  memberType: "Employee - Full Time (W-2)",
  salaryInterval: "annualSalary",
  pay_rate: "",
  jobType: "non-manual",
  exemptionStatus: "",
  ptoType: [],
};

export const invitationFormSchema = Yup.object().shape({
  memberType: Yup.string().required("Required"),
  salaryInterval: Yup.string().required("Required"),
  pay_rate: Yup.string()
    .required("Required")
    .matches(
      /^(\$)?(?!0\d)(?:\d{1,3}(?:,\d{3})*|\d+)(?:\.\d{1,2})?$/, // Matches for salary amounts as per the schema
      "Invalid salary"
    ),
  jobType: Yup.string().required("Required"),
  exemptionStatus: Yup.string().required("Required"),
});

export const exemptionStatusCopyMap = (exemptionStatus: string) => {
  switch (exemptionStatus) {
    case "nonExempt":
      return "Employee is entitled overtime pay and/or minimum wage. They must track their time worked with timecards.";
    case "exempt":
      return "Employee is not entitled overtime pay and/or minimum wage.";
    default:
      return "";
  }
};

// Some of the types here might not be necssary once the graphql for this is implmented
// because we should be able use the auto generated the types from the graphql schema
// source: clockwork_web/app/models/pto_policy.rb
export enum PTO_POLICY_TYPE {
  vacation = "vacation",
  sick_leave = "sick_leave",
  other = "other",
  international = "international",
}

export enum PTO_POLICY_TYPE_LABEL {
  vacation = "Vacation",
  sick_leave = "Sick leave",
  other = "Other",
  international = "Non-US employee",
}

export enum PTO_POLICY_STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
  DELETED = "deleted",
}

export enum PTO_POLICY_SYNC_STATUS {
  SYNC = "sync",
  DONT_SYNC = "dont_sync",
}

export enum PTO_POLICY_TIME_PERIOD_TYPE {
  beginning_of_year = "beginning_of_year",
  anniversary = "anniversary",
}

// source: clockwork_web/app/models/pto_policy.rb
export enum PTO_POLICY_AWARD_METHOD {
  // This legacy pto policy award method accrues every single day (including holidays and weekends)
  // It is being deprecated in favor or pto_daily
  AWARD_METHOD_LEGACY_DAILY = "daily",
  // This daily award method only accrues on paid time off days
  AWARD_METHOD_DAILY = "pto_daily",
  AWARD_METHOD_ANNUALLY = "annually",
  AWARD_METHOD_HOURLY = "hourly",
}
