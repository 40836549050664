import { Dialog, Button } from "@justworkshr/milo-core";

interface ThankYouDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const ThankYouDialog: React.FC<ThankYouDialogProps> = ({ isOpen, onClose }) => {
  return (
    <Dialog
      title="Thank you!"
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={<Button onClick={onClose}>Close</Button>}
    >
      A member of our international team will contact you soon.
    </Dialog>
  );
};

export default ThankYouDialog;
