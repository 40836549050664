import usePlaidModal from "./usePlaidModal";
import { useSubmitPlaidToken } from "./useSubmitPlaidToken";

export const usePlaidBankAccountFlow = () => {
  const { mutate, isPending, isSuccess, isError } = useSubmitPlaidToken();

  const { openPlaidModal, isPlaidModalLoading, plaidError } = usePlaidModal({
    onSubmit: (publicToken) => {
      mutate({ publicToken });
    },
  });

  return {
    openPlaidModal,
    isPending: isPending || isPlaidModalLoading,
    isSuccess,
    plaidError,
    plaidAccountCreateError: isError,
  };
};

export default usePlaidBankAccountFlow;
