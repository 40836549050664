import { Outlet } from "react-router-dom";
import { ReviewerTabListTypes } from "./components/ReviewerHeader/ReviewerHeader.constants";
import { ReviewerHeader } from "./components/ReviewerHeader";
import { ReviewerChildren } from "./components/ReviewerChildren";
import {
  useRequestDetail,
  useGlobalConfigurationStore,
} from "pages/expenses/store";
import { useEffect } from "react";

export const ReviewerOutlet = () => {
  const {
    permissions: { isAdmin, isManager, isReporter },
  } = useGlobalConfigurationStore();
  const { resetRequestDetails } = useRequestDetail();

  let tabListType;
  if (isAdmin) tabListType = ReviewerTabListTypes.ADMIN;
  else if (isManager) tabListType = ReviewerTabListTypes.MANAGER;
  else if (isReporter) tabListType = ReviewerTabListTypes.REPORTER;

  useEffect(() => {
    resetRequestDetails();
  }, [resetRequestDetails]);

  return (
    <div>
      <ReviewerHeader title={<>Expenses</>} tabListType={tabListType}>
        <ReviewerChildren />
      </ReviewerHeader>
      <Outlet />
    </div>
  );
};
