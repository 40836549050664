import {
  useMemo,
  Dispatch,
  useReducer,
  useContext,
  createContext,
} from "react";

import cloneDeep from "lodash/cloneDeep";

const createStore = <Store, Action>(
  initialStore: Store,
  reducer: (store: Store, action: Action) => Store
) => {
  const StoreContext = createContext<{
    store: Store;
    dispatch: Dispatch<Action>;
  }>({
    store: initialStore,
    dispatch: () => null,
  });

  const useStore = (): {
    store: Store;
    dispatch: Dispatch<Action>;
  } => {
    const context = useContext(StoreContext);

    if (!context) {
      throw new Error("Store not initialized");
    }

    return context;
  };

  const useSelectorStore = <SelecterOutput,>(
    selector: (currentStore: Store) => SelecterOutput
  ): {
    store: SelecterOutput;
    dispatch: Dispatch<Action>;
  } => {
    const { dispatch, store } = useStore();

    return { store: selector(store), dispatch };
  };

  const Provider = ({
    children,
  }: {
    children: JSX.Element | JSX.Element[];
  }): JSX.Element => {
    const [store, dispatch] = useReducer(reducer, cloneDeep(initialStore));

    const memoizedStore = useMemo(
      () => ({
        store,
        dispatch,
      }),
      [store, dispatch]
    );

    return (
      <StoreContext.Provider value={memoizedStore}>
        {children}
      </StoreContext.Provider>
    );
  };

  return { useStore, useSelectorStore, Provider, StoreContext };
};

export default createStore;
