import React from "react";
import styles from "./NoncontributoryOptionsTable.module.css";

const {
  tableContainer,
  subHeader,
  contentTable,
  coverageAmountAndCostsContent,
} = styles;

type tableProps = {
  costTableInfo: {
    employerPaidOptionOne: {
      optionId?: string;
      optionLabel?: string;
      coverageAmount: string;
      costs: string;
    };
    employerPaidOptionTwo: {
      optionId?: string;
      optionLabel?: string;
      coverageAmount: string;
      costs: string;
    };
    employeePaidOption: {
      optionId?: string;
      optionLabel?: string;
      coverageAmount: string;
      costs: string;
    };
  };
};

export const NoncontributoryOptionsTable: React.FC<tableProps> = ({
  costTableInfo,
}) => {
  return (
    <>
      <div className="milo--grid"></div>
      <div>
        <table className={tableContainer}>
          <thead>
            <tr>
              <th colSpan={2}>Noncontributory (employer paid)</th>
              <th>Voluntary (employee paid)</th>
            </tr>
          </thead>
          <thead>
            <tr>
              <td className={subHeader}>Option 1</td>
              <td className={subHeader}>Option 2</td>
              <td className={subHeader}>Option 3</td>
            </tr>
          </thead>

          <tbody>
            {/* TODO: map over OPTIONS_AND_COSTS_CONTENT hash to simplify*/}
            <tr>
              <td className={contentTable}>
                <strong>Coverage amount</strong>
                <br></br>
                <div className={coverageAmountAndCostsContent}>
                  {costTableInfo.employerPaidOptionOne.coverageAmount}
                </div>
              </td>
              <td className={contentTable}>
                <strong>Coverage amount</strong>
                <br></br>
                <div className={coverageAmountAndCostsContent}>
                  {costTableInfo.employerPaidOptionTwo.coverageAmount}
                </div>
              </td>
              <td className={contentTable}>
                <strong>Coverage amount</strong>
                <br></br>
                <div className={coverageAmountAndCostsContent}>
                  {costTableInfo.employeePaidOption.coverageAmount}
                </div>
              </td>
            </tr>
            <tr>
              <td className={contentTable}>
                <strong>Costs</strong>
                <br></br>
                <div className={coverageAmountAndCostsContent}>
                  {costTableInfo.employerPaidOptionOne.costs}
                </div>
              </td>
              <td className={contentTable}>
                <strong>Costs</strong>
                <br></br>
                <div className={coverageAmountAndCostsContent}>
                  {costTableInfo.employerPaidOptionTwo.costs}
                </div>
              </td>
              <td className={contentTable}>
                <strong>Costs (for your employee)</strong>
                <br></br>
                <div className={coverageAmountAndCostsContent}>
                  {costTableInfo.employeePaidOption.costs}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
