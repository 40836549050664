import { prependBasePath } from "pages/expenses/constants";
import { useGlobalConfigurationStore } from "pages/expenses/store";
import { useNavigate } from "react-router-dom";
import { useGetConfigurationQuery } from "types/generated/operations";

export const useGetConfiguration = () => {
  const navigate = useNavigate();
  const { setConfiguration } = useGlobalConfigurationStore();

  const configurationResponse = useGetConfigurationQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    onCompleted(configurationResponseData) {
      if (configurationResponseData) {
        const hasJoinedExpenses =
          configurationResponseData?.authenticatedMember?.company
            ?.companySetting?.value === "true";

        setConfiguration(configurationResponseData);

        if (!hasJoinedExpenses) {
          navigate(prependBasePath("LEARN"));
        }
      }
    },
  });

  return configurationResponse?.loading;
};
