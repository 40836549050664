import React from "react";
import { NoticeTypes } from "./Notice.types";
import { mergeClassNames } from "../../utils";
import styles from "./Notice.module.css";

const { NoticeWrapper, NoticeTitle, info, warning } = styles;

interface NoticeProps {
  title?: string;
  className?: string;
  type?: NoticeTypes;
  children: React.ReactNode;
}

export const Notice: React.FC<NoticeProps> = ({
  title,
  className,
  type = NoticeTypes.INFO,
  children,
}) => {
  const typeClassName = type === NoticeTypes.WARNING ? warning : info;
  const mergedClassNames = mergeClassNames([
    NoticeWrapper,
    typeClassName,
    className,
  ]);
  return (
    <div className={mergedClassNames}>
      {title && <h4 className={NoticeTitle}>{title}</h4>}
      {children}
    </div>
  );
};

export default Notice;
