import benefitsClasses from "pages/benefits/benefits.module.css";
import { UnorderedList, UnorderedListItem } from "@justworkshr/milo-core";

const EligibilityTab = () => {
  return (
    <div>
      <h2 className={benefitsClasses.benefitsHeader}>Who can sign up?</h2>

      <p className={benefitsClasses.benefitsParagraph}>
        You'll set your eligibility requirements when setting up your plan with
        Empower. Initial guidelines include:
      </p>

      <UnorderedList>
        <UnorderedListItem>
          The default age to participate is 18. You can decide to implement an
          age requirement up to 21.
        </UnorderedListItem>
        <UnorderedListItem>
          Empower doesn't have a minimum service requirement, but you can decide
          to implement one.
        </UnorderedListItem>
        <UnorderedListItem>
          Full-time, part-time, interns, paid, and unpaid owners must be
          eligible.
        </UnorderedListItem>
        <UnorderedListItem>
          Any contractors, vendors, or employees (regardless of working class)
          under a labor union are ineligible.
        </UnorderedListItem>
      </UnorderedList>
    </div>
  );
};

export default EligibilityTab;
