import React, { ReactElement } from "react";
import styles from "./Step.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";

const {
  stepDisplay,
  verticalDisplay,
  stepIconDisplay,
  verticalStepIconDisplay,
  titleDisplay,
  subtitleDisplay,
  completeBackground,
  warningBackground,
  errorBackground,
  currentBackground,
  disabledDisplay,
  hidden,
} = styles;

export interface StepProps {
  /* A description of the Step. */
  title: string;
  /* Additional context of the Step. */
  subtitle?: string;
  /* Whether the step is disabled. */
  disabled?: boolean;
  /* The state of a step, which influences its styling. */
  status?: "complete" | "pending" | "current" | "error" | "warning";
  /* The sequential position of a Step */
  stepNumber: number;
  /* The direction of the stepper. */
  direction?: "vertical" | "horizontal";
  /* Whether to hide the step label. */
  hideLabel?: boolean;
}

const Step = ({
  title,
  subtitle,
  stepNumber,
  disabled = false,
  status = "pending",
  direction = "horizontal",
  hideLabel = false,
}: StepProps): ReactElement => {
  let stepIcon = <>{stepNumber}</>;
  let stepIconClass;
  switch (status) {
    case "complete":
      stepIcon = <SystemIcon iconName="check" color="inverse" />;
      stepIconClass = completeBackground;
      break;
    case "warning":
      stepIcon = <>!</>;
      stepIconClass = warningBackground;

      break;
    case "error":
      stepIcon = <SystemIcon iconName="close" color="inverse" />;
      stepIconClass = errorBackground;
      break;
    case "current":
      stepIconClass = currentBackground;
      break;
  }
  if (disabled) {
    stepIcon = <>{stepNumber}</>;
    stepIconClass = disabledDisplay;
  }

  const subtitleText =
    subtitle !== undefined ? (
      <div className={subtitleDisplay}>{subtitle}</div>
    ) : (
      <></>
    );

  const stepDisplayClasses = [stepDisplay];
  if (direction === "vertical") {
    stepDisplayClasses.push(verticalDisplay);
  }

  const stepIconDisplayClasses = [stepIconDisplay, stepIconClass];
  if (direction === "vertical") {
    stepIconDisplayClasses.push(verticalStepIconDisplay);
  }

  return (
    <li className={stepDisplayClasses.join(" ")}>
      <div className={stepIconDisplayClasses.join(" ")}>{stepIcon}</div>
      <div className={hideLabel ? hidden : ""}>
        <div className={titleDisplay}>{title}</div>
        {subtitleText}
      </div>
    </li>
  );
};

export default Step;
