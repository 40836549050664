import { ReactElement } from "react";
import Search from "./search";
import CompanyDisplay from "./company-display";
import styles from "./header.module.css";
import HelpButton from "./help/help-button";
import { UserNavCapabilities } from "types/UserNavCapabilities";
import LogoIcon from "app-frame/common/LogoIcon";
import AlertsButton from "./alerts/alerts-button";
import WhatsNewButton from "./whats-new/whats-new-button";
import UserMenuButton from "./user-menu/user-menu-button";
import SideMenuButton from "./side-menu/side-menu-button";
import PanelControlContextProvider from "./context/panels-control-context-provider";
import { ViewType, useViewContext } from "./context/view-context-provider";
const { header, headerButtonContainer } = styles;

interface Header {
  capabilities: UserNavCapabilities;
}

export default function Header({ capabilities }: Header): ReactElement {
  const { view } = useViewContext();

  const {
    hasAccessToMemberSearch,
    hasAccessToReceiveAppAlerts,
    hasAccessToWhatsNewProductPosts,
  } = capabilities;

  const mobileHeader = (
    <>
      <SideMenuButton />
      <div data-testid="company-logo">
        <LogoIcon color="black" height="16" />
      </div>
      <UserMenuButton />
    </>
  );

  const tabletHeader = (
    <>
      {hasAccessToMemberSearch && <Search />}
      <div className={headerButtonContainer}>
        {hasAccessToReceiveAppAlerts && <AlertsButton />}
        {hasAccessToWhatsNewProductPosts && <WhatsNewButton />}
        <HelpButton />
        <UserMenuButton />
      </div>
    </>
  );

  const desktopHeader = (
    <>
      <CompanyDisplay />
      {hasAccessToMemberSearch && <Search />}
      <div className={headerButtonContainer}>
        {hasAccessToReceiveAppAlerts && <AlertsButton />}
        {hasAccessToWhatsNewProductPosts && <WhatsNewButton />}
        <HelpButton />
        <UserMenuButton />
      </div>
    </>
  );

  return (
    <div className={header} aria-label="app-header">
      <PanelControlContextProvider>
        {view === ViewType.MOBILE && mobileHeader}
        {view === ViewType.TABLET && tabletHeader}
        {view === ViewType.DESKTOP && desktopHeader}
      </PanelControlContextProvider>
    </div>
  );
}
