import { ReactElement, useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { buildClockworkWebPath } from "lib/resource-finder";

import Avatar from "app-frame/common/Avatar";

import styles from "./ProfilePage.module.css";

import {
  ALERT_COLOR,
  INTERNATIONAL_CONTRACTOR_MEMBER_DETAIL_PATH,
  MEMBER_STATUS,
  SUBPATHS,
} from "pages/international-contractor-profile-page/constants";
import { ACTIONS_LINKS, TABS } from "./constants";
import { InternationalContractorMemberContext } from "../../context/InternationalContractorMemberContext";
import TerminateContractorModal from "pages/international-contractor-profile-page/components/TerminateContractorModal";
import RehireContractorModal from "pages/international-contractor-profile-page/components/RehireContractorModal";
import { Alert, Badge, Tab, TabList } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import { AlertContext } from "pages/international-contractor-profile-page/context/AlertContext";
import { useUpdateInternationalMemberStatusMutation } from "types/generated/operations";
import { getMemberPreferredName } from "pages/international-contractor-profile-page/utils";

export default function InternationalContractorMemberDetail(): ReactElement {
  const { alert, setAlert, clearAlert } = useContext(AlertContext);

  const [showTerminateContractorModal, setShowTerminateContractorModal] =
    useState(false);
  const [showRehireContractorModal, setShowRehireContractorModal] =
    useState(false);

  const [updateInternationalCompanyMemberStatusMutation] =
    useUpdateInternationalMemberStatusMutation();

  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const tabPath = pathSegments[pathSegments.length - 1];

  const { member, error, refetch } = useContext(
    InternationalContractorMemberContext
  );
  const memberName = getMemberPreferredName(member);
  const isMemberTerminated = member?.status === "terminated";

  const getSuccessAlertMessage = (status: string) => {
    const rehireMessage = `An email has been sent to ${memberName} and they are now rehired.`;
    const terminateMessage = `${memberName} has been successfully terminated.`;
    return status === "active" ? rehireMessage : terminateMessage;
  };

  const updateMemberStatus = (memberId: string, status: string) => {
    let errors = null;

    try {
      updateInternationalCompanyMemberStatusMutation({
        variables: {
          companyMemberId: memberId,
          status: status,
        },
        onCompleted() {
          handleCompleted(status);
          refetch();
        },
        onError(e) {
          errors = e;
          handleError();
        },
      });
    } catch (e) {
      errors = e;
      handleError();
    }

    if (status === MEMBER_STATUS.TERMINATED_HIDDEN && !errors) {
      window.location.href = buildClockworkWebPath(
        ACTIONS_LINKS.CONTRACTORS_LIST
      );
      return;
    }
    if (status === MEMBER_STATUS.ACTIVE) {
      setShowRehireContractorModal(false);
    }
    if (status === MEMBER_STATUS.TERMINATED) {
      setShowTerminateContractorModal(false);
    }
  };

  const handleCompleted = (status: string) => {
    setAlert({
      color: ALERT_COLOR.ADDITIVE,
      message: getSuccessAlertMessage(status),
    });
  };

  const handleError = () => {
    setAlert({
      color: ALERT_COLOR.DESTRUCTIVE,
      message:
        "Something went wrong, please try again. If the problem persists, please contact support.",
    });
  };

  useEffect(() => {
    if (isMemberTerminated && !member.hasBeenPaid) {
      setAlert({
        color: ALERT_COLOR.NEW,
        message: (
          <span>
            {memberName}'s account was terminated before they received any
            payments. Would you like to remove them from Justworks?
            <a
              className={styles.removeMemberButton}
              onClick={() =>
                updateMemberStatus(
                  member.uuid!,
                  MEMBER_STATUS.TERMINATED_HIDDEN
                )
              }
            >
              Remove {memberName}
            </a>
          </span>
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMemberTerminated]);

  if (!member || error) {
    return <></>;
  }

  return (
    <>
      {alert && (
        <Alert
          dismissible
          visible
          color={alert.color}
          onDismiss={clearAlert}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          children={[alert.message]}
        />
      )}

      <section
        id="InternationalContractorMemberDetail"
        className={styles.container}
      >
        <header
          className={`${styles.pageHeader} ${
            isMemberTerminated ? styles.terminated : ""
          }`}
        >
          <nav className={styles.breadcrumbs}>
            <a href={buildClockworkWebPath(ACTIONS_LINKS.CONTRACTORS_LIST)}>
              {"Contractors"}{" "}
            </a>
            {" / International Contractors"}
          </nav>

          <div>
            <div className={styles.avatarContainer}>
              <Avatar name={member.firstName} height={140} width={140} />
            </div>

            <div className={styles.details}>
              <div className={styles.personalInfo}>
                <div className={styles.header}>
                  <>
                    <h1>{memberName}</h1>
                    {(() => {
                      if (member.pronouns) {
                        return (
                          <p className={styles.pronounContent}>
                            ({member.pronouns})
                          </p>
                        );
                      }
                    })()}
                    {isMemberTerminated && (
                      <Badge className={styles.badge}>Terminated</Badge>
                    )}
                  </>
                </div>
                {member.emojiFlag}{" "}
                <span className={styles.memberCountry}>{member.country}</span>
              </div>
            </div>
          </div>
        </header>

        <nav className={styles.actions}>
          {ACTIONS_LINKS.MAKE_PAYMENT && !isMemberTerminated && (
            <a
              href={buildClockworkWebPath(
                `/${ACTIONS_LINKS.MAKE_PAYMENT}?uuid=${member.uuid}`
              )}
            >
              <SystemIcon
                className={styles.narrow}
                color="brand"
                iconName="dollar"
              />
              Make a payment
            </a>
          )}
          {ACTIONS_LINKS.VIEW_PAYMENTS && (
            <a
              href={buildClockworkWebPath(
                `/${ACTIONS_LINKS.VIEW_PAYMENTS}?intlContractorUuid=${member.uuid}`
              )}
            >
              <SystemIcon
                className={styles.narrow}
                color="brand"
                iconName="dollar"
              />
              View payments
            </a>
          )}
          {!isMemberTerminated && (
            <a onClick={() => setShowTerminateContractorModal(true)}>
              <SystemIcon
                className={styles.narrow}
                color="brand"
                iconName="close"
              />
              Terminate
            </a>
          )}
          {isMemberTerminated && (
            <a onClick={() => setShowRehireContractorModal(true)}>
              <SystemIcon color="brand" iconName="person-plus" />
              Rehire
            </a>
          )}
        </nav>

        <section className={styles.pageSection}>
          <div className={styles.tabListContainer}>
            <TabList selected={TABS[tabPath]} orientation="vertical">
              <Tab
                onClick={() =>
                  navigate(
                    `${INTERNATIONAL_CONTRACTOR_MEMBER_DETAIL_PATH}/${member.uuid}/${SUBPATHS.PERSONAL_INFO}`
                  )
                }
              >
                Information
              </Tab>
              <Tab
                onClick={() =>
                  navigate(
                    `${INTERNATIONAL_CONTRACTOR_MEMBER_DETAIL_PATH}/${member.uuid}/${SUBPATHS.DOCUMENTS}`
                  )
                }
              >
                Documents
              </Tab>
            </TabList>
          </div>
          <Outlet />
        </section>
      </section>

      {showTerminateContractorModal && (
        <TerminateContractorModal
          onDismiss={() => setShowTerminateContractorModal(false)}
          onSubmit={() =>
            updateMemberStatus(member.uuid!, MEMBER_STATUS.TERMINATED)
          }
        />
      )}

      {showRehireContractorModal && (
        <RehireContractorModal
          onDismiss={() => setShowRehireContractorModal(false)}
          onSubmit={() =>
            updateMemberStatus(member.uuid!, MEMBER_STATUS.ACTIVE)
          }
        />
      )}
    </>
  );
}
