import { ReactElement } from "react";
import style from "./work-status-page.module.css";
import { PageData, WorkStatus, ClaimFormProps } from "../../../types";
import SharedForm from "../../shared/shared-form-outline";
import { Formik, Form } from "formik";
import FormContinue from "../../shared/form-continue";
import { FormikFormField } from "../../../../../../lib/formik/FormikFormField/index";
import { CheckboxInput, DateInput, RadioButton } from "@justworkshr/milo-form";
import RadioButtonGroup from "../../../../../team-management/components/RadioButtonGroup/RadioButtonGroup";
import { validationFormSchema } from "./utils";

const {
  workStatusSection,
  header,
  missTimeSection,
  yesWrapContent,
  losingTimeContent,
  returnWorkContent,
  confirmReturn,
  returnWorkInput,
  btnContinue,
  losingTimeDateInput,
  errorReturnWorkContent,
} = style;

export default function WorkStatusPage({
  handleErrorMessage,
  pageData,
  setPageData,
  currentPageNumber,
  name,
  goBack,
  goForward,
  exitForm,
  submitDraft,
}: ClaimFormProps & {
  setPageData: (e: WorkStatus) => void;
  submitDraft: (section: WorkStatus, total: PageData) => void;
}): ReactElement {
  const formData = pageData.workStatus;

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationFormSchema}
      onSubmit={(values) => {
        submitDraft(values, pageData);
        setPageData(values);
        goForward();
      }}
    >
      {({
        setFieldValue,
        values,
        errors,
        setValues,
        setFieldTouched,
        touched,
      }) => (
        <SharedForm
          handleErrorMessage={handleErrorMessage}
          currentPageNumber={currentPageNumber}
          name={name}
          key="exit-work-status-page"
          exitFn={() => {
            submitDraft(values, pageData);
            exitForm();
          }}
        >
          <Form data-testid="work-status-form">
            <div className={header}>
              <h1>Work Status</h1>
            </div>
            <div className={workStatusSection}>
              <div className={missTimeSection}>
                <FormikFormField
                  label="Did this employee miss time from work?"
                  name="missTime"
                  required
                >
                  <div>
                    <RadioButtonGroup
                      name="missTime"
                      value={values.missTime}
                      onChange={(e) => {
                        if (e.target.value === "No") {
                          setFieldValue("paid", "");
                          setFieldValue("losingTime", "");
                          setFieldValue("returnToWorkDate", "");
                          setFieldValue("returnToWorkBoolean", undefined);
                        }
                      }}
                    >
                      <RadioButton label="Yes" value="Yes" />
                      <RadioButton label="No" value="No" />
                    </RadioButtonGroup>
                  </div>
                </FormikFormField>
              </div>

              {values.missTime === "Yes" && (
                <div className={yesWrapContent}>
                  <FormikFormField
                    label="Was the employee paid for the time missed from work?"
                    name="paid"
                    required
                  >
                    <div>
                      <RadioButtonGroup name="paid" value={values.paid}>
                        <RadioButton label="Yes" value="Yes" />
                        <RadioButton label="No" value="No" />
                      </RadioButtonGroup>
                    </div>
                  </FormikFormField>

                  <div className={losingTimeContent}>
                    <FormikFormField
                      label="When did the employee start losing time?"
                      name="losingTime"
                      required
                    >
                      <div className={losingTimeDateInput}>
                        <DateInput
                          name="losingTime"
                          value={values.losingTime}
                          onAccept={(date) => {
                            setFieldValue("losingTime", date);
                          }}
                        />
                      </div>
                    </FormikFormField>
                  </div>

                  <div
                    className={`${
                      touched.returnToWorkDate && errors.returnToWorkDate
                        ? errorReturnWorkContent
                        : ""
                    } ${returnWorkContent}`}
                  >
                    <FormikFormField
                      label="When did the employee return to work?"
                      name="returnToWorkDate"
                      required
                    >
                      <DateInput
                        name="returnToWorkDate"
                        disabled={values.returnToWorkBoolean}
                        onAccept={(date) => {
                          setFieldValue("returnToWorkDate", date);

                          if (values.returnToWorkBoolean) {
                            setFieldValue("returnToWorkDate", "");
                          }
                        }}
                      />
                    </FormikFormField>

                    <div className={confirmReturn}>
                      <div className={returnWorkInput}>
                        <CheckboxInput
                          label="Employee has not returned yet"
                          name="returnToWorkBoolean"
                          value="returnToWorkBoolean"
                          checked={values.returnToWorkBoolean}
                          onChange={(event) => {
                            const hasReturnWork = event.target.checked;

                            if (hasReturnWork) {
                              setValues({
                                ...values,
                                returnToWorkBoolean: hasReturnWork,
                                returnToWorkDate: "",
                              });
                            } else {
                              setFieldTouched("returnToWorkDate");
                              setFieldValue(
                                "returnToWorkBoolean",
                                hasReturnWork
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <hr />
              <div className={btnContinue}>
                <FormContinue
                  continueKey="continue-work-status-page"
                  backKey="back-work-status-page"
                  backFn={() => {
                    setPageData(values);
                    goBack();
                  }}
                  alignment="space-between"
                />
              </div>
            </div>
          </Form>
        </SharedForm>
      )}
    </Formik>
  );
}
