import { useRequestDetail } from "pages/expenses/store";
import styles from "./ExpenseDetailsPage.module.css";
import {
  ExpenseProject,
  ExpenseCategory,
  RequestDetailExpense,
  ExpenseLibraryExpenseReceipt,
} from "types/Expenses";
import { useState, useMemo, useEffect, useCallback } from "react";
import { MEALS_AND_ENTERTAINMENT_CATEGORY_NAME } from "pages/expenses/constants";
import {
  getIsFormValid,
  getExpenseType,
  handleExpenseType,
  getIsExpenseUpdated,
  convertNumberToPennies,
  formatNumberWithCommas,
  parseExpenseDetailPageData,
} from "./ExpenseDetailsPage.utils";
import { handleDate, parseExpenseForCreation } from "pages/expenses/utils";
import { useGetExpenseDetailsPageQuery } from "types/generated/operations";
import {
  Amount,
  Project,
  Receipt,
  Category,
  Merchant,
  Billable,
  Attendees,
  Description,
  ExpenseType,
  TransactionDate,
  UnattachedExpenseActionFooter,
  ReimbursementExpenseActionFooter,
} from "./components";
import { Loading } from "pages/expenses/components";
import { FileResponse } from "pages/expenses/hooks";
import differenceBy from "lodash/differenceBy";

const {
  ExpenseDetailsPageWrapper,
  ExpenseDetailsPageContainer,
  ExpenseDetailsPageFormWrapper,
} = styles;

export const ExpenseDetailsPage = () => {
  const {
    setRequestDetail,
    requestDetail: {
      editMode: { editExpense },
    },
  } = useRequestDetail();

  const { data: expenseDetailPageData, loading: isExpenseDetailPageLoading } =
    useGetExpenseDetailsPageQuery();

  const {
    projects,
    categories,
    projectsMapping,
    categoriesMapping,
    authenticatedMember,
  } = useMemo(
    () => parseExpenseDetailPageData(expenseDetailPageData),
    [expenseDetailPageData]
  );

  const isEditMode = editExpense !== null;

  const expense = useMemo(() => {
    if (isEditMode) {
      return editExpense;
    }

    return {};
  }, [editExpense, isEditMode]) as RequestDetailExpense;

  const [form, setForm] = useState({
    merchant: { value: expense?.merchant || "", error: "" },
    attendees: { value: expense?.attendees || "", error: "" },
    description: { value: expense?.description || "", error: "" },
    transactionDate: {
      value: handleDate(expense?.transactionDate),
      error: "",
    },
    amount: {
      value: expense?.amount ? (expense.amount / 100.0).toString() : "",
      error: "",
    },
    isBillableToClient: {
      value: (expense?.isBillableToClient || false).toString(),
      error: "",
    },
    expenseType: {
      value: handleExpenseType(expense?.expenseType),
      error: "",
    },
  });

  const [receipts, setReceipts] = useState<ExpenseLibraryExpenseReceipt[]>(
    expense?.receipts || []
  );
  const [selectedCategory, setSelectedCategory] =
    useState<ExpenseCategory | null>(null);
  const [selectedProject, setSelectedProject] = useState<ExpenseProject | null>(
    null
  );

  const handleFormValue = useCallback(
    ({
      key,
      value,
    }: {
      key: keyof typeof form;
      value: (typeof form)[keyof typeof form]["value"];
    }) =>
      setForm((prevFormState) => ({
        ...prevFormState,
        [key]: { ...prevFormState[key], value },
      })),
    []
  );

  const handleFormError = useCallback(
    ({
      key,
      error,
    }: {
      key: keyof typeof form;
      error: (typeof form)[keyof typeof form]["error"];
    }) =>
      setForm((prevFormState) => ({
        ...prevFormState,
        [key]: { ...prevFormState[key], error },
      })),
    []
  );

  const handleCategoryChange = (categoryName: "name") => {
    let attendees = { value: "", error: "" };
    const newCategory = categoriesMapping[categoryName];

    setSelectedCategory(newCategory);

    if (
      newCategory.name === MEALS_AND_ENTERTAINMENT_CATEGORY_NAME &&
      authenticatedMember &&
      !expense?.attendees
    ) {
      attendees = { value: authenticatedMember, error: "" };
    }

    setForm((prevFormState) => ({
      ...prevFormState,
      attendees,
      merchant: { value: "", error: "" },
      description: { value: "", error: "" },
    }));
  };

  const handleProjectChange = (projectName: "projectName") => {
    const newProject = projectsMapping[projectName];

    setSelectedProject(newProject);
  };

  const handleFileInputChange = (file: FileResponse) =>
    setReceipts((prevReceipts) => [...prevReceipts, file]);

  const handleReceiptDelete = (receipt: ExpenseLibraryExpenseReceipt) =>
    setReceipts((currentReceipts) =>
      currentReceipts.filter((curReceipt) => curReceipt.uuid !== receipt.uuid)
    );

  useEffect(() => {
    if (!isExpenseDetailPageLoading && isEditMode) {
      if (expense?.category) {
        setSelectedCategory(expense.category);
      }

      if (expense?.project) {
        setSelectedProject(expense.project);
      }

      if (expense?.amount) {
        handleFormValue({
          key: "amount",
          value: formatNumberWithCommas(expense.amount / 100.0),
        });
      }
    }
  }, [isExpenseDetailPageLoading, isEditMode, expense, handleFormValue]);

  useEffect(() => {
    if (
      !isExpenseDetailPageLoading &&
      authenticatedMember &&
      !expense?.attendees &&
      !isEditMode
    ) {
      handleFormValue({
        key: "attendees",
        value: authenticatedMember,
      });
    }
  }, [
    expense,
    isEditMode,
    handleFormValue,
    authenticatedMember,
    isExpenseDetailPageLoading,
  ]);

  const buildExpenseForReimbursementRequest = (): RequestDetailExpense => {
    const receiptUuidsToRemove = differenceBy(
      expense?.receipts,
      receipts,
      "uuid"
    );

    if (expense?.uuid && receiptUuidsToRemove.length) {
      setRequestDetail({
        metadata: {
          receiptUuidsToRemove: {
            [expense.uuid]: differenceBy(expense?.receipts, receipts, "uuid"),
          },
        },
      });
    }

    return {
      uuid: expense?.uuid || "",
      merchant: form.merchant.value,
      amount: convertNumberToPennies(form.amount.value),
      transactionDate: form.transactionDate.value,
      description: form.description.value,
      attendees: form.attendees.value,
      category: selectedCategory as ExpenseCategory,
      project: selectedProject,
      receipts,
      isBillableToClient:
        form.isBillableToClient.value === "true" ? true : false,
      expenseType: getExpenseType(form.expenseType.value),
    };
  };

  const buildExpenseForCreation = () =>
    parseExpenseForCreation({
      receipts,
      uuid: expense?.uuid || "",
      merchant: form.merchant.value,
      attendees: form.attendees.value,
      description: form.description.value,
      project: selectedProject,
      isBillableToClient:
        form.isBillableToClient.value === "true" ? true : false,
      expenseType: getExpenseType(form.expenseType.value),
      transactionDate: form.transactionDate.value,
      amount: convertNumberToPennies(form.amount.value),
      category: selectedCategory as ExpenseCategory,
    });

  const isFormValid =
    !!selectedCategory &&
    !!form.amount.value &&
    !!form.transactionDate.value &&
    getIsFormValid({
      form,
      metadata: {
        receipts,
        selectedCategory,
      },
    });

  const isExpenseUpdated = useMemo(() => {
    if (!editExpense) return true;

    return getIsExpenseUpdated(form, editExpense, {
      receipts,
      selectedProject,
      selectedCategory,
    });
  }, [form, editExpense, selectedProject, selectedCategory, receipts]);

  if (isExpenseDetailPageLoading) {
    return <Loading />;
  }

  return (
    <div className={ExpenseDetailsPageWrapper}>
      <header>
        <h1>Expenses</h1>
      </header>
      <div className={ExpenseDetailsPageContainer}>
        <h2>Expense detail</h2>
        <div className={ExpenseDetailsPageFormWrapper}>
          <TransactionDate
            handleFormValue={handleFormValue}
            handleFormError={handleFormError}
            transactionDate={form.transactionDate}
          />
          <Amount
            amount={form.amount}
            handleFormValue={handleFormValue}
            handleFormError={handleFormError}
          />
          <Project
            projects={projects}
            selectedProject={selectedProject}
            handleProjectChange={handleProjectChange}
          />
          <Category
            categories={categories}
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
          />
          <Description
            description={form.description}
            handleFormValue={handleFormValue}
            handleFormError={handleFormError}
            selectedCategory={selectedCategory}
          />
          <Merchant
            merchant={form.merchant}
            handleFormValue={handleFormValue}
            handleFormError={handleFormError}
            selectedCategory={selectedCategory}
          />
          <Attendees
            attendees={form.attendees}
            handleFormValue={handleFormValue}
            handleFormError={handleFormError}
            selectedCategory={selectedCategory}
          />
          <Receipt
            receipts={receipts}
            selectedCategory={selectedCategory}
            handleReceiptDelete={handleReceiptDelete}
            handleFileInputChange={handleFileInputChange}
          />
          <Billable
            handleFormValue={handleFormValue}
            selectedCategory={selectedCategory}
            isBillableToClient={form.isBillableToClient}
          />
          <ExpenseType
            expenseType={form.expenseType}
            handleFormValue={handleFormValue}
            selectedCategory={selectedCategory}
          />
        </div>
      </div>
      <UnattachedExpenseActionFooter
        isEditMode={isEditMode}
        isFormValid={isFormValid}
        isExpenseUpdated={isExpenseUpdated}
        buildExpenseForCreation={buildExpenseForCreation}
      />
      <ReimbursementExpenseActionFooter
        isEditMode={isEditMode}
        isFormValid={isFormValid}
        isExpenseUpdated={isExpenseUpdated}
        buildExpenseForReimbursementRequest={
          buildExpenseForReimbursementRequest
        }
      />
    </div>
  );
};
