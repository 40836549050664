import { RadioButtonGroup } from "../../../../../../../../components/RadioButtonGroup/RadioButtonGroup";
import { RadioButton } from "../../../../../../../../components/RadioButton/RadioButton";

interface BooleanProps {
  questionId: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const BooleanQuestion = ({
  questionId,
  value,
  onChange,
}: BooleanProps) => {
  return (
    <RadioButtonGroup
      name={questionId}
      value={value}
      onChange={onChange}
      orientation="horizontal"
    >
      <RadioButton value="true" label="Yes" />
      <RadioButton value="false" label="No" />
    </RadioButtonGroup>
  );
};
