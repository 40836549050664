import { Outlet } from "react-router-dom";
import { SeparationReasonStepper } from "../SeparationReasonStepper";
import styles from "../../SeparationReasonOutlet.module.css";
import { SeparationFlowContext } from "pages/team-management/contexts";
import { Loading } from "pages/team-management/routes/SeparationFlow/Loading/Loading";
import { useContext } from "react";
import { Alert } from "@justworkshr/milo-core";

const { formWrapper, grid, globalGenericErrorAlert } = styles;

export const SeparationReasonBody = () => {
  const { showGlobalGenericError, isLoading } = useContext(
    SeparationFlowContext
  );

  return isLoading ? (
    <Loading orientation="tall" />
  ) : (
    <div className={`${grid} milo--grid`}>
      {!!showGlobalGenericError && (
        <Alert color="destructive" className={`${globalGenericErrorAlert}`}>
          Something went wrong. Try refreshing or submitting again. If that
          doesn’t work, give us a call at (888) 534-1711.
        </Alert>
      )}
      <SeparationReasonStepper />
      <div className={formWrapper}>
        <Outlet />
      </div>
    </div>
  );
};
