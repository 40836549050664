import React from "react";
import { ExpenseDialog } from "pages/expenses/components";
import { Button } from "@justworkshr/milo-core";
import {
  DISCARD_EDITS_DIALOG_TITLE,
  DISCARD_EDITS_DIALOG_MESSAGE,
} from "./DiscardEditsDialog.constants";
import { useNavigate } from "react-router-dom";
import { prependBasePath } from "pages/expenses/constants";

interface DiscardEditsDialogProps {
  isOpen: boolean;
  onDiscard: () => void;
  handleDiscardEditsModal: () => void;
}

export const DiscardEditsDialog: React.FC<DiscardEditsDialogProps> = ({
  isOpen,
  onDiscard,
  handleDiscardEditsModal,
}) => {
  const navigate = useNavigate();

  const handleDiscard = () => {
    handleDiscardEditsModal();

    onDiscard();

    navigate(prependBasePath("EXPENSE_LIBRARY"));
  };

  return (
    <ExpenseDialog
      isOpen={isOpen}
      onClose={handleDiscardEditsModal}
      actions={[
        <Button
          type="button"
          variant="ghost"
          key="keep-expense-button"
          onClick={handleDiscardEditsModal}
        >
          Keep expense
        </Button>,
        <Button
          type="button"
          color="destructive"
          onClick={handleDiscard}
          key="discard-edits-button"
        >
          Discard edits
        </Button>,
      ]}
    >
      <>
        <h2>{DISCARD_EDITS_DIALOG_TITLE}</h2>
        <p>{DISCARD_EDITS_DIALOG_MESSAGE}</p>
      </>
    </ExpenseDialog>
  );
};
