import {
  PayFrequencyTypes,
  PaymentMethodType,
  PaymentTypeTypes,
  YesOrNoTypes,
} from "./AdditionalPaymentsPage.types";

export const ADDITIONAL_PAYMENTS_YES_OR_NO_OPTIONS = {
  [YesOrNoTypes.YES]: "Yes",
  [YesOrNoTypes.NO]: "No",
};

export const ADDITIONAL_PAYMENTS_PAYMENT_TYPE_OPTIONS = {
  [PaymentTypeTypes.BONUS]: "Bonus",
  [PaymentTypeTypes.COMMISSION]: "Commission",
  [PaymentTypeTypes.SEVERANCE]: "Severance",
  [PaymentTypeTypes.SUPPLEMENTAL]: "Supplemental",
};

export const ADDITIONAL_PAYMENTS_PAYMENT_METHOD_OPTIONS = {
  [PaymentMethodType.DIRECT_DEPOSIT]: {
    label: "Direct deposit",
    message:
      "Justworks will debit your account and then deposit the payment directly into your employee’s account.",
  },
  [PaymentMethodType.CHECK]: {
    label: "Physical check",
    message:
      "Justworks will calculate the taxes owed on your payment. Then, you’ll hand your employee a physical check with the correct net amount.",
  },
};

export const ADDITIONAL_PAYMENTS_PAY_FREQUENCY_OPTIONS = {
  [PayFrequencyTypes.WEEKLY]: "Once a week",
  [PayFrequencyTypes.BIWEEKLY]: "Once every two weeks",
  [PayFrequencyTypes.MONTHLY]: "Every month",
};

export const ADDITIONAL_PAYMENTS_FORM_COPY = {
  additional_payments_yes_or_no: "Will they receive any additional payments?",
  additional_payments_yes_or_no_message:
    "This includes bonuses, commissions, or severance–but not their final paycheck.",
  is_amount_of_payments_known: "Do you know the amount of the payments?",
  is_amount_of_payments_known_message:
    "If you don’t know the payment amount, then you can skip for now and schedule the payment later on in the Payments Center.",
  skip_separation_pay_label: "Skip filling separation pay",
  type: "Payment type",
  repeat_payment: "Does this payment repeat?",
  pay_date: "Pay date",
  amount: "Amount",
  payment_method: "Method",
  notes: "Notes",
  notes_message:
    "All payees of this payment will receive this message on their paystub.",
  pay_start_date: "Start date",
  amount_per_pay: "Amount per payment",
  pay_frequency: "Pay frequency",
  number_of_payments: "How many payments?",
  add_additional_payment: "Add additional payment",
  remove_additional_payment: "Remove additional payment",
};

const EMPLOYEE_FULL_TIME = "EMPLOYEE_FULL_TIME";
const EMPLOYEE_PART_TIME = "EMPLOYEE_PART_TIME";
const OWNER_FULL_TIME = "OWNER_FULL_TIME";
const PAID_INTERN = "PAID_INTERN";

export const ELIGIBLE_MEMBER_TYPES = [
  EMPLOYEE_FULL_TIME,
  EMPLOYEE_PART_TIME,
  OWNER_FULL_TIME,
  PAID_INTERN,
];

export const NO_PERMISSION_COPY =
  "Sorry, you don't have permissions to make payments but you can continue with the separation. Reach out to another admin to schedule the separation payments.";

export const RECIPIENT_NOT_ELIGIBLE_COPY =
  "Sorry, this person is not eligible to receive additional payments, but you can continue with the separation.";
