import React from "react";
import { useRedirectIfNotSteppedUpAdmin } from "pages/company-settings/hooks/useRedirectIfNotSteppedUpAdmin";

type ProtectedRouteProps = {
  children: React.ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const isLoading = useRedirectIfNotSteppedUpAdmin();

  if (isLoading) {
    return null;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
