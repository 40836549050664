import { useCallback } from "react";

import { useExpensesSelectorStore } from "../../store";
import {
  FeedbackDialogStoreType,
  INITIAL_FEEDBACK_DIALOG,
} from "../../constants";
import { FeedbackDialogActionTypes } from "../../actions";

export const useFeedbackDialogStore = () => {
  const { store: feedbackDialog, dispatch } = useExpensesSelectorStore(
    (store) => store.FeedbackDialog
  );

  const setFeedbackDialog = useCallback(
    (payload: FeedbackDialogStoreType) => {
      dispatch({
        type: FeedbackDialogActionTypes.SET_FEEDBACKDIALOG,
        payload,
      });
    },
    [dispatch]
  );

  const dismissFeedbackDialog = useCallback(() => {
    dispatch({
      type: FeedbackDialogActionTypes.SET_FEEDBACKDIALOG,
      payload: INITIAL_FEEDBACK_DIALOG,
    });
  }, [dispatch]);

  return { feedbackDialog, setFeedbackDialog, dismissFeedbackDialog };
};
