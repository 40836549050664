import React, { useState } from "react";
import styles from "./ExpenseDetailsCards.module.css";
import { RequestDetailExpense, RequestDetailReceipt } from "types/Expenses";
import {
  ReceiptDrawer,
  ExpenseDetailsCard,
  ExpenseUuidTooltip,
} from "pages/expenses/components";
import { DeleteButton, DetachButton, EditButton } from "./components";

interface ExpenseDetailsCardsProps {
  expenses: RequestDetailExpense[];
}

const {
  ExpenseDetailsCardWrapper,
  ExpenseDetailsCardsWrapper,
  ExpenseDetailsCardHeaderWrapper,
  ExpenseDetailsCardHeaderButtonWrapper,
} = styles;

export const ExpenseDetailsCards: React.FC<ExpenseDetailsCardsProps> =
  React.memo(({ expenses }) => {
    const [currentReceipt, setCurrentReceipt] =
      useState<RequestDetailReceipt | null>(null);

    const handleReceipt = (receipt: RequestDetailReceipt | null) => {
      setCurrentReceipt(receipt);
    };

    return (
      <div>
        <div className={ExpenseDetailsCardsWrapper}>
          {expenses.map((expense, index) => (
            <div key={expense.uuid} className={ExpenseDetailsCardWrapper}>
              <div className={ExpenseDetailsCardHeaderWrapper}>
                {expense?.uuid && (
                  <ExpenseUuidTooltip label="ID: " uuid={expense.uuid} />
                )}
                <div className={ExpenseDetailsCardHeaderButtonWrapper}>
                  {!expense?.uuid && <DeleteButton expenseIndex={index} />}
                  <DetachButton expense={expense} />
                  <EditButton expense={expense} expenseIndex={index} />
                </div>
              </div>
              <ExpenseDetailsCard
                showViewReceipt
                expense={expense}
                onReceiptChange={handleReceipt}
              />
            </div>
          ))}
        </div>
        {currentReceipt && (
          <ReceiptDrawer
            receipt={currentReceipt}
            onReceiptChange={handleReceipt}
          />
        )}
      </div>
    );
  });
