import { MouseEvent, ReactElement } from "react";
import { PageHeader, Button } from "@justworkshr/milo-core";
import { buildWebPath } from "lib/resource-finder";
import styles from "./more-services.module.css";
import UpgradeBenefits from "./images/upgrade-benefits.svg?react";
import UpgradeCompliance from "./images/upgrade-compliance.svg?react";
import UpgradePayroll from "./images/upgrade-payroll.svg?react";
import { useEnterDemoModeMutation } from "types/generated/operations";
import LogoIcon from "app-frame/common/LogoIcon";

const {
  moreServices,
  pageHeader,
  servicesContainer,
  listContainer,
  item,
  list,
  subheader,
  previewButtonContainer,
  trident,
  tridentLeft,
  tridentCenter,
  tridentRight,
  tridentCenterTop,
  tridentCenterBottom,
  tridentCenterLeft,
  tridentCenterRight,
  tridentLogo,
} = styles;

export default function MoreServices(): ReactElement {
  const [enterDemoModeMutation, { data }] = useEnterDemoModeMutation();
  if (data?.vendorModeSetter) {
    const { success } = data.vendorModeSetter;
    if (success) {
      window.location.assign(buildWebPath("/dashboard"));
    } else {
      window.location.assign(buildWebPath("/page-404"));
    }
  }
  const handleSubmit = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    void enterDemoModeMutation();
  };

  return (
    <div id="MoreServices" className={`${moreServices} milo--grid`}>
      <PageHeader
        className={pageHeader}
        title="Justworks is more than just a great way to receive payments"
      />
      <div className={servicesContainer}>
        <div className={item}>
          <UpgradeBenefits aria-hidden="true" />
          <h2>HR & Benefits</h2>
          Your employees deserve the best. Give them access to great{" "}
          <strong>medical, dental, and vision</strong> coverage without paying
          exorbitant fees.
        </div>
        <div className={item}>
          <UpgradePayroll aria-hidden="true" />
          <h2>Payroll & Payments</h2>
          <strong>Automated payroll</strong> means never having to worry that
          your employees get paid on time.
        </div>
        <div className={item}>
          <UpgradeCompliance aria-hidden="true" />
          <h2>Insurance</h2>
          Protect your company and your team with automated{" "}
          <strong>Workers' Comp, Disability Insurance, and EPLI.</strong>
        </div>
      </div>
      <figure className={trident}>
        <span className={tridentLeft}></span>
        <div className={tridentCenter}>
          <span className={tridentCenterTop}></span>
          <div className={tridentCenterBottom}>
            <span className={tridentCenterLeft}></span>
            <LogoIcon className={tridentLogo} color="black" />
            <span className={tridentCenterRight}></span>
          </div>
        </div>
        <span className={tridentRight}></span>
      </figure>
      <h2 className={subheader}>
        You have a company to run. Justworks provides a solid foundation for
        your business to grow and thrive.
      </h2>
      <div className={listContainer}>
        <ul className={list}>
          <li>Medical, Dental, and Vision</li>
          <li>401(k) and Commuter benefits</li>
          <li>Life insurance, COBRA administration, and more!</li>
          <li>Automatic tax calculations, payment, and filing</li>
          <li>Free contractor and vendor payments, anytime</li>
          <li>Cash flow forecasting</li>
          <li>HR Concierge - Call anytime with your HR questions!</li>
          <li>Quickbooks integration</li>
          <li>Online document storage</li>
        </ul>
      </div>
      <div className={previewButtonContainer}>
        <Button color="brand" variant="filled" onClick={handleSubmit}>
          Preview the full version of Justworks
        </Button>
      </div>
    </div>
  );
}
