import React from "react";

interface ErrorBlockProps {
  errorText: string;
}

const ErrorBlockMessage: React.FC<ErrorBlockProps> = ({ errorText }) => (
  <div role="alert">
    <ul className="_3VQQBa_formFieldStatus _3VQQBa_formFieldError">
      <li>
        <svg
          aria-hidden="false"
          aria-labelledby="milo--icon-error-title"
          className="ZQ50fa_errorFill ZQ50fa_mediumSize"
          fill="currentColor"
          height="24"
          role="img"
          viewBox="0 0 24 24"
          width="24"
        >
          <title id="milo--icon-error-title">Error:</title>
          <path d="M15.4 8.5c.4.4.4 1 .1 1.4L13.4 12l2.1 2.1c.4.4.4 1 0 1.3-.4.4-1 .4-1.3 0l-2.2-2-2.1 2.1c-.4.4-1 .4-1.3 0-.4-.4-.4-1 0-1.3l2.1-2.1-2.2-2.2c-.4-.4-.4-1 0-1.3.4-.4 1-.4 1.3 0l2.1 2.1L14 8.6c.4-.4 1-.4 1.4-.1zm6.6-.6v8.3L16.1 22H7.9L2 16.1V7.9L7.9 2h8.3L22 7.9zm-2 .8L15.3 4H8.7L4 8.7v6.6L8.7 20h6.6l4.7-4.7V8.7z"></path>
        </svg>
        {errorText}
      </li>
    </ul>
  </div>
);

export default ErrorBlockMessage;
