import { Button } from "@justworkshr/milo-core";
import { useRequestDetail, useFeedbackBannerStore } from "pages/expenses/store";
import { RequestDetailExpense } from "types/Expenses";
import { useExpenseDetailsCards } from "../../ExpenseDetailsCards.utils";
import { AlertTypes, RETURNED_STATUS } from "pages/expenses/constants";

interface DetachButtonProps {
  expense: RequestDetailExpense;
}

export const DetachButton: React.FC<DetachButtonProps> = ({ expense }) => {
  const {
    setDetachedExpenses,
    setRequestDetailExpenses,
    requestDetail: {
      request: { expenses, status },
      metadata: { isApproverView },
    },
  } = useRequestDetail();

  const { isEditable } = useExpenseDetailsCards();
  const { setFeedbackBanner } = useFeedbackBannerStore();

  const handleDetach = () => {
    const updatedExpenses = [...expenses].filter(
      (updatedExpense) => updatedExpense.uuid !== expense.uuid
    );

    if (status === RETURNED_STATUS && expense?.uuid) {
      setFeedbackBanner({
        type: AlertTypes.SUCCESS,
        show: true,
        message: `An expense has been detached from this request.`,
      });

      setDetachedExpenses((currentDetachedExpenses) => [
        ...currentDetachedExpenses,
        expense,
      ]);
    }

    setRequestDetailExpenses(updatedExpenses);
  };

  if (expense?.uuid === "" || !isEditable || isApproverView) {
    return null;
  }

  return (
    <Button
      type="button"
      variant="ghost"
      onClick={handleDetach}
      leftIcon="minus-circle"
    >
      Detach
    </Button>
  );
};

export default DetachButton;
