import React from "react";
import { TOOLTIP_HORIZONTAL_ALIGNMENTS, Tooltip } from "../Tooltip";
import { AdminApprover } from "types/Expenses";
import styles from "./AdminApproversTooltip.module.css";

const { AdminApproversTooltipContent } = styles;

interface AdminApproversTooltipProps {
  adminApprovers: AdminApprover[];
}

export const AdminApproversTooltip: React.FC<AdminApproversTooltipProps> =
  React.memo(({ adminApprovers }) => {
    return (
      <Tooltip
        visibility="visible"
        content={
          <div className={AdminApproversTooltipContent}>
            <p>
              Your request will be sent to all admins with payment permissions
              for approval:
            </p>
            <ul>
              {adminApprovers.map((adminApprover) => (
                <li key={adminApprover.uuid}>
                  {adminApprover.friendlyFullName}
                </li>
              ))}
            </ul>
          </div>
        }
        id="admin-approvers-tooltip"
        arrowAlignment={TOOLTIP_HORIZONTAL_ALIGNMENTS.CENTER}
      >
        Admins
      </Tooltip>
    );
  });
