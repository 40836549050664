export const toCurrency = (
  number: number,
  currency: string,
  signDisplay: "auto" | "always" | "never" | "exceptZero" = "always"
) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    currencyDisplay: "narrowSymbol",
    signDisplay: signDisplay,
  }).format(number / 100);
};
