import React from "react";
import { Button } from "@justworkshr/milo-core";
import { mergeClassNames } from "pages/expenses/utils";
import styles from "../../RequestHistoryPage.module.css";
const { ExportActionItemsWrapper, ExportActionItems, Open } = styles;
interface ExportReportButtonProps {
  isActive: boolean;
  collapse: boolean;
  toggleCollapseState: () => void;
  handleDownloadReport: (type: string) => void;
}
export const ExportReportButton: React.FC<ExportReportButtonProps> = ({
  isActive,
  collapse,
  toggleCollapseState,
  handleDownloadReport,
}) => {
  const iconName = collapse ? "chevron-down" : "chevron-up";

  return (
    <div className={ExportActionItemsWrapper}>
      <Button
        size="sm"
        rightIcon={iconName}
        onClick={toggleCollapseState}
        disabled={!isActive}
        data-testid="export-options"
      >
        Export
      </Button>
      <div
        id="export-action-items"
        className={mergeClassNames([ExportActionItems, collapse ? null : Open])}
      >
        <Button
          size="sm"
          variant="ghost"
          color="neutral"
          onClick={() => handleDownloadReport("pdf")}
          data-testid="export-options"
        >
          Expense report and receipts (.pdf)
        </Button>
        <Button
          size="sm"
          variant="ghost"
          color="neutral"
          onClick={() => handleDownloadReport("csv")}
          data-testid="export-options"
        >
          Expense report (.csv)
        </Button>
        <Button
          size="sm"
          variant="ghost"
          color="neutral"
          onClick={() => handleDownloadReport("zip")}
          data-testid="export-options"
        >
          Receipt image (.zip)
        </Button>
      </div>
    </div>
  );
};

export default ExportReportButton;
