import styles from "pages/company-settings/components/Steps.module.css";

type Step = {
  title: string;
  description: () => JSX.Element;
};

type StepsProps = {
  steps: Step[];
};

const { list, title, item, timelineNotch, content } = styles;

function Steps({ steps }: StepsProps) {
  return (
    <div className={list}>
      {steps.map((step, index) => (
        <div key={index} className={item}>
          <div className={timelineNotch}>&#9675;</div>
          <div className={content}>
            <h2 className={title}>{step.title}</h2>
            <p>{step.description()}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Steps;
