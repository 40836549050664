import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSwitchCompaniesMutation } from "types/generated/operations";
import styles from "./multi-member-navigation-validator.module.css";
import { Page404 } from "pages";

const { overlay, overlayHidden } = styles;

const MultiMemberNavigationValidator: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [switchCompanies] = useSwitchCompaniesMutation();
  const [isSwitchingCompanySuccessfully, setIsSwitchingCompanySuccessfully] =
    useState(true);
  const [isSwitchingCompanyOnAppInit, setIsSwitchingCompanyOnAppInit] =
    useState(false);
  const [isSwitchingCompanyOnTabSwitch, setIsSwitchingCompanyOnTabSwitch] =
    useState(false);
  const { companyUuid, companyCanonicalId } = useParams();

  useEffect(() => {
    const switchCompanyOnAppInit = async () => {
      setIsSwitchingCompanyOnAppInit(true);
      try {
        await switchCompanies({
          variables: {
            targetCompanyCanonicalId: companyCanonicalId,
            targetCompanyUuid: companyUuid,
          },
        });
      } catch (error) {
        setIsSwitchingCompanySuccessfully(false);
      }
      setIsSwitchingCompanyOnAppInit(false);
    };

    switchCompanyOnAppInit();
  }, [companyCanonicalId, companyUuid, switchCompanies]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    const switchCompanyOnTabSwitch = async () => {
      if (!document.hidden) {
        setIsSwitchingCompanyOnTabSwitch(true);
        timeoutId = setTimeout(async () => {
          try {
            await switchCompanies({
              variables: {
                targetCompanyCanonicalId: companyCanonicalId,
                targetCompanyUuid: companyUuid,
              },
            });
          } catch (error) {
            setIsSwitchingCompanySuccessfully(true);
          }
          setIsSwitchingCompanyOnTabSwitch(false);
        }, 300);
      } else if (document.hidden && timeoutId) {
        clearTimeout(timeoutId);
      }
    };

    document.addEventListener("visibilitychange", switchCompanyOnTabSwitch);

    return () => {
      document.removeEventListener(
        "visibilitychange",
        switchCompanyOnTabSwitch
      );
    };
  }, [companyCanonicalId, companyUuid, switchCompanies]);

  if (isSwitchingCompanyOnAppInit) {
    return <></>;
  }

  if (!isSwitchingCompanySuccessfully) {
    return <Page404 />;
  }

  const overlayClasses = [overlay];
  if (!isSwitchingCompanyOnTabSwitch) {
    overlayClasses.push(overlayHidden);
  }

  return (
    <div>
      <span className={overlayClasses.join(" ")} data-testid="overlay" />
      {children}
    </div>
  );
};

export default MultiMemberNavigationValidator;
