import { useEffect, useRef, useState } from "react";

export const useAutoSave = (
  autoSaveFn: () => Promise<void> | undefined,
  timeout: number
) => {
  const timerIdRef: { current: NodeJS.Timeout | null } = useRef(null);
  const [isPollingEnabled, setIsPollingEnabled] = useState(true);

  useEffect(() => {
    const pollingCallback = async () => {
      try {
        await autoSaveFn();
      } catch (e) {
        console.error(e);
        setIsPollingEnabled(false);
      }
    };

    const startPolling = () =>
      (timerIdRef.current = setInterval(pollingCallback, timeout));
    const stopPolling = () =>
      clearInterval(timerIdRef.current as NodeJS.Timeout);

    if (isPollingEnabled) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [autoSaveFn, isPollingEnabled, timeout]);
};
