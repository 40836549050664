import React from "react";

export type DisconnectedPanelLegalInfoProps = {
  companyName: string | undefined;
  partnerName: string | undefined;
  customContinue?: JSX.Element;
};

export const DisconnectedPanelLegalInfoSending: React.FC<
  DisconnectedPanelLegalInfoProps
> = ({ companyName, partnerName }) => (
  <div>
    By setting up the integration with {partnerName}, on behalf of {companyName}
    , you acknowledge and agree that Justworks will send certain {companyName}{" "}
    data, including personal information, to {partnerName}, and that{" "}
    {companyName} is responsible for obtaining any consent required from such
    data subjects.
  </div>
);

export const DisconnectedPanelLegalInfoReceiving: React.FC<
  DisconnectedPanelLegalInfoProps
> = ({ companyName, partnerName }) => (
  <div>
    By setting up the integration with {partnerName}, on behalf of {companyName}
    , you acknowledge and agree that Justworks will receive certain{" "}
    {companyName} data, including personal information, from {partnerName}, and
    that {companyName} is responsible for obtaining any consent required from
    such data subjects.
  </div>
);

export const DisconnectedPanelLegalInfoSharing: React.FC<
  DisconnectedPanelLegalInfoProps
> = ({ companyName, partnerName, customContinue }) => (
  <div>
    By integrating with {partnerName}, you agree that Justworks will share
    certain {companyName} data, including personal information, with{" "}
    {partnerName}. {companyName} is responsible for obtaining any required
    consent from data subjects.
    <br />
    <br />
    {customContinue || <> Continue to {partnerName} to get started.</>}
  </div>
);

export const genDisconnectedPanelLegalInfoSharing: (
  customContinue: DisconnectedPanelLegalInfoProps["customContinue"]
) => typeof DisconnectedPanelLegalInfoSharing =
  (customContinue) =>
  ({ companyName, partnerName }: DisconnectedPanelLegalInfoProps) =>
    (
      <DisconnectedPanelLegalInfoSharing
        companyName={companyName}
        partnerName={partnerName}
        customContinue={customContinue}
      />
    );
