import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Button, PageHeader, Box } from "@justworkshr/milo-core";
import { buildWebPath } from "lib/resource-finder";
import { WAIVER_EDIT_URL, COI_EDIT_URL } from "../constants";

import styles from "./landing-page.module.css";

const {
  header,
  getStartedWaiver,
  getStartedCoi,
  documentSelect,
  docBox,
  boxText,
  boxHeader,
  waiverContainer,
  coiContainer,
  boxParagraph,
} = styles;

export default function LandingPage(): ReactElement {
  return (
    <div>
      <div className="milo--grid">
        <div className={header}>
          <PageHeader
            title="Waiver and Certificate Requests"
            linkPrevious={<a href={buildWebPath("admin/documents")}>Back</a>}
          >
            You may need specific documents in order for your employees to
            perform their job duties at another jobsite. Use the options below
            to request the appropriate waiver or certificate.
          </PageHeader>
        </div>
      </div>
      <hr />
      <div className="milo--grid">
        <div className={documentSelect}>
          <div className={docBox}>
            <Box
              border={{
                borderColor: "neutralSubtle",
                borderWidth: "sm",
                borderRadius: "md",
              }}
              padding="lg"
            >
              <div className={boxText}>
                <h2 className={boxHeader}>Waiver of Subrogation</h2>
                <div className={waiverContainer}>
                  <p>
                    A waiver of subrogation may be requested by your vendor,
                    client, or another party as part of your insurance
                    verification requirements for workers&apos; compensation
                    insurance.
                  </p>
                  <Button
                    className={getStartedWaiver}
                    as={Link}
                    to={WAIVER_EDIT_URL}
                    variant="outlined"
                  >
                    Get started
                  </Button>
                </div>
              </div>
            </Box>
          </div>
          <div className={docBox}>
            <Box
              border={{
                borderColor: "neutralSubtle",
                borderWidth: "sm",
                borderRadius: "md",
              }}
              padding="lg"
            >
              <div className={boxText}>
                <h2 className={boxHeader}>Certificate of Insurance</h2>
                <div className={coiContainer}>
                  <p className={boxParagraph}>
                    A certificate of insurance (COI) provides proof of workers'
                    compensation insurance.
                  </p>
                  <Button
                    className={getStartedCoi}
                    as={Link}
                    to={COI_EDIT_URL}
                    variant="outlined"
                  >
                    Get started
                  </Button>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
