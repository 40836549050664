import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useFirmsPageQueries, useFormError } from "../../helpers";
import { expandPermissions } from "../../../../helpers";
import { PageHeader, Spinner, Alert, Button } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import { Form, Formik } from "formik";
import {
  FormPermissionsAlert,
  FormStatusAlert,
  PermissionGroups,
} from "pages/firms/components";
import * as Yup from "yup";
import { datadogRum } from "@datadog/browser-rum";
import styles from "./EditFirmPage.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useUpdatePermissionsMutation } from "types/generated/operations";

const { editFirmPage } = styles;

export default function EditFirmPage(): ReactElement {
  const navigate = useNavigate();

  const { data, loading, error } = useFirmsPageQueries();
  const { scrollToError, formErrorRef } = useFormError();
  const [updatePermissions] = useUpdatePermissionsMutation();

  const firm = data?.firm;
  const companyUuid = data?.authenticatedMember?.company?.uuid;
  const { t } = useTranslation();

  async function submit(
    values: { permissions: string[] },
    { setStatus }: { setStatus: (status: string) => void }
  ) {
    if (!firm || !companyUuid) return;

    try {
      await updatePermissions({
        variables: {
          companyUuid,
          firmUuid: firm.uuid,
          permissions: expandPermissions(values.permissions),
        },
      });
      navigate("/firms");
    } catch (err) {
      datadogRum.addError(err);
      setStatus("There was an error saving your changes, try again.");
      scrollToError();
    }
  }

  function EditFirm({ children }: { children: ReactElement }) {
    return (
      <div className={editFirmPage}>
        <PageHeader title={t("Edit firm permissions")}>{firm?.name}</PageHeader>
        {children}
      </div>
    );
  }

  if (loading || !firm) {
    return (
      <EditFirm>
        <Spinner />
      </EditFirm>
    );
  }

  if (error) {
    return (
      <EditFirm>
        <Alert color="destructive">
          {t(
            "We were unable to load this firm’s information. Please try again."
          )}
        </Alert>
      </EditFirm>
    );
  }

  return (
    <EditFirm>
      <div data-testid={`permission-modal-${firm.uuid}`}>
        <Formik
          enableReinitialize
          validationSchema={Yup.object().shape({
            permissions: Yup.array()
              .of(Yup.string())
              .min(1, "You need to select at least one permission"),
          })}
          initialValues={{ permissions: firm.permissions ?? [] }}
          onSubmit={submit}
        >
          {({ validateForm, isSubmitting }) => {
            return (
              <Form>
                <div ref={formErrorRef}>
                  <FormStatusAlert />
                  <FormPermissionsAlert />
                </div>
                <PermissionGroups />
                <ActionFooter
                  actions={[
                    <Button
                      variant="ghost"
                      as={Link}
                      to="/firms"
                      key="cancel-permissions"
                    >
                      {t("Cancel")}
                    </Button>,
                    <Button
                      key="save-permissions"
                      type="submit"
                      loading={isSubmitting}
                      onClick={async (event: MouseEvent) => {
                        const errors = await validateForm();
                        if (errors.permissions?.length ?? 0 > 0) {
                          event.preventDefault();
                          scrollToError();
                        }
                      }}
                    >
                      {t("Save")}
                    </Button>,
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </EditFirm>
  );
}
