import { useEffect, useState } from "react";
import { DEFAULT_PMT_INTEGRATION_PARAMS } from "../integration-marketplace/constants";
import { useNavigate } from "react-router-dom";

export const setPmtIntegrationVariables = (
  integrationName?: string,
  instanceKeys?: string[],
  limit?: number
) => {
  const options = {
    ...DEFAULT_PMT_INTEGRATION_PARAMS,
  };

  return {
    integration_uuid: options.integration_uuid,
    integration_status: options.integration_status,
    integration_name: integrationName
      ? integrationName
      : options.integration_name,
    integration_instance_status: options.integration_instance_status,
    integration_instance_keys: instanceKeys
      ? instanceKeys
      : options.integration_instance_keys,
    limit: limit ? limit : options.limit, // limit number of instances per key to return
  };
};

export const useAuthSuccessParam = (currentUrl: string) => {
  const navigate = useNavigate();
  const [authSuccess, setAuthSuccess] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    const params = new URLSearchParams(currentUrl.split("?")[1]);
    const authSuccessParam = params.get("auth_success");
    if (authSuccessParam) {
      setAuthSuccess(authSuccessParam === "true");
      params.delete("auth_success");
      params.delete("error");
      navigate({ search: params.toString() }, { replace: true });
    }
  }, [currentUrl, navigate]);

  return authSuccess;
};
