import { BonusTypes, PaymentTypes } from "./BonusTypeInputRow.types";

export const BONUS_TYPE_OPTIONS = {
  [BonusTypes.DISCRETIONARY]: {
    label: "Discretionary",
    description:
      "A bonus that is not announced, is unexpected, and is more like a gift.",
  },
  [BonusTypes.NON_DISCRETIONARY]: {
    label: "Non-discretionary",
    description: "A bonus that’s required because of an agreement or contract.",
  },
  [BonusTypes.FLAT_SUM]: {
    label: "Flat sum",
    description:
      "A non-discretionary bonus for California employees that is not tied to hours worked.",
  },
};

export const PAYMENT_TYPE_OPTIONS = {
  [PaymentTypes.GROSS]: {
    label: "Gross pay",
    description: "Enter the full bonus amount including taxes.",
  },
  [PaymentTypes.NET]: {
    label: "Net pay",
    description: "Enter the bonus amount with taxes taken out.",
  },
};

export const BONUS_TYPE_INPUT_ROW_COPY = {
  bonus_type: "Bonus type",
  is_net_pay: "Payment type",
  is_net_pay_message:
    "Choosing net pay will automatically withhold federal tax at a flat 22% tax rate and will not apply deductions. Select “Gross Pay” if you would like to change these preferences.",
};
