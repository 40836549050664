import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { useFormikContext } from "formik";
import { TextInput } from "@justworkshr/milo-form";
import { SystemIcon } from "@justworkshr/milo-icons";
import parsePhoneNumber, {
  type CountryCode,
  getCountryCallingCode,
} from "libphonenumber-js";

import styles from "./IntlPhoneNumberInput.module.css";
import { SupportedCountriesContext } from "./contexts/supportedCountriesContext";
import { useTranslation } from "react-i18next";

type Props = {
  id?: string;
  className?: string;
  name: string;
  onChange: (event: React.ChangeEvent) => void;
  onBlur: (event: React.FocusEvent) => void;
  value: string;
  countryCode?: string;
};

const IntlPhoneNumberInput = ({
  id,
  className,
  name,
  onBlur,
  countryCode = "CA",
  value,
}: Props) => {
  const { t } = useTranslation();
  const { supportedCountriesData } = useContext(SupportedCountriesContext);
  const supportedCountries = useMemo(
    () =>
      Object.values(supportedCountriesData).map((country) => {
        const phoneCountryCode = `+${getCountryCallingCode(
          country.code as CountryCode
        )}`;
        return {
          commonName: country.commonName,
          emojiFlag: country.emojiFlag,
          phoneCountryCode,
        };
      }) || [],
    [supportedCountriesData]
  );

  const [countryPhoneCode, setCountryPhoneCode] = useState<string>(
    parsePhoneNumber(value)?.countryCallingCode
      ? `+${parsePhoneNumber(value)?.countryCallingCode}`
      : `+${getCountryCallingCode(countryCode as CountryCode)}`
  );
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [flag, setFlag] = useState<string>("");
  const { setFieldValue, setFieldTouched } = useFormikContext();

  useEffect(() => {
    if (value && value.length > 0) {
      const phoneNumber = parsePhoneNumber(value);

      if (phoneNumber) {
        // country calling code does not include the plus sign, but it is
        // required for us to parse and validate phone numbers
        setCountryPhoneCode(`+${phoneNumber.countryCallingCode}`);
        setPhoneNumber(phoneNumber.formatNational());
      }
    }
  }, [value]);

  useEffect(() => {
    const strippedPhoneNumber = phoneNumber
      .replace(`${countryPhoneCode}`, "")
      .replace(/\D/g, "");
    const parsedPhoneNumber = parsePhoneNumber(
      `${countryPhoneCode}${strippedPhoneNumber}`
    );
    if (parsedPhoneNumber) {
      setFieldValue(name, parsedPhoneNumber.number, true);
      setPhoneNumber(parsedPhoneNumber.formatNational());
    } else {
      setFieldValue(name, `${countryPhoneCode}${strippedPhoneNumber}`, true);
    }
  }, [name, countryPhoneCode, phoneNumber, setFieldValue]);

  const setFlagByCountryPhoneCode = useCallback(
    (countryNumberCode: string) => {
      const country = supportedCountries.find(
        (country) => country.phoneCountryCode === countryNumberCode
      );

      if (country) {
        setFlag(country.emojiFlag || "");
      }
    },
    [supportedCountries]
  );

  useEffect(() => {
    if (countryCode) {
      const callingCode = `+${getCountryCallingCode(
        countryCode as CountryCode
      )}`;
      setFlagByCountryPhoneCode(callingCode);
      setCountryPhoneCode(callingCode);
    }
  }, [countryCode, setFlagByCountryPhoneCode]);

  useEffect(() => {
    // to show error when switching country phone codes on a pre-populated field
    setFlagByCountryPhoneCode(countryPhoneCode);
    setCountryPhoneCode(countryPhoneCode);
    if (phoneNumber) {
      setFieldTouched(name, true, true);
    }
  }, [
    name,
    phoneNumber,
    countryPhoneCode,
    setFlagByCountryPhoneCode,
    setFieldTouched,
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.currentTarget.value);
  };

  return (
    <div className={className}>
      <div className={styles.countrySelectContainer}>
        <select
          className={styles.countryCodeSelect}
          value={countryPhoneCode}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            setCountryPhoneCode(event.currentTarget.value)
          }
          required
        >
          {supportedCountries.map((country) => {
            return (
              <option
                value={country.phoneCountryCode}
                key={country.phoneCountryCode}
              >
                {`${country.emojiFlag} ${
                  country.commonName ? t(country.commonName) : ""
                } ${country.phoneCountryCode}`}
              </option>
            );
          })}
        </select>

        <div className={styles.countryFlag}>{flag}</div>
        <SystemIcon iconName="chevron-down" />
        <span className={styles.countryCode}>{countryPhoneCode}</span>

        <div className={styles.phoneNumberInput}>
          <TextInput
            id={id}
            name={name}
            value={phoneNumber}
            onBlur={onBlur}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default IntlPhoneNumberInput;
