import React, { ReactElement } from "react";
import styles from "./search-result.module.css";
import { buildWebPath } from "lib/resource-finder";

const { name, avatar, selectedResult, result, match } = styles;

interface SearchResultProps {
  selectedIndex: number;
  resultName: string;
  itemIndex: number;
  uuid: string;
  term: string;
  key: string;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}

function underlineMatch(resultName: string, term: string) {
  let displayParts = Array<JSX.Element | string>();
  const terms = term.toLowerCase().split(" ");
  terms.sort((a, b) => b.length - a.length);

  displayParts = [resultName];

  terms.forEach((term, termIdx) => {
    const newDisplayParts = Array<JSX.Element | string>();
    let isMatch = false;
    displayParts.forEach((part, partIdx) => {
      if (isMatch) {
        return newDisplayParts.push(part);
      }
      if (typeof part !== "string") {
        return newDisplayParts.push(part);
      }
      const termLocation = part.toLowerCase().indexOf(term.toLocaleLowerCase());
      if (termLocation === -1) {
        return newDisplayParts.push(part);
      }
      newDisplayParts.push(part.slice(0, termLocation));
      newDisplayParts.push(
        <span className={match} key={termIdx + partIdx}>
          {part.slice(termLocation, termLocation + term.length)}
        </span>
      );
      newDisplayParts.push(part.slice(termLocation + term.length));
      isMatch = true;
    });
    displayParts = newDisplayParts;
  });
  return displayParts;
}

export default function SearchResult({
  selectedIndex,
  resultName,
  itemIndex,
  uuid,
  term,
  setSelectedIndex,
}: SearchResultProps): ReactElement {
  const selectedStateClass =
    selectedIndex === itemIndex ? selectedResult : result;
  const firstInitial = resultName.charAt(0);
  const lastInitial = resultName.charAt(resultName.indexOf(" ") + 1);
  const initials = `${firstInitial}${lastInitial}`;
  const formattedResultName = !term.replace(/\s/g, "").length
    ? resultName
    : underlineMatch(resultName, term);

  function handleOnClick(): void {
    setSelectedIndex(itemIndex);
    window.location.assign(buildWebPath(`/members/${uuid}`));
  }

  return (
    <li
      className={selectedStateClass}
      title="result"
      key={uuid}
      onClick={handleOnClick}
    >
      <span key={`avatar${uuid}`} className={avatar}>
        {initials}
      </span>
      <span key={`name${uuid}`} className={name}>
        {formattedResultName}
      </span>
    </li>
  );
}
