import styles from "./ProjectsPage.module.css";
import { Card, Button } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import { useProjectsStore } from "pages/expenses/store";
import { useEffect, useMemo, useState } from "react";
import {
  useGetProjectsQuery,
  UpdateProjectsMutationVariables,
} from "types/generated/operations";

import { ExpenseProject } from "types/Expenses";
import {
  Loading,
  Notice,
  NoticeTypes,
  SettingsTable,
} from "pages/expenses/components";

import { NO_PROJECTS_TEXT } from "./ProjectsPage.constants";
import {
  ProjectDialog,
  ProjectTableRow,
  CancelProjectDialog,
} from "./components";
import { useUpdateProjects } from "pages/expenses/hooks";
import { formatProjectUpdates, hasProjectsUpdated } from "./ProjectsPage.utils";

const {
  ProjectsPageWrapper,
  ProjectsPageCardWrapper,
  ProjectsActionFooterWrapper,
} = styles;

export const ProjectsPage = () => {
  const { loading: isProjectsLoading, data } = useGetProjectsQuery();

  const {
    projects: { projects },
    setProjects,
    setActiveProject,
  } = useProjectsStore();

  const { updateProjects, isUpdateProjectsLoading } = useUpdateProjects(
    projects.length === 0
  );

  const hasProjects = projects.length > 0;
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [apiProjects, setApiProjects] = useState<ExpenseProject[]>([]);
  const [showCancelProjectModal, setShowCancelProjectModal] = useState(false);

  const isProjectsUpdated = useMemo(
    () => hasProjectsUpdated(projects, apiProjects),
    [projects, apiProjects]
  );

  const isSubmitButtonDisabled =
    projects.length === 0 || isUpdateProjectsLoading || !isProjectsUpdated;

  useEffect(() => {
    if (!isProjectsLoading && data?.expenseManagement?.projects) {
      setProjects({ projects: data.expenseManagement.projects } as {
        projects: ExpenseProject[];
      });

      setApiProjects(data.expenseManagement.projects as ExpenseProject[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProjectsLoading, data]);

  const handleProjectModal = () =>
    setShowProjectModal((prevState) => !prevState);

  const handleCancelProjectModal = () =>
    setShowCancelProjectModal((prevState) => !prevState);

  const handleCancel = () => {
    setActiveProject({ activeProject: null });
    handleCancelProjectModal();
  };

  const variables = useMemo(() => {
    return {
      projectUpdates: formatProjectUpdates(projects),
    };
  }, [projects]) as UpdateProjectsMutationVariables;

  const renderActionFooter = () => {
    return (
      <ActionFooter
        className={ProjectsActionFooterWrapper}
        actions={[
          <Button
            type="button"
            variant="ghost"
            key="cancel-button"
            onClick={handleCancel}
            data-testid="cancel-button"
          >
            Cancel
          </Button>,
          <Button
            type="button"
            key="submit-button"
            loading={isProjectsLoading}
            onClick={() => {
              updateProjects({ variables });
            }}
            disabled={isSubmitButtonDisabled}
            data-testid="submit-button"
          >
            Submit
          </Button>,
        ]}
      />
    );
  };

  const renderProjects = () => {
    if (!hasProjects) {
      return <Notice type={NoticeTypes.INFO}>{NO_PROJECTS_TEXT}</Notice>;
    }

    return (
      <SettingsTable headers={["Active", "Project name", ""]}>
        {projects.map((project, index) => (
          <ProjectTableRow
            project={project}
            key={`project_${project.uuid}_${index}`}
            handleProjectModal={handleProjectModal}
          />
        ))}
      </SettingsTable>
    );
  };

  if (isProjectsLoading) {
    return <Loading />;
  }

  return (
    <div className={ProjectsPageWrapper}>
      <Card
        title="Projects"
        actions={[
          <Button
            variant="ghost"
            leftIcon="plus-circle"
            onClick={handleProjectModal}
            data-testid="add-project-button"
          >
            Add a project
          </Button>,
        ]}
        className={ProjectsPageCardWrapper}
      >
        {renderProjects()}
      </Card>
      {renderActionFooter()}
      <CancelProjectDialog
        showCancelProjectModal={showCancelProjectModal}
        handleCancelProjectModal={handleCancelProjectModal}
      />
      <ProjectDialog
        showProjectModal={showProjectModal}
        handleProjectModal={handleProjectModal}
      />
    </div>
  );
};

export default ProjectsPage;
