import {
  Divider,
  Step,
  Stepper,
  UnorderedList,
  UnorderedListItem,
} from "@justworkshr/milo-core";
import benefitsClasses from "pages/benefits/benefits.module.css";
import clsx from "lib/styling-utils/clsx";

const OverviewTab = () => {
  return (
    <div>
      <h2 className={benefitsClasses.benefitsHeader}>
        Your 401(k) is moving over to Empower
      </h2>
      <p className={benefitsClasses.benefitsParagraph}>
        Starting June 28, initiate the transfer of the Justworks Retirement
        Savings Plan from Slavic to Empower. Your account balance, contribution
        elections, beneficiary elections, loans, and distribution arrangements
        will begin to transfer automatically.
        <br />
        <br />
        The transition is expected to be completed around the mid to end of
        July. Beginning June 3, 2024, certain transactions will be unavailable
        as Slavic prepares for this transition. Keep reading for more details.
      </p>

      <h3 className={benefitsClasses.benefitsHeader}>Who is Empower?</h3>
      <p className={benefitsClasses.benefitsParagraph}>
        Empower is an award-winning provider with extensive experience with PEOs
        like Justworks. They’re known for their advanced technology, efficient
        tools, and customer service.
      </p>

      <h3 className={benefitsClasses.benefitsHeader}>
        Why did you choose Empower?
      </h3>
      <p className={benefitsClasses.benefitsParagraph}>
        By switching to Empower, we’ll be able to offer a more user-friendly and
        comprehensive 401(k) experience with a robust mobile app that makes it
        easy to stay on top of your financial planning.  Like Justworks, Empower
        is driven by a customer-centric mission that offers increased levels of
        dedicated customer support. Their enhanced financial planning resources,
        mobile app, and simplified plan management tools will help you plan for
        your financial future.
      </p>

      <h3 className={benefitsClasses.benefitsHeader}>
        What are some highlights of working with Empower?
      </h3>
      <UnorderedList>
        <UnorderedListItem>
          Enhanced and simplified financial planning resources–all supported by
          Empower financial professionals dedicated to helping you improve your
          financial health.
        </UnorderedListItem>
        <UnorderedListItem>
          Improved accessibility with the Empower app and chat through on your
          computer, tablet, or phone.
        </UnorderedListItem>
        <UnorderedListItem>
          Continued access to a broad range of investment choices to help you
          build a diversified portfolio, including access to Empower Advisory
          Services.
        </UnorderedListItem>
        <UnorderedListItem>
          Access to the Empower Customer Care Center-even on Saturdays.
        </UnorderedListItem>
      </UnorderedList>

      <Divider />

      <h3 className={benefitsClasses.benefitsHeader}>What happens next?</h3>
      <p className={benefitsClasses.benefitsParagraph}>
        Starting in May, we’ll send out more information to help you better
        understand what the transition will look like.
      </p>
      <h3 className={benefitsClasses.benefitsHeader}>Timeline</h3>
      <Stepper orientation="vertical">
        <Step
          stepNumber={1}
          status="pending"
          title="June 3: Final day in Slavic"
          subtitle={
            (
              <p className={clsx(benefitsClasses.benefitsParagraph)}>
                This is the last day to request a new loan, repayment of initial
                new loans, and distribution requests with Slavic401k.
              </p>
            ) as unknown as string
          }
        />
        <Step
          stepNumber={2}
          status="pending"
          title="June 18: Blackout period starts"
          subtitle={
            (
              <p className={clsx(benefitsClasses.benefitsParagraph)}>
                Starting June 18th, there will be a blackout period where you
                won’t be able to access your account. This is also the last day
                to make any changes to your deferral elections. This is a
                standard part of the process when switching providers. 401k
                contributions will still be made so your retirement plan can
                stay on track.
              </p>
            ) as unknown as string
          }
        />
        <Step
          stepNumber={3}
          status="pending"
          title="Mid to end July: New plans go live"
          subtitle={
            (
              <p className={clsx(benefitsClasses.benefitsParagraph)}>
                The new plans will go live in Empower around the middle to the
                end of July. You’ll be able to set up your new account, and
                resume all plan account activity that was unavailable during the
                restricted period.
              </p>
            ) as unknown as string
          }
        />
      </Stepper>
    </div>
  );
};

export default OverviewTab;
