import production from "./environments/production-settings";
import defaultSettings from "./environments/default-settings";
import sandbox from "./environments/sandbox-settings";
import staging from "./environments/staging-settings";
import development from "./environments/development-settings";
import test from "./environments/test-settings";
import { FLAG_NAMES } from "./flag-constants";
import { SettingsConfig } from "./settings-type";
import { importSettings, flagIsEnabled } from "./feature-flags";
import { Environment } from "../environment-finder";

const environments: Record<Environment, Partial<SettingsConfig>> = {
  production,
  sandbox,
  staging,
  development,
  test,
};

importSettings(defaultSettings, environments);

export { flagIsEnabled, FLAG_NAMES };
