import { ROUTES } from "pages/expenses/constants";
import { getRoute } from "pages/expenses/utils";

export const getSelectedSubmitterTab = (pathname: string) => {
  const route = getRoute(pathname);

  switch (route) {
    case ROUTES.HOME:
      return 0;
    case ROUTES.EXPENSE_LIBRARY:
      return 1;
    default:
      return 0;
  }
};
