import { useTranslation } from "react-i18next";
import { EOR_PROFILE_PATH } from "pages/employer-of-record/routes";
import { SUBPATHS } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import RequestChangesAction from "../RequestChangesAction/RequestChangesAction";
import { Section } from "../EditableProfile/EditProfile";
import CardAction from "../CardAction/CardAction";
import { useGetFeatureFlag } from "lib/launch-darkly";

type Props = {
  target: Section;
  enabled: boolean;
};

export default function EditAction({ target, enabled }: Props) {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getFeatureFlag } = useGetFeatureFlag();

  const isEditingEnabled = getFeatureFlag("release-admin-edit-eor-ee-profile");

  function navigateToEditPage() {
    navigate(
      `/employer-of-record${EOR_PROFILE_PATH}/${id}/${SUBPATHS.EDIT}#${target}`
    );
  }

  if (typeof isEditingEnabled === "boolean" && isEditingEnabled && enabled) {
    return (
      <CardAction name={t("Edit")} icon="edit" onClick={navigateToEditPage} />
    );
  } else {
    return <RequestChangesAction />;
  }
}
