import {
  compact as originalCompact,
  format as originalFormat,
  name as originalName,
  localName as originalLocalName,
  abbreviation as originalAbbreviation,
} from "stdnum/src/pt/nif";
import { ValidateReturn } from "stdnum/lib/cjs/types";
import { ValidationError } from "stdnum/src/exceptions";
import * as exceptions from "stdnum/src/exceptions";
import { strings, weightedSum } from "stdnum/src/util";

function clean(input: string): ReturnType<typeof strings.cleanUnicode> {
  return strings.cleanUnicode(input, " -.", "PT");
}

const ptTaxIdValidator = {
  name: originalName,
  localName: originalLocalName,
  abbreviation: originalAbbreviation,

  compact(input: string): string {
    return originalCompact(input);
  },

  format(input: string): string {
    return originalFormat(input);
  },

  validate(input: string): ValidateReturn {
    const [value, error] = clean(input);

    if (error) {
      return { isValid: false, error };
    }
    if (value.length !== 9) {
      return { isValid: false, error: new exceptions.InvalidLength() };
    }
    if (!strings.isdigits(value) || value[0] === "0") {
      return { isValid: false, error: new exceptions.InvalidFormat() };
    }

    const [front, check] = strings.splitAt(value, -1);

    const sum = weightedSum(front, {
      weights: [9, 8, 7, 6, 5, 4, 3, 2, 1],
      modulus: 11,
    });

    if (String(((11 - sum) % 11) % 10) !== check) {
      return { isValid: false, error: new exceptions.InvalidChecksum() };
    }

    // Throw error if NIF is from a company
    // https://www.nif.pt/nif-das-empresas/
    if ("56789".includes(value[0])) {
      return {
        isValid: false,
        error: new ValidationError("NIF is invalid"),
      };
    }

    return {
      isValid: true,
      compact: value,
      isIndividual: true,
      isCompany: false,
    };
  },
};

export { ptTaxIdValidator };
