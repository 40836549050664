import { ReactElement } from "react";
import styles from "./incident-information-form.module.css";
import SharedForm from "../../shared/shared-form-outline";
import { FormIncident, PageData, ClaimFormProps } from "../../../types";
import { Formik, Form } from "formik";
import {
  CheckboxInput,
  TextInput,
  TextArea,
  RadioButtonGroup,
  RadioButton,
} from "@justworkshr/milo-form";
import FormContinue from "../../shared/form-continue";
import { validationFormSchema } from "./utils";
import { ALL_STATES } from "../../constants";

import StateSelect from "pages/workers-compensation/document-request/components/state-select";
import { FormikFormField } from "lib/formik/FormikFormField";

const {
  incidentSection,
  firstSection,
  contentWithBar,
  verticalBar,
  contentText,
  fatalityContent,
  textInput,
  spanInfo,
  secondSection,
  thirdSection,
  addressLines,
  wrapCity,
  city,
  state,
  postalCode,
  fourthSection,
  fifthSection,
  sixthSection,
  radioButtonGroup,
  btnContinue,
  injuryInput,
} = styles;

export default function IncidentInformationForm({
  handleErrorMessage,
  submitDraft,
  setPageData,
  goForward,
  goBack,
  exitForm,
  pageData,
  currentPageNumber,
  name,
}: ClaimFormProps & {
  setPageData: (e: FormIncident) => void;
  submitDraft: (section: FormIncident, total: PageData) => void;
}): ReactElement {
  const formData = pageData.incidentInformation;

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationFormSchema}
      onSubmit={(values) => {
        submitDraft(values, pageData);
        setPageData(values);
        goForward();
      }}
    >
      {({ values, setFieldValue }) => (
        <SharedForm
          handleErrorMessage={handleErrorMessage}
          currentPageNumber={currentPageNumber}
          name={name}
          key="exit-response-to-the-injury"
          exitFn={() => {
            submitDraft(values, pageData);
            exitForm();
          }}
        >
          <Form data-testid="response-to-the-injury-form">
            <div className={incidentSection}>
              <h2>Tell us about the incident</h2>
              <div className={styles.hrBar} />
              <div className={firstSection}>
                <div className={fatalityContent}>
                  <div className={injuryInput}>
                    <CheckboxInput
                      label="Select if injury was fatal"
                      name="fatality"
                      checked={values.fatality}
                      value="fatality"
                      onChange={(event) => {
                        const newFatalityState = event.target.checked;

                        setFieldValue("fatality", newFatalityState);

                        if (!newFatalityState) {
                          setFieldValue("oshaNumber", undefined);
                        }
                      }}
                    />
                  </div>
                </div>

                {values.fatality && (
                  <div className={contentWithBar}>
                    <div className={verticalBar} />

                    <div className={contentText}>
                      <FormikFormField
                        label="For fatal injuries, provide the OSHA number"
                        name="oshaNumber"
                        required
                      >
                        <TextInput
                          name="oshaNumber"
                          value={values.oshaNumber}
                        />
                      </FormikFormField>
                    </div>
                  </div>
                )}
              </div>

              <div className={secondSection}>
                <FormikFormField
                  label="How did the injury occur?"
                  name="incidentDescription"
                  required
                >
                  <div className={spanInfo}>
                    <span>
                      Provide details of the incident as reported by the injured
                      employee. Please be as descriptive as possible.
                    </span>
                    <TextArea
                      className={textInput}
                      style={{ marginTop: "15px", height: "75px" }}
                      name="incidentDescription"
                      value={values.incidentDescription}
                    />
                  </div>
                </FormikFormField>
              </div>

              <div className={thirdSection}>
                <h2>Where did the injury occur?</h2>
                <div className={styles.hrBar} />
                <div className={addressLines}>
                  <FormikFormField
                    label="Address Line 1"
                    name="address.street1"
                    required
                  >
                    <TextInput
                      name="address.street1"
                      value={values.address.street1}
                    />
                  </FormikFormField>

                  <FormikFormField
                    label="Address Line 2"
                    name="address.street2"
                  >
                    <TextInput name="address.street2" />
                  </FormikFormField>
                  <div className={wrapCity}>
                    <div className={city}>
                      <FormikFormField
                        label="City"
                        name="address.city"
                        required
                      >
                        <TextInput name="address.city" />
                      </FormikFormField>
                    </div>
                    <div className={state}>
                      <FormikFormField
                        label="State"
                        name="address.state"
                        required
                      >
                        <StateSelect
                          selectedState={values.address.state}
                          handleEventChange={(e) =>
                            setFieldValue("address.state", e.target.value)
                          }
                          stateList={ALL_STATES}
                        />
                      </FormikFormField>
                    </div>
                    <div className={postalCode}>
                      <FormikFormField
                        label="ZIP"
                        name="address.postalCode"
                        required
                      >
                        <TextInput name="address.postalCode" />
                      </FormikFormField>
                    </div>
                  </div>
                </div>
              </div>

              <div className={fourthSection}>
                <FormikFormField
                  label="What part of the body was affected by the injury?"
                  name="injuryBodyPart"
                  required
                >
                  <div className={spanInfo}>
                    <span>Describe the body part/area that was affected</span>
                    <TextArea
                      className={textInput}
                      style={{ marginTop: "15px", height: "100px" }}
                      name="injuryBodyPart"
                      value={values.injuryBodyPart}
                    />
                  </div>
                </FormikFormField>
              </div>

              <div className={fifthSection}>
                <FormikFormField
                  label="Describe the injury"
                  name="bodyPartDescription"
                  required
                >
                  <div className={spanInfo}>
                    <span>
                      Describe the injury in as much detail as possible as
                      reported by the employee.
                    </span>
                    <TextArea
                      className={textInput}
                      style={{ marginTop: "15px", height: "100px" }}
                      name="bodyPartDescription"
                      value={values.bodyPartDescription}
                    />
                  </div>
                </FormikFormField>
              </div>

              <div className={sixthSection}>
                <FormikFormField
                  label="Were any safeguards in use at the time of injury?"
                  name="safeGuard"
                  required
                >
                  <div className={radioButtonGroup}>
                    <RadioButtonGroup
                      name="safeGuard"
                      value={values.safeGuard}
                      onChange={(e) => {
                        const hasSafeGuard = e.target.value === "true";

                        if (!hasSafeGuard) {
                          setFieldValue("safeGuardList", "");
                        }
                      }}
                    >
                      <RadioButton label="Yes" value="Yes" />
                      <RadioButton label="No" value="No" />
                    </RadioButtonGroup>
                  </div>
                </FormikFormField>

                {values.safeGuard === "Yes" && (
                  <FormikFormField
                    label="Please list the safeguards."
                    name="safeGuardList"
                    required
                  >
                    <TextArea
                      className={textInput}
                      name="safeGuardList"
                      style={{
                        marginBottom: values.safeGuard ? "0px" : undefined,
                        height: "50px",
                      }}
                      value={values.safeGuardList}
                    />
                  </FormikFormField>
                )}
              </div>
              <div className={btnContinue}>
                <FormContinue
                  continueKey="continue-response-to-the-injury"
                  backKey="back-response-to-the-injury"
                  backFn={() => {
                    setPageData(values);
                    goBack();
                  }}
                  alignment="space-between"
                />
              </div>
            </div>
          </Form>
        </SharedForm>
      )}
    </Formik>
  );
}
