import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

export type StepperHelpers = {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  reset: () => void;
  canGoToNextStep: boolean;
  setStep: Dispatch<SetStateAction<number>>;
  currentStep: number;
  stepIndex: undefined | number;
};

type setStepCallbackType = (step: number | ((step: number) => number)) => void;

export function useStepper(
  maxStep: number,
  foundCurrentStep?: number,
  initialStep = 0
): StepperHelpers {
  const [currentStep, setCurrentStep] = useState(initialStep);

  useEffect(() => {
    if (foundCurrentStep) {
      setCurrentStep(foundCurrentStep);
    }
  }, [foundCurrentStep]);

  const canGoToNextStep = useMemo(
    () => currentStep + 1 <= maxStep - 1,
    [currentStep, maxStep]
  );

  const setStep = useCallback<setStepCallbackType>(
    (step) => {
      const newStep = step instanceof Function ? step(currentStep) : step;

      if (newStep >= 0 && newStep <= maxStep) {
        setCurrentStep(newStep);
        return;
      }

      throw new Error("Step not valid");
    },
    [maxStep, currentStep]
  );

  const goToNextStep = useCallback(() => {
    if (canGoToNextStep) {
      setCurrentStep((step) => step + 1);
    }
  }, [canGoToNextStep]);

  const goToPreviousStep = useCallback(() => {
    setCurrentStep((step) => step - 1);
  }, []);

  const reset = useCallback(() => {
    setCurrentStep(0);
  }, []);

  return {
    goToNextStep,
    goToPreviousStep,
    canGoToNextStep,
    setStep,
    reset,
    currentStep,
    stepIndex: undefined,
  };
}
