import Avatar from "app-frame/common/Avatar";
import { useGetUserMenuQuery } from "types/generated/operations";
import UserLinks from "../user-links";
import Dropdown from "app-frame/header/common/dropdown";
import { UserMenuMemberWithCompany } from "types";
import styles from "./user-menu-button.module.css";
import HeaderButton from "app-frame/header/common/header-button";
import { usePanelsControlContext } from "app-frame/header/context/panels-control-context-provider";
import { PanelsControlActionType } from "app-frame/header/context/panels-control-context-provider/types";
import {
  ViewType,
  useViewContext,
} from "app-frame/header/context/view-context-provider";

const { userMenuButton } = styles;

export default function UserMenuButton() {
  const { view } = useViewContext();
  const { state, dispatch } = usePanelsControlContext();
  const { userMenuDropdownShown, userMenuDrawerShown } = state;

  const { data, loading } = useGetUserMenuQuery();
  if (loading || !data) {
    return <></>;
  }
  const companyData = data.authenticatedUser.members;
  const currentMember = data.authenticatedMember;
  const capabilities = currentMember.capabilities;
  const companyId = currentMember.company.uuid;
  const companyList: UserMenuMemberWithCompany[] = [];
  companyData?.forEach((x) => {
    if (x.company.uuid !== companyId) {
      companyList.push(x);
    }
  });

  const dropdownContent = (
    <UserLinks
      uuid={currentMember.uuid}
      csTools={capabilities?.hasAccessToCsTools}
      profileView={capabilities?.hasAccessToProfileView}
      otherCompaniesOfCurrentUser={companyList}
      productsAndCompanies={data.currentUserProductsAndCompanies}
    />
  );

  const buttonContent = (
    <HeaderButton
      customIcon={
        <div data-heap-redact-attributes="src">
          <Avatar
            name={`${currentMember.firstName} ${currentMember.lastName}`}
            photoUrl={
              currentMember?.photoUrl ? currentMember.photoUrl : undefined
            }
            width={36}
            height={36}
          />
        </div>
      }
      testId="user-menu-button"
      showCaret
      active={userMenuDropdownShown}
    />
  );

  const tabletAndDesktopUserMenuButton = (
    <Dropdown
      dropdownName="user links"
      content={dropdownContent}
      className={userMenuButton}
      open={userMenuDropdownShown}
      onOpenChange={(newOpen) => {
        if (newOpen) {
          dispatch({
            type: PanelsControlActionType.OPEN_USER_MENU_DROPDOWN,
          });
        } else {
          dispatch({
            type: PanelsControlActionType.CLOSE_USER_MENU_DROPDOWN,
          });
        }
      }}
      onClickOutside={() => {
        dispatch({
          type: PanelsControlActionType.CLOSE_USER_MENU_DROPDOWN,
        });
      }}
    >
      {buttonContent}
    </Dropdown>
  );

  const mobileUserMenuButton = (
    <div className={userMenuButton}>
      <HeaderButton
        customIcon={
          <div data-heap-redact-attributes="src">
            <Avatar
              name={`${currentMember.firstName} ${currentMember.lastName}`}
              photoUrl={
                currentMember?.photoUrl ? currentMember.photoUrl : undefined
              }
              width={36}
              height={36}
            />
          </div>
        }
        onClick={() => {
          if (userMenuDrawerShown) {
            dispatch({
              type: PanelsControlActionType.CLOSE_USER_MENU_DRAWER,
            });
          } else {
            dispatch({
              type: PanelsControlActionType.OPEN_USER_MENU_DRAWER,
            });
          }
        }}
        testId="user-menu-button"
        showCaret
        active={userMenuDropdownShown}
      />
    </div>
  );

  return view === ViewType.MOBILE
    ? mobileUserMenuButton
    : tabletAndDesktopUserMenuButton;
}
