import { Alert, Button, Card, PageHeader } from "@justworkshr/milo-core";
import {
  ActionFooter,
  Form,
  FormField,
  RadioButton,
  RadioButtonGroup,
  Select,
  SelectOption,
  TextInput,
} from "@justworkshr/milo-form";
import {
  Formik,
  type FormikErrors,
  type FormikProps,
  type FormikValues,
} from "formik";
import { formatDate } from "pages/employer-of-record/employee-profile/utils";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useOnboardingForm from "../../../hooks/useOnboardingForm";
import Layout from "../../../Layout";
import onboardingStyles from "../../../Onboarding.module.css";
import { NATIONALITIES, months } from "../../constants";
import { unwrapDate } from "../../helpers";
import styles from "../../ProfileInfo.module.css";
import { compactTaxId } from "../../taxIdUtils";
import {
  AdditionalFieldsES,
  ProfileInfoFormTypeES as ProfileInfoFormType,
} from "../../types";
import BaseFormFragment, {
  initialValues as baseInitialValues,
  schema as baseSchema,
} from "../base/BaseFormFragment";
import IdentifierNumberFormFragment, {
  initialValues as identifierNumberInitialValues,
  schema as identifierNumberSchema,
} from "../identifier-input/IdentifierNumberFormFragment";
import {
  esEducationalQualificationOptions,
  esMaritalStatusOptions,
  esSexOptions,
  esSsnRegex,
} from "./constants.es";
import ESChildrenDOBArrayField from "./ESChildrenDOBArrayField";

const { dateField, dateOfBirthContainer, inputField } = styles;

const state_key = "profile-info";

const ProfileInfoES: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    memberData: { workCountry },
    form: { submitting, showErrorAlert, errorAlertMessage, onSubmit },
    memberOnboarding: {
      stepNumber,
      redirecting,
      profileInfo,
      idVerificationStatus,
    },
  } = useOnboardingForm(state_key);

  const MONTHS = months();
  const NOW = new Date();

  const isBetween = (num1: number, num2: number, value: number) =>
    value >= num1 && value <= num2;

  const onFormSubmit = (values: FormikValues) => {
    // Compose date and identifier values.
    // Note: I have refactored this from the default form, just for code simplicity.
    const {
      year: DOBYear,
      month: DOBMonth,
      day: DOBDay,
    } = values.profileInfo.dateOfBirth;
    const profileInfoDateOfBirth = `${DOBYear}-${DOBMonth}-${DOBDay}`;

    const {
      year: esYear,
      month: esMonth,
      day: esDay,
    } = values.profileInfo.esDateSinceResidentOfSpain;

    const profileEsDateSinceResidentOfSpain = `${esYear}-${String(
      esMonth
    ).padStart(2, "0")}-${String(esDay).padStart(2, "0")}`;

    const profileInfoIdentifierNumber = compactTaxId(
      workCountry,
      values.profileInfo.identifierNumber
    );

    // Filter out empty values and send the correct date format
    const profileEsChildrensBirthDates =
      values.profileInfo.esChildrensBirthDates
        .filter(Boolean)
        .map((dateStr: string) => formatDate(dateStr, "YYYY-MM-DD"));

    onSubmit({
      profileInfo: {
        ...values.profileInfo,
        dateOfBirth: profileInfoDateOfBirth,
        esDateSinceResidentOfSpain: profileEsDateSinceResidentOfSpain,
        esChildrensBirthDates: profileEsChildrensBirthDates,
        esNumChildren: profileEsChildrensBirthDates.length,
        identifierNumber: profileInfoIdentifierNumber || null,
        confirmIdentifierNumber: undefined,
      },
    });
  };

  /* ES specific fields */
  const schemaES = Yup.object({
    nationality: Yup.string().required("This field is required."),
    esDateSinceResidentOfSpain: Yup.object()
      .shape({
        year: Yup.string(),
        month: Yup.string(),
        day: Yup.string(),
      })
      .test("valid", t("Invalid entry. Please try again."), (date) => {
        const { year, month, day } = date;
        const nowYear = NOW.getUTCFullYear();

        // Return false if any of the fields are empty
        if (!year || !month || !day) {
          return false;
        }

        // Check if the individual date fields are valid
        if (!isBetween(1920, nowYear, parseInt(year))) return false;
        if (!isBetween(1, 12, parseInt(month))) return false;
        if (!isBetween(1, 31, parseInt(day))) return false;

        return true;
      }),
    esSex: Yup.string().required("This field is required."),
    esMaritalStatus: Yup.string().required("This field is required."),
    esEducationalQualification: Yup.string().required(
      "This field is required."
    ),
    esSsn: Yup.string()
      .required("This field is required.")
      .matches(esSsnRegex, t("Must be a valid Social Security Number.")),
    esUnemploymentStatus: Yup.boolean().required("This field is required."),
    esChildrensBirthDates: Yup.array().of(Yup.string()),
    esNie: Yup.string()
      .test("check-nationality", "This field is required.", (value, ctx) =>
        ctx.parent.nationality === "ES" ? true : Boolean(value?.length)
      )
      .matches(/[A-Z]{1}[0-9]{7}[A-Z]{1}/, t("Must be a valid NIE.")),
  });
  const formSchema = Yup.object().shape({
    profileInfo: schemaES
      .concat(baseSchema(t))
      .concat(identifierNumberSchema(t, "ES")),
  });
  // Inferring type from the schema causes issues for esDateSinceResidentOfSpain field
  type FormType = {
    profileInfo: AdditionalFieldsES;
  };
  const initialValuesES: FormType = {
    profileInfo: {
      nationality: "",
      esDateSinceResidentOfSpain: {
        year: "",
        month: "",
        day: "",
      },
      esSex: "",
      esMaritalStatus: "",
      esEducationalQualification: "",
      esSsn: "",
      esUnemploymentStatus: false,
      esChildrensBirthDates: [""],
      esNie: "",
    },
  };

  /* END ES specific fields */

  const getInitialValues = (): ProfileInfoFormType => {
    const initialValues = {
      profileInfo: {
        ...baseInitialValues.profileInfo,
        ...identifierNumberInitialValues.profileInfo,
      },
    };

    const defaultInitialValues = profileInfo || initialValues;

    const mergedValues: ProfileInfoFormType = {
      profileInfo: {
        ...initialValuesES.profileInfo,
        ...defaultInitialValues.profileInfo,
      },
    };

    // there are many problems that could be avoided if we switched to
    // using a date picker component, but for now we'll just unwrap the
    // dates here
    // the new version of the datepicker in Milo 2.0 would require a larger refactor
    if (
      profileInfo &&
      "esDateSinceResidentOfSpain" in profileInfo.profileInfo
    ) {
      mergedValues.profileInfo.esDateSinceResidentOfSpain = unwrapDate(
        String(profileInfo.profileInfo.esDateSinceResidentOfSpain)
      );
    }

    return mergedValues;
  };

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {errorAlertMessage ||
            t("An error occurred while submitting your profile information.")}
        </Alert>

        <PageHeader title="Complete your profile" />

        <Formik
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validationSchema={formSchema}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            setFieldValue,
            submitCount,
            touched,
            values,
          }: FormikProps<ProfileInfoFormType>) => {
            const handleBooleanChange = (
              e: React.ChangeEvent<HTMLInputElement>
            ) => {
              setFieldValue(e.target.name, e.target.value === "true");
            };

            const fieldErrorHandler = (
              name: keyof ProfileInfoFormType["profileInfo"]
            ) => {
              if (!errors.profileInfo || !touched.profileInfo) return;
              if (errors.profileInfo[name] && touched.profileInfo[name]) {
                return errors.profileInfo[name]?.toString();
              }
            };

            const dateSinceResidentOfSpainErrors = (
              values: ProfileInfoFormType,
              errors: FormikErrors<ProfileInfoFormType>
            ): string => {
              const allFieldsFilled: boolean = Object.values(
                values?.profileInfo?.esDateSinceResidentOfSpain
              ).every((value) => value);
              if (
                (allFieldsFilled || submitCount > 0) &&
                errors?.profileInfo?.esDateSinceResidentOfSpain
              ) {
                return errors?.profileInfo
                  ?.esDateSinceResidentOfSpain as string;
              }
              return "";
            };

            return (
              <Form onSubmit={handleSubmit}>
                <Card title={t("Personal information")}>
                  <BaseFormFragment />

                  {/* <!-- ES specific fields --> */}
                  <div className={inputField}>
                    <FormField
                      required
                      name="profileInfo.nationality"
                      label={t("Nationality")}
                      error={fieldErrorHandler("nationality")}
                    >
                      <Select
                        name="profileInfo.nationality"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={String(values.profileInfo.nationality)}
                      >
                        {NATIONALITIES.map((option) => (
                          <SelectOption value={option.value} key={option.value}>
                            {option.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      error={dateSinceResidentOfSpainErrors(values, errors)}
                      label={t("Date since resident of Spain")}
                      required
                    >
                      <div className={dateOfBirthContainer}>
                        <div className={dateField}>
                          <label htmlFor="profileInfo.esDateSinceResidentOfSpain.year">
                            {t("Year")}
                          </label>
                          <TextInput
                            id="profileInfo.esDateSinceResidentOfSpain.year"
                            name="profileInfo.esDateSinceResidentOfSpain.year"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={
                              values.profileInfo.esDateSinceResidentOfSpain.year
                            }
                          />
                        </div>

                        <div className={dateField}>
                          <label htmlFor="profileInfo.esDateSinceResidentOfSpain.month">
                            {t("Month")}
                          </label>
                          <Select
                            id="profileInfo.esDateSinceResidentOfSpain.month"
                            name="profileInfo.esDateSinceResidentOfSpain.month"
                            onChange={handleChange}
                            defaultValue={
                              values.profileInfo.esDateSinceResidentOfSpain
                                .month
                            }
                            // We cannot pass a placeholder and a default value.
                            //   So, if we initially have a value for month, we
                            //   can't send a placeholder
                            placeholder={
                              values.profileInfo.esDateSinceResidentOfSpain
                                .month?.length > 0
                                ? undefined
                                : t("Select month")
                            }
                          >
                            {MONTHS.map((month) => {
                              return (
                                <SelectOption
                                  key={month.value}
                                  value={month.value}
                                >
                                  {month.description}
                                </SelectOption>
                              );
                            })}
                          </Select>
                        </div>

                        <div className={dateField}>
                          <label htmlFor="profileInfo.esDateSinceResidentOfSpain.day">
                            Day
                          </label>
                          <TextInput
                            id="profileInfo.esDateSinceResidentOfSpain.day"
                            name="profileInfo.esDateSinceResidentOfSpain.day"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={
                              values.profileInfo.esDateSinceResidentOfSpain.day
                            }
                          />
                        </div>
                      </div>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="profileInfo.esSex"
                      label={t("Sex")}
                      error={fieldErrorHandler("esSex")}
                    >
                      <Select
                        name="profileInfo.esSex"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={String(values.profileInfo.esSex)}
                        placeholder={
                          values.profileInfo.esSex.length
                            ? undefined
                            : t("Select Sex")
                        }
                      >
                        {esSexOptions.map((option) => (
                          <SelectOption value={option.value} key={option.value}>
                            {option.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="profileInfo.esMaritalStatus"
                      label={t("Marital Status")}
                      error={fieldErrorHandler("esMaritalStatus")}
                    >
                      <Select
                        name="profileInfo.esMaritalStatus"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={String(
                          values.profileInfo.esMaritalStatus
                        )}
                        placeholder={
                          values.profileInfo.esMaritalStatus.length
                            ? undefined
                            : "Select Marital Status"
                        }
                      >
                        {esMaritalStatusOptions.map((option) => (
                          <SelectOption value={option.value} key={option.value}>
                            {option.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="profileInfo.esEducationalQualification"
                      label={t("Educational Qualification")}
                      error={fieldErrorHandler("esEducationalQualification")}
                    >
                      <Select
                        name="profileInfo.esEducationalQualification"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={String(
                          values.profileInfo.esEducationalQualification
                        )}
                        placeholder={
                          values.profileInfo.esEducationalQualification.length
                            ? undefined
                            : t("Select Educational Qualification")
                        }
                      >
                        {esEducationalQualificationOptions.map((option) => (
                          <SelectOption value={option.value} key={option.value}>
                            {option.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="profileInfo.esSsn"
                      label={t("Social Security ID (Número de afiliación)")}
                      error={fieldErrorHandler("esSsn")}
                    >
                      <TextInput
                        name="profileInfo.esSsn"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.profileInfo.esSsn)}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="profileInfo.esUnemploymentStatus"
                      label={t("Unemployment status (Desempleado)")}
                      error={fieldErrorHandler("esUnemploymentStatus")}
                    >
                      <RadioButtonGroup
                        name="profileInfo.esUnemploymentStatus"
                        onChange={handleBooleanChange}
                        onBlur={handleBlur}
                        value={String(values.profileInfo.esUnemploymentStatus)}
                      >
                        <RadioButton value="true" label={t("Yes")} />
                        <RadioButton value="false" label={t("No")} />
                      </RadioButtonGroup>
                    </FormField>
                  </div>

                  <ESChildrenDOBArrayField
                    name="profileInfo.esChildrensBirthDates"
                    label={t("Children's birth dates")}
                  />

                  <div className={inputField}>
                    <FormField
                      required={values.profileInfo.nationality !== "ES"}
                      name="profileInfo.esNie"
                      label={t("NIE (Número de Identificación de Extranjero)")}
                      error={fieldErrorHandler("esNie")}
                    >
                      <TextInput
                        name="profileInfo.esNie"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.profileInfo.esNie)}
                      />
                    </FormField>
                  </div>

                  {/* <!-- END ES specific fields --> */}

                  <IdentifierNumberFormFragment
                    workCountry="ES"
                    required={values.profileInfo.nationality === "ES"}
                  />
                </Card>

                <ActionFooter
                  className={onboardingStyles.footer}
                  actions={[
                    <Button
                      color="brand"
                      key="submit-btn"
                      loading={submitting}
                      type="submit"
                    >
                      {t("Save & continue")}
                    </Button>,
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </Layout>
  );
};

export default ProfileInfoES;
