import { ReactElement } from "react";
import styles from "./review-page.module.css";
import { Formik, Form } from "formik";
import FormContinue from "../../shared/form-continue";
import { PageData, WitnessDetails, ClaimFormProps } from "../../../types";
import SharedForm from "../../shared/shared-form-outline";
import {
  formatPhone,
  handleHourType,
  formatTreatmentGiven,
} from "../../shared/utils";

const { box, titleBox, mainTitle, btnSection, margin } = styles;

const witnessReview = (
  witness: WitnessDetails,
  iterator: number,
  key: number
) => {
  return (
    <>
      <div className={margin} key={key}>
        <strong>
          <small>Name of Witness #{iterator}</small>
        </strong>
        <br />
        <p>{witness.name}</p>
      </div>
      <div className={margin}>
        <div className={box}>
          <div>
            <strong>
              <small>Contact information for the witness</small>
            </strong>
            <p>{witness.contact}</p>
          </div>

          <div>
            <strong>
              <small>
                Anything related to the incident you would like to add
              </small>
            </strong>
            <p>{witness.notes}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default function submittedPage({
  pageData,
  submitComplete,
  goBack,
  exitForm,
  loading,
  currentPageNumber,
  name,
  handleErrorMessage,
}: ClaimFormProps & {
  submitComplete: (e: PageData) => void;
  loading: boolean;
}): ReactElement {
  const {
    injuryDate,
    incidentInformation,
    personalInformation,
    incidentResponse,
  } = pageData;

  return (
    <Formik
      initialValues={pageData}
      onSubmit={(values) => {
        submitComplete(values);
      }}
    >
      <Form data-testid="review-page-form">
        <SharedForm
          handleErrorMessage={handleErrorMessage}
          currentPageNumber={currentPageNumber}
          name={name}
          exitFn={exitForm}
          key="exit-review-page"
        >
          <>
            <h2 className={mainTitle}>Review</h2>
            <div className={box}>
              <div>
                <strong>
                  <small>Impacted employee</small>
                </strong>
                <p>{injuryDate.memberName}</p>
              </div>
              <div>
                <strong>
                  <small>Date & Time of incident</small>
                </strong>
                <p>{injuryDate.date}</p>
                <p>{handleHourType(injuryDate?.hour, injuryDate?.minute)}</p>
              </div>
            </div>
            <div className={titleBox}>
              <h2>Contact Information</h2>
            </div>
            <div className={box}>
              <div>
                <div className={margin}>
                  <strong>{injuryDate.memberName}</strong>
                </div>
                <div className={margin}>
                  <strong>
                    <small>Home Address</small>
                  </strong>
                  <br />
                  {injuryDate.homeAddress?.street1}
                  <br />
                  {injuryDate.homeAddress?.street2}
                  <br />
                  {injuryDate.homeAddress?.city},{" "}
                  {injuryDate.homeAddress?.state},{" "}
                  {injuryDate.homeAddress?.postalCode}
                </div>
                <div className={margin}>
                  <strong>
                    <small>Employee Work Email</small>
                  </strong>
                  <br />
                  {injuryDate.workEmail}
                </div>
                <div className={margin}>
                  <strong>
                    <small>Employee Personal Email</small>
                  </strong>
                  <br />
                  {personalInformation.homeEmail}
                </div>
                <div className={margin}>
                  <strong>
                    <small>Employee Personal Phone</small>
                  </strong>
                  <br />
                  {injuryDate.contactPhone &&
                    formatPhone(injuryDate.contactPhone)}
                </div>
                <div className={margin}>
                  <strong>
                    <small>Employee Policy Number</small>
                  </strong>
                  <br />
                  {personalInformation.policyNumber}
                </div>
              </div>
              <div>
                <div className={margin}>
                  <strong>{injuryDate.companyName}</strong>
                </div>
                <div className={margin}>
                  <strong>
                    <small>Office Address</small>
                  </strong>
                  <br />
                  {injuryDate.companyAddress?.street1}
                  <br />
                  {injuryDate.companyAddress?.street2}
                  <br />
                  {injuryDate.companyAddress?.city},{" "}
                  {injuryDate.companyAddress?.state},{" "}
                  {injuryDate.companyAddress?.postalCode}
                </div>
                <div className={margin}>
                  <strong>
                    <small>Company Contact</small>
                  </strong>
                  <br />
                  {injuryDate.adminEmail}
                  <br />
                  {injuryDate.adminPhone}
                </div>
              </div>
            </div>
            <div className={titleBox}>
              <h2>About the incident</h2>
            </div>
            <div>
              <div className={margin}>
                <strong>
                  <small>Incident level</small>
                </strong>
                <p>{incidentInformation.fatality ? "Fatal" : "Not fatal"}</p>
              </div>
              <div className={margin}>
                <strong>
                  <small>How did the injury occur?</small>
                </strong>
                <p>{incidentInformation.incidentDescription}</p>
              </div>
              <div className={margin}>
                <strong>
                  <small>Where did the injury occur?</small>
                </strong>
                <p>
                  {incidentInformation.address?.street1}
                  <br />
                  {incidentInformation.address?.street2}
                  <br />
                  {incidentInformation.address?.city},{" "}
                  {incidentInformation.address?.state},{" "}
                  {incidentInformation.address?.postalCode}
                </p>
              </div>
              <div className={margin}>
                <strong>
                  <small>What part of the body was affected?</small>
                </strong>
                <p>{incidentInformation.injuryBodyPart}</p>
              </div>
              <div className={margin}>
                <strong>
                  <small>Describe the injury</small>
                </strong>
                <p>{incidentInformation.bodyPartDescription}</p>
              </div>
              <div className={margin}>
                <strong>
                  <small>
                    Where any safeguards in use at the time of injury?
                  </small>
                </strong>
                <p>
                  {incidentInformation.safeGuard}
                  <br />
                  {incidentInformation.safeGuardList}
                </p>
              </div>
            </div>
            <div className={titleBox}>
              <h2>Response to the injury</h2>
            </div>
            {incidentResponse ? (
              <div>
                <div className={margin}>
                  <strong>
                    <small>
                      Was EMS, police, or fire department contacted?
                    </small>
                  </strong>
                  <p>
                    {incidentResponse.wasContacted ? (
                      <span>Yes</span>
                    ) : (
                      <span>No</span>
                    )}
                  </p>
                  <p>{incidentResponse.wasContactedDetails}</p>
                </div>
                <div className={margin}>
                  <strong>
                    <small>What treatment was given?</small>
                  </strong>
                  <p>{formatTreatmentGiven(incidentResponse)}</p>
                  <br />
                  <p>{incidentResponse.treatmentDetails}</p>
                </div>
                <div className={margin}>
                  <strong>
                    <small>Address of service, clinic, or physician</small>
                  </strong>
                  {incidentResponse.address?.street1 ? (
                    <p>
                      {incidentResponse.address?.street1}
                      <br />
                      {incidentResponse.address?.street2 &&
                        incidentResponse.address?.street2}
                      {incidentResponse.address?.street2 && <br />}
                      {incidentResponse.address?.city},{" "}
                      {incidentResponse.address?.state},{" "}
                      {incidentResponse.address?.postalCode}
                    </p>
                  ) : (
                    <p>No address provided</p>
                  )}
                  <span>{incidentResponse.phoneNumber}</span>
                </div>
              </div>
            ) : (
              <p>No response</p>
            )}
            <div>
              <div className={titleBox}>
                <h2>Witnesses</h2>
              </div>
              {pageData.witnessInformation.witnessList.length > 0 ? (
                <>
                  {pageData.witnessInformation.witnessList.map((witness, i) =>
                    witnessReview(witness, i + 1, i)
                  )}
                </>
              ) : (
                <p>No witnesses</p>
              )}
            </div>

            <div className={titleBox}>
              <h2>Work Status</h2>
            </div>
            <div className={box}>
              <div>
                <div className={margin}>
                  <strong>
                    <small>Did this employee miss time from work?</small>
                  </strong>
                  <br />
                  {pageData.workStatus.missTime}
                </div>
                {pageData.workStatus.missTime === "Yes" && (
                  <div className={margin}>
                    <strong>
                      <small>When did the employee start losing time?</small>
                    </strong>
                    <br />
                    {pageData.workStatus.losingTime}
                  </div>
                )}
              </div>
              {pageData.workStatus.missTime === "Yes" && (
                <div>
                  <div className={margin}>
                    <strong>
                      <small>
                        Was the employee paid for the time missed from work?
                      </small>
                    </strong>
                    <br />
                    {pageData.workStatus.paid}
                  </div>
                  <div className={margin}>
                    <strong>
                      <small>When did the employee return to work?</small>
                    </strong>
                    <br />
                    {pageData.workStatus.returnToWorkDate}
                  </div>
                </div>
              )}
            </div>
            <div className={btnSection}>
              <FormContinue
                continueKey="continue-review-page"
                backKey="back-review-page"
                backFn={goBack}
                loading={loading}
                textContinue="Submit report"
                alignment="space-between"
              />
            </div>
          </>
        </SharedForm>
      </Form>
    </Formik>
  );
}
