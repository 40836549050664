import { parsePhoneNumberFromString } from "libphonenumber-js";
import { IncidentResponse } from "../../types";

export function formatPhone(phoneNumberString: string) {
  const phoneNumber = parsePhoneNumberFromString(phoneNumberString, "US");

  if (phoneNumber && phoneNumber.isValid()) {
    return phoneNumber.formatNational();
  }

  return phoneNumberString;
}

export const handleHourType = (hour: string = "0", minute: string = "0") => {
  const hourInt = parseInt(hour, 10);

  if (isNaN(hourInt)) {
    return "";
  }

  const hourStr = hourInt < 10 ? `0${hourInt}` : `${hourInt}`;
  const minuteStr =
    hourInt > 0 && minute === "0" ? "00" : minute.padStart(2, "0");
  const period = hourInt <= 12 ? "AM EST" : "PM EST";

  return `${hourStr}:${minuteStr} ${period}`;
};

interface ResponseDictionary {
  firstAid: string;
  medicalClinic: string;
  EMSTransport: string;
  overnightHospitalization: string;
  other: string;
}

export const formatTreatmentGiven = (treatment: IncidentResponse) => {
  if (treatment.noMedicalTreatment) return "No Medical Treatment";

  const dictionary: ResponseDictionary = {
    firstAid: "On-Site First Aid",
    medicalClinic: "Medical Clinic Treatment",
    EMSTransport: "EMS Transport",
    overnightHospitalization: "Overnight Hospitalization",
    other: "Other",
  };

  const toFormat = (({
    firstAid,
    medicalClinic,
    EMSTransport,
    overnightHospitalization,
    other,
  }) => ({
    firstAid,
    medicalClinic,
    EMSTransport,
    overnightHospitalization,
    other,
  }))(treatment);

  const chosen = [];
  for (const [key, value] of Object.entries(toFormat)) {
    if (value) {
      chosen.push(key);
    }
  }

  return chosen.reduce((formatted, curr, iter) => {
    if (iter === chosen.length - 1) {
      return formatted + " " + dictionary[curr as keyof ResponseDictionary];
    } else {
      return (
        formatted + " " + dictionary[curr as keyof ResponseDictionary] + ", "
      );
    }
  }, "");
};
