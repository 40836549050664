import React from "react";
import {
  REQUIRED,
  WHITESPACE_ERROR,
  FIVE_HUNDRED_CHARACTER_LIMIT_AND_EMOJI_ERROR,
} from "pages/expenses/constants";
import { ExpenseCategory } from "types/Expenses";
import {
  invalidStringInputValue,
  invalidWhitespaceInputValue,
} from "pages/expenses/utils";
import { FormField, TextInput } from "@justworkshr/milo-form";
import { isFormFieldHidden } from "../../ExpenseDetailsPage.utils";

const MERCHANT_KEY = "merchant";

interface MerchantProps {
  merchant: {
    value: string;
    error: string;
  };
  handleFormValue: ({
    key,
    value,
  }: {
    key: typeof MERCHANT_KEY;
    value: string;
  }) => void;
  handleFormError: ({
    key,
    error,
  }: {
    key: typeof MERCHANT_KEY;
    error: string;
  }) => void;
  selectedCategory: ExpenseCategory | null;
}

export const Merchant: React.FC<MerchantProps> = React.memo(
  ({ merchant, selectedCategory, handleFormValue, handleFormError }) => {
    if (
      !selectedCategory ||
      isFormFieldHidden(MERCHANT_KEY, selectedCategory)
    ) {
      return null;
    }

    const isRequired = selectedCategory.merchant === REQUIRED;

    const validateMerchantValue = (merchantValue: string) => {
      if (invalidStringInputValue(merchantValue, 100)) {
        handleFormError({
          key: MERCHANT_KEY,
          error: FIVE_HUNDRED_CHARACTER_LIMIT_AND_EMOJI_ERROR,
        });
      } else if (invalidWhitespaceInputValue(merchantValue)) {
        handleFormError({ key: MERCHANT_KEY, error: WHITESPACE_ERROR });
      } else {
        handleFormError({ key: MERCHANT_KEY, error: "" });
      }
    };

    const handleMerchantChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      validateMerchantValue(event.target.value);

      handleFormValue({
        key: MERCHANT_KEY,
        value: event.target.value,
      });
    };

    return (
      <FormField label="Merchant" required={isRequired} error={merchant.error}>
        <TextInput
          name={MERCHANT_KEY}
          required={isRequired}
          value={merchant.value}
          onChange={handleMerchantChange}
          data-testid="merchant-input"
        />
      </FormField>
    );
  }
);
