import { useFormikContext } from "formik";
import { FormikFormField } from "lib/formik/FormikFormField";
import {
  RadioButton,
  RadioButtonGroup,
} from "pages/team-management/components";
import { PaymentDetailsFormValues } from "../../../../SeparationPay.formUtils";

import styles from "../../../../SeparationPay.module.css";
import {
  COMMISSION_PAYMENT_DETAILS_TAX_METHOD_OPTIONS,
  PAYMENT_DETAILS_TAX_METHOD_OPTIONS,
  PAY_GROUP_OPTIONS,
} from "./TaxMethodInputRow.constants";
import { PAYMENT_DETAILS_FORM_COPY } from "../PaymentDetailsForm.constants";
import { isCommissionPayment } from "../../../../AdditionalPaymentsPage/components";
import { TaxMethodTypes } from "./TaxMethodInputRow.types";

const { RowContainer } = styles;

export const TaxMethodInputRow = () => {
  const { setFieldValue, values } =
    useFormikContext<PaymentDetailsFormValues>();

  const taxMethodOptions = (() => {
    if (isCommissionPayment(values.type)) {
      return COMMISSION_PAYMENT_DETAILS_TAX_METHOD_OPTIONS;
    } else {
      return PAYMENT_DETAILS_TAX_METHOD_OPTIONS;
    }
  })();

  return (
    <div className={RowContainer}>
      <FormikFormField
        name="tax_method"
        label={PAYMENT_DETAILS_FORM_COPY.tax_method}
        required={true}
      >
        <RadioButtonGroup
          name="tax_method"
          data-testid="tax_method"
          onChange={(option) => {
            setFieldValue("tax_method", option);
          }}
          variant="framed"
          orientation="horizontal"
        >
          {Object.entries(taxMethodOptions).map(([key, value]) => {
            return (
              <RadioButton
                key={key}
                value={key}
                data-testid={`tax_method__${key}`}
                label={value.label}
                description={value.message}
              />
            );
          })}
        </RadioButtonGroup>
      </FormikFormField>

      {values.tax_method == TaxMethodTypes.CUMULATIVE && (
        <FormikFormField
          name="pay_group"
          label={PAYMENT_DETAILS_FORM_COPY.pay_group}
          required={true}
        >
          <RadioButtonGroup
            name="pay_group"
            data-testid="pay_group"
            onChange={(option) => {
              setFieldValue("pay_group", option);
            }}
            variant="framed"
            orientation="horizontal"
          >
            {Object.entries(PAY_GROUP_OPTIONS).map(([key, value]) => {
              return (
                <RadioButton
                  data-testid={`pay_group__${key}`}
                  key={key}
                  value={key}
                  label={value}
                />
              );
            })}
          </RadioButtonGroup>
        </FormikFormField>
      )}
    </div>
  );
};
