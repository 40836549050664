import React from "react";
import styles from "./PaginationSelect.module.css";
import { Pagination } from "../Pagination/Pagination";
import { Select, SelectOption } from "@justworkshr/milo-form";

const {
  PaginationSelection,
  PaginationSelectWrapper,
  PaginationSelectionLabel,
} = styles;

interface PaginationSelectProps {
  disabled?: boolean;
  currentPage: number;
  totalResults: number;
  resultsPerPage: number;
  onPageChange: (newPage: number) => void;
  options: { value: string | number; description: string }[];
  onSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const PaginationSelect: React.FC<PaginationSelectProps> = React.memo(
  ({
    options,
    disabled,
    currentPage,
    onPageChange,
    totalResults,
    onSelectChange,
    resultsPerPage,
  }) => {
    return (
      <div className={PaginationSelectWrapper}>
        <Pagination
          disabled={disabled}
          totalCount={totalResults}
          pageSize={resultsPerPage}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
        <div className={PaginationSelection}>
          <h5 className={PaginationSelectionLabel}>Items per page</h5>
          <Select
            disabled={disabled}
            onChange={onSelectChange}
            value={resultsPerPage.toString()}
          >
            {options.map((option) => {
              return (
                <SelectOption key={option.value} value={option.value}>
                  {option.description}
                </SelectOption>
              );
            })}
          </Select>
        </div>
      </div>
    );
  }
);

export default PaginationSelect;
