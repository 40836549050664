import { Alert, Button, Card, PageHeader } from "@justworkshr/milo-core";
import {
  ActionFooter,
  Form,
  FormField,
  Select,
  SelectOption,
  TextInput,
} from "@justworkshr/milo-form";
import {
  Formik,
  type FormikErrors,
  type FormikProps,
  type FormikValues,
} from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Layout from "../../../Layout";
import onboardingStyles from "../../../Onboarding.module.css";
import useOnboardingForm from "../../../hooks/useOnboardingForm";
import styles from "../../ProfileInfo.module.css";
import { NATIONALITIES, months } from "../../constants";
import { unwrapDate } from "../../helpers";
import { compactTaxId } from "../../taxIdUtils";
import type { ProfileInfoFormTypePT as ProfileInfoFormType } from "../../types";
import BaseFormFragment, {
  initialValues as baseInitialValues,
  schema as baseSchema,
} from "../base/BaseFormFragment";
import IdentifierNumberFormFragment, {
  initialValues as identifierNumberInitialValues,
  schema as identifierNumberSchema,
} from "../identifier-input/IdentifierNumberFormFragment";

const { dateField, dateOfBirthContainer, inputField } = styles;
const STATE_KEY = "profile-info";

const NATIONALITIES_OPTIONS = [
  {
    value: "",
    description: "",
  },
  ...NATIONALITIES,
] as const;

export default function ProfileInfoPT() {
  const { t } = useTranslation();
  const {
    loading,
    memberData: { workCountry },
    form: { submitting, showErrorAlert, errorAlertMessage, onSubmit },
    memberOnboarding: {
      stepNumber,
      redirecting,
      profileInfo,
      idVerificationStatus,
    },
  } = useOnboardingForm(STATE_KEY);

  const MONTHS = months();

  const isBetween = (num1: number, num2: number, value: number) =>
    value >= num1 && value <= num2;

  const onFormSubmit = (values: FormikValues) => {
    // Compose date and identifier values.
    const profileInfoDateOfBirth =
      values.profileInfo.dateOfBirth.year +
      "-" +
      values.profileInfo.dateOfBirth.month +
      "-" +
      values.profileInfo.dateOfBirth.day;

    const { ptCcIssDate } = values.profileInfo;
    let profileInfoPtCcIssDate = null;
    if (ptCcIssDate.year && ptCcIssDate.month && ptCcIssDate.day) {
      profileInfoPtCcIssDate = `${
        ptCcIssDate.year
      }-${ptCcIssDate.month.padStart(2, "0")}-${ptCcIssDate.day.padStart(
        2,
        "0"
      )}`;
    }

    const profileInfoPtCcExpDate = `${
      values.profileInfo.ptCcExpDate.year
    }-${values.profileInfo.ptCcExpDate.month.padStart(
      2,
      "0"
    )}-${values.profileInfo.ptCcExpDate.day.padStart(2, "0")}`;

    const profileInfoIdentifierNumber = compactTaxId(
      workCountry,
      values.profileInfo.identifierNumber
    );

    onSubmit({
      profileInfo: {
        ...values.profileInfo,
        confirmIdentifierNumber: undefined,
        dateOfBirth: profileInfoDateOfBirth,
        identifierNumber: profileInfoIdentifierNumber,
        ptCcIssDate: profileInfoPtCcIssDate,
        ptCcExpDate: profileInfoPtCcExpDate,
      },
    });
  };

  const profileInfoSchema = Yup.object({
    nationality: Yup.string()
      .oneOf(NATIONALITIES_OPTIONS.map((nationality) => nationality.value))
      .required("Required."),
    ptCc: Yup.string()
      .required("Required.")
      .matches(/^\d{15}$/, "Must be exactly 15 digits."),
    ptCcIssDate: Yup.object()
      .shape({
        year: Yup.string(),
        month: Yup.string(),
        day: Yup.string(),
      })
      .test("valid", t("Invalid entry. Please try again."), (date) => {
        const { year, month, day } = date;

        // Return true if all fields are empty because this field is optional
        if (!year && !month && !day) {
          return true;
        }

        // Return false if any of the fields are empty
        if (!year || !month || !day) {
          return false;
        }

        // Check if the individual date fields are valid
        if (!isBetween(1, 12, parseInt(month))) return false;
        if (!isBetween(1, 31, parseInt(day))) return false;

        return true;
      }),
    ptCcExpDate: Yup.object()
      .shape({
        year: Yup.string(),
        month: Yup.string(),
        day: Yup.string(),
      })
      .test("valid", t("Invalid entry. Please try again."), (date) => {
        const { year, month, day } = date;

        // Return false if any of the fields are empty
        if (!year || !month || !day) {
          return false;
        }

        // Check if the individual date fields are valid
        if (!isBetween(1, 12, parseInt(month))) return false;
        if (!isBetween(1, 31, parseInt(day))) return false;

        return true;
      }),
    ptNiss: Yup.string()
      .required("Required.")
      .matches(/^\d{11}$/, "Must be exactly 11 digits."),
  });

  const initialValuesPT = {
    profileInfo: {
      nationality: "",
      ptCc: "",
      ptCcIssDate: {
        year: "",
        month: "",
        day: "",
      },
      ptCcExpDate: {
        year: "",
        month: "",
        day: "",
      },
      ptNiss: "",
    },
  };

  const getInitialValues = (): ProfileInfoFormType => {
    const initialValues = {
      profileInfo: {
        ...baseInitialValues.profileInfo,
        ...identifierNumberInitialValues.profileInfo,
      },
    };

    const defaultInitialValues = profileInfo || initialValues;

    const mergedValues: ProfileInfoFormType = {
      profileInfo: {
        ...initialValuesPT.profileInfo,
        ...defaultInitialValues.profileInfo,
      },
    };

    // there are many problems that could be avoided if we switched to
    // using a date picker component, but for now we'll just unwrap the
    // dates here
    // the new version of the datepicker in Milo 2.0 would require a larger refactor
    if (profileInfo && "ptCcExpDate" in profileInfo.profileInfo) {
      mergedValues.profileInfo.ptCcExpDate = unwrapDate(
        String(profileInfo.profileInfo.ptCcExpDate)
      );
    }

    if (profileInfo && "ptCcIssDate" in profileInfo.profileInfo) {
      mergedValues.profileInfo.ptCcIssDate = unwrapDate(
        String(profileInfo.profileInfo.ptCcIssDate)
      );
    }

    return mergedValues;
  };

  const schema = Yup.object({
    profileInfo: profileInfoSchema
      .concat(baseSchema(t))
      .concat(identifierNumberSchema(t, "PT")),
  });

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {errorAlertMessage ||
            t("An error occurred while submitting your profile information.")}
        </Alert>

        <PageHeader title={t("Complete your profile")} />

        <Formik
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validationSchema={schema}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            submitCount,
            touched,
            values,
          }: FormikProps<ProfileInfoFormType>) => {
            const dateFieldErrors = (
              values: ProfileInfoFormType,
              errors: FormikErrors<ProfileInfoFormType>,
              fieldName: keyof ProfileInfoFormType["profileInfo"]
            ): string => {
              const allFieldsFilled: boolean = Object.values(
                values?.profileInfo?.[fieldName]
              ).every((value) => value);
              if (
                (allFieldsFilled || submitCount > 0) &&
                errors?.profileInfo?.[fieldName]
              ) {
                return errors?.profileInfo?.[fieldName] as string;
              }
              return "";
            };

            return (
              <Form onSubmit={handleSubmit}>
                <Card title={t("Personal information")}>
                  <BaseFormFragment />

                  {/* PT specific fields */}
                  <div className={inputField}>
                    <FormField
                      name="profileInfo.nationality"
                      required
                      label={t("Nationality")}
                      error={
                        touched?.profileInfo?.nationality
                          ? errors?.profileInfo?.nationality
                          : ""
                      }
                    >
                      <Select
                        name="profileInfo.nationality"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.profileInfo.nationality}
                      >
                        {NATIONALITIES_OPTIONS.map((country) => (
                          <SelectOption
                            value={country?.value}
                            key={country?.value}
                          >
                            {country?.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      label={t(
                        "Cartão de cidadão number (Citizen card number)"
                      )}
                      required
                      error={
                        touched?.profileInfo?.ptCc
                          ? errors?.profileInfo?.ptCc
                          : ""
                      }
                    >
                      <TextInput
                        name="profileInfo.ptCc"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.profileInfo.ptCc}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      error={dateFieldErrors(values, errors, "ptCcIssDate")}
                      label={t("Cartão de cidadão Issue date")}
                    >
                      <div className={dateOfBirthContainer}>
                        <div className={dateField}>
                          <label htmlFor="profileInfo.ptCcIssDate.year">
                            {t("Year")}
                          </label>
                          <TextInput
                            id="profileInfo.ptCcIssDate.year"
                            name="profileInfo.ptCcIssDate.year"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.profileInfo.ptCcIssDate.year}
                          />
                        </div>

                        <div className={dateField}>
                          <label htmlFor="profileInfo.ptCcIssDate.month">
                            {t("Month")}
                          </label>
                          <Select
                            id="profileInfo.ptCcIssDate.month"
                            name="profileInfo.ptCcIssDate.month"
                            onChange={handleChange}
                            defaultValue={values.profileInfo.ptCcIssDate.month}
                            // We cannot pass a placeholder and a default value.
                            //   So, if we initially have a value for month, we
                            //   can't send a placeholder
                            placeholder={
                              values.profileInfo.ptCcIssDate.month?.length > 0
                                ? undefined
                                : t("Select month")
                            }
                          >
                            {MONTHS.map((month) => {
                              return (
                                <SelectOption
                                  key={month.value}
                                  value={month.value}
                                >
                                  {month.description}
                                </SelectOption>
                              );
                            })}
                          </Select>
                        </div>

                        <div className={dateField}>
                          <label htmlFor="profileInfo.ptCcIssDate.day">
                            Day
                          </label>
                          <TextInput
                            id="profileInfo.ptCcIssDate.day"
                            name="profileInfo.ptCcIssDate.day"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.profileInfo.ptCcIssDate.day}
                          />
                        </div>
                      </div>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      error={dateFieldErrors(values, errors, "ptCcExpDate")}
                      label={t("Cartão de cidadão expiration date")}
                      required
                    >
                      <div className={dateOfBirthContainer}>
                        <div className={dateField}>
                          <label htmlFor="profileInfo.ptCcExpDate.year">
                            {t("Year")}
                          </label>
                          <TextInput
                            id="profileInfo.ptCcExpDate.year"
                            name="profileInfo.ptCcExpDate.year"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.profileInfo.ptCcExpDate.year}
                          />
                        </div>

                        <div className={dateField}>
                          <label htmlFor="profileInfo.ptCcExpDate.month">
                            {t("Month")}
                          </label>
                          <Select
                            id="profileInfo.ptCcExpDate.month"
                            name="profileInfo.ptCcExpDate.month"
                            onChange={handleChange}
                            defaultValue={values.profileInfo.ptCcExpDate.month}
                            // We cannot pass a placeholder and a default value.
                            //   So, if we initially have a value for month, we
                            //   can't send a placeholder
                            placeholder={
                              values.profileInfo.ptCcExpDate.month?.length > 0
                                ? undefined
                                : t("Select month")
                            }
                          >
                            {MONTHS.map((month) => {
                              return (
                                <SelectOption
                                  key={month.value}
                                  value={month.value}
                                >
                                  {month.description}
                                </SelectOption>
                              );
                            })}
                          </Select>
                        </div>

                        <div className={dateField}>
                          <label htmlFor="profileInfo.ptCcExpDate.day">
                            Day
                          </label>
                          <TextInput
                            id="profileInfo.ptCcExpDate.day"
                            name="profileInfo.ptCcExpDate.day"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.profileInfo.ptCcExpDate.day}
                          />
                        </div>
                      </div>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      label={t("Instituto Nacional do Seguro Social (NISS)")}
                      required
                      error={
                        touched?.profileInfo?.ptNiss
                          ? errors?.profileInfo?.ptNiss
                          : ""
                      }
                      message={t("The NISS is the Social Security ID Number")}
                    >
                      <TextInput
                        name="profileInfo.ptNiss"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.profileInfo.ptNiss}
                      />
                    </FormField>
                  </div>

                  {/* END PT specific fields */}

                  <IdentifierNumberFormFragment workCountry="PT" />
                </Card>

                <ActionFooter
                  className={onboardingStyles.footer}
                  actions={[
                    <Button
                      color="brand"
                      key="submit-btn"
                      loading={submitting}
                      type="submit"
                    >
                      {t("Save & continue")}
                    </Button>,
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </Layout>
  );
}
