import { useParams } from "react-router-dom";
import styles from "./RequestDetailPage.module.css";
import { useRequestDetailPage } from "pages/expenses/hooks";
import { useFeedbackBannerStore, useRequestDetail } from "pages/expenses/store";
import {
  Loading,
  ReturnReasons,
  ReimbursementSummary,
} from "pages/expenses/components";
import { ExpenseDetailsCards } from "./components";
import { useEffect } from "react";
import { RETURNED_STATUS } from "pages/expenses/constants/request-status";
import { AlertTypes } from "pages/expenses/constants";

interface RequestDetailPageProps {
  isApproverView?: boolean;
}

const {
  RequestDetailPageName,
  RequestDetailPageColumn,
  RequestDetailPageWrapper,
  RequestDetailPageContainer,
  RequestDetailPageSectionTitle,
} = styles;

export const RequestDetailPage: React.FC<RequestDetailPageProps> = ({
  isApproverView = false,
}) => {
  const params = useParams();

  const { isRequestDetailLoading } = useRequestDetailPage({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    uuid: params.request_uuid!,
    additionalConfiguration: {
      metadata: { isApproverView },
    },
  });

  const {
    requestDetail: {
      request: {
        name,
        expenses,
        status,
        member: submitter,
        myRequest: isCurrentMemberRequest,
      },
    },
  } = useRequestDetail();

  const { setFeedbackBanner } = useFeedbackBannerStore();

  const isRequestReturned = status === RETURNED_STATUS;

  useEffect(() => {
    if (!isRequestDetailLoading && isRequestReturned) {
      if (isCurrentMemberRequest) {
        setFeedbackBanner({
          show: true,
          type: AlertTypes.ERROR,
          message: `${name} was returned by your admin. Check return reasons for more info.`,
        });
      } else if (isApproverView) {
        setFeedbackBanner({
          show: true,
          type: AlertTypes.ERROR,
          message: `${name} was returned to ${submitter?.friendlyFullName}. After they update the request, it will come back to you for
          approval again.`,
        });
      }
    }
  }, [
    isRequestReturned,
    setFeedbackBanner,
    name,
    submitter?.friendlyFullName,
    isApproverView,
    isCurrentMemberRequest,
    isRequestDetailLoading,
  ]);

  if (isRequestDetailLoading) {
    return <Loading />;
  }

  return (
    <div className={RequestDetailPageWrapper}>
      <div className={RequestDetailPageContainer}>
        <div className={RequestDetailPageColumn}>
          <h2>Reimbursement request</h2>
          <p className={RequestDetailPageSectionTitle}>Request name</p>
          <p className={RequestDetailPageName}>{name}</p>
          <p className={RequestDetailPageSectionTitle}>Expenses</p>
          <ExpenseDetailsCards expenses={expenses} />
          {isRequestReturned && <ReturnReasons />}
        </div>
        <div className={RequestDetailPageColumn}>
          <ReimbursementSummary isCreateFlow={false} />
        </div>
      </div>
    </div>
  );
};

export default RequestDetailPage;
