import { ReactElement } from "react";
import styles from "./Wallet.module.css";
import { SystemIcon, DisplayIcon } from "@justworkshr/milo-icons";
import Tooltip from "@justworkshr/milo-tooltip";
import { Row, SkeletonText, Stack } from "@justworkshr/milo-core";

interface WalletProps {
  loading?: boolean;
  balance?: string;
  withTooltip: boolean;
}
const {
  walletBalanceContainer,
  walletBalanceHeader,
  walletBalanceIcon,
  walletBalance,
} = styles;

export default function Wallet({
  loading,
  balance,
  withTooltip,
}: WalletProps): ReactElement {
  return (
    <Row spacing="lg" className={walletBalanceContainer}>
      <DisplayIcon
        iconName="piggy-bank"
        size="medium"
        color="yellowPurple"
        className={walletBalanceIcon}
      />
      <Stack spacing="xs">
        <Row spacing="xs" className={walletBalanceHeader}>
          <div>Justworks Wallet balance</div>
          {withTooltip && (
            <Tooltip
              color="neutral"
              position="right-start"
              title={
                (
                  <>
                    <p>
                      <strong>Meet Justworks Wallet</strong>
                    </p>
                    <p>
                      Justworks Wallet is a new way to quickly see the money you
                      have with us as well as your latest transactions.
                    </p>
                    <br />
                    <p>Stay tuned—we have a lot more planned.</p>
                  </>
                ) as unknown as string
              }
              data-testid="tooltip"
            >
              <SystemIcon
                iconName="help"
                size="extra-small"
                color="brand"
                data-testid="help-icon"
              />
            </Tooltip>
          )}
        </Row>
        {loading && <SkeletonText size="xl" />}
        {!loading && balance && (
          <span className={walletBalance}>{balance}</span>
        )}
      </Stack>
    </Row>
  );
}
