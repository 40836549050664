import { InternationalDocument } from "types/international";
import { DOCUMENTS_TABLE_KEYS } from "./constants";
import { SORT } from "./Table/constants";

type SortDocumentsParams = {
  documents?: InternationalDocument[];
  sortOptions: {
    sortBy: string;
    sortDirection: string;
  };
};

export const sortDocuments = ({
  documents,
  sortOptions,
}: SortDocumentsParams) => {
  const { sortBy, sortDirection } = sortOptions;

  const toSort = [...(documents || [])];

  switch (sortBy) {
    case DOCUMENTS_TABLE_KEYS.UPLOADED:
      return toSort.sort((a, b) => {
        let value = 0;
        const date1 = new Date(a.uploadDate);
        const date2 = new Date(b.uploadDate);
        if (date1 > date2) {
          value = 1;
        } else if (date1 < date2) {
          value = -1;
        }
        return sortDirection === SORT.ASC ? value : -value;
      });
    case DOCUMENTS_TABLE_KEYS.NAME:
    default:
      return toSort.sort((a, b) => {
        let value = 0;
        if (a.name > b.name) {
          value = 1;
        } else if (a.name < b.name) {
          value = -1;
        }
        return sortDirection === SORT.ASC ? value : -value;
      });
  }
};

export const formatDate = (dateString: string) => {
  return new Date(dateString).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};
