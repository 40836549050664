import ImageCard from "pages/pmt/integrations/partner-setup-page/components/ImageCard/ImageCard";
import { Button, Spinner } from "@justworkshr/milo-core";
import styles from "./LeverSetup.module.css";
import { StepperHelpers } from "pages/pmt/integrations/partner-setup-page/hooks/useStepper";
import { useState, useEffect, useRef, useCallback } from "react";
import React from "react";
import { StatusMessage } from "./utils";
import useProcessPmtIntegrationAction, {
  ActionData,
} from "../../../partner-setup-page/hooks/useProcessPmtIntegrationAction";
import { DisplayIcon } from "@justworkshr/milo-icons";

const { wrapper, spinnerSpacing, retryButton } = styles;

type LeverSetupStepThreeProps = StepperHelpers & {
  processPmtIntegrationAction: (
    integrationName: string,
    action: string
  ) => Promise<
    | {
        success: boolean;
        actionData: ActionData | null;
      }
    | undefined
  >;
  loading: boolean;
};

export const LeverSetupStepThreeRenderer: React.FC<
  LeverSetupStepThreeProps
> = ({ processPmtIntegrationAction, loading, ...stepperHelpers }) => {
  const { goToNextStep } = stepperHelpers;
  const [integrationSetupSuccess, setIntegrationSetupSuccess] = useState(false);
  const attemptedSetupRef = useRef(false);

  const attemptSetup = useCallback(async () => {
    if (!attemptedSetupRef.current) {
      attemptedSetupRef.current = true;
      processPmtIntegrationAction("lever", "setup").then(
        (processActionResponse) => {
          if (processActionResponse?.success) {
            setIntegrationSetupSuccess(true);

            const moveToNextStepTimer = setTimeout(() => {
              goToNextStep();
            }, 1000);

            return () => {
              clearTimeout(moveToNextStepTimer);
            };
          } else {
            attemptedSetupRef.current = false;
          }
        }
      );
    }
  }, [processPmtIntegrationAction, goToNextStep]);

  useEffect(() => {
    attemptSetup();
  }, [attemptSetup]);

  return (
    <ImageCard
      header="Connecting Justworks to Lever"
      image={
        <DisplayIcon
          iconName="clock-with-arrows"
          color="greenYellow"
          size="large"
        />
      }
    >
      <div className={wrapper}>
        <span>
          Here we go! We're creating the webhook in Lever, which is how
          Justworks and Lever will share data.
        </span>
        {loading && (
          <span className={spinnerSpacing}>
            <Spinner aria-label="loading" />
          </span>
        )}
        {!loading && (
          <>
            {!integrationSetupSuccess && (
              <Button
                leftIcon="retry"
                variant="outlined"
                type="button"
                className={retryButton}
                onClick={attemptSetup}
              >
                Retry
              </Button>
            )}
            <StatusMessage
              integrationSetupSuccess={integrationSetupSuccess}
              currentStep={3}
            />
          </>
        )}
      </div>
    </ImageCard>
  );
};

const LeverSetupStepThree: React.FC<StepperHelpers> = (
  stepperHelpers: StepperHelpers
) => {
  const { processPmtIntegrationAction, loading } =
    useProcessPmtIntegrationAction();
  return (
    <LeverSetupStepThreeRenderer
      processPmtIntegrationAction={processPmtIntegrationAction}
      loading={loading}
      {...stepperHelpers}
    />
  );
};

export default LeverSetupStepThree;
