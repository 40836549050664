import { permission } from "../../../../constants";
import { Box } from "@justworkshr/milo-core";
import { Permission } from "../";
import styles from "./PermissionGroup.module.css";

const { heading, divider } = styles;

interface PermissionGroupProps {
  name: string;
  permissions: permission[];
}

const PermissionGroup = ({ name, permissions }: PermissionGroupProps) => {
  return (
    <Box
      padding="2xl"
      border={{
        borderColor: "neutralSubtle",
        borderWidth: "sm",
        borderRadius: "md",
      }}
    >
      <h5 className={heading}>{name}</h5>
      <hr className={divider} />
      {permissions.map((permissionConfig) => {
        return (
          <Permission
            key={permissionConfig.id}
            permissionKey={permissionConfig.id}
            capabilities={permissionConfig.capabilities}
            label={permissionConfig.label}
            grantedBy={permissionConfig.grantedBy}
          />
        );
      })}
    </Box>
  );
};

export default PermissionGroup;
