import React from "react";
import styles from "../InstanceDetailsPanel.module.css";
import { Button } from "@justworkshr/milo-core";
import { Link } from "react-router-dom";

const { detailsPanelButtons, setUpButtonText } = styles;

export type SetupButtonProps = {
  solutionSlug: string | undefined;
  hasPermission: boolean;
  inAlert?: boolean;
};

const SetupButton: React.FC<SetupButtonProps> = ({
  solutionSlug,
  hasPermission,
  inAlert,
}) => {
  return (
    <Button
      className={detailsPanelButtons}
      color={inAlert ? "neutral" : "brand"}
      variant={inAlert ? "ghost" : "filled"}
      disabled={!hasPermission}
      as={Link}
      to={`/integrations/${solutionSlug}/setup`}
    >
      <span className={setUpButtonText}>Set up</span>
    </Button>
  );
};

export default SetupButton;
