import styles from "../../SubmissionRequirementsPage.module.css";
import { Card } from "@justworkshr/milo-core";
import { DEFAULT_FIELDS_DESCRIPTION } from "./DefaultFields.constants";

const { DefaultFieldsList, DefaultFieldsListWrapper } = styles;

export const DefaultFields = () => {
  return (
    <Card title="Default fields" description={DEFAULT_FIELDS_DESCRIPTION}>
      <div className={DefaultFieldsListWrapper}>
        <div>
          <strong>Required</strong>
          <ul className={DefaultFieldsList}>
            <li>Request name</li>
            <li>Transaction date</li>
            <li>Amount</li>
            <li>Category</li>
          </ul>
        </div>
        <div>
          <strong>Optional</strong>
          <ul className={DefaultFieldsList}>
            <li>Project - will only appear if there are active projects</li>
          </ul>
        </div>
      </div>
    </Card>
  );
};
