import { SystemIcon } from "@justworkshr/milo-icons";
import { Button } from "@justworkshr/milo-core";
import { UserMenuMemberWithCompany, UserProductsAndCompanies } from "types";
import styles from "./user-menu-drawer-content.module.css";
import { buildWebPath, buildCsToolsPath } from "lib/resource-finder";
import { usePanelsControlContext } from "app-frame/header/context/panels-control-context-provider";
import { PanelsControlActionType } from "app-frame/header/context/panels-control-context-provider/types";
import { useGetHelpMenuQuery } from "types/generated/operations";
import UserLink from "../../user-link";
import useAccountSettingsLink from "../../useAccountSettingsLink";

const { listItems } = styles;

interface UserMenuDrawerContentProps {
  isMultiProductNavEnabled: boolean;
  hasAccessToAlerts: boolean;
  hasAccessToWhatsNew: boolean;
  hasAccessToCsTools: boolean;
  hasAccessToProfileView: boolean;
  memberUuid: string;
  otherCompaniesOfCurrentUser: UserMenuMemberWithCompany[];
  productsAndCompanies: UserProductsAndCompanies[];
}

function UserMenuDrawerContent({
  isMultiProductNavEnabled = false,
  hasAccessToAlerts,
  hasAccessToWhatsNew,
  hasAccessToCsTools,
  hasAccessToProfileView,
  memberUuid,
  otherCompaniesOfCurrentUser,
  productsAndCompanies,
}: UserMenuDrawerContentProps) {
  const { dispatch } = usePanelsControlContext();
  const accountSettingsLink = useAccountSettingsLink();

  const { data, loading } = useGetHelpMenuQuery();
  if (loading || !data) {
    return <></>;
  }
  const hasAccessToAdvancedHelp =
    data.authenticatedMember.capabilities.hasAccessToAdvancedHelpChat;

  const handleHelpButtonOnClick = () => {
    if (hasAccessToAdvancedHelp) {
      dispatch({ type: PanelsControlActionType.OPEN_ADVANCED_HELP_DRAWER });
    } else if (window.Forethought) {
      dispatch({ type: PanelsControlActionType.OPEN_FORETHOUGHT_HELP_CHAT });
      window.Forethought("widget", "open");
    }
  };

  const alertsButton = (
    <Button
      variant="ghost"
      onClick={() =>
        dispatch({
          type: PanelsControlActionType.OPEN_ALERTS_DRAWER,
        })
      }
    >
      <SystemIcon iconName="bell" color="brand" size="small" />
      Alerts
    </Button>
  );

  const whatsNewButton = (
    <Button
      variant="ghost"
      onClick={() =>
        dispatch({
          type: PanelsControlActionType.OPEN_WHATS_NEW_DRAWER,
        })
      }
    >
      <SystemIcon iconName="whats-new" color="brand" size="small" />
      What's new
    </Button>
  );

  const helpButton = (
    <Button variant="ghost" onClick={handleHelpButtonOnClick}>
      <SystemIcon iconName="help" color="brand" size="small" />
      Help
    </Button>
  );

  const switchCompaniesButton = (
    <Button
      variant="ghost"
      onClick={() =>
        dispatch({
          type: PanelsControlActionType.OPEN_SWITCH_COMPANIES_DRAWER,
        })
      }
    >
      <SystemIcon iconName="building" color="brand" size="small" />
      Switch companies
    </Button>
  );

  const logoutButton = (
    <Button variant="ghost" as="a" href={buildWebPath("/logout")}>
      <SystemIcon iconName="logout" color="brand" size="small" />
      Log out
    </Button>
  );

  const items: JSX.Element[] = [];
  if (hasAccessToAlerts) {
    items.push(alertsButton);
  }
  if (hasAccessToWhatsNew) {
    items.push(whatsNewButton);
  }
  items.push(helpButton);
  if (
    !isMultiProductNavEnabled &&
    otherCompaniesOfCurrentUser &&
    otherCompaniesOfCurrentUser.length >= 1
  ) {
    items.push(switchCompaniesButton);
  }
  items.push(logoutButton);

  if (isMultiProductNavEnabled) {
    return (
      <>
        <div className={styles.linksSection}>
          {hasAccessToProfileView && (
            <UserLink
              href={buildWebPath(`employee_profile/${memberUuid}`)}
              iconName="person"
              text="View profile"
            />
          )}

          <UserLink
            href={accountSettingsLink}
            iconName="gear"
            text="Account settings"
          />

          {hasAccessToCsTools && (
            <UserLink
              href={buildCsToolsPath()}
              iconName="internal-controls"
              text="Internal controls"
            />
          )}
        </div>

        {productsAndCompanies.length > 1 && (
          <div className={styles.linksSection}>
            {productsAndCompanies.map((productCompany) => (
              <UserLink
                href={productCompany.redirectUrl}
                {...(productCompany.isSelected ? { iconName: "home" } : {})}
                text={productCompany.companyName}
                subText={productCompany.product}
                dimColor={!productCompany.isSelected}
                key={productCompany.companyCanonicalId}
              />
            ))}
          </div>
        )}

        <div className={styles.linksSection}>
          {items.map((item, index) => {
            return (
              <div className={styles.bottomLink} key={index}>
                {item}
              </div>
            );
          })}
        </div>
      </>
    );
  } else {
    return (
      <ul className={listItems}>
        {items.map((item, index) => {
          return <li key={`user-nav-item-${index}`}>{item}</li>;
        })}
      </ul>
    );
  }
}

export default UserMenuDrawerContent;
