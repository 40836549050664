import React, { ReactElement, useId } from "react";
import styles from "./RadioButton.module.css";

const {
  radioButton,
  radioLabel,
  radioButtonWrapper,
  radioDescription,
  framed,
  selected,
} = styles;

type ExcludedAttributes = "onChange";

export interface RadioButtonProps
  extends Omit<React.HTMLAttributes<HTMLInputElement>, ExcludedAttributes> {
  /** Used to collect multiple RadioButtons into a single, mutually exclusive group, for uncontrolled use cases. When used within a RadioButtonGroup component, this property should not be set directly on the radio button.  */
  name?: string;
  /** The value associated with the radio button. This value is submitted with the form data when the radio button is selected. */
  value: string;
  /** The label displayed next to the radio button. */
  label: string;
  /**
   * Event handler that will be called when the radio button is selected or deselected. If the radio button is wrapped by a RadioButtonGroup, this prop should not be provided as it will be inherited from the group.
   * @param newSelection The value of the selected radio button.
   * @param event The change event object.
   */
  onChange?: (
    newSelection: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  /** Whether the radio button is checked. If the radio button is wrapped by a RadioButtonGroup, this prop should not be provided as it will be determined by the group's `value` property. */
  checked?: boolean;
  /** Whether the radio button is read-only */
  disabled?: boolean;
  /** whether the radio button is in an error state. If the radio button is wrapped by a RadioButtonGroup, this prop should not be provided as it will be inherited from the group. */
  invalid?: boolean;
  /** The unique ID for the radio button. If not provided, a random ID will be generated. */
  id?: string;
  /** Additional information about the radio button's value  */
  description?: string;
  /** This should not be directly used by developers - it should be set at the RadioButtonGroup level which will pass down the proper value here */
  _variant?: "basic" | "framed";
}

export const RadioButton = React.forwardRef(
  (
    {
      name,
      value,
      label,
      onChange,
      checked,
      disabled,
      id,
      description,
      _variant: variant,
      ...props
    }: RadioButtonProps,
    ref
  ): ReactElement => {
    const generatedId = useId();
    const radioButtonId = id ?? generatedId;

    const handleOnChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      onChange?.(value, event);
    };

    const handleFramedStyling = (): string => {
      if (variant === "framed") {
        return `${radioButtonWrapper} ${framed}`;
      }
      return radioButtonWrapper;
    };

    const inputProps = Object.entries(props).reduce(
      (
        acc: { [key: string]: string | boolean },
        [key, value]: [key: string, value: string | boolean]
      ) => {
        acc[key] = String(value);
        return acc;
      },
      {}
    );

    return (
      <label
        htmlFor={radioButtonId}
        className={`${handleFramedStyling()} ${checked && selected}`}
      >
        <input
          {...inputProps}
          type="radio"
          checked={checked}
          value={value}
          name={name}
          onChange={handleOnChange}
          disabled={disabled}
          ref={ref as React.RefObject<HTMLInputElement>}
          id={radioButtonId}
          className={radioButton}
        />
        {Boolean(label) && (
          <span className={radioLabel}>
            {label}
            {Boolean(description) && (
              <div className={radioDescription}>{description}</div>
            )}
          </span>
        )}
      </label>
    );
  }
);

RadioButton.displayName = "RadioButton";

export default RadioButton;
