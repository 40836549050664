import HeaderButton from "app-frame/header/common/header-button";
import {
  useGetWhatsNewPostsQuery,
  useMarkProductUpdatesAsSeenMutation,
} from "types/generated/operations";
import { usePanelsControlContext } from "app-frame/header/context/panels-control-context-provider";
import { PanelsControlActionType } from "app-frame/header/context/panels-control-context-provider/types";

export default function WhatsNewButton() {
  const { state, dispatch } = usePanelsControlContext();
  const { whatsNewDrawerShown } = state;

  const [markProductUpdatesAsSeen] = useMarkProductUpdatesAsSeenMutation();
  const { data, loading } = useGetWhatsNewPostsQuery();
  if (loading || !data) {
    return <></>;
  }
  const unseenPostsCount = data.currentMember.productPosts?.unseenPostsCount
    ? data.currentMember.productPosts?.unseenPostsCount
    : 0;

  return (
    <HeaderButton
      outstandingTaskCount={unseenPostsCount}
      buildInIconName="whats-new"
      text="What's New"
      testId="whats-new-button"
      onClick={async () => {
        dispatch({
          type: PanelsControlActionType.OPEN_WHATS_NEW_DRAWER,
        });
        await markProductUpdatesAsSeen();
      }}
      active={whatsNewDrawerShown}
    />
  );
}
