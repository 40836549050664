import { ReactElement, useState } from "react";
import { ActionFooter } from "@justworkshr/milo-form";
import { Button } from "@justworkshr/milo-core";
import { Formik, FormikProps } from "formik";
import { FormikFormField } from "lib/formik/FormikFormField";
import { microdepositVerifyFormSchema } from "pages/company-settings/utils/microdepositVerifyFormSchema";
import styles from "pages/company-settings/components/EditBankAccountForm.module.css";
import DecoratedTextInput from "pages/company-settings/components/DecoratedTextInput";
import { useVerifyMicrodeposit } from "pages/company-settings/hooks/useVerifyMicrodeposit";

const { form, formFieldWrapper, errorNotice, errorNoticeDetails, footer } =
  styles;

interface ExtendedError extends Error {
  status?: number;
  body: [string];
}

interface MicrodepositVerifyFormProps {
  onError: (error: ExtendedError | null) => void;
}

interface MicrodepositVerifyFormValues {
  deposit1: string;
  deposit2: string;
}

const microdepositVerifyFormFormInitialValues = {
  deposit1: "",
  deposit2: "",
};

export default function MicrodepositVerifyForm({
  onError,
}: MicrodepositVerifyFormProps): ReactElement {
  const { mutate, isPending } = useVerifyMicrodeposit();

  const [formError, setFormError] = useState<ExtendedError | null>(null);

  const hasBlockingError = formError ? formError.status === 403 : false;

  const onSubmit = (values: MicrodepositVerifyFormValues) => {
    mutate(values, {
      onError: (err: Error) => {
        const extendedError = err as ExtendedError;
        if (extendedError.status === 422 || extendedError.status === 403) {
          setFormError(extendedError);
        } else {
          onError(extendedError);
        }
      },
    });
  };

  return (
    <Formik
      initialValues={microdepositVerifyFormFormInitialValues}
      validationSchema={microdepositVerifyFormSchema}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        isValid,
        dirty,
      }: FormikProps<MicrodepositVerifyFormValues>) => (
        <>
          {formError && (
            <div className={errorNotice}>
              <p className={errorNoticeDetails}>{formError.body[0]}</p>
            </div>
          )}
          <div className={form}>
            <div className={formFieldWrapper}>
              <FormikFormField label="Deposit 1" name="deposit1" required>
                <DecoratedTextInput
                  disabled={hasBlockingError}
                  prefix="$0."
                  type="text"
                  name="deposit1"
                  placeholder={"03"}
                />
              </FormikFormField>
            </div>
            <div className={formFieldWrapper}>
              <FormikFormField label="Deposit 2" name="deposit2" required>
                <DecoratedTextInput
                  disabled={hasBlockingError}
                  prefix="$0."
                  type="text"
                  name="deposit2"
                  placeholder={"89"}
                />
              </FormikFormField>
            </div>
          </div>

          <ActionFooter
            className={footer}
            actions={[
              <Button
                data-testid="submit-button"
                type="submit"
                disabled={!isValid || !dirty || hasBlockingError}
                onClick={handleSubmit}
                loading={isPending}
                key="save-button"
              >
                Verify
              </Button>,
            ]}
          />
        </>
      )}
    </Formik>
  );
}
