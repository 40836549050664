import styles from "./RequestDetailOutlet.module.css";
import { Outlet } from "react-router-dom";
import { RequestDetailHeader } from "./components";

const { RequestDetailOutletWrapper } = styles;

export const RequestDetailOutlet = () => {
  return (
    <div className={RequestDetailOutletWrapper}>
      <RequestDetailHeader />
      <Outlet />
    </div>
  );
};

export default RequestDetailOutlet;
