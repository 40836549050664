import { BackgroundInfoSection } from "./components/BackgroundInfoSection/BackgroundInfoSection";
import { DetailsSection } from "./components/DetailsSection/DetailsSection";
import { AdditionalPaymentsDetailsSection } from "./components/AdditionalPaymentsDetailsSection/AdditionalPaymentsDetailsSection";
import styles from "./SeperationReviewPage.module.css";
import { Button } from "@justworkshr/milo-core";
import { AlertMessage } from "pages/team-management/components/AlertMessage";
import { SEPARATION_REVIEW_PAGE_COPY } from "./SeparationReviewPage.constants";
import { useGetMemberSeparation } from "pages/team-management/hooks/useGetMemberSeparation";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { SeparationFlowContext } from "pages/team-management/contexts";
import { Loading } from "../Loading/Loading";
import { BenefitsAlert } from "./components/BenefitsAlert/BenefitsAlert";
import { useNavigate } from "react-router-dom";
import { SEPARATION_FLOW_COPY } from "../SeparationFlow.constants";
import {
  PaymentSettings,
  useConfirmMemberSeparationMutation,
} from "types/generated/operations";
import {
  AdditionalPaymentsFormValues,
  PaymentDetailsFormValues,
  castAmountToNumber,
} from "../SeparationPay";
import {
  completePaymentDetailsKeys,
  nonSeverancePaymentKeys,
} from "../SeparationPay/PaymentDetailsPage/PaymentDetailsPage.utils";
import { ROUTES } from "pages/team-management/constants";
import { scrollToFirstError } from "../SeparationPay/SeparationPay.formUtils";

const { separationReviewPage, footer, subHeading } = styles;

export const SeparationReviewPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { memberUuid } = useParams();
  const navigate = useNavigate();
  const {
    memberSeparation,
    loading: isMemberSeparationLoading,
    error: memberSeparationError,
  } = useGetMemberSeparation(memberUuid);
  const [confirmMemberSeparationMutation] =
    useConfirmMemberSeparationMutation();
  const separationFlowContext = useContext(SeparationFlowContext);
  const {
    member,
    hasAdditionalPayments,
    isKnownAmount,
    additionalPaymentsFormValues,
    paymentDetailsFormValues,
    setShowGlobalGenericError,
  } = separationFlowContext;

  const statusToPath = (status: string | undefined): string => {
    switch (status) {
      case "questionnaire_complete":
      case "questionnaire_skipped":
        return ROUTES.SEPARATION_PAY_ADDITIONAL_PAYMENTS;
      case "intake_complete":
      case "questionnaire_partially_complete":
        return ROUTES.SEPARATION_REASON_QUESTIONNAIRE;
      default:
        return ROUTES.SEPARATION_REASON_INITIAL_INTAKE;
    }
  };

  useEffect(() => {
    if (memberSeparationError) {
      setShowGlobalGenericError?.(true);
    } else {
      setShowGlobalGenericError?.(false);
    }
  }, [memberSeparationError, setShowGlobalGenericError]);

  useEffect(() => {
    const isPaymentsContextValid = (): boolean => {
      return (
        !hasAdditionalPayments || // Either we have no additional payments or...
        !isKnownAmount ||
        (!!additionalPaymentsFormValues && // We have the maps in the context for payment info...
          !!paymentDetailsFormValues &&
          nonSeverancePaymentKeys(additionalPaymentsFormValues).length ===
            completePaymentDetailsKeys(paymentDetailsFormValues).length) // ...and details for each non severance payment.
      );
    };

    if (
      !isMemberSeparationLoading &&
      !memberSeparationError &&
      (!["questionnaire_skipped", "questionnaire_complete"].includes(
        memberSeparation?.status || ""
      ) ||
        !isPaymentsContextValid())
    ) {
      navigate(
        `/team-management/separation-flow/${memberUuid}/${statusToPath(
          memberSeparation?.status
        )}`
      );
    }
  }, [
    additionalPaymentsFormValues,
    hasAdditionalPayments,
    isKnownAmount,
    isMemberSeparationLoading,
    memberSeparation,
    memberSeparationError,
    memberUuid,
    navigate,
    paymentDetailsFormValues,
  ]);

  const answers = memberSeparation?.memberSeparationAnswers;
  const memberSeparationId = memberSeparation?.memberSeparationId;

  const handleBack = () => {
    navigate(
      `/team-management/separation-flow/${memberUuid}/additional-payments`
    );
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const paymentSettings: PaymentSettings[] =
      hasAdditionalPayments &&
      isKnownAmount &&
      additionalPaymentsFormValues &&
      paymentDetailsFormValues
        ? Object.keys(additionalPaymentsFormValues).map((key) => {
            const values: AdditionalPaymentsFormValues &
              PaymentDetailsFormValues = {
              ...additionalPaymentsFormValues[Number(key)],
              ...paymentDetailsFormValues[Number(key)],
            };

            return {
              amount: Math.round(castAmountToNumber(values.amount) * 100),
              bonusType: values.bonus_type,
              deductionsSetting: values.deductions_setting,
              notes: values.notes,
              numberOfPayments: Number(values.number_of_payments),
              payDate: values.pay_date,
              payFrequency: values.pay_frequency,
              payGroup: values.pay_group,
              payeeUuid: memberUuid!,
              isNetPay: values.is_net_pay,
              paymentMethod: values.payment_method,
              taxMethod: values.tax_method,
              type: values.type,
              workEndDate: values.work_end_date,
              workStartDate: values.work_start_date,
              repeatPayment: values.repeat_payment === "yes",
            };
          })
        : [];
    const response = await confirmMemberSeparationMutation({
      variables: {
        memberSeparationId: `${memberSeparationId}`,
        paymentSettings,
      },
    });
    const success = response.data?.confirmMemberSeparation?.success;
    setIsLoading(false);
    if (success) {
      setShowGlobalGenericError?.(false);
      navigate(
        `/team-management/separation-notice/state-requirements/${memberUuid}/`
      );
    } else {
      setShowGlobalGenericError?.(true);
      scrollToFirstError();
    }
  };

  return isMemberSeparationLoading || !answers || !member?.friendlyName ? (
    <Loading />
  ) : (
    <div className={separationReviewPage}>
      <DetailsSection answers={answers} />
      <BackgroundInfoSection answers={answers} />
      {hasAdditionalPayments && isKnownAmount && (
        <AdditionalPaymentsDetailsSection />
      )}
      <BenefitsAlert answers={answers} memberName={member.friendlyName} />

      <AlertMessage>
        <div className={subHeading}>
          {SEPARATION_REVIEW_PAGE_COPY.confirmation_alert_title}
        </div>
        <div>{SEPARATION_REVIEW_PAGE_COPY.confirmation_alert_body}</div>
      </AlertMessage>

      <div className={footer}>
        <Button
          data-testid="back-button"
          variant="outlined"
          onClick={handleBack}
        >
          {SEPARATION_FLOW_COPY.previous}
        </Button>

        <Button
          data-testid="submit-button"
          loading={isLoading}
          variant="filled"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
