import { buildWebPath } from "lib/resource-finder";
import style from "./OnboardingHeader.module.css";
import LogoIcon from "app-frame/common/LogoIcon";
import { useTranslation } from "react-i18next";
import { LanguagePicker } from "../components/LanguagePicker/LanguagePicker";
const { header, logo, headerOptions, logout } = style;

const OnboardingHeader = () => {
  const { t } = useTranslation();
  return (
    <div className={header} aria-label="app-header">
      <LogoIcon className={logo} aria-label={"JustWorks"} />
      <div className={headerOptions}>
        <LanguagePicker />
        <a className={logout} href={buildWebPath("/logout")}>
          {t("Logout")}
        </a>
      </div>
    </div>
  );
};

export default OnboardingHeader;
