/* istanbul ignore file */
/* Will be tested when /review is migrated */
import { fetchClient } from "@justworkshr/fe-utility-kit";
import { buildClockworkWebPath } from "lib/resource-finder";

export type PayDateValidationResponse = {
  data: {
    dueDate: Date | null;
    debitDate: Date | null;
    disbursementDelay: number | null;
    payDateNote: string | null;
    payDate: string;
    payDateChosen: boolean;
    payDateValid: boolean;
  } | null;
  complete: boolean;
  pending: boolean;
  error: boolean;
  message: string | null;
};

const DAILY_CUTOFF_HOUR = 10; //UTC 3pm

const ENDPOINT = "/validate_paydate.json?pay_date={date}";

const SUCCESS_MESSAGE = "Funds will be debited on {debit_on_date}";

export const validatePayDate = async (
  dateString: string
): Promise<PayDateValidationResponse> => {
  if (!dateString) {
    return {
      data: null,
      complete: false,
      pending: false,
      error: false,
      message: null,
    };
  }

  let debitDate = null;
  let dueDate = null;
  let note = null;
  let disbursementDelay = null;

  const validatePayDateUrl = ENDPOINT.replace(/{date}/, dateString);

  try {
    const request = await fetchClient.get(
      buildClockworkWebPath(validatePayDateUrl),
      {
        cache: "no-cache",
      }
    );

    const response = (await request.json()) as {
      error: string;
      valid: boolean;
      due_date: string;
      collect_date: string;
      disbursement_delay: number;
    };

    if (response.valid) {
      debitDate = new Date(
        new Date(response.collect_date).setHours(DAILY_CUTOFF_HOUR)
      );

      dueDate = new Date(
        new Date(response.due_date).setHours(DAILY_CUTOFF_HOUR)
      );

      const formattedDebitDate = new Date(debitDate).toLocaleDateString(
        "en-US",
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }
      );

      note = SUCCESS_MESSAGE.replace(/{debit_on_date}/, formattedDebitDate);

      disbursementDelay = response.disbursement_delay;
    } else {
      dueDate = null;
      debitDate = null;
      note = response.error;
    }

    return {
      data: {
        dueDate,
        debitDate,
        disbursementDelay,
        payDateNote: note,
        payDate: dateString,
        payDateChosen: true,
        payDateValid: response.valid,
      },
      error: false,
      message: note,
      complete: true,
      pending: false,
    };
  } catch {
    return {
      data: null,
      error: true,
      complete: true,
      pending: false,
      message: "Invalid Date",
    };
  }
};
