import React, { useCallback, useContext } from "react";
import styles from "./SeparationReasonStepper.module.css";
import { Stepper, Step } from "@justworkshr/milo-core";
import { useLocation } from "react-router-dom";
import { SeparationFlowContext } from "../../../../contexts";
import { ROUTES, ROUTE_ORDERING } from "pages/team-management/constants";

const { separationReasonStepperWrapper } = styles;

export const SeparationReasonStepper = () => {
  const { member } = useContext(SeparationFlowContext);
  const { pathname } = useLocation();

  const isCurrent = useCallback(
    (paths: Array<string>, stepNumber: number) => {
      const splitPathname = pathname.split("/");
      let endpoint = splitPathname.pop();
      // we pop again if the URL ends with "/"
      if (endpoint === "") {
        endpoint = splitPathname.pop();
      }

      if (paths.includes(endpoint || "")) {
        return "current";
      }

      return ROUTE_ORDERING[endpoint!] > stepNumber ? "complete" : "pending";
    },
    [pathname]
  );

  if (!member?.friendlyName) {
    return null;
  }

  return (
    <div className={separationReasonStepperWrapper}>
      <Stepper orientation="vertical">
        <Step
          key={1}
          stepNumber={1}
          title="Background"
          status={isCurrent(
            [
              ROUTES.SEPARATION_REASON_INITIAL_INTAKE,
              ROUTES.SEPARATION_REASON_QUESTIONNAIRE,
            ],
            1
          )}
        />
        <Step
          key={2}
          stepNumber={2}
          title="Additional payments"
          status={isCurrent(
            [
              ROUTES.SEPARATION_PAY_ADDITIONAL_PAYMENTS,
              ROUTES.SEPARATION_PAY_PAYMENT_DETAILS,
            ],
            2
          )}
        />
        <Step
          key={3}
          stepNumber={3}
          title="Review & submit"
          status={isCurrent([ROUTES.SEPARATION_REVIEW], 3)}
        />
      </Stepper>
    </div>
  );
};
