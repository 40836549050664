import { ReactElement, Ref } from "react";
import SearchResult from "../search-result";
import { GetUserInfoQueryCompanyMember } from "types";
import styles from "./search-result-list.module.css";

const { noResults, searchBoxResults, hidden } = styles;
interface SearchResultListProps {
  resultOutput: GetUserInfoQueryCompanyMember[];
  selectedIndex: number;
  term: string;
  resultsListRef: Ref<HTMLUListElement>;
  shouldShow: boolean;
  setIsMouseInResultList: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}

export default function SearchResultList({
  resultOutput,
  selectedIndex,
  term,
  resultsListRef,
  shouldShow,
  setIsMouseInResultList,
  setSelectedIndex,
}: SearchResultListProps): ReactElement {
  const items = resultOutput.map(({ firstName, lastName, uuid }, idx) => {
    const fullDisplayName = `${firstName} ${lastName}`;
    return (
      <SearchResult
        resultName={fullDisplayName}
        itemIndex={idx}
        uuid={uuid}
        selectedIndex={selectedIndex}
        term={term}
        key={uuid}
        setSelectedIndex={setSelectedIndex}
      />
    );
  });
  const itemsToRender = items.length
    ? items
    : [
        <li className={noResults} key="no results">
          No results
        </li>,
      ];
  const listClassNames = [searchBoxResults];
  if (!shouldShow) {
    listClassNames.push(hidden);
  }
  return (
    <ul
      className={listClassNames.join(" ")}
      ref={resultsListRef}
      aria-label="search results"
      onMouseEnter={() => setIsMouseInResultList(true)}
      onMouseLeave={() => setIsMouseInResultList(false)}
    >
      {itemsToRender}
    </ul>
  );
}
