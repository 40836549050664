import { Route, Routes } from "react-router-dom";
import { Page404 } from "pages";
import { BankFormProvider } from "pages/company-settings/hooks/useBankForm";
import EditBankAccountPage from "pages/company-settings/components/EditBankAccountPage";
import EditAccountTermsConditionsPage from "pages/company-settings/components/BankAccountConfirmationPage";
import MicrodepositVerifyPage from "pages/company-settings/components/MicrodepositVerifyPage";
import SuccessfulMicrodepositInitiatedPage from "pages/company-settings/components/SuccessfulMicrodepositInitiatedPage";
import InitiateMicrodepositPage from "pages/company-settings/components/InitiateMicrodepositPage";
import DebitBlockedErrorPage from "pages/company-settings/components/DebitBlockedErrorPage";
import EditBankAccountReverseWirePage from "pages/company-settings/components/EditBankAccountReverseWirePage";
import ProtectedRoute from "pages/company-settings/components/ProtectedRoute";

const EDIT_BANK_ACCOUNT_PATH = "/edit-bank-account";
const TERMS_CONDITIONS_PATH = "/terms-and-conditions";
const VERIFY_MICRODEPOSIT_AMOUNTS_PATH = "/verify-microdeposit-amounts";
const EDIT_BANK_ACCOUNT_RW_PATH = "/edit-bank-account-reverse-wire";

export default function CompanySettingsApp() {
  return (
    <BankFormProvider>
      <Routes>
        <Route
          path={EDIT_BANK_ACCOUNT_RW_PATH}
          element={
            <ProtectedRoute>
              <EditBankAccountReverseWirePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={EDIT_BANK_ACCOUNT_PATH}
          element={
            <ProtectedRoute>
              <EditBankAccountPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={TERMS_CONDITIONS_PATH}
          element={<EditAccountTermsConditionsPage />}
        />
        <Route
          path={VERIFY_MICRODEPOSIT_AMOUNTS_PATH}
          element={
            <ProtectedRoute>
              <MicrodepositVerifyPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${EDIT_BANK_ACCOUNT_PATH}/error/:errorType`}
          element={<EditBankAccountPage />}
        />
        <Route
          path={`${EDIT_BANK_ACCOUNT_PATH}/debit-blocked`}
          element={<DebitBlockedErrorPage />}
        />
        <Route
          path={`${EDIT_BANK_ACCOUNT_PATH}/microdeposit/initiate`}
          element={<InitiateMicrodepositPage />}
        />
        <Route
          path={`${EDIT_BANK_ACCOUNT_PATH}/microdeposit/initiate/success`}
          element={<SuccessfulMicrodepositInitiatedPage />}
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BankFormProvider>
  );
}
