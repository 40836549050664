import styles from "../../ProjectsPage.module.css";
import { CheckboxInput } from "@justworkshr/milo-form";
import { Button } from "@justworkshr/milo-core";
import { useProjectsStore } from "pages/expenses/store";
import React from "react";
import { ExpenseProject } from "types/Expenses";

const { ProjectsTableRowLastColumn, ProjectsTableRowModifyButtonsWrapper } =
  styles;

interface ProjectTableRowProps {
  project: ExpenseProject;
  handleProjectModal: () => void;
}

export const ProjectTableRow: React.FC<ProjectTableRowProps> = ({
  project,
  handleProjectModal,
}) => {
  const {
    projects: { projects },
    setProjects,
    setActiveProject,
  } = useProjectsStore();

  const handleProjectsChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const updatedProjects = projects.map((prevProject) => {
      return prevProject.projectName === target.name
        ? { ...prevProject, visible: target.checked }
        : prevProject;
    });

    setProjects({ projects: updatedProjects });
  };

  const handleProjectDelete = () => {
    const updatedProjects = projects.filter(
      (prevProject) => prevProject.projectName !== project.projectName
    );

    setProjects({ projects: updatedProjects });
  };

  const renderModifyProjectButtons = () => {
    const showAdditionalModifyButtons = project.uuid === "";

    return (
      <div className={ProjectsTableRowModifyButtonsWrapper}>
        {showAdditionalModifyButtons && (
          <Button
            type="button"
            variant="ghost"
            leftIcon="trash"
            color="destructive"
            disabled={!project.visible}
            onClick={handleProjectDelete}
            data-testid="delete-project-button"
          >
            Delete
          </Button>
        )}
        <Button
          type="button"
          variant="ghost"
          leftIcon="edit"
          disabled={!project.visible}
          onClick={() => {
            setActiveProject({ activeProject: project });
            handleProjectModal();
          }}
          data-testid="edit-project-button"
        >
          Edit
        </Button>
      </div>
    );
  };

  return (
    <tr>
      <td>
        <CheckboxInput
          id={project.projectName}
          checked={project.visible}
          name={project.projectName}
          value={project.projectName}
          onChange={handleProjectsChange}
          data-testid="project-checkbox"
        />
      </td>
      <td>{project.projectName}</td>
      <td className={ProjectsTableRowLastColumn}>
        {renderModifyProjectButtons()}
      </td>
    </tr>
  );
};

export default ProjectTableRow;
