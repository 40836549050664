import { useCallback } from "react";
import { useProcessPmtIntegrationFrameworkActionMutation } from "types/generated/operations";

export interface ActionData {
  url: string;
  secret_key: string;
  keep_alive_status: boolean | null;
}

export interface Integration {
  uuid: string;
}

function useProcessPmtIntegrationAction() {
  const [processIntegrationAction, { loading }] =
    useProcessPmtIntegrationFrameworkActionMutation();

  const processPmtIntegrationAction = useCallback(
    async (integrationName: string, action: string) => {
      try {
        const response = await processIntegrationAction({
          variables: {
            integration_name: integrationName,
            integration_uuid: null,
            action_data: null,
            action: action,
          },
        });
        if (
          response.data?.processPmtIntegrationFrameworkAction?.metadata.success
        ) {
          return {
            success: true,
            actionData: response.data?.processPmtIntegrationFrameworkAction
              ?.actionData
              ? (response.data?.processPmtIntegrationFrameworkAction
                  ?.actionData as ActionData)
              : null,
            integration: response.data?.processPmtIntegrationFrameworkAction
              ?.integration
              ? (response.data?.processPmtIntegrationFrameworkAction
                  ?.integration as Integration)
              : null,
          };
        } else {
          return {
            success: false,
            actionData: null,
            integration: null,
          };
        }
      } catch (error) {
        return {
          success: false,
          actionData: null,
          integration: null,
        };
      }
    },
    [processIntegrationAction]
  );
  return { processPmtIntegrationAction, loading };
}

export default useProcessPmtIntegrationAction;
