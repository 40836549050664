import { ReactElement } from "react";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import Row from "../../../Row/Row";
import RequestChangesAction from "../../../RequestChangesAction/RequestChangesAction";
import { Card } from "@justworkshr/milo-core";
import { EorEmployeePersonalInfo } from "types/employer-of-record";
import BadgeTitle from "../BadgeTitle";

export default function EducationCard(): ReactElement {
  const { profileInfo } = useProfileContextData();

  const personalInfo: EorEmployeePersonalInfo =
    profileInfo?.eorEmployeeProfile.personalInfo;

  const esEducationalQualification = personalInfo?.esEducationalQualification;
  return (
    <>
      <Card
        title={
          (
            <BadgeTitle title="Education" badgeText="Spain" />
          ) as unknown as string
        }
        actions={[<RequestChangesAction />]}
      >
        <Row
          label="Educational qualification"
          value={esEducationalQualification}
          required={false}
        />
      </Card>
    </>
  );
}
