import { ReactElement } from "react";
import LogoIcon from "app-frame/common/LogoIcon";
import { buildWebPath } from "lib/resource-finder";
import SideNav from "./side-nav";
import styles from "./sidebar.module.css";

const { sidebar, logo } = styles;

export default function Sidebar(): ReactElement {
  return (
    <nav className={sidebar} aria-label="app-sidebar">
      <a
        href={buildWebPath("/dashboard")}
        aria-label="Justworks"
        data-testid="company-logo-sidebar"
      >
        <LogoIcon color="white" className={logo} height="16" />
      </a>
      <SideNav />
    </nav>
  );
}
