import {
  Alert,
  Avatar,
  Button,
  Card,
  PageHeader,
} from "@justworkshr/milo-core";
import {
  ActionFooter,
  CheckboxInput,
  FileInput,
  Form,
  FormField,
  TextInput,
} from "@justworkshr/milo-form";
import { buildWebPath } from "lib/resource-finder";
import { FormEvent, ReactElement, useState, useRef } from "react";
import {
  useEditProfileDetailsMutation,
  useGetMemberAccountSettingsQuery,
} from "types/generated/operations";
import {
  ACCEPTED_FILE_TYPES,
  ERROR_ALERT_MESSAGE,
  FILE_COULD_NOT_BE_PROCESSED,
  SUCCESS_ALERT_MESSAGE,
} from "./constants";
import styles from "./edit-profile.module.css";
import { validateEditProfile, validateFile } from "./validator";
import {
  GetUserMenuDocument,
  GetMemberAccountSettingsDocument,
} from "types/generated/operations";
import { useGetFeatureFlag } from "lib/launch-darkly";

const {
  editProfile,
  profileCards,
  avatarSelector,
  fileInput,
  avatarPreview,
  profileForm,
  birthdayValue,
  birthdayNotFound,
} = styles;

interface AlertContentType {
  message: string;
  id: string;
  color: "additive" | "destructive" | "info" | "warning" | "disabled";
}

async function readAsDataURL(file: File): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = () => {
      resolve(fr.result?.toString());
    };
    fr.readAsDataURL(file);
  });
}

export default function EditProfile(): ReactElement {
  const { getFeatureFlag } = useGetFeatureFlag();
  const { data, loading } = useGetMemberAccountSettingsQuery();
  const [editProfileDetails] = useEditProfileDetailsMutation({
    refetchQueries: [
      { query: GetUserMenuDocument },
      { query: GetMemberAccountSettingsDocument },
    ],
  });

  const fileInputRef = useRef<HTMLButtonElement>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState<AlertContentType>({
    message: "",
    id: "success-alert",
    color: "additive",
  });
  const [hideBirthday, setHideBirthday] = useState<boolean | undefined>();
  const [pronouns, setPronouns] = useState<string | undefined>();
  const [formErrors, setFormErrors] = useState<{
    pronouns: string;
    photoFile: string[];
  }>({
    pronouns: "",
    photoFile: [],
  });
  const [photoFile, setPhotoFile] = useState<{
    dataUrl?: string | undefined;
    file?: File | undefined;
    key?: string;
  }>({ dataUrl: undefined, file: undefined, key: undefined });

  if (loading || !data) {
    return <></>;
  }
  const {
    dateOfBirth = "",
    memberHideBirthday,
    friendlyFullName,
    ...others
  } = data?.authenticatedMember;
  const photoUrl = others.photoUrl;
  const birthMonth = dateOfBirth
    ? new Intl.DateTimeFormat("en-US", {
        month: "long",
        timeZone: "UTC",
      }).format(new Date(dateOfBirth))
    : "";
  const dayOfBirth = dateOfBirth
    ? new Intl.DateTimeFormat("en-US", {
        day: "numeric",
        timeZone: "UTC",
      }).format(new Date(dateOfBirth))
    : "";
  const memberPronouns = others.pronouns ?? "";

  const noUpdate =
    (hideBirthday === undefined || hideBirthday === memberHideBirthday) &&
    (pronouns === undefined || pronouns === memberPronouns) &&
    !photoFile.dataUrl &&
    !photoFile.file;
  const hasErrors =
    formErrors.pronouns !== "" || formErrors.photoFile.length > 0;
  const disableSaveButton = noUpdate || hasErrors;

  const onSelectFile = async (
    file: File | File[] | undefined
  ): Promise<void> => {
    let selectedFile: File | undefined = undefined;
    if (file && Array.isArray(file)) {
      if (file.length > 0) {
        selectedFile = file[0];
      } else if (file instanceof File) {
        selectedFile = file;
      }
    }
    const fileValidationErrors = validateFile(selectedFile);
    if (fileValidationErrors.length === 0) {
      const processedFile =
        selectedFile !== undefined
          ? await readAsDataURL(selectedFile)
          : undefined;
      if (selectedFile && processedFile === undefined) {
        setPhotoFile({ dataUrl: undefined, file: undefined });
        setFormErrors({
          ...formErrors,
          photoFile: [FILE_COULD_NOT_BE_PROCESSED],
        });
      } else setFormErrors({ ...formErrors, photoFile: [] });
      setPhotoFile({
        dataUrl: processedFile,
        file: processedFile ? selectedFile : undefined,
      });
    } else {
      setPhotoFile({ dataUrl: undefined, file: undefined });
      setFormErrors({ ...formErrors, photoFile: fileValidationErrors });
    }
  };

  const onSelectFileWithPresignedUrl = async (
    file: File | File[] | undefined
  ): Promise<void> => {
    let selectedFile: File | undefined = undefined;
    if (file && Array.isArray(file)) {
      if (file.length > 0) {
        selectedFile = file[0];
      }
    } else if (file instanceof File) {
      selectedFile = file;
    }

    if (!selectedFile) {
      return;
    }

    const fileValidationErrors = validateFile(selectedFile);
    if (fileValidationErrors.length === 0) {
      const processedFile =
        selectedFile !== undefined
          ? await readAsDataURL(selectedFile)
          : undefined;
      if (selectedFile && processedFile === undefined) {
        setPhotoFile({ dataUrl: undefined, file: undefined });
        setFormErrors({
          ...formErrors,
          photoFile: [FILE_COULD_NOT_BE_PROCESSED],
        });
      } else setFormErrors({ ...formErrors, photoFile: [] });

      try {
        const presignedResponse = await fetch(
          `http://localhost:3001/presigned-url?key=${selectedFile.name}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!presignedResponse.ok) {
          throw new Error("Could not get pre-signed URL");
        }
        const response = await presignedResponse.json();

        const uploadResponse = await fetch(response.presignedUrl, {
          method: "PUT",
          body: selectedFile,
          headers: {
            "Content-Type": selectedFile.type,
          },
        });
        if (!uploadResponse.ok) {
          throw new Error("Failed to upload file");
        }
        setPhotoFile({
          dataUrl: processedFile,
          file: processedFile ? selectedFile : undefined,
          key: selectedFile.name,
        });
      } catch (error) {
        setFormErrors({ ...formErrors, photoFile: ["Error uploading file"] });
      }
    } else {
      setPhotoFile({ dataUrl: undefined, file: undefined });
      setFormErrors({ ...formErrors, photoFile: fileValidationErrors });
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowAlert(false);
    const photoObj =
      photoFile.dataUrl && photoFile.file
        ? {
            contentType: photoFile.file.type,
            originalFilename: photoFile.file.name,
            blobFile: photoFile.dataUrl,
          }
        : null;
    const currentHideBirthday = hideBirthday ?? memberHideBirthday;
    const currentPronouns = pronouns ?? memberPronouns;
    const response = await editProfileDetails({
      variables: {
        hideBirthday: currentHideBirthday,
        pronouns: currentPronouns,
        photo: photoObj,
      },
    });
    if (response.data?.editProfileDetails?.success) {
      setAlertContent({
        message: SUCCESS_ALERT_MESSAGE,
        id: "success-alert",
        color: "additive",
      });
      if (
        photoFile.dataUrl &&
        photoFile.file &&
        fileInputRef.current !== null
      ) {
        fileInputRef.current.click();
      }
    } else {
      setAlertContent({
        message: ERROR_ALERT_MESSAGE,
        id: "error-alert",
        color: "destructive",
      });
    }
    window.scrollTo(0, 0);
    setShowAlert(true);
  };

  const handleSubmitWithMutation = async (
    event: FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setShowAlert(false);
    const currentHideBirthday = hideBirthday ?? memberHideBirthday;
    const currentPronouns = pronouns ?? memberPronouns;
    const photoPath = photoFile.file?.name;
    const response = await editProfileDetails({
      variables: {
        hideBirthday: currentHideBirthday,
        pronouns: currentPronouns,
        photoPath: photoPath,
        photo: null,
      },
    });

    if (response.data?.editProfileDetails?.success) {
      setAlertContent({
        message: SUCCESS_ALERT_MESSAGE,
        id: "success-alert",
        color: "additive",
      });
      if (
        photoFile.dataUrl &&
        photoFile.file &&
        fileInputRef.current !== null
      ) {
        fileInputRef.current.click();
      }
    } else {
      setAlertContent({
        message: ERROR_ALERT_MESSAGE,
        id: "error-alert",
        color: "destructive",
      });
    }
    window.scrollTo(0, 0);
    setShowAlert(true);
  };

  const handlePronounsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setPronouns(newValue);
    validateEditProfile(newValue, formErrors, setFormErrors);
  };

  return (
    <div id="EditProfileApp">
      <Alert
        color={alertContent.color}
        dismissible
        visible={showAlert}
        id={alertContent.id}
      >
        {alertContent.message}
      </Alert>
      <div className={`${editProfile} milo--grid`}>
        <PageHeader
          title="Profile"
          linkPrevious={
            <a href={buildWebPath("/account_settings")}>
              Back to Account Settings
            </a>
          }
        />
        <div className={profileCards}>
          <Form
            onSubmit={(event) => {
              getFeatureFlag("release-upload-file-with-presigned-url")
                ? handleSubmitWithMutation(event)
                : handleSubmit(event);
            }}
          >
            <Card
              title="Profile picture"
              description="Add your picture so your coworkers know what you look like. You can add a JPG, GIF or PNG."
            >
              <div className={avatarSelector}>
                <FormField
                  name="photo-file-input"
                  label="Profile picture"
                  message="Your new profile picture must be a PNG, JPEG, or GIF."
                  error={formErrors.photoFile}
                >
                  <FileInput
                    accept={ACCEPTED_FILE_TYPES}
                    onFileChange={(file) => {
                      getFeatureFlag("release-upload-file-with-presigned-url")
                        ? onSelectFileWithPresignedUrl(file)
                        : onSelectFile(file);
                    }}
                    data-testid="file-input"
                    ref={fileInputRef}
                    className={fileInput}
                  />
                </FormField>
                <FormField name="avatar" label="Preview" required={true}>
                  <div className={avatarPreview}>
                    <div
                      data-heap-redact-attributes="src"
                      data-dd-action-name="large Avatar in Edit Profile"
                    >
                      <Avatar
                        name={friendlyFullName}
                        photoUrl={photoFile.dataUrl ?? photoUrl ?? ""}
                        size="2xl"
                      />
                    </div>
                    <div
                      data-heap-redact-attributes="src"
                      data-dd-action-name="small Avatar in Edit Profile"
                    >
                      <Avatar
                        name={friendlyFullName}
                        photoUrl={photoFile.dataUrl ?? photoUrl ?? ""}
                        size="md"
                      />
                    </div>
                  </div>
                </FormField>
              </div>
            </Card>
            <Card title="Profile preferences">
              <div className={profileForm}>
                <FormField
                  name="birthday"
                  label="Birthday"
                  required={true}
                  message="If your birthday isn't correct, contact your company admin."
                >
                  {birthMonth !== "" ? (
                    <p
                      className={birthdayValue}
                    >{`${birthMonth} ${dayOfBirth}`}</p>
                  ) : (
                    <p className={birthdayNotFound}>Not on file</p>
                  )}
                </FormField>
                <CheckboxInput
                  name="hideBirthday"
                  id="showBirthday"
                  data-testid="hide-birthday"
                  checked={hideBirthday ?? memberHideBirthday}
                  onChange={(event) => setHideBirthday(event.target.checked)}
                  label="Hide my birthday (month and day) on my public profile and in the company directory"
                  value="hideBirthday"
                />

                <FormField
                  name="pronouns"
                  label="Pronouns"
                  error={formErrors.pronouns}
                  message="This information will be displayed on your public profile page and in your company org chart."
                >
                  <TextInput
                    name="pronouns"
                    placeholder="e.g. He/him, She/her, They/them"
                    value={pronouns ?? memberPronouns ?? ""}
                    onChange={handlePronounsChange}
                    autoComplete="off"
                  />
                </FormField>
              </div>
            </Card>
            <ActionFooter
              actions={[
                <Button
                  variant="outlined"
                  as="a"
                  href={buildWebPath("/account_settings")}
                  key="cancel-button"
                >
                  Cancel
                </Button>,
                <Button
                  data-testid="submit-button"
                  type="submit"
                  disabled={disableSaveButton}
                  key="save-button"
                >
                  Save updates
                </Button>,
              ]}
            />
          </Form>
        </div>
      </div>
    </div>
  );
}
