import classes from "./EmployerDetailsPage.module.css";
import benefitsClasses from "pages/benefits/benefits.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";
import { Box, Button, PageHeader } from "@justworkshr/milo-core";
import { buildClockworkWebPath } from "lib/resource-finder";
import {
  useGetCompany401kDetailsQuery,
  useGetUserPermissionsQuery,
} from "types/generated/operations";
import clsx from "lib/styling-utils/clsx";
import { PermissionsError } from "../../PermissionsError";
import EmpowerHeader from "../../../images/401k/empower-header.png";
import EmpowerLogoSidebar from "../../../images/401k/empower-logo-sidebar.png";
import PlanDetails from "./PlanDetails";

const EmployerDetailsPage = () => {
  const userPermissions = useGetUserPermissionsQuery();
  const company401kDetails = useGetCompany401kDetailsQuery();

  if (userPermissions.loading) {
    return null;
  }

  const PERMISSION_BENEFITS = 1;
  if (
    !userPermissions.data?.currentMember?.permissions?.includes(
      PERMISSION_BENEFITS
    )
  ) {
    return <PermissionsError />;
  }

  const plan = company401kDetails.data?.currentMember?.company?.company401kPlan;

  let planDetails;
  if (company401kDetails.loading) {
    planDetails = <p>Loading plan details...</p>;
  } else if (!plan) {
    planDetails = <p>Plan not found.</p>;
  } else {
    planDetails = <PlanDetails plan={plan} />;
  }

  return (
    <div className={classes.EmployerDetailsPage}>
      <PageHeader
        title="Empower 401(k)"
        linkPrevious={
          <a href={buildClockworkWebPath("/benefits/view")}>
            Back to Benefits Center
          </a>
        }
      />

      <div className={clsx("milo--grid", classes.main)}>
        <div className={classes.content}>
          <div className={classes.heroImage}>
            <img src={EmpowerHeader} alt="Empower Logo" />
          </div>
          {planDetails}
        </div>

        <div className={classes.sidebar}>
          <Box
            border={{
              borderColor: "neutralSubtle",
              borderRadius: "md",
              borderWidth: "sm",
            }}
            padding="2xl"
          >
            <div className={classes.empowerLogoSmall}>
              <img src={EmpowerLogoSidebar} alt="Empower Logo" />
            </div>
            <Box backgroundColor="neutralSubtle" padding="md">
              <p className={benefitsClasses.benefitsParagraph}>
                You are offering Empower.
              </p>
              <p className={benefitsClasses.benefitsParagraph}>
                Need help? Reach out at{" "}
                <a href="mailto:support@justworks.com">support@justworks.com</a>
                .
              </p>
            </Box>

            <Button
              variant="filled"
              style={{ width: "100%" }}
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href={"https://www.empower.com/justworks"}
              className={classes.loginButton}
            >
              Login to Empower
              <SystemIcon iconName="external-link" size="medium" />
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default EmployerDetailsPage;
