import { ComponentProps, ReactElement, useEffect, useState } from "react";
import { Alert, Button, PageHeader, Spinner } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import {
  useGetIntegrationSolutionDataQuery,
  useGetPmtIntegrationFrameworkDataQuery,
  GetPmtIntegrationFrameworkDataQueryVariables,
} from "types/generated/operations";
import { CompanyAdmin } from "types";
import { PERMISSION } from "types/RequiredPermissions";
import IntegrationCard from "./components/IntegrationCard/integration-card";
import CardSection from "./components/CardSection/card-section";
import { REQUEST_INTEGRATION_URL } from "./constants";
import {
  getAccountingIntegrationsData,
  getGreenhouseIntegrationData,
  getPmtIntegrationsData,
  sortCardsAlphabetically,
} from "./utils";

import {
  useAlert,
  standardAlerts,
} from "pages/pmt/integrations/hooks/useAlert";
import styles from "./integration-marketplace.module.css";
import { setPmtIntegrationVariables, buildCardSections } from "./utils";
import { FLAG_NAMES, flagIsEnabled } from "lib/environment";
import { useResetScrollOnRouteChange } from "../hooks/useResetScrollOnRouteChange";

const {
  integrationMarketplace,
  headerAndButton,
  buttonContent,
  cardSectionWrapper,
  requestButton,
  header,
} = styles;

export type CardData = ComponentProps<typeof IntegrationCard>;

export default function IntegrationMarketplace(): ReactElement {
  const { data, loading, error } = useGetIntegrationSolutionDataQuery();
  const variables = {
    ...setPmtIntegrationVariables(),
  } as GetPmtIntegrationFrameworkDataQueryVariables;
  const {
    data: pmtIntegrationsData,
    loading: pmtIntegrationsDataLoading,
    error: pmtIntegrationsDataError,
  } = useGetPmtIntegrationFrameworkDataQuery({
    variables: variables,
  });

  const [cards, setCards] = useState<CardData[]>([]);
  const { alertProps, setAlert } = useAlert();
  const userPermissions: PERMISSION[] | undefined =
    data?.authenticatedMember.permissions;
  const companyAdmins: CompanyAdmin[] | undefined =
    data?.authenticatedMember.company.admins;
  const pmtIntegrationsEnabled = flagIsEnabled(
    FLAG_NAMES.PMT_INTEGRATIONS,
    data?.authenticatedMember.company.uuid
  );
  const combinedLoading = loading || pmtIntegrationsDataLoading;
  const eitherQueryError =
    error || (pmtIntegrationsEnabled && pmtIntegrationsDataError);
  const showAllIntegrations = data && !combinedLoading && pmtIntegrationsData;
  const showCurrentIntegrations = data && !loading;

  const shouldShowPmtIntegrations = pmtIntegrationsEnabled
    ? showAllIntegrations
    : showCurrentIntegrations;

  useEffect(() => {
    const pmtIntegrationsSuccess =
      pmtIntegrationsData?.pmtIntegrationsFramework?.solutions?.metadata
        .success;
    if (eitherQueryError) {
      setAlert(standardAlerts.somethingWentWrong);
    }
    if (
      pmtIntegrationsEnabled &&
      pmtIntegrationsData &&
      !pmtIntegrationsSuccess
    ) {
      setAlert(standardAlerts.failedToLoadIntegrations);
    }
    if (shouldShowPmtIntegrations) {
      const accountingIntegrations: (CardData | null)[] | undefined =
        getAccountingIntegrationsData(
          data?.accountingIntegrations?.integrationsInfo
        );
      const greenhouseIntegration: CardData[] = getGreenhouseIntegrationData(
        data?.authenticatedMember?.company?.greenhouseCandidateHiredIntegration
      );

      const pmtIntegrations: (CardData | null)[] = pmtIntegrationsEnabled
        ? getPmtIntegrationsData(
            pmtIntegrationsData?.pmtIntegrationsFramework?.solutions?.data,
            pmtIntegrationsData?.pmtIntegrationsFramework?.integrations?.data,
            data?.authenticatedMember.company.uuid
          )
        : [];
      const formattedCards = [
        accountingIntegrations,
        pmtIntegrations,
        greenhouseIntegration,
      ]
        .flat()
        .filter((integration) => integration) as CardData[];
      setCards(sortCardsAlphabetically(formattedCards));
    }
  }, [
    data,
    eitherQueryError,
    combinedLoading,
    pmtIntegrationsData,
    pmtIntegrationsEnabled,
    shouldShowPmtIntegrations,
    setAlert,
    setCards,
  ]);

  const cardSections = buildCardSections(cards);
  useResetScrollOnRouteChange();

  return (
    <div id={"IntegrationMarketplaceApp"}>
      <Alert {...alertProps} />

      <div className={`${integrationMarketplace} milo--grid`}>
        <div className={headerAndButton}>
          <PageHeader className={header} title="Integrations">
            Connect your favorite tools to Justworks to make running and growing
            your business even easier.
          </PageHeader>
          {shouldShowPmtIntegrations && (
            <Button
              className={requestButton}
              variant="outlined"
              as="a"
              href={REQUEST_INTEGRATION_URL}
              target="_blank"
            >
              <div className={buttonContent}>
                <SystemIcon iconName="external-link" color="brand" />
                Request an Integration
              </div>
            </Button>
          )}
        </div>
      </div>
      {combinedLoading && <Spinner />}
      {shouldShowPmtIntegrations && (
        <div className={`milo--grid ${cardSectionWrapper}`}>
          {cardSections.map((section) => (
            <CardSection
              {...section}
              key={section.header}
              userPermissions={userPermissions}
              companyAdmins={companyAdmins}
            />
          ))}
        </div>
      )}
    </div>
  );
}
