import styles from "./PendingFirmTable.module.css";
import { Link } from "react-router-dom";
import { Button } from "@justworkshr/milo-core";
import { ThirdPartyTable } from "pages/firms/components";
import { FirmsPageQuery } from "types/generated/operations";
import { useTranslation } from "react-i18next";
import { buildRelativeRemoveFirmLink } from "../../helpers";

const { tableActionBtn, tableRow } = styles;

type Firm = NonNullable<FirmsPageQuery["firm"]>;

interface PendingFirmTableProps {
  firm: Firm;
}

const PendingFirmTable = ({ firm }: PendingFirmTableProps) => {
  const { t } = useTranslation();

  return (
    <ThirdPartyTable
      tableKey="pending-firms"
      tableTitle="Invited firm"
      dataCountLabel={`1 person`}
    >
      <>
        <thead>
          <tr className={tableRow}>
            <td>Name</td>
            <td>Email</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr className={tableRow} key={firm.uuid}>
            <td data-heap-redact-text={true}>{firm.invitation?.fullName}</td>
            <td data-heap-redact-text={true}>{firm.invitation?.email}</td>
            <td>
              <Button
                className={tableActionBtn}
                size="sm"
                variant="ghost"
                leftIcon="trash"
                color="destructive"
                as={Link}
                state={{ preserveScrollPosition: true }}
                to={buildRelativeRemoveFirmLink(firm.uuid)}
              >
                {t("Remove firm")}
              </Button>
            </td>
          </tr>
        </tbody>
      </>
    </ThirdPartyTable>
  );
};

export default PendingFirmTable;
