import { Alert, Button, Card, PageHeader } from "@justworkshr/milo-core";
import {
  ActionFooter,
  Form,
  FormField,
  Select,
  SelectOption,
  TextInput,
} from "@justworkshr/milo-form";
import { Formik, type FormikProps, type FormikValues } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useOnboardingForm from "../../../hooks/useOnboardingForm";
import Layout from "../../../Layout";
import onboardingStyles from "../../../Onboarding.module.css";
import { NATIONALITIES } from "../../constants";
import styles from "../../ProfileInfo.module.css";
import { ProfileInfoFormTypeCO as ProfileInfoFormType } from "../../types";
import BaseFormFragment, {
  initialValues as baseInitialValues,
  schema as baseSchema,
} from "../base/BaseFormFragment";

const { inputField } = styles;

const state_key = "profile-info";

const NATIONALITIES_OPTIONS = [
  {
    value: "",
    description: "",
  },
  ...NATIONALITIES,
] as const;

const ProfileInfoCO: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    form: { submitting, showErrorAlert, errorAlertMessage, onSubmit },
    memberOnboarding: {
      stepNumber,
      redirecting,
      profileInfo,
      idVerificationStatus,
    },
  } = useOnboardingForm(state_key);

  const onFormSubmit = (values: FormikValues) => {
    // Compose date values.
    const profileInfoDateOfBirth =
      values.profileInfo.dateOfBirth.year +
      "-" +
      values.profileInfo.dateOfBirth.month +
      "-" +
      values.profileInfo.dateOfBirth.day;

    // Temporary fix: https://justworks.atlassian.net/browse/IEORN-2351
    delete values.profileInfo?.confirmIdentifierNumber;
    delete values.profileInfo?.identifierNumber;

    onSubmit({
      profileInfo: {
        ...values.profileInfo,
        dateOfBirth: profileInfoDateOfBirth,
      },
    });
  };

  /* CO specific fields */
  const schemaCO = Yup.object({
    nationality: Yup.string()
      .oneOf(NATIONALITIES_OPTIONS.map((nationality) => nationality.value))
      .required("Required."),
    coCedulaDeCiudadania: Yup.string()
      .min(6, "The minimum length is 6 characters.")
      .max(10, "The maximum length is 10 characters.")
      .required("This field is required."),
    coNombreEps: Yup.string().optional(),
    coNombreFondoDePensiones: Yup.string().optional(),
    coNombreArl: Yup.string().optional(),
  });
  const formSchema = Yup.object().shape({
    profileInfo: schemaCO.concat(baseSchema(t)),
  });
  type SchemaType = Yup.InferType<typeof schemaCO>;
  type FormType = {
    profileInfo: SchemaType;
  };
  const initialValuesCO: FormType = {
    profileInfo: {
      nationality: "",
      coCedulaDeCiudadania: "",
      coNombreEps: "",
      coNombreFondoDePensiones: "",
      coNombreArl: "",
    },
  };

  const getInitialValues = (): ProfileInfoFormType => {
    const initialValues = {
      profileInfo: {
        ...baseInitialValues.profileInfo,
      },
    };

    const defaultInitialValues = profileInfo || initialValues;

    return {
      profileInfo: {
        ...initialValuesCO.profileInfo,
        ...defaultInitialValues.profileInfo,
      },
    };
  };

  /* END CO specific fields */

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {errorAlertMessage ||
            t("An error occurred while submitting your profile information.")}
        </Alert>

        <PageHeader title="Complete your profile" />

        <Formik
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validationSchema={formSchema}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            touched,
            values,
          }: FormikProps<ProfileInfoFormType>) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Card title={t("Personal information")}>
                  <BaseFormFragment />

                  {/* <!-- CO specific fields --> */}
                  <div className={inputField}>
                    <FormField
                      name="profileInfo.nationality"
                      required
                      label={t("Nationality")}
                      error={
                        touched?.profileInfo?.nationality
                          ? errors?.profileInfo?.nationality
                          : ""
                      }
                    >
                      <Select
                        name="profileInfo.nationality"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.profileInfo.nationality}
                        placeholder={
                          values.profileInfo.nationality.length > 0
                            ? undefined
                            : t("Select Nationality")
                        }
                      >
                        {NATIONALITIES_OPTIONS.map((country) => (
                          <SelectOption
                            value={country?.value}
                            key={country?.value}
                          >
                            {country?.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      error={
                        touched?.profileInfo?.coCedulaDeCiudadania
                          ? errors?.profileInfo?.coCedulaDeCiudadania
                          : ""
                      }
                      label={t(
                        "Cédula de Ciudadanía Number (National ID number)"
                      )}
                      required
                    >
                      <TextInput
                        name="profileInfo.coCedulaDeCiudadania"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.profileInfo.coCedulaDeCiudadania}
                      />
                    </FormField>
                  </div>
                  <div className={inputField}>
                    <FormField
                      error={
                        touched?.profileInfo?.coNombreEps
                          ? errors?.profileInfo?.coNombreEps
                          : ""
                      }
                      label={t("Nombre de EPS (EPS fund name)")}
                    >
                      <TextInput
                        name="profileInfo.coNombreEps"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.profileInfo.coNombreEps}
                      />
                    </FormField>
                  </div>
                  <div className={inputField}>
                    <FormField
                      error={
                        touched?.profileInfo?.coNombreFondoDePensiones
                          ? errors?.profileInfo?.coNombreFondoDePensiones
                          : ""
                      }
                      label={t("Fondo de Pensiones (Pension fund name)")}
                    >
                      <TextInput
                        name="profileInfo.coNombreFondoDePensiones"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.profileInfo.coNombreFondoDePensiones}
                      />
                    </FormField>
                  </div>
                  <div className={inputField}>
                    <FormField
                      error={
                        touched?.profileInfo?.coNombreArl
                          ? errors?.profileInfo?.coNombreArl
                          : ""
                      }
                      label={t("Nombre de ARL (Severance fund name)")}
                    >
                      <TextInput
                        name="profileInfo.coNombreArl"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.profileInfo.coNombreArl}
                      />
                    </FormField>
                  </div>

                  {/* <!-- END CO specific fields --> */}
                </Card>

                <ActionFooter
                  className={onboardingStyles.footer}
                  actions={[
                    <Button
                      color="brand"
                      key="submit-btn"
                      loading={submitting}
                      type="submit"
                    >
                      {t("Save & continue")}
                    </Button>,
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </Layout>
  );
};

export default ProfileInfoCO;
