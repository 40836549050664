import { Button } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import { Link } from "react-router-dom";
import { WAIVER_EDIT_URL } from "pages/workers-compensation/document-request/constants";

import { JobAddressReview } from "./job-address-review";
import { WaiverJobLocation } from "../../types";
import styles from "./location-review.module.css";
import sharedStyles from "../waiver-review.module.css";

const { formBorder, formInfo, formInfoHeader, header } = styles;
const { editButtonStyle, removePadding } = sharedStyles;

export const LocationReview = ({
  jobLocations,
}: {
  jobLocations: WaiverJobLocation[];
}) => {
  return (
    <section className={formBorder}>
      <div className="milo--grid">
        <div className={formInfo}>
          <div className={header}>
            <h3 className={formInfoHeader}>Job locations</h3>
            <Button
              to={`${WAIVER_EDIT_URL}?currentStep=3`}
              as={Link}
              variant="ghost"
              className={removePadding}
            >
              <span className={editButtonStyle}>
                <SystemIcon iconName="edit" color="brand" />
                Edit
              </span>
            </Button>
          </div>

          {jobLocations.map((location: WaiverJobLocation, index: number) => (
            <JobAddressReview
              location={location}
              locationNumber={1 + +index}
              key={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
