import React from "react";
import { mergeClassNames } from "pages/expenses/utils";
import styles from "./ToggleSwitch.module.css";

const {
  ToggleSwitchWrapper,
  Switch,
  CheckboxToggle,
  Slider,
  Round,
  Checked,
  ExpensesEnabledLabel,
  ToggleLabel,
} = styles;

interface ToggleSwitchProps {
  checked: boolean;
  disabled: boolean;
  onToggleChange: (checked: boolean) => void;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  checked,
  disabled,
  onToggleChange,
}) => {
  const toggleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    onToggleChange(event.target.checked);
  };

  return (
    <div
      className={mergeClassNames([
        ToggleSwitchWrapper,
        disabled ? "Disabled" : "",
      ])}
    >
      <label className={Switch}>
        <input
          type="checkbox"
          className={CheckboxToggle}
          onChange={toggleCheck}
          checked={checked}
          disabled={disabled}
        />
        <span
          className={mergeClassNames([Slider, Round, checked ? Checked : ""])}
          data-testid="expenses-toggle"
        />
        <span
          className={mergeClassNames([
            ToggleLabel,
            checked ? ExpensesEnabledLabel : "",
          ])}
        >
          {checked ? "Enabled" : "Disabled"}
        </span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
