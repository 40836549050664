import {
  CATEGORY_STATUSES,
  EXPENSE_REIMBURSABLE_TYPE,
  EXPENSE_NON_REIMBURSABLE_TYPE,
} from "pages/expenses/constants";
import { handleDate } from "pages/expenses/utils";
import {
  ExpenseProject,
  ExpenseCategory,
  ExpenseLibraryExpenseReceipt,
  RequestDetailExpense,
} from "types/Expenses";
import isEqual from "lodash/isEqual";
import { GetExpenseDetailsPageQuery } from "types/generated/operations";

export const parseExpenseDetailPageData = (
  data: GetExpenseDetailsPageQuery | undefined
) => {
  const projects = data?.expenseManagement?.projects || [];
  const categories = data?.expenseManagement?.categories || [];
  const authenticatedMember = data?.authenticatedMember?.friendlyFullName;

  const categoriesMapping = [...categories].reduce((acc, category) => {
    const categoryKey = category.name as keyof ExpenseCategory;

    acc[categoryKey] = category;

    return acc;
  }, {} as Record<keyof ExpenseCategory, ExpenseCategory>);

  const projectsMapping = [...projects].reduce((acc, project) => {
    const projectKey = project.projectName as keyof ExpenseProject;

    acc[projectKey] = project;

    return acc;
  }, {} as Record<keyof ExpenseProject, ExpenseProject>);

  return {
    projects,
    categories,
    projectsMapping,
    categoriesMapping,
    authenticatedMember,
  };
};

export const isFutureDate = (date: string) => {
  const currentDate = new Date();
  const selectedDate = new Date(date);

  return selectedDate > currentDate;
};

export const getIsFormValid = ({
  metadata,
  form,
}: {
  metadata: {
    receipts: ExpenseLibraryExpenseReceipt[];
    selectedCategory: ExpenseCategory;
  };
  form: {
    [key: string]: {
      value: string;
      error: string;
    };
  };
}) => {
  const formErrors = Object.entries(form).reduce((acc, [, data]) => {
    if (data.error) {
      acc.push(data.error);
    }

    return acc;
  }, [] as string[]);

  const hasFormErrors = formErrors.length > 0;

  if (hasFormErrors) {
    return false;
  }

  const formValues = Object.entries(form).reduce((acc, [key, data]) => {
    acc[key] = data.value;

    return acc;
  }, {} as { [key: string]: string });

  const requiredFormKeys = Object.entries(metadata.selectedCategory).reduce(
    (acc, [key, value]) => {
      if (value === CATEGORY_STATUSES.REQUIRED) {
        acc.push(key);
      }

      return acc;
    },
    [] as string[]
  );

  for (const key of requiredFormKeys) {
    const isReceiptKey = key === "receipt";
    const billableToClient = key === "billableToClient";

    if (billableToClient && !formValues["isBillableToClient"]) {
      return false;
    } else if (isReceiptKey && metadata.receipts.length === 0) {
      return false;
    } else if (!formValues[key] && !isReceiptKey && !billableToClient) {
      return false;
    }
  }

  return true;
};

export const formatNumberWithCommas = (amount: number | string) => {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const isString = typeof amount === "string";

  if (isString && amount.includes(",")) {
    return parseFloat(amount.replaceAll(",", "")).toLocaleString(
      "en-US",
      options
    );
  }

  return parseFloat(amount.toString()).toLocaleString("en-US", options);
};

export const convertNumberToPennies = (numberString: string) => {
  const cleanedString = numberString.replace(/[$,]/g, "");

  return Math.round(parseFloat(cleanedString) * 100);
};

export const isFormFieldHidden = (
  field: keyof ExpenseCategory,
  category: ExpenseCategory
) => {
  if (category[field] === CATEGORY_STATUSES.HIDDEN) {
    return true;
  }

  return false;
};

export const handleExpenseType = (expenseType: string | null | undefined) => {
  if (!expenseType || expenseType === EXPENSE_REIMBURSABLE_TYPE) {
    return "true";
  }

  return "false";
};

export const getExpenseType = (expenseType: string) => {
  if (expenseType === "true") {
    return EXPENSE_REIMBURSABLE_TYPE;
  }

  return EXPENSE_NON_REIMBURSABLE_TYPE;
};

export const getIsExpenseUpdated = (
  form: {
    [key: string]: {
      value: string;
      error: string;
    };
  },
  editExpense: RequestDetailExpense | null,
  {
    receipts,
    selectedProject,
    selectedCategory,
  }: {
    receipts: ExpenseLibraryExpenseReceipt[];
    selectedCategory: ExpenseCategory | null;
    selectedProject: ExpenseProject | null;
  }
) => {
  const formValues = Object.entries(form).reduce((acc, [key, data]) => {
    acc[key] = data.value;

    return acc;
  }, {} as { [key: string]: string });

  return !isEqual(
    {
      ...formValues,
      category: selectedCategory?.name || "",
      project: selectedProject?.displayName || "",
      receipts: receipts.map((receipt) => receipt.uuid),
    },
    {
      category: editExpense?.category?.name || "",
      project: editExpense?.project?.displayName || "",
      merchant: editExpense?.merchant || "",
      attendees: editExpense?.attendees || "",
      description: editExpense?.description || "",
      transactionDate: editExpense?.transactionDate
        ? handleDate(editExpense.transactionDate)
        : "",
      amount: editExpense?.amount
        ? formatNumberWithCommas(editExpense.amount / 100.0)
        : null,
      isBillableToClient: editExpense?.isBillableToClient ? "true" : "false",
      expenseType: handleExpenseType(editExpense?.expenseType),
      receipts: editExpense?.receipts?.map((receipt) => receipt.uuid),
    }
  );
};
