import classes from "./LearnPageEmployee.module.css";
import benefitsClasses from "pages/benefits/benefits.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, PageHeader, TabList, Tab } from "@justworkshr/milo-core";
import { buildClockworkWebPath } from "lib/resource-finder";
import { useGetUser401kInfoQuery } from "types/generated/operations";
import OverviewTab from "./OverviewTab";
import EmpowerTab from "./EmpowerTab";
import EligibilityTab from "./EligibilityTab";
import FaqTab from "./FaqTab";
import EmpowerHeader from "../../../images/401k/empower-header.png";
import EmpowerLogoSidebar from "../../../images/401k/empower-logo-sidebar.png";
import ResourcesBox from "../resources-box";

const LearnPageEmployee = () => {
  const { tabId } = useParams();

  const navigate = useNavigate();

  const { data, loading } = useGetUser401kInfoQuery();
  if (loading) {
    return <></>;
  }

  const showEnrollmentFlow =
    data?.currentMember.member401kInfo.enrollmentFlowEligible;

  const goToTab = (tabId: string) => {
    navigate(`/benefits/setup/401k/employee/learn/${tabId}`);
  };

  const getStartedUrl = () => {
    if (data?.currentMember?.member401kInfo?.showYtd401kActionCard) {
      return "/cf/benefits/setup/401k/employee/additional-contributions";
    } else {
      return "https://justworks.empower-retirement.com/participant/#/register/?accu=Justworks";
    }
  };

  const tabIds = ["overview", "empower", "eligibility", "faq"];

  return (
    <div className={classes.benefits401kLearnPageEmployee}>
      <div>
        <a href={buildClockworkWebPath("/my_benefits/retirement_planning")}>
          Back to Add Benefits{" "}
        </a>
      </div>

      <PageHeader title="401(k) with Empower" />

      <div className="milo--grid" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <div style={{ gridColumn: "span 8" }}>
          <div className={classes.heroImage}>
            <img src={EmpowerHeader} alt="Empower Logo" />
          </div>

          <TabList
            selected={tabIds.indexOf(tabId as string)}
            onChange={(tabIndex) => goToTab(tabIds[tabIndex])}
          >
            <Tab>Overview</Tab>
            <Tab>Empower</Tab>
            <Tab>Eligibility & costs</Tab>
            <Tab>FAQ</Tab>
          </TabList>

          <div className={classes.tabContent}>
            {tabId === "overview" && <OverviewTab />}
            {tabId === "empower" && <EmpowerTab />}
            {tabId === "eligibility" && <EligibilityTab />}
            {tabId === "faq" && <FaqTab />}
          </div>
        </div>

        <div className={classes.sidebar} style={{ gridColumn: "span 4" }}>
          <Box
            border={{
              borderColor: "neutralSubtle",
              borderRadius: "md",
              borderWidth: "sm",
            }}
            padding="2xl"
          >
            <div className={classes.empowerLogoSmall}>
              <img src={EmpowerLogoSidebar} alt="Empower Logo" />
            </div>

            <p className={benefitsClasses.benefitsParagraph}>
              Empower is a financial services company on a mission to empower
              financial freedom for all. They work with individuals and all
              sizes of organizations to offer investment, wealth management and
              retirement solutions.
            </p>

            {showEnrollmentFlow && (
              <p className={benefitsClasses.benefitsParagraph}>
                <h3>Next steps</h3>
                <span>
                  Once you select <strong>Get started</strong>, we'll take you
                  to Empower to create your account, set up your contributions,
                  and more.
                </span>
              </p>
            )}

            {showEnrollmentFlow && (
              <Box backgroundColor="neutralSubtle" padding="lg">
                <h5 className={classes.boxHeader}>Note on account setup</h5>
                <p className={classes.benefitsParagraph}>
                  Your company might have a waiting period set up with Empower.
                  Check with your admin for your eligibility date.
                </p>

                <p className={classes.benefitsParagraph}>
                  Once you are 30 days away from becoming eligible, you'll be
                  able to set up your account with Empower.
                </p>

                <Button
                  variant="filled"
                  style={{ width: "100%" }}
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={getStartedUrl()}
                  className={classes.getStartedButton}
                >
                  Get started
                </Button>
              </Box>
            )}

            {!showEnrollmentFlow && (
              <Box backgroundColor="neutralSubtle" padding="lg">
                <p className={classes.benefitsParagraph}>
                  Our records show that your profile is currently ineligible to
                  enroll. Once we are notified of your eligibility date and your
                  eligibility date is 30 days away, you'll be able to set up
                  your account with Empower.
                </p>
              </Box>
            )}
          </Box>

          <ResourcesBox />
        </div>
      </div>
    </div>
  );
};

export default LearnPageEmployee;
