import { Badge } from "@justworkshr/milo-core";

export const READY_FOR_APPROVAL_BADGE = (
  <Badge role="info" data-testid="expense-request-status">
    Ready for approval
  </Badge>
);
export const MANAGER_REVIEW_BADGE = (
  <Badge role="warning" data-testid="expense-request-status">
    Manager review
  </Badge>
);
