import Steps from "pages/company-settings/components/Steps";
import styles from "pages/company-settings/components/MicrodepositNextStepsInfo.module.css";
import { useBankForm } from "../hooks/useBankForm";

const { info, title } = styles;

export default function DebitBlockedNextStepsInfo() {
  const { formData } = useBankForm();

  const steps = [
    {
      title: "1. Contact your bank to remove the block",
      description: () => (
        <span>
          Reach out to {formData.bankName} and give them{" "}
          <b>Authorization code 3462283648</b> so they can lift the block. It
          typically takes 24–48 hours to remove the block.
        </span>
      ),
    },
    {
      title: "2. Re-enter account details",
      description: () => (
        <span>
          After the block has been removed, head back to your company settings
          to update your bank account.
        </span>
      ),
    },
  ];
  return (
    <div className={info}>
      <h3 className={title}>Next Steps</h3>
      <Steps steps={steps} />
    </div>
  );
}
