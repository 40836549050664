export const isHighlightedExpensesNavItem = (
  locationPath: string,
  navItemPath: string
): boolean => {
  // Expenses routes: src/pages/expenses/constants/routes.ts
  const reviewerRouteKeywords = ["review", "settings", "learn"];
  const submitterRouteKeywords = ["new", "library"];
  if (navItemPath === "/expenses/review") {
    return reviewerRouteKeywords.some((keyword) =>
      locationPath.includes(keyword)
    );
  } else if (navItemPath === "/expenses") {
    return (
      locationPath === "/expenses" ||
      submitterRouteKeywords.some((keyword) => locationPath.includes(keyword))
    );
  } else return false;
};
