import React from "react";
import styles from "./DeceasedTerminationReasonTypeCard.module.css";
import { buildWebPath } from "lib/resource-finder";
import { DisplayIcon } from "@justworkshr/milo-icons";

export const DeceasedTerminationReasonTypeCard = () => {
  return (
    <div className={styles.titleCard}>
      <div className={styles.titleCardContent}>
        <div className={styles.titleCardHeader}>
          <div className={styles.confirmationCheck}>
            <DisplayIcon iconName="check-mark" color="blueGreen" />
          </div>
          <h1>Separation scheduled</h1>
        </div>
        <div>
          No other actions are required on your part. Return to your
          <a href={buildWebPath("/dashboard")}> dashboard.</a>
        </div>
      </div>
    </div>
  );
};

export default DeceasedTerminationReasonTypeCard;
