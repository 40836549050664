import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import ConnectionStatusBadge from "../ConnectionStatusBadge/connection-status-badge";
import PermissionLock from "../PermissionLock/permission-lock";
import BetaBadge from "../BetaBadge/beta-badge";
import {
  userPermissionCheck,
  companyAdminsWithPermissionCheck,
  listAdminPermissions,
} from "../../utils";
import { PERMISSION } from "types/RequiredPermissions";
import { buildWebPath } from "lib/resource-finder";
import { CompanyAdmin } from "types";
import Tooltip from "../Tooltip/tooltip";
import { TOOLTIP_HORIZONTAL_ALIGNMENTS } from "../Tooltip/constants";
import { PERMISSION_NAME_MAP, permissionText } from "../../constants";
import styles from "./integration-card.module.css";

export type IntegrationCardProps = {
  logoUrl?: string;
  isBeta?: boolean;
  connectionStatus?: "CONNECTED" | "DISCONNECTED" | "PENDING" | "ERROR";
  integrationName: string;
  integrationDescription: string;
  integrationCategory: string;
  requiredPermissions: PERMISSION[];
  slug: string;
  pdpUrl: string;
  userPermissions?: PERMISSION[];
  companyAdmins?: CompanyAdmin[];
};
const {
  integrationCard,
  badgeHeader,
  statusBadges,
  cardText,
  integrationLogo,
  companyName,
  companyDescription,
} = styles;
const IntegrationCard: React.FC<IntegrationCardProps> = ({
  logoUrl,
  isBeta,
  connectionStatus,
  integrationName,
  integrationDescription,
  requiredPermissions,
  userPermissions,
  pdpUrl,
  companyAdmins,
}) => {
  const navigate = useNavigate();
  const lockRef = useRef<HTMLSpanElement | null>(null);
  const handleCardClick = () => {
    const splitPdpUrl = pdpUrl.split("/cf/");
    const isClockfaceUrl = splitPdpUrl.length > 1;
    if (isClockfaceUrl) {
      navigate(`/${splitPdpUrl[1]}`);
    } else {
      window.location.href = buildWebPath(pdpUrl);
    }
  };

  const hasPermission: boolean = userPermissionCheck(
    requiredPermissions,
    userPermissions
  );

  const adminsWithPermission = companyAdminsWithPermissionCheck(
    requiredPermissions,
    companyAdmins
  );
  const firstAdminName =
    adminsWithPermission.length > 0 ? adminsWithPermission[0] : "an admin";
  const toolTipAdminPermission = listAdminPermissions(
    requiredPermissions,
    PERMISSION_NAME_MAP
  );
  const tooltipVisibility = hasPermission ? "hidden" : "visible";
  const tooltipText = permissionText(toolTipAdminPermission, firstAdminName);

  const badgesReactiveSpacing = {
    "--badges-reactive-spacing":
      connectionStatus === "DISCONNECTED" && !isBeta && hasPermission
        ? ""
        : "var(--base-spacing-md)",
  } as React.CSSProperties;

  return (
    <Tooltip
      content={
        <>
          <p>{tooltipText}</p>
        </>
      }
      id={"Admin"}
      arrowAlignment={TOOLTIP_HORIZONTAL_ALIGNMENTS.CENTER}
      targetRefOverride={lockRef}
      visibility={tooltipVisibility}
    >
      <div className={integrationCard} data-testid={"integrationCard"}>
        <button
          onClick={handleCardClick}
          disabled={!hasPermission}
          className={`${styles.buttonAsCard} ${
            styles[`${connectionStatus?.toLowerCase()}`]
          }`}
        >
          <div className={badgeHeader} style={badgesReactiveSpacing}>
            <img className={integrationLogo} src={logoUrl} alt="logo" />
            <div className={statusBadges}>
              {!hasPermission && (
                <span ref={lockRef} id="lock">
                  <PermissionLock adminsWithPermission={adminsWithPermission} />
                </span>
              )}
              {isBeta && <BetaBadge />}
              <ConnectionStatusBadge connectionStatus={connectionStatus} />
            </div>
          </div>
          <div className={cardText}>
            <h2 className={companyName}>{integrationName}</h2>
            <p className={companyDescription}>{integrationDescription}</p>
          </div>
        </button>
      </div>
    </Tooltip>
  );
};

export default IntegrationCard;
