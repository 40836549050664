import { RadioButton } from "@justworkshr/milo-form";
import { ReactElement, useRef } from "react";
import styles from "./RadioButtonCard.module.css";

type Props = {
  name: string;
  label: string;
  description: string;
  value: string;
  checked: boolean;
  onChange: (value: string) => void;
};

export const RadioButtonCard = ({
  name,
  label,
  description,
  value,
  checked,
  onChange,
}: Props): ReactElement => {
  const radioButtonRef = useRef<HTMLInputElement>(null);

  const handleWrapperClick = () => {
    if (radioButtonRef.current) {
      radioButtonRef.current.click();
      radioButtonRef.current.checked = true;
    }
  };

  const handleOnChange = (selection: string) => {
    onChange(selection);
  };

  return (
    <div className={styles.boxContentWrapper} onClick={handleWrapperClick}>
      <RadioButton
        ref={radioButtonRef}
        label={label}
        name={name}
        value={value}
        checked={checked}
        className={styles.radioButton}
        onChange={handleOnChange}
      />
      <span className={styles.radioDescription}>{description}</span>
    </div>
  );
};
