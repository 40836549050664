import React from "react";
import {
  REQUIRED,
  EMOJI_ERROR,
  WHITESPACE_ERROR,
  MEALS_AND_ENTERTAINMENT_CATEGORY_NAME,
} from "pages/expenses/constants";
import { ExpenseCategory } from "types/Expenses";
import { invalidWhitespaceInputValue } from "pages/expenses/utils";
import { FormField, TextArea } from "@justworkshr/milo-form";
import { isFormFieldHidden } from "../../ExpenseDetailsPage.utils";
import { MEALS_AND_ENTERTAINMENT_MESSAGE } from "./Attendees.constants";
import styles from "../../ExpenseDetailsPage.module.css";

const { ExpenseDetailsPageTextAreaWrapper } = styles;

const ATTENDEES_KEY = "attendees";

interface AttendeesProps {
  attendees: {
    value: string;
    error: string;
  };
  handleFormValue: ({
    key,
    value,
  }: {
    key: typeof ATTENDEES_KEY;
    value: string;
  }) => void;
  handleFormError: ({
    key,
    error,
  }: {
    key: typeof ATTENDEES_KEY;
    error: string;
  }) => void;
  selectedCategory: ExpenseCategory | null;
}

export const Attendees: React.FC<AttendeesProps> = React.memo(
  ({ attendees, selectedCategory, handleFormValue, handleFormError }) => {
    if (
      !selectedCategory ||
      isFormFieldHidden(ATTENDEES_KEY, selectedCategory)
    ) {
      return null;
    }

    const isRequired = selectedCategory.attendees === REQUIRED;

    const validateAttendeesValue = (attendeesValue: string) => {
      if (/\p{Extended_Pictographic}/u.test(attendeesValue) === true) {
        handleFormError({ key: ATTENDEES_KEY, error: EMOJI_ERROR });
      } else if (invalidWhitespaceInputValue(attendeesValue)) {
        handleFormError({ key: ATTENDEES_KEY, error: WHITESPACE_ERROR });
      } else {
        handleFormError({ key: ATTENDEES_KEY, error: "" });
      }
    };

    const handleAttendeesChange = (
      event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      validateAttendeesValue(event.target.value);

      handleFormValue({
        key: ATTENDEES_KEY,
        value: event.target.value,
      });
    };

    return (
      <div className={ExpenseDetailsPageTextAreaWrapper}>
        <FormField
          label="Attendees"
          required={isRequired}
          error={attendees.error}
          message={
            selectedCategory.name === MEALS_AND_ENTERTAINMENT_CATEGORY_NAME
              ? MEALS_AND_ENTERTAINMENT_MESSAGE
              : ""
          }
        >
          <TextArea
            name={ATTENDEES_KEY}
            required={isRequired}
            value={attendees.value}
            onChange={handleAttendeesChange}
            data-testid="attendees-input"
          />
        </FormField>
      </div>
    );
  }
);
