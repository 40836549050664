export const PRONOUNS_ERROR =
  "Sorry, no emoji or numbers. The only special characters allowed are comma (,)" +
  " slash (/) or hyphen (-).";

export const PRONOUNS_CHAR_LIMIT_ERROR =
  "Sorry, pronouns can only be up to 255 characters.";

export const MAXIMUM_FILE_SIZE = 10;
export const ACCEPTED_FILE_TYPES = "image/png, image/jpeg, image/gif";

export const FILE_TOO_LARGE_ERROR = `Please select a file under ${MAXIMUM_FILE_SIZE}MB`;

const acceptedFileTypesArray = ACCEPTED_FILE_TYPES.split(", ").map((type) => {
  const lastIndexOfSlash = type.lastIndexOf("/");
  return type.slice(lastIndexOfSlash + 1).toUpperCase();
});
const length = acceptedFileTypesArray.length;
const acceptedFileTypesInError =
  length === 1
    ? acceptedFileTypesArray[0]
    : acceptedFileTypesArray.slice(0, length - 1).join(", ") +
      ", or " +
      acceptedFileTypesArray[length - 1];
export const FILE_TYPE_NOT_ACCEPTED_ERROR = `That doesn't look like a photo. Make sure it’s a ${acceptedFileTypesInError}`;

export const FILE_COULD_NOT_BE_PROCESSED = `There was an issue processing this file. Please try again, or try a new file`;

export const SUCCESS_ALERT_MESSAGE = "Profile was successfully updated.";
export const ERROR_ALERT_MESSAGE =
  "We found some errors. Please check the form and try again.";
