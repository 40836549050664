import { useTranslation } from "react-i18next";
import { Button, Divider } from "@justworkshr/milo-core";
import { DisplayIcon } from "@justworkshr/milo-icons";
import { getEnvironmentName } from "lib/environment";

import styles from "./TimeTrackingLanding.module.css";

const {
  timeTrackingLanding,
  headerInfo,
  supportLink,
  ttContent,
  ttContentTitle,
  ttContentInfo,
} = styles;

export default function TimeTrackingLanding() {
  const { t } = useTranslation();
  return (
    <div className={timeTrackingLanding}>
      <div>
        <h1>{t("Time Tracking")}</h1>
        <div className={headerInfo}>
          {t(
            "Local regulations require us to keep track of the " +
              "time you work each day. For more details about rules in your country,"
          )}{" "}
          <a
            className={supportLink}
            href="https://help.justworks.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("visit the Help Center.")}
          </a>
        </div>
      </div>
      <Divider />
      <div className={ttContent}>
        <DisplayIcon iconName="punch-card-clock" />
        <div className={ttContentTitle}>{t("Record your hours")}</div>
        <div className={ttContentInfo}>
          <div>
            {t(
              "Head over to Justworks Time Tracking to fill out your timesheet."
            )}
          </div>
          {t("Have questions? Email us at")}{" "}
          <a
            href="mailto:international-support@justworks.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            international-support@justworks.com
          </a>
        </div>
        <Button
          onClick={() =>
            window.open(getTimeTrackingUrl(), "_blank", "noopener,noreferrer")
          }
          rightIcon="external-link"
        >
          {t("Go to Time Tracking")}
        </Button>
      </div>
    </div>
  );
}

function getTimeTrackingUrl(): string {
  const getBaseUrl = () => {
    switch (getEnvironmentName()) {
      case "production":
        return "https://hours.justworks.com";
      case "staging":
        return "https://staging.justworkshours.com";
      case "sandbox":
        return "https://sandbox.justworkshours.com";
      default:
        return "https://localhost:3001";
    }
  };
  return `${getBaseUrl()}/dashboard/shifts`;
}
