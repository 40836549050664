import { ReactElement } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { SUBPATHS } from "./constants";
import { ProfilePage } from "./pages";
import { DocumentsTab, PersonalInfoTab } from "./components";
import { Page404 } from "pages";
import { InternationalContractorMemberContextProvider } from "./context/InternationalContractorMemberContext";
import { AlertContextProvider } from "./context/AlertContext";

export default function InternationalContractorMemberDetail(): ReactElement {
  return (
    <Routes>
      <Route
        path="/:id"
        element={
          <InternationalContractorMemberContextProvider>
            <AlertContextProvider>
              <ProfilePage />
            </AlertContextProvider>
          </InternationalContractorMemberContextProvider>
        }
      >
        <Route
          path={`/:id/${SUBPATHS.PERSONAL_INFO}`}
          element={<PersonalInfoTab />}
        />
        <Route path={`/:id/${SUBPATHS.DOCUMENTS}`} element={<DocumentsTab />} />
        <Route
          path="/:id/*"
          element={<Navigate to={SUBPATHS.PERSONAL_INFO} replace />}
        />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
