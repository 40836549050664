import {
  RequestDetailActions,
  ExpenseLibraryActions,
  RequestDetailActionTypes,
  ExpenseLibraryActionTypes,
} from "./actions";
import { INITIAL_REQUEST_DETAILS, INITIAL_EXPENSE_LIBRARY } from "./constants";
import { mergeRequestDetail } from "./utils";
import cloneDeep from "lodash/cloneDeep";
import uniqBy from "lodash/uniqBy";

export const INITIAL_REQUEST_DETAIL_STORE = {
  RequestDetail: INITIAL_REQUEST_DETAILS,
  ExpenseLibrary: INITIAL_EXPENSE_LIBRARY,
};

export type RequestDetailStore = typeof INITIAL_REQUEST_DETAIL_STORE;

export type RequestDetailAction = RequestDetailActions | ExpenseLibraryActions;

export const RequestDetailReducer = (
  store: RequestDetailStore = INITIAL_REQUEST_DETAIL_STORE,
  action: RequestDetailAction
): RequestDetailStore => {
  switch (action.type) {
    case RequestDetailActionTypes.SET_REQUEST_DETAIL:
      return {
        ...store,
        RequestDetail: mergeRequestDetail(store.RequestDetail, action.payload),
      };
    case ExpenseLibraryActionTypes.SET_EXPENSE_LIBRARY:
      return {
        ...store,
        ExpenseLibrary: {
          ...store.ExpenseLibrary,
          library: action.payload.library,
        },
      };
    case RequestDetailActionTypes.RESET_REQUEST_DETAILS:
      return {
        ...store,
        RequestDetail: cloneDeep(INITIAL_REQUEST_DETAILS),
      };
    case RequestDetailActionTypes.SET_IS_UNATTACHED_VIEW:
      return {
        ...store,
        RequestDetail: {
          ...store.RequestDetail,
          metadata: {
            ...store.RequestDetail.metadata,
            isUnattachedExpenseView: action.payload,
          },
        },
      };
    case RequestDetailActionTypes.SET_CUSTOM_PAYDATE:
      return {
        ...store,
        RequestDetail: {
          ...store.RequestDetail,
          metadata: {
            ...store.RequestDetail.metadata,
            customPayDate: action.payload,
          },
        },
      };
    case RequestDetailActionTypes.SET_IS_CUSTOM_PAYDATE_VALID:
      return {
        ...store,
        RequestDetail: {
          ...store.RequestDetail,
          metadata: {
            ...store.RequestDetail.metadata,
            isCustomPayDateValid: action.payload,
          },
        },
      };
    case RequestDetailActionTypes.SET_REQUEST_DETAIL_EXPENSES:
      return {
        ...store,
        RequestDetail: {
          ...store.RequestDetail,
          request: {
            ...store.RequestDetail.request,
            expenses: action.payload,
          },
        },
      };
    case RequestDetailActionTypes.SET_REQUEST_DETAILS_DETACHED_EXPENSES:
      return {
        ...store,
        RequestDetail: {
          ...store.RequestDetail,
          metadata: {
            ...store.RequestDetail.metadata,
            detachedExpenses: uniqBy(action.payload, "uuid"),
          },
        },
      };

    default:
      return store;
  }
};
