import { Button } from "@justworkshr/milo-core";
import { FormField, TextInput } from "@justworkshr/milo-form";
import { Address, AddressErrors } from "../../types";
import styles from "./job-location.module.css";
import StateSelect from "pages/workers-compensation/document-request/components/state-select";
import { VALID_CERTIFICATE_STATES } from "pages/workers-compensation/document-request/constants";

const { inputField } = styles;

interface JobLocationAddressProps {
  changeAddress: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  setCertificateAddress: () => void;
  address: Address;
  index: number;
  errors: AddressErrors;
}

export default function JobLocationAddress({
  address,
  changeAddress,
  setCertificateAddress,
  index,
  errors,
}: JobLocationAddressProps): JSX.Element {
  const { street1, street2, city, state, postalCode } = address;
  const {
    street1: street1Error,
    city: cityError,
    postalCode: postalCodeError,
    state: stateError,
  } = errors;

  return (
    <div>
      <div className={inputField}>
        <FormField
          name="street1"
          label="Address street line 1"
          required={true}
          error={street1Error}
        >
          <TextInput
            name="street1"
            onChange={changeAddress}
            value={street1}
            data-heap-redact-text={true}
          />
        </FormField>
        {index === 0 ? (
          <Button
            onClick={setCertificateAddress}
            variant="ghost"
            size="sm"
            type="button"
          >
            Same as certificate holder address
          </Button>
        ) : null}
      </div>

      <div className={inputField}>
        <FormField name="street2" label="Address street line 2">
          <TextInput
            name="street2"
            value={street2}
            onChange={changeAddress}
            data-heap-redact-text={true}
          />
        </FormField>
      </div>

      <div className={inputField}>
        <FormField name="city" label="City" required={true} error={cityError}>
          <TextInput name="city" value={city} onChange={changeAddress} />
        </FormField>
      </div>

      <div className={inputField}>
        <FormField
          name="state"
          label="State"
          required={true}
          error={stateError}
        >
          <StateSelect
            selectedState={state}
            handleEventChange={changeAddress}
            stateList={VALID_CERTIFICATE_STATES}
          />
        </FormField>
      </div>

      <div className={inputField}>
        <FormField
          name="postalCode"
          label="ZIP code"
          required={true}
          error={postalCodeError}
        >
          <TextInput
            name="postalCode"
            value={postalCode}
            onChange={changeAddress}
          />
        </FormField>
      </div>
    </div>
  );
}
