import { Button } from "@justworkshr/milo-core";
import {
  useRequestDetail,
  useFeedbackBannerStore,
  useGlobalConfigurationStore,
} from "pages/expenses/store";
import React, { useState } from "react";
import styles from "../../ReimbursementSummary.module.css";
import {
  CancelReviewDialog,
  ReturnRequestDialog,
} from "pages/expenses/components";
import {
  useReturnToEmployee,
  useApproveReimbursementRequest,
} from "pages/expenses/hooks";
import { Review } from "types/generated/operations";
import { useNavigate } from "react-router-dom";
import { AlertTypes, prependBasePath } from "pages/expenses/constants";
import {
  formatApiDate,
  formatExpensesForSubmission,
} from "pages/expenses/utils";
import {
  useReimbursementSummary,
  parseExpensesForCreation,
} from "../../ReimbursementSummary.utils";

const { ReimbursementSummarySubmitButtonsWrapper } = styles;

export const ReviewButtons: React.FC = () => {
  const navigate = useNavigate();
  const { permissions } = useGlobalConfigurationStore();

  const { setFeedbackBanner } = useFeedbackBannerStore();
  const {
    resetRequestDetails,
    requestDetail: {
      request: { name, member, uuid, expenses },
      metadata: {
        customPayDate,
        detachedExpenses,
        isCustomPayDateValid,
        receiptUuidsToRemove,
      },
    },
  } = useRequestDetail();
  const { isAdminApproverView, isManagerApproverView } =
    useReimbursementSummary();
  const {
    handleApproveReimbursementRequest,
    isApproveReimbursementRequestLoading,
  } = useApproveReimbursementRequest();

  const [showReturnRequestDialog, setShowReturnRequestDialog] = useState(false);

  const [showCancelReviewDialog, setShowCancelReviewDialog] = useState(false);

  const { returnToEmployee, isReturnToEmployeeLoading } = useReturnToEmployee({
    onSuccess: () => {
      setFeedbackBanner({
        type: AlertTypes.SUCCESS,
        show: true,
        message: `${name} was returned to ${member?.friendlyFullName}.`,
      });

      resetRequestDetails();

      navigate(prependBasePath("ACTIVE_REQUESTS_LIST"));
    },
  });

  const isRequestLoading =
    isReturnToEmployeeLoading || isApproveReimbursementRequestLoading;

  const showReviewButtons = isManagerApproverView || isAdminApproverView;

  const returnRequestMessage = `Let ${member?.firstName} know what needs to be updated in order for the request to be approved.`;

  const formattedExpenses = formatExpensesForSubmission(
    parseExpensesForCreation(expenses, {
      detachedExpenses,
      receiptUuidsToRemove,
    })
  );

  const handleCancelReviewDialog = () => {
    setShowCancelReviewDialog((p) => !p);
  };

  const handleReturnRequestDialog = () => {
    setShowReturnRequestDialog((p) => !p);
  };

  const handleApprove = () => {
    const variables = permissions.isAdmin
      ? {
          payDate: formatApiDate(customPayDate),
          reviews: [
            {
              uuid,
              name,
              type: Review.Approve,
              expenses: formattedExpenses,
            },
          ],
        }
      : {
          reviews: [
            {
              uuid,
              name,
              type: Review.Review,
              expenses: formattedExpenses,
            },
          ],
        };

    handleApproveReimbursementRequest(variables);
  };

  const handleReturn = (reason: string) => {
    returnToEmployee({
      variables: {
        reviews: [
          {
            name,
            uuid,
            reason,
            type: Review.Return,
            expenses: formattedExpenses,
          },
        ],
      },
    });
  };

  const handleCancel = () => resetRequestDetails();

  if (!showReviewButtons) {
    return null;
  }

  return (
    <div>
      <div className={ReimbursementSummarySubmitButtonsWrapper}>
        <Button
          variant="filled"
          onClick={handleApprove}
          disabled={!isCustomPayDateValid || isRequestLoading}
          data-testid="approve-button"
        >
          {permissions.isAdmin
            ? "Approve & schedule pay"
            : "Send to admin for approval"}
        </Button>
        <Button
          variant="outlined"
          disabled={isRequestLoading}
          onClick={handleReturnRequestDialog}
          data-testid="return-button"
        >
          Return to employee
        </Button>
        <Button
          variant="ghost"
          disabled={isRequestLoading}
          onClick={handleCancelReviewDialog}
        >
          Cancel
        </Button>
      </div>
      {showCancelReviewDialog && (
        <CancelReviewDialog
          onCancel={handleCancel}
          isOpen={showCancelReviewDialog}
          onClose={handleCancelReviewDialog}
        />
      )}
      {showReturnRequestDialog && (
        <ReturnRequestDialog
          onReturn={handleReturn}
          message={returnRequestMessage}
          isOpen={showReturnRequestDialog}
          onClose={handleReturnRequestDialog}
        />
      )}
    </div>
  );
};

export default ReviewButtons;
