import { Route, Routes, useLocation } from "react-router-dom";
import { FirmsPage, InvitationPage, EditFirmPage } from "./pages";
import { Page404 } from "../page-404";
import { useEffect } from "react";

const FirmsApp = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.state?.preserveScrollPosition) {
      return;
    }
    scrollTo({ top: 0, left: 0 });
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<FirmsPage />}>
        <Route path="/:firmUuid/:modalAction" element={<FirmsPage />} />
      </Route>
      <Route path="/:firmUuid/edit-firm" element={<EditFirmPage />} />
      <Route path="/invitation/new/:page?" element={<InvitationPage />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default FirmsApp;
