import { ApolloError } from "@apollo/client";
import { FC, ReactNode, createContext, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  GetEoREmployeeProfileQuery,
  useGetEoREmployeeProfileQuery,
  useGetEoRMemberByUuidQuery,
} from "types/generated/operations";
import { getEmployeeStatus } from "../components/HeaderSection/utils";

interface EoRProfileInfoContextType {
  profileInfo?: GetEoREmployeeProfileQuery;
  employeeStatus?: string;
  loadingProfileInfo: boolean;
  error?: ApolloError;
  refetch?: () => Promise<unknown>;
}

interface EoRMemberContextProviderProps {
  children: ReactNode;
  memberUuid?: string;
}

const ProfileInfoContext = createContext<EoRProfileInfoContextType>({
  loadingProfileInfo: false,
});

export const ProfileInfoProvider: FC<EoRMemberContextProviderProps> = ({
  children,
  memberUuid,
}) => {
  const { id: idFromParams } = useParams();
  const id = idFromParams ?? memberUuid;

  const { data: memberData } = useGetEoRMemberByUuidQuery({
    variables: { memberUuid: id },
    skip: !id,
  });

  // Fetch employee profile data based on member data
  const {
    data: profileInfo,
    loading: loadingProfileInfo,
    error: profileInfoLoadError,
    refetch,
  } = useGetEoREmployeeProfileQuery({
    variables: { memberId: memberData?.eorMember?.id },
    skip: !memberData?.eorMember.id,
  });

  return (
    <ProfileInfoContext.Provider
      value={{
        profileInfo: profileInfo,
        employeeStatus: getEmployeeStatus(profileInfo),
        loadingProfileInfo: loadingProfileInfo,
        error: profileInfoLoadError,
        refetch,
      }}
    >
      {children}
    </ProfileInfoContext.Provider>
  );
};

export const useProfileContextData = (forceFetch?: boolean) => {
  const context = useContext(ProfileInfoContext);

  if (forceFetch) {
    context.refetch?.();
  }

  return context;
};
