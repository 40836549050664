import { ReactElement, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import {
  Page404,
  EmployerOfRecordFrameless,
  EMPLOYER_OF_RECORD_PATH,
} from "pages";

export default function Frameless(): ReactElement {
  return (
    <div className="milo--root">
      <Routes>
        <Route
          path={`${EMPLOYER_OF_RECORD_PATH}/*`}
          element={
            <Suspense fallback="Loading...">
              <EmployerOfRecordFrameless />
            </Suspense>
          }
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </div>
  );
}
