import { INITIAL_REVIEW_STATUS } from "pages/expenses/constants/request-status";
import {
  RequestsListRequest,
  RequestsListRequestPayment,
} from "types/Expenses";
import {
  ADMIN_REVIEW_BADGE,
  CANCELED_STATUS_BADGE,
  MANAGER_REVIEW_BADGE,
  RETURNED_BADGE,
} from "./RequestsListTableRow.constants";
import { ReimbursementRequestStatus } from "types/generated/operations";
import { formatDate } from "pages/expenses/utils";

export const getBadgeForStatus = (status: ReimbursementRequestStatus) => {
  switch (status) {
    case ReimbursementRequestStatus.InitialReview:
      return MANAGER_REVIEW_BADGE;
    case ReimbursementRequestStatus.Returned:
      return RETURNED_BADGE;
    case ReimbursementRequestStatus.ReadyForApproval:
      return ADMIN_REVIEW_BADGE;
    case ReimbursementRequestStatus.Approved:
      return "Approved";
    default:
      return "";
  }
};

export const renderReviewStatus = ({
  request,
  hasManagerApprover,
}: {
  request: RequestsListRequest;
  hasManagerApprover: boolean;
}) => {
  if (request?.payment?.status === "Canceled") {
    return CANCELED_STATUS_BADGE;
  }

  if (request.status === INITIAL_REVIEW_STATUS && !hasManagerApprover) {
    return ADMIN_REVIEW_BADGE;
  }

  return getBadgeForStatus(request.status);
};

export const renderPayDate = (payment: RequestsListRequestPayment) => {
  let payDate = <>&#8212;</>;

  if (payment?.status === "Canceled") {
    payDate = CANCELED_STATUS_BADGE;
  } else if (payment?.payDate) {
    payDate = <>{formatDate(payment.payDate)}</>;
  }

  return payDate;
};
