import { ReactElement } from "react";
import styles from "../TimeOff/TimeOff.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";

type Props = {
  title?: string;
  link?: string;
};

export default function ExternalLink({ title, link }: Props): ReactElement {
  return (
    <>
      <div className={styles.cardExternalLink}>
        <a
          href={link}
          target="_blank"
          className={styles.cardExternalLinkBody}
          rel="noopener noreferrer"
        >
          {title}
          <SystemIcon iconName="external-link" color="brand" size="medium" />
        </a>
      </div>
    </>
  );
}
