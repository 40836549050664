import { VARIABLE_NAMES } from "../variable-constants";
import { SettingsConfig } from "../settings-type";

const stagingSettings: Partial<SettingsConfig> = {
  [VARIABLE_NAMES.VARIABLE_TO_TEST_WITH]: "staging",
  [VARIABLE_NAMES.FAVICON_COLOR]: "purple",
  [VARIABLE_NAMES.MESH_HOST]: "https://mesh.staging.justworks.com",
};

export default stagingSettings;
