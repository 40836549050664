import styles from "./home-page.module.css";
import { ReactElement } from "react";
import { GetDraftsDataQuery } from "types/generated/operations";
import { formatApiToDraftState } from "../claims-form/container/format-api";
const {
  loadedReports,
  tableContainer,
  memberName,
  dateEdited,
  draftInjuryDate,
  draftProgress,
  draftContinue,
  draftItem,
} = styles;

type IDraftQueryData = GetDraftsDataQuery["draftClaimReportsByAdmin"][0];

const reportDraftItems: (
  pg: IDraftQueryData,
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<IDraftQueryData | null>>
) => ReactElement = (draft, index, setSelected) => {
  const draftState = formatApiToDraftState(draft.data);
  return (
    <div
      className={`${draftItem} ${tableContainer}`}
      key={`${index}-${draft.data?.claim?.memberName}`}
    >
      <div
        className={memberName}
        style={{ fontWeight: "bold", paddingLeft: "10px" }}
      >
        {draft.data?.claim?.memberName}
      </div>
      <div className={dateEdited}>
        {new Date(draft.createdAt).toLocaleDateString("en-US")}
      </div>
      <div className={draftInjuryDate}>
        {draft.data?.claim?.estimatedAccidentDateTime &&
          new Date(
            draft.data?.claim?.estimatedAccidentDateTime
          ).toLocaleString()}
      </div>
      <div className={draftProgress}>{`Step ${draftState.page}/7`}</div>
      <div className={draftContinue} onClick={() => setSelected(draft)}>
        Resume
      </div>
    </div>
  );
};

export function SavedDrafts({
  drafts,
  setSelected,
}: {
  drafts: GetDraftsDataQuery["draftClaimReportsByAdmin"];
  setSelected: React.Dispatch<React.SetStateAction<IDraftQueryData | null>>;
}): ReactElement {
  if (!drafts.length) return <></>;

  const mostRecentDrafts = [...drafts];
  mostRecentDrafts.sort((a, b) =>
    new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
  );

  return (
    <section className={loadedReports}>
      <div className={tableContainer}>
        <div
          className={memberName}
          style={{ fontWeight: "bold", paddingLeft: "10px" }}
        >
          Name
        </div>
        <div className={dateEdited} style={{ fontWeight: "bold" }}>
          Date claim started
        </div>
        <div className={draftInjuryDate} style={{ fontWeight: "bold" }}>
          Date of incident
        </div>
        <div className={draftProgress} style={{ fontWeight: "bold" }}>
          Progress
        </div>
      </div>
      {mostRecentDrafts.map((draft, index) =>
        reportDraftItems(draft, index, setSelected)
      )}
    </section>
  );
}
