import { DatePicker } from "../../../../../../../../components/DatePicker/DatePicker";
import { MemberSeparationQuestionRecursive } from "pages/team-management/types";
import styles from "./DatePickerQuestion.module.css";

const { QuestionnaireCalendar } = styles;

interface DatePickerProps {
  question: MemberSeparationQuestionRecursive;
  questionId: string;
  value: string;
  onChange: (value: string) => void;
}

export const DatePickerQuestion = ({
  questionId,
  value,
  onChange,
}: DatePickerProps) => {
  return (
    <DatePicker
      id={questionId}
      name={questionId}
      value={value}
      handleChange={onChange}
      className={QuestionnaireCalendar}
    />
  );
};
