import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { flagIsEnabled, FLAG_NAMES } from "lib/environment";
import { Page404 } from "pages/page-404";
import { EoRMemberContextProvider } from "./contexts/eorMemberContext";
import { EOR_PROFILE_PATH, INVOICE_PATH, OFFBOARDING_PATH } from "./routes";
import CombinedInvoice from "./combined-invoice/CombinedInvoice";
import EmployeeProfileInfo from "./employee-profile/EmployeeProfileInfo";
import InvitePage, { INVITE_PATH } from "./invite";
import AdditionalPayments from "./payments/AdditionalPayments";
import { ADDITIONAL_PAY_PATH } from "pages";
import { SupportedCountriesContextProvider } from "./contexts/supportedCountriesContext";
import { EorOffboarding } from "./offboarding";

const EmployerOfRecord = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <EoRMemberContextProvider>
      <SupportedCountriesContextProvider>
        <Routes>
          {flagIsEnabled(FLAG_NAMES.EMPLOYER_OF_RECORD) && (
            <>
              <Route
                path={`${INVOICE_PATH}/:processingDate`}
                element={<CombinedInvoice />}
              />
              <Route
                path={`${EOR_PROFILE_PATH}/*`}
                element={<EmployeeProfileInfo />}
              />
              <Route path={INVITE_PATH} element={<InvitePage />} />
              <Route
                path={`${OFFBOARDING_PATH}/:id/*`}
                element={<EorOffboarding />}
              />
              <Route
                path={`${ADDITIONAL_PAY_PATH}/:formType/*`}
                element={<AdditionalPayments />}
              />
            </>
          )}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </SupportedCountriesContextProvider>
    </EoRMemberContextProvider>
  );
};

export default EmployerOfRecord;
