import {
  ReimbursementRequestStatus,
  ExpenseCategoryFieldSetting,
} from "types/generated/operations";
import { INITIAL_REQUEST_DETAILS } from "../constants";
import { INITIAL_REQUEST_DETAIL_STORE, RequestDetailStore } from "../reducer";
import { RequestDetailStoreContext } from "../store";
import { DeepPartial } from "pages/expenses/types";

export const MockUseRequestDetail = {
  requestDetail: {
    ...INITIAL_REQUEST_DETAILS,
    request: {
      ...INITIAL_REQUEST_DETAILS.request,
      expenses: [
        {
          __typename: "Expense",
          uuid: "5e16dc8e-4bb2-4a6c-b481-852e67b21e0e",
          merchant: null,
          amount: 4500,
          transactionDate: "2023-10-03",
          description: null,
          attendees: null,
          expenseType: "REIMBURSABLE",
          isBillableToClient: false,
          receipts: [
            {
              __typename: "ExpenseReceipt",
              filename: "912c176a-11d0-4ef2-ad60-3f6e440a79c8.png",
              url: "https://jw-expense-receipt-images.s3.amazonaws.com/development/47094/3f3187ae-b67b-494e-a529-913401baf7c7.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARVIMX2VTHKRQ2DMQ%2F20231007%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231007T005721Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=74ecf19780aea597354deee20678b89e813bacabf93506c15598285ca2c571ed",
              uuid: "3f3187ae-b67b-494e-a529-913401baf7c7",
            },
            {
              __typename: "ExpenseReceipt",
              filename: "44389df8-6e8d-4180-8b88-f6906d21992e.png",
              url: "https://jw-expense-receipt-images.s3.amazonaws.com/development/47094/fb6aeb9f-93ab-4e82-b982-5908d81f8212.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARVIMX2VTHKRQ2DMQ%2F20231007%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231007T005721Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=999f855690c520a944b88be31511fc916ea10b741f866d6637bdefa86e0a2094",
              uuid: "fb6aeb9f-93ab-4e82-b982-5908d81f8212",
            },
          ],
          category: {
            __typename: "ExpenseCategory",
            uuid: "7a4d8d46-167b-4355-b8d9-a195282f7d7a",
            name: "Test",
            visible: true,
            custom: true,
            merchant: "HIDDEN",
            description: "HIDDEN",
            receipt: "OPTIONAL",
            attendees: "HIDDEN",
            expenseType: "REQUIRED",
            billableToClient: "HIDDEN",
          },
          project: null,
        },
        {
          __typename: "Expense",
          uuid: "0193a307-86eb-4048-ae65-bfe6dd1007e4",
          merchant: null,
          amount: 1000,
          transactionDate: "2023-10-02",
          description: null,
          attendees: null,
          expenseType: "NON_REIMBURSABLE",
          isBillableToClient: false,
          receipts: [
            {
              __typename: "ExpenseReceipt",
              filename: "ecde89ac-89fb-4c18-a35b-d0ca71f1c7eb.png",
              url: "https://jw-expense-receipt-images.s3.amazonaws.com/development/47094/6a2d2e41-c5dc-4949-910d-f52a56c1e744.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARVIMX2VTHKRQ2DMQ%2F20231007%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231007T005721Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=a69bea7ac871d1b14f1a262dc26c0f099499c33be7cbcec531b76506e083f372",
              uuid: "6a2d2e41-c5dc-4949-910d-f52a56c1e744",
            },
            {
              __typename: "ExpenseReceipt",
              filename: "912c176a-11d0-4ef2-ad60-3f6e440a79c8.png",
              url: "https://jw-expense-receipt-images.s3.amazonaws.com/development/47094/a9c85911-e139-4bd0-a8e9-3a5d6eb8a9b1.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARVIMX2VTHKRQ2DMQ%2F20231007%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231007T005721Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=19a422b4ccf74245eb2e8ee35c56f6db9c851fbbf78dc7ab515ad8b323fdb305",
              uuid: "a9c85911-e139-4bd0-a8e9-3a5d6eb8a9b1",
            },
            {
              __typename: "ExpenseReceipt",
              filename: "44389df8-6e8d-4180-8b88-f6906d21992e.png",
              url: "https://jw-expense-receipt-images.s3.amazonaws.com/development/47094/8fd706ef-67f1-4fd0-9e32-8fd74b7ac340.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARVIMX2VTHKRQ2DMQ%2F20231007%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231007T005721Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=b5bf9c63908566a1030516e9e3b43a638ee135720551249be83196646f3463c4",
              uuid: "8fd706ef-67f1-4fd0-9e32-8fd74b7ac340",
            },
            {
              __typename: "ExpenseReceipt",
              filename: "ccf0cae3-36ca-4b5a-8815-ff5b16d84224 (1).png",
              url: "https://jw-expense-receipt-images.s3.amazonaws.com/development/47094/85cd5cbe-f1ed-460d-b4ff-4cb4b93a5ec9.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARVIMX2VTHKRQ2DMQ%2F20231007%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231007T005721Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=b3fc362708a53d9480929910632e724b429307c707d4d4f40f93bd249bd0007f",
              uuid: "85cd5cbe-f1ed-460d-b4ff-4cb4b93a5ec9",
            },
          ],
          category: {
            __typename: "ExpenseCategory",
            uuid: "7a4d8d46-167b-4355-b8d9-a195282f7d7a",
            name: "Test",
            visible: true,
            custom: true,
            merchant: "HIDDEN",
            description: "HIDDEN",
            receipt: "OPTIONAL",
            attendees: "HIDDEN",
            expenseType: "REQUIRED",
            billableToClient: "HIDDEN",
          },
          project: null,
        },
      ],
      managerApprover: null,
      myRequest: true,
      member: {
        firstName: "Test",
        friendlyFullName: "Test Admin",
        __typename: "CompanyMember",
      },
      status: "RETURNED",
    },
    metadata: {
      isApproverView: true,
      customPayDate: "10/08/2023",
      detachedExpenses: [
        {
          __typename: "Expense",
          uuid: "5e16dc8e-4bb2-4a6c-b481-852e67b21e0e",
          merchant: null,
          amount: 4500,
          transactionDate: "2023-10-03",
          description: null,
          attendees: null,
          expenseType: "REIMBURSABLE",
          isBillableToClient: false,
          receipts: [
            {
              __typename: "ExpenseReceipt",
              filename: "912c176a-11d0-4ef2-ad60-3f6e440a79c8.png",
              url: "https://jw-expense-receipt-images.s3.amazonaws.com/development/47094/3f3187ae-b67b-494e-a529-913401baf7c7.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARVIMX2VTHKRQ2DMQ%2F20231007%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231007T005721Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=74ecf19780aea597354deee20678b89e813bacabf93506c15598285ca2c571ed",
              uuid: "3f3187ae-b67b-494e-a529-913401baf7c7",
            },
            {
              __typename: "ExpenseReceipt",
              filename: "44389df8-6e8d-4180-8b88-f6906d21992e.png",
              url: "https://jw-expense-receipt-images.s3.amazonaws.com/development/47094/fb6aeb9f-93ab-4e82-b982-5908d81f8212.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARVIMX2VTHKRQ2DMQ%2F20231007%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231007T005721Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=999f855690c520a944b88be31511fc916ea10b741f866d6637bdefa86e0a2094",
              uuid: "fb6aeb9f-93ab-4e82-b982-5908d81f8212",
            },
          ],
          category: {
            __typename: "ExpenseCategory",
            uuid: "7a4d8d46-167b-4355-b8d9-a195282f7d7a",
            name: "Test",
            visible: true,
            custom: true,
            merchant: "HIDDEN",
            description: "HIDDEN",
            receipt: "OPTIONAL",
            attendees: "HIDDEN",
            expenseType: "REQUIRED",
            billableToClient: "HIDDEN",
          },
          project: null,
        },
      ],
      receiptUuidsToRemove: {},
    },
    reviews: { reviewed: [], returned: [] },
  },
};

export const MockRequestDetailStore = {
  RequestDetail: {
    request: {
      expenses: [],
      managerApprover: null,
      member: {
        firstName: "",
        friendlyFullName: "",
      },
      myRequest: false,
      name: "",
      payment: null,
      reviews: [],
      status: ReimbursementRequestStatus.InitialReview,
      submittedAt: "",
      totalAmount: 0,
      uuid: "",
    },
    reviews: {
      approved: [],
      reviewed: [],
      returned: [],
    },
    editMode: {
      editExpense: null,
      editExpenseIndex: null,
    },
    metadata: {
      customPayDate: "",
      earliestPayDate: "",
      detachedExpenses: [],
      isApproverView: false,
      isRequestEditView: false,
      isRequestUpdated: false,
      receiptUuidsToRemove: null,
      isCustomPayDateValid: false,
      isUnattachedExpenseView: true,
    },
  },
  ExpenseLibrary: {
    library: {
      page: 0,
      expenses: [
        {
          uuid: "963f0546-254a-4715-8f42-6f0573eee272",
          category: {
            uuid: "6702ebf5-0f3d-4c3d-8cac-59dec6178d56",
            name: "Meals & entertainment",
            visible: true,
            custom: false,
            merchant: ExpenseCategoryFieldSetting.Required,
            description: ExpenseCategoryFieldSetting.Required,
            receipt: ExpenseCategoryFieldSetting.Required,
            attendees: ExpenseCategoryFieldSetting.Required,
            expenseType: ExpenseCategoryFieldSetting.Hidden,
            billableToClient: ExpenseCategoryFieldSetting.Hidden,
          },
          project: null,
          createdAt: "2023-09-11",
          transactionDate: "2023-09-04",
          amount: 111100,
          merchant: "Applebees",
          attendees: "Jared Baker",
          description: "Applebees",
          expenseType: "REIMBURSABLE",
          isBillableToClient: false,
          receipts: [
            {
              url: "https://jw-expense-receipt-images.s3.amazonaws.com/development/47094/ccf0cae3-36ca-4b5a-8815-ff5b16d84224.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARVIMX2VTHKRQ2DMQ%2F20230912%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230912T140124Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=84ff68dd744b42171ec9aa5bfc30234441df760c85b52f9281698936e7b74f6e",
              filename: "44389df8-6e8d-4180-8b88-f6906d21992e.png",
              uuid: "ccf0cae3-36ca-4b5a-8815-ff5b16d84224",
            },
          ],
        },
        {
          uuid: "1bccbc56-3c9f-4155-8cd1-381f72a2c93d",
          category: {
            uuid: "f38e5058-b290-4f68-a10f-f7f1fefb08f2",
            name: "Test",
            visible: true,
            custom: true,
            merchant: ExpenseCategoryFieldSetting.Hidden,
            description: ExpenseCategoryFieldSetting.Hidden,
            receipt: ExpenseCategoryFieldSetting.Optional,
            attendees: ExpenseCategoryFieldSetting.Hidden,
            expenseType: ExpenseCategoryFieldSetting.Required,
            billableToClient: ExpenseCategoryFieldSetting.Required,
          },
          project: null,
          createdAt: "2023-09-11",
          transactionDate: "2023-09-01",
          amount: 1200,
          merchant: null,
          attendees: null,
          description: null,
          expenseType: "REIMBURSABLE",
          isBillableToClient: false,
          receipts: [
            {
              url: "https://jw-expense-receipt-images.s3.amazonaws.com/development/47094/57160393-c2c7-4996-be78-17d14e7c9020.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARVIMX2VTHKRQ2DMQ%2F20230912%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230912T140124Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=27f4731ad580168157892b44de90d364667ef476cd3e06b11971e087b21ebdba",
              filename: "44389df8-6e8d-4180-8b88-f6906d21992e.png",
              uuid: "57160393-c2c7-4996-be78-17d14e7c9020",
            },
          ],
        },
      ],
      totalPages: 1,
      totalResults: 2,
    },
  },
};

export const MockExpense = {
  uuid: "963f0546-254a-4715-8f42-6f0573eee272",
  category: {
    uuid: "6702ebf5-0f3d-4c3d-8cac-59dec6178d56",
    name: "Meals & entertainment",
    visible: true,
    custom: false,
    merchant: ExpenseCategoryFieldSetting.Required,
    description: ExpenseCategoryFieldSetting.Required,
    receipt: ExpenseCategoryFieldSetting.Required,
    attendees: ExpenseCategoryFieldSetting.Required,
    expenseType: ExpenseCategoryFieldSetting.Hidden,
    billableToClient: ExpenseCategoryFieldSetting.Required,
  },
  project: {
    uuid: "963f0546-254a-4715-8f42-6f0573eee272",
    projectCode: "1234",
    projectName: "Test Project",
    displayName: "1234 - Test Project",
    visible: true,
  },
  createdAt: "2023-09-11",
  transactionDate: "2023-09-04",
  amount: 111100,
  merchant: "Applebees",
  attendees: "Jared Baker",
  description: "Applebees description",
  expenseType: "REIMBURSABLE",
  isBillableToClient: true,
  receipts: [
    {
      url: "https://jw-expense-receipt-images.s3.amazonaws.com/development/47094/ccf0cae3-36ca-4b5a-8815-ff5b16d84224.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARVIMX2VTHKRQ2DMQ%2F20230911%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230911T194418Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=1d7a7b23279fa60a5f978b2ff58fdfa98a2f8e6df9be9dbc2a96d32b2e418808",
      filename: "44389df8-6e8d-4180-8b88-f6906d21992e.png",
      uuid: "ccf0cae3-36ca-4b5a-8815-ff5b16d84224",
    },
  ],
};

export const MockedRequestDetailProvider: React.FC<{
  providerProps?: DeepPartial<RequestDetailStore>;
  children: React.ReactElement;
}> = ({ children, providerProps }) => {
  const store = Object.assign({}, INITIAL_REQUEST_DETAIL_STORE, providerProps);

  return (
    <RequestDetailStoreContext.Provider
      value={{
        store,
        dispatch: () => null,
      }}
    >
      {children}
    </RequestDetailStoreContext.Provider>
  );
};
