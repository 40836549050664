import React from "react";
import styles from "./SettingsHeader.module.css";
import { SETTINGS_PATHNAME } from "./SettingsHeader.constants";
import { getTabList } from "./SettingsHeader.utils";
import {
  useCategoriesStore,
  useSettingsConfigurationStore,
} from "pages/expenses/store";
import { Link, useLocation } from "react-router-dom";
import { TabListTypes } from "./SettingsHeader.types";
import { PageHeader } from "@justworkshr/milo-core";
import { getRoute } from "pages/expenses/utils";
import { ROUTES, prependBasePath } from "pages/expenses/constants";
import { SettingsChildren } from "../SettingsChildren";
import {
  CATEGORIES_CHILDREN,
  PROJECTS_CHILDREN,
} from "../../SettingsOutlet.constants";

const {
  SettingsHeaderWrapper,
  SettingsHeaderContainer,
  SettingsHeaderTabListWrapper,
} = styles;

export const SettingsHeader: React.FC = () => {
  const location = useLocation();
  const {
    settingsConfiguration: { initialSetupComplete },
  } = useSettingsConfigurationStore();
  const { activeCategory, setActiveCategory } = useCategoriesStore();

  const getHeaderProps = () => {
    const route = getRoute(location.pathname);

    switch (route) {
      case ROUTES.SETTINGS:
        return {
          title: "Expenses",
          superscript: undefined,
          tabList: TabListTypes.ADMIN,
          children: <SettingsChildren />,
        };
      case ROUTES.CATEGORIES:
        return {
          title: "Categories",
          superscript: undefined,
          tabList: null,
          children: CATEGORIES_CHILDREN,
        };
      case ROUTES.SUBMISSION_REQUIREMENTS:
        return {
          title: activeCategory?.name || "",
          superscript: (
            <Link
              to={prependBasePath("CATEGORIES")}
              onClick={() => setActiveCategory({ activeCategory: null })}
            >
              Back to categories
            </Link>
          ),
          tabList: null,
          children: null,
        };
      case ROUTES.PROJECTS:
        return {
          title: "Projects",
          superscript: (
            <Link to={prependBasePath("SETTINGS")}>
              Back to expenses settings
            </Link>
          ),
          tabList: null,
          children: PROJECTS_CHILDREN,
        };

      default:
        return {
          title: "Expenses",
          superscript: undefined,
          tabList: null,
          children: null,
        };
    }
  };

  const { title, superscript, children, tabList } = getHeaderProps();

  if (!initialSetupComplete && location.pathname === SETTINGS_PATHNAME) {
    return null;
  }

  return (
    <div className={SettingsHeaderWrapper}>
      <PageHeader
        title={title}
        border={false}
        linkPrevious={superscript}
        className={SettingsHeaderContainer}
      />
      {children}
      {tabList && (
        <div className={SettingsHeaderTabListWrapper}>
          {getTabList(tabList)}
        </div>
      )}
    </div>
  );
};

export default SettingsHeader;
