import { SettingsConfigurationStoreType } from "../constants";

export enum SettingsConfigurationActionTypes {
  SET_SETTINGS_CONFIGURATION = "SET_SETTINGS_CONFIGURATION",
}

export type SettingsConfigurationActions = {
  type: SettingsConfigurationActionTypes;
  payload: SettingsConfigurationStoreType;
};
