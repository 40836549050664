import { CheckboxInput } from "@justworkshr/milo-form";
import { EmployeeOptionType, EmployeeUpdateMethods } from "../../types";
import specificStyles from "./employee-search-container.module.css";

const { employeeListItem, employeeCheckBoxSelect } = specificStyles;

interface EmployeeSearchListProps {
  loading: boolean;
  w2Employees: EmployeeOptionType[];
  limited: EmployeeOptionType[];
  handleEmployeeUpdate: EmployeeUpdateMethods;
}

export const EmployeeSearchList = ({
  loading,
  w2Employees,
  limited,
  handleEmployeeUpdate,
}: EmployeeSearchListProps) => {
  const handleCheckmark = (checked: boolean, value: string) => {
    if (loading) return;

    const updateMethod = checked
      ? handleEmployeeUpdate.add
      : handleEmployeeUpdate.remove;
    const employee = limited.find((e: { uuid: string }) => e.uuid === value);

    if (!employee) return;

    updateMethod(employee);
  };

  return (
    <ul className={employeeCheckBoxSelect}>
      {w2Employees.length > 0 ? (
        limited.map(
          (emp: {
            uuid: string;
            name: string;
            selected: boolean | undefined;
          }) => (
            <li
              className={employeeListItem}
              key={emp.uuid}
              data-heap-redact-text={true}
            >
              <CheckboxInput
                label={emp.name}
                name={emp.uuid}
                checked={emp.selected}
                indeterminate={false}
                onChange={({ target }) =>
                  handleCheckmark(target.checked, target.value)
                }
                value={emp.uuid}
              />
            </li>
          )
        )
      ) : (
        <li className={employeeListItem}>Loading W2 employees...</li>
      )}
    </ul>
  );
};
