import styles from "./TextArea.module.css";
import React from "react";

const { textArea } = styles;

interface TextAreaProps extends React.ComponentPropsWithoutRef<"textarea"> {
  className?: string;
}

function TextArea({ className, ...props }: TextAreaProps) {
  const classes = [textArea];
  if (className) {
    classes.push(className);
  }

  return <textarea className={classes.join(" ")} {...props} />;
}

export default TextArea;
