import { useContext } from "react";
import { Alert } from "@justworkshr/milo-core";
import Layout from "../Layout";
import { OnboardingContext } from "../contexts/onboardingContext";
import { useTranslation } from "react-i18next";

const Skeleton = () => {
  const onboardingContext = useContext(OnboardingContext);
  const { t } = useTranslation();

  return (
    <Layout
      step={-1}
      loading={
        !onboardingContext ||
        onboardingContext?.loading ||
        onboardingContext?.redirecting
      }
    >
      <Alert
        color="destructive"
        visible={!!onboardingContext?.onboardingStateError}
      >
        {t("We were unable to determine your current onboarding step.")}
      </Alert>
      <Alert color="destructive" visible={!!onboardingContext?.memberError}>
        {t("We were unable to retrieve your account information.")}
      </Alert>
    </Layout>
  );
};

export default Skeleton;
