export const formatDate = (inputDate: Date): string =>
  `${inputDate.toLocaleDateString()} ${inputDate.toLocaleTimeString(undefined, {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })}`.toLowerCase();

export const supportLink = (
  <a className="milo--link-secondary" href={`mailto: support@justworks.com`}>
    support@justworks.com
  </a>
);

const addDays = (date: Date, days: number) => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
};

export const getDaysRemainingUntilExpiration = (lastActivityDate: Date) => {
  const dateToday = new Date();
  const expiringDate = addDays(lastActivityDate, 14);
  return Math.floor(
    (expiringDate.getTime() - dateToday.getTime()) / (1000 * 3600 * 24)
  );
};
