import { ReactElement } from "react";
import { Accordion, AccordionPanel } from "@justworkshr/milo-core";
import styles from "../../InternationalSolutionsPage.module.css";

export const ContractorsFAQ = (): ReactElement => {
  const { componentWrapper, mdHead, faqLink } = styles;
  return (
    <div className={componentWrapper}>
      <div className={mdHead}>FAQs</div>
      <div className={componentWrapper}>
        <Accordion>
          <AccordionPanel isOpen title="How many countries are supported?">
            Right now, you can pay contractors in 40+ countries—with plans to
            add more soon.
            <a
              href="https://help.justworks.com/hc/en-us/articles/18040350606619-International-Contractors"
              target="_blank"
            >
              {" "}
              <span className={faqLink}>
                See a full list of supported countries
              </span>{" "}
            </a>
            in the Help Center.
          </AccordionPanel>
          <AccordionPanel
            isOpen
            title="Will I be able to send payments to employees or businesses outside of the US?"
          >
            We support employee and contractor payments through our EOR and
            International Contractor Payment services.
          </AccordionPanel>
          <AccordionPanel
            isOpen
            title="Are these payments considered a wire transfer?"
          >
            Payments run on the local equivalent of an automated clearinghouse
            (ACH), not wires. These are typically cheaper to run (both for the
            payor and payee), offer better exchange rates, and are usually more
            reliable, especially as you leave the northwestern hemisphere. You
            will see the estimated exchange rate when setting up a payment. For
            now, all payments are USD denominated — as in, you specify the
            amount to pay in USD and we'll convert to the contractor's local
            currency.
          </AccordionPanel>
          <AccordionPanel
            isOpen
            title="How long does it take for a payment to appear in a contractor’s bank account?"
          >
            Depending on the country, payments take between 5–7 business days to
            land in a bank account.
          </AccordionPanel>
          <AccordionPanel
            isOpen
            title="What about taxes? Where is the end of year checklist?"
          >
            Unlike domestic (US) contractors, we do not provide year–end tax
            documents for international contractors. In many countries, unless
            you have an active entity in that country, year–end tax documents
            are not required. We do recommend reaching out to your legal counsel
            to ensure you're following local requirements regarding contractors.
          </AccordionPanel>
        </Accordion>
      </div>
    </div>
  );
};
