import React from "react";
import { buildClockworkWebPath } from "lib/resource-finder";
import {
  COMPLETED,
  CardStatus,
  ExpensesTaskCard,
  NOT_STARTED_OPTIONAL,
} from "pages/expenses/components/ExpensesTaskCard";
import { Button } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import {
  useFeedbackBannerStore,
  useGlobalConfigurationStore,
} from "pages/expenses/store";
import { AlertTypes } from "pages/expenses/constants";
import {
  managerPermissionsTaskCardBodyCopy,
  managerPermissionsTaskCardProTipCopy,
} from "./ManagerPermissionsTaskCard.constants";
import styles from "../../SettingsPage.module.css";

const { CardBody, ProTipHeading } = styles;

interface ManagerPermissionsTaskCardProps {
  cardStatus: CardStatus;
  adminPermissionsCardStatus: CardStatus;
}
export const ManagerPermissionsTaskCard: React.FC<
  ManagerPermissionsTaskCardProps
> = ({ cardStatus, adminPermissionsCardStatus }) => {
  const isCardActive =
    adminPermissionsCardStatus === COMPLETED && cardStatus !== COMPLETED;
  const showContent = isCardActive || cardStatus === COMPLETED;
  const { setFeedbackBanner } = useFeedbackBannerStore();
  const {
    permissionsAdmins,
    permissions: { isGranter },
  } = useGlobalConfigurationStore();

  const handleClick = () => {
    if (!isGranter) {
      setFeedbackBanner({
        type: AlertTypes.ERROR,
        show: true,
        message: `You need edit employee permissions. Reach out to a
            company admin like ${permissionsAdmins[0]} who can set-up
            permissions for this step.`,
      });
    } else {
      window.location.href = buildClockworkWebPath(
        "/permissions/managers?expenses_setup=true"
      );
    }
  };

  return (
    <ExpensesTaskCard
      status={cardStatus}
      activeCard={isCardActive}
      title="Manager permissions"
    >
      <div className={CardBody}>
        {managerPermissionsTaskCardBodyCopy}
        {showContent && (
          <>
            <div className={ProTipHeading}>
              <SystemIcon iconName="arrow-right" />
              <div className="protip-title">
                <strong>Pro tip</strong>
              </div>
            </div>
            <p>{managerPermissionsTaskCardProTipCopy}</p>
          </>
        )}
        {showContent && (
          <Button
            onClick={handleClick}
            disabled={adminPermissionsCardStatus !== COMPLETED}
            variant={
              cardStatus === NOT_STARTED_OPTIONAL ? "filled" : "outlined"
            }
            data-testid="get-started-manager-button"
          >
            {cardStatus === COMPLETED ? "Review" : "Get started"}
          </Button>
        )}
      </div>
    </ExpensesTaskCard>
  );
};

export default ManagerPermissionsTaskCard;
