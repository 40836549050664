import { useField, FieldHookConfig } from "formik";
import { CheckboxInput, FormField } from "@justworkshr/milo-form";
import React, { ComponentProps, ReactElement, ReactNode } from "react";

export const FormikFormField = <T,>({
  children,
  fieldConfig,
  name,
  ...props
}: {
  name: string;
  children: ReactNode;
  fieldConfig?: Partial<FieldHookConfig<T[]>>;
} & ComponentProps<typeof FormField>) => {
  const [field, meta] = useField({ name, ...fieldConfig });
  const error = meta.touched && meta.error ? meta.error : undefined;
  const input = React.Children.toArray(children)[0];
  return (
    <FormField error={error} {...props}>
      {React.cloneElement(input as ReactElement, field)}
    </FormField>
  );
};

export const FormikCheckboxInput = <T extends string | number>({
  fieldConfig,
  name,
  ...props
}: {
  name: string;
  value: T;
  fieldConfig?: Partial<FieldHookConfig<T[]>>;
} & ComponentProps<typeof CheckboxInput>) => {
  const [field] = useField({ name, ...fieldConfig });
  const checked = Array.isArray(field.value)
    ? field.value.includes(props.value)
    : field.value;
  return <CheckboxInput {...field} {...props} checked={checked} />;
};
