import React from "react";
import { ExpenseCategory, ExpenseLibraryExpenseReceipt } from "types/Expenses";
import { CATEGORY_STATUSES } from "pages/expenses/constants";
import { ReceiptUpload } from "pages/expenses/components";
import { isFormFieldHidden } from "../../ExpenseDetailsPage.utils";
import { FileResponse } from "pages/expenses/hooks";

interface ReceiptProps {
  selectedCategory: ExpenseCategory | null;
  receipts: ExpenseLibraryExpenseReceipt[];
  handleFileInputChange: (file: FileResponse) => void;
  handleReceiptDelete: (receipt: ExpenseLibraryExpenseReceipt) => void;
}

export const Receipt: React.FC<ReceiptProps> = React.memo(
  ({
    receipts,
    selectedCategory,
    handleReceiptDelete,
    handleFileInputChange,
  }) => {
    if (!selectedCategory || isFormFieldHidden("receipt", selectedCategory)) {
      return null;
    }

    return (
      <ReceiptUpload
        receipts={receipts}
        handleReceiptDelete={handleReceiptDelete}
        handleFileInputChange={handleFileInputChange}
        isRequired={selectedCategory.receipt === CATEGORY_STATUSES.REQUIRED}
      />
    );
  }
);
