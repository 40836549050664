import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { prependBasePath } from "pages/expenses/constants";
import { PermissionsError } from "pages/expenses/components";
import { useGlobalConfigurationStore } from "pages/expenses/store";

interface SettingsPagePermissionsWrapperProps {
  children: JSX.Element;
}

export const SettingsPagePermissionsWrapper: React.FC<
  SettingsPagePermissionsWrapperProps
> = ({ children }) => {
  const navigate = useNavigate();

  const {
    permissions: {
      isAllowedToAccessSettingsPages,
      isRequiredToViewProductDetailsPage,
    },
  } = useGlobalConfigurationStore();

  useEffect(() => {
    if (isRequiredToViewProductDetailsPage) {
      navigate(prependBasePath("LEARN"));
    }
  }, [isRequiredToViewProductDetailsPage, navigate]);

  if (isAllowedToAccessSettingsPages) {
    return children;
  }

  return <PermissionsError />;
};
