import { useGetAuditLogQuery } from "types/generated/operations";
import groupBy from "lodash/groupBy";

export const useAuditLog = (uuid: string) => {
  const { data, loading: isAuditLogLoading } = useGetAuditLogQuery({
    variables: { uuid },
    fetchPolicy: "no-cache",
  });

  const auditLogWithDate = [...(data?.expenseManagement?.request?.audits || [])]
    .reverse()
    .map((log) => ({
      ...log,
      date: new Intl.DateTimeFormat().format(new Date(log.occurredAt)),
    }));

  return {
    isAuditLogLoading,
    auditLogData: groupBy(auditLogWithDate, "date"),
    requestName: data?.expenseManagement?.request?.name || "",
    myRequest: data?.expenseManagement?.request?.myRequest || false,
  };
};
