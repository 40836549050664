import { FLAG_NAMES } from "../flag-constants";
import { SettingsConfig } from "../settings-type";

const productionSettings: Partial<SettingsConfig> = {
  [FLAG_NAMES.DATADOG_RUM_ENABLED]: true,
  [FLAG_NAMES.FLAG_TO_TEST_WITH]: false,
  [FLAG_NAMES.INVITATION_PAGE_REWRITE]: false,
  [FLAG_NAMES.EMPLOYER_OF_RECORD]: true,
  [FLAG_NAMES.JAZZHR_ENABLED]: true,
  [FLAG_NAMES.LEVER_ENABLED]: true,
  [FLAG_NAMES.PMT_INTEGRATIONS]: true,
  [FLAG_NAMES.INTERNATIONAL_CONTRACTOR_DOCUMENTS]: false,
};

export default productionSettings;
