import { FC } from "react";
import { Link } from "react-router-dom";
import { PageHeader, Badge, Button } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import { buildClockworkWebPath } from "lib/resource-finder";
import { FULL_PATHS } from "../../constants";
import styles from "./reporting-landing-page.module.css";
import CollapsiblePanel from "../collapsible-panel";

export const ReportingLandingPage: FC = () => {
  return (
    <div
      className={styles.reportingLandingPage}
      data-testid="reporting-landing-page"
    >
      <PageHeader border={false} title="Reporting">
        Extract your data to support your operational and strategic decision
        making.
      </PageHeader>
      <div className={styles.reportingCards}>
        <section className={styles.reportingCard}>
          <header className={styles.cardHeader}>
            <h2>Reports</h2>
          </header>
          <p className={styles.cardText}>
            Pre-built templates powered by Justworks.{" "}
            <Link
              to="https://help.justworks.com/hc/en-us/articles/360017958592-Reporting-by-Justworks"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about Reports.
            </Link>
          </p>
          <Button
            variant="outlined"
            as="a"
            href={buildClockworkWebPath("/reports")}
          >
            Go to Reports
          </Button>
        </section>
        <section className={styles.reportingCard}>
          <header className={styles.cardHeader}>
            <h2>Data Exports</h2>
            <Badge role="new">New</Badge>
          </header>
          <p className={styles.cardText}>
            Fully customizable reports enabled by our trusted partner, Visier.{" "}
            <Link
              to="https://help.justworks.com/hc/en-us/articles/22464441168667"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about Data Exports.
            </Link>
          </p>
          <Button
            variant="outlined"
            as={Link}
            to={FULL_PATHS.DATA_EXPORTS}
            state={{ backPath: FULL_PATHS.REPORTING }}
          >
            Go to Data Exports
          </Button>
        </section>
      </div>
      <CollapsiblePanel title="What’s the difference between Reports and Data Exports?">
        <table className={styles.comparisonTable}>
          <thead>
            <tr>
              <th></th>
              <th>
                <h3>Reports</h3>
              </th>
              <th>
                <h3>
                  Data Exports <Badge role="new">New</Badge>
                </h3>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h4>Customization</h4>
              </td>
              <td>Limited to Custom Payroll Report</td>
              <td>
                <SystemIcon
                  iconName="check-circle-inverted"
                  size="small"
                  className={styles.icon}
                />
              </td>
            </tr>
            <tr>
              <td>
                <h4>Save for reuse</h4>
              </td>
              <td>Limited to Custom Payroll Report</td>
              <td>
                <SystemIcon
                  iconName="check-circle-inverted"
                  size="small"
                  className={styles.icon}
                />
              </td>
            </tr>
            <tr>
              <td>
                <h4>Share with team</h4>
              </td>
              <td>
                <SystemIcon
                  iconName="close-circle"
                  size="small"
                  className={styles.icon}
                />
              </td>
              <td>
                <SystemIcon
                  iconName="check-circle-inverted"
                  size="small"
                  className={styles.icon}
                />
              </td>
            </tr>
            <tr>
              <td>
                <h4>Download format</h4>
              </td>
              <td>.csv</td>
              <td>.xlsx</td>
            </tr>
            <tr>
              <td>
                <h4>Updates</h4>
              </td>
              <td>Real-time</td>
              <td>Nightly</td>
            </tr>
            <tr>
              <td>
                <h4>Pre-built templates</h4>
              </td>
              <td>18 Templates</td>
              <td>7 Templates</td>
            </tr>
          </tbody>
        </table>
      </CollapsiblePanel>
      <CollapsiblePanel title="What pre-built templates are in Reports and Data Exports?">
        <table className={styles.templateListTable}>
          <thead>
            <tr>
              <th>
                <h3>Reports</h3>
              </th>
              <th>
                <h3>
                  Data Exports <Badge role="new">New</Badge>
                </h3>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <section>
                  <p>Benefits</p>
                  <ul>
                    <li>Benefits usage</li>
                    <li>Retirement</li>
                  </ul>
                  <p>HR</p>
                  <ul>
                    <li>Company census</li>
                    <li>Headcount</li>
                    <li>Onboarding status</li>
                    <li>Time off balance</li>
                    <li>Time off requests</li>
                    <li>Training status</li>
                    <li>Work history</li>
                  </ul>
                  <p>Payments</p>
                  <ul>
                    <li>Hours worked</li>
                    <li>Last pay change</li>
                    <li>Custom payroll report</li>
                    <li>1099 Report</li>
                    <li>Paycheck Protection Program</li>
                    <li>PPP Forgiveness</li>
                    <li>Social Security tax deferral</li>
                    <li>International contractors</li>
                  </ul>
                </section>
              </td>
              <td>
                <section>
                  <p>Benefits</p>
                  <ul>
                    <li>Benefits playground</li>
                    <li>Benefits usage data</li>
                  </ul>
                  <p>Workforce</p>
                  <ul>
                    <li>Workforce playground</li>
                    <li>Headcount data</li>
                    <li>Census data</li>
                  </ul>
                  <p>Time off</p>
                  <ul>
                    <li>Time off playground</li>
                    <li>Time off requests data</li>
                  </ul>
                  <p>Compensation</p>
                  <ul>
                    <li>Earnings playground</li>
                    <li>Last pay change data</li>
                  </ul>
                </section>
              </td>
            </tr>
          </tbody>
        </table>
      </CollapsiblePanel>
      <CollapsiblePanel title="Why is Justworks partnered with Visier? ">
        <p className={styles.visierDescription}>
          <a
            href="https://www.visier.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visier
          </a>{" "}
          is the recognized industry leader in HR data analytics and workforce
          trends. Justworks is proud to bring you a robust People Analytics tool
          enabled by Visier and customized to fit the needs of your business.
          This solution combines Visier’s expertise in processing and
          visualizing data with Justworks' expertise in designing simple, secure
          solutions for small businesses. Learn more about Visier{" "}
          <a
            href="https://www.visier.com/trust/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>
      </CollapsiblePanel>
    </div>
  );
};

export default ReportingLandingPage;
