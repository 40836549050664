import { useCallback } from "react";
import { useExpensesSelectorStore } from "../../store";
import { ConfigurationActionTypes } from "../../actions";
import {
  ConfigurationActionsUpdateConfigurationPayload,
  ConfigurationStoreType,
} from "../../constants";
import { GetConfigurationQuery } from "types/generated/operations";

export const useGlobalConfigurationStore = () => {
  const { store: configuration, dispatch } = useExpensesSelectorStore(
    (store) => store.Configuration
  );

  const updateConfiguration = useCallback(
    (payload: ConfigurationActionsUpdateConfigurationPayload) => {
      dispatch({
        type: ConfigurationActionTypes.UPDATE_CONFIGURATION,
        payload,
      });
    },
    [dispatch]
  );

  const setConfiguration = useCallback(
    (payload: GetConfigurationQuery) => {
      dispatch({
        type: ConfigurationActionTypes.SET_CONFIGURATION,
        payload,
      });
    },
    [dispatch]
  );

  const updatePermissions = useCallback(
    (payload: Partial<ConfigurationStoreType["permissions"]>) => {
      dispatch({
        type: ConfigurationActionTypes.UPDATE_PERMISSIONS,
        payload,
      });
    },
    [dispatch]
  );

  return {
    setConfiguration,
    updatePermissions,
    updateConfiguration,
    permissions: configuration.permissions,
    expenseAdmins: configuration.expenseAdmins,
    permissionsAdmins: configuration.permissionsAdmins,
    isExpensesEnabled: configuration.isExpensesEnabled,
    hasJoinedExpenses: configuration.hasJoinedExpenses,
    isExpensesEverEnabled: configuration.isExpensesEverEnabled,
  };
};
