import { SeparationNoticeStateRequirement } from "../types";

export const STANDARD_TEMPLATE = "standard_template";

export const IOWA_STATE_REQUIREMENTS: SeparationNoticeStateRequirement = {
  state: "Iowa",
  stateAbbreviation: "IA",
  notifyState: true,
  description:
    "A Notice of Separation Form must be completed by an employer when an employee leaves or refuses employment.",
  link: "https://uiclaims.iwd.iowa.gov/EmployerSeparation",
  linkText: "Go to Iowa’s Notice of Separation Form",
};

export const MARYLAND_STATE_REQUIREMENTS: SeparationNoticeStateRequirement = {
  state: "Maryland",
  stateAbbreviation: "MD",
  notifyState: true,
  description:
    "Maryland requires the Employer to provide a notice of separation to employees through a mailed letter, email, or text.",
  link: "https://www.dllr.state.md.us/forms/uiavailnotice.pdf",
  linkText: "Get resources",
};
export const MISSISSIPPI_STATE_REQUIREMENTS = {
  state: "Mississippi",
  stateAbbreviation: "MS",
  notifyState: true,
  description:
    "Mississippi requires the Employer to report a separation or refusal of work.",
  link: "https://accessms.mdes.ms.gov/accessms/faces/ensars/ensarsresponse.xhtml?resGroup=menu&resGroupForward=ensarsresponse",
  linkText: "Report a separation",
};
export const MISSOURI_STATE_REQUIREMENTS = {
  state: "Missouri",
  stateAbbreviation: "MO",
  notifyState: true,
  description:
    "Missouri requires the Employer to give separated employees Form M-INF-288-5.",
  link: "https://labor.mo.gov/sites/labor/files/pubs_forms/M-INF-288-5-AI.pdf",
  linkText: "Download form for Missouri",
};
export const ARIZONA_STATE_REQUIREMENTS = {
  state: "Arizona",
  stateAbbreviation: "AZ",
  notifyState: false,
  description:
    "All separated employees must receive a printed notice of separation.",
};
export const CALIFORNIA_STATE_REQUIREMENTS = {
  state: "California",
  stateAbbreviation: "CA",
  notifyState: false,
  description:
    "Employers must provide written notice to an employee upon a change in employment status. The CA Employment Development Department provides a sample notice that satisfies minimum requirements.",
};
export const COLORADO_STATE_REQUIREMENTS = {
  state: "Colorado",
  stateAbbreviation: "CO",
  notifyState: false,
  description:
    "At separation, employers must provide a notice to employees which includes information including the reason for separation.",
};
export const CONNECTICUT_STATE_REQUIREMENTS = {
  state: "Connecticut",
  stateAbbreviation: "CT",
  notifyState: false,
  description:
    "At the time of separation, Employers must provide an Unemployment Separation Package and a completed form UC-61 Unemployment Notice found in the separation package. If a copy cannot be provided in-person, it must be mailed to the employee’s last known address.",
};
export const GEORGIA_STATE_REQUIREMENTS = {
  state: "Georgia",
  stateAbbreviation: "GA",
  notifyState: false,
  description:
    "Employers must sign and complete a Separation Notice and provide a copy to the employee either in person or by mail.",
};
export const ILLINOIS_STATE_REQUIREMENTS = {
  state: "Illinois",
  stateAbbreviation: "IL",
  notifyState: false,
  description:
    "Employers must provide Form CLI111L to employees after the employee has been separated. More details including timing are included in the form.",
};
export const LOUISIANA_STATE_REQUIREMENTS = {
  state: "Louisiana",
  stateAbbreviation: "LA",
  notifyState: false,
  description:
    "Employers are required to submit a Notice of Separation to the employee and the state. The form can be completed online or in person.",
};
export const MASSACHUSETTS_STATE_REQUIREMENTS = {
  state: "Massachusetts",
  stateAbbreviation: "MA",
  notifyState: false,
  description:
    "Employees must be provided with Form 0590-A within 30 days of separation. It can be given in-person or sent to the employee’s last known address.",
};
export const MICHIGAN_STATE_REQUIREMENTS = {
  state: "Michigan",
  stateAbbreviation: "MI",
  notifyState: false,
  description:
    "A notice of separation must be provided to an employee upon separation.",
};
export const NEVADA_STATE_REQUIREMENTS = {
  state: "Nevada",
  stateAbbreviation: "NV",
  notifyState: false,
  description:
    "A notice of separation must be given to the employee at the time of separation.",
};
export const NEW_JERSEY_STATE_REQUIREMENTS = {
  state: "New Jersey",
  stateAbbreviation: "NJ",
  notifyState: false,
  description:
    "A notice of separation must be given to the employee at the time of separation, regardless of separation reason.",
};
export const NEW_YORK_STATE_REQUIREMENTS = {
  state: "New York",
  stateAbbreviation: "NY",
  notifyState: false,
  description:
    "Employees who go off payroll must receive written notice that includes the reason for separation, whether the separation is permanent or temporary, the employer’s name, employer’s NY Employer Registration Number, and the mailing address where payroll records are kept.",
};
export const PENNSYLVANIA_STATE_REQUIREMENTS = {
  state: "Pennsylvania",
  stateAbbreviation: "PA",
  notifyState: false,
  description:
    "Employers must notify the employee of the availability of unemployment benefits.",
};
export const RHODE_ISLAND_STATE_REQUIREMENTS = {
  state: "Rhode Island",
  stateAbbreviation: "RI",
  notifyState: false,
  description:
    "Employers must notify the employee of the availability of unemployment benefits.",
};
export const SOUTH_CAROLINA_STATE_REQUIREMENTS = {
  state: "South Carolina",
  stateAbbreviation: "SC",
  notifyState: false,
  description:
    "The employer must notify the employee of the availability of unemployment benefits by mail, text message, email, or in person.",
};
export const TENNESSEE_STATE_REQUIREMENTS = {
  state: "Tennessee",
  stateAbbreviation: "TN",
  notifyState: false,
  description:
    "Employers must provide Form LB-0489 to employees within 24 hours of separation–unless they were employed less than a week or will be recalled within a week.",
};

export const ALL_STATE_REQUIREMENTS: {
  [key: string]: SeparationNoticeStateRequirement;
} = {
  AZ: ARIZONA_STATE_REQUIREMENTS,
  IA: IOWA_STATE_REQUIREMENTS,
  MD: MARYLAND_STATE_REQUIREMENTS,
  MS: MISSISSIPPI_STATE_REQUIREMENTS,
  MO: MISSOURI_STATE_REQUIREMENTS,
  CA: CALIFORNIA_STATE_REQUIREMENTS,
  CO: COLORADO_STATE_REQUIREMENTS,
  CT: CONNECTICUT_STATE_REQUIREMENTS,
  GA: GEORGIA_STATE_REQUIREMENTS,
  IL: ILLINOIS_STATE_REQUIREMENTS,
  LA: LOUISIANA_STATE_REQUIREMENTS,
  MA: MASSACHUSETTS_STATE_REQUIREMENTS,
  MI: MICHIGAN_STATE_REQUIREMENTS,
  NV: NEVADA_STATE_REQUIREMENTS,
  NJ: NEW_JERSEY_STATE_REQUIREMENTS,
  NY: NEW_YORK_STATE_REQUIREMENTS,
  PA: PENNSYLVANIA_STATE_REQUIREMENTS,
  RI: RHODE_ISLAND_STATE_REQUIREMENTS,
  SC: SOUTH_CAROLINA_STATE_REQUIREMENTS,
  TN: TENNESSEE_STATE_REQUIREMENTS,
};
