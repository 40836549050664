import { ContactInfoFormType } from "./types";

export const initialValues: ContactInfoFormType = {
  contactInfo: {
    homeAddress: {
      address1: "",
      address2: "",
      city: "",
      countryCode: "",
      postalCode: "",
      zoneCode: "",
    },
    mailingAddress: {
      address1: "",
      address2: "",
      city: "",
      countryCode: "",
      postalCode: "",
      zoneCode: "",
    },
    phoneNumbers: [{ value: "", type: "mobile" }],
    homeEmail: "",
    emergencyContact: {
      firstName: "",
      lastName: "",
      relationship: "",
      phoneNumber: "",
    },
  },
};
