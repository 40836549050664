import { useNavigate } from "react-router-dom";
import styles from "../../RequestDetailHeader.module.css";
import { Button } from "@justworkshr/milo-core";
import { prependBasePath } from "pages/expenses/constants";
import { DeleteRequestButton } from "pages/expenses/components";

const { RequestDetailHeaderAdditionalButtonsWrapper } = styles;

export const ReturnedRequestButtons = () => {
  const navigate = useNavigate();

  return (
    <div className={RequestDetailHeaderAdditionalButtonsWrapper}>
      <DeleteRequestButton />
      <Button
        type="button"
        leftIcon="edit"
        variant="outlined"
        onClick={() => navigate(prependBasePath("EDIT_REQUEST"))}
        data-testid="update-request-button"
      >
        Update request
      </Button>
    </div>
  );
};
