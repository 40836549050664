import React, { ReactElement, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PageHeader, Spinner } from "@justworkshr/milo-core";
import { integrationConfigs } from "pages/pmt/integrations/configs/config";
import Notice, {
  NoticeColor,
} from "pages/pmt/integrations/partner-description-page/components/Notice/Notice";
import { setupTimeCopy, setupHelpCopy } from "./constants";
import styles from "./partner-setup-page.module.css";
import Stepper from "./components/Stepper/Stepper";
import { StepperHelpers, useStepper } from "./hooks/useStepper";
import { Step } from "./components/Step";
import { IntegrationsConfig } from "types/IntegrationConfig";
import useSetupPageData from "./hooks/useSetupPageData";
import { useAuthSuccessParam } from "./utils";
import { Page404 } from "pages/page-404";
import { useResetScrollOnRouteChange } from "../hooks/useResetScrollOnRouteChange";
import { FLAG_NAMES, flagIsEnabled } from "lib/environment";
import { userPermissionCheck } from "../integration-marketplace/utils";
import { isPmtIntegrationDisabled } from "../utils";
import ErrorPage from "./components/ErrorPage/ErrorPage";

const {
  setupHeader,
  leftColumn,
  rightColumn,
  timeNotice,
  helpNotice,
  loadingSpinner,
  errorBanner,
} = styles;

export type setupProps = {
  integration: ReturnType<typeof useSetupPageData>;
  config: IntegrationsConfig;
  stepperHelpers: StepperHelpers;
  authSuccess: boolean | undefined;
};

export const PartnerSetupPageRenderer: React.FC<setupProps> = ({
  integration,
  config,
  stepperHelpers,
  authSuccess,
}) => {
  const navigate = useNavigate();
  useResetScrollOnRouteChange();

  const loading = integration?.loading;
  const hasPermission: boolean = userPermissionCheck(
    config?.copy?.marketplaceCard?.requiredPermissions,
    integration?.memberPermissions
  );

  useEffect(() => {
    if (!hasPermission && !loading) {
      navigate(`/integrations/${config?.copy.marketplaceCard.slug}`);
    }
  }, [hasPermission, navigate, config?.copy.marketplaceCard.slug, loading]);

  const pmtIntegrationsEnabled = flagIsEnabled(
    FLAG_NAMES.PMT_INTEGRATIONS,
    integration?.companyUuid
  );

  const integrationEnabled = !isPmtIntegrationDisabled(
    integration.integrationName,
    integration.companyUuid
  );

  const RightHeaderButton = config?.copy.setup.rightHeaderButton;
  const rightHeaderButtonProps = {
    ...config?.copy.setup.rightHeaderButtonProps,
    ...stepperHelpers,
  };

  return (
    <div className={"pdpSetup"}>
      {loading && (
        <div className={loadingSpinner}>
          <Spinner aria-label="loading" />
        </div>
      )}
      {!integration.loading &&
        (integration.error === "Solution Error" ||
          integration.error === "Integration Error") && (
          <div className="milo--grid">
            <div className={setupHeader}>
              <PageHeader
                title={`Set up ${config?.copy.marketplaceCard.integrationName} integration`}
                linkPrevious={
                  <Link
                    to={`/integrations/${config?.copy.marketplaceCard.slug}`}
                  >
                    Back to {config?.copy.marketplaceCard.integrationName}{" "}
                    integration
                  </Link>
                }
              />
            </div>
            <div className={errorBanner}>
              <ErrorPage error={integration.error} />
            </div>
          </div>
        )}
      {!integration.loading && integration.error == "Error" && <Page404 />}
      {(!pmtIntegrationsEnabled || !integrationEnabled) &&
        !loading &&
        !integration.error && <Page404 />}
      {pmtIntegrationsEnabled &&
        integrationEnabled &&
        !loading &&
        !integration.error && (
          <div className="milo--grid">
            <div className={setupHeader}>
              <PageHeader
                title={`Set up ${integration.integrationName} integration`}
                linkPrevious={
                  <Link
                    to={`/integrations/${integration.solution?.data?.name}`}
                  >
                    Back to {integration.integrationName} integration
                  </Link>
                }
              />
              {RightHeaderButton && (
                <RightHeaderButton {...rightHeaderButtonProps} />
              )}
            </div>
            <div className={leftColumn}>
              <Stepper {...stepperHelpers}>
                {config &&
                  Object.entries(config?.copy.setup.stepperConfig).map(
                    ([key, value]) => (
                      <Step
                        key={key}
                        name={value.name}
                        doneName={value.doneName}
                        stepsConfig={config.copy.setup.steps}
                        oAuthSuccess={authSuccess}
                      />
                    )
                  )}
              </Stepper>
            </div>
            <div className={rightColumn}>
              <div className={timeNotice}>
                <Notice color={NoticeColor.blue}>
                  {setupTimeCopy(config?.copy.setup.estimatedSetupTime)}
                </Notice>
              </div>
              <div className={helpNotice}>
                <Notice>
                  {setupHelpCopy(config?.copy.setup.helpCenterLink)}
                </Notice>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};
export default function PartnerSetupPage(): ReactElement {
  const { solutionSlug = "" } = useParams();
  const config = integrationConfigs[solutionSlug];
  const integrationData = useSetupPageData(
    solutionSlug,
    config?.copy.setup.instanceKeys
  );

  const authSuccess = useAuthSuccessParam(window.location.href);
  const currentStep = config?.copy.setup.getCurrentStepFunction(
    integrationData.integration?.data,
    integrationData.instancesByKey,
    authSuccess
  );
  const stepperHelpers = useStepper(
    config?.copy.setup.steps.length,
    currentStep
  );

  return (
    <PartnerSetupPageRenderer
      integration={integrationData}
      config={config}
      stepperHelpers={stepperHelpers}
      authSuccess={authSuccess}
    />
  );
}
