import { Button, Dialog as MiloDialog } from "@justworkshr/milo-core";
import { InternationalContractorMemberContext } from "../../../context/InternationalContractorMemberContext";
import { ReactElement, useContext, useState } from "react";
import { Formik, FormikErrors, FormikValues, useFormikContext } from "formik";
import { DocumentUploadForm } from "./DocumentUploadForm";
import { getMemberPreferredName } from "../../../utils";
import {
  ERRORS,
  GENERIC_ERROR_MESSAGE,
  formSchema,
  defaultValues,
  requiredFields,
} from "./constants";
import { DocumentUploadFormType } from "./types";
import styles from "./DocumentUploadDialog.module.css";
import { DialogHeader } from "../../DialogHeader";
import { useDocumentUpload } from "pages/international-contractor-profile-page/hooks/useDocumentUpload";
import { useTranslation } from "react-i18next";
import { InternationalDocument } from "types";
import { DOCUMENT_ACCESS } from "../constants";
import { useUpdateInternationalDocumentMutation } from "types/generated/operations";
import { FileRequest } from "pages/international-contractor-profile-page/types/documents";

interface DialogProps {
  close: () => void;
  isEdit?: boolean;
  isOpen: boolean;
  handleSubmit: (
    values: FormikValues,
    errors: FormikErrors<DocumentUploadFormType>
  ) => void;
  fileError: string;
  setFileError: (value: string) => void;
  submitError: string;
  loading: boolean;
  title: string;
}

export function Dialog({
  close,
  isEdit,
  isOpen,
  handleSubmit,
  fileError,
  setFileError,
  submitError,
  loading,
  title,
}: DialogProps) {
  const { errors, values, touched, setTouched } =
    useFormikContext<DocumentUploadFormType>();

  const buttonTitle = isEdit ? "Save changes" : "Upload Document";

  const submit = () => {
    setTouched({
      ...touched,
      ...requiredFields,
    });
    handleSubmit(values, errors);
  };

  return (
    <div className={styles.container}>
      <MiloDialog
        isOpen={isOpen}
        onClose={close}
        showCloseButton={false}
        size="md"
        primaryButton={
          <Button
            loading={loading}
            onClick={submit}
            variant="filled"
            className={styles.submitButton}
            type="submit"
          >
            {buttonTitle}
          </Button>
        }
        secondaryButton={
          <Button onClick={close} variant="ghost">
            Cancel
          </Button>
        }
      >
        <DialogHeader onClose={close} alertError={submitError} title={title} />
        <DocumentUploadForm
          isEdit={isEdit}
          setFileError={setFileError}
          fileError={fileError}
        />
      </MiloDialog>
    </div>
  );
}

interface DocumentUploadModalProps {
  close: () => void;
  documentToEdit?: InternationalDocument;
  onComplete: () => void;
}

export default function DocumentUploadDialog({
  close,
  documentToEdit,
  onComplete,
}: DocumentUploadModalProps): ReactElement {
  const { t } = useTranslation();

  const [fileError, setFileError] = useState("");
  const [submitError, setSubmitError] = useState("");
  const { uploadDocument, loading: uploadingDocument } = useDocumentUpload({
    accept: ["application/pdf"],
    maxSize: 10 * 1024 * 1024, // 10MB
    acceptErrorMessage: t("Only PDFs are accepted, please try again."),
    maxSizeErrorMessage: t("File is too big."),
  });

  const [updateInternationalDocumentMutation, { loading: updatingDocument }] =
    useUpdateInternationalDocumentMutation();

  const { member } = useContext(InternationalContractorMemberContext);
  const memberName = getMemberPreferredName(member);

  const isEdit = !!documentToEdit;

  const initialValues = isEdit
    ? {
        name: documentToEdit.name,
        category: documentToEdit.category,
        signatureRequired: documentToEdit.signatureRequired,
        remindersSet: documentToEdit.remindersSet,
        memberAccess:
          documentToEdit.documentMemberAssignments[0].visibilityLevel ===
          DOCUMENT_ACCESS.ADMIN_AND_CONTRACTOR,
      }
    : defaultValues;

  const handleSuccess = () => {
    onComplete();
    close();
  };

  const validateFile = (file: File) => {
    if (!file) {
      setFileError(ERRORS.EMPTY_FILE);
      return false;
    }
    setFileError("");
    return true;
  };

  const handleSubmit = (
    values: FormikValues,
    errors: FormikErrors<DocumentUploadFormType>
  ) => {
    setSubmitError("");
    if (isEdit) {
      editDocument(values, errors);
    } else {
      createDocument(values, errors);
    }
  };

  const createDocument = async (
    values: FormikValues,
    errors: FormikErrors<DocumentUploadFormType>
  ) => {
    if (!validateFile(values.file) || Object.keys(errors).length > 0) {
      return;
    }

    if (values.file) {
      try {
        await uploadDocument({
          member_id: member?.uuid,
          file: values.file.file,
          category: values.category,
          name: values.name,
          signature_required: values.signatureRequired,
          reminders_set: values.remindersSet,
          document_member_assignments: [
            {
              company_member_id: member?.uuid,
              visibility_level: values.memberAccess
                ? DOCUMENT_ACCESS.ADMIN_AND_CONTRACTOR
                : DOCUMENT_ACCESS.ADMIN_ONLY,
            },
          ],
        } as FileRequest);
        handleSuccess();
      } catch (err) {
        setSubmitError(GENERIC_ERROR_MESSAGE);
      }
    }
  };

  const editDocument = (
    values: FormikValues,
    errors: FormikErrors<DocumentUploadFormType>
  ) => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    const documentMemberAssignment = {
      id: documentToEdit?.documentMemberAssignments[0]?.id,
      visibilityLevel: values.memberAccess
        ? DOCUMENT_ACCESS.ADMIN_AND_CONTRACTOR
        : DOCUMENT_ACCESS.ADMIN_ONLY,
    };

    updateInternationalDocumentMutation({
      variables: {
        documentId: documentToEdit?.id || "",
        name: values.name,
        category: values.category,
        documentMemberAssignments: [documentMemberAssignment],
      },
      onCompleted() {
        handleSuccess();
      },
      onError() {
        setSubmitError(GENERIC_ERROR_MESSAGE);
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={() => {}}
      validateOnBlur
      validateOnChange
    >
      <Dialog
        close={close}
        isEdit={isEdit}
        isOpen
        handleSubmit={handleSubmit}
        fileError={fileError}
        setFileError={setFileError}
        submitError={submitError}
        loading={uploadingDocument || updatingDocument}
        title={
          isEdit
            ? `Edit ${documentToEdit.name}`
            : `Upload document for ${memberName}`
        }
      />
    </Formik>
  );
}
