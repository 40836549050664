import styles from "pages/company-settings/components/CompanyBankNotice.module.css";
import CustomCheckMark from "../.svg/CustomCheckMark.svg";
import CustomLetterI from "../.svg/CustomLetterI.svg";
import CustomCautionSign from "../.svg/CustomCautionSign.svg";

const { noticeWrapper, noticeTitle, noticeContents, info, warning, success } =
  styles;

export enum NoticeTypes {
  INFO = "info",
  WARNING = "warning",
  SUCCESS = "success",
}

interface NoticeProps {
  title?: string;
  type?: NoticeTypes;
  children: React.ReactNode;
}
type IconName =
  | typeof CustomLetterI
  | typeof CustomCautionSign
  | typeof CustomCheckMark;

const iconMapping: Record<NoticeTypes, IconName> = {
  [NoticeTypes.INFO]: CustomLetterI,
  [NoticeTypes.WARNING]: CustomCautionSign,
  [NoticeTypes.SUCCESS]: CustomCheckMark,
};

const typeClassMapping = {
  [NoticeTypes.INFO]: info,
  [NoticeTypes.WARNING]: warning,
  [NoticeTypes.SUCCESS]: success,
};

const CompanyBankNotice = ({
  title,
  type = NoticeTypes.INFO,
  children,
}: NoticeProps) => {
  const icon = iconMapping[type];
  const typeClassName = typeClassMapping[type];

  return (
    <div className={`${noticeWrapper} ${typeClassName}`}>
      <div>
        <img src={icon} />
      </div>
      <div className={noticeContents}>
        {title && <h2 className={noticeTitle}>{title}</h2>}
        {children}
      </div>
    </div>
  );
};

export default CompanyBankNotice;
