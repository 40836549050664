import { SORT } from "./Table/constants";

export const DOCUMENTS_TABLE_KEYS = {
  NAME: "name",
  UPLOADED: "uploadDate",
};

export const DOCUMENTS_TABLE_HEADERS = [
  { key: DOCUMENTS_TABLE_KEYS.NAME, label: "Name", isSortable: true },
  { key: DOCUMENTS_TABLE_KEYS.UPLOADED, label: "Uploaded", isSortable: true },
];

export const DEFAULT_DOCUMENTS_TABLE_PARAMS = {
  sortBy: DOCUMENTS_TABLE_KEYS.UPLOADED,
  sortDirection: SORT.DESC,
};

export const DOCUMENT_ACCESS = {
  ADMIN_ONLY: "admin_only",
  ADMIN_AND_CONTRACTOR: "admin_and_contractor",
};

export const GENERIC_ERROR_MESSAGE = "Something went wrong. Please try again.";

export const DOCUMENT_OPERATIONS = {
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
};
