import { Button, Box } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import { Dropdown } from "pages/employer-of-record/components";
import { useState } from "react";
import styles from "./HeaderActions.module.css";
import { useGetFeatureFlag } from "lib/launch-darkly";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "lib/styling-utils/clsx";

export default function HeaderActions() {
  const { id } = useParams();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const { getFeatureFlag } = useGetFeatureFlag();
  const navigate = useNavigate();
  const isV1OffboardingEnabled = getFeatureFlag(
    "enable-eor-employee-termination"
  );

  const terminationHandler = () => {
    if (isV1OffboardingEnabled) {
      navigate(`/employer-of-record/offboarding/${id}`);
    }
  };

  return (
    <>
      <div
        className={clsx(
          isV1OffboardingEnabled
            ? styles.terminateWrapperV1
            : styles.terminateWrapper
        )}
      >
        <Button
          as="button"
          variant="outlined"
          type="button"
          size="sm"
          leftIcon="close"
          id="requestTermProfile"
          onClick={terminationHandler}
        >
          Request separation
        </Button>
      </div>
      <div className={styles.headerMobileAction}>
        <Dropdown
          dropdownName="header-mobile-actions"
          contentClassName={styles.dropdownContent}
          onClickOutside={() => {
            setDropdownOpen(false);
          }}
          onOpenChange={(newOpen) => {
            setDropdownOpen(newOpen);
          }}
          content={
            <Box
              padding="xs"
              border={{
                borderWidth: "md",
                borderColor: "brand",
                borderRadius: "md",
              }}
            >
              <div
                className={styles.mobileTerminate}
                onClick={() => {
                  setDropdownOpen(false);
                }}
              >
                Request separation
              </div>
            </Box>
          }
          open={dropdownOpen}
        >
          <SystemIcon iconName="ellipsis" color="brand" className="" />
        </Dropdown>
      </div>
    </>
  );
}
