import { Security } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import { buildClockworkWebPath } from "lib/resource-finder";
import { useRestoreOriginalUri } from "./useRestoreOriginalUri";
import { VARIABLE_NAMES, getEnvVariable } from "lib/environment";

interface OktaProviderProps {
  children: React.ReactNode;
}

/**
 * Exporting the singletone here so apollo.ts can use it
 * TODO: Move this inside body of OktaProvider, remove export, and have apollo.ts `useOktaAuth()` instead of relying on this singleton
 */
export const oktaAuth = new OktaAuth({
  issuer: getEnvVariable(VARIABLE_NAMES.OKTA_ISSUER),
  clientId: getEnvVariable(VARIABLE_NAMES.OKTA_CLIENT_ID),
  redirectUri: `${window.location.origin}/cf/login/callback`,
  postLogoutRedirectUri: buildClockworkWebPath("/logout"),
  scopes: ["openid", "profile", "email"],
});

export const OktaProvider: React.FC<OktaProviderProps> = ({ children }) => {
  const restoreOriginalUri = useRestoreOriginalUri();
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};

export default OktaProvider;
