import styles from "./Spinner.module.css";

const { spinner, bounce1, bounce2, bounce3 } = styles;

interface SpinnerProps {
  className?: string;
}

function Spinner({ className }: SpinnerProps) {
  return (
    <span className={[spinner, className].join(" ")}>
      <span className={bounce1}></span>
      <span className={bounce2}></span>
      <span className={bounce3}></span>
    </span>
  );
}

export default Spinner;
