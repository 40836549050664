import { fetchClient } from "@justworkshr/fe-utility-kit";
import { buildClockworkWebPath } from "lib/resource-finder";
import { prependBasePath } from "pages/expenses/constants";
import { useState } from "react";

export type FileResponse = {
  uuid: string;
  url: string;
  filename: string;
};

interface UseReceiptUploadProps {
  maxSize: number;
  accept: string[];
  acceptErrorMessage: string;
  maxSizeErrorMessage: string;
  onSuccess: (file: FileResponse) => void;
}

const DEFAULT_UPLOAD_ERROR_MESSAGE =
  "There was an error with your upload. Please try again.";

export const useReceiptUpload = ({
  accept,
  maxSize,
  onSuccess,
  acceptErrorMessage,
  maxSizeErrorMessage,
}: UseReceiptUploadProps) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [fileName: string]: string[] }>({});

  const resetErrors = () => setErrors({});

  const handleError = (file: File, message: string) =>
    setErrors((currentErrors) => {
      const fileName = file.name;

      if (currentErrors[fileName]) {
        return {
          ...currentErrors,
          [fileName]: Array.from(
            new Set([...currentErrors[fileName], message])
          ),
        };
      }

      return { ...currentErrors, [fileName]: [message] };
    });

  const dismissError = (filename: string) =>
    setErrors((currentErrors) => {
      const updatedErrors = { ...currentErrors };
      delete updatedErrors[filename];

      return updatedErrors;
    });

  const validateFile = (file: File) => {
    let hasValidationError = false;

    if (!accept.includes(file.type)) {
      hasValidationError = true;
      handleError(file, acceptErrorMessage);
    }

    if (file.size > maxSize) {
      hasValidationError = true;
      handleError(file, maxSizeErrorMessage);
    }

    return hasValidationError;
  };

  const uploadReceipt = async (file: File) => {
    try {
      const hasValidationErrors = validateFile(file);

      if (hasValidationErrors) {
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      setLoading(true);

      const request = await fetchClient.post(
        buildClockworkWebPath(prependBasePath("POST_RECEIPT")),
        {
          cache: "no-cache",
          headers: {
            Accept: "multipart/form-data",
          },
          body: formData,
        }
      );

      const response: FileResponse = await request.json();

      if (!response.url) {
        throw new Error();
      }

      onSuccess(response);
    } catch {
      handleError(file, DEFAULT_UPLOAD_ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  return {
    errors,
    loading,
    resetErrors,
    dismissError,
    uploadReceipt,
  };
};
