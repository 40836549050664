import { ReactElement, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useGetFeatureFlag } from "lib/launch-darkly";

import {
  COI_EDIT_RELATIVE_URL,
  COI_REVIEW_RELATIVE_URL,
  COI_SUBMIT_RELATIVE_URL,
  COI_SUBMIT_URL,
  COI_EDIT_URL,
  initialCertificateState,
} from "../constants";

import { Page404 } from "pages/page-404";
import { CertificateReview } from "./certificate-review";

import CertificateOfInsuranceEdit from "./certificate-edit";
import RequestSubmissionComplete from "./request-submitted/request-submitted";

import { useCreateInsuranceDocumentRequestMutation } from "types/generated/operations";
import { createDocumentRequest } from "../api";
import { CertificateFormData } from "./types";
import { RequestForm } from "../waivers-of-subrogation/types";

export default function CertificatesContainer(): ReactElement {
  const [formData, setFormData] = useState<CertificateFormData>(
    initialCertificateState
  );
  const [supportingDocuments, setSupportingDocuments] = useState<
    {
      dataUrl: string | undefined;
      file: File | undefined;
    }[]
  >([]);

  const [submitError, setSubmitError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [createInsuranceDocumentRequestMutation] =
    useCreateInsuranceDocumentRequestMutation();

  const navigate = useNavigate();
  const { getFeatureFlag } = useGetFeatureFlag();
  const supportingDocumentsEnabled = getFeatureFlag(
    "waiver_coi_supporting_documentation"
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      let formattedWithDocuments: RequestForm = { ...formData };

      if (supportingDocumentsEnabled && supportingDocuments.length > 0) {
        const documents = supportingDocuments.map((document) => {
          return {
            contentType: document.file?.type || "",
            originalFilename: document.file?.name || "",
            blobFile: document.dataUrl || "",
          };
        });

        formattedWithDocuments = {
          ...formattedWithDocuments,
          documents,
        };
      }

      const result = await createDocumentRequest(
        createInsuranceDocumentRequestMutation,
        formattedWithDocuments
      );

      if (result) {
        if (result.data?.createInsuranceDocumentRequest?.success === false) {
          const error = result.data.createInsuranceDocumentRequest.error;
          const setError = error === undefined || error === null ? "" : error;
          setSubmitError(setError);
          setIsLoading(false);
          navigate(COI_EDIT_URL);
        } else {
          setIsLoading(false);
          setFormData({
            ...formData,
            ...initialCertificateState,
          });
          navigate(COI_SUBMIT_URL);
        }
      }
    } catch (error) {
      const errorMessage =
        "Sorry! Looks like there was an issue completing this task. You can refresh your browser and try again. If you continue experiencing issues, reach out to our customer support team.";
      setSubmitError(errorMessage);
      setIsLoading(false);
    }
    return;
  };

  return (
    <div>
      <Routes>
        <Route
          path={COI_EDIT_RELATIVE_URL}
          element={
            <CertificateOfInsuranceEdit
              formData={formData}
              setFormData={setFormData}
              submitError={submitError}
              setDocuments={setSupportingDocuments}
            />
          }
        />
        <Route
          path={COI_REVIEW_RELATIVE_URL}
          element={
            <CertificateReview
              formData={formData}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              submitError={submitError}
              supportingDocuments={supportingDocuments}
            />
          }
        />
        <Route
          path={COI_SUBMIT_RELATIVE_URL}
          element={<RequestSubmissionComplete />}
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </div>
  );
}
