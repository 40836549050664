import { ReactElement, useContext, useState } from "react";
import { FieldConfig, SetupFormGenerator } from "../../SetupFormGenerator";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { OffCyclePayloadType } from "../types";
import useCountryConfigFetcher from "../hooks/useCountryConfigFetcher";
import { EmployeePayType } from "pages/employer-of-record/payments/constants";

export const OffCycle = (): ReactElement => {
  const additionalPayContext = useContext(AdditionalPayContext);
  const [paymentSettingsFields, setPaymentSettingsFields] = useState<
    FieldConfig[]
  >([]);

  useCountryConfigFetcher(
    EmployeePayType.OFF_CYCLE,
    additionalPayContext,
    setPaymentSettingsFields
  );

  return (
    <>
      <div>
        <SetupFormGenerator<OffCyclePayloadType>
          title="Payment settings"
          fields={paymentSettingsFields}
          formikFieldName="paymentSettings"
        />
      </div>
    </>
  );
};
