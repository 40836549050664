import React from "react";
import styles from "../learn/partner-description-page.module.css";
import styles_new from "./LtdEnrollmentSetupPage.module.css";
import { useState, useEffect } from "react";
import { HEADER_INFO, ROUTES } from "../constants/pdpGeneral";
import { PageHeader, Button } from "@justworkshr/milo-core";
import {
  RadioButtonGroup,
  RadioButton,
  ActionFooter,
  Select,
  SelectGroup,
  SelectOption,
} from "@justworkshr/milo-form";
import { ResourceCard } from "./ResourceCard/ResourceCard";
import { NoncontributoryOptionsTable } from "./NoncontributoryOptionsTable/NoncontributoryOptionsTable";
import { OPTIONS_AND_COSTS_CONTENT } from "../constants/ltdTabContent";
import {
  LTD_COST_CALCULATION_EXAMPLE,
  PLAN_EFFECTIVENESS_DETAILS,
} from "../constants/EnrollmentPageContent";
import { Link, useNavigate } from "react-router-dom";
import {
  generateEffectiveDateOptions,
  resolveOpenEnrollmentWindow,
  openEnrollmentNoticeContent,
} from "../utils/utils";
import { openEnrollmentWindowDates } from "../../types/index";

const { headerBanner, leftColumn, rightColumn, footer } = styles;

const {
  headerText,
  headerTextSecond,
  contentDivision,
  policyEffectivenessNote,
  policyEffectivenessDate,
  dateSelect,
  setupFooter,
  enrollmentInfoCardHeader,
  reviewButtonText,
  enrollmentInfoCardContent,
  proTipOutline,
  framedRadioButton,
  framedRadioButtonContent,
  framedRadioButtonSelected,
  infoBox,
  enrollmentInfoOutline,
  enrollmentInfoCard,
} = styles_new;

export type costCalculationContent = {
  [key: string]: string;
};

export type costTableBenefitOption = {
  [key: string]: string | null;
};

export const LtdEnrollmentSetupPage: React.FC = () => {
  const [shouldShowVoluntaryNote, setVoluntaryNote] = useState<boolean>(false);
  const [isVoluntary, setIsVoluntary] = useState<boolean>(false);
  const [effectiveDate, setEffectiveDate] = useState<string | null>(null);
  const [openEnrollmentWindow, setOpenEnrollmentWindow] =
    useState<openEnrollmentWindowDates | null>(null);

  const [costCalculation, setCostCalculation] =
    useState<costCalculationContent>(
      LTD_COST_CALCULATION_EXAMPLE.EmployerOptionOne
    );

  const [benefitInfo, setBenefitInfo] = useState<costTableBenefitOption | null>(
    null
  );

  const [employerOptionOneClicked, setEmployerOptionOneClicked] =
    useState(false);
  const [employerOptionTwoClicked, setEmployerOptionTwoClicked] =
    useState(false);
  const [employeeOptionClicked, setEmployeeOptionClicked] = useState(false);

  useEffect(() => {
    if (effectiveDate !== null) {
      const openEnrollmentWindow = resolveOpenEnrollmentWindow(effectiveDate);
      setOpenEnrollmentWindow({
        startDate: openEnrollmentWindow.startDate,
        endDate: openEnrollmentWindow.endDate,
      });
    }
  }, [effectiveDate]);

  const hasNoOptionSelected: boolean =
    !employerOptionOneClicked &&
    !employerOptionTwoClicked &&
    !employeeOptionClicked;

  const handleOptionClick = (option: string): void => {
    switch (option) {
      case "option_1":
        employerOptionOneClicked
          ? setEmployerOptionOneClicked(false)
          : setEmployerOptionOneClicked(true);
        setEmployerOptionTwoClicked(false);
        setEmployeeOptionClicked(false);
        break;
      case "option_2":
        setEmployerOptionOneClicked(false);
        setEmployeeOptionClicked(false);
        employerOptionTwoClicked
          ? setEmployerOptionTwoClicked(false)
          : setEmployerOptionTwoClicked(true);
        break;
      case "option_3":
        setEmployerOptionOneClicked(false);
        setEmployerOptionTwoClicked(false);
        employeeOptionClicked
          ? setEmployeeOptionClicked(false)
          : setEmployeeOptionClicked(true);
        break;
    }
  };

  const setSelectedOption = (option: string): void => {
    switch (option) {
      case "option_1":
        setCostCalculation(LTD_COST_CALCULATION_EXAMPLE.EmployerOptionOne);
        setVoluntaryNote(false);
        setIsVoluntary(false);
        setBenefitInfo(
          OPTIONS_AND_COSTS_CONTENT.costTableInfo.employerPaidOptionOne
        );
        handleOptionClick(option);
        break;
      case "option_2":
        setCostCalculation(LTD_COST_CALCULATION_EXAMPLE.EmployerOptionTwo);
        setVoluntaryNote(false);
        setIsVoluntary(false);
        setBenefitInfo(
          OPTIONS_AND_COSTS_CONTENT.costTableInfo.employerPaidOptionTwo
        );
        handleOptionClick(option);
        break;
      case "option_3":
        setCostCalculation(LTD_COST_CALCULATION_EXAMPLE.EmployeeOption);
        setVoluntaryNote(true);
        setIsVoluntary(true);
        setBenefitInfo(
          OPTIONS_AND_COSTS_CONTENT.costTableInfo.employeePaidOption
        );
        handleOptionClick(option);
    }
  };

  const getOptionClickedStatus = (option: string): boolean => {
    switch (option) {
      case "option_1":
        return employerOptionOneClicked;
      case "option_2":
        return employerOptionTwoClicked;
      case "option_3":
        return employeeOptionClicked;
      default:
        return false;
    }
  };

  const availableEffectiveDates = generateEffectiveDateOptions();

  const navigate = useNavigate();

  const handleReviewClick = () => {
    navigate(ROUTES.reviewPage, {
      state: { effectiveDate: effectiveDate, benefitData: benefitInfo },
    });
  };

  return (
    <>
      <div className="milo--grid">
        <div className={headerBanner}>
          <PageHeader title="Set up long term disability insurance">
            {HEADER_INFO.ltdDescription}
          </PageHeader>
        </div>
        <div className={leftColumn}>
          <p className={headerText}>Coverage Options</p>
          <NoncontributoryOptionsTable
            costTableInfo={OPTIONS_AND_COSTS_CONTENT.costTableInfo}
          />
          <p className={headerTextSecond}>
            What policy would you like to offer your team?
          </p>
          <div>
            <RadioButtonGroup name="ltd_benefit_type_selection">
              {Object.values(OPTIONS_AND_COSTS_CONTENT.costTableInfo).map(
                (benefitOption: {
                  optionId: string;
                  optionLabel: string;
                  coverageAmount: string;
                  costs: string;
                }) => (
                  <div className={infoBox} key={benefitOption.optionId}>
                    <button
                      className={
                        getOptionClickedStatus(benefitOption.optionId)
                          ? framedRadioButtonSelected
                          : framedRadioButton
                      }
                      id={benefitOption.optionId}
                      onClick={() => setSelectedOption(benefitOption.optionId)}
                      type="button"
                    >
                      <RadioButton
                        name="ltd_benefit_type_selection"
                        value={benefitOption.optionId}
                        label={benefitOption.optionLabel}
                        id={benefitOption.optionId}
                        checked={getOptionClickedStatus(benefitOption.optionId)}
                        onChange={() =>
                          setSelectedOption(benefitOption.optionId)
                        }
                      ></RadioButton>
                      <p className={framedRadioButtonContent}>
                        {" "}
                        {benefitOption.coverageAmount}
                      </p>
                    </button>
                  </div>
                )
              )}
            </RadioButtonGroup>
          </div>
          <div className={contentDivision}></div>

          {!hasNoOptionSelected && (
            <section>
              <div className={proTipOutline}>
                <strong>Pro tip:</strong> Consider how much coverage you want to
                offer your employees. If most of your employees make around or
                over $100,000, you may want to provide more coverage. <br></br>
                <br></br> For example, your employee, Bennie, makes{" "}
                {LTD_COST_CALCULATION_EXAMPLE.salary} per year. With{" "}
                {costCalculation.optionFormatted},
                {isVoluntary ? " Bennie would" : " you’d"} pay{" "}
                {costCalculation.monthlyCost} per month for{" "}
                {isVoluntary ? " their" : " Bennie’s"} coverage and they would
                get {costCalculation.weeklyPay} per week and{" "}
                {costCalculation.monthlyPay} per month.
              </div>
            </section>
          )}

          <p className={headerTextSecond}>
            {PLAN_EFFECTIVENESS_DETAILS.effectiveDateQuestion}
          </p>
          <p className={policyEffectivenessNote}>
            {PLAN_EFFECTIVENESS_DETAILS.efectivenessEmployeeEligibility}
          </p>
          <p className={policyEffectivenessDate}>Policy effective date</p>
          <div className={dateSelect}>
            <Select
              placeholder="Select a month"
              onChange={(event) => setEffectiveDate(event.target.value)}
            >
              <SelectGroup label="">
                {availableEffectiveDates.map((effectiveDateOption, index) => (
                  <SelectOption key={index + 1}>
                    {effectiveDateOption}
                  </SelectOption>
                ))}
              </SelectGroup>
            </Select>
          </div>
          {shouldShowVoluntaryNote &&
            effectiveDate !== null &&
            openEnrollmentWindow !== null && (
              <div className={enrollmentInfoCard}>
                <section>
                  <div className={enrollmentInfoOutline}>
                    <p className={enrollmentInfoCardHeader}>Enrollment Info</p>
                    <p className={enrollmentInfoCardContent}>
                      {openEnrollmentNoticeContent(
                        openEnrollmentWindow.startDate,
                        openEnrollmentWindow.endDate,
                        effectiveDate
                      )}
                    </p>
                  </div>
                </section>
              </div>
            )}
          <div className={setupFooter}>
            {" "}
            <ActionFooter
              actions={[
                <Button
                  disabled={
                    effectiveDate === null ||
                    benefitInfo === null ||
                    hasNoOptionSelected
                  }
                  color="brand"
                  key="review"
                  onClick={handleReviewClick}
                >
                  <span className={reviewButtonText}>Review</span>
                </Button>,
              ]}
              secondary={[
                <Button
                  variant="outlined"
                  color="brand"
                  as={Link}
                  to={ROUTES.ltdPdpPage}
                  key="go_back"
                >
                  Back
                </Button>,
              ]}
            />
          </div>
        </div>
        <div className={rightColumn}>
          <ResourceCard selectedBenefit="ltd" />
        </div>
      </div>
      <div className={footer}></div>
    </>
  );
};
