import { useState } from "react";
import { Button } from "@justworkshr/milo-core";
import Dropdown from "../../../components/Dropdown/Dropdown";
import styles from "./DropdownMenu.module.css";
import { DropdownMenuRow } from "./components/DropdownMenuRow";
import { useNavigate } from "react-router-dom";
import { TABNAMES } from "../../constants";

interface DropdownMenuProps {
  selected: string;
  paths: string[];
}

export const DropdownMenu = ({ selected, paths }: DropdownMenuProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const navigate = useNavigate();

  const goToSubRoute = (route: string) => {
    navigate(`${route}`);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const openChange = (newOpen: boolean) => {
    if (newOpen) {
      setDropdownOpen(true);
    } else {
      closeDropdown();
    }
  };

  return (
    <div>
      <Dropdown
        dropdownName="employee profile actions"
        contentClassName={styles.contentContainer}
        content={
          <>
            {paths.map((path, index) => (
              <DropdownMenuRow
                key={index}
                name={path}
                displayName={TABNAMES[path]}
                onClick={() => goToSubRoute(path)}
                selected={path == selected}
              />
            ))}
          </>
        }
        open={dropdownOpen}
        onOpenChange={openChange}
        onClickOutside={closeDropdown}
      >
        <Button
          color="neutral"
          type="button"
          variant="outlined"
          className={styles.dropdownButton}
          rightIcon="chevron-down"
          size="sm"
        >
          <div className={styles.dropdownTitleWrapper}>
            <span>{TABNAMES[selected]}</span>
          </div>
        </Button>
      </Dropdown>
    </div>
  );
};
