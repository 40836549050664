import { Form, Formik } from "formik";
import { ReactElement, useContext } from "react";

import styles from "../../../SeparationPay.module.css";
import {
  AdditionalPaymentsFormValues,
  AdditionalPaymentsSchema,
} from "../../../SeparationPay.formUtils";
import { SeparationFlowContext } from "pages/team-management/contexts";
import { PaymentInputSection } from "./PaymentInputSection";
import { Button } from "@justworkshr/milo-core";
import { AdditionalPaymentsPageContext } from "../../AdditionalPaymentsPageContextProvider";
import Divider from "pages/life-and-leave/enrollment/components/Divider/Divider";
import { ADDITIONAL_PAYMENTS_FORM_COPY } from "../../AdditionalPaymentsPage.constants";

const { PaymentContainer, FormWrapper, AddSubtractButtonContainer } = styles;

export const AdditionalPaymentsForm = ({
  paymentKey,
  paymentKeys,
  setPaymentKeys,
  terminationDate,
  earliestPayDate,
}: {
  paymentKey: number;
  paymentKeys: number[];
  setPaymentKeys: (paymentKeys: number[]) => void;
  terminationDate: Date;
  earliestPayDate: Date;
}): ReactElement => {
  // See if we are returning to this page from having previously filled out values.
  // If so, pre-populate the form with those values.
  const {
    additionalPaymentsFormValues,
    setAdditionalPaymentsFormValues,
    paymentDetailsFormValues,
    setPaymentDetailsFormValues,
  } = useContext(SeparationFlowContext);

  const {
    setSubmitHandlers,
    isAdditionalPaymentsFormDirty,
    setIsAdditionalPaymentsFormDirty,
  } = useContext(AdditionalPaymentsPageContext);

  const additionalPaymentsDefaultValues: AdditionalPaymentsFormValues = {
    type: "",
    repeat_payment: "no",
    pay_date: "",
    amount: "$",
    notes: "",
    pay_frequency: "",
    number_of_payments: "",
    payment_method: "",
    termination_date: terminationDate,
    earliest_pay_date: earliestPayDate,
  };

  const additionalPaymentsInitialValues: AdditionalPaymentsFormValues = {
    ...additionalPaymentsDefaultValues,
    ...additionalPaymentsFormValues?.[paymentKey],
  };

  const additionalPaymentsSubmit = (values: AdditionalPaymentsFormValues) => {
    isAdditionalPaymentsFormDirty[paymentKey] &&
      // @ts-expect-error: un-typable setState function
      setPaymentDetailsFormValues?.((prevState) => ({
        ...prevState,
        [paymentKey]: { type: values.type },
      }));

    // @ts-expect-error: un-typable setState function
    setAdditionalPaymentsFormValues?.((prevState) => ({
      ...prevState,
      [paymentKey]: values,
    }));
  };

  const addPayment = () => {
    setPaymentKeys([...paymentKeys, paymentKeys[paymentKeys.length - 1] + 1]);
  };

  const deletePayment = () => {
    setPaymentKeys(paymentKeys.filter((key) => key !== paymentKey));
    if (additionalPaymentsFormValues) {
      // @ts-expect-error: un-typable setState function
      setAdditionalPaymentsFormValues?.((prevState) => {
        delete prevState[paymentKey];
        return prevState;
      });
    }

    // @ts-expect-error: un-typable setState function
    setSubmitHandlers?.((prevState) => {
      delete prevState[paymentKey];
      return prevState;
    });

    if (paymentDetailsFormValues) {
      // @ts-expect-error: un-typable setState function
      setPaymentDetailsFormValues?.((prevState) => {
        delete prevState[paymentKey];
        return prevState;
      });
    }

    if (isAdditionalPaymentsFormDirty[paymentKey]) {
      // @ts-expect-error: un-typable setState function
      setIsAdditionalPaymentsFormDirty?.((prevState) => {
        delete prevState[paymentKey];
        return prevState;
      });
    }
  };

  return (
    <Formik
      initialValues={additionalPaymentsInitialValues}
      onSubmit={additionalPaymentsSubmit}
      validationSchema={AdditionalPaymentsSchema}
    >
      {({ handleSubmit }) => {
        return (
          <>
            <Divider size="3xl" />

            <Form
              data-testid={`payment-form-${paymentKey}`}
              onSubmit={handleSubmit}
              id={`AdditionalPaymentsForm-${paymentKey}`}
            >
              <div className={FormWrapper}>
                <div className={PaymentContainer}>
                  <PaymentInputSection paymentKey={paymentKey} />
                  <div className={AddSubtractButtonContainer}>
                    <Button
                      variant="ghost"
                      onClick={addPayment}
                      leftIcon="plus"
                      data-testid="add-payment"
                    >
                      {ADDITIONAL_PAYMENTS_FORM_COPY.add_additional_payment}
                    </Button>
                    {paymentKeys.length > 1 && (
                      <Button
                        color="destructive"
                        variant="ghost"
                        onClick={deletePayment}
                        leftIcon="trash"
                      >
                        {
                          ADDITIONAL_PAYMENTS_FORM_COPY.remove_additional_payment
                        }
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
