import styles from "../../enrollment/setup/components/EnrollmentSetupPage.module.css";

const { enrollmentInfoContentSpace } = styles;
export const generateEffectiveDateOptions = () => {
  const currentMonth: number = new Date().getMonth();
  const firstOfMonths: Date[] = [];
  for (let month = 0; month < 12; month++) {
    const year: number =
      month > currentMonth
        ? new Date().getFullYear()
        : new Date().getFullYear() + 1;
    firstOfMonths.push(new Date(year, month, 1));
  }

  firstOfMonths.sort((a, b) => a.getTime() - b.getTime());
  const sortedAllowedEffectiveDates: string[] = firstOfMonths.map((date) =>
    formatDate(date)
  );
  return sortedAllowedEffectiveDates;
};

export const resolveOpenEnrollmentWindow = (effectiveDateString: string) => {
  type openEnrollmentWindow = {
    startDate: string;
    endDate: string;
  };
  const today = new Date();
  const effectiveDate = new Date(effectiveDateString);

  // Calculate the date 30 days before the effective date
  const thirtyDaysBeforeEffectiveDate = new Date(
    new Date(effectiveDate).setDate(new Date(effectiveDate).getDate() - 30)
  );

  const startDate =
    today < thirtyDaysBeforeEffectiveDate
      ? new Date(effectiveDate.getFullYear(), effectiveDate.getMonth() - 1, 1)
      : today;

  const endDate = new Date(
    new Date(startDate).setDate(new Date(startDate).getDate() + 30)
  );

  const openEnrollmentWindowDates: openEnrollmentWindow = {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  };

  return openEnrollmentWindowDates;
};

export const openEnrollmentNoticeContent = (
  startDate: string,
  endDate: string,
  effectiveDate: string
) => {
  return (
    <>
      Once you set up your policy, your eligible employees will be able to
      enroll from {startDate} - {endDate}. Once enrolled, policies will become
      effective on {effectiveDate}.
      <p className={enrollmentInfoContentSpace}>
        Future employees will be able to enroll beginning on the day they become
        eligible, and their policies will be effective the first of the month
        after they become eligible.
      </p>
    </>
  );
};

const formatDate = (date: Date) => {
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};
