import React from "react";
import styles from "../Offboarding.module.css";
import RequestSeparationHeader from "./Header";
import RequestSeparationStepper from "./Stepper";
import { Alert, FlexSpace, Row } from "@justworkshr/milo-core";
import { useRequestSeparationContext } from "../context/RequestSeparationContext";

type Props = {
  headerTitle: string;
  children: React.ReactNode;
};

const RequestSeparationLayout = ({ headerTitle, children }: Props) => {
  const { alert } = useRequestSeparationContext();
  return (
    <>
      {alert && (
        <Alert color={alert.type} dismissible visible={alert.visible}>
          {alert.message}
        </Alert>
      )}
      <div className={styles.pageContainer}>
        <RequestSeparationHeader title={headerTitle} />
        <Row spacing="2xl">
          <RequestSeparationStepper />
          <FlexSpace className={styles.stepViewContainer}>{children}</FlexSpace>
        </Row>
      </div>
    </>
  );
};

export default RequestSeparationLayout;
