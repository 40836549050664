import React, { useState } from "react";
import { Alert, Button, Dialog } from "@justworkshr/milo-core";
import useProcessPmtIntegrationAction from "../../hooks/useProcessPmtIntegrationAction";
import { useAlert } from "pages/pmt/integrations/hooks/useAlert";
import { StepperHelpers } from "../../hooks/useStepper";

export interface ResetButtonProps extends StepperHelpers {
  integrationSlug?: string | undefined;
  allowedResetSteps?: number[];
}

const supportLink = (
  <a className="milo--link-secondary" href={`mailto: support@justworks.com`}>
    support@justworks.com
  </a>
);

const resetFailureAlert = {
  message: (
    <>
      We weren’t able to reset the integration setup. Try again or email support
      at {supportLink}
    </>
  ),
  color: "destructive" as const,
  visible: true,
  dismissible: false,
};

const ResetButton: React.FC<ResetButtonProps> = ({
  integrationSlug,
  allowedResetSteps,
  ...stepperHelpers
}) => {
  const { processPmtIntegrationAction, loading } =
    useProcessPmtIntegrationAction();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeDialog = () => setIsDialogOpen(false);
  const { alertProps, setAlert, resetAlert } = useAlert();

  const disable = () => {
    if (!integrationSlug) {
      return;
    }
    processPmtIntegrationAction(integrationSlug, "disable").then(
      (processActionResponse) => {
        if (processActionResponse?.success) {
          resetAlert();
          stepperHelpers.reset();
          closeDialog();
        } else {
          setAlert(resetFailureAlert);
        }
      }
    );
  };

  return (
    <div>
      <Button
        variant="ghost"
        leftIcon="undo"
        type="button"
        onClick={() => setIsDialogOpen(true)}
        disabled={!allowedResetSteps?.includes(stepperHelpers.currentStep)}
      >
        Reset setup
      </Button>
      <Dialog
        title={"Reset integration setup"}
        isOpen={isDialogOpen}
        onClose={closeDialog}
        showCloseButton={true}
        primaryButton={
          <Button
            onClick={disable}
            variant="filled"
            loading={loading}
            disabled={!integrationSlug || loading}
          >
            Reset
          </Button>
        }
        secondaryButton={
          <Button variant="ghost" onClick={closeDialog}>
            Cancel
          </Button>
        }
      >
        <Alert {...alertProps} />
        {alertProps.visible && <br />}
        By resetting the integration setup, you will be able to start fresh and
        set the integration again.
      </Dialog>
    </div>
  );
};

export default ResetButton;
