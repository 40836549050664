import { useCallback } from "react";
import { useSettingsSelectorStore } from "../../store";
import { SettingsConfigurationActionTypes } from "../../actions";
import { SettingsConfigurationStoreType } from "../../constants";

export const useSettingsConfigurationStore = () => {
  const { store: settingsConfiguration, dispatch } = useSettingsSelectorStore(
    (store) => store.SettingsConfiguration
  );

  const setSettingsConfiguration = useCallback(
    (payload: SettingsConfigurationStoreType) => {
      dispatch({
        type: SettingsConfigurationActionTypes.SET_SETTINGS_CONFIGURATION,
        payload,
      });
    },
    [dispatch]
  );

  return { settingsConfiguration, setSettingsConfiguration };
};
