import { AlertTypes, prependBasePath } from "pages/expenses/constants";
import { useFeedbackBannerStore } from "pages/expenses/store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDeleteUnattachedExpenseMutation } from "types/generated/operations";

export const useDeleteUnattachedExpense = (callback: () => void) => {
  const navigate = useNavigate();
  const { setFeedbackBanner } = useFeedbackBannerStore();

  const [
    deleteUnattachedExpense,
    {
      data: deleteUnattachedExpenseData,
      loading: isDeleteUnattachedExpenseLoading,
    },
  ] = useDeleteUnattachedExpenseMutation();

  useEffect(() => {
    if (
      !isDeleteUnattachedExpenseLoading &&
      deleteUnattachedExpenseData?.deleteUnattachedExpenses?.success &&
      deleteUnattachedExpenseData?.deleteUnattachedExpenses?.deletedExpenses
    ) {
      callback();

      const isMultipleDeletedExpenses =
        deleteUnattachedExpenseData.deleteUnattachedExpenses.deletedExpenses
          .length > 1;

      navigate(prependBasePath("EXPENSE_LIBRARY"));

      setFeedbackBanner({
        type: AlertTypes.SUCCESS,
        show: true,
        message: `Your ${
          isMultipleDeletedExpenses ? "expenses were" : "expense was"
        } successfully deleted.`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setFeedbackBanner,
    deleteUnattachedExpenseData,
    isDeleteUnattachedExpenseLoading,
  ]);

  return {
    deleteUnattachedExpense,
    isDeleteUnattachedExpenseLoading,
  };
};
