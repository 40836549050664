import { ReactElement } from "react";
import styles from "./RehireContractorModal.module.css";
import { Button, Dialog } from "@justworkshr/milo-core";
import { DialogHeader } from "../DialogHeader";

type Props = {
  onDismiss: () => void;
  onSubmit: () => void;
  loading?: boolean;
};

export default function RehireContractorModal({
  onDismiss,
  onSubmit,
  loading,
}: Props): ReactElement {
  return (
    <div className={styles.container}>
      <Dialog
        isOpen
        onClose={onDismiss}
        showCloseButton={false}
        primaryButton={
          <Button
            onClick={onSubmit}
            variant="filled"
            loading={loading}
            disabled={loading}
          >
            Rehire
          </Button>
        }
        secondaryButton={
          <Button onClick={onDismiss} variant="ghost">
            Cancel
          </Button>
        }
      >
        <DialogHeader
          onClose={onDismiss}
          alertError={""}
          title="You are about to rehire this contractor."
        />
        <div className={styles.content}>
          <p className={styles.description}>
            This will change the contractor status to Ready for Payment. Once
            this occurs, you will have the ability to make payments to them.
          </p>
          <p>Please ensure their bank information is up to date.</p>
        </div>
      </Dialog>
    </div>
  );
}
