import { ConfigurationStoreType } from "../constants";
import { GetConfigurationQuery } from "types/generated/operations";

export const handleConfigurationPayload = (
  data: GetConfigurationQuery
): ConfigurationStoreType => {
  return {
    isExpensesEnabled: data?.expenseManagement?.policy?.enabled || false,
    isExpensesEverEnabled:
      data?.expenseManagement?.policy?.everEnabled || false,
    hasJoinedExpenses:
      data?.authenticatedMember?.company?.companySetting?.value === "true" ||
      false,
    expenseAdmins:
      data?.expenseManagement?.expenseAdmins.map(
        (expenseAdmin) => expenseAdmin.friendlyFullName
      ) || [],
    permissionsAdmins:
      data?.expenseManagement?.permissionsAdmins.map(
        (permissionsAdmin) => permissionsAdmin.friendlyFullName
      ) || [],
    permissions: {
      isAdmin: data?.expenseManagement?.iHaveAdminPermission || false,
      isGranter:
        data?.expenseManagement?.iHaveGrantPermissionsPermission || false,
      isManager: data?.expenseManagement?.iHaveManagerPermission || false,
      isReporter: data?.expenseManagement?.iHaveReportsPermission || false,
      isSubmitter: data?.expenseManagement?.iHaveSubmitterPermission || false,
      isAdminAndManager:
        (data?.expenseManagement?.iHaveAdminPermission &&
          data?.expenseManagement?.iHaveManagerPermission) ||
        false,
      isAllowedToAccessReviewPage:
        data?.expenseManagement?.iAmAllowedIntoReviewPage || false,
      isAllowedToAccessSettingsPages:
        data?.expenseManagement?.iAmAllowedIntoSettingsPages || false,
      isAllowedToAccessProductDetailsPage:
        data?.expenseManagement?.iAmAllowedToViewProductDetailsPage || false,
      isAllowedToAccessReviewHistoryPage:
        data?.expenseManagement?.iAmAllowedIntoReviewHistoryPage || false,
      isAllowedToFirstTimeSetup:
        data?.expenseManagement?.iAmAllowedToFirstTimeSetup || false,
      isRequiredToViewProductDetailsPage:
        data?.expenseManagement?.iAmRequiredToViewProductDetailsPage || false,
    },
  };
};
