import { FormEvent, ReactElement } from "react";
import {
  FormField,
  TextInput,
  TextArea,
  InputMessage,
} from "@justworkshr/milo-form";
import styles from "./job-information-edit.module.css";
import { JobInformationEditProps } from "../../types";
import DateSelect from "./date-select/date-select";

const { jobInformation, formBorder, firstInput, inputField, header } = styles;

export default function JobInformationEdit({
  formData,
  errors,
  handleDateChange,
  handleEventChange,
}: JobInformationEditProps): ReactElement {
  return (
    <div className={formBorder}>
      <div className="milo--grid">
        <div className={jobInformation} data-testid="jobInformationEdit">
          <h2 className={header}>Job Information</h2>
          <hr />
          <div className={firstInput}>
            <div className={inputField}>
              <FormField
                name="jobName"
                label="Job name"
                message="If this is for a lease, state 'lease'."
                error={errors.jobName}
              >
                <>
                  <TextInput
                    name="jobName"
                    onChange={handleEventChange}
                    value={formData.jobName}
                  />
                  <InputMessage type="help">
                    {
                      "Either specific name of job or If this is for a lease, state 'lease'."
                    }
                  </InputMessage>
                </>
              </FormField>
            </div>
          </div>
          <div className={inputField}>
            <FormField
              name="jobNumber"
              label="Contract number"
              message="Enter a job or contract number if relevant to your organization."
              error={errors.jobNumber}
            >
              <>
                <TextInput
                  name="jobNumber"
                  onChange={handleEventChange}
                  value={formData.jobNumber}
                />
                <InputMessage type="help">
                  {"If there is no job number, this can be left blank."}
                </InputMessage>
              </>
            </FormField>
          </div>
          <div className={inputField}>
            <FormField
              name="dateStart"
              label="Start date"
              required={true}
              error={errors.dateStart}
            >
              <>
                <DateSelect
                  id="dateStart"
                  onChange={(date: FormEvent<HTMLInputElement> | Date) =>
                    handleDateChange(date, "dateStart")
                  }
                  value={formData.dateStart}
                />
                <InputMessage type="help">
                  {"List the month, day, and year."}
                </InputMessage>
              </>
            </FormField>
          </div>
          <div className={inputField}>
            <FormField
              name="dateEnd"
              label="End date"
              required={true}
              message="We can help you renew the waiver if dates change."
              error={errors.dateEnd}
            >
              <>
                <DateSelect
                  id="dateEnd"
                  onChange={(date: FormEvent<HTMLInputElement> | Date) =>
                    handleDateChange(date, "dateEnd")
                  }
                  value={formData.dateEnd}
                />
                <InputMessage type="help">
                  {"List the month, day, and year."}
                </InputMessage>
              </>
            </FormField>
          </div>
          <div className={inputField}>
            <FormField
              name="jobDuties"
              label="Job duties"
              required={true}
              message="Provide a detailed explanation of the task and duties performed by employees for this job. What are the employees actually, physically doing? (Avoid single word descriptions or “N/A”)"
              error={errors.jobDuties}
            >
              <TextArea
                name="jobDuties"
                onChange={handleEventChange}
                value={formData.jobDuties}
              />
            </FormField>
          </div>
        </div>
      </div>
    </div>
  );
}
