import { Alert, PageHeader } from "@justworkshr/milo-core";
import moment from "moment";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useInviteEorMutation } from "types/generated/operations";
import { SupportedCountriesContext } from "../contexts/supportedCountriesContext";
import styles from "./InvitePage.module.css";
import Form, {
  COUNTRY_EXTENDED_CONFIG,
  SchemaType,
  isCountryExtendedConfig,
  type InviteFormProps,
} from "./form";
import { ContractTerm, EmailTypes, PayBasis, type AlertState } from "./types";
import {
  convertNumberToPennies,
  filterObject,
  scrollToTop,
  today,
} from "./utils";

const initialValues: SchemaType = {
  firstName: "",
  lastName: "",
  homeEmail: "",
  workEmail: "",
  contractTerm: ContractTerm.Indefinite,
  jobTitle: "",
  workCountry: "",
  payBasis: PayBasis.Salary,
  sendInvitationTo: EmailTypes.Work,
  startDate: "",
  payRate: "",
  managerUuid: "",
  departmentUuid: "",
  roleResponsibilities: "",
};

export default function InvitePage() {
  const { t } = useTranslation();
  const [inviteEorMutation, { loading }] = useInviteEorMutation();

  const [alert, setAlert] = useState<AlertState>();
  const { supportedCountriesData } = useContext(SupportedCountriesContext);

  const handleFormSubmit: InviteFormProps["onFormSubmit"] = (
    { sendInvitationTo, ...formValues },
    formActions
  ) => {
    const sendInviteTo =
      sendInvitationTo === EmailTypes.Home
        ? formValues.homeEmail ?? formValues.workEmail
        : formValues.workEmail;

    const currency = () => {
      const currencies =
        supportedCountriesData[formValues.workCountry]?.currencies;

      return currencies != null ? currencies[0].currencyCode : "";
    };

    const buildCountryDTO = () => {
      const workCountry = formValues.workCountry;
      const countryConfig = isCountryExtendedConfig(workCountry)
        ? COUNTRY_EXTENDED_CONFIG[workCountry]
        : undefined;

      if (countryConfig?.initialValues) {
        const keysToExclude = countryConfig.keysToExclude;
        const filteredEntries = Object.entries(formValues).filter(
          ([key]) => key in countryConfig.initialValues
        );
        const countryValues = Object.fromEntries(filteredEntries);

        return keysToExclude
          ? filterObject(countryValues, keysToExclude)
          : countryValues;
      }
    };

    const filteredBaseEntries = Object.entries(formValues).filter(
      ([key]) => key in initialValues
    );

    const baseValues = Object.fromEntries(
      filteredBaseEntries
    ) as typeof initialValues;

    const handleOnCompleted = () => {
      formActions.resetForm();
      setAlert({
        visible: true,
        message: `${t(
          "An invitation to the Employer of Record has been sent to"
        )} ${sendInviteTo}`,
        type: "additive",
      });
      scrollToTop();
    };

    const handleOnError = () => {
      setAlert({
        visible: true,
        message: t("An error occurred while sending the invitation"),
        type: "destructive",
      });
      scrollToTop();
    };

    inviteEorMutation({
      variables: {
        formDetails: {
          ...baseValues,
          currency: currency(),
          payRate: convertNumberToPennies(formValues.payRate),
          sendInviteAt: today,
          sendInviteTo,
          startDate: moment(formValues.startDate).format("YYYY-MM-DD"),
          ...buildCountryDTO(),
        },
      },
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    });
  };

  return (
    <div className={styles.pageContainer}>
      {alert && (
        <Alert
          className={styles.alert}
          color={alert.type}
          dismissible
          visible={alert.visible}
        >
          {alert.message}
        </Alert>
      )}

      <PageHeader
        title={t("Add person")}
        linkPrevious={
          <Link to="/invitation-center">{t("Back to Invitation Center")}</Link>
        }
      />
      <Form
        onFormSubmit={handleFormSubmit}
        isLoading={loading}
        initialValues={initialValues}
      />
    </div>
  );
}
