import { ROUTES, ROUTES_PERMISSIONS } from "./constants";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  ExpensesProvider,
  RequestDetailProvider,
  ExpensesConfigurationConsumer,
} from "./store";
import {
  FeedbackBanner,
  FeedbackDialog,
  ExceptionBoundary,
  PermissionsWrapper,
} from "./components";
import { scrollToTop, useFesk } from "./hooks";
import {
  ReviewerOutlet,
  SettingsOutlet,
  SubmitterOutlet,
  RequestDetailOutlet,
} from "./outlets";
import {
  SettingsPage,
  ProjectsPage,
  AuditLogPage,
  CategoriesPage,
  RequestsListPage,
  RequestDetailPage,
  ExpenseLibraryPage,
  ProductDetailsPage,
  ExpenseDetailsPage,
  ActiveRequestsPage,
  RequestHistoryPage,
  ReimbursementRequestPage,
  SubmissionRequirementsPage,
} from "./routes";
import { SettingsPagePermissionsWrapper } from "./routes/SettingsPage/components";
import { ActiveRequestsPagePermissionsWrapper } from "./routes/ActiveRequestsPage/components";
import { RequestHistoryPagePermissionsWrapper } from "./routes/RequestHistoryPage/components";
import { RequestDetailPagePermissionsWrapper } from "./routes/RequestDetailPage/components";

export const ExpenseManagementApp = () => {
  const location = useLocation();
  const isFeskEnabled = useFesk();

  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  if (isFeskEnabled) {
    return null;
  }

  return (
    <div id="ExpenseManagementApp">
      <ExceptionBoundary>
        <ExpensesProvider>
          <ExpensesConfigurationConsumer>
            <RequestDetailProvider>
              <FeedbackBanner />
              <FeedbackDialog />
              <Routes>
                <Route
                  path={ROUTES.LEARN}
                  element={
                    <PermissionsWrapper permission={ROUTES_PERMISSIONS.LEARN}>
                      <ProductDetailsPage />
                    </PermissionsWrapper>
                  }
                />
                <Route
                  path={ROUTES.EXPENSE_DETAILS}
                  element={
                    <PermissionsWrapper
                      permission={ROUTES_PERMISSIONS.EXPENSE_DETAILS}
                    >
                      <ExpenseDetailsPage />
                    </PermissionsWrapper>
                  }
                />
                <Route
                  path={ROUTES.ACTIVE_REQUESTS_LIST}
                  element={<ReviewerOutlet />}
                >
                  <Route
                    path={ROUTES.ACTIVE_REQUESTS_LIST}
                    element={
                      <ActiveRequestsPagePermissionsWrapper>
                        <ActiveRequestsPage />
                      </ActiveRequestsPagePermissionsWrapper>
                    }
                  />
                  <Route
                    path={ROUTES.REQUEST_HISTORY}
                    element={
                      <RequestHistoryPagePermissionsWrapper>
                        <RequestHistoryPage />
                      </RequestHistoryPagePermissionsWrapper>
                    }
                  />
                </Route>
                <Route path={ROUTES.HOME} element={<SubmitterOutlet />}>
                  <Route
                    path={ROUTES.HOME}
                    element={
                      <PermissionsWrapper permission={ROUTES_PERMISSIONS.HOME}>
                        <RequestsListPage />
                      </PermissionsWrapper>
                    }
                  />
                  <Route
                    path={ROUTES.EXPENSE_LIBRARY}
                    element={
                      <PermissionsWrapper
                        permission={ROUTES_PERMISSIONS.EXPENSE_LIBRARY}
                      >
                        <ExpenseLibraryPage />
                      </PermissionsWrapper>
                    }
                  />
                </Route>
                <Route path={ROUTES.SETTINGS} element={<SettingsOutlet />}>
                  <Route
                    path={ROUTES.SETTINGS}
                    element={
                      <SettingsPagePermissionsWrapper>
                        <SettingsPage />
                      </SettingsPagePermissionsWrapper>
                    }
                  />
                  <Route
                    path={ROUTES.CATEGORIES}
                    element={
                      <PermissionsWrapper
                        permission={ROUTES_PERMISSIONS.CATEGORIES}
                      >
                        <CategoriesPage />
                      </PermissionsWrapper>
                    }
                  />
                  <Route
                    path={ROUTES.SUBMISSION_REQUIREMENTS}
                    element={
                      <PermissionsWrapper
                        permission={ROUTES_PERMISSIONS.SUBMISSION_REQUIREMENTS}
                      >
                        <SubmissionRequirementsPage />
                      </PermissionsWrapper>
                    }
                  />
                  <Route
                    path={ROUTES.PROJECTS}
                    element={
                      <PermissionsWrapper
                        permission={ROUTES_PERMISSIONS.PROJECTS}
                      >
                        <ProjectsPage />
                      </PermissionsWrapper>
                    }
                  />
                </Route>
                <>
                  <Route
                    path={ROUTES.NEW_REQUEST}
                    element={
                      <PermissionsWrapper
                        permission={ROUTES_PERMISSIONS.NEW_REQUEST}
                      >
                        <ReimbursementRequestPage />
                      </PermissionsWrapper>
                    }
                  />
                  <Route
                    path={ROUTES.EDIT_REQUEST}
                    element={
                      <PermissionsWrapper
                        permission={ROUTES_PERMISSIONS.EDIT_REQUEST}
                      >
                        <ReimbursementRequestPage />
                      </PermissionsWrapper>
                    }
                  />
                </>
                <Route
                  path={ROUTES.REQUEST_DETAILS}
                  element={<RequestDetailOutlet />}
                >
                  <Route
                    path={ROUTES.REQUEST_DETAILS}
                    element={
                      <PermissionsWrapper
                        permission={ROUTES_PERMISSIONS.REQUEST_DETAILS}
                      >
                        <RequestDetailPage />
                      </PermissionsWrapper>
                    }
                  />
                  <Route
                    path={ROUTES.AUDIT_LOG}
                    element={
                      <PermissionsWrapper
                        permission={ROUTES_PERMISSIONS.AUDIT_LOG}
                      >
                        <AuditLogPage />
                      </PermissionsWrapper>
                    }
                  />
                  <Route
                    path={ROUTES.REVIEW_REQUEST}
                    element={
                      <RequestDetailPagePermissionsWrapper>
                        <RequestDetailPage isApproverView />
                      </RequestDetailPagePermissionsWrapper>
                    }
                  />
                </Route>
              </Routes>
            </RequestDetailProvider>
          </ExpensesConfigurationConsumer>
        </ExpensesProvider>
      </ExceptionBoundary>
    </div>
  );
};

export default ExpenseManagementApp;
