import { ReactElement, useState } from "react";
import styles from "./ActionsMenuContent.module.css";
import { Spinner } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import { useGetInternationalDocumentUrlLazyQuery } from "types/generated/operations";

interface ActionsMenuContentProps {
  documentId: string;
  deleteDocument: () => void;
  editDocument: () => void;
  close: () => void;
}

export default function ActionsMenuContent({
  documentId,
  deleteDocument,
  editDocument,
  close,
}: ActionsMenuContentProps): ReactElement {
  const [fileOpening, setFileOpening] = useState(false);
  const [fileOpeningError, setFileOpeningError] = useState(false);
  const [fileDownloading, setFileDownloading] = useState(false);
  const [fileDownloadingError, setFileDownloadingError] = useState(false);

  const fileLoading = fileOpening || fileDownloading;

  const [getInternationalDocumentUrl] = useGetInternationalDocumentUrlLazyQuery(
    {
      variables: {
        documentId,
      },
      fetchPolicy: "no-cache",
    }
  );

  const deleteDoc = () => {
    deleteDocument();
    close();
  };

  const editDoc = () => {
    editDocument();
    close();
  };

  const downloadFile = async () => {
    setFileDownloading(true);

    const { data, error } = await getInternationalDocumentUrl();

    if (error) {
      setFileDownloading(false);
      setFileDownloadingError(true);
    } else {
      const link = document.createElement("a");
      link.href = data?.internationalDocumentUrl.url || "";
      link.setAttribute("download", "document.pdf");
      document.getElementsByClassName(styles.links)[0].appendChild(link);
      link.click();
      document.getElementsByClassName(styles.links)[0].removeChild(link);
      close();
    }
  };

  const openFile = async () => {
    setFileOpening(true);

    const { data, error } = await getInternationalDocumentUrl();

    if (error) {
      setFileOpening(false);
      setFileOpeningError(true);
    } else {
      const dataUrl = data?.internationalDocumentUrl.url || "";
      window.open(dataUrl, "_blank");
      close();
    }
  };

  return (
    <div title="Document actions" className={styles.links}>
      <div
        className={`${styles.link} ${fileDownloading ? styles.loading : ""} ${
          fileLoading ? styles.disabled : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          fileLoading ? null : downloadFile();
        }}
      >
        {fileDownloading ? (
          <Spinner />
        ) : (
          <>
            <SystemIcon iconName="download" className={styles.icon} />
            <span>{fileDownloadingError ? "Retry" : "Download"}</span>
          </>
        )}
      </div>
      <div
        className={`${styles.link} ${fileOpening ? styles.loading : ""} ${
          fileLoading ? styles.disabled : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          fileLoading ? null : openFile();
        }}
      >
        {fileOpening ? (
          <Spinner />
        ) : (
          <>
            <SystemIcon iconName="external-link" className={styles.icon} />
            <span>{fileOpeningError ? "Retry" : "Open"}</span>
          </>
        )}
      </div>
      <div
        className={`${styles.link} ${fileLoading ? styles.disabled : ""}`}
        onClick={fileLoading ? () => null : editDoc}
      >
        <SystemIcon iconName="edit" className={styles.icon} />
        <span>Edit</span>
      </div>
      <div
        className={`${styles.link} ${fileLoading ? styles.disabled : ""}`}
        onClick={fileLoading ? () => null : deleteDoc}
      >
        <SystemIcon iconName="trash" className={styles.icon} />
        <span>Delete</span>
      </div>
    </div>
  );
}
