// source of truth: clockwork_web/config/initializers/globals.rb
export enum PERMISSION {
  BENEFITS = 1,
  TIMECARDS = 2,
  MAKE_PAYMENTS = 3,
  CHANGE_EMPLOYEES = 4,
  ADMIN_PTO = 5,
  EMPLOYEE_INFO_FULL = 6,
  DOC_CENTER_ADMIN = 7,
  //  INSIGHTS=8,
  PAY_METRICS = 9,
  EMPLOYEE_INFO_LIMITED = 10,
  GRANT_PERMISSIONS = 11,
  CHANGE_COMPANY_SETTINGS = 12,
  PAY_CONTRACTORS_VENDORS = 13,
  EXPENSES = 14,
}
