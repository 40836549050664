import { FC, useState } from "react";
import { Formik, FormikProps } from "formik";
import { Button, Card } from "@justworkshr/milo-core";
import { CheckboxInput, Form, FormField } from "@justworkshr/milo-form";
import * as Yup from "yup";
import { useLaunchEoRIdVerificationMutation } from "types/generated/operations";
import { IdVerificationFormType } from "./types";
import styles from "./IdVerification.module.css";

interface Props {
  memberId: string;
  onSubmit: (values: IdVerificationFormType) => void;
}

const LaunchIdVerification: FC<Props> = ({ memberId, onSubmit }) => {
  const personaTermsOfUseLink = (
    <a
      href="https://withpersona.com/legal/terms-of-use"
      target="_blank"
      rel="noopener noreferrer"
    >
      Terms of Use
    </a>
  );

  const [launching, setLaunching] = useState(false);

  const [launchEoRIdVerification] = useLaunchEoRIdVerificationMutation({
    variables: {
      memberId: memberId || "",
    },
    onCompleted: (data) => {
      if (data.personaLaunchIdVerification?.hostedFlowUrl) {
        setLaunching(false);
        window.location.href = data.personaLaunchIdVerification.hostedFlowUrl;
      }
    },
  });

  const schema = Yup.object().shape({
    agreement: Yup.boolean().oneOf([true], "You must agree to continue"),
  });

  return (
    <Formik
      initialValues={{ agreement: false }}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({
        errors,
        values,
        validateForm,
        handleSubmit,
        handleChange,
      }: FormikProps<IdVerificationFormType>) => (
        <Form onSubmit={handleSubmit}>
          <Card
            className={styles.card}
            title="Secure identity verification"
            description="Justworks partners with Persona for secure identity verification. Please have a form of identification ready, and be prepared to take a gorgeous selfie."
          >
            <div className={styles.hideFormFieldLabel}>
              <FormField error={errors.agreement} aria-labelledby="_" required>
                <div className={styles.checkboxWrapper}>
                  <CheckboxInput
                    name="agreement"
                    onChange={handleChange}
                    value={values.agreement ? 1 : 0}
                    required
                  />
                  <p>I agree to Persona's {personaTermsOfUseLink}.</p>
                </div>
              </FormField>
            </div>

            <div className={styles.buttonGroup}>
              <Button
                color="brand"
                variant="filled"
                onClick={() => {
                  setLaunching(true);

                  validateForm(values).then((errors) => {
                    if (Object.keys(errors).length === 0) {
                      launchEoRIdVerification();
                    } else {
                      setLaunching(false);
                    }
                  });
                }}
                loading={launching}
              >
                Launch Persona
              </Button>
            </div>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default LaunchIdVerification;
