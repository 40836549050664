import React from "react";
import { SystemIcon } from "@justworkshr/milo-icons";
import { RETURNED_STATUS } from "pages/expenses/constants/request-status";
import { formatDate } from "pages/expenses/utils";
import { RequestsListRequest } from "types/Expenses";
import {
  renderPayDate,
  renderReviewStatus,
} from "./RequestsListTableRow.utils";
import styles from "../../RequestsListPage.module.css";
import { Link } from "react-router-dom";

const { RequestsListTableRowRequestNameWrapper } = styles;

interface RequestsListTableRowProps {
  request: RequestsListRequest;
  hasManagerApprover: boolean;
}

export const RequestsListTableRow: React.FC<RequestsListTableRowProps> = ({
  request,
  hasManagerApprover,
}) => {
  const hasMultipleExpenses = request.expenses.length > 1;

  return (
    <tr>
      <td>
        <div className={RequestsListTableRowRequestNameWrapper}>
          {request.status === RETURNED_STATUS && (
            <SystemIcon iconName="warning" size="small" color="warning" />
          )}
          <Link
            to={`/expenses/${request.uuid}`}
            data-testid="expense-request-link"
          >
            {request.name}
          </Link>
          <span>{hasMultipleExpenses && ` (${request.expenses.length})`}</span>
        </div>
      </td>
      <td>{formatDate(request.submittedAt)}</td>
      <td data-testid="expense-request-status">
        {renderReviewStatus({ request, hasManagerApprover })}
      </td>
      <td>{renderPayDate(request.payment)}</td>
    </tr>
  );
};
