import React from "react";
import { ExpenseDialog } from "../ExpenseDialog";
import { Button } from "@justworkshr/milo-core";
import { useNavigate } from "react-router-dom";
import { prependBasePath } from "pages/expenses/constants";

interface CancelRequestDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
}

export const CancelRequestDialog: React.FC<CancelRequestDialogProps> = ({
  isOpen,
  onClose,
  onCancel,
}) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    onCancel();

    navigate(prependBasePath("HOME"));
  };

  return (
    <ExpenseDialog
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        <Button
          type="button"
          variant="ghost"
          onClick={onClose}
          key="keep-request-cancel-request"
        >
          Keep request
        </Button>,
        <Button
          type="button"
          variant="filled"
          key="cancel-request"
          onClick={handleCancel}
        >
          Cancel request
        </Button>,
      ]}
    >
      <h2>Cancel this request?</h2>
      <p>
        Are you sure you want to cancel this reimbursement request? Any expenses
        you've added won't be saved.
      </p>
    </ExpenseDialog>
  );
};
