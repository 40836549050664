import styles from "./ReimbursementRequestPage.module.css";
import {
  RequestName,
  NewExpenseButtons,
  ReimbursementRequestPageHeader,
} from "./components";
import { useRequestDetail } from "pages/expenses/store";
import { ExpenseDetailsCards } from "../RequestDetailPage/components";
import { ReturnReasons, ReimbursementSummary } from "pages/expenses/components";
import {
  ROUTES,
  RETURNED_STATUS,
  prependBasePath,
} from "pages/expenses/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useBeforeUnload } from "pages/expenses/hooks";

const {
  ReimbursementRequestPageColumn,
  ReimbursementRequestPageWrapper,
  ReimbursementRequestPageContainer,
  ReimbursementRequestPageSectionTitle,
} = styles;

export const ReimbursementRequestPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    setRequestDetail,
    requestDetail: {
      request: { expenses, uuid, status },
      metadata: { isRequestUpdated },
    },
  } = useRequestDetail();

  const isRequestEditView = location.pathname.endsWith(ROUTES.EDIT_REQUEST);

  useBeforeUnload(isRequestUpdated);

  const pageTitle =
    isRequestEditView && uuid
      ? "Reimbursement request"
      : "Create a reimbursement request";

  const isRequestReturned = status === RETURNED_STATUS;

  useEffect(() => {
    setRequestDetail({ metadata: { isRequestEditView } });
  }, [isRequestEditView, setRequestDetail]);

  useEffect(() => {
    if (isRequestEditView && !uuid && expenses.length === 0) {
      navigate(prependBasePath("HOME"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, navigate, isRequestEditView]);

  return (
    <div className={ReimbursementRequestPageWrapper}>
      <ReimbursementRequestPageHeader />
      <div className={ReimbursementRequestPageContainer}>
        <div className={ReimbursementRequestPageColumn}>
          <h2>{pageTitle}</h2>
          <RequestName />
          <p className={ReimbursementRequestPageSectionTitle}>Expenses</p>
          <NewExpenseButtons />
          <ExpenseDetailsCards expenses={expenses} />
          {isRequestReturned && isRequestEditView && <ReturnReasons />}
        </div>
        <div className={ReimbursementRequestPageColumn}>
          <ReimbursementSummary isCreateFlow={!isRequestEditView} />
        </div>
      </div>
    </div>
  );
};

export default ReimbursementRequestPage;
