import { ReactElement } from "react";
import styles from "./submitted-page.module.css";
import { DisplayIcon, SystemIcon } from "@justworkshr/milo-icons";
import { Link } from "react-router-dom";
import FormContinue from "../../shared/form-continue";
import { Button } from "@justworkshr/milo-core";

const {
  pageWrapper,
  firstColumn,
  secondColumn,
  firstRow,
  secondRow,
  centralized,
  leftJustified,
  hrBar,
  element,
  titleAlign,
  child,
  parent,
  icon,
  backgroundBlur,
  ellipseDiv,
  internTitle,
  actionClose,
  footerActions,
  textArea,
  contentWrapper,
} = styles;
export default function submittedPage({
  onClick,
  memberName,
}: {
  onClick: () => void;
  memberName: string;
}): ReactElement {
  return (
    <main>
      <div className={backgroundBlur} />
      <div className={pageWrapper} data-testid="submitted-page">
        <div className={`${firstRow} ${centralized}`}>
          <div className="checkIcon">
            <DisplayIcon color="green" iconName="check-mark" size="small" />
          </div>
          <div className={titleAlign}>
            <h1>Claim submitted for {memberName}</h1>
          </div>
          <div className={actionClose}>
            <Button
              variant="ghost"
              type="button"
              size="sm"
              rightIcon={"close"}
              color="neutral"
              onClick={onClick}
              key="close-submitted-page"
            >
              Close
            </Button>
          </div>
        </div>
        <div className={secondRow} data-testid="submitted-page-content">
          <div className={contentWrapper}>
            <div className={firstColumn}>
              <h2 className={`${leftJustified} ${internTitle}`}>
                What&apos;s next for your claim and you&#58;
              </h2>
              <div className={`${hrBar}`} />
              <div className={element}>
                <div className={ellipseDiv}>
                  <SystemIcon
                    iconName="arrow-right"
                    size="extra-small"
                    color="inverse"
                  />
                </div>
                <div className={textArea}>
                  <h3>
                    An Optum health card has been emailed to your employee
                  </h3>
                  <p>
                    This card is to be used for the initial treatment and first
                    prescription fills in response to this incident. Your
                    employee cannot use their standard health insurance card at
                    this time, as applicable care in response to this incident
                    will be handled uniquely by Optum at first.
                  </p>
                </div>
              </div>
              <div className={element}>
                <div className={ellipseDiv}>
                  <SystemIcon
                    iconName="arrow-right"
                    size="extra-small"
                    color="inverse"
                  />
                </div>
                <div className={textArea}>
                  <h3>
                    You will receive an email confirmation of this claim with
                    guidance on how to assist your employee
                  </h3>
                  <p>
                    Your employee can get medical care in this time. Provide us
                    with more information or updates on their healthcare needs
                    through customer support.
                  </p>
                </div>
              </div>
              <div className={element}>
                <div className={ellipseDiv}>
                  <SystemIcon
                    iconName="arrow-right"
                    size="extra-small"
                    color="inverse"
                  />
                </div>
                <div className={textArea}>
                  <h3>
                    The claim is being set up with the insurance carrier, and
                    additional information will be sent to your employee when
                    ready.
                  </h3>
                  <p>
                    The insurance carrier will communicate directly with your
                    employee once they have processed this claim.
                  </p>
                </div>
              </div>
            </div>
            <div className={secondColumn}>
              <h2 className={`${centralized} ${internTitle}`}>
                More assistance
              </h2>
              <div className={parent}>
                <div className={child}>
                  <div className={icon}>
                    <DisplayIcon
                      color="yellow"
                      iconName="papers"
                      size="medium"
                    />
                  </div>
                  <div>
                    <Link to="/workers-compensation-claims/about">
                      <h3>Guide to claims</h3>
                    </Link>
                  </div>
                </div>

                <div className={child}>
                  <div className={icon}>
                    <DisplayIcon
                      iconName="hand-waving"
                      color="blue"
                      size="medium"
                    />
                  </div>
                  <div>
                    <Link to="/company-settings">
                      <h3>Contact support</h3>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={footerActions}>
            <FormContinue
              continueKey="continue-submitted-page"
              continueFn={onClick}
              textContinue="Close"
              alignment="end"
            />
          </div>
        </div>
      </div>
    </main>
  );
}
