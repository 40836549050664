import ImageCard from "pages/pmt/integrations/partner-setup-page/components/ImageCard/ImageCard";
import { Button } from "@justworkshr/milo-core";
import styles from "./LeverSetup.module.css";
import { StepperHelpers } from "pages/pmt/integrations/partner-setup-page/hooks/useStepper";
import { useEffect, useState } from "react";
import { StatusMessage } from "./utils";
import useProcessIntegrationAction, {
  ActionData,
} from "../../../partner-setup-page/hooks/useProcessPmtIntegrationAction";
import { DisplayIcon } from "@justworkshr/milo-icons";

const { wrapper, bold, cardText } = styles;

type LeverSetupStepTwoProps = StepperHelpers & {
  processPmtIntegrationAction: (
    integrationName: string,
    action: string
  ) => Promise<
    | {
        success: boolean;
        actionData: ActionData | null;
      }
    | undefined
  >;
  oAuthSuccess?: boolean;
};

export const LeverSetupStepTwoRenderer: React.FC<LeverSetupStepTwoProps> = ({
  processPmtIntegrationAction,
  oAuthSuccess,
  ...stepperHelpers
}) => {
  const { goToNextStep } = stepperHelpers;
  const [integrationAuthSuccess, setIntegrationAuthSuccess] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [loadingOauth, setLoadingOauth] = useState(false);

  useEffect(() => {
    if (oAuthSuccess) {
      setIntegrationAuthSuccess(true);
      setShowMessage(true);
      const moveToNextStepTimer = setTimeout(() => {
        goToNextStep();
      }, 1000);

      return () => {
        clearTimeout(moveToNextStepTimer);
      };
    }
  }, [goToNextStep, oAuthSuccess]);

  const handleConnectToIntegration = async () => {
    let oAuthUrl = "";
    setLoadingOauth(true);
    processPmtIntegrationAction("lever", "auth_init").then(
      (processActionResponse) => {
        if (processActionResponse?.success) {
          if (processActionResponse.actionData) {
            oAuthUrl = processActionResponse.actionData?.url;
            setShowMessage(false);
            window.location.assign(oAuthUrl);
          } else {
            setShowMessage(true);
            setLoadingOauth(false);
          }
        } else {
          setShowMessage(true);
          setLoadingOauth(false);
        }
      }
    );
  };

  return (
    <ImageCard
      header="Connect your Lever account"
      image={<DisplayIcon iconName="shield" color="redPurple" size="large" />}
    >
      <div className={wrapper}>
        <span className={cardText}>
          After you <span className={bold}>Connect to Lever</span> and enter
          your login information, Justworks and Lever will be allowed to share
          data.
        </span>
        {!integrationAuthSuccess && (
          <Button
            onClick={handleConnectToIntegration}
            loading={loadingOauth}
            disabled={loadingOauth}
          >
            Connect to Lever
          </Button>
        )}
        {showMessage && (
          <StatusMessage
            integrationSetupSuccess={integrationAuthSuccess}
            currentStep={2}
          />
        )}
      </div>
    </ImageCard>
  );
};

const LeverSetupStepTwo: React.FC<StepperHelpers> = (
  stepperHelpers: StepperHelpers
) => {
  const { processPmtIntegrationAction } = useProcessIntegrationAction();
  return (
    <LeverSetupStepTwoRenderer
      processPmtIntegrationAction={processPmtIntegrationAction}
      {...stepperHelpers}
    />
  );
};

export default LeverSetupStepTwo;
