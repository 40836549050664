import React from "react";
import { AuditLogItem } from "../AuditLogItem";
import { getDollarsFromCents } from "pages/expenses/utils";
import {
  getExpenseText,
  renderAuditLogExpense,
} from "../../AuditLogPage.utils";
import { AuditLogPaymentSubmittedType } from "types/Expenses";

interface SubmittedProps {
  name: string;
  numAdmins: number;
  occurredAt: string;
  memberName: string;
  totalAmount: number;
  nextMemberName: string;
  isNextMemberAnAdmin: boolean;
  isNextMemberAManager: boolean;
  expenses: AuditLogPaymentSubmittedType["expenses"];
}

export const Submitted: React.FC<SubmittedProps> = ({
  name,
  expenses,
  numAdmins,
  occurredAt,
  memberName,
  totalAmount,
  nextMemberName,
  isNextMemberAnAdmin,
  isNextMemberAManager,
}) => {
  const renderPreviewText = () => {
    if (isNextMemberAManager) {
      return `Submitted to manager for review`;
    } else if (isNextMemberAnAdmin && numAdmins > 1) {
      return "Submitted to admins for review";
    }

    return "Submitted to admin for review";
  };

  const renderDetailText = () => {
    const numExpenses = expenses.length;

    if (isNextMemberAnAdmin && numAdmins > 1) {
      return (
        <p>
          {getDollarsFromCents(totalAmount)} total reimbursement for {name} with{" "}
          {getExpenseText(numExpenses)} was submitted to admins.
        </p>
      );
    }

    return (
      <p>
        {getDollarsFromCents(totalAmount)} total reimbursement for {name} with{" "}
        {getExpenseText(numExpenses)} was submitted to {nextMemberName}.
      </p>
    );
  };

  return (
    <AuditLogItem
      author={memberName}
      occurredAt={occurredAt}
      previewText={renderPreviewText()}
    >
      {renderDetailText()}
      {expenses.map((expense) => (
        <React.Fragment key={expense.uuid}>
          {renderAuditLogExpense(expense)}
        </React.Fragment>
      ))}
    </AuditLogItem>
  );
};

export default Submitted;
