import benefitsClasses from "pages/benefits/benefits.module.css";
import { UnorderedList, UnorderedListItem } from "@justworkshr/milo-core";
import FaqItem from "../FaqItem";

const FaqTab = () => {
  return (
    <div>
      <FaqItem title="Why should we use the Justworks retirement plan instead of a separate, single employer plan?">
        <>
          <p className={benefitsClasses.benefitsParagraph}>
            Together with Empower, we handle a range of administrative tasks so
            you can focus on your business, including:
          </p>

          <UnorderedList>
            <UnorderedListItem>
              Transmitting contributions and participant data consistent with
              Department of Labor requirements.
            </UnorderedListItem>
            <UnorderedListItem>
              Performing nondiscrimination testing and completing required
              government filings, including Form 5500.
            </UnorderedListItem>
            <UnorderedListItem>
              Managing the annual independent audit, potentially saving you both
              time and money.
            </UnorderedListItem>
            <UnorderedListItem>
              Notifying employees of their eligibility, key plan amendments, and
              other required notices.
            </UnorderedListItem>
          </UnorderedList>
        </>
      </FaqItem>

      <FaqItem
        title="Am I required to contribute to my employees’ 401(k) accounts?"
        collapsed
      >
        <p className={benefitsClasses.benefitsParagraph}>
          It all depends on your plan set up; some plans require your
          contribution, others don’t.
        </p>
      </FaqItem>

      <FaqItem
        title="What types of investments are available to employees?"
        collapsed
      >
        <>
          <p className={benefitsClasses.benefitsParagraph}>
            We offer a wide range of professionally managed investments,
            including:
          </p>
          <UnorderedList>
            <UnorderedListItem>
              {
                (
                  <>
                    <strong>Target retirement solutions</strong>
                    <br />
                    <p className={benefitsClasses.benefitsParagraph}>
                      Pre-mixed portfolios with diversified asset allocations
                      that automatically adjust over time as a fund's target
                      retirement date grows nearer.
                    </p>
                  </>
                ) as unknown as string
              }
            </UnorderedListItem>
            <UnorderedListItem>
              {
                (
                  <>
                    <strong>Index funds</strong>
                    <br />
                    <p className={benefitsClasses.benefitsParagraph}>
                      Funds that seek to replicate the performance of a specific
                      investment index, like the S&P 500 Index.
                    </p>
                  </>
                ) as unknown as string
              }
            </UnorderedListItem>
            <UnorderedListItem>
              {
                (
                  <>
                    <strong>Actively managed funds</strong>
                    <br />
                    <p className={benefitsClasses.benefitsParagraph}>
                      A range of options, including investments in value, blend,
                      growth, international, cash-alternative, and core
                      fixed-income classes.
                    </p>
                  </>
                ) as unknown as string
              }
            </UnorderedListItem>
          </UnorderedList>

          <p className={benefitsClasses.benefitsParagraph}>
            Empower will also continue to offer a brokerage window for companies
            that want to offer additional options. To learn more, select{" "}
            <strong>Get started</strong> to connect with Empower.
          </p>
        </>
      </FaqItem>

      <FaqItem
        title="Does the plan allow for Roth after-tax or after-tax deferrals?"
        collapsed
      >
        <p className={benefitsClasses.benefitsParagraph}>
          Roth contributions are allowed by the plan, but after-tax salary
          deferrals are not permitted, which means the Mega Backdoor Roth option
          is not available. While the qualified status of earnings applies to
          Roth contributions, they are considered post-tax-deferred salary. The
          primary difference between a Roth and a pretax contribution is the
          treatment of earnings upon distribution at retirement. Roth
          contributions withdrawn at retirement are tax-free income, provided
          the participant is age 59&frac12; or older and has held their account
          for at least five years. Pretax distributions from a pretax account
          are taxed as ordinary income.
        </p>
      </FaqItem>

      <FaqItem
        title="Can K-1 income earners contribute to the Justworks Retirement Savings Plan?"
        collapsed
      >
        <p className={benefitsClasses.benefitsParagraph}>
          The Justworks Retirement Savings Plan recognizes K-1 income for
          partners and members within the plan’s definition of eligible
          compensation. If K-1 income is paid through the Justworks payroll
          system, K-1 employees and W-2 employees can enroll in the plan. K-1
          income needs to be paid through the Justworks payroll system in order
          for a K-1 employee to contribute to the plan. If K-1 income is not set
          up through Justworks payroll, please contact Justworks directly for
          assistance.
        </p>
      </FaqItem>

      <FaqItem
        title="How can an employee transfer outside accounts into the Justworks Retirement Savings Plan?"
        collapsed
      >
        <>
          <p className={benefitsClasses.benefitsParagraph}>
            An employee can either:
          </p>

          <UnorderedList>
            <UnorderedListItem>
              {
                (
                  <>
                    Log on to{" "}
                    <a
                      href="https://www.empower.com/justworks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      empower.com/justworks
                    </a>
                    , select the Change tab, then the Change option and then
                    follow the prompts, or,
                  </>
                ) as unknown as string
              }
            </UnorderedListItem>
            <UnorderedListItem>
              {
                (
                  <>
                    Contact an Empower Customer Care Center representative at{" "}
                    <a href="tel:8555954015" style={{ whiteSpace: "nowrap" }}>
                      855-JWK-401k
                    </a>{" "}
                    (
                    <a href="tel:8555954015" style={{ whiteSpace: "nowrap" }}>
                      855-595-4015
                    </a>
                    )
                  </>
                ) as unknown as string
              }
            </UnorderedListItem>
          </UnorderedList>
        </>
      </FaqItem>

      <FaqItem
        title="When I hire a new employee, when, and how are they able to sign up?"
        collapsed
      >
        <p className={benefitsClasses.benefitsParagraph}>
          Each 401(k) plan has their own requirements when it comes to
          eligibility. Reach out to{" "}
          <a href="mailto:justworks401kconsult@empower.com">
            justworks401kconsult@empower.com
          </a>{" "}
          for more information on how new employees can sign up.
        </p>
      </FaqItem>

      <FaqItem
        title="How will my employees be affected by transferring my existing 401(k) to Justworks & Empower?"
        collapsed
      >
        <>
          <p className={benefitsClasses.benefitsParagraph}>
            While Empower tries to make the plan merger process as easy as
            possible, there are a few things to keep in mind during transfer of
            funds.
          </p>

          <p className={benefitsClasses.benefitsParagraph}>
            First, there will be a brief blackout period (about 2 weeks) during
            the plan merger process where employees will not have access to
            their assets, take a distribution, or take a loan against their
            assets. Empower will tell employees when this blackout period will
            begin and end. The full transfer period, including this blackout
            period, can take 60-90 days.
          </p>

          <p className={benefitsClasses.benefitsParagraph}>
            You should also encourage your employees to check their 401(k)
            balance from the previous provider to make sure they don’t over
            contribute for the plan year when setting up their new account and
            contributions.
          </p>
        </>
      </FaqItem>

      <FaqItem
        title="What if I want to keep my existing 401(k) provider?"
        collapsed
      >
        <p className={benefitsClasses.benefitsParagraph}>
          If you choose to keep your 401(k) provider, you can use our manual
          deduction function to create your employee payroll deductions. Funds
          deducted from your employee’s pay by manual deductions will reflect in
          the employee’s paycheck. The actual funds will remain in your
          company’s bank account for you to transfer to your 401(k) provider.
          You can also generate reports that your 401(k) provider will need,
          such as an employee census or retirement report that’s used for
          distributing funds. Read more about{" "}
          <a
            href="https://help.justworks.com/hc/en-us/articles/360004528751-Retirement-Report"
            target="_blank"
            rel="noopener noreferrer"
          >
            retirement reports
          </a>{" "}
          in our Help Center.
        </p>
      </FaqItem>

      <FaqItem title="Can I change my plan after it is set up?" last collapsed>
        <p className={benefitsClasses.benefitsParagraph}>
          Yes, after the plan has been set up, you can amend a plan provision or
          add a new feature by contacting Empower at{" "}
          <a href="tel:8555954015" style={{ whiteSpace: "nowrap" }}>
            855-JWK-401k
          </a>{" "}
          (
          <a href="tel:8555954015" style={{ whiteSpace: "nowrap" }}>
            855-595-4015
          </a>
          ) or{" "}
          <a href="mailto:justworks401kconsult@empower.com">
            justworks401kconsult@empower.com
          </a>
          .
        </p>
      </FaqItem>
    </div>
  );
};

export default FaqTab;
