import { ConnectionStatusBadgeProps } from "pages/pmt/integrations/integration-marketplace/components/ConnectionStatusBadge/connection-status-badge";
import { PmtIntegrationInstancesByKey } from "types/pmt-integrations";
import { getInstanceSuccessMap } from "../configs/lever/setup-steps/utils";

export function parseConnectionStatusFromString(
  input: string | undefined
): ConnectionStatusBadgeProps["connectionStatus"] {
  const validConnectionStatuses = [
    "CONNECTED",
    "DISCONNECTED",
    "PENDING",
    "ERROR",
  ];

  if (input && !validConnectionStatuses.includes(input)) {
    return input as "ERROR";
  }

  return input as ConnectionStatusBadgeProps["connectionStatus"];
}

export const pdpPermissionText = (
  adminPermissions: string[],
  firstAdminName: string
) => {
  const formatter = new Intl.ListFormat("en", {
    style: "long",
    type: "conjunction",
  });
  const formattedAdminPermissions = formatter.format(adminPermissions);
  return (
    <span>
      Only Justworks admins with<strong> {formattedAdminPermissions} </strong>
      permissions can set up this integration. Contact
      <strong> {firstAdminName} </strong> to request access.
    </span>
  );
};

export const findAuthError = (
  integrationInstances: PmtIntegrationInstancesByKey[] | undefined | null
) => {
  const instanceSuccessMap = getInstanceSuccessMap(integrationInstances);
  return !instanceSuccessMap?.refresh_token || !instanceSuccessMap?.get_token;
};

export const findExpiringIntegration = (
  integrationInstances: PmtIntegrationInstancesByKey[] | undefined | null
) => {
  const instanceSuccessMap = getInstanceSuccessMap(integrationInstances);
  return instanceSuccessMap?.warn_unused && !instanceSuccessMap?.keep_alive;
};

export const findAutoDisabledIntegration = (
  integrationInstances: PmtIntegrationInstancesByKey[] | undefined | null
) => {
  const instanceSuccessMap = getInstanceSuccessMap(integrationInstances);
  return instanceSuccessMap?.disable_unused;
};
