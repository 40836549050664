import React from "react";
import { formatDate } from "pages/expenses/utils";
import { RequestDetailReview } from "types/Expenses";
import styles from "./ReturnReasonInfo.module.css";

const {
  ReturnReasonInfoDate,
  ReturnReasonInfoAdmin,
  ReturnReasonInfoWrapper,
  ReturnReasonInfoReturnReason,
} = styles;

interface ReturnReasonInfoProps {
  review: RequestDetailReview;
}

export const ReturnReasonInfo = React.memo(
  ({ review }: ReturnReasonInfoProps) => {
    return (
      <div className={ReturnReasonInfoWrapper}>
        <div>
          <p className={ReturnReasonInfoDate}>
            {formatDate(review.reviewedAt)}
          </p>
          <p>Returned</p>
          <p
            className={ReturnReasonInfoAdmin}
          >{`By ${review.member.friendlyFullName}`}</p>
        </div>
        <div className={ReturnReasonInfoReturnReason}>
          <span>{`\u2022`} Reason:</span> {review.reason}
        </div>
      </div>
    );
  }
);
