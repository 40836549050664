import { GetUserInfoQueryCompanyMember } from "types";

function countMatches(terms: string[], attr: string) {
  let numMatches = 0;
  terms.forEach((term) => {
    if (attr.startsWith(term)) {
      numMatches++;
    }
  });
  return numMatches;
}

function memberSearch(
  members: GetUserInfoQueryCompanyMember[],
  term: string
): GetUserInfoQueryCompanyMember[] {
  if (!term.replace(/\s/g, "").length) return members;
  const terms = term.toLowerCase().split(" ");
  const nonEmptyTerms = terms.filter((term: string) => term.length > 0);

  const results = members
    .filter((member) => {
      const attributes = [
        member.firstName.toLowerCase().split(" "),
        member.lastName.toLowerCase().split(" "),
      ].flat();

      let matchCount = 0;
      attributes.forEach((attr) => {
        matchCount += countMatches(nonEmptyTerms, attr);
      });
      return matchCount >= nonEmptyTerms.length;
    })
    .sort(function (a, b) {
      const textA = `${a.firstName.toUpperCase()} ${a.lastName.toUpperCase()}`;
      const textB = `${b.firstName.toUpperCase()} ${b.lastName.toUpperCase()}`;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

  return results;
}

export { memberSearch };
