/* eslint-disable @typescript-eslint/no-explicit-any */
import { initialPageData } from "../constants";
import {
  CreateClaimReportMutationVariables,
  GetDraftsDataQuery,
} from "types/generated/operations";
import { Address, FormIncident, PageData } from "../../types";

type IDraftQueryData =
  GetDraftsDataQuery["draftClaimReportsByAdmin"][0]["data"];

const formatDateToApi = (date: string) => {
  if (date === "_ _ /_ _ /_ _ _ _ " || date === "") return null;

  const [month, day, year] = date.split("/");

  return `${year}-${month}-${day}`;
};

const formatDateToInputDate = (date: string) => {
  const [month, day, year] = new Date(date).toLocaleDateString().split("/");

  return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${year}`;
};

const formatWitnessToApi = ({ name, contact, notes }: any) => {
  return {
    contactInformation: contact,
    description: notes,
    name,
  };
};

const formatWitnessToState = ({
  contactInformation,
  description,
  name,
}: any) => {
  return {
    contact: contactInformation,
    notes: description,
    name,
  };
};

const selectPageFromData = (data: IDraftQueryData) => {
  if (!data) return 1;

  const { claim, claimant, witnesses, workStatus } = data;

  if (
    workStatus &&
    workStatus.endTimeOut &&
    workStatus.startTimeOut &&
    workStatus.paid !== null
  ) {
    return 7;
  }

  if (
    workStatus &&
    !!(
      workStatus.endTimeOut ||
      workStatus.startTimeOut ||
      workStatus.paid !== null
    )
  ) {
    return 6;
  }

  if (witnesses && witnesses?.length > 0) {
    return 6;
  }

  if (claim?.treatmentGiven && claim?.treatmentGiven.length > 0) {
    return 5;
  }

  // All of the required fields are filled out
  if (
    claim?.incidentDescription &&
    claim?.accidentLocation?.street1 &&
    claim?.accidentLocation?.city &&
    claim?.accidentLocation?.state &&
    claim?.accidentLocation?.postalCode &&
    claim?.affectedBodyPart &&
    claim?.injuryDescription &&
    claim?.safeguardsUsed
  ) {
    return 4;
  }

  if (claimant?.personalEmail) {
    return 3;
  }

  if (claim?.memberName) {
    return 2;
  }

  return 1;
};

export const formatApiToDraftState = (data: IDraftQueryData) => {
  if (data === null || data === undefined) {
    return { formData: initialPageData, page: 1 };
  }

  return {
    formData: formatApiToState(data),
    page: selectPageFromData(data),
  };
};

export const formatApiToSubmittedState = (api: any) => {
  return {
    data: formatApiToState(api.data),
    date: api.date,
    pdf: api.pdf,
  };
};

const formatApiToState = (data: IDraftQueryData) => {
  if (!data) return initialPageData;

  const { claim, claimant, witnesses, workStatus } = data;
  // First Page

  const accidentDate = new Date(claim?.estimatedAccidentDateTime || "");

  const injuryDate = {
    date: formatDateToInputDate(claim?.estimatedAccidentDateTime || ""),
    hour: accidentDate.getHours().toString().padStart(2, "0"),
    minute: accidentDate.getMinutes().toString().padStart(2, "0"),
    adminEmail: claim?.adminEmail || "",
    adminPhone: claim?.adminPhone || "",
    companyName: claim?.companyName || "",
    memberName: claim?.memberName || "",
    uuid: claim?.memberUuid || "",
    homeAddress: {
      city: claimant?.homeAddress?.city || "",
      state: claimant?.homeAddress?.state || "",
      street1: claimant?.homeAddress?.street1 || "",
      street2: claimant?.homeAddress?.street2 || "",
      postalCode: claimant?.homeAddress?.postalCode || "",
    },
    companyAddress: {
      city: claimant?.workAddress?.city || "",
      state: claimant?.workAddress?.state || "",
      street1: claimant?.workAddress?.street1 || "",
      street2: claimant?.workAddress?.street2 || "",
      postalCode: claimant?.workAddress?.postalCode || "",
    },
    contactPhone: claimant?.phoneNumber || "",
    adminName: claim?.companyName || "",
    title: "", // Look at again
    workEmail: "", // Look at again
  };

  // Second Page
  const personalInformation = claimant?.personalEmail
    ? {
        homeEmail: claimant?.personalEmail,
        siteCode: claim?.companyUuid || "",
        policyNumber: claim?.employerPolicyNumber || "",
      }
    : initialPageData.personalInformation;

  // Third Page
  const incidentInformation: FormIncident = {
    address: {
      city: claim?.accidentLocation?.city || "",
      postalCode: claim?.accidentLocation?.postalCode || "",
      state: claim?.accidentLocation?.state || "",
      street1: claim?.accidentLocation?.street1 || "",
      street2: claim?.accidentLocation?.street2 || "",
    },
    injuryBodyPart: claim?.affectedBodyPart || "",
    incidentDescription: claim?.incidentDescription || "",
    bodyPartDescription: claim?.injuryDescription || "",
    oshaNumber: claim?.oshaNumber || "",
    safeGuard: claim?.safeguardsUsed ? "Yes" : "No",
    safeGuardList: claim?.describedSafeguards || "",
    fatality: !!claim?.fatality,
  };

  // Fourth Page
  const incidentResponse = {
    wasContacted: !!claim?.emergencyResponseContacted,
    wasContactedDetails: claim?.emergencyResponseDetails || "",

    noMedicalTreatment:
      claim?.treatmentGiven?.includes("No Medical Treatment") || false,
    firstAid: claim?.treatmentGiven?.includes("On-Site First Aid") || false,
    medicalClinic:
      claim?.treatmentGiven?.includes("Medical Clinic Treatment") || false,
    EMSTransport: claim?.treatmentGiven?.includes("EMS Transport") || false,
    overnightHospitalization:
      claim?.treatmentGiven?.includes("Overnight Hospitalization") || false,
    other: claim?.treatmentGiven?.includes("Other") || false,

    treatmentDetails: claim?.clinicOrProviderName || "",
    phoneNumber: claim?.treatmentFacilityPhone || "",
    address: claim?.treatmentFacilityAddress as Address,
  };

  // Fifth Page
  const witnessInformation =
    witnesses && witnesses?.length > 0
      ? {
          witnessesExist: witnesses.length > 0 ? "Yes" : "No",
          witnessList: witnesses.map((witness: any) =>
            formatWitnessToState(witness)
          ),
        }
      : initialPageData.witnessInformation;

  // Sixth Page
  const workStatusData =
    workStatus?.startTimeOut || workStatus?.endTimeOut || workStatus?.paid
      ? {
          missTime:
            workStatus.startTimeOut !== "" && workStatus.endTimeOut !== ""
              ? "Yes"
              : "No",
          losingTime: workStatus.startTimeOut
            ? formatDateToInputDate(workStatus.startTimeOut)
            : "",
          returnToWorkDate: workStatus.endTimeOut
            ? formatDateToInputDate(workStatus.endTimeOut)
            : "",
          returnToWorkBoolean: !workStatus.endTimeOut,
          paid: workStatus.paid ? "Yes" : "No",
        }
      : initialPageData.workStatus;

  return {
    injuryDate,
    personalInformation,
    incidentInformation,
    incidentResponse,
    witnessInformation,
    workStatus: workStatusData,
  };
};

export const formatTreatmentGiven = ({
  noMedicalTreatment,
  firstAid,
  medicalClinic,
  EMSTransport,
  overnightHospitalization,
  other,
}: any) => {
  if (noMedicalTreatment) return "No Medical Treatment";

  const onSiteFirstAid = firstAid ? "On-Site First Aid, " : "";
  const medicalClinicTreatment = medicalClinic
    ? "Medical Clinic Treatment, "
    : "";
  const emsTransport = EMSTransport ? "EMS Transport, " : "";
  const overnightHospitalizationTreatment = overnightHospitalization
    ? "Overnight Hospitalization, "
    : "";
  const otherTreatment = other ? "Other" : "";

  return `${onSiteFirstAid}${medicalClinicTreatment}${emsTransport}${overnightHospitalizationTreatment}${otherTreatment}`;
};

export const formatStateToApi = (
  data: PageData,
  adminData: any
): CreateClaimReportMutationVariables["data"] => {
  const {
    injuryDate,
    personalInformation,
    incidentInformation,
    incidentResponse,
    witnessInformation,
    workStatus,
  } = data;

  const {
    adminUuid,
    companyUuid,
    adminName,
    adminEmail,
    adminPhone,
    companyId,
  } = adminData;

  let workStatusPaid = null;

  if (workStatus.paid === "Yes") {
    workStatusPaid = true;
  } else if (workStatus.paid === "No") {
    workStatusPaid = false;
  }

  return {
    claim: {
      accidentLocation: {
        city: incidentInformation?.address?.city,
        postalCode: incidentInformation?.address?.postalCode,
        state: incidentInformation?.address?.state,
        street1: incidentInformation?.address?.street1,
        street2: incidentInformation?.address?.street2,
      },
      adminName: adminName,
      adminEmail: adminEmail,
      adminPhone: adminPhone || injuryDate.adminPhone,
      adminUuid: adminUuid,
      affectedBodyPart: incidentInformation.injuryBodyPart,
      clinicOrProviderName: incidentResponse.treatmentDetails,
      companyName: injuryDate.companyName,
      companyUuid: companyUuid,
      emergencyResponseContacted: incidentResponse.wasContacted,
      emergencyResponseDetails: incidentResponse.wasContactedDetails,
      employerPolicyNumber: personalInformation.policyNumber,
      estimatedAccidentDateTime: new Date(
        `${injuryDate.date} ${injuryDate.hour}:${injuryDate.minute}`
      ).toISOString(),
      fatality: incidentInformation.fatality,
      incidentDescription: incidentInformation.incidentDescription,
      injuryDescription: incidentInformation.bodyPartDescription,
      memberName: injuryDate.memberName || "",
      memberId: injuryDate.id,
      memberUuid: injuryDate.uuid || "",
      oshaNumber: incidentInformation.oshaNumber,
      safeguardsUsed: incidentInformation.safeGuard === "Yes",
      describedSafeguards: incidentInformation.safeGuardList,
      treatmentFacilityAddress: {
        city: incidentResponse?.address?.city || "",
        postalCode: incidentResponse?.address?.postalCode || "",
        state: incidentResponse?.address?.state || "",
        street1: incidentResponse?.address?.street1 || "",
        street2: incidentResponse?.address?.street2 || "",
      },
      treatmentFacilityPhone: incidentResponse?.phoneNumber,
      treatmentGiven: formatTreatmentGiven(incidentResponse),
      companyId: companyId,
    },
    claimant: {
      homeAddress: {
        city: injuryDate.homeAddress?.city,
        postalCode: injuryDate.homeAddress?.postalCode,
        state: injuryDate.homeAddress?.state,
        street1: injuryDate.homeAddress?.street1,
        street2: injuryDate.homeAddress?.street2,
      },
      personalEmail: personalInformation.homeEmail,
      phoneNumber: injuryDate.contactPhone,
      workAddress: {
        city: injuryDate.companyAddress?.city,
        postalCode: injuryDate.companyAddress?.postalCode,
        state: injuryDate.companyAddress?.state,
        street1: injuryDate.companyAddress?.street1,
        street2: injuryDate.companyAddress?.street2,
      },
    },
    witnesses:
      witnessInformation.witnessesExist === "Yes"
        ? witnessInformation.witnessList.map((witness: any) =>
            formatWitnessToApi(witness)
          )
        : [],
    workStatus: {
      endTimeOut: formatDateToApi(workStatus.returnToWorkDate),
      startTimeOut: formatDateToApi(workStatus.losingTime),
      paid: workStatusPaid,
    },
  };
};
