import React, { useEffect, useState } from "react";

import { useCategoriesStore } from "pages/expenses/store";
import { DUPLICATE_CATEGORY_NAME_ERROR } from "../../CatagoriesPage.constants";
import {
  invalidStringInputValue,
  invalidWhitespaceInputValue,
} from "pages/expenses/utils";
import {
  ONE_HUNDRED_CHARACTER_LIMIT_AND_EMOJI_ERROR,
  WHITESPACE_ERROR,
} from "pages/expenses/constants";
import { Button } from "@justworkshr/milo-core";
import { FormField, TextInput } from "@justworkshr/milo-form";
import { ExpenseCategoryFieldSetting } from "types/generated/operations";
import { ExpenseDialog } from "pages/expenses/components";

interface CustomCategoryDialogProps {
  isCustomCategoryModalOpen: boolean;
  handleCustomCategoryModal: () => void;
}

export const CustomCategoryDialog: React.FC<CustomCategoryDialogProps> =
  React.memo(({ isCustomCategoryModalOpen, handleCustomCategoryModal }) => {
    const {
      categories: { activeCategory, categories },
      setCategories,
      setActiveCategory,
    } = useCategoriesStore();

    const isEditMode = activeCategory !== null;
    const [newCategoryName, setNewCategoryName] = useState("");
    const [dialogErrorMessage, setDialogErrorMessage] = useState("");

    useEffect(() => {
      if (isEditMode && activeCategory?.name) {
        setNewCategoryName(activeCategory.name);
      }
    }, [activeCategory, isEditMode]);

    const title = isEditMode
      ? "Update custom category"
      : "Enter your custom category";
    const submitButtonText = isEditMode ? "Update" : "Add";
    const isSubmitDisabled =
      dialogErrorMessage !== "" || newCategoryName.length === 0;

    const handleReset = () => {
      setNewCategoryName("");
      setActiveCategory({ activeCategory: null });
      setDialogErrorMessage("");
      handleCustomCategoryModal();
    };

    const handleClose = () => {
      handleReset();
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      let updatedCategories = [];

      if (isEditMode) {
        updatedCategories = [...categories].map((category) => {
          if (category.name === activeCategory.name) {
            return {
              ...category,
              name: newCategoryName,
            };
          }

          return category;
        });
      } else {
        updatedCategories = [
          ...categories,
          {
            uuid: "",
            name: newCategoryName,
            visible: true,
            custom: true,
            merchant: ExpenseCategoryFieldSetting.Hidden,
            description: ExpenseCategoryFieldSetting.Hidden,
            receipt: ExpenseCategoryFieldSetting.Required,
            attendees: ExpenseCategoryFieldSetting.Hidden,
            billableToClient: ExpenseCategoryFieldSetting.Hidden,
            expenseType: ExpenseCategoryFieldSetting.Hidden,
          },
        ];
      }

      setCategories({ categories: updatedCategories });
      handleReset();
    };

    const validateCustomCategoryName = (givenCategoryName: string) => {
      const doesCategoryExists = categories
        .map((item) => item.name.trim().toLowerCase())
        .includes(givenCategoryName.trim().toLowerCase());

      if (doesCategoryExists) {
        setDialogErrorMessage(DUPLICATE_CATEGORY_NAME_ERROR);
      } else if (invalidStringInputValue(givenCategoryName, 100)) {
        setDialogErrorMessage(ONE_HUNDRED_CHARACTER_LIMIT_AND_EMOJI_ERROR);
      } else if (invalidWhitespaceInputValue(givenCategoryName)) {
        setDialogErrorMessage(WHITESPACE_ERROR);
      } else {
        setDialogErrorMessage("");
      }
    };

    const handleChange = ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
      setNewCategoryName(value);
      validateCustomCategoryName(value);
    };

    if (!isCustomCategoryModalOpen) {
      return null;
    }

    return (
      <ExpenseDialog
        onClose={handleClose}
        isOpen={isCustomCategoryModalOpen}
        actions={[
          <Button
            type="button"
            variant="ghost"
            key="custom-category-cancel"
            onClick={handleClose}
            data-testid="cancel-custom-category-button"
          >
            Cancel
          </Button>,
          <Button
            type="button"
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
            key="custom-category-submit"
            data-testid="submit-custom-category-button"
          >
            {submitButtonText}
          </Button>,
        ]}
      >
        <h2>{title}</h2>
        <FormField required error={dialogErrorMessage} label="Name">
          <TextInput
            required
            name="Name"
            value={newCategoryName}
            onChange={handleChange}
            data-testid="custom-category-field"
          />
        </FormField>
      </ExpenseDialog>
    );
  });

export default CustomCategoryDialog;
