import { FC } from "react";
import { TextInput } from "@justworkshr/milo-form";
import { SystemIcon } from "@justworkshr/milo-icons";
import * as DatetimeModule from "react-datetime";
import { DatetimepickerProps } from "react-datetime";
import "./DateTime.css";
import styles from "./DatePicker.module.css";
import { formatDatePickerDate } from "./DatePicker.utils";

const Datetime: FC<DatetimepickerProps> = (() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let value = DatetimeModule as any;
  while ("default" in value) value = value.default;
  return value;
})();

const { DatePickerWrapper, DatePickerInputWrapper, DatePickerCalendarIcon } =
  styles;

interface DatePickerProps {
  id: string;
  name: string;
  value?: string | undefined;
  disabled?: boolean;
  placeholder?: string;
  isValidDate?: (currentDate: Date) => boolean;
  handleChange: (dateValue: string) => void;
  className?: string;
  "data-testid"?: string;
}

export const DatePicker: FC<DatePickerProps> = ({
  id,
  name,
  value,
  handleChange,
  placeholder,
  isValidDate,
  disabled = false,
  className,
  "data-testid": dataTestId,
}) => {
  return (
    <div
      id="TeamManagementDatePicker"
      className={`${DatePickerWrapper}${className && " " + className}`}
    >
      <Datetime
        data-testid={dataTestId}
        closeOnSelect
        value={value}
        timeFormat={false}
        dateFormat="MM/DD/YYYY"
        isValidDate={isValidDate}
        onChange={(date) => handleChange(formatDatePickerDate(date.toString()))}
        renderInput={(props, openCalendar) => (
          <div className={DatePickerInputWrapper}>
            <TextInput
              {...props}
              id={id}
              name={name}
              autoComplete="off"
              disabled={disabled}
              placeholder={placeholder}
            />
            <SystemIcon
              size="medium"
              iconName="calendar"
              className={DatePickerCalendarIcon}
              onClick={() => {
                if (!disabled) {
                  openCalendar();
                }
              }}
            />
          </div>
        )}
      />
    </div>
  );
};
