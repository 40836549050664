import styles from "./TabsContentTemplate.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";
import { PERMISSION_NAME_MAP } from "pages/pmt/integrations/integration-marketplace/constants";
import { PERMISSION } from "types/RequiredPermissions";
import Notice from "../Notice/Notice";

const { linkWithIcon, inlineLinkWithIcon } = styles;

export const inlineLinkWithIconFormatter = (
  textBefore: string,
  link: string,
  linkText: string,
  textAfter?: string
) => {
  return (
    <span className={inlineLinkWithIcon}>
      {textBefore}{" "}
      <a
        className={linkWithIcon}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {linkText}
        <SystemIcon iconName="external-link" color="brand" />
      </a>
      {textAfter}
    </span>
  );
};

function getPermissionDescriptions(permissions: PERMISSION[]) {
  return permissions.map((permission) => PERMISSION_NAME_MAP[permission]);
}

export const justworksPermissionsCopy = (permissions: PERMISSION[]) => {
  const adminPermissions = getPermissionDescriptions(permissions);
  const permissionList = adminPermissions.join(", ");

  return permissionList;
};

export const partnerPermissionsCopy = (
  partnerName: string,
  permissions: string[]
) => {
  const permissionSentence = permissions.join(", ");

  return (
    <div>
      {partnerName} users need <strong>{permissionSentence}</strong>{" "}
      permissions.
    </div>
  );
};

export const dataMappingsNoticeRenderer = (
  partnerName: string,
  helpCenterLink: string
) => {
  return (
    <Notice systemIcon="info-circle">
      <p>
        Read more about{" "}
        <a
          className="milo--link-secondary"
          href={helpCenterLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {partnerName}’s data mappings
        </a>{" "}
        in our Help Center article.
      </p>
    </Notice>
  );
};
