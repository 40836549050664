import { ReactElement, useContext } from "react";
import styles from "./PersonalInfoTab.module.css";
import infoCardStyles from "./InfoCard/InfoCard.module.css";
import { InfoCard } from "./InfoCard";
import { isEmpty } from "lodash";
import { InternationalContractorMemberContext } from "../../context/InternationalContractorMemberContext";

export default function PersonalInfoTab(): ReactElement {
  const { member } = useContext(InternationalContractorMemberContext);

  if (!member) {
    return <></>;
  }

  return (
    <section className={styles.PersonalInfoTab}>
      <div className={styles.infoCardRow}>
        <InfoCard title="General information">
          <dl>
            <dt>Full name</dt>
            <dd>
              {member.firstName} {member.lastName}
            </dd>

            <dt>Address</dt>
            <dd>{member.streetLine1}</dd>
            <dd>{member.streetLine2}</dd>
            <dd>
              {member.city}, {member.state} {member.postalCode}
            </dd>

            <dt>Services Provided</dt>
            <dd>{member.serviceProvided}</dd>

            <dt>Website</dt>
            {member.website && (
              <dd>
                <a href={member.website}>{member.website}</a>
              </dd>
            )}
          </dl>
        </InfoCard>
        <InfoCard title="Contact information">
          <dl>
            <dt>Phone</dt>
            {isEmpty(member.phoneNumber) && (
              <dd className={infoCardStyles.blank}>Not on file</dd>
            )}
            {member.phoneNumber && (
              <dd>
                <a href={`tel:${member.phoneNumber}`}>{member.phoneNumber}</a>
              </dd>
            )}

            <dt>Email</dt>
            {isEmpty(member.email) && (
              <dd className={infoCardStyles.blank}>Not on file</dd>
            )}
            <dd className={member.email ? undefined : styles.error}>
              {member.email ? (
                <a href={`mailto:${member.email}`}>{member.email} </a>
              ) : (
                "Missing "
              )}
            </dd>
          </dl>
        </InfoCard>
      </div>
    </section>
  );
}
