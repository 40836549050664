import React from "react";
import { DocumentInput } from "pages/team-management/components";
import DocumentList from "pages/team-management/components/DocumentInput/helper/DocumentList";

interface DocumentInputQuestionProps {
  questionId: string;
  value: string[];
  onChange: (event: React.ChangeEvent<HTMLInputElement> | number[]) => void;
}

export const DocumentInputQuestion = ({
  questionId,
  value,
  onChange,
}: DocumentInputQuestionProps) => {
  const documentList = DocumentList();

  return (
    <DocumentInput
      id={questionId}
      onChange={onChange}
      value={value}
      documentList={documentList}
    />
  );
};
