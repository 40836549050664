import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Button } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";

import { WAIVER_EDIT_URL } from "pages/workers-compensation/document-request/constants";
import { DisplayDataProps } from "../../types";

import styles from "./job-information-review.module.css";
import sharedStyles from "../waiver-review.module.css";

const {
  formBorder,
  jobInfoReview,
  header,
  editLink,
  headerText,
  formInfo,
  formInfoHeader,
} = styles;

const { editButtonStyle, removePadding } = sharedStyles;

export default function JobInformationReview({
  formData,
}: DisplayDataProps): ReactElement {
  const formatDate = (date: Date | string) => {
    if (!(date instanceof Date)) return date;

    return date.toLocaleDateString("en-US");
  };

  return (
    <div className={formBorder}>
      <div className="milo--grid">
        <div className={jobInfoReview} data-testid="jobInformationReview">
          <div className={header}>
            <h2 className={headerText}>Job information</h2>
            <div className={editLink}>
              <Button
                to={`${WAIVER_EDIT_URL}?currentStep=0`}
                as={Link}
                variant="ghost"
                className={removePadding}
              >
                <span className={editButtonStyle}>
                  <SystemIcon iconName="edit" color="brand" />
                  Edit
                </span>
              </Button>
            </div>
          </div>

          <div className={formInfo}>
            <h3 className={formInfoHeader}>Job Name</h3>
            <p>{formData.jobName}</p>
          </div>

          <div className={formInfo}>
            <h3 className={formInfoHeader}>Contract Number</h3>
            <p>{formData.jobNumber}</p>
          </div>

          <div className={formInfo}>
            <h3 className={formInfoHeader}>Start Date</h3>
            <p>{formatDate(formData.dateStart)}</p>
          </div>

          <div className={formInfo}>
            <h3 className={formInfoHeader}>End Date</h3>
            <p>{formatDate(formData.dateEnd)}</p>
          </div>

          <div className={formInfo}>
            <h3 className={formInfoHeader}>Job Duties</h3>
            <p>{formData.jobDuties}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
