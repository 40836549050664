import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { prependBasePath } from "pages/expenses/constants";
import { PermissionsError } from "pages/expenses/components";
import { useGlobalConfigurationStore } from "pages/expenses/store";

interface ActiveRequestsPagePermissionsWrapperProps {
  children: JSX.Element;
}

export const ActiveRequestsPagePermissionsWrapper: React.FC<
  ActiveRequestsPagePermissionsWrapperProps
> = ({ children }) => {
  const navigate = useNavigate();

  const {
    isExpensesEverEnabled,
    permissions: {
      isAdmin,
      isManager,
      isReporter,
      isAllowedToFirstTimeSetup,
      isAllowedToAccessReviewPage,
    },
  } = useGlobalConfigurationStore();

  useEffect(() => {
    if (isAllowedToFirstTimeSetup) {
      navigate(prependBasePath("SETTINGS"));
    } else if (isReporter && isExpensesEverEnabled && !isManager && !isAdmin) {
      navigate(prependBasePath("REQUEST_HISTORY"));
    }
  }, [
    isAdmin,
    navigate,
    isManager,
    isReporter,
    isExpensesEverEnabled,
    isAllowedToFirstTimeSetup,
  ]);

  if (isAllowedToAccessReviewPage) {
    return children;
  }

  return <PermissionsError />;
};
