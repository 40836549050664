import BankAccount from "../BankAccount/BankAccount";
import { useProfileContextData } from "../../contexts/ProfileInfoContext";
import { lazy, Suspense } from "react";
import styles from "../PersonalInfo/PersonalInfo.module.css";
const COPensionCard = lazy(() => import("./COPensionCard"));
export default function Finances() {
  const { profileInfo } = useProfileContextData();
  const workCountry = profileInfo?.eorEmployeeProfile?.employment?.workCountry;

  const renderCountryCards = () => {
    switch (workCountry) {
      case "CO":
        return <COPensionCard />;
      default:
        return undefined;
    }
  };

  const countryCards = renderCountryCards();
  return (
    <div className={styles.cardSection}>
      <BankAccount />
      {!!countryCards && <Suspense>{countryCards}</Suspense>}
    </div>
  );
}
