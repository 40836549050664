import styles from "./EmptyState.module.css";
import { ReactElement, useContext } from "react";
import { InternationalContractorMemberContext } from "../../../context/InternationalContractorMemberContext";
import { UploadButton } from "../UploadButton";
import { getMemberPreferredName } from "../../../utils";

interface EmptyStateProps {
  openUploadDialog: () => void;
}

export default function EmptyState({
  openUploadDialog,
}: EmptyStateProps): ReactElement {
  const { member } = useContext(InternationalContractorMemberContext);

  const memberName = getMemberPreferredName(member);

  return (
    <div className={styles.container}>
      <div className={styles.message}>
        <h3>No documents for {memberName}</h3>
        <div>
          Are there any documents you'd like to share with {memberName}?
        </div>
      </div>

      <UploadButton onClick={openUploadDialog} />
    </div>
  );
}
