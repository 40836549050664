export const INITIAL_FEEDBACK_DIALOG: FeedbackDialogStoreType = {
  title: null,
  open: false,
  message: null,
};

export type FeedbackDialogStoreType = {
  open: boolean;
  title: string | null;
  message: string | null;
};
