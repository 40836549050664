import styles from "./FormField.module.css";

import React, { useId } from "react";

type LayoutTypes = "vertical" | "horizontal";

const {
  hasError,
  fieldContainer,
  formField,
  inputContainer,
  labelContainer,
  optionalTag,
  layoutVertical,
  descriptionWrapper,
  errorMessageWrapper,
} = styles;

interface FormFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  labelContent?: React.ReactNode;
  children: React.ReactNode;
  description?: React.ReactNode;
  optional?: boolean;
  error?: React.ReactNode;
  layout?: LayoutTypes;
  className?: string;
}

function FormField({
  id,
  labelContent,
  children,
  description,
  optional,
  error,
  className,
  ...otherProps
}: FormFieldProps) {
  const classes = [formField, className, layoutVertical];
  if (error) {
    classes.push(hasError);
  }
  const labelId = useId();

  return (
    <div role="group" className={classes.join(" ")} {...otherProps}>
      {labelContent && (
        <div className={labelContainer}>
          <label htmlFor={id || labelId}>
            {labelContent}
            {optional && <span className={optionalTag}>Optional</span>}
          </label>
        </div>
      )}
      <div className={fieldContainer}>
        <div className={inputContainer}>
          {React.cloneElement(children as React.ReactElement, {
            id: id || labelId,
          })}
          {(() => {
            if (error) {
              const errorMessage = Array.isArray(error) ? error[0] : error;
              if (typeof errorMessage === "string") {
                return (
                  <p
                    className={errorMessageWrapper}
                    role="alert"
                    aria-live="assertive"
                  >
                    {errorMessage}
                  </p>
                );
              } else {
                return (
                  <div
                    className={errorMessageWrapper}
                    role="alert"
                    aria-live="assertive"
                  >
                    {errorMessage}
                  </div>
                );
              }
            }
          })()}
        </div>
        {(() => {
          if (description) {
            if (typeof description === "string") {
              return <p className={descriptionWrapper}>{description}</p>;
            } else {
              return <div className={descriptionWrapper}>{description}</div>;
            }
          }
        })()}
      </div>
    </div>
  );
}
export default FormField;
