import { DateParts } from "./types";

export function unwrapDate(date: string): DateParts {
  let dateParts = { month: "", day: "", year: "" };

  if (date) {
    const [year, month, day] = date.split("-").map(Number);
    const dateObj = new Date(year, month - 1, day);

    dateParts = {
      day: dateObj.getDate().toString(),
      month: (dateObj.getMonth() + 1).toString(),
      year: dateObj.getFullYear().toString(),
    };
  }
  return dateParts;
}
