import { ComponentProps, ReactElement } from "react";
import { Button } from "@justworkshr/milo-core";
import {
  SelectOption,
  RadioButton,
  RadioButtonGroup,
  TextInput,
  Select,
  ActionFooter,
  FormField,
} from "@justworkshr/milo-form";
import styles from "./invitation.module.css";
import { FormikProps } from "formik";
import { FormikFormField } from "lib/formik/FormikFormField/index";
import {
  invitationFormInitialValues,
  exemptionStatusCopyMap,
} from "./invitation-form-utils";
import FormRow from "../FormRow/FormRow";
import employeeFile from "../Employee.json";
import workerTypeFile from "../workerType.json";
import ptoTypeFile from "../ptoType.json";
import InvitationFormPtoPolicyOption from "./InvitationFormPtoPolicyOption";

const { form, formFooter, checkboxGroup } = styles;

export default function InvitationForm({
  handleSubmit,
  isValid,
  dirty,
  values,
}: FormikProps<typeof invitationFormInitialValues>): ReactElement {
  const employeeType = JSON.parse(JSON.stringify(employeeFile)) as {
    [key: string]: string;
  };

  const workerType = JSON.parse(JSON.stringify(workerTypeFile)) as [
    string,
    string
  ][];

  const ptoType = JSON.parse(JSON.stringify(ptoTypeFile)) as ComponentProps<
    typeof InvitationFormPtoPolicyOption
  >[];

  return (
    <div className={form}>
      <FormRow>
        <FormikFormField label="Member Type" name="memberType" required={true}>
          <Select required defaultValue="Employee - Full Time (W-2)">
            {Object.values(employeeType).map((employeeType) => (
              <SelectOption key={employeeType}>{employeeType}</SelectOption>
            ))}
          </Select>
        </FormikFormField>
        <p>
          A full time employee is someone who is employed by your company on a
          full-time basis.
        </p>
      </FormRow>

      <FormRow>
        <FormikFormField label="Salary Interval" name="salaryInterval" required>
          <RadioButtonGroup name="salaryInterval">
            <RadioButton value="annualSalary" label="Annual Salary" />
            <RadioButton value="hourlyRate" label="Hourly Rate" />
          </RadioButtonGroup>
        </FormikFormField>
      </FormRow>

      <FormRow>
        <FormikFormField
          label={
            values.salaryInterval == "annualSalary"
              ? "Annual Salary"
              : "Hourly Rate"
          }
          name="pay_rate"
          required
        >
          <TextInput
            name="pay_rate"
            placeholder={`e.g. $${
              values.salaryInterval == "annualSalary" ? "45,000" : "25"
            }.00`}
          />
        </FormikFormField>
      </FormRow>

      <FormRow>
        <FormikFormField name="jobType" label="Worker Type" required>
          <Select required defaultValue="non-manual">
            {Object.values(workerType).map(([displayString, value]) => (
              <SelectOption value={value}>{displayString}</SelectOption>
            ))}
          </Select>
        </FormikFormField>
        <p>
          Learn more about who is{" "}
          <a
            href="https://help.justworks.com/hc/en-us/articles/360004521771-Manual-Workers"
            target="_blank"
          >
            considered a manual worker.
          </a>
        </p>
      </FormRow>

      <FormRow>
        <FormikFormField
          label="Exemption Status"
          name="exemptionStatus"
          message={exemptionStatusCopyMap(values.exemptionStatus)}
          required
        >
          <RadioButtonGroup name="exemptionStatus">
            <RadioButton value="nonExempt" label="Non-exempt" />
            <RadioButton value="exempt" label="Exempt" />
          </RadioButtonGroup>
        </FormikFormField>
        <p>
          Learn more about{" "}
          <a
            href="https://help.justworks.com/hc/en-us/articles/360004521811-Exemption-and-Overtime-Rules-under-the-FLSA"
            target="_blank"
          >
            exemption status and the FLSA.
          </a>
        </p>
      </FormRow>

      <FormRow>
        <FormField name="ptoTypeGroup" label="PTO Policies">
          <div id="ptoTypeGroup" role="group" className={checkboxGroup}>
            {ptoType.map((pto_policy) => (
              <InvitationFormPtoPolicyOption {...pto_policy} />
            ))}
          </div>
        </FormField>
      </FormRow>

      <ActionFooter
        actions={
          <Button
            variant="filled"
            onClick={handleSubmit}
            disabled={!isValid || !dirty}
          >
            Submit
          </Button>
        }
        className={formFooter}
      />
    </div>
  );
}
