import { useGetApproversQuery } from "types/generated/operations";

export const useApprovers = () => {
  const { data: approversData, loading: isApproversLoading } =
    useGetApproversQuery();

  return {
    adminApprovers: isApproversLoading
      ? []
      : approversData?.expenseManagement?.expenseAdmins || [],
    managerApprovers: isApproversLoading
      ? []
      : approversData?.expenseManagement?.managers || [],
  };
};
