import React, { useEffect } from "react";
import styles from "./InstanceDetailsPanel.module.css";
import ConnectionStatusBadge, {
  ConnectionStatusBadgeProps,
} from "pages/pmt/integrations/integration-marketplace/components/ConnectionStatusBadge/connection-status-badge";
import SetupButton from "./SetupButton/SetupButton";
import DisconnectButton from "./DisconnectButton/DisconnectButton";
import {
  formatDate,
  getDaysRemainingUntilExpiration,
  supportLink,
} from "./utils";
import { AlertData } from "pages/pmt/integrations/hooks/useAlert";
import Notice from "../Notice/Notice";
import RefreshButton from "./RefreshButton/RefreshButton";
import { integrationConfigs } from "pages/pmt/integrations/configs/config";
import { IntegrationsConfig } from "types/IntegrationConfig";

const {
  instanceDetailsPanelContent,
  statusAndActivitySections,
  connectedHeaders,
  connectedButtonSection,
  integrationAlert,
  alertText,
} = styles;

export type ConnectedPanelContentProps = {
  config: IntegrationsConfig["copy"]["pdp"];
  solutionSlug: string;
  connectionStatus: ConnectionStatusBadgeProps["connectionStatus"];
  lastActivityDate: Date | null | undefined;
  setAlertData: (alertData: AlertData) => void;
  hasPermission: boolean;
  permissionNotice: JSX.Element;
  hasAuthError: boolean;
  integrationExpiring?: boolean;
};

// This component displays when the connection status is CONNECTED or PENDING
const ConnectedOrPendingPanelContent: React.FC<ConnectedPanelContentProps> = ({
  config,
  solutionSlug,
  lastActivityDate,
  connectionStatus,
  setAlertData,
  hasPermission,
  permissionNotice,
  hasAuthError,
  integrationExpiring,
}) => {
  const daysRemainingUntilExpiration = lastActivityDate
    ? getDaysRemainingUntilExpiration(lastActivityDate)
    : "less than 14";

  useEffect(() => {
    if (integrationExpiring) {
      const pdpConfig = integrationConfigs[solutionSlug]["copy"]["pdp"];
      setAlertData({
        message: (
          <div className={integrationAlert}>
            <span className={alertText}>
              {
                pdpConfig.expiringOrDisabledAlertData?.integrationExpiring
                  .message1
              }
              {daysRemainingUntilExpiration}
              {
                pdpConfig.expiringOrDisabledAlertData?.integrationExpiring
                  .message2
              }
            </span>
            {pdpConfig.expiringOrDisabledAlertData?.integrationExpiring
              .buttonType === "refresh" && (
              <span>
                <RefreshButton
                  solutionSlug={solutionSlug}
                  setAlertData={setAlertData}
                  hasPermission={hasPermission}
                  inAlert={true}
                />
              </span>
            )}
            {pdpConfig.expiringOrDisabledAlertData?.integrationExpiring
              .buttonType === "disconnect" && (
              <span>
                <DisconnectButton
                  solutionSlug={solutionSlug}
                  setAlertData={setAlertData}
                  hasPermission={hasPermission}
                  inAlert={true}
                />
              </span>
            )}
          </div>
        ),
        color: pdpConfig.expiringOrDisabledAlertData?.integrationExpiring.color,
        visible:
          pdpConfig.expiringOrDisabledAlertData?.integrationExpiring.visible,
        dismissible:
          pdpConfig.expiringOrDisabledAlertData?.integrationExpiring
            .dismissible,
      });
    }
    if (connectionStatus === "ERROR") {
      if (hasAuthError) {
        setAlertData({
          message: (
            <div className={integrationAlert}>
              <span className={alertText}>
                There’s an error with your Lever integration, disconnect it and
                set up a new one. Your pending hires won’t be affected.
              </span>
              <span>
                <DisconnectButton
                  solutionSlug={solutionSlug}
                  setAlertData={setAlertData}
                  hasPermission={hasPermission}
                  inAlert={true}
                />
              </span>
            </div>
          ),
          color: "destructive" as const,
          visible: true,
          dismissible: false,
        });
      } else {
        setAlertData({
          message: (
            <>
              There is an error with the integration. Refresh the page or
              contact Customer Support at {supportLink}.
            </>
          ),
          color: "destructive",
          visible: true,
          dismissible: true,
        });
      }
    }
  }, [
    connectionStatus,
    setAlertData,
    hasAuthError,
    hasPermission,
    solutionSlug,
    integrationExpiring,
    daysRemainingUntilExpiration,
  ]);

  const customDisconnectButton =
    config?.instanceDetailsPanelConfig?.customDisconnectButton;
  const showLastActive = !config?.instanceDetailsPanelConfig?.hideLastActive;

  return (
    <div className={instanceDetailsPanelContent}>
      <div className={statusAndActivitySections}>
        <div className={connectedHeaders}>Status</div>
        <ConnectionStatusBadge connectionStatus={connectionStatus} />
      </div>
      {showLastActive && (
        <div className={statusAndActivitySections}>
          <div className={connectedHeaders}>Last Activity</div>
          <div>
            {lastActivityDate
              ? formatDate(lastActivityDate)
              : "No activity yet"}
          </div>
        </div>
      )}
      {hasPermission ? null : <Notice>{permissionNotice}</Notice>}
      <div className={connectedButtonSection}>
        {connectionStatus !== "CONNECTED" && (
          <SetupButton
            hasPermission={hasPermission}
            solutionSlug={solutionSlug}
          />
        )}
        {customDisconnectButton || (
          <DisconnectButton
            solutionSlug={solutionSlug}
            setAlertData={setAlertData}
            hasPermission={hasPermission}
          />
        )}
      </div>
    </div>
  );
};

export default ConnectedOrPendingPanelContent;
