import { SystemIcon } from "@justworkshr/milo-icons";
import styles from "./StateRequirementsCard.module.css";
import { SeparationNoticeStateRequirement } from "pages/team-management/types";

export const StateRequirementsCard = ({
  stateRequirement,
  presignedUrl,
}: {
  stateRequirement: SeparationNoticeStateRequirement;
  presignedUrl?: string;
}) => {
  return (
    <div className={styles.stateRequirementsCard}>
      <h3>{stateRequirement.state}</h3>

      <p>{stateRequirement.description}</p>
      <div className={styles.stateRequirementDownloadLink}>
        <a href={stateRequirement.link || presignedUrl}>
          {stateRequirement.linkText ||
            `Download form for ${stateRequirement.state}`}
        </a>
        <SystemIcon iconName="external-link" color="brand" />
      </div>
    </div>
  );
};
