import Viewport from "./viewport/viewport";
import styles from "./app-layout.module.css";
import { useGetUserNavCapabilitiesQuery } from "types/generated/operations";
import Header from "./header";
import Sidebar from "./sidebar";
import Footer from "./footer";
import { ViewContextProvider } from "./header/context/view-context-provider";

const { appLayout, sidebar, header, viewport, footer } = styles;

export default function AppLayout(): JSX.Element {
  /*
   * TODO: investigate performance of blocking w/await
   * to ensure this isn't delayed in the event loop
   * (out of scope, 6/27/22, @leland)
   */
  const { data, loading } = useGetUserNavCapabilitiesQuery();

  if (loading || !data) return <></>;

  const { capabilities } = data.authenticatedMember;

  return (
    <>
      <div className={appLayout}>
        <section className={sidebar}>
          <Sidebar />
        </section>
        <section className={header}>
          <ViewContextProvider>
            <Header capabilities={capabilities} />
          </ViewContextProvider>
        </section>
        <section className={viewport}>
          <Viewport />
        </section>
        <section className={footer}>
          <Footer />
        </section>
      </div>
    </>
  );
}
