import { useState, useEffect } from "react";
import { ReimbursementRequestForHistory } from "../../routes/RequestHistoryPage/RequestHistoryPage.types";
import {
  useGetRequestHistoryQuery,
  GetRequestHistoryQueryVariables,
} from "types/generated/operations";

export const useGetRequestHistory = (
  variables: GetRequestHistoryQueryVariables
) => {
  const { data: requestHistoryData, loading: isRequestHistoryLoading } =
    useGetRequestHistoryQuery({
      variables,
    });
  const [requestHistory, setRequestHistory] = useState<
    ReimbursementRequestForHistory[]
  >([]);
  useEffect(() => {
    if (
      !isRequestHistoryLoading &&
      requestHistoryData?.expenseManagement?.requests?.requests
    ) {
      setRequestHistory(requestHistoryData.expenseManagement.requests.requests);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestHistoryLoading, requestHistoryData]);
  return {
    requestHistory,
    requestHistoryResponse: requestHistoryData?.expenseManagement?.requests,
    isRequestHistoryLoading,
  };
};
