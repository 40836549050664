import React from "react";
import { ExpenseLibraryExpense } from "types/Expenses";
import {
  Drawer,
  ExpenseDetailsCard,
  ExpenseUuidTooltip,
} from "pages/expenses/components";
import styles from "./ExpenseDetailDrawer.module.css";
import { Button } from "@justworkshr/milo-core";
import { useRequestDetail } from "pages/expenses/store";
import { useNavigate } from "react-router-dom";
import { prependBasePath } from "pages/expenses/constants";

const { ExpenseDetailDrawerHeaderWrapper, ExpenseDetailDrawerButtonsWrapper } =
  styles;

interface ExpenseDetailDrawerProps {
  expenseIndex: number;
  expense: ExpenseLibraryExpense;
  onSelectedExpenseChange: (
    data: {
      expense: ExpenseLibraryExpense;
      expenseIndex: number;
    } | null
  ) => void;
  onRemoveExpense: () => void;
}

export const ExpenseDetailDrawer: React.FC<ExpenseDetailDrawerProps> =
  React.memo(
    ({ expense, expenseIndex, onSelectedExpenseChange, onRemoveExpense }) => {
      const navigate = useNavigate();
      const { setRequestDetail } = useRequestDetail();
      const handleClose = () => onSelectedExpenseChange(null);

      return (
        <Drawer
          isOpen={!!expense}
          onClose={handleClose}
          title="Expense details"
        >
          <div className={ExpenseDetailDrawerHeaderWrapper}>
            <ExpenseUuidTooltip label="Expense ID: " uuid={expense.uuid} />
            <div className={ExpenseDetailDrawerButtonsWrapper}>
              <Button
                size="sm"
                type="button"
                variant="ghost"
                leftIcon="trash"
                color="destructive"
                onClick={onRemoveExpense}
                data-testid="expense-drawer-delete-button"
              >
                Delete
              </Button>
              <Button
                size="sm"
                type="button"
                leftIcon="edit"
                variant="ghost"
                onClick={() => {
                  setRequestDetail({
                    editMode: {
                      editExpense: expense,
                      editExpenseIndex: expenseIndex,
                    },
                    metadata: {
                      isUnattachedExpenseView: true,
                    },
                  });

                  navigate(prependBasePath("EXPENSE_DETAILS"));
                }}
                data-testid="expense-drawer-edit-button"
              >
                Edit
              </Button>
            </div>
          </div>
          <ExpenseDetailsCard expense={expense} />
        </Drawer>
      );
    }
  );
