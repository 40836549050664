import React from "react";
import { Dialog, Spinner } from "@justworkshr/milo-core";
import styles from "./DownloadReportDialog.module.css";

interface DownloadReportDialogProps {
  isOpen: boolean;
  onClose: () => void;
}
const { DownloadReportDialogCopy } = styles;

export const DownloadReportDialog: React.FC<DownloadReportDialogProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Dialog isOpen={isOpen} onClose={onClose} title="Preparing file">
      <p className={DownloadReportDialogCopy}>
        Please wait while we get your expense report ready. Closing the modal
        will cancel the export.
      </p>
      <Spinner />
    </Dialog>
  );
};

export default DownloadReportDialog;
