import React from "react";
import { ReactElement } from "react";
import styles from "./whats-new-notification.module.css";

const {
  article,
  circle,
  infoLine,
  infoDivider,
  dateTime,
  postTitle,
  postDescription,
  subHeader,
  postImage,
  postLink,
  postTitleWithImage,
} = styles;

export default function WhatsNewNotification({
  category,
  date,
  description,
  image,
  url,
  title,
  seen,
  call_to_action,
}: {
  category: string;
  date: string;
  description: string;
  image?: string;
  url?: string;
  title: string;
  seen: boolean;
  call_to_action?: string;
}): ReactElement {
  const dateESTTime = new Date(date.replace(/-/g, "/"));
  const formattedDateTimeString: string = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(new Date(dateESTTime));
  return (
    <article className={article}>
      {image && <img src={image} alt="" className={postImage} />}
      <div className={subHeader}>
        {!seen && (
          <span
            className={circle}
            data-testid="whats-new-unseen-purple-circle"
          ></span>
        )}
        <span className={infoLine}>{category}</span>
        <span className={infoDivider}> | </span>
        <time className={dateTime}>{formattedDateTimeString}</time>
      </div>
      <h5 className={image ? postTitleWithImage : postTitle}>{title}</h5>
      <p className={postDescription}>{description}</p>
      {url && call_to_action && (
        <a className={postLink} href={url}>
          {call_to_action}
        </a>
      )}
    </article>
  );
}
