import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import { ViewType } from "./types";

interface ViewContextValue {
  view: ViewType | undefined;
}

interface ViewContextProviderProps {
  children: ReactNode;
}

const ViewContext = createContext<ViewContextValue>({
  view: undefined,
});

export function useViewContext() {
  const context = useContext(ViewContext);
  if (context === undefined) {
    throw new Error("useViewContext must be used within a ViewContextProvider");
  }
  return context;
}

export default function ViewContextProvider({
  children,
}: ViewContextProviderProps) {
  const [view, setView] = useState<ViewType>();

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia("(max-width: 879px)");
    const tabletMediaQuery = window.matchMedia(
      "(min-width: 880px) and (max-width: 1151px)"
    );
    const desktopMediaQuery = window.matchMedia("(min-width: 1152px)");

    const handleMobileMediaQueryChange = (e: MediaQueryListEvent) => {
      if (e.matches) {
        setView(ViewType.MOBILE);
      }
    };

    const handleTabletMediaQueryChange = (e: MediaQueryListEvent) => {
      if (e.matches) {
        setView(ViewType.TABLET);
      }
    };

    const handleDesktopMediaQueryChange = (e: MediaQueryListEvent) => {
      if (e.matches) {
        setView(ViewType.DESKTOP);
      }
    };

    mobileMediaQuery.addEventListener("change", handleMobileMediaQueryChange);
    tabletMediaQuery.addEventListener("change", handleTabletMediaQueryChange);
    desktopMediaQuery.addEventListener("change", handleDesktopMediaQueryChange);

    if (mobileMediaQuery.matches) {
      setView(ViewType.MOBILE);
    }
    if (tabletMediaQuery.matches) {
      setView(ViewType.TABLET);
    }
    if (desktopMediaQuery.matches) {
      setView(ViewType.DESKTOP);
    }

    return () => {
      mobileMediaQuery.removeEventListener(
        "change",
        handleMobileMediaQueryChange
      );
      tabletMediaQuery.removeEventListener(
        "change",
        handleTabletMediaQueryChange
      );
      desktopMediaQuery.removeEventListener(
        "change",
        handleDesktopMediaQueryChange
      );
    };
  }, []);

  return (
    <ViewContext.Provider
      value={{
        view,
      }}
    >
      {children}
    </ViewContext.Provider>
  );
}
