import { ReactElement } from "react";
import { FormField } from "@justworkshr/milo-form";
import MessageWithLink from "pages/employer-of-record/components/MessageWithLink/MessageWithLink";
import styles from "../../../../SetupFormGenerator.module.css";
import { GeneratedComponentProp } from "../../../../types";
import { FormPayloadType } from "../../../../../../types";

export function TextArea<T extends FormPayloadType>({
  field,
  accessor,
  touchedField,
  errorField,
  value,
  handleBlur,
  handleChange,
}: GeneratedComponentProp<T>): ReactElement {
  return (
    <FormField
      label={field.label}
      required={field.required}
      error={touchedField ? errorField : ""}
    >
      <>
        {field.message && (
          <div className={styles.description}>
            <MessageWithLink {...field.message} />
          </div>
        )}
        <textarea
          name={`setUp.${accessor}`}
          className={`${styles.textArea} ${
            errorField && touchedField ? styles.error : ""
          }`}
          rows={2}
          onBlur={handleBlur}
          value={value}
          onChange={handleChange}
        />
      </>
    </FormField>
  );
}
