import Drawer from "app-frame/header/common/drawer";
import styles from "./whats-new-drawer.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";
import WhatsNewInbox from "./whats-new-inbox";
import NestedDrawerHeader from "app-frame/header/common/nested-drawer-header";
import { usePanelsControlContext } from "app-frame/header/context/panels-control-context-provider";
import { PanelsControlActionType } from "app-frame/header/context/panels-control-context-provider/types";
import { useGetWhatsNewPostsQuery } from "types/generated/operations";
import {
  ViewType,
  useViewContext,
} from "app-frame/header/context/view-context-provider";

const {
  tabletAndDesktopDrawerHeader,
  mobileDrawerHeader,
  iconLabelContainer,
  whatsNewClose,
  whatsNewIcon,
} = styles;

function WhatsNewDrawer() {
  const { view } = useViewContext();
  const { state, dispatch } = usePanelsControlContext();
  const { whatsNewDrawerShown } = state;
  const headerTitle = "What's new";

  const { data, loading } = useGetWhatsNewPostsQuery();
  if (loading || !data) {
    return <></>;
  }
  const productPosts = data.currentMember.productPosts;

  const drawerHeaderForTabletAndDesktop = (
    <div className={tabletAndDesktopDrawerHeader}>
      <div className={iconLabelContainer}>
        <span className={whatsNewIcon} />
        <h1>{headerTitle}</h1>
      </div>
      <button
        onClick={() =>
          dispatch({
            type: PanelsControlActionType.CLOSE_WHATS_NEW_DRAWER_FROM_TABLET_OR_DESKTOP,
          })
        }
      >
        <span className={whatsNewClose} />
      </button>
    </div>
  );

  const drawerHeaderForMobile = (
    <div className={mobileDrawerHeader}>
      <NestedDrawerHeader
        icon={
          <SystemIcon iconName="whats-new" size="large" color="brand-subtle" />
        }
        onClick={() =>
          dispatch({
            type: PanelsControlActionType.CLOSE_WHATS_NEW_DRAWER_FROM_MOBILE,
          })
        }
      >
        {headerTitle}
      </NestedDrawerHeader>
    </div>
  );

  const drawerContent = (
    <div data-testid="whats-new-inbox">
      <WhatsNewInbox productPosts={productPosts} />
    </div>
  );

  return (
    <Drawer
      name="whats-new-drawer"
      header={
        view === ViewType.MOBILE
          ? drawerHeaderForMobile
          : drawerHeaderForTabletAndDesktop
      }
      onClose={() =>
        dispatch({
          type: PanelsControlActionType.CLOSE_WHATS_NEW_DRAWER_FROM_TABLET_OR_DESKTOP,
        })
      }
      open={whatsNewDrawerShown}
      zIndex={10000000}
      showOverlay={view !== ViewType.MOBILE}
    >
      {drawerContent}
    </Drawer>
  );
}

export default WhatsNewDrawer;
