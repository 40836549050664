import * as Yup from "yup";
import { noEmojiTest } from "../../../validations";

export const retroactivePayloadSchema = Yup.object().shape({
  paymentSettings: Yup.object().shape({
    effectiveDate: Yup.date().required("Effective date is required"),
    payPeriodId: Yup.string().required("Pay date is required"),
    notes: noEmojiTest,
  }),
});
