import React from "react";
import styles from "./notice.module.css";
import { SystemIcon, SystemIconProps } from "@justworkshr/milo-icons";
import clsx from "lib/styling-utils/clsx";

const { noticeBox, systemIconSpan } = styles;

export enum NoticeColor {
  grey = "var(--intent-color-background-neutral-subtle)",
  blue = "var(--intent-color-background-brand-subtle)",
}

export type NoticeProps = {
  children: React.ReactNode;
  systemIcon?: SystemIconProps["iconName"];
  color?: NoticeColor | string;
  copyNotice?: true;
};

const Notice: React.FC<NoticeProps> = ({
  children,
  systemIcon,
  color,
  copyNotice,
}) => {
  const noticeBoxStyle = {
    "--notice-box-color": color || NoticeColor.grey,
  } as React.CSSProperties;

  return (
    <div className="milo--root">
      <div
        className={clsx(noticeBox, copyNotice && "copyNoticeBox")}
        style={noticeBoxStyle}
      >
        {systemIcon && (
          <span className={systemIconSpan}>
            <SystemIcon iconName={systemIcon} />
          </span>
        )}
        {children}
      </div>
    </div>
  );
};

export default Notice;
