import { ApolloError } from "@apollo/client";
import { buildWebPath } from "../../../lib/resource-finder";
import { MemberSeparation } from "../types";

export const mergeClassNames = (
  classNames: Array<
    string | null | number | undefined | { [key: string]: boolean }
  >
) => {
  const activeClassNames = [];

  for (const className of classNames) {
    const isObjectType = typeof className === "object";

    if (className && isObjectType && Object.keys(className || {}).length) {
      Object.entries(className).forEach(([cName, isActive]) => {
        if (isActive) activeClassNames.push(cName);
      });
    } else if (className && !isObjectType) {
      activeClassNames.push(className);
    }
  }

  return activeClassNames.join(" ");
};

export const getTerminationReasonType = (
  memberSeparation: MemberSeparation | undefined
): string | null => {
  return (
    memberSeparation?.memberSeparationAnswers?.find(
      (answer) => answer.fieldId === "termination_reason"
    )?.values?.[0]?.valueTextSmall ?? null
  );
};

export const handleMemberError = (
  memberError: ApolloError,
  memberUuid: string | undefined
) => {
  const errorMessage = memberError?.message;
  if (!errorMessage) {
    return;
  }

  if (!memberUuid) {
    window.location.assign(buildWebPath(`/`));
    return;
  }

  switch (errorMessage) {
    case "Member not found":
    case "Access denied for this admin member":
    case "Member belongs to a different company":
      window.location.assign(buildWebPath(`/`));
      return;
    case "Member has already been terminated":
      window.location.assign(
        buildWebPath(`/members/${memberUuid}/personal-info`)
      );
      return;
    case "Wrong separation workflow for this member":
      window.location.assign(buildWebPath(`/terminate_employee/${memberUuid}`));
      return;
  }
};

export const handleTerminatedMember = (memberUuid: string | undefined) => {
  if (!memberUuid) {
    return;
  }

  window.location.assign(buildWebPath(`/members/${memberUuid}/personal-info`));
};

export const isEmptyValue = (
  value: string | number | (string | number)[] | undefined
): boolean => {
  const isSingleValueEmpty = (
    value: string | number | (string | number)[] | undefined
  ) => value === null || value === undefined || !String(value).trim().length;

  if (value && Array.isArray(value)) {
    return !value.filter((v) => !isSingleValueEmpty(v)).length;
  }
  return isSingleValueEmpty(value);
};
