import { ReactElement } from "react";
import { useGetNavigationGroupsQuery } from "types/generated/operations";
import NavGroupSection from "./nav-group-section";
import NavCalloutSection from "./nav-callout-section";
import styles from "./side-nav.module.css";

export default function SideNav(): ReactElement {
  const { data, loading } = useGetNavigationGroupsQuery();
  // TODO: Handle error and dataless case
  if (loading || !data) {
    return <></>;
  }
  const navGroups = data.authenticatedMember.routeDirectory;
  const navGroupsWithChildren = navGroups.filter((val) => val.navItems?.length);
  const navGroupRenders = navGroupsWithChildren.map(
    ({ standaloneContent, groupName, navItems }, index) => {
      if (standaloneContent) {
        return (
          <NavCalloutSection
            key={`nav-callout-section-${index}`}
            navItems={navItems}
          />
        );
      } else {
        return (
          <NavGroupSection
            key={`nav-group-section-${index}`}
            groupName={groupName}
            navItems={navItems}
          />
        );
      }
    }
  );
  return (
    <nav className={styles.sideNav} aria-label="site-navigation">
      {navGroupRenders}
    </nav>
  );
}
