import { Outlet } from "react-router-dom";
import { pkceIsEnabled } from "app/okta";
import { useRequireAuth } from "./hooks";
import { Spinner } from "@justworkshr/milo-core";

export const RequireAuth: React.FC = () => {
  const { authState } = useRequireAuth();

  if (!pkceIsEnabled()) return <Outlet />;

  if (!authState || !authState?.isAuthenticated) {
    return <Spinner />;
  }
  return <Outlet />;
};
