import { ReactElement, useContext, useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableHeader, TableRow } from "../Table";
import { SystemIcon } from "@justworkshr/milo-icons";
import { AccessIcon } from "../AccessIcon";
import ActionsMenuButton from "../ActionsMenu/ActionsMenuButton";
import {
  DEFAULT_DOCUMENTS_TABLE_PARAMS,
  DOCUMENTS_TABLE_HEADERS,
  DOCUMENT_ACCESS,
} from "../constants";
import styles from "./DocumentsTable.module.css";
import tableStyles from "../Table/Table.module.css";
import { formatDate, sortDocuments } from "../utils";
import { InternationalDocument } from "types";
import { InternationalContractorMemberContext } from "pages/international-contractor-profile-page/context/InternationalContractorMemberContext";

interface DocumentsTableProps {
  documents: InternationalDocument[];
  onEditClick: (doc: InternationalDocument) => void;
  onDeleteClick: (doc: InternationalDocument) => void;
}

export default function DocumentsTable({
  documents,
  onEditClick,
  onDeleteClick,
}: DocumentsTableProps): ReactElement {
  const { member } = useContext(InternationalContractorMemberContext);

  const [sortedData, setSortedData] = useState<InternationalDocument[]>([]);

  const [tableParams, setTableParams] = useState(
    DEFAULT_DOCUMENTS_TABLE_PARAMS
  );

  useEffect(() => {
    const sortedDocuments = sortDocuments({
      documents,
      sortOptions: {
        sortBy: tableParams.sortBy,
        sortDirection: tableParams.sortDirection,
      },
    });

    setSortedData(sortedDocuments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableParams.sortBy, tableParams.sortDirection, documents]);

  const handleSort = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string;
    sortDirection: string;
  }) => {
    setTableParams({
      sortBy,
      sortDirection,
    });
  };

  return (
    <Table>
      <TableHeader
        headers={DOCUMENTS_TABLE_HEADERS}
        onSort={handleSort}
        sortBy={tableParams.sortBy}
        sortDirection={tableParams.sortDirection}
      />
      <TableBody>
        {sortedData.map((doc, index) => {
          const memberAssignment = doc.documentMemberAssignments.find(
            (assignment) => assignment.memberId === member?.uuid
          );
          const visibilityLevel = memberAssignment?.visibilityLevel;
          const noAccess = visibilityLevel === DOCUMENT_ACCESS.ADMIN_ONLY;

          return (
            <TableRow key={index}>
              <TableCell className={tableStyles.textWithIcon}>
                <SystemIcon
                  className={tableStyles.iconLeft}
                  iconName="document-pdf"
                  color="disabled"
                  size="small"
                />
                {doc.name}
                {noAccess && <AccessIcon />}
              </TableCell>
              <TableCell
                className={`${tableStyles.lightText} ${styles.uploadTableColumn}`}
              >
                {formatDate(`${doc.uploadDate}T00:00:00`)}
              </TableCell>
              <TableCell className={styles.actionsTableColumn}>
                <ActionsMenuButton
                  documentId={doc.id || ""}
                  editDocument={() => onEditClick(doc)}
                  deleteDocument={() => onDeleteClick(doc)}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
