import React from "react";
import {
  BILLABLE_VALUE,
  NON_BILLABLE_VALUE,
  DEFAULT_DOWNLOAD_PROPS,
} from "pages/expenses/constants";
import {
  capitalize,
  formatDate,
  getDollarsFromCents,
} from "pages/expenses/utils";
import {
  AuditLogChildDifferences,
  AuditLogExpenseAddedType,
  AuditLogParentDifferences,
  AuditLogPaymentSubmittedType,
} from "types/Expenses";
import { ExpenseUuidTooltip } from "pages/expenses/components";
import styles from "./AuditLogPage.module.css";

const { AuditLogDetail, AuditLogDetailUuid, AuditLogDetailList } = styles;

const formatStringWithUnderscore = (data: string | null | undefined) => {
  if (!data) return "";

  return `${capitalize(data.toLowerCase().replace(/_/g, "-"))}`;
};

const handleChildDifference = (props: AuditLogChildDifferences) => {
  switch (props.__typename) {
    case "AmountChanged":
      return <li>Amount: {getDollarsFromCents(props?.amount)}</li>;
    case "ProjectChanged":
      return <li>Project: {props?.project?.displayName}</li>;
    case "CategoryChanged":
      return <li>Category: {props?.category?.name}</li>;
    case "MerchantChanged":
      return <li>Merchant: {props?.merchant}</li>;
    case "TransactionDateChanged":
      return <li>Transaction date: {formatDate(props?.transactionDate)}</li>;
    case "DescriptionChanged":
      return <li>Description: {props?.description}</li>;
    case "AttendeesChanged":
      return <li>Attendees: {props?.attendees}</li>;
    case "ExpenseTypeChanged":
      return (
        <li>Expense type: {formatStringWithUnderscore(props?.expenseType)}</li>
      );
    case "IsBillableToClientChanged":
      return (
        <li>
          Billable to client:{" "}
          {props?.isBillableToClient ? BILLABLE_VALUE : NON_BILLABLE_VALUE}
        </li>
      );
    case "ReceiptRemoved":
      return <li>{props?.filename} removed.</li>;
    case "ReceiptAdded":
      return <li>{props?.filename} added.</li>;

    default:
      return null;
  }
};

const renderReceipts = (receipts: AuditLogExpenseAddedType["receipts"]) => {
  if (receipts?.length === 0) {
    return null;
  }

  const hasMultipleReceipts = receipts.length > 1;

  const anchorProps = {
    ...DEFAULT_DOWNLOAD_PROPS,
    href: receipts[0].url,
  };

  if (hasMultipleReceipts) {
    return (
      <li>
        Receipts:{" "}
        {receipts.map(({ uuid, filename, url }, index) => {
          const isLastElement = index === receipts.length - 1;

          return (
            <React.Fragment key={uuid}>
              <a {...anchorProps} href={url}>
                {filename}
              </a>
              {isLastElement ? "" : ", "}
            </React.Fragment>
          );
        })}
      </li>
    );
  }

  return (
    <li>
      Receipt: <a {...anchorProps}>{receipts[0].filename}</a>
    </li>
  );
};

const renderExpenseUUIDTooltip = (
  uuid: string,
  expenseType: string | null | undefined
) => {
  let label = `Expense #`;

  if (expenseType) {
    label = `${formatStringWithUnderscore(expenseType)} Expense #`;
  }

  return (
    <div className={AuditLogDetailUuid}>
      <ExpenseUuidTooltip uuid={uuid} label={label} />
    </div>
  );
};

export const renderAuditLogExpense = (
  expense: AuditLogPaymentSubmittedType["expenses"][0]
) => {
  return (
    <div>
      {renderExpenseUUIDTooltip(expense.uuid, expense?.expenseType)}
      <ul className={AuditLogDetailList}>
        {expense?.amount && (
          <li>Amount: {getDollarsFromCents(expense.amount)}</li>
        )}
        {expense?.transactionDate && (
          <li>Transaction date: {formatDate(expense.transactionDate)}</li>
        )}
        {expense?.project?.displayName && (
          <li>Project: {expense?.project?.displayName}</li>
        )}
        {expense?.category?.name && (
          <li>Category: {expense?.category?.name}</li>
        )}
        {expense?.merchant && <li>Merchant: {expense.merchant}</li>}
        {expense?.description && <li>Description: {expense.description}</li>}
        {expense?.attendees && <li>Attendees: {expense.attendees}</li>}
        {renderReceipts(expense.receipts)}
        {expense?.category?.billableToClient !== "HIDDEN" && (
          <li>
            Billable to client:{" "}
            {expense.isBillableToClient ? BILLABLE_VALUE : NON_BILLABLE_VALUE}
          </li>
        )}
      </ul>
    </div>
  );
};

export const handleParentDifference = (props: AuditLogParentDifferences) => {
  switch (props.__typename) {
    case "NameChange":
      return <p>Request name: {props?.newName}</p>;
    case "ExpenseEdited":
      return (
        <div>
          {renderExpenseUUIDTooltip(props?.uuid, props?.expenseType)}
          <ul className={AuditLogDetailList}>
            {(props?.differences ?? []).map(
              ({ type, ...childDifferences }, index) => {
                return (
                  <React.Fragment key={`${type}_${props?.uuid}_${index}}`}>
                    {handleChildDifference(childDifferences)}
                  </React.Fragment>
                );
              }
            )}
          </ul>
        </div>
      );
    case "ExpenseAdded":
      return renderAuditLogExpense(props);
    case "ExpenseRemoved":
      return (
        <div className={AuditLogDetail}>
          {renderExpenseUUIDTooltip(props?.uuid, props?.expenseType)} was
          deleted.
        </div>
      );

    default:
      return null;
  }
};

export const getExpenseText = (numExpenses: number) => {
  if (numExpenses === 1) return `1 expense`;

  return `${numExpenses} expenses`;
};

export const getTimeFromTimestamp = (timestamp: string) =>
  new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "shortGeneric",
  }).format(new Date(timestamp));

export const getDateFromTimestamp = (timestamp: string) =>
  new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  }).format(new Date(timestamp));
