import { usePanelsControlContext } from "app-frame/header/context/panels-control-context-provider";
import HeaderButton from "../../common/header-button";
import { PanelsControlActionType } from "app-frame/header/context/panels-control-context-provider/types";
import { useGetMemberAlertsQuery } from "types/generated/operations";

export default function AlertsButton() {
  const { state, dispatch } = usePanelsControlContext();
  const { alertsDrawerShown } = state;

  const { data, loading } = useGetMemberAlertsQuery();
  if (loading || !data) {
    return <></>;
  }
  const notificationsCount = data.currentMember.notificationsCount;

  return (
    <HeaderButton
      outstandingTaskCount={notificationsCount}
      text="Alerts"
      testId="alerts-button"
      buildInIconName="alerts"
      onClick={() =>
        dispatch({
          type: PanelsControlActionType.OPEN_ALERTS_DRAWER,
        })
      }
      active={alertsDrawerShown}
    />
  );
}
