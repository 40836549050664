import { ReactElement, useState } from "react";
import styles from "../../InternationalSolutionsPage.module.css";
import { Box, Button } from "@justworkshr/milo-core";
import { CF_ADD_EMPLOYEES_LINK } from "pages/international-solutions/constants";
import ThankYouDialog from "../Dialog/ThankYouDialog";
import { useGetIsEmployerOfRecordEnabledQuery } from "types/generated/operations";
import { FLAG_NAMES, flagIsEnabled } from "lib/environment";

const EORPriceCard = (): ReactElement => {
  const { divWrapper, mdGap, mdHead, mdPadding } = styles;
  const [showThankYouDialog, setShowThankYouDialog] = useState(false);
  const { data: eorEnabledData } = useGetIsEmployerOfRecordEnabledQuery();
  const isEmployerOfRecordEnabled =
    eorEnabledData?.authenticatedMember.company.companySetting?.value ===
      "true" && flagIsEnabled(FLAG_NAMES.EMPLOYER_OF_RECORD);

  const handleThankYouDialog = () => {
    setShowThankYouDialog((prev) => !prev);
  };

  return (
    <Box
      backgroundColor="neutral"
      border={{
        borderColor: "neutralSubtle",
        borderRadius: "md",
        borderWidth: "md",
      }}
      padding="md"
    >
      <div className={`${divWrapper} ${mdGap}`}>
        <div className={`${divWrapper} ${mdPadding}`}>
          <div className={mdHead}>Availability </div>
          <div>
            Top Talent Markets include the UK, Canada, Mexico, Portugal,
            Netherlands, and many more.
          </div>
        </div>
        {isEmployerOfRecordEnabled ? (
          <Button
            data-heap-id="eor-add-employees-button"
            color="brand"
            type="button"
            size="md"
            as="a"
            href={CF_ADD_EMPLOYEES_LINK}
          >
            Add employees
          </Button>
        ) : (
          <Button
            data-heap-id="no-eor-im-interested-button"
            color="brand"
            type="button"
            size="md"
            onClick={() => {
              handleThankYouDialog();
            }}
          >
            I'm interested
          </Button>
        )}
      </div>

      {showThankYouDialog && (
        <ThankYouDialog
          isOpen={showThankYouDialog}
          onClose={handleThankYouDialog}
        />
      )}
    </Box>
  );
};

export default EORPriceCard;
