import { MemberSeparationDocuments } from "pages/team-management/types";
import { useState, useMemo } from "react";

export interface DocumentListInterface {
  addFileDocumentMapping: (fileName: string, documentId: string) => void;
  getDocumentIdByFileName: (fileName: string) => string | undefined;
  removeFileDocumentMapping: (fileName: string) => void;
  clearFileDocumentMapping: () => void;
  getDocumentIds: () => number[];
  addExistingDocumentMap: (fileName: string, documentId: string) => void;
  removeExistingDocumentMapping: (fileName: string) => void;
  addExistingDocumentsMap: (documents: MemberSeparationDocuments[]) => void;
  fileDocumentMap: Record<string, string>;
  existingDocumentMap: Record<string, string>;
}

function DocumentList() {
  const [fileDocumentMap, setFileDocumentMap] = useState<
    Record<string, string>
  >({});

  const [existingDocumentMap, setExistingDocumentMap] = useState<
    Record<string, string>
  >({});

  const addExistingDocumentMap = (fileName: string, documentId: string) => {
    setExistingDocumentMap((prevMap) => ({
      ...prevMap,
      [fileName]: documentId,
    }));
  };

  const addExistingDocumentsMap = (documents: MemberSeparationDocuments[]) => {
    documents?.map(({ title, id }) => {
      title && id && addExistingDocumentMap(title, id);
    });
  };

  const removeExistingDocumentMapping = (fileName: string) => {
    if (fileName) {
      const newMap = { ...existingDocumentMap };
      delete newMap[fileName];
      setExistingDocumentMap(newMap);
    }
  };

  const addFileDocumentMapping = (fileName: string, documentId: string) => {
    setFileDocumentMap((prevMap) => ({ ...prevMap, [fileName]: documentId }));
  };

  const getDocumentIdByFileName = (fileName: string) => {
    return fileDocumentMap[fileName];
  };

  const removeFileDocumentMapping = (fileName: string) => {
    const newMap = { ...fileDocumentMap };
    delete newMap[fileName];
    setFileDocumentMap(newMap);
  };

  const clearFileDocumentMapping = () => {
    setFileDocumentMap({});
  };

  const getDocumentIds = () => {
    const allDocumentIds = [
      ...Object.values(fileDocumentMap),
      ...Object.values(existingDocumentMap),
    ];

    const documentIds = allDocumentIds
      .map((id) => parseInt(id))
      .filter((id) => !isNaN(id));

    return documentIds;
  };

  const memoizedFileDocumentMap = useMemo(
    () => fileDocumentMap,
    [fileDocumentMap]
  );

  const memoizedExistingDocumentMap = useMemo(
    () => existingDocumentMap,
    [existingDocumentMap]
  );

  return {
    addFileDocumentMapping,
    getDocumentIdByFileName,
    removeFileDocumentMapping,
    clearFileDocumentMapping,
    getDocumentIds,
    addExistingDocumentMap,
    removeExistingDocumentMapping,
    addExistingDocumentsMap,
    fileDocumentMap: memoizedFileDocumentMap,
    existingDocumentMap: memoizedExistingDocumentMap,
  };
}

export default DocumentList;
