import { ReactElement } from "react";
import styles from "./style.module.css";
import { FormikCheckboxInput } from "lib/formik/FormikFormField/index";
import {
  PTO_POLICY_TYPE_LABEL,
  PTO_POLICY_AWARD_METHOD,
  PTO_POLICY_TYPE,
  PTO_POLICY_STATUS,
  PTO_POLICY_TIME_PERIOD_TYPE,
  PTO_POLICY_SYNC_STATUS,
} from "../invitation-form-utils";
const { checkboxItemInfo } = styles;

type InvitationFormPtoPolicyOptionProps = {
  id: number;
  uuid: number;
  name: string;
  policy_type: PTO_POLICY_TYPE;
  capped: boolean;
  effective_date: string;
  deactivation_date?: string;
  company_id: number;
  minutes_per_year?: number;
  carryover_days_per_year?: number;
  days_per_year?: number;
  carryover_minutes: number;
  status: PTO_POLICY_STATUS;
  time_period_type: PTO_POLICY_TIME_PERIOD_TYPE;
  award_method: PTO_POLICY_AWARD_METHOD;
  sync_status: PTO_POLICY_SYNC_STATUS;
  overtime_included: boolean;
  minutes_accrued_rate?: number;
  minutes_worked_rate?: number;
  description: string;
  time_period_renewal?: string;
  accrual_cap_minutes?: number;
  created_at: string;
  updated_at: string;
};

export default function InvitationFormPtoPolicyOption(
  pto_policy: InvitationFormPtoPolicyOptionProps
): ReactElement {
  return (
    <span>
      <FormikCheckboxInput
        name="ptoType"
        label={pto_policy.name}
        key={pto_policy.uuid}
        fieldConfig={{ type: "checkbox" }}
        value={pto_policy.uuid}
        aria-describedby={`${pto_policy.uuid}-info`}
      />
      <div id={`${pto_policy.uuid}-info`} className={checkboxItemInfo}>
        <p>
          {PTO_POLICY_TYPE_LABEL[
            pto_policy.policy_type as keyof typeof PTO_POLICY_TYPE_LABEL
          ] || PTO_POLICY_TYPE_LABEL.other}{" "}
          policy effective on {pto_policy.effective_date}
        </p>
        {pto_policy.capped ? (
          <p>
            {pto_policy.days_per_year} days{" "}
            {pto_policy.award_method ==
            PTO_POLICY_AWARD_METHOD.AWARD_METHOD_LEGACY_DAILY
              ? "accrued"
              : "granted"}{" "}
            with{" "}
            {pto_policy.carryover_days_per_year
              ? `${pto_policy.carryover_days_per_year} days`
              : "unlimited"}{" "}
            carryover.
          </p>
        ) : (
          <p>Unlimited days.</p>
        )}
      </div>
    </span>
  );
}
