import CollapsibleCard from "./CollapsibleCard";
import { FormikProps } from "formik";
import { EditableProfileInfoFormType } from "../types";
import { useTranslation } from "react-i18next";
import ManagerSelectField from "pages/employer-of-record/invite/components/ManagerSelectField";
import DepartmentSelectField from "pages/employer-of-record/invite/components/DepartmentSelectField";
import { FormField, TextInput } from "@justworkshr/milo-form";

type Props = {
  open: boolean;
  toggleOpen: () => void;
} & FormikProps<EditableProfileInfoFormType>;

export default function EditJobInformation(props: Props) {
  const { open, toggleOpen } = props;
  const { values, touched, errors } = props;

  const { t } = useTranslation();

  return (
    <CollapsibleCard
      open={open}
      title={t("Job Information")}
      onClick={toggleOpen}
    >
      <FormField
        name="workId"
        label={t("Work ID")}
        required={true}
        error={touched?.workId ? errors?.workId : ""}
      >
        <TextInput
          name="workId"
          value={values.workId}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
        />
      </FormField>

      <DepartmentSelectField
        value={values.departmentUuid}
        name="departmentUuid"
        {...props}
      />

      <ManagerSelectField
        value={values.managerUuid}
        name="managerUuid"
        {...props}
      />
    </CollapsibleCard>
  );
}
