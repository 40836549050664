import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PermissionsError } from "../PermissionsError";
import { useGlobalConfigurationStore } from "pages/expenses/store";
import { ROUTES, prependBasePath } from "pages/expenses/constants";
import { AllPermissions, RoutePermission } from "./PermissionsWrapper.types";

interface PermissionsWrapperProps {
  children: JSX.Element;
  permission: RoutePermission;
  redirectTo?: keyof typeof ROUTES;
}

export const PermissionsWrapper: React.FC<PermissionsWrapperProps> = ({
  children,
  redirectTo,
  permission,
}) => {
  const navigate = useNavigate();

  const {
    permissions,
    isExpensesEnabled,
    hasJoinedExpenses,
    isExpensesEverEnabled,
  } = useGlobalConfigurationStore();

  const permissionsToCheck = {
    ...permissions,
    hasJoinedExpenses,
    isExpensesEnabled,
    isExpensesEverEnabled,
  };

  const hasPermission = Object.entries(permission).every(
    ([key, value]) => permissionsToCheck[key as AllPermissions] === value
  );

  useEffect(() => {
    if (!hasPermission && redirectTo) {
      navigate(prependBasePath(redirectTo));
    }
  }, [hasPermission, navigate, redirectTo]);

  if (!hasPermission) {
    return <PermissionsError />;
  }

  return children;
};
