import { ReactElement } from "react";
import { Card } from "@justworkshr/milo-core";
import { SetupFormGeneratorProps } from "./types";
import {
  AdditionalPayFormData,
  FieldValue,
  FormPayloadType,
} from "../../types";
import { useFormikContext } from "formik";
import FieldFactory from "./components/FieldFactory";

export function SetupFormGenerator<T extends FormPayloadType>({
  title,
  fields,
  formikFieldName,
}: SetupFormGeneratorProps<T>): ReactElement {
  const formik = useFormikContext<AdditionalPayFormData<T>>();

  const handleFieldsChanges = (
    changes: {
      fieldName: string;
      value: FieldValue;
    }[]
  ) => {
    const valuesObj = { ...formik.values };

    changes.forEach(({ fieldName, value }) => {
      valuesObj.setUp.payload[formikFieldName as keyof T][
        fieldName as keyof T[keyof T]
      ] = value as T[keyof T][keyof T[keyof T]];
    });

    formik.setValues(valuesObj);
  };

  const props = {
    formikFieldName,
    formik,
    handleFieldsChanges,
  };

  return (
    <Card title={title}>
      {fields.map((field) => (
        <div key={field.name}>
          <FieldFactory {...{ field, ...props }} />
        </div>
      ))}
    </Card>
  );
}
