import { useNavigate } from "react-router-dom";
import { Button, Card } from "@justworkshr/milo-core";
import { ACCOUNTING_INTEGRATIONS_DESCRIPTION_COPY } from "./AccountingIntegrations.constants";
import styles from "../AdvancedSettings.module.css";

const { WithoutBottomBorder, EditButtonStyle } = styles;

export const AccountingIntegrations = () => {
  const navigate = useNavigate();
  return (
    <Card
      className={WithoutBottomBorder}
      title="Accounting integrations"
      description={ACCOUNTING_INTEGRATIONS_DESCRIPTION_COPY}
      actions={[
        <Button
          className={EditButtonStyle}
          onClick={() => navigate("/integration-marketplace")}
          type="button"
          variant="ghost"
          leftIcon="edit"
          data-testid="edit-setting-button"
        >
          Edit
        </Button>,
      ]}
    >
      <></>
    </Card>
  );
};
