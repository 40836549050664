import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useFeedbackDialogStore } from "../../store";
import { Dialog } from "@justworkshr/milo-core";

export const FeedbackDialog = () => {
  const location = useLocation();

  const {
    feedbackDialog: { open, title, message },
    dismissFeedbackDialog,
  } = useFeedbackDialogStore();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (open) {
      dismissFeedbackDialog();
    }
  }, [location.pathname]);

  if (!open || !title) {
    return null;
  }

  return (
    <Dialog isOpen={open} onClose={dismissFeedbackDialog} title={title}>
      <p>{message}</p>
    </Dialog>
  );
};

export default FeedbackDialog;
