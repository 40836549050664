import { createContext } from "react";

interface CombinedInvoiceContextValue {
  isBreakdownSectionExpanded: boolean[];
  setIsBreakdownSectionExpanded: React.Dispatch<
    React.SetStateAction<boolean[]>
  >;
  feeSectionIndex: number;
}

export const CombinedInvoiceContext =
  createContext<CombinedInvoiceContextValue>({
    isBreakdownSectionExpanded: [],
    setIsBreakdownSectionExpanded: () => {},
    feeSectionIndex: 0,
  });
