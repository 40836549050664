import { DEFAULT_TABLE_PARAMS } from "pages/expenses/constants";
import { useExpenseLibraryStore } from "pages/expenses/store";
import { setTableQueryVariables } from "pages/expenses/utils";
import { useEffect } from "react";
import {
  useGetExpenseLibraryQuery,
  GetExpenseLibraryQueryVariables,
} from "types/generated/operations";

export const useExpenseLibrary = (params: typeof DEFAULT_TABLE_PARAMS) => {
  const {
    expenseLibrary,
    setExpenseLibrary,
    getAttachedExpenseUUIDs,
    getDetachedExpenseUUIDs,
  } = useExpenseLibraryStore();

  const { data: expenseLibraryData, loading: isExpenseLibraryLoading } =
    useGetExpenseLibraryQuery({
      variables: {
        ...setTableQueryVariables(params),
        attachedUuids: getAttachedExpenseUUIDs(),
        detachedUuids: getDetachedExpenseUUIDs(),
      } as GetExpenseLibraryQueryVariables,
    });

  useEffect(() => {
    if (
      !isExpenseLibraryLoading &&
      expenseLibraryData?.expenseManagement?.unattachedExpenses
    ) {
      const {
        page = 0,
        expenses = [],
        totalPages = 0,
        totalResults = 0,
      } = expenseLibraryData.expenseManagement.unattachedExpenses;

      setExpenseLibrary({
        library: {
          page,
          expenses,
          totalPages,
          totalResults,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpenseLibraryLoading, expenseLibraryData, setExpenseLibrary]);

  return { expenseLibrary, isExpenseLibraryLoading };
};
