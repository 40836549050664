import { ReactElement } from "react";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import Row from "../../../Row/Row";
import RequestChangesAction from "../../../RequestChangesAction/RequestChangesAction";
import { Card } from "@justworkshr/milo-core";
import { EorEmployeePersonalInfo } from "types/employer-of-record";
import BadgeTitle from "../BadgeTitle";

export default function FamilyCard(): ReactElement {
  const { profileInfo } = useProfileContextData();

  const personalInfo: EorEmployeePersonalInfo =
    profileInfo?.eorEmployeeProfile.personalInfo;

  const esChildrensBirthDates = personalInfo?.esChildrensBirthDates;

  return (
    <>
      <Card
        title={
          (<BadgeTitle title="Family" badgeText="Spain" />) as unknown as string
        }
        actions={[<RequestChangesAction />]}
      >
        <Row label="Children's birth dates" required={false}>
          {esChildrensBirthDates?.map((child, index) => (
            <div key={index}>
              Child {index + 1} birth date: {child}
            </div>
          ))}
        </Row>
      </Card>
    </>
  );
}
