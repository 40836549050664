import { ApolloError } from "@apollo/client";
import { getPmtIntegrationIsConnectionStatus } from "../../../integration-marketplace/utils";
import {
  PmtIntegrationPdpSolution,
  PmtIntegrationSetupIntegration,
  PmtIntegrationSetupSolution,
  PmtIntegrationWithMetadata,
} from "types/pmt-integrations";
import {
  useGetPmtIntegrationFrameworkPdpDataQuery,
  useGetPmtIntegrationFrameworkSetupDataQuery,
} from "types/generated/operations";

const ERROR_TYPE_ENUM = {
  SOLUTION: "Solution Error",
  INTEGRATION: "Integration Error",
  ERROR: "Error",
};

const INSTANCE_STATUS_ENUM = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};

const SOLUTION_STATUS_ENUM = {
  GENERAL_AVAILABILITY: "GENERAL_AVAILABILITY",
  LIMITED_RELEASE: "LIMITED_RELEASE",
  DISABLED: "DISABLED",
};

const getSolutionError = (
  solution: PmtIntegrationSetupSolution | PmtIntegrationPdpSolution,
  error: ApolloError | undefined,
  metadataSuccess: boolean | undefined
) => {
  const errors = [
    (error || !metadataSuccess) && ERROR_TYPE_ENUM.SOLUTION,
    !solution && ERROR_TYPE_ENUM.SOLUTION,
    solution?.status === SOLUTION_STATUS_ENUM.DISABLED &&
      ERROR_TYPE_ENUM.SOLUTION,
  ];

  return errors.filter((error) => error)[0];
};

const findLastActivityDate = (
  integration: PmtIntegrationSetupIntegration | undefined
) => {
  if (!integration || !integration.instancesByKey) return null;
  const { instancesByKey } = integration;
  const allInstancesSortedByDate = instancesByKey
    .map((instanceByKey) => instanceByKey.instances)
    .flat()
    .sort((a, b) => {
      const aDate = new Date(a.updatedAt);
      const bDate = new Date(b.updatedAt);
      return aDate.getTime() - bDate.getTime();
    });
  const mostRecentInstance =
    allInstancesSortedByDate[allInstancesSortedByDate.length - 1];
  return new Date(mostRecentInstance?.updatedAt || integration.updatedAt);
};

export const getIntegrationError = (
  integration: PmtIntegrationWithMetadata | undefined | null,
  error: ApolloError | undefined
) => {
  const disabledIntegration =
    integration?.data?.status === INSTANCE_STATUS_ENUM.DISABLED;
  const metadataSuccess = integration?.metadata.success;
  const status = integration?.metadata.status;

  const errors = [
    (error || !metadataSuccess) && status != 404 && ERROR_TYPE_ENUM.INTEGRATION,
    disabledIntegration && ERROR_TYPE_ENUM.INTEGRATION,
  ];
  return errors.filter((error) => error)[0];
};

export const parsePartnerIntegrationsData = ({
  data,
  loading,
  error,
  refetch,
}:
  | ReturnType<typeof useGetPmtIntegrationFrameworkPdpDataQuery>
  | ReturnType<typeof useGetPmtIntegrationFrameworkSetupDataQuery>) => {
  const { pmtIntegrationsFramework } = data || {};
  const authenticatedMember = (
    data as ReturnType<typeof useGetPmtIntegrationFrameworkPdpDataQuery>["data"]
  )?.authenticatedMember;
  const { integration, solution } = pmtIntegrationsFramework || {};
  const solutionError = getSolutionError(
    solution?.data,
    error,
    solution?.metadata.success
  );
  const integrationError = getIntegrationError(integration, error);
  const errorString = error ? ERROR_TYPE_ENUM.ERROR : null;
  const status = getPmtIntegrationIsConnectionStatus(integration?.data);

  return {
    companyUuid: authenticatedMember?.company?.uuid,
    companyName: authenticatedMember?.company?.name,
    memberPermissions: authenticatedMember?.permissions,
    companyAdmins: authenticatedMember?.company?.admins,
    integrationName: solution?.data?.copy?.title,
    connected: status === "CONNECTED",
    status,
    integration,
    solution,
    error: errorString || solutionError || integrationError,
    loading,
    instancesByKey: (integration?.data as PmtIntegrationSetupIntegration)
      ?.instancesByKey,
    refetch: refetch,
    lastActivityDate: findLastActivityDate(integration?.data),
  };
};
