import { ReactElement } from "react";
import styles from "./Loading.module.css";
import { Spinner } from "@justworkshr/milo-core";

const { loadingWrapper, loadingWrapperTall, loadingWrapperShort } = styles;

interface LoadingProps {
  orientation?: "normal" | "tall" | "short";
}

const wrapperClassFromOrientation = (orientation: string) => {
  switch (orientation) {
    case "tall":
      return `${loadingWrapper} ${loadingWrapperTall}`;
    case "short":
      return `${loadingWrapper} ${loadingWrapperShort}`;
    default:
      return loadingWrapper;
  }
};

export const Loading = ({
  orientation = "normal",
}: LoadingProps): ReactElement => {
  return (
    <div
      data-testid="loading"
      className={wrapperClassFromOrientation(orientation)}
    >
      <Spinner />
    </div>
  );
};
