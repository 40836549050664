import { ReactElement } from "react";
import styles from "../../InternationalSolutionsPage.module.css";
import ContractorsImage from "../../images/contractors-banner.svg?react";
import ContractorsPriceCard from "pages/international-solutions/components/PriceCard/ContractorsPriceCard";
import { ContractorsFAQ } from "pages/international-solutions/components/FAQ/ContractorsFAQ";

const Contractors = (): ReactElement => {
  const {
    container,
    column1,
    column2,
    divWrapper,
    mdFont,
    mdGap,
    lgGap,
    lgHead,
    listWrapper,
    xsHead,
    hr,
    infoText,
    price,
    listItems,
  } = styles;

  return (
    <>
      <div className={container}>
        <div className={column1}>
          <ContractorsImage />
          <div className={`${divWrapper} ${lgGap}`}>
            <div className={lgHead}>
              Manage and pay contractors around the world
            </div>
            <div className={mdFont}>
              Bring on great talent quickly and pay them easily—wherever they
              are. Embrace the potential of a global workforce and streamline
              your operations.
            </div>
            <div>
              <ul className={listWrapper}>
                <li>
                  <div className={xsHead}>
                    Build your dream team around the world
                  </div>
                  Grow your team strategically. Hire talent with the key skills
                  you need without being limited by location. With the ability
                  to add international contractors in over 40 countries, access
                  the talent you need to propel your business to new heights.
                </li>
                <li>
                  <div className={xsHead}>
                    Pay your whole team from one platform
                  </div>
                  Skip the hassle of managing multiple logins. Save time and
                  money by consolidating payment tools and paying your whole
                  team from a single platform. Keeping team members all on one
                  system helps you stay organized and streamlines HR workflows.
                </li>
                <li>
                  <div className={xsHead}>
                    Get insights into international contractor payments
                  </div>
                  View the exchange rate on each payment and pull payment
                  reports using preset or custom date ranges to better manage
                  expenses.
                </li>
              </ul>
            </div>
          </div>
          <hr className={hr}></hr>
          <div className={`${divWrapper} ${mdGap}`}>
            <div className={lgHead}>International Contractor Pricing</div>

            <div>
              <p>
                You’ll be charged only for the contractors you paid during the
                month.
              </p>
              <div className={infoText}>
                <span className={price}>$39</span>/month per contractor
              </div>
              <h3>Billing</h3>
              <div className={infoText}>
                At the end of the month, we’ll calculate your total
                international contractor fees and add them under Justworks fees
                in your invoice.
              </div>
            </div>
          </div>

          <div className={`${divWrapper} ${mdGap}`}>
            <div className={lgHead}>How it works</div>
            <div>
              <div>
                Paying international contractors is very similar to paying
                U.S.–based contractors.
              </div>
              <ul className={listItems}>
                <li>
                  Go to Manage {">"} Contractors to add a new International
                  contractor in the Invitation center.
                </li>
                <li>
                  Fill out all of their details and invite them to Justworks.
                </li>
                <li>
                  When you're ready to pay the new contractor, head to the
                  Payments Center and find International contractor pay.
                </li>
                <li>
                  When you select Send payment, you'll see all of the
                  international contractors that have been added to the team.
                </li>
                <li>
                  Select the contractors you want to pay. You'll see the
                  timeline for the transaction as well as the total amounts.
                  Remember, funds are always debited in USD but delivered to the
                  contractor in their local currency.
                </li>
                <li>Select Submit payments and you're done. </li>
              </ul>
            </div>
          </div>
          <div className={`${divWrapper} ${mdGap}`}>
            <ContractorsFAQ />
          </div>
        </div>
        <div className={column2}>
          <ContractorsPriceCard />
        </div>
      </div>
    </>
  );
};

export default Contractors;
