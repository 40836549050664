import SidebarResponsive from "app-frame/sidebar";
import styles from "./side-menu-drawer.module.css";
import Drawer from "app-frame/header/common/drawer";
import { SystemIcon } from "@justworkshr/milo-icons";
import { usePanelsControlContext } from "app-frame/header/context/panels-control-context-provider";
import { PanelsControlActionType } from "app-frame/header/context/panels-control-context-provider/types";

const { sideMenuContainer } = styles;

function SideMenuDrawer() {
  const { state, dispatch } = usePanelsControlContext();
  const { sideMenuDrawerShown } = state;

  return (
    <Drawer
      name="side-menu-drawer"
      open={sideMenuDrawerShown}
      onClose={() =>
        dispatch({ type: PanelsControlActionType.CLOSE_SIDE_MENU_DRAWER })
      }
      showOverlay
      placement="left"
    >
      <div className={sideMenuContainer}>
        <SidebarResponsive />
        <button
          onClick={() =>
            dispatch({ type: PanelsControlActionType.CLOSE_SIDE_MENU_DRAWER })
          }
        >
          <SystemIcon iconName="close" size="large" color="neutral-subtle" />
        </button>
      </div>
    </Drawer>
  );
}

export default SideMenuDrawer;
