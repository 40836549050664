import React, { ReactElement } from "react";
import Badge from "app-frame/common/Badge";
import styles from "./nav-badge.module.css";
import { NavBadgeTypeEnum } from "types/generated/operations";

const { badge, outStandingTaskNotification, new: newStyle } = styles;

interface NavBadgeParams {
  badgeText: string | number;
  badgeType: NavBadgeTypeEnum | "outstandingTaskNotification";
  isLongBadge?: boolean;
}

export default function NavBadge({
  badgeText,
  badgeType,
  isLongBadge,
}: NavBadgeParams): ReactElement {
  type ColorLookupIndex = {
    [index in NavBadgeParams["badgeType"]]: "red" | "purple";
  };

  let badgeStyle = "";
  if (badgeType === "outstandingTaskNotification") {
    badgeStyle = outStandingTaskNotification;
  } else if (badgeType === NavBadgeTypeEnum.New) {
    badgeStyle = newStyle;
  } else if (badgeType === NavBadgeTypeEnum.Callout) {
    badgeStyle = badge;
  }

  if (isLongBadge) {
    badgeStyle += ` ${styles.longBadge}`;
  }

  const colorLookup: ColorLookupIndex = {
    outstandingTaskNotification: "red",
    new: "purple",
    callout: "red",
  };

  return (
    <Badge
      label={badgeText}
      color={colorLookup[badgeType]}
      className={badgeStyle}
      mode={"inverted"}
    />
  );
}
