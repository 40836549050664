import RequestSeparationLayout from "../components/Layout";
import { useTranslation } from "react-i18next";
import RequestSeparationForm from "../components/Form";

const RequestSeparationBackground = () => {
  const { t } = useTranslation();

  return (
    <RequestSeparationLayout headerTitle={t("Request separation")}>
      <RequestSeparationForm />
    </RequestSeparationLayout>
  );
};

export default RequestSeparationBackground;
