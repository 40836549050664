import CreateStore from "../createStore";
import { GlobalReducer, INITIAL_GLOBAL_STORE } from "./reducer";

const {
  useStore: useExpensesStore,
  Provider: ExpensesProvider,
  StoreContext: ExpensesStoreContext,
  useSelectorStore: useExpensesSelectorStore,
} = CreateStore(INITIAL_GLOBAL_STORE, GlobalReducer);

export {
  useExpensesStore,
  ExpensesProvider,
  ExpensesStoreContext,
  useExpensesSelectorStore,
};
