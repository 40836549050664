import { FC, ReactNode } from "react";
import styles from "./metrics-prohibited-content.module.css";

interface MetricsCardRejectionContentProps {
  header: string;
  description: ReactNode;
}

const MetricsProhibitedContent: FC<MetricsCardRejectionContentProps> = ({
  header,
  description,
}) => {
  return (
    <div
      data-testid="metrics-prohibited-content"
      className={styles.metricsProhibitedcontent}
    >
      <h4>{header}</h4>
      <p>{description}</p>
    </div>
  );
};

export default MetricsProhibitedContent;
