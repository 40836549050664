import React from "react";
import { AuditLogItem } from "../AuditLogItem";
import { getDollarsFromCents } from "pages/expenses/utils";

interface PaymentReceivedProps {
  name: string;
  occurredAt: string;
  memberName: string;
  payment: { amount: number };
}

export const PaymentReceived: React.FC<PaymentReceivedProps> = ({
  name,
  payment,
  occurredAt,
  memberName,
}) => {
  return (
    <AuditLogItem
      author={memberName}
      occurredAt={occurredAt}
      previewText="Request received"
    >
      Payment for {name} in the amount of {getDollarsFromCents(payment.amount)}{" "}
      has been received.
    </AuditLogItem>
  );
};

export default PaymentReceived;
