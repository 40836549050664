export const CATEGORIES_CHILDREN = (
  <>
    <p>
      Set categories to organize and sort your team&#39;s expenses.{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://help.justworks.com/hc/en-us/articles/17024877999515-Expenses-Desktop-"
      >
        Learn more about categories
      </a>{" "}
      in the Help Center.
    </p>
    <br />
    <p>
      The inclusion of a particular category of expense is not intended to
      determine or imply the extent to which certain expenses within the
      category are non-taxable. Consult with your own tax/legal advisor to
      determine whether an expense is non-taxable.
    </p>
  </>
);

export const PROJECTS_CHILDREN = (
  <p>
    Create projects so employees can map expenses to a specific project. Project
    details will also be included in corresponding Expense reports.{" "}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://help.justworks.com/hc/en-us/articles/17024877999515-Expenses-Desktop-"
    >
      Learn more about projects
    </a>{" "}
    in the Help Center.
  </p>
);
