import styles from "./ExpenseLibraryPage.module.css";
import { Button } from "@justworkshr/milo-core";
import { ExpenseLibrary, Notice, NoticeTypes } from "pages/expenses/components";
import { prependBasePath } from "pages/expenses/constants";
import { useMultiAction } from "pages/expenses/hooks";
import { useNavigate } from "react-router-dom";
import { ExpenseLibraryExpense } from "types/Expenses";

const { ExpenseLibraryEmptyNoticeWrapper } = styles;

export const ExpenseLibraryPage = () => {
  const navigate = useNavigate();
  const multiActionProps = useMultiAction<ExpenseLibraryExpense>();

  const handleClick = () => {
    navigate(prependBasePath("EXPENSE_DETAILS"));
  };

  const emptyNotice = (
    <Notice type={NoticeTypes.INFO}>
      <div className={ExpenseLibraryEmptyNoticeWrapper}>
        Expenses that aren&apos;t attached to a reimbursement request live
        here–in the Expense Library. Need to create an expense now?
        <Button
          type="button"
          variant="outlined"
          onClick={handleClick}
          leftIcon="plus-circle"
          data-testid="create-expense-button"
        >
          Create expense
        </Button>
      </div>
    </Notice>
  );

  return (
    <ExpenseLibrary
      showMultiAction
      showExpenseDetailDrawer
      emptyNotice={emptyNotice}
      multiActionProps={multiActionProps}
    />
  );
};

export default ExpenseLibraryPage;
