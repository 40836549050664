import {
  CheckboxInput,
  FormField,
  Select,
  SelectOption,
  TextInput,
} from "@justworkshr/milo-form";
import { FormikErrors, useFormikContext } from "formik";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import styles from "../../ProfileInfo.module.css";
import { months } from "../../constants";

export const initialValues = {
  profileInfo: {
    firstName: "",
    lastName: "",
    preferredFirstName: "",
    middleName: "",
    pronouns: "",
    dateOfBirth: {
      year: "",
      month: "",
      day: "",
    },
    settings: {
      showDateOfBirth: false,
    },
  },
};

const { checkboxField, dateField, dateOfBirthContainer, inputField } = styles;

const NOW = new Date();

const isBetween = (num1: number, num2: number, value: number) =>
  value >= num1 && value <= num2;

export type TBaseFormFragmentSchema = {
  profileInfo: Yup.InferType<ReturnType<typeof schema>>;
};

export const schema = (t: TFunction) =>
  Yup.object({
    firstName: Yup.string().required(
      t("This field is required. Please enter your legal given name.")
    ),
    lastName: Yup.string().required(
      t("This field is required. Please enter your surname.")
    ),
    middleName: Yup.string().optional(),
    preferredFirstName: Yup.string().optional(),
    pronouns: Yup.string().optional(),
    dateOfBirth: Yup.object()
      .shape({
        year: Yup.string(),
        month: Yup.string(),
        day: Yup.string(),
      })
      .test("valid", t("Invalid entry. Please try again."), (date) => {
        const { year, month, day } = date;
        const nowYear = NOW.getUTCFullYear();

        // Return false if any of the fields are empty
        if (!year || !month || !day) {
          return false;
        }

        // Check if the individual date fields are valid
        if (!isBetween(1920, nowYear, parseInt(year))) return false;
        if (!isBetween(1, 12, parseInt(month))) return false;
        if (!isBetween(1, 31, parseInt(day))) return false;

        return true;
      })
      .test("legal working age", t("Must be at least 18 years old"), (date) => {
        const { year, month, day } = date;

        if (!year || !month || !day) {
          return true;
        }

        return (
          new Date(
            parseInt(year, 10) + 18,
            parseInt(month, 10) - 1,
            parseInt(day, 10)
          ) <= new Date()
        );
      }),
    settings: Yup.object().shape({
      showDateOfBirth: Yup.boolean(),
    }),
  });

export default function BaseFormFragment() {
  const { t } = useTranslation();
  const { touched, errors, handleBlur, handleChange, values, submitCount } =
    useFormikContext<TBaseFormFragmentSchema>();

  const MONTHS = months();

  const dateOfBirthErrors = (
    values: TBaseFormFragmentSchema,
    errors: FormikErrors<TBaseFormFragmentSchema>
  ): string => {
    const allFieldsFilled: boolean = Object.values(
      values?.profileInfo?.dateOfBirth
    ).every((value) => value);
    if (
      (allFieldsFilled || submitCount > 0) &&
      errors?.profileInfo?.dateOfBirth
    ) {
      return errors?.profileInfo?.dateOfBirth as string;
    }
    return "";
  };

  return (
    <>
      <div className={inputField}>
        <FormField
          error={
            touched?.profileInfo?.firstName
              ? errors?.profileInfo?.firstName
              : ""
          }
          label={t("Legal given name")}
          message={t(
            "This name was pulled from your employment contract with Justworks."
          )}
          required
        >
          <TextInput
            name="profileInfo.firstName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.profileInfo.firstName}
          />
        </FormField>
      </div>

      <div className={inputField}>
        <FormField
          error={
            touched?.profileInfo?.lastName ? errors?.profileInfo?.lastName : ""
          }
          label={t("Surname")}
          message={t(
            "This name was pulled from your employment contract with Justworks."
          )}
          required
        >
          <TextInput
            name="profileInfo.lastName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.profileInfo.lastName}
          />
        </FormField>
      </div>

      <div className={inputField}>
        <FormField
          label={t("Preferred name")}
          message={t(
            "This is the name you'll see in your company directory and in emails from us."
          )}
        >
          <TextInput
            name="profileInfo.preferredFirstName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.profileInfo.preferredFirstName}
          />
        </FormField>
      </div>

      <div className={inputField}>
        <FormField label={t("Middle name")}>
          <TextInput
            name="profileInfo.middleName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.profileInfo.middleName}
          />
        </FormField>
      </div>

      <div className={inputField}>
        <FormField
          label={t("Pronouns")}
          message={t(
            "This will be displayed on your public profile page and in your company org chart."
          )}
        >
          <TextInput
            name="profileInfo.pronouns"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={t("e.g. He/him, She/her, They/them")}
            value={values.profileInfo.pronouns}
          />
        </FormField>
      </div>

      <div className={inputField}>
        <FormField
          error={dateOfBirthErrors(values, errors)}
          label={t("Date of birth")}
          message={t(
            "This will be displayed on your public profile page and in your company org chart."
          )}
          required
        >
          <div className={dateOfBirthContainer}>
            <div className={dateField}>
              <label htmlFor="profileInfo.dateOfBirth.year">{t("Year")}</label>
              <TextInput
                id="profileInfo.dateOfBirth.year"
                name="profileInfo.dateOfBirth.year"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.profileInfo.dateOfBirth.year}
              />
            </div>

            <div className={dateField}>
              <label htmlFor="profileInfo.dateOfBirth.month">
                {t("Month")}
              </label>
              <Select
                id="profileInfo.dateOfBirth.month"
                name="profileInfo.dateOfBirth.month"
                onChange={handleChange}
                defaultValue={values.profileInfo.dateOfBirth.month}
                // We cannot pass a placeholder and a default value.
                //   So, if we initially have a value for month, we
                //   can't send a placeholder
                placeholder={
                  values.profileInfo.dateOfBirth.month?.length &&
                  values.profileInfo.dateOfBirth.month?.length > 0
                    ? undefined
                    : t("Select month")
                }
              >
                {MONTHS.map((month) => {
                  return (
                    <SelectOption key={month.value} value={month.value}>
                      {month.description}
                    </SelectOption>
                  );
                })}
              </Select>
            </div>

            <div className={dateField}>
              <label htmlFor="profileInfo.dateOfBirth.day">Day</label>
              <TextInput
                id="profileInfo.dateOfBirth.day"
                name="profileInfo.dateOfBirth.day"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.profileInfo.dateOfBirth.day}
              />
            </div>
          </div>
        </FormField>

        <div className={checkboxField}>
          <CheckboxInput
            checked={values.profileInfo.settings.showDateOfBirth}
            label="Don't show my birthday on my profile"
            name="profileInfo.settings.showDateOfBirth"
            onBlur={handleBlur}
            onChange={handleChange}
            value={
              values.profileInfo.settings.showDateOfBirth ? "true" : "false"
            }
          />
        </div>
      </div>
    </>
  );
}
