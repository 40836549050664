import React from "react";
import styles from "./Table.module.css";

const { TableWrapper, TableContainer } = styles;

interface TableProps {
  children: React.ReactNode;
}

export const Table: React.FC<TableProps> = React.memo(({ children }) => {
  return (
    <div className={TableWrapper}>
      <table className={TableContainer}>{children}</table>
    </div>
  );
});

export default Table;
