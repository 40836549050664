import { SystemIcon } from "@justworkshr/milo-icons";
import { SORT } from "../Table.constants";

interface SortIconProps {
  sortBy: string;
  sortKey: string;
  sortDirection: string;
}

export const SortIcon: React.FC<SortIconProps> = ({
  sortBy,
  sortKey,
  sortDirection,
}) => {
  if (sortBy === sortKey) {
    if (sortDirection === SORT.ASC) {
      return <SystemIcon iconName="arrow-up" className="arrow asc" />;
    } else if (sortDirection === SORT.DESC) {
      return <SystemIcon iconName="arrow-down" className="arrow desc" />;
    }
  }

  return <SystemIcon iconName="arrows-vertical" className="arrows" />;
};

export default SortIcon;
