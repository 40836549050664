import { ExpenseCategory } from "types/Expenses";

export const INITIAL_CATEGORIES: CategoriesStoreType = {
  activeCategory: null,
  categories: [],
  defaultCategories: [],
  customCategories: [],
};

export type CategoriesStoreType = {
  activeCategory: ExpenseCategory | null;
  categories: ExpenseCategory[];
  defaultCategories: ExpenseCategory[];
  customCategories: ExpenseCategory[];
};

export type SetCategoriesPayload = {
  categories: ExpenseCategory[];
};

export type SetActiveCategoryPayload = {
  activeCategory: ExpenseCategory | null;
};
