const PROJECT_CODE_FIELD = "projectCode";
const PROJECT_NAME_FIELD = "projectName";

export const DUPLICATE_PROJECT_NAME_ERROR = "That project name already exists.";
export const DUPLICATE_PROJECT_CODE_ERROR = "That project code already exists.";
export const PROJECT_NAME_DESCRIPTION =
  "Something your team can easily recognize and associate with their work.";
export const NO_PROJECTS_TEXT = (
  <>
    You haven't created any projects yet. <strong>Add a project</strong> to get
    started.
  </>
);
export const PROJECT_CHANGE_MESSAGE = `Changing a project code or project name, will update all past and future activity logs, expense reports, and request history details associated with it.`;
export const INITIAL_ADD_PROJECT_STATE = {
  [PROJECT_CODE_FIELD]: "",
  [PROJECT_NAME_FIELD]: "",
};
export const PROJECTS_CANCEL_MESSAGE = `Anything that you've added will not be saved.`;
export const PROJECTS_CANCEL_TITLE = `Are you sure you want to cancel?`;
