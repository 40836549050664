import { fetchClient } from "@justworkshr/fe-utility-kit";
import { useMutation } from "@tanstack/react-query";
import { buildClockworkWebPath } from "lib/resource-finder";
import { BankFormData } from "pages/company-settings/hooks/useBankForm";
import { useNavigate } from "react-router-dom";

export interface ProcessedBankResponseError {
  status: number;
  // not doing anything with the body yet
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any;
  message: string;
}

export interface ProcessedBankResponseSuccess {
  rail_type?: string;
}

const parseJSONResponse = async (response: Response) => {
  try {
    return await response.json();
  } catch (e) {
    return null;
  }
};

export const useSubmitMicrodepositTest = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (formData: BankFormData) => {
      const response = await fetchClient.post(
        buildClockworkWebPath("/company/bank_account/penny_test"),
        {
          body: JSON.stringify({
            account_number: formData.accountNumber,
            routing_number: formData.routingNumber,
          }),
        }
      );
      if (!response.ok) {
        const errorBody = await parseJSONResponse(response);

        throw {
          message: "Something went wrong",
          status: response.status,
          body: errorBody,
        } as ProcessedBankResponseError;
      }
      const json: ProcessedBankResponseSuccess = await response.json();
      return json;
    },
    onSuccess: (responseJson) => {
      navigate(
        `/company-settings/edit-bank-account/microdeposit/initiate/success?rail_type=${responseJson.rail_type}`
      );
    },
  });
};
