import { desktopHelpCenterLink } from "pages/expenses/constants";

export const adminPermissionsTaskCardProtipCopy = (
  <p>
    Be sure to include yourself or else you won’t be able to add any categories
    in the next step.{" "}
    <a target="_blank" rel="noopener noreferrer" href={desktopHelpCenterLink}>
      Learn more about permissions
    </a>{" "}
    in the Help Center.
  </p>
);

export const adminPermissionsTaskCardBodyCopy = (
  <p>
    In <strong>Manage expenses</strong>, select the admins who should be able to
    approve reimbursement requests and schedule payments. After you save
    changes, we’ll bring you back here so you can continue the set up.
  </p>
);
