/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo } from "react";
import { DocumentUploader } from "./components/DocumentUploader/DocumentUploader";
import { ExistingDocuments } from "./components/ExistingDocuments/ExistingDocuments";
import { DocumentListInterface } from "./helper/DocumentList";
import { useGetMemberSeparationDocuments } from "pages/team-management/hooks/useGetMemberSeparationDocuments/useGetMemberSeparationDocuments";
import { QuestionnaireContext } from "pages/team-management/contexts/QuestionnaireContextProvider";

interface DocumentInputProps {
  id: string;
  value: string[];
  documentList: DocumentListInterface;
  onChange: (event: React.ChangeEvent<HTMLInputElement> | number[]) => void;
}

export const DocumentInput: React.FC<DocumentInputProps> = ({
  id,
  value,
  documentList,
  onChange,
}) => {
  const existingDocumentIds = useMemo(() => {
    return value;
  }, []);

  const { incrementDocLoadingCounter, decrementDocLoadingCounter } =
    useContext(QuestionnaireContext);

  const { loading, error } = useGetMemberSeparationDocuments(
    existingDocumentIds,
    documentList
  );

  useEffect(() => {
    if (loading) {
      incrementDocLoadingCounter?.();
    } else {
      decrementDocLoadingCounter?.();
    }
  }, [loading]);

  useEffect(() => {
    const document_ids = documentList.getDocumentIds();
    onChange(document_ids);
  }, [documentList.fileDocumentMap, documentList.existingDocumentMap]);

  const removeDocument = (title: string) => {
    documentList.removeExistingDocumentMapping(title);
  };

  return (
    <div id={`${id}`}>
      <DocumentUploader documentList={documentList} />
      {!loading && !error && (
        <ExistingDocuments
          documentList={documentList}
          removeDocument={removeDocument}
        />
      )}
    </div>
  );
};
