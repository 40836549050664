import { Button } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import styles from "pages/company-settings/components/EditBankAccountReverseWirePage.module.css";
import ReverseWireAuthNotice from "pages/company-settings/components/ReverseWireAuthNotice";
import { useNavigate } from "react-router-dom";
import ReverseWireAuthSteps from "pages/company-settings/components/ReverseWireAuthSteps";
import ExpandableCard from "pages/company-settings/components/ExpandableCard";
import BankAccountPageHeader from "./BankAccountPageHeader";

const { page, content, noticeContainer, notice, footer, cardContent } = styles;

export default function EditBankAccountReverseWirePage() {
  const navigate = useNavigate();

  return (
    <div className={page}>
      <div>
        <BankAccountPageHeader />
      </div>
      <div className={content}>
        <div className={noticeContainer}>
          <div className={notice}>
            <ReverseWireAuthNotice />
          </div>
          <ExpandableCard title="How to keep your 2-day reverse wire in place">
            <div className={cardContent}>
              <ReverseWireAuthSteps />
            </div>
          </ExpandableCard>
        </div>

        <ActionFooter
          className={footer}
          actions={[
            <Button
              data-testid="submit-button"
              type="submit"
              onClick={() => navigate("../edit-bank-account")}
              key="save-button"
            >
              Confirm & continue
            </Button>,
          ]}
        />
      </div>
    </div>
  );
}
