import { FLAG_NAMES, flagIsEnabled } from "lib/environment";
import greenhouseLogo from "pages/pmt/integrations/images/integration-partner-logos/greenhouse-logo.svg";
import jazzHrLogo from "pages/pmt/integrations/images/integration-partner-logos/jazzhr-logo.svg";
import leverLogo from "pages/pmt/integrations/images/integration-partner-logos/lever-logo.svg";
import multipleAtsLogo from "pages/pmt/integrations/images/integration-partner-logos/multiple-ats-logo.png";
import { AtsPendingHireDetails, PmtIntegrationAlertsDetails } from "types";

type PartnerLogos = {
  partnerLogos: {
    [key: string]: string;
  };
};

export const pmtAlertTypes = {
  needsWarningOverlay: {
    pmtIntegrationAuthError: "pmt_integration_auth_error",
  },
};

export const integrationLogos: PartnerLogos = {
  partnerLogos: {
    ats: multipleAtsLogo,
    greenhouse: greenhouseLogo,
    jazzhr: jazzHrLogo,
    lever: leverLogo,
  },
};

export const createPendingHireNotification = (item: AtsPendingHireDetails) => {
  const partnerName = item.type.toLowerCase().split("_")[0];
  const photoUrl = integrationLogos.partnerLogos[partnerName];

  // Makeshift "member" object to pass logo url to avatar to render logos for pending hire alerts
  const avatar = {
    firstName: "",
    friendlyFirstName: "",
    name: "",
    lastName: partnerName,
    photoUrl: photoUrl,
    uuid: "",
    preferredName: partnerName,
  };

  return {
    alertNotificationProps: {
      key: item.url,
      message: item.message,
      url: item.url,
      member: avatar,
      priority: item.priority,
    },
  };
};

export const createPmtIntegrationAlertsNotification = (
  item: PmtIntegrationAlertsDetails
) => {
  const photoUrl = integrationLogos.partnerLogos[item.integrationName];

  // Makeshift "member" object to pass logo url to avatar to render logos
  const avatar = {
    firstName: "",
    friendlyFirstName: "",
    name: "",
    lastName: item.integrationName,
    photoUrl: photoUrl,
    uuid: Object.values(pmtAlertTypes.needsWarningOverlay).includes(item.type)
      ? "warning_overlay"
      : "",
    preferredName: item.integrationName,
  };

  return {
    alertNotificationProps: {
      key: item.url,
      message: item.message,
      url: item.url,
      member: avatar,
      priority: item.priority,
    },
  };
};

// NOTE: this relies on integration feature flag names having the format {INTEGRATION_NAME_UPPERCASE}_ENABLED
// if there is no flag set, this will return false - so the integration WILL appear in the marketplace
// this is done so that TMS BE can control the visibility of its own integrations in the marketplace (via launch darkly)
export const isPmtIntegrationDisabled = (
  name?: string,
  companyUuid?: string
) => {
  if (!companyUuid || !name) return false;
  const flagNameKey = `${name.toUpperCase()}_ENABLED`;
  const flagName = FLAG_NAMES[flagNameKey as keyof typeof FLAG_NAMES];
  const flagValue = flagIsEnabled(flagName, companyUuid);
  if (flagValue === false) {
    return true;
  }
  return false;
};
