import ImageCard from "pages/pmt/integrations/partner-setup-page/components/ImageCard/ImageCard";
import { Button } from "@justworkshr/milo-core";
import styles from "./LeverSetup.module.css";
import { StepperHelpers } from "pages/pmt/integrations/partner-setup-page/hooks/useStepper";
import { Link } from "react-router-dom";
import LeverSetupImage from "../../../images/setup-images/lever/step-four-setup.svg";
import Notice from "pages/pmt/integrations/partner-description-page/components/Notice/Notice";
import { inlineLinkWithIconFormatter } from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/constants";
import Divider from "pages/pmt/integrations/partner-description-page/components/Divider/Divider";
const { wrapper, finishButton, finishButtonText, stepFourList } = styles;

const LeverSetupStepFour: React.FC<StepperHelpers> = ({}) => {
  const SUPPORT_EMAIL = "support@justworks.com";

  return (
    <ImageCard header="Enable the webhook in Lever">
      <div className={wrapper}>
        <ol className={stepFourList}>
          <li>
            {inlineLinkWithIconFormatter(
              "Now that the webhook has been created, visit",
              "https://hire.lever.co/settings/integrations?tab=webhooks",
              "Lever’s webhooks page"
            )}
          </li>
        </ol>
        <Notice>
          Having trouble with the link above? In Lever, go to Settings {">"}{" "}
          Integrations and API {">"} Webhooks.
        </Notice>
        <ol className={stepFourList} start={2}>
          <li>
            Find the <strong>Candidate Hired</strong> webhook and turn it on.
          </li>
          <li>
            Select the ^ to expand the section and then{" "}
            <strong>Verify Connection.</strong> If the integration is working,
            the text will change to <strong>Verified.</strong>
          </li>
        </ol>
        <span>
          If the webhook has been successfully enabled and verified, it should
          look like this.
        </span>
        <img src={LeverSetupImage} />
        <span>
          If this doesn’t match what you’re seeing, try refreshing the page or
          you can email us at{" "}
          <a href={`mailto: ${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
        </span>
        <Divider size="sm" />
        <Button
          className={finishButton}
          as={Link}
          to={`/integrations/lever`}
          state={{ alert: "setupSuccess" }}
        >
          <span className={finishButtonText}>Finish setup</span>
        </Button>
      </div>
    </ImageCard>
  );
};

export default LeverSetupStepFour;
