import { AlertTypes, prependBasePath } from "pages/expenses/constants";
import { useFeedbackBannerStore, useRequestDetail } from "pages/expenses/store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateReimbursementRequestMutation } from "types/generated/operations";

export const useCreateReimbursementRequest = () => {
  const navigate = useNavigate();
  const { setFeedbackBanner } = useFeedbackBannerStore();
  const { resetRequestDetails } = useRequestDetail();

  const [
    createReimbursementRequest,
    {
      data: createReimbursementRequestData,
      loading: createReimbursementRequestLoading,
    },
  ] = useCreateReimbursementRequestMutation();

  useEffect(() => {
    if (
      !createReimbursementRequestLoading &&
      createReimbursementRequestData?.createReimbursementRequest?.success
    ) {
      setFeedbackBanner({
        type: AlertTypes.SUCCESS,
        show: true,
        message: `Your reimbursement request has been submitted. We’ll email you when it’s been approved & paid or if it needs to be updated.`,
      });

      resetRequestDetails();

      navigate(prependBasePath("HOME"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createReimbursementRequestLoading, createReimbursementRequestData]);

  return {
    createReimbursementRequest,
    createReimbursementRequestLoading,
  };
};
