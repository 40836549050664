import { Box } from "@justworkshr/milo-core";
import styles from "pages/company-settings/components/TermsAndConditionsCopy.module.css";
import { useGetCompanyDisplayQuery } from "types/generated/operations";

const { detailsHeader, copyContainer, copyList } = styles;

export default function TermsAndConditionsCopy() {
  let companyName = "";

  const { data, loading } = useGetCompanyDisplayQuery();
  if (loading || !data) {
    return <></>;
  }

  companyName = data.authenticatedMember.company.name;

  return (
    <Box>
      <h2 className={detailsHeader}>ACH and Account Authorization</h2>
      <div className={copyContainer}>
        <p>
          {companyName} (<b>"Customer"</b>) authorizes Justworks Employment
          Group LLC or any Justworks Affiliate(s) (collectively, “
          <b>Justworks</b>”), directly or through any authorized third-party
          service provider, to electronically debit the bank account or accounts
          (the “<b>Account</b>
          ”) provided by Customer to Justworks. If necessary, Customer further
          authorizes Justworks to electronically credit such Account to correct
          erroneous debits. Customer agrees that Company may retain and use any
          Account information provided by Customer for purposes of processing
          debit transactions under the Customer Service Agreement and Justworks
          Terms and Conditions (together, the “<b>Agreement</b>”) and this ACH
          and Account Information Authorization (the “<b>Authorization</b>”), or
          as otherwise instructed or directed by Customer from time to time.
        </p>
        <p>
          Customer further authorizes Justworks to debit its Account from time
          to time for any and all amounts owed, due, or payable under the
          Agreement, including any amounts indicated on an invoice, notice, or
          otherwise, from Justworks to Customer. Customer acknowledges and
          agrees that each debit to an Account will be made by Justworks in
          accordance with the obligations and requirements set forth in the
          Agreement. Customer also agrees to be bound by any applicable
          requirements of the Nacha Operating Rules and Guidelines related to
          such debit transactions, which rules are incorporated herein by
          reference. Any reversal, refund, credit, or other return or
          cancellation of a debit transaction processed by Justworks under this
          Authorization, as well as Customer’s right to dispute or decline any
          such debit, is governed solely by the terms of the Agreement.
        </p>
        <p>
          Customer further authorizes Justworks to verify the Account
          information provided by Customer, including through a third-party
          service provider, and share Customer information with any third-party
          service providers as may be necessary to perform any activities
          contemplated by this Authorization.
        </p>
        <p>
          This Authorization is effective as of the date agreed to by Customer
          and shall remain in full force and effect for so long as any amounts
          owed and due from Customer under the Agreement remain outstanding,
          unless otherwise agreed to in writing by Justworks.
        </p>
        <p>By agreeing to this Authorization, I represent and warrant that:</p>
        <ol className={copyList}>
          <li>I am authorized to act on behalf of Customer.</li>
          <li>
            All transactions initiated under this Authorization comply with
            applicable law.
          </li>
          <li>
            The Account provided by Customer is used primarily for business and
            commercial purposes.
          </li>
        </ol>
        <p>
          Capitalized terms used in this Authorization but not defined herein
          are given the meanings set forth in the Agreement.
        </p>
      </div>
    </Box>
  );
}
