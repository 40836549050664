import { useFormikContext } from "formik";
import { FormikFormField } from "lib/formik/FormikFormField";
import {
  RadioButton,
  RadioButtonGroup,
} from "pages/team-management/components";
import { AdditionalPaymentsFormValues } from "../../../../SeparationPay.formUtils";

import styles from "../../../../SeparationPay.module.css";
import { PAYMENT_DETAILS_DEDUCTION_SETTINGS_OPTIONS } from "./DeductionsSettingInputRow.constants";
import { PAYMENT_DETAILS_FORM_COPY } from "../PaymentDetailsForm.constants";

const { RowContainer } = styles;

export const DeductionsSettingInputRow = () => {
  const { setFieldValue } = useFormikContext<AdditionalPaymentsFormValues>();

  return (
    <div className={RowContainer}>
      <FormikFormField
        name="deductions_setting"
        label={PAYMENT_DETAILS_FORM_COPY.deductions_setting}
        message={PAYMENT_DETAILS_FORM_COPY.deductions_setting_message}
        required={true}
      >
        <RadioButtonGroup
          name="deductions_setting"
          data-testid="deductions_setting"
          onChange={(option) => {
            setFieldValue("deductions_setting", option);
          }}
          variant="framed"
          orientation="horizontal"
        >
          {Object.entries(PAYMENT_DETAILS_DEDUCTION_SETTINGS_OPTIONS).map(
            ([key, value]) => {
              return (
                <RadioButton
                  data-testid={`deductions_setting__${key}`}
                  key={key}
                  value={key}
                  label={value.label}
                  description={value.description}
                />
              );
            }
          )}
        </RadioButtonGroup>
      </FormikFormField>
    </div>
  );
};
