import { AlertTypes } from "../../../constants";

export const INITIAL_FEEDBACK_BANNER: FeedbackBannerStoreType = {
  show: false,
  message: null,
  type: AlertTypes.SUCCESS,
};

export type FeedbackBannerStoreType = {
  show: boolean;
  message: string | null;
  type: AlertTypes;
};
