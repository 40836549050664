import { PaymentTypeTypes } from "../../AdditionalPaymentsPage.types";

export const isBonusPayment = (type: string) => type === PaymentTypeTypes.BONUS;
export const isSeverancePayment = (type: string) =>
  type === PaymentTypeTypes.SEVERANCE;
export const isSupplementalPayment = (type: string) =>
  type === PaymentTypeTypes.SUPPLEMENTAL;
export const isCommissionPayment = (type: string) =>
  type === PaymentTypeTypes.COMMISSION;

export const renderPaymentMethodInput = (type: string) =>
  isBonusPayment(type) || isSupplementalPayment(type);
export const renderApplicableWorkPeriodInput = (type: string) =>
  isBonusPayment(type) ||
  isCommissionPayment(type) ||
  isSupplementalPayment(type);
export const renderTaxMethodInput = (type: string) =>
  isBonusPayment(type) || isCommissionPayment(type);
export const renderTaxesAndDeductionsSection = (type: string) =>
  isBonusPayment(type) ||
  isCommissionPayment(type) ||
  isSupplementalPayment(type);
