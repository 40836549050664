import { FC } from "react";
import { useLocation } from "react-router-dom";
import { FULL_PATHS, VISIER_SRC_SUFFIXES } from "../../constants";
import DataExportsThumbnailImage from "../../images/data-exports-thumb-640x360.jpg";
import FullscreenWrapper from "../fullscreen-wrapper";
import VisierAppIframe from "../visier-app-iframe";

export const DataExportsPage: FC = () => {
  const location = useLocation();

  const drawerContent = [
    {
      title: "Data Exports 101: Locate Data with Ease",
      description:
        "Analyze data and identify cause-and-effect relationships using advanced Insights features.",
      learnMoreHref:
        "https://help.justworks.com/hc/en-us/articles/24707714314651",
      imageSrc: DataExportsThumbnailImage,
    },
    {
      title: "Overview",
      description:
        "What is People Analytics? A brief overview of Justworks’ new insights and reporting tool.",
      learnMoreHref:
        "https://help.justworks.com/hc/en-us/articles/22463671611931",
      imageSrc: null,
    },
    {
      title: "Data Exports",
      description:
        "Learn how to create custom reports from scratch, save templates for future use, etc.",
      learnMoreHref:
        "https://help.justworks.com/hc/en-us/articles/22464441168667",
      imageSrc: null,
    },
    {
      title: "Filters",
      description:
        "Use filters to narrow down your searches and quickly find the data you need.",
      learnMoreHref:
        "https://help.justworks.com/hc/en-us/articles/22464519982107",
      imageSrc: null,
    },
  ];

  return (
    <div data-testid="data-exports-page">
      <FullscreenWrapper
        backCopy="Exit"
        backPath={location?.state?.backPath || FULL_PATHS.REPORTING}
        drawerContent={drawerContent}
      >
        <VisierAppIframe visierSrcSuffix={VISIER_SRC_SUFFIXES.DATA_EXPORTS} />
      </FullscreenWrapper>
    </div>
  );
};

export default DataExportsPage;
