const esSexOptions = [
  { value: "male", description: "Male" },
  { value: "female", description: "Female" },
];

const esMaritalStatusOptions = [
  {
    value: "single",
    description: "Single",
  },
  {
    value: "married",
    description: "Married",
  },
  {
    value: "civilPartnership",
    description: "Civil Partnership",
  },
];

const esEducationalQualificationOptions = [
  {
    value: "noFormalEducation",
    description: "No Formal Education",
  },
  {
    value: "primaryEducation",
    description: "Primary Education",
  },
  {
    value: "secondaryEducation",
    description: "Secondary Education",
  },
  {
    value: "bachelorsDegree",
    description: "Bachelor's Degree",
  },
  {
    value: "mastersDegree",
    description: "Masters Degree",
  },
  {
    value: "doctorateOrHigher",
    description: "Doctorate or higher",
  },
];

// Reference:
// https://docs.trellix.com/bundle/data-loss-prevention-11.10.x-classification-definitions-reference-guide/page/GUID-AACF0E3A-3089-4578-83EB-3E6C3DB41684.html
const esSsnRegex = /^(?:\b[0-6][0-9][ -]?[0-9]{8}[ -]?[0-9]{2}\b)$/;

export {
  esSexOptions,
  esMaritalStatusOptions,
  esEducationalQualificationOptions,
  esSsnRegex,
};
