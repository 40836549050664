import { TooltipIcon } from "../TooltipIcon";
import styles from "./AccessIcon.module.css";
import tableStyles from "../Table/Table.module.css";
import { useContext } from "react";
import { InternationalContractorMemberContext } from "../../../context/InternationalContractorMemberContext";
import { getMemberPreferredName } from "../../../utils";

export default function AccessIcon() {
  const { member } = useContext(InternationalContractorMemberContext);

  const memberName = getMemberPreferredName(member);

  return (
    <div className={`${tableStyles.iconRight} ${styles.accessIconContainer}`}>
      <TooltipIcon
        iconClassName={styles.accessIcon}
        iconColor="disabled"
        iconName="eye-slash"
        content={
          <p className={styles.accessTooltipContent}>
            This document is not viewable by {memberName}. Only admins with
            document center permissions can.
          </p>
        }
      />
    </div>
  );
}
