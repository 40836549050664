import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { FLAG_NAMES, flagIsEnabled } from "lib/environment";
import Backend from "i18next-http-backend";

export const initializeI18n = () =>
  i18n
    .use(initReactI18next)
    .use(Backend)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: "en",
      debug: flagIsEnabled(FLAG_NAMES.ENABLE_I18N_DEBUG_MODE),
      returnEmptyString: false,
      missingInterpolationHandler: (key: string) => key,
      missingKeyNoValueFallbackToKey: true,
      initImmediate: true,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: {
        loadPath: (lng: string) => {
          return `/static/locales/${lng}.json`;
        },
      },
    });

export default i18n;
