import { ReactElement } from "react";
import { Card } from "../../../Card";
import styles from "../../TimeOff.module.css";
import ExternalLink from "../../../ExternalLink/ExternalLink";
import { DisplayIcon } from "@justworkshr/milo-icons";

export default function WorkingOnAHoliday(): ReactElement {
  return (
    <>
      <Card
        title="Working on a Holiday"
        cardIcon={<DisplayIcon iconName="palm-tree" />}
      >
        <div className={styles.cardBodyContent}>
          Record hours worked during a holiday.
          <ExternalLink
            title="Log hours"
            link="https://justworkshr.formstack.com/forms/working_on_holiday"
          />
        </div>
      </Card>
    </>
  );
}
