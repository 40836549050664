import {
  CategoryActions,
  CategoryActionTypes,
  ProjectsActions,
  ProjectsActionTypes,
  SettingsConfigurationActions,
  SettingsConfigurationActionTypes,
} from "./actions";
import {
  INITIAL_PROJECTS,
  INITIAL_CATEGORIES,
  INITIAL_SETTINGS_CONFIGURATION,
} from "./constants";
import { parseProject } from "./utils/projects.utils";

export const INITIAL_SETTINGS_STORE = {
  Projects: INITIAL_PROJECTS,
  Categories: INITIAL_CATEGORIES,
  SettingsConfiguration: INITIAL_SETTINGS_CONFIGURATION,
};

export type SettingsStore = typeof INITIAL_SETTINGS_STORE;

export type SettingsAction =
  | CategoryActions
  | ProjectsActions
  | SettingsConfigurationActions;

export const SettingsReducer = (
  store: SettingsStore = INITIAL_SETTINGS_STORE,
  action: SettingsAction
): SettingsStore => {
  switch (action.type) {
    case CategoryActionTypes.SET_CATEGORIES:
      return {
        ...store,
        Categories: {
          ...store.Categories,
          categories: action.payload.categories,
          defaultCategories: action.payload.categories.filter(
            (category) => !category.custom
          ),
          customCategories: action.payload.categories.filter(
            (category) => category.custom
          ),
        },
      };

    case CategoryActionTypes.SET_ACTIVE_CATEGORY:
      return {
        ...store,
        Categories: {
          ...store.Categories,
          activeCategory: action.payload.activeCategory,
        },
      };
    case SettingsConfigurationActionTypes.SET_SETTINGS_CONFIGURATION:
      return {
        ...store,
        SettingsConfiguration: {
          ...store.SettingsConfiguration,
          ...action.payload,
        },
      };

    case ProjectsActionTypes.SET_PROJECTS:
      return {
        ...store,
        Projects: {
          ...store.Projects,
          projects: action.payload.projects.map(parseProject),
        },
      };

    case ProjectsActionTypes.SET_ACTIVE_PROJECT:
      return {
        ...store,
        Projects: {
          ...store.Projects,
          activeProject: action.payload.activeProject
            ? parseProject(action.payload.activeProject)
            : null,
        },
      };

    default:
      return store;
  }
};
