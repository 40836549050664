import {
  COMPLETED,
  CardStatus,
  NOT_STARTED_OPTIONAL,
  NOT_STARTED_REQUIRED,
} from "pages/expenses/components";
import { useSettingsConfigurationStore } from "pages/expenses/store";
import {
  GetExpensesSettingsQuery,
  useGetExpensesSettingsQuery,
  useCreateExpensePolicyMutation,
} from "types/generated/operations";

export const useExpensesSettings = () => {
  const { setSettingsConfiguration } = useSettingsConfigurationStore();

  const [createExpensePolicy] = useCreateExpensePolicyMutation();

  const { error, loading, data, refetch } = useGetExpensesSettingsQuery({
    onCompleted(expensesSettingsData) {
      if (!expensesSettingsData?.expenseManagement?.policy) {
        createExpensePolicy();
      }

      if (
        expensesSettingsData?.expenseManagement?.policy?.enabled ||
        (expensesSettingsData?.expenseManagement?.policy?.categoriesReviewed &&
          expensesSettingsData?.expenseManagement?.expenseAdmins?.length > 0)
      ) {
        setSettingsConfiguration({ initialSetupComplete: true });
      }
    },
  });

  const policy = data?.expenseManagement?.policy;
  const managers = data?.expenseManagement?.managers || [];
  const expenseAdmins = data?.expenseManagement?.expenseAdmins || [];

  const determineSetupStatus = (
    policyData: GetExpensesSettingsQuery["expenseManagement"]["policy"],
    managersData: GetExpensesSettingsQuery["expenseManagement"]["managers"],
    adminsData: GetExpensesSettingsQuery["expenseManagement"]["expenseAdmins"]
  ): {
    adminPermissions: CardStatus;
    managerPermissions: CardStatus;
    categories: CardStatus;
  } => {
    const status = {
      adminPermissions: NOT_STARTED_REQUIRED as CardStatus,
      managerPermissions: NOT_STARTED_OPTIONAL as CardStatus,
      categories: NOT_STARTED_REQUIRED as CardStatus,
    };

    if (adminsData.length === 0) {
      return status;
    } else if (!policyData?.categoriesReviewed) {
      if (managersData.length === 0) {
        status.adminPermissions = COMPLETED;
        status.managerPermissions = NOT_STARTED_OPTIONAL;
        status.categories = NOT_STARTED_REQUIRED;
      } else if (managersData.length > 0) {
        status.adminPermissions = COMPLETED;
        status.managerPermissions = COMPLETED;
        status.categories = NOT_STARTED_REQUIRED;
      }
    }

    return status;
  };

  return {
    error,
    refetchExpensesSettings: refetch,
    isExpensesSettingsLoading: loading,
    data: {
      managers,
      expenseAdmins,
      statuses: determineSetupStatus(policy, managers, expenseAdmins),
      currentMember: data?.currentMember,
      projects: data?.expenseManagement?.projects || [],
      categories: data?.expenseManagement?.categories || [],
      reportsAdmins: data?.expenseManagement?.reportsAdmins || [],
    },
  };
};
