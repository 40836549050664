import { Button, Dialog as MiloDialog } from "@justworkshr/milo-core";
import { ReactElement } from "react";
import styles from "./DeleteDocumentDialog.module.css";
import { DialogHeader } from "../../DialogHeader";
import { useDeleteInternationalDocumentMutation } from "types/generated/operations";
import { InternationalDocument } from "types";
import { GENERIC_ERROR_MESSAGE } from "../constants";

interface DialogProps {
  documentToDelete: InternationalDocument;
  error: string;
  close: () => void;
  confirm: () => void;
  loading: boolean;
}

export function Dialog({
  documentToDelete,
  error,
  close,
  confirm,
  loading,
}: DialogProps) {
  return (
    <MiloDialog
      isOpen
      onClose={close}
      showCloseButton={false}
      primaryButton={
        <Button
          onClick={confirm}
          variant="filled"
          color="destructive"
          className="deleteDocumentButton"
          loading={loading}
        >
          Delete file
        </Button>
      }
      secondaryButton={
        <Button onClick={close} variant="ghost">
          Cancel
        </Button>
      }
    >
      <DialogHeader
        onClose={close}
        alertError={error}
        title="Delete document?"
      />
      <div className={styles.content}>
        <p className={styles.description}>
          You’re about to permanently delete this file. A deleted file will be
          removed from Justworks and anyone who has access to it.
        </p>
        <p>
          Are you sure you want to delete{" "}
          <strong>{documentToDelete.name}</strong>?
        </p>
      </div>
    </MiloDialog>
  );
}

interface DeleteDocumentDialogProps {
  documentToDelete: InternationalDocument;
  onComplete: () => void;
  close: () => void;
}

export default function DeleteDocumentDialog({
  documentToDelete,
  onComplete,
  close,
}: DeleteDocumentDialogProps): ReactElement {
  const [deleteInternationalDocumentMutation, { loading, error }] =
    useDeleteInternationalDocumentMutation();

  const handleSuccess = () => {
    onComplete();
    close();
  };

  const confirm = async () => {
    await deleteInternationalDocumentMutation({
      variables: {
        documentId: documentToDelete?.id || "",
      },
    });
    if (!error) {
      handleSuccess();
    }
  };

  return (
    <div className={styles.container}>
      <Dialog
        documentToDelete={documentToDelete}
        error={error && error.message ? GENERIC_ERROR_MESSAGE : ""}
        close={close}
        confirm={confirm}
        loading={loading}
      />
    </div>
  );
}
