import { ExpenseProject } from "types/Expenses";

export const INITIAL_PROJECTS: ProjectsStoreType = {
  projects: [],
  activeProject: null,
};

export type ProjectsStoreType = {
  projects: ExpenseProject[];
  activeProject: ExpenseProject | null;
};

export type SetProjectsPayload = {
  projects: ExpenseProject[];
};

export type SetActiveProjectPayload = {
  activeProject: ExpenseProject | null;
};
