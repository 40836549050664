import React, { createContext, ReactNode, useState, useEffect } from "react";
import { Member } from "../types";
import { useGetSeparatingMember } from "../hooks/useGetSeparatingMember";
import { useParams, useLocation } from "react-router-dom";
import {
  AdditionalPaymentsFormValues,
  PaymentDetailsFormValues,
} from "../routes/SeparationFlow/SeparationPay/SeparationPay.formUtils";
import { handleMemberError, handleTerminatedMember } from "../utils/utils";
import { useGetAdminPermissions } from "../hooks/useGetAdminPermissions";

export interface SeparationFlowContextValue {
  isLoading: boolean;
  member?: Member | undefined | null;
  additionalPaymentsFormValues?: {
    [key: number]: AdditionalPaymentsFormValues;
  };
  setAdditionalPaymentsFormValues?: (additionalPaymentsFormValues: {
    [key: number]: AdditionalPaymentsFormValues;
  }) => void;

  paymentDetailsFormValues?: {
    [key: number]: PaymentDetailsFormValues;
  };
  setPaymentDetailsFormValues?: (paymentDetailsFormValues: {
    [key: number]: PaymentDetailsFormValues;
  }) => void;

  hasAdditionalPayments?: boolean;
  setHasAdditionalPayments?: (value: boolean) => void;

  isKnownAmount?: boolean;
  setIsKnownAmount?: (value: boolean) => void;

  showGlobalGenericError?: boolean;
  setShowGlobalGenericError?: (value: boolean) => void;
  canMakePayments?: boolean;
}

interface SeparationFlowContextProviderProps {
  children: ReactNode;
  initialAdditionalPaymentsFormValues?: {
    [key: number]: AdditionalPaymentsFormValues;
  };
  initialPaymentDetailsFormValues?: { [key: number]: PaymentDetailsFormValues };
}

const SeparationFlowContext = createContext<SeparationFlowContextValue>({
  isLoading: true,
  member: {
    friendlyName: "",
    effectiveDate: "",
    addressState: "",
    uuid: "",
    isTerminated: false,
    memberType: "",
  },
  showGlobalGenericError: false,
});

const SeparationFlowContextProvider = ({
  children,
  initialAdditionalPaymentsFormValues = {},
  initialPaymentDetailsFormValues = {},
}: SeparationFlowContextProviderProps) => {
  const { memberUuid } = useParams();
  const location = useLocation();

  const { member, error: memberError } = useGetSeparatingMember(memberUuid);
  const { canMakePayments } = useGetAdminPermissions();

  const [hasAdditionalPayments, setHasAdditionalPayments] =
    useState<boolean>(true);
  const [isKnownAmount, setIsKnownAmount] = useState<boolean>(true);
  const [additionalPaymentsFormValues, setAdditionalPaymentsFormValues] =
    useState<{ [key: number]: AdditionalPaymentsFormValues }>(
      initialAdditionalPaymentsFormValues
    );
  const [paymentDetailsFormValues, setPaymentDetailsFormValues] = useState<{
    [key: number]: PaymentDetailsFormValues;
  }>(initialPaymentDetailsFormValues);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showGlobalGenericError, setShowGlobalGenericError] =
    useState<boolean>(false);

  useEffect(() => {
    setShowGlobalGenericError(false);
  }, [location.pathname]);

  useEffect(() => {
    setIsLoading(!member?.friendlyName || canMakePayments === undefined);
  }, [member, canMakePayments]);

  if (memberError) {
    handleMemberError(memberError, memberUuid);
    return null;
  }

  if (member?.isTerminated) {
    handleTerminatedMember(memberUuid);
    return null;
  }

  return (
    <SeparationFlowContext.Provider
      value={{
        isLoading,
        member,
        canMakePayments,
        hasAdditionalPayments,
        setHasAdditionalPayments,
        isKnownAmount,
        setIsKnownAmount,
        additionalPaymentsFormValues,
        setAdditionalPaymentsFormValues,
        paymentDetailsFormValues,
        setPaymentDetailsFormValues,
        showGlobalGenericError,
        setShowGlobalGenericError,
      }}
    >
      {children}
    </SeparationFlowContext.Provider>
  );
};

export { SeparationFlowContext, SeparationFlowContextProvider };
