import { ReactElement, useEffect } from "react";
import { useFormikContext } from "formik";
import { InitialIntakeFormValues } from "../../InitialIntakeForm.types";
import { FormikFormField } from "../../../../../../../../../../lib/formik/FormikFormField";
import {
  RadioButton,
  RadioButtonGroup,
} from "../../../../../../../../components";
import { TERMINATION_TYPE_OPTIONS } from "../../InitialIntakeForm.constants";

export const TerminationTypeField: () => ReactElement = () => {
  const { values, dirty, touched, errors, setFieldTouched } =
    useFormikContext<InitialIntakeFormValues>();

  useEffect(() => {
    if (dirty && values?.termination_type?.trim().length) {
      setFieldTouched("termination_type", true);
    }
  }, [dirty, values?.termination_type, setFieldTouched]);

  return (
    <div>
      <FormikFormField label="Separation type" name="termination_type" required>
        <RadioButtonGroup
          name="termination_type"
          value={values.termination_type}
          invalid={!!(touched.termination_type && !!errors.termination_type)}
          variant="framed"
          orientation="horizontal"
        >
          {Object.entries(TERMINATION_TYPE_OPTIONS).map(
            ([key, { label, description }]) => {
              return (
                <RadioButton
                  key={key}
                  value={key}
                  label={label}
                  description={description}
                  data-testid={`termination_type_${key}`}
                />
              );
            }
          )}
        </RadioButtonGroup>
      </FormikFormField>
    </div>
  );
};
