import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";

export const useRestoreOriginalUri = () => {
  const navigate = useNavigate();
  return useCallback(
    async (_oktaAuth: OktaAuth, originalUri: string) => {
      const destinationUri = originalUri.includes("/cf")
        ? originalUri.split("/cf")[1]
        : originalUri;
      navigate(toRelativeUrl(destinationUri, window.location.origin));
    },
    [navigate]
  );
};
