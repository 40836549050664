import { CATEGORIES_FIELD_NAMES } from "pages/expenses/constants";
import { ExpenseCategory } from "types/Expenses";

export const formatCategories = (categories: ExpenseCategory[]) =>
  categories.map(
    (category) =>
      CATEGORIES_FIELD_NAMES.reduce((obj, key) => {
        const categoryKey = key as keyof ExpenseCategory;

        return { ...obj, [key]: category[categoryKey] };
      }, {}) as Record<keyof ExpenseCategory, unknown>
  );
