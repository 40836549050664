import { FormField } from "@justworkshr/milo-form";
import styles from "../PaymentDetailsRow/PaymentDetailsRow.module.css";
import {
  DatePicker,
  handleInvalidDate,
} from "pages/employer-of-record/components";
import { FormikErrors, FormikTouched, useFormikContext } from "formik";
import {
  AdditionalPayFormData,
  FormPayloadType,
  PaymentDetailsType,
} from "../../../Setup/types";
import { EorMembersType } from "pages/employer-of-record/payments/types";
import { isWithinEmploymentDates } from "pages/employer-of-record/payments/utils";

type Props = {
  index: number;
  member: EorMembersType;
  errorRow: FormikErrors<PaymentDetailsType> | null;
  touchedRow: FormikTouched<PaymentDetailsType> | null;
  getUpdatedMemberDetail: (
    name: string,
    value: string,
    index: number
  ) => PaymentDetailsType[];
};

const DateRangePicker = ({
  index,
  errorRow,
  touchedRow,
  member,
  getUpdatedMemberDetail,
}: Props) => {
  const { values, handleBlur, setFieldValue } =
    useFormikContext<AdditionalPayFormData<FormPayloadType>>();

  const isValidDateHandler = (date: string) => {
    const startDate = member.workStartDate;
    const endDate = member.workEndDate;
    return isWithinEmploymentDates(date, startDate, endDate);
  };

  return (
    <>
      <td className={`${styles.td} ${styles.tdForm}  ${styles.noLabelWrapper}`}>
        <div className={styles.pickerWidth}>
          <FormField
            aria-labelledby="_"
            required
            name=""
            error={
              errorRow?.workPeriodStart && touchedRow?.workPeriodStart
                ? errorRow?.workPeriodStart
                : ""
            }
          >
            <DatePicker
              id={`startDate_${index}`}
              name={`paymentDetails[${index}].workPeriodStart`}
              value={values.paymentDetails[index]?.workPeriodStart}
              onBlur={handleBlur}
              className={`${
                errorRow?.workPeriodStart && touchedRow?.workPeriodStart
                  ? styles.error
                  : ""
              }`}
              placeholder="MM/DD/YY"
              onChange={(startDate: string) => {
                const date = handleInvalidDate(startDate);
                setFieldValue(
                  `paymentDetails`,
                  getUpdatedMemberDetail("workPeriodStart", date, index)
                );
              }}
              isValidDate={isValidDateHandler}
            />
          </FormField>
        </div>
      </td>
      <td className={`${styles.td} ${styles.tdForm} `}>
        <FormField
          aria-labelledby="_"
          required
          name=""
          error={
            errorRow?.workPeriodEnd && touchedRow?.workPeriodEnd
              ? errorRow?.workPeriodEnd
              : ""
          }
        >
          <DatePicker
            id={`endDate_${index}`}
            name={`paymentDetails[${index}].workPeriodEnd`}
            onBlur={handleBlur}
            className={`${
              errorRow?.workPeriodEnd && touchedRow?.workPeriodEnd
                ? styles.error
                : ""
            }`}
            value={values.paymentDetails[index]?.workPeriodEnd}
            placeholder="MM/DD/YY"
            onChange={(endDate: string) => {
              const date = handleInvalidDate(endDate);
              setFieldValue(
                `paymentDetails`,
                getUpdatedMemberDetail("workPeriodEnd", date, index)
              );
            }}
            isValidDate={isValidDateHandler}
          />
        </FormField>
      </td>
    </>
  );
};

export default DateRangePicker;
