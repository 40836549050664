import { FeedbackDialogStoreType } from "../constants";

export enum FeedbackDialogActionTypes {
  SET_FEEDBACKDIALOG = "SET_FEEDBACKDIALOG",
}

export type FeedbackDialogActions = {
  type: FeedbackDialogActionTypes;
  payload: FeedbackDialogStoreType;
};
