import benefitsClasses from "pages/benefits/benefits.module.css";
import { UnorderedList, UnorderedListItem } from "@justworkshr/milo-core";
import { Divider } from "@justworkshr/milo-core";

const EmpowerTab = () => {
  return (
    <div>
      <h2 className={benefitsClasses.benefitsHeader}>Who is Empower?</h2>

      <p className={benefitsClasses.benefitsParagraph}>
        Empower is an award-winning provider with extensive experience with PEOs
        like Justworks. They're known for their advanced technology, efficient
        tools, and customer service. They are committed to providing companies
        with the support they need to help their employees save for the future
        and increase financial independence.
      </p>

      <Divider />

      <h3 className={benefitsClasses.benefitsHeader}>
        What are some highlights of working with Empower?
      </h3>

      <UnorderedList>
        <UnorderedListItem>
          Like Justworks, Empower is driven by a customer-centric mission that
          ofers increased levels of dedicated customer support.
        </UnorderedListItem>
        <UnorderedListItem>
          Enhanced and simplified financial planning resources-all supported by
          Empower financial professionals dedicated to help you improve your
          financial health.
        </UnorderedListItem>
        <UnorderedListItem>
          Improved accessibility with the Empower app and chat through on your
          computer, tablet, or phone.
        </UnorderedListItem>
        <UnorderedListItem>
          Continued access to a broad range of investment choices to help you
          build a diversified portfolio, including access to Empower Advisory
          Services.
        </UnorderedListItem>
        <UnorderedListItem>
          Access to the Empower Customer Care Center-even on Saturdays.
        </UnorderedListItem>
      </UnorderedList>

      <Divider />

      <h3 className={benefitsClasses.benefitsHeader}>
        What financial wellness resources will be available with Empower?
      </h3>

      <p className={benefitsClasses.benefitsParagraph}>
        Empower has a number of helpful resources available to help you manage
        your financial future, including:
      </p>

      <UnorderedList>
        <UnorderedListItem>
          My Financial Path provides personalized, actionable steps you can take
          to reduce financial stress and help you achieve your goals-whether
          you're new to the workforce, in the middle of your career or getting
          close to retirement.
        </UnorderedListItem>
        <UnorderedListItem>
          The Learning Center is your source for interactive multimedia,
          learning experiences, leading-edge modeling tools and calculators, and
          guided learning packages.
        </UnorderedListItem>
        <UnorderedListItem>
          The Next Step Evaluator gives you a financial action plan designed
          specifically for you. There are no lengthy worksheets to get started
          either. Just answer a few yes or no questions.
        </UnorderedListItem>
      </UnorderedList>
    </div>
  );
};

export default EmpowerTab;
