import { Avatar } from "@justworkshr/milo-core";
import { FormField } from "@justworkshr/milo-form";
import { ChangeEvent, ReactElement, useContext } from "react";
import styles from "./PaymentDetailsRow.module.css";
import {
  amountInCents,
  getActiveCurrency,
  getEmployeeName,
  getEstimatedValue,
} from "pages/employer-of-record/payments/utils";
import {
  AdditionalPayFormData,
  FormPayloadType,
  PaymentDetailsType,
} from "../../../Setup/types";
import { FormikErrors, FormikTouched, useFormikContext } from "formik";
import { Link, useParams } from "react-router-dom";
import { buildClockworkWebPath } from "lib/resource-finder";
import type { EorMembersType } from "pages/employer-of-record/payments/types";
import {
  ADDITIONAL_PAY_FORM_ROUTES,
  EmployeePayType,
} from "pages/employer-of-record/payments/constants";
import PaymentAmountInput from "../PaymentAmountInput/PaymentAmountInput";
import { SupportedCountriesDataType } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import DateDropdown from "../DateDropdown/DateDropdown";
import DateRangePicker from "../DateRangePicker/DateRangerPicker";

type Props = {
  countryInfo: SupportedCountriesDataType;
  member: EorMembersType;
  index: number;
  hasDatePeriod: boolean;
  exchangeRate: number | undefined;
};

export const PaymentDetailsRow = ({
  member,
  index,
  countryInfo,
  hasDatePeriod,
  exchangeRate,
}: Props): ReactElement => {
  const formik = useFormikContext<AdditionalPayFormData<FormPayloadType>>();
  const { values, setFieldValue, touched, errors, handleBlur } = formik;
  const additionalPayContext = useContext(AdditionalPayContext);

  const { formType } = useParams();
  const isOtherRowFilled = () => {
    if (formType !== ADDITIONAL_PAY_FORM_ROUTES.REIMBURSEMENT) {
      return false;
    }

    return values.paymentDetails.some(
      (detail, i) => i !== index && detail?.grossAmount
    );
  };

  const touchedRow: FormikTouched<PaymentDetailsType> | null =
    touched.paymentDetails && touched.paymentDetails[index]
      ? touched.paymentDetails[index]
      : null;

  const errorRow: FormikErrors<PaymentDetailsType> | null =
    errors.paymentDetails && errors.paymentDetails[index]
      ? (errors.paymentDetails[index] as PaymentDetailsType)
      : null;

  const getUpdatedMemberDetail = (
    name: string,
    value: string,
    index: number
  ) => {
    const paymentDetails = [...values.paymentDetails];
    paymentDetails[index] = {
      ...paymentDetails[index],
      [name]: value,
    };
    return paymentDetails;
  };

  const onBlurHandler = (
    e: React.FocusEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = Number(values.paymentDetails[index]?.grossAmount);

    if (!isNaN(value)) {
      handleBlur(e);
    }
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    handleBlur(e);
    const { value } = e.target;
    const sanitizedValue = value && Number(value.replace(/[^0-9.-]/g, ""));
    const grossAmount = sanitizedValue ? amountInCents(sanitizedValue) : value;
    setFieldValue(
      `paymentDetails`,
      getUpdatedMemberDetail("grossAmount", grossAmount, index)
    );
  };

  const rowStarted =
    !!values.paymentDetails[index]?.grossAmount ||
    (hasDatePeriod &&
      (!!values.paymentDetails[index]?.workPeriodStart ||
        !!values.paymentDetails[index]?.workPeriodEnd));

  const fullName = getEmployeeName(member);
  const estimatedValue = getEstimatedValue(
    values.paymentDetails[index]?.grossAmount,
    exchangeRate
  );

  const currencyData = getActiveCurrency(countryInfo.currencies);
  const currency = currencyData?.currencyCode || "";
  const currencySymbol = currencyData?.symbol || "";

  return (
    <>
      <tr className={`${rowStarted ? styles.rowStarted : ""}`}>
        <td className={styles.td}>
          <div className={styles.nameWrapper}>
            <Avatar name={fullName}></Avatar>
            <div className={styles.name}>
              <Link
                to={buildClockworkWebPath(`members/${member.memberUuid}`)}
                target="_blank"
              >
                {fullName}
              </Link>
            </div>
          </div>
        </td>

        <td className={`${styles.td} ${styles.tdForm} `}>
          <FormField
            aria-labelledby="_"
            required
            name=""
            error={touchedRow?.grossAmount ? errorRow?.grossAmount : ""}
          >
            <>
              <div className={styles.grossAmountWrapper}>
                <div
                  className={`${styles.currency} ${
                    errorRow?.grossAmount ? styles.error : ""
                  }`}
                >
                  {currencySymbol} {currency}
                </div>
                <div className={styles.inputWrap}>
                  <PaymentAmountInput
                    disabled={isOtherRowFilled()}
                    name={`paymentDetails[${index}].grossAmount`}
                    value={
                      values.paymentDetails[index]?.grossAmount || undefined
                    }
                    className={`${styles.currencyInput} ${
                      errorRow?.grossAmount ? styles.error : ""
                    }`}
                    onBlur={(e) => onBlurHandler(e, index)}
                    onChange={(e) => onChangeHandler(e, index)}
                  ></PaymentAmountInput>
                </div>
              </div>
              {!errorRow?.grossAmount && estimatedValue && (
                <div className={styles.estimatedValue}>{estimatedValue}</div>
              )}
            </>
          </FormField>
        </td>

        {hasDatePeriod && values.setUp.type !== EmployeePayType.OFF_CYCLE && (
          <DateRangePicker
            index={index}
            member={member}
            getUpdatedMemberDetail={getUpdatedMemberDetail}
            errorRow={errorRow}
            touchedRow={touchedRow}
          />
        )}

        {hasDatePeriod && values.setUp.type === EmployeePayType.OFF_CYCLE && (
          <DateDropdown
            index={index}
            member={member}
            pastPayPeriodOptions={additionalPayContext?.pastPayPeriodOptions}
            errorRow={errorRow}
            touchedRow={touchedRow}
          />
        )}
      </tr>
    </>
  );
};
