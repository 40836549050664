import styles from "./BankAccountHeader.module.css";
import { PropsWithChildren } from "react";
import PiggyBankIcon from "./PiggyBankIcon";
import EditBankAccountAction from "./EditBankAccountAction";
import useIsOwnProfile from "pages/employer-of-record/employee-profile/hooks/useIsOwnProfile";

type Props = PropsWithChildren;

export default function BankAccountHeader(props: Props) {
  const isOwnProfile = useIsOwnProfile();

  return (
    <div className={styles.header}>
      <div className={styles.headerTitle}>
        <PiggyBankIcon />
        <div className={styles.headerText}>{props.children}</div>
      </div>
      {isOwnProfile && <EditBankAccountAction />}
    </div>
  );
}
