export const SORT = {
  NONE: "none",
  DESC: "desc",
  ASC: "asc",
};

export const NEXT_SORT_MAP = {
  [SORT.NONE]: SORT.ASC,
  [SORT.ASC]: SORT.DESC,
  [SORT.DESC]: SORT.ASC,
};
