import { IntegrationsConfig } from "types/IntegrationConfig";
import { ACCOUNTING_PERMISSIONS } from "pages/pmt/integrations/integration-marketplace/constants";
import netsuiteLogo from "pages/pmt/integrations/images/integration-partner-logos/netsuite-logo.svg";

// Netsuite uses a separate PDP and setup flow. Only the marketplaceCard is used from this file.
export const netsuiteConfig: IntegrationsConfig = {
  numAllowedInstances: 1,
  copy: {
    marketplaceCard: {
      logoUrl: netsuiteLogo,
      integrationName: "NetSuite",
      integrationDescription:
        "Seamlessly connect business systems for streamlined operations",
      integrationCategory: "Accounting",
      requiredPermissions: ACCOUNTING_PERMISSIONS,
      slug: "netsuite",
      pdpUrl: "/netsuite/settings",
    },
    pdp: {
      headerDescription: "",
      heroImageUrl: "",
      horizontalLogoUrl: "",
      resourceCard: {
        links: [],
      },
      infoTabsConfig: {},
      infoTabsContent: {
        overviewTab: {
          header: "",
          subheader: "",
          list: [
            {
              summary: "",
              detail: "",
            },
          ],
        },
      },
      disconnectModalContent: {
        title: "",
        body: <div></div>,
        disconnectButtonText: "",
      },
      allowedPdpActions: [],
    },
    setup: {
      estimatedSetupTime: "",
      helpCenterLink: "",
      stepperConfig: {},
      steps: [],
      instanceKeys: [],
      getCurrentStepFunction: () => 0,
    },
  },
};
