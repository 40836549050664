import styles from "./TableHeader.module.css";

import React from "react";
import { NEXT_SORT_MAP, SORT } from "../Table.constants";
import { Button } from "@justworkshr/milo-core";
import { SortIcon } from "../SortIcon";
import { mergeClassNames } from "pages/expenses/utils";

const { TableHeaderLabel, TableHeaderButton, TableHeaderWrapper } = styles;

interface TableHeaderProps {
  headers: {
    key: string;
    label: string;
    isSortable: boolean;
  }[];
  sortBy: string;
  sortDirection: string;
  onSort: (data: { sortBy: string; sortDirection: string }) => void;
}

export const TableHeader: React.FC<TableHeaderProps> = ({
  headers,
  sortBy,
  onSort,
  sortDirection,
}) => {
  const handleClick = (key: string) => {
    const nextDirection =
      key === sortBy ? NEXT_SORT_MAP[sortDirection] : SORT.ASC;

    onSort({ sortBy: key, sortDirection: nextDirection });
  };

  return (
    <thead className={TableHeaderWrapper}>
      <tr>
        {headers.map(({ key, label, isSortable }, index) => {
          const mapKey = `${label}_${index}`;

          if (isSortable) {
            const buttonClassName = mergeClassNames([
              key,
              TableHeaderButton,
              { sorted: key === sortBy },
            ]);

            return (
              <th
                key={mapKey}
                className={`${key} TableHeaderColumnSortableHeader`}
              >
                <Button
                  size="sm"
                  type="button"
                  variant="ghost"
                  data-testid={key}
                  className={buttonClassName}
                  onClick={() => handleClick(key)}
                >
                  <span className={TableHeaderLabel}>{label}</span>
                  <SortIcon
                    sortKey={key}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                  />
                </Button>
              </th>
            );
          }

          return (
            <th
              key={mapKey}
              className={`${key} TableHeaderColumnHeaderNotSortable`}
            >
              <span className={TableHeaderLabel}>{label}</span>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
