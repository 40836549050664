import React, { SVGProps } from "react";

import AustraliaMap from "./icons/Austrailia.svg?react";
import BrazilMap from "./icons/Brazil.svg?react";
import CanadaMap from "./icons/canada.svg?react";
import ChileMap from "./icons/Chile.svg?react";
import ColombiaMap from "./icons/Colombia.svg?react";
import CostaRicaMap from "./icons/CostaRica.svg?react";
import DenmarkMap from "./icons/Denmark.svg?react";
import FranceMap from "./icons/France.svg?react";
import GermanyMap from "./icons/Germany.svg?react";
import IndiaMap from "./icons/India.svg?react";
import IrelandMap from "./icons/Ireland.svg?react";
import MalaysiaMap from "./icons/Malaysia.svg?react";
import MexicoMap from "./icons/Mexico.svg?react";
import NetherlandsMap from "./icons/Netherlands.svg?react";
import NorwayMap from "./icons/Norway.svg?react";
import PhillippinesMap from "./icons/Phillippines.svg?react";
import SingaporeMap from "./icons/Singapore.svg?react";
import SpainMap from "./icons/Spain.svg?react";
import SwedenMap from "./icons/Sweden.svg?react";
import UKMap from "./icons/UK.svg?react";

import { Box } from "@justworkshr/milo-core";
import { CountryCode } from "pages/employer-of-record/onboarding/constants";

interface CountryMap {
  [key: string]: React.ComponentType<SVGProps<SVGSVGElement>>;
}

const countryMap: CountryMap = {
  [CountryCode.CA]: CanadaMap,
  [CountryCode.AU]: AustraliaMap,
  [CountryCode.BR]: BrazilMap,
  [CountryCode.CL]: ChileMap,
  [CountryCode.CO]: ColombiaMap,
  [CountryCode.CR]: CostaRicaMap,
  [CountryCode.DK]: DenmarkMap,
  [CountryCode.FR]: FranceMap,
  [CountryCode.DE]: GermanyMap,
  [CountryCode.IN]: IndiaMap,
  [CountryCode.IE]: IrelandMap,
  [CountryCode.MY]: MalaysiaMap,
  [CountryCode.MX]: MexicoMap,
  [CountryCode.NL]: NetherlandsMap,
  [CountryCode.NO]: NorwayMap,
  [CountryCode.PH]: PhillippinesMap,
  [CountryCode.SG]: SingaporeMap,
  [CountryCode.ES]: SpainMap,
  [CountryCode.SE]: SwedenMap,
  [CountryCode.GB]: UKMap,
};

interface IconComponentProps {
  name: keyof typeof CountryCode;
}

const CountryMapSvg: React.FC<IconComponentProps & SVGProps<SVGSVGElement>> = ({
  name,
  ...props
}) => {
  let Icon;
  if (name) {
    Icon = countryMap[name];
  }

  if (Icon) {
    return <Icon {...props} data-testid="icon-svg" />;
  }
  // Return a default or fallback component if the country is not found
  return (
    <div data-testid="default-div">
      <Box padding="3xl">{""}</Box>
    </div>
  );
};

export default CountryMapSvg;
