import {
  ADDITIONAL_PAYMENTS_FORM_COPY,
  ADDITIONAL_PAYMENTS_YES_OR_NO_OPTIONS,
} from "../../AdditionalPaymentsPage.constants";

import styles from "../../../SeparationPay.module.css";
import {
  RadioButton,
  RadioButtonGroup,
} from "pages/team-management/components";
import { SeparationFlowContext } from "pages/team-management/contexts";
import { useContext } from "react";
import { YesOrNoTypes } from "../../AdditionalPaymentsPage.types";

const { SectionContainer, SubHeadingLg, RowContainer } = styles;

export const PreambleForm = () => {
  const {
    hasAdditionalPayments,
    setHasAdditionalPayments,
    isKnownAmount,
    setIsKnownAmount,
  } = useContext(SeparationFlowContext);

  return (
    <div className={SectionContainer}>
      <div className={SubHeadingLg}>
        {ADDITIONAL_PAYMENTS_FORM_COPY.additional_payments_yes_or_no}
      </div>
      <div>
        {ADDITIONAL_PAYMENTS_FORM_COPY.additional_payments_yes_or_no_message}
      </div>
      <div className={RowContainer}>
        <RadioButtonGroup
          data-testid="has-additional-payments"
          name="additional_payments_yes_or_no"
          value={hasAdditionalPayments ? YesOrNoTypes.YES : YesOrNoTypes.NO}
          onChange={(option) => {
            setHasAdditionalPayments &&
              setHasAdditionalPayments(
                option.target.value === YesOrNoTypes.YES
              );
          }}
          orientation="horizontal"
        >
          {Object.entries(ADDITIONAL_PAYMENTS_YES_OR_NO_OPTIONS).map(
            ([key, value]) => {
              return <RadioButton key={key} value={key} label={value} />;
            }
          )}
        </RadioButtonGroup>
      </div>
      {hasAdditionalPayments && (
        <>
          <div>
            <b>{ADDITIONAL_PAYMENTS_FORM_COPY.is_amount_of_payments_known} </b>
          </div>
          <div>
            {ADDITIONAL_PAYMENTS_FORM_COPY.is_amount_of_payments_known_message}
          </div>
          <div className={RowContainer}>
            <RadioButtonGroup
              name="is_amount_of_payments_known"
              value={isKnownAmount ? YesOrNoTypes.YES : YesOrNoTypes.NO}
              onChange={(option) => {
                setIsKnownAmount &&
                  setIsKnownAmount(option.target.value === YesOrNoTypes.YES);
              }}
              orientation="horizontal"
            >
              {Object.entries(ADDITIONAL_PAYMENTS_YES_OR_NO_OPTIONS).map(
                ([key, value]) => {
                  return <RadioButton key={key} value={key} label={value} />;
                }
              )}
            </RadioButtonGroup>
          </div>
        </>
      )}
    </div>
  );
};
