import { ReactElement, useContext, useState } from "react";

import styles from "./Retroactive.module.css";
import { FieldConfig, SetupFormGenerator } from "../../SetupFormGenerator";

import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { EmployeePayType } from "pages/employer-of-record/payments/constants";
import useCountryConfigFetcher from "../hooks/useCountryConfigFetcher";

export const Retroactive = (): ReactElement => {
  const additionalPayContext = useContext(AdditionalPayContext);
  const [paymentSettingsFields, setPaymentSettingsFields] = useState<
    FieldConfig[]
  >([]);

  useCountryConfigFetcher(
    EmployeePayType.RETROACTIVE,
    additionalPayContext,
    setPaymentSettingsFields
  );

  return (
    <div className={styles.card}>
      <SetupFormGenerator
        title="Payment settings"
        fields={paymentSettingsFields}
        formikFieldName="paymentSettings"
      />
    </div>
  );
};
