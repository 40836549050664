import { useGetAdminPermissionsQuery } from "types/generated/operations";

export const useGetAdminPermissions = () => {
  const { data: permissionsContainer } = useGetAdminPermissionsQuery({
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });
  const { canDoMakePayments: canMakePayments } =
    permissionsContainer?.adminPermissions || {};

  return { canMakePayments };
};
