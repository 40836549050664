import { useState } from "react";

import { Button, ButtonType, ButtonProps } from "@justworkshr/milo-core";
import { CheckboxInput } from "@justworkshr/milo-form";
import {
  Table,
  Notice,
  Loading,
  TableBody,
  TableHeader,
  PaginationSelect,
  ReturnRequestDialog,
} from "pages/expenses/components";
import { setTableQueryVariables, formatDate } from "pages/expenses/utils";
import { ReviewReimbursementRequestsPayload } from "types/Expenses";
import {
  GetActiveRequestsDocument,
  ReimbursementRequestStatus,
} from "types/generated/operations";
import {
  isMultipleEmployeeRequest,
  getReimbursementStatusParam,
} from "./ActiveRequestsPage.utils";
import {
  AlertTypes,
  PAGINATION_OPTIONS,
  DEFAULT_TABLE_PARAMS,
} from "pages/expenses/constants";
import {
  NO_ACTIVE_REQUESTS_COPY,
  EXPENSES_TURNED_OFF_COPY,
  NO_ACTIVE_REQUESTS_TITLE,
  EXPENSES_TURNED_OFF_TITLE,
  ACTIVE_REQUESTS_TABLE_HEADERS,
  ACTIVE_REQUESTS_TABLE_WITH_NON_REIMBURSABLE_HEADERS,
} from "./ActiveRequestsPage.constants";
import { ActiveRequestsTableRow } from "./components";
import {
  MultiAction,
  useMultiAction,
  useActiveRequests,
  useReturnToEmployee,
  useCategoriesExpenseType,
  useApproveReimbursementRequests,
} from "pages/expenses/hooks";
import {
  useFeedbackBannerStore,
  useGlobalConfigurationStore,
} from "pages/expenses/store";
import {
  Review,
  GetActiveRequestsQueryVariables,
} from "types/generated/operations";
import { ReimbursementRequest } from "types/Expenses";
import styles from "./ActiveRequestsPage.module.css";

const {
  ActiveRequestsPageWrapper,
  ActiveRequestsTableWrapper,
  AllRequestsCheckboxWrapper,
} = styles;

export const ActiveRequestsPage = () => {
  const { permissions, isExpensesEnabled } = useGlobalConfigurationStore();
  const reviewerPermissions = {
    isAdmin: permissions.isAdmin,
    isManager: permissions.isManager,
    isAdminAndManager: permissions.isAdminAndManager,
  };
  const { setFeedbackBanner, dismissFeedbackBanner } = useFeedbackBannerStore();
  const { clearItems, selectedItems, handleSelectedItem, handleSelectedItems } =
    useMultiAction<ReimbursementRequest>();

  const handleCheckboxChange = () => handleSelectedItems(activeRequests);
  const {
    isCategoryWithNonReimbursableExpenses,
    isCategoriesExpenseTypeLoading,
  } = useCategoriesExpenseType();
  const [showReturnRequestDialog, setShowReturnRequestDialog] = useState(false);
  const [tableParams, setTableParams] = useState(DEFAULT_TABLE_PARAMS);
  const tableHeaders = isCategoryWithNonReimbursableExpenses
    ? ACTIVE_REQUESTS_TABLE_WITH_NON_REIMBURSABLE_HEADERS
    : ACTIVE_REQUESTS_TABLE_HEADERS;
  const variables = {
    ...setTableQueryVariables(tableParams),
    status: getReimbursementStatusParam(permissions),
  } as GetActiveRequestsQueryVariables;

  const {
    loading: isActiveRequestsLoading,
    activeRequests,
    activeRequestsResponse,
    earliestPayDate,
    resetActiveRequests,
  } = useActiveRequests(variables);

  const handleActiveRequests = () => {
    resetActiveRequests();
    clearItems();
  };

  const { returnToEmployee, returnToEmployeeData, isReturnToEmployeeLoading } =
    useReturnToEmployee({
      onSuccess: () => {
        handleActiveRequests();
        setReturnReimbursementBanner();
      },
      mutationOptions: {
        refetchQueries: [
          {
            query: GetActiveRequestsDocument,
            variables,
          },
        ],
        awaitRefetchQueries: true,
      },
    });

  const {
    approveReimbursementRequests,
    approveReimbursementRequestsData,
    isApproveReimbursementRequestsLoading,
  } = useApproveReimbursementRequests(variables, selectedItems, () => {
    handleActiveRequests();

    if (
      approveReimbursementRequestsData?.reviewReimbursementRequests
        ?.updatedRequests &&
      approveReimbursementRequestsData?.reviewReimbursementRequests
        ?.updatedRequests[0].status ===
        ReimbursementRequestStatus.ReadyForApproval
    ) {
      setReadyForApprovalBanner();
    } else {
      setApproveReimbursementBanner();
    }
  });

  const isApprovedItemsNonReimbursable = approveReimbursementRequestsData
    ?.reviewReimbursementRequests?.updatedRequests?.length
    ? approveReimbursementRequestsData?.reviewReimbursementRequests?.updatedRequests?.every(
        (request) => request.totalNonReimbursableAmount > 0
      )
    : false;

  const approvedRequestName =
    approveReimbursementRequestsData?.reviewReimbursementRequests
      ?.updatedRequests?.[0]?.name;

  const isMultipleApprovedRequests = isMultipleEmployeeRequest(
    approveReimbursementRequestsData?.reviewReimbursementRequests as ReviewReimbursementRequestsPayload
  );

  const setApproveNonReimbursableBanner = () => {
    return setFeedbackBanner({
      show: true,
      type: AlertTypes.SUCCESS,
      message: isMultipleApprovedRequests
        ? "These requests were approved."
        : `${approvedRequestName} was approved.`,
    });
  };

  const setReturnReimbursementBanner = () => {
    const returnedRequestName =
      returnToEmployeeData?.reviewReimbursementRequests?.updatedRequests?.[0]
        ?.name;

    const friendlyFullName =
      returnToEmployeeData?.reviewReimbursementRequests?.updatedRequests?.[0]
        ?.member?.friendlyFullName;

    const isMultipleReturnedRequests = isMultipleEmployeeRequest(
      returnToEmployeeData?.reviewReimbursementRequests as ReviewReimbursementRequestsPayload
    );
    return setFeedbackBanner({
      show: true,
      type: AlertTypes.SUCCESS,
      message: isMultipleReturnedRequests
        ? "These requests were returned to employees."
        : `${returnedRequestName} was returned to ${friendlyFullName}.`,
    });
  };
  const setApproveReimbursementBanner = () => {
    const payDate = formatDate(earliestPayDate || "");
    if (isApprovedItemsNonReimbursable) {
      return setApproveNonReimbursableBanner();
    }
    const message = isMultipleApprovedRequests
      ? `These requests were approved and will be scheduled to be paid on ${payDate}.`
      : `${approvedRequestName} was approved and will be scheduled to be paid on ${payDate}.`;

    return setFeedbackBanner({
      show: true,
      type: AlertTypes.SUCCESS,
      message,
    });
  };
  const setReadyForApprovalBanner = () => {
    const message = isMultipleApprovedRequests
      ? "These requests were approved and will be sent for final approval."
      : `${approvedRequestName} was approved and will be sent for final approval.`;
    return setFeedbackBanner({
      show: true,
      type: AlertTypes.SUCCESS,
      message,
    });
  };

  const hasActiveRequests = activeRequests?.length !== 0;
  const isAllRequestsSelected = selectedItems.length === activeRequests.length;
  const selectedItemsUuids: string[] = selectedItems.map((item) => item.uuid);
  const isLoading = isActiveRequestsLoading || isCategoriesExpenseTypeLoading;

  const renderMultiActionButtons = (isActive: boolean) => {
    const returnButtonProps = {
      size: "sm",
      leftIcon: "redo",
      disabled: !isActive || isReturnToEmployeeLoading,
      color: "destructive",
      "data-testid": "return-button",
      onClick: () => {
        dismissFeedbackBanner();
        setShowReturnRequestDialog(true);
      },
    } as Partial<ButtonProps<ButtonType>>;

    const approveButtonProps = {
      size: "sm",
      leftIcon: "check",
      disabled: !isActive || isApproveReimbursementRequestsLoading,
      "data-testid": "approve-button",
      onClick: () => {
        dismissFeedbackBanner();
        approveReimbursementRequests();
      },
    } as Partial<ButtonProps<ButtonType>>;

    if (permissions.isAdmin) {
      return (
        <>
          <Button {...returnButtonProps}>Return to employee</Button>
          <Button {...approveButtonProps}>Approve & schedule pay</Button>
        </>
      );
    }

    return (
      <>
        <Button {...returnButtonProps}>Return to employee</Button>
        <Button {...approveButtonProps}>Approve</Button>
      </>
    );
  };
  const renderTableBody = () => {
    return (
      <TableBody>
        {activeRequests.map((request) => {
          const isCheckboxActive = !!selectedItems.find(
            (item) => item.uuid === request.uuid
          );

          const handleCheckboxChange = () =>
            handleSelectedItem(request, isCheckboxActive, (prevSelectedItems) =>
              prevSelectedItems.filter((item) => item.uuid !== request.uuid)
            );
          return (
            <ActiveRequestsTableRow
              key={request.uuid}
              request={request}
              reviewerPermissions={reviewerPermissions}
              isCheckboxActive={isCheckboxActive}
              handleCheckboxChange={handleCheckboxChange}
              showNonReimbursableColumn={isCategoryWithNonReimbursableExpenses}
            />
          );
        })}
      </TableBody>
    );
  };

  const paginationProps = {
    options: PAGINATION_OPTIONS,
    currentPage: (activeRequestsResponse?.page || 0) + 1,
    totalResults: activeRequestsResponse?.totalResults || 0,
    resultsPerPage: tableParams.resultsPerPage,
    onPageChange: (newPageNum: number) =>
      setTableParams((prevTableParams) => ({
        ...prevTableParams,
        pageNum: newPageNum - 1,
      })),
    onSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) =>
      setTableParams((prevTableParams) => ({
        ...prevTableParams,
        resultsPerPage: parseInt(event.target.value, 10),
      })),
  };

  const handleReturn = (returnReason: string) => {
    const reviews = selectedItems.map((item) => ({
      uuid: item.uuid,
      reason: returnReason,
      type: Review.Return,
    }));

    returnToEmployee({ variables: { reviews } });
  };

  const renderReturnRequestMessage = () => {
    const isMultipleSelectedItems = selectedItems.length !== 1;
    const employee = activeRequests?.filter((request) =>
      selectedItemsUuids.includes(request.uuid)
    )?.[0]?.member?.firstName;

    if (isMultipleSelectedItems) {
      return "Let your employees know what needs to be updated in order for the request to be approved.";
    }

    return `Let ${employee} know what needs to be updated in order for the request to be approved.`;
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!isExpensesEnabled) {
    return (
      <div className={ActiveRequestsPageWrapper}>
        <Notice title={EXPENSES_TURNED_OFF_TITLE}>
          {EXPENSES_TURNED_OFF_COPY}
        </Notice>
      </div>
    );
  }
  if (!hasActiveRequests || !activeRequests) {
    return (
      <div className={ActiveRequestsPageWrapper}>
        <Notice title={NO_ACTIVE_REQUESTS_TITLE}>
          {NO_ACTIVE_REQUESTS_COPY}
        </Notice>
      </div>
    );
  }

  return (
    <div className={ActiveRequestsPageWrapper}>
      <MultiAction selectedItems={selectedItems}>
        {({ isActive }) => renderMultiActionButtons(isActive)}
      </MultiAction>
      <div className={ActiveRequestsTableWrapper}>
        <div className={AllRequestsCheckboxWrapper}>
          <CheckboxInput
            name="allRequestsCheckbox"
            value="allRequestsCheckbox"
            onChange={handleCheckboxChange}
            checked={isAllRequestsSelected}
            aria-label={`${
              isAllRequestsSelected ? "Deselect" : "Select"
            } all requests`}
          />
        </div>
        <Table dataTestId="request-table">
          <TableHeader
            headers={tableHeaders}
            sortBy={tableParams.sortBy}
            sortDirection={tableParams.sortDirection}
            onSort={({ sortBy, sortDirection }) =>
              setTableParams((prevTableParams) => ({
                ...prevTableParams,
                pageNum: 0,
                sortBy,
                sortDirection,
              }))
            }
          />
          {renderTableBody()}
        </Table>
      </div>
      {showReturnRequestDialog && (
        <ReturnRequestDialog
          onReturn={handleReturn}
          isOpen={showReturnRequestDialog}
          message={renderReturnRequestMessage()}
          onClose={() => setShowReturnRequestDialog(false)}
        />
      )}
      <PaginationSelect {...paginationProps} />
    </div>
  );
};
export default ActiveRequestsPage;
