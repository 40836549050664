import { useGetPendoInfoQuery } from "types/generated/operations";

interface CustomWindow extends Window {
  pendo: {
    initialize(init: object): void;
  };
}

declare const window: CustomWindow;

function Pendo(): JSX.Element {
  const { data, loading } = useGetPendoInfoQuery();
  if (!data || loading) return <></>;
  const authenticatedMember = data.authenticatedMember;
  const company = data.authenticatedMember.company;
  if ("pendo" in window) {
    const init = {
      apiKey: "1b0ee175-28f7-489a-7775-962efa6590da",
      visitor: {},
      account: {},
    };

    if (authenticatedMember) {
      init.visitor = {
        shouldNagForBeneficiaries: authenticatedMember.nagForBeneficiaries,
        id: authenticatedMember.uuid,
        isSuperUser: authenticatedMember.adminStatus,
        permissions: authenticatedMember.permissions,
        memberWorkState: authenticatedMember.address?.state,
        memberType: authenticatedMember.memberType,
        memberStatus: authenticatedMember.memberStatus,
        managerPermissions: authenticatedMember.managerPermissions,
        benefitsMedical: authenticatedMember.adoptedBenefits.medical,
        benefitsDental: authenticatedMember.adoptedBenefits.dental,
        benefitsVision: authenticatedMember.adoptedBenefits.vision,
      };
    }

    if (company) {
      init.account = {
        id: company.uuid,
        workersCompCompleted: company.workersCompStatus,
        companyStatus: company.benefitsEligibility,
        companyStateHq: company.primaryAddress?.state,
        companyPlanType: company.companyPlanType,
        numberOfActiveW2Employees: company.numOfActiveW2Employees,
      };
    }
    window.pendo.initialize(init);
    return <span data-testid="pendo-script"></span>;
  }
  return <></>;
}

export default Pendo;
