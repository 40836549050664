import React from "react";
import IntegrationCard from "../IntegrationCard/integration-card";
import styles from "./card-section.module.css";
import { CardData } from "../../integration-marketplace";
import { PERMISSION } from "types/RequiredPermissions";
import { CompanyAdmin } from "types";

const { cardSection, cardSectionHeader } = styles;

type CardSectionProps = {
  header: string;
  cards: CardData[];
  userPermissions: PERMISSION[] | undefined;
  companyAdmins?: CompanyAdmin[];
};
const CardSection: React.FC<CardSectionProps> = ({
  header,
  cards,
  userPermissions,
  companyAdmins,
}) =>
  cards?.length ? (
    <>
      <h2 className={cardSectionHeader}>{header}</h2>
      {cards && (
        <div className={cardSection}>
          {cards.map((card) => (
            <IntegrationCard
              {...card}
              key={card.slug}
              userPermissions={userPermissions}
              companyAdmins={companyAdmins}
            />
          ))}
        </div>
      )}
    </>
  ) : null;

export default CardSection;
