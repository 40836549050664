import { Route, Routes } from "react-router-dom";
import { RequestSeparationProvider } from "./context/RequestSeparationContext";
import RequestSeparationBackground from "./pages/BackgroundPage";
import RequestSeparationComplete from "./pages/CompletionPage";
import RequestSeparationReview from "./pages/ReviewPage";

const Offboarding = () => (
  <RequestSeparationProvider>
    <Routes>
      <Route path="/review" element={<RequestSeparationReview />} />
      <Route path="/complete" element={<RequestSeparationComplete />} />
      <Route path="/" element={<RequestSeparationBackground />} />
    </Routes>
  </RequestSeparationProvider>
);

export default Offboarding;
