import { ReactElement, ReactNode, useState } from "react";
import { Tooltip } from "./Tooltip";
import styles from "./TooltipIcon.module.css";
import { SystemIcon, SystemIconProps } from "@justworkshr/milo-icons";

interface TooltipIconProps {
  content: ReactNode;
  iconClassName?: string;
  iconColor?: SystemIconProps["color"];
  iconName: SystemIconProps["iconName"];
}

export default function TooltipIcon({
  content,
  iconClassName,
  iconColor,
  iconName,
}: TooltipIconProps): ReactElement {
  const [open, setOpen] = useState(false);

  return (
    <Tooltip content={content} open={open}>
      <div
        className={`${styles.iconContainer} ${iconClassName}`}
        onMouseOver={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <SystemIcon size="small" color={iconColor} iconName={iconName} />
      </div>
    </Tooltip>
  );
}
