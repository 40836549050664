import React from "react";
import { ExpenseDialog } from "../ExpenseDialog";
import { Button } from "@justworkshr/milo-core";
import { useNavigate } from "react-router-dom";
import { prependBasePath } from "pages/expenses/constants";

interface CancelReviewDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
}

export const CancelReviewDialog: React.FC<CancelReviewDialogProps> = ({
  isOpen,
  onClose,
  onCancel,
}) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    onCancel();

    navigate(prependBasePath("ACTIVE_REQUESTS_LIST"));
  };

  return (
    <ExpenseDialog
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        <Button
          type="button"
          variant="ghost"
          onClick={onClose}
          key="keep-request-cancel-review"
        >
          Keep request
        </Button>,
        <Button
          type="button"
          variant="filled"
          key="cancel-review"
          onClick={handleCancel}
        >
          Cancel request
        </Button>,
      ]}
    >
      <h2>Are you sure you want to cancel?</h2>
      <p>
        This reimbursement will remain unpaid and any notes that you&apos;ve
        added will not be saved.
      </p>
    </ExpenseDialog>
  );
};
