import React from "react";
import styles from "./InstanceDetailsPanel.module.css";
import { Box } from "@justworkshr/milo-core";
import { ConnectionStatusBadgeProps } from "pages/pmt/integrations/integration-marketplace/components/ConnectionStatusBadge/connection-status-badge";
import DisconnectedPanelContent from "./DisconnectedPanelContent";
import ConnectedPanelContent from "./ConnectedOrPendingPanelContent";
import { AlertData } from "pages/pmt/integrations/hooks/useAlert";
import { IntegrationsConfig } from "types/IntegrationConfig";
const { instanceDetailsPanelContent, horizontalLogo } = styles;

export type InstanceDetailsPanelProps = {
  config: IntegrationsConfig["copy"]["pdp"];
  solutionSlug: string;
  partnerName: string | undefined;
  companyName: string | undefined;
  connectionStatus: ConnectionStatusBadgeProps["connectionStatus"];
  lastActivityDate: Date | null | undefined;
  setAlertData: (alertData: AlertData) => void;
  hasPermission: boolean;
  permissionNotice: JSX.Element;
  hasAuthError: boolean;
  integrationAutoDisabled?: boolean;
  integrationExpiring?: boolean;
};

const InstanceDetailsPanel: React.FC<InstanceDetailsPanelProps> = ({
  config,
  solutionSlug,
  partnerName,
  companyName,
  connectionStatus,
  lastActivityDate,
  setAlertData,
  hasPermission,
  permissionNotice,
  hasAuthError,
  integrationAutoDisabled,
  integrationExpiring,
}) => {
  const logoUrl = config?.horizontalLogoUrl;
  return (
    <Box
      padding="2xl"
      border={{
        borderWidth: "sm",
        borderColor: "neutralSubtle",
        borderRadius: "md",
      }}
    >
      <div className={instanceDetailsPanelContent}>
        <img
          className={horizontalLogo}
          src={logoUrl}
          alt="horizontal partner logo"
        />
        {connectionStatus === "DISCONNECTED" ? (
          <DisconnectedPanelContent
            solutionSlug={solutionSlug}
            partnerName={partnerName}
            companyName={companyName}
            hasPermission={hasPermission}
            permissionNotice={permissionNotice}
            setAlertData={setAlertData}
            integrationAutoDisabled={integrationAutoDisabled}
          />
        ) : (
          <ConnectedPanelContent
            config={config}
            solutionSlug={solutionSlug}
            lastActivityDate={lastActivityDate}
            connectionStatus={connectionStatus}
            setAlertData={setAlertData}
            hasPermission={hasPermission}
            permissionNotice={permissionNotice}
            hasAuthError={hasAuthError}
            integrationExpiring={integrationExpiring}
          />
        )}
      </div>
    </Box>
  );
};

export default InstanceDetailsPanel;
