import { useGetFeatureFlag } from "lib/launch-darkly";
import { buildWebPath } from "lib/resource-finder";
import { useGetMemberTypeQuery } from "types/generated/operations";

export default function useAccountSettingsLink() {
  const { getFeatureFlag } = useGetFeatureFlag();
  const { data } = useGetMemberTypeQuery();

  const isEorEmployee = data?.authenticatedMember.memberType.includes("EOR");
  const eorEeAccountSettingsEnabled = getFeatureFlag(
    "release-eor-ee-account-settings"
  );

  if (isEorEmployee && eorEeAccountSettingsEnabled) {
    return buildWebPath("/cf/account-settings");
  }

  return buildWebPath("/account_settings");
}
