import React from "react";
import styles from "./ExpandableList.module.css";
import { ExpandableText } from "../ExpandableText";
import { mergeClassNames } from "pages/expenses/utils";

const { ExpandableListWrapper } = styles;

interface ExpandableListProps {
  className?: string;
  children:
    | React.ReactElement<typeof ExpandableText>
    | React.ReactElement<typeof ExpandableText>[];
}

export const ExpandableList: React.FC<ExpandableListProps> = ({
  className,
  children,
}) => {
  const mergedClassName = mergeClassNames([ExpandableListWrapper, className]);

  return (
    <div className={mergedClassName}>
      {React.Children.map(children, (component) => {
        if (component?.type != ExpandableText) return;

        return component;
      })}
    </div>
  );
};

export default ExpandableList;
