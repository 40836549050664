import React from "react";
import styles from "../../ReimbursementSummary.module.css";
import { useRequestDetail } from "pages/expenses/store";

const { ReimbursementSummaryItemWrapper } = styles;

interface SubmitterProps {
  isCreateFlow: boolean;
}

export const Submitter: React.FC<SubmitterProps> = React.memo(
  ({ isCreateFlow }) => {
    const {
      requestDetail: {
        request: { member: submitter, myRequest: isCurrentMemberRequest },
      },
    } = useRequestDetail();

    const showSubmitter = !isCurrentMemberRequest && !isCreateFlow && submitter;

    if (!showSubmitter) {
      return null;
    }

    return (
      <div className={ReimbursementSummaryItemWrapper}>
        <dt>Submitted by</dt>
        <dd>{submitter?.friendlyFullName}</dd>
      </div>
    );
  }
);
