import benefitsClasses from "pages/benefits/benefits.module.css";
import { Box } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";

interface ResourcesBoxProps {
  show_transition_link?: boolean;
}

const ResourcesBox = ({ show_transition_link }: ResourcesBoxProps) => {
  return (
    <Box
      border={{
        borderColor: "neutralSubtle",
        borderRadius: "md",
        borderWidth: "sm",
      }}
      padding="2xl"
    >
      <h3 className={benefitsClasses.benefitsHeader}>Resources</h3>
      {show_transition_link && (
        <p className={benefitsClasses.benefitsParagraph}>
          <a
            style={{ display: "flex" }}
            href="https://www.empower.com/client/justworks/transition/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Empower Transition{" "}
            <SystemIcon
              iconName="external-link"
              color="brand"
              style={{ marginLeft: "4px" }}
            />
          </a>
        </p>
      )}
      <p className={benefitsClasses.benefitsParagraph}>
        <a
          style={{ display: "flex" }}
          href="https://www.empower.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more about Empower{" "}
          <SystemIcon
            iconName="external-link"
            color="brand"
            style={{ marginLeft: "4px" }}
          />
        </a>
      </p>
    </Box>
  );
};

export default ResourcesBox;
