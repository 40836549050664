import { VARIABLE_NAMES } from "../variable-constants";
import { SettingsConfig } from "../settings-type";

const productionSettings: Partial<SettingsConfig> = {
  [VARIABLE_NAMES.VARIABLE_TO_TEST_WITH]: "production",
  [VARIABLE_NAMES.HEAP_KEY]: "1234739207",
  [VARIABLE_NAMES.JAZZHR_BASE_URL]: "https://app.jazz.co",
  [VARIABLE_NAMES.OKTA_CLIENT_ID]: "0oae79n3p7tvf8Q3P697",
  [VARIABLE_NAMES.OKTA_ISSUER]: "https://login.justworks.com/oauth2/default",
};

export default productionSettings;
