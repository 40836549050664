export const PAYMENT_DETAILS_FORM_COPY = {
  work_start_date: "Start date",
  work_end_date: "End date",
  tax_method: "Federal tax rate",
  pay_group: "Bonus pay frequency",
  deductions_setting: "Benefit deductions",
  deductions_setting_message:
    "Non-benefit deductions, like wage garnishments, will always be deducted from this payment.",
};

export const COMMISSION_DETAILS_COPY =
  "These are taxable supplemental wages which can only be paid to employees.";

export const SUPPLEMENTAL_DETAILS_COPY =
  "Supplemental pay is distinct from regular pay and will be taxed differently. " +
  "This is pay that’s in addition to your employee’s regular salary or wages.";
