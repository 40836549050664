/* istanbul ignore file */

import { TextInput } from "@justworkshr/milo-form";
import { SystemIcon } from "@justworkshr/milo-icons";
import React from "react";
import Datetime from "react-datetime";
import "./DateTime.css";
import styles from "./DatePicker.module.css";
import { formatDatePickerDate } from "./DatePicker.utils";

const { DatePickerWrapper, DatePickerInputWrapper, DatePickerCalendarIcon } =
  styles;

interface DatePickerProps {
  id: string;
  name: string;
  value: string;
  disabled?: boolean;
  placeholder?: string;
  isValidDate?: (currentDate: string) => boolean;
  onChange: (dateValue: string) => void;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  id,
  name,
  value,
  onChange,
  placeholder,
  isValidDate,
  disabled = false,
}) => {
  const handleChange = (dateValue: string) => {
    onChange(formatDatePickerDate(dateValue));
  };

  return (
    <div className={DatePickerWrapper} data-testid="date-input">
      <Datetime
        closeOnSelect
        value={value}
        timeFormat={false}
        dateFormat="MM/DD/YYYY"
        isValidDate={isValidDate}
        onChange={(date) => handleChange(date.toString())}
        renderInput={(props, openCalendar) => (
          <div className={DatePickerInputWrapper}>
            <TextInput
              {...props}
              id={id}
              name={name}
              autoComplete="off"
              disabled={disabled}
              placeholder={placeholder}
            />
            <SystemIcon
              size="medium"
              iconName="calendar"
              className={DatePickerCalendarIcon}
              color={disabled ? "disabled" : "neutral"}
              onClick={() => {
                if (!disabled) {
                  openCalendar();
                }
              }}
            />
          </div>
        )}
      />
    </div>
  );
};
