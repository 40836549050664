import CreateStore from "../createStore";
import { SettingsReducer, INITIAL_SETTINGS_STORE } from "./reducer";

const {
  useStore: useSettingsStore,
  Provider: SettingsProvider,
  StoreContext: SettingsStoreContext,
  useSelectorStore: useSettingsSelectorStore,
} = CreateStore(INITIAL_SETTINGS_STORE, SettingsReducer);

export {
  useSettingsStore,
  SettingsProvider,
  SettingsStoreContext,
  useSettingsSelectorStore,
};
