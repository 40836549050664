export const BASE_PATH = "/analytics";

export const SUBPATHS = {
  COMPANY_OVERVIEW: "/insights",
  INSIGHTS_LEARN: "/insights/learn",
  WORKFORCE_DASHBOARD: "/insights/workforce",
  BENEFITS_DASHBOARD: "/insights/benefits",
  FINANCE_DASHBOARD: "/insights/finance",
  PTO_DASHBOARD: "/insights/PTO",
  REPORTING: "/reporting",
  DATA_EXPORTS: "/reporting/data-exports",
};

export const FULL_PATHS = Object.entries(SUBPATHS).reduce(
  (acc, [key, subpath]) => {
    acc[key] = BASE_PATH + subpath;
    return acc;
  },
  {} as { [key: string]: string }
);

export const VISIER_SRC_SUFFIXES = {
  WORKFORCE_DASHBOARD:
    "/prod/appcontainer#/analytics/myanalyses/c20579ec-2048-4ccf-95ca-e5cabd6e3e64",
  BENEFITS_DASHBOARD:
    "/prod/appcontainer#/analytics/myanalyses/bd5a979d-f11d-43fc-929e-7f0b81d96011",
  FINANCE_DASHBOARD:
    "/prod/appcontainer#/analytics/myanalyses/cd2cb47f-c4e8-4b12-9e3c-c4f4ec13ffa1",
  PTO_DASHBOARD:
    "/prod/appcontainer#/analytics/myanalyses/d5fef069-8e76-419d-97fa-9a93b930ded9",
  DATA_EXPORTS: "/prod/appcontainer#/analytics/dataconnector",
};
