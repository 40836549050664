import { TextInput } from "@justworkshr/milo-form";

interface TextInputProps {
  questionId: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number | readonly string[] | undefined;
}

export const TextInputQuestion = ({
  questionId,
  onChange,
  value,
}: TextInputProps) => {
  const displayedValue = value ? String(value) : "";
  return (
    <TextInput
      name={questionId}
      onChange={onChange}
      value={displayedValue}
    ></TextInput>
  );
};
