import { useParams } from "react-router-dom";
import LeadPage from "./LeadPage";
import ReviewPage from "./ReviewPage";
import NextStepsPage from "./NextStepsPage";

const LandingPage = () => {
  const { stepId } = useParams();
  return (
    <div>
      {stepId === "form" && <LeadPage />}
      {stepId === "review" && <ReviewPage />}
      {stepId === "next-steps" && <NextStepsPage />}
    </div>
  );
};

export default LandingPage;
