import React from "react";
import { Button } from "@justworkshr/milo-core";
import { useNavigate } from "react-router-dom";
import { prependBasePath } from "pages/expenses/constants";
import { ExpenseDialog } from "pages/expenses/components";
import {
  PROJECTS_CANCEL_TITLE,
  PROJECTS_CANCEL_MESSAGE,
} from "../../ProjectsPage.constants";

interface CancelProjectModalProps {
  showCancelProjectModal: boolean;
  handleCancelProjectModal: () => void;
}

export const CancelProjectDialog: React.FC<CancelProjectModalProps> = ({
  showCancelProjectModal,
  handleCancelProjectModal,
}) => {
  const navigate = useNavigate();

  return (
    <ExpenseDialog
      isOpen={showCancelProjectModal}
      onClose={handleCancelProjectModal}
      actions={[
        <Button
          type="button"
          variant="ghost"
          key="cancel-dialog-go-back"
          onClick={handleCancelProjectModal}
          data-testid="go-back-button"
        >
          Go back
        </Button>,
        <Button
          type="button"
          key="cancel-dialog-cancel"
          onClick={() => navigate(prependBasePath("SETTINGS"))}
          data-testid="cancel-button"
        >
          Cancel
        </Button>,
      ]}
    >
      <>
        <h2>{PROJECTS_CANCEL_TITLE}</h2>
        <p>{PROJECTS_CANCEL_MESSAGE}</p>
      </>
    </ExpenseDialog>
  );
};
