import { FormikProps } from "formik";
import { EditableProfileInfoFormType } from "../types";
import CollapsibleCard from "./CollapsibleCard";
import { useTranslation } from "react-i18next";
import styles from "./EditAddressInformation.module.css";
import { Divider } from "@justworkshr/milo-core";
import AddressForm from "pages/employer-of-record/components/InternationalAddressForm/AddressForm";
import { CheckboxInput } from "@justworkshr/milo-form";
import { useState } from "react";
import { isEqual } from "lodash";

type Props = {
  open: boolean;
  toggleOpen: () => void;
  workCountry: string;
} & FormikProps<EditableProfileInfoFormType>;

export default function EditAddressInformation(props: Props) {
  const { workCountry, open, toggleOpen } = props;
  const { values, setFieldValue } = props;
  const { homeAddress, mailingAddress } = values.contactInfo;
  const { t } = useTranslation();
  const [sameAddress, setSameAddress] = useState(
    isEqual(homeAddress, mailingAddress)
  );

  if (sameAddress && !isEqual(homeAddress, mailingAddress)) {
    // Assign all home address values to mailing address
    Object.entries(values.contactInfo.homeAddress).forEach(([key, value]) => {
      setFieldValue(`contactInfo.mailingAddress.${key}`, value);
    });
  }

  return (
    <CollapsibleCard
      noGap
      title={t("Home & mailing address")}
      open={open}
      onClick={toggleOpen}
    >
      <div className={styles.title}>Home address</div>
      <div className={styles.addressContainer}>
        <AddressForm
          formPath="homeAddress"
          supportedCountries={[workCountry]}
          {...props}
        />
      </div>

      <Divider />

      <div className={styles.mailingAddressContainer}>
        <div className={styles.title}>Mailing address</div>
        <div className={styles.addressContainer}>
          <div className={styles.sameAddress}>
            <CheckboxInput
              name="sameAddress"
              label={t("Same as home address")}
              onChange={() => setSameAddress((oldState) => !oldState)}
              checked={sameAddress}
              required={false}
              value={sameAddress ? 1 : 0}
            />
          </div>
          {!sameAddress && <AddressForm formPath="mailingAddress" {...props} />}
        </div>
      </div>
    </CollapsibleCard>
  );
}
