import { ReactElement } from "react";
import styles from "./callout.module.css";
import NavBadge from "../nav-badge";
import { NavBadgeTypeEnum } from "types";
import { buildWebPath, buildStaticAssetPath } from "lib/resource-finder";
import chevronImage from "./icons/arrow-right-thick-rounded-navy.svg";

const { callout, arrow, image, noImage, highlighted } = styles;

interface CalloutProps {
  icon?: string;
  children: string;
  path: string;
  outstandingTaskCount?: null | number;
}

export default function Callout({
  icon,
  path,
  children,
  outstandingTaskCount,
}: CalloutProps): ReactElement {
  let isHighlighted;
  const pathname = window.location.pathname;

  // the path needs to be converted to the format used in clockface before matching against the current location
  if (pathname.includes(path.replace(/_/g, "-"))) {
    isHighlighted = true;
  }

  const clickIndicator = outstandingTaskCount ? (
    <NavBadge
      badgeText={outstandingTaskCount}
      badgeType={NavBadgeTypeEnum.Callout}
    />
  ) : (
    <img className={arrow} src={chevronImage} alt={"arrow right"} />
  );

  return (
    <a
      className={isHighlighted ? `${callout} ${highlighted}` : callout}
      href={buildWebPath(path)}
    >
      {icon ? (
        <img src={buildStaticAssetPath(icon)} alt="" className={image} />
      ) : (
        <></>
      )}
      <h3 className={icon ? "" : noImage}>{children}</h3>
      {clickIndicator}
    </a>
  );
}
