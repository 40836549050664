import { useExpensesResources } from "pages/expenses/hooks";
import styles from "./ExpensesResources.module.css";
import { DEFAULT_DOWNLOAD_PROPS } from "pages/expenses/constants";
import { documentIcon } from "./ExpensesResources.constants";

const { ResourceListWrapper, Subheading } = styles;

export const ExpensesResources = () => {
  const {
    loading,
    data: {
      adminOnePagerUrl,
      employeeOnePagerUrl,
      emailAnnouncementTemplateUrl,
    },
  } = useExpensesResources();

  if (loading) return null;

  return (
    <div>
      <h3 className={Subheading}>Resources</h3>
      <ul className={ResourceListWrapper}>
        <li>
          <a href={adminOnePagerUrl} {...DEFAULT_DOWNLOAD_PROPS}>
            {documentIcon}
            <span>Expenses Product Overview</span>
          </a>
        </li>
        <li>
          <a href={employeeOnePagerUrl} {...DEFAULT_DOWNLOAD_PROPS}>
            {documentIcon}
            <span>Employee Guide to Expenses</span>
          </a>
        </li>
        <li>
          <a href={emailAnnouncementTemplateUrl} {...DEFAULT_DOWNLOAD_PROPS}>
            {documentIcon}
            <span>Email announcement template</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ExpensesResources;
