import React from "react";
import { TextInput } from "@justworkshr/milo-form";
import Tooltip from "@justworkshr/milo-tooltip";
import { getInitialLanguage } from "pages/employer-of-record/components/LanguagePicker/LanguagePicker";
import { formatCentsValue } from "pages/employer-of-record/payments/utils";

interface PaymentAmountInputProps {
  disabled: boolean;
  value?: string;
  name: string;
  className: string;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PaymentAmountInput: React.FC<PaymentAmountInputProps> = ({
  disabled,
  name,
  value = "",
  className,
  onBlur,
  onChange,
}) => {
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e);
    e.target.value = formatCentsValue(value, getInitialLanguage()) || value;
  };

  return disabled ? (
    <Tooltip
      title="You can only schedule one payment at a time for now."
      position="top"
    >
      <TextInput name="" disabled placeholder="0.00" />
    </Tooltip>
  ) : (
    <TextInput
      placeholder="0.00"
      disabled={disabled}
      name={name}
      defaultValue={formatCentsValue(value, getInitialLanguage())}
      className={className}
      onBlur={handleBlur}
      onChange={onChange}
    />
  );
};

export default PaymentAmountInput;
