import {
  ExpensesSettingsManagers,
  ExpensesSettingsAdmins,
  ExpensesSettingsReportsAdmins,
} from "types/Expenses";
import { SystemIcon } from "@justworkshr/milo-icons";

export const generateEmployeeLists = (
  managers: ExpensesSettingsManagers,
  expenseAdmins: ExpensesSettingsAdmins,
  reportsAdmins: ExpensesSettingsReportsAdmins
) => {
  const employeeLists = {
    managers: (managers || []).map((employee) => employee.uuid),
    expenseAdmins: (expenseAdmins || []).map((employee) => employee.uuid),
    reportsAdmins: (reportsAdmins || []).map((employee) => employee.uuid),
  };
  return employeeLists;
};

export const generateEmployeeListForTable = (
  managers: ExpensesSettingsManagers,
  expenseAdmins: ExpensesSettingsAdmins,
  reportsAdmins: ExpensesSettingsReportsAdmins
) => {
  const employeeListForTable = [
    ...(managers || []),
    ...(expenseAdmins || []),
    ...(reportsAdmins || []),
  ].filter(
    (member, index, array) =>
      array.findIndex((value) => value["uuid"] === member["uuid"]) === index
  );
  return employeeListForTable;
};

export const hasManageEmployeeExpensesPermission = (
  managers: string[],
  uuid: string
) => {
  const color = managers.includes(uuid) ? "success" : "disabled";
  const iconName = managers.includes(uuid) ? "check-circle" : "close-circle";
  return <SystemIcon iconName={iconName} color={color} />;
};

export const hasManageExpensesPermission = (
  expenseAdmins: string[],
  uuid: string
) => {
  const color = expenseAdmins.includes(uuid) ? "success" : "disabled";
  const iconName = expenseAdmins.includes(uuid)
    ? "check-circle"
    : "close-circle";
  return <SystemIcon iconName={iconName} color={color} />;
};

export const hasReportsPermission = (reportsAdmins: string[], uuid: string) => {
  const color = reportsAdmins.includes(uuid) ? "success" : "disabled";
  const iconName = reportsAdmins.includes(uuid)
    ? "check-circle"
    : "close-circle";
  return <SystemIcon iconName={iconName} color={color} />;
};
