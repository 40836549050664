import { useGetMemberSeparationQuery } from "types/generated/operations";
import { MemberSeparation } from "../../types";
import { ApolloError } from "@apollo/client";

export const useGetMemberSeparation: (memberUuid: string | undefined) => {
  memberSeparation: MemberSeparation | undefined;
  loading: boolean;
  error?: ApolloError;
} = (memberUuid: string | undefined) => {
  const {
    data: memberSeparationContainer,
    loading,
    error,
  } = useGetMemberSeparationQuery({
    variables: {
      memberUuid: memberUuid as string,
    },
    skip: !memberUuid,
    fetchPolicy: "network-only",
  });
  if (!memberSeparationContainer?.memberSeparation) {
    return { memberSeparation: undefined, loading, error };
  }

  const memberSeparation: MemberSeparation = {
    memberSeparationId:
      memberSeparationContainer.memberSeparation.memberSeparationId,
    companyId: memberSeparationContainer.memberSeparation.companyId,
    earliestPayDate: memberSeparationContainer.memberSeparation.earliestPayDate,
    questionnaireVersion:
      memberSeparationContainer.memberSeparation.questionnaireVersion,
    status: memberSeparationContainer.memberSeparation.status,
    lastUpdatedById: memberSeparationContainer.memberSeparation.lastUpdatedById,
    memberSeparationAnswers:
      memberSeparationContainer.memberSeparation?.memberSeparationAnswers ||
      undefined,
  };

  return { memberSeparation, loading, error };
};
