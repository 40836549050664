import styles from "../../../SeparationPay.module.css";
import {
  isBonusPayment,
  isCommissionPayment,
  isSupplementalPayment,
  renderApplicableWorkPeriodInput,
  renderTaxMethodInput,
  renderTaxesAndDeductionsSection,
} from "../../../AdditionalPaymentsPage";
import {
  COMMISSION_DETAILS_COPY,
  SUPPLEMENTAL_DETAILS_COPY,
} from "./PaymentDetailsForm.constants";
import { useFormikContext } from "formik";
import { TaxMethodInputRow } from "./TaxMethodInputRow";
import { DeductionsSettingInputRow } from "./DeductionsSettingInputRow";
import { BonusTypeInputRow } from "./BonusTypeInputRow";
import { ApplicableWorkPeriodRow } from "./ApplicableWorkPeriodRow";
import { useContext, useEffect } from "react";
import { PaymentDetailsPageContext } from "../../PaymentDetailsPageContextProvider";
import {
  BONUS_COMMISSION_TAX_METHOD_DESCRIPTION_COPY,
  TAX_METHOD_DESCRIPTION_COPY,
} from "./TaxMethodInputRow/TaxMethodInputRow.constants";

const { DetailContainer, SubHeadingLg, SubHeadingMd, HeaderContainer } = styles;

export const DetailsInputSection = ({ paymentKey }: { paymentKey: number }) => {
  const {
    values: { type },
    handleSubmit,
  } = useFormikContext<Record<string, string>>();

  const { setSubmitHandlers } = useContext(PaymentDetailsPageContext);

  useEffect(() => {
    // @ts-expect-error: un-typable setState function
    setSubmitHandlers?.((prevState) => ({
      ...prevState,
      [paymentKey]: handleSubmit,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPaymentDetailsDescription = (type: string) => {
    if (isSupplementalPayment(type)) {
      return SUPPLEMENTAL_DETAILS_COPY;
    } else if (isBonusPayment(type)) {
      return (
        <>
          These are taxable wage payments which are only available for full-time
          and part-time employees. Contractors and consultants are not eligible
          for wage payments, including bonus wage payments.{" "}
          <a href="https://help.justworks.com/hc/en-us/articles/360004527871-Supplemental-Payments">
            Learn how bonus payments work
          </a>{" "}
          at the Help Center.
        </>
      );
    } else if (isCommissionPayment(type)) {
      return COMMISSION_DETAILS_COPY;
    }
  };

  const renderTitleCopy = (type: string) => {
    let paymentTypeCopy = type.charAt(0).toUpperCase() + type.slice(1);

    if (isSupplementalPayment(type)) {
      paymentTypeCopy += " pay";
    }

    return paymentTypeCopy;
  };

  return (
    <div
      data-testid={`payment-details-${paymentKey}`}
      className={DetailContainer}
    >
      <div className={HeaderContainer}>
        <div className={SubHeadingLg}>{renderTitleCopy(type)} details</div>
        <div>{renderPaymentDetailsDescription(type)}</div>
      </div>
      {renderApplicableWorkPeriodInput(type) && <ApplicableWorkPeriodRow />}
      {isBonusPayment(type) && <BonusTypeInputRow />}
      {renderTaxesAndDeductionsSection(type) && (
        <div>
          <div className={HeaderContainer}>
            <div className={SubHeadingMd}>Taxes and deductions</div>
            <div>
              {isBonusPayment(type) || isCommissionPayment(type)
                ? BONUS_COMMISSION_TAX_METHOD_DESCRIPTION_COPY
                : TAX_METHOD_DESCRIPTION_COPY}
            </div>
          </div>
          {renderTaxMethodInput(type) && <TaxMethodInputRow />}
          <DeductionsSettingInputRow />
        </div>
      )}
    </div>
  );
};
