import React from "react";
import { InitialIntakeForm } from "./components";
import styles from "./InitialIntakePage.module.css";

const { formWrapper } = styles;

export const InitialIntakePage = () => {
  return (
    <div id="InitialIntakePage" className={formWrapper}>
      <InitialIntakeForm />
    </div>
  );
};
