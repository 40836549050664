import { useState } from "react";
import { useGetMemberSeparationDocumentsQuery } from "types/generated/operations";
import { MemberSeparationDocuments } from "../../types";
import { DocumentListInterface } from "pages/team-management/components/DocumentInput/helper/DocumentList";

export const useGetMemberSeparationDocuments = (
  documentIds: string[],
  documentList: DocumentListInterface
) => {
  const [loading, setLoading] = useState(true);
  const [existingDocuments, setExistingDocuments] = useState<
    MemberSeparationDocuments[]
  >([]);
  const { error } = useGetMemberSeparationDocumentsQuery({
    variables: { documentIds },
    onCompleted(data) {
      const memberSeparationDocuments =
        data?.memberSeparationDocuments?.map((document) => ({
          id: document.id,
          uuid: document.uuid,
          title: document.title,
          downloadUrl: document.downloadUrl,
        })) || [];

      setExistingDocuments(memberSeparationDocuments);
      documentList.addExistingDocumentsMap(memberSeparationDocuments);
      setLoading(false);
    },
  });

  return { existingDocuments, loading, error };
};
