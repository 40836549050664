import { Box, Button } from "@justworkshr/milo-core";
import styles from "./MetlifeInformationPanel.module.css";
import { Link } from "react-router-dom";
import MetlifeSmallLogo from "../../images/metlife-horizontal-logo.svg";
import { INFO_PANEL_CONTENT } from "../../constants/pdpGeneral";

const {
  insuranceDetailsPanelContent,
  horizontalLogo,
  detailsPanelButtons,
  setUpButtonText,
  insuranceDetailsPanelContentChunk,
  planEffectiveButton,
  planEffectiveButtonText,
} = styles;

export type metlifeProps = {
  route: string;
  planExists?: boolean;
};

export const MetlifeInformationPanel: React.FC<metlifeProps> = ({
  route,
  planExists,
}) => {
  return (
    <Box
      padding="2xl"
      border={{
        borderWidth: "sm",
        borderColor: "neutralSubtle",
        borderRadius: "md",
      }}
    >
      <div className={insuranceDetailsPanelContent}>
        <img
          className={horizontalLogo}
          src={MetlifeSmallLogo}
          alt="horizontal Metlife logo"
        />

        {Object.values(INFO_PANEL_CONTENT).map(
          (item: { title: string; description: string; id: number }) => (
            <div key={item.id} className={insuranceDetailsPanelContentChunk}>
              <strong>{item.title}</strong>
              <br></br>
              {item.description}
            </div>
          )
        )}
        {planExists ? (
          <Button className={planEffectiveButton} disabled>
            <span className={planEffectiveButtonText}>You're enrolled</span>
          </Button>
        ) : (
          <Button
            className={detailsPanelButtons}
            color="brand"
            as={Link}
            to={route}
          >
            <span className={setUpButtonText}>Get Started</span>
          </Button>
        )}
      </div>
    </Box>
  );
};
