import { FeedbackBannerStoreType } from "../constants";

export enum FeedbackBannerActionTypes {
  SET_FEEDBACKBANNER = "SET_FEEDBACKBANNER",
}

export type FeedbackBannerActions = {
  type: FeedbackBannerActionTypes;
  payload: FeedbackBannerStoreType;
};
