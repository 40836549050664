import { PageHeader, Button } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import { FC, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FULL_PATHS } from "../../constants";
import { useInsightsLearnPageViewContext } from "../../contexts/insights-learn-page-view-context";
import WorkforceMetricsCard from "../workforce-metrics-card";
import FinanceMetricsCard from "../finance-metrics-card";
import PtoMetricsCard from "../pto-metrics-card";
import BenefitsMetricsCard from "../benefits-metrics-card";
import styles from "./company-overview-page.module.css";

const CompanyOverviewPage: FC = () => {
  const { hasViewedInsightsLearnPage } = useInsightsLearnPageViewContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasViewedInsightsLearnPage) {
      navigate(FULL_PATHS.INSIGHTS_LEARN);
    }
  }, [hasViewedInsightsLearnPage, navigate]);

  return (
    <div
      className={`milo--grid ${styles.companyOverviewPage}`}
      data-testid="company-overview-page"
    >
      <PageHeader className={styles.pageHeader} border={false} title="Insights">
        Make data-driven operational and strategic decisions to gain an edge in
        hiring and retention and improve your workforce’s overall health.{" "}
        <Link to={FULL_PATHS.INSIGHTS_LEARN}>Learn more about Insights.</Link>
      </PageHeader>
      <section className={styles.metricsHeader}>
        <h2>Company overview</h2>
        <p className={styles.refreshDisclaimer}>
          <SystemIcon iconName="warning" />
          Data refreshed nightly
        </p>
      </section>
      <WorkforceMetricsCard />
      <FinanceMetricsCard />
      <PtoMetricsCard />
      <BenefitsMetricsCard />
      <section className={styles.reportingCard}>
        <h2>Data Exports</h2>
        <p>
          Get more from your data with fully customizable reports enabled by our
          trusted partner, Visier.{" "}
          <Link
            to="https://help.justworks.com/hc/en-us/articles/22464441168667"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about Data Exports.
          </Link>
        </p>
        <Button
          variant="outlined"
          as={Link}
          to={FULL_PATHS.DATA_EXPORTS}
          state={{ backPath: FULL_PATHS.COMPANY_OVERVIEW }}
        >
          Go to Data Exports
        </Button>
      </section>
    </div>
  );
};

export default CompanyOverviewPage;
