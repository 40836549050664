import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  defaultDataIdFromObject,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  StoreValue,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { oktaAuth, pkceIsEnabled } from "app/okta";

import { buildGraphQLPath, buildClockworkWebPath } from "lib/resource-finder";

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    let result = defaultDataIdFromObject(responseObject);

    if (responseObject.uuid !== null && responseObject.uuid !== undefined) {
      const recordUuid: StoreValue = responseObject.uuid;

      if (responseObject.__typename) {
        result = `${responseObject.__typename}:${recordUuid as string}`;
      }

      if (responseObject.__typename === "ExpenseManagement") {
        result = responseObject.__typename;
      }
    }

    return result;
  },
  typePolicies: {
    AllMembers: {
      fields: {
        capabilities: {
          merge: true,
        },
      },
    },
  },
});

// https://www.apollographql.com/docs/react/networking/advanced-http-networking/#customizing-request-logic
const addRequestOriginHeaderMiddleware = new ApolloLink(
  (operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      const accessToken = pkceIsEnabled() ? oktaAuth.getAccessToken() : null;
      return {
        headers: {
          ...headers,
          // when added to clockwork logging and datadog trace outputs will allow team members
          // to more accurately determine the page-source of an error in GraphQL
          "x-justworks-request-origin": document.location.href,
          authorization: accessToken ? `Bearer ${accessToken}` : "",
        },
      };
    });

    return forward(operation);
  }
);

const httpLink = createHttpLink({
  uri: buildGraphQLPath("/graphql"),
  credentials: "include",
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions?.code === 401) {
        window.location.assign(
          buildClockworkWebPath(
            `/login?redirect=${encodeURIComponent(window.location.href)}`
          )
        );
      }
    });
  }
});

const createApolloClient = () => {
  return new ApolloClient({
    link: from([addRequestOriginHeaderMiddleware, errorLink, httpLink]),
    cache,
  });
};

export function getApolloClient(): ApolloClient<NormalizedCacheObject> {
  if (!apolloClient) {
    apolloClient = createApolloClient();
  }
  return apolloClient;
}
