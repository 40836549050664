import { FieldConfig } from "../../../../SetupFormGenerator";
import { COMPONENTLIST } from "../../../../SetupFormGenerator/constants";

export const PAYMENT_SETTINGS_FORM_FIELDS: FieldConfig[] = [
  {
    label: "Pay date",
    name: "payPeriodId",
    type: COMPONENTLIST.SELECT,
    options: [{ value: "", label: "" }],
    required: true,
  },
  {
    label: "Internal Notes",
    name: "notes",
    type: COMPONENTLIST.TEXTAREA,
    required: false,
    message: { text: "Only visible to admins" },
  },
];
