/* istanbul ignore file */
import { useCallback } from "react";
import { useRequestDetailSelectorStore } from "../../store";
import { RequestDetailActionTypes } from "../../actions";
import {
  SetRequestDetailPayload,
  SetRequestDetailExpensesPayload,
} from "../../constants";
import { RequestDetailExpense } from "types/Expenses";

export const useRequestDetail = () => {
  const { store: requestDetail, dispatch } = useRequestDetailSelectorStore(
    (store) => store.RequestDetail
  );

  const resetRequestDetails = useCallback(
    () =>
      dispatch({
        type: RequestDetailActionTypes.RESET_REQUEST_DETAILS,
      }),
    [dispatch]
  );

  const setRequestDetail = useCallback(
    (payload: SetRequestDetailPayload) =>
      dispatch({
        type: RequestDetailActionTypes.SET_REQUEST_DETAIL,
        payload,
      }),
    [dispatch]
  );

  const setIsUnattachedExpenseView = useCallback(
    (payload: boolean) =>
      dispatch({
        type: RequestDetailActionTypes.SET_IS_UNATTACHED_VIEW,
        payload,
      }),
    [dispatch]
  );

  const setRequestDetailExpenses = useCallback(
    (payload: SetRequestDetailExpensesPayload) =>
      dispatch({
        type: RequestDetailActionTypes.SET_REQUEST_DETAIL_EXPENSES,
        payload,
      }),
    [dispatch]
  );

  const setCustomPayDate = useCallback(
    (payload: string) =>
      dispatch({
        type: RequestDetailActionTypes.SET_CUSTOM_PAYDATE,
        payload,
      }),
    [dispatch]
  );

  const setIsCustomPayDateValid = useCallback(
    (payload: boolean) =>
      dispatch({
        type: RequestDetailActionTypes.SET_IS_CUSTOM_PAYDATE_VALID,
        payload,
      }),
    [dispatch]
  );

  const setDetachedExpenses = useCallback(
    (
      updateFn: (
        detachedExpenses: RequestDetailExpense[]
      ) => RequestDetailExpense[]
    ) =>
      dispatch({
        type: RequestDetailActionTypes.SET_REQUEST_DETAILS_DETACHED_EXPENSES,
        payload: updateFn(requestDetail.metadata.detachedExpenses),
      }),
    [dispatch, requestDetail.metadata.detachedExpenses]
  );

  return {
    requestDetail,
    setRequestDetail,
    setCustomPayDate,
    resetRequestDetails,
    setDetachedExpenses,
    setIsCustomPayDateValid,
    setRequestDetailExpenses,
    setIsUnattachedExpenseView,
  };
};
