import React from "react";
import styles from "../learn/partner-description-page.module.css";
import styles_new from "../learn/basicLifeAndAddTabsContentTemplate.module.css";
import { useState, useEffect } from "react";
import { HEADER_INFO, ROUTES } from "../constants/pdpGeneral";
import { PageHeader, Button } from "@justworkshr/milo-core";
import {
  RadioButtonGroup,
  RadioButton,
  ActionFooter,
  Select,
  SelectGroup,
  SelectOption,
} from "@justworkshr/milo-form";
import Divider from "../components/Divider/Divider";
import Notice from "../components/Notice/Notice";
import { ResourceCard } from "./ResourceCard/ResourceCard";
import { OPTIONS_AND_COSTS_CONTENT } from "../constants/basicLifeAndAddTabContent";
import { BASIC_AND_ADD_CALCULATION_PLAN_DETAILS } from "../constants/EnrollmentPageContent";
import { Link, useNavigate } from "react-router-dom";
import {
  generateEffectiveDateOptions,
  resolveOpenEnrollmentWindow,
  openEnrollmentNoticeContent,
} from "../utils/utils";
import { openEnrollmentWindowDates } from "../../types/index";

const { headerBanner, leftColumn, rightColumn, footer } = styles;

const {
  headerTextSecond,
  contentDivision,
  policyEffectivenessNote,
  policyEffectivenessDate,
  dateSelect,
  setupFooter,
  enrollmentInfoCardHeader,
  enrollmentInfoCard,
  reviewButtonText,
  enrollmentInfoCardContent,
  framedRadioButton,
  framedRadioButtonSelected,
  infoBox,
  tableContainer,
  tableRowLight,
  headerTextCoverageOptions,
  enrollmentInfoOutline,
} = styles_new;

export type costCalculationContent = {
  [key: string]: string;
};

export type costTableBenefitOption = {
  [key: string]: string | null;
};

export type planOptionData = {
  id: string;
  isClicked: boolean;
};

export const BasicLifeAndADDEnrollmentSetupPage: React.FC = () => {
  const [basicLifeOptionOneClicked, setBasicLifeOptionOneClicked] =
    useState<planOptionData>({ id: "option_1", isClicked: false });
  const [basicLifeOptionTwoClicked, setBasicLifeOptionTwoClicked] =
    useState<planOptionData>({ id: "option_2", isClicked: false });
  const [basicLifeOptionThreeClicked, setBasicLifeOptionThreeClicked] =
    useState<planOptionData>({ id: "option_3", isClicked: false });
  const [basicLifeOptionFourClicked, setBasicLifeOptionFourClicked] =
    useState<planOptionData>({ id: "option_4", isClicked: false });
  const [basicLifeOptionFiveClicked, setBasicLifeOptionFiveClicked] =
    useState<planOptionData>({ id: "option_5", isClicked: false });
  const [basicLifeOptionSixClicked, setBasicLifeOptionSixClicked] =
    useState<planOptionData>({ id: "option_6", isClicked: false });

  const basicLifeOptions: Array<planOptionData> = [
    basicLifeOptionOneClicked,
    basicLifeOptionTwoClicked,
    basicLifeOptionThreeClicked,
    basicLifeOptionFourClicked,
    basicLifeOptionFiveClicked,
    basicLifeOptionSixClicked,
  ];

  const hasNoBasicLifeOptionSelected: boolean = basicLifeOptions.every(
    (basicLifeOption) => basicLifeOption.isClicked === false
  );

  const [suppLifeOptionOneClicked, setSuppLifeOptionOneClicked] =
    useState<boolean>(false);
  const [suppLifeOptionTwoClicked, setSuppLifeOptionTwoClicked] =
    useState<boolean>(false);

  const hasSuppLifeOptionSelected: boolean =
    suppLifeOptionOneClicked || suppLifeOptionTwoClicked;

  const [shouldShowBasicEffectiveDatePicker, setBasicEffectiveDatePicker] =
    useState<boolean>(false);

  const [
    shouldShowSuppLifeEffectiveDatePicker,
    setSuppLifeEffectiveDatePicker,
  ] = useState<boolean>(false);

  const [basicEffectiveDate, setBasicEffectiveDate] = useState<string | null>(
    null
  );

  const [suppLifeEffectiveDate, setSuppLifeEffectiveDate] = useState<
    string | null
  >(null);
  const [benefitInfo, setBenefitInfo] = useState<costTableBenefitOption | null>(
    null
  );

  const [suppLifeBenefitInfo, setSuppLifeBenfitInfo] =
    useState<costTableBenefitOption | null>(null);

  const availableEffectiveDates = generateEffectiveDateOptions();

  const [openEnrollmentWindow, setOpenEnrollmentWindow] =
    useState<openEnrollmentWindowDates | null>(null);

  useEffect(() => {
    if (hasNoBasicLifeOptionSelected) {
      setBasicEffectiveDate(null);
    }

    setBasicEffectiveDatePicker(
      !hasNoBasicLifeOptionSelected && !basicLifeOptionSixClicked.isClicked
    );
  }, [
    hasNoBasicLifeOptionSelected,
    basicLifeOptionSixClicked.isClicked,
    basicEffectiveDate,
  ]);

  useEffect(() => {
    if (suppLifeEffectiveDate !== null) {
      const openEnrollmentWindow = resolveOpenEnrollmentWindow(
        suppLifeEffectiveDate
      );
      setOpenEnrollmentWindow({
        startDate: openEnrollmentWindow.startDate,
        endDate: openEnrollmentWindow.endDate,
      });
    }
  }, [suppLifeEffectiveDate]);

  const incompleteBasicLifeInput: boolean =
    basicEffectiveDate === null || benefitInfo === null;

  const incompleteSuppLifeInput: boolean =
    suppLifeEffectiveDate === null || suppLifeBenefitInfo === null;

  const setSuppLifeSelectedOption = (option: string): void => {
    setSuppLifeEffectiveDatePicker(
      option !== "option_2" && !suppLifeOptionOneClicked
    );
    if (option === "option_1") {
      setSuppLifeEffectiveDate(null);
    }
    const suppLifeOption =
      option === "option_1"
        ? OPTIONS_AND_COSTS_CONTENT.supplementaryLifeEnrollment.optionOne
        : null;
    setSuppLifeBenfitInfo(suppLifeOption);
    handleSuppLifeOptionClick(option);
  };

  const setEmployerOptionClicked = (option: string, value: boolean): void => {
    switch (option) {
      case "option_1":
        setBasicLifeOptionOneClicked((prevState) => ({
          ...prevState,
          isClicked: value,
        }));
        break;
      case "option_2":
        setBasicLifeOptionTwoClicked((prevState) => ({
          ...prevState,
          isClicked: value,
        }));
        break;
      case "option_3":
        setBasicLifeOptionThreeClicked((prevState) => ({
          ...prevState,
          isClicked: value,
        }));
        break;
      case "option_4":
        setBasicLifeOptionFourClicked((prevState) => ({
          ...prevState,
          isClicked: value,
        }));
        break;
      case "option_5":
        setBasicLifeOptionFiveClicked((prevState) => ({
          ...prevState,
          isClicked: value,
        }));
        break;
      case "option_6":
        setBasicLifeOptionSixClicked((prevState) => ({
          ...prevState,
          isClicked: value,
        }));
        break;
    }
  };

  const handleOptionClick = (option: string): void => {
    const selectedOption = basicLifeOptions.find(
      (basicLifeOption) => basicLifeOption.id === option
    );
    selectedOption?.isClicked
      ? setEmployerOptionClicked(option, false)
      : setEmployerOptionClicked(option, true);

    const optionsToUnselect = basicLifeOptions.filter(
      (basicLifeOption) => basicLifeOption.id !== option
    );
    optionsToUnselect.map((unselectOption) =>
      setEmployerOptionClicked(unselectOption.id, false)
    );
  };

  const getOptionClickedStatus = (option: string): boolean | undefined => {
    const selectedOption = basicLifeOptions.find(
      (basicLifeOption) => basicLifeOption.id === option
    );
    return selectedOption?.isClicked;
  };

  const setSelectedOption = (option: string): void => {
    switch (option) {
      case "option_1":
        setBenefitInfo(
          OPTIONS_AND_COSTS_CONTENT.costTableInfo.coverageAmountOne
        );
        handleOptionClick(option);
        break;
      case "option_2":
        setBenefitInfo(
          OPTIONS_AND_COSTS_CONTENT.costTableInfo.coverageAmountTwo
        );
        handleOptionClick(option);
        break;
      case "option_3":
        setBenefitInfo(
          OPTIONS_AND_COSTS_CONTENT.costTableInfo.coverageAmountThree
        );
        handleOptionClick(option);
        break;
      case "option_4":
        setBenefitInfo(
          OPTIONS_AND_COSTS_CONTENT.costTableInfo.coverageAmountFour
        );
        handleOptionClick(option);
        break;
      case "option_5":
        setBenefitInfo(
          OPTIONS_AND_COSTS_CONTENT.costTableInfo.coverageAmountFive
        );
        handleOptionClick(option);
        break;
      case "option_6":
        handleOptionClick(option);
        setBasicEffectiveDate(null);
        setBenefitInfo(null);
        break;
    }
  };

  const handleSuppLifeOptionClick = (option: string): void => {
    switch (option) {
      case "option_1":
        suppLifeOptionOneClicked
          ? setSuppLifeOptionOneClicked(false)
          : setSuppLifeOptionOneClicked(true);
        setSuppLifeOptionTwoClicked(false);
        break;
      case "option_2":
        suppLifeOptionTwoClicked
          ? setSuppLifeOptionTwoClicked(false)
          : setSuppLifeOptionTwoClicked(true);
        setSuppLifeOptionOneClicked(false);
        break;
    }
  };

  const getSuppLifeOptionClickedStatus = (option: string): boolean => {
    const suppLifeOptionClicked: boolean =
      option === "option_1"
        ? suppLifeOptionOneClicked
        : suppLifeOptionTwoClicked;
    return suppLifeOptionClicked;
  };

  const navigate = useNavigate();

  const handleReviewClick = () => {
    navigate(ROUTES.reviewPage, {
      state: {
        effectiveDate: basicEffectiveDate,
        benefitData: benefitInfo,
        suppLifeEffectiveDate: suppLifeEffectiveDate,
        suppLifeData: suppLifeBenefitInfo,
      },
    });
  };

  const coverageOptionsTableData = OPTIONS_AND_COSTS_CONTENT.costTableInfo;

  return (
    <>
      <div className="milo--grid">
        <div className={headerBanner}>
          <PageHeader title="Set up life insurance">
            {HEADER_INFO.basicLifeAndADDDescription}
          </PageHeader>
        </div>
        <div className={leftColumn}>
          <p className={headerTextCoverageOptions}>
            Basic life and AD&D coverage options
          </p>
          <table className={tableContainer}>
            <thead>
              <tr>
                <td>
                  <strong>Coverage amount</strong>
                </td>
                <td>
                  <strong>Costs</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              {/* TODO: map over OPTIONS_AND_COSTS_CONTENT hash to simplify*/}
              <tr className={tableRowLight}>
                <td>
                  {coverageOptionsTableData.coverageAmountOne.coverageAmount}
                </td>
                <td>{coverageOptionsTableData.coverageAmountOne.costs}</td>
              </tr>
              <tr>
                <td>
                  {coverageOptionsTableData.coverageAmountTwo.coverageAmount}
                </td>
                <td>{coverageOptionsTableData.coverageAmountTwo.costs}</td>
              </tr>
              <tr className={tableRowLight}>
                <td>
                  {coverageOptionsTableData.coverageAmountThree.coverageAmount}
                </td>
                <td>{coverageOptionsTableData.coverageAmountThree.costs}</td>
              </tr>
              <tr>
                <td>
                  {coverageOptionsTableData.coverageAmountFour.coverageAmount}
                </td>
                <td>{coverageOptionsTableData.coverageAmountFour.costs}</td>
              </tr>
              <tr className={tableRowLight}>
                <td>
                  {coverageOptionsTableData.coverageAmountFive.coverageAmount}
                </td>
                <td>{coverageOptionsTableData.coverageAmountFive.costs}</td>
              </tr>
            </tbody>
          </table>

          <p className={headerTextSecond}>
            Which policy would you like to offer your team?
          </p>
          <div>
            <RadioButtonGroup name="basic_life_benefit_type_selection">
              {Object.values(coverageOptionsTableData).map(
                (benefitOption: {
                  optionId: string;
                  coverageAmount: string;
                }) => (
                  <div className={infoBox} key={benefitOption.optionId}>
                    <button
                      className={
                        getOptionClickedStatus(benefitOption.optionId)
                          ? framedRadioButtonSelected
                          : framedRadioButton
                      }
                      id={benefitOption.optionId}
                      onClick={() => setSelectedOption(benefitOption.optionId)}
                      type="button"
                    >
                      <RadioButton
                        name="basic_life_benefit_type_selection"
                        value={benefitOption.optionId}
                        label={benefitOption.coverageAmount}
                        id={benefitOption.optionId}
                        checked={getOptionClickedStatus(benefitOption.optionId)}
                        onChange={() =>
                          setSelectedOption(benefitOption.optionId)
                        }
                      ></RadioButton>
                    </button>
                  </div>
                )
              )}
            </RadioButtonGroup>
          </div>
          <div className={contentDivision}></div>
          {shouldShowBasicEffectiveDatePicker && (
            <div>
              <p className={headerTextSecond}>
                {BASIC_AND_ADD_CALCULATION_PLAN_DETAILS.basicLifeEffectiveDate}
              </p>
              <p className={policyEffectivenessNote}>
                {
                  BASIC_AND_ADD_CALCULATION_PLAN_DETAILS.basicLifeEffectiveDateEligiblity
                }
              </p>
              <p className={policyEffectivenessDate}>Policy effective date</p>
              <div className={dateSelect}>
                <Select
                  placeholder="Select a month"
                  onChange={(event) =>
                    setBasicEffectiveDate(event.target.value)
                  }
                >
                  <SelectGroup label="">
                    {availableEffectiveDates.map(
                      (effectiveDateOption, index) => (
                        <SelectOption key={index + 1}>
                          {effectiveDateOption}
                        </SelectOption>
                      )
                    )}
                  </SelectGroup>
                </Select>
              </div>
            </div>
          )}
          <Divider />
          <p className={headerTextSecond}>
            {
              BASIC_AND_ADD_CALCULATION_PLAN_DETAILS.supplementaryLifeOfferQuestion
            }
          </p>
          <p className={policyEffectivenessNote}>
            {BASIC_AND_ADD_CALCULATION_PLAN_DETAILS.supplementaryLifeOfferInfo}
          </p>
          <RadioButtonGroup name="supp_life_plan_selection">
            {Object.values(
              OPTIONS_AND_COSTS_CONTENT.supplementaryLifeEnrollment
            ).map((benefitOption: { optionId: string; option: string }) => (
              <div className={infoBox} key={benefitOption.optionId}>
                <button
                  className={
                    getSuppLifeOptionClickedStatus(benefitOption.optionId)
                      ? framedRadioButtonSelected
                      : framedRadioButton
                  }
                  id={benefitOption.optionId}
                  onClick={() =>
                    setSuppLifeSelectedOption(benefitOption.optionId)
                  }
                  type="button"
                >
                  <RadioButton
                    name="supp_life_plan_selection"
                    value={benefitOption.optionId}
                    label={benefitOption.option}
                    id={benefitOption.optionId}
                    checked={getSuppLifeOptionClickedStatus(
                      benefitOption.optionId
                    )}
                    onChange={() =>
                      setSuppLifeSelectedOption(benefitOption.optionId)
                    }
                  ></RadioButton>
                </button>
              </div>
            ))}
          </RadioButtonGroup>
          {shouldShowSuppLifeEffectiveDatePicker && (
            <div>
              <p className={headerTextSecond}>
                {
                  BASIC_AND_ADD_CALCULATION_PLAN_DETAILS.supplementaryLifeEffectiveDate
                }
              </p>
              <p className={policyEffectivenessNote}>
                {
                  BASIC_AND_ADD_CALCULATION_PLAN_DETAILS.supplementaryLifeEffectiveDateEligiblity
                }
              </p>
              <p className={policyEffectivenessDate}>Policy effective date</p>
              <div className={dateSelect}>
                <Select
                  placeholder="Select a month"
                  onChange={(event) =>
                    setSuppLifeEffectiveDate(event.target.value)
                  }
                >
                  <SelectGroup label="">
                    {availableEffectiveDates.map(
                      (effectiveDateOption, index) => (
                        <SelectOption key={index + 1}>
                          {effectiveDateOption}
                        </SelectOption>
                      )
                    )}
                  </SelectGroup>
                </Select>
              </div>
            </div>
          )}

          {shouldShowSuppLifeEffectiveDatePicker &&
            suppLifeEffectiveDate !== null &&
            openEnrollmentWindow !== null && (
              <div className={enrollmentInfoCard}>
                <section>
                  <div className={enrollmentInfoOutline}>
                    <p className={enrollmentInfoCardHeader}>Enrollment Info</p>
                    <p className={enrollmentInfoCardContent}>
                      {openEnrollmentNoticeContent(
                        openEnrollmentWindow.startDate,
                        openEnrollmentWindow.endDate,
                        suppLifeEffectiveDate
                      )}
                    </p>
                  </div>
                </section>
              </div>
            )}
          {basicLifeOptionSixClicked.isClicked && suppLifeOptionTwoClicked && (
            <Notice
              isStd={false}
              content={
                BASIC_AND_ADD_CALCULATION_PLAN_DETAILS.lifeInsuranceDeclinedInfo
              }
            />
          )}
          <div className={setupFooter}>
            {" "}
            <ActionFooter
              actions={[
                <Button
                  color="brand"
                  key="review"
                  onClick={handleReviewClick}
                  disabled={
                    (incompleteBasicLifeInput && incompleteSuppLifeInput) ||
                    (hasNoBasicLifeOptionSelected &&
                      hasSuppLifeOptionSelected &&
                      suppLifeEffectiveDate !== null) ||
                    (incompleteSuppLifeInput &&
                      !suppLifeOptionTwoClicked &&
                      !hasNoBasicLifeOptionSelected &&
                      basicEffectiveDate !== null) ||
                    (!hasNoBasicLifeOptionSelected &&
                      !basicLifeOptionSixClicked.isClicked &&
                      basicEffectiveDate === null) ||
                    (!shouldShowBasicEffectiveDatePicker &&
                      !shouldShowSuppLifeEffectiveDatePicker)
                  }
                >
                  <span className={reviewButtonText}>Review</span>
                </Button>,
              ]}
              secondary={[
                <Button
                  variant="outlined"
                  color="brand"
                  as={Link}
                  to={ROUTES.basicAndADDPdpPage}
                  key="go_back"
                >
                  Back
                </Button>,
              ]}
            />
          </div>
        </div>
        <div className={rightColumn}>
          <ResourceCard selectedBenefit="basicLifeAndADD" />
        </div>
      </div>
      <div className={footer}></div>
    </>
  );
};
