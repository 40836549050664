import { Button } from "@justworkshr/milo-core";

const FormContinue = ({
  backFn,
  continueFn,
  textContinue,
  textBack,
  loading,
  alignment = "center",
  shouldDisable = false,
  continueKey,
  backKey,
}: {
  backFn?: () => void;
  continueFn?: () => void;
  shouldDisable?: boolean;
  textContinue?: string | undefined;
  textBack?: string | undefined;
  loading?: boolean | undefined;
  alignment?: "start" | "end" | "center" | "space-between";
  continueKey?: string;
  backKey?: string;
}) => {
  const styles = {
    formSubmitButtons: {
      gap: "10px",
      display: "grid",
      gridAutoFlow: "column",
      justifyContent: alignment,
      borderRadius: "0px",
    },
  };

  return (
    <footer style={styles.formSubmitButtons} data-testid="form-continue">
      {backFn && (
        <Button
          onClick={backFn}
          type="button"
          color="brand"
          variant="ghost"
          size="sm"
          data-testid="submit-back-button"
          key={backKey}
        >
          {textBack ? textBack : "Back"}
        </Button>
      )}
      <Button
        onClick={continueFn ? continueFn : undefined}
        type="submit"
        color="brand"
        variant="filled"
        size="sm"
        disabled={shouldDisable}
        style={textContinue ? { borderRadius: "5px" } : {}}
        loading={loading}
        data-testid="submit-continue-button"
        key={continueKey}
      >
        {textContinue ? textContinue : "Continue"}
      </Button>
    </footer>
  );
};

export default FormContinue;
