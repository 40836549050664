import { IntegrationsConfig } from "types/IntegrationConfig";
import { ACCOUNTING_PERMISSIONS } from "pages/pmt/integrations/integration-marketplace/constants";
import sageIntacctLogo from "pages/pmt/integrations/images/integration-partner-logos/sage-intacct-logo.png";

// Sage Intacct uses a separate PDP and setup flow. Only the marketplaceCard is used from this file.
export const sageIntacctConfig: IntegrationsConfig = {
  numAllowedInstances: 1,
  copy: {
    marketplaceCard: {
      logoUrl: sageIntacctLogo,
      integrationName: "Sage Intacct",
      integrationDescription:
        "Seamlessly connect business systems for streamlined operations",
      integrationCategory: "Accounting",
      requiredPermissions: ACCOUNTING_PERMISSIONS,
      slug: "sage-intacct",
      pdpUrl: "/sage_intacct/settings",
    },
    pdp: {
      headerDescription: "",
      heroImageUrl: "",
      horizontalLogoUrl: "",
      resourceCard: {
        links: [],
      },
      infoTabsConfig: {},
      infoTabsContent: {
        overviewTab: {
          header: "",
          subheader: "",
          list: [
            {
              summary: "",
              detail: "",
            },
          ],
        },
      },
      disconnectModalContent: {
        title: "",
        body: <div></div>,
        disconnectButtonText: "",
      },
      allowedPdpActions: [],
    },
    setup: {
      estimatedSetupTime: "",
      helpCenterLink: "",
      stepperConfig: {},
      steps: [],
      instanceKeys: [],
      getCurrentStepFunction: () => 0,
    },
  },
};
