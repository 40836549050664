import React, { useRef, useState } from "react";
import {
  TOOLTIP_HORIZONTAL_ALIGNMENTS,
  generateRandomIdForAria,
} from "./constants";

import styles from "./tooltip.module.css";

export type TooltipProps = {
  content: React.ReactNode;

  arrowAlignment: TOOLTIP_HORIZONTAL_ALIGNMENTS;

  children: React.ReactNode;

  id: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  targetRefOverride?: React.RefObject<any> | null;
  visibility?: React.CSSProperties["visibility"];
};

export type PositionType = {
  top?: number | string;
  bottom?: number | string;
  left?: number | string;
  right?: number | string;
};

const Tooltip: React.FC<TooltipProps> = ({
  content,
  children,
  id,
  arrowAlignment = TOOLTIP_HORIZONTAL_ALIGNMENTS.CENTER,
  targetRefOverride,
  visibility,
}) => {
  const containerRef = useRef<HTMLSpanElement | null>(null);
  const targetRef =
    targetRefOverride === undefined ? containerRef : targetRefOverride;

  const contentRef = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState<PositionType>({ top: 0, left: 0 });
  const generatedId: string = generateRandomIdForAria();
  const tooltipId = id || generatedId;

  const handleCenterTooltip = () => {
    const newPosition = findCoordinates();
    if (!newPosition) return;
    setPosition(newPosition);
  };

  const getContentSize = () => {
    return {
      height: contentRef.current?.offsetHeight,
      width: contentRef.current?.offsetWidth,
    };
  };

  const findCoordinates = () => {
    const content = getContentSize();
    if (containerRef.current) {
      const targetRefPosition = targetRef?.current?.getBoundingClientRect();
      const containerRefPosition =
        containerRef?.current?.getBoundingClientRect();

      const offsets = {
        top: targetRefPosition?.top - containerRefPosition?.top || 0,
        left: targetRefPosition?.left - containerRefPosition?.left || 0,
        right: targetRefPosition?.right - containerRefPosition?.right || 0,
        bottom: targetRefPosition?.bottom - containerRefPosition?.bottom || 0,
      };

      return {
        top: `-${(content.height as number) - offsets.top + 10}px`,
        left: `${getLeftAlignment() + offsets.left}px`,
      };
    }
  };
  const getLeftAlignment = () => {
    const content = {
      height: contentRef?.current?.offsetHeight,
      width: contentRef?.current?.offsetWidth,
    };
    const target = {
      width: targetRef?.current?.offsetWidth,
    };

    const targetWidth = target.width as number;
    const contentWidth = content.width as number;
    const halfTargetWidth = targetWidth / 2;
    const halfContentWidth = contentWidth / 2;
    if (arrowAlignment === TOOLTIP_HORIZONTAL_ALIGNMENTS.RIGHT) {
      return halfTargetWidth - contentWidth + 20;
    } else if (arrowAlignment === TOOLTIP_HORIZONTAL_ALIGNMENTS.LEFT) {
      return halfTargetWidth - 20;
    } else {
      return halfTargetWidth - halfContentWidth;
    }
  };

  return (
    <span
      className={styles.tooltip}
      role="button"
      tabIndex={0}
      onMouseEnter={handleCenterTooltip}
      aria-describedby={tooltipId}
      ref={containerRef}
    >
      <div
        className={`${styles.tooltipContent} ${
          styles[`arrow${arrowAlignment}`]
        }`}
        role="tooltip"
        id={tooltipId}
        style={{ ...position, visibility: visibility }}
        ref={contentRef}
      >
        {content}
      </div>

      <span>{children}</span>
    </span>
  );
};

export default Tooltip;
