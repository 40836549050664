import { ReactElement, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { LandingPage } from "./landing-page";

import WaiversContainer from "./waivers-of-subrogation";
import CertificatesContainer from "./certificates-of-insurance";
import { useWcDocumentRequestQuery } from "types/generated/operations";
import { WAIVER_BASE_URL, COI_BASE_URL } from "./constants";
import { NOT_FOUND_URL } from "pages/page-404/constants";

export default function WCDocumentRequest(): ReactElement {
  const { data, loading } = useWcDocumentRequestQuery();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.preserveScrollPosition) {
      return;
    }
    scrollTo({ top: 0, left: 0 });
  }, [location]);

  if (loading || !data) {
    return <></>;
  }

  const adminPermissions = data.authenticatedMember.permissions;
  const docCenterPermissionGranted = 7;

  if (
    adminPermissions &&
    !adminPermissions.includes(docCenterPermissionGranted)
  ) {
    return <Navigate to={NOT_FOUND_URL} />;
  }

  return (
    <Routes>
      <Route path={`${WAIVER_BASE_URL}/*`} element={<WaiversContainer />} />
      <Route path={`${COI_BASE_URL}/*`} element={<CertificatesContainer />} />
      <Route path="*" element={<LandingPage />} />
    </Routes>
  );
}
