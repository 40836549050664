import { Button, Card } from "@justworkshr/milo-core";
import {
  ActionFooter,
  FormField,
  Form as MiloForm,
  RadioButton,
  RadioButtonGroup,
  Select,
  SelectOption,
  TextInput,
} from "@justworkshr/milo-form";
import { Formik, type FormikConfig } from "formik";
import clsx from "lib/styling-utils/clsx";
import { useContext, type ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { DatePicker } from "../../components";
import { SupportedCountriesContext } from "../../contexts/supportedCountriesContext";
import DepartmentSelectField from "../components/DepartmentSelectField";
import ManagerSelectField from "../components/ManagerSelectField";
import { ContractTerm, EmailTypes, PayBasis } from "../types";
import { isValidStartDate } from "../utils";
import styles from "./Form.module.css";
import MX_CONFIG, { type SchemaType as MXSchema } from "./mx";
import NL_CONFIG, { type SchemaType as NLSchema } from "./nl";
import IN_CONFIG, { type SchemaType as INSchema } from "./in";
import baseSchema from "./schema";

export const COUNTRY_EXTENDED_CONFIG = {
  MX: MX_CONFIG,
  NL: NL_CONFIG,
  IN: IN_CONFIG,
} as const;

export const isCountryExtendedConfig = (
  key: string
): key is keyof typeof COUNTRY_EXTENDED_CONFIG =>
  key in COUNTRY_EXTENDED_CONFIG;

type BaseSchemaType = Yup.InferType<typeof baseSchema>;
export type SchemaType =
  | BaseSchemaType
  | (BaseSchemaType & MXSchema)
  | (BaseSchemaType & NLSchema)
  | (BaseSchemaType & INSchema);

export type InviteFormProps = {
  initialValues: SchemaType;
  isLoading?: boolean;
  onFormSubmit: FormikConfig<SchemaType>["onSubmit"];
};

export default function Form({
  initialValues,
  isLoading,
  onFormSubmit,
}: InviteFormProps) {
  const { t } = useTranslation();

  const { supportedCountriesData } = useContext(SupportedCountriesContext);
  const updatedSchema = baseSchema.shape({
    workCountry: Yup.string()
      .oneOf(["", ...Object.keys(supportedCountriesData)])
      .required("Required"),
    payRate: Yup.string()
      .required("Required")
      .test("is-greater-than-1", `Must be $1 or greater.`, function (value) {
        const number = parseInt(value, 10);
        const valid = !isNaN(number) && number >= 1;
        const country = this.resolve(Yup.ref("workCountry")) as string;
        const currencies = supportedCountriesData[country]?.currencies;
        const symbol =
          currencies != null && country ? `${currencies[0].symbol}` : "";
        if (!valid && symbol) {
          return this.createError({
            message: `Must be ${symbol}1 or greater.`,
          });
        }
        return valid;
      }),
  });

  const generateLazyValidationSchema = () =>
    Yup.lazy((values: SchemaType) => {
      const workCountry = values.workCountry;

      // For now this is the only way to achieve type narrowing to play nicely with concat
      if (isCountryExtendedConfig(workCountry)) {
        if (values.workCountry === "MX" && COUNTRY_EXTENDED_CONFIG["MX"])
          return updatedSchema.concat(COUNTRY_EXTENDED_CONFIG["MX"].schema);
        if (values.workCountry === "NL" && COUNTRY_EXTENDED_CONFIG["NL"])
          return updatedSchema.concat(COUNTRY_EXTENDED_CONFIG["NL"].schema);
        if (values.workCountry === "IN" && COUNTRY_EXTENDED_CONFIG["IN"])
          return updatedSchema.concat(COUNTRY_EXTENDED_CONFIG["IN"].schema);
      }

      return updatedSchema;
    });

  return (
    <Formik<SchemaType>
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validationSchema={generateLazyValidationSchema}
      enableReinitialize
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        touched,
        errors,
        values,
      }) => {
        const fieldErrorHandler = (name: keyof BaseSchemaType) =>
          errors[name] && touched[name] ? errors[name] : undefined;

        const handleHomeEmailFieldChange = (
          event: ChangeEvent<HTMLInputElement>
        ) => {
          if (!event.target.value) {
            setFieldValue("sendInvitationTo", EmailTypes.Work);
          }

          handleChange(event);
        };

        const payAmountLabel = () => {
          const currencies =
            supportedCountriesData[values.workCountry]?.currencies;
          const currency =
            currencies != null && values.workCountry
              ? ` (${currencies[0].currencyCode})`
              : "";
          const basis =
            values.payBasis === PayBasis.Hourly
              ? t(`Pay amount per hour`)
              : t(`Pay amount per year`);
          return basis + currency;
        };

        const datePickerHandlePick = (dateValue: string) => {
          dateValue === "Invalid Date"
            ? setFieldValue("startDate", "")
            : setFieldValue("startDate", dateValue);
        };

        const handleWorkCountryOnChange = (
          event: ChangeEvent<HTMLSelectElement>
        ) => {
          const baseInitialValues = () => {
            const filteredEntries = Object.entries(values).filter(
              ([key]) => key in initialValues
            );
            const filteredValues = Object.fromEntries(filteredEntries);
            return { ...initialValues, ...filteredValues };
          };

          const workCountry = event.target.value;
          const baseValues = baseInitialValues();

          if (isCountryExtendedConfig(workCountry)) {
            setValues(
              {
                ...baseValues,
                ...COUNTRY_EXTENDED_CONFIG[workCountry]?.initialValues,
              },
              false
            );
          } else {
            setValues(baseValues, false);
          }

          return handleChange(event);
        };

        return (
          <MiloForm onSubmit={handleSubmit} noValidate>
            <div className={styles.formBody}>
              <div>
                <Card title={t("Employee information")}>
                  <div className={styles.cardBody}>
                    <FormField
                      required
                      name="firstName"
                      label={t("First name")}
                      error={fieldErrorHandler("firstName")}
                    >
                      <TextInput
                        required
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                      />
                    </FormField>
                    <FormField
                      required
                      name="lastName"
                      label={t("Last name")}
                      error={fieldErrorHandler("lastName")}
                    >
                      <TextInput
                        required
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                      />
                    </FormField>
                  </div>
                </Card>
              </div>
              <div>
                <Card title={t("Contact information")}>
                  <div className={styles.cardBody}>
                    <FormField
                      name="homeEmail"
                      label={t("Home email address")}
                      error={fieldErrorHandler("homeEmail")}
                    >
                      <TextInput
                        name="homeEmail"
                        onChange={handleHomeEmailFieldChange}
                        onBlur={handleBlur}
                        value={values.homeEmail}
                      />
                    </FormField>
                    <FormField
                      name="workEmail"
                      label={t("Work email address")}
                      required
                      error={fieldErrorHandler("workEmail")}
                    >
                      <TextInput
                        required
                        name="workEmail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.workEmail}
                      />
                    </FormField>
                  </div>
                </Card>
              </div>
              <div>
                <Card title={t("Job & compensation information")}>
                  <div className={styles.cardBody}>
                    <FormField
                      name="workCountry"
                      required
                      label={t("Work country")}
                      error={fieldErrorHandler("workCountry")}
                    >
                      <Select
                        required
                        name="workCountry"
                        placeholder={t("Select country")}
                        onChange={handleWorkCountryOnChange}
                        onBlur={handleBlur}
                        value={values.workCountry}
                      >
                        {Object.values(supportedCountriesData || []).map(
                          (country) => (
                            <SelectOption
                              value={country?.code}
                              key={country?.code}
                            >
                              {t(country?.commonName || "")}
                            </SelectOption>
                          )
                        )}
                      </Select>
                    </FormField>
                    <FormField
                      required
                      name="contractTerm"
                      label={t("Contract term")}
                      error={fieldErrorHandler("contractTerm")}
                    >
                      <Select
                        required
                        name="contractTerm"
                        placeholder={t("Select contract type")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.contractTerm}
                      >
                        <SelectOption
                          value={ContractTerm.Indefinite}
                          key={ContractTerm.Indefinite}
                        >
                          {t("Indefinite")}
                        </SelectOption>
                        <SelectOption
                          value={ContractTerm.Fixed}
                          key={ContractTerm.Fixed}
                          disabled
                        >
                          {t("Fixed")}
                        </SelectOption>
                      </Select>
                    </FormField>
                    <ManagerSelectField
                      name="managerUuid"
                      error={fieldErrorHandler("managerUuid")}
                      value={values.managerUuid}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                    <FormField
                      required
                      name="jobTitle"
                      label={t("Job title")}
                      error={fieldErrorHandler("jobTitle")}
                    >
                      <TextInput
                        required
                        name="jobTitle"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.jobTitle}
                      />
                    </FormField>
                    <FormField
                      required
                      name="roleResponsibilities"
                      label={t("Role Responsibilities")}
                      error={fieldErrorHandler("roleResponsibilities")}
                    >
                      <textarea
                        className={clsx(
                          styles.textArea,
                          errors.roleResponsibilities &&
                            touched.roleResponsibilities &&
                            styles.error
                        )}
                        name="roleResponsibilities"
                        required
                        value={values.roleResponsibilities}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormField>
                    <DepartmentSelectField
                      name="departmentUuid"
                      error={fieldErrorHandler("departmentUuid")}
                      value={values.departmentUuid}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                    <FormField
                      required
                      name="startDate"
                      label={t("Start date")}
                      error={fieldErrorHandler("startDate")}
                    >
                      <DatePicker
                        key={`startDate-${values.startDate}`}
                        placeholder={t("Select date")}
                        id="startDate"
                        name="startDate"
                        value={values.startDate}
                        onChange={datePickerHandlePick}
                        isValidDate={isValidStartDate}
                      />
                    </FormField>
                    <FormField
                      name="payBasis"
                      required
                      label={t("Pay basis")}
                      error={fieldErrorHandler("payBasis")}
                      message={t(
                        "We only support full-time, salaried employees for now."
                      )}
                    >
                      <Select
                        required
                        name="payBasis"
                        placeholder={t("Select pay basis")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.payBasis}
                      >
                        <SelectOption
                          value={PayBasis.Salary}
                          key={PayBasis.Salary}
                        >
                          {t("Salaried")}
                        </SelectOption>
                        <SelectOption
                          value={PayBasis.Hourly}
                          key={PayBasis.Hourly}
                          disabled
                        >
                          {t("Hourly")}
                        </SelectOption>
                      </Select>
                    </FormField>
                    <FormField
                      required
                      name="payRate"
                      label={payAmountLabel()}
                      error={fieldErrorHandler("payRate")}
                    >
                      <TextInput
                        required
                        name="payRate"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.payRate.toString()}
                      />
                    </FormField>
                    {values.workCountry &&
                      isCountryExtendedConfig(values.workCountry) && (
                        <div className={styles.cardBody}>
                          {
                            COUNTRY_EXTENDED_CONFIG[values.workCountry]
                              ?.component
                          }
                        </div>
                      )}
                  </div>
                </Card>
              </div>
              <FormField
                name="sendInvitationTo"
                required
                label={t("Send invitation to")}
                error={fieldErrorHandler("sendInvitationTo")}
              >
                <RadioButtonGroup
                  name="sendInvitationTo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.sendInvitationTo}
                >
                  <RadioButton
                    value={EmailTypes.Work}
                    label={t("Work email")}
                  />
                  <RadioButton
                    value={EmailTypes.Home}
                    label={t("Home email")}
                    disabled={!values.homeEmail}
                  />
                </RadioButtonGroup>
              </FormField>
            </div>
            <ActionFooter
              actions={[
                <Button key="submit-btn" type="submit" disabled={isLoading}>
                  {t("Submit")}
                </Button>,
              ]}
            />
          </MiloForm>
        );
      }}
    </Formik>
  );
}
