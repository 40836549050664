import styles from "./SettingsChildren.module.css";
import { Button } from "@justworkshr/milo-core";
import {
  useFeedbackDialogStore,
  useGlobalConfigurationStore,
} from "pages/expenses/store";
import { FEEDBACK_DIALOG_COPY } from "./SettingsChildren.constants";

const { SettingsChildrenLegalCopy } = styles;

export const SettingsChildren = () => {
  const {
    permissions: { isAdmin },
  } = useGlobalConfigurationStore();
  const { setFeedbackDialog } = useFeedbackDialogStore();

  const legalCopy = (
    <p className={SettingsChildrenLegalCopy}>
      This tool is not intended to help determine if an expense is considered{" "}
      <Button
        type="button"
        variant="ghost"
        onClick={() =>
          setFeedbackDialog({
            open: true,
            title: "Important",
            message: FEEDBACK_DIALOG_COPY,
          })
        }
      >
        non-taxable
      </Button>
      .
    </p>
  );

  if (isAdmin) {
    return (
      <div className="SettingsChildrenWrapper">
        Pay back an employee when they pay out-of-pocket for valid, non-taxable
        business expenses.{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://help.justworks.com/hc/en-us/articles/17024877999515-Expenses-Desktop-"
        >
          Learn more about Expenses
        </a>{" "}
        in the Help Center.
        <br />
        <br />
        {legalCopy}
      </div>
    );
  }

  return (
    <div className="SettingsChildrenWrapper">
      Approve expense reimbursements from your direct reports when they pay
      out-of-pocket for valid, non-taxable business expenses.{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://help.justworks.com/hc/en-us/articles/17024877999515-Expenses-Desktop-"
      >
        Learn more about Expenses
      </a>{" "}
      in the Help Center.
      <br />
      <br />
      {legalCopy}
    </div>
  );
};
