import React from "react";
import {
  PayDate,
  Submitter,
  AdminReview,
  ReviewButtons,
  ManagerReview,
  SubmitterButtons,
  TotalReimbursement,
} from "./components";
import styles from "./ReimbursementSummary.module.css";

interface ReimbursementSummaryProps {
  isCreateFlow: boolean;
}

const { ReimbursementSummaryContainer } = styles;

export const ReimbursementSummary: React.FC<ReimbursementSummaryProps> =
  React.memo(({ isCreateFlow }) => {
    return (
      <>
        <h2>Reimbursement summary</h2>
        <div className={ReimbursementSummaryContainer}>
          <Submitter isCreateFlow={isCreateFlow} />
          <ManagerReview isCreateFlow={isCreateFlow} />
          <AdminReview isCreateFlow={isCreateFlow} />
          <PayDate isCreateFlow={isCreateFlow} />
          <TotalReimbursement />
          <ReviewButtons />
          <SubmitterButtons isCreateFlow={isCreateFlow} />
        </div>
      </>
    );
  });
