import styles from "./FirmsPage.module.css";
import { Alert, Box, PageHeader, Spinner } from "@justworkshr/milo-core";
import {
  InfoBox,
  LegacyTabList,
  PendingFirmTable,
  ActiveFirmTable,
  ThirdPartyAdminTable,
} from "./components";
import { FirmStatus } from "types/generated/operations";
import { useFirmsPageQueries, useConsumeSuccessMessage } from "./helpers";
import { canManageFirms } from "../../helpers";
import RemoveFirmModal from "pages/firms/components/RemoveFirmModal/RemoveFirmModal";
import { useParams } from "react-router-dom";

const { firmsPage, firmsPageHeader, section } = styles;

export type FirmTableMember = {
  uuid: string;
  firstName: string;
  lastName: string;
  emailWork: string;
  deskTitle: string;
  firmName?: string | null;
  phoneNumber: string | null;
};

const FirmsPage = () => {
  const { data, loading, error } = useFirmsPageQueries();
  const { modalAction } = useParams();

  const firm = data?.firm;
  const thirdPartyAdmins =
    data?.authenticatedMember?.company?.thirdPartyAdmins ?? [];
  const successMessage = useConsumeSuccessMessage();
  const canManage = canManageFirms(data?.authenticatedMember?.permissions);

  return (
    <>
      {successMessage && (
        <Alert color="additive" visible dismissible>
          {successMessage}
        </Alert>
      )}
      <div className={`${firmsPage} milo--grid`}>
        <PageHeader className={firmsPageHeader} title="Team management" />
        {loading ? (
          <Spinner />
        ) : Boolean(error) ? (
          <Alert visible={Boolean(error)} color="destructive">
            We were unable to load third-party members. Please try again.
          </Alert>
        ) : !canManage ? (
          <Box backgroundColor="warningSubtle" padding="2xl">
            <>
              You don't have permission to do that. Please make sure you're
              logged into the correct account. If you think you're seeing this
              message in error please feel free to{" "}
              <a href="https://justworks.desk.com/">contact us</a>.
            </>
          </Box>
        ) : (
          <>
            <LegacyTabList />
            <div className={section}>
              <InfoBox />
            </div>
            {firm && firm.status == FirmStatus.Active && (
              <div key={firm.uuid} className={section}>
                <ActiveFirmTable firm={firm} />
              </div>
            )}
            {firm && firm.status == FirmStatus.Pending && (
              <div key={firm.uuid} className={section}>
                <PendingFirmTable firm={firm} />
              </div>
            )}
            {thirdPartyAdmins.length > 0 && (
              <div className={section}>
                <ThirdPartyAdminTable members={thirdPartyAdmins} />
              </div>
            )}
          </>
        )}
      </div>
      {firm && modalAction === "remove-firm" && (
        <RemoveFirmModal firm={firm} companyUuid={data.companyUuid} />
      )}
    </>
  );
};

export default FirmsPage;
