type ValuedValidation = {
  value: number;
  message: string;
};

type BooleanValidation = {
  enabled: boolean;
  message: string;
};

// TODO: Do not add custom validations here
export type ValidationType = {
  number?: {
    max?: ValuedValidation;
    min?: ValuedValidation;
  };
  text?: {
    bankCode?: BooleanValidation;
    bsbCode?: BooleanValidation;
    clabe?: BooleanValidation;
    email?: BooleanValidation;
    iban?: BooleanValidation;
    ifsCode?: BooleanValidation;
    length?: ValuedValidation;
    number?: BooleanValidation;
    phoneNumber?: BooleanValidation;
    routingNumber?: BooleanValidation;
    sortCode?: BooleanValidation;
    swiftCode?: BooleanValidation;
    max?: ValuedValidation;
    min?: ValuedValidation;
    bankName?: BooleanValidation;
  };
  required: BooleanValidation;
};

export enum FieldTypes {
  text = "text",
  number = "number",
  select = "select",
  group = "group",
}

export interface BaseFieldDataType {
  name: string;
  label?: string | null;
  placeholder?: string;
  validations?: ValidationType;
  format?: (value: string) => string;
  compact?: (value: string) => string;
}

type TextFieldType = BaseFieldDataType & {
  type: FieldTypes.text;
};

type NumberFieldType = BaseFieldDataType & {
  type: FieldTypes.number;
};

export type SelectFieldType = BaseFieldDataType & {
  type: FieldTypes.select;
  options: { value: string | number; description: string }[];
};
export type RadioGroupFieldType = BaseFieldDataType & {
  type: FieldTypes.group;
  options: { value: string | number; description: string }[];
};

export type FieldDataType =
  | TextFieldType
  | NumberFieldType
  | SelectFieldType
  | RadioGroupFieldType;

export type FieldData = FieldDataType & {
  fields?: FieldDataType[] | null;
};

export type FormType = {
  header: string;
  subheader?: string;
  fields: FieldData[];
  initialValues: {
    [key: string]: string | boolean | { [key: string]: string | boolean };
  };
};
