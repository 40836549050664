import { useState, useEffect } from "react";

import { useAllActiveMembersQuery } from "types/generated/operations";
import { VALID_MEMBER_TYPES } from "../../../constants";

import { EmployeeSearchField } from "./employee-search-field";

import generalStyles from "../job-information-edit/job-information-edit.module.css";
import specificStyles from "./employee-search-container.module.css";
import { EmployeeSearchList } from "./employee-search-list";
import { EmployeeOptionType, EmployeeUpdateMethods } from "../../types";

import { InputMessage } from "@justworkshr/milo-form";
import { SystemIcon } from "@justworkshr/milo-icons";
import { WaiverErrors } from "../../types";

const { jobInformation, formBorder, header } = generalStyles;
const { subheading, employeeError, errorIcon } = specificStyles;

type EmployeeSearchContainerType = {
  handleEmployeeUpdate: EmployeeUpdateMethods;
  selectedEmployees: EmployeeOptionType[];
  errors: WaiverErrors;
};

export default function EmployeeSearchContainer({
  handleEmployeeUpdate,
  selectedEmployees,
  errors,
}: EmployeeSearchContainerType) {
  const [employeeInput, setEmployeeInput] = useState("");
  const [w2Employees, setW2Employees] = useState<EmployeeOptionType[]>([]);
  const [limited, setLimited] = useState<EmployeeOptionType[]>([]);

  const { data, loading } = useAllActiveMembersQuery();
  const allMembers = data?.authenticatedMember?.company?.activeW2Members;

  useEffect(() => {
    if (!allMembers || allMembers.length < 1) return;

    const filteredMembers = allMembers?.reduce(
      (employees, { uuid, friendlyFullName, memberType }) => {
        VALID_MEMBER_TYPES.indexOf(memberType) >= 0 &&
          employees.push({ uuid, name: friendlyFullName, selected: false });
        return employees;
      },
      [] as EmployeeOptionType[]
    );
    if (filteredMembers) {
      setW2Employees(filteredMembers);
    }
  }, [allMembers]);

  useEffect(() => {
    if (w2Employees.length < 1) return;

    if (employeeInput.length === 0) {
      setLimited(w2Employees);
    } else {
      const filtered: EmployeeOptionType[] = w2Employees.filter((emp) =>
        emp.name.toLowerCase().includes(employeeInput.toLowerCase())
      );

      setLimited(filtered);
    }
  }, [w2Employees, employeeInput]);

  useEffect(() => {
    setW2Employees((prev) => {
      return prev.map((emp) => ({
        ...emp,
        selected: selectedEmployees.some(
          (e: { uuid: string }) => e.uuid === emp.uuid
        ),
      })) as EmployeeOptionType[];
    });
  }, [selectedEmployees]);

  return (
    <div className={formBorder}>
      <div className="milo--grid">
        <div className={jobInformation} data-testid="jobInformationEdit">
          <h2 className={header}>Select W2 employees</h2>
          <hr />
          <p className={subheading}>
            Include all employees doing the same job duties. Please note, only
            active W2 employees can be added
          </p>
          <section>
            <EmployeeSearchField
              selectedEmployees={selectedEmployees}
              employeeInput={employeeInput}
              setEmployeeInput={setEmployeeInput}
              loading={loading}
              w2Employees={w2Employees}
              handleEmployeeUpdate={handleEmployeeUpdate}
            />
            <EmployeeSearchList
              loading={loading}
              w2Employees={w2Employees}
              limited={limited}
              handleEmployeeUpdate={handleEmployeeUpdate}
            />
            <InputMessage type="help">
              {
                "List the exact number of W2 employees working this job/at this lease."
              }
            </InputMessage>
          </section>
          {errors.employees.length > 0 && (
            <div className={employeeError}>
              <SystemIcon
                iconName="error"
                size="medium"
                color="error"
                className={errorIcon}
              />
              <p>{errors.employees}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
