import { ReactElement, ReactNode } from "react";
import styles from "./Card.module.css";
import { Box } from "@justworkshr/milo-core";
import {
  SystemIcon,
  DisplayIcon,
  SystemIconName,
  DisplayIconNames,
} from "@justworkshr/milo-icons";

type Props = {
  title?: string;
  cardIcon?: ReactNode;
  cardIconName?: keyof typeof DisplayIconNames;
  actionIconName?: SystemIconName;
  actionName?: string;
  actionId?: string;
  onActionClick?: () => void;
  children?: ReactNode;
};

export default function InfoCard({
  title,
  cardIcon,
  cardIconName,
  actionIconName,
  actionName,
  actionId,
  onActionClick,
  children,
}: Props): ReactElement {
  return (
    <div className={styles.card}>
      <Box
        padding="2xl"
        border={{
          borderWidth: "md",
          borderColor: "neutralSubtle",
          borderRadius: "md",
        }}
      >
        <div className={styles.cardHeader}>
          <div className={styles.cardTitle}>
            {cardIcon}
            {cardIconName && (
              <DisplayIcon
                iconName={cardIconName || "phone-book"}
                size="small"
                color="blue"
              />
            )}
            <div>{title}</div>
          </div>
          <div
            id={actionId}
            className={actionId ? styles.actionDiv : styles.nonPendoActionDiv}
            onClick={onActionClick}
          >
            {actionIconName && (
              <SystemIcon
                iconName={actionIconName || "edit"}
                color="brand"
                size="medium"
              />
            )}
            <span className={styles.action}>{actionName}</span>
          </div>
        </div>

        <div className={styles.cardBody}>{children}</div>
      </Box>
    </div>
  );
}
