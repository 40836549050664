import { ReactElement } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";

import { CONTRACTORS_PATH, EMPLOYEES_EOR_PATH, OVERVIEW_PATH } from "./routes";
import { Header } from "./components/Header/Header";
import styles from "./InternationalSolutionsPage.module.css";
import { Tab, TabList } from "@justworkshr/milo-core";
import { TABS } from "./constants";
import Overview from "./pages/Overview/Overview";
import Contractors from "./pages/Contractors/Contractors";
import EmployerOfRecord from "./pages/EmployerOfRecord/EmployerOfRecord";
import { INTERNATIONAL_SOLUTIONS_PATH } from "pages";

const InternationalSolutionsPage = (): ReactElement => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const getSelectedTabIndex = (): number => {
    const index = TABS.findIndex((tab) => pathname.includes(tab.path));
    return index !== -1 ? index : 0;
  };

  const handleTabChange = (index: number) => {
    if (index === 0) {
      return navigate(`${INTERNATIONAL_SOLUTIONS_PATH}${OVERVIEW_PATH}`);
    } else if (index === 1) {
      return navigate(`${INTERNATIONAL_SOLUTIONS_PATH}${EMPLOYEES_EOR_PATH}`);
    } else if (index === 2) {
      return navigate(`${INTERNATIONAL_SOLUTIONS_PATH}${CONTRACTORS_PATH}`);
    } else {
      return navigate(`${INTERNATIONAL_SOLUTIONS_PATH}${OVERVIEW_PATH}`);
    }
  };

  return (
    <div className={styles.contentWrapper}>
      <Header />
      <TabList
        orientation="horizontal"
        selected={getSelectedTabIndex()}
        onChange={handleTabChange}
      >
        {TABS.map((tab) => (
          <Tab key={tab.path}>{tab.name}</Tab>
        ))}
      </TabList>
      <div className={styles.componentWrapper}>
        <Outlet />
      </div>

      <Routes>
        <Route path={`${OVERVIEW_PATH}`} element={<Overview />} />
        <Route path={`${EMPLOYEES_EOR_PATH}`} element={<EmployerOfRecord />} />
        <Route path={`${CONTRACTORS_PATH}`} element={<Contractors />} />
        <Route
          path="/"
          element={<Navigate to={`.${OVERVIEW_PATH}`} replace />}
        />
      </Routes>
    </div>
  );
};

export default InternationalSolutionsPage;
