import React from "react";
import { Link } from "react-router-dom";
import { buildClockworkWebPath } from "lib/resource-finder";
import { Avatar } from "./components/Avatar";
import styles from "./MemberGlance.module.css";

const { MemberGlanceWrapper, MemberInfo, DeskTitle } = styles;

interface MemberGlanceProps {
  member: {
    name: string;
    photoUrl: string | null | undefined;
    deskTitle: string | null | undefined;
    uuid: string;
  };
  disableLink?: boolean;
}
export const MemberGlance: React.FC<MemberGlanceProps> = ({
  member,
  disableLink = false,
}) => {
  const memberName = disableLink ? (
    <>{member.name}</>
  ) : (
    <Link to={buildClockworkWebPath(`/members/${member.uuid}`)}>
      {member.name}
    </Link>
  );
  return (
    <div className={MemberGlanceWrapper}>
      <Avatar fullName={member.name} photoUrl={member.photoUrl} />
      <div className={MemberInfo}>
        {memberName}
        {member.deskTitle && (
          <div className={DeskTitle}>{member.deskTitle}</div>
        )}
      </div>
    </div>
  );
};

export default MemberGlance;
