import { prependBasePath } from "pages/expenses/constants";
import { useRequestDetail } from "pages/expenses/store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateReimbursementRequestMutation } from "types/generated/operations";

export const useUpdateReimbursementRequest = () => {
  const navigate = useNavigate();
  const { resetRequestDetails } = useRequestDetail();

  const [
    updateReimbursementRequest,
    {
      data: updateReimbursementRequestData,
      loading: updateReimbursementRequestLoading,
    },
  ] = useUpdateReimbursementRequestMutation();

  useEffect(() => {
    if (
      !updateReimbursementRequestLoading &&
      updateReimbursementRequestData?.updateReimbursementRequest?.success
    ) {
      resetRequestDetails();

      navigate(prependBasePath("HOME"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateReimbursementRequestLoading, updateReimbursementRequestData]);

  return {
    updateReimbursementRequest,
    updateReimbursementRequestLoading,
  };
};
