import { ReactElement } from "react";
import { Routes, Route } from "react-router-dom";
import { HomePage } from "./home-page";
import { AboutClaims } from "./about-page";

export default function ClaimsRoutes(): ReactElement {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path={"about"} element={<AboutClaims />} />
    </Routes>
  );
}
