import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TabList, Tab } from "@justworkshr/milo-core";
import { getSelectedAdminTab } from "./AdminTabList.utils";
import { prependBasePath } from "pages/expenses/constants";
import { useGlobalConfigurationStore } from "pages/expenses/store";

export const AdminTabList: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const selected = getSelectedAdminTab(pathname);

  const {
    permissions: { isAllowedToAccessReviewPage },
  } = useGlobalConfigurationStore();

  const handleChange = (index: number) => {
    if (isAllowedToAccessReviewPage) {
      if (index === 0) {
        navigate(prependBasePath("ACTIVE_REQUESTS_LIST"));
      } else if (index === 1) {
        navigate(prependBasePath("REQUEST_HISTORY"));
      } else if (index === 2) {
        navigate(prependBasePath("SETTINGS"));
      }
    } else {
      if (index === 0) {
        navigate(prependBasePath("REQUEST_HISTORY"));
      } else if (index === 1) {
        navigate(prependBasePath("SETTINGS"));
      }
    }
  };

  return (
    <TabList selected={selected} onChange={handleChange}>
      {isAllowedToAccessReviewPage && (
        <Tab data-testid="active-request-tab">Active Requests</Tab>
      )}
      <Tab data-testid="request-history-tab">Request History</Tab>
      <Tab data-testid="settings-tab">Settings</Tab>
    </TabList>
  );
};

export default AdminTabList;
