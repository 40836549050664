import classes from "./FaqItem.module.css";
import benefitsClasses from "pages/benefits/benefits.module.css";
import React, { ReactNode, useState } from "react";
import { Divider } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import clsx from "lib/styling-utils/clsx";

const FaqItem = ({
  title,
  children,
  last,
  collapsed,
}: {
  title: ReactNode;
  children: ReactNode;
  last?: boolean;
  collapsed?: boolean;
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!!collapsed);
  const toggleCollapsed = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsCollapsed((collapsed) => !collapsed);
  };

  return (
    <div
      className={clsx(
        classes.FaqItem,
        isCollapsed ? classes.collapsed : classes.expanded
      )}
    >
      <a onClick={toggleCollapsed}>
        <SystemIcon iconName={isCollapsed ? "chevron-right" : "chevron-down"} />
      </a>
      <div className={classes.inner}>
        <a onClick={toggleCollapsed}>
          <h3 className={benefitsClasses.benefitsHeader}>{title}</h3>
        </a>
        <div className={classes.content}>{children}</div>
      </div>
      {!last && <Divider />}
    </div>
  );
};

export default FaqItem;
