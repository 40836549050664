import React from "react";
import styles from "../../learn/partner-description-page.module.css";
import MetlifeSmallLogo from "../../images/metlife-horizontal-logo.svg";
import styles_new from "./EnrollmentReviewPage.module.css";
import { PageHeader, Box, Button } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import {
  benefitType,
  ResourceCard,
} from "../../components/ResourceCard/ResourceCard";
import Divider from "../../components/Divider/Divider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { OPTIONS_AND_COSTS_CONTENT } from "../../constants/stdTabContent";
import { HEADER_REVIEW_CONTENT } from "../../constants/reviewPageContent";
import Notice from "../../components/Notice/Notice";
import {
  useGetCompanyUuidQuery,
  useCreateCompanyAncillaryBenefitPlanMutation,
} from "types/generated/operations";

const { headerBanner, leftColumn, rightColumn, footer } = styles;
const {
  reviewSubheader,
  planCoverageAndCostsSubheader,
  horizontalLogo,
  providerHeader,
  cardContent,
  cardBottom,
  whatToExpect,
  reviewButtonText,
  setupFooter,
  expectHeader,
  boxDivider,
} = styles_new;

type ReviewState = {
  effectiveDate?: string;
  benefitData?: {
    [key: string]: string;
  };
  suppLifeData?: {
    [key: string]: string;
  };
  suppLifeEffectiveDate?: string;
};

export const EnrollmentReviewPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { effectiveDate, benefitData, suppLifeData, suppLifeEffectiveDate } =
    (location.state as ReviewState) || {
      effectiveDate: "",
      benefitData:
        OPTIONS_AND_COSTS_CONTENT.costTableInfo.employerPaidOptionOne,
      suppLifeData: null,
      suppLifeEffectiveDate: null,
    };
  const { data } = useGetCompanyUuidQuery();
  const [createCompanyAncillaryBenefitPlanMutation] =
    useCreateCompanyAncillaryBenefitPlanMutation();
  const companyUuid = data?.authenticatedMember?.company?.uuid;
  const isSuppLife =
    suppLifeData !== null &&
    suppLifeData !== undefined &&
    suppLifeEffectiveDate !== null &&
    suppLifeEffectiveDate !== undefined;
  const planType = isSuppLife ? suppLifeData?.planType : benefitData?.planType;
  const isBasicLife = benefitData?.planType === "life_add";
  const isVoluntary =
    planType === "vol_std" ||
    planType === "vol_ltd" ||
    (planType === "supp_life" && !isBasicLife);

  const getHeaderContent = (planType?: string): { [key: string]: string } => {
    switch (planType) {
      case "ltd":
      case "vol_ltd":
        return HEADER_REVIEW_CONTENT.ltd;
      case "std":
      case "vol_std":
        return HEADER_REVIEW_CONTENT.std;
      case "supp_life":
      case "life_add":
        return HEADER_REVIEW_CONTENT.basicAndAdd;
      // TODO: update default
      default:
        return HEADER_REVIEW_CONTENT.ltd;
    }
  };

  const getPlanType = (planType?: string): benefitType => {
    switch (planType) {
      case "ltd":
      case "vol_ltd":
        return "ltd";
      case "std":
      case "vol_std":
        return "std";
      case "supp_life":
      case "life_add":
        return "basicLifeAndADD";
      // TODO: update default
      default:
        return "ltd";
    }
  };

  const headerContent = getHeaderContent(planType);
  const selectedPlanType: benefitType = getPlanType(planType);

  const handleSubmit = async () => {
    const amount = benefitData?.amount;
    const abpPlanType = benefitData?.planType;

    const suppPlanType = suppLifeData?.planType;
    const suppLifeAmount = suppLifeData?.amount;

    if (isSuppLife && isBasicLife) {
      const response = await createCompanyAncillaryBenefitPlanMutation({
        variables: {
          companyUuid: companyUuid,
          effectiveDate: effectiveDate,
          amount: amount,
          abpPlanType: abpPlanType,
        },
      });
      if (response.data?.createCompanyAncillaryBenefitPlan?.success) {
        const supp_life_response =
          await createCompanyAncillaryBenefitPlanMutation({
            variables: {
              companyUuid: companyUuid,
              effectiveDate: suppLifeEffectiveDate,
              amount: suppLifeAmount,
              abpPlanType: suppPlanType,
            },
          });
        if (
          supp_life_response.data?.createCompanyAncillaryBenefitPlan?.success
        ) {
          navigate(headerContent.submitSuccessRoute, {
            state: {
              planType: abpPlanType,
              effectiveDate: effectiveDate,
              success: true,
            },
          });
          window.scrollTo(0, 0);
        } else {
          navigate(headerContent.submitSuccessRoute, {
            state: {
              planType: abpPlanType,
              effectiveDate: effectiveDate,
              success: false,
            },
          });
          window.scrollTo(0, 0);
        }
      } else {
        navigate(headerContent.submitSuccessRoute, {
          state: {
            planType: abpPlanType,
            effectiveDate: effectiveDate,
            success: false,
          },
        });
        window.scrollTo(0, 0);
      }
    } else {
      const benefitAmount = isSuppLife ? suppLifeAmount : amount;
      const benefitPlanType = isSuppLife ? suppPlanType : abpPlanType;
      const benefitEffectiveDate = isSuppLife
        ? suppLifeEffectiveDate
        : effectiveDate;

      const response = await createCompanyAncillaryBenefitPlanMutation({
        variables: {
          companyUuid: companyUuid,
          effectiveDate: benefitEffectiveDate,
          amount: benefitAmount,
          abpPlanType: benefitPlanType,
        },
      });
      if (response.data?.createCompanyAncillaryBenefitPlan?.success) {
        navigate(headerContent.submitSuccessRoute, {
          state: {
            planType: benefitPlanType,
            effectiveDate: benefitEffectiveDate,
            success: true,
          },
        });
        window.scrollTo(0, 0);
      } else {
        navigate(headerContent.submitSuccessRoute, {
          state: {
            planType: benefitPlanType,
            effectiveDate: benefitEffectiveDate,
            success: false,
          },
        });
        window.scrollTo(0, 0);
      }
    }
  };

  return (
    <>
      <div className="milo--grid">
        <div className={headerBanner}>
          <PageHeader title={headerContent.header}>
            Justworks brings your employees access to{" "}
            {headerContent.benefitType} through MetLife.
          </PageHeader>
        </div>
        <div className={leftColumn}>
          <div className={reviewSubheader}>Review your plan details</div>
          <div className={planCoverageAndCostsSubheader}>
            Plan coverage & costs
          </div>
          {benefitData?.planType && (
            <Box
              padding="2xl"
              border={{
                borderWidth: "sm",
                borderColor: "neutralSubtle",
                borderRadius: "md",
              }}
            >
              <div className={planCoverageAndCostsSubheader}>
                {isBasicLife
                  ? "Basic life and AD&D insurance"
                  : benefitData?.optionLabel}
              </div>
              <Divider />
              <div className={providerHeader}>Provider</div>
              <img
                className={horizontalLogo}
                src={MetlifeSmallLogo}
                alt="horizontal Metlife logo"
              />
              <div className={cardContent}>
                <strong>Coverage amount</strong>
                <br></br>
                {benefitData?.coverageAmount}
              </div>
              <div className={cardContent}>
                <strong>
                  {benefitData?.optionId === "option_3" && !isBasicLife
                    ? "Cost to employee"
                    : "Cost to you"}
                </strong>
                <br></br>
                {benefitData?.costs}
              </div>
              {benefitData?.optionId === "option_3" && !isBasicLife && (
                <div className={cardContent}>
                  <strong>Cost to you</strong>
                  <br></br>
                  $0.00. This is an employee funded benefit.
                </div>
              )}

              <div className={cardContent}>
                <strong>Effective Date</strong>
                <br></br>
                {effectiveDate}
              </div>
            </Box>
          )}
          {isSuppLife && (
            <>
              <div className={boxDivider}></div>
              <Box
                padding="2xl"
                border={{
                  borderWidth: "sm",
                  borderColor: "neutralSubtle",
                  borderRadius: "md",
                }}
              >
                <div className={planCoverageAndCostsSubheader}>
                  Supplemental life insurance
                </div>
                <Divider />
                <div className={providerHeader}>Provider</div>
                <img
                  className={horizontalLogo}
                  src={MetlifeSmallLogo}
                  alt="horizontal Metlife logo"
                />
                <div className={cardContent}>
                  <strong>Coverage amount</strong>
                  <br></br>
                  {suppLifeData.coverageAmount}
                </div>
                <div className={cardContent}>
                  <strong>Cost to you</strong>
                  <br></br>
                  {suppLifeData.costs}
                </div>

                <div className={cardContent}>
                  <strong>Effective Date</strong>
                  <br></br>
                  {suppLifeEffectiveDate}
                </div>
              </Box>
            </>
          )}
          <div className={cardBottom}></div>
          <div>
            <div className={expectHeader}>What to expect next</div>
            <ul className={whatToExpect}>
              {isVoluntary ? (
                <li>
                  Once you set up this benefit, all eligible employees will have
                  coverage starting on the effective date. For new hires,
                  they'll have coverage starting on the 1st of the next month
                  after their start date.
                </li>
              ) : (
                <li>You’ll be billed on the second invoice of the month</li>
              )}
              {isVoluntary ? (
                <li>
                  Once your employees enroll, the monthly cost is split between
                  two paychecks.
                </li>
              ) : (
                <li>
                  Once you set up this benefit, all eligible employees will have
                  coverage starting on the effective date. For new hires,
                  they'll have coverage starting on the 1st of the next month
                  after their start date.
                </li>
              )}
            </ul>
          </div>
          {(planType === "std" || planType === "vol_std") && (
            <Notice
              isStd={true}
              content={OPTIONS_AND_COSTS_CONTENT.noticeContent}
            />
          )}
          <div></div>
          <div className={setupFooter}>
            {" "}
            <ActionFooter
              actions={[
                <Button color="brand" onClick={handleSubmit} key="review">
                  <span className={reviewButtonText}>Submit</span>
                </Button>,
              ]}
              secondary={[
                <Button
                  variant="outlined"
                  color="brand"
                  as={Link}
                  to={headerContent.goBackRoute}
                  key="go_back"
                >
                  Back
                </Button>,
              ]}
            />
            <div className={footer}></div>
          </div>
        </div>

        <div className={rightColumn}>
          <ResourceCard selectedBenefit={selectedPlanType} />
        </div>
      </div>
    </>
  );
};
