import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import useProcessIntegrationAction from "../../../partner-setup-page/hooks/useProcessPmtIntegrationAction";
import { AlertData } from "../../../hooks/useAlert";
import { getDefaultActionAlerts } from "./utils";
import { integrationConfigs } from "pages/pmt/integrations/configs/config";

export const usePdpAction = (
  integrationSlug: string,
  action: string | undefined,
  setAlert: (alertData: AlertData) => void
) => {
  const allowedActions =
    integrationConfigs[integrationSlug]?.copy?.pdp.allowedPdpActions;
  const { processPmtIntegrationAction, loading: actionLoading } =
    useProcessIntegrationAction();
  const attemptedActionRef = useRef(false);

  useEffect(() => {
    if (action && !allowedActions.includes(action)) {
      return;
    }
    if (action && !attemptedActionRef.current) {
      attemptedActionRef.current = true;
      processPmtIntegrationAction(integrationSlug, action).then(
        (processActionResponse) => {
          if (
            processActionResponse?.success &&
            processActionResponse?.actionData?.keep_alive_status
          ) {
            setAlert(
              getDefaultActionAlerts(integrationSlug)[`${action}_success`]
            );
          } else if (
            !processActionResponse?.success &&
            !processActionResponse?.actionData?.keep_alive_status
          ) {
            attemptedActionRef.current = false;
            setAlert(
              getDefaultActionAlerts(integrationSlug)[`${action}_failure`]
            );
          }
        }
      );
    }
  }, [
    action,
    setAlert,
    processPmtIntegrationAction,
    integrationSlug,
    allowedActions,
  ]);

  return { actionLoading, action };
};

export const usePdpActionParam = (
  currentUrl: string,
  integrationName: string,
  setAlert: (alertData: AlertData) => void
) => {
  const navigate = useNavigate();
  const [pdpAction, setPdpAction] = useState<string | undefined>(undefined);

  useEffect(() => {
    const params = new URLSearchParams(currentUrl.split("?")[1]);
    const actionParam = params.get("action");
    if (actionParam) {
      setPdpAction(actionParam);
      params.delete("action");
      params.delete("error");
      navigate({ search: params.toString() }, { replace: true });
    }
  }, [currentUrl, navigate]);

  return usePdpAction(integrationName, pdpAction, setAlert);
};
