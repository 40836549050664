import { buildStaticAssetPath } from "lib/resource-finder";
import { useGetExpensesResourcesQuery } from "types/generated/operations";

const isCsTools = window.location.hostname === "cstools.justworks.com";
const isCsToolsWorkforce =
  window.location.hostname === "cstools-workforce.justworks.com";
const isProduction = window.location.hostname === "secure.justworks.com";
const isReadonly = window.location.hostname === "read-only.justworks.com";

const getAssetUrl = (assetUrl: string | undefined) => {
  if (!assetUrl) {
    return "";
  }

  return isProduction || isCsToolsWorkforce || isCsTools || isReadonly
    ? assetUrl
    : buildStaticAssetPath(assetUrl);
};

export const useExpensesResources = () => {
  const { data, loading } = useGetExpensesResourcesQuery();

  const adminOnePagerUrl = getAssetUrl(
    data?.expensesResources.adminOnePagerUrl
  );

  const employeeOnePagerUrl = getAssetUrl(
    data?.expensesResources.employeeOnePagerUrl
  );

  const emailAnnouncementTemplateUrl = getAssetUrl(
    data?.expensesResources.emailAnnouncementTemplateUrl
  );

  return {
    loading,
    data: {
      adminOnePagerUrl,
      employeeOnePagerUrl,
      emailAnnouncementTemplateUrl,
    },
  };
};
