import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
  useReducer,
  type Reducer,
  type Dispatch,
} from "react";
import type { ApolloError } from "@apollo/client";
import { useLocation } from "react-router-dom";
import type { FormikValues } from "formik";
import { useGetEoRMemberOnboardingStateQuery } from "types/generated/operations";
import type { EoROnboardingStates, EoRMemberOnboardingState } from "types";
import type { ProfileInfoFormType } from "../profile-info/types";
import type { ContactInfoFormType } from "../contact-info/types";
import type { PaymentInfoFormType } from "../payment-info/types";
import { initialValues as profileInfoInitialValues } from "../profile-info/constants";
import { initialValues as contactInfoInitialValues } from "../contact-info/constants";
import { initialValues as paymentInfoInitialValues } from "../payment-info/constants";
import { EoRMemberContext } from "../../contexts/eorMemberContext";
import useStepNavigation from "../hooks/useStepNavigation";
import { SupportedCountriesContext } from "../../contexts/supportedCountriesContext";
import { cleanData } from "pages/employer-of-record/utils";

interface OnboardingContextProps {
  loading: boolean;
  redirecting: boolean;
  memberError?: ApolloError;
  onboardingStateError?: ApolloError;
  currentStep?: EoROnboardingStates;
  setCurrentStep: (step: EoROnboardingStates) => void;
  profileInfo: ProfileInfoFormType;
  contactInfo: ContactInfoFormType;
  idVerificationStatus?: string;
  refetchIdVerification: () => void;
  paymentInfo: PaymentInfoFormType;
  dispatch: Dispatch<Action>;
  workCountry?: string;
}

export const OnboardingContext = createContext<OnboardingContextProps | null>(
  null
);

interface OnboardingContextProviderProps {
  children: ReactNode;
}

type State = {
  profileInfo: ProfileInfoFormType["profileInfo"];
  contactInfo: ContactInfoFormType["contactInfo"];
  paymentInfo: PaymentInfoFormType["paymentInfo"];
};

type Action =
  | {
      type: "assign-work-country";
      payload: {
        workCountry: string;
        currency: string;
      };
    }
  | {
      type: EoROnboardingStates;
      payload: FormikValues;
    }
  | {
      type: "onboarding-info";
      payload: EoRMemberOnboardingState;
    };

/* This reducer is used to update the state of the onboarding form.
 *
 * Actions:
 * - assign-work-country: Defaults country selected for contact and payment info forms
 *     to the employee's work country
 * - profile-info: Updates the profile info state with the values provided to the form.
 * - contact-info: Updates the contact info state with the values provided to the form.
 * - onboarding-info: Updates the state with all the values pulled from the API, and
 *     massages the data to make sure it conforms to what Formik expects.
 *
 * NOTE: When new onboarding steps are added, they should be added to the switch.
 */
const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case "assign-work-country":
      const updatedPaymentInfo = state.paymentInfo;
      if (!state.paymentInfo.countryCode)
        updatedPaymentInfo["countryCode"] = action.payload.workCountry;
      if (!state.paymentInfo.currency)
        updatedPaymentInfo["currency"] = action.payload.currency;

      const updatedContactInfo = state.contactInfo;
      if (!state.contactInfo.homeAddress.countryCode)
        updatedContactInfo["homeAddress"]["countryCode"] =
          action.payload.workCountry;
      if (!state.contactInfo.mailingAddress.countryCode)
        updatedContactInfo["mailingAddress"]["countryCode"] =
          action.payload.workCountry;

      return {
        ...state,
        paymentInfo: updatedPaymentInfo,
        contactInfo: updatedContactInfo,
      };
    case "profile-info":
      if (typeof action.payload.profileInfo.dateOfBirth === "string") {
        const dateOfBirth = new Date(action.payload.profileInfo.dateOfBirth);

        action.payload.profileInfo.dateOfBirth = {
          day: dateOfBirth.getDate().toString(),
          month: (dateOfBirth.getMonth() + 1).toString(),
          year: dateOfBirth.getFullYear().toString(),
        };
      }

      if (action.payload.profileInfo.identifierNumber) {
        delete action.payload.profileInfo.identifierNumber;
      }

      if (action.payload.profileInfo.confirmIdentifierNumber) {
        delete action.payload.profileInfo.confirmIdentifierNumber;
      }

      const updatedProfileInfo = cleanData<State["profileInfo"]>(
        action.payload.profileInfo,
        state.profileInfo
      );

      return {
        ...state,
        profileInfo: updatedProfileInfo,
      };
    case "contact-info":
      const newContactInfo = cleanData<State["contactInfo"]>(
        action.payload.contactInfo,
        state.contactInfo
      );

      return {
        ...state,
        contactInfo: newContactInfo,
      };
    case "payment-info":
      const newPaymentInfo = cleanData<State["paymentInfo"]>(
        action.payload.paymentInfo,
        state.paymentInfo
      );

      return {
        ...state,
        paymentInfo: newPaymentInfo,
      };
    case "onboarding-info":
      let profileInfoDateOfBirth = { month: "", day: "", year: "" };

      if (action.payload?.profileInfo?.dateOfBirth) {
        const [year, month, day] = action.payload?.profileInfo.dateOfBirth
          .split("-")
          .map(Number);
        const dateOfBirth = new Date(year, month - 1, day);

        profileInfoDateOfBirth = {
          day: dateOfBirth.getDate().toString(),
          month: (dateOfBirth.getMonth() + 1).toString(),
          year: dateOfBirth.getFullYear().toString(),
        };
      }

      return {
        ...state,
        profileInfo: cleanData<State["profileInfo"]>(
          {
            ...action.payload.profileInfo,
            dateOfBirth: profileInfoDateOfBirth,
          },
          state.profileInfo
        ),
        contactInfo: cleanData<State["contactInfo"]>(
          action.payload.contactInfo || undefined,
          state.contactInfo
        ),
        paymentInfo: cleanData<State["paymentInfo"]>(
          action.payload.paymentInfo || undefined,
          state.paymentInfo
        ),
      };
    default:
      return state;
  }
};

export const OnboardingContextProvider: FC<OnboardingContextProviderProps> = ({
  children,
}) => {
  const [onboardingState, dispatch] = useReducer(reducer, {
    profileInfo: profileInfoInitialValues.profileInfo,
    contactInfo: contactInfoInitialValues.contactInfo,
    paymentInfo: paymentInfoInitialValues.paymentInfo,
  });

  const eorMemberContext = useContext(EoRMemberContext);
  const { supportedCountriesData, getDefaultCurrency } = useContext(
    SupportedCountriesContext
  );
  const { navigateToStep } = useStepNavigation();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [redirecting, setRedirecting] = useState(false);
  const [currentStep, setCurrentStep] = useState<EoROnboardingStates>();

  const { loading: loadingOnboardingState, error: onboardingStateError } =
    useGetEoRMemberOnboardingStateQuery({
      skip: !eorMemberContext?.eorMemberId,
      variables: {
        memberId: eorMemberContext?.eorMemberId || "",
      },
      onCompleted: (onboardingStateData) => {
        if (onboardingStateData?.eorMemberOnboardingState) {
          setCurrentStep(
            onboardingStateData.eorMemberOnboardingState
              .state as EoROnboardingStates
          );

          dispatch({
            type: "onboarding-info",
            payload: onboardingStateData.eorMemberOnboardingState || {},
          });
        }
      },
    });

  useEffect(() => {
    if (currentStep) {
      setRedirecting(true);
      navigateToStep(currentStep);
    }
  }, [currentStep, navigateToStep]);

  useEffect(() => {
    setLoading(
      !eorMemberContext || eorMemberContext?.loading || loadingOnboardingState
    );
  }, [eorMemberContext, loadingOnboardingState]);

  useEffect(() => {
    if (eorMemberContext?.workCountry && supportedCountriesData) {
      dispatch({
        type: "assign-work-country",
        payload: {
          workCountry: eorMemberContext?.workCountry,
          currency: getDefaultCurrency(eorMemberContext?.workCountry),
        },
      });
    }
  }, [eorMemberContext, supportedCountriesData, getDefaultCurrency]);

  // Make sure we set redirecting to false when the location changes
  useEffect(() => {
    setRedirecting(false);
  }, [location]);

  return (
    <OnboardingContext.Provider
      value={{
        loading,
        redirecting,
        memberError: eorMemberContext?.error,
        onboardingStateError,
        currentStep,
        setCurrentStep,
        profileInfo: { profileInfo: onboardingState.profileInfo },
        contactInfo: { contactInfo: onboardingState.contactInfo },
        idVerificationStatus: eorMemberContext?.idVerificationStatus,
        refetchIdVerification:
          eorMemberContext?.refetchIdVerification || (() => null),
        paymentInfo: { paymentInfo: onboardingState.paymentInfo },
        dispatch: dispatch,
        workCountry: eorMemberContext?.workCountry,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
