import { AdditionalPayFormData } from "./types";
import {
  REIMBURSEMENT_INITIAL_VALUES,
  ReimbursementPayloadType,
} from "./components/Reimbursement";
import {
  BenefitsAndAllowancesType,
  EmployeePayType,
  ReimbursementPayType,
} from "../../constants";
import {
  BENEFITS_AND_ALLOWANCES_PAYLOAD_INITIAL_VALUES,
  BenefitsAndAllowancesPayloadType,
} from "./components/TaxableBenefitsAndAllowances";
import { EmployeePayPayload } from "./components/EmployeePay/types";

export const employeePayTypes = Object.values(EmployeePayType);

export const PAY_FORM_INITIAL_VALUES: AdditionalPayFormData<EmployeePayPayload> =
  {
    setUp: {
      workCountry: undefined,
      type: "",
      payload: {
        paymentSettings: {
          payPeriodId: "",
          disbursementDate: "",
          notes: "",
        },
      },
    },
    paymentDetails: [],
  };

export const EXPENSE_REIMBURSEMENT_FORM_INITIAL_VALUES: AdditionalPayFormData<ReimbursementPayloadType> =
  {
    setUp: {
      workCountry: undefined,
      type: ReimbursementPayType.REIMBURSEMENT,
      expenseType: "",
      payload: REIMBURSEMENT_INITIAL_VALUES,
    },
    paymentDetails: [],
  };

export const BENEFITS_AND_ALLOWANCES_FORM_INITIAL_VALUES: AdditionalPayFormData<BenefitsAndAllowancesPayloadType> =
  {
    setUp: {
      workCountry: undefined,
      type: BenefitsAndAllowancesType.BENEFITSANDALLOWANCES,
      benefitType: "",
      payload: BENEFITS_AND_ALLOWANCES_PAYLOAD_INITIAL_VALUES,
    },
    paymentDetails: [],
  };
