import { useState } from "react";
import { Alert } from "@justworkshr/milo-core";
import styles from "pages/company-settings/components/InitiateMicrodepositPage.module.css";
import MicrodepositVerifyForm from "pages/company-settings/components/MicrodepositVerifyForm";
import BankAccountPageHeader from "./BankAccountPageHeader";

const { page, formContainer, amountDetails, detailsHeader, content } = styles;

export default function MicrodepositVerifyPage() {
  const [error, setError] = useState<Error | null>(null);

  return (
    <div id="EditBankAccount">
      {error && (
        <Alert color="destructive">
          Something went wrong. Please try submitting again.
        </Alert>
      )}
      <div className={page}>
        <BankAccountPageHeader title="Confirm microdeposit amounts" />
        <div className={content}>
          <h2 className={detailsHeader}>Enter amounts</h2>
          <p className={amountDetails}>
            Enter the two deposit amounts that we have sent to verify your bank
            account.
          </p>
          <div className={formContainer}>
            <MicrodepositVerifyForm onError={setError} />
          </div>
        </div>
      </div>
    </div>
  );
}
