import { ReactElement } from "react";
import { Alert, Box, PageHeader, Spinner } from "@justworkshr/milo-core";
import { useTransactionsPageData } from "pages/pay-transactions/hooks/useTransactionsPageData";
import BankAccount, {
  BankAccountSkeleton,
} from "pages/pay-transactions/components/BankAccount/BankAccount";
import Wallet from "pages/pay-transactions/components/Wallet/Wallet";
import styles from "pages/pay-transactions/PayTransactions.module.css";
import { Table } from "pages/pay-transactions/components/Table";
import { toCurrency } from "./util/toCurrency";
import { formatDateToMMDDYYYY } from "./util/formatDateToMMDDYYY";

const { payTransactions, recentHeadline, bankAccounts, wallets } = styles;

// NOTE: This is awaiting the actual design for the zero state
const ZeroState = () => <p>No transactions</p>;

export default function PayTransactions(): ReactElement {
  const { transactions, bankAccount, wallet, loading, error } =
    useTransactionsPageData();

  return (
    <>
      {error && <Alert color="destructive">{error.message}</Alert>}
      <div className={`${payTransactions} milo--grid`}>
        <section>
          <PageHeader title="Transactions">
            Here are your Justworks Wallet and bank account details.
          </PageHeader>
          <div className={wallets}>
            <Wallet
              balance={toCurrency(wallet?.amount || 0, "USD", "never")}
              withTooltip={true}
              loading={loading}
            />
          </div>
        </section>
        <section>
          <div className={recentHeadline}>
            <h2>Recent</h2>
          </div>
          {loading && (
            <Box>
              <Spinner data-testid="loading-spinner" />
            </Box>
          )}
          {!loading && transactions.length === 0 && <ZeroState />}
          {!loading && transactions.length > 0 && (
            <Table>
              <tbody>
                {transactions.map((transaction) => (
                  <tr key={transaction.id}>
                    <td style={{ textAlign: "right" }}>
                      {formatDateToMMDDYYYY(transaction.date)}
                    </td>
                    <td>
                      {transaction.descriptionLink ? (
                        <a href={transaction.descriptionLink}>
                          {transaction.description}
                        </a>
                      ) : (
                        transaction.description
                      )}
                    </td>
                    <td>{transaction.type}</td>
                    <td style={{ textAlign: "right" }}>
                      {toCurrency(transaction.amount, "USD")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </section>
        <section className={bankAccounts}>
          <h2>Bank Accounts</h2>
          {loading && <BankAccountSkeleton />}
          {!loading && !bankAccount && <p>No bank account</p>}
          {!loading && bankAccount && (
            <BankAccount
              bankName={bankAccount.bankName}
              accountNumberMasked={bankAccount.accountTail}
              verificationStatus={bankAccount.verified}
            />
          )}
        </section>
      </div>
    </>
  );
}
