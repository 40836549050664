import { ReactNode, FC } from "react";
import styles from "./company-overview-metric.module.css";

interface CompanyOverviewMetricProps {
  description: ReactNode;
  metric: ReactNode;
  subtext: ReactNode;
}

const CompanyOverviewMetric: FC<CompanyOverviewMetricProps> = ({
  description,
  metric,
  subtext,
}) => {
  return (
    <div data-testid="company-overview-metric" className={styles.metric}>
      <p className={styles.metricDescription}>{description}</p>
      <h1 className={styles.metricHeader}>{metric}</h1>
      <p className={styles.metricSubtext}>{subtext}</p>
    </div>
  );
};

export default CompanyOverviewMetric;
