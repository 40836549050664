import React from "react";
import { Badge } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import { mergeClassNames } from "pages/expenses/utils";
import {
  COMPLETED,
  CardStatus,
  NOT_STARTED_REQUIRED,
  NOT_STARTED_STATUSES,
} from ".";
import { REQUIRED_BADGE_COPY, OPTIONAL_BADGE_COPY } from "../../constants";
import styles from "./ExpensesTaskCard.module.css";

const {
  Completed,
  ActiveCard,
  NotCompleted,
  NotActiveCard,
  ExpensesTaskCardWrapper,
} = styles;

interface ExpensesTaskCardProps {
  title: string;
  warning?: boolean;
  className?: string;
  status: CardStatus;
  activeCard: boolean;
  children: React.ReactNode;
}

export const ExpensesTaskCard: React.FC<ExpensesTaskCardProps> = ({
  title,
  status,
  warning,
  children,
  activeCard,
}) => {
  return (
    <div
      className={mergeClassNames([
        ExpensesTaskCardWrapper,
        activeCard ? ActiveCard : status === COMPLETED ? "" : NotActiveCard,
      ])}
    >
      {NOT_STARTED_STATUSES.includes(status) && (
        <div className={NotCompleted}>
          <h2>{title}</h2>
          <Badge role="neutral" emphasis="bold">
            {status === NOT_STARTED_REQUIRED
              ? REQUIRED_BADGE_COPY
              : OPTIONAL_BADGE_COPY}
          </Badge>
        </div>
      )}
      {status === COMPLETED && (
        <div className={Completed}>
          {warning ? (
            <SystemIcon iconName="warning" />
          ) : (
            <SystemIcon iconName="check-circle-inverted" color="success" />
          )}
          <h2>{title}</h2>
        </div>
      )}
      {children}
    </div>
  );
};

export default ExpensesTaskCard;
