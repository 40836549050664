import { ReimbursementRequestStatus } from "types/generated/operations";
import { ReimbursementRequest } from "types/Expenses";
import {
  READY_FOR_APPROVAL_BADGE,
  MANAGER_REVIEW_BADGE,
} from "./ActiveRequestsTableRow.constants";
import { ReviewerPermissions } from "../../ActiveRequestsPage.types";

export const renderReviewStatusBadge = (
  request: ReimbursementRequest,
  permissions: ReviewerPermissions
) => {
  const { isManager, isAdmin, isAdminAndManager } = permissions;
  if (
    (isAdminAndManager && request.myDirectReport) ||
    (isManager &&
      request.status === ReimbursementRequestStatus.InitialReview &&
      request.myDirectReport) ||
    (isAdmin &&
      request.status === ReimbursementRequestStatus.ReadyForApproval) ||
    (isAdmin &&
      request.status === ReimbursementRequestStatus.InitialReview &&
      !request.managerApprover)
  ) {
    return READY_FOR_APPROVAL_BADGE;
  } else {
    return MANAGER_REVIEW_BADGE;
  }
};
