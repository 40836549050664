import { useEffect } from "react";
import { useGlobalConfigurationStore } from "pages/expenses/store";
import {
  Review,
  GetActiveRequestsDocument,
  GetActiveRequestsQueryVariables,
  useApproveReimbursementRequestsMutation,
} from "types/generated/operations";
import { ReimbursementRequest } from "types/Expenses";

export const useApproveReimbursementRequests = (
  variables: GetActiveRequestsQueryVariables,
  selectedItems: ReimbursementRequest[],
  callback: () => void
) => {
  const { permissions } = useGlobalConfigurationStore();
  const [
    approveReimbursementRequests,
    {
      data: approveReimbursementRequestsData,
      loading: isApproveReimbursementRequestsLoading,
    },
  ] = useApproveReimbursementRequestsMutation({
    variables: {
      reviews: selectedItems.map(({ uuid }) => {
        if (permissions.isAdmin) {
          return {
            uuid,
            type: Review.Approve,
          };
        }

        return {
          uuid,
          type: Review.Review,
        };
      }),
    },
    refetchQueries: [
      {
        query: GetActiveRequestsDocument,
        variables,
      },
    ],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (
      approveReimbursementRequestsData?.reviewReimbursementRequests?.success &&
      approveReimbursementRequestsData?.reviewReimbursementRequests
        ?.updatedRequests
    ) {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveReimbursementRequestsData]);

  return {
    approveReimbursementRequests,
    approveReimbursementRequestsData,
    isApproveReimbursementRequestsLoading,
  };
};
