import styles from "pages/company-settings/components/ExpandableCard.module.css";
import { useState, ReactNode } from "react";
import { Divider } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";

const { card, headerContainer, collapsedHeader, expandedHeader, icon } = styles;

export interface ExpandableCardProps {
  /** Prominent heading text of the card. */
  title: string;
  /** The content of the card. */
  children: ReactNode;
}
export default function ExpandableCard({
  title,
  children,
}: ExpandableCardProps) {
  const [expanded, setExpanded] = useState(false);

  const iconName = expanded ? "chevron-up" : "chevron-down";
  const headerStyle = expanded ? expandedHeader : collapsedHeader;

  return (
    <div className={card}>
      <div className={headerContainer} onClick={() => setExpanded(!expanded)}>
        <div className={headerStyle}>{title}</div>
        <SystemIcon
          className={icon}
          iconName={iconName}
          data-testid={iconName}
        />
      </div>
      {expanded && (
        <div>
          <Divider spacing="md" />
          {children}
        </div>
      )}
    </div>
  );
}
