import { isSeverancePayment } from "../AdditionalPaymentsPage/components/AdditionalPaymentsForm/AdditionalPaymentsForm.helpers";
import {
  AdditionalPaymentsFormValues,
  PaymentDetailsFormValues,
} from "../SeparationPay.formUtils";

export const nonSeverancePaymentKeys = (formValues: {
  [key: number]: AdditionalPaymentsFormValues | PaymentDetailsFormValues;
}) => {
  return Object.keys(formValues).filter(
    (key: unknown) => !isSeverancePayment(formValues[key as number].type)
  );
};

export const completePaymentDetailsKeys = (paymentDetailsFormValues: {
  [key: number]: PaymentDetailsFormValues;
}) => {
  return Object.keys(paymentDetailsFormValues).filter(
    (key: unknown) => !!paymentDetailsFormValues[key as number].work_start_date
  );
};
