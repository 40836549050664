import FaqItem from "../FaqItem";
import benefitsClasses from "pages/benefits/benefits.module.css";

const FaqTab = () => {
  return (
    <div>
      <FaqItem title="How much longer do I have to make changes to my account?">
        <p className={benefitsClasses.benefitsParagraph}>
          You can continue making certain account changes at Slavic401k until
          the blackout period starts on June 18, 2024, at 4 p.m. ET.
        </p>
      </FaqItem>
      <FaqItem
        title="When will I be able to access my account?"
        collapsed={false}
      >
        <p className={benefitsClasses.benefitsParagraph}>
          You can access your account during the week of July 14, 2024 after the
          blackout period is lifted. We’ll notify when your new Empower account
          is available.
        </p>
      </FaqItem>
      <FaqItem
        title="Do I need to do anything to transfer my money to Empower?"
        collapsed={false}
      >
        <p className={benefitsClasses.benefitsParagraph}>
          Your account balance, contribution elections, loans, beneficiary
          elections and contact information will transfer to Empower
          automatically. You don’t need to do anything now unless you want to
          make changes to your current account with Slavic401k prior to the
          transition.
        </p>
      </FaqItem>
      <FaqItem
        title="What happens to my payroll deductions and employer contributions during the blackout period?"
        collapsed={false}
      >
        <p className={benefitsClasses.benefitsParagraph}>
          Your payroll deductions, including 401(k) deferrals and loan payments,
          will continue during the blackout period. If you have employer
          contributions, they will also continue. Empower will deposit
          contributions into your account once the plan’s transition is
          complete. Your contribution elections(s) will automatically transfer
          from Slavic to Empower.
        </p>
      </FaqItem>
      <FaqItem
        title="Will this transition have any tax implications for me?"
        collapsed={true}
      >
        <p className={benefitsClasses.benefitsParagraph}>
          No. The transfer of your Justworks Retirement Savings Plan account
          from Slavic to Empower is not a taxable event and will not impact the
          tax treatment of your account. Your account will seamlessly transition
          to a new account with Empower.
        </p>
      </FaqItem>
      <FaqItem title="Can I leave my account at Slavic?" collapsed={true}>
        <p className={benefitsClasses.benefitsParagraph}>
          No. Since the Justworks Retirement Savings Plan is an
          employer-sponsored plan, all participant 401(k) accounts will
          transition automatically to Empower. Your 401(k) account cannot be
          rolled over or moved to another plan or provider unless you qualify to
          take a distribution.
        </p>
      </FaqItem>
      <FaqItem
        title="Where can I find fund information for the investment lineup at Empower?"
        collapsed={true}
      >
        <p className={benefitsClasses.benefitsParagraph}>
          For a list of available investment options in your Justworks
          Retirement Savings Plan, refer to the transition packet that will be
          sent to your home in late April 2024. You can also access all the
          investment information at{" "}
          <a
            href="https://www.empower.com/client/justworks/transition/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Empower transition
          </a>
          .
        </p>
      </FaqItem>
    </div>
  );
};

export default FaqTab;
