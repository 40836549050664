import { useState } from "react";
import { fetchClient } from "@justworkshr/fe-utility-kit";
import { buildClockworkWebPath } from "lib/resource-finder";
import { prependBasePath } from "pages/team-management/constants";

type Document = {
  id: string;
  uuid: string;
  title: string;
  downloadUrl: string;
};

export type FileResponse = {
  document: Document;
  error: string;
};

interface UseDocumentUploaderProps {
  accept: string[];
  maxSize: number;
  memberUuid: string | undefined;
  incrementDocLoadingCounter?: () => void;
  decrementDocLoadingCounter?: () => void;
}

export const useDocumentUploader = ({
  accept,
  maxSize,
  memberUuid,
  incrementDocLoadingCounter,
  decrementDocLoadingCounter,
}: UseDocumentUploaderProps) => {
  const [errors, setErrors] = useState<{ [fileName: string]: string[] }>({});
  const [loading, setLoading] = useState(false);

  const onError = (file: File, message: string) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [file.name]: [message],
    }));
  };

  const resetErrors = () => setErrors({});

  const validateFile = (file: File) => {
    let hasValidationError = false;

    if (!accept.includes(file.type)) {
      hasValidationError = true;
      onError(
        file,
        "Error: Invalid File Type. We can accept DOCX, DOC, PDF, JPEG, XLSX, XLS, EML, TXT, or RTF files."
      );
    }

    if (file.size > maxSize) {
      hasValidationError = true;
      onError(file, "File size exceeds the limit");
    }

    return hasValidationError;
  };

  const uploadDocument = async (file: File) => {
    if (memberUuid) {
      try {
        const hasValidationErrors = validateFile(file);

        if (hasValidationErrors) {
          return;
        }

        setLoading(true);
        incrementDocLoadingCounter?.();

        const formData = new FormData();
        formData.append("member_uuid", memberUuid);
        formData.append("documents[]", file);

        const response = await fetchClient.post(
          buildClockworkWebPath(
            prependBasePath("POST_SEPARATION_QUESTIONNAIRE_DOCUMENTS")
          ),
          {
            cache: "no-cache",
            headers: {
              Accept: "application/json",
            },
            body: formData,
          }
        );

        const responseData: FileResponse = await response.json();

        if (responseData.error) {
          onError(file, responseData.error);
        }

        setLoading(false);
        decrementDocLoadingCounter?.();
        return responseData.document;
      } catch (error) {
        setLoading(false);
        decrementDocLoadingCounter?.();
        if (error instanceof Error) {
          onError(file, error.message);
        }
      }
    }
  };
  return {
    errors,
    loading,
    resetErrors,
    uploadDocument,
  };
};
