import React from "react";
import { mergeClassNames } from "../../../../utils";
import styles from "./Avatar.module.css";

const { AvatarWrapper, AvatarInitials } = styles;
interface AvatarProps {
  photoUrl?: string | null;
  fullName: string;
}

export const Avatar: React.FC<AvatarProps> = ({ photoUrl, fullName }) => {
  const initials: string = fullName
    .split(" ")
    .reduce((initials, name) => initials + name[0], "")
    .toUpperCase();
  return (
    <figure
      className={
        photoUrl
          ? AvatarWrapper
          : mergeClassNames([AvatarWrapper, AvatarInitials])
      }
      style={{
        backgroundImage: photoUrl ? `url(${photoUrl})` : "",
      }}
      data-heap-redact-attributes="style"
      data-heap-redact-text={true}
    >
      {!photoUrl && initials}
    </figure>
  );
};

export default Avatar;
