import {
  Form,
  Formik,
  FormikErrors,
  FormikTouched,
  useFormikContext,
} from "formik";
import styles from "./QuestionnaireForm.module.css";
import { useContext, useEffect, useState } from "react";
import { Button } from "@justworkshr/milo-core";
import { SeparationQuestion } from "../SeparationQuestion/SeparationQuestion";
import {
  MemberSeparation,
  MemberSeparationQuestion,
  MemberSeparationQuestionRecursive,
} from "pages/team-management/types";
import { useNavigate } from "react-router-dom";
import {
  getInitialValues,
  getQuestionnaireFormSchema,
  handleAutoSaveQuestionnaire,
  handleSaveAnswers,
} from "./QuestionnaireForm.utils";
import {
  QuestionnaireFormSchema,
  QuestionnaireFormSchemaShape,
} from "./QuestionnaireForm.types";
import {
  SaveMemberSeparationAnswersMutationFn,
  useSaveMemberSeparationAnswersMutation,
} from "types/generated/operations";
import { SEPARATION_FLOW_COPY } from "pages/team-management/routes/SeparationFlow/SeparationFlow.constants";
import { Loading } from "pages/team-management/routes/SeparationFlow/Loading/Loading";
import { QuestionnaireContext } from "pages/team-management/contexts/QuestionnaireContextProvider";
import { useAutoSave } from "pages/team-management/hooks/useAutoSave";
import { SeparationFlowContext } from "pages/team-management/contexts";

const { form, formSubmitButton, formButtons } = styles;

export interface QuestionnaireFormBodyProps {
  memberSeparation: MemberSeparation | undefined;
  fullQuestionTree: MemberSeparationQuestionRecursive[] | undefined;
  initialValues: QuestionnaireFormSchemaShape;
  saveMemberSeparationAnswersMutation: SaveMemberSeparationAnswersMutationFn;
  resetVisibleQuestions: (
    questionId: string,
    value: string | number | (string | number)[] | undefined
  ) => void;
  handleBack: () => void;
  handleErrors: (errors: FormikErrors<QuestionnaireFormSchemaShape>) => void;
}

export const QuestionnaireFormBody: React.FC<QuestionnaireFormBodyProps> = ({
  memberSeparation,
  fullQuestionTree,
  initialValues,
  saveMemberSeparationAnswersMutation,
  resetVisibleQuestions,
  handleBack,
  handleErrors,
}) => {
  const [lastAutoSavedValues, setLastAutoSavedValues] = useState<
    QuestionnaireFormSchemaShape | undefined
  >();
  const { handleSubmit, values, isValid, errors } =
    useFormikContext<QuestionnaireFormSchemaShape>();
  const { docLoadingCounter } = useContext(QuestionnaireContext);

  useAutoSave(async () => {
    await handleAutoSaveQuestionnaire(
      saveMemberSeparationAnswersMutation,
      memberSeparation,
      fullQuestionTree,
      values,
      initialValues,
      lastAutoSavedValues,
      setLastAutoSavedValues
    );
  }, 3000);

  return (
    <Form className={form} data-testid="questionnaireFormTestId">
      {fullQuestionTree &&
        fullQuestionTree.map((question: MemberSeparationQuestionRecursive) => (
          <SeparationQuestion
            question={question}
            key={question.questionId}
            handleFormChange={resetVisibleQuestions}
          />
        ))}
      {fullQuestionTree && (
        <div className={formButtons}>
          <Button
            variant="outlined"
            onClick={handleBack}
            data-testid="backButtonTestId"
          >
            {SEPARATION_FLOW_COPY.previous}
          </Button>
          <Button
            data-testid="nextButtonTestId"
            type="submit"
            className={formSubmitButton}
            onClick={(event: React.FormEvent<HTMLFormElement>) =>
              isValid ? handleSubmit(event) : handleErrors(errors)
            }
            disabled={docLoadingCounter > 0}
          >
            {SEPARATION_FLOW_COPY.next}
          </Button>
        </div>
      )}
    </Form>
  );
};

export interface QuestionnaireFormProps {
  memberSeparation: MemberSeparation | undefined;
  fullQuestionTree: MemberSeparationQuestionRecursive[] | undefined;
  fullQuestionList: MemberSeparationQuestion[] | null | undefined;
  backUrl: string;
  nextUrl: string;
  loading: boolean;
}

export const QuestionnaireForm: React.FC<QuestionnaireFormProps> = ({
  memberSeparation,
  fullQuestionTree,
  fullQuestionList,
  backUrl,
  nextUrl,
  loading: questionsLoading,
}) => {
  const { setShowGlobalGenericError } = useContext(SeparationFlowContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] =
    useState<QuestionnaireFormSchemaShape>({});
  const [initialTouched, setInitialTouched] = useState<
    FormikTouched<QuestionnaireFormSchemaShape>
  >({});
  const [validationSchema, setValidationSchema] =
    useState<QuestionnaireFormSchema>();
  const navigate = useNavigate();
  const [saveMemberSeparationAnswersMutation] =
    useSaveMemberSeparationAnswersMutation();
  const handleBack = () => {
    navigate(backUrl, { state: { time: new Date().getTime() } });
  };
  const handleNext = () => {
    navigate(nextUrl, { state: { time: new Date().getTime() } });
  };

  useEffect(() => {
    setLoading(questionsLoading);
  }, [questionsLoading]);

  useEffect(() => {
    const newInitialValues = getInitialValues(
      fullQuestionList,
      memberSeparation?.memberSeparationAnswers
    );
    setInitialValues(newInitialValues);
    setValidationSchema(
      getQuestionnaireFormSchema(fullQuestionTree, newInitialValues)
    );
  }, [
    fullQuestionList,
    fullQuestionTree,
    memberSeparation?.memberSeparationAnswers,
  ]);

  const handleFormSubmit = async (values: typeof initialValues) => {
    if (!memberSeparation?.memberSeparationId) {
      return;
    }

    await handleSaveAnswers(
      setLoading,
      saveMemberSeparationAnswersMutation,
      memberSeparation,
      fullQuestionTree,
      values,
      handleNext,
      setShowGlobalGenericError
    );
  };

  const handleErrors = (errors: FormikErrors<QuestionnaireFormSchemaShape>) => {
    if (!errors || !Object.keys(errors).length) {
      return;
    }

    const fieldId = Object.keys(errors)[0];
    const field = document.querySelector(`label[for="${fieldId}"]`);
    field &&
      field.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
  };

  const resetVisibleQuestions = (
    questionId: string,
    value: string | number | (string | number)[] | undefined
  ) => {
    setInitialValues?.((prevState) => {
      const newInitialValues = { ...prevState, [questionId]: value };
      setValidationSchema(
        getQuestionnaireFormSchema(fullQuestionTree, newInitialValues)
      );
      return newInitialValues;
    });
    setInitialTouched?.((prevState) => {
      return { ...prevState, [questionId]: true };
    });
  };

  return loading ? (
    <Loading />
  ) : (
    <Formik
      initialValues={initialValues}
      initialTouched={initialTouched}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
      enableReinitialize={true}
      validateOnMount={true}
      validateOnChange={false}
    >
      {() => {
        return (
          <QuestionnaireFormBody
            memberSeparation={memberSeparation}
            fullQuestionTree={fullQuestionTree}
            initialValues={getInitialValues(
              fullQuestionList,
              memberSeparation?.memberSeparationAnswers
            )}
            saveMemberSeparationAnswersMutation={
              saveMemberSeparationAnswersMutation
            }
            resetVisibleQuestions={resetVisibleQuestions}
            handleBack={handleBack}
            handleErrors={handleErrors}
          />
        );
      }}
    </Formik>
  );
};
