import { Environment, getEnvironmentName } from "../environment-finder";
import { SettingsConfig, VariableValues } from "./settings-type";

type EnvVariableMap = Record<Environment, Partial<SettingsConfig>>;

let variableMap: EnvVariableMap, defaultVariables: SettingsConfig;

export function importSettings(
  defaultVariableParam: SettingsConfig,
  envVariableMap: EnvVariableMap
): void {
  defaultVariables = defaultVariableParam;
  variableMap = envVariableMap;
}

export function getEnvVariable(variableName: VariableValues): string {
  const envName = getEnvironmentName();
  const currentEnvironmentVariables = variableMap[envName] || {};
  const currentEnvVariableValue = currentEnvironmentVariables[variableName];

  return currentEnvVariableValue !== undefined
    ? currentEnvVariableValue
    : defaultVariables[variableName];
}
