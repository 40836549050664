import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getEnvironmentName } from "lib/environment";
import { Button } from "@justworkshr/milo-core";
import {
  CountryCode,
  Language,
  getLanguage,
  getLanguageDisplayName,
} from "app/internationalization/locales";
import Dropdown from "../Dropdown/Dropdown";
import styles from "./LanguagePicker.module.css";
import { LanguageItemRow } from "./components/LanguageItemRow";

// This key is shared across FE projects so that we can share language settings
const LOCAL_STORAGE_KEY = "locale";

export const getInitialLanguage = (): Language => {
  const navLanguage = navigator.language;
  const rootLanguage = navLanguage.split("-")[0];
  const storedLanguage = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (storedLanguage && isLanguage(storedLanguage)) {
    return storedLanguage;
  } else if (isLanguage(navLanguage)) {
    return navLanguage;
  } else if (isLanguage(rootLanguage)) {
    return rootLanguage;
  } else {
    return Language.en;
  }
};

export const isLanguage = (language: string): language is Language => {
  return Object.values(Language).includes(language as Language);
};

export const LanguagePicker = () => {
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [countryCode, setCountryCode] = useState(CountryCode.US);

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  // We only support these languages on prod right now, can expand as needed
  const availableCountryCodes =
    getEnvironmentName() === "production"
      ? [CountryCode.US, CountryCode.CA]
      : Object.values(CountryCode);

  useEffect(() => {
    const preferredLanguage: Language = getInitialLanguage();
    if (preferredLanguage) i18n.changeLanguage(preferredLanguage);
    setLoading(false);
  }, [setLoading, i18n]);

  const setLanguage = (countryCode: CountryCode) => {
    const languageLocale = getLanguage(countryCode);
    setCountryCode(countryCode);
    localStorage.setItem(LOCAL_STORAGE_KEY, languageLocale);
    i18n.changeLanguage(languageLocale);
  };

  if (loading) return null;

  return (
    <div>
      <Dropdown
        dropdownName="languages actions"
        content={
          <>
            {availableCountryCodes.map((countryCode) => (
              <LanguageItemRow
                countryCode={countryCode}
                key={countryCode}
                onClick={() => setLanguage(countryCode)}
              />
            ))}
          </>
        }
        open={dropdownOpen}
        onOpenChange={(newOpen) => {
          if (newOpen) {
            setDropdownOpen(true);
          } else {
            closeDropdown();
          }
        }}
        onClickOutside={() => {
          closeDropdown();
        }}
      >
        <Button
          color="neutral"
          type="button"
          variant="outlined"
          className={styles.dropdownButton}
          rightIcon="chevron-down"
          leftIcon="globe"
          size="sm"
        >
          <div className={styles.dropdownTitleWrapper}>
            <span>{getLanguageDisplayName(countryCode)}</span>
            <span className={styles.countryCode}>
              {countryCode.toUpperCase()}
            </span>
          </div>
        </Button>
      </Dropdown>
    </div>
  );
};
