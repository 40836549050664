import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TabList, Tab } from "@justworkshr/milo-core";
import { getSelectedManagerTab } from "./ManagerTabList.utils";
import { prependBasePath } from "pages/expenses/constants";

export const ManagerTabList: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const selected = getSelectedManagerTab(pathname);

  const handleChange = (index: number) => {
    if (index === 0) {
      return navigate(prependBasePath("ACTIVE_REQUESTS_LIST"));
    } else if (index === 1) {
      return navigate(prependBasePath("REQUEST_HISTORY"));
    }
  };

  return (
    <TabList selected={selected} onChange={handleChange}>
      <Tab data-testid="active-request-tab">Active Requests</Tab>
      <Tab data-testid="request-history-tab">Request History</Tab>
    </TabList>
  );
};

export default ManagerTabList;
