import { FormField, TextInput } from "@justworkshr/milo-form";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

type FormFragment = {
  firstName: string;
  middleName: string;
  lastName: string;
};

export default function NamesFragment() {
  const { t } = useTranslation();
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<FormFragment>();

  const fieldErrorHandler = (name: keyof FormFragment) =>
    errors[name] && touched[name] ? errors[name] : undefined;

  return (
    <>
      <FormField
        required
        name="firstName"
        label={t("First name")}
        error={fieldErrorHandler("firstName")}
      >
        <TextInput
          required
          name="firstName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.firstName}
        />
      </FormField>

      <FormField label={t("Middle name")}>
        <TextInput
          name="middleName"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.middleName}
        />
      </FormField>

      <FormField
        required
        name="lastName"
        label={t("Last name")}
        error={fieldErrorHandler("lastName")}
      >
        <TextInput
          required
          name="lastName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.lastName}
        />
      </FormField>
    </>
  );
}
