import {
  ReactElement,
  MouseEventHandler,
  ReactNode,
  useRef,
  useEffect,
  useState,
} from "react";
import styles from "./drawer.module.css";
import { createPortal } from "react-dom";

const {
  drawer,
  drawerLeft,
  drawerRight,
  drawerHidden,
  overlay,
  overlayHidden,
  overlayMotionEnter,
  overlayMotionLeave,
  panelContentWrapper,
  panelContentWrapperHidden,
  panelMotionRightEnter,
  panelMotionRightLeave,
  panelMotionLeftEnter,
  panelMotionLeftLeave,
  panelContent,
  panelHeader,
  panelBody,
} = styles;

interface DrawerProps {
  children: ReactElement;
  name: string;
  className?: string;
  header?: ReactNode;
  onClose: MouseEventHandler<HTMLSpanElement>;
  open: boolean;
  placement?: "left" | "right";
  showOverlay?: boolean;
  zIndex?: number;
}

export default function Drawer({
  children,
  name,
  className,
  header,
  onClose,
  open = false,
  placement = "right",
  showOverlay = true,
  zIndex = 9999999,
}: DrawerProps): ReactElement {
  const animationDuration = 300; /* this duration should match the duration in css file */
  const [hidden, toggleHidden] = useState(true);
  const [motionEnter, toggleMotionEnter] = useState(false);
  const [motionLeave, toggleMotionLeave] = useState(false);
  const drawerRef = useRef<HTMLDivElement>(null);
  const portalRootRef = useRef(document.createElement("div"));
  const bodyRef = useRef(document.querySelector("body"));

  useEffect(() => {
    drawerRef.current?.style.setProperty("--zIndex", zIndex.toString());
  }, [zIndex]);

  useEffect(() => {
    const portal = portalRootRef.current;
    return () => {
      portal.remove();
    };
  }, []);

  useEffect(() => {
    /* toggle drawer visibility and animation */
    if (open) {
      toggleMotionEnter(true);
      toggleHidden(false);
      setTimeout(() => {
        toggleMotionEnter(false);
      }, animationDuration);
    } else {
      toggleMotionLeave(true);
      setTimeout(() => {
        toggleMotionLeave(false);
        toggleHidden(true);
      }, animationDuration);
    }

    /* render drawer through react portal when it's open for the first time */
    if (open && !bodyRef.current?.contains(portalRootRef.current)) {
      bodyRef.current?.appendChild(portalRootRef.current);
    }
  }, [open]);

  const drawerClasses = [drawer];
  if (className) {
    drawerClasses.push(className);
  }
  if (placement === "left") {
    drawerClasses.push(drawerLeft);
  } else {
    drawerClasses.push(drawerRight);
  }
  if (!open) {
    drawerClasses.push(drawerHidden);
  }

  const overlayClasses = [overlay];
  if (hidden) {
    overlayClasses.push(overlayHidden);
  }
  if (motionEnter) {
    overlayClasses.push(overlayMotionEnter);
  }
  if (motionLeave) {
    overlayClasses.push(overlayMotionLeave);
  }

  const panelContentWrapperClasses = [panelContentWrapper];
  if (hidden) {
    panelContentWrapperClasses.push(panelContentWrapperHidden);
  }
  if (motionEnter) {
    if (placement === "left") {
      panelContentWrapperClasses.push(panelMotionLeftEnter);
    } else {
      panelContentWrapperClasses.push(panelMotionRightEnter);
    }
  }
  if (motionLeave) {
    if (placement === "left") {
      panelContentWrapperClasses.push(panelMotionLeftLeave);
    } else {
      panelContentWrapperClasses.push(panelMotionRightLeave);
    }
  }

  const drawerOverlay = (
    <span
      className={overlayClasses.join(" ")}
      data-testid="overlay"
      aria-hidden="true"
      onClick={onClose}
    />
  );

  const drawerPanel = (
    <div
      className={panelContentWrapperClasses.join(" ")}
      data-testid={`${name}-panel`}
    >
      <div className={panelContent}>
        {header && <div className={panelHeader}>{header}</div>}
        <div className={panelBody}>{children}</div>
      </div>
    </div>
  );

  return createPortal(
    <div className={drawerClasses.join(" ")} ref={drawerRef} data-testid={name}>
      {showOverlay && drawerOverlay}
      {drawerPanel}
    </div>,
    portalRootRef.current
  );
}
