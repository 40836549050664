import React from "react";
import styles from "../../ExpenseDetailsPage.module.css";
import { isFormFieldHidden } from "../../ExpenseDetailsPage.utils";
import { ExpenseCategory } from "types/Expenses";
import {
  FormField,
  RadioButton,
  RadioButtonGroup,
} from "@justworkshr/milo-form";
import { BILLABLE_VALUE, NON_BILLABLE_VALUE } from "./Billable.constants";

const { ExpenseDetailsPageRadioWrapper } = styles;

const BILLABLE_KEY = "isBillableToClient";

interface BillableProps {
  isBillableToClient: {
    value: string;
    error: string;
  };
  handleFormValue: ({
    key,
    value,
  }: {
    key: typeof BILLABLE_KEY;
    value: string;
  }) => void;
  selectedCategory: ExpenseCategory | null;
}

export const Billable: React.FC<BillableProps> = React.memo(
  ({ selectedCategory, handleFormValue, isBillableToClient }) => {
    if (
      !selectedCategory ||
      isFormFieldHidden("billableToClient", selectedCategory)
    ) {
      return null;
    }

    const handleBillableChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      handleFormValue({
        key: BILLABLE_KEY,
        value: event.target.value,
      });
    };

    return (
      <FormField required label="Billable to client">
        <div className={ExpenseDetailsPageRadioWrapper}>
          <RadioButtonGroup
            name="Billable to client"
            onChange={handleBillableChange}
            value={isBillableToClient.value}
          >
            <RadioButton value="false" label={NON_BILLABLE_VALUE} />
            <RadioButton value="true" label={BILLABLE_VALUE} />
          </RadioButtonGroup>
        </div>
      </FormField>
    );
  }
);
