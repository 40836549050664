import React, { ReactNode, useState } from "react";
import styles from "./Row.module.css";
import { Link } from "react-router-dom";
import { buildClockworkWebPath } from "lib/resource-finder";

interface RowProps {
  label?: string;
  value?: string | null;
  children?: ReactNode;
  required?: boolean;
  showHideButton?: boolean;
  hiddenValueFormat?: string;
  tag?: string;
  boldValue?: boolean;
  capitalizeValue?: boolean;
  subtag?: string;
  className?: string;
  link?: string | null;
}

const Row: React.FC<RowProps> = ({
  label,
  value,
  children,
  required = false,
  showHideButton = false,
  hiddenValueFormat = "•••",
  tag,
  boldValue = false,
  capitalizeValue = true,
  subtag,
  className,
  link,
}: RowProps) => {
  const [isValueHidden, setIsValueHidden] = useState(showHideButton);

  const renderContent = () => {
    const content = value || children;
    const linkContent = (
      <Link to={buildClockworkWebPath(link || "members/employees")}>
        {content}
      </Link>
    );

    return isValueHidden ? hiddenValueFormat : link ? linkContent : content;
  };

  const renderInfoValue = (content: ReactNode, tag?: string) => (
    <div className={`${styles.valueRowWrapper} ${className}`}>
      <div
        className={`${styles.valueRow} ${styles.infoValue} ${
          capitalizeValue ? styles.capitalize : ""
        }`}
      >
        {content ? (
          <>
            {
              <span className={`${boldValue ? styles.bold : ""}`}>
                {renderContent()}
              </span>
            }
            {showHideButton && (
              <div
                onClick={() => {
                  setIsValueHidden(!isValueHidden);
                }}
                className={styles.showAction}
              >
                {isValueHidden ? "Show" : "Hide"}
              </div>
            )}
          </>
        ) : required ? (
          <span className={styles.missing}>Missing</span>
        ) : (
          <span className={styles.label}>Not On File</span>
        )}
        {tag && <span className={styles.tag}>{tag}</span>}
      </div>
      {subtag && <span className={styles.subtag}>{subtag}</span>}
    </div>
  );

  if (!label) {
    return renderInfoValue(value || children, tag);
  }

  return (
    <div className={styles.infoRow}>
      <div className={styles.label}>{label}</div>
      {renderInfoValue(value || children, tag)}
    </div>
  );
};

export default Row;
