import { FlexSpace } from "@justworkshr/milo-core";
import styles from "../Offboarding.module.css";
import { REQUEST_SEPARATION_COPY } from "../constants";
import { useRequestSeparationContext } from "../context/RequestSeparationContext";
import { DisplayIcon } from "@justworkshr/milo-icons";
import { useTranslation } from "react-i18next";

const RequestSeparationComplete = () => {
  const { t } = useTranslation();
  const { memberName } = useRequestSeparationContext();

  return (
    <FlexSpace className={styles.completionPageContainer}>
      <div className={styles.completionPageInnerContainer}>
        <DisplayIcon iconName={"check-mark"} color="blueGreen" />
        <h2>{t("Separation requested")}</h2>
        <h1>{t("Important next steps")}</h1>
        <p>{REQUEST_SEPARATION_COPY.nextStepsPara1(memberName)}</p>
        <p>{REQUEST_SEPARATION_COPY.nextStepsPara2}</p>
      </div>
    </FlexSpace>
  );
};

export default RequestSeparationComplete;
