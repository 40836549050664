import { SORT } from "../components/Table/Table.constants";

export const HIDDEN = "HIDDEN";
export const REQUIRED = "REQUIRED";
export const OPTIONAL = "OPTIONAL";

export const REQUIRED_BADGE_COPY = "Required";
export const OPTIONAL_BADGE_COPY = "Optional";

export const DEFAULT_TABLE_PARAMS = {
  pageNum: 0,
  resultsPerPage: 20,
  sortBy: "DATE",
  sortDirection: SORT.DESC,
};

export const PAGINATION_OPTIONS = [
  { value: 20, description: "20" },
  { value: 50, description: "50" },
  { value: 75, description: "75" },
  { value: 100, description: "100" },
];

export const DEFAULT_DOWNLOAD_PROPS = {
  target: "_blank",
  rel: "noopener noreferrer",
  download: true,
};
