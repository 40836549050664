import { useGetHelpMenuQuery } from "types/generated/operations";
import Dropdown from "../../common/dropdown";
import HelpContent from "../help-content";
import HeaderButton from "../../common/header-button";
import styles from "./help-button.module.css";
import { usePanelsControlContext } from "app-frame/header/context/panels-control-context-provider";
import { PanelsControlActionType } from "app-frame/header/context/panels-control-context-provider/types";
import {
  ViewType,
  useViewContext,
} from "app-frame/header/context/view-context-provider";

const { dropdown } = styles;

export default function HelpButton(): JSX.Element {
  const { view } = useViewContext();
  const { state, dispatch } = usePanelsControlContext();
  const { advancedHelpDropdownShown } = state;

  const { data, loading } = useGetHelpMenuQuery();
  if (loading || !data) {
    return <></>;
  }
  const hasAccessToAdvancedHelp =
    data.authenticatedMember.capabilities.hasAccessToAdvancedHelpChat;
  const accountManager = data.authenticatedMember.company.accountManager;

  const chatLink = function () {
    if (window.Forethought) {
      dispatch({ type: PanelsControlActionType.OPEN_FORETHOUGHT_HELP_CHAT });
      window.Forethought("widget", "open");
    }
  };

  const normalHelpButton = (
    <HeaderButton
      onClick={chatLink}
      testId="help-menu-button"
      text="Help"
      buildInIconName="help"
    />
  );

  const advancedHelpButtonContent = (
    <HeaderButton
      text="Help"
      buildInIconName="help"
      testId="help-menu-button"
      showCaret
      hideCaretOnTabletAndMobile
      active={advancedHelpDropdownShown}
    />
  );

  const dropdownContent = <HelpContent accountManager={accountManager} />;

  const advancedHelpButton = (
    <Dropdown
      className={dropdown}
      dropdownName="help menu"
      content={dropdownContent}
      open={view !== ViewType.MOBILE && advancedHelpDropdownShown}
      showArrow
      onOpenChange={(newOpen) => {
        if (newOpen) {
          dispatch({
            type: PanelsControlActionType.OPEN_ADVANCED_HELP_DROPDOWN,
          });
        } else {
          dispatch({
            type: PanelsControlActionType.CLOSE_ADVANCED_HELP_DROPDOWN,
          });
        }
      }}
      onClickOutside={() => {
        dispatch({
          type: PanelsControlActionType.CLOSE_ADVANCED_HELP_DROPDOWN,
        });
      }}
    >
      {advancedHelpButtonContent}
    </Dropdown>
  );

  return hasAccessToAdvancedHelp ? advancedHelpButton : normalHelpButton;
}
