import { NavGroup } from "types";
import { ReactElement } from "react";
import Callout from "../callout";

type NavCalloutSectionParams = Pick<NavGroup, "navItems">;

export default function NavCalloutSection({
  navItems,
}: NavCalloutSectionParams): ReactElement {
  const calloutItems = navItems.map(
    ({ badge, path, displayText, outstandingTaskCount }) => {
      const calloutArgs = {
        path,
        icon: badge?.imageUrl || undefined,
        outstandingTaskCount,
      };
      return (
        <Callout key={path} {...calloutArgs}>
          {displayText}
        </Callout>
      );
    }
  );
  /*
    <% nav_group.nav_items.each do |nav_item| %>
          <% has_callout_image_badge = !nav_item.badge.nil? && nav_item.badge.type == 'callout' %>
    <a class="banner-link <%= 'current' if nav_item.is_active %>" href="<%=nav_item.path%>">
        <% if has_callout_image_badge %>
    <%= image_tag nav_item.badge.image_url %>
    <% end %>
    <h3 class="<%= 'no-image' if !has_callout_image_badge %>">
    <%= nav_item.display_text %>
    </h3>
    <% count_of_tasks_resolved_here = outstanding_tasks[nav_item.path] %>
        <% if !count_of_tasks_resolved_here.nil? && count_of_tasks_resolved_here > 0  %>
    <span class="badge <%=nav_item.badge.type%>-badge"><%= count_of_tasks_resolved_here %></span>
        <% else %>
<%= image_tag "arrow-right-thick-rounded-navy.svg", class: "arrow" %>
    <% end %>
    </a>
    <% end %>

 */
  return <>{calloutItems}</>;
}
