import { Button } from "@justworkshr/milo-core";
import { ActionFooter, TextInput } from "@justworkshr/milo-form";
import { SystemIcon } from "@justworkshr/milo-icons";
import { fetchClient } from "@justworkshr/fe-utility-kit";
import { buildClockworkWebPath } from "lib/resource-finder";
import { Formik, FormikProps } from "formik";
import { FormikFormField } from "lib/formik/FormikFormField";
import { bankAccountFormSchema } from "pages/company-settings/utils/bankAccountFormSchema";
import styles from "pages/company-settings/components/EditBankAccountForm.module.css";
import { useNavigate } from "react-router-dom";
import {
  useBankForm,
  BankFormData,
} from "pages/company-settings/hooks/useBankForm";
import { useState } from "react";

interface EditBankAccountFormProps {
  onSubmit: (values: BankFormData) => Promise<void>;
  loading?: boolean;
}

const {
  form,
  formFieldWrapper,
  formBankNameContainer,
  formBankName,
  formBankNameSuccessIcon,
  actionFooter,
} = styles;

// TODO: extract bankNameCache and fetchBankName to a separate file, post refactor
const bankNameCache: Record<
  string,
  { bankName: string; errors: Record<string, string> }
> = {};

const fetchBankName = async (routingNumber: string) => {
  if (bankNameCache[routingNumber]) {
    return {
      bankName: bankNameCache[routingNumber].bankName,
      errors: bankNameCache[routingNumber].errors,
    };
  }

  if (routingNumber === "026009593") {
    return {
      bankName: "",
      errors: {
        routingNumber:
          "This is a wire transfer routing number; please use a direct deposit one.",
      },
    };
  }

  try {
    const url = `/company/bank_account/get_bank_name?routing_number=${routingNumber}`;
    const response = await fetchClient.get(buildClockworkWebPath(url));
    if (response.ok) {
      const data = await response.json();
      let bankNameErrors;
      if (data.bank_name === "") {
        bankNameErrors = {
          routingNumber: "We don't recognize that routing number. Try again.",
        };
      } else {
        bankNameErrors = {};
      }
      bankNameCache[routingNumber] = {
        bankName: data.bank_name,
        errors: bankNameErrors,
      };
      return { bankName: data.bank_name, errors: bankNameErrors };
    }
    return {
      bankName: "",
      errors: { routingNumber: "Error fetching bank name." },
    };
  } catch (error) {
    return {
      bankName: "",
      errors: { routingNumber: "Error fetching bank name." },
    };
  }
};

export const EditBankAccountForm: React.FC<EditBankAccountFormProps> = ({
  onSubmit,
  loading,
}) => {
  const navigate = useNavigate();

  const { formData, setFormData, setShowGlobalNetworkError } = useBankForm();

  const [submitting, setSubmitting] = useState(false);

  const checkDuplicateEntry = async (formData: BankFormData) => {
    try {
      const response = await fetchClient.post(
        buildClockworkWebPath("/company/bank_account/duplicate_entry"),
        {
          body: JSON.stringify({
            account_number: formData.accountNumber,
            routing_number: formData.routingNumber,
          }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        return data.duplicate;
      } else {
        throw new Error("Duplicate entry check failed to fetch data");
      }
    } catch (error) {
      setShowGlobalNetworkError && setShowGlobalNetworkError(true);
    }
  };

  const handleBankFormSubmit = async (values: BankFormData) => {
    setSubmitting(true);
    setFormData({ ...values, bankName: formData.bankName });
    const duplicate = await checkDuplicateEntry(values);

    if (duplicate === true) {
      navigate("/company-settings/edit-bank-account/error/already-on-file");
      return;
    }

    await onSubmit(values);
    setSubmitting(false);
  };

  const validateRoutingNumber = async (values: BankFormData) => {
    let errors;
    if (
      values.routingNumber.length === 9 &&
      !isNaN(Number(values.routingNumber))
    ) {
      const { bankName, errors: fetchErrors } = await fetchBankName(
        values.routingNumber
      );
      setFormData({ ...formData, bankName });
      errors = fetchErrors;
    } else {
      setFormData({ ...formData, bankName: "" });
    }
    return errors;
  };

  return (
    <Formik
      initialValues={formData}
      validate={validateRoutingNumber}
      validationSchema={bankAccountFormSchema}
      onSubmit={handleBankFormSubmit}
    >
      {({ handleSubmit, isValid, values }: FormikProps<BankFormData>) => (
        <>
          <div className={form}>
            <div className={formFieldWrapper}>
              <FormikFormField
                label="Routing number"
                name="routingNumber"
                required
              >
                <TextInput name="routingNumber" />
              </FormikFormField>
              {formData.bankName && (
                <div className={formBankNameContainer}>
                  <SystemIcon
                    className={formBankNameSuccessIcon}
                    iconName="check-circle"
                    color="success"
                  />
                  <div className={formBankName}>{formData.bankName}</div>
                </div>
              )}
            </div>
            <div className={formFieldWrapper}>
              <FormikFormField
                label="Account number"
                name="accountNumber"
                required
              >
                <TextInput name="accountNumber" />
              </FormikFormField>
            </div>
          </div>
          <ActionFooter
            className={actionFooter}
            actions={[
              <Button
                data-testid="submit-button"
                type="submit"
                disabled={
                  !isValid ||
                  values.accountNumber === "" ||
                  values.routingNumber === ""
                }
                loading={submitting || loading}
                onClick={handleSubmit}
                key="save-button"
              >
                Continue
              </Button>,
            ]}
          />
        </>
      )}
    </Formik>
  );
};

export default EditBankAccountForm;
