import React, { useState } from "react";
import { Button } from "@justworkshr/milo-core";
import { MemberGlance, Notice, SettingsTable } from "../../../../components";
import {
  ExpensesSettingsManagers,
  ExpensesSettingsAdmins,
  ExpensesSettingsReportsAdmins,
} from "types/Expenses";
import {
  PERMISSIONS_TABLE_HEADERS,
  PERMISSIONS_EMPTY_STATE_COPY,
} from "./PermissionsTable.constants";
import {
  generateEmployeeLists,
  generateEmployeeListForTable,
  hasManageEmployeeExpensesPermission,
  hasManageExpensesPermission,
  hasReportsPermission,
} from "./PermissionsTable.utils";
import styles from "./PermissionsTable.module.css";

const { PermissionsTableWrapper, ExpandButton } = styles;

interface PermissionsTableProps {
  managers: ExpensesSettingsManagers;
  expenseAdmins: ExpensesSettingsAdmins;
  reportsAdmins: ExpensesSettingsReportsAdmins;
}

export const PermissionsTable: React.FC<PermissionsTableProps> = ({
  managers,
  expenseAdmins,
  reportsAdmins,
}) => {
  const [isTableExpanded, setIsTableExpanded] = useState(false);
  const employeeLists = generateEmployeeLists(
    managers,
    expenseAdmins,
    reportsAdmins
  );
  const employeeListForTable = generateEmployeeListForTable(
    managers,
    expenseAdmins,
    reportsAdmins
  );

  if (managers.length === 0 && expenseAdmins.length === 0) {
    return <Notice>{PERMISSIONS_EMPTY_STATE_COPY}</Notice>;
  }
  return (
    <div className={PermissionsTableWrapper}>
      <SettingsTable
        headers={PERMISSIONS_TABLE_HEADERS}
        dataTestId="permissions-table"
      >
        {(isTableExpanded
          ? employeeListForTable
          : employeeListForTable.slice(0, 3)
        ).map((employee) => {
          return (
            <tr key={employee.uuid}>
              <td>
                <MemberGlance
                  member={{
                    uuid: employee.uuid,
                    name: employee.friendlyFullName,
                    photoUrl: employee.photoUrl,
                    deskTitle: employee.deskTitle,
                  }}
                />
              </td>
              <td>
                {hasManageEmployeeExpensesPermission(
                  employeeLists.managers,
                  employee.uuid
                )}
              </td>
              <td>
                {hasManageExpensesPermission(
                  employeeLists.expenseAdmins,
                  employee.uuid
                )}
              </td>
              <td>
                {hasReportsPermission(
                  employeeLists.reportsAdmins,
                  employee.uuid
                )}
              </td>
            </tr>
          );
        })}
      </SettingsTable>
      {employeeListForTable.length > 3 && (
        <Button
          className={ExpandButton}
          variant="ghost"
          rightIcon={isTableExpanded ? "chevron-up" : "chevron-down"}
          onClick={() => setIsTableExpanded(!isTableExpanded)}
        >
          {isTableExpanded ? "Collapse" : "View all"}
        </Button>
      )}
    </div>
  );
};

export default PermissionsTable;
