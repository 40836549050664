import { Box, Divider } from "@justworkshr/milo-core";
import styles from "./CollapsibleCard.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";
import { useTranslation } from "react-i18next";

const ShowHideAction = ({
  show,
  onClick,
}: {
  show: boolean;
  onClick: () => void;
}) => {
  const { t } = useTranslation();

  const iconName = show ? "chevron-up" : "chevron-down";
  const text = t(show ? "Hide Detail" : "Show Detail");

  return (
    <div className={styles.showHideContainer}>
      <SystemIcon iconName={iconName} color="brand" />
      <span onClick={onClick}>{text}</span>
    </div>
  );
};

const CollapsibleCard = ({
  children,
  open,
  title,
  noGap,
  onClick,
}: React.PropsWithChildren<{
  open: boolean;
  title: string;
  noGap?: boolean;
  onClick: () => void;
}>) => {
  return (
    <Box
      padding="2xl"
      border={{ borderColor: "neutral", borderWidth: "sm", borderRadius: "md" }}
    >
      <div className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
        <ShowHideAction show={open} onClick={onClick} />
      </div>
      {open && (
        <>
          <Divider spacing="lg" />
          <div className={noGap ? undefined : styles.collapseContentContainer}>
            {children}
          </div>
        </>
      )}
    </Box>
  );
};

export default CollapsibleCard;
