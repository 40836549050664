import { ReactElement, useEffect, useState } from "react";
import styles from "./home-page.module.css";
import ClaimsForm from "../claims-form";
import { Link } from "react-router-dom";
import { formatApiToDraftState } from "../claims-form/container/format-api";
import { SavedDrafts } from "./drafts-table";
import { AdminDetails } from "../types";
import {
  GetDraftsDataQuery,
  useGetCompanyAdminDetailsQuery,
  useGetDraftsDataQuery,
  useGetSubmittedClaimReportsByCompanyQuery,
} from "types/generated/operations";
import { Button, TabList, Tab, Spinner } from "@justworkshr/milo-core";
import { SubmittedReportsTable } from "./submitted-table";
const {
  actionSection,
  actionHeader,
  claimsHome,
  contentStyles,
  existingDocuments,
  actionDescription,
  actionButton,
  actionButtonContainer,
  disableScroll,
  actionGuide,
  description,
  yellowStyle,
  backLink,
  header,
  tabBox,
  actionGuideText,
} = styles;

const tableText = {
  drafts:
    "Complete and submit all drafts to provide benefits to your employee.",
  submitted:
    "View and download your previously submitted claims. Changes or updates to a claim after submission are not reflected.",
};

export default function HomePage(): ReactElement {
  const [showModal, setShowModal] = useState(false);

  const [loggedInData, setLoggedInData] = useState<AdminDetails>({
    adminName: "",
    adminEmail: "",
    adminPhone: "",
    companyId: null,
    adminUuid: "",
    companyUuid: "",
  });

  // Table Logic
  const [selectedDraft, setSelectedDraft] = useState<
    GetDraftsDataQuery["draftClaimReportsByAdmin"][0] | null
  >(null);
  const [tableType, setTableType] = useState<"drafts" | "submitted">("drafts");

  // API Logic
  const { data, loading } = useGetCompanyAdminDetailsQuery();
  const {
    data: draftsData,
    refetch: draftsRefetch,
    loading: draftsLoading,
  } = useGetDraftsDataQuery({
    variables: {
      adminUuid: loggedInData?.adminUuid,
    },
    notifyOnNetworkStatusChange: true,
    skip: !loggedInData?.adminUuid,
  });
  const {
    data: submittedReportData,
    refetch: submittedReportRefetch,
    loading: submittedReportLoading,
  } = useGetSubmittedClaimReportsByCompanyQuery({
    variables: {
      companyUuid: loggedInData?.companyUuid,
    },
    notifyOnNetworkStatusChange: true,
    skip: !loggedInData?.adminUuid,
  });

  // loops through the shaped claim reports and prints out
  useEffect(() => {
    if (!loading && data?.currentMember) {
      setLoggedInData({
        adminUuid: data.currentMember.uuid,
        adminEmail: data.currentMember.emailWork || "",
        adminPhone: data.currentMember.phoneOffice || "",
        companyUuid: data.currentMember.company.uuid,
        companyId: data.currentMember.company.id,
        adminName: data.currentMember.friendlyFullName,
      });
    }
  }, [data, loading]);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showModal]);

  function handleCloseModal() {
    setShowModal(false);
    draftsRefetch();
    submittedReportRefetch();
  }

  function handleBeginNewReport() {
    setSelectedDraft(null);
    setShowModal(true);
  }

  const claimsFormData = formatApiToDraftState(selectedDraft?.data);

  console.log({ claimsFormData });

  return (
    <>
      <main
        className={`${claimsHome} ${showModal && disableScroll}`}
        data-testid="claims-home-page"
      >
        <div className={yellowStyle} />
        <section className={`${actionSection}`}>
          <div className="milo--grid">
            <div className={contentStyles}>
              <div className={header}>
                <a href="workers-compensation-claims" className={backLink}>
                  Back
                </a>
                <h1>Workers&apos; compensation claims</h1>
                <p className={actionDescription}>
                  Provide benefits in response to incidents that occur at the
                  workplace.
                </p>
                <hr />
              </div>
              <div className={actionButtonContainer}>
                <section className={actionHeader}>
                  <div className={description}>
                    <h2>Report an incident</h2>
                    <p>
                      File a report whenever an incident occurs at work, whether
                      your employee needs medical care or not.
                    </p>
                  </div>
                  <div className={actionButton} data-testid="new=-report">
                    <Button
                      onClick={handleBeginNewReport}
                      key="begin-new-report"
                    >
                      Begin
                    </Button>
                  </div>
                </section>
                <section className={actionGuide}>
                  <div className={actionGuideText}>
                    <h3>Guide to claims</h3>
                    <div>Our resource for workers' compensation questions</div>
                  </div>
                  <Link
                    target="_blank"
                    to="/workers-compensation-claims/about"
                    data-testid="guide-click-open-link"
                  >
                    <Button
                      variant="ghost"
                      size="sm"
                      rightIcon="external-link"
                      key="guide-click-open"
                    >
                      Open
                    </Button>
                  </Link>
                </section>
              </div>
            </div>
          </div>
        </section>
        <div className="milo--grid">
          <section
            className={`${existingDocuments}`}
            data-testid="existing-claims-table"
          >
            <section className={tabBox}>
              <TabList
                defaultSelected={0}
                orientation="horizontal"
                onChange={(index: number) =>
                  index === 0
                    ? setTableType("drafts")
                    : setTableType("submitted")
                }
              >
                <Tab data-testid="drafts-tab">Drafts</Tab>
                <Tab data-testid="submitted-tab">Submitted Claims</Tab>
              </TabList>
            </section>
            <p>{tableText[tableType]}</p>
            {tableType === "drafts" &&
              (draftsLoading ? (
                <Spinner />
              ) : (
                <SavedDrafts
                  drafts={draftsData?.draftClaimReportsByAdmin || []}
                  setSelected={(draft) => {
                    setSelectedDraft(draft);
                    setShowModal(true);
                  }}
                />
              ))}
            {tableType === "submitted" &&
              (submittedReportLoading ? (
                <Spinner />
              ) : (
                <SubmittedReportsTable
                  claims={
                    submittedReportData?.submittedClaimReportsByCompany || []
                  }
                />
              ))}
          </section>
        </div>
      </main>
      {showModal && (
        <ClaimsForm
          data-testid="claims-form"
          closeModal={handleCloseModal}
          adminData={loggedInData}
          loadedStep={claimsFormData?.page ? claimsFormData.page - 1 : 0}
          preloadedData={
            selectedDraft !== null ? claimsFormData.formData : undefined
          }
          selectedDraftId={selectedDraft?.draftId || ""}
        />
      )}
    </>
  );
}
