import { MouseEventHandler, ReactElement } from "react";
import styles from "./header-button.module.css";

const {
  headerButton,
  alertsHeaderButton,
  helpHeaderButton,
  whatsNewHeaderButton,
  iconLabelContainer,
  iconContainer,
  badge,
  label,
  hasTaskIcon,
  caretIcon,
  hasCaretIcon,
  hasResponsiveCaretIcon,
  isActive,
  customizedIcon,
  buildInIcon,
} = styles;

interface HeaderButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  buildInIconName?: "alerts" | "help" | "whats-new";
  customIcon?: ReactElement;
  text?: string;
  outstandingTaskCount?: number;
  testId?: string;
  showCaret?: boolean;
  hideCaretOnTabletAndMobile?: boolean;
  active?: boolean;
}

export default function HeaderButton({
  onClick,
  buildInIconName,
  customIcon,
  text,
  outstandingTaskCount,
  testId = "",
  showCaret,
  hideCaretOnTabletAndMobile,
  active,
}: HeaderButtonProps): ReactElement {
  const headerButtonClasses = [headerButton];
  if (buildInIconName) {
    if (buildInIconName === "alerts") {
      headerButtonClasses.push(alertsHeaderButton);
    } else if (buildInIconName === "help") {
      headerButtonClasses.push(helpHeaderButton);
    } else if (buildInIconName === "whats-new") {
      headerButtonClasses.push(whatsNewHeaderButton);
    }
  }
  if (active) {
    headerButtonClasses.push(isActive);
  }
  if (showCaret) {
    headerButtonClasses.push(hasCaretIcon);
    if (hideCaretOnTabletAndMobile) {
      headerButtonClasses.push(hasResponsiveCaretIcon);
    }
  }

  let taskCountIcon: ReactElement | null = null;
  if (outstandingTaskCount && outstandingTaskCount > 0) {
    taskCountIcon = <div className={badge}>{outstandingTaskCount}</div>;
  }

  return (
    <button
      className={headerButtonClasses.join(" ")}
      onClick={onClick}
      data-testid={testId}
    >
      <div className={iconLabelContainer}>
        <div className={`${iconContainer} ${taskCountIcon ? hasTaskIcon : ""}`}>
          {customIcon ? (
            <span className={customizedIcon}>{customIcon}</span>
          ) : (
            <span className={buildInIcon} data-testid="build-in-icon"></span>
          )}
          {taskCountIcon}
        </div>
        {text && <span className={label}>{text}</span>}
      </div>
      {showCaret && <span className={caretIcon}></span>}
    </button>
  );
}
