import React, { useState, ReactElement } from "react";
import { RadioButtonProps } from "../RadioButton/RadioButton";
import styles from "./RadioButtonGroup.module.css";

const { radioButtonGroup, horizontal, vertical, framed, basic } = styles;

export interface RadioButtonGroupProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /** The name of the radio button group. This name is applied to all nested radio buttons to group them together. */
  name: string;
  /** The default value that should be selected when the radio button group is first rendered. */
  defaultValue?: string;
  /** The currently selected value in the radio button group. */
  value?: string;
  /** Whether the radio button group is in an invalid state. */
  invalid?: boolean;
  /** Whether the radio buttons in the group should be disabled or not. */
  disabled?: boolean;
  /** The radio buttons that should be rendered as part of the group. */
  children:
    | ReactElement<RadioButtonProps>
    | Array<ReactElement<RadioButtonProps>>;
  /**
   * A function that is called when the selected radio button in the group is changed.
   * @param event The change event object.
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /** Whether the radio buttons are framed vs unframed */
  variant?: "basic" | "framed";
  /** Whether the radio buttons are stacked or displayed in a grid */
  orientation?: "vertical" | "horizontal";
}

type NewRadioButtonProps = Pick<
  RadioButtonProps,
  "name" | "onChange" | "checked" | "invalid" | "disabled" | "_variant"
>;

export const RadioButtonGroup = React.forwardRef(
  (
    {
      name,
      defaultValue,
      value,
      invalid = false,
      disabled,
      children,
      onChange,
      variant = "basic",
      orientation = "vertical",
      ...props
    }: RadioButtonGroupProps,
    ref
  ): ReactElement => {
    const [selectedValue, setSelectedValue] = useState(value ?? defaultValue);
    const [preValueSelected, setPreValueSelected] = useState(value);

    if (value !== preValueSelected) {
      setSelectedValue(value);
      setPreValueSelected(value);
    }

    const handleOnChange = (
      newSelection: string,
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      if (newSelection !== selectedValue) {
        setSelectedValue(newSelection);
        onChange?.(event);
      }
    };

    const getRadioButtons = (): ReactElement[] => {
      const mappedChildren = React.Children.map(children, (radioButton) => {
        const newProps: NewRadioButtonProps = {
          name,
          onChange: handleOnChange,
          checked: selectedValue === radioButton.props.value,
        };
        if (invalid) {
          newProps.invalid = true;
        }
        if (disabled === true) {
          newProps.disabled = true;
        }
        if (variant) {
          newProps._variant = variant;
        }
        return React.cloneElement(radioButton as ReactElement, newProps);
      });
      return mappedChildren;
    };

    return (
      <div
        className={`${radioButtonGroup} ${
          orientation === "horizontal" ? horizontal : vertical
        } ${variant === "framed" ? framed : basic}`}
        ref={ref as React.RefObject<HTMLDivElement>}
        {...props}
      >
        {getRadioButtons()}
      </div>
    );
  }
);

RadioButtonGroup.displayName = "RadioButtonGroup";

export default RadioButtonGroup;
