import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { loadCountryConfigFiles } from "../countryConfigLoader";
import { EmployeePayType } from "pages/employer-of-record/payments/constants";
import { addDisbursementDate } from "pages/employer-of-record/payments/utils";
import { AdditionalPayContextProps } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { FieldConfig } from "../../SetupFormGenerator";
import { CountryConfigKeyType } from "../types";
import { useFormikContext } from "formik";
import {
  AdditionalPayFormData,
  FormPayloadType,
  PaymentDetailsType,
} from "../../../types";
import type { EorMembersType } from "pages/employer-of-record/payments/types";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";

function useCountryConfigFetcher<T extends FormPayloadType = FormPayloadType>(
  type: EmployeePayType,
  additionalPayContext: AdditionalPayContextProps | null,
  setPaymentSettingsFields: Dispatch<SetStateAction<FieldConfig<T>[]>>,
  setFormFields?: Dispatch<SetStateAction<FieldConfig<T>[]>>
): void {
  const { setFieldValue, values } =
    useFormikContext<AdditionalPayFormData<T>>();
  const { getDefaultCurrency } = useContext(SupportedCountriesContext);
  const selectedCountry = additionalPayContext?.selectedCountry;
  const membersData = additionalPayContext?.membersData;
  useEffect(() => {
    const fetchCountryConfigs = async () => {
      const configs = await loadCountryConfigFiles(
        type,
        selectedCountry as CountryConfigKeyType
      );
      let paymentSettingsFormFields = configs.PAYMENT_SETTINGS_FORM_FIELDS;
      if (additionalPayContext?.disbursementDateOptions) {
        paymentSettingsFormFields = addDisbursementDate(
          paymentSettingsFormFields,
          Object.values(additionalPayContext.disbursementDateOptions)
        );
      }
      setPaymentSettingsFields(paymentSettingsFormFields);
      if (setFormFields && "TAX_FORM_FIELDS" in configs) {
        // This can either have complex type narrowing,
        // or we can just cast it to FieldConfig[]
        setFormFields(configs.TAX_FORM_FIELDS as FieldConfig<T>[]);
      }
    };

    fetchCountryConfigs();
  }, [
    selectedCountry,
    additionalPayContext?.disbursementDateOptions,
    setFormFields,
    setPaymentSettingsFields,
    type,
  ]);

  useEffect(() => {
    const formikMemberDetails: Array<PaymentDetailsType> = [];
    const validationMemberData: Array<
      Pick<EorMembersType, "id" | "workStartDate" | "workEndDate">
    > = [];
    membersData?.forEach((data: EorMembersType) => {
      const memberFormData = {
        memberId: data.id,
        grossAmount: "",
        currency: getDefaultCurrency(selectedCountry),
        workPeriodStart: "",
        workPeriodEnd: "",
      };
      formikMemberDetails.push(memberFormData);
      validationMemberData.push({
        id: data.id,
        workStartDate: data.workStartDate,
        workEndDate: data.workEndDate,
      });
    });

    setFieldValue("paymentDetails", formikMemberDetails);
    setFieldValue("validationData.members", validationMemberData);
  }, [
    membersData,
    setFieldValue,
    selectedCountry,
    values.paymentDetails.length,
    getDefaultCurrency,
  ]);
}

export default useCountryConfigFetcher;
