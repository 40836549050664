import * as Yup from "yup";
import { TFunction } from "i18next";
import { FieldData, FieldTypes, ValidationType } from "./types";
import { MixedSchema, ObjectShape } from "yup";

type FieldValidationSchema =
  | Yup.StringSchema
  | Yup.NumberSchema
  | InstanceType<typeof MixedSchema>
  | Yup.BooleanSchema
  | object;

const generateFieldValidation = (
  fieldType: string,
  validations: ValidationType
): FieldValidationSchema => {
  let yupValidation;

  if (fieldType === FieldTypes.text && validations.text) {
    yupValidation = Yup.string();

    const bankCodeRegEx = /^[0-9]{3}$/;
    const bsbCodeRegEx = /^\s*(\d{3}(-?|\s*)\d{3})\s*$/;
    const clabeRegEx = /^\d{18,18}$/;
    const ifsCodeRegEx = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    const numberRegEx = /^[0-9]+$/;
    const routingNumberRegEx = /^[0-9]{3}$/;
    const sortCodeRegEx = /^([\d]{2} ?-?){3}$/;
    const swiftCodeRegEx =
      /^[A-Z]{4}[-]{0,1}[A-Z]{2}[-]{0,1}[A-Z0-9]{2}[-]{0,1}[A-Z0-9]{3}$/;

    if (validations.text?.max) {
      yupValidation = yupValidation.max(
        validations.text.max.value,
        validations.text.max.message
      );
    }

    if (validations.text?.min) {
      yupValidation = yupValidation.min(
        validations.text.min.value,
        validations.text.min.message
      );
    }

    if (validations.text?.length) {
      yupValidation = yupValidation.length(
        validations.text.length.value,
        validations.text.length.message
      );
    }

    if (validations.text.number?.enabled) {
      yupValidation = yupValidation.matches(
        numberRegEx,
        validations.text.number.message
      );
    } else if (validations.text?.sortCode?.enabled) {
      yupValidation = yupValidation.matches(
        sortCodeRegEx,
        validations.text.sortCode.message
      );
    } else if (validations.text?.bankCode?.enabled) {
      yupValidation = yupValidation.matches(
        bankCodeRegEx,
        validations.text.bankCode.message
      );
    } else if (validations.text?.bsbCode?.enabled) {
      yupValidation = yupValidation.matches(
        bsbCodeRegEx,
        validations.text.bsbCode.message
      );
    } else if (validations.text?.routingNumber?.enabled) {
      yupValidation = yupValidation.matches(
        routingNumberRegEx,
        validations.text.routingNumber.message
      );
    } else if (validations.text?.clabe?.enabled) {
      yupValidation = yupValidation.matches(
        clabeRegEx,
        validations.text.clabe.message
      );
    } else if (validations.text?.ifsCode?.enabled) {
      yupValidation = yupValidation.matches(
        ifsCodeRegEx,
        validations.text.ifsCode.message
      );
    } else if (validations.text?.swiftCode?.enabled) {
      yupValidation = yupValidation.matches(
        swiftCodeRegEx,
        validations.text.swiftCode.message
      );
    } else if (validations.text?.email?.enabled) {
      yupValidation = yupValidation.email(validations.text.email.message);
    }
  } else if (fieldType === FieldTypes.number && validations.number) {
    yupValidation = Yup.number();

    if (validations.number.max) {
      yupValidation = yupValidation.max(
        validations.number.max.value,
        validations.number.max.message
      );
    }

    if (validations.number.min) {
      yupValidation = yupValidation.min(
        validations.number.min.value,
        validations.number.min.message
      );
    }
  } else {
    yupValidation = Yup.mixed();
  }

  if (validations.required?.enabled) {
    yupValidation = yupValidation.required(validations.required.message);
  }

  return yupValidation;
};

type ObjectSchemaType = { [key: string]: FieldValidationSchema };

type FormSchemaShape = {
  [key: string]: FieldValidationSchema | Yup.ObjectSchema<ObjectSchemaType>;
};

const generateFormValidations = (fields: FieldData[], t: TFunction) => {
  const validationShape = fields.reduce<FormSchemaShape>((acc, curr) => {
    if (curr.fields?.length) {
      acc[curr.name] = generateFormValidations(curr.fields, t);
    } else if (curr.validations) {
      acc[curr.name] = generateFieldValidation(curr.type, curr.validations);
    }

    return acc;
  }, {});

  return Yup.object().shape(validationShape as ObjectShape);
};

export default generateFormValidations;
