const STATUSES = {
  APPROVED: "APPROVED",
  RETURNED: "RETURNED",
  INITIAL_REVIEW: "INITIAL_REVIEW",
  READY_FOR_APPROVAL: "READY_FOR_APPROVAL",
  APPROVE_REIMBURSEMENT: "APPROVE_REIMBURSEMENT",
  RETURN_REIMBURSEMENT: "RETURN_REIMBURSEMENT",
};

export const RETURNED_STATUS = STATUSES.RETURNED;

export const INITIAL_REVIEW_STATUS = STATUSES.INITIAL_REVIEW;

export const READY_FOR_APPROVAL_STATUS = STATUSES.READY_FOR_APPROVAL;

export const APPROVED_STATUS = STATUSES.APPROVED;

export const ADMIN_APPROVABLE_REQUEST_STATUSES = [
  STATUSES.INITIAL_REVIEW,
  STATUSES.READY_FOR_APPROVAL,
];

export const APPROVED_STATUSES = [
  STATUSES.READY_FOR_APPROVAL,
  STATUSES.APPROVED,
];

export const MANAGER_APPROVABLE_REQUEST_STATUSES = [STATUSES.INITIAL_REVIEW];

export const REQUEST_HISTORY_ADMIN_STATUSES = [
  STATUSES.RETURNED,
  STATUSES.APPROVED,
];

export const REQUEST_HISTORY_MANAGER_STATUSES = [
  STATUSES.RETURNED,
  STATUSES.READY_FOR_APPROVAL,
  STATUSES.APPROVED,
];
