import { useUpdateProjectsMutation } from "types/generated/operations";
import { useFeedbackBannerStore, useProjectsStore } from "../../store";
import { useNavigate } from "react-router-dom";
import {
  AlertTypes,
  prependBasePath,
  CREATE_PROJECTS_BANNER,
  UPDATE_PROJECTS_BANNER,
} from "../../constants";
import { useEffect } from "react";
import { ExpenseProject } from "types/Expenses";

export const useUpdateProjects = (isInitialSetup: boolean) => {
  const navigate = useNavigate();
  const { setFeedbackBanner } = useFeedbackBannerStore();
  const { setProjects } = useProjectsStore();

  const [
    updateProjects,
    { data: updateProjectsData, loading: isUpdateProjectsLoading },
  ] = useUpdateProjectsMutation();

  useEffect(() => {
    if (
      !isUpdateProjectsLoading &&
      updateProjectsData?.updateProjects?.success
    ) {
      setProjects({
        projects: updateProjectsData.updateProjects
          .updatedProjects as ExpenseProject[],
      });

      navigate(prependBasePath("SETTINGS"));

      setFeedbackBanner({
        type: AlertTypes.SUCCESS,
        show: true,
        message: isInitialSetup
          ? CREATE_PROJECTS_BANNER
          : UPDATE_PROJECTS_BANNER,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateProjectsLoading, updateProjectsData, setProjects]);

  return {
    updateProjects,
    isUpdateProjectsLoading,
  };
};
