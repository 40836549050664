import { Badge } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import styles from "./WorkLocationBadge.module.css";

type Props = {
  countryName: string;
};

export default function WorkLocationBadge(props: Props) {
  const { countryName } = props;

  const content = (
    <div className={styles.container}>
      <SystemIcon
        size="extra-small"
        iconName="pin"
        // className={styles.headerIcon}
        aria-labelledby="pin-label"
      />
      <span id="pin-label">{countryName}</span>
    </div>
  ) as unknown as string; // TODO(damon-g-b): IEORN-2314 - Right now, Badge only allows children to be a `string`

  return <Badge role="info">{content}</Badge>;
}
