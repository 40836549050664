export const REQUESTS_LIST_TABLE_HEADERS = [
  {
    key: "NAME",
    label: "Request Name",
    isSortable: true,
  },
  {
    key: "DATE",
    label: "Submission Date",
    isSortable: true,
  },
  {
    key: "STATUS",
    label: "Review Status",
    isSortable: true,
  },
  {
    key: "PAY_DATE",
    label: "Estimated Pay Date",
    isSortable: false,
  },
];

export const NO_REQUESTS_MESSAGE = `You haven't submitted any expenses yet. But, this is where you'll find them.`;
