export enum BonusTypes {
  DISCRETIONARY = "discretionary",
  NON_DISCRETIONARY = "nonDiscretionary",
  FLAT_SUM = "flatSum",
}

export enum PaymentTypes {
  GROSS = "false",
  NET = "true",
}
