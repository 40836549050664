import React from "react";
import styles from "../InstanceDetailsPanel.module.css";
import { Button, ButtonProps } from "@justworkshr/milo-core";
import { Link } from "react-router-dom";

const { detailsPanelButtons, connectedButtonText } = styles;

export type ExternalLinkButtonProps = {
  copy: string;
  url: string;
  variant?: ButtonProps<never>["variant"];
};

const ExternalLinkButton: React.FC<ExternalLinkButtonProps> = ({
  copy,
  url,
  variant = "filled",
}) => (
  <Button
    className={detailsPanelButtons}
    variant={variant}
    as={Link}
    to={url}
    target="_blank"
    rightIcon="external-link"
  >
    <span className={connectedButtonText}>{copy}</span>
  </Button>
);

export default ExternalLinkButton;
