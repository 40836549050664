import { ReactElement, ReactNode } from "react";
import styles from "./InfoCard.module.css";

type Props = {
  title?: string;
  subtitle?: string;
  description?: string;
  children?: ReactNode;
  footer?: ReactNode;
  padding?: boolean;
  className?: string;
};

export default function InfoCard({ title, children }: Props): ReactElement {
  return (
    <section className={styles.InfoCard}>
      <header className={styles.header}>
        <h3>{title}</h3>
      </header>
      <section>{children}</section>
    </section>
  );
}
