import { Button } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import { useRequestDetail } from "pages/expenses/store";
import React, { useState } from "react";
import { ExpenseInput } from "types/generated/operations";
import styles from "../../ExpenseDetailsPage.module.css";
import { DiscardEditsDialog } from "../DiscardEditsDialog";
import {
  useCreateUnattachedExpense,
  useUpdateUnattachedExpense,
} from "pages/expenses/hooks";
import { formatExpensesForSubmission } from "pages/expenses/utils";

const { ExpenseDetailsPageActionFooterWrapper } = styles;

interface UnattachedExpenseActionFooterProps {
  isEditMode: boolean;
  isFormValid: boolean;
  isExpenseUpdated: boolean;
  buildExpenseForCreation: () => ExpenseInput;
}

export const UnattachedExpenseActionFooter: React.FC<UnattachedExpenseActionFooterProps> =
  React.memo(
    ({
      isEditMode,
      isFormValid,
      isExpenseUpdated,
      buildExpenseForCreation,
    }) => {
      const [showDiscardEditsModal, setShowDiscardEditsModal] = useState(false);

      const handleDiscardEditsModal = () =>
        setShowDiscardEditsModal((prevState) => !prevState);

      const {
        resetRequestDetails,
        requestDetail: {
          metadata: { isUnattachedExpenseView },
        },
      } = useRequestDetail();

      const { createUnattachedExpense } = useCreateUnattachedExpense();
      const { updateUnattachedExpense } = useUpdateUnattachedExpense(() => {
        resetRequestDetails();
      });

      const handleDiscard = () => {
        resetRequestDetails();
      };

      const handleBack = () => {
        handleDiscardEditsModal();
      };

      const handleSubmit = async () => {
        const expenseDetails = buildExpenseForCreation();

        const expense = formatExpensesForSubmission([expenseDetails])[0];

        const data = {
          variables: {
            expense,
          },
        };

        if (isEditMode) {
          return updateUnattachedExpense(data);
        }

        return createUnattachedExpense(data);
      };

      const saveButtonText = isEditMode ? "Update" : "Save";

      const isSubmitDisabled =
        !isFormValid || (isEditMode && !isExpenseUpdated);

      if (!isUnattachedExpenseView) {
        return null;
      }

      return (
        <>
          {showDiscardEditsModal && (
            <DiscardEditsDialog
              onDiscard={handleDiscard}
              isOpen={showDiscardEditsModal}
              handleDiscardEditsModal={handleDiscardEditsModal}
            />
          )}
          <ActionFooter
            className={ExpenseDetailsPageActionFooterWrapper}
            actions={[
              <Button
                type="button"
                variant="outlined"
                key="go-back-button"
                onClick={handleBack}
                data-testid="go-back-button"
              >
                Go back
              </Button>,
              <Button
                type="button"
                key="submit-button"
                onClick={handleSubmit}
                disabled={isSubmitDisabled}
                data-testid="save-button"
              >
                {saveButtonText}
              </Button>,
            ]}
          />
        </>
      );
    }
  );
