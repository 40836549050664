import styles from "./ltdTabsContentTemplate.module.css";
import Divider from "../components/Divider/Divider";
import { NoncontributoryOptionsTable } from "../components/NoncontributoryOptionsTable/NoncontributoryOptionsTable";
import {
  OVERVIEW_CONTENT,
  OPTIONS_AND_COSTS_CONTENT,
  FAQ_CONTENT,
} from "../constants/ltdTabContent";
import faq_styles from "./faqTabContent.module.css";

const {
  headerText,
  subHeader,
  contentSection,
  infoTabsListItem,
  summaryText,
  detailText,
  subHeaderText,
  contentAndTableDivision,
} = styles;

const { faqContentSection, faqDetailText, faqSubDetailText } = faq_styles;

export const OverviewTab: React.FC = () => {
  return (
    <div>
      <div className={contentSection}>
        <div className={headerText}>{OVERVIEW_CONTENT.header.title}</div>
        <div className={subHeader}>{OVERVIEW_CONTENT.header.headerInfo}</div>
        <div className={subHeader}>{OVERVIEW_CONTENT.header.headerSubInfo}</div>
      </div>
      <Divider />
      <section>
        <div className={contentSection}>
          {Object.values(OVERVIEW_CONTENT.body).map(
            (item: { title: string; info: string; id: number }) => (
              <div key={item.id} className={infoTabsListItem}>
                <div className={summaryText}>{item.title}</div>
                <div className={detailText}>{item.info}</div>
              </div>
            )
          )}
        </div>
      </section>
    </div>
  );
};

export const OptionsAndCostsTab: React.FC = () => {
  return (
    <div>
      <div className={contentSection}>
        <div className={headerText}>
          {OPTIONS_AND_COSTS_CONTENT.header.title}
        </div>
        <div className={subHeader}>
          {OPTIONS_AND_COSTS_CONTENT.header.headerInfo}
        </div>
      </div>
      <Divider />
      <section>
        <div className={contentSection}>
          <div className={subHeaderText}>
            {OPTIONS_AND_COSTS_CONTENT.bodyTitle}
          </div>

          {Object.values(OPTIONS_AND_COSTS_CONTENT.body).map(
            (item: { subtitle: string; info: string; id: number }) => (
              <div key={item.id} className={infoTabsListItem}>
                <div className={detailText}>
                  <strong>{item.subtitle}</strong>
                  <br></br>
                  {item.info}
                </div>
              </div>
            )
          )}
        </div>
        <div className={contentAndTableDivision}></div>
      </section>
      <NoncontributoryOptionsTable
        costTableInfo={OPTIONS_AND_COSTS_CONTENT.costTableInfo}
      />
    </div>
  );
};

export const FaqTab: React.FC = () => {
  return (
    <div>
      <section>
        <div className={faqContentSection}>
          {Object.values(FAQ_CONTENT).map(
            (item: {
              title: string;
              info: string;
              id: number;
              subInfo?: string;
            }) => (
              <div key={item.id} className={infoTabsListItem}>
                <div className={faqDetailText}>
                  <strong>{item.title}</strong>
                  <br></br>
                  {item.info}
                  {item.subInfo !== undefined && (
                    <div className={faqSubDetailText}>{item.subInfo}</div>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </section>
    </div>
  );
};
