import { FC } from "react";
import { Alert, Button, PageHeader } from "@justworkshr/milo-core";
import { ActionFooter, Form } from "@justworkshr/milo-form";
import { Formik, type FormikProps } from "formik";
import * as Yup from "yup";
import parsePhoneNumber from "libphonenumber-js";
import EmergencyContactCard from "./EmergencyContactCard";
import PhonesAndEmailCard from "./PhonesAndEmailCard";
import useOnboardingForm from "../hooks/useOnboardingForm";
import Layout from "../Layout";
import { ContactInfoFormType } from "./types";
import { initialValues } from "./constants";
import HomeAddressCard from "./HomeAddressCard";
import MailingAddressCard from "./MailingAddressCard";
import styles from "./ContactInfo.module.css";
import onboardingStyles from "../Onboarding.module.css";
import { useTranslation } from "react-i18next";

const state_key = "contact-info";

const ContactInfo: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    form: {
      submitting,
      navigatingBack,
      showErrorAlert,
      errorAlertMessage,
      onSubmit,
      onBack,
    },
    memberOnboarding: {
      contactInfo,
      stepNumber,
      redirecting,
      idVerificationStatus,
    },
  } = useOnboardingForm(state_key);

  const FormSchema = Yup.object().shape({
    contactInfo: Yup.object().shape({
      homeAddress: Yup.object().shape({
        address1: Yup.string().required(t("This field is required")),
        address2: Yup.string(),
        city: Yup.string().required(t("This field is required")),
        countryCode: Yup.string().required(t("This field is required")),
        postalCode: Yup.string().required(t("This field is required")),
        zoneCode: Yup.string().required(t("This field is required")),
      }),
      mailingAddress: Yup.object().shape({
        address1: Yup.string().required(t("This field is required")),
        address2: Yup.string(),
        city: Yup.string().required(t("This field is required")),
        countryCode: Yup.string().required(t("This field is required")),
        postalCode: Yup.string().required(t("This field is required")),
        zoneCode: Yup.string().required(t("This field is required")),
      }),
      phoneNumbers: Yup.array()
        .of(
          Yup.object().shape({
            type: Yup.string(),
            value: Yup.string().test(
              "valid",
              t("Invalid Phone Number"),
              (value) => {
                const number = parsePhoneNumber(value || "");
                return number?.isValid() || false;
              }
            ),
          })
        )
        .min(1, t("Please enter at least one phone number"))
        .max(3),
      homeEmail: Yup.string()
        .email(t("This field must be a valid email"))
        .required(t("This field is required")),
      emergencyContact: Yup.object().shape({
        firstName: Yup.string().required(t("This field is required")),
        lastName: Yup.string().required(t("This field is required")),
        relationship: Yup.string().required(t("This field is required")),
        phoneNumber: Yup.string()
          .required(t("This field is required"))
          .test("valid", t("Invalid Phone Number"), (value) => {
            const number = parsePhoneNumber(value || "");
            return number?.isValid() || false;
          }),
      }),
    }),
  });

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {t(errorAlertMessage) ||
            t("An error occurred while submitting your contact information.")}
        </Alert>

        <PageHeader title={t("Enter your contact information")} />

        <Formik
          initialValues={contactInfo || initialValues}
          validationSchema={FormSchema}
          onSubmit={onSubmit}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
          }: FormikProps<ContactInfoFormType>) => (
            <Form onSubmit={handleSubmit}>
              <div className={styles.cards}>
                <HomeAddressCard
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                <MailingAddressCard
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                <PhonesAndEmailCard
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                <EmergencyContactCard
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </div>
              <ActionFooter
                className={onboardingStyles.footer}
                actions={[
                  <Button
                    type="submit"
                    color="brand"
                    loading={submitting}
                    disabled={navigatingBack}
                    key="submit-btn"
                  >
                    {t("Save & continue")}
                  </Button>,
                ]}
                secondary={[
                  <Button
                    type="button"
                    variant="outlined"
                    color="brand"
                    key="back-btn"
                    loading={navigatingBack}
                    onClick={onBack}
                  >
                    {t("Go back")}
                  </Button>,
                ]}
              />
            </Form>
          )}
        </Formik>
      </>
    </Layout>
  );
};

export default ContactInfo;
