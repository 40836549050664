import React from "react";
import { TextInput, Select, SelectOption } from "@justworkshr/milo-form";
import { useFormikContext } from "formik";
import {
  ContactInfoFormType,
  InternationalAddressInputType,
} from "../../onboarding/contact-info/types";
import { Zone } from "@shopify/address-consts";
import { SupportedCountriesData } from "../../contexts/supportedCountriesContext";
import { useTranslation } from "react-i18next";

type Props = {
  fieldName: string;
  handleBlur: (event: React.FocusEvent) => void;
  handleChange: (event: React.ChangeEvent) => void;
  zones: Zone[] | undefined;
  formPath: string;
  supportedCountriesData: SupportedCountriesData;
};

const fieldTypeMap: InternationalAddressInputType = {
  countryCode: "select",
  address1: "text",
  address2: "text",
  city: "text",
  postalCode: "text",
  zoneCode: "select",
};

const AddressFieldElement = ({
  fieldName,
  handleChange,
  handleBlur,
  zones,
  formPath,
  supportedCountriesData,
}: Props) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<ContactInfoFormType>();
  const type = fieldTypeMap[fieldName];

  switch (type) {
    case "text":
      return (
        <TextInput
          name={`contactInfo.${formPath}.${fieldName}`}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.contactInfo[formPath][fieldName]}
        />
      );
    case "select":
      if (fieldName === "countryCode") {
        return (
          <Select
            name={`contactInfo.${formPath}.${fieldName}`}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={
              values.contactInfo[formPath][fieldName].length > 0
                ? undefined
                : t("Select one")
            }
            value={values.contactInfo[formPath][fieldName] ?? ""}
          >
            {Object.values(supportedCountriesData).map((country) => {
              const translatedName = country.commonName
                ? t(country.commonName)
                : "";
              return (
                <SelectOption value={country.code} key={country.code}>
                  {country.emojiFlag + " " + translatedName}
                </SelectOption>
              );
            })}
          </Select>
        );
      }

      if (fieldName === "zoneCode") {
        return (
          <Select
            name={`contactInfo.${formPath}.${fieldName}`}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={
              values.contactInfo[formPath][fieldName].length > 0
                ? undefined
                : t("Select one")
            }
            value={values.contactInfo[formPath][fieldName] ?? ""}
          >
            {zones?.map((zone) => (
              <SelectOption value={zone.code} key={zone.code}>
                {zone.name}
              </SelectOption>
            ))}
          </Select>
        );
      }
      break;
  }

  return null;
};

export default AddressFieldElement;
