import React, { FC, ReactNode } from "react";
import { Spinner } from "@justworkshr/milo-core";
import useWindowSize from "../hooks/useWindowSize";
import Stepper from "../Stepper";
import Step from "../Step";
import { EoROnboardingSteps, type StepperStatus } from "./constants";

import styles from "./Layout.module.css";
import OnboardingHeader from "./OnboardingHeader";

const { centered, content, layout, padding, fullWidth, hidden, stepper } =
  styles;

type Props = {
  step: number;
  loading: boolean;
  idVerificationStatus?: StepperStatus;
  children: ReactNode;
};

const Layout: FC<Props> = ({
  step,
  loading,
  idVerificationStatus,
  children,
}) => {
  const { width } = useWindowSize();

  return (
    <>
      <OnboardingHeader />
      <div
        className={["milo--grid", padding, step < 1 ? centered : layout].join(
          " "
        )}
      >
        <aside className={step < 1 ? hidden : stepper}>
          <Stepper
            direction={width > 850 ? "vertical" : "horizontal"}
            hideStepLabels={width <= 850}
          >
            {EoROnboardingSteps.map(({ key, title, status }, index) => (
              <Step
                key={key}
                title={title}
                stepNumber={index + 1}
                status={status(
                  index + 1,
                  step,
                  key === "id-verification" ? idVerificationStatus : undefined
                )}
              />
            ))}
          </Stepper>
        </aside>

        <section className={step < 1 ? fullWidth : content}>
          {loading ? <Spinner /> : children}
        </section>
      </div>
    </>
  );
};

Layout.defaultProps = {
  idVerificationStatus: undefined,
};

export default Layout;
