import styles from "./Pagination.module.css";
import { IconButton } from "@justworkshr/milo-core";

const {
  PaginationLabel,
  PaginationWrapper,
  PaginationShowing,
  PaginationControls,
} = styles;

interface PaginationProps {
  pageSize: number;
  totalCount: number;
  disabled?: boolean;
  currentPage: number;
  onPageChange: (newPage: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  pageSize,
  totalCount,
  currentPage,
  onPageChange,
  disabled = false,
}) => {
  const totalPages = Math.ceil(totalCount / pageSize);
  const leftDataRange = (currentPage - 1) * pageSize + 1;
  const rightDataRange =
    currentPage === totalPages ? totalCount : leftDataRange - 1 + pageSize;

  const handleBackClick = () => onPageChange(currentPage - 1);

  const handleNextClick = () => onPageChange(currentPage + 1);

  return (
    <div className={PaginationWrapper}>
      <div className={PaginationShowing}>
        <h5 className={PaginationLabel}>
          Showing {leftDataRange}-{rightDataRange} of {totalCount}
        </h5>
      </div>
      <div className={PaginationControls}>
        <IconButton
          type="button"
          color="neutral"
          variant="ghost"
          title="left button"
          iconName="chevron-left"
          aria-label="back-button"
          onClick={handleBackClick}
          data-testid="back-button"
          disabled={currentPage === 1 || disabled}
        />
        <h5 className={PaginationLabel}>
          Page {currentPage} of {totalPages}
        </h5>
        <IconButton
          type="button"
          color="neutral"
          variant="ghost"
          title="right button"
          aria-label="next-button"
          iconName="chevron-right"
          onClick={handleNextClick}
          data-testid="next-button"
          disabled={currentPage === totalPages || disabled}
        />
      </div>
    </div>
  );
};

export default Pagination;
