import React from "react";
import styles from "./Table.module.css";

const { TableContainer } = styles;

interface TableProps {
  children: React.ReactNode;
}

export const Table: React.FC<TableProps> = React.memo(({ children }) => {
  return (
    <table role="table" className={TableContainer}>
      <thead>
        <tr>
          <th>Date</th>
          <th>Description</th>
          <th>Type</th>
          <th style={{ textAlign: "right" }}>Amount (USD)</th>
        </tr>
      </thead>
      {children}
    </table>
  );
});

export default Table;
