import { Badge } from "@justworkshr/milo-core";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface StatusBadgeProps {
  isProcessed: boolean;
  invoiceId: string;
}

export const StatusBadge: FC<StatusBadgeProps> = ({
  isProcessed,
  invoiceId,
}) => {
  const { t } = useTranslation();
  const label = isProcessed
    ? `${t("Invoice")} ${t("ID")}: ${invoiceId}`
    : t("Estimated Invoice");
  return (
    <Badge
      role={isProcessed ? "info" : "warning"}
      emphasis={isProcessed ? "subtle" : "bold"}
    >
      {label}
    </Badge>
  );
};
