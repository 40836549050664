import React from "react";
import { AuditLogItem } from "../AuditLogItem";

interface ReturnedProps {
  name: string;
  reason: string;
  occurredAt: string;
  memberName: string;
}

export const Returned: React.FC<ReturnedProps> = ({
  name,
  reason,
  occurredAt,
  memberName,
}) => {
  return (
    <AuditLogItem
      author={memberName}
      occurredAt={occurredAt}
      previewText="Returned request"
    >
      {name} was returned for: {reason}
    </AuditLogItem>
  );
};

export default Returned;
