import styles from "./ThirdPartyTable.module.css";
import { ReactElement } from "react";

const {
  headingContainer,
  heading,
  subheading,
  count,
  headingAction,
  tableContainer,
} = styles;

interface ThirdPartyTable {
  tableKey: string;
  tableTitle: string;
  subTitle?: string;
  dataCountLabel: string;
  primaryActionBtn?: ReactElement;
  secondaryActionBtn?: ReactElement;
  afterTable?: ReactElement;
  children: ReactElement;
}

const ThirdPartyTable = ({
  tableKey,
  tableTitle,
  subTitle,
  dataCountLabel,
  primaryActionBtn,
  secondaryActionBtn,
  afterTable,
  children,
}: ThirdPartyTable) => {
  return (
    <div data-testid={`firms-table-${tableKey}`}>
      <div className={headingContainer}>
        <h2 className={heading}>
          {tableTitle}
          <span className={subheading}>{subTitle} </span>
          <span className={count}>{dataCountLabel} </span>
        </h2>
        <div className={headingAction}></div>
        {primaryActionBtn}
        {secondaryActionBtn}
      </div>
      <table className={tableContainer}>{children}</table>
      {afterTable}
    </div>
  );
};

export default ThirdPartyTable;
