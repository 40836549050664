import { Box } from "@justworkshr/milo-core";
import { DisplayIcon } from "@justworkshr/milo-icons";
import styles from "./InfoBox.module.css";
import { Link } from "react-router-dom";

const { content, icon } = styles;
const InfoBox = () => {
  return (
    <Box backgroundColor="neutralSubtle" padding="2xl">
      <div className={content}>
        <div className={icon}>
          <DisplayIcon iconName="check-on-page" size="medium" />
        </div>
        <p>
          Add a third-party admin, individual accountant or accounting firm in
          the{" "}
          <Link
            className="milo--link-small"
            to={"/invitation-center#third-party-or-temp"}
          >
            Invite center
          </Link>
          . They will be added to the company directory and do not count towards
          your seat fees.
        </p>
      </div>
    </Box>
  );
};

export default InfoBox;
