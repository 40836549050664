import { FormField, TextInput } from "@justworkshr/milo-form";
import {
  WHITESPACE_ERROR,
  ONE_HUNDRED_CHARACTER_LIMIT_AND_EMOJI_ERROR,
} from "pages/expenses/constants";
import {
  invalidStringInputValue,
  invalidWhitespaceInputValue,
} from "pages/expenses/utils";
import React, { useEffect, useState } from "react";
import styles from "./RequestName.module.css";
import { useRequestDetail } from "pages/expenses/store";

const { RequestNameWrapper } = styles;

export const RequestName: React.FC = () => {
  const {
    requestDetail: {
      request: { name: requestName },
    },
    setRequestDetail,
  } = useRequestDetail();

  const [name, setName] = useState({
    value: requestName || "",
    error: "",
  });

  useEffect(() => {
    setRequestDetail({
      request: {
        name: name.value,
      },
      metadata: {
        isRequestUpdated: !!name.value,
      },
    });
  }, [name, setRequestDetail]);

  const handleRequestName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (invalidStringInputValue(value, 100)) {
      setName({
        value,
        error: ONE_HUNDRED_CHARACTER_LIMIT_AND_EMOJI_ERROR,
      });
    } else if (invalidWhitespaceInputValue(value)) {
      setName({ value, error: WHITESPACE_ERROR });
    } else {
      setName({ value, error: "" });
    }
  };

  return (
    <div className={RequestNameWrapper}>
      <FormField required label="Request Name" error={name.error}>
        <TextInput
          required
          name="requestName"
          value={name.value}
          onChange={handleRequestName}
          data-testid="request-name-input"
        />
      </FormField>
    </div>
  );
};
