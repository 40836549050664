import {
  ACCEPTED_FILE_TYPES,
  FILE_TOO_LARGE_ERROR,
  FILE_TYPE_NOT_ACCEPTED_ERROR,
  MAXIMUM_FILE_SIZE,
  PRONOUNS_CHAR_LIMIT_ERROR,
  PRONOUNS_ERROR,
} from "./constants";

export const validateFile = (file: File | undefined): string[] => {
  if (!file) return [];
  const fileErrors: string[] = [];
  const validSize = file.size <= MAXIMUM_FILE_SIZE * 1000000;
  const validType = ACCEPTED_FILE_TYPES.split(",").some(
    (fileType) => file.type === fileType.trim()
  );
  if (!validSize) fileErrors.push(FILE_TOO_LARGE_ERROR);
  if (!validType) fileErrors.push(FILE_TYPE_NOT_ACCEPTED_ERROR);
  return fileErrors;
};

const validatePronounsInput = (pronouns: string) => {
  const VALIDATE_PRONOUNS = /^[/a-z ,.'-]+$/i;
  if (pronouns.length > 255) {
    return PRONOUNS_CHAR_LIMIT_ERROR;
  } else if (pronouns.length === 0) {
    return "";
  } else if (!VALIDATE_PRONOUNS.exec(pronouns)) {
    return PRONOUNS_ERROR;
  }
  return "";
};

export const validateEditProfile = (
  input: string,
  formErrors: { pronouns: string; photoFile: string[] },
  setFormErrors: React.Dispatch<
    React.SetStateAction<{
      pronouns: string;
      photoFile: string[];
    }>
  >
): string => {
  const errors: string | null = validatePronounsInput(input);
  setFormErrors({ ...formErrors, pronouns: errors });
  return errors;
};
