import {
  useContext,
  createContext,
  ReactNode,
  useState,
  SetStateAction,
  Dispatch,
  useEffect,
} from "react";
import { AlertState, RequestSeparationFormType } from "../types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetEoRMemberByUuidQuery } from "types/generated/operations";
import { Loading } from "pages/team-management/routes/SeparationFlow/Loading/Loading";

type RequestSeparationContextType = {
  formValues: RequestSeparationFormType;
  setFormValues: Dispatch<SetStateAction<RequestSeparationFormType>>;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  memberName: string;
  memberId: string;
  alert: AlertState | null;
  setAlert: Dispatch<SetStateAction<AlertState | null>>;
};

type Props = {
  children: ReactNode;
};

const formInitialValues = {
  desiredLastDay: "",
  separationType: "",
  separationReason: "",
};

const RequestSeparationContext = createContext<RequestSeparationContextType>({
  formValues: formInitialValues,
  setFormValues: () => {},
  currentStep: 1,
  setCurrentStep: () => {},
  memberName: "",
  memberId: "",
  alert: null,
  setAlert: () => {},
});

export const RequestSeparationProvider = ({ children }: Props) => {
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const [formValues, setFormValues] =
    useState<RequestSeparationFormType>(formInitialValues);
  const [alert, setAlert] = useState<AlertState | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const offboardingRootPath = `/employer-of-record/offboarding/${id}`;

  const { data: memberData, loading } = useGetEoRMemberByUuidQuery({
    variables: { memberUuid: id },
  });
  const memberName = memberData?.eorMember.firstName || "";
  const memberId = memberData?.eorMember.id || "";

  // Navigation
  useEffect(() => {
    // Clear alert on route change
    setAlert(null);

    // Step 1
    if (currentStep === 1 && location.pathname !== offboardingRootPath) {
      navigate(offboardingRootPath);
    }
    // Step 2
    if (
      currentStep === 2 &&
      location.pathname !== `${offboardingRootPath}/review`
    ) {
      navigate(`${offboardingRootPath}/review`);
    }
    // Step 3
    if (
      currentStep === 3 &&
      location.pathname !== `${offboardingRootPath}/complete`
    ) {
      navigate(`${offboardingRootPath}/complete`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, location.pathname]);

  if (loading) {
    return <Loading />;
  }

  return (
    <RequestSeparationContext.Provider
      value={{
        formValues,
        setFormValues,
        currentStep,
        setCurrentStep,
        memberName,
        memberId,
        alert,
        setAlert,
      }}
    >
      {children}
    </RequestSeparationContext.Provider>
  );
};

export const useRequestSeparationContext = () =>
  useContext(RequestSeparationContext);
