import * as Yup from "yup";

const PLACEHOLDER = "_ _ /_ _ /_ _ _ _";

export const landingPageSchema = Yup.object().shape({
  date: Yup.string()
    .required("Required")
    .test("is-placeholder", "Required", function (value) {
      return !value.includes(PLACEHOLDER);
    })
    .test("is-future-date", "Future dates are not allowed", function (value) {
      if (value.includes(PLACEHOLDER)) {
        return true;
      }
      const dateValue = new Date(value);
      const now = new Date();
      now.setHours(0, 0, 0, 0); // set to start of the day
      return dateValue.getTime() <= now.getTime();
    }),
  memberName: Yup.string().required("Required"),
});

export const witnessesSchema = Yup.object().shape({
  witnessesExist: Yup.string().required("Required"),
  witnessList: Yup.array().when(
    "witnessesExist",
    ([witnessesExist], schema) => {
      return witnessesExist === "Yes"
        ? schema.of(
            Yup.object({
              name: Yup.string().required("Required"),
              contact: Yup.string().required("Required"),
            })
          )
        : schema;
    }
  ),
});
