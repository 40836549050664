import { AlertTypes, prependBasePath } from "pages/expenses/constants";
import { useFeedbackBannerStore, useRequestDetail } from "pages/expenses/store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDeleteReimbursementRequestMutation } from "types/generated/operations";

export const useDeleteReimbursementRequest = () => {
  const navigate = useNavigate();
  const { resetRequestDetails } = useRequestDetail();
  const { setFeedbackBanner } = useFeedbackBannerStore();

  const [
    deleteReimbursementRequest,
    {
      data: deleteReimbursementRequestData,
      loading: isDeleteReimbursementRequestLoading,
    },
  ] = useDeleteReimbursementRequestMutation();

  useEffect(() => {
    if (
      !isDeleteReimbursementRequestLoading &&
      deleteReimbursementRequestData?.deleteReimbursementRequests?.success &&
      deleteReimbursementRequestData?.deleteReimbursementRequests
        ?.deletedReimbursementRequests
    ) {
      navigate(prependBasePath("HOME"));

      setFeedbackBanner({
        show: true,
        type: AlertTypes.SUCCESS,
        message: `${deleteReimbursementRequestData.deleteReimbursementRequests.deletedReimbursementRequests[0]?.name} has been deleted.`,
      });

      resetRequestDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteReimbursementRequestLoading, deleteReimbursementRequestData]);

  return {
    deleteReimbursementRequest,
  };
};
