import { useEffect, useState } from "react";
import { useProfileContextData } from "../contexts/ProfileInfoContext";
import styles from "./Profile.module.css";
import { Alert, Box, Spinner, Tab, TabList } from "@justworkshr/milo-core";
import { EorEmployeePersonalInfo } from "types/employer-of-record";
import { getEmployeeName } from "../utils/index";
import { HeaderSectionProps } from "../components/HeaderSection/types";
import HeaderSection from "../components/HeaderSection/HeaderSection";
import { CLOCKWORK_WEB_PATH, SUBPATHS, TABNAMES } from "../constants";
import { buildClockworkWebPath } from "lib/resource-finder";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { DropdownMenu } from "../components/DropdownMenu/DropdownMenu";
import { useGetAdminCapabilitiesQuery } from "types/generated/operations";
import { CountryCode } from "pages/employer-of-record/onboarding/constants";

function Breadcrumbs({ department }: { department?: string }) {
  return (
    <nav className={styles.breadcrumbs}>
      <a href={buildClockworkWebPath(CLOCKWORK_WEB_PATH.MEMBERS_LIST)}>
        {" "}
        {"Employee"}{" "}
      </a>
      {department && " / "}
      <a href={buildClockworkWebPath(CLOCKWORK_WEB_PATH.MEMBERS_LIST)}>
        {" "}
        {department}
      </a>
    </nav>
  );
}

type UrlSlug = string;
type TabName = string;

type Props = {
  hideBreadcrumbs?: boolean;
  tabs?: Record<UrlSlug, TabName>;
};

const Profile = ({ hideBreadcrumbs, tabs = TABNAMES }: Props) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [profileUpdatedAlertText] = useState(searchParams.get("updated"));
  const showProfileUpdatedAlert = !!profileUpdatedAlertText;
  const { data } = useGetAdminCapabilitiesQuery();
  const { profileInfo, loadingProfileInfo } = useProfileContextData(
    !!profileUpdatedAlertText
  );

  const canManageTimeOff =
    data?.authenticatedMember?.capabilities?.hasPermissionToUpdateTimeOff;

  const personalInfo: EorEmployeePersonalInfo =
    profileInfo?.eorEmployeeProfile.personalInfo;

  const department = profileInfo?.eorEmployeeProfile.jobInformation?.department;
  const navigate = useNavigate();

  const countryCode = profileInfo?.eorEmployeeProfile?.employment
    ?.workCountry as keyof typeof CountryCode;

  const subdivisionCode =
    profileInfo?.eorEmployeeProfile?.contactDetails?.homeAddress?.zoneCode;

  const headerSectionProps: HeaderSectionProps = {
    name: getEmployeeName(personalInfo),
    pronouns: personalInfo?.pronouns,
    title: profileInfo?.eorEmployeeProfile?.role?.title,
    countryCode: countryCode || "",
    subdivisionCode: subdivisionCode || "",
    dateOfBirth: personalInfo?.dateOfBirth,
  };

  const [orientation, setOrientation] = useState<
    "horizontal" | "vertical" | undefined
  >("horizontal");

  useEffect(() => {
    const handleResize = () => {
      const isSmallScreen = window.innerWidth < 768;

      setOrientation(isSmallScreen ? "horizontal" : "vertical");
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (searchParams.get("updated")) {
      const url = new URL(window.location.href);
      url.search = ""; // Remove query params

      // Replace the current url with one that does not have the `updated` parameter for two reasons:
      // 1. When the user refreshes the page, they do not see a profile edit success message
      // 2. When the user goes back, they end up on the previous page, rather than this without query params
      navigate(url.pathname.replace("/cf", ""), {
        replace: true,
      });
    }
  }, [id, navigate, searchParams]);

  const goToSubRoute = (route: string) => {
    navigate(`${route}`);
  };

  const filteredTabs = Object.entries(tabs).filter(([urlSlug]) => {
    if (urlSlug === SUBPATHS.TIME_OFF) {
      return canManageTimeOff;
    }
    return true;
  });

  const getSelectedTab = () => {
    const { pathname } = location;

    for (let i = 0; i < filteredTabs.length; i++) {
      if (pathname.includes(filteredTabs[i][0])) {
        return i;
      }
    }
    return 0;
  };

  const horizontalMenuLayout = () => {
    return (
      <div className={styles.dropdown}>
        <DropdownMenu
          selected={filteredTabs[getSelectedTab()][0]}
          paths={filteredTabs.map(([slug]) => slug)}
        />
        <Outlet />
      </div>
    );
  };

  const verticalMenuLayout = () => {
    return (
      <div className={styles.tabSection}>
        <div className={styles.tabList}>
          <TabList orientation={orientation} defaultSelected={getSelectedTab()}>
            {filteredTabs.map(([urlSlug, tabName]) => (
              <Tab
                key={urlSlug}
                onClick={() => {
                  goToSubRoute(urlSlug);
                }}
              >
                {tabName}
              </Tab>
            ))}
          </TabList>
        </div>
        <Outlet />
      </div>
    );
  };

  // Ensures the page shows as loading during a refetch of the profile
  const loading = !profileInfo || loadingProfileInfo;

  return (
    <>
      {showProfileUpdatedAlert && !loading && (
        <Alert color="additive" visible dismissible>
          {profileUpdatedAlertText}
        </Alert>
      )}
      <Box padding="lg">
        {!hideBreadcrumbs && (
          <Breadcrumbs department={department ?? undefined} />
        )}

        {!loading && (
          <>
            <HeaderSection {...headerSectionProps} />
            {orientation == "horizontal"
              ? horizontalMenuLayout()
              : verticalMenuLayout()}
          </>
        )}
        {loading && (
          <div className={styles.spinner}>
            <Spinner data-testid="spinner" />
          </div>
        )}
      </Box>
    </>
  );
};

export default Profile;
