import React, { Children, ReactElement, ReactNode, cloneElement } from "react";
import styles from "./stepper.module.css";
import { StepperHelpers } from "pages/pmt/integrations/partner-setup-page/hooks/useStepper";
import clsx from "lib/styling-utils/clsx";

const { stepper, step, completed } = styles;

export type StepperProps = {
  children: ReactNode;
} & StepperHelpers;

const Stepper: React.FC<StepperProps> = ({ children, ...stepperHelpers }) => {
  const validChildren = Children.toArray(children).filter(
    (child): child is ReactElement => React.isValidElement(child)
  );

  return (
    <ul className={["milo--root milo--grid", stepper].join(" ")}>
      {validChildren.map((child, index: number) => {
        const isPastStep = (index ? index : 0) < stepperHelpers.currentStep;
        return (
          <li
            className={clsx(step, isPastStep && completed)}
            id={`step-${index}`}
            key={child.props.name}
            aria-current={index === stepperHelpers.currentStep}
          >
            {cloneElement(child as ReactElement, {
              stepperHelpers: { ...stepperHelpers, stepIndex: index },
            })}
          </li>
        );
      })}
    </ul>
  );
};

export default Stepper;
