import styles from "./ActiveFirmTable.module.css";
import { Link } from "react-router-dom";
import { Button, Card } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import { ThirdPartyTable } from "pages/firms/components";
import {
  buildRelativePermissionsLink,
  buildRelativeRemoveFirmLink,
} from "../../helpers";
import { FirmsPageQuery } from "types/generated/operations";
import { useTranslation } from "react-i18next";
import { permissionGroupDetails } from "../../../../constants";

type Firm = NonNullable<FirmsPageQuery["firm"]>;

const { tableRow, columns, activeFirmCard, stackSM, stackMD, stackLG, rowSM } =
  styles;

interface ActiveFirmTableProps {
  firm: Firm;
}

const ActiveFirmTable = ({ firm }: ActiveFirmTableProps) => {
  const { t } = useTranslation();

  function iconName(id: string) {
    return firm.permissions.includes(id) ? "check-circle" : "error";
  }

  function iconColor(id: string) {
    return firm.permissions.includes(id) ? "success" : "disabled";
  }

  return (
    <ThirdPartyTable
      tableKey={firm.uuid ?? ""}
      tableTitle={t("Accounting firm")}
      subTitle={firm.name ?? ""}
      dataCountLabel={
        firm.firmMembers.length != 1
          ? `${firm.firmMembers.length} people`
          : "1 person"
      }
      afterTable={
        <div className={stackMD}>
          <Card
            className={activeFirmCard}
            title={t("Firm permissions")}
            actions={[
              <Button
                variant="ghost"
                size="sm"
                as={Link}
                to={buildRelativePermissionsLink(firm.uuid)}
              >
                {t("Edit")}
              </Button>,
            ]}
          >
            <div className={columns}>
              {[
                ["settings", "payments"],
                ["company_hr", "benefits"],
              ].map((column) => (
                <div className={stackLG} key={String(column)}>
                  {column.map((key) => (
                    <div className={stackSM} key={key}>
                      <b>{permissionGroupDetails[key].name}</b>
                      <div>
                        {Object.values(
                          permissionGroupDetails[key].permissions
                        ).map((permission) => (
                          <div className={rowSM} key={permission.id}>
                            <SystemIcon
                              iconName={iconName(permission.id)}
                              color={iconColor(permission.id)}
                              size="small"
                            />
                            {permission.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Card>

          <Button
            size="sm"
            variant="ghost"
            leftIcon="trash"
            color="destructive"
            as={Link}
            state={{ preserveScrollPosition: true }}
            to={buildRelativeRemoveFirmLink(firm.uuid)}
          >
            {t("Remove firm")}
          </Button>
        </div>
      }
    >
      <>
        <thead>
          <tr className={tableRow}>
            <td>{t("Name")}</td>
            <td>{t("Title")}</td>
            <td>{t("Phone")}</td>
            <td>{t("Email")}</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {firm.firmMembers.map((member) => (
            <tr className={tableRow} key={member.uuid}>
              <td data-heap-redact-text={true}>{member.name}</td>
              <td>{member.title}</td>
              <td>{member.phone}</td>
              <td>{member.email}</td>
              <td>
                <SystemIcon
                  size="small"
                  iconName="lock"
                  color="inverse-subtle"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </>
    </ThirdPartyTable>
  );
};

export default ActiveFirmTable;
