import { ReactElement, useContext } from "react";
import styles from "./Confirmation.module.css";
import { Box } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import { PayNavigation } from "../PayNavigation";
import { useStepperContext } from "../../contexts/stepperFormHandler";
import {
  getEmployeeName,
  getTotalGrossAmount,
  getTotalNumberOfPayees,
  getExchangeRate,
  getTypeDisplayValue,
  getActiveCurrency,
} from "../../utils";
import { toCurrencyFormat } from "pages/employer-of-record/utils";
import { AdditionalPayContext } from "../../contexts/additionalPayContext";
import { useLocation } from "react-router-dom";
import { ADDITIONAL_PAY_FORM_ROUTES } from "../../constants";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";

export const Confirmation = (): ReactElement => {
  const { formData } = useStepperContext();
  const { supportedCountriesData } = useContext(SupportedCountriesContext);
  const additionalPayContext = useContext(AdditionalPayContext);

  const countryInfo =
    supportedCountriesData[additionalPayContext?.selectedCountry || ""];
  const currencyData = getActiveCurrency(countryInfo.currencies);

  const location = useLocation();

  const payDate =
    additionalPayContext?.disbursementDateOptions[
      formData.setup.payload.paymentSettings.payPeriodId
    ].label;

  const payType = getTypeDisplayValue(formData.setup.type);

  const totalGrossAmount = toCurrencyFormat(
    Number(getTotalGrossAmount(formData.paymentDetails)),
    currencyData?.currencyCode || "",
    true,
    1
  );

  const exchangeRate = getExchangeRate(
    currencyData?.currencyCode,
    additionalPayContext?.exchangeRates
  );

  const renderPayeeRow = () => {
    const isMassPay = location.pathname.includes(
      `${ADDITIONAL_PAY_FORM_ROUTES.EMPLOYEE_PAY}`
    );
    const isExpenseReimbursement = location.pathname.includes(
      `${ADDITIONAL_PAY_FORM_ROUTES.REIMBURSEMENT}`
    );
    if (isMassPay) {
      return (
        <div className={styles.summaryRow}>
          <div className={styles.rowLabel}>Payees</div>
          <div className={styles.rowValue}>
            {getTotalNumberOfPayees(formData.paymentDetails)}
          </div>
        </div>
      );
    } else if (isExpenseReimbursement) {
      return (
        <div className={styles.summaryRow}>
          <div className={styles.rowLabel}>Payee</div>
          <div className={styles.rowValue}>
            {getEmployeeName(additionalPayContext?.membersData[0])}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <PayNavigation />
        <Box padding="2xl" backgroundColor="neutral">
          <div className={styles.headerSection}>
            <SystemIcon
              iconName="check-circle-inverted"
              size="extra-large"
              color="inverse"
            />
            <div className={styles.scheduled}>Payment scheduled!</div>
            <div className={styles.amount}>{totalGrossAmount}</div>
          </div>
          <div className={`${styles.smallText} ${styles.cardMsg}`}>
            Allow up to 1 business day for this payment to appear in your
            estimated invoice. The actual FX rate may vary from the estimate.
            We’ll email your final invoice with an adjusted FX rate.
          </div>
          <div className={`${styles.body}`}>
            {renderPayeeRow()}
            <div className={styles.summaryRow}>
              <div className={styles.rowLabel}>Pay type</div>
              <div className={styles.rowValue}>{payType}</div>
            </div>
            {exchangeRate && (
              <div className={styles.summaryRow}>
                <div className={styles.rowLabel}>Estimated FX rate</div>
                <div className={styles.rowValue}>{exchangeRate}</div>
              </div>
            )}
            <div className={styles.summaryRow}>
              <div className={styles.rowLabel}>Pay date</div>
              <div className={styles.rowValue}>{payDate}</div>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.bold}>
              Have questions or need to edit this payment?
            </div>
            <div>
              Email{" "}
              <span className={styles.bold}>
                international-support@justworks.com
              </span>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};
