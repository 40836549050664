import { ReactElement } from "react";
import { Badge } from "@justworkshr/milo-core";
import styles from "./BadgeTitle.module.css";

type BadgeTitleProps = {
  title: string;
  badgeText: string;
};

export default function BadgeTitle({
  title,
  badgeText,
}: BadgeTitleProps): ReactElement {
  return (
    <span className={`${styles.title}`}>
      {title}
      <Badge role="info">{badgeText}</Badge>
    </span>
  );
}
