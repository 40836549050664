import { createContext, FC, ReactNode } from "react";
import type { ApolloError, ApolloQueryResult } from "@apollo/client";
import {
  GetInternationalMemberQuery,
  useGetInternationalMemberQuery,
} from "types/generated/operations";
import { useParams } from "react-router-dom";
import { InternationalMember } from "types";

interface InternationalContractorMemberContextType {
  member?: InternationalMember;
  loading: boolean;
  error?: ApolloError;
  refetch: () =>
    | Promise<ApolloQueryResult<GetInternationalMemberQuery>>
    | undefined;
}

export const InternationalContractorMemberContext =
  createContext<InternationalContractorMemberContextType>({
    loading: false,
    refetch: () => undefined,
  });

interface InternationalContractorMemberContextProviderProps {
  children: ReactNode;
}

export const InternationalContractorMemberContextProvider: FC<
  InternationalContractorMemberContextProviderProps
> = ({ children }) => {
  const { id } = useParams();
  const { data, loading, error, refetch } = useGetInternationalMemberQuery({
    variables: {
      memberUuid: id || "",
    },
    fetchPolicy: "no-cache",
  });

  return (
    <InternationalContractorMemberContext.Provider
      value={{
        member: data?.internationalMember,
        loading,
        error,
        refetch,
      }}
    >
      {children}
    </InternationalContractorMemberContext.Provider>
  );
};
