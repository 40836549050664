import * as Yup from "yup";
import {
  MemberSeparation,
  MemberSeparationAnswer,
  MemberSeparationAnswerOptionRecursive,
  MemberSeparationAnswerSubmission,
  MemberSeparationAnswerValue,
  MemberSeparationQuestion,
  MemberSeparationQuestionRecursive,
  MemberSeparationValidations,
} from "pages/team-management/types";
import {
  QuestionnaireFormSchemaAccumulator,
  QuestionnaireFormSchemaShape,
} from "./QuestionnaireForm.types";
import { COMPONENT_INPUT_MAP } from "../../constants/componentInputMap";
import {
  SaveMemberSeparationAnswersMutation,
  SaveMemberSeparationAnswersMutationFn,
  SeparationForm,
} from "types/generated/operations";
import { FetchResult } from "@apollo/client";
import { isEmptyValue } from "pages/team-management/utils/utils";
import { Dispatch, SetStateAction } from "react";

const INVALID_DATE_ERROR_MSG = "Invalid Date";
const INVALID_TIME_ERROR_MSG = "Invalid time";

export const getValidatorByInputType = (
  inputType: string,
  validations: MemberSeparationValidations
) => {
  const { required, minLength } = validations;

  switch (inputType) {
    case "time": {
      return Yup.string()
        .matches(
          /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM|am|pm)$/,
          INVALID_TIME_ERROR_MSG
        )
        .test("required", "Missing field", (value) =>
          required ? !!value : true
        );
    }
    case "date": {
      return Yup.string().test("required", "Missing valid date", (value) => {
        const invalidDate =
          !value ||
          !value.trim().length ||
          value.toLowerCase() === INVALID_DATE_ERROR_MSG.toLowerCase();
        if (invalidDate) {
          return !required;
        }

        return true;
      });
    }
    case "boolean": {
      return Yup.boolean().test("required", "Missing field", (value) =>
        required ? value !== undefined : true
      );
    }
    case "document": {
      return Yup.array()
        .of(Yup.string())
        .test("required", "Missing document", (value) =>
          required ? value && value?.length > 0 : true
        );
    }
    case "multiSelect": {
      return Yup.array()
        .of(Yup.string())
        .test("required", "Missing at least one selection", (value) =>
          required ? !!value && value.length > 0 : true
        );
    }
    case "textarea":
      if (minLength) {
        return Yup.string().test(
          "required",
          `Please give a response of at least ${minLength} characters`,
          (value) => (required ? !!value && value.length >= minLength! : true)
        );
      } else {
        return Yup.string().test("required", "Missing field", (value) =>
          required ? !!value : true
        );
      }
    default: {
      return Yup.string().test("required", "Missing field", (value) =>
        required ? !!value : true
      );
    }
  }
};

export const getAllVisibleQuestions = (
  questions: (MemberSeparationQuestionRecursive | null)[] | undefined,
  values: QuestionnaireFormSchemaShape | undefined = {},
  requiredOnly: boolean
): MemberSeparationQuestionRecursive[] | [] => {
  if (!questions) {
    return [];
  }
  const visibleQuestions: MemberSeparationQuestionRecursive[] = [];
  const getShallowQuestion: (
    question: MemberSeparationQuestionRecursive
  ) => MemberSeparationQuestionRecursive = (question) => {
    const { questionId, questionText, inputType, validations } = question;
    return { questionId, questionText, inputType, validations };
  };
  questions.forEach((questionDefinition) => {
    if (!questionDefinition) {
      return;
    }
    visibleQuestions.push(getShallowQuestion(questionDefinition));
    if (questionDefinition.answers?.length) {
      const existingAnswers = questionDefinition.answers.filter(({ value }) => {
        const questionValue = values[questionDefinition.questionId];
        if (questionValue && Array.isArray(questionValue)) {
          return questionValue.includes(String(value));
        }

        return values[questionDefinition.questionId]?.toString() === value;
      });
      if (existingAnswers?.length) {
        const subQuestions: (MemberSeparationQuestionRecursive | null)[] =
          existingAnswers.reduce(
            (
              acc: (MemberSeparationQuestionRecursive | null)[],
              answer: MemberSeparationAnswerOptionRecursive
            ) => {
              const subQuestions = answer.subQuestions?.filter(
                (question) =>
                  !requiredOnly || question?.validations.required !== false
              );
              if (subQuestions?.length) {
                acc.push(...subQuestions);
              }
              return acc;
            },
            []
          );
        visibleQuestions.push(
          ...getAllVisibleQuestions(subQuestions, values, requiredOnly)
        );
      }
    }
  });

  return visibleQuestions;
};

export const getQuestionnaireFormSchema = (
  fullQuestionTree: MemberSeparationQuestionRecursive[] | undefined,
  values: QuestionnaireFormSchemaShape | undefined = {}
) => {
  const allQuestions: MemberSeparationQuestionRecursive[] =
    getAllVisibleQuestions(fullQuestionTree, values, false);

  if (!fullQuestionTree?.length) {
    return;
  }

  const schemaShape = allQuestions.reduce(
    (
      acc: QuestionnaireFormSchemaAccumulator,
      questionDefinition: MemberSeparationQuestionRecursive
    ) => {
      acc[questionDefinition.questionId] = getValidatorByInputType(
        questionDefinition.inputType,
        questionDefinition.validations
      );
      return acc;
    },
    {}
  );

  return Yup.object().shape(schemaShape);
};

export const getDefaultAnswer: (
  questionData: MemberSeparationQuestion
) => string | number | (string | number)[] | null | undefined = ({
  inputType,
  answers,
}) => {
  // only single select inputs have a default value, which is the first answer value
  if (inputType !== "singleSelect" || !answers?.length) {
    return;
  }

  return answers[0].value;
};

export const INPUT_TYPE_VALUE_MAP: {
  [key: keyof typeof COMPONENT_INPUT_MAP]: keyof MemberSeparationAnswerValue;
} = {
  boolean: "valueBoolean",
  date: "valueDate",
  datetime: "valueDate",
  document: "valueDocumentId",
  singleSelect: "valueTextSmall",
  multiSelect: "valueTextSmall",
  radioButtonGroup: "valueTextSmall", //Currently not needed
  textarea: "valueText",
  textinput: "valueTextSmall",
  time: "valueTextSmall",
};

export const getInitialValues: (
  fullQuestionList: MemberSeparationQuestion[] | null | undefined,
  memberSeparationAnswers: MemberSeparationAnswer[] | undefined
) => QuestionnaireFormSchemaShape = (
  fullQuestionList,
  memberSeparationAnswers
) => {
  if (!fullQuestionList) {
    return {};
  }

  return fullQuestionList.reduce(
    (
      acc: QuestionnaireFormSchemaShape,
      questionData: MemberSeparationQuestion
    ) => {
      const existingAnswer = memberSeparationAnswers?.find(
        (answer) => answer.fieldId == questionData.questionId
      );
      const defaultAnswer = getDefaultAnswer(questionData);
      acc[questionData.questionId] = (() => {
        let value: string | number | (string | number)[] = "";
        if (existingAnswer?.values) {
          const valueType = INPUT_TYPE_VALUE_MAP[questionData.inputType];
          const values = existingAnswer.values.map(
            (valueEntry) => valueEntry[valueType] || ""
          );
          value = values?.length > 1 ? values : values[0];
        } else if (defaultAnswer) {
          value = defaultAnswer;
        }
        return value;
      })();
      return acc;
    },
    {}
  );
};

export const getFormattedAnswers: (
  fullQuestionList: MemberSeparationQuestion[] | null | undefined,
  questionId: string,
  values: string | number | (string | number)[] | undefined
) => MemberSeparationAnswerSubmission[] | undefined = (
  fullQuestionList,
  questionId,
  values
) => {
  const isInvalidDate = (
    value: string | number | undefined,
    valueType: string
  ) =>
    valueType === "valueDate" &&
    String(value)?.toLowerCase() === INVALID_DATE_ERROR_MSG.toLowerCase();
  const questionDefinition = fullQuestionList?.find(
    (question) => question.questionId === questionId
  );
  const inputType = questionDefinition?.inputType;
  if (!inputType) {
    return;
  }
  const valueType = INPUT_TYPE_VALUE_MAP[inputType];
  const mapOneValue = (value: string | number | undefined) => ({
    fieldId: questionId,
    [valueType]: isInvalidDate(value, valueType) ? null : value,
  });
  if (Array.isArray(values)) {
    return values.map((value) => mapOneValue(value));
  } else {
    return [mapOneValue(values)];
  }
};

export const buildSeparationAnswers = (
  values: QuestionnaireFormSchemaShape,
  requiredQuestions: MemberSeparationQuestionRecursive[],
  skipEmptyValues: boolean = false
) => {
  const separationAnswers: MemberSeparationAnswerSubmission[] = [];
  Object.entries(values).forEach(([questionId, value]) => {
    if (skipEmptyValues && isEmptyValue(value)) {
      return;
    }
    const formattedAnswers = getFormattedAnswers(
      requiredQuestions,
      questionId,
      value
    );
    if (formattedAnswers) {
      separationAnswers.push(...formattedAnswers);
    }
  });

  return separationAnswers;
};

export const saveAnswers = async (
  saveMemberSeparationAnswersMutation: SaveMemberSeparationAnswersMutationFn,
  memberSeparation: MemberSeparation,
  fullQuestionTree: (MemberSeparationQuestionRecursive | null)[] | undefined,
  values: QuestionnaireFormSchemaShape
) => {
  const requiredQuestions = getAllVisibleQuestions(
    fullQuestionTree,
    values,
    false
  );
  const separationAnswers = buildSeparationAnswers(values, requiredQuestions);

  return await saveMemberSeparationAnswersMutation({
    variables: {
      memberSeparationId: memberSeparation.memberSeparationId,
      separationFormType: SeparationForm.Questionnaire,
      separationAnswers,
    },
  });
};

export const handleSaveAnswers = async (
  setLoading: (loading: boolean) => void,
  saveMemberSeparationAnswersMutation: SaveMemberSeparationAnswersMutationFn,
  memberSeparation: MemberSeparation,
  fullQuestionTree: (MemberSeparationQuestionRecursive | null)[] | undefined,
  values: QuestionnaireFormSchemaShape,
  handleNext: () => void,
  setShowGlobalGenericError: ((value: boolean) => void) | undefined
) => {
  setLoading(true);

  try {
    const responseFull: FetchResult<SaveMemberSeparationAnswersMutation> =
      await saveAnswers(
        saveMemberSeparationAnswersMutation,
        memberSeparation,
        fullQuestionTree,
        values
      );

    const response = responseFull.data?.saveMemberSeparationAnswers;
    if (response?.success) {
      handleNext();
    } else {
      setShowGlobalGenericError?.(true);
    }
  } catch {
    setShowGlobalGenericError?.(true);
  }

  setLoading(false);
};

export const autoSaveAnswers = async (
  saveMemberSeparationAnswersMutation: SaveMemberSeparationAnswersMutationFn,
  memberSeparation: MemberSeparation,
  fullQuestionTree: (MemberSeparationQuestionRecursive | null)[] | undefined,
  values: QuestionnaireFormSchemaShape
) => {
  const visibleQuestions = getAllVisibleQuestions(
    fullQuestionTree,
    values,
    false
  );
  const separationAnswers = buildSeparationAnswers(
    values,
    visibleQuestions,
    true
  );

  return await saveMemberSeparationAnswersMutation({
    variables: {
      memberSeparationId: memberSeparation.memberSeparationId,
      separationFormType: SeparationForm.QuestionnairePartial,
      separationAnswers,
    },
  });
};

export const handleAutoSaveQuestionnaire = async (
  saveMemberSeparationAnswersMutation: SaveMemberSeparationAnswersMutationFn,
  memberSeparation: MemberSeparation | undefined,
  fullQuestionTree: (MemberSeparationQuestionRecursive | null)[] | undefined,
  values: QuestionnaireFormSchemaShape,
  initialValues: QuestionnaireFormSchemaShape,
  lastAutoSavedValues: QuestionnaireFormSchemaShape | undefined,
  setLastAutoSavedValues: Dispatch<
    SetStateAction<QuestionnaireFormSchemaShape | undefined>
  >
) => {
  if (!memberSeparation) {
    return;
  }

  const initialValuesEqual = areQuestionnaireValuesEqual(values, initialValues);
  const lastAutoSavedValuesEqual = areQuestionnaireValuesEqual(
    values,
    lastAutoSavedValues
  );
  if (initialValuesEqual || lastAutoSavedValuesEqual) {
    return;
  }

  await autoSaveAnswers(
    saveMemberSeparationAnswersMutation,
    memberSeparation,
    fullQuestionTree,
    values
  );

  setLastAutoSavedValues(values);
};

export const areQuestionnaireValuesEqual: (
  values1: QuestionnaireFormSchemaShape | undefined,
  values2: QuestionnaireFormSchemaShape | undefined
) => boolean = (values1, values2) => {
  if (!values1 && !values2) {
    return true;
  }
  if (!values1 || !values2) {
    return false;
  }
  const keys = Object.keys(values1) as Array<keyof typeof values1>;
  return keys.every((k) => values1[k] === values2[k]);
};
