import { IntegrationsConfig } from "types/IntegrationConfig";
import { HR_PERMISSIONS } from "pages/pmt/integrations/integration-marketplace/constants";
import leverHeroImage from "../../images/hero-images/lever-hero.svg";
import leverLogo from "pages/pmt/integrations/images/integration-partner-logos/lever-logo.svg";
import leverHorizontalLogo from "../../images/horizontal-logos/lever-horizontal.svg";
import {
  DataAndPermissionsTab,
  OverviewTab,
  PostSetupTab,
} from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/TabsContentTemplate";
import {
  inlineLinkWithIconFormatter,
  justworksPermissionsCopy,
  partnerPermissionsCopy,
} from "pages/pmt/integrations/partner-description-page/components/TabsContentTemplate/constants";
import image1 from "../../images/info-tabs-images/lever/image1.png";
import image2 from "../../images/info-tabs-images/lever/image2.png";
import LeverSetupStepOne from "./setup-steps/LeverSetupStepOne";
import LeverSetupStepTwo from "./setup-steps/LeverSetupStepTwo";
import LeverSetupStepThree from "./setup-steps/LeverSetupStepThree";
import LeverSetupStepFour from "./setup-steps/LeverSetupStepFour";
import { getStepForLever } from "./setup-steps/utils";
import SetupButton from "pages/pmt/integrations/partner-description-page/components/InstanceDetailsPanel/SetupButton/SetupButton";
import React from "react";
import styles from "pages/pmt/integrations/partner-description-page/components/InstanceDetailsPanel/InstanceDetailsPanel.module.css";
import { DisconnectedPanelLegalInfoReceiving } from "../../partner-description-page/components/InstanceDetailsPanel/DisconnectedPanelContentLegalInfo";

const { integrationAlert, alertText } = styles;
export const leverConfig: IntegrationsConfig = {
  numAllowedInstances: 1,
  copy: {
    marketplaceCard: {
      logoUrl: leverLogo,
      integrationName: "Lever",
      integrationDescription:
        "Automatically import new hires from Lever into Justworks.",
      integrationCategory: "Hiring",
      requiredPermissions: HR_PERMISSIONS,
      slug: "lever",
      pdpUrl: "/cf/integrations/lever",
    },

    pdp: {
      headerDescription:
        "Lever is a powerful applicant tracking system that simplifies and streamlines the hiring process, helping companies source, track, and evaluate candidates.",
      heroImageUrl: leverHeroImage,
      horizontalLogoUrl: leverHorizontalLogo,
      LegalInfo: DisconnectedPanelLegalInfoReceiving,
      resourceCard: {
        links: [
          {
            title: "Lever in the Help Center",
            href: "https://help.justworks.com/hc/en-us/articles/20750805296283-Lever-Integration",
            systemIconName: "external-link",
            external: true,
          },
          {
            title: "Learn more about Lever",
            href: "https://help.lever.co/hc/en-us",
            systemIconName: "external-link",
            external: true,
          },
        ],
      },
      infoTabsConfig: {
        0: {
          title: "Overview",
          Component: OverviewTab,
        },
        1: {
          title: "Data & permissions",
          Component: DataAndPermissionsTab,
        },
        2: {
          title: "Using the integration",
          Component: PostSetupTab,
        },
      },
      infoTabsContent: {
        overviewTab: {
          header: "Lever + Justworks",
          subheader:
            "With the Lever and Justworks integration, you can import new hire information and add them directly to Justworks as employees or contractors.",
          list: [
            {
              summary: "Save time adding new hires into Justworks",
              detail:
                "New hires are great–until you remember all the things you need to take care of. Connecting to Lever means one less task to manage.",
            },
            {
              summary: "Reduce errors from manually inputting data",
              detail:
                "The new hire's info is already in Lever. No need to retype it and risk potential mistakes.",
            },
            {
              summary: "One-time setup",
              detail:
                "You only need to set up the connection once to work with every candidate marked as hired in Lever.",
            },
          ],
        },
        dataAndPermissionsTab: {
          dataSharedSection: {
            header: "What data will Justworks receive from Lever?",
            sharedDataGroups: [
              {
                header:
                  "Whenever a candidate is marked as hired in Lever, we’ll automatically pull in the following data:",
                sharedData: [
                  "Member type",
                  "Compensation amount (salary or wage)",
                  "Type of earnings (salary or wage)",
                  "Exemption status",
                  "First name",
                  "Last name",
                  "Title",
                  "Manager *",
                  "Department",
                  "Office",
                  "Start date *",
                  "Home email",
                ],
              },
            ],
            footnote: inlineLinkWithIconFormatter(
              "* Justworks will only pull in these fields if you’ve gone through",
              "https://help.lever.co/hc/en-us/articles/206229896-Building-offer-approval-workflows",
              "Lever's offer workflow"
            ),
          },
          permissionsSection: {
            header: "What permissions will you need for this integration?",
            list: [
              {
                summary: "In Justworks",
                detail: (
                  <div>
                    Admins need{" "}
                    <strong>{justworksPermissionsCopy(HR_PERMISSIONS)}</strong>{" "}
                    permissions.
                  </div>
                ),
              },
              {
                summary: "In Lever",
                detail: partnerPermissionsCopy("Lever", [
                  "Manage API credentials and manage integrations",
                ]),
              },
            ],
          },
        },
        postSetupTab: {
          header: "How do I use this integration?",
          subheader: inlineLinkWithIconFormatter(
            "To make sure you’re importing the most complete data, use the",
            "https://help.lever.co/hc/en-us/articles/206229896-Building-offer-approval-workflows",
            " Lever offer workflow",
            "and mark your candidate as hired after the offer is signed."
          ),
          list: [
            {
              caption: (
                <p>
                  When a candidate is marked as hired in Lever, we’ll send an
                  email to all admins with{" "}
                  <strong>{justworksPermissionsCopy(HR_PERMISSIONS)}</strong>{" "}
                  permissions, and you’ll see an alert on your Justworks
                  dashboard.
                </p>
              ),
              image: image1,
            },
            {
              caption: (
                <p>
                  After selecting <strong>Review</strong>, you’ll see all
                  pending hires who haven’t yet been invited to Justworks.
                </p>
              ),
              image: image2,
            },
            {
              caption: (
                <p>
                  Selecting <strong>Review & Invite</strong> will prompt you to
                  create a new member, which will be pre-populated with the data
                  fields from Lever. You can still edit this information prior
                  to inviting the new person.
                </p>
              ),
            },
          ],
        },
      },
      disconnectModalContent: {
        title: "Disconnect from Lever",
        body: (
          <span>
            Once the integration is disconnected, we won’t automatically import
            pending hires from Lever anymore.
          </span>
        ),
        disconnectButtonText: "Disconnect",
      },
      allowedPdpActions: [],
      expiringOrDisabledAlertData: {
        integrationExpiring: {
          message1: "Your integration will expire in ",
          message2:
            " days, disconnect it and set up a new one to prevent disruption. Your pending hires won’t be affected.",
          buttonType: "disconnect",
          color: "new" as const,
          visible: true,
          dismissible: false,
        },
        integrationDisabled: {
          message: (
            <div className={integrationAlert}>
              <span className={alertText}>
                Your integration expired. Please set up a new integration.
              </span>
              <span>
                <SetupButton
                  solutionSlug={"lever"}
                  hasPermission={true}
                  inAlert={true}
                />
              </span>
            </div>
          ),
          color: "new" as const,
          visible: true,
          dismissible: false,
        },
      },
    },
    setup: {
      estimatedSetupTime: "5",
      helpCenterLink:
        "https://help.justworks.com/hc/en-us/articles/20750805296283-Lever-Integration",
      stepperConfig: {
        setup: {
          name: "Setup Started",
          doneName: "Setup started",
        },
        connect: {
          name: "Connect Your Lever Account",
          doneName: "Lever account connected",
        },
        create: {
          name: "Create Webhook",
          doneName: "Webhook created",
        },
        enable: {
          name: "Enable Webhook",
        },
      },
      steps: [
        LeverSetupStepOne,
        LeverSetupStepTwo,
        LeverSetupStepThree,
        LeverSetupStepFour,
      ],
      instanceKeys: [
        "warn_unused",
        "disable_unused",
        "refresh_token",
        "get_token",
        "setup",
        "candidate_hired",
      ],
      getCurrentStepFunction: getStepForLever,
    },
  },
};
