export const SEPARATION_FLOW_COPY = {
  previous: "Previous",
  next: "Next",
};

export const enum SEPARATION_FLOW_STATUS {
  COMPLETE = "complete",
  INCOMPLETE = "incomplete",
  INTAKE_COMPLETE = "intake_complete",
  INTAKE_PARTIALLY_COMPLETE = "intake_partially_complete",
  QUESTIONNAIRE_PARTIALLY_COMPLETE = "questionnaire_partially_complete",
  QUESTIONNAIRE_COMPLETE = "questionnaire_complete",
  QUESTIONNAIRE_SKIPPED = "questionnaire_skipped",
}
