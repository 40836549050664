import { FC } from "react";
import Tooltip from "@justworkshr/milo-tooltip";
import { useGetVisierPtoMetricsQuery } from "types/generated/operations";
import { FULL_PATHS } from "../../constants";
import { usePeopleAnalyticsCapabilities } from "../../hooks";
import { formatDayCount, formatMonth } from "../../utils";
import { buildWebPath } from "lib/resource-finder";
import CompanyOverviewCard from "../company-overview-card";
import CompanyOverviewMetric from "../company-overview-metric";
import MetricsProhibitedContent from "../metrics-prohibited-content";
import styles from "../company-overview-card/company-overview-card.module.css";

const PtoMetricsCard: FC = () => {
  const { memberCompanyHasPlusAccess, memberCompanyHasPtoPolicies } =
    usePeopleAnalyticsCapabilities();

  const { data } = useGetVisierPtoMetricsQuery({
    skip: !memberCompanyHasPtoPolicies,
  });

  const { averagePtoDaysLastMonth, highestPtoDepartmentLastMonth } =
    data?.visierPtoMetrics || {
      averagePtoDaysLastMonth: undefined,
      highestPtoDepartmentLastMonth: undefined,
    };

  const metrics = (
    <div className={styles.companyOverviewMetrics}>
      <h3 className={styles.companyOverviewMetricsHeader}>
        Average days requested
      </h3>

      <div className={styles.metricContainer}>
        {averagePtoDaysLastMonth && (
          <CompanyOverviewMetric
            description="Across company"
            metric={
              <>
                {formatDayCount(averagePtoDaysLastMonth)}
                <span className={styles.metricSubheading}> / employee</span>
              </>
            }
            subtext={formatMonth(new Date(), -1)}
          />
        )}

        {highestPtoDepartmentLastMonth?.departmentName &&
          highestPtoDepartmentLastMonth?.dayCount && (
            <CompanyOverviewMetric
              description={
                <Tooltip
                  title={highestPtoDepartmentLastMonth.departmentName}
                  position="top"
                >
                  Highest department
                </Tooltip>
              }
              metric={
                <>
                  {formatDayCount(highestPtoDepartmentLastMonth.dayCount)}
                  <span className={styles.metricSubheading}> / employee</span>
                </>
              }
              subtext={formatMonth(new Date(), -1)}
            />
          )}
      </div>
    </div>
  );

  return (
    <CompanyOverviewCard
      title="Time off"
      insightsPath={
        memberCompanyHasPlusAccess && memberCompanyHasPtoPolicies
          ? FULL_PATHS.PTO_DASHBOARD
          : undefined
      }
    >
      {(() => {
        if (!memberCompanyHasPtoPolicies)
          return (
            <MetricsProhibitedContent
              header="Your company doesn’t have a PTO policy yet"
              description="Set up a PTO policy to start tracking time off with Justworks."
            />
          );

        if (
          memberCompanyHasPtoPolicies &&
          averagePtoDaysLastMonth == "0" &&
          highestPtoDepartmentLastMonth?.dayCount == "0"
        )
          return (
            <MetricsProhibitedContent
              header="Your company is not tracking Time Off through Justworks"
              description={
                <>
                  Check your time off settings{" "}
                  <a href={buildWebPath("/pto/requests")}>here</a>.
                </>
              }
            />
          );

        if (!data) return;

        return metrics;
      })()}
    </CompanyOverviewCard>
  );
};

export default PtoMetricsCard;
