import Drawer from "app-frame/header/common/drawer";
import NestedDrawerHeader from "../../common/nested-drawer-header";
import { SystemIcon } from "@justworkshr/milo-icons";
import HelpContent from "app-frame/header/help/help-content";
import { usePanelsControlContext } from "app-frame/header/context/panels-control-context-provider";
import { useGetHelpMenuQuery } from "types/generated/operations";
import { PanelsControlActionType } from "app-frame/header/context/panels-control-context-provider/types";

function HelpDrawer() {
  const { state, dispatch } = usePanelsControlContext();
  const { advancedHelpDrawerShown } = state;

  const { data, loading } = useGetHelpMenuQuery();
  if (loading || !data) {
    return <></>;
  }
  const headerText = "Help";
  const accountManager = data.authenticatedMember.company.accountManager;

  const drawerHeader = (
    <NestedDrawerHeader
      icon={<SystemIcon iconName="help" size="large" color="brand-subtle" />}
      onClick={() =>
        dispatch({
          type: PanelsControlActionType.CLOSE_ADVANCED_HELP_DRAWER,
        })
      }
    >
      {headerText}
    </NestedDrawerHeader>
  );

  const drawerContent = <HelpContent accountManager={accountManager} />;

  return (
    <Drawer
      name="advanced-help-drawer"
      header={drawerHeader}
      onClose={() =>
        dispatch({ type: PanelsControlActionType.CLOSE_ADVANCED_HELP_DRAWER })
      }
      open={advancedHelpDrawerShown}
      showOverlay={false}
      zIndex={10000000}
    >
      {drawerContent}
    </Drawer>
  );
}

export default HelpDrawer;
