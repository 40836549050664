import { SystemIcon, SystemIconName } from "@justworkshr/milo-icons";
import styles from "./CardAction.module.css";

type Props = {
  name: string;
  icon: SystemIconName;
  onClick?: () => void;
};

export default function CardAction({ name, icon, onClick }: Props) {
  return (
    <div id="requestChange" className={styles.cardAction} onClick={onClick}>
      <SystemIcon iconName={icon} color="brand" size="medium" />
      <span className={styles.action}>{name}</span>
    </div>
  );
}
