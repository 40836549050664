import styles from "./ExpandableText.module.css";
import React, { useState } from "react";
import { mergeClassNames } from "pages/expenses/utils";
import { IconButton } from "@justworkshr/milo-core";

const {
  isTopOpenDirection,
  ExpandableTextLabel,
  ExpandableTextButton,
  ExpandableTextWrapper,
  isBottomOpenDirection,
  ExpandableTextPanelOpen,
  ExpandableTextPanelClosed,
  ExpandableTextButtonLabelWrapper,
} = styles;

interface ExpandableTextProps {
  open?: boolean;
  className?: string;
  children: React.ReactNode;
  openDirection?: "top" | "bottom";
  buttonLabel?: string | React.ReactNode;
  buttonLabelAsRenderer?: ({
    isOpen,
    iconButton,
    handleClick,
  }: {
    isOpen: boolean;
    iconButton: React.ReactElement;
    handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  }) => React.ReactNode;
}

export const ExpandableText: React.FC<ExpandableTextProps> = ({
  children,
  className,
  buttonLabel,
  open = false,
  openDirection = "top",
  buttonLabelAsRenderer,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const openDirectionClass =
    openDirection === "bottom" ? isBottomOpenDirection : isTopOpenDirection;

  const sectionClassName = mergeClassNames([
    ExpandableTextWrapper,
    className,
    openDirectionClass,
  ]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setIsOpen((o) => !o);
  };

  const iconButton = (
    <IconButton
      size="sm"
      type="button"
      variant="ghost"
      onClick={handleClick}
      className={ExpandableTextButton}
      title={isOpen ? "Expand" : "Collapse"}
      iconName={isOpen ? "chevron-up" : "chevron-down"}
      data-testid={isOpen ? "collapse-button" : "expand-button"}
    />
  );

  const renderButtonLabel = () => {
    if (buttonLabelAsRenderer) {
      return buttonLabelAsRenderer({ isOpen, iconButton, handleClick });
    }

    if (buttonLabel) {
      return (
        <div className={ExpandableTextButtonLabelWrapper}>
          {buttonLabel}
          {iconButton}
        </div>
      );
    }
  };

  return (
    <section className={sectionClassName}>
      <label className={ExpandableTextLabel}>{renderButtonLabel()}</label>
      <div
        aria-hidden={isOpen}
        className={isOpen ? ExpandableTextPanelOpen : ExpandableTextPanelClosed}
      >
        {children}
      </div>
    </section>
  );
};

export default ExpandableText;
