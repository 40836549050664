import React from "react";
import { ReviewerTabListTypes } from "./ReviewerHeader.constants";
import { getReviewerTabList } from "./ReviewerHeader.utils";
import styles from "./ReviewerHeader.module.css";
const {
  ReviewerHeaderWrapper,
  ReviewerHeaderTitle,
  ReviewerHeaderTabListWrapper,
} = styles;
interface ReviewerHeaderProps {
  tabListType: ReviewerTabListTypes | undefined;
  title: JSX.Element | null;
  children: JSX.Element | null;
}

export const ReviewerHeader: React.FC<ReviewerHeaderProps> = ({
  title,
  children,
  tabListType,
}) => {
  return (
    <div className={ReviewerHeaderWrapper}>
      <header>
        {title && <h1 className={ReviewerHeaderTitle}>{title}</h1>}
        {children}
      </header>
      <div className={ReviewerHeaderTabListWrapper}>
        {getReviewerTabList(tabListType)}
      </div>
    </div>
  );
};
