import * as Yup from "yup";

const zipRegex = /^\d{5}(?:\d{4})?$/;

export const validationFormSchema = Yup.object().shape({
  fatality: Yup.boolean(),
  oshaNumber: Yup.string().when("fatality", {
    is: true,
    then: (schema) =>
      schema
        .required("OSHA number is required")
        .test("is-number", "Must be a number", (value) => {
          return !isNaN(Number(value));
        }),
  }),
  incidentDescription: Yup.string().required(
    "Incident description is required"
  ),
  address: Yup.object().shape({
    street1: Yup.string().required("Street 1 is required"),
    street2: Yup.string(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    postalCode: Yup.string()
      .required("ZIP is required")
      .matches(zipRegex, "Invalid ZIP code"),
  }),
  injuryBodyPart: Yup.string().required("Injured body part is required"),
  bodyPartDescription: Yup.string().required(
    "Body part description is required"
  ),
  safeGuard: Yup.string().required("Select at least one safeguard"),
  safeGuardList: Yup.string(),
});
