import classes from "./TransitionPage.module.css";
import benefitsClasses from "pages/benefits/benefits.module.css";
import { UnorderedList, UnorderedListItem } from "@justworkshr/milo-core";
import { Divider, Step, Stepper } from "@justworkshr/milo-core";
import clsx from "lib/styling-utils/clsx";

const EmpowerTab = () => {
  return (
    <div>
      <h2 className={benefitsClasses.benefitsHeader}>
        Your 401(k) is moving over to Empower
      </h2>
      <p className={benefitsClasses.benefitsParagraph}>
        Starting June 28, we'll initiate the transfer of the Justworks
        Retirement Savings Plan from Slavic to Empower. Your account balance,
        contribution elections, beneficiary elections, loans, and distribution
        arrangements will begin to transfer automatically.
      </p>
      <p className={benefitsClasses.benefitsParagraph}>
        The transition is expected to be completed around the mid to end of
        July. Beginning June 3, 2024, certain transactions will be unavailable
        as Slavic prepares for this transition. Keep reading for more details.
      </p>
      <h2 className={benefitsClasses.benefitsHeader}>Who is Empower?</h2>
      <p className={benefitsClasses.benefitsParagraph}>
        Empower is an award-winning provider with extensive experience with PEOs
        like Justworks. They're known for their advanced technology, efficient
        tools, and customer service.
      </p>
      <h2 className={benefitsClasses.benefitsHeader}>
        Why did you choose Empower?
      </h2>
      <p className={benefitsClasses.benefitsParagraph}>
        By switching to Empower, we'll be able to offer a more user-friendly and
        comprehensive 401(k) experience with a robust mobile app that makes it
        easy to stay on top of your financial planning.
      </p>
      <h2 className={benefitsClasses.benefitsHeader}>
        Will there be any changes to my current account features?
      </h2>
      <UnorderedList>
        <UnorderedListItem>
          Once the plan is live, all enrollment and deferral elections,
          including for regular wage payments, bonus and commissions, will be
          made at Empower. Previously, some of these were done at Slavic and
          others at Justworks.
        </UnorderedListItem>
        <UnorderedListItem>
          If you have an automatic deferral increase on file at Slavic, this
          election will transfer to Empower.
        </UnorderedListItem>
        <UnorderedListItem>
          {
            (
              <>
                If you have a Power of Attorney (POA), Conservatorship, or
                Guardianship assigned to your account at Slavic, this
                information will not transfer to Empower. Reach out to Empower
                at <a href="tel:8555952878">(855) 595-2878</a> or{" "}
                <a href="mailto:justworks401k@empower.com">
                  justworks401k@empower.com
                </a>{" "}
                to complete the necessary paperwork to reestablish this
                arrangement following the transition.
              </>
            ) as unknown as string
          }
        </UnorderedListItem>
      </UnorderedList>
      <Divider />
      <h2 className={benefitsClasses.benefitsHeader}>What happens next?</h2>
      <p className={benefitsClasses.benefitsParagraph}>
        Starting in May, we'll send out more information to help you better
        understand what the transition will look like.
      </p>
      <h2 className={benefitsClasses.benefitsHeader}>Timeline of events</h2>
      <Stepper orientation="vertical">
        <Step
          stepNumber={1}
          status="pending"
          title="June 3: Final day in Slavic"
          subtitle={
            (
              <p
                className={clsx(
                  classes.stepSubtitle,
                  benefitsClasses.benefitsParagraph
                )}
              >
                This is the last day to make any changes to your deferral
                elections, request a new loan, repayment of initial new loans,
                and distribution requests with Slavic401k.
              </p>
            ) as unknown as string
          }
        />
        <Step
          stepNumber={2}
          status="pending"
          title="June 18: Blackout period starts"
          subtitle={
            (
              <p
                className={clsx(
                  classes.stepSubtitle,
                  benefitsClasses.benefitsParagraph
                )}
              >
                Starting June 18th, there will be a blackout period where
                employees won't be able to access their account. This is a
                standard part of the process when switching providers. 401k
                contributions will still be made so their retirement plan can
                stay on track.
              </p>
            ) as unknown as string
          }
        />
        <Step
          stepNumber={3}
          status="pending"
          title="Mid to end July: New plans go live"
          subtitle={
            (
              <p
                className={clsx(
                  classes.stepSubtitle,
                  benefitsClasses.benefitsParagraph
                )}
              >
                The new plans will go live in Empower around the middle to the
                end of July. Employees will be able to set up their new
                accounts, and resume all plan activity that was unavailable
                during the blackout period.
              </p>
            ) as unknown as string
          }
        />
      </Stepper>
    </div>
  );
};

export default EmpowerTab;
