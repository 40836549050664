import { FieldConfig } from "../../../../SetupFormGenerator";
import { COMPONENTLIST } from "../../../../SetupFormGenerator/constants";
import { ComissionFrequency } from "../../constants";

enum Links {
  LEARNTAXES = "https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/payroll/payroll-deductions-contributions/income-tax/employees-paid-commission.html",
}

export const PAYMENT_SETTINGS_FORM_FIELDS: FieldConfig[] = [
  {
    label: "Pay date",
    name: "payPeriodId",
    type: COMPONENTLIST.SELECT,
    options: [{ value: "", label: "" }],
    required: true,
  },
  {
    label: "Internal Notes",
    name: "notes",
    type: COMPONENTLIST.TEXTAREA,
    required: false,
    message: { text: "Only visible to admins" },
  },
];

export const TAX_FORM_FIELDS: FieldConfig[] = [
  {
    label: "Cadence",
    name: "cadence",
    type: COMPONENTLIST.RADIO,
    options: [
      {
        value: ComissionFrequency.REGULAR,
        label: "Regular intervals",
        description:
          "Comission is paid on a recurring schedule such as monthly, quarterly etc.",
      },
      {
        value: ComissionFrequency.IRREGULAR,
        label: "Irregular intervals",
        description:
          "Commission is paid at different times throughout the year.",
      },
    ],
    required: true,
    message: {
      text: "The cadence for commission impacts the tax rules related to this payment. Learn more about intervals to choose the right one.",
      linkText: "Learn more about intervals",
      link: Links.LEARNTAXES,
    },
  },
];
