import React from "react";
import {
  RequestDetailExpense,
  ExpenseLibraryExpense,
  ExpenseLibraryExpenseReceipt,
} from "types/Expenses";
import { Badge, Button } from "@justworkshr/milo-core";
import styles from "./ExpenseDetailsCard.module.css";
import {
  BILLABLE_VALUE,
  CATEGORY_STATUSES,
  NON_BILLABLE_VALUE,
  EXPENSE_REIMBURSABLE_LABEL,
  EXPENSE_NON_REIMBURSABLE_TYPE,
  EXPENSE_NON_REIMBURSABLE_LABEL,
} from "pages/expenses/constants";
import { handleDate, getDollarsFromCents } from "pages/expenses/utils";

const {
  ExpenseDetailsCardItem,
  ExpenseDetailsCardWrapper,
  ExpenseDetailsCardReceiptName,
  ExpenseDetailsCardReceiptsWrapper,
  ExpenseDetailsCardReceiptContainer,
} = styles;

interface ExpenseDetailsCardProps {
  showViewReceipt?: boolean;
  expense: RequestDetailExpense | ExpenseLibraryExpense;
  onReceiptChange?: (receipt: ExpenseLibraryExpenseReceipt) => void;
}

export const ExpenseDetailsCard: React.FC<ExpenseDetailsCardProps> = React.memo(
  ({ expense, showViewReceipt, onReceiptChange }) => {
    const isNonReimbursable =
      expense?.expenseType === EXPENSE_NON_REIMBURSABLE_TYPE;
    const categoryName = expense?.category?.name;
    const projectDisplayName = expense?.project?.displayName;
    const showBillable =
      expense?.category?.billableToClient !== CATEGORY_STATUSES.HIDDEN;
    const receipts = expense?.receipts || [];
    const hasReceipts = receipts.length !== 0;
    const transactionDate = handleDate(expense.transactionDate);

    const renderReceiptRow = (receipt: ExpenseLibraryExpenseReceipt) => {
      return (
        <div className={ExpenseDetailsCardReceiptContainer} key={receipt.uuid}>
          <span className={ExpenseDetailsCardReceiptName}>
            {receipt.filename}
          </span>
          {showViewReceipt && onReceiptChange && (
            <Button
              size="sm"
              type="button"
              leftIcon="eye"
              variant="ghost"
              onClick={() => onReceiptChange(receipt)}
            >
              View Receipt
            </Button>
          )}
        </div>
      );
    };

    return (
      <ul
        className={ExpenseDetailsCardWrapper}
        data-testid="expense-drawer-data-list"
      >
        <li className={ExpenseDetailsCardItem}>
          <Badge role="info">
            {isNonReimbursable
              ? EXPENSE_NON_REIMBURSABLE_LABEL
              : EXPENSE_REIMBURSABLE_LABEL}
          </Badge>
        </li>
        <li
          className={ExpenseDetailsCardItem}
          data-testid="expense-drawer-data-list-element"
        >
          <p>Amount</p>
          {getDollarsFromCents(expense.amount)}
        </li>
        <li
          className={ExpenseDetailsCardItem}
          data-testid="expense-drawer-data-list-element"
        >
          <p>Transaction date</p>
          {transactionDate}
        </li>
        {projectDisplayName && (
          <li
            className={ExpenseDetailsCardItem}
            data-testid="expense-drawer-data-list-element"
          >
            <p>Project</p>
            {projectDisplayName}
          </li>
        )}
        {categoryName && (
          <li
            className={ExpenseDetailsCardItem}
            data-testid="expense-drawer-data-list-element"
          >
            <p>Category</p>
            {categoryName}
          </li>
        )}
        {expense.merchant && (
          <li
            className={ExpenseDetailsCardItem}
            data-testid="expense-drawer-data-list-element"
          >
            <p>Merchant</p>
            {expense.merchant}
          </li>
        )}
        {expense.description && (
          <li
            className={ExpenseDetailsCardItem}
            data-testid="expense-drawer-data-list-element"
          >
            <p>Description</p>
            {expense.description}
          </li>
        )}
        {expense.attendees && (
          <li
            className={ExpenseDetailsCardItem}
            data-testid="expense-drawer-data-list-element"
          >
            <p>Attendees</p>
            {expense.attendees}
          </li>
        )}
        {showBillable && (
          <li
            className={ExpenseDetailsCardItem}
            data-testid="expense-drawer-data-list-element"
          >
            <p>Billable to client</p>
            {expense?.isBillableToClient ? BILLABLE_VALUE : NON_BILLABLE_VALUE}
          </li>
        )}
        {hasReceipts && (
          <li
            className={ExpenseDetailsCardItem}
            data-testid="expense-drawer-data-list-element"
          >
            <p>Receipt</p>
            <div className={ExpenseDetailsCardReceiptsWrapper}>
              {receipts.map(renderReceiptRow)}
            </div>
          </li>
        )}
      </ul>
    );
  }
);
