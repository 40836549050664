import { ExpenseLibraryResponse } from "types/Expenses";

export const INITIAL_EXPENSE_LIBRARY: ExpenseLibraryStoreType = {
  library: {
    page: 0,
    expenses: [],
    totalPages: 0,
    totalResults: 0,
  },
};

export type ExpenseLibraryStoreType = {
  library: ExpenseLibraryResponse;
};

export type SetExpenseLibraryPayload = {
  library: ExpenseLibraryResponse;
};
