import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { prependBasePath } from "pages/expenses/constants";
import { PermissionsError } from "pages/expenses/components";
import { useGlobalConfigurationStore } from "pages/expenses/store";

interface RequestHistoryPagePermissionsWrapperProps {
  children: JSX.Element;
}

export const RequestHistoryPagePermissionsWrapper: React.FC<
  RequestHistoryPagePermissionsWrapperProps
> = ({ children }) => {
  const navigate = useNavigate();

  const {
    permissions: {
      isAllowedToFirstTimeSetup,
      isAllowedToAccessReviewHistoryPage,
    },
  } = useGlobalConfigurationStore();

  useEffect(() => {
    if (isAllowedToFirstTimeSetup) {
      navigate(prependBasePath("SETTINGS"));
    }
  }, [isAllowedToFirstTimeSetup, navigate]);

  if (!isAllowedToAccessReviewHistoryPage) {
    return <PermissionsError />;
  }

  return children;
};
