import { ReactElement } from "react";
import styles from "./PayNavigation.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";
import { buildClockworkWebPath } from "lib/resource-finder";
import {
  ADDITIONAL_PAY_FORM_ROUTES,
  CLOCKWORK_WEB_PATH,
} from "../../constants";
import { useParams } from "react-router";

const getFormTitle = (formType: string) => {
  switch (formType) {
    case ADDITIONAL_PAY_FORM_ROUTES.REIMBURSEMENT:
      return "Expense Reimbursement";
    case ADDITIONAL_PAY_FORM_ROUTES.TAXABLEBENEFITSANDALLOWANCES:
      return "Taxable benefits & allowances";
    default:
      return "Employee Pay";
  }
};

export const PayNavigation = (): ReactElement => {
  const { formType } = useParams();
  return (
    <>
      <div className={styles.nav}>
        <SystemIcon iconName="arrow-left" size="small" color="brand" />
        <a href={buildClockworkWebPath(CLOCKWORK_WEB_PATH.PAYMENTCENTER)}>
          Back to Payment center
        </a>
      </div>

      <h1>{getFormTitle(formType || "")}</h1>
    </>
  );
};
