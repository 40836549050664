import { PageHeader, Stack } from "@justworkshr/milo-core";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { REQUEST_SEPARATION_COPY } from "../constants";

type Props = {
  title: string;
};

const RequestSeparationHeader = ({ title }: Props) => {
  const { id: memberId } = useParams();
  const { t } = useTranslation();
  const memberProfileLink = `/employer-of-record/employee-profile/${memberId}/personal-info`;

  return (
    <PageHeader
      title={title}
      linkPrevious={
        <Link to={memberProfileLink}>{t("Back to member view")}</Link>
      }
    >
      <Stack>
        <div>{REQUEST_SEPARATION_COPY.requestSeparationDescription}</div>
        <div>
          <strong>{t("Note")}: </strong>
          {REQUEST_SEPARATION_COPY.requestSeparationNote}
        </div>
      </Stack>
    </PageHeader>
  );
};

export default RequestSeparationHeader;
