import React from "react";
import { SystemIcon } from "@justworkshr/milo-icons";
import styles from "./LeverSetup.module.css";
import {
  PmtIntegration,
  PmtIntegrationInstancesByKey,
} from "types/pmt-integrations";

const { statusMessage, failedWebhookState } = styles;

interface StatusMessageProps {
  integrationSetupSuccess: boolean;
  currentStep: number;
}

enum ErrorMessagesPerStep {
  StepOne = "Integration setup failed. Try again or contact support.",
  StepTwo = "Failed to authenticate. Try connecting again or contact support.",
  StepThree = "Failed to set up the integration. Retry or contact support.",
}

enum SuccessMessagesPerStep {
  StepTwo = "Lever connected",
  StepThree = "Webhook created",
}

function getCurrentStepSuccessMessage(currentStep: number): string {
  switch (currentStep) {
    case 2:
      return SuccessMessagesPerStep.StepTwo;
    case 3:
      return SuccessMessagesPerStep.StepThree;
    default:
      return "";
  }
}

function getCurrentStepErrorMessage(currentStep: number): string {
  switch (currentStep) {
    case 1:
      return ErrorMessagesPerStep.StepOne;
    case 2:
      return ErrorMessagesPerStep.StepTwo;
    case 3:
      return ErrorMessagesPerStep.StepThree;
    default:
      return "";
  }
}

export const StatusMessage: React.FC<StatusMessageProps> = ({
  integrationSetupSuccess,
  currentStep,
}) => {
  const statusMessageColor = {
    "--status-message-color": integrationSetupSuccess
      ? "var(--intent-color-text-success-default)"
      : "var(--intent-color-text-danger-default)",
  } as React.CSSProperties;

  if (integrationSetupSuccess) {
    return (
      <div className={statusMessage} style={statusMessageColor}>
        <SystemIcon iconName="check-circle-inverted" color="success" />
        {getCurrentStepSuccessMessage(currentStep)}
      </div>
    );
  }

  return (
    <div className={failedWebhookState}>
      <div className={statusMessage} style={statusMessageColor}>
        <SystemIcon iconName="warning" color="error" />
        {getCurrentStepErrorMessage(currentStep)}
      </div>
    </div>
  );
};

export const getInstanceSuccessMap = (
  recentIntegrationInstances: PmtIntegrationInstancesByKey[] | undefined | null
) =>
  recentIntegrationInstances?.reduce((acc, keyedInstance) => {
    acc[keyedInstance.key] = keyedInstance.instances[0]?.status === "success";
    return acc;
  }, {} as { [key: string]: boolean });

export const getStepForLever = (
  integration: PmtIntegration | undefined | null,
  recentIntegrationInstances: PmtIntegrationInstancesByKey[] | undefined | null,
  authSuccess?: boolean
) => {
  // steps: 0) create 1) oauth_init / get_token 2) setup webhook 3) enable webhook
  if (!integration || integration.status === "inactive") return 0; // we don't have an active integration
  if (!recentIntegrationInstances?.length) return 1; // we don't have any event instances

  const instanceSuccessMap = getInstanceSuccessMap(recentIntegrationInstances);

  // if we don't have a successful get_token event, or recent auth attempt failed, we need to do oauth
  if (!instanceSuccessMap?.get_token || authSuccess === false) return 1;

  //if we have just returned from a successful auth attempt, we need to show the success message on the oauth step before moving on
  if (authSuccess === true && !instanceSuccessMap?.setup) return 1;

  // if we don't have a successful setup event, we need to do webhook setup
  return instanceSuccessMap?.setup ? 3 : 2;
};
