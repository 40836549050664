import React from "react";
import { AuditLogItem } from "../AuditLogItem";

interface PaymentCanceledProps {
  occurredAt: string;
  memberName: string;
}

export const PaymentCanceled: React.FC<PaymentCanceledProps> = ({
  occurredAt,
  memberName,
}) => {
  return (
    <AuditLogItem
      author={memberName}
      occurredAt={occurredAt}
      previewText="Request canceled"
    >
      Reach out to your admin for more information.
    </AuditLogItem>
  );
};

export default PaymentCanceled;
