import React, { ReactElement } from "react";
import styles from "./HeaderStatus.module.css";
import { getStatusStyling } from "../utils";
import { HEADERSTATUS } from "../constants";
import { formatDate } from "pages/employer-of-record/employee-profile/utils";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";

const HeaderStatus = (): ReactElement => {
  const { employeeStatus, profileInfo } = useProfileContextData();
  const contract = profileInfo?.eorEmployeeProfile.contract;

  const startDate = formatDate(contract?.startDate, "MMM D, YYYY");
  const endDate = formatDate(contract?.endDate, "MMM D, YYYY");
  const statusStyling = getStatusStyling(employeeStatus, styles);

  return (
    <div className={`${styles.statusWrapper} ${statusStyling}`}>
      {employeeStatus === HEADERSTATUS.PENDING && (
        <div>
          <span className={styles.emphasize}>Start date:</span> {startDate}
        </div>
      )}
      {employeeStatus === HEADERSTATUS.SCHEDULED && (
        <div>
          <span className={styles.emphasize}>Scheduled for termination</span>:
          Last day on {endDate}. For further changes or questions, contact{" "}
          <span className={styles.emphasize}>
            international-support@justworks.com
          </span>
        </div>
      )}
      {employeeStatus === HEADERSTATUS.TERMINATED && (
        <div>
          <span className={styles.emphasize}>Terminated</span> as of {endDate}.
          If you would like to rehire, contact{" "}
          <span className={styles.emphasize}>
            international-support@justworks.com
          </span>
        </div>
      )}
    </div>
  );
};

export default HeaderStatus;
