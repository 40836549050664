import { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { pkceIsEnabled } from "app/okta";

export const useRequireAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const isAuthenticated = authState?.isAuthenticated;

  useEffect(() => {
    if (!authState || !pkceIsEnabled()) {
      return;
    }

    if (!isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, authState, isAuthenticated]);

  return { authState };
};
