import styles from "../witnesses.module.css";
import { FieldArray, FieldArrayRenderProps } from "formik";
import { FormikFormField } from "lib/formik/FormikFormField";
import { TextArea, TextInput } from "@justworkshr/milo-form";
import { SystemIcon } from "@justworkshr/milo-icons";
import { WitnessInformation } from "../../../../types";
import { useEffect } from "react";

const {
  witnessList,
  witnessForm,
  inputHelp,
  witnessFormDivider,
  removeWitness,
  addWitness,
  infoTextInput,
} = styles;

export interface IWitnessesFormComponentProps {
  formData: WitnessInformation;
  values: WitnessInformation;
}

export default function WitnessesFormComponent({
  values,
}: IWitnessesFormComponentProps) {
  function resetField(helpers: FieldArrayRenderProps) {
    for (let i = 0; i < values.witnessList.length; i++) {
      helpers.pop();
    }
    return <></>;
  }

  useEffect(() => {
    if (values.witnessesExist === "Yes" && values.witnessList.length === 0) {
      values.witnessList.push({
        name: "",
        contact: "",
        notes: "",
      });
    }
  }, [values.witnessList, values.witnessesExist]);

  return (
    <div className={witnessList}>
      <FieldArray
        name="witnessList"
        render={(arrayHelpers) => {
          {
            values.witnessesExist !== "Yes" ? resetField(arrayHelpers) : <></>;
          }
          return (
            <main>
              {values.witnessList.map((witness, index) => {
                return (
                  <div className={witnessForm} key={index}>
                    <FormikFormField
                      label={`Name of witness #${index + 1}`}
                      name={`witnessList[${index}].name`}
                      required
                    >
                      <TextInput
                        name={`witnessList[${index}].name`}
                        value={witness.name}
                      />
                    </FormikFormField>

                    <div className={infoTextInput}>
                      <h3 id="contact-label">
                        Contact information for the witness
                      </h3>
                      <p className={inputHelp}>
                        This can be a phone number, email, or address
                      </p>
                      <FormikFormField
                        name={`witnessList[${index}].contact`}
                        aria-labelledby="contact-label"
                        required
                      >
                        <TextArea
                          name={`witnessList[${index}].contact`}
                          value={witness.contact}
                          maxLength={10000}
                        />
                      </FormikFormField>
                    </div>

                    <div className={infoTextInput}>
                      <h3 id="notes-label">
                        Anything related to the incident you would like to add
                      </h3>
                      <p className={inputHelp}>
                        Add any additional notes or findings from the witness
                      </p>

                      <FormikFormField
                        name={`witnessList[${index}].notes`}
                        aria-labelledby="notes-label"
                      >
                        <TextArea
                          name={`witnessList[${index}].notes`}
                          value={witness.notes}
                          maxLength={10000}
                        />
                      </FormikFormField>
                    </div>

                    {values.witnessList.length - 1 > index && (
                      <hr className={witnessFormDivider} />
                    )}

                    {values.witnessList.length > 1 && (
                      <button
                        onClick={() => arrayHelpers.remove(index)}
                        type="button"
                        className={removeWitness}
                      >
                        <SystemIcon iconName="minus-circle" color="error" />
                        Delete witness
                      </button>
                    )}

                    {index === values.witnessList.length - 1 &&
                      values.witnessList.length < 3 && (
                        <button
                          onClick={() =>
                            arrayHelpers.push({
                              name: "",
                              contact: "",
                              notes: "",
                            })
                          }
                          type="button"
                          className={`milo--link ${addWitness}`}
                        >
                          <SystemIcon iconName="plus-circle" color="brand" />
                          Add new witness
                        </button>
                      )}
                  </div>
                );
              })}
            </main>
          );
        }}
      />
    </div>
  );
}
