import { useGetMemberSeparationQuestionnaire } from "pages/team-management/hooks/useGetMemberSeparationQuestionnaire";
import { useGetMemberSeparation } from "pages/team-management/hooks/useGetMemberSeparation";
import { QuestionnaireForm } from "./components/QuestionnaireForm";
import { MemberSeparation } from "pages/team-management/types";
import { useParams, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { SeparationFlowContext } from "pages/team-management/contexts";

const getTerminationCode = (memberSeparation: MemberSeparation | undefined) => {
  const memberSeparationAnswers = memberSeparation?.memberSeparationAnswers;
  const terminationAnswer = memberSeparationAnswers?.find(
    (answer) => answer.fieldId === "termination_reason"
  );
  if (
    terminationAnswer?.values &&
    terminationAnswer.values[0]?.valueTextSmall
  ) {
    return terminationAnswer.values[0].valueTextSmall;
  }

  return null;
};

export const QuestionnairePage = () => {
  const navigate = useNavigate();
  const { memberUuid } = useParams();
  const { setShowGlobalGenericError } = useContext(SeparationFlowContext);
  const {
    memberSeparation,
    loading: isMemberSeparationLoading,
    error: memberSeparationError,
  } = useGetMemberSeparation(memberUuid);
  const backUrl = `/team-management/separation-flow/${memberUuid}/initial-intake`;
  const nextUrl = `/team-management/separation-flow/${memberUuid}/additional-payments`;

  const terminationCode = getTerminationCode(memberSeparation) ?? "";
  const {
    error: questionFetchError,
    loading: isQuestionnaireLoading,
    fullQuestionTree,
    fullQuestionList,
  } = useGetMemberSeparationQuestionnaire({
    terminationCode: terminationCode,
  });

  useEffect(() => {
    if (
      !isMemberSeparationLoading &&
      ["incomplete", "intake_partially_complete"].includes(
        memberSeparation?.status || ""
      )
    ) {
      navigate(backUrl, {
        replace: true,
      });
    }
  }, [backUrl, isMemberSeparationLoading, memberSeparation?.status, navigate]);

  useEffect(() => {
    if (
      questionFetchError?.message?.startsWith(
        "No questions found for termination code"
      )
    ) {
      navigate(nextUrl, {
        replace: true,
        state: { time: new Date().getTime() },
      });
    } else if (questionFetchError || memberSeparationError) {
      setShowGlobalGenericError?.(true);
    }
  }, [
    questionFetchError,
    navigate,
    nextUrl,
    setShowGlobalGenericError,
    memberSeparationError,
  ]);

  return (
    <>
      <div data-testid="QuestionnairePage">
        <QuestionnaireForm
          memberSeparation={memberSeparation}
          fullQuestionTree={fullQuestionTree}
          fullQuestionList={fullQuestionList}
          backUrl={backUrl}
          nextUrl={nextUrl}
          loading={isMemberSeparationLoading || isQuestionnaireLoading}
        />
      </div>
    </>
  );
};
