import { MemberSeparationAnswer } from "pages/team-management/types";

export const getAnswer = (
  answers: MemberSeparationAnswer[],
  questionId: string
) => {
  return answers.find((answer) => answer.fieldId === questionId)?.values?.[0];
};

export const booleanStringToFriendlyString = (
  value: string | null | undefined
) => {
  if (!value) {
    return undefined;
  }

  const lowerCaseValue = value.toLowerCase();

  if (lowerCaseValue === "true") {
    return "Yes";
  } else if (lowerCaseValue === "false") {
    return "No";
  } else {
    return undefined;
  }
};

// Converts strings in the format of "YYYY-MM-DD" to a Date object
// in the local timezone. This is because the new Date() constructor
// parses that format as UTC leading to a date with the previous
// day in locales with a negative UTC offset (e.g. US timezones).
// e.g. new Date('2021-10-01') in US timezones will have a date of 09/30/2021
// whereas isoStringToDate('2021-10-01') will correctly have the date of
// 10/01/2021 in whatever the local timezone is.
export const isoStringToDate = (isoString: string): Date => {
  const [year, month, day] = isoString.split("-").map(Number);
  return new Date(year, month - 1, day);
};

export const formatDateAsString = (date: Date) => {
  return `${("0" + (date.getMonth() + 1)).slice(-2)}/${(
    "0" + date.getDate()
  ).slice(-2)}/${date.getFullYear()}`;
};

export const lastDayOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};
