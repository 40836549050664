import { TextArea } from "@justworkshr/milo-form";
import styles from "./TextAreaQuestion.module.css";
import { MemberSeparationValidations } from "pages/team-management/types";

interface TextAreaProps extends React.ComponentPropsWithoutRef<"textarea"> {
  questionId: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string | number | readonly string[] | undefined;
  validations?: MemberSeparationValidations;
}

export const TextAreaQuestion = ({
  questionId,
  onChange,
  value,
  validations,
}: TextAreaProps) => {
  const displayedValue = value ? String(value) : "";

  return (
    <div>
      <TextArea
        name={questionId}
        onChange={onChange}
        value={displayedValue}
      ></TextArea>
      {validations?.minLength && (
        <span className={styles.counter}>
          Remaining required characters:{" "}
          {Math.max(validations.minLength - displayedValue.length, 0)}
        </span>
      )}
    </div>
  );
};
