import { ReactElement } from "react";
import { NotificationMemberDetails } from "types";
import Avatar from "app-frame/common/Avatar";
import styles from "./alerts-notification.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";

const { alertsNotification, hasAvatar, warningOverlay } = styles;

export default function AlertsNotification({
  message,
  url,
  member,
}: {
  message: string;
  url?: string;
  member?: NotificationMemberDetails;
}): ReactElement {
  let alertsNotificationClasses = alertsNotification;
  if (member) {
    alertsNotificationClasses = `${alertsNotificationClasses} ${hasAvatar}`;
  }
  const displayMessage = url ? (
    <a href={url} data-heap-redact-text={true}>
      {message}
    </a>
  ) : (
    <span data-heap-redact-text={true}>{message}</span>
  );
  const avatar = member ? (
    <div data-heap-redact-attributes="src">
      <span>
        <Avatar
          name={`${member.preferredName} ${member.lastName}`}
          photoUrl={member.photoUrl ? member.photoUrl : ""}
          width={30}
          height={30}
          data-heap-redact-text={true}
        />
        {member.uuid === "warning_overlay" && (
          <span className={warningOverlay}>
            <SystemIcon
              iconName="warning"
              color="inverse"
              size="extra-small"
              data-testid="warning-overlay"
            />
          </span>
        )}
      </span>
    </div>
  ) : (
    ""
  );
  return (
    <div className={alertsNotificationClasses}>
      {avatar}
      {displayMessage}
    </div>
  );
}
