import { ValidationError } from "stdnum/src/exceptions";
import { ValidateReturn } from "stdnum/lib/cjs/types";

const phTaxIdValidator = {
  name: "Phillipines Taxpayer Identification Number",
  localName: "Taxpayer Identification Number",
  abbreviation: "TIN",
  compact(input: string): string {
    return input;
  },

  format(input: string): string {
    return input;
  },

  validate(input: string): ValidateReturn {
    if (input.length < 9 || input.length > 12) {
      return {
        isValid: false,
        error: new ValidationError("TIN must be 9-12 digits"),
      };
    }
    if (!/^[0-9]+$/.test(input)) {
      return {
        isValid: false,
        error: new ValidationError("TIN must consist of only digits"),
      };
    }

    return {
      isValid: true,
      compact: input,
      isIndividual: true,
      isCompany: false,
    };
  },
};

export { phTaxIdValidator };
