import { Alert } from "@justworkshr/milo-core";
import { useFormikContext } from "formik";

const FormPermissionsAlert = () => {
  const { errors, setFieldError } = useFormikContext<{
    permissions: string[];
  }>();

  const permissionsError = Array.isArray(errors.permissions)
    ? errors.permissions.join(", ")
    : errors.permissions;

  const handleDismiss = () => {
    setFieldError("permissions", undefined);
  };

  return (
    <Alert
      onDismiss={handleDismiss}
      color="destructive"
      visible={!!permissionsError}
      dismissible
    >
      {permissionsError ?? ""}
    </Alert>
  );
};

export default FormPermissionsAlert;
