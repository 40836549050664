import styles from "./Badge.module.css";

const { badge, ...badgeTypes } = styles;

type ColorType =
  | "green"
  | "yellow"
  | "gold"
  | "blue"
  | "navy"
  | "purple"
  | "red"
  | "gray";
type ModeType = "standard" | "inverted";

interface BadgeProps {
  className?: string;
  color: ColorType;
  mode?: ModeType;
  label: string | number;
}

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

function Badge({ className, color, mode = "standard", label }: BadgeProps) {
  const badgeColor = badgeTypes[`color${capitalize(color)}`];
  const badgeMode = badgeTypes[`mode${capitalize(mode)}`];

  return (
    <span className={[badge, badgeColor, badgeMode, className].join(" ")}>
      {label}
    </span>
  );
}

export default Badge;
