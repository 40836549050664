import { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./Payments.module.css";
import { Outlet, useParams } from "react-router-dom";
import { AdditionalPayContext } from "./contexts/additionalPayContext";
import usePayStepNavigation from "./hooks/usePayStepNavigation";
import {
  calculateStepNumber,
  extendFormData,
  getForValidationSchema,
  getFormInitialValues,
  recursivelyConvertFilesToBase64Objects,
  removeEntriesWithNoGrossAmount,
} from "./utils";
import { StepperLayout } from "./components/StepperLayout";
import { Form, Formik, FormikHelpers } from "formik";
import {
  AdditionalPayFormData,
  FormPayloadType,
} from "./components/Setup/types";
import { Confirmation } from "./components/Confirmation/Confirmation";
import { PayNavigation } from "./components/PayNavigation";
import PaymentsAlerts from "./components/Alert/Alert";
import { Spinner } from "@justworkshr/milo-core";
import { SUBPATHS } from "./constants";
import { useLocation } from "react-router-dom";
import { useEorCreateDisbursementOrdersMutation } from "types/generated/operations";
import { ERROR_MESSAGE } from "./components/Alert";

export const Payments = (): ReactElement => {
  const additionalPayContext = useContext(AdditionalPayContext);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { formType } = useParams();

  const formIntialValues = getFormInitialValues(formType || "");
  const formValidationSchema = getForValidationSchema(formType || "");

  const { navigateToStep } = usePayStepNavigation();
  const { pathname } = useLocation();

  const [createDisbursementOrders, { loading }] =
    useEorCreateDisbursementOrdersMutation();
  const handleSubmit = async <T extends FormPayloadType>(
    values: AdditionalPayFormData<T>,
    { setSubmitting }: FormikHelpers<AdditionalPayFormData<T>>
  ) => {
    if (pathname.includes(SUBPATHS.REVIEW)) {
      const extendedData = extendFormData(
        values.setUp,
        additionalPayContext?.disbursementDateOptions || {}
      );

      const validPaymentEntries = removeEntriesWithNoGrossAmount(
        values.paymentDetails
      );

      await recursivelyConvertFilesToBase64Objects(extendedData);
      void createDisbursementOrders({
        variables: {
          setup: extendedData,
          paymentDetails: validPaymentEntries,
        },
        onCompleted() {
          setSubmitting(loading);
          setFormSubmitted(true);
        },
        onError() {
          additionalPayContext?.setErrorMessage(ERROR_MESSAGE);
        },
      });
      window.scrollTo(0, 0);
    } else {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (additionalPayContext?.currentStep) {
      navigateToStep(additionalPayContext.currentStep);
    }
  }, [additionalPayContext?.currentStep, navigateToStep]);

  const showSpinner = additionalPayContext?.loadingMemberData && (
    <div className={styles.spinnerWrapper}>
      <Spinner size="extraLarge" />
    </div>
  );

  const showForm = !formSubmitted && (
    <div className={styles.contentWrapper}>
      <div className={styles.contentWrapper}>
        <PayNavigation />

        <div className={styles.layout}>
          {showSpinner}
          {!additionalPayContext?.loadingMemberData && (
            <Formik
              initialValues={formIntialValues}
              onSubmit={handleSubmit}
              validationSchema={formValidationSchema}
            >
              <Form>
                <StepperLayout
                  step={calculateStepNumber(additionalPayContext?.currentStep)}
                  loading={false}
                >
                  <Outlet />
                </StepperLayout>
              </Form>
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
  const showConfirmation = formSubmitted && <Confirmation />;

  return (
    <>
      {<PaymentsAlerts />}
      {showForm}
      {showConfirmation}
    </>
  );
};
