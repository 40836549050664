import * as Yup from "yup";
import NLFragment from "./NLFragment";

export type ConfigType = {
  component: JSX.Element;
  schema: typeof schema;
  initialValues: SchemaType;
  keysToExclude?: Partial<keyof SchemaType>[];
};

export type SchemaType = Yup.InferType<typeof schema>;

const schema = Yup.object({
  workCountry: Yup.string().oneOf(["NL"]).required("Required"),
  nlGrossPayIncludesHolidayPay: Yup.boolean().required("Required"),
  averageMinutesPerWeek: Yup.number()
    .min(0, "Must be greater than or equal to 0")
    // 10080 = 7 days * 24 hours * 60 minutes
    // note that the message 168 is the number of hours in a week and is intended to
    // be human readable, while 10080 is the minutes value that the form checks against.
    // We tested this and it works as expected :)
    .max(10080, "Must be less than or equal to 168")
    .required("Required"),
});

const CONFIG: ConfigType = {
  component: <NLFragment />,
  schema: schema,
  initialValues: {
    workCountry: "NL",
    nlGrossPayIncludesHolidayPay: false,
    averageMinutesPerWeek: 2400, //  40 * 60 = 40 hours
  },
  keysToExclude: [],
};

export default CONFIG;
