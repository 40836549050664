import { useTranslation } from "react-i18next";
import { useGetFeatureFlag } from "lib/launch-darkly";
import CardAction from "../../CardAction/CardAction";
import RequestChangesAction from "../../RequestChangesAction/RequestChangesAction";
import useNavigateToBankAccountEditing from "pages/employer-of-record/employee-profile/hooks/useNavigateToBankAccountEditing";

export default function EditBankAccountAction() {
  const { t } = useTranslation();
  const { getFeatureFlag } = useGetFeatureFlag();
  const navigateToBankAccountEditing = useNavigateToBankAccountEditing();

  const isEditingEnabled = getFeatureFlag("release-eor-ee-bank-account-edits");

  if (typeof isEditingEnabled === "boolean" && isEditingEnabled) {
    return (
      <CardAction
        name={t("Verify to edit")}
        icon="lock"
        onClick={navigateToBankAccountEditing}
      />
    );
  } else {
    return <RequestChangesAction />;
  }
}
