import { FC, useEffect } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import { NotFoundError, ForbiddenError } from "@justworkshr/milo-core";
import { usePeopleAnalyticsCapabilities } from "./hooks";
import { SUBPATHS, VISIER_SRC_SUFFIXES } from "./constants";
import VisierEventHandler from "./components/visier-event-handler";
import ReportingLandingPage from "./components/reporting-landing-page";
import DataExportsPage from "./components/data-exports-page";
import CompanyOverviewPage from "./components/company-overview-page";
import InsightsLearnPage from "./components/insights-learn-page";
import InsightsPage from "./components/insights-page";
import InsightsLearnPageViewContextProvider from "./contexts/insights-learn-page-view-context";
import MaintenanceAlert from "./components/maintenance-alert";

const PeopleAnalytics: FC = () => {
  const location = useLocation();
  useEffect(() => {
    scrollTo({ top: 0, left: 0 });
  }, [location]);

  const {
    loading,
    memberCompanyHasBasicAccess,
    memberCompanyHasPlusAccess,
    memberCompanyHasBenefits,
    memberCompanyHasPtoPolicies,
  } = usePeopleAnalyticsCapabilities();

  if (loading) {
    return <></>;
  }

  if (!memberCompanyHasBasicAccess) {
    return <ForbiddenError />;
  }

  return (
    <div data-testid="people-analytics">
      <VisierEventHandler>
        <InsightsLearnPageViewContextProvider>
          <MaintenanceAlert />
          <Routes>
            <Route
              path={SUBPATHS.REPORTING}
              element={<ReportingLandingPage />}
            />
            <Route path={SUBPATHS.DATA_EXPORTS} element={<DataExportsPage />} />
            <Route
              path={SUBPATHS.COMPANY_OVERVIEW}
              element={<CompanyOverviewPage />}
            />
            <Route
              path={SUBPATHS.INSIGHTS_LEARN}
              element={<InsightsLearnPage />}
            />
            <Route
              path={SUBPATHS.WORKFORCE_DASHBOARD}
              element={
                memberCompanyHasPlusAccess ? (
                  <InsightsPage
                    visierSrcSuffix={VISIER_SRC_SUFFIXES.WORKFORCE_DASHBOARD}
                  />
                ) : (
                  <ForbiddenError />
                )
              }
            />
            <Route
              path={SUBPATHS.FINANCE_DASHBOARD}
              element={
                memberCompanyHasPlusAccess ? (
                  <InsightsPage
                    visierSrcSuffix={VISIER_SRC_SUFFIXES.FINANCE_DASHBOARD}
                  />
                ) : (
                  <ForbiddenError />
                )
              }
            />
            <Route
              path={SUBPATHS.PTO_DASHBOARD}
              element={
                memberCompanyHasPlusAccess && memberCompanyHasPtoPolicies ? (
                  <InsightsPage
                    visierSrcSuffix={VISIER_SRC_SUFFIXES.PTO_DASHBOARD}
                  />
                ) : (
                  <ForbiddenError />
                )
              }
            />
            <Route
              path={SUBPATHS.BENEFITS_DASHBOARD}
              element={
                memberCompanyHasPlusAccess && memberCompanyHasBenefits ? (
                  <InsightsPage
                    visierSrcSuffix={VISIER_SRC_SUFFIXES.BENEFITS_DASHBOARD}
                  />
                ) : (
                  <ForbiddenError />
                )
              }
            />
            <Route path="*" element={<NotFoundError />} />
          </Routes>
        </InsightsLearnPageViewContextProvider>
      </VisierEventHandler>
    </div>
  );
};

export default PeopleAnalytics;
