import FaqItem from "../FaqItem";
import benefitsClasses from "pages/benefits/benefits.module.css";

const FaqTab = () => {
  return (
    <div>
      <FaqItem
        title="Can I tell my employees about the transition? Are there any resources I can share with my employees?"
        collapsed={false}
      >
        <p className={benefitsClasses.benefitsParagraph}>
          Absolutely-just know they don’t need to take any action until after
          the blackout period ends. We’ll reach out to employees before the
          blackout period starts.
        </p>
        <p className={benefitsClasses.benefitsParagraph}>
          Employees can first visit Empower Transition for more in-depth
          information. They’ll also have access to both Justworks Customer
          Support and Empower’s participant level support team.
        </p>
      </FaqItem>
      <FaqItem
        title="Will there be any hands-on training or overviews of the new plan and product experience? "
        collapsed={false}
      >
        <p className={benefitsClasses.benefitsParagraph}>
          In addition to the wealth of resources on Empower’s own site, we’re
          also offering Empower Transition for more in-depth information.
          Empower will also be offering support webinars for employees during
          the 2 weeks after the transition is complete. You can also request an
          on-site or virtual education day for your company for an additional
          fee.
        </p>
      </FaqItem>
      <FaqItem
        title="What's going to happen to our current funds and assets? "
        collapsed={true}
      >
        <p className={benefitsClasses.benefitsParagraph}>
          We're working with our investment advisors to ensure that the funds we
          are mapping to are substantially similar to our current investment
          options. Outside of the Stable Value Fund, the core lineup is not
          changing, which means the expense ratios are also not changing. We
          will do our best to get the full list to you in the next few weeks
          however we encourage you to work with a financial advisor for any
          investment questions you may have.
        </p>
      </FaqItem>
      <FaqItem
        title="Who assumes the fiduciary responsibility for the new plan with Empower?"
        collapsed={true}
      >
        <p className={benefitsClasses.benefitsParagraph}>
          Nothing about this transition changes the fiduciary responsibilities
          that have been in place with our previous provider. Justworks remains
          the plan sponsor and retains all fiduciary obligations. There are no
          additional obligations for you due to the transition as it relates to
          the plan.
        </p>
        <p className={benefitsClasses.benefitsParagraph}>
          Note: If you decide to transfer your plan to an external provider, you
          will be responsible for the administration of your retirement savings
          plan.
        </p>
      </FaqItem>
      <FaqItem
        title="Are there any penalties or tax implications associated with the transfer? Since this is a mid-year change, how will Justworks handle the required IRS tax reporting?"
        collapsed={true}
      >
        <p className={benefitsClasses.benefitsParagraph}>
          There are no taxable events happening based on the transfer so we do
          not anticipate any penalties or tax implications related to the
          transfer of the recordkeeper. The blackout period is a standard
          process that ensures everything transfers properly. It is not a
          process unique to Justworks. Additionally, Justworks will continue to
          file the annual 5500 documents in accordance with federal
          requirements.
        </p>
      </FaqItem>
      <FaqItem
        title="What happens to separated employees that have active Slavic accounts during the transition?"
        collapsed={true}
      >
        <p className={benefitsClasses.benefitsParagraph}>
          Former employees with an account balance of less than $5,000 are
          eligible to be cashed out of the plan prior to the transition. Any
          employees that meet this criteria that are not processed out of the
          plan prior to the migration will be transferred to Empower where the
          mandatory cash out process will continue. Former employees with a
          balance more than $5,000 will be migrated to Empower with the rest of
          their company’s assets.
        </p>
      </FaqItem>
      <FaqItem
        title="What happens to my payroll deductions and employer contributions during the blackout period?"
        collapsed={true}
      >
        <p className={benefitsClasses.benefitsParagraph}>
          Your payroll deductions, including 401(k) deferrals and loan payments,
          will continue during the blackout period. If you have employer
          contributions, they will also continue. Empower will deposit
          contributions into your account once the plan’s transition is
          complete. Your deferral elections(s) will automatically transfer from
          Slavic to Empower.
        </p>
      </FaqItem>
    </div>
  );
};

export default FaqTab;
