import { Loading } from "pages/expenses/components";
import { useApolloLinks, useGetConfiguration } from "pages/expenses/hooks";

interface ExpensesConfigurationConsumerProps {
  children: JSX.Element;
}

export const ExpensesConfigurationConsumer: React.FC<
  ExpensesConfigurationConsumerProps
> = ({ children }) => {
  useApolloLinks();

  const isGetConfigurationLoading = useGetConfiguration();

  if (isGetConfigurationLoading) {
    return <Loading />;
  }

  return children;
};
