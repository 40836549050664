import { PayGroupTypes, TaxMethodTypes } from "./TaxMethodInputRow.types";

export const BONUS_COMMISSION_TAX_METHOD_DESCRIPTION_COPY =
  "Typically, withholding on the basis of an employee’s cumulative wages may " +
  "only be used when wages, including bonuses and commissions are paid with " +
  "the same frequency. Additionally, this method requires an employee’s written " +
  "request and the employee retains the right to revoke their request, which " +
  "also must be done in writing. Please consult a tax advisor if you are not " +
  "sure which method to use.";

export const TAX_METHOD_DESCRIPTION_COPY =
  "State taxes will be withheld at supplemental rates, if applicable.";

export const FLAT_RATE_DESCRIPTION_COPY =
  "For supplemental wages cumulatively $1M or less, the IRS tax rate is 22%. " +
  "For supplemental wages cumulatively greater than $1M, the IRS tax rate is 37%.";

export const CUMULATIVE_TAX_DESCRIPTION_COPY =
  "A variable tax rate based on all the employee’s taxable remuneration for the current calendar year.";

export const COMMISSION_CUMULATIVE_TAX_DESCRIPTION_COPY =
  "A variable tax rate based on all the employee’s taxable remuneration " +
  "for the current calendar year. Do not use if this is their first commission year.";

export const PAYMENT_DETAILS_TAX_METHOD_OPTIONS = {
  [TaxMethodTypes.FLAT]: {
    label: "Flat rate",
    message: FLAT_RATE_DESCRIPTION_COPY,
  },
  [TaxMethodTypes.CUMULATIVE]: {
    label: "Cumulative",
    message: CUMULATIVE_TAX_DESCRIPTION_COPY,
  },
};

export const COMMISSION_PAYMENT_DETAILS_TAX_METHOD_OPTIONS = {
  [TaxMethodTypes.FLAT]: {
    label: "Flat rate",
    message: FLAT_RATE_DESCRIPTION_COPY,
  },
  [TaxMethodTypes.CUMULATIVE]: {
    label: "Cumulative",
    message: COMMISSION_CUMULATIVE_TAX_DESCRIPTION_COPY,
  },
};

export const PAY_GROUP_OPTIONS: Record<PayGroupTypes, string> = {
  [PayGroupTypes.MONTHLY]: "Monthly",
  [PayGroupTypes.QUARTERLY]: "Quarterly",
};
