export const REQUEST_UUID_DESCRIPTION = (
  <p>
    For a reimbursement to be non-taxable, the request must be submitted &
    approved in a reasonable time frame, generally within 60 days after the
    expense is paid or incurred.{" "}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://help.justworks.com/hc/en-us/articles/17024877999515-Expenses-Desktop-"
    >
      Learn more about approving reimbursement requests
    </a>{" "}
    in the Help Center.
  </p>
);
