import Steps from "pages/company-settings/components/Steps";
import styles from "pages/company-settings/components/ReverseWireAuthSteps.module.css";
import ReverseWireAuthJpmTable from "pages/company-settings/components/ReverseWireAuthJpmTable";
import ReverseWireAuthWfTable from "pages/company-settings/components/ReverseWireAuthWfTable";

const { tables, stepContent } = styles;

export default function ReverseWireAuthSteps() {
  const steps = [
    {
      title: "1. Confirm your new bank supports reverse wire",
      description: () => (
        <span>
          Reverse wires drawdowns are common, but some banks may not support
          them.
        </span>
      ),
    },
    {
      title: "2. Review fees",
      description: () => (
        <span>
          Banks may charge a fee for each wire transaction. See what your bank
          charges and if the fees are negotiable.
        </span>
      ),
    },
    {
      title: "3. Authorize your new account",
      description: () => (
        <div className={stepContent}>
          <span>
            Most banks will give you a form so you can include the account info
            and provide written authorization. Here’s everything you’ll need
            from us to get the authorization process started:
          </span>
          <div className={tables}>
            <ReverseWireAuthJpmTable />
            <ReverseWireAuthWfTable />
          </div>
          <span>
            <b>Note:</b> Please be sure your bank authorizes both Justworks
            accounts so that we have multiple options available to process your
            payments.
          </span>
        </div>
      ),
    },
    {
      title: "4. Confirm the authorization was successful and inform Justworks",
      description: () => (
        <span>
          Two to three days after you’ve sent over the authorization, reach out
          to the bank to confirm it was successful. Once you get confirmation,
          reach out to customer support at{" "}
          <a href="mailto:support@justworks.com">support@justworks.com</a> so we
          can make sure everything is working properly on our end.
        </span>
      ),
    },
  ];
  return <Steps steps={steps} />;
}
