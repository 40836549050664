import React from "react";
import styles from "./Table.module.css";

const { TableWrapper, TableContainer } = styles;

interface TableProps {
  dataTestId?: string;
  children: React.ReactNode;
}

export const Table: React.FC<TableProps> = React.memo(
  ({ dataTestId, children }) => {
    return (
      <div className={TableWrapper}>
        <table className={TableContainer} data-testid={dataTestId}>
          {children}
        </table>
      </div>
    );
  }
);

export default Table;
