import React, { Fragment } from "react";

import { useTranslation } from "react-i18next";
import { FormField } from "@justworkshr/milo-form";
import { Button } from "@justworkshr/milo-core";
import {
  FieldArray,
  FieldArrayRenderProps,
  getIn,
  useFormikContext,
} from "formik";

import { DatePicker } from "pages/employer-of-record/components";
import styles from "../../ProfileInfo.module.css";
import { ProfileInfoFormTypeES } from "../../types";

const { inputField, dateArrayFieldContainer } = styles;
const LIMIT = 10;

type Props = {
  name: string;
  label: string;
};

const ESChildrenDOBArrayField = ({ name, label }: Props) => {
  const { t } = useTranslation();
  const { values, handleBlur, setFieldValue } =
    useFormikContext<ProfileInfoFormTypeES>();

  const value: string[] = getIn(values, name);
  const esChildrensBirthDates: string[] = value?.length ? value : [""]; // 1 empty field by defult
  const isAllDatesFilled = esChildrensBirthDates.every(
    (value) => value.length > 0
  );
  const isMoreThanLimit = esChildrensBirthDates.length > LIMIT;
  const isAddMoreDisabled = !isAllDatesFilled || isMoreThanLimit;

  const getName = (index: number) => `${name}.${index}`;

  const handleChange = (index: number, dateStr: string) => {
    const field = getName(index);
    const value = dateStr === "Invalid Date" ? "" : dateStr;
    setFieldValue(field, value);
  };

  const handleRemove = (arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.pop();
  };

  const handleAddMore = (arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.push(""); // Add an empty string
  };

  const isValidDate = (currentDate: string) => {
    const today = new Date();
    return new Date(currentDate) <= today;
  };

  const shouldShowRemoveBtn = (index: number) =>
    esChildrensBirthDates.length > 1 &&
    index === esChildrensBirthDates.length - 1;

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          <FormField
            label={label}
            message={t(
              "Add the dates for all the children. Leave blank if no children."
            )}
            children={<Fragment />}
          />
          {esChildrensBirthDates.map((value, index) => (
            <div className={dateArrayFieldContainer} key={getName(index)}>
              <DatePicker
                id={getName(index)}
                name={getName(index)}
                placeholder={t("Select date")}
                onChange={(dateStr) => {
                  handleChange(index, dateStr);
                }}
                onBlur={handleBlur}
                value={value}
                isValidDate={isValidDate}
              />
              {/* Show only for the last one */}
              {shouldShowRemoveBtn(index) && (
                <Button
                  variant="ghost"
                  type="button"
                  onClick={() => handleRemove(arrayHelpers)}
                  leftIcon="trash"
                  children={null}
                />
              )}
            </div>
          ))}
          <div className={inputField}>
            <Button
              variant="outlined"
              type="button"
              onClick={() => handleAddMore(arrayHelpers)}
              disabled={isAddMoreDisabled}
            >
              {t("Add another child")}
            </Button>
          </div>
        </>
      )}
    />
  );
};

export default ESChildrenDOBArrayField;
