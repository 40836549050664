import { ReactElement } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useGetAdminCapabilitiesQuery } from "types/generated/operations";
import Documents from "./components/Documents/Documents";
import JobInformation from "./components/JobInformation/JobInformation";
import PersonalInfo from "./components/PersonalInfo/PersonalInfo";
import TimeOff from "./components/TimeOff/TimeOff";
import { SUBPATHS } from "./constants";
import AuthorizedProfilePage from "./pages/AuthorizedProfilePage";
import EditProfilePage from "./pages/EditProfilePage";
import { ProfileInfoProvider } from "./contexts/ProfileInfoContext";
import Finances from "./components/Finances/Finances";

export default function EmployeeProfileInfo(): ReactElement {
  const { data, loading } = useGetAdminCapabilitiesQuery();
  const capabilities = data?.authenticatedMember?.capabilities;

  return (
    <Routes>
      <Route
        path="/:id"
        element={
          <AuthorizedProfilePage
            loading={loading}
            hasAccessToAdminProfileView={
              capabilities?.hasAccessToAdminProfileView
            }
          />
        }
      >
        <Route
          path={`/:id/${SUBPATHS.PERSONAL_INFO}`}
          element={<PersonalInfo editable />}
        />
        <Route
          path={`/:id/${SUBPATHS.JOB_INFORMATION}`}
          element={<JobInformation editable />}
        />
        {capabilities?.hasPermissionToUpdateTimeOff && (
          <Route path={`/:id/${SUBPATHS.TIME_OFF}`} element={<TimeOff />} />
        )}
        <Route path={`/:id/${SUBPATHS.DOCUMENTS}`} element={<Documents />} />
        <Route path={`/:id/${SUBPATHS.FINANCES}`} element={<Finances />} />
        <Route
          path="/:id/*"
          element={<Navigate to={SUBPATHS.PERSONAL_INFO} replace />}
        />
        <Route
          path="/:id"
          element={<Navigate to={SUBPATHS.PERSONAL_INFO} replace />}
        />
      </Route>
      <Route
        path={`/:id/${SUBPATHS.EDIT}`}
        element={
          <ProfileInfoProvider>
            <EditProfilePage />
          </ProfileInfoProvider>
        }
      />
    </Routes>
  );
}
