import { useCategoriesStore } from "pages/expenses/store";
import styles from "../../SubmissionRequirementsPage.module.css";
import { Card } from "@justworkshr/milo-core";
import {
  RadioButton,
  CheckboxInput,
  RadioButtonGroup,
} from "@justworkshr/milo-form";
import { ExpenseCategory } from "types/Expenses";
import { ExpenseCategoryFieldSetting } from "types/generated/operations";
import {
  HIDDEN,
  REQUIRED,
  CATEGORY_FIELD_OPTIONS,
  ADDITIONAL_CATEGORIES_FIELD_NAMES,
  ATTENDEES_CUSTOM_DESCRIPTION,
  BILLABLE_TO_CLIENT_DESCRIPTION,
  MEALS_AND_ENTERTAINMENT_CATEGORY_NAME,
  ADDITION_FIELD_NAMES_DISABLED_OPTIONAL,
  CATEGORY_FIELD_OPTIONS_OPTIONAL_DISABLED,
} from "pages/expenses/constants";
import { fieldNameLabelFormat } from "./AdditionalFields.utils";
import { mergeClassNames } from "pages/expenses/utils";
import { useEffect } from "react";
import {
  SettingsTable,
  SettingsTableTableNote,
  SettingsTableCheckboxNote,
} from "pages/expenses/components";

const {
  AdditionalFieldsCardWrapper,
  AdditionalFieldsRadioButtonWrapper,
  AdditionalFieldsRadioButtonWrapperVisible,
} = styles;

export const AdditionalFields = () => {
  const {
    setActiveCategory,
    categories: { activeCategory },
  } = useCategoriesStore();

  useEffect(() => {
    const identifiers = {
      attendees: "#attendees-radio-button-disabled",
      expenseType: "#expenseType-radio-button-disabled",
      billableToClient: "#billableToClient-radio-button-disabled",
    } as { [key: string]: string };

    Object.values(identifiers).forEach((id) =>
      document.querySelector(id)?.setAttribute("disabled", "true")
    );
  }, []);

  if (!activeCategory) {
    return null;
  }

  const isMealsAndEntertainmentCategory =
    activeCategory.name === MEALS_AND_ENTERTAINMENT_CATEGORY_NAME;

  const handleCheckboxChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const field = target.name as keyof ExpenseCategory;
    const value =
      activeCategory[field] === ExpenseCategoryFieldSetting.Hidden
        ? ExpenseCategoryFieldSetting.Required
        : ExpenseCategoryFieldSetting.Hidden;

    setActiveCategory({
      activeCategory: {
        ...activeCategory,
        [field]: value,
      },
    });
  };

  const handleRadioChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const field = target.name as keyof ExpenseCategory;
    const value = target.value as ExpenseCategoryFieldSetting;

    setActiveCategory({
      activeCategory: {
        ...activeCategory,
        [field]: value,
      },
    });
  };

  const renderNote = (fieldName: keyof ExpenseCategory) => {
    if (fieldName === "attendees") {
      return <>{ATTENDEES_CUSTOM_DESCRIPTION}</>;
    } else if (fieldName === "billableToClient") {
      return <>{BILLABLE_TO_CLIENT_DESCRIPTION}</>;
    } else if (fieldName === "expenseType") {
      return (
        <>
          Allow the employee to select if an expense is reimbursable or
          non-reimbursable. Read more about{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.justworks.com/hc/en-us/articles/17024877999515-Expenses-Desktop-"
          >
            expense types
          </a>
          .
        </>
      );
    }
  };

  const renderCheckboxNote = (fieldName: keyof ExpenseCategory) => {
    if (fieldName === "billableToClient") {
      return (
        <p className={SettingsTableCheckboxNote}>
          By default, <strong>Billable to client</strong> will be required if it
          is included in expense details.
        </p>
      );
    } else if (fieldName === "expenseType") {
      return (
        <p className={SettingsTableCheckboxNote}>
          By default, <strong>Expense type</strong> will be required if it is
          included in expense details.
        </p>
      );
    }
  };

  const renderRadioButtons = (
    fieldName: keyof ExpenseCategory,
    props: {
      name: string;
      value: string;
      onChange: ({ target }: React.ChangeEvent<HTMLInputElement>) => void;
    }
  ) => {
    const isAttendeesField = fieldName === "attendees";
    const isFieldWithDisabledOptions =
      ADDITION_FIELD_NAMES_DISABLED_OPTIONAL.includes(fieldName);

    if (
      isFieldWithDisabledOptions ||
      (isAttendeesField && isMealsAndEntertainmentCategory)
    ) {
      return (
        <RadioButtonGroup {...props}>
          {CATEGORY_FIELD_OPTIONS_OPTIONAL_DISABLED.map((option) => {
            return (
              <RadioButton
                label={option.label}
                value={option.value}
                disabled={option.disabled}
                id={`${fieldName}-radio-button${
                  option.disabled ? "-disabled" : ""
                }`}
                key={`${fieldName}-${option.value}`}
              />
            );
          })}
        </RadioButtonGroup>
      );
    }

    return (
      <RadioButtonGroup {...props}>
        {CATEGORY_FIELD_OPTIONS.map((option) => {
          return (
            <RadioButton
              label={option.label}
              value={option.value}
              key={`${fieldName}-${option.value}`}
              data-testid={`${option.value}-radio-button`}
            />
          );
        })}
      </RadioButtonGroup>
    );
  };

  return (
    <Card
      className={AdditionalFieldsCardWrapper}
      title="Additional fields"
      description={`Add additional fields for ${activeCategory.name} submissions.`}
    >
      <SettingsTable headers={["Visible", "Rule", ""]}>
        {ADDITIONAL_CATEGORIES_FIELD_NAMES.map((fieldName) => {
          const label = fieldNameLabelFormat(fieldName);
          const activeCategoryField = fieldName as keyof ExpenseCategory;
          const isReceiptField = activeCategoryField === "receipt";
          const isAttendeesField = activeCategoryField === "attendees";
          const checkboxValue =
            activeCategory[activeCategoryField] === HIDDEN ? HIDDEN : REQUIRED;
          const isChecked =
            activeCategory[activeCategoryField] !== HIDDEN || isReceiptField;
          const isCheckboxDisabled =
            isReceiptField ||
            (isAttendeesField && isMealsAndEntertainmentCategory);
          const radioButtonGroupProps = {
            name: fieldName,
            onChange: handleRadioChange,
            value: activeCategory[activeCategoryField] as string,
          };
          const radioButtonWrapperClassName = mergeClassNames([
            AdditionalFieldsRadioButtonWrapper,
            isChecked ? AdditionalFieldsRadioButtonWrapperVisible : null,
          ]);

          return (
            <tr key={fieldName}>
              <td>
                <CheckboxInput
                  label={label}
                  name={fieldName}
                  checked={isChecked}
                  value={checkboxValue}
                  disabled={isCheckboxDisabled}
                  onChange={handleCheckboxChange}
                  data-testid="category-checkbox"
                />
                {renderCheckboxNote(activeCategoryField)}
              </td>
              <td>
                <div className={radioButtonWrapperClassName}>
                  {renderRadioButtons(
                    activeCategoryField,
                    radioButtonGroupProps
                  )}
                </div>
              </td>
              <td className={SettingsTableTableNote}>
                {renderNote(activeCategoryField)}
              </td>
            </tr>
          );
        })}
      </SettingsTable>
    </Card>
  );
};
