import styles from "./NsfAlertMessage.module.css";

const { BlueBox } = styles;

interface NsfAlertMessageProps {
  returnCode: ReturnCode | string;
}
type ReturnCode =
  | "R01"
  | "R02"
  | "R03"
  | "R08"
  | "R09"
  | "R10"
  | "R16"
  | "R20"
  | "catchAll";

const textMap: Record<ReturnCode, string> = {
  R01: "The bank account on file did not have sufficient funds to cover the amount needed to process payroll. Here's what you can do to get this resolved as quickly as possible:",
  R02: "The bank account on file was closed when we tried to process payroll. After we fix the failed debit, we'll also need you to add a new, active bank account. Here's what you can do to get this resolved as quickly as possible:",
  R03: "We couldn't locate the bank account on file based on the information provided.  After we fix the failed debit, we will also need you to confirm the details for the bank account on file.\n\nDouble check the account details are correct in Justworks. If there are no errors to fix, please contact your bank to find out why we weren't able to locate your bank account based on the information on file. Here's what you can do to get this resolved as quickly as possible:",
  R08: "The bank account on file blocked the transaction. After we fix the failed debit, please work with your bank to ensure that Justworks (company ID 3462283648) will be authorized and able to debit your bank account going forward. Here's what you can do to get this resolved as quickly as possible:",
  R09: "The bank account on file didn't have sufficient funds to cover the debit. If you were expecting funds to come in which would increase your account balance to a sufficient level, those funds may not have been posted before we attempted to process payroll.",
  R10: "The bank was unaware that we were authorized to debit your bank account. After we fix the failed debit, be sure to to work with your bank to ensure that Justworks (company ID 3462283648) will be able to debit your company bank account in the future.",
  R16: "The bank account was frozen when we attempted the debit. After we fix the failed debit, you'll also need to work with your bank to have the account un-frozen, or add a new, valid bank account to your Justworks account.",
  R20: "The bank account was designated as a non-transaction account which limits or blocks debits entirely. After we fix the failed debit, you'll also need to work with your bank to ensure this account can receive debit requests going forward, or add a new, valid bank account to your Justworks account.",
  catchAll:
    "We were unable to debit your account to process payroll. After we take care of this payroll, please contact your bank to find out what happened. Here's what you can do to get this resolved as quickly as possible:",
};

export function NsfAlertMessage({ returnCode }: NsfAlertMessageProps) {
  const message = textMap[returnCode as ReturnCode] || textMap.catchAll;

  return <div className={BlueBox}>{message}</div>;
}
