import { ReactElement } from "react";
import NavBadge from "../nav-badge";
import styles from "./nav-item.module.css";
import { NavItem as NavigationItem } from "types";
import { buildWebPath } from "lib/resource-finder";
import { Link, useLocation, useParams } from "react-router-dom";
import { isHighlightedExpensesNavItem } from "./nav-item.utils";

const { navItem, highlighted } = styles;

export default function NavItem({
  displayText,
  path,
  badge,
  hasBadge,
  outstandingTaskCount,
}: NavigationItem): ReactElement {
  const { companyUuid } = useParams();
  const location = useLocation();
  const isClockfacePath = path.includes("/cf/");
  const isExternalPath = path.startsWith("http");
  const clockfaceRelativePath = path.replace(/^\/cf/, "");
  const resourcePath = companyUuid
    ? location.pathname.replace(`/company-uuid/${companyUuid}`, "")
    : location.pathname;
  const isExpensesPath = resourcePath.includes("/expenses");
  const isHighlighted = isExpensesPath
    ? isHighlightedExpensesNavItem(resourcePath, clockfaceRelativePath)
    : resourcePath.includes(clockfaceRelativePath.replace("_", "-"));
  let badgeContent = <></>;

  // note, this is intended to include a value of zero as falsy
  if (outstandingTaskCount) {
    const taskType = "outstandingTaskNotification";
    badgeContent = (
      <NavBadge
        badgeText={outstandingTaskCount}
        badgeType={taskType}
        isLongBadge={path === "/member_post_onboarding"}
      />
    );
  } else if (hasBadge && badge && badge.text) {
    badgeContent = <NavBadge badgeText={badge.text} badgeType={badge.type} />;
  }

  if (isClockfacePath) {
    return (
      <Link
        to={clockfaceRelativePath}
        className={isHighlighted ? `${navItem} ${highlighted}` : navItem}
      >
        {displayText}
        {badgeContent}
      </Link>
    );
  } else if (isExternalPath) {
    return (
      <a
        href={path}
        className={isHighlighted ? `${navItem} ${highlighted}` : navItem}
      >
        {displayText}
        {badgeContent}
      </a>
    );
  }

  return (
    <a
      href={buildWebPath(path)}
      className={isHighlighted ? `${navItem} ${highlighted}` : navItem}
    >
      {displayText}
      {badgeContent}
    </a>
  );
}
