import ImageCard from "pages/pmt/integrations/partner-setup-page/components/ImageCard/ImageCard";
import { Button } from "@justworkshr/milo-core";
import styles from "./JazzHRSetup.module.css";
import { StepperHelpers } from "pages/pmt/integrations/partner-setup-page/hooks/useStepper";
import { useState } from "react";
import { StatusMessage } from "./utils";
import useSetupPageData from "pages/pmt/integrations/partner-setup-page/hooks/useSetupPageData";
import useProcessIntegrationAction, {
  ActionData,
} from "../../../partner-setup-page/hooks/useProcessPmtIntegrationAction";
import { jazzHrConfig } from "../config";
const { wrapper, bold, cardText, stepOneCard } = styles;

type JazzHRSetupStepOneProps = StepperHelpers & {
  processPmtIntegrationAction: (
    integrationName: string,
    action: string
  ) => Promise<
    | {
        success: boolean;
        actionData: ActionData | null;
      }
    | undefined
  >;
  loading: boolean;
};

export const JazzHRSetupStepOneRenderer: React.FC<JazzHRSetupStepOneProps> = ({
  processPmtIntegrationAction,
  loading,
  ...stepperHelpers
}) => {
  const { canGoToNextStep, goToNextStep } = stepperHelpers;
  const { refetch } = useSetupPageData(
    "jazzhr",
    jazzHrConfig.copy.setup.instanceKeys,
    1,
    { skip: true }
  );
  const [integrationSetupFailed, setIntegrationSetupFailed] = useState(false);

  const handleGetStarted = () => {
    setIntegrationSetupFailed(false);
    processPmtIntegrationAction("jazzhr", "create").then(
      (processActionResponse) => {
        refetch();
        if (processActionResponse?.success && canGoToNextStep) {
          goToNextStep();
        } else {
          setIntegrationSetupFailed(true);
        }
      }
    );
  };

  return (
    <div className={stepOneCard}>
      <ImageCard header="Let’s get you up and running">
        <div className={wrapper}>
          <span className={cardText}>
            Before we get started, make sure you are in a{" "}
            <span className={bold}>Super Administrator</span>
            {", "}
            <span className={bold}>Recruiting Administrator</span>
            {", "} or <span className={bold}>Developer</span> role in JazzHR to
            set up the integration.
          </span>
          <Button
            onClick={handleGetStarted}
            loading={loading}
            disabled={loading}
          >
            Get started
          </Button>
          {integrationSetupFailed && (
            <StatusMessage
              integrationSetupSuccess={!integrationSetupFailed}
              currentStep={1}
            />
          )}
        </div>
      </ImageCard>
    </div>
  );
};

const JazzHRSetupStepOne: React.FC<StepperHelpers> = (
  stepperHelpers: StepperHelpers
) => {
  const { processPmtIntegrationAction, loading } =
    useProcessIntegrationAction();
  return (
    <JazzHRSetupStepOneRenderer
      processPmtIntegrationAction={processPmtIntegrationAction}
      loading={loading}
      {...stepperHelpers}
    />
  );
};

export default JazzHRSetupStepOne;
