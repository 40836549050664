import { Badge } from "@justworkshr/milo-core";
import { formatDate } from "pages/expenses/utils";
import { ReimbursementRequestStatus } from "types/generated/operations";
import { ReimbursementRequestForHistory } from "../../RequestHistoryPage.types";

export const renderHistoryStatusBadge = (
  request: ReimbursementRequestForHistory,
  permissions: { isAdmin: boolean; isManager: boolean }
) => {
  const { isAdmin, isManager } = permissions;
  if (request.status === ReimbursementRequestStatus.Returned) {
    return <Badge role="negative">Returned</Badge>;
  } else if (request.status === ReimbursementRequestStatus.Approved) {
    return "Approved";
  } else if (request.status === ReimbursementRequestStatus.ReadyForApproval) {
    if (!isAdmin && isManager)
      return <Badge role="warning">Admin review</Badge>;
  }
};

export const renderPayDate = (request: ReimbursementRequestForHistory) => {
  if (
    request.status === ReimbursementRequestStatus.Returned ||
    request.status === ReimbursementRequestStatus.ReadyForApproval
  ) {
    return <>&#8212;</>;
  } else if (request.payment?.status === "Canceled") {
    return "Canceled";
  } else if (
    request.status === ReimbursementRequestStatus.Approved &&
    request.payment?.payDate
  ) {
    return formatDate(request.payment.payDate);
  }
};
