export interface FileErrorMapping {
  [key: string]: string[];
}

export interface FileInputProps extends React.HTMLAttributes<HTMLDivElement> {
  /**  A list of accepted file types, according to the [HTML accept element](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept) */
  accept?: string;
  /** A function that runs on file change. It will be given the Javascript File object processed by FileInput. */
  onFileChange?: (file?: File | File[]) => void;
  /** A function that runs when removeFile is called. It will be given the Javascript File object processed by FileInput. */
  onFileRemove?: (fileName: string) => void;
  /** Disables the FileInput, applying styling and prohibiting functionality. */
  disabled?: boolean;
  /** A unique ID for the component. Will be randomly generated if not provided. */
  id?: string;
  /** Whether the FileInput can select multiple files. */
  multiple?: boolean;
  /** A hash map of validation feedback when processing multiple files. For each file name, render a list of strings as warning messages. Unused when `multiple` is false. */
  fileErrorMapping?: FileErrorMapping;
}

export interface FileInputState {
  dragActive: boolean;
  processingFile: boolean;
  completedProcessing: boolean;
  file: File[];
}

export interface FileInputAction {
  type: FileInputStateActionType;
  file?: File[];
  fileName?: string;
  multiple?: boolean;
}
export enum FileInputStateActionType {
  FILE_HOVER,
  FILE_UNHOVER,
  PROCESS_FILE,
  COMPLETE_PROCESSING,
  PROCESS_MULTIPLE_FILES,
  COMPLETE_MULTIPLE_PROCESSING,
  REMOVE_FILE,
}

export const initialState: FileInputState = {
  dragActive: false,
  processingFile: false,
  completedProcessing: false,
  file: [],
};
