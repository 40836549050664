import FaqItem from "../FaqItem";
import benefitsClasses from "pages/benefits/benefits.module.css";

const FaqTab = () => {
  return (
    <div>
      <FaqItem title="What's the maximum annual contribution?">
        <>
          <p className={benefitsClasses.benefitsParagraph}>
            The IRS has an annual contribution for anyone in a 401(k) plan.
            Justworks automatically caps your contribution to the IRS limit for
            the year, so you will not over contribute.
          </p>
        </>
      </FaqItem>

      <FaqItem
        title="Can I change my 401(k) contribution level? Can I contribute $0?"
        collapsed={false}
      >
        <p className={benefitsClasses.benefitsParagraph}>
          Yes. Once enrolled, you can change your contribution level at any
          time, including contributing $0.
        </p>
      </FaqItem>
    </div>
  );
};

export default FaqTab;
