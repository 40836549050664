import { FC, ReactNode } from "react";
import { Spinner, Step, Stepper } from "@justworkshr/milo-core";
import { AdditionalPayStepStructure } from "../../constants";
import styles from "./StepperLayout.module.css";
import { StepperStatus } from "../../types";

type Props = {
  step: number;
  loading: boolean;
  idVerificationStatus?: StepperStatus;
  children: ReactNode;
};

export const StepperLayout: FC<Props> = ({
  step,
  loading,

  children,
}) => {
  return (
    <>
      {loading && <Spinner data-testid="spinner" />}

      {!loading && (
        <>
          <div className={styles.wrapper}>
            <Stepper orientation="horizontal">
              {AdditionalPayStepStructure.map(
                ({ key, title, status }, index) => (
                  <Step
                    key={key}
                    title={title}
                    stepNumber={index + 1}
                    status={status(index + 1, step)}
                  />
                )
              )}
            </Stepper>
          </div>
          {children}
        </>
      )}
    </>
  );
};
