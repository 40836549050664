/* istanbul ignore file */
import { useEffect } from "react";

const MESSAGE = "Changes you made may not be saved.";

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  // For IE and Firefox
  if (event) {
    event.returnValue = MESSAGE;
  }
  // For Safari / chrome
  return MESSAGE;
};

export const useBeforeUnload = (data: boolean) => {
  useEffect(() => {
    if (data) {
      window.addEventListener("beforeunload", handleBeforeUnload);

      return () =>
        window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [data]);
};
