import ActionsMenuContent from "../ActionsMenuContent";
import { Dropdown } from "../../Dropdown";
import styles from "./ActionsMenuButton.module.css";
import { IconButton } from "@justworkshr/milo-core";
import { ReactElement, useState } from "react";

interface ActionsMenuButtonProps {
  documentId: string;
  deleteDocument: () => void;
  editDocument: () => void;
}

export default function ActionsMenuButton({
  documentId,
  deleteDocument,
  editDocument,
}: ActionsMenuButtonProps): ReactElement {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [pressed, setPressed] = useState(false);

  const closeDropdown = () => {
    setDropdownOpen(false);
    setPressed(false);
  };

  const dropdownContent = (
    <ActionsMenuContent
      documentId={documentId}
      deleteDocument={deleteDocument}
      editDocument={editDocument}
      close={closeDropdown}
    />
  );

  return (
    <Dropdown
      dropdownName="document actions"
      content={dropdownContent}
      className={styles.actionsDropdown}
      open={dropdownOpen}
      onOpenChange={(newOpen) => {
        if (newOpen) {
          setDropdownOpen(true);
        } else {
          closeDropdown();
        }
      }}
      onClickOutside={() => {
        closeDropdown();
      }}
    >
      <IconButton
        className={styles.actionsButton}
        variant={pressed ? "outlined" : "ghost"}
        onMouseDown={() => setPressed(true)}
        iconName="ellipsis"
        title=""
        size="sm"
        style={{ borderRadius: "50%" }}
      />
    </Dropdown>
  );
}
