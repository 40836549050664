import { AlertType } from "./context/AlertContext";

export const INTERNATIONAL_CONTRACTOR_MEMBER_DETAIL_PATH = "/members";
export const INTERNATIONAL_UPLOAD_DOCUMENT =
  "/international/contractor/document/create";

export const SUBPATHS = {
  PERSONAL_INFO: "personal-info",
  DOCUMENTS: "documents",
};

export const MEMBER_STATUS = {
  ACTIVE: "active",
  TERMINATED: "terminated",
  TERMINATED_HIDDEN: "terminated_hidden",
};

export const ALERT_COLOR: { [key: string]: AlertType["color"] } = {
  ADDITIVE: "additive",
  DESTRUCTIVE: "destructive",
  DISABLED: "disabled",
  INFO: "info",
  NEW: "new",
  WARNING: "warning",
};
