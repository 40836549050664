import React, { useContext } from "react";
import { CheckboxInput, InputMessage } from "@justworkshr/milo-form";
import styles from "./Acknowledgment.module.css";
import { ADDITIONAL_PAY_FORM_ROUTES } from "pages/employer-of-record/payments/constants";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";

type AcknowledgementProps = {
  formType: string;
  disclaimersChecked: (value: boolean) => void;
};

const Acknowledgement: React.FC<AcknowledgementProps> = ({
  formType,
  disclaimersChecked,
}) => {
  const additionalPayContext = useContext(AdditionalPayContext);
  const [disclaimerCheckBoxChecked, setDisclaimerCheckBoxChecked] =
    React.useState<boolean>(false);
  const [adminApprovalChecked, setAdminApprovalChecked] =
    React.useState<boolean>(false);
  const [expenseDutiesChecked, setExpenseDutiesChecked] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    const isDisabled =
      formType === ADDITIONAL_PAY_FORM_ROUTES.REIMBURSEMENT &&
      (!disclaimerCheckBoxChecked ||
        !adminApprovalChecked ||
        !expenseDutiesChecked);
    disclaimersChecked(isDisabled);
  }, [
    disclaimerCheckBoxChecked,
    adminApprovalChecked,
    expenseDutiesChecked,
    formType,
    disclaimersChecked,
  ]);

  const disclaimerCheckboxHandler = () => {
    setDisclaimerCheckBoxChecked(!disclaimerCheckBoxChecked);
  };

  const expenseDutiesCheckboxHandler = () => {
    setExpenseDutiesChecked(!expenseDutiesChecked);
  };

  const adminApprovalCheckboxHandler = () => {
    setAdminApprovalChecked(!adminApprovalChecked);
  };

  return (
    <div className={`${styles.acknowledgementBox} ${styles.card}`}>
      <b>Acknowledgement</b>

      <CheckboxInput
        checked={disclaimerCheckBoxChecked}
        onChange={disclaimerCheckboxHandler}
        name="disclaimerCheckbox"
        value={"disclaimer"}
        required
        label="I confirm the reimbursement is not for any of the following:"
      />
      <ul className={styles.list}>
        <li>Personal expenses not directly related to business activities</li>
        <li>Fines, penalties, or legal fees resulting from non-compliance</li>
        <li>
          Alcoholic beverages, except when pre-approved for client entertainment
          purposes
        </li>
      </ul>
      {additionalPayContext?.errorMessage && !disclaimerCheckBoxChecked && (
        <InputMessage type="error">
          You must acknowledge the terms to continue.
        </InputMessage>
      )}
      <div className={styles.checkboxPadding}>
        <CheckboxInput
          checked={expenseDutiesChecked}
          onChange={expenseDutiesCheckboxHandler}
          required
          className={styles.checkboxPadding}
          name="expenseDutiesApprovalCheckbox"
          value={"expenseDutiesApproved"}
          label="I confirm that these expenses were necessarily, wholly, and exclusively incurred in the performance of the employee’s duties."
        />
        {additionalPayContext?.errorMessage && !expenseDutiesChecked && (
          <InputMessage type="error">
            You must acknowledge the terms to continue.
          </InputMessage>
        )}
      </div>

      <div className={styles.checkboxPadding}>
        <CheckboxInput
          checked={adminApprovalChecked}
          onChange={adminApprovalCheckboxHandler}
          required
          name="adminApprovalCheckbox"
          value={"adminApproved"}
          label="I confirm that this expense reimbursement has complied with the appropriate approval process."
        />
        {additionalPayContext?.errorMessage && !adminApprovalChecked && (
          <InputMessage type="error">
            You must acknowledge the terms to continue.
          </InputMessage>
        )}
      </div>
    </div>
  );
};

export default Acknowledgement;
