import { IntegrationsConfig } from "types/IntegrationConfig";
import { HR_PERMISSIONS } from "pages/pmt/integrations/integration-marketplace/constants";
import greenhouseLogo from "pages/pmt/integrations/images/integration-partner-logos/greenhouse-logo.svg";

// Greenhouse uses a separate PDP and setup flow. Only the marketplaceCard is used from this file.
export const greenhouseConfig: IntegrationsConfig = {
  numAllowedInstances: 1,
  copy: {
    marketplaceCard: {
      logoUrl: greenhouseLogo,
      integrationName: "Greenhouse",
      integrationDescription: "Add new hires from Greenhouse into Justworks",
      integrationCategory: "Hiring",
      requiredPermissions: HR_PERMISSIONS,
      slug: "greenhouse",
      pdpUrl: "/integrations/greenhouse",
    },
    pdp: {
      headerDescription: "",
      heroImageUrl: "",
      horizontalLogoUrl: "",
      resourceCard: {
        links: [],
      },
      infoTabsConfig: {},
      infoTabsContent: {
        overviewTab: {
          header: "",
          subheader: "",
          list: [
            {
              summary: "",
              detail: "",
            },
          ],
        },
      },
      disconnectModalContent: {
        title: "",
        body: <div></div>,
        disconnectButtonText: "",
      },
      allowedPdpActions: [],
    },
    setup: {
      estimatedSetupTime: "",
      helpCenterLink: "",
      stepperConfig: {},
      steps: [],
      instanceKeys: [],
      getCurrentStepFunction: () => 0,
    },
  },
};
