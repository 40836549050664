import {
  FieldDataType,
  FieldTypes,
} from "pages/employer-of-record/components/AutoForm";

export const PaymentInfoAutoFormFields: Record<string, FieldDataType> = {
  accountNickname: {
    type: FieldTypes.text,
    name: "accountNickname",
    // t("Account Nickname")
    label: "Account Nickname",
  },
  accountNumber: {
    type: FieldTypes.text,
    name: "accountNumber",
    // t("Account Number")
    label: "Account Number",
    validations: {
      required: {
        enabled: true,
        message: "validations.required.message",
      },
      text: {
        number: {
          enabled: true,
          message: "validations.text.number.message",
        },
      },
    },
  },
  accountType: {
    type: FieldTypes.select,
    name: "accountType",
    label: "Account Type",
    options: [],
    validations: {
      required: {
        enabled: true,
        message: "validations.required.message",
      },
    },
  },
  bankCode: {
    type: FieldTypes.text,
    name: "bankCode",
    // t("Bank Code")
    label: "Bank Code",
    validations: {
      required: {
        enabled: true,
        // t("validations.required.message")
        message: "validations.required.message",
      },
      text: {
        number: {
          enabled: true,
          // t("validations.text.number.message")
          message: "validations.text.number.message",
        },
      },
    },
  },
  bankName: {
    type: FieldTypes.text,
    name: "bankName",
    // t("Bank Name")
    label: "Bank Name",
    validations: {
      required: {
        enabled: true,
        // t("validations.required.message")
        message: "validations.required.message",
      },
    },
  },
  branchCode: {
    type: FieldTypes.text,
    name: "branchCode",
    label: "Branch Code",
    validations: {
      required: {
        enabled: true,
        // t("validations.required.message")
        message: "validations.required.message",
      },
    },
  },
  bsbNumber: {
    type: FieldTypes.text,
    name: "bsbNumber",
    label: "BSB",
    validations: {
      required: {
        enabled: true,
        // t("validations.required.message")
        message: "validations.required.message",
      },
      text: {
        length: {
          value: 6,
          // t("validations.text.length.message")
          message: "validations.text.length.message",
        },
      },
    },
  },
  clabe: {
    type: FieldTypes.text,
    name: "clabe",
    label: "CLABE",
    validations: {
      required: {
        enabled: true,
        // t("validations.required.message")
        message: "validations.required.message",
      },
      text: {
        length: {
          value: 18,
          // t("validations.text.length.message")
          message: "validations.text.length.message",
        },
        number: {
          enabled: true,
          // t("validations.text.number.message")
          message: "validations.text.number.message",
        },
      },
    },
  },
  iban: {
    type: FieldTypes.text,
    name: "iban",
    label: "IBAN",
    validations: {
      required: {
        enabled: true,
        // t("validations.required.message")
        message: "validations.required.message",
      },
      text: {
        min: {
          value: 15,
          // t("validations.text.min.message")
          message: "validations.text.min.message",
        },
        max: {
          value: 34,
          // t("validations.text.max.message")
          message: "validations.text.max.message",
        },
      },
    },
  },
  ifscCode: {
    type: FieldTypes.text,
    name: "ifscCode",
    label: "IFSC",
    validations: {
      required: {
        enabled: true,
        // t("validations.required.message")
        message: "validations.required.message",
      },
      text: {
        length: {
          value: 11,
          // t("validations.text.length.message")
          message: "validations.text.length.message",
        },
      },
    },
  },
  institutionNumber: {
    type: FieldTypes.text,
    name: "institutionNumber",
    // t("Institution Number")
    label: "Institution Number",
    validations: {
      required: {
        enabled: true,
        // t("validations.required.message")
        message: "validations.required.message",
      },
      text: {
        length: {
          value: 3,
          // t("validations.text.length.message")
          message: "validations.text.length.message",
        },
        number: {
          enabled: true,
          // t("validations.text.number.message")
          message: "validations.text.number.message",
        },
      },
    },
  },
  sortCode: {
    type: FieldTypes.text,
    name: "sortCode",
    // t("Sort Code")
    label: "Sort Code",
    validations: {
      required: {
        enabled: true,
        message: "validations.required.message",
      },
      text: {
        sortCode: {
          enabled: true,
          // t("validations.text.sortCode.message")
          message: "validations.text.sortCode.message",
        },
      },
    },
    compact: (value: string) => {
      return value.replace(/\D/g, "");
    },
    format: (value: string) => {
      return ((value.match(/.{1,2}/g) as string[]) || []).join("-");
    },
  },
  swiftCode: {
    type: FieldTypes.text,
    name: "swiftCode",
    label: "SWIFT Code",
    validations: {
      required: {
        enabled: true,
        // t("validations.required.message")
        message: "validations.required.message",
      },
      text: {
        // t("validations.text.max.message")
        max: { value: 11, message: "validations.text.max.message" },
        // t("validations.text.min.message")
        min: { value: 8, message: "validations.text.min.message" },
      },
    },
  },
  transitNumber: {
    type: FieldTypes.text,
    name: "transitNumber",
    // t("Transit Number")
    label: "Transit Number",
    validations: {
      required: {
        enabled: true,
        message: "validations.required.message",
      },
      text: {
        length: {
          value: 5,
          message: "validations.text.length.message",
        },
        number: {
          enabled: true,
          message: "validations.text.number.message",
        },
      },
    },
  },
};
