import { AlertTypes } from "pages/expenses/constants";

export const getAlertColor = (type?: AlertTypes) => {
  switch (type) {
    case AlertTypes.SUCCESS:
      return "additive";
    case AlertTypes.ERROR:
      return "destructive";
    case AlertTypes.INFO:
      return "info";
    case AlertTypes.WARNING:
      return "warning";
    default:
      return "info";
  }
};
