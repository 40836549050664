import styles from "../../CategoriesPage.module.css";

import { CategoriesCardRow } from "../CategoriesCardRow/CategoriesCardRow";

import { useCategoriesStore } from "pages/expenses/store";
import { Notice, NoticeTypes, SettingsTable } from "pages/expenses/components";
import { Button, Card } from "@justworkshr/milo-core";
import { CATEGORY_CUSTOM_DESCRIPTION } from "../../CatagoriesPage.constants";

const { CategoriesCardWrapper, CustomCategoriesCardNotice } = styles;

interface CustomCategoriesCardProps {
  handleCustomCategoryModal: () => void;
}

export const CustomCategoriesCard: React.FC<CustomCategoriesCardProps> = ({
  handleCustomCategoryModal,
}) => {
  const {
    categories: { customCategories },
  } = useCategoriesStore();

  const renderCustomCategories = () => {
    if (customCategories.length === 0) {
      return (
        <Notice className={CustomCategoriesCardNotice} type={NoticeTypes.INFO}>
          You haven&#39;t created any custom categories yet. You can{" "}
          <strong>Add a custom category</strong> below.
        </Notice>
      );
    }

    return (
      <SettingsTable headers={["Active", "Category", ""]}>
        {customCategories.map((category) => (
          <CategoriesCardRow
            key={category.name}
            category={category}
            handleCustomCategoryModal={handleCustomCategoryModal}
          />
        ))}
      </SettingsTable>
    );
  };

  return (
    <Card
      title="Custom"
      className={CategoriesCardWrapper}
      description={CATEGORY_CUSTOM_DESCRIPTION}
      actions={[
        <Button
          variant="ghost"
          leftIcon="plus-circle"
          onClick={handleCustomCategoryModal}
          data-testid="add-custom-category-button"
        >
          Add a custom category
        </Button>,
      ]}
    >
      {renderCustomCategories()}
    </Card>
  );
};

export default CustomCategoriesCard;
