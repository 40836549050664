import { buildWebPath } from "lib/resource-finder";
import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIsOnboardingCheck } from "./useIsOnboardingCheck";

export interface BankFormData {
  accountNumber: string;
  routingNumber: string;
  bankName: string;
}

export interface BankFormContextValue {
  formData: BankFormData;
  setFormData: React.Dispatch<React.SetStateAction<BankFormData>>;
  showGlobalNetworkError?: boolean;
  setShowGlobalNetworkError?: (value: boolean) => void;
  isOnboarding: boolean;
  isLoadingOnboardingCheck: boolean;
  returnToFormPage: () => void;
  backRoute?: string;
  acceptedTerms: boolean;
  acceptTerms: () => void;
}

const initialData: BankFormData = {
  accountNumber: "",
  routingNumber: "",
  bankName: "",
};

export const defaultBankFormContext: BankFormContextValue = {
  formData: initialData,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFormData: () => {},
  showGlobalNetworkError: false,
  isOnboarding: false,
  isLoadingOnboardingCheck: true,
  returnToFormPage: () => {},
  acceptedTerms: false,
  acceptTerms: () => {},
};

export const BankFormContext = createContext<BankFormContextValue>(
  defaultBankFormContext
);

export const useBankForm = () => useContext(BankFormContext);

interface BankFormProviderProps {
  children: ReactNode;
}

export const BankFormProvider = ({ children }: BankFormProviderProps) => {
  const { isLoading, data } = useIsOnboardingCheck();
  const [formData, setFormData] = useState(initialData);
  const [acceptedTerms, setAcceptTerms] = useState(false);
  const [showGlobalNetworkError, setShowGlobalNetworkError] =
    useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setShowGlobalNetworkError(false);
  }, [location.pathname]);

  const returnToFormPage = useCallback(() => {
    navigate("/company-settings/edit-bank-account", {
      state: location.state,
    });
    window.scrollTo(0, 0);
  }, [navigate, location.state]);

  const backRoute = buildWebPath(
    data?.is_onboarding ? "/onboarding_checklist" : "/company"
  );

  const acceptTerms = () => {
    returnToFormPage();
    setAcceptTerms(true);
  };

  const value = {
    formData,
    setFormData,
    showGlobalNetworkError,
    setShowGlobalNetworkError,
    isOnboarding: data?.is_onboarding ?? false,
    isLoadingOnboardingCheck: isLoading,
    backRoute,
    returnToFormPage,
    acceptedTerms,
    acceptTerms,
  };

  return (
    <BankFormContext.Provider value={value}>
      {children}
    </BankFormContext.Provider>
  );
};
