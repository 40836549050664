import { datadogRum } from "@datadog/browser-rum";
import { flagIsEnabled, getEnvironmentName, FLAG_NAMES } from "lib/environment";

export function initializeDataDog(): void {
  if (flagIsEnabled(FLAG_NAMES.DATADOG_RUM_ENABLED)) {
    const environmentName = getEnvironmentName();

    datadogRum.init({
      applicationId: "d667d7ff-da98-42e5-81bf-2a7df275f419",
      clientToken: "pub32dea9ce5d1ba1ec12c7d5f6d910e094",
      site: "datadoghq.com",
      service: "clockface",
      env: environmentName,
      version: "1.0.0",
      sampleRate: 100,
      trackInteractions: true,
      trackFrustrations: true,
      defaultPrivacyLevel: "mask-user-input",
    });
  }
}
