import { useNavigate } from "react-router-dom";
import {
  useActivateExpensesMutation,
  useCreateExpensePolicyMutation,
} from "types/generated/operations";
import { prependBasePath } from "pages/expenses/constants";
import { useGlobalConfigurationStore } from "pages/expenses/store";

export const useActivateExpenses = () => {
  const navigate = useNavigate();
  const { updatePermissions } = useGlobalConfigurationStore();

  const [initialExpensePolicySetup] = useCreateExpensePolicyMutation({
    onCompleted: (dataInitialExpensePolicySetup) => {
      if (dataInitialExpensePolicySetup?.updateExpensePolicy?.success) {
        updatePermissions({
          isRequiredToViewProductDetailsPage: false,
        });

        navigate(prependBasePath("SETTINGS"));
      }
    },
  });

  const [activateExpenses, { loading: isActivateExpensesLoading }] =
    useActivateExpensesMutation({
      onCompleted: (activateExpensesData) => {
        if (activateExpensesData?.setCompanySetting?.success) {
          initialExpensePolicySetup();
        }
      },
    });

  return {
    activateExpenses,
    isActivateExpensesLoading,
  };
};
