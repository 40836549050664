import * as Yup from "yup";
import { bonusPayloadSchema } from "./components/Setup/components/EmployeePay/BonusPay/validationSchemas";
import { comissionPayloadSchema } from "./components/Setup/components/EmployeePay/Comission/validationSchemas";
import { paymentValidationSchema } from "./components/PaymentDetails/validationSchemas";
import { expensePayloadSchema } from "./components/Setup/components/Reimbursement/validationSchemas";
import { EmployeePayType } from "./constants";
import { benefitsAndAllowancesSchema } from "./components/Setup/components/TaxableBenefitsAndAllowances/validationSchemas";
import { offcyclePayloadSchema } from "./components/Setup/components/EmployeePay/OffCycle";
import { retroactivePayloadSchema } from "./components/Setup/components/EmployeePay/Retroactive";

export const payFormValidationSchema = Yup.object().shape({
  setUp: Yup.object().shape({
    workCountry: Yup.string().required("Work country is required"),
    type: Yup.string()
      .oneOf(Object.values(EmployeePayType), "Invalid pay type")
      .required("Pay type is required"),
    payload: Yup.lazy((_, { context }) => {
      switch (context.setUp.type) {
        case EmployeePayType.BONUS:
          return bonusPayloadSchema;
        case EmployeePayType.COMISSION:
          return comissionPayloadSchema;
        case EmployeePayType.RETROACTIVE:
          return retroactivePayloadSchema;
        case EmployeePayType.OFF_CYCLE:
          return offcyclePayloadSchema;
        case "":
        default:
          return Yup.object();
      }
    }),
  }),
  paymentDetails: paymentValidationSchema,
});

export const expenseFormValidationSchema = Yup.object().shape({
  setUp: Yup.object().shape({
    workCountry: Yup.string().required("Work country is required"),
    expenseType: Yup.string().required("Expense type is required"),
    payload: expensePayloadSchema,
  }),
  paymentDetails: paymentValidationSchema,
});

export const benefitsAndAllowancesFormValidationSchema = Yup.object().shape({
  setUp: Yup.object().shape({
    workCountry: Yup.string().required("Work country is required"),
    benefitType: Yup.string().required("Benefit type is required"),
    payload: benefitsAndAllowancesSchema,
  }),
  paymentDetails: paymentValidationSchema,
});
