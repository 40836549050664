import {
  ReactNode,
  createContext,
  FC,
  useState,
  useRef,
  useEffect,
} from "react";
import { buildClockworkWebPath } from "lib/resource-finder";
import { usePeopleAnalyticsCapabilities } from "../../hooks";
import { logVisierEvent } from "../../utils";
import styles from "./visier-event-handler.module.css";

interface VisierEventHandlerContextValue {
  visierSessionEstablished: boolean | undefined;
}

export const VisierEventHandlerContext =
  createContext<VisierEventHandlerContextValue>({
    visierSessionEstablished: undefined,
  });

interface VisierEventHandlerProps {
  children: ReactNode;
}

const VisierEventHandler: FC<VisierEventHandlerProps> = ({ children }) => {
  const { currentMemberUuid } = usePeopleAnalyticsCapabilities();
  const [visierSessionEstablished, setVisierSessionEstablished] =
    useState<boolean>(false);
  const visierSessionKeepAliveInterval = useRef<NodeJS.Timeout>();

  useEffect(() => {
    /**
     * Logging requires currentMemberUuid so if it isn't present yet, return
     * early and do all this set up once it is available.
     */
    if (currentMemberUuid === undefined) return;

    /**
     * Set up an event listener on the window to receive SAML_UNAUTHORIZED
     * messages in the case where the clockwork session expires.
     */
    const samlUnauthorizedListener = (event: MessageEvent) => {
      if (
        event.origin === window.location.origin &&
        event.data === "SAML_UNAUTHORIZED"
      ) {
        const loginRedirectPath = buildClockworkWebPath(
          "_login?redirect=" + encodeURIComponent(window.location.href)
        );

        window.location.replace(loginRedirectPath);
      }
    };

    window.addEventListener("message", samlUnauthorizedListener);

    /**
     * Per Visier documentation, instantiate the window.visier function before
     * fetching Visier SDK. It looks like they use window.visier.q to hold on to
     * args that might be passed in before the actual function is loaded.
     */
    /* eslint-disable @typescript-eslint/no-explicit-any */
    (window as any)["visier"] ||= (...args: any) => {
      (window as any)["visier"].q ||= [];
      (window as any)["visier"].q.push(args);
    };

    /**
     * Create and mount <script> to fetch Visier SDK. On <script> load, start
     * Visier session and set state variable visierSessionEstablished = true.
     */
    const VISIER_SDK_SCRIPT_ID = "visier-sdk-script";
    if (document.getElementById(VISIER_SDK_SCRIPT_ID)) return;

    const script = document.createElement("script");
    script.src =
      "https://justworks.visier.com/hr/assets/embedded/webAssets/sdk.v2.js";
    script.id = VISIER_SDK_SCRIPT_ID;
    script.async = true;

    script.onload = () => {
      logVisierEvent({
        eventType: "call",
        message: "bootstrap",
        currentMemberUuid,
      });

      (window as any)["visier"]("bootstrap", {
        idpUrl: buildClockworkWebPath("/people_analytics/saml"),
        startSession: true,
        requestTimeout: 10000,
      });

      (window as any)["visier"]("on", "session", (message: any) => {
        logVisierEvent({
          eventType: "session",
          message,
          currentMemberUuid,
        });

        switch (message?.code?.toUpperCase()) {
          case "SESSION_ESTABLISHED":
            setVisierSessionEstablished(true);

            visierSessionKeepAliveInterval.current = setInterval(() => {
              logVisierEvent({
                eventType: "trigger",
                message: "PARENT_SESSION_ALIVE",
                currentMemberUuid,
              });

              (window as any)["visier"]("trigger", "PARENT_SESSION_ALIVE");
            }, 600000);

            break;
          default:
        }
      });

      (window as any)["visier"]("on", "error", (message: any) => {
        logVisierEvent({
          eventType: "error",
          message,
          currentMemberUuid,
        });
      });

      (window as any)["visier"]("on", "debug", (message: any) => {
        logVisierEvent({
          eventType: "debug",
          message,
          currentMemberUuid,
        });
      });
    };
    /* eslint-enable @typescript-eslint/no-explicit-any */

    document.body.appendChild(script);

    /**
     * When component unmounts, clean up event listener, <script>, and interval.
     */
    return () => {
      window.removeEventListener("message", samlUnauthorizedListener);
      document.body.removeChild(script);
      clearInterval(visierSessionKeepAliveInterval.current);
    };
  }, [currentMemberUuid]);

  return (
    <div
      className={styles.visierEventHandler}
      data-testid="visier-event-handler"
    >
      <VisierEventHandlerContext.Provider
        value={{
          visierSessionEstablished,
        }}
      >
        {children}
      </VisierEventHandlerContext.Provider>
    </div>
  );
};

export default VisierEventHandler;
