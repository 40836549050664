import { Box } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import styles from "./ResourceCard.module.css";
import { Link } from "react-router-dom";
import { LINKS } from "../../constants/pdpGeneral";

const { resourceCardHeader, resourceCardContent, resourceCardLinks } = styles;

export type benefitType = "ltd" | "std" | "basicLifeAndADD";

type ResourceProps = {
  selectedBenefit: benefitType;
};

type ResourceData = {
  [key: string]: string;
};

export const ResourceCard: React.FC<ResourceProps> = ({ selectedBenefit }) => {
  const resourceData: ResourceData[] = LINKS.resourcePages[selectedBenefit];

  return (
    <Box
      padding="2xl"
      border={{
        borderWidth: "sm",
        borderColor: "neutralSubtle",
        borderRadius: "md",
      }}
    >
      <div className={resourceCardHeader}>Resources</div>
      <div className={resourceCardContent}>
        {Object.values(resourceData).map((resourceInfo, index) => (
          <div key={index + 1}>
            <Link
              className={resourceCardLinks}
              to={resourceInfo.resourcePage}
              target="_blank"
              rel="noopener noreferrer"
              key={index + 1}
            >
              {resourceInfo.resourceLabel}
              <SystemIcon iconName="external-link" color="brand" />
            </Link>
          </div>
        ))}
        <Link
          className={resourceCardLinks}
          target="_blank"
          rel="noopener noreferrer"
          to={LINKS.metlife}
          key="metlife"
        >
          Metlife
          <SystemIcon iconName="external-link" color="brand" />
        </Link>
      </div>
    </Box>
  );
};
