import { useState } from "react";
import { Button } from "@justworkshr/milo-core";
import styles from "./CopyButton.module.css";

const { copied } = styles;

interface CopyButtonProps {
  copyText: string;
  disabled?: boolean;
}

function CopyButton({ copyText, disabled = false }: CopyButtonProps) {
  const COPY_TEXT = "Copy";
  const COPIED_TEXT = "Copied";
  const [copyState, setCopyState] = useState(false);

  const copyToClipboard = (text: string) => {
    setCopyState(true);
    setTimeout(() => {
      setCopyState(false);
    }, 10000);
    navigator.clipboard.writeText(text);
  };

  return (
    <Button
      className={copyState ? copied : ""}
      type="button"
      variant="outlined"
      disabled={disabled}
      onClick={() => copyToClipboard(copyText)}
    >
      {copyState ? COPIED_TEXT : COPY_TEXT}
    </Button>
  );
}

export default CopyButton;
