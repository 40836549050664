import React from "react";

import styles from "../landing-page.module.css";
import SearchListCard from "./search-list-card";
import { GetEligibleClaimsMembersByDateQuery } from "types/generated/operations";
import { Spinner } from "@justworkshr/milo-core";

type MembersListType =
  GetEligibleClaimsMembersByDateQuery["eligibleClaimsMembersByDate"];

interface ISearchListProps {
  members: MembersListType;
  loading: boolean;
  onSelectItem: (member: MembersListType[0]) => void;
}

export default function SearchList({ ...props }: ISearchListProps) {
  const { members, onSelectItem, loading } = props;

  return (
    <div className={styles.searchListWrapper}>
      {loading && (
        <div className={styles.loadingWrappeer}>
          <Spinner />
        </div>
      )}

      {members.map((member) => (
        <button key={member.uuid} onClick={() => onSelectItem(member)}>
          <SearchListCard member={member} />
        </button>
      ))}
    </div>
  );
}
