import { FieldDataType } from "pages/employer-of-record/components/AutoForm";
import {
  addValidation,
  addOptions,
} from "../../../components/AutoForm/field-utils";
import { PaymentInfoAutoFormFields } from "./payment-info-autoform-fields";

type BankAccountTypeOption = {
  value: string;
  description: string;
};

export const BankAccountTypeOptions: Record<string, BankAccountTypeOption[]> = {
  AU: [
    {
      value: "transaction",
      description: "Current (Transaction)",
    },
    {
      value: "savings",
      description: "Savings (Savings)",
    },
  ],
  BR: [
    {
      value: "corrente",
      description: "Current (Corrente)",
    },
    {
      value: "poupança",
      description: "Savings (Poupança)",
    },
  ],
  CA: [
    {
      value: "chequing",
      description: "Chequing",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  CL: [
    {
      value: "corriente",
      description: "Chequing (Corriente)",
    },
    {
      value: "ahorro",
      description: "Savings (Ahorro)",
    },
  ],
  CO: [
    {
      value: "corriente",
      description: "Current (Corriente)",
    },
    {
      value: "ahorros",
      description: "Savings (Ahorros)",
    },
  ],
  CR: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  DE: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  DK: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  ES: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  FR: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  GB: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  IE: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  IN: [
    {
      value: "चालू खाता",
      description: "Current (चालू खाता)",
    },
    {
      value: "बचत खाता",
      description: "Savings (बचत खाता)",
    },
  ],
  MX: [
    {
      value: "current",
      description: "Current",
    },
  ],
  MY: [
    {
      value: "semasa",
      description: "Current (Akaun Semasa)",
    },
    {
      value: "simpanan",
      description: "Savings (Simpanan)",
    },
  ],
  NL: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  PH: [
    {
      value: "tsekehan",
      description: "Current (Tsekehan)",
    },
    {
      value: "iponan",
      description: "Savings (Iponan)",
    },
  ],
  PT: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  SE: [
    {
      value: "current",
      description: "Current",
    },
    {
      value: "savings",
      description: "Savings",
    },
  ],
  SG: [
    {
      value: "流动账户",
      description: "Current (流动账户)",
    },
    {
      value: "储蓄账户",
      description: "Savings (储蓄账户)",
    },
  ],
};

const IBANAccountFormFields = (accountTypeOptions: BankAccountTypeOption[]) => [
  PaymentInfoAutoFormFields["iban"],
  addOptions(PaymentInfoAutoFormFields["accountType"], accountTypeOptions),
  PaymentInfoAutoFormFields["accountNickname"],
];

export const PaymentInfoAutoFormConfig: Record<string, FieldDataType[]> = {
  AU: [
    PaymentInfoAutoFormFields["bankName"],
    PaymentInfoAutoFormFields["bsbNumber"],
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 9, message: "validations.text.max.message" },
        // t("validations.text.min.message")
        min: { value: 4, message: "validations.text.min.message" },
      },
    }),
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.AU
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  BR: [
    addValidation(PaymentInfoAutoFormFields["bankCode"], {
      text: {
        length: {
          value: 3,
          // t("validations.text.length.message")
          message: "validations.text.length.message",
        },
      },
    }),
    addValidation(PaymentInfoAutoFormFields["branchCode"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 5, message: "validations.text.max.message" },
        // t("validations.text.min.message")
        min: { value: 4, message: "validations.text.min.message" },
      },
    }),
    PaymentInfoAutoFormFields["accountNumber"],
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.BR
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  CA: [
    PaymentInfoAutoFormFields["institutionNumber"],
    PaymentInfoAutoFormFields["transitNumber"],
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 12, message: "validations.text.max.message" },
        // t("validations.text.min.message")
        min: { value: 6, message: "validations.text.min.message" },
      },
    }),
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.CA
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  CL: [
    addValidation(PaymentInfoAutoFormFields["bankCode"], {
      text: {
        length: {
          value: 3,
          // t("validations.text.length.message")
          message: "validations.text.length.message",
        },
      },
    }),
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 25, message: "validations.text.max.message" },
        // t("validations.text.min.message")
        min: { value: 5, message: "validations.text.min.message" },
      },
    }),
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.CL
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  CO: [
    PaymentInfoAutoFormFields["accountNumber"],
    PaymentInfoAutoFormFields["bankName"],
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.CO
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  CR: IBANAccountFormFields(BankAccountTypeOptions.CR),
  DE: IBANAccountFormFields(BankAccountTypeOptions.DE),
  DK: IBANAccountFormFields(BankAccountTypeOptions.DK),
  ES: IBANAccountFormFields(BankAccountTypeOptions.ES),
  FR: IBANAccountFormFields(BankAccountTypeOptions.FR),
  GB: [
    PaymentInfoAutoFormFields["sortCode"],
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        max: { value: 8, message: "validations.text.max.message" },
      },
    }),
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.GB
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  IE: IBANAccountFormFields(BankAccountTypeOptions.IE),
  IN: [
    PaymentInfoAutoFormFields["ifscCode"],
    PaymentInfoAutoFormFields["accountNumber"],
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.IN
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  MX: [
    PaymentInfoAutoFormFields["bankName"],
    PaymentInfoAutoFormFields["clabe"],
    PaymentInfoAutoFormFields["accountNumber"],
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.MX
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  MY: [
    PaymentInfoAutoFormFields["swiftCode"],
    PaymentInfoAutoFormFields["bankName"],
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 17, message: "validations.text.max.message" },
        // t("validations.text.min.message")
        min: { value: 5, message: "validations.text.min.message" },
      },
    }),
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.MY
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  NL: IBANAccountFormFields(BankAccountTypeOptions.NL),
  PH: [
    PaymentInfoAutoFormFields["swiftCode"],
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 17, message: "validations.text.max.message" },
      },
    }),
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.PH
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
  PT: IBANAccountFormFields(BankAccountTypeOptions.PT),
  SE: IBANAccountFormFields(BankAccountTypeOptions.SE),
  SG: [
    addValidation(PaymentInfoAutoFormFields["bankCode"], {
      length: {
        value: 4,
        // t("validations.text.length.message")
        message: "validations.text.length.message",
      },
    }),
    addValidation(PaymentInfoAutoFormFields["branchCode"], {
      length: {
        value: 3,
        // t("validations.text.length.message")
        message: "validations.text.length.message",
      },
    }),
    addValidation(PaymentInfoAutoFormFields["accountNumber"], {
      text: {
        // t("validations.text.max.message")
        max: { value: 19, message: "validations.text.max.message" },
        // t("validations.text.min.message")
        min: { value: 6, message: "validations.text.min.message" },
      },
    }),
    addOptions(
      PaymentInfoAutoFormFields["accountType"],
      BankAccountTypeOptions.BR
    ),
    PaymentInfoAutoFormFields["accountNickname"],
  ],
};
