import { useRequestDetail } from "pages/expenses/store";
import { RequestDetailsStoreType } from "pages/expenses/store/RequestDetailsStore/constants";
import { DeepPartial } from "pages/expenses/types";
import { useEffect } from "react";
import { Review, useGetRequestDetailQuery } from "types/generated/operations";
import merge from "lodash/merge";

interface UseRequestDetailPageProps {
  uuid: string;
  additionalConfiguration?: DeepPartial<RequestDetailsStoreType>;
}

export const useRequestDetailPage = ({
  uuid,
  additionalConfiguration,
}: UseRequestDetailPageProps) => {
  const { setRequestDetail } = useRequestDetail();
  const { loading: isRequestDetailLoading, data: requestDetailData } =
    useGetRequestDetailQuery({
      variables: {
        uuid,
      },
    });

  useEffect(() => {
    if (
      !isRequestDetailLoading &&
      requestDetailData?.expenseManagement?.request
    ) {
      const reviews =
        requestDetailData?.expenseManagement?.request?.reviews.reduce(
          (acc, review) => {
            if (review.type === Review.Return) {
              acc.returned.push(review);
            } else if (review.type === Review.Review) {
              acc.reviewed.push(review);
            } else if (review.type === Review.Approve) {
              acc.approved.push(review);
            }

            return acc;
          },
          {
            approved: [],
            reviewed: [],
            returned: [],
          } as RequestDetailsStoreType["reviews"]
        );

      setRequestDetail(
        merge(
          {
            reviews: {
              ...reviews,
              returned: reviews.returned.reverse(),
            },
            metadata: {
              earliestPayDate:
                requestDetailData?.expenseManagement?.earliestPayDate,
            },
            request: requestDetailData?.expenseManagement?.request,
          },
          additionalConfiguration
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestDetailLoading, requestDetailData]);

  return { isRequestDetailLoading };
};
