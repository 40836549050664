import { useState } from "react";
import { CheckboxInput } from "@justworkshr/milo-form";
import {
  Loading,
  Notice,
  PaginationSelect,
  Table,
  TableBody,
  TableHeader,
} from "pages/expenses/components";

import {
  RequestHistoryTableRow,
  DownloadReportDialog,
  ExportReportButton,
} from "./components";
import {
  EMPTY_STATE_NOTICE_TITLE,
  EMPTY_STATE_NOTICE_COPY,
} from "./RequestHistoryPage.constants";
import {
  useGetRequestHistory,
  useCategoriesExpenseType,
  useMultiAction,
  MultiAction,
} from "pages/expenses/hooks";
import {
  useGlobalConfigurationStore,
  useFeedbackBannerStore,
} from "pages/expenses/store";
import {
  DEFAULT_TABLE_PARAMS,
  PAGINATION_OPTIONS,
  AlertTypes,
} from "pages/expenses/constants";
import { setTableQueryVariables } from "pages/expenses/utils";
import {
  getRequestHistoryStatusParam,
  generateRequestHistoryTableHeaders,
  handleDownloadReport,
} from "./RequestHistoryPage.utils";
import { GetRequestHistoryQueryVariables } from "types/generated/operations";
import { ReimbursementRequestForHistory } from "./RequestHistoryPage.types";
import styles from "./RequestHistoryPage.module.css";

const {
  ActionsWrapper,
  RequestHistoryPageWrapper,
  AllRequestsCheckboxWrapper,
  RequestHistoryTableWrapper,
  RequestHistoryTableWrapperReporter,
} = styles;

export const RequestHistoryPage = () => {
  const { permissions } = useGlobalConfigurationStore();
  const { isCategoryWithNonReimbursableExpenses } = useCategoriesExpenseType();
  const tableHeaders = generateRequestHistoryTableHeaders(
    permissions.isReporter,
    isCategoryWithNonReimbursableExpenses
  );
  const { dismissFeedbackBanner, setFeedbackBanner } = useFeedbackBannerStore();
  const { selectedItems, handleSelectedItem, handleSelectedItems } =
    useMultiAction<ReimbursementRequestForHistory>();
  const [tableParams, setTableParams] = useState(DEFAULT_TABLE_PARAMS);
  const [showDownloadReportDialog, setShowDownloadReportDialog] =
    useState(false);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const [collapse, setCollapse] = useState(true);
  const variables = {
    ...setTableQueryVariables(tableParams),
    status: getRequestHistoryStatusParam(permissions),
  } as GetRequestHistoryQueryVariables;
  const { requestHistory, requestHistoryResponse, isRequestHistoryLoading } =
    useGetRequestHistory(variables);
  const paginationProps = {
    options: PAGINATION_OPTIONS,
    currentPage: (requestHistoryResponse?.page || 0) + 1,
    totalResults: requestHistoryResponse?.totalResults || 0,
    resultsPerPage: tableParams.resultsPerPage,
    onPageChange: (newPageNum: number) =>
      setTableParams((prevTableParams) => ({
        ...prevTableParams,
        pageNum: newPageNum - 1,
      })),
    onSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) =>
      setTableParams((prevTableParams) => ({
        ...prevTableParams,
        resultsPerPage: parseInt(event.target.value, 10),
      })),
  };
  const isAllSelected = selectedItems.length === requestHistory.length;
  const handleCheckboxChange = () => handleSelectedItems(requestHistory);
  const renderTableBody = () => {
    return (
      <TableBody>
        {requestHistory.map((request) => {
          const isCheckboxActive = !!selectedItems.find(
            (item) => item.uuid === request.uuid
          );
          const handleCheckboxChange = () =>
            handleSelectedItem(request, isCheckboxActive, (prevSelectedItems) =>
              prevSelectedItems.filter((item) => item.uuid !== request.uuid)
            );
          return (
            <RequestHistoryTableRow
              key={request.uuid}
              request={request}
              handleCheckboxChange={handleCheckboxChange}
              isCheckboxActive={isCheckboxActive}
              permissions={permissions}
              showNonReimbursableColumn={isCategoryWithNonReimbursableExpenses}
            />
          );
        })}
      </TableBody>
    );
  };
  const toggleCollapseState = () => setCollapse((prevState) => !prevState);

  const handleDownloadReportDialog = () => {
    if (abortController) {
      abortController.abort();
    }
    setShowDownloadReportDialog((prevState) => !prevState);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;

    if (!target.closest("#export-action-items") && !collapse) {
      setCollapse(true);
    }
  };

  if (isRequestHistoryLoading || !permissions) {
    return <Loading />;
  }

  if (!isRequestHistoryLoading && !requestHistory.length) {
    return (
      <div className={RequestHistoryPageWrapper}>
        <Notice title={EMPTY_STATE_NOTICE_TITLE}>
          {EMPTY_STATE_NOTICE_COPY}
        </Notice>
      </div>
    );
  }

  return (
    <div className={RequestHistoryPageWrapper} onMouseDown={handleMouseDown}>
      {permissions.isReporter && (
        <div className={ActionsWrapper}>
          <MultiAction selectedItems={selectedItems}>
            {({ isActive }) => (
              <ExportReportButton
                isActive={isActive}
                collapse={collapse}
                toggleCollapseState={toggleCollapseState}
                handleDownloadReport={async (type: string) => {
                  dismissFeedbackBanner();
                  handleDownloadReportDialog();

                  const response = await handleDownloadReport(
                    type,
                    selectedItems,
                    setAbortController,
                    toggleCollapseState
                  );

                  if (response) {
                    setAbortController(null);
                    handleDownloadReportDialog();
                  } else {
                    setAbortController(null);
                    handleDownloadReportDialog();
                    setFeedbackBanner({
                      show: true,
                      type: AlertTypes.ERROR,
                      message: "Your report failed to generate. Try again.",
                    });
                  }
                }}
              />
            )}
          </MultiAction>
          <div className={AllRequestsCheckboxWrapper}>
            <CheckboxInput
              name="allRequestsCheckbox"
              value="allRequestsCheckbox"
              aria-label={`${
                isAllSelected ? "Deselect all requests" : "Select all requests"
              }`}
              onChange={handleCheckboxChange}
              checked={isAllSelected}
            />
          </div>
        </div>
      )}
      <div
        className={
          permissions.isReporter
            ? RequestHistoryTableWrapperReporter
            : RequestHistoryTableWrapper
        }
      >
        <Table dataTestId="request-table">
          <TableHeader
            headers={tableHeaders}
            sortBy={tableParams.sortBy}
            sortDirection={tableParams.sortDirection}
            onSort={({ sortBy, sortDirection }) =>
              setTableParams((prevTableParams) => ({
                ...prevTableParams,
                pageNum: 0,
                sortBy,
                sortDirection,
              }))
            }
          />
          {renderTableBody()}
        </Table>
      </div>
      <PaginationSelect {...paginationProps} />
      <DownloadReportDialog
        isOpen={showDownloadReportDialog}
        onClose={handleDownloadReportDialog}
      />
    </div>
  );
};
