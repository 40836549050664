import { SystemIcon } from "@justworkshr/milo-icons";
import { DEFAULT_DOWNLOAD_PROPS } from "pages/expenses/constants";

export const STREAMLINE_WORKFLOWS_COPY =
  "Trade spreadsheets for automated approval workflows and flexible rules. \
  Give your team one less tool to remember.";

export const MINIMIZE_ERROR_COPY =
  "Eliminate spreadsheets and close the books faster with Expenses syncing \
  directly into accounting software like QuickBooks Online, Xero, and \
  NetSuite.";

export const ON_THE_GO_COPY =
  "With the Justworks mobile app, employees can create and submit expenses \
  right when they happen, including uploading receipts–no more chasing \
  employees for receipts.";

export const REQUIRED_PERMISSIONS_LABEL = (
  <strong>What admin permissions are required to set up Expenses?</strong>
);

export const REQUIRED_PERMISSIONS_COPY = (
  <p>
    To get started, Admins must have <strong>Edit employee permissions</strong>.
    Learn more about the{" "}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://help.justworks.com/hc/en-us/articles/17024877999515-Expenses-Desktop-"
    >
      permissions needed for Expenses
    </a>{" "}
    at the Help Center.
  </p>
);
export const ADMIN_FIRST_LOOK_LABEL = (
  <strong>Can admins try it out before employees get access?</strong>
);

export const ADMIN_FIRST_LOOK_COPY =
  "We got you! We know how important it is for Admins to get a first look. \
  We won't reach out to your team. After you set up Expenses and see how it \
  works, you can share the good news with your team. We even have an \
  announcement template that you can use once you're ready to tell them.";

export const LEARN_ABOUT_EXPENSES_LABEL = (
  <strong>How will my team learn how to use Expenses?</strong>
);

export const EXPENSES_VS_PAYMENTS_CENTER_LABEL = (
  <strong>
    How is Expenses different from scheduling reimbursements in the Payments
    Center?
  </strong>
);

export const EXPENSES_VS_PAYMENTS_CENTER_REIMBURSEMENT_COPY =
  "Both are for valid, non-taxable business expenses but Expenses is much more \
 robust. Admins can set up Manager approval workflows and flexible rules for \
 expense categories, such as requiring a receipt. Plus, once an Admin approves \
 a request, the reimbursement is automatically scheduled.";

export const renderLearnAboutExpensesCopy = (employeeOnePagerUrl: string) => (
  <>
    The first time employees use Expenses on desktop, they&apos;ll automatically
    get an interactive product tour. They&apos;ll be guided step-by-step so they
    can get started quickly. Admins can download the{" "}
    <a {...DEFAULT_DOWNLOAD_PROPS} href={employeeOnePagerUrl}>
      Employee Guide to Expenses
    </a>{" "}
    and distribute it to the team.
  </>
);

export const EXPENSES_LEARN_MORE =
  "https://help.justworks.com/hc/en-us/articles/17024877999515-Expenses-Desktop-";

export const documentIcon = (
  <SystemIcon iconName="document" color="brand" size="small" />
);
