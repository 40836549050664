import styles from "./page-403.module.css";
import { ForbiddenError } from "@justworkshr/milo-core";

const { container } = styles;

export default function Page403(): JSX.Element {
  return (
    <div className={container}>
      <ForbiddenError />
    </div>
  );
}
