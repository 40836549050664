import CreateStore from "../createStore";
import { RequestDetailReducer, INITIAL_REQUEST_DETAIL_STORE } from "./reducer";

const {
  useStore: useRequestDetailStore,
  Provider: RequestDetailProvider,
  StoreContext: RequestDetailStoreContext,
  useSelectorStore: useRequestDetailSelectorStore,
} = CreateStore(INITIAL_REQUEST_DETAIL_STORE, RequestDetailReducer);

export {
  useRequestDetailStore,
  RequestDetailProvider,
  RequestDetailStoreContext,
  useRequestDetailSelectorStore,
};
