import React from "react";
import { mergeClassNames } from "../../utils";
import styles from "./DecoratedTextInput.module.css";

const { Error, Prefix, DecoratedTextInputWrapper } = styles;

interface DecoratedTextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  prefix?: string;
  error?: boolean;
}

export const DecoratedTextInput: React.FC<DecoratedTextInputProps> = ({
  className,
  prefix,
  error = false,
  ...otherProps
}) => {
  const mergedClassNames = mergeClassNames([
    DecoratedTextInputWrapper,
    className,
    error ? Error : null,
  ]);

  const inputClassNames = mergeClassNames([error ? Error : null]);

  return (
    <div className={mergedClassNames}>
      {prefix && (
        <span className={mergeClassNames([Prefix, error ? Error : null])}>
          {prefix}
        </span>
      )}
      <input className={inputClassNames} {...otherProps} />
    </div>
  );
};

export default DecoratedTextInput;
