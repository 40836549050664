import { ROUTES, prependBasePath } from "pages/expenses/constants";
import {
  REQUEST_HISTORY_ADMIN_STATUSES,
  REQUEST_HISTORY_MANAGER_STATUSES,
} from "pages/expenses/constants/request-status";

import {
  REQUEST_HISTORY_TABLE_HEADERS,
  REQUEST_HISTORY_TABLE_HEADERS_WITH_NON_REIMBURSABLE,
} from "pages/expenses/routes/RequestHistoryPage/RequestHistoryPage.constants";
import { ReimbursementRequestForHistory } from "./RequestHistoryPage.types";
import { fetchClient } from "@justworkshr/fe-utility-kit";
import { buildClockworkWebPath } from "lib/resource-finder";

export const getRequestHistoryStatusParam = (permissions: {
  isAdmin: boolean;
  isManager: boolean;
}) => {
  if (permissions.isAdmin) {
    return REQUEST_HISTORY_ADMIN_STATUSES;
  } else if (permissions.isManager) {
    return REQUEST_HISTORY_MANAGER_STATUSES;
  } else {
    return [];
  }
};

export const generateRequestHistoryTableHeaders = (
  isReporter: boolean,
  isCategoryWithNonReimbursableExpenses: boolean
) => {
  if (isReporter) {
    return isCategoryWithNonReimbursableExpenses
      ? REQUEST_HISTORY_TABLE_HEADERS_WITH_NON_REIMBURSABLE
      : REQUEST_HISTORY_TABLE_HEADERS;
  } else {
    return isCategoryWithNonReimbursableExpenses
      ? REQUEST_HISTORY_TABLE_HEADERS_WITH_NON_REIMBURSABLE.slice(1)
      : REQUEST_HISTORY_TABLE_HEADERS.slice(1);
  }
};
const getRouteForDownload = (reportType: string) => {
  switch (reportType) {
    case "csv":
      return "POST_CSV_REPORT";
    case "pdf":
      return "POST_PDF_REPORT";
    case "zip":
      return "POST_ZIP_RECEIPTS";
  }
};
export const handleDownloadReport = async (
  reportType: string,
  selectedItems: ReimbursementRequestForHistory[],
  setAbortController: (AbortController: AbortController) => void,
  toggleCollapseState: () => void
) => {
  const formData = new FormData();
  selectedItems.forEach((request) => formData.append("uuids[]", request.uuid));
  const downloadReportAbortController = new AbortController();
  setAbortController(downloadReportAbortController);

  const url = getRouteForDownload(reportType);

  try {
    const response = await fetchClient.post(
      buildClockworkWebPath(prependBasePath(url as keyof typeof ROUTES)),
      {
        body: formData,
        cache: "no-cache",
        headers: {
          Accept: "multipart/form-data",
        },
        signal: downloadReportAbortController.signal,
      }
    );
    const blobResponse = await response.blob();
    const filename = `Expense Report.${reportType}`;
    if (blobResponse) {
      toggleCollapseState();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blobResponse);
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    return true;
  } catch (error: unknown) {
    if ((error as Error).name === "AbortError") {
      return true;
    } else {
      return false;
    }
  }
};
