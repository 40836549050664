import { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import {
  CONTRACTORS_PATH,
  EMPLOYEES_EOR_PATH,
  OVERVIEW_PATH,
} from "pages/international-solutions/routes";
import { INTERNATIONAL_SOLUTIONS_PATH } from "pages";
import { PageHeader } from "@justworkshr/milo-core";

const fullPath = (path: string) => {
  return `${INTERNATIONAL_SOLUTIONS_PATH}${path}`;
};

const getFormTitle = (path: string) => {
  switch (path) {
    case fullPath(EMPLOYEES_EOR_PATH):
      return "Employer of Record";
    case fullPath(CONTRACTORS_PATH):
      return "International contractors";
    default:
      return "Grow your global workforce";
  }
};

export const Header = (): ReactElement => {
  const { pathname } = useLocation();
  const isOverview = pathname === fullPath(OVERVIEW_PATH);

  return (
    <div>
      <PageHeader
        title={getFormTitle(pathname)}
        linkPrevious={
          !isOverview ? (
            <a href={`..${fullPath(OVERVIEW_PATH)}`}>Back to overview</a>
          ) : undefined
        }
      />
    </div>
  );
};
