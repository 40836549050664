export const WHITESPACE_ERROR = "No empty spaces, please.";

export const ONE_HUNDRED_CHARACTER_LIMIT_AND_EMOJI_ERROR =
  "Less than 100 characters, please. And no emoji, sorry 😔";

export const ONE_HUNDRED_CHARACTER_LIMIT_ERROR =
  "Less than 100 characters, please.";

export const FIVE_HUNDRED_CHARACTER_LIMIT_ERROR =
  "Less than 500 characters, please.";

export const EMOJI_ERROR = "No emoji, sorry 😔";

export const FIVE_HUNDRED_CHARACTER_LIMIT_AND_EMOJI_ERROR =
  "Less than 500 characters, please. And no emoji, sorry 😔";

export const EARLIEST_PAY_DATE_APPROVAL_ERROR =
  "The pay date you requested isn’t available. We’ve selected the next available pay date so you can try again.";

export const DEFAULT_ERROR =
  "Sorry, something went wrong. Please try again later.";
