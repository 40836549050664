import { ReactElement } from "react";
import styles from "./TerminalContractorModal.module.css";
import { Button, Dialog } from "@justworkshr/milo-core";
import { DialogHeader } from "../DialogHeader";

type Props = {
  onDismiss: () => void;
  onSubmit: () => void;
  loading?: boolean;
};

export default function TerminateContractorModal({
  onDismiss,
  onSubmit,
  loading,
}: Props): ReactElement {
  return (
    <div className={styles.container}>
      <Dialog
        isOpen
        onClose={onDismiss}
        showCloseButton={false}
        primaryButton={
          <Button
            onClick={onSubmit}
            variant="filled"
            color="destructive"
            loading={loading}
            disabled={loading}
          >
            Terminate
          </Button>
        }
        secondaryButton={
          <Button onClick={onDismiss} variant="ghost">
            Cancel
          </Button>
        }
      >
        <DialogHeader
          onClose={onDismiss}
          alertError={""}
          title="You are about to terminate this contractor."
        />
        <div className={styles.content}>
          <p className={styles.description}>
            This will add a terminated status to the contractor, removing the
            ability to pay them. However, they will continue showing up in
            reports.
          </p>
          <p>Once terminated, international contractors can be rehired.</p>
        </div>
      </Dialog>
    </div>
  );
}
