import { ReactElement } from "react";
import { Button } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import { Link } from "react-router-dom";
import {
  WAIVER_EDIT_URL,
  COI_EDIT_URL,
} from "pages/workers-compensation/document-request/constants";
import styles from "./certificate-holder-review.module.css";
import { CertificateHolderReviewProps } from "../waivers-of-subrogation/types";

const {
  formBorder,
  certHolderReview,
  header,
  headerText,
  editHeader,
  editButtonStyle,
  removePadding,
  formInfo,
  formInfoHeader,
} = styles;

export default function CertficateHolderReview({
  formData,
  requestType,
  supportingDocuments,
}: CertificateHolderReviewProps): ReactElement {
  const editLink =
    requestType === "waiver"
      ? `${WAIVER_EDIT_URL}?currentStep=2`
      : COI_EDIT_URL;
  const {
    street1,
    street2,
    city,
    state,
    postalCode,
    certName,
    certAdditionalNames,
    additionalInformation,
  } = formData;
  return (
    <div className={formBorder}>
      <div className="milo--grid">
        <div className={certHolderReview} data-testid="certificateHolderReview">
          <div className={header}>
            <h2 className={headerText}>Certificate details</h2>
            <div className={editHeader}>
              <Button
                to={editLink}
                as={Link}
                variant="ghost"
                className={removePadding}
              >
                <span className={editButtonStyle}>
                  <SystemIcon iconName="edit" color="brand" />
                  Edit
                </span>
              </Button>
            </div>
          </div>

          <div className={formInfo}>
            <h3 className={formInfoHeader}>Certificate Holder Name</h3>
            <p data-heap-redact-text={true}>{certName}</p>
          </div>

          <div className={formInfo}>
            <h3 className={formInfoHeader}>Address Street Line 1</h3>
            <p data-heap-redact-text={true}>{street1}</p>
          </div>

          <div className={formInfo}>
            <h3 className={formInfoHeader}>Address Street Line 2</h3>
            <p data-heap-redact-text={true}>{street2}</p>
          </div>

          <div className={formInfo}>
            <h3 className={formInfoHeader}>City</h3>
            <p>{city}</p>
          </div>

          <div className={formInfo}>
            <h3 className={formInfoHeader}>State</h3>
            <p>{state}</p>
          </div>

          <div className={formInfo}>
            <h3 className={formInfoHeader}>Zip Code</h3>
            <p>{postalCode}</p>
          </div>

          <div className={formInfo}>
            <h3 className={formInfoHeader}>Additional Holders</h3>
            <p data-heap-redact-text={true}>{certAdditionalNames}</p>
          </div>
          <div className={formInfo}>
            <h3 className={formInfoHeader}>Additional Notes</h3>
            <p>{additionalInformation}</p>
          </div>
          {supportingDocuments && supportingDocuments.length > 0 && (
            <div className={formInfo}>
              <h3 className={formInfoHeader}>Documents Uploaded</h3>
              {supportingDocuments.map((doc) => {
                if (doc.file?.name) {
                  return <p key={doc.file.name}>{doc.file.name}</p>;
                }
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
