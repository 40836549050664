import React from "react";
import { ExpenseDialog } from "../ExpenseDialog";
import { Button } from "@justworkshr/milo-core";
import styles from "./RemoveExpenseDialog.module.css";

const { RemoveExpenseDialogWrapper } = styles;

interface RemoveExpenseDialogProps {
  isOpen: boolean;
  isMultiple: boolean;
  onClose: () => void;
  onRemove: () => void;
}

export const RemoveExpenseDialog: React.FC<RemoveExpenseDialogProps> = ({
  isOpen,
  onClose,
  onRemove,
  isMultiple,
}) => {
  const expenseCopy = isMultiple ? "these expenses" : "this expense";

  return (
    <ExpenseDialog
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        <Button
          type="button"
          variant="ghost"
          onClick={onClose}
          key="keep-expense"
          data-testid="keep-expense-button"
        >
          Keep Expense{isMultiple ? "s" : ""}
        </Button>,
        <Button
          type="button"
          onClick={onRemove}
          color="destructive"
          key="delete-expense"
          data-testid="delete-expense-button"
        >
          Delete Expense{isMultiple ? "s" : ""}
        </Button>,
      ]}
    >
      <div className={RemoveExpenseDialogWrapper}>
        <h2>Delete {expenseCopy}</h2>
        <p>
          Are you sure you want to delete {expenseCopy}? Any info you&apos;ve
          added won&apos;t be saved.
        </p>
      </div>
    </ExpenseDialog>
  );
};
