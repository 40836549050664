import { FormikFormField } from "lib/formik/FormikFormField";
import styles from "../../../SeparationPay.module.css";
import {
  ADDITIONAL_PAYMENTS_FORM_COPY,
  ADDITIONAL_PAYMENTS_PAYMENT_METHOD_OPTIONS,
  ADDITIONAL_PAYMENTS_PAYMENT_TYPE_OPTIONS,
  ADDITIONAL_PAYMENTS_YES_OR_NO_OPTIONS,
} from "../../AdditionalPaymentsPage.constants";
import { useFormikContext } from "formik";
import { Select, SelectOption, TextArea } from "@justworkshr/milo-form";
import {
  RadioButtonGroup,
  RadioButton,
} from "pages/team-management/components";
import { PaymentInputRow } from "./PaymentInputRow";
import { renderPaymentMethodInput } from "./AdditionalPaymentsForm.helpers";
import { ReactElement, useContext, useEffect, useState } from "react";
import { AdditionalPaymentsPageContext } from "../../AdditionalPaymentsPageContextProvider";
import { AdditionalPaymentsFormValues } from "../../../SeparationPay.formUtils";
import { useGetDateAnalysisQuery } from "types/generated/operations";
import {
  formatDateAsString,
  isoStringToDate,
} from "pages/team-management/routes/SeparationFlow/SeparationReview/SeparationReview.util";

const { TwoColumnRow, RowContainer } = styles;

export const PaymentInputSection = ({
  paymentKey,
}: {
  paymentKey: number;
}): ReactElement => {
  const { values, setFieldValue, validateForm } =
    useFormikContext<Record<string, string>>();

  const [lastPayDate, setLastPayDate] = useState<string | null>(null);
  const { data: dateAnalysisData } = useGetDateAnalysisQuery({
    variables: {
      date: values.pay_date,
    },
    skip: !values?.pay_date || values.pay_date === lastPayDate,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (dateAnalysisData?.dateAnalysis) {
      setLastPayDate(values.pay_date);
      setFieldValue(
        "is_business_day",
        dateAnalysisData.dateAnalysis.isBusinessDay
      );
      setFieldValue(
        "previous_business_day",
        formatDateAsString(
          isoStringToDate(dateAnalysisData.dateAnalysis.previousBusinessDay)
        )
      );
      setFieldValue(
        "next_business_day",
        formatDateAsString(
          isoStringToDate(dateAnalysisData.dateAnalysis.nextBusinessDay)
        )
      );

      validateForm({
        ...values,
        is_business_day: dateAnalysisData.dateAnalysis.isBusinessDay,
        previous_business_day: formatDateAsString(
          isoStringToDate(dateAnalysisData.dateAnalysis.previousBusinessDay)
        ),
        next_business_day: formatDateAsString(
          isoStringToDate(dateAnalysisData.dateAnalysis.nextBusinessDay)
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateAnalysisData]);

  const { handleSubmit } = useFormikContext();

  const { setSubmitHandlers, setIsAdditionalPaymentsFormDirty } = useContext(
    AdditionalPaymentsPageContext
  );

  useEffect(() => {
    // @ts-expect-error: un-typable setState function
    setSubmitHandlers?.((prevState) => ({
      ...prevState,
      [paymentKey]: handleSubmit,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { dirty } = useFormikContext<AdditionalPaymentsFormValues>();

  useEffect(() => {
    setIsAdditionalPaymentsFormDirty &&
      // @ts-expect-error: un-typable setState function
      setIsAdditionalPaymentsFormDirty((prevState) => ({
        ...prevState,
        [paymentKey]: dirty,
      }));
  }, [dirty, setIsAdditionalPaymentsFormDirty, paymentKey]);

  return (
    <>
      <div className={RowContainer}>
        <div className={TwoColumnRow}>
          <FormikFormField
            name={`type`}
            label={ADDITIONAL_PAYMENTS_FORM_COPY.type}
            required={true}
          >
            <Select
              data-testid="type"
              name={`type`}
              value={values.type}
              placeholder="Select.."
            >
              {Object.entries(ADDITIONAL_PAYMENTS_PAYMENT_TYPE_OPTIONS).map(
                ([key, value]) => {
                  return (
                    <SelectOption value={key} key={key}>
                      {value}
                    </SelectOption>
                  );
                }
              )}
            </Select>
          </FormikFormField>

          <FormikFormField
            name={`repeat_payment`}
            label={ADDITIONAL_PAYMENTS_FORM_COPY.repeat_payment}
            required={true}
          >
            <RadioButtonGroup
              data-testid={`repeat_payment`}
              name={`repeat_payment`}
              onChange={(option) => {
                setFieldValue(`repeat_payment`, option);
              }}
              orientation="horizontal"
            >
              {Object.entries(ADDITIONAL_PAYMENTS_YES_OR_NO_OPTIONS).map(
                ([key, value]) => {
                  return <RadioButton key={key} value={key} label={value} />;
                }
              )}
            </RadioButtonGroup>
          </FormikFormField>
        </div>
      </div>
      <div className={RowContainer}>
        <PaymentInputRow isRecurring={values.repeat_payment === "yes"} />
      </div>
      {renderPaymentMethodInput(values.type) && (
        <div className={RowContainer}>
          <FormikFormField
            name={`payment_method`}
            label={ADDITIONAL_PAYMENTS_FORM_COPY.payment_method}
            required={true}
          >
            <RadioButtonGroup
              name={`payment_method`}
              orientation="horizontal"
              variant="framed"
            >
              {Object.entries(ADDITIONAL_PAYMENTS_PAYMENT_METHOD_OPTIONS).map(
                ([key, value]) => {
                  return (
                    <RadioButton
                      key={key}
                      value={key}
                      label={value.label}
                      description={value.message}
                    />
                  );
                }
              )}
            </RadioButtonGroup>
          </FormikFormField>
        </div>
      )}

      <div className={RowContainer}>
        <FormikFormField
          name={`notes`}
          label={ADDITIONAL_PAYMENTS_FORM_COPY.notes}
          message={ADDITIONAL_PAYMENTS_FORM_COPY.notes_message}
        >
          <TextArea
            name={`notes`}
            data-testid={`notes`}
            value={values.notes}
            placeholder="Enter notes"
          />
        </FormikFormField>
      </div>
    </>
  );
};
