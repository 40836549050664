import React, { useState } from "react";
import classes from "./AdditionalContributionsPage.module.css";
import { Alert, Box, Button, PageHeader } from "@justworkshr/milo-core";
import { buildClockworkWebPath } from "lib/resource-finder";
import {
  useCreateMember401kAdditionalContributionMutation,
  useGetUser401kInfoQuery,
} from "types/generated/operations";
import ResourcesBox from "../resources-box";
import {
  ActionFooter,
  FormField,
  RadioButton,
  RadioButtonGroup,
  TextInput,
} from "@justworkshr/milo-form";

const AdditionalContributionsPage = () => {
  const [contributedThisYear, setContributedThisYear] = useState("");
  const [ytdContributionAmount, setYtdContributionAmount] = useState("");
  const [submitError, setSubmitError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createMember401kAdditionalContribution] =
    useCreateMember401kAdditionalContributionMutation();
  const year = new Date().getFullYear();

  const { data, loading } = useGetUser401kInfoQuery();
  if (loading) {
    return <></>;
  }

  const calcYtdAmount = () => {
    switch (contributedThisYear) {
      case "YES":
        return parseInt(ytdContributionAmount) * 100;
      case "NO":
        return 0;
      default:
        return 0;
    }
  };

  const handleSubmit = async (event: MouseEvent) => {
    event.preventDefault();
    setSubmitError(false);
    setIsSubmitting(true);

    if (contributedThisYear === "NOT_SURE") {
      window.location.href = buildClockworkWebPath(
        "my_benefits/retirement_planning"
      );
    }

    try {
      await createMember401kAdditionalContribution({
        variables: {
          year: year,
          amount: calcYtdAmount(),
        },
      });
      window.scrollTo(0, 0);
      window.location.href = buildClockworkWebPath(
        "my_benefits/retirement_planning"
      );
    } catch (error) {
      setSubmitError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const ageStatus = data?.currentMember?.member401kInfo?.eligibleFor401kCatchup
    ? "over"
    : "under";
  const deferralMax = (
    (data?.currentMember?.member401kInfo?.member401kDeferralMax || 0) / 100
  ).toLocaleString();

  return (
    <div className={classes.additionalContributionsForm}>
      <Alert color="destructive" visible={submitError}>
        There was an issue submitting your plan details to Empower.
      </Alert>
      <PageHeader
        title="Get setup with Empower"
        linkPrevious={
          <a href={buildClockworkWebPath("/my_benefits/retirement_planning")}>
            Back to Benefits
          </a>
        }
      >
        Tell us about your contributions for the year and then you can head to
        Empower to finish up.
      </PageHeader>

      <div className="milo--grid" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <div style={{ gridColumn: "span 8" }}>
          <h3 id="contributions-label">
            Have you contributed to a 401(k) this year before you started using
            Justworks?
          </h3>
          <FormField aria-labelledby="contributions-label" required>
            <RadioButtonGroup
              name="contributed_this_year"
              value={contributedThisYear}
              onChange={(event) => setContributedThisYear(event.target.value)}
            >
              <RadioButton
                id="contributed_yes"
                name="contributed_this_year"
                label="Yes"
                value="YES"
              />
              <RadioButton
                id="contributed_no"
                name="contributed_this_year"
                label="No"
                value="NO"
              />
              <RadioButton
                id="contributed_not_sure"
                name="contributed_this_year"
                label="I'm not sure"
                value="NOT_SURE"
              />
            </RadioButtonGroup>
          </FormField>

          {contributedThisYear === "YES" ? (
            <>
              <div className={classes.maxContributionBox}>
                <Box backgroundColor="brandSubtle" padding="lg">
                  <h3>Max contribution</h3>
                  <p>
                    Folks like yourself {ageStatus} the age of 50 are allowed to
                    contribute ${deferralMax}/year to your 401(k). This applies
                    to your total year-to-date (YTD) contributions across all of
                    your 401(k) accounts-but not any employer match. If you go
                    over this amount, you'll be taxed twice on any individual
                    contributions over the limit. We'll automatically cap your
                    401(k) contributions to make sure you don't exceed the
                    legally allowed maximum amount.
                  </p>
                </Box>
                <div className={classes.ytdContributions}>
                  <FormField
                    label={`What were your ${year} YTD contributions?`}
                    message={`Check your last paystub with your previous 401(k) provider. Enter no if you didn't make any contributions in ${year}.`}
                    required={contributedThisYear === "YES"}
                  >
                    <TextInput
                      name="ytd_contribution_amount"
                      value={ytdContributionAmount}
                      placeholder={`$ ${year} YTD contributions`}
                      type="number"
                      onChange={(event) =>
                        setYtdContributionAmount(event.target.value.trim())
                      }
                    />
                  </FormField>
                </div>
              </div>
            </>
          ) : null}

          {contributedThisYear === "NOT_SURE" ? (
            <>
              <div className={classes.notSureContributionBox}>
                <Box backgroundColor="brandSubtle" padding="lg">
                  <h3>Keep going and tell us later</h3>
                  <p>
                    Your last paystub from your previous 401(k) provider should
                    have your 2024 year-to-date (YTD) contributions. If you
                    contributed to a different 401(k) this year before joining
                    Justworks, go to your Benefits Center after completing
                    enrollment with Empower to let us know how much you've
                    contributed.
                  </p>
                </Box>
              </div>
            </>
          ) : null}

          <div className={classes.nextUpBox}>
            <Box backgroundColor="neutralSubtle" padding="lg">
              <h3>Next up at Empower</h3>
              <p>
                After you <strong>Save</strong>, you'll be able to go to Empower
                and make your selections and finish the enrollment. After 24
                hours, you'll be able to see your contribution elections in
                Justworks.
              </p>
            </Box>
          </div>
          <div className={classes.setupFooter}>
            <ActionFooter
              actions={[
                <Button
                  color="brand"
                  onClick={handleSubmit}
                  key="review"
                  disabled={contributedThisYear === "" || isSubmitting}
                >
                  Save
                </Button>,
              ]}
              secondary={[
                <Button
                  as="a"
                  variant="outlined"
                  href={buildClockworkWebPath(
                    "/my_benefits/retirement_planning"
                  )}
                  key="back"
                >
                  Back
                </Button>,
              ]}
            />
          </div>
        </div>
        <div style={{ gridColumn: "span 4" }}>
          <ResourcesBox />
        </div>
      </div>
    </div>
  );
};

export default AdditionalContributionsPage;
