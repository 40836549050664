import React from "react";
import styles from "./SubmitterHeader.module.css";
import { Button } from "@justworkshr/milo-core";
import { useGlobalConfigurationStore } from "pages/expenses/store";
import { prependBasePath } from "pages/expenses/constants";
import { SubmitterTabList } from "../SubmitterTabList";
import { SUBMITTER_HEADER_DESCRIPTION } from "./SubmitterHeader.constants";
import { useNavigate } from "react-router-dom";

const {
  SubmitterHeaderTitle,
  SubmitterHeaderWrapper,
  SubmitterHeaderContainer,
  SubmitterHeaderSuperscript,
} = styles;

export const SubmitterHeader: React.FC = () => {
  const navigate = useNavigate();
  const { isExpensesEnabled } = useGlobalConfigurationStore();

  return (
    <div className={SubmitterHeaderWrapper}>
      <header>
        <div className={SubmitterHeaderContainer}>
          <h1 className={SubmitterHeaderTitle}>Expenses</h1>
          <div className={SubmitterHeaderSuperscript}>
            <Button
              type="button"
              onClick={() => navigate(prependBasePath("NEW_REQUEST"))}
              disabled={!isExpensesEnabled}
              data-testid="create-request-button"
            >
              Create request
            </Button>
          </div>
        </div>
        {SUBMITTER_HEADER_DESCRIPTION}
      </header>
      <SubmitterTabList />
    </div>
  );
};

export default SubmitterHeader;
