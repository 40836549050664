import Drawer from "app-frame/header/common/drawer";
import NestedDrawerHeader from "../../common/nested-drawer-header";
import { SystemIcon } from "@justworkshr/milo-icons";
import { UserMenuMemberWithCompany } from "types";
import {
  useGetUserMenuQuery,
  useSwitchCompaniesMutation,
} from "types/generated/operations";
import { buildWebPath } from "lib/resource-finder";
import styles from "./switch-companies-drawer.module.css";
import { usePanelsControlContext } from "app-frame/header/context/panels-control-context-provider";
import { PanelsControlActionType } from "app-frame/header/context/panels-control-context-provider/types";
import { asyncThrow } from "@justworkshr/fe-utility-kit";

const { companyList, companyButton } = styles;

function SwitchCompaniesDrawer() {
  const { state, dispatch } = usePanelsControlContext();
  const { switchCompaniesDrawerShown } = state;
  const headerText = "Companies";

  const [switchCompanies] = useSwitchCompaniesMutation();
  const { data, loading } = useGetUserMenuQuery();
  if (loading || !data) {
    return <></>;
  }
  const companyData = data.authenticatedUser.members;
  const currentMember = data.authenticatedMember;
  const companyId = currentMember.company.uuid;
  const otherCompaniesOfCurrentUser: UserMenuMemberWithCompany[] = [];
  companyData?.forEach((x) => {
    if (x.company.uuid !== companyId) {
      otherCompaniesOfCurrentUser.push(x);
    }
  });

  const handleSwitchCompanies = async (targetCompanyUuid: string) => {
    try {
      const response = await switchCompanies({
        variables: {
          targetCompanyUuid,
        },
      });
      if (response.data?.switchCompanies?.success) {
        const path = buildWebPath("/dashboard");
        window.location.assign(path);
      }
    } catch {
      // TODO: Better to show an error notification here
      asyncThrow("Failed to switch companies");
    }
  };

  const drawerHeader = (
    <NestedDrawerHeader
      icon={
        <SystemIcon iconName="building" size="large" color="brand-subtle" />
      }
      onClick={() =>
        dispatch({
          type: PanelsControlActionType.CLOSE_SWITCH_COMPANIES_DRAWER,
        })
      }
    >
      {headerText}
    </NestedDrawerHeader>
  );

  const drawerContent = (
    <ul title="Company List" className={companyList}>
      {otherCompaniesOfCurrentUser?.map((element, index) => (
        <li key={index}>
          <button
            className={companyButton}
            onClick={() => handleSwitchCompanies(element.company.uuid)}
          >
            {element.company.name}
          </button>
        </li>
      ))}
    </ul>
  );

  return (
    <Drawer
      name="switch-companies-drawer"
      header={drawerHeader}
      onClose={() =>
        dispatch({
          type: PanelsControlActionType.CLOSE_SWITCH_COMPANIES_DRAWER,
        })
      }
      open={switchCompaniesDrawerShown}
      showOverlay={false}
      zIndex={10000000}
    >
      {drawerContent}
    </Drawer>
  );
}

export default SwitchCompaniesDrawer;
