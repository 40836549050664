import moment from "moment";

export const scrollToTop = () => window.scrollTo(0, 0);
export const today = moment().format("YYYY-MM-DD");

export const filterObject = <T extends object, K extends keyof T>(
  source: T,
  keysToFilter: K[]
): Partial<T> =>
  Object.entries(source).reduce((acc, [key, value]) => {
    if (!keysToFilter.includes(key as K)) {
      acc[key as K] = value;
    }
    return acc;
  }, {} as Partial<T>);

export const convertNumberToPennies = (numberString: string) => {
  const cleanedString = numberString.replace(/[$,]/g, "");

  return Math.round(parseFloat(cleanedString) * 100);
};

export const isValidStartDate = (date: string) => {
  const yesterday = moment().subtract(1, "day");
  return moment(date).isAfter(yesterday);
};
