export interface PanelsControlState {
  userMenuDrawerShown: boolean;
  userMenuDropdownShown: boolean;
  alertsDrawerShown: boolean;
  whatsNewDrawerShown: boolean;
  advancedHelpDropdownShown: boolean;
  advancedHelpDrawerShown: boolean;
  switchCompaniesDrawerShown: boolean;
  sideMenuDrawerShown: boolean;
}

export interface PanelsControlAction {
  type: PanelsControlActionType;
}

export enum PanelsControlActionType {
  OPEN_USER_MENU_DRAWER,
  OPEN_USER_MENU_DROPDOWN,
  OPEN_ALERTS_DRAWER,
  OPEN_WHATS_NEW_DRAWER,
  OPEN_ADVANCED_HELP_DRAWER,
  OPEN_ADVANCED_HELP_DROPDOWN,
  OPEN_FORETHOUGHT_HELP_CHAT,
  OPEN_SWITCH_COMPANIES_DRAWER,
  OPEN_SIDE_MENU_DRAWER,
  CLOSE_USER_MENU_DRAWER,
  CLOSE_USER_MENU_DROPDOWN,
  CLOSE_ALERTS_DRAWER_FROM_MOBILE,
  CLOSE_ALERTS_DRAWER_FROM_TABLET_OR_DESKTOP,
  CLOSE_WHATS_NEW_DRAWER_FROM_MOBILE,
  CLOSE_WHATS_NEW_DRAWER_FROM_TABLET_OR_DESKTOP,
  CLOSE_ADVANCED_HELP_DRAWER,
  CLOSE_ADVANCED_HELP_DROPDOWN,
  CLOSE_SWITCH_COMPANIES_DRAWER,
  CLOSE_SIDE_MENU_DRAWER,
}

export const initialState: PanelsControlState = {
  userMenuDrawerShown: false,
  userMenuDropdownShown: false,
  alertsDrawerShown: false,
  whatsNewDrawerShown: false,
  advancedHelpDropdownShown: false,
  advancedHelpDrawerShown: false,
  switchCompaniesDrawerShown: false,
  sideMenuDrawerShown: false,
};
