import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchClient } from "@justworkshr/fe-utility-kit";
import { buildClockworkWebPath } from "lib/resource-finder";
import { useNavigate } from "react-router-dom";
import { redirectToStepUpLogin } from "./redirectToStepUpLogin";
import { FORBIDDEN_ERROR_PATH } from "pages/page-403";
import { UNKNOWN_ERROR_PATH } from "pages/page-520";

export const useRedirectIfNotSteppedUpAdmin = () => {
  const navigate = useNavigate();
  const [delayComplete, setDelayComplete] = useState(false);
  const { isLoading, error } = useQuery({
    queryKey: ["stepped_up_admin_check"],
    queryFn: async () => {
      if (!delayComplete) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setDelayComplete(true);
      }

      const response = await fetchClient.get(
        buildClockworkWebPath("/company/stepped_up_admin"),
        {},
        {
          401: redirectToStepUpLogin,
          403: () => navigate(FORBIDDEN_ERROR_PATH),
          500: () => navigate(UNKNOWN_ERROR_PATH),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    },
  });

  useEffect(() => {
    if (error) {
      navigate(UNKNOWN_ERROR_PATH);
    }
  }, [error, navigate]);

  return isLoading;
};
