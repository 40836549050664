import { createContext, FC, ReactNode, useEffect } from "react";
import type { ApolloError } from "@apollo/client";
import {
  useGetEoRMemberByUuidQuery,
  type GetEoRMemberByUuidQuery,
  useGetEoRMemberIdVerificationStatusQuery,
  useGetEoREmployeeEmploymentWorkCountryQuery,
} from "types/generated/operations";

interface EoRMemberContextType {
  eorMemberId?: string;
  loading: boolean;
  error?: ApolloError;
  idVerificationStatus?: string;
  refetchIdVerification: () => void;
  memberData?: GetEoRMemberByUuidQuery;
  workCountry: string | undefined;
}

export const EoRMemberContext = createContext<EoRMemberContextType | null>(
  null
);

interface EoRMemberContextProviderProps {
  children: ReactNode;
}

export const EoRMemberContextProvider: FC<EoRMemberContextProviderProps> = ({
  children,
}) => {
  const {
    data: memberData,
    loading: loadingMemberData,
    error: memberError,
  } = useGetEoRMemberByUuidQuery({});

  const { data: employmentData, loading: loadingEmploymentWorkCountry } =
    useGetEoREmployeeEmploymentWorkCountryQuery({
      variables: {
        memberId: memberData?.eorMember.id,
      },
      skip: !memberData?.eorMember.id,
    });

  const {
    data: idVerificationData,
    startPolling: startPollingIdVerification,
    stopPolling: stopPollingIdVerification,
    refetch: refetchIdVerification,
  } = useGetEoRMemberIdVerificationStatusQuery({
    variables: {
      memberId: memberData?.eorMember.id || "",
    },
    skip: !memberData?.eorMember.id,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const status = idVerificationData?.personaIdVerificationStatus;

    if (
      status === "approved" ||
      status === "declined" ||
      status === "not_started"
    ) {
      stopPollingIdVerification();
    } else {
      startPollingIdVerification(5000);
    }
  }, [
    idVerificationData?.personaIdVerificationStatus,
    stopPollingIdVerification,
    startPollingIdVerification,
  ]);

  return (
    <EoRMemberContext.Provider
      value={{
        eorMemberId: memberData?.eorMember.id,
        loading: loadingMemberData || loadingEmploymentWorkCountry,
        error: memberError,
        idVerificationStatus: idVerificationData?.personaIdVerificationStatus,
        refetchIdVerification,
        memberData,
        workCountry:
          employmentData?.eorEmployeeProfile.employment?.workCountry ||
          undefined,
      }}
    >
      {children}
    </EoRMemberContext.Provider>
  );
};
