import styles from "./Loading.module.css";
import { Spinner } from "@justworkshr/milo-core";

const { LoadingWrapper } = styles;

export const Loading = () => {
  return (
    <div className={LoadingWrapper}>
      <Spinner />
    </div>
  );
};
