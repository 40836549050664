import { useMemo } from "react";
import { OperationVariables } from "@apollo/client";
import {
  GetPmtIntegrationFrameworkDataQueryVariables,
  useGetPmtIntegrationFrameworkSetupDataQuery,
} from "types/generated/operations";
import { setPmtIntegrationVariables } from "../../utils";
import { parsePartnerIntegrationsData } from "../../../partner-description-page/hooks/usePdpPageData/utils";

const useSetupPageData = (
  integrationSlug: string,
  integrationInstanceKeys?: string[],
  limit?: number,
  options?: OperationVariables
) => {
  const variables = {
    ...setPmtIntegrationVariables(
      integrationSlug,
      integrationInstanceKeys,
      limit
    ),
  } as GetPmtIntegrationFrameworkDataQueryVariables;

  const queryResult = useGetPmtIntegrationFrameworkSetupDataQuery({
    variables: variables,
    skip: !integrationSlug,
    fetchPolicy: "cache-and-network",
    ...options,
  });

  return useMemo(
    () => parsePartnerIntegrationsData(queryResult),
    [queryResult]
  );
};

export default useSetupPageData;
