import { Button } from "@justworkshr/milo-core";
import styles from "../DropdownMenu.module.css";

interface DropdownMenuRowProps {
  name: string;
  displayName: string;
  onClick: () => void;
  selected: boolean;
}
export const DropdownMenuRow = ({
  name,
  displayName,
  onClick,
  selected,
}: DropdownMenuRowProps) => {
  return (
    <div
      className={selected ? styles.rowBoxSelected : styles.rowBox}
      data-testid={`dropdown_button_${name}`}
    >
      <Button
        variant="ghost"
        type="button"
        className={styles.optionButton}
        size="sm"
        onClick={onClick}
      >
        <span>{displayName}</span>
      </Button>
    </div>
  );
};
