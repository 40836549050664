import {
  useFirmsPageQuery,
  useGetCompanyDisplayQuery,
} from "types/generated/operations";
import { useLocation } from "react-router-dom";
import { useRef } from "react";

export const buildRelativePermissionsLink = (firmUuid: string): string =>
  `${firmUuid}/edit-firm`;

export const buildRelativeRemoveFirmLink = (firmUuid: string): string =>
  `${firmUuid}/remove-firm`;

export function useFirmsPageQueries() {
  const {
    data: companyData,
    loading: companyLoading,
    error: companyError,
  } = useGetCompanyDisplayQuery();

  const companyUuid = companyData?.authenticatedMember?.company?.uuid ?? "";

  const {
    data: firmData,
    loading: firmLoading,
    error: firmError,
  } = useFirmsPageQuery({
    skip: companyLoading || !!companyError,
    variables: {
      companyUuid: companyUuid,
    },
  });

  return {
    data: { ...firmData, companyUuid },
    loading: companyLoading || firmLoading,
    error: companyError || firmError,
  };
}

export function useConsumeSuccessMessage() {
  const location = useLocation();
  const statusMessageRef = useRef("");
  if (location.state?.successMessage) {
    // Store the status message in a ref and then clear it from the location state
    // so that the message is not displayed again on page refresh
    statusMessageRef.current = location.state.successMessage;
    window.history.replaceState({}, "");
  }
  return statusMessageRef.current;
}

export function useFormError() {
  const formErrorRef = useRef<HTMLDivElement>(null);

  function scrollToError() {
    formErrorRef?.current?.scrollIntoView({
      behavior: "smooth",
    });
  }

  return { formErrorRef, scrollToError };
}
