import { useCallback } from "react";
import { useSettingsSelectorStore } from "../../store";
import {
  SetProjectsPayload,
  SetActiveProjectPayload,
} from "../../constants/projects";
import { ProjectsActionTypes } from "../../actions/projects";

export const useProjectsStore = () => {
  const { store: projects, dispatch } = useSettingsSelectorStore(
    (store) => store.Projects
  );

  const setProjects = useCallback(
    (payload: SetProjectsPayload) => {
      dispatch({
        type: ProjectsActionTypes.SET_PROJECTS,
        payload,
      });
    },
    [dispatch]
  );

  const setActiveProject = useCallback(
    (payload: SetActiveProjectPayload) => {
      dispatch({
        type: ProjectsActionTypes.SET_ACTIVE_PROJECT,
        payload,
      });
    },
    [dispatch]
  );

  return { projects, setProjects, setActiveProject };
};
