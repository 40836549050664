import React from "react";
import styles from "./RequestDetailHeader.module.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { prependBasePath } from "pages/expenses/constants";
import {
  RequestDetailTabList,
  REQUEST_DETAIL_NAVLINK_TYPES,
} from "../RequestDetailTabList";
import { PageHeader } from "@justworkshr/milo-core";
import { REQUEST_UUID_DESCRIPTION } from "./RequestDetailHeader.constants";
import {
  useGlobalConfigurationStore,
  useRequestDetail,
} from "pages/expenses/store";
import { RETURNED_STATUS } from "pages/expenses/constants/request-status";
import { ReturnedRequestButtons } from "./components";

const {
  RequestDetailHeaderWrapper,
  RequestDetailHeaderContainer,
  RequestDetailHeaderPageHeaderWrapper,
} = styles;

interface RequestDetailHeaderProps {
  type?: REQUEST_DETAIL_NAVLINK_TYPES;
}

export const RequestDetailHeader: React.FC<RequestDetailHeaderProps> = ({
  type,
}) => {
  const location = useLocation();
  const { request_uuid } = useParams();
  const {
    permissions: { isManager, isAdmin },
  } = useGlobalConfigurationStore();
  const {
    resetRequestDetails,
    requestDetail: {
      request: { status, myRequest },
    },
  } = useRequestDetail();

  const isAdminOrManager = isManager || isAdmin;
  const isActivityPage = location.pathname.includes("activity");
  const backToExpensesPath =
    isAdminOrManager && !myRequest ? "ACTIVE_REQUESTS_LIST" : "HOME";
  const showReturnRequestButtons = status === RETURNED_STATUS && myRequest;

  const renderPageDescription = () => {
    if (request_uuid) {
      return REQUEST_UUID_DESCRIPTION;
    }

    return null;
  };

  const renderAdditionalButtons = () => {
    if (showReturnRequestButtons) {
      return <ReturnedRequestButtons />;
    }

    return null;
  };

  return (
    <div className={RequestDetailHeaderWrapper}>
      <div className={RequestDetailHeaderPageHeaderWrapper}>
        <PageHeader
          border={false}
          title="Reimbursement request"
          className={RequestDetailHeaderContainer}
          linkPrevious={
            <Link
              onClick={() => resetRequestDetails()}
              to={prependBasePath(backToExpensesPath)}
            >
              Back to expenses
            </Link>
          }
        />
        {!isActivityPage && renderAdditionalButtons()}
      </div>
      {!isActivityPage && renderPageDescription()}
      <RequestDetailTabList type={type} />
    </div>
  );
};

export default RequestDetailHeader;
