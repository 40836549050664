import { AlertTypes, prependBasePath } from "pages/expenses/constants";
import { useFeedbackBannerStore } from "pages/expenses/store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateUnattachedExpenseMutation } from "types/generated/operations";

export const useUpdateUnattachedExpense = (onSuccess: () => void) => {
  const navigate = useNavigate();
  const { setFeedbackBanner } = useFeedbackBannerStore();

  const [
    updateUnattachedExpense,
    {
      data: updateUnattachedExpenseData,
      loading: updateUnattachedExpenseLoading,
    },
  ] = useUpdateUnattachedExpenseMutation();

  useEffect(() => {
    if (
      !updateUnattachedExpenseLoading &&
      updateUnattachedExpenseData?.updateUnattachedExpense?.success
    ) {
      onSuccess();

      setFeedbackBanner({
        type: AlertTypes.SUCCESS,
        show: true,
        message: `Your expense was successfully updated.`,
      });

      navigate(prependBasePath("EXPENSE_LIBRARY"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUnattachedExpenseLoading, updateUnattachedExpenseData]);

  return {
    updateUnattachedExpense,
  };
};
