import { getIn, FormikContextType } from "formik";
import {
  FormPayloadType,
  AdditionalPayFormData,
  FieldValue,
} from "../../../../types";
import { COMPONENTLIST } from "../../constants";
import {
  DateInput,
  DistanceInput,
  FileInput,
  RadioInput,
  SelectInput,
  TextArea,
} from "./components";
import { FieldConfig } from "../../types";
import { recurseSubFieldsForNames } from "pages/employer-of-record/payments/utils";
import styles from "./FieldFactory.module.css";

const ComponentMap = {
  [COMPONENTLIST.SELECT]: SelectInput,
  [COMPONENTLIST.TEXTAREA]: TextArea,
  [COMPONENTLIST.RADIO]: RadioInput,
  [COMPONENTLIST.DATE]: DateInput,
  [COMPONENTLIST.FILE]: FileInput,
  [COMPONENTLIST.DISTANCEINPUT]: DistanceInput,
};

export interface IFieldFactory<T extends FormPayloadType = FormPayloadType> {
  formik: FormikContextType<AdditionalPayFormData<T>>;
  formikFieldName: keyof T;
  field: FieldConfig<T>;
  handleFieldsChanges: (
    changes: {
      fieldName: string;
      value: FieldValue;
    }[]
  ) => void;
}

export function FieldFactory<T extends FormPayloadType>({
  field,
  formik,
  formikFieldName,
  handleFieldsChanges,
}: IFieldFactory<T>) {
  const { errors, handleBlur, values, handleChange, setFieldValue, touched } =
    formik;

  const accessor = `payload.${String(formikFieldName)}.${field.name}`;
  const touchedField = getIn(touched.setUp, accessor);
  const errorField = getIn(errors.setUp, accessor);
  const value = getIn(values.setUp, accessor);
  const renderCheck = field.renderCheck ? field.renderCheck(values) : true;
  if (field.disabledCheck) {
    field.disabled = field.disabledCheck(values);
  }

  if (field.conditionalMessage) {
    field.message = field.conditionalMessage(values);
  }

  if (!renderCheck) {
    if (value) {
      handleFieldsChanges(
        recurseSubFieldsForNames(field).map((fieldName) => ({
          fieldName,
          value: undefined,
        }))
      );
    }
    return null;
  }

  const params = {
    field,
    accessor,
    touchedField,
    errorField,
    value,
    disabled: field.disabled,
    setFieldValue,
    handleBlur,
    handleChange,
    workCountry: values?.setUp?.workCountry ?? "",
  };

  const Component = ComponentMap[field.type];

  return (
    <>
      <div className={styles.inputField} key={field.name}>
        <Component {...params} />
      </div>
      {field.subFields &&
        field.subFields.map((subField) => (
          <FieldFactory
            {...{
              formik,
              formikFieldName,
              handleFieldsChanges,
              field: subField,
              key: subField.name,
            }}
          />
        ))}
    </>
  );
}
