import { Step, Stepper } from "@justworkshr/milo-core";
import { useRequestSeparationContext } from "../context/RequestSeparationContext";

const RequestSeparationStepper = () => {
  const { currentStep } = useRequestSeparationContext();
  return (
    <div>
      <Stepper orientation="vertical">
        <Step
          status={currentStep === 1 ? "current" : "complete"}
          stepNumber={1}
          title="Background"
        />
        <Step
          status={currentStep === 2 ? "current" : "pending"}
          stepNumber={2}
          title="Review & submit"
        />
      </Stepper>
    </div>
  );
};

export default RequestSeparationStepper;
