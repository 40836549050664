import {
  ADMIN_APPROVABLE_REQUEST_STATUSES,
  MANAGER_APPROVABLE_REQUEST_STATUSES,
} from "pages/expenses/constants";
import { ReviewReimbursementRequestsPayload } from "types/Expenses";

export const getReimbursementStatusParam = (permissions: {
  isAdmin: boolean;
  isManager: boolean;
}) => {
  if (permissions?.isAdmin) {
    return ADMIN_APPROVABLE_REQUEST_STATUSES;
  } else if (permissions?.isManager) {
    return MANAGER_APPROVABLE_REQUEST_STATUSES;
  }

  return [];
};

export const isMultipleEmployeeRequest = (
  data: ReviewReimbursementRequestsPayload
) => {
  return data?.success && data?.updatedRequests?.length !== 1;
};
