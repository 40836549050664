import React from "react";
import styles from "./FormRow.module.css";

const { formContent, formInfo } = styles;
const FormRow = ({
  children,
}: {
  label?: string;
  children: React.ReactNode;
}) => {
  const childElements = React.Children.toArray(children);
  return (
    <div className="milo--grid">
      <div className={`${formContent}`}>{childElements[0]}</div>

      <div className={`${formInfo}`}>{childElements[1]}</div>
    </div>
  );
};
export default FormRow;
