import React from "react";
import { Button } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import { prependBasePath } from "pages/expenses/constants";
import { useRequestDetail } from "pages/expenses/store";
import { useNavigate } from "react-router-dom";
import { RequestDetailExpense } from "types/Expenses";
import styles from "../../ExpenseDetailsPage.module.css";

const { ExpenseDetailsPageActionFooterWrapper } = styles;

interface ReimbursementExpenseActionFooterProps {
  isEditMode: boolean;
  isFormValid: boolean;
  isExpenseUpdated: boolean;
  buildExpenseForReimbursementRequest: () => RequestDetailExpense;
}

export const ReimbursementExpenseActionFooter: React.FC<
  ReimbursementExpenseActionFooterProps
> = ({
  isEditMode,
  isFormValid,
  isExpenseUpdated,
  buildExpenseForReimbursementRequest,
}) => {
  const navigate = useNavigate();
  const {
    setRequestDetail,
    requestDetail: {
      request: { expenses },
      metadata: { isApproverView, isRequestEditView, isUnattachedExpenseView },
      editMode: { editExpenseIndex },
    },
  } = useRequestDetail();

  const NEXT_ROUTE =
    isRequestEditView || isApproverView || isEditMode
      ? prependBasePath("EDIT_REQUEST")
      : prependBasePath("NEW_REQUEST");

  const isSubmitDisabled =
    !isFormValid ||
    (isApproverView && !isExpenseUpdated) ||
    (isEditMode && !isExpenseUpdated);

  const submitButtonText =
    isEditMode || isApproverView ? "Update" : "Add to Request";

  const handleBack = () => {
    setRequestDetail({
      editMode: {
        editExpense: null,
        editExpenseIndex: null,
      },
    });

    navigate(NEXT_ROUTE);
  };

  const handleSubmit = () => {
    const expenseDetails = buildExpenseForReimbursementRequest();

    if (isEditMode && editExpenseIndex !== null) {
      const updatedExpenses = [...expenses];

      updatedExpenses[editExpenseIndex] = expenseDetails;

      setRequestDetail({
        request: {
          expenses: updatedExpenses,
        },
        editMode: {
          editExpense: null,
          editExpenseIndex: null,
        },
        metadata: {
          isRequestUpdated: true,
        },
      });
    } else {
      setRequestDetail({
        request: {
          expenses: [...expenses, expenseDetails],
        },
        metadata: {
          isRequestUpdated: true,
        },
      });
    }

    navigate(NEXT_ROUTE);
  };

  if (isUnattachedExpenseView) {
    return null;
  }

  return (
    <ActionFooter
      className={ExpenseDetailsPageActionFooterWrapper}
      actions={[
        <Button
          type="button"
          variant="outlined"
          key="go-back-button"
          onClick={handleBack}
        >
          Go back
        </Button>,
        <Button
          type="button"
          onClick={handleSubmit}
          key="add-to-request-button"
          disabled={isSubmitDisabled}
        >
          {submitButtonText}
        </Button>,
      ]}
    />
  );
};
