type Links = {
  helpCenter: string;
  metlife: string;
  stdStateLeave: string;
  resourcePages: {
    ltd: [
      {
        [key: string]: string;
      }
    ];
    std: [
      {
        [key: string]: string;
      }
    ];
    basicLifeAndADD: [
      {
        [key: string]: string;
      },
      {
        [key: string]: string;
      }
    ];
  };
  suppLifeResourcePage: string;
  metlifeAdvantages: string;
};

export const HEADER_INFO = {
  ltdTitle: "Long term disability insurance",
  ltdDescription:
    "Justworks brings your employees access to long term disability insurance through MetLife.",
  stdTitle: "Short term disability insurance",
  stdDescription:
    "Justworks brings your employees access to short term disability insurance through MetLife.",
  basicLifeAndADDTitle: "Life insurance",
  basicLifeAndADDDescription:
    "Justworks brings your employees access to basic life, accidental death and dismemberment (AD&D) and supplemental life insurances through MetLife.",
};

export const LINKS: Links = {
  helpCenter:
    "https://help.justworks.com/hc/en-us/articles/360004471792-Ancillary-Benefits",
  metlife: "https://www.metlife.com/",
  stdStateLeave: "https://help.justworks.com/hc/en-us/articles/20267805542043",
  resourcePages: {
    ltd: [
      {
        resourceLabel: "Long Term Disability Insurance",
        resourcePage:
          "https://help.justworks.com/hc/en-us/articles/20430335085723",
      },
    ],
    std: [
      {
        resourceLabel: "Short Term Disability Insurance",
        resourcePage:
          "https://help.justworks.com/hc/en-us/articles/20429939273883",
      },
    ],
    basicLifeAndADD: [
      {
        resourceLabel: "Basic Life and AD&D Insurance",
        resourcePage:
          "https://help.justworks.com/hc/en-us/articles/360004990391-Life-Insurance-Accidental-Death-and-Dismemberment",
      },
      {
        resourceLabel: "Supplementary Life Insurance",
        resourcePage:
          "https://justworks.my.salesforce.com/sfc/p/#i0000000fGVY/a/0H00000096TT/JAohF6qeW_RMO9jOR9NlFwY5giTuYgXXuNqlouG9rHU",
      },
    ],
  },
  suppLifeResourcePage:
    "https://justworks.my.salesforce.com/sfc/p/#i0000000fGVY/a/0H00000096TT/JAohF6qeW_RMO9jOR9NlFwY5giTuYgXXuNqlouG9rHU",
  metlifeAdvantages:
    "https://justworks.my.salesforce.com/sfc/p/#i0000000fGVY/a/PJ0000001QV7/gJsTKDcvfG.V9g3qUvY57L8m65NBafW3Ea.N5Fv10jY",
};

export const ROUTES = {
  ltdPdpPage: `/life-and-leave/enrollment/ltd`,
  stdPdpPage: `/life-and-leave/enrollment/std`,
  basicAndADDPdpPage: `/life-and-leave/enrollment/basic-life-and-add`,
  ltdSetupPage: `/life-and-leave/enrollment/ltd/setup`,
  stdSetupPage: `/life-and-leave/enrollment/std/setup`,
  basicAndADDSetupPage: `/life-and-leave/enrollment/basic-life-and-add/setup`,
  basicAndADDReviewPage: `/life-and-leave/enrollment/basic-life-and-add/review`,
  reviewPage: `/life-and-leave/enrollment/review`,
};

export const INFO_PANEL_CONTENT = {
  aboutMetlife: {
    id: 0,
    title: "About Metlife",
    description:
      "MetLife is one of the largest life insurance companies in the world. Founded in 1868, MetLife is a global provider of life insurance, annuities, employee benefits and asset management.",
  },
  costToYou: {
    id: 1,
    title: "Cost to You",
    description: "Costs vary by plan and employees covered.",
  },
  eligibility: {
    id: 2,
    title: "Eligibility",
    description: "All employees working 30+ hours/ week.",
  },
};

export const ENROLLMENT_ALERT_CONTENT = {
  ltdSuccessMessage:
    "Long term disability insurance is now set up for your employees.",
  stdSuccessMessage:
    "Short term disability insurance is now set up for your employees.",
  basicSuccessMessage: "Life insurance is now set up for your employees.",
  failureMessage:
    "Sorry, something went wrong on our end. Please submit your plan again. ",
};
