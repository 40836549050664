import React from "react";
import { createRoot } from "react-dom/client";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { initializeDataDog } from "app/analytics";
import {
  initializeFetchClient,
  initializeHeap,
} from "@justworkshr/fe-utility-kit";
import { VARIABLE_NAMES, getEnvVariable } from "lib/environment";
import { initializeI18n } from "app/internationalization/i18n";
import { redirectToLogin } from "lib/auth";
import RollbarProvider from "app/analytics/rollbar";

const asyncNoop = async () => {
  // do nothing
};
const mswStarter = async () => {
  const { worker } = await import("./test-tools/browser");
  return worker.start();
};

const root = createRoot(document.getElementById("root") as HTMLElement);

// determine if Mock Service Worker should be running
const appPreReq =
  import.meta.env.VITE_APP_MSW_ENABLED === "true" ? mswStarter : asyncNoop;

const defaultFetchHeaders: RequestInit["headers"] = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

const defaultFetchOptions: RequestInit = {
  headers: defaultFetchHeaders,
  credentials: "include",
};

const defaultHandlers = {
  401: redirectToLogin,
};

void appPreReq().then(() => {
  initializeFetchClient(defaultFetchOptions, defaultHandlers);
  initializeDataDog();
  initializeHeap(getEnvVariable(VARIABLE_NAMES.HEAP_KEY));
  initializeI18n();
  root.render(
    <React.StrictMode>
      <RollbarProvider>
        <App />
      </RollbarProvider>
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(cosole.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
