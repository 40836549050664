import { PERMISSION } from "types/RequiredPermissions";
import { CompanyPlan } from "types/generated/operations";

const REQUEST_INTEGRATION_URL = "https://airtable.com/shrWW9m0qwDOrpaPP";

export { REQUEST_INTEGRATION_URL };

export const permissionText = (
  adminPermissions: string[],
  firstAdminName: string
) => {
  const formatter = new Intl.ListFormat("en", {
    style: "long",
    type: "conjunction",
  });
  const formattedAdminPermissions = formatter.format(adminPermissions);
  return (
    <>
      Only admins with<strong> {formattedAdminPermissions} </strong>permissions
      can set up this integration. Contact
      <strong> {firstAdminName} </strong>
      at your organization to request access.
    </>
  );
};

export const PERMISSION_NAME_MAP = {
  [PERMISSION.BENEFITS]: "Manage company benefits",
  [PERMISSION.TIMECARDS]: "Manage all timecards",
  [PERMISSION.MAKE_PAYMENTS]: "Make payments",
  [PERMISSION.CHANGE_EMPLOYEES]: "Manage employees",
  [PERMISSION.ADMIN_PTO]: "Manage all paid time off",
  [PERMISSION.EMPLOYEE_INFO_FULL]: "View all employee information",
  [PERMISSION.DOC_CENTER_ADMIN]: "Upload & view all documents",
  [PERMISSION.PAY_METRICS]: "View invoices & reporting",
  [PERMISSION.EMPLOYEE_INFO_LIMITED]: "View basic employee information",
  [PERMISSION.GRANT_PERMISSIONS]: "Edit employee permissions",
  [PERMISSION.CHANGE_COMPANY_SETTINGS]: "Edit company settings",
  [PERMISSION.PAY_CONTRACTORS_VENDORS]: "Pay contractors & vendors",
  [PERMISSION.EXPENSES]: "Manage expenses",
};
export const ACCOUNTING_PERMISSIONS: PERMISSION[] = [
  PERMISSION.PAY_METRICS,
  PERMISSION.MAKE_PAYMENTS,
  PERMISSION.EXPENSES,
];

export const HR_PERMISSIONS: PERMISSION[] = [PERMISSION.CHANGE_EMPLOYEES];

export const HUB_ACCESS_PERMISSIONS: PERMISSION[] = [
  PERMISSION.CHANGE_EMPLOYEES,
  PERMISSION.PAY_METRICS,
  PERMISSION.MAKE_PAYMENTS,
  PERMISSION.EXPENSES,
];

export const DEFAULT_PMT_INTEGRATION_PARAMS = {
  integration_uuid: null,
  integration_status: ["active", "not_started"],
  integration_name: null,
  integration_instance_status: null,
  integration_instance_keys: ["get_token", "setup", "candidate_hired"],
  limit: 1,
};

export const ALLOWED_PLAN_TYPES: CompanyPlan[] = [
  CompanyPlan.Basic,
  CompanyPlan.Committed,
  CompanyPlan.Peo,
  CompanyPlan.Premier,
  CompanyPlan.Premier_2019,
  CompanyPlan.PremierUnbundled,
  CompanyPlan.Standard,
];
