import {
  compact as originalCompact,
  validate as originalValidate,
  name as originalName,
  localName as originalLocalName,
  abbreviation as originalAbbreviation,
} from "stdnum/src/co/nit";
import { ValidateReturn } from "stdnum/lib/cjs/types";

const coTaxIdValidator = {
  name: originalName,
  localName: originalLocalName,
  abbreviation: originalAbbreviation,

  compact(input: string): string {
    return originalCompact(input);
  },

  format(input: string): string {
    if (input.length >= 10) {
      const p1 = input.slice(0, 3);
      const p2 = input.slice(3, 6);
      const p3 = input.slice(6, 9);
      const p4 = input.slice(9);
      return `${p1}.${p2}.${p3}-${p4}`;
    } else {
      return this.digits(input);
    }
  },

  validate(input: string): ValidateReturn {
    return originalValidate(input);
  },

  digits(input: string): string {
    return this.compact(input);
  },
};

export { coTaxIdValidator };
