import { ReactNode, createContext, useContext, useState } from "react";

type LeadFormData = { [key: string]: string | boolean | undefined };

interface LeadFormContextValue {
  leadForm: LeadFormData;
  setLeadForm: React.Dispatch<React.SetStateAction<LeadFormData>>;
}

const LeadFormContext = createContext<LeadFormContextValue>({
  leadForm: {},
  setLeadForm: () => {},
});

interface LeadFormContextProviderProps {
  children: ReactNode;
}

export const useLeadFormContext = () => {
  const context = useContext(LeadFormContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useLeadFormContext must be used within a LeadFormContextProvider"
    );
  }
  return context;
};

const LeadFormContextProvider = ({
  children,
}: LeadFormContextProviderProps) => {
  const [leadForm, setLeadForm] = useState<LeadFormData>({});

  return (
    <LeadFormContext.Provider
      value={{
        leadForm,
        setLeadForm,
      }}
    >
      {children}
    </LeadFormContext.Provider>
  );
};

export default LeadFormContextProvider;
