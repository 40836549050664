import React, { ReactElement } from "react";
import styles from "./AlertMessage.module.css";

import { DisplayIcon } from "@justworkshr/milo-icons";

const { AlertMessageContainer } = styles;

interface AlertMessageProps {
  children: ReactElement | Array<ReactElement>;
  iconComponent?: ReactElement;
  "data-testid"?: string;
}

export const AlertMessage: React.FC<AlertMessageProps> = ({
  children,
  iconComponent = (
    <DisplayIcon iconName="caution-sign" color="yellow" size="small" />
  ),
  "data-testid": dataTestId,
}) => {
  return (
    <div data-testid={dataTestId} className={AlertMessageContainer}>
      <div className={styles.iconContainer}>{iconComponent}</div>
      <div>{children}</div>
    </div>
  );
};

export default AlertMessage;
