import React from "react";
import styles from "./ErrorPage.module.css";
import clsx from "lib/styling-utils/clsx";
import { DisplayIcon } from "@justworkshr/milo-icons";

const {
  errorPage,
  errorPageContent,
  errorPageMessage,
  errorMessageHeader,
  errorMessageDescription,
} = styles;

enum ErrorPageMessageTypes {
  SolutionError = "This integration might be temporarily disabled.",
  IntegrationError = "Something went wrong on our end.",
}

export type ErrorPageProps = {
  error: "Solution Error" | "Integration Error";
};

const ErrorPage: React.FC<ErrorPageProps> = ({ error }) => {
  return (
    <div className={clsx(errorPage, "milo--root")}>
      <div className={errorPageContent}>
        <DisplayIcon iconName="exclamation-mark" color="red" />
        <div className={errorPageMessage}>
          <span className={errorMessageHeader}>
            {error == "Solution Error"
              ? ErrorPageMessageTypes.SolutionError
              : ErrorPageMessageTypes.IntegrationError}
          </span>
          <span className={errorMessageDescription}>
            Try refreshing the page or email support at{" "}
            <a
              className="milo--link-secondary"
              href={`mailto: support@justworks.com`}
            >
              support@justworks.com
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
