import styles from "pages/company-settings/components/ReverseWireAuthTables.module.css";

const { table, row, leftCell, leftEnd, rightCell, rightEnd } = styles;

export default function ReverseWireAuthJpmTable() {
  return (
    <table className={table}>
      <tr className={row}>
        <td className={leftCell}>
          <b>Upon receipt of instruction from</b>
        </td>
        <td className={rightCell}>
          <b>JP Morgan Chase</b>
        </td>
      </tr>
      <tr className={row}>
        <td className={leftCell}>Representative name</td>
        <td className={rightCell}>JP Morgan Chase</td>
      </tr>
      <tr className={row}>
        <td className={leftCell}>Representative address</td>
        <td className={rightCell}>270 Park Avenue, New York, NY 10172</td>
      </tr>
      <tr className={row}>
        <td className={leftCell}>Representative ABA number</td>
        <td className={rightCell}>021000021</td>
      </tr>
      <tr className={row}>
        <th className={leftCell}>
          <b>Authorization to credit</b>
        </th>
        <th className={rightCell}>
          <b>JP Morgan Chase</b>
        </th>
      </tr>
      <tr className={row}>
        <td className={leftCell}>Account number on representative’s books</td>
        <td className={rightCell}>576310525</td>
      </tr>
      <tr className={row}>
        <td className={leftEnd}>Account or third party name</td>
        <td className={rightEnd}>Justworks Employment Group LLC</td>
      </tr>
    </table>
  );
}
