import React from "react";
import { SystemIcon } from "@justworkshr/milo-icons";
import styles from "./JazzHRSetup.module.css";
import {
  PmtIntegration,
  PmtIntegrationInstancesByKey,
} from "../../../../../../types";
import { getInstanceSuccessMap } from "../../lever/setup-steps/utils";

const {
  statusMessage,
  failedWebhookState,
  testIntegrationIcon,
  stepFailedLink,
} = styles;

interface StatusMessageProps {
  integrationSetupSuccess: boolean;
  currentStep: number;
}

enum ErrorMessagesPerStep {
  StepOne = "Integration setup failed. Try again or contact support.",
}

const ErrorMessageStepTwo = (
  <div data-testid="ErrorMessageStepTwo">
    We couldn’t generate the client secret, please try again. If it still
    doesn’t work,{" "}
    <a className={stepFailedLink} href="mailto:support@justworks.com">
      contact customer support
    </a>
    .
  </div>
);

const ErrorMessageStepThree = (
  <div data-testid="ErrorMessageStepThree">
    We weren't able to verify the integration. <strong>Retry</strong> or go back
    to create the webhook again. If it still doesn't work,{" "}
    <a className={stepFailedLink} href="mailto:support@justworks.com">
      contact customer support
    </a>
    .
  </div>
);

enum SuccessMessagesPerStep {
  StepThree = "JazzHR connected",
}

function getCurrentStepSuccessMessage(currentStep: number): string {
  switch (currentStep) {
    case 3:
      return SuccessMessagesPerStep.StepThree;
    default:
      return "";
  }
}

function getCurrentStepErrorMessage(currentStep: number): string | JSX.Element {
  switch (currentStep) {
    case 1:
      return ErrorMessagesPerStep.StepOne;
    case 2:
      return ErrorMessageStepTwo;
    case 3:
      return ErrorMessageStepThree;
    default:
      return "";
  }
}

export const StatusMessage: React.FC<StatusMessageProps> = ({
  integrationSetupSuccess,
  currentStep,
}) => {
  const statusMessageColor = {
    "--status-message-color": integrationSetupSuccess
      ? "var(--intent-color-text-success-default)"
      : "var(--intent-color-text-danger-default)",
  } as React.CSSProperties;

  if (integrationSetupSuccess) {
    return (
      <div className={statusMessage} style={statusMessageColor}>
        <SystemIcon iconName="check-circle-inverted" color="success" />
        {getCurrentStepSuccessMessage(currentStep)}
      </div>
    );
  }

  return (
    <div className={failedWebhookState}>
      <div className={statusMessage} style={statusMessageColor}>
        <SystemIcon
          className={testIntegrationIcon}
          iconName="warning"
          color="error"
          size={"large"}
        />
        {getCurrentStepErrorMessage(currentStep)}
      </div>
    </div>
  );
};

export const getStepForJazzhr = (
  integration: PmtIntegration | undefined | null,
  recentIntegrationInstances: PmtIntegrationInstancesByKey[] | undefined | null
) => {
  // steps: 0) create 1) create webhook 2) test integration/finish setup
  if (!integration || integration.status === "inactive") return 0; // we don't have an active integration

  const instanceSuccessMap = getInstanceSuccessMap(recentIntegrationInstances);
  // if we don't have a successful verify_candidate_hired event, or integration status is "not_started" we need to setup the webhook
  if (
    integration.status === "not_started" ||
    !instanceSuccessMap?.verify_candidate_hired
  )
    return 1;

  // if we have a successful verify webhook event, and integration status is active
  return integration.status === "active" &&
    instanceSuccessMap?.verify_candidate_hired
    ? 2
    : 1;
};
