import { FC } from "react";
import { useGetVisierBenefitsMetricsQuery } from "types/generated/operations";
import { FULL_PATHS } from "../../constants";
import { usePeopleAnalyticsCapabilities } from "../../hooks";
import { formatDollars } from "../../utils";
import CompanyOverviewCard from "../company-overview-card";
import CompanyOverviewMetric from "../company-overview-metric";
import MetricsProhibitedContent from "../metrics-prohibited-content";
import styles from "../company-overview-card/company-overview-card.module.css";

const BenefitsMetricsCard: FC = () => {
  const { memberCompanyHasPlusAccess, memberCompanyHasBenefits } =
    usePeopleAnalyticsCapabilities();

  const { data } = useGetVisierBenefitsMetricsQuery({
    skip: !memberCompanyHasBenefits,
  });

  const { companyCostOfBenefitsLastYear, employeeCostOfBenefitsLastYear } =
    data?.visierBenefitsMetrics || {
      companyCostOfBenefitsLastYear: undefined,
      employeeCostOfBenefitsLastYear: undefined,
    };

  const metrics = (
    <div className={styles.companyOverviewMetrics}>
      <h3 className={styles.companyOverviewMetricsHeader}>
        Cost for health benefits
      </h3>

      <div className={styles.metricContainer}>
        {companyCostOfBenefitsLastYear && (
          <CompanyOverviewMetric
            description="Total company cost"
            metric={formatDollars(companyCostOfBenefitsLastYear)}
            subtext={new Date().getFullYear() - 1}
          />
        )}

        {employeeCostOfBenefitsLastYear && (
          <CompanyOverviewMetric
            description="Total employee cost"
            metric={formatDollars(employeeCostOfBenefitsLastYear)}
            subtext={new Date().getFullYear() - 1}
          />
        )}
      </div>
    </div>
  );

  return (
    <CompanyOverviewCard
      title="Health benefits"
      insightsPath={
        memberCompanyHasPlusAccess && memberCompanyHasBenefits
          ? FULL_PATHS.BENEFITS_DASHBOARD
          : undefined
      }
    >
      {(() => {
        if (!memberCompanyHasBenefits)
          return (
            <MetricsProhibitedContent
              header="Your company doesn’t offer health insurance"
              description={
                <>
                  <a href="mailto:accountdevelopment@justworks.com">
                    Contact us
                  </a>{" "}
                  to start offering health insurance to your employees with
                  Justworks.
                </>
              }
            />
          );

        if (!data) return;

        return metrics;
      })()}
    </CompanyOverviewCard>
  );
};

export default BenefitsMetricsCard;
