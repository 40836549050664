import { PageHeader } from "@justworkshr/milo-core";
import { buildWebPath } from "lib/resource-finder";
import styles from "./EditableProfileHeader.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";
import { useTranslation } from "react-i18next";
import { EOR_PROFILE_PATH } from "pages/employer-of-record/routes";
import { SUBPATHS } from "pages/employer-of-record/employee-profile/constants";

type Props = {
  memberUuid: string;
};

export default function EditableProfileHeader({ memberUuid }: Props) {
  const { t } = useTranslation();

  return (
    <PageHeader
      linkPrevious={
        <a
          href={buildWebPath(
            `/cf/employer-of-record${EOR_PROFILE_PATH}/${memberUuid}/${SUBPATHS.PERSONAL_INFO}`
          )}
        >
          <div className={styles.backContainer}>
            <SystemIcon iconName="arrow-left" color="brand" size="small" />
            <span>{t("Back to Profile")}</span>
          </div>
        </a>
      }
      title={t("Edit Profile")}
    />
  );
}
