import React from "react";
import { AuditLogItem } from "../AuditLogItem";
import { getDollarsFromCents } from "pages/expenses/utils";
import { getExpenseText } from "../../AuditLogPage.utils";

interface ReviewedProps {
  name: string;
  occurredAt: string;
  memberName: string;
  totalAmount: number;
  numExpenses: number;
  nextMemberName: string;
  hasManyNextMembers: boolean;
}
export const Reviewed: React.FC<ReviewedProps> = ({
  name,
  occurredAt,
  memberName,
  numExpenses,
  totalAmount,
  nextMemberName,
  hasManyNextMembers,
}) => {
  const renderPreviewText = () => {
    if (hasManyNextMembers) {
      return "Edited request";
    }

    return "Submitted to admin for review";
  };

  const renderDetailText = () => {
    if (hasManyNextMembers) {
      return (
        <>
          {memberName} approved the request {name} for a total reimbursement of{" "}
          {getDollarsFromCents(totalAmount)} with {getExpenseText(numExpenses)}{" "}
          and submitted it to admins for review.
        </>
      );
    }

    return (
      <>
        {memberName} approved the request {name} for a total reimbursement of{" "}
        {getDollarsFromCents(totalAmount)} with {getExpenseText(numExpenses)}{" "}
        and submitted it to {nextMemberName} for review.
      </>
    );
  };

  return (
    <AuditLogItem
      author={memberName}
      occurredAt={occurredAt}
      previewText={renderPreviewText()}
    >
      {renderDetailText()}
    </AuditLogItem>
  );
};

export default Reviewed;
