import styles from "./RequestDetailTabList.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TabList, Tab } from "@justworkshr/milo-core";
import { prependBasePath } from "pages/expenses/constants";
import {
  replaceRequestUuid,
  getSelectedRequestDetailTab,
} from "./RequestDetailTabList.utils";
import { REQUEST_DETAIL_NAVLINK_TYPES } from "./RequestDetailTabList.types";
import { useRequestDetail } from "pages/expenses/store";

const { RequestDetailTabListWrapper } = styles;

interface RequestDetailTabListProps {
  type?: REQUEST_DETAIL_NAVLINK_TYPES;
}

export const RequestDetailTabList: React.FC<RequestDetailTabListProps> = ({
  type,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { request_uuid = "" } = useParams();

  const {
    requestDetail: {
      metadata: { isApproverView },
    },
  } = useRequestDetail();

  const isReviewer =
    type === REQUEST_DETAIL_NAVLINK_TYPES.REVIEWER_REQUEST_DETAILS ||
    isApproverView;

  if (!request_uuid) {
    return null;
  }

  const selected = getSelectedRequestDetailTab(pathname, isReviewer);

  const handleChange = (index: number) => {
    if (index === 0) {
      const nextPath = isReviewer
        ? replaceRequestUuid(prependBasePath("REVIEW_REQUEST"), request_uuid)
        : replaceRequestUuid(prependBasePath("REQUEST_DETAILS"), request_uuid);

      return navigate(nextPath);
    } else {
      return navigate(
        replaceRequestUuid(prependBasePath("AUDIT_LOG"), request_uuid)
      );
    }
  };

  return (
    <div className={RequestDetailTabListWrapper}>
      <TabList selected={selected} onChange={handleChange}>
        <Tab data-testid="request-detail">Request detail</Tab>
        <Tab data-testid="view-details">Activity</Tab>
      </TabList>
    </div>
  );
};
