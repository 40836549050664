import React from "react";
import {
  REQUIRED,
  WHITESPACE_ERROR,
  FIVE_HUNDRED_CHARACTER_LIMIT_AND_EMOJI_ERROR,
} from "pages/expenses/constants";
import { ExpenseCategory } from "types/Expenses";
import {
  invalidStringInputValue,
  invalidWhitespaceInputValue,
} from "pages/expenses/utils";
import { FormField, TextArea } from "@justworkshr/milo-form";
import { isFormFieldHidden } from "../../ExpenseDetailsPage.utils";
import styles from "../../ExpenseDetailsPage.module.css";

const { ExpenseDetailsPageTextAreaWrapper } = styles;

const DESCRIPTION_KEY = "description";

interface DescriptionProps {
  description: {
    value: string;
    error: string;
  };
  handleFormValue: ({
    key,
    value,
  }: {
    key: typeof DESCRIPTION_KEY;
    value: string;
  }) => void;
  handleFormError: ({
    key,
    error,
  }: {
    key: typeof DESCRIPTION_KEY;
    error: string;
  }) => void;
  selectedCategory: ExpenseCategory | null;
}

export const Description: React.FC<DescriptionProps> = React.memo(
  ({ description, selectedCategory, handleFormValue, handleFormError }) => {
    if (
      !selectedCategory ||
      isFormFieldHidden(DESCRIPTION_KEY, selectedCategory)
    ) {
      return null;
    }

    const isRequired = selectedCategory.description === REQUIRED;

    const validateDescriptionValue = (descriptionValue: string) => {
      if (invalidStringInputValue(descriptionValue, 500)) {
        handleFormError({
          key: DESCRIPTION_KEY,
          error: FIVE_HUNDRED_CHARACTER_LIMIT_AND_EMOJI_ERROR,
        });
      } else if (invalidWhitespaceInputValue(descriptionValue)) {
        handleFormError({ key: DESCRIPTION_KEY, error: WHITESPACE_ERROR });
      } else {
        handleFormError({ key: DESCRIPTION_KEY, error: "" });
      }
    };

    const handleDescriptionChange = (
      event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      validateDescriptionValue(event.target.value);

      handleFormValue({
        key: DESCRIPTION_KEY,
        value: event.target.value,
      });
    };

    return (
      <div className={ExpenseDetailsPageTextAreaWrapper}>
        <FormField
          label="Description"
          required={isRequired}
          error={description.error}
        >
          <TextArea
            required={isRequired}
            name={DESCRIPTION_KEY}
            value={description.value}
            onChange={handleDescriptionChange}
            data-testid="description-input"
          />
        </FormField>
      </div>
    );
  }
);
