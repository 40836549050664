import { useState, useEffect } from "react";
import { useGetPeopleAnalyticsCapabilityQuery } from "types/generated/operations";

export const usePeopleAnalyticsCapabilities = () => {
  const [currentMemberUuid, setCurrentMemberUuid] = useState<
    string | undefined
  >();
  const [memberCompanyHasBasicAccess, setMemberCompanyHasBasicAccess] =
    useState(false);
  const [memberCompanyHasPlusAccess, setMemberCompanyHasPlusAccess] =
    useState(false);
  const [memberCompanyHasBenefits, setMemberCompanyHasBenefits] =
    useState(false);
  const [memberCompanyHasPtoPolicies, setMemberCompanyHasPtoPolicies] =
    useState(false);

  const { data, loading } = useGetPeopleAnalyticsCapabilityQuery();

  useEffect(() => {
    if (!data) return;

    setCurrentMemberUuid(data.authenticatedMember.uuid || "");

    setMemberCompanyHasBasicAccess(
      data.authenticatedMember.capabilities.hasAccessToPeopleAnalytics
    );

    setMemberCompanyHasPlusAccess(
      data.authenticatedMember.capabilities.hasAccessToPeopleAnalyticsPlus
    );

    setMemberCompanyHasBenefits(
      data.authenticatedMember.company.isEligibleForBenefits &&
        !data.authenticatedMember.company.enrolledInMico
    );

    setMemberCompanyHasPtoPolicies(
      data.authenticatedMember.company.activePtoPolicies.length > 0
    );
  }, [data]);

  return {
    loading,
    currentMemberUuid,
    memberCompanyHasBasicAccess,
    memberCompanyHasPlusAccess,
    memberCompanyHasBenefits,
    memberCompanyHasPtoPolicies,
  };
};
