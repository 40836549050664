import styles from "./date-select.module.css";

import { useEffect, useState } from "react";
import { Select, SelectOption } from "@justworkshr/milo-form";

import { SelectOptionType, setDays, MONTHS, YEARS } from "./constants";

/*
NOTE:

For Employer Risk purposes we don't need to keep track of multiple timezones
Because of this, it's safe to use UTC for internal field management
In the future, we may need Luxon or an additional library
*/
type DateSelectInputProps = {
  id: string;
  onChange: (date: React.FormEvent<HTMLInputElement> | Date) => void;
  value: Date;
};

const DateSelectInput = ({ id, onChange, value }: DateSelectInputProps) => {
  const [availableDays, setAvailableDays] = useState<SelectOptionType[]>(
    setDays(31)
  );

  const [selectedYear, setSelectedYear] = useState<string>(
    (value && value.getFullYear().toString()) || ""
  );
  const [selectedMonth, setSelectedMonth] = useState<string>(
    (value && (value.getMonth() + 1).toString()) || ""
  );
  const [selectedDay, setSelectedDay] = useState<string>(
    (value && value.getUTCDate().toString()) || ""
  );

  const handleSelectInputChange: (
    {
      value,
    }: {
      value: string;
    },
    setFn: (value: string) => void
  ) => void = ({ value }, setFn) => setFn(value);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (
      selectedYear.length === 0 ||
      selectedMonth.length === 0 ||
      !selectedDay ||
      selectedDay.length === 0
    )
      return;

    onChange(new Date(`${selectedYear}-${selectedMonth}-${selectedDay}`));
  }, [selectedYear, selectedMonth, selectedDay]);

  // Will update days available
  useEffect(() => {
    switch (selectedMonth) {
      // Months with 28/29 Days:
      case "2": {
        // February
        const yearNum = parseInt(selectedYear);
        let leapYear: boolean;

        // Check to see if the currently selected year is a leap year
        if (yearNum % 400 === 0) {
          leapYear = true;
        } else if (yearNum % 100 === 0) {
          leapYear = false;
        } else if (yearNum % 4 === 0) {
          leapYear = true;
        } else {
          leapYear = false;
        }

        return setAvailableDays(setDays(leapYear ? 29 : 28));
      }

      // Months with 30 Days:
      case "4": // April
      case "6": // June
      case "9": // September
      case "11": // November
        return setAvailableDays(setDays(30));

      // Months with 31 Days:
      // January, March, May, July, August, October, December
      default:
        return setAvailableDays(setDays(31));
    }
  }, [selectedYear, selectedMonth]);

  return (
    <span className={styles.DateSelectInputGroup}>
      <div className={styles.selectWrapper}>
        <Select
          id={`${id}-select-month`}
          data-testid={`${id}-select-month`}
          onChange={({ target }) =>
            handleSelectInputChange(target, setSelectedMonth)
          }
          value={selectedMonth}
        >
          {MONTHS.map((month) => {
            return (
              <SelectOption
                value={month.value}
                data-testid={`month-option-${month.value}`}
                key={month.value}
                disabled={month.disabled}
              >
                {month.description}
              </SelectOption>
            );
          })}
        </Select>
      </div>
      <div className={styles.selectDayWrapper}>
        <Select
          id={`${id}-select-day`}
          data-testid={`${id}-select-day`}
          onChange={({ target }) =>
            handleSelectInputChange(target, setSelectedDay)
          }
          value={selectedDay}
        >
          {availableDays.map((day) => {
            return (
              <SelectOption
                value={day.value}
                data-testid={`day-option-${day.value}`}
                key={day.value}
                disabled={day.disabled}
              >
                {day.description}
              </SelectOption>
            );
          })}
        </Select>
      </div>
      <div className={styles.selectWrapper}>
        <Select
          id={`${id}-select-year`}
          data-testid={`${id}-select-year`}
          onChange={({ target }) =>
            handleSelectInputChange(target, setSelectedYear)
          }
          value={selectedYear}
        >
          {YEARS.map((year) => {
            return (
              <SelectOption
                value={year.value}
                data-testid={`year-option-${year.value}`}
                key={year.value}
                disabled={year.disabled}
              >
                {year.description}
              </SelectOption>
            );
          })}
        </Select>
      </div>
    </span>
  );
};

export default DateSelectInput;
