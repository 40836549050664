import { ReactElement } from "react";

import { Button } from "@justworkshr/milo-core";
import { DisplayIcon } from "@justworkshr/milo-icons";
import { WC_DOC_REQUEST_BASE_URL, COI_EDIT_URL } from "../../constants";
import { Link } from "react-router-dom";

import styles from "./request-submitted.module.css";

const {
  customerSupport,
  requestReceived,
  coiSubmission,
  centerGridItem,
  supportGridSection,
  mainGridSection,
  gridContainer,
  coiDescriptionButtons,
} = styles;

export default function RequestSubmissionComplete(): ReactElement {
  const chatLink = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (window.Forethought) {
      window.Forethought("widget", "open");
    }
  };
  return (
    <div className={coiSubmission}>
      <section className={`${requestReceived} ${gridContainer}`}>
        <span className={centerGridItem}>
          <DisplayIcon color="blue" iconName="check-on-page" size="large" />
        </span>
        <span className={mainGridSection}>
          <h1>Next steps for your certificate of insurance</h1>
          <p>
            We&apos;ll process your request with the insurance carrier and email
            you as soon as we have a confirmation. Most COIs are processed and
            confirmed within 3 - 5 business days.
          </p>
          <div className={coiDescriptionButtons}>
            <Button to={WC_DOC_REQUEST_BASE_URL} as={Link}>
              Back to waiver & certificate requests
            </Button>
            <Button to={COI_EDIT_URL} as={Link} variant="outlined">
              Submit another COI
            </Button>
          </div>
        </span>
      </section>
      <section className={`${customerSupport} ${gridContainer}`}>
        <span className={centerGridItem}>
          <DisplayIcon color="blue" iconName="shield" size="medium" />
        </span>
        <span className={supportGridSection}>
          <div>
            <h2>Need further assistance?</h2>
            <p>
              If you need more information or help regarding certificates of
              insurance, reach out to our customer support team.
            </p>
          </div>
        </span>
        <span className={centerGridItem}>
          <Button variant="outlined" type="button" onClick={chatLink}>
            Contact support
          </Button>
        </span>
      </section>
    </div>
  );
}
