import {
  EXPENSE_REIMBURSABLE_LABEL,
  EXPENSE_NON_REIMBURSABLE_TYPE,
  EXPENSE_NON_REIMBURSABLE_LABEL,
} from "pages/expenses/constants";
import styles from "../../ExpenseLibrary.module.css";
import { Button } from "@justworkshr/milo-core";
import { CheckboxInput } from "@justworkshr/milo-form";
import { formatDate, getDollarsFromCents } from "pages/expenses/utils";
import React from "react";
import {
  ExpenseLibraryExpense,
  ExpenseLibraryExpenseReceipt,
} from "types/Expenses";

const { ExpenseLibraryExpenseType } = styles;

interface ExpenseLibraryTableRowProps {
  expense: ExpenseLibraryExpense;
  expenseIndex: number;
  isCheckboxActive: boolean;
  onExpenseChange: (
    data: {
      expense: ExpenseLibraryExpense;
      expenseIndex: number;
    } | null
  ) => void;
  onCheckboxChange: () => void;
  showExpenseDetailDrawer: boolean;
  isCategoryWithNonReimbursableExpenses: boolean;
}

export const ExpenseLibraryTableRow: React.FC<ExpenseLibraryTableRowProps> =
  React.memo(
    ({
      expense,
      expenseIndex,
      onExpenseChange,
      isCheckboxActive,
      onCheckboxChange,
      showExpenseDetailDrawer,
      isCategoryWithNonReimbursableExpenses,
    }) => {
      const onReceiptClick = (receipts: ExpenseLibraryExpenseReceipt[]) =>
        receipts.forEach((receipt) => {
          window.open(receipt.url, "_blank", "noopener,noreferrer");
        });

      const renderReceipts = () => {
        if (expense?.receipts?.length > 0) {
          return (
            <Button
              size="sm"
              type="button"
              variant="ghost"
              onClick={() => onReceiptClick(expense.receipts)}
            >
              {expense.receipts.length} attached
            </Button>
          );
        }

        return <>0 attached</>;
      };

      const renderExpenseType = () => {
        if (!isCategoryWithNonReimbursableExpenses || !expense?.expenseType) {
          return null;
        }

        const expenseTypeLabel =
          expense.expenseType === EXPENSE_NON_REIMBURSABLE_TYPE
            ? EXPENSE_NON_REIMBURSABLE_LABEL
            : EXPENSE_REIMBURSABLE_LABEL;

        return (
          <div className={ExpenseLibraryExpenseType}>{expenseTypeLabel}</div>
        );
      };

      const renderCreationDate = () => {
        const formattedDate = formatDate(expense.createdAt);

        if (showExpenseDetailDrawer) {
          return (
            <Button
              size="sm"
              type="button"
              variant="ghost"
              onClick={() => onExpenseChange({ expense, expenseIndex })}
              data-testid="expense-drawer"
            >
              {formattedDate}
            </Button>
          );
        }

        return <>{formattedDate}</>;
      };

      return (
        <tr>
          <td>
            <CheckboxInput
              id={expense.uuid}
              name={expense.uuid}
              value={expense.uuid}
              checked={isCheckboxActive}
              onChange={onCheckboxChange}
              aria-label={`${isCheckboxActive ? "Deselect" : "Select"} Expense`}
              data-testid="request-checkbox"
            />
          </td>
          <td>{renderCreationDate()}</td>
          <td>{expense?.merchant ?? <>&#8212;</>}</td>
          <td>{expense.category.name}</td>
          <td>{formatDate(expense.transactionDate)}</td>
          <td>
            {getDollarsFromCents(expense.amount)}
            {renderExpenseType()}
          </td>
          <td>{renderReceipts()}</td>
        </tr>
      );
    }
  );
