import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { buildWebPath } from "lib/resource-finder";
import {
  PROFILE_INFO_PATH,
  CONTACT_INFO_PATH,
  ID_VERIFICATION_PATH,
  PAYMENT_INFO_PATH,
  TAX_INFO_PATH,
  buildEoROnboardingPath,
} from "../routes";

const useStepNavigation = () => {
  const navigate = useNavigate();

  const navigateToStep = useCallback(
    (state: string) => {
      switch (state) {
        case "profile-info":
          navigate(buildEoROnboardingPath(PROFILE_INFO_PATH));
          break;
        case "contact-info":
          navigate(buildEoROnboardingPath(CONTACT_INFO_PATH));
          break;
        case "id-verification":
          navigate(buildEoROnboardingPath(ID_VERIFICATION_PATH));
          break;
        case "payment-info":
          navigate(buildEoROnboardingPath(PAYMENT_INFO_PATH));
          break;
        case "tax-info":
          navigate(buildEoROnboardingPath(TAX_INFO_PATH));
          break;
        default:
          window.location.replace(buildWebPath("/"));
          break;
      }
    },
    [navigate]
  );

  return { navigateToStep };
};

export default useStepNavigation;
