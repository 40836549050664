import { DeductionsSettingTypes } from "./DeductionsSettingInputRow.types";

export const PAYMENT_DETAILS_DEDUCTION_SETTINGS_OPTIONS = {
  [DeductionsSettingTypes.ALL]: {
    label: "Make all deductions",
    description: "Withhold all benefit contributions, including 401(k).",
  },
  [DeductionsSettingTypes.ONLY_401K]: {
    label: "Only deduct 401k contributions",
    description:
      "Just withhold your employees’ 401(k) contribution and debit your company for any applicable match.",
  },
  [DeductionsSettingTypes.NONE]: {
    label: "Do not make any deductions",
    description: "Send the entire amount.",
  },
};
