import React, { useContext } from "react";
import styles from "./JobInformation.module.css";
import { useProfileContextData } from "../../contexts/ProfileInfoContext";
import { convertFromCents, formatDate, getEmployeeName } from "../../utils";
import Row from "../Row/Row";
import { SupportedCountriesContext } from "../../../contexts/supportedCountriesContext";
import { useTranslation } from "react-i18next";
import RequestChangesAction from "../RequestChangesAction/RequestChangesAction";
import EditAction from "../EditAction/EditAction";
import { Card } from "@justworkshr/milo-core";
import { useGetAdminCapabilitiesQuery } from "types/generated/operations";

interface StringMap {
  [key: string]: string;
}

interface DetailsMap {
  [key: string]: string | undefined;
}

const JobInformation: React.FC<{ editable: boolean }> = ({ editable }) => {
  const { t } = useTranslation();
  const { profileInfo } = useProfileContextData();
  const { data: capabilities } = useGetAdminCapabilitiesQuery();
  const { getCountryNameByCode, getCurrencySymbol } = useContext(
    SupportedCountriesContext
  );

  const startDate =
    profileInfo?.eorEmployeeProfile.employment?.actualStartDate ||
    profileInfo?.eorEmployeeProfile.contract?.startDate;
  const formattedStartDate = formatDate(startDate);

  const generalInfoValues: DetailsMap = {
    workId: profileInfo?.eorEmployeeProfile.jobInformation?.workId || "",
    department:
      profileInfo?.eorEmployeeProfile.jobInformation?.department || "",
    manager: getEmployeeName(
      profileInfo?.eorEmployeeProfile.jobInformation?.managerInfo
    ),
  };

  const generalInfoMap: StringMap = {
    workId: "Work ID",
    department: "Department",
    manager: "Manager",
  };

  const managerLink =
    profileInfo?.eorEmployeeProfile.jobInformation?.managerInfo?.uuid;
  const linkMap: StringMap = {
    manager: `members/${managerLink || "#"}`,
  };

  const countryCode = profileInfo?.eorEmployeeProfile.employment?.workCountry;
  const pay = convertFromCents(profileInfo?.eorEmployeeProfile.role?.payRate);

  const editableContractDetails: DetailsMap = {
    startDate: formattedStartDate,
    contractTerm: profileInfo?.eorEmployeeProfile.contract?.term || "",
    title: profileInfo?.eorEmployeeProfile.role?.title || undefined,
    office: profileInfo?.eorEmployeeProfile.jobInformation?.office || "",
    workCountry: getCountryNameByCode(countryCode),
    pay: `${getCurrencySymbol(
      countryCode
    )}${pay?.toLocaleString()} ${profileInfo?.eorEmployeeProfile.role?.currency?.toUpperCase()} ${
      profileInfo?.eorEmployeeProfile.role?.payBasis === "salary"
        ? "per year"
        : "per hour"
    }`,
  };

  const staticContractDetails: DetailsMap = {
    exemptionStatus: "Exempt",
    memberType: "Employer of Record (EOR)",
  };

  const mxContractDetails: DetailsMap = {
    mxChristmasBonusDays:
      profileInfo?.eorEmployeeProfile.role?.mxChristmasBonusDays?.toString(),
    mxHasInfonavitLoan: profileInfo?.eorEmployeeProfile.personalInfo
      ?.mxHasInfonavitLoan
      ? "Yes"
      : "No",
    mxHasFonacotLoan: profileInfo?.eorEmployeeProfile.personalInfo
      ?.mxHasFonacotLoan
      ? "Yes"
      : "No",
  };

  const contractDetailsLabels: StringMap = {
    startDate: "Start Date",
    contractTerm: "Contract term",
    title: "Title",
    office: "Office",
    workCountry: "Work country",
    pay: "Pay",
    mxChristmasBonusDays: "Christmas bonus (aguinaldo) days",
    mxHasInfonavitLoan: "Do you have an infonavit loan?",
    mxHasFonacotLoan: "Do you have a fonacot loan?",
    exemptionStatus: "Exemption status",
    memberType: "Justworks service",
  };

  const getContractDetailsValues = () => {
    switch (countryCode) {
      case "MX":
        return {
          ...editableContractDetails,
          ...mxContractDetails,
          ...staticContractDetails,
        };
      default:
        return { ...editableContractDetails, ...staticContractDetails };
    }
  };

  const subTag: StringMap = {
    pay: `Paid ${profileInfo?.eorEmployeeProfile.role?.payFrequency}`,
  };

  const renderRows = (
    data: DetailsMap,
    map: StringMap,
    hiddenValues: string[] = []
  ) =>
    Object.entries(data).map(([key, value]) => (
      <Row
        key={key}
        label={map[key]}
        value={value}
        showHideButton={hiddenValues.includes(key)}
        subtag={subTag[key] ?? null}
        capitalizeValue={!hiddenValues.includes(key)}
        link={Object.keys(linkMap).includes(key) ? linkMap[key] : null}
      />
    ));

  const contractDetailsValues = getContractDetailsValues();

  if (
    !capabilities?.authenticatedMember.capabilities.hasAccessToAdminProfileView
  ) {
    // Only show these fields to admins.
    delete contractDetailsValues.exemptionStatus;
    delete contractDetailsValues.memberType;
  }

  return (
    <div className={styles.cardSection}>
      <Card
        title={t("General Information")}
        actions={[<EditAction target="job" enabled={editable} />]}
      >
        {renderRows(generalInfoValues, generalInfoMap)}
      </Card>

      <Card title={t("Contract Details")} actions={[<RequestChangesAction />]}>
        {renderRows(contractDetailsValues, contractDetailsLabels, ["pay"])}
      </Card>
    </div>
  );
};

export default JobInformation;
