import styles from "../../CategoriesPage.module.css";

import { Card } from "@justworkshr/milo-core";

import { CategoriesCardRow } from "../CategoriesCardRow/CategoriesCardRow";
import { useCategoriesStore } from "pages/expenses/store";
import { DEFAULT_CATEGORY_DESCRIPTION } from "../../CatagoriesPage.constants";
import { SettingsTable } from "pages/expenses/components";

const { CategoriesCardWrapper } = styles;

export const DefaultCategoriesCard = () => {
  const {
    categories: { defaultCategories },
  } = useCategoriesStore();

  return (
    <Card
      title="Default"
      className={CategoriesCardWrapper}
      description={DEFAULT_CATEGORY_DESCRIPTION}
    >
      <SettingsTable headers={["Active", "Category", ""]}>
        {defaultCategories.map((category) => (
          <CategoriesCardRow key={category.uuid} category={category} />
        ))}
      </SettingsTable>
    </Card>
  );
};

export default DefaultCategoriesCard;
