import { FC } from "react";
import useOnboardingForm from "../hooks/useOnboardingForm";
import ProfileInfoCO from "./form/co/ProfileInfoCO";
import ProfileInfoES from "./form/es/ProfileInfoES";
import ProfileInfoGlobal from "./form/global/ProfileInfoGlobal";
import ProfileInfoIE from "./form/ie/ProfileInfoIE";
import ProfileInfoIN from "./form/in/ProfileInfoIN";
import ProfileInfoMX from "./form/mx/ProfileInfoMX";
import ProfileInfoNL from "./form/nl/ProfileInfoNL";
import ProfileInfoPT from "./form/pt/ProfileInfoPT";

const STATE_KEY = "profile-info";

const ProfileInfo: FC = () => {
  const {
    memberData: { workCountry },
  } = useOnboardingForm(STATE_KEY);

  // NOTE: work_country exists in the database on member, role and, employment
  //  during onboarding, the workCountry variable is driven by the value in the
  //  EMPLOYMENT table
  switch (workCountry) {
    case "CO":
      return <ProfileInfoCO />;
    case "ES":
      return <ProfileInfoES />;
    case "IE":
      return <ProfileInfoIE />;
    case "IN":
      return <ProfileInfoIN />;
    case "MX":
      return <ProfileInfoMX />;
    case "NL":
      return <ProfileInfoNL />;
    case "PT":
      return <ProfileInfoPT />;
    default:
      return <ProfileInfoGlobal />;
  }
};

export default ProfileInfo;
