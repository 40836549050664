import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { PageHeader, Button } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import { FULL_PATHS } from "../../constants";
import { useInsightsLearnPageViewContext } from "../../contexts/insights-learn-page-view-context";
import InsightsLearnPageHero from "../../images/insights-pdp-hero-1472x904.png";
import CollapsiblePanel from "../collapsible-panel";
import styles from "./insights-learn-page.module.css";

const InsightsLearnPage: FC = () => {
  const { setHasViewedInsightsLearnPage } = useInsightsLearnPageViewContext();
  useEffect(setHasViewedInsightsLearnPage, [setHasViewedInsightsLearnPage]);

  return (
    <div
      className={`milo--grid ${styles.insightsLearnPage}`}
      data-testid="insights-learn-page"
    >
      <PageHeader
        className={styles.pageHeader}
        border={false}
        title="Insights"
      />

      <div className={styles.mainContent}>
        <img src={InsightsLearnPageHero}></img>

        <header>
          <h2>Insights</h2>
          <p>
            With Insights, you can make data-driven, strategic decisions about
            your workforce, without a data science degree. Access high-level
            statistics, interactive charts, and dashboards that give you a deep
            understanding of your organization. Gain clarity on areas such as
            employee turnover, pay equity, and time off to help you improve
            business and talent outcomes.
          </p>
        </header>

        <h2>Capabilities</h2>

        <section>
          <h3>Dashboards</h3>
          <p>
            We designed four detailed dashboards that give you visibility into
            the the major categories of your organization: Workforce, Time off,
            Finances, and Health Benefits. View detailed charts, graphs, and
            statistics that will provide a holistic view of your people data.
          </p>
        </section>

        <section>
          <h3>Scheduled emails</h3>
          <p>
            Schedule insights to be delivered straight to your inbox on a
            frequency of your choice. Schedule an email once or have the them
            sent on a recurring basis.
          </p>
        </section>

        <section>
          <h3>Share with your team</h3>
          <p>
            Share an insight with specific coworkers directly through Justworks.
            Users will receive an email notification and will only be able to
            view the insight if they have the appropriate permissions.
          </p>
        </section>

        <section>
          <h3>Download</h3>
          <p>
            Download insights in multiple formats including PDF, Excel, or
            Powerpoint. The download is a copy of what the dashboard looks like
            when presenting in full screen and includes all headers, text,
            visuals, and links.
          </p>
        </section>

        <div className={styles.faqs}>
          <h2>FAQs</h2>

          <CollapsiblePanel title="What type of data is available in Insights?">
            <p>
              The four main categories of data available in the Insights tool
              are Workforce, Time off, Compensation, and Health Benefits.
            </p>
          </CollapsiblePanel>

          <CollapsiblePanel title="How did Justworks build this People Analytics tool?">
            <p>
              You can use your People Analytics data to more easily identify
              patterns, trends, and correlations, providing valuable insight
              into financial metrics and performance, benefits usage, employee
              turnover, time off, and more. This can help guide decision-making
              and strategy development. Learn more about common use cases by
              navigating to our Resources linked above.
            </p>
          </CollapsiblePanel>

          <CollapsiblePanel title="What type of data is available in Insights?">
            <p>
              Justworks is proud to work with our enablement partner - Visier -
              on a People Analytics tool customized for the needs of your
              business. This solution combines Visier’s expertise in processing
              and visualizing data with Justworks' expertise in designing
              simple, secure solutions for small businesses.
            </p>
          </CollapsiblePanel>

          <CollapsiblePanel title="How do Justworks and Visier handle my workforce data?">
            <p>
              At Justworks, trust, safety, and security are core values, and we
              are committed to protecting your data. We chose to partner with{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.visier.com/"
              >
                Visier
              </a>{" "}
              because of their alignment with these values. Visier has
              industry-leading privacy and security protocols in place to ensure
              they continually adhere to the highest standards of practice.{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.visier.com/trust/"
              >
                Read more about Visier's Privacy Commitment.
              </a>
            </p>
          </CollapsiblePanel>
        </div>
      </div>

      <div className={styles.sidebar}>
        <div className={styles.detailBox}>
          <h2>Insights</h2>

          <section>
            <h4>Permissions required</h4>
            <p>
              You will have access to the same level of permissions in Insights
              as you already have as a Justworks Admin.
            </p>
          </section>

          <Button as={Link} to={FULL_PATHS.COMPANY_OVERVIEW}>
            Go to Insights
          </Button>
        </div>

        <div className={styles.resourcesBox}>
          <h3>Resources</h3>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.justworks.com/hc/en-us/articles/22463671611931"
          >
            <span>
              Learn more
              <br />
              about People Analytics
            </span>
            <SystemIcon iconName={"external-link"} color="brand" />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.justworks.com/hc/en-us/articles/22463885583771"
          >
            <span>Learn more about Insights</span>
            <SystemIcon iconName={"external-link"} color="brand" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default InsightsLearnPage;
