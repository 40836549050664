import { ROUTES } from "pages/expenses/constants";
import { getRoute } from "pages/expenses/utils";

export const getSelectedRequestDetailsTab = (pathname: string) => {
  const route = getRoute(pathname);

  switch (route) {
    case ROUTES.REQUEST_DETAILS:
      return 0;
    case ROUTES.REVIEW_REQUEST:
      return 0;
    case ROUTES.AUDIT_LOG:
      return 1;
    default:
      return 0;
  }
};
