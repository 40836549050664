import Steps from "pages/company-settings/components/Steps";
import styles from "pages/company-settings/components/MicrodepositNextStepsInfo.module.css";

const { info, title } = styles;

export default function MicrodepositNextStepsInfo() {
  const steps = [
    {
      title: "1. Initiate the test",
      description: () => (
        <span>
          We’ll send two small deposits to your bank account. Each deposit
          amount should not be larger than one dollar.
        </span>
      ),
    },
    {
      title: "2. Find the deposits",
      description: () => (
        <span>
          Later, you should see the deposits from “JUSTWORKS VERIFY” in your
          bank account.
        </span>
      ),
    },
    {
      title: "3. Confirm the amounts",
      description: () => (
        <span>
          Navigate to Justworks platform to enter the two deposit amounts to
          confirm your account.
        </span>
      ),
    },
  ];
  return (
    <div className={info}>
      <h3 className={title}>Next Steps</h3>
      <Steps steps={steps} />
    </div>
  );
}
