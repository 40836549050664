import { useEffect, useState } from "react";
import { fetchClient } from "@justworkshr/fe-utility-kit";
import { buildClockworkWebPath } from "lib/resource-finder";
import { ActionFooter } from "@justworkshr/milo-form";
import { Button, Alert, Dialog } from "@justworkshr/milo-core";
import CompanyBankNotice, {
  NoticeTypes,
} from "pages/company-settings/components/CompanyBankNotice";
import MicrodepositNextStepsInfo from "pages/company-settings/components/MicrodepositNextStepsInfo";
import { useSubmitMicrodepositTest } from "pages/company-settings/hooks/useSubmitMicrodepositTest";
import { useBankForm } from "pages/company-settings/hooks/useBankForm";
import styles from "pages/company-settings/components/InitiateMicrodepositPage.module.css";
import BankAccountPageHeader from "./BankAccountPageHeader";

const { page, notice, footer, content } = styles;

export default function InitiateMicrodepositPage() {
  const [isPayrollWarningOpen, setIsPayrollWarningOpen] = useState(false);
  const closePayrollWarning = () => setIsPayrollWarningOpen(false);
  const [nextPayrollDate, setNextPayrollDate] = useState("");
  const { formData, returnToFormPage } = useBankForm();
  const { mutate, error, isPending } = useSubmitMicrodepositTest();

  useEffect(() => {
    if (
      !formData.accountNumber &&
      !formData.routingNumber &&
      !formData.bankName
    ) {
      returnToFormPage();
    }
  }, [formData, returnToFormPage]);

  const formatNextPayrollDate = (iso_date: string) => {
    const date = new Date(
      Date.UTC(
        parseInt(iso_date.substring(0, 4), 10), // Year
        parseInt(iso_date.substring(5, 7), 10) - 1, // Month (zero-based)
        parseInt(iso_date.substring(8, 10), 10) // Day
      )
    );

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const day = daysOfWeek[date.getUTCDay()];
    const monthDate = `${date.toLocaleString("default", {
      month: "short",
    })} ${date.getUTCDate()}`;
    const formattedDate = `${day}, ${monthDate}`;
    return formattedDate;
  };

  useEffect(() => {
    const getNextPayrollDate = async () => {
      try {
        const url = `/company/bank_account/payroll_date`;
        const response = await fetchClient.get(buildClockworkWebPath(url));
        if (response.ok) {
          const data = await response.json();
          if (data.next_payroll_forecast_date) {
            setNextPayrollDate(
              formatNextPayrollDate(data.next_payroll_forecast_date)
            );
          }
        }
      } catch (error) {
        return;
      }
    };
    getNextPayrollDate();
  }, []);

  const initiateMicrodepositTest = () => {
    if (isPayrollWarningOpen) {
      closePayrollWarning();
    }
    mutate(formData);
  };

  const showPayrollWarning = () => {
    if (nextPayrollDate) {
      setIsPayrollWarningOpen(true);
      return;
    }
    initiateMicrodepositTest();
  };

  return (
    <>
      {error && (
        <Alert color="destructive">
          Something went wrong. Please try submitting again.
        </Alert>
      )}
      <div className={page}>
        <BankAccountPageHeader>
          Add the details for the new business bank account that you’d like to
          use. We securely verify your account information with GIACT, a trusted
          security industry leader.
        </BankAccountPageHeader>
        <div className={content}>
          <div className={notice}>
            <CompanyBankNotice
              title={"We’re unable to verify the new account"}
              type={NoticeTypes.INFO}
            >
              We can't validate your account information. But, you can still use
              the microdeposit test to verify the account.
            </CompanyBankNotice>
          </div>
          <MicrodepositNextStepsInfo />
          <ActionFooter
            className={footer}
            secondary={
              <Button
                variant="outlined"
                onClick={returnToFormPage}
                key="cancel-button"
              >
                Go Back
              </Button>
            }
            actions={[
              <Button
                data-testid="submit-button"
                type="submit"
                onClick={showPayrollWarning}
                loading={isPending}
                key="save-button"
              >
                Initiate Test
              </Button>,
            ]}
          />
          <Dialog
            isOpen={isPayrollWarningOpen}
            onClose={closePayrollWarning}
            showCloseButton={false}
            primaryButton={
              <Button onClick={initiateMicrodepositTest} variant="filled">
                Initiate Test
              </Button>
            }
            secondaryButton={
              <Button onClick={closePayrollWarning} variant="outlined">
                Cancel
              </Button>
            }
            title="Initiating the test today may affect payroll"
          >
            <div>
              <p>
                Payroll is scheduled to be run on {nextPayrollDate}. If you
                initiate the test today, it could affect payroll and any other
                pending payments.
              </p>
              <br />
              <p>
                You can also <b>Cancel</b> and re-enter your new bank account
                info at a later time. What would you like to do?
              </p>
            </div>
          </Dialog>
        </div>
      </div>
    </>
  );
}
