import React, { FC, useState } from "react";
import { Alert, Button, PageHeader, Card } from "@justworkshr/milo-core";
import {
  ActionFooter,
  Form,
  FormField,
  FileInput,
} from "@justworkshr/milo-form";
import { Formik, type FormikProps } from "formik";
import useOnboardingForm from "../hooks/useOnboardingForm";
import Layout from "../Layout";
import { FileInputType, TaxInfoFormType } from "./types";
import onboardingStyles from "../Onboarding.module.css";
import { initialValues } from "./constants";
import styles from "./TaxInfo.module.css";
import { useTranslation } from "react-i18next";
import { TaxInfoConfig } from "./TaxInfoForm/tax-info-config";

const { cards, fileInputField } = styles;

const state_key = "tax-info";

async function readAsDataURL(file: File): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = () => {
      resolve(fr.result?.toString());
    };
    fr.readAsDataURL(file);
  });
}

const TaxInfo: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    memberData: { workCountry },
    form: {
      submitting,
      navigatingBack,
      showErrorAlert,
      errorAlertMessage,
      onSubmit,
      onBack,
    },
    memberOnboarding: { stepNumber, redirecting, idVerificationStatus },
  } = useOnboardingForm(state_key);
  const taxInfoConfig = workCountry ? TaxInfoConfig[workCountry] : [];

  const [fileInputs, setFileInputs] = useState<Record<string, FileInputType>>(
    Object.fromEntries(
      taxInfoConfig.map((taxDocumentConfig) => [
        taxDocumentConfig.name,
        { dataUrl: undefined, file: undefined },
      ])
    )
  );

  // Once a file has been selected via the FileInput component,
  // this function will read the file and convert it to a dataUrl
  // and associated File object. These values will be managed using
  // the component state and extracted / used during form submission
  // per the onFormSubmit function.
  const onSelectFile = async (
    field: string,
    file: File | File[] | undefined,
    fileForm: Record<string, FileInputType>,
    setFileForm: React.Dispatch<
      React.SetStateAction<Record<string, FileInputType>>
    >
  ): Promise<void> => {
    let selectedFile: File | undefined = undefined;
    if (file && Array.isArray(file)) {
      if (file.length > 0) {
        selectedFile = file[0];
      } else if (file instanceof File) {
        selectedFile = file;
      }
    }

    const processedFile =
      selectedFile !== undefined
        ? await readAsDataURL(selectedFile)
        : undefined;

    if (selectedFile && processedFile === undefined) {
      setFileForm({
        ...fileForm,
        [field]: {
          dataUrl: undefined,
          file: undefined,
        },
      });
    } else {
      setFileForm({
        ...fileForm,
        [field]: {
          dataUrl: processedFile,
          file: processedFile ? selectedFile : undefined,
        },
      });
    }
  };

  const validateAndSelectFile = (
    field: string,
    setFieldError: (field: string, message: string | undefined) => void,
    fileForm: Record<string, FileInputType>,
    setFileForm: React.Dispatch<
      React.SetStateAction<Record<string, FileInputType>>
    >,
    file?: File | File[]
  ) => {
    const acceptedFileTypes = ["application/pdf"];

    if (Array.isArray(file) && file.length > 1) {
      setFieldError(field, t("Only one file is accepted, please try again."));
      return;
    } else if (
      (Array.isArray(file) &&
        file.length === 1 &&
        !acceptedFileTypes.includes(file[0].type)) ||
      (!Array.isArray(file) && file && !acceptedFileTypes.includes(file.type))
    ) {
      setFieldError(field, t("Only PDFs are accepted, please try again."));
      return;
    }

    setFieldError(field, undefined);

    onSelectFile(field, file, fileForm, setFileForm);
  };

  const onFormSubmit = () => {
    const taxInfo = Object.fromEntries(
      taxInfoConfig.map((taxDocumentConfig) => [
        taxDocumentConfig.name,
        {
          blobFile: fileInputs[taxDocumentConfig.name].dataUrl,
          contentType: fileInputs[taxDocumentConfig.name].file?.type,
          originalFilename: fileInputs[taxDocumentConfig.name].file?.name,
        },
      ])
    );

    onSubmit({ taxInfo });
  };

  const requiredFilesUploaded = taxInfoConfig.every(
    (taxDocumentConfig) =>
      !taxDocumentConfig.required || !!fileInputs[taxDocumentConfig.name].file
  );
  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {errorAlertMessage ||
            t("An error occurred while submitting your tax information.")}
        </Alert>

        <PageHeader title={t("Submit your tax information")} />

        <Formik initialValues={initialValues} onSubmit={onFormSubmit}>
          {({
            errors,
            handleSubmit,
            setFieldError,
          }: FormikProps<TaxInfoFormType>) => {
            const taxDocumentUploadCards = taxInfoConfig.map(
              (taxDocumentConfig) => (
                <Card
                  key={`${taxDocumentConfig.name}-card`}
                  title={t(taxDocumentConfig.cardTitle)}
                  data-testid={`${taxDocumentConfig.name}-card`}
                >
                  {taxDocumentConfig.cardDescriptionInitialText && (
                    <div>
                      {t(taxDocumentConfig.cardDescriptionInitialText)}
                      {taxDocumentConfig.cardDescriptionLinks?.map((link) => (
                        <>
                          <a href={link.linkUrl} target="_blank">
                            {t(link.linkText)}
                          </a>
                          {t(link.afterLinkText)}
                        </>
                      ))}
                    </div>
                  )}
                  <FormField
                    aria-labelledby="_"
                    name={taxDocumentConfig.name}
                    error={errors.documents}
                    required
                  >
                    <div className={fileInputField}>
                      <FileInput
                        accept="application/pdf"
                        onFileChange={(file) =>
                          validateAndSelectFile(
                            taxDocumentConfig.name,
                            setFieldError,
                            fileInputs,
                            setFileInputs,
                            file
                          )
                        }
                      />
                    </div>
                  </FormField>
                </Card>
              )
            );
            return (
              <Form onSubmit={handleSubmit}>
                <div className={cards}>{taxDocumentUploadCards}</div>

                <ActionFooter
                  className={onboardingStyles.footer}
                  actions={[
                    <Button
                      type="submit"
                      color="brand"
                      loading={submitting}
                      disabled={navigatingBack || !requiredFilesUploaded}
                      key="submit-btn"
                    >
                      {t("Submit")}
                    </Button>,
                  ]}
                  secondary={[
                    <Button
                      type="button"
                      variant="outlined"
                      color="brand"
                      key="back-btn"
                      loading={navigatingBack}
                      onClick={onBack}
                    >
                      {t("Go back")}
                    </Button>,
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </Layout>
  );
};

export default TaxInfo;
