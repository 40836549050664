import { Button } from "@justworkshr/milo-core";
import { FormField, Select, SelectOption } from "@justworkshr/milo-form";
import { SystemIcon } from "@justworkshr/milo-icons";
import {
  FieldArray,
  ArrayHelpers,
  FormikErrors,
  useFormikContext,
} from "formik";
import IntlPhoneNumberInput from "pages/employer-of-record/IntlPhoneNumberInput";
import styles from "./ContactInfo.module.css";
import { useTranslation } from "react-i18next";
import { difference, map } from "lodash";

const dynamicPhoneErrors = (
  errors: FormikErrors<{ type: string; value: string }> | string
) => {
  if (typeof errors == "string") {
    return errors;
  } else {
    return errors.value || errors.type;
  }
};

export const PHONE_TYPES = {
  mobile: "mobile",
  home: "home",
  work: "work",
};

export default function PhoneNumbersFragment({
  workCountry,
}: {
  workCountry?: string;
}) {
  const { values, touched, errors, handleBlur, handleChange } =
    useFormikContext<{
      contactInfo: { phoneNumbers: { type: string; value: string }[] };
    }>();

  const { t } = useTranslation();
  const usedPhoneTypes = map(values.contactInfo.phoneNumbers, "type");
  const unusedPhoneTypes = difference(
    Object.values(PHONE_TYPES),
    usedPhoneTypes
  );

  const addPhoneField = (arrayHelpers: ArrayHelpers) => {
    arrayHelpers.push({
      type: difference(Object.values(PHONE_TYPES), usedPhoneTypes)[0],
      value: "",
    });
  };

  const removePhoneField = (arrayHelpers: ArrayHelpers, index: number) => {
    arrayHelpers.remove(index);
  };

  return (
    <FieldArray
      name="contactInfo.phoneNumbers"
      render={(arrayHelpers: ArrayHelpers) => {
        return (
          <div className={`${styles.formFieldWrapper} ${styles.hideLabel}`}>
            {values.contactInfo.phoneNumbers.map((phone, index) => (
              <FormField
                key={index}
                label={`Phone Option ${index + 1}`}
                name={`contactInfo.phoneNumbers.${index}`}
                required={true}
                error={
                  touched?.contactInfo?.phoneNumbers?.[index] &&
                  errors?.contactInfo?.phoneNumbers?.[index]
                    ? dynamicPhoneErrors(
                        errors?.contactInfo?.phoneNumbers?.[index]
                      )
                    : ""
                }
              >
                <>
                  <IntlPhoneNumberInput
                    className={styles.phoneValueInput}
                    name={`contactInfo.phoneNumbers.${index}.value`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactInfo.phoneNumbers[index].value}
                    countryCode={workCountry}
                  />
                  <div className={styles.phoneTypeSelect}>
                    <Select
                      name={`contactInfo.phoneNumbers.${index}.type`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contactInfo.phoneNumbers[index].type}
                    >
                      {[
                        values.contactInfo.phoneNumbers[index].type,
                        ...unusedPhoneTypes,
                      ].map((type) => (
                        <SelectOption value={type} key={type}>
                          {t(type)}
                        </SelectOption>
                      ))}
                    </Select>
                  </div>
                  {index != 0 && (
                    <div className={styles.removePhoneButtonWrapper}>
                      <Button
                        name={`contactInfo.removePhone.${index}`}
                        className={styles.removePhoneButton}
                        type="button"
                        color="neutral"
                        variant="ghost"
                        onClick={() => removePhoneField(arrayHelpers, index)}
                        size="sm"
                      >
                        <SystemIcon
                          title={t("close")}
                          iconName="close"
                          size="medium"
                        />
                      </Button>
                    </div>
                  )}
                </>
              </FormField>
            ))}
            {values.contactInfo.phoneNumbers.length < 3 && (
              <Button
                name="addPhoneButton"
                type="button"
                leftIcon="plus"
                variant="ghost"
                onClick={() => addPhoneField(arrayHelpers)}
              >
                {t("add phone number")}
              </Button>
            )}
          </div>
        );
      }}
    />
  );
}
