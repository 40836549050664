import { Projects } from "./Projects";
import { AccountingIntegrations } from "./AccountingIntegrations";
import styles from "./AdvancedSettings.module.css";

const { AdvancedSettingsWrapper, Subheading } = styles;

export const AdvancedSettings = () => {
  return (
    <div className={AdvancedSettingsWrapper}>
      <h3 className={Subheading}>Advanced settings</h3>
      <Projects />
      <AccountingIntegrations />
    </div>
  );
};

export default AdvancedSettings;
