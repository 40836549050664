import { ReactElement, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import {
  EditDataProps,
  EmployeeUpdateMethods,
  EmployeeOptionType,
  FormData,
  WaiverJobLocation,
  JobAddressMethods,
  AddressErrors,
} from "../types";
import { JobInformationEdit } from "./job-information-edit";
import { CertificateHolderEdit } from "../../components";
import EmployeeSearchContainer from "./employee-search-container/employee-search-container";
import { PageHeader, Button, Alert } from "@justworkshr/milo-core";
import { Form, ActionFooter } from "@justworkshr/milo-form";

import {
  errorText,
  WC_DOC_REQUEST_BASE_URL,
  WAIVER_REVIEW_URL,
  initialWaiverErrors,
  initialAddressErrors,
  waiverSizes,
  ZIP_REGEX,
  validationErrors,
  initialWaiverLocation,
  addressErrorText,
  useAdditionalMessage,
} from "pages/workers-compensation/document-request/constants";

import styles from "./waiver-edit.module.css";
import WaiverPriority from "./waiver-priority";
import JobLocation from "./job-location/job-location";
import WaiverEditStepper from "./waiver-edit-stepper/waiver-edit-stepper";
import { waiverEditHeaderSteps } from "./waiver-edit-stepper/constants";

const { waiverForm, footer, reviewButton } = styles;

export default function WaiverEdit({
  formData,
  waiverAddresses,
  setWaiverAddresses,
  setFormData,
  submitError,
  setDocuments,
}: EditDataProps): ReactElement {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [showAlert, setShowAlert] = useState(false);
  const [errors, setErrors] = useState({ ...initialWaiverErrors });
  const [currentStep, setCurrentStep] = useState(
    parseInt(searchParams.get("currentStep") || "0")
  );

  useEffect(() => {
    setSearchParams((params) => {
      params.set("currentStep", `${currentStep}`);
      return params;
    });
  }, [currentStep, setSearchParams]);

  const handleDateChange = (
    date: React.FormEvent<HTMLInputElement> | Date,
    index: string
  ) => {
    setFormData((prevData) => {
      return { ...prevData, [index]: date };
    });

    setErrors((prev) => ({
      ...prev,
      [index]: undefined,
    }));
  };

  const handleErrorState = (name: string, value: string | string[]) => {
    if (!errorText[name as keyof typeof errorText]) return;

    if (value.length <= 0) {
      setErrors((prev) => {
        return { ...prev, [name]: errorText[name as keyof typeof errorText] };
      });
    } else {
      setErrors((prev) => {
        return { ...prev, [name]: undefined };
      });
    }
  };

  const handleWaiverLocationError = (address: AddressErrors, key: string) => {
    return {
      ...address,
      [key]: addressErrorText[key as keyof typeof addressErrorText],
    };
  };

  const handleEventChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ): void => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      return { ...prevData, [name]: value };
    });

    handleErrorState(name, value);
  };

  const handleEmployeeUpdate: EmployeeUpdateMethods = {
    add: (employee) => {
      setFormData((prev: FormData) => ({
        ...prev,
        employees: [...prev.employees, employee],
      }));
    },
    remove: (employee) => {
      setFormData((prev: FormData) => ({
        ...prev,
        employees: prev.employees.filter((e: EmployeeOptionType) => {
          return e.uuid !== employee.uuid;
        }),
      }));
    },
    addAll: (employees) => {
      setFormData((prev: FormData) => ({
        ...prev,
        employees,
      }));
    },
    removeAll: () => {
      setFormData((prev: FormData) => ({
        ...prev,
        employees: [],
      }));
    },
  };

  const handleLocationChange: JobAddressMethods = {
    remove: (index: number) => {
      setWaiverAddresses((prev: WaiverJobLocation[]) => [
        ...prev.filter((_, i) => i !== index),
      ]);
    },
    setToCertificateAddress: (index: number) => {
      setWaiverAddresses((prev: WaiverJobLocation[]) => {
        const newArr = prev;
        newArr[index] = {
          street1: formData.street1,
          street2: formData.street2,
          city: formData.city,
          state: formData.state,
          postalCode: formData.postalCode,
          errors: initialAddressErrors,
        };

        return [...newArr];
      });
    },
    add: () => {
      setWaiverAddresses((prev: WaiverJobLocation[]) => [
        ...prev,
        initialWaiverLocation,
      ]);
    },
    update: (index: number, field: string, value: string) => {
      setWaiverAddresses((prev: WaiverJobLocation[]) => {
        const newArr = prev;
        newArr[index] = {
          ...newArr[index],
          [field]: value,
        };

        if (value.length <= 0) {
          newArr[index].errors = handleWaiverLocationError(
            newArr[index].errors,
            field
          );
        } else {
          newArr[index].errors = {
            ...newArr[index].errors,
            [field]: "",
          };
        }

        return [...newArr];
      });
    },
  };

  function validateJobInformation() {
    let isValid = true;
    const formDataJobInformation: string[] = ["jobName", "jobNumber"];
    setErrors({ ...initialWaiverErrors });
    if (formData["jobDuties"] == "") {
      isValid = false;
      setErrors((prev) => ({
        ...prev,
        ["jobDuties"]: errorText["jobDuties"],
      }));
    }
    if (formData.dateStart && formData.dateEnd) {
      if (formData.dateStart > formData.dateEnd) {
        isValid = false;
        setErrors((prev) => ({
          ...prev,
          dateEnd: `End date cannot be before start date`,
        }));
      }
    }
    formDataJobInformation.forEach((key) => {
      if (typeof formData[key as keyof typeof formData] === typeof String()) {
        const value = formData[key as keyof typeof formData] as string;
        if (
          waiverSizes[key as keyof typeof waiverSizes] &&
          value.length > waiverSizes[key as keyof typeof waiverSizes]
        ) {
          isValid = false;

          setErrors((prev) => ({
            ...prev,
            [key as keyof typeof formData]: useAdditionalMessage,
          }));
        }
      }
    });
    return isValid;
  }

  function validateEmployees() {
    let isValid = true;
    setErrors({ ...initialWaiverErrors });
    if (!formData.employees[0]) {
      isValid = false;
      setErrors((prev) => ({
        ...prev,
        employees: errorText.employees,
      }));
    }
    return isValid;
  }

  function validateCertHolder() {
    let isValid = true;
    const formDataCertHolder: string[] = [
      "street1",
      "street2",
      "city",
      "certName",
      "certAdditionalNames",
      "state",
      "postalCode",
      "additionalInformation",
    ];
    setErrors({ ...initialWaiverErrors });
    setWaiverAddresses((locations) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return locations.map(({ errors, ...keepAttrs }) => ({
        ...keepAttrs,
        errors: initialAddressErrors,
      }));
    });
    formDataCertHolder.forEach((key) => {
      if (
        errorText[key as keyof typeof errorText] &&
        formData[key as keyof typeof errorText] === ""
      ) {
        isValid = false;

        setErrors((prev) => ({
          ...prev,
          [key as keyof typeof errorText]:
            errorText[key as keyof typeof errorText],
        }));
      }
    });
    if (!ZIP_REGEX.test(formData.postalCode)) {
      isValid = false;
      setErrors((prev) => ({
        ...prev,
        postalCode: validationErrors.postalCode,
      }));
    }
    formDataCertHolder.slice(0, 5).forEach((key) => {
      if (typeof formData[key as keyof typeof formData] === typeof String()) {
        const value = formData[key as keyof typeof formData] as string;
        if (
          waiverSizes[key as keyof typeof waiverSizes] &&
          value.length > waiverSizes[key as keyof typeof waiverSizes]
        ) {
          isValid = false;

          setErrors((prev) => ({
            ...prev,
            [key as keyof typeof formData]: useAdditionalMessage,
          }));
        }
      }
    });
    return isValid;
  }

  function validateJobLocation() {
    let isValid = true;
    const formDataJobLocation: string[] = [
      "priority",
      "street1",
      "street2",
      "city",
      "state",
      "postalCode",
    ];
    setErrors({ ...initialWaiverErrors });
    setWaiverAddresses((locations) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return locations.map(({ errors, ...keepAttrs }) => ({
        ...keepAttrs,
        errors: initialAddressErrors,
      }));
    });
    formDataJobLocation.forEach((key) => {
      if (
        errorText[key as keyof typeof errorText] &&
        formData[key as keyof typeof errorText] === ""
      ) {
        isValid = false;

        setErrors((prev) => ({
          ...prev,
          [key as keyof typeof errorText]:
            errorText[key as keyof typeof errorText],
        }));
      }
    });
    waiverAddresses.forEach((address, index) => {
      for (const key in address) {
        if (
          addressErrorText[key as keyof typeof addressErrorText] &&
          !address[key as keyof typeof addressErrorText]
        ) {
          isValid = false;
          setWaiverAddresses((prev) => {
            const newArr = [...prev];

            newArr[index] = {
              ...prev[index],
              errors: handleWaiverLocationError(prev[index].errors, key),
            };

            return newArr;
          });
        }
      }
      if (!ZIP_REGEX.test(address.postalCode)) {
        isValid = false;
        setWaiverAddresses((prev) => {
          const newArr = [...prev];

          newArr[index] = {
            ...prev[index],
            errors: {
              ...prev[index].errors,
              postalCode: validationErrors.postalCode,
            },
          };

          return newArr;
        });
      }
    });
    if (!ZIP_REGEX.test(formData.postalCode)) {
      isValid = false;
      setErrors((prev) => ({
        ...prev,
        postalCode: validationErrors.postalCode,
      }));
    }
    return isValid;
  }

  const handleEditSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    navigate(WAIVER_REVIEW_URL);
  };

  function handleBackButton() {
    if (currentStep === 0) {
      return;
    }
    setSearchParams((params) => {
      params.set("currentStep", `${currentStep - 1}`);
      return params;
    });
    setCurrentStep((prev) => prev - 1);
  }

  function handleNextButton() {
    setShowAlert(false);
    if (currentStep === 0) {
      if (validateJobInformation()) {
        setCurrentStep((prev) => prev + 1);
      } else {
        setShowAlert(true);
      }
    } else if (currentStep === 1) {
      if (validateEmployees()) {
        setCurrentStep((prev) => prev + 1);
      } else {
        setShowAlert(true);
      }
    } else if (currentStep === 2) {
      if (validateCertHolder()) {
        setCurrentStep((prev) => prev + 1);
      } else {
        setShowAlert(true);
      }
    } else if (currentStep === 3) {
      if (validateJobLocation()) {
        setCurrentStep((prev) => prev + 1);
      } else {
        setShowAlert(true);
      }
    }
  }

  const waiverEditSteps = [
    <JobInformationEdit
      formData={formData}
      errors={errors}
      handleDateChange={handleDateChange}
      handleEventChange={handleEventChange}
    />,
    <EmployeeSearchContainer
      handleEmployeeUpdate={handleEmployeeUpdate}
      selectedEmployees={formData.employees}
      errors={errors}
    />,
    <CertificateHolderEdit
      formData={formData}
      errors={errors}
      setDocuments={setDocuments}
      handleEventChange={handleEventChange}
      docType="waiver"
    />,
    <WaiverPriority
      priority={formData.priority}
      setPriority={(level: string) => {
        setFormData((prev) => ({
          ...prev,
          priority: level,
        }));
      }}
    />,
  ];

  waiverEditSteps[waiverEditSteps.length - 1] = (
    <>
      <JobLocation
        waiverAddresses={waiverAddresses}
        handleLocationChange={handleLocationChange}
      />
      <WaiverPriority
        priority={formData.priority}
        setPriority={(level: string) => {
          setFormData((prev) => ({
            ...prev,
            priority: level,
          }));
        }}
      />
    </>
  );
  return (
    <div className="milo--grid">
      <div className={waiverForm} data-testid="waiverEditForm">
        <PageHeader
          title="Waiver of Subrogation"
          linkPrevious={<Link to={WC_DOC_REQUEST_BASE_URL}>Back</Link>}
        >
          A waiver of subrogation is an agreement between you and a third party
          agreeing that you will not seek reimbursement from the other party for
          any workers' compensation benefits paid out.
        </PageHeader>
        <Alert color="destructive" visible={submitError.length > 0}>
          {submitError}
        </Alert>
        <WaiverEditStepper
          currentStep={currentStep}
          steps={waiverEditHeaderSteps}
        />
        <hr />
        <Form onSubmit={handleEditSubmit}>
          {waiverEditSteps[currentStep]}
          <Alert color="destructive" visible={showAlert}>
            Please complete all required fields.
          </Alert>
          <ActionFooter
            className={footer}
            actions={[
              <Button
                variant="outlined"
                key="BackWaiverEdit"
                onClick={handleBackButton}
              >
                Back
              </Button>,
              <Button
                variant="filled"
                key="ContinueWaiverEdit"
                className={reviewButton}
                type={
                  currentStep === waiverEditSteps.length ? "submit" : "button"
                }
                onClick={handleNextButton}
              >
                {currentStep === waiverEditSteps.length ? "Review" : "Next"}
              </Button>,
            ]}
          />
        </Form>
      </div>
    </div>
  );
}
