import JobLocationAddress from "./job-location-address";
import { Button } from "@justworkshr/milo-core";
import { InputMessage } from "@justworkshr/milo-form";
import { WaiverJobLocation, JobAddressMethods } from "../../types";

import styles from "./job-location.module.css";

type JobLocationProps = {
  handleLocationChange: JobAddressMethods;
  waiverAddresses: WaiverJobLocation[];
};

const { flexHeaderDiv, header, formBorder } = styles;

export default function JobLocation({
  waiverAddresses,
  handleLocationChange,
}: JobLocationProps): JSX.Element {
  return (
    <div>
      {waiverAddresses.map((address, index) => {
        return (
          <section
            className={formBorder}
            data-testid="manageAddressesSection"
            key={index}
          >
            <div className={flexHeaderDiv}>
              <h2 className={header}>Job location {1 + +index}</h2>
              {waiverAddresses.length > 1 && (
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => handleLocationChange.remove(index)}
                >
                  Remove
                </Button>
              )}
            </div>
            <JobLocationAddress
              address={address}
              setCertificateAddress={() =>
                handleLocationChange.setToCertificateAddress(index)
              }
              changeAddress={({ target }) =>
                handleLocationChange.update(index, target.name, target.value)
              }
              index={index}
              key={index}
              errors={address.errors}
            />
            <InputMessage type="help">
              {
                "Provide the full address of where this job will be taking place. If for a lease, please provide the address of where the employees will be working. (Do note that waivers will only cover W2 employees, not contractors.)"
              }
            </InputMessage>
          </section>
        );
      })}
      {waiverAddresses.length < 5 && (
        <Button
          variant="outlined"
          type="button"
          onClick={handleLocationChange.add}
        >
          Add another job location
        </Button>
      )}
    </div>
  );
}
