import { fetchClient } from "@justworkshr/fe-utility-kit";
import { useMutation } from "@tanstack/react-query";
import { buildClockworkWebPath } from "lib/resource-finder";
import { useBankForm } from "pages/company-settings/hooks/useBankForm";
import { getEnvironmentName } from "lib/environment";
import { useNavigate } from "react-router-dom";

export interface ProcessedBankResponseError {
  status: number;
  // not doing anything with the body yet
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any;
  message: string;
}

const parseJSONResponse = async (response: Response) => {
  try {
    return await response.json();
  } catch (e) {
    return null;
  }
};

export const useSubmitPlaidToken = () => {
  const navigate = useNavigate();

  const { isOnboarding } = useBankForm();

  return useMutation({
    mutationFn: async ({ publicToken }: { publicToken: string }) => {
      const response = await fetchClient.post(
        buildClockworkWebPath(
          "/company/bank_account/update_company_bank_account_via_plaid"
        ),
        {
          credentials:
            getEnvironmentName() === "development" ? "include" : undefined,
          body: JSON.stringify({
            plaid_public_token: publicToken,
          }),
        }
      );
      if (!response.ok) {
        const errorBody = await parseJSONResponse(response);

        throw {
          message: "Something went wrong",
          status: response.status,
          body: errorBody,
        } as ProcessedBankResponseError;
      }
      const json = await response.json();
      return json;
    },
    onSuccess: () => {
      if (isOnboarding) {
        window.location.assign(
          buildClockworkWebPath("/onboarding_checklist/bank_verify_success")
        );
      } else {
        window.location.assign(
          buildClockworkWebPath("/company/bank_verify_success")
        );
      }
    },
    onError: (error: ProcessedBankResponseError) => {
      window.scrollTo(0, 0);
      if (error.status == 422) {
        return navigate(
          "/company-settings/edit-bank-account/error/non-business-account"
        );
      }
      return;
    },
  });
};
