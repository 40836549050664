import { GetEoRCombinedInvoicesQuery } from "types/generated/operations";
import { CombinedInvoiceData } from "./types";
import { getInitialLanguage } from "../components/LanguagePicker/LanguagePicker";

// Parse and return an invoice response as an invoice object
export function parseInvoiceResponse(
  data: GetEoRCombinedInvoicesQuery
): CombinedInvoiceData | null {
  // Gracefully handle empty results or malformed json_data
  try {
    const invoiceData = data.combinedInvoices;
    if (invoiceData && invoiceData[0]) {
      return invoiceData[0];
    }
    return null;
  } catch {
    return null;
  }
}

// compares two dates and returns true if the "A Date" is after the "B Date"
export function compareDates({
  dateA,
  dateB,
  timeA = "00:00:00",
  timeB = "00:00:00",
  locale = "en-US",
  timeZone = "America/New_York",
}: {
  dateA: string;
  dateB: string;
  timeA?: string;
  timeB?: string;
  locale?: string;
  timeZone?: string;
}) {
  const a = new Date(
    new Date(`${dateA} ${timeA}`).toLocaleString(locale, { timeZone })
  );
  const b = new Date(
    new Date(`${dateB} ${timeB}`).toLocaleString(locale, { timeZone })
  );
  return a > b;
}

// Determines if it is past the EST cutoff time
export function isPastCutoff(
  cutoffDate: string,
  cutoffTime: string = "00:00:00",
  locale: string = "en-US",
  timeZone: string = "America/New_York"
) {
  return compareDates({
    dateA: new Date().toLocaleDateString(locale),
    dateB: cutoffDate,
    timeA: new Date().toLocaleTimeString(locale),
    timeB: cutoffTime,
    locale,
    timeZone,
  });
}

// Converts a date to format: MM/DD/YY
export function toBannerProcessingDateFormat(date: Date) {
  return new Intl.DateTimeFormat("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
    timeZone: "UTC",
  }).format(date);
}

// Converts a date to format: Month D, Yr
export function toProcessingDateFormat(date: Date) {
  return new Intl.DateTimeFormat(getInitialLanguage(), {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  }).format(date);
}

// Converts a date to format: Mon D, Yr
export function toPaymentDateFormat(date: Date) {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  }).format(date);
}

// Returns a string formatted as (Mon D, Yr - Mon D, Yr) using a start and end date
export function toPayPeriodRangeFormat(
  startDate: string,
  endDate: string
): string {
  return `(${toPaymentDateFormat(new Date(startDate))} - ${toPaymentDateFormat(
    new Date(endDate)
  )})`;
}

// Builds a string representing the local currency payout
export const localCurrencyPayoutText = (
  localCurrencyLength: number,
  localCurrencyPayoutPrefix: string,
  localCurrencyPayoutSuffix: string
) => {
  return localCurrencyLength > 1
    ? `${localCurrencyPayoutPrefix} ${localCurrencyLength} ${localCurrencyPayoutSuffix}`
    : "";
};

// Starting with the payroll run on 9/15/2024, we will no longer be supporting wiring instructions for payments.
// Returns true if the invoice date is after 9/15/2024
const WIRING_CUTOFF_DATE = "2024-08-31";
export const isPastWiringInstructionsCutoff = (
  invoiceDate: string,
  invoiceTime: string = "00:00:00",
  locale: string = "en-us",
  timeZone: string = "America/New_York"
) => {
  return compareDates({
    dateA: invoiceDate,
    timeA: invoiceTime,
    dateB: WIRING_CUTOFF_DATE,
    locale,
    timeZone,
  });
};
