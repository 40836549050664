import React from "react";
import { Badge } from "@justworkshr/milo-core";

export type ConnectionStatusBadgeProps = {
  connectionStatus?: "CONNECTED" | "DISCONNECTED" | "PENDING" | "ERROR";
};
const ConnectionStatusBadge: React.FC<ConnectionStatusBadgeProps> = ({
  connectionStatus,
}) => {
  if (connectionStatus === "CONNECTED") {
    return <Badge role="positive">Connected</Badge>;
  } else if (connectionStatus === "PENDING") {
    return <Badge role="neutral">Pending</Badge>;
  } else if (connectionStatus === "ERROR") {
    return <Badge role="negative">Error</Badge>;
  } else {
    return null;
  }
};

export default ConnectionStatusBadge;
