import styles from "pages/company-settings/components/ReverseWireAuthNotice.module.css";
import { DisplayIcon } from "@justworkshr/milo-icons";

const { noticeWrapper, noticeTitle, noticeContent } = styles;

export default function ReverseWireAuthNotice() {
  return (
    <div className={noticeWrapper}>
      <DisplayIcon
        iconName="exclamation-mark"
        color="red"
        size="small"
        data-testid="exclamation-mark"
      />
      <div className={noticeContent}>
        <h2 className={noticeTitle}>
          Your current 2-day payment processing may be affected
        </h2>
        <p>
          2-day payment processing requires a reverse wire authorization from
          your bank.
          <br />
          <br />
          You’ll need to establish the authorization with your bank within 5
          business days after updating your bank account or you’ll be switched
          to the standard 4-day processing timeline.
        </p>
      </div>
    </div>
  );
}
