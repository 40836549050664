import React, { ReactElement } from "react";
import styles from "./FileInfo.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";

const { fileInfo, disabledDisplay, fileName, errorList, closeButton } = styles;

interface FileInfoProps {
  file: File;
  errors?: string[];
  disabled?: boolean;
  onClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    file: File
  ) => void;
}
const FileInfo = ({
  file,
  disabled = false,
  errors = [],
  onClick,
}: FileInfoProps): ReactElement => {
  const errorDisplay =
    errors.length > 0 ? (
      <ul className={errorList}>
        {errors.map((value, index) => (
          <li key={index}>
            <SystemIcon iconName="warning" color="warning" />
            {value}
          </li>
        ))}
      </ul>
    ) : (
      ""
    );

  const classes = [fileInfo];
  if (disabled) {
    classes.push(disabledDisplay);
  }

  return (
    <div className={classes.join(" ")}>
      <span className={fileName}>
        {file.name}
        {errorDisplay}
      </span>

      <button
        aria-label={`remove ${file.name}`}
        type="button"
        onClick={(e) => {
          if (!disabled) onClick(e, file);
        }}
        className={closeButton}
        disabled={disabled}
      >
        <SystemIcon
          iconName="close"
          size="small"
          color={disabled ? "disabled" : "neutral"}
        />
      </button>
    </div>
  );
};
export default FileInfo;
