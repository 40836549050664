import { ReactElement, useState } from "react";
import { FormField, FileInput } from "@justworkshr/milo-form";

// Constants
const MAXIMUM_FILE_SIZE = 10000000;
const ACCEPTED_FILE_TYPES =
  "image/png, image/jpeg, application/pdf, application/xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
export const errorMessages = {
  invalidFileType: (name: string) =>
    `${name} is an invalid file type and will not be submitted, please try a different file`,
  invalidFileSize: (name: string) =>
    `${name} is too large and will not be submitted, please remove and try a different file`,
};

// Helper Functions
async function readAsDataURL(file: File): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = () => {
      resolve(fr.result?.toString());
    };
    fr.readAsDataURL(file);
  });
}

// Component
export default function CertificateFileInput({
  setDocuments,
}: {
  setDocuments: React.Dispatch<
    React.SetStateAction<
      {
        dataUrl: string | undefined;
        file: File | undefined;
      }[]
    >
  >;
}): ReactElement {
  const [error, setError] = useState<string | undefined>(undefined);

  const validFile = ({ size, name, type }: File): boolean | null => {
    const { invalidFileSize, invalidFileType } = errorMessages;

    if (size > MAXIMUM_FILE_SIZE) {
      setError(invalidFileSize(name));
      return false;
    }

    const validType = ACCEPTED_FILE_TYPES.split(",").some(
      (fileType) => type === fileType.trim()
    );

    if (!validType) {
      setError(invalidFileType(name));
      return false;
    }

    return true;
  };

  const onSelectFile = async (
    files: File | File[] | undefined
  ): Promise<void> => {
    let processedFiles: { dataUrl: string | undefined; file: File }[] = [];

    if (files instanceof File) {
      if (!validFile(files)) return;

      processedFiles = await readAsDataURL(files).then((dataUrl) => [
        { dataUrl, file: files },
      ]);
    } else if (files && files.length > 0) {
      for (const file of files) {
        if (!validFile(file)) {
          return;
        }
      }

      processedFiles = await Promise.all(
        files.map(async (file) => {
          return readAsDataURL(file).then((dataUrl) => ({ dataUrl, file }));
        })
      );
    }

    setError("");
    setDocuments(processedFiles);
  };

  return (
    <FormField
      label="Upload Additional Documentation"
      name="supportingDocuments"
      message="If any supporting documentation was received from the vendor and/or certificate holder, please attach it here. Uploaded documents should be one of the following types: xml, doc, docx, pdf, jpg, png."
      required={false}
      error={error}
    >
      <FileInput
        onFileChange={async (files) => await onSelectFile(files)}
        multiple={true}
        accept={ACCEPTED_FILE_TYPES}
        data-testid="supportingDocuments"
      />
    </FormField>
  );
}
