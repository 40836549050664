import { FC, useContext, useRef, useEffect } from "react";
import { Spinner } from "@justworkshr/milo-core";
import { DisplayIcon } from "@justworkshr/milo-icons";
import { useGetFeatureFlag } from "lib/launch-darkly";
import { usePeopleAnalyticsCapabilities } from "../../hooks";
import { logVisierEvent } from "../../utils";
import { VisierEventHandlerContext } from "../visier-event-handler";
import styles from "./visier-app-iframe.module.css";

interface VisierAppIframeProps {
  visierSrcSuffix: string | null;
}

const VisierAppIframe: FC<VisierAppIframeProps> = ({ visierSrcSuffix }) => {
  const { currentMemberUuid } = usePeopleAnalyticsCapabilities();
  const { visierSessionEstablished } = useContext(VisierEventHandlerContext);
  const visierAppIframeRef = useRef<HTMLIFrameElement>(null);

  const { getFeatureFlag } = useGetFeatureFlag();
  const maintenanceFlagValue = getFeatureFlag(
    "people_analytics_planned_maintenance"
  );

  useEffect(() => {
    /**
     * Logging requires currentMemberUuid so if it isn't present yet, return
     * early and do all this set up once it is available.
     */
    if (currentMemberUuid === undefined) return;

    if (visierAppIframeRef?.current?.id) {
      logVisierEvent({
        eventType: "call",
        message: "embedApp",
        currentMemberUuid,
      });

      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      (window as any)["visier"](
        "embedApp",
        visierAppIframeRef.current.id,
        visierSrcSuffix
      );
    }
  }, [
    currentMemberUuid,
    visierSessionEstablished,
    visierAppIframeRef,
    visierSrcSuffix,
  ]);

  if (maintenanceFlagValue === "true") {
    return (
      <div className={styles.visierMaintenanceScreen}>
        <DisplayIcon
          color="greenYellow"
          iconName="toolbox"
          size="large"
          className={styles.displayIcon}
        />
        <h1>We’ll be back soon</h1>
        <p>
          People Analytics will be unavailable for a few hours while we work to
          make your experience even better.
        </p>
        <p>Check back later to see what’s new.</p>
      </div>
    );
  }

  return visierSessionEstablished && visierSrcSuffix ? (
    <iframe
      title="Visier App"
      className={styles.visierAppIframe}
      data-testid="visier-app-iframe"
      id="visier-app-iframe"
      ref={visierAppIframeRef}
    />
  ) : (
    <div
      className={styles.loadingSpinner}
      data-testid="visier-app-loading-spinner"
    >
      <Spinner />
    </div>
  );
};

export default VisierAppIframe;
