import React from "react";
import { mergeClassNames } from "pages/expenses/utils";
import styles from "./AuditLogTimelineLine.module.css";

const {
  Active,
  AuditLogTimeline,
  AuditLogTimelineCircle,
  AuditLogTimelineWrapper,
} = styles;

interface AuditLogTimelineLineProps {
  isLastAudit: boolean;
  isFirstAudit: boolean;
}

export const AuditLogTimelineLine: React.FC<AuditLogTimelineLineProps> = ({
  isLastAudit,
  isFirstAudit,
}) => {
  return (
    <div className={AuditLogTimelineWrapper}>
      <span
        className={mergeClassNames([
          AuditLogTimelineCircle,
          isFirstAudit ? Active : null,
        ])}
      />
      {!isLastAudit && <span className={AuditLogTimeline} />}
    </div>
  );
};

export default AuditLogTimelineLine;
