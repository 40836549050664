import benefitsClasses from "pages/benefits/benefits.module.css";
import { Divider } from "@justworkshr/milo-core";

const OverviewTab = () => {
  return (
    <div>
      <h2 className={benefitsClasses.benefitsHeader}>
        Help your team save for their future
      </h2>

      <p className={benefitsClasses.benefitsParagraph}>
        With a 401(k) retirement savings plan, you can improve recruitment and
        retention of employees, increase employee morale, and even reduce your
        taxable income from employer contributions.
      </p>

      <Divider />

      <h3 className={benefitsClasses.benefitsHeader}>
        Support your team's financial future
      </h3>

      <p className={benefitsClasses.benefitsParagraph}>
        The Justworks 401(k) with Empower allows your company to confidently
        support your employees' financial wellness by helping them with
        long-term savings for retirement.
      </p>

      <h3 className={benefitsClasses.benefitsHeader}>
        A better employee experience
      </h3>

      <p className={benefitsClasses.benefitsParagraph}>
        Your employees benefit from competitive plan fees due to the buying
        power of being a Justworks customer. They also have a better integrated
        experience thanks to single sign-on for their Empower and Justworks
        account.
      </p>

      <h3 className={benefitsClasses.benefitsHeader}>
        None of the burden, less of the risk
      </h3>

      <p className={benefitsClasses.benefitsParagraph}>
        Because you’re on a MEP with Justworks, you don’t have to take on the
        burden or risks associated with administering a compliant 401(k) plan.
        We’ll take care of your plan administration and compliance, so you can
        focus on growing your business and helping your team.
      </p>
    </div>
  );
};

export default OverviewTab;
