import { ReactElement } from "react";
import { Route, Routes } from "react-router-dom";
import { ProductDescriptionPage } from "./learn/ProductDescriptionPage";
import { LtdEnrollmentSetupPage } from "./components/LtdEnrollmentSetupPage";
import { StdEnrollmentSetupPage } from "./components/StdEnrollmentSetupPage";
import { BasicLifeAndADDEnrollmentSetupPage } from "./components/BasicLifeAndAddEnrollmentSetupPage";
import { EnrollmentReviewPage } from "./review/components/EnrollmentReviewPage";
import { Page404 } from "../../index";

const COMPANY_ENROLLMENT_LTD_PATH = "/ltd";
const COMPANY_ENROLLMENT_STD_PATH = "/std";
const COMPANY_ENROLLMENT_LIFE_ADD_PATH = "/basic-life-and-add";
const COMPANY_ENROLLMENT_LTD_SETUP_PATH = "/ltd/setup";
const COMPANY_ENROLLMENT_STD_SETUP_PATH = "/std/setup";
const COMPANY_ENROLLMENT_LIFE_ADD_SETUP_PATH = "/basic-life-and-add/setup";
const COMPANY_ENROLLMENT_REVIEW_PATH = "/review";

export default function LifeAndLeaveEnrollment(): ReactElement {
  return (
    <Routes>
      <Route
        path={COMPANY_ENROLLMENT_LTD_PATH}
        element={<ProductDescriptionPage planType={"ltd"} />}
      />
      <Route
        path={COMPANY_ENROLLMENT_STD_PATH}
        element={<ProductDescriptionPage planType={"std"} />}
      />
      <Route
        path={COMPANY_ENROLLMENT_LIFE_ADD_PATH}
        element={<ProductDescriptionPage planType={"basicLifeAndADD"} />}
      />
      <Route
        path={COMPANY_ENROLLMENT_LTD_SETUP_PATH}
        element={<LtdEnrollmentSetupPage />}
      />
      <Route
        path={COMPANY_ENROLLMENT_STD_SETUP_PATH}
        element={<StdEnrollmentSetupPage />}
      />
      <Route
        path={COMPANY_ENROLLMENT_LIFE_ADD_SETUP_PATH}
        element={<BasicLifeAndADDEnrollmentSetupPage />}
      />
      <Route
        path={COMPANY_ENROLLMENT_REVIEW_PATH}
        element={<EnrollmentReviewPage />}
      />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
