import { Button } from "@justworkshr/milo-core";
import { TextInput } from "@justworkshr/milo-form";
import { EmployeeOptionType, EmployeeUpdateMethods } from "../../types";
import specificStyles from "./employee-search-container.module.css";

const {
  searchEmployees,
  employeeSelectAll,
  innerFlex,
  employeeCount,
  clearButton,
  selectAllButton,
  selectButton,
} = specificStyles;

type EmployeeSearchFieldProps = {
  selectedEmployees: EmployeeOptionType[];
  employeeInput: string;
  setEmployeeInput: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  w2Employees: EmployeeOptionType[];
  handleEmployeeUpdate: EmployeeUpdateMethods;
};

export const EmployeeSearchField = ({
  selectedEmployees,
  employeeInput,
  setEmployeeInput,
  loading,
  w2Employees,
  handleEmployeeUpdate,
}: EmployeeSearchFieldProps) => {
  return (
    <>
      <div className={searchEmployees}>
        <TextInput
          name="employees"
          placeholder="Search employees"
          value={employeeInput}
          disabled={loading}
          onChange={({ target }) => setEmployeeInput(target.value)}
        />
      </div>
      <div className={employeeSelectAll}>
        <div className={innerFlex}>
          <h3
            className={employeeCount}
          >{`${selectedEmployees.length} employees selected`}</h3>
          {selectedEmployees && selectedEmployees.length !== 0 && (
            <div className={selectButton}>
              <Button
                onClick={() => {
                  handleEmployeeUpdate.removeAll();
                  setEmployeeInput("");
                }}
                variant="ghost"
                className={clearButton}
                type="button"
              >
                Clear Selection
              </Button>
            </div>
          )}
        </div>
        {selectedEmployees.length !== w2Employees.length && (
          <div className={selectButton}>
            <Button
              onClick={() => {
                handleEmployeeUpdate.addAll(w2Employees);
                setEmployeeInput("");
              }}
              variant="ghost"
              className={selectAllButton}
              type="button"
            >
              Select all
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
