import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button } from "@justworkshr/milo-core";
import { Formik, useFormikContext } from "formik";
import { FormikFormField } from "lib/formik/FormikFormField";
import { InitialIntakeFormValues } from "./InitialIntakeForm.types";
import {
  autoSaveAnswers,
  getInitialValues,
  initialIntakeFormSchema,
  isDateMoreThan30DaysPast,
  saveAnswers,
} from "./InitialIntakeForm.utils";
import styles from "./InitialIntakeForm.module.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DatePicker } from "pages/team-management/components";
import {
  SaveMemberSeparationAnswersMutation,
  SaveMemberSeparationAnswersMutationFn,
  useCreateMemberSeparationMutation,
  useSaveMemberSeparationAnswersMutation,
} from "types/generated/operations";
import { useGetMemberSeparation } from "pages/team-management/hooks/useGetMemberSeparation";
import { MemberSeparation } from "pages/team-management/types";
import { FetchResult } from "@apollo/client";
import { getNextRouteByStatus } from "pages/team-management/constants";
import { AlertMessage } from "pages/team-management/components/AlertMessage";
import { Loading } from "pages/team-management/routes/SeparationFlow/Loading/Loading";
import { TerminationTypeField } from "./components/TerminationTypeField";
import { TerminationReasonField } from "./components/TerminationReasonField";
import { SeparationFlowContext } from "pages/team-management/contexts";
import { useAutoSave } from "pages/team-management/hooks/useAutoSave";
import { SEPARATION_FLOW_STATUS } from "pages/team-management/routes/SeparationFlow/SeparationFlow.constants";

const {
  alertMessageWrapper,
  form,
  dateFieldsWrapper,
  singleDateFieldWrapper,
  formSubmitButton,
} = styles;

export interface InitialIntakeFormBodyProps {
  saveMemberSeparationAnswersMutation: SaveMemberSeparationAnswersMutationFn;
  memberSeparation: MemberSeparation | undefined;
  lastTerminationType: string | undefined;
  initialValues: InitialIntakeFormValues;
  initialValuesValid: boolean;
  setLastTerminationType: Dispatch<SetStateAction<string | undefined>>;
}

export const InitialIntakeFormBody: React.FC<InitialIntakeFormBodyProps> = ({
  saveMemberSeparationAnswersMutation,
  memberSeparation,
  lastTerminationType,
  initialValues,
  initialValuesValid,
  setLastTerminationType,
}) => {
  const [lastAutoSavedValues, setLastAutoSavedValues] = useState<
    InitialIntakeFormValues | undefined
  >();
  const {
    values,
    dirty,
    isValid,
    errors,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<InitialIntakeFormValues>();

  useAutoSave(async () => {
    await autoSaveAnswers(
      saveMemberSeparationAnswersMutation,
      memberSeparation,
      values,
      initialValues,
      lastAutoSavedValues,
      setLastAutoSavedValues
    );
  }, 3000);

  return (
    <>
      <div className={form}>
        <div className={dateFieldsWrapper}>
          <div className={singleDateFieldWrapper}>
            <FormikFormField
              label="Separation date"
              name="termination_date"
              required
            >
              <DatePicker
                id="termination_date"
                name="termination_date"
                value={values.termination_date}
                handleChange={async (date) => {
                  await setFieldValue("termination_date", date);
                  await setFieldTouched("termination_date", true);
                }}
              />
            </FormikFormField>
          </div>
          <div className={singleDateFieldWrapper}>
            <FormikFormField
              label="Last day worked"
              name="final_work_date"
              required
            >
              <DatePicker
                id="final_work_date"
                name="final_work_date"
                value={values.final_work_date}
                handleChange={async (date) => {
                  await setFieldValue("final_work_date", date);
                  await setFieldTouched("final_work_date", true);
                }}
              />
            </FormikFormField>
          </div>
        </div>
        {isDateMoreThan30DaysPast(values.termination_date) && (
          <div className={alertMessageWrapper}>
            <AlertMessage>
              <h3>
                <strong>
                  This separation is being scheduled for 30+ days in the past
                </strong>
              </h3>
              <p>
                Double check the separation date is accurate. If it is, then
                please{" "}
                <a href="mailto:support@justworks.com">
                  email Customer Support
                </a>{" "}
                to let them know and then continue with the separation.
              </p>
            </AlertMessage>
          </div>
        )}
        <TerminationTypeField />
        <TerminationReasonField
          lastTerminationType={
            lastTerminationType || initialValues.termination_type
          }
          setLastTerminationType={setLastTerminationType}
          loading={!memberSeparation}
        />
      </div>
      <Button
        data-testid="submit-button"
        type="submit"
        disabled={
          (!dirty && !initialValuesValid) ||
          !isValid ||
          Object.keys(errors).length > 0
        }
        className={formSubmitButton}
        onClick={handleSubmit}
      >
        Next
      </Button>
    </>
  );
};

export const InitialIntakeForm = (): ReactElement => {
  const [searchParams] = useSearchParams();
  const { member, setShowGlobalGenericError } = useContext(
    SeparationFlowContext
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [memberSeparation, setMemberSeparation] = useState<
    MemberSeparation | undefined
  >(undefined);
  const [lastTerminationType, setLastTerminationType] = useState<
    string | undefined
  >();
  const navigate = useNavigate();
  const [saveMemberSeparationAnswersMutation] =
    useSaveMemberSeparationAnswersMutation();
  const [createMemberSeparation] = useCreateMemberSeparationMutation();
  const { memberUuid } = useParams();
  const {
    memberSeparation: memberSeparationQuery,
    loading: isMemberSeparationLoading,
    error: memberSeparationError,
  } = useGetMemberSeparation(memberUuid);
  const initialValues: InitialIntakeFormValues =
    getInitialValues(memberSeparation);
  const initialValuesValid = initialIntakeFormSchema(
    member!.effectiveDate!
  ).isValidSync(initialValues);

  useEffect(() => {
    if (memberSeparationError) {
      setShowGlobalGenericError?.(true);
    } else {
      setShowGlobalGenericError?.(false);
    }
  }, [memberSeparationError, setShowGlobalGenericError]);

  /*
    Loading stays true until we either get back an in-progress separation record or, if there isn't one,
    we have successfully created a new one.
  */
  useEffect(() => {
    const newSeparation = async (memberUuid: string) => {
      try {
        const newMemberSeparation = await createMemberSeparation({
          variables: {
            memberUuid,
          },
        });

        if (newMemberSeparation.data?.createMemberSeparation?.errors) {
          setShowGlobalGenericError?.(true);
        } else {
          setMemberSeparation(
            newMemberSeparation.data?.createMemberSeparation
              ?.memberSeparation as MemberSeparation
          );
        }
      } catch (error) {
        setShowGlobalGenericError?.(true);
      }

      setLoading(false);
    };

    if (!isMemberSeparationLoading && memberUuid) {
      if (
        !memberSeparationError &&
        (!memberSeparationQuery ||
          memberSeparationQuery.status === SEPARATION_FLOW_STATUS.COMPLETE)
      ) {
        newSeparation(memberUuid);
      } else {
        setMemberSeparation(memberSeparationQuery);
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMemberSeparationLoading, memberUuid]);

  useEffect(() => {
    if (!lastTerminationType && initialValues?.termination_type) {
      setLastTerminationType(initialValues.termination_type);
    }
  }, [
    initialValues?.termination_type,
    lastTerminationType,
    setLastTerminationType,
  ]);

  useEffect(() => {
    if (searchParams?.get("redirect") && memberSeparation?.status) {
      const landingRoute = getNextRouteByStatus(memberSeparation.status);
      if (landingRoute !== "initial-intake") {
        navigate(
          `/team-management/separation-flow/${memberUuid}/${landingRoute}`
        );
      }
    }
  }, [
    isMemberSeparationLoading,
    memberSeparation?.status,
    memberUuid,
    navigate,
    searchParams,
  ]);

  const handleSubmit = async (values: typeof initialValues) => {
    if (!memberSeparation?.memberSeparationId) {
      return;
    }

    setLoading(true);

    try {
      const responseFull: FetchResult<SaveMemberSeparationAnswersMutation> =
        await saveAnswers(
          saveMemberSeparationAnswersMutation,
          memberSeparation,
          values
        );

      const response = responseFull.data?.saveMemberSeparationAnswers;

      if (response?.success) {
        navigate(
          `/team-management/separation-flow/${memberUuid}/questionnaire`,
          {
            state: values,
          }
        );
      } else {
        setShowGlobalGenericError?.(true);
      }
    } catch (error) {
      setShowGlobalGenericError?.(true);
    }

    setLoading(false);
  };

  return loading ? (
    <Loading />
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={initialIntakeFormSchema(member!.effectiveDate!)}
      validateOnChange={true}
      validiateOnMount={true}
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <InitialIntakeFormBody
            saveMemberSeparationAnswersMutation={
              saveMemberSeparationAnswersMutation
            }
            memberSeparation={memberSeparation}
            lastTerminationType={lastTerminationType}
            initialValues={initialValues}
            initialValuesValid={initialValuesValid}
            setLastTerminationType={setLastTerminationType}
          />
        );
      }}
    </Formik>
  );
};
