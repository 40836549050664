import { Member, MemberSeparation } from "pages/team-management/types";
import styles from "./StateSpecificRequirementsPage.module.css";
import { Button } from "@justworkshr/milo-core";
import {
  ALL_STATE_REQUIREMENTS,
  GENERIC_STATE_DESCRIPTION,
  GENERIC_STATE_TITLE,
  ROUTES,
  IOWA_STATE_REQUIREMENTS,
  MISSOURI_STATE_REQUIREMENTS,
  MISSISSIPPI_STATE_REQUIREMENTS,
  MARYLAND_STATE_REQUIREMENTS,
} from "pages/team-management/constants";
import { SEPARATION_FLOW_STATUS } from "../../SeparationFlow.constants";
import { Link, useParams } from "react-router-dom";
import { useGetMemberSeparation } from "pages/team-management/hooks/useGetMemberSeparation";
import { useGetStateSeparationNoticeRequirementsQuery } from "types/generated/operations";
import { useGetSeparatingMember } from "pages/team-management/hooks/useGetSeparatingMember";
import { Loading } from "../../Loading/Loading";
import { DisplayIcon } from "@justworkshr/milo-icons";
import { DeceasedTerminationReasonTypeCard } from "./components/DeceasedTerminationReasonTypeCard";
import { InvoluntaryTerminationReasonTypes } from "../../SeparationReason/InitialIntakePage/components/InitialIntakeForm/InitialIntakeForm.types";
import { getTerminationReasonType } from "../../../../utils/utils";
import { Page404 } from "../../../../../page-404";

export const StateSpecificRequirementsPage = () => {
  const { memberUuid } = useParams();
  const { member } = useGetSeparatingMember(memberUuid);
  const { friendlyName, addressState } = member as Member;

  const { data } = useGetStateSeparationNoticeRequirementsQuery({
    variables: {
      state: addressState,
    },
    skip: !addressState,
  });

  const { memberSeparation, loading: isMemberSeparationLoading } =
    useGetMemberSeparation(memberUuid);

  const getStateSpecificRequirements = (addressState: string | undefined) => {
    if (addressState && addressState in ALL_STATE_REQUIREMENTS) {
      return ALL_STATE_REQUIREMENTS[addressState];
    }
    return {
      state: null,
      description: null,
    };
  };

  const { state, description } = getStateSpecificRequirements(addressState);

  const renderNoticeLinkCopy = () => {
    if (state === IOWA_STATE_REQUIREMENTS.state) {
      return "Go to Iowa’s Notice of Separation Form";
    } else if (state === MISSOURI_STATE_REQUIREMENTS.state) {
      return "Go to Form M-INF-288-5";
    } else if (
      state === MISSISSIPPI_STATE_REQUIREMENTS.state ||
      state === MARYLAND_STATE_REQUIREMENTS.state
    ) {
      return `Go to ${state}'s website`;
    } else if (addressState && addressState in ALL_STATE_REQUIREMENTS) {
      return `Download ${state}'s form`;
    }
    return `Download separation template`;
  };

  const getDefaultStateSpecificComponent = () => {
    return (
      <>
        <div className={styles.titleCard}>
          <div className={styles.titleCardContent}>
            <div className={styles.titleCardHeader}>
              <div className={styles.confirmationCheck}>
                <DisplayIcon iconName="check-mark" color="blueGreen" />
              </div>
              <div className={styles.headerMd}>Separation scheduled</div>
              <h1>Important next steps</h1>
            </div>
            <div>
              Best practices require notifying {friendlyName} when they are
              being separated.
            </div>
          </div>
        </div>
        <div className={styles.stateSpecificSection}>
          <div className={styles.stateSpecificDescription}>
            <div className={styles.headerMd}>
              {state ? `${state}'s state requirements` : GENERIC_STATE_TITLE}
            </div>
            <div>{description ? description : GENERIC_STATE_DESCRIPTION}</div>
          </div>
          <div className={styles.noticeOfSeparationLinkSection}>
            {!data?.stateSeparationNoticeRequirements ? (
              <Loading orientation="short" />
            ) : (
              <Button
                as="a"
                href={
                  (addressState &&
                    addressState in ALL_STATE_REQUIREMENTS &&
                    ALL_STATE_REQUIREMENTS[addressState].link) ||
                  data.stateSeparationNoticeRequirements[0].presignedUrl
                }
                target="_blank"
                rightIcon="external-link"
              >
                {renderNoticeLinkCopy()}
              </Button>
            )}

            <Button
              variant="outlined"
              as={Link}
              to={`../${ROUTES.SEPARATION_NOTICE_ALL_STATE_REQUIREMENTS}`}
              rightIcon="arrow-right"
            >
              See all Notices of Separation
            </Button>
          </div>
        </div>
      </>
    );
  };

  const getComponentToRender = (
    memberSeparation: MemberSeparation | undefined
  ) => {
    if (
      !memberSeparation ||
      memberSeparation.status !== SEPARATION_FLOW_STATUS.COMPLETE
    ) {
      return <Page404 />;
    }

    const terminationReasonType = getTerminationReasonType(memberSeparation);
    if (terminationReasonType === InvoluntaryTerminationReasonTypes.DECEASED) {
      return <DeceasedTerminationReasonTypeCard />;
    }
    return getDefaultStateSpecificComponent();
  };

  return !member?.friendlyName || !addressState || isMemberSeparationLoading ? (
    <Loading orientation="tall" />
  ) : (
    getComponentToRender(memberSeparation)
  );
};

export default StateSpecificRequirementsPage;
