import { useFormikContext } from "formik";
import { DatePicker } from "pages/team-management/components";
import { PaymentDetailsFormValues } from "../../../SeparationPay.formUtils";
import { FormikFormField } from "lib/formik/FormikFormField";
import { PAYMENT_DETAILS_FORM_COPY } from "./PaymentDetailsForm.constants";
import styles from "../../../SeparationPay.module.css";

const { TwoColumnRow, SectionContainer, SubHeadingMd, InputRowSubheader } =
  styles;

export const ApplicableWorkPeriodRow = () => {
  const { values, setFieldValue } =
    useFormikContext<PaymentDetailsFormValues>();

  return (
    <div className={SectionContainer}>
      <div className={SubHeadingMd}>Applicable work period</div>
      <div className={InputRowSubheader}>
        The start and end dates should reflect the period during which the
        additional payment was earned.
      </div>
      <div className={TwoColumnRow}>
        <FormikFormField
          name="work_start_date"
          label={PAYMENT_DETAILS_FORM_COPY.work_start_date}
          required={true}
        >
          <DatePicker
            id="workStartDate"
            name="work_start_date"
            value={values.work_start_date}
            handleChange={(date) => {
              setFieldValue("work_start_date", date);
            }}
          />
        </FormikFormField>
        <FormikFormField
          name="work_end_date"
          label={PAYMENT_DETAILS_FORM_COPY.work_end_date}
          required={true}
        >
          <DatePicker
            id="workEndDate"
            name="work_end_date"
            value={values.work_end_date}
            handleChange={(date) => {
              setFieldValue("work_end_date", date);
            }}
          />
        </FormikFormField>
      </div>
    </div>
  );
};
