// https://stackoverflow.com/questions/53253940/make-react-useeffect-hook-not-run-on-initial-render/57941438#57941438
// like useEffect, but doesn't run on the initial page load

import { DependencyList, useEffect, useRef } from "react";

const useMountedEffect = (func: () => void, deps?: DependencyList): void => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useMountedEffect;
