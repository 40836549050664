import { fetchClient } from "@justworkshr/fe-utility-kit";
import { useMutation } from "@tanstack/react-query";
import { buildClockworkWebPath } from "lib/resource-finder";
import {
  BankFormData,
  useBankForm,
} from "pages/company-settings/hooks/useBankForm";
import { getEnvironmentName } from "lib/environment";
import { NavigateFunction, useNavigate } from "react-router-dom";

export interface ProcessedBankResponseError {
  status: number;
  // not doing anything with the body yet
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any;
  message: string;
}

const parseJSONResponse = async (response: Response) => {
  try {
    return await response.json();
  } catch (e) {
    return null;
  }
};

export const useSubmitBankAccount = () => {
  const navigate = useNavigate();

  const { isOnboarding } = useBankForm();
  return useMutation({
    mutationFn: async (formData: BankFormData) => {
      const response = await fetchClient.put(
        buildClockworkWebPath("/company/bank_account"),
        {
          credentials:
            getEnvironmentName() === "development" ? "include" : undefined,
          body: JSON.stringify({
            account_number: formData.accountNumber,
            routing_number: formData.routingNumber,
          }),
        }
      );
      if (!response.ok) {
        const errorBody = await parseJSONResponse(response);

        throw {
          message: "Something went wrong",
          status: response.status,
          body: errorBody,
        } as ProcessedBankResponseError;
      }
      const json = await response.json();
      return json;
    },
    onSuccess: () => {
      if (isOnboarding) {
        window.location.assign(
          buildClockworkWebPath("/onboarding_checklist/bank_verify_success")
        );
      } else {
        window.location.assign(
          buildClockworkWebPath("/company/bank_verify_success")
        );
      }
    },
    onError: (error) => {
      window.scrollTo(0, 0);

      if ("status" in error) {
        handleBankResponseError(
          error as unknown as ProcessedBankResponseError,
          navigate
        );
      }
    },
  });
};

export const handleBankResponseError = (
  bankResponseError: ProcessedBankResponseError,
  navigate: NavigateFunction
) => {
  if (bankResponseError.body.penny_testable) {
    navigate("/company-settings/edit-bank-account/microdeposit/initiate");
    return;
  }

  if (bankResponseError.status != 422) {
    return;
  }

  const reasons = bankResponseError.body.reasons;

  if (reasons.includes("prepaid_debit_aba_number")) {
    return navigate(
      "/company-settings/edit-bank-account/error/prepaid-account"
    );
  }

  if (
    reasons.includes("invalid_routing") ||
    reasons.includes("invalid_account")
  ) {
    return navigate(
      "/company-settings/edit-bank-account/error/invalid-account"
    );
  }

  if (reasons.includes("consumer_checking_account")) {
    return navigate(
      "/company-settings/edit-bank-account/error/non-business-account"
    );
  }

  if (reasons.includes("closed_account")) {
    return navigate("/company-settings/edit-bank-account/error/closed-account");
  }

  if (reasons.includes("non_demand_deposit")) {
    return navigate("/company-settings/edit-bank-account/debit-blocked");
  }

  navigate("/company-settings/edit-bank-account/error/unknown");
};
