import { integrationConfigs } from "../configs/config";
import IntegrationCard, {
  IntegrationCardProps,
} from "./components/IntegrationCard/integration-card";
import { ComponentProps } from "react";
import {
  AccountingIntegrationsData,
  GreenhouseIntegrationData,
  ExternalPartnerIntegrationsInstances,
} from "types/IntegrationSolutionData";
import { PERMISSION } from "types/RequiredPermissions";
import { CompanyAdmin } from "types";
import {
  PmtIntegrationsWithMetadata,
  PmtIntegrationInstancesByKey,
  PmtIntegration,
  PmtSolutionsWithMetadata,
} from "types";
import { DEFAULT_PMT_INTEGRATION_PARAMS } from "./constants";
import {
  PmtIntegrationPdpIntegration,
  PmtIntegrationSetupIntegration,
} from "types/pmt-integrations";
import { isPmtIntegrationDisabled } from "../utils";

// Sorts alphabetically by category then integration name
export const sortCardsAlphabetically = (
  cards: IntegrationCardProps[]
): IntegrationCardProps[] => {
  return cards.sort((aCard, bCard) => {
    const aCardSortValue = (
      aCard.integrationCategory + aCard.integrationName
    ).toLowerCase();
    const bCardSortValue = (
      bCard.integrationCategory + bCard.integrationName
    ).toLowerCase();

    if (aCardSortValue < bCardSortValue) {
      return -1;
    }
    if (aCardSortValue > bCardSortValue) {
      return 1;
    }
    return 0;
  });
};

export const buildCardSections = (
  cards: IntegrationCardProps[]
): { header: string; cards: IntegrationCardProps[] }[] => {
  return cards.reduce((acc, card) => {
    const header = card.integrationCategory;
    const existingSection = acc.find((section) => section.header === header);
    if (existingSection) {
      existingSection.cards.push(card);
    } else {
      acc.push({ header, cards: [card] });
    }
    return acc;
  }, [] as { header: string; cards: IntegrationCardProps[] }[]);
};

export const getAccountingIntegrationsData = (
  integrations: AccountingIntegrationsData | undefined
): (ComponentProps<typeof IntegrationCard> | null)[] | undefined =>
  integrations?.map((integration) => {
    const config = integrationConfigs[integration.slug];
    if (!config) return null;
    return {
      ...config.copy.marketplaceCard,
      isBeta: integration.isBeta,
      connectionStatus:
        integration.status === "CONNECTED" ? "CONNECTED" : "DISCONNECTED",
    };
  });

export const getGreenhouseIntegrationData = (
  integration: GreenhouseIntegrationData
): ComponentProps<typeof IntegrationCard>[] => {
  if (!integration?.name) return [];
  const config = integrationConfigs[integration.name];
  return [
    {
      ...config?.copy.marketplaceCard,
      isBeta: false,
      connectionStatus:
        integration.status === "active" ? "CONNECTED" : "DISCONNECTED",
    },
  ];
};

export const getPartnerIntegrationIsConnectionStatus = (
  instance:
    | NonNullable<ExternalPartnerIntegrationsInstances>[number]
    | undefined,
  isOverInstanceLimit: boolean
) => {
  if (isOverInstanceLimit) return "ERROR";
  if (!instance) return "DISCONNECTED";
  if (instance && instance.status === "ENABLED") {
    return instance.lastUsedDate ? "CONNECTED" : "PENDING";
  }
  // The PI endpoint should only return enabled instances
  // If we ever get a disabled instance, we should show it as an error since that is not an expected state
  return "ERROR";
};
export const getPmtIntegrationIsConnectionStatus = (
  integration:
    | PmtIntegration
    | PmtIntegrationPdpIntegration
    | PmtIntegrationSetupIntegration
    | undefined
) => {
  const instancesByKey = integration?.instancesByKey;

  if (!integration || integration?.status === "inactive") return "DISCONNECTED";
  if (instanceError(instancesByKey)) return "ERROR";
  if (integration.status === "active") return "CONNECTED";
  if (integration.status === "not_started") return "PENDING";
  return "DISCONNECTED";
};

export const getPmtIntegrationsData = (
  solutions: PmtSolutionsWithMetadata["data"],
  integrations: PmtIntegrationsWithMetadata["data"],
  companyUuid?: string
): (ComponentProps<typeof IntegrationCard> | null)[] =>
  (solutions || [])?.map((solution) => {
    if (isPmtIntegrationDisabled(solution.name, companyUuid)) {
      return null;
    }
    const slug = solution.name.toLowerCase();
    const config = integrationConfigs[slug];
    const integration = integrations?.find(
      (integration) => integration.name === solution.name
    );

    if (!config) return null;
    return {
      ...config.copy.marketplaceCard,
      isBeta:
        solution.status === "BETA" || solution.status === "LIMITED_RELEASE",
      connectionStatus: getPmtIntegrationIsConnectionStatus(integration),
    };
  });

export const instanceError = (
  instancesByKey: PmtIntegrationInstancesByKey[] | undefined | null
) => {
  return instancesByKey?.some(
    (instanceByKey) => instanceByKey.instances[0]?.status === "error"
  );
};

export const userPermissionCheck = (
  requiredPermissions: PERMISSION[],
  userPermissions: PERMISSION[] | undefined
) => {
  if (!userPermissions) return false;
  // Changed this back to .some instead of .every because we want to check if
  // the user has at least one of the required permissions, since we dont have a
  // concept of umbrella permissions yet and admins with either pay_metrics or
  // make_payments permissions should have access
  return requiredPermissions.some((permission) =>
    userPermissions?.includes(permission)
  );
};

export const hubPermissionCheck = (
  requiredPermissions: PERMISSION[],
  userPermissions: PERMISSION[] | undefined
) => {
  if (!userPermissions) return false;
  return requiredPermissions.some((permission) =>
    userPermissions?.includes(permission)
  );
};

export const companyAdminsWithPermissionCheck = (
  requiredPermissions: PERMISSION[],
  companyAdmins: CompanyAdmin[] | undefined
) => {
  if (!companyAdmins) return [];
  const adminsWithPermissions: string[] = [];
  companyAdmins.map((admin) => {
    const permissions = admin.permissions;
    const hasPermission = userPermissionCheck(requiredPermissions, permissions);
    if (hasPermission) {
      adminsWithPermissions.push(admin.friendlyFullName);
    }
  });
  return adminsWithPermissions;
};

export const listAdminPermissions = (
  requiredPermissions: PERMISSION[],
  permissionsMap: { [key: number]: string }
) => {
  const permissionDescriptions: string[] = [];
  if (!permissionsMap || !requiredPermissions) return [];
  requiredPermissions.map((permission) => {
    const permissionDescription = permissionsMap[permission];
    permissionDescriptions.push(permissionDescription);
  });
  return permissionDescriptions;
};

export const setPmtIntegrationVariables = () => {
  const options = {
    ...DEFAULT_PMT_INTEGRATION_PARAMS,
  };

  return {
    integration_status: options.integration_status,
    integration_name: options.integration_name,
    integration_instance_status: options.integration_instance_status,
    integration_instance_keys: options.integration_instance_keys,
    limit: options.limit,
  };
};
