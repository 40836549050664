import { useMemo } from "react";
import { parsePartnerIntegrationsData } from "./utils";
import { OperationVariables } from "@apollo/client";
import {
  GetPmtIntegrationFrameworkDataQueryVariables,
  useGetPmtIntegrationFrameworkPdpDataQuery,
} from "types/generated/operations";
import { setPmtIntegrationVariables } from "../../../partner-setup-page/utils";

const usePdpPageData = (
  integrationSlug: string,
  integrationInstanceKeys?: string[],
  limit?: number,
  options?: OperationVariables
) => {
  const variables = {
    ...setPmtIntegrationVariables(
      integrationSlug,
      integrationInstanceKeys,
      limit
    ),
  } as GetPmtIntegrationFrameworkDataQueryVariables;

  const queryResult = useGetPmtIntegrationFrameworkPdpDataQuery({
    variables: variables,
    skip: !integrationSlug,
    fetchPolicy: "cache-and-network",
    ...options,
  });

  return useMemo(
    () => parsePartnerIntegrationsData(queryResult),
    [queryResult]
  );
};

export default usePdpPageData;
