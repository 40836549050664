import { Button } from "@justworkshr/milo-core";
import { useRequestDetail } from "pages/expenses/store";
import React, { useState } from "react";
import styles from "../../ReimbursementSummary.module.css";
import {
  useCreateReimbursementRequest,
  useUpdateReimbursementRequest,
} from "pages/expenses/hooks";
import { formatExpensesForSubmission } from "pages/expenses/utils";
import { CancelRequestDialog } from "pages/expenses/components";
import { parseExpensesForCreation } from "../../ReimbursementSummary.utils";

const { ReimbursementSummarySubmitButtonsWrapper } = styles;

interface SubmitterButtonsProps {
  isCreateFlow: boolean;
}

export const SubmitterButtons: React.FC<SubmitterButtonsProps> = ({
  isCreateFlow,
}) => {
  const {
    resetRequestDetails,
    requestDetail: {
      request: { name, uuid, expenses },
      metadata: {
        isApproverView,
        detachedExpenses,
        isRequestEditView,
        receiptUuidsToRemove,
      },
    },
  } = useRequestDetail();

  const [showCancelRequestDialog, setShowCancelRequestDialog] = useState(false);

  const { createReimbursementRequestLoading, createReimbursementRequest } =
    useCreateReimbursementRequest();

  const { updateReimbursementRequestLoading, updateReimbursementRequest } =
    useUpdateReimbursementRequest();

  const isLoading =
    createReimbursementRequestLoading || updateReimbursementRequestLoading;

  const isFormValid = (name && expenses.length !== 0) || false;

  const handleCancel = () => resetRequestDetails();

  const handleSubmit = () => {
    const formattedExpenses = formatExpensesForSubmission(
      parseExpensesForCreation(expenses, {
        detachedExpenses,
        receiptUuidsToRemove,
      })
    );

    if (isRequestEditView && uuid) {
      return updateReimbursementRequest({
        variables: {
          name,
          uuid,
          expenses: formattedExpenses,
        },
      });
    }

    return createReimbursementRequest({
      variables: {
        name,
        expenses: formattedExpenses,
      },
    });
  };

  const handleCancelRequestDialog = () => {
    setShowCancelRequestDialog((p) => !p);
  };

  if (!(isCreateFlow || isRequestEditView) || isApproverView) {
    return null;
  }

  return (
    <div>
      <div className={ReimbursementSummarySubmitButtonsWrapper}>
        <Button
          variant="filled"
          onClick={handleSubmit}
          disabled={isLoading || !isFormValid}
          data-testid="submit-button"
        >
          Submit for approval
        </Button>
        <Button
          variant="ghost"
          disabled={isLoading}
          onClick={handleCancelRequestDialog}
          data-testid="cancel-button"
        >
          Cancel
        </Button>
      </div>
      {showCancelRequestDialog && (
        <CancelRequestDialog
          onCancel={handleCancel}
          isOpen={showCancelRequestDialog}
          onClose={handleCancelRequestDialog}
        />
      )}
    </div>
  );
};

export default SubmitterButtons;
