import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { loadCountryConfigFiles } from "../countryConfigLoader";
import { addDisbursementDate } from "pages/employer-of-record/payments/utils";
import { AdditionalPayContextProps } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { FieldConfig } from "../../SetupFormGenerator";
import { CountryConfigKeyType } from "../types";
import { useFormikContext } from "formik";
import {
  AdditionalPayFormData,
  FormPayloadType,
  PaymentDetailsType,
} from "../../../types";
import type { EorMembersType } from "pages/employer-of-record/payments/types";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { MessageWithLinkType } from "pages/employer-of-record/components/MessageWithLink/types";

const useCountryConfigFetcher = (
  additionalPayContext: AdditionalPayContextProps | null,
  setPaymentSettingsFields: Dispatch<SetStateAction<FieldConfig[]>>,
  setTaxableBenefitsValues: Dispatch<SetStateAction<string[]>>,
  setLink: Dispatch<SetStateAction<string | undefined>>,
  setMessage: Dispatch<SetStateAction<MessageWithLinkType | null>>
) => {
  const { getDefaultCurrency } = useContext(SupportedCountriesContext);
  const selectedCountry = additionalPayContext?.selectedCountry;
  const membersData = additionalPayContext?.membersData;

  const { setFieldValue, values } =
    useFormikContext<AdditionalPayFormData<FormPayloadType>>();

  useEffect(() => {
    if (selectedCountry) {
      const fetchCountryConfigs = () => {
        const configs = loadCountryConfigFiles(
          selectedCountry as CountryConfigKeyType
        );
        let paymentSettingsFormFields = configs.PAYMENT_SETTINGS_FIELDS;
        if (additionalPayContext?.disbursementDateOptions) {
          paymentSettingsFormFields = addDisbursementDate(
            paymentSettingsFormFields,
            Object.values(additionalPayContext.disbursementDateOptions)
          );
        }
        setPaymentSettingsFields(paymentSettingsFormFields);
        setTaxableBenefitsValues(configs.TAXABLEBENFITSVALUES);

        "TAXABLEBENEFITSLINK" in configs
          ? setLink(configs.TAXABLEBENEFITSLINK)
          : setLink(undefined);

        const message =
          configs.TaxableBenefitsTypeMessage?.[
            values.setUp.benefitType || ""
          ] || null;

        setMessage(message);
      };

      fetchCountryConfigs();
    }
  }, [
    selectedCountry,
    additionalPayContext?.disbursementDateOptions,
    setTaxableBenefitsValues,
    setPaymentSettingsFields,
    setLink,
    setMessage,
    values.setUp.benefitType,
  ]);

  useEffect(() => {
    const formikMemberDetails: Array<PaymentDetailsType> = [];
    const validationMemberData: Array<
      Pick<EorMembersType, "id" | "workStartDate" | "workEndDate">
    > = [];
    membersData?.forEach((data: EorMembersType) => {
      const memberFormData = {
        memberId: data.id,
        currency: getDefaultCurrency(selectedCountry),
        grossAmount: "",
        workPeriodStart: "",
        workPeriodEnd: "",
      };
      formikMemberDetails.push(memberFormData);
      validationMemberData.push({
        id: data.id,
        workStartDate: data.workStartDate,
        workEndDate: data.workEndDate,
      });
    });

    setFieldValue("paymentDetails", formikMemberDetails);
    setFieldValue("validationData.members", validationMemberData);
  }, [
    membersData,
    setFieldValue,
    selectedCountry,
    values.paymentDetails.length,
    getDefaultCurrency,
  ]);
};

export default useCountryConfigFetcher;
