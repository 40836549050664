import * as Yup from "yup";
import { NonDiscretionaryType } from "./constants";
import { noEmojiTest } from "../../../validations";

export const bonusPayloadSchema = Yup.object().shape({
  taxes: Yup.object().shape({
    reason: Yup.string().when([], function ([], _, schema) {
      const {
        payload: {
          taxes: { bonusType, nonDiscretionaryType },
        },
      } = schema.context.setUp;

      const isNonDiscretionaryPerformance =
        bonusType === "Non-Discretionary" &&
        nonDiscretionaryType === NonDiscretionaryType.PERFORMANCE;

      return isNonDiscretionaryPerformance
        ? noEmojiTest.required("Reason is required")
        : Yup.string().notRequired();
    }),
  }),
  paymentSettings: Yup.object().shape({
    payPeriodId: Yup.string().required("Pay date is required"),
    notes: noEmojiTest,
  }),
});
