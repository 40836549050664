import React from "react";
import { useRequestDetail } from "pages/expenses/store";
import { ExpandableText } from "../Expandable";
import { ReturnReasonInfo } from "./components";
import styles from "./ReturnReasons.module.css";

const { ReturnReasonsWrapper } = styles;

export const ReturnReasons = React.memo(() => {
  const {
    requestDetail: {
      reviews: { returned },
    },
  } = useRequestDetail();

  if (returned.length === 0) return null;

  return (
    <div className={ReturnReasonsWrapper}>
      <h2>Return reason</h2>
      <ReturnReasonInfo review={returned[0]} />
      {returned.length > 1 && (
        <ExpandableText openDirection="bottom" buttonLabel="Show all">
          {returned.slice(1).map((review, index) => (
            <ReturnReasonInfo
              review={review}
              key={`${review.reviewedAt}_${index}`}
            />
          ))}
        </ExpandableText>
      )}
    </div>
  );
});

ReturnReasons.displayName = "ReturnReasons";

export default ReturnReasons;
