import React from "react";
import styles from "./divider.module.css";

type DividerProps = {
  size?:
    | "4xs"
    | "3xs"
    | "2xs"
    | "xs"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "7xl";
};

const { dividerPadding, dividerLine } = styles;
const Divider: React.FC<DividerProps> = ({ size }) => {
  const dividerPaddingSize = {
    "--divider-padding": size
      ? `var(--base-spacing-${size})`
      : "var(--base-spacing-xl)",
  } as React.CSSProperties;

  return (
    <div className={dividerPadding} style={dividerPaddingSize}>
      <div className={dividerLine}></div>
    </div>
  );
};

export default Divider;
