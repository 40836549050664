import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import styles from "./page-404.module.css";
import { NotFoundError } from "@justworkshr/milo-core";

const { container } = styles;

export default function Page404(): JSX.Element {
  useEffect(() => {
    try {
      throw new Error(`The page ${window.location.href} does not exist.`);
    } catch (error) {
      // Log the error to Datadog while not breaking the page
      datadogRum.addError(error);
    }
  }, []);

  return (
    <div className={container}>
      <NotFoundError />
    </div>
  );
}
