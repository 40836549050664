import React from "react";
import { useRequestDetail } from "pages/expenses/store";
import { formatDate } from "pages/expenses/utils";
import { PayDatePicker } from "../PayDatePicker";
import styles from "../../ReimbursementSummary.module.css";
import { useReimbursementSummary } from "../../ReimbursementSummary.utils";

const {
  ReimbursementSummaryItemWrapper,
  ReimbursementSummaryItemPayDateCanceledStatus,
} = styles;

interface PayDateProps {
  isCreateFlow: boolean;
}

export const PayDate: React.FC<PayDateProps> = React.memo(
  ({ isCreateFlow }) => {
    const {
      requestDetail: {
        request: { payment, myRequest: isCurrentMemberRequest },
        metadata: { isApproverView, isRequestEditView },
      },
    } = useRequestDetail();

    const { isAdminApproverView, isManagerApproverView } =
      useReimbursementSummary();

    const isCancelledPayment = payment?.status === "Canceled";

    const getPayDate = () => {
      if (isCancelledPayment) {
        return "Canceled";
      } else if (payment?.payDate) {
        return formatDate(payment.payDate);
      }

      return "—";
    };

    const renderPayDateCopy = () => {
      if (isCurrentMemberRequest) {
        return <dt>Estimated pay date</dt>;
      }

      return <dt>Pay date</dt>;
    };

    if (
      isCreateFlow ||
      (isRequestEditView && !isApproverView) ||
      isManagerApproverView
    ) {
      return null;
    }

    return (
      <div className={ReimbursementSummaryItemWrapper}>
        {renderPayDateCopy()}
        <dd
          className={
            isCancelledPayment && !isApproverView
              ? ReimbursementSummaryItemPayDateCanceledStatus
              : ""
          }
        >
          {isAdminApproverView ? <PayDatePicker /> : getPayDate()}
        </dd>
      </div>
    );
  }
);

export default PayDate;
