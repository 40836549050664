import { ReactElement } from "react";
import { Link, useParams } from "react-router-dom";
import styles from "../../SeperationReviewPage.module.css";
import { BACKGROUND_INFO_SECTION_COPY } from "./BackgroundInfoSection.constants";
import { MemberSeparationAnswer } from "pages/team-management/types";
import { InitialIntakeFormValues } from "../../../SeparationReason/InitialIntakePage/components/InitialIntakeForm/InitialIntakeForm.types";
import { booleanStringToFriendlyString } from "../../SeparationReview.util";

const {
  section,
  row,
  column,
  splitRow,
  heading,
  subHeading,
  answerSection,
  document,
} = styles;

interface BackgroundInfoSectionProps {
  answers: MemberSeparationAnswer[];
}

export const BackgroundInfoSection = ({
  answers,
}: BackgroundInfoSectionProps): ReactElement => {
  const { memberUuid } = useParams();

  // This is a slightly hacky solution to ensure that we filter out any answers that come
  // from the initial intake form.
  const fieldIdsToExclude: InitialIntakeFormValues = {
    termination_type: "",
    termination_reason: "",
    termination_date: "",
    final_work_date: "",
  };

  const filteredAnswers = answers.filter(
    (answer) => !Object.keys(fieldIdsToExclude).includes(answer.fieldId)
  );

  const answerRows = filteredAnswers.map((answer) => (
    <div
      key={answer.fieldId}
      data-testid={`answer-${answer.fieldId}`}
      className={row}
    >
      <div className={column}>
        <div className={subHeading}>{answer.fieldLabel}</div>
        <div className={answerSection}>
          {answer?.values?.map((val, index) => (
            <div key={index} className={`${val.valueDocumentId && document}`}>
              {val.valueDocumentId
                ? `File: '${val.documentTitle}'`
                : booleanStringToFriendlyString(val.valueBoolean) ||
                  val.valueDate ||
                  val.valueNumeric ||
                  val.valueText ||
                  val.valueTextSmall}
            </div>
          ))}
        </div>
      </div>
    </div>
  ));

  return answerRows?.length ? (
    <div data-testid="background-info-section" className={section}>
      <div className={splitRow}>
        <div className={heading}>{BACKGROUND_INFO_SECTION_COPY.title}</div>
        <div>
          <Link
            to={`/team-management/separation-flow/${memberUuid}/questionnaire`}
          >
            {BACKGROUND_INFO_SECTION_COPY.action}
          </Link>
        </div>
      </div>
      {answerRows}
    </div>
  ) : (
    <></>
  );
};
