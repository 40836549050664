export const formatTimePickerTime = (value: string) => {
  if (value === "") {
    return value;
  }

  const date = new Date(value);

  const formatted = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return formatted.toString();
};
