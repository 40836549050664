import { VARIABLE_NAMES } from "../variable-constants";
import { SettingsConfig } from "../settings-type";

const developmentSettings: Partial<SettingsConfig> = {
  [VARIABLE_NAMES.VARIABLE_TO_TEST_WITH]: "development",
  [VARIABLE_NAMES.FAVICON_COLOR]: "red",
  [VARIABLE_NAMES.MESH_HOST]: "http://localhost:4000",
};

export default developmentSettings;
