import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import styles from "./Tooltip.module.css";

export type PositionType = {
  top?: number | string;
  left?: number | string;
  right?: number | string;
  bottom?: number | string;
};

interface TooltipProps {
  children: ReactNode;
  content: ReactNode;
  open: boolean;
}

export default function Tooltip({
  children,
  content,
  open,
}: TooltipProps): ReactElement {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const targetRef = containerRef;
  const contentRef = useRef<HTMLDivElement | null>(null);

  const [position, setPosition] = useState<PositionType>({ top: 0, left: 0 });

  useEffect(() => {
    if (open) {
      handleCenterTooltip();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleCenterTooltip = () => {
    const newPosition = findCoordinates();

    if (!newPosition) return;

    setPosition(newPosition);
  };

  const findCoordinates = () => {
    if (containerRef.current) {
      const targetRefPosition = targetRef?.current?.getBoundingClientRect();
      const containerRefPosition =
        containerRef?.current?.getBoundingClientRect();

      const offsets = {
        left:
          targetRefPosition?.left && containerRefPosition?.left
            ? targetRefPosition.left - containerRefPosition.left
            : 0,
        bottom: targetRefPosition?.bottom
          ? targetRefPosition.bottom - targetRefPosition.top
          : 0,
      };

      let margin = 0;
      if (targetRef?.current) {
        const targetStyles = getComputedStyle(targetRef?.current);
        margin =
          parseFloat(targetStyles.marginLeft) +
          parseFloat(targetStyles.marginRight);
      }

      return {
        bottom: `${offsets.bottom + 10}px`,
        left: `${getLeftAlignment() + offsets.left + margin}px`,
      };
    }
  };

  const getLeftAlignment = () => {
    const content = {
      width: contentRef?.current?.offsetWidth,
    };

    const target = {
      width: targetRef?.current?.offsetWidth,
    };

    const targetWidth = target.width as number;
    const contentWidth = content.width as number;
    const halfTargetWidth = targetWidth / 2;
    const halfContentWidth = contentWidth / 2;

    return halfTargetWidth - halfContentWidth;
  };

  return (
    <div className={styles.tooltip} ref={containerRef}>
      <div className={styles.trigger}>{children}</div>
      <div
        className={`${styles.contentContainer} ${styles.arrowCenter}`}
        ref={contentRef}
        style={{ ...position, visibility: open ? "visible" : "hidden" }}
      >
        {content}
      </div>
    </div>
  );
}
