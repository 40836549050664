import { Avatar } from "@justworkshr/milo-core";
import CountryMapSvg from "../../CountryMapSvg/CountryMapSvg";
import { CountryCode } from "pages/employer-of-record/onboarding/constants";
import styles from "./AvatarWithMap.module.css";

type Props = React.PropsWithChildren<{
  name: string;
  countryCode: keyof typeof CountryCode;
}>;

export default function AvatarWithMap(props: Props) {
  const { name, countryCode } = props;

  return (
    <div className={styles.locationMap}>
      <CountryMapSvg name={countryCode} className={styles.countryMap} />
      <div className={styles.avatarImage}>
        <Avatar name={name} size="xl" />
      </div>

      <div className={styles.headerActionSection}>{props.children}</div>
    </div>
  );
}
