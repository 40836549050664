import {
  ALL_STATE_REQUIREMENTS,
  STANDARD_TEMPLATE,
} from "../../../../constants";
import styles from "./AllStateRequirementsPage.module.css";
import { StateRequirementsCard } from "./components/StateRequirementsCard";
import { useGetStateSeparationNoticeRequirementsQuery } from "types/generated/operations";
import { Loading } from "pages/expenses/components";
import { SeparationNoticeStateRequirement } from "pages/team-management/types";
import { DisplayIcon, SystemIcon } from "@justworkshr/milo-icons";

export const AllStateRequirementsPage = () => {
  const { data, loading } = useGetStateSeparationNoticeRequirementsQuery({
    variables: {
      state: null, // state defined null to retrieve all state documentation
    },
  });

  const findStateDocumentLink = (
    stateRequirement?: SeparationNoticeStateRequirement | undefined
  ) => {
    const stateAbbreviation = stateRequirement
      ? stateRequirement.stateAbbreviation
      : STANDARD_TEMPLATE;

    const separationNoticeRequirement =
      data?.stateSeparationNoticeRequirements?.find(
        (stateDocument) => stateDocument.stateAbbreviation === stateAbbreviation
      );

    return (
      separationNoticeRequirement?.presignedUrl ||
      // we return the Standard Template if the caller provides a State Requirement
      // but it doesn't exist in the returned State Separation Notice Requirements
      data?.stateSeparationNoticeRequirements?.find(
        (stateDocument) => stateDocument.stateAbbreviation === STANDARD_TEMPLATE
      )?.presignedUrl
    );
  };

  return loading ? (
    <div className={styles.loader}>
      <Loading />
    </div>
  ) : (
    <div className={styles.allStateRequirementsContainer}>
      <div className={styles.noticeSeparationHeader}>
        <a href="">Back to Team management</a>
        <h1>Notice of Separation</h1>
        <p>
          Best practices require notifying your employee when they are being
          terminated. Some states have standardized processes in place that
          require additional steps be taken to remain complaint. Below are
          different templates and forms to notify your employee of the
          separation based on their state’s requirements.
        </p>
      </div>

      <div className={styles.noticeSeparationSubHeader}>
        <DisplayIcon iconName="papers" color="redPurple" size="large" />
        <div className={styles.noticeSeparationSubHeaderText}>
          <h1>
            States that require the employer to send separation info to the
            state
          </h1>
          <p>
            These states requires you, as the employer to send the appropriate
            form to the employee upon termination.
          </p>
        </div>
      </div>

      <div className={styles.stateCardContainer}>
        {Object.values(ALL_STATE_REQUIREMENTS)
          .filter((stateRequirement) => {
            return stateRequirement.notifyState == true;
          })
          .map((stateRequirement) => {
            return (
              <StateRequirementsCard
                stateRequirement={stateRequirement}
                presignedUrl={findStateDocumentLink(stateRequirement)}
              />
            );
          })}
      </div>

      <div className={styles.noticeSeparationSubHeader}>
        <DisplayIcon iconName="shield" color="redPurple" size="large" />
        <div className={styles.noticeSeparationSubHeaderText}>
          <h1>State-specific forms to send with Notice of separation</h1>
          <p>
            These states require you as the Employer to send the ex-employee the
            appropriate form upon separation.
          </p>
        </div>
      </div>

      <div className={styles.stateCardContainer}>
        {Object.values(ALL_STATE_REQUIREMENTS)
          .filter((stateRequirement) => {
            return stateRequirement.notifyState == false;
          })
          .map((stateRequirement) => {
            return (
              <StateRequirementsCard
                stateRequirement={stateRequirement}
                presignedUrl={findStateDocumentLink(stateRequirement)}
              />
            );
          })}
      </div>

      <div className={styles.noticeSeparationFooter}>
        <DisplayIcon iconName="check-on-page" color="blueYellow" size="large" />

        <div className={styles.noticeSeparationFooterText}>
          <h1>All other states</h1>
          <p>
            While there are no state requirements to follow, it’s best practice
            to send a notice of separation to your employee. We’ve put together
            a template on how to inform the employee they’ll be separating from
            the company.
          </p>
        </div>
      </div>

      {!loading && data?.stateSeparationNoticeRequirements && (
        <div className={styles.footerDownloadLink}>
          <a href={findStateDocumentLink()}>Download separation template</a>
          <SystemIcon iconName="external-link" color="brand" />
        </div>
      )}
    </div>
  );
};

export default AllStateRequirementsPage;
