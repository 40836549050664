import { fetchClient } from "@justworkshr/fe-utility-kit";
import { useMutation } from "@tanstack/react-query";
import { buildClockworkWebPath } from "lib/resource-finder";
import { useBankForm } from "pages/company-settings/hooks/useBankForm";

export interface ProcessedBankResponseError {
  status: number;
  // not doing anything with the body yet
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any;
  message: string;
}

interface MicrodepositVerificationData {
  deposit1: string;
  deposit2: string;
}

const parseJSONResponse = async (response: Response) => {
  try {
    return await response.json();
  } catch (e) {
    return null;
  }
};

export const useVerifyMicrodeposit = () => {
  const { isOnboarding } = useBankForm();
  return useMutation({
    mutationFn: async ({
      deposit1,
      deposit2,
    }: MicrodepositVerificationData) => {
      const response = await fetchClient.post(
        buildClockworkWebPath("/company/bank_account/validate_penny_test"),
        {
          body: JSON.stringify({ deposit1, deposit2 }),
        }
      );

      if (!response.ok) {
        const errorBody = await parseJSONResponse(response);
        const errorMessage =
          response.status === 400 ? "Invalid request" : "Something went wrong";

        throw {
          message: errorMessage,
          status: response.status,
          body: errorBody,
        } as ProcessedBankResponseError;
      }

      return await parseJSONResponse(response);
    },
    onSuccess: () => {
      if (isOnboarding) {
        window.location.assign(
          buildClockworkWebPath("/onboarding_checklist/bank_verify_success")
        );
      } else {
        window.location.assign(
          buildClockworkWebPath("/company/bank_verify_success")
        );
      }
    },
  });
};
