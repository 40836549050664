/* istanbul ignore file */

import React, { useRef, useState } from "react";
import {
  generateRandomIdForAria,
  TOOLTIP_HORIZONTAL_ALIGNMENTS,
} from "./Tooltip.constants";

import styles from "./Tooltip.module.css";
import { PositionType } from "./Tooltip.types";

export type TooltipProps = {
  id?: string;
  content: React.ReactNode;
  children: React.ReactNode;
  arrowAlignment: TOOLTIP_HORIZONTAL_ALIGNMENTS;
  visibility?: React.CSSProperties["visibility"];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  targetRefOverride?: React.RefObject<any> | null;
};

export const Tooltip: React.FC<TooltipProps> = ({
  id,
  content,
  children,
  visibility,
  arrowAlignment,
  targetRefOverride,
}) => {
  const containerRef = useRef<HTMLSpanElement | null>(null);
  const targetRef =
    targetRefOverride === undefined ? containerRef : targetRefOverride;

  const contentRef = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState<PositionType>({ top: 0, left: 0 });
  const generatedId: string = generateRandomIdForAria();
  const tooltipId = id || generatedId;

  const handleCenterTooltip = () => {
    const newPosition = findCoordinates();

    if (!newPosition) return;

    setPosition(newPosition);
  };

  const getContentSize = () => ({
    height: contentRef.current?.offsetHeight,
    width: contentRef.current?.offsetWidth,
  });

  const findCoordinates = () => {
    const content = getContentSize();

    if (containerRef.current) {
      const targetRefPosition = targetRef?.current?.getBoundingClientRect();
      const containerRefPosition =
        containerRef?.current?.getBoundingClientRect();

      const offsets = {
        top: targetRefPosition?.top - containerRefPosition?.top || 0,
        left: targetRefPosition?.left - containerRefPosition?.left || 0,
        right: targetRefPosition?.right - containerRefPosition?.right || 0,
        bottom: targetRefPosition?.bottom - containerRefPosition?.bottom || 0,
      };

      return {
        top: `-${(content.height as number) - offsets.top + 10}px`,
        left: `${getLeftAlignment() + offsets.left}px`,
      };
    }
  };

  const getLeftAlignment = () => {
    const content = {
      height: contentRef?.current?.offsetHeight,
      width: contentRef?.current?.offsetWidth,
    };

    const target = {
      width: targetRef?.current?.offsetWidth,
    };

    const targetWidth = target.width as number;
    const contentWidth = content.width as number;
    const halfTargetWidth = targetWidth / 2;
    const halfContentWidth = contentWidth / 2;

    if (arrowAlignment === TOOLTIP_HORIZONTAL_ALIGNMENTS.RIGHT) {
      return halfTargetWidth - contentWidth + 20;
    } else if (arrowAlignment === TOOLTIP_HORIZONTAL_ALIGNMENTS.LEFT) {
      return halfTargetWidth - 20;
    } else {
      return halfTargetWidth - halfContentWidth;
    }
  };

  return (
    <span
      tabIndex={0}
      role="button"
      ref={containerRef}
      className={styles.tooltip}
      aria-describedby={tooltipId}
      onMouseEnter={handleCenterTooltip}
    >
      <div
        className={`${styles.tooltipContent} ${
          styles[`arrow${arrowAlignment}`]
        }`}
        role="tooltip"
        id={tooltipId}
        ref={contentRef}
        style={{ ...position, visibility: visibility }}
      >
        {content}
      </div>
      <span>{children}</span>
    </span>
  );
};

export default Tooltip;
