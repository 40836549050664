import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { ReviewDataProps } from "../types";
import { JobInformationReview } from "./job-information-review";
import { CertficateHolderReview } from "../../components";
import { EmployeeReview } from "./employee-review";
import { Button, PageHeader, Alert } from "@justworkshr/milo-core";
import { ActionFooter, Form } from "@justworkshr/milo-form";
import { SystemIcon } from "@justworkshr/milo-icons";
import { WAIVER_EDIT_URL } from "pages/workers-compensation/document-request/constants";
import styles from "./waiver-review.module.css";
import { LocationReview } from "./location-review";

const {
  reviewForm,
  formNavigation,
  visited,
  highlighted,
  reviewHeader,
  backButton,
  headerContainer,
} = styles;

export default function WaiverReview({
  formData,
  handleSubmit,
  isLoading,
  submitError,
  jobLocations,
  supportingDocuments,
}: ReviewDataProps): ReactElement {
  return (
    <div className="milo--grid">
      <div className={reviewForm} data-testid="waiverReview">
        <div className={headerContainer}>
          <Button
            variant="ghost"
            as={Link}
            to={WAIVER_EDIT_URL}
            className={backButton}
          >
            Back
          </Button>
          <PageHeader
            title="Review"
            children="Make sure all of the information is correct and then submit"
            className={reviewHeader}
          ></PageHeader>
        </div>
        <Alert color="destructive" visible={submitError.length > 0}>
          {submitError}
        </Alert>
        <div className={formNavigation}>
          <SystemIcon iconName="check" color="success" />
          <p className={visited}>Waiver Info</p>
          <SystemIcon iconName="chevron-right" />
          <p className={highlighted}>Review</p>
        </div>
        <hr />
        <JobInformationReview formData={formData} />
        <EmployeeReview employees={formData.employees} />
        <CertficateHolderReview
          supportingDocuments={supportingDocuments}
          formData={formData}
          requestType="waiver"
        />
        <LocationReview jobLocations={jobLocations} />
        <Form onSubmit={handleSubmit}>
          <ActionFooter
            actions={[
              <Button
                variant="outlined"
                key="BackWaiverReview"
                as={Link}
                to={`${WAIVER_EDIT_URL}?currentStep=3`}
              >
                Back
              </Button>,
              <Button
                variant="filled"
                key="SubmitWaiverReview"
                type="submit"
                loading={isLoading}
              >
                Submit
              </Button>,
            ]}
          />
        </Form>
      </div>
    </div>
  );
}
