import { RetroactivePayload } from "../../../types";

export const RETROACTIVE_INITIAL_VALUES: RetroactivePayload = {
  paymentSettings: {
    payPeriodId: "",
    effectiveDate: "",
    disbursementDate: "",
    notes: "",
  },
};
