import {
  EXPENSE_NON_REIMBURSABLE_TYPE,
  EXPENSE_REIMBURSABLE_TYPE,
} from "pages/expenses/constants";
import { RequestDetailExpense } from "types/Expenses";

export const getTotalReimbursableAmount = (
  expenses: RequestDetailExpense[]
) => {
  const totalReimbursableAmount = expenses.reduce((total, expense) => {
    const isReimbursable =
      expense?.expenseType === EXPENSE_REIMBURSABLE_TYPE ||
      expense?.expenseType === null;

    if (isReimbursable) {
      return total + parseFloat((expense.amount / 100.0).toString());
    }

    return total;
  }, 0);

  return totalReimbursableAmount.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const getTotalSubmitted = (expenses: RequestDetailExpense[]) =>
  expenses.reduce(
    (totalSubmitted, expense) => (totalSubmitted += expense.amount),
    0
  );

export const getTotalNonReimbursable = (expenses: RequestDetailExpense[]) => {
  return expenses.reduce((totalNonReimbursable, expense) => {
    const isNonReimbursable =
      expense?.expenseType === EXPENSE_NON_REIMBURSABLE_TYPE;

    if (isNonReimbursable) {
      totalNonReimbursable += expense.amount;
    }

    return totalNonReimbursable;
  }, 0);
};
