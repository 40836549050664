import styles from "./page-520.module.css";
import { UnknownError } from "@justworkshr/milo-core";

const { container } = styles;

export default function Page520(): JSX.Element {
  return (
    <div className={container}>
      <UnknownError />
    </div>
  );
}
