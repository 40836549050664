import React from "react";
import { ExpandableText } from "pages/expenses/components/Expandable";
import { getTimeFromTimestamp } from "../../AuditLogPage.utils";
import styles from "./AuditLogItem.module.css";

const {
  AuditLogItemTime,
  AuditLogItemWrapper,
  AuditLogItemContainer,
  AuditLogItemDetailWrapper,
} = styles;

interface AuditLogItemProps {
  author: string;
  occurredAt: string;
  previewText: string;
  children: React.ReactNode | React.ReactNode[];
}

export const AuditLogItem: React.FC<AuditLogItemProps> = ({
  author,
  children,
  occurredAt,
  previewText,
}) => {
  return (
    <div className={AuditLogItemWrapper}>
      <div className={AuditLogItemContainer}>
        <p className={AuditLogItemTime}>{getTimeFromTimestamp(occurredAt)}</p>
        <p>{previewText}</p>
        {author && <p>By {author}</p>}
        <ExpandableText
          buttonLabelAsRenderer={({ isOpen, handleClick, iconButton }) => {
            return (
              <span
                role="button"
                onClick={handleClick}
                data-testid="audit-dropdown-toggle"
              >
                {isOpen ? "Hide" : "View"} details {iconButton}
              </span>
            );
          }}
        >
          <div className={AuditLogItemDetailWrapper}>{children}</div>
        </ExpandableText>
      </div>
    </div>
  );
};
export default AuditLogItem;
