export const PERMISSIONS_TABLE_HEADERS = [
  "Employee",
  "Manage employee expenses",
  "Manage expenses",
  "View invoices & reporting",
];

export const PERMISSIONS_EMPTY_STATE_COPY = (
  <p>
    There are no approvers set. Employees will not be able to submit expenses
    until permissions are set. Select <strong>Edit</strong> to get started.
  </p>
);
