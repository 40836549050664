import { SetCategoriesPayload, SetActiveCategoryPayload } from "../constants";

export enum CategoryActionTypes {
  SET_CATEGORIES = "SET_CATEGORIES",
  SET_ACTIVE_CATEGORY = "SET_ACTIVE_CATEGORY",
}

export type CategoryActions =
  | {
      type: CategoryActionTypes.SET_CATEGORIES;
      payload: SetCategoriesPayload;
    }
  | {
      type: CategoryActionTypes.SET_ACTIVE_CATEGORY;
      payload: SetActiveCategoryPayload;
    };
