import React from "react";
import { TOOLTIP_HORIZONTAL_ALIGNMENTS, Tooltip } from "../Tooltip";
import styles from "./ExpenseUuidTooltip.module.css";

const { ExpenseUuidTooltipText } = styles;

interface ExpenseUuidTooltipProps {
  uuid: string;
  label: string;
}

export const ExpenseUuidTooltip: React.FC<ExpenseUuidTooltipProps> = ({
  uuid,
  label,
}) => {
  return (
    <Tooltip
      visibility="visible"
      content={<>{uuid}</>}
      id="expense-uuid-tooltip"
      arrowAlignment={TOOLTIP_HORIZONTAL_ALIGNMENTS.CENTER}
    >
      <p className={ExpenseUuidTooltipText}>
        {`${label}${uuid.slice(0, 8)}...`}
      </p>
    </Tooltip>
  );
};
