import { useState, useCallback } from "react";

export const useMultiAction = <T>() => {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);

  const clearItems = useCallback(() => setSelectedItems([]), []);

  const handleSelectedItems = useCallback(
    (newSelections: T[] = []) => {
      const isEmpty = selectedItems.length === 0;
      const hasItems = selectedItems.length !== 0;
      const isFilled = selectedItems.length === newSelections.length;

      if (isFilled) {
        setSelectedItems([]);
      } else if (isEmpty || hasItems) {
        setSelectedItems(newSelections);
      }
    },
    [selectedItems]
  );

  const handleSelectedItem = useCallback(
    (newSelectedItem: T, exists: boolean, callback: (data: T[]) => T[]) => {
      if (exists) {
        setSelectedItems((prevSelectedItems) => callback(prevSelectedItems));
      } else {
        setSelectedItems((prevSelectedItems) => [
          ...prevSelectedItems,
          newSelectedItem,
        ]);
      }
    },
    []
  );

  return { clearItems, selectedItems, handleSelectedItem, handleSelectedItems };
};

export default useMultiAction;
