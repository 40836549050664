import { useProfileContextData } from "../../contexts/ProfileInfoContext";
import styles from "./BankAccount.module.css";
import { useTranslation } from "react-i18next";
import BankAccountDescription from "./components/BankAccountDescription";
import BankAccountHeader from "./components/BankAccountHeader";
import BankAccountAttributes from "./components/BankAccountAttributes";

export default function BankAccount() {
  const { profileInfo } = useProfileContextData();
  const { t } = useTranslation();

  const workCountry = profileInfo?.eorEmployeeProfile.employment?.workCountry;
  const bankAccount = profileInfo?.eorEmployeeProfile.bankAccount;
  const bankAccountData = bankAccount?.data;

  if (!workCountry || !bankAccount || !bankAccountData) return <></>;

  return (
    <div className={styles.bankAccount}>
      <BankAccountHeader>{t("Bank account")}</BankAccountHeader>

      <div className={styles.description}>
        <BankAccountDescription
          accountNickname={bankAccount.nickname ?? undefined}
          accountType={bankAccountData.accountType ?? undefined}
          bankName={bankAccountData.bankName ?? undefined}
          defaultDisplayName={t("Primary Account")}
        />
      </div>

      <BankAccountAttributes
        workCountry={workCountry}
        bankAccountData={bankAccountData}
      />
    </div>
  );
}
