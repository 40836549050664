import { ReactElement, useState } from "react";
import Button from "app-frame/common/Button";
import Form from "app-frame/common/Form";
import FormField from "app-frame/common/Form/FormField";
import TextArea from "app-frame/common/TextArea";
import Avatar from "app-frame/common/Avatar";
import Check from "./icons/check.svg?react";
import Close from "./icons/close.svg?react";
import {
  useApproveDenyTimeOffRequestMutation,
  useGetMemberAlertsQuery,
} from "types/generated/operations";
import { containsIllegalChar } from "lib/text-validation";
import styles from "./pto-request.module.css";
import { ApproveDenyTimeOffRequestStatus } from "types/generated/operations";
import { useAlertContext } from "../../context/alert-context-provider";
import { buildWebPath } from "lib/resource-finder";

const {
  ptoRequest,
  ptoRequestContent,
  messageLink,
  approveDenyButtons,
  feedbackForms,
  feedbackFormButtons,
  inner,
  showForm,
} = styles;

export interface PTORequestProps {
  message: string;
  firstName: string;
  lastName: string;
  photoUrl?: string;
  memberUuid: string;
  timeOffRequestUuid: string;
  url: string;
}

enum RequestFormState {
  SELECT,
  APPROVE = "approve",
  DENY = "deny",
}

const INPUT_ERROR_MESSAGE =
  "Some emojis and special characters are not supported. Please try again.";

export default function PTORequest({
  message,
  firstName,
  lastName,
  photoUrl,
  memberUuid,
  timeOffRequestUuid,
  url,
}: PTORequestProps): ReactElement {
  const [formState, toggleFormState] = useState(RequestFormState.SELECT);
  const [privateNotes, setPrivateNotes] = useState("");
  const [privateNotesValid, togglePrivateNotesValid] = useState(true);
  const [publicNotes, setPublicNotes] = useState("");
  const [publicNotesValid, togglePublicNotesValid] = useState(true);
  const [loading, toggleLoading] = useState(false);
  const { setAlertMessage, setAlertColor, toggleAlert } = useAlertContext();

  const { refetch } = useGetMemberAlertsQuery();
  const [approveDenyTimeOffRequestMutation] =
    useApproveDenyTimeOffRequestMutation();

  const handleSubmit = async (event: React.MouseEvent<HTMLElement>) => {
    toggleLoading(true);
    event.preventDefault();
    if (privateNotesValid && publicNotesValid) {
      const response = await approveDenyTimeOffRequestMutation({
        variables: {
          memberUuid: memberUuid,
          notesPrivate: privateNotes,
          notesPublic: publicNotes,
          status:
            formState === RequestFormState.APPROVE
              ? ApproveDenyTimeOffRequestStatus.Approved
              : ApproveDenyTimeOffRequestStatus.Denied,
          timeOffRequestUuid: timeOffRequestUuid,
        },
      });
      if (response.data?.approveDenyTimeOffRequest?.success) {
        await refetch();
        setAlertMessage("PTO request processed successfully.");
        setAlertColor("additive");
      } else {
        setAlertMessage(
          "We could not process that PTO request. Try again later."
        );
        setAlertColor("destructive");
      }
      toggleLoading(false);
      toggleAlert(true);
    }
  };

  const privateNotesChangedHandler = function (value: string) {
    if (containsIllegalChar(value)) {
      togglePrivateNotesValid(false);
    } else {
      setPrivateNotes(value);
      togglePrivateNotesValid(true);
    }
  };

  const publicNotesChangedHandler = function (value: string) {
    if (containsIllegalChar(value)) {
      togglePublicNotesValid(false);
    } else {
      setPublicNotes(value);
      togglePublicNotesValid(true);
    }
  };

  const approveDenyState = (
    <div className={approveDenyButtons}>
      <Button
        size="small"
        mode="secondary"
        color="green"
        type="button"
        icon={{ figure: <Check />, afterText: false, iconOnly: false }}
        onClick={() => toggleFormState(RequestFormState.APPROVE)}
      >
        Approve
      </Button>
      <Button
        size="small"
        mode="secondary"
        color="red"
        type="button"
        icon={{ figure: <Close />, afterText: false, iconOnly: false }}
        onClick={() => toggleFormState(RequestFormState.DENY)}
      >
        Deny
      </Button>
    </div>
  );

  const submitState = (
    <div className={feedbackForms}>
      <Form layout="vertical">
        <FormField
          labelContent={`Response for ${firstName}`}
          error={!publicNotesValid ? INPUT_ERROR_MESSAGE : ""}
        >
          <TextArea
            name="publicNotes"
            maxLength={10000}
            onChange={(event) => publicNotesChangedHandler(event.target.value)}
          />
        </FormField>
        <FormField
          labelContent="Internal note"
          error={!privateNotesValid ? INPUT_ERROR_MESSAGE : ""}
        >
          <TextArea
            name="privateNotes"
            maxLength={10000}
            onChange={(event) => privateNotesChangedHandler(event.target.value)}
          />
        </FormField>
        <div className={feedbackFormButtons}>
          <Button
            mode="frameless"
            type="button"
            onClick={() => toggleFormState(RequestFormState.SELECT)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={(e) => handleSubmit(e)}
            loading={loading}
            data-testid="submit-button"
          >
            {formState}
          </Button>
        </div>
      </Form>
    </div>
  );

  const innerClasses = [inner];
  if (formState !== RequestFormState.SELECT) {
    innerClasses.push(showForm);
  }

  return (
    <div className={ptoRequest} data-testid="ptoRequest">
      <Avatar
        name={`${firstName} ${lastName}`}
        photoUrl={photoUrl}
        width={30}
        height={30}
      />
      <div className={ptoRequestContent}>
        <a href={buildWebPath(url)} className={messageLink}>
          {message}
        </a>
        <div className={innerClasses.join(" ")}>
          {approveDenyState}
          {submitState}
        </div>
      </div>
    </div>
  );
}
