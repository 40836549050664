import { ClaimsHome } from "./claims-request";
import { WCDocumentRequest } from "./document-request";

const WC_DOCUMENT_REQUEST_PATH = "/document-request";
const WC_CLAIMS_REQUEST_PATH = "/workers-compensation-claims";

export {
  ClaimsHome,
  WCDocumentRequest,
  WC_DOCUMENT_REQUEST_PATH,
  WC_CLAIMS_REQUEST_PATH,
};
