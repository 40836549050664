export const DUPLICATE_CATEGORY_NAME_ERROR = "That category already exists.";

export const DEFAULT_CATEGORY_DESCRIPTION = `Select which expense categories you want to make visible to your employees.
    You can also edit the rules to include different fields in expense form submissions.`;

export const CATEGORY_VISIBLE_TEXT =
  "You need to have at least 1 category visible.";

export const CATEGORY_CUSTOM_DESCRIPTION =
  "Create new expense categories that you want to make visible to your employees.";
