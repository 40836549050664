import { FormikFormField } from "lib/formik/FormikFormField";
import {
  ADDITIONAL_PAYMENTS_FORM_COPY,
  ADDITIONAL_PAYMENTS_PAY_FREQUENCY_OPTIONS,
} from "../../AdditionalPaymentsPage.constants";
import { DatePicker } from "pages/team-management/components";
import { Select, SelectOption, TextInput } from "@justworkshr/milo-form";
import { useFormikContext } from "formik";
import {
  AdditionalPaymentsFormValues,
  castAmountToNumber,
} from "../../../SeparationPay.formUtils";

import styles from "../../../SeparationPay.module.css";
import { isSeverancePayment } from "./AdditionalPaymentsForm.helpers";
import { useEffect } from "react";

const { TwoColumnRow } = styles;

export const PaymentInputRow = ({ isRecurring }: { isRecurring: boolean }) => {
  const { values, setFieldValue } =
    useFormikContext<AdditionalPaymentsFormValues>();

  useEffect(() => {
    if (!isRecurring && (values.pay_frequency || values.number_of_payments)) {
      setFieldValue("pay_frequency", "");
      setFieldValue("number_of_payments", "");
    }
  }, [
    isRecurring,
    setFieldValue,
    values.number_of_payments,
    values.pay_frequency,
  ]);

  useEffect(() => {
    if (values?.amount.length === 0 || values.amount.charAt(0) !== "$") {
      const numericalAmount = castAmountToNumber(values?.amount);
      setFieldValue("amount", `$${numericalAmount ? numericalAmount : ""}`);
    }
  }, [setFieldValue, values?.amount]);

  return (
    <>
      <div className={TwoColumnRow}>
        <FormikFormField
          name={"pay_date"}
          label={
            isRecurring
              ? ADDITIONAL_PAYMENTS_FORM_COPY.pay_start_date
              : ADDITIONAL_PAYMENTS_FORM_COPY.pay_date
          }
          required={true}
        >
          <DatePicker
            id={isRecurring ? "payStartDate" : "payDate"}
            data-testid={isRecurring ? "pay_start_date" : "pay-date"}
            name={isRecurring ? "pay_start_date" : "pay-date"}
            value={values.pay_date}
            handleChange={(date) => {
              setFieldValue("pay_date", date);
            }}
            isValidDate={(date) => {
              const currentDate = new Date(date);
              const isValidPayDate =
                currentDate >= new Date(values.earliest_pay_date);
              const isNotWeekend =
                currentDate.getDay() !== 0 && currentDate.getDay() !== 6;
              if (!isSeverancePayment(values.type)) {
                return isValidPayDate && isNotWeekend;
              }
              return (
                isValidPayDate &&
                isNotWeekend &&
                currentDate > new Date(values.termination_date)
              );
            }}
          />
        </FormikFormField>
        <FormikFormField
          name="amount"
          label={
            isRecurring
              ? ADDITIONAL_PAYMENTS_FORM_COPY.amount_per_pay
              : ADDITIONAL_PAYMENTS_FORM_COPY.amount
          }
          required={true}
        >
          <TextInput
            onKeyDown={(event) => {
              const isCtrlOrCmdPressed = event.ctrlKey || event.metaKey;
              const forbiddenCharacters = /[^0-9.]/; // Do not allow any characters other than numbers and dot
              // The event key length is checked to allow the user to use the arrow keys, backspace, delete, etc.
              // We check if ctrl/cmd is pressed to allow the user to use shortcuts like ctrl + c, cmd + a, etc.
              // We prevent more than one decimal from being entered
              // And we prevent more than two numbers after the decimal
              if (
                event.key.length === 1 &&
                !isCtrlOrCmdPressed &&
                (forbiddenCharacters.test(event.key) ||
                  (event.key === "." && values.amount.includes(".")) ||
                  values.amount.split(".")[1]?.length > 1)
              ) {
                event.preventDefault();
              }
            }}
            name="amount"
            placeholder="Enter amount"
          />
        </FormikFormField>
      </div>
      {isRecurring ? (
        <div className={TwoColumnRow}>
          <FormikFormField
            name={"pay_frequency"}
            label={ADDITIONAL_PAYMENTS_FORM_COPY.pay_frequency}
            required={true}
          >
            <Select
              name="pay_frequency"
              value={values.pay_frequency}
              placeholder="Select.."
            >
              {Object.entries(ADDITIONAL_PAYMENTS_PAY_FREQUENCY_OPTIONS).map(
                ([key, value]) => {
                  return (
                    <SelectOption value={key} key={key}>
                      {value}
                    </SelectOption>
                  );
                }
              )}
            </Select>
          </FormikFormField>
          <FormikFormField
            name="number_of_payments"
            label={ADDITIONAL_PAYMENTS_FORM_COPY.number_of_payments}
            required={true}
          >
            <TextInput
              name="number_of_payments"
              value={values.number_of_payments}
              placeholder="Enter amount"
            />
          </FormikFormField>
        </div>
      ) : null}
    </>
  );
};
