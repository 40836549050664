import { Box } from "@justworkshr/milo-core";
import styles from "./Documents.module.css";
import { ReactElement } from "react";
import { useProfileContextData } from "../../contexts/ProfileInfoContext";
import { buildWebPath } from "lib/resource-finder";

export default function Documents(): ReactElement {
  const { profileInfo } = useProfileContextData();
  const displayName = profileInfo?.eorEmployeeProfile.personalInfo
    ?.preferredFirstName
    ? profileInfo?.eorEmployeeProfile.personalInfo?.preferredFirstName
    : profileInfo?.eorEmployeeProfile.personalInfo?.firstName;

  return (
    <>
      <div className={styles.wrapper}>
        <Box padding="xl">
          <div className={styles.text}>
            To view paystubs for {displayName && <> {displayName},</>} visit{" "}
            <a href={buildWebPath("/admin/documents")}>Documents Center</a>.
          </div>
        </Box>
      </div>
    </>
  );
}
