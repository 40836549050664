import * as Yup from "yup";

const zipRegex = /^\d{5}(?:\d{4})?$/;

export const validationSchema = Yup.object().shape({
  wasContacted: Yup.boolean(),
  wasContactedDetails: Yup.string().when("wasContacted", {
    is: true,
    then: (schema) =>
      schema
        .required("This field is required")
        .test(
          "no-whitespace-only",
          "Whitespace only is not allowed",
          (value) => {
            return !/^\s+$/.test(value);
          }
        ),
  }),
  noMedicalTreatment: Yup.boolean(),
  firstAid: Yup.boolean(),
  EMSTransport: Yup.boolean(),
  overnightHospitalization: Yup.boolean(),
  other: Yup.boolean(),
  medicalClinic: Yup.boolean(),
  checkBoxTreatment: Yup.boolean().when(
    [
      "noMedicalTreatment",
      "firstAid",
      "EMSTransport",
      "overnightHospitalization",
      "other",
      "medicalClinic",
    ],
    {
      is: (
        noMedicalTreatment: boolean,
        firstAid: boolean,
        EMSTransport: boolean,
        overnightHospitalization: boolean,
        other: boolean,
        medicalClinic: boolean
      ) =>
        !noMedicalTreatment &&
        !firstAid &&
        !EMSTransport &&
        !overnightHospitalization &&
        !other &&
        !medicalClinic,
      then: (schema) =>
        schema.required("At least one checkbox must be selected"),
    }
  ),
  treatmentDetails: Yup.string(),
  address: Yup.object().when(
    [
      "firstAid",
      "EMSTransport",
      "overnightHospitalization",
      "other",
      "medicalClinic",
    ],
    {
      is: (
        firstAid: boolean,
        EMSTransport: boolean,
        overnightHospitalization: boolean,
        other: boolean,
        medicalClinic: boolean
      ) =>
        firstAid ||
        EMSTransport ||
        overnightHospitalization ||
        other ||
        medicalClinic,
      then: (schema) =>
        schema.shape({
          street1: Yup.string().when(["city", "state", "postalCode"], {
            is: (city: string, state: string, postalCode: string) =>
              city &&
              city.length > 0 &&
              state &&
              state.length > 0 &&
              postalCode &&
              postalCode.length > 0,
            then: (schema) => schema.required("This field is required"),
          }),
          city: Yup.string(),
          state: Yup.string().test(
            "default-text",
            "Select A State",
            (value) => {
              return value !== "Select A State";
            }
          ),
          postalCode: Yup.string().matches(zipRegex, "Invalid ZIP code"),
        }),
      otherwise: (schema) => schema,
    }
  ),
  phoneNumber: Yup.string(),
});
