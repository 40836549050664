import { useState } from "react";
import { Button } from "@justworkshr/milo-core";
import { useRequestDetail } from "pages/expenses/store";
import { useExpenseDetailsCards } from "../../ExpenseDetailsCards.utils";
import { RemoveExpenseDialog } from "pages/expenses/components";

interface DeleteButtonProps {
  expenseIndex: number;
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ expenseIndex }) => {
  const {
    setRequestDetailExpenses,
    requestDetail: {
      request: { expenses },
      metadata: { isApproverView },
    },
  } = useRequestDetail();

  const { isEditable } = useExpenseDetailsCards();

  const [showRemoveExpenseModal, setShowRemoveExpenseModal] = useState(false);

  const handleRemoveExpenseDialog = () => {
    setShowRemoveExpenseModal((p) => !p);
  };

  const onRemove = () => {
    const updatedExpenses = [...expenses].filter(
      (_, index) => index !== expenseIndex
    );

    setRequestDetailExpenses(updatedExpenses);
    handleRemoveExpenseDialog();
  };

  if (!isEditable || isApproverView) {
    return null;
  }

  return (
    <div>
      <Button
        type="button"
        variant="ghost"
        leftIcon="trash"
        color="destructive"
        onClick={handleRemoveExpenseDialog}
      >
        Delete
      </Button>
      {showRemoveExpenseModal && (
        <RemoveExpenseDialog
          isOpen
          isMultiple={false}
          onRemove={onRemove}
          onClose={handleRemoveExpenseDialog}
        />
      )}
    </div>
  );
};

export default DeleteButton;
