import styles from "../../ProjectsPage.module.css";
import React, { useEffect, useState } from "react";
import { FormField, TextInput } from "@justworkshr/milo-form";
import { Button } from "@justworkshr/milo-core";
import { ExpenseDialog } from "pages/expenses/components";
import { useProjectsStore } from "pages/expenses/store";
import {
  validateProjectCode,
  validateProjectName,
} from "../../ProjectsPage.utils";
import {
  PROJECT_CHANGE_MESSAGE,
  PROJECT_NAME_DESCRIPTION,
  INITIAL_ADD_PROJECT_STATE,
} from "../../ProjectsPage.constants";

const {
  ProjectsNameDescription,
  ProjectDialogFormWrapper,
  ProjectsChangeDescription,
} = styles;

interface ProjectDialogProps {
  showProjectModal: boolean;
  handleProjectModal: () => void;
}

export const ProjectDialog: React.FC<ProjectDialogProps> = React.memo(
  ({ showProjectModal, handleProjectModal }) => {
    const {
      projects: { projects, activeProject },
      setProjects,
      setActiveProject,
    } = useProjectsStore();

    const isEditMode = activeProject !== null;

    const isNewProject = activeProject?.uuid === "";
    const title = isEditMode ? "Edit a project" : "Create a project";
    const submitButtonText = isEditMode ? "Update" : "Add";
    const [form, setForm] = useState(INITIAL_ADD_PROJECT_STATE);
    const [formErrors, setFormErrors] = useState(INITIAL_ADD_PROJECT_STATE);
    const isSubmitDisabled =
      form.projectName === "" ||
      formErrors.projectCode !== "" ||
      formErrors.projectName !== "";

    useEffect(() => {
      if (isEditMode) {
        setForm({
          projectCode: activeProject?.projectCode || "",
          projectName: activeProject?.projectName || "",
        });
      }
    }, [activeProject, isEditMode]);

    const handleReset = () => {
      setActiveProject({ activeProject: null });
      handleProjectModal();
      setForm(INITIAL_ADD_PROJECT_STATE);
      setFormErrors(INITIAL_ADD_PROJECT_STATE);
    };

    const handleClose = () => {
      handleReset();
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      let updatedProjects = [];

      if (isEditMode) {
        updatedProjects = [...projects].map((project) => {
          if (project.projectName === activeProject.projectName) {
            return {
              ...project,
              projectCode: form.projectCode,
              projectName: form.projectName,
            };
          }

          return project;
        });
      } else {
        updatedProjects = [
          ...projects,
          {
            uuid: "",
            visible: true,
            projectName: form.projectName,
            projectCode: form.projectCode,
            displayName: `${form.projectCode} - ${form.projectName}}`,
          },
        ];
      }

      setProjects({ projects: updatedProjects });
      handleReset();
    };

    const handleFormErrors = (key: keyof typeof form, errorMessage: string) => {
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [key]: errorMessage,
      }));
    };

    const validateInputs = (key: keyof typeof form, value: string) => {
      if (key === "projectName") {
        const projectNameErrorMessage = validateProjectName(value, projects);

        handleFormErrors("projectName", projectNameErrorMessage);
      } else if (key === "projectCode") {
        const projectCodeErrorMessage = validateProjectCode(value, projects);

        handleFormErrors("projectCode", projectCodeErrorMessage);
      }
    };

    const handleChange = (key: keyof typeof form, value: string) => {
      setForm((prevForm) => ({
        ...prevForm,
        [key]: value,
      }));

      validateInputs(key, value);
    };

    return (
      <ExpenseDialog
        isOpen={showProjectModal}
        onClose={handleClose}
        actions={[
          <Button
            type="button"
            variant="ghost"
            key="add-project-cancel"
            onClick={handleClose}
            data-testid="modal-cancel-button"
          >
            Cancel
          </Button>,
          <Button
            type="button"
            onClick={handleSubmit}
            key="add-project-submit"
            disabled={isSubmitDisabled}
            data-testid="add-button"
          >
            {submitButtonText}
          </Button>,
        ]}
      >
        <h2>{title}</h2>
        {isEditMode && !isNewProject && (
          <p className={ProjectsChangeDescription}>{PROJECT_CHANGE_MESSAGE}</p>
        )}
        <div className={ProjectDialogFormWrapper}>
          <FormField error={formErrors.projectCode} label="Project Code">
            <TextInput
              name="Project Code"
              value={form.projectCode}
              onChange={({ target: { value } }) =>
                handleChange("projectCode", value)
              }
              data-testid="project-code-input"
            />
          </FormField>
          <FormField
            required
            error={formErrors.projectName}
            label="Project Name"
          >
            <TextInput
              required
              name="Project Name"
              value={form.projectName}
              onChange={({ target: { value } }) =>
                handleChange("projectName", value)
              }
              data-testid="project-name-input"
            />
          </FormField>
          <p className={ProjectsNameDescription}>{PROJECT_NAME_DESCRIPTION}</p>
        </div>
      </ExpenseDialog>
    );
  }
);
