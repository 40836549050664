import { ReactElement } from "react";
import styles from "./home-page.module.css";
import { IconButton } from "@justworkshr/milo-core";
import { GetSubmittedClaimReportsByCompanyQuery } from "types/generated/operations";

const {
  loadedReports,
  submittedDownload,
  tableContainer,
  dateEdited,
  memberName,
  submitRow,
} = styles;

const handleDownload = async (pdf: string) => {
  try {
    const response = await fetch(pdf);

    const blob = await response.blob();
    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "report.pdf");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log(error);
  }
};

export function SubmittedReportsTable({
  claims,
}: {
  claims: GetSubmittedClaimReportsByCompanyQuery["submittedClaimReportsByCompany"];
}): ReactElement {
  return (
    <section className={loadedReports}>
      <div className={tableContainer}>
        <div
          className={memberName}
          style={{ fontWeight: "bold", paddingLeft: "10px" }}
        >
          Employee Name
        </div>
        <div className={dateEdited} style={{ fontWeight: "bold" }}>
          Date Submitted
        </div>
        <div className={submittedDownload}>Download PDF</div>
      </div>
      {claims.map((claim) => (
        <div
          className={`${tableContainer} ${submitRow}`}
          key={claim.data?.claim?.memberName}
        >
          <div className={memberName}>{claim.data?.claim?.memberName}</div>
          <div className={dateEdited}>
            {claim.data?.claim?.estimatedAccidentDateTime}
          </div>
          {claim.pdfReport && claim.pdfReport.length > 0 && (
            <div className={submittedDownload}>
              <IconButton
                className={submittedDownload}
                onClick={() => handleDownload(claim.pdfReport)}
                color="neutral"
                variant="ghost"
                iconName="download"
                title="Download PDF"
              />
            </div>
          )}
        </div>
      ))}
    </section>
  );
}
