import { MultiSelect, SelectOption } from "@justworkshr/milo-form";
import { MemberSeparationAnswerOptionRecursive } from "pages/team-management/types";
import React, { useEffect, useState } from "react";

interface MultiSelectProps {
  answers: MemberSeparationAnswerOptionRecursive[];
  value: (string | number)[];
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | string
      | number
      | (string | number)[]
  ) => void;
}

interface MultiSelectValueProps {
  name: string;
  value: string | number;
}

export const MultiSelectQuestion = ({
  answers,
  value,
  onChange,
}: MultiSelectProps) => {
  const [suppressOnChange, setSuppressOnChange] = useState<boolean>(true);
  const values = (() => {
    if (!value) {
      return;
    }

    if (Array.isArray(value)) {
      return value;
    } else {
      return [value];
    }
  })();

  useEffect(() => {
    setSuppressOnChange(false);
  }, [suppressOnChange]);

  const updateValueWithCallback = (event: MultiSelectValueProps[]) => {
    if (suppressOnChange) {
      return;
    }
    const callbackValues = event.map(
      (option: MultiSelectValueProps) => option.value
    );

    onChange(callbackValues);
  };

  return (
    <MultiSelect
      values={values}
      onChange={(event) => updateValueWithCallback(event)}
    >
      {answers.map((answer, idx) => (
        <SelectOption value={String(answer.value)} key={idx}>
          {String(answer.value)}
        </SelectOption>
      ))}
    </MultiSelect>
  );
};
