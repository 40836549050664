import { PageHeader } from "@justworkshr/milo-core";
import { DeleteRequestButton } from "pages/expenses/components";
import styles from "./ReimbursementRequestPageHeader.module.css";
import { useRequestDetail } from "pages/expenses/store";
import { RETURNED_STATUS } from "pages/expenses/constants";

const {
  ReimbursementRequestPageHeaderWrapper,
  ReimbursementRequestPageHeaderContainer,
} = styles;

export const ReimbursementRequestPageHeader = () => {
  const {
    requestDetail: {
      request: { status },
    },
  } = useRequestDetail();

  const showDeleteRequestButton = status === RETURNED_STATUS;

  return (
    <div className={ReimbursementRequestPageHeaderWrapper}>
      <PageHeader
        border={false}
        title="Reimbursement request"
        className={ReimbursementRequestPageHeaderContainer}
      />
      {showDeleteRequestButton && <DeleteRequestButton />}
    </div>
  );
};
