/* istanbul ignore file */
import { Component, ErrorInfo } from "react";
import { Alert } from "@justworkshr/milo-core";

type ExceptionBoundaryProps = {
  children: React.ReactNode;
};

type ExceptionBoundaryState = {
  hasError: boolean;
  error: Error | undefined;
  errorInfo: unknown | undefined;
};

export class ExceptionBoundary extends Component<
  ExceptionBoundaryProps,
  ExceptionBoundaryState
> {
  state = { hasError: false, error: undefined, errorInfo: undefined };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (error instanceof Error) {
      console.error({ error, errorInfo });
    }

    this.setState({ error, errorInfo });
  }

  render() {
    const {
      state: { hasError },
      props: { children },
    } = this;

    return (
      <>
        {hasError && (
          <Alert dismissible color="destructive">
            Sorry, something went wrong on our end. But, we are working on it.
            Refresh the page or try again later. If the error persists, contact
            support@justworks.com.
          </Alert>
        )}
        {children}
      </>
    );
  }
}

export default ExceptionBoundary;
