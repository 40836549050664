import { Formik } from "formik";
import {
  PaymentDetailsFormValues,
  PaymentDetailsSchema,
} from "../../../SeparationPay.formUtils";
import { Form } from "@justworkshr/milo-form";
import { SeparationFlowContext } from "pages/team-management/contexts";
import { useContext } from "react";
import { DetailsInputSection } from "./DetailsInputSection";

export const PaymentDetailsForm = ({ paymentKey }: { paymentKey: number }) => {
  const { paymentDetailsFormValues, setPaymentDetailsFormValues } = useContext(
    SeparationFlowContext
  );

  const defaultValues: PaymentDetailsFormValues = {
    type: "",
    work_start_date: "",
    work_end_date: "",
    bonus_type: "",
    is_net_pay: "",
    pay_group: "",
    tax_method: "",
    deductions_setting: "",
  };
  const initialValues: PaymentDetailsFormValues = {
    ...defaultValues,
    ...paymentDetailsFormValues?.[paymentKey],
  };

  const handleSubmit = (values: PaymentDetailsFormValues) => {
    // @ts-expect-error: un-typable setState function
    setPaymentDetailsFormValues?.((prevState) => ({
      ...prevState,
      [paymentKey]: values,
    }));
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={PaymentDetailsSchema}
    >
      {({ handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit} id={`PaymentDetailsForm-${paymentKey}`}>
            <DetailsInputSection paymentKey={paymentKey} />
          </Form>
        );
      }}
    </Formik>
  );
};
