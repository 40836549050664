import React, { FC, useContext } from "react";
import { Alert, Button, PageHeader, Card, Box } from "@justworkshr/milo-core";
import {
  ActionFooter,
  FormField,
  TextInput,
  Select,
  SelectOption,
} from "@justworkshr/milo-form";
import { Formik, FormikValues, type FormikProps } from "formik";
import * as Yup from "yup";
import useOnboardingForm from "../hooks/useOnboardingForm";
import Layout from "../Layout";
import { PaymentInfoFormType } from "./types";
import { initialValues } from "./constants";
import styles from "./PaymentInfo.module.css";
import onboardingStyles from "../Onboarding.module.css";
import { useTranslation } from "react-i18next";
import { SupportedCountriesContext } from "../../contexts/supportedCountriesContext";
import AutoForm, { generateFormValidations } from "../../components/AutoForm";
import { cleanFieldValues } from "../../components/AutoForm/field-utils";
import {
  BankAccountTypeOptions,
  PaymentInfoAutoFormConfig,
} from "./PaymentInfoAutoForm/payment-info-autoform-config";
import { PaymentInfoAutoFormFields } from "./PaymentInfoAutoForm/payment-info-autoform-fields";

const state_key = "payment-info";

const PaymentInfo: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    memberData: { workCountry },
    form: {
      submitting,
      navigatingBack,
      showErrorAlert,
      errorAlertMessage,
      onSubmit,
      onBack,
    },
    memberOnboarding: {
      paymentInfo,
      stepNumber,
      redirecting,
      idVerificationStatus,
    },
  } = useOnboardingForm(state_key);

  const { supportedCountriesData } = useContext(SupportedCountriesContext);
  const countryCodes = Object.values(supportedCountriesData).map((country) => ({
    code: country.code,
    emojiFlag: country.emojiFlag,
    name: country.commonName ? t(country.commonName) : "",
  }));
  const orderedFields =
    workCountry && workCountry in PaymentInfoAutoFormConfig
      ? PaymentInfoAutoFormConfig[workCountry]
      : [];
  const [defaultAccountType] =
    workCountry && workCountry in BankAccountTypeOptions
      ? BankAccountTypeOptions[workCountry]
      : [];
  const accountType = paymentInfo?.paymentInfo.accountType
    ? paymentInfo?.paymentInfo.accountType
    : defaultAccountType?.value;
  const paymentInfoInitialValues: PaymentInfoFormType = {
    paymentInfo: {
      ...initialValues.paymentInfo,
      ...paymentInfo?.paymentInfo,
      accountType,
    },
  };

  const onFormSubmit = (values: FormikValues) => {
    onSubmit({
      paymentInfo: {
        ...cleanFieldValues(PaymentInfoAutoFormFields, values.paymentInfo),
      },
    });
  };

  const FormSchema = Yup.object().shape({
    paymentInfo: Yup.object()
      .shape({
        countryCode: Yup.string().required(t("This field is required")),
        currency: Yup.string().required(t("This field is required")),
      })
      .concat(generateFormValidations(orderedFields, t)),
  });

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {t(errorAlertMessage) ||
            t("An error occurred while submitting your payment information.")}
        </Alert>

        <PageHeader title={t("Enter your payment information")} />

        <Formik
          initialValues={paymentInfoInitialValues}
          onSubmit={onFormSubmit}
          validationSchema={FormSchema}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            values,
          }: FormikProps<PaymentInfoFormType>) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Card
                  className={styles.card}
                  description={t(
                    "Enter your account information to get paid with direct deposit. If you need to make any changes, you can add or change your bank account information any time in Justworks."
                  )}
                  title={t("Bank Account")}
                >
                  <FormField
                    error={
                      touched?.paymentInfo?.countryCode
                        ? errors?.paymentInfo?.countryCode
                        : ""
                    }
                    name="paymentInfo.countryCode"
                    label={t("Country")}
                    required
                  >
                    <Select
                      name="paymentInfo.countryCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.paymentInfo.countryCode}
                      disabled
                      required
                    >
                      {countryCodes.map((country) => (
                        <SelectOption value={country.code} key={country.code}>
                          {country.emojiFlag + " " + country.name}
                        </SelectOption>
                      ))}
                    </Select>
                  </FormField>
                  <div className={styles.currencyBoxWrapper}>
                    <Box
                      backgroundColor="neutralSubtle"
                      border={{
                        borderWidth: "sm",
                        borderColor: "neutralSubtle",
                        borderRadius: "md",
                      }}
                      padding="lg"
                    >
                      <p className={styles.currencyBoxTitle}>
                        <b>
                          {t("You will be paid in {{currency}}.", {
                            currency: values.paymentInfo.currency,
                          })}
                        </b>
                      </p>
                      <p>
                        {t(
                          "Based on your banking country, you will be paid in the local currency of {{currency}}.",
                          { currency: values.paymentInfo.currency }
                        )}
                      </p>
                    </Box>
                  </div>
                  <div className={styles.hidden}>
                    <FormField
                      name="paymentInfo.currency"
                      label={t("Currency")}
                      required
                      error={errors?.paymentInfo?.currency || ""}
                    >
                      <TextInput
                        name="paymentInfo.currency"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.paymentInfo.currency}
                        disabled
                      />
                    </FormField>
                  </div>

                  <AutoForm orderedFields={orderedFields} name="paymentInfo" />
                </Card>
                <ActionFooter
                  className={onboardingStyles.footer}
                  actions={[
                    <Button
                      type="submit"
                      color="brand"
                      loading={submitting}
                      disabled={navigatingBack}
                      key="submit-btn"
                    >
                      {t("Save & continue")}
                    </Button>,
                  ]}
                  secondary={[
                    <Button
                      type="button"
                      variant="outlined"
                      color="brand"
                      key="back-btn"
                      loading={navigatingBack}
                      onClick={onBack}
                    >
                      {t("Go back")}
                    </Button>,
                  ]}
                />
              </form>
            );
          }}
        </Formik>
      </>
    </Layout>
  );
};

export default PaymentInfo;
