import { useUpdateCategoriesMutation } from "types/generated/operations";
import {
  useCategoriesStore,
  useFeedbackBannerStore,
  useGlobalConfigurationStore,
} from "../../store";
import { useNavigate } from "react-router-dom";
import { AlertTypes, prependBasePath } from "../../constants";
import { useEffect } from "react";

export const useUpdateCategories = (isCategoriesReviewed: boolean) => {
  const navigate = useNavigate();

  const { setFeedbackBanner } = useFeedbackBannerStore();
  const { setCategories } = useCategoriesStore();
  const { updatePermissions } = useGlobalConfigurationStore();

  const [
    updateCategories,
    { data: updateCategoriesData, loading: isUpdateCategoriesLoading },
  ] = useUpdateCategoriesMutation();

  useEffect(() => {
    if (
      !isUpdateCategoriesLoading &&
      updateCategoriesData?.updateExpenseCategories?.success &&
      updateCategoriesData?.updateExpenseCategories?.updatedCategories
    ) {
      setCategories({
        categories:
          updateCategoriesData.updateExpenseCategories.updatedCategories,
      });

      if (!isCategoriesReviewed) {
        updatePermissions({
          isAllowedToFirstTimeSetup: false,
        });
      }

      navigate(prependBasePath("SETTINGS"));

      setFeedbackBanner({
        type: AlertTypes.SUCCESS,
        show: !isCategoriesReviewed,
        message: `Great, Expenses are set up. You can review Advanced settings or Turn on Expenses under Settings when you're ready to share with your team.`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCategories, updateCategoriesData, isUpdateCategoriesLoading]);

  return {
    updateCategories,
    isUpdateCategoriesLoading,
  };
};
