import { SeparationFlowContextProvider } from "pages/team-management/contexts";
import styles from "./SeparationReasonOutlet.module.css";
import { SeparationReasonHeader, SeparationReasonBody } from "./components";

const { pageLayout } = styles;

export const SeparationReasonOutlet = () => {
  return (
    <SeparationFlowContextProvider>
      <div className={pageLayout}>
        <SeparationReasonHeader />
        <SeparationReasonBody />
      </div>
    </SeparationFlowContextProvider>
  );
};
