export const FLAG_NAMES = {
  DATADOG_RUM_ENABLED: "datadogRumEnabled",
  FLAG_TO_TEST_WITH: "flagToTestWith",
  INVITATION_PAGE_REWRITE: "invitationPageRewrite",
  EMPLOYER_OF_RECORD: "employerOfRecord",
  LEVER_ENABLED: "leverEnabled",
  JAZZHR_ENABLED: "jazzHrEnabled",
  PMT_INTEGRATIONS: "pmtIntegrations",
  INTERNATIONAL_CONTRACTOR_DOCUMENTS: "internationalContractorDocuments",
  ENABLE_I18N_DEBUG_MODE: "enableI18nDebugMode",
  ENABLE_MESH: "enableMesh",
  ENABLE_PKCE: "enablePKCE",
} as const;
