export const EXPENSE_INPUT_FIELD_NAMES = [
  "uuid",
  "amount",
  "detach",
  "merchant",
  "attendees",
  "projectUuid",
  "description",
  "expenseType",
  "expenseType",
  "categoryUuid",
  "receiptUuids",
  "transactionDate",
  "isBillableToClient",
  "isBillableToClient",
  "receiptUuidsToRemove",
];
