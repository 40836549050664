import { FC } from "react";
import { useGetVisierWorkforceMetricsQuery } from "types/generated/operations";
import { FULL_PATHS } from "../../constants";
import { usePeopleAnalyticsCapabilities } from "../../hooks";
import CompanyOverviewCard from "../company-overview-card";
import CompanyOverviewMetric from "../company-overview-metric";
import styles from "../company-overview-card/company-overview-card.module.css";

const WorkforceMetricsCard: FC = () => {
  const { memberCompanyHasPlusAccess } = usePeopleAnalyticsCapabilities();

  const { data } = useGetVisierWorkforceMetricsQuery();

  const { fullTimeEmployeeCountCurrent, partTimeEmployeeCountCurrent } =
    data?.visierWorkforceMetrics || {
      fullTimeEmployeeCountCurrent: undefined,
      partTimeEmployeeCountCurrent: undefined,
    };

  const metrics = (
    <div className={styles.companyOverviewMetrics}>
      <h3 className={styles.companyOverviewMetricsHeader}>Headcount</h3>

      <div className={styles.metricContainer}>
        {fullTimeEmployeeCountCurrent && (
          <CompanyOverviewMetric
            description="Full-time employees"
            metric={fullTimeEmployeeCountCurrent}
            subtext="Yesterday"
          />
        )}

        {partTimeEmployeeCountCurrent && (
          <CompanyOverviewMetric
            description="Part-time employees"
            metric={partTimeEmployeeCountCurrent}
            subtext="Yesterday"
          />
        )}
      </div>
    </div>
  );

  return (
    <CompanyOverviewCard
      title="Workforce"
      insightsPath={
        memberCompanyHasPlusAccess ? FULL_PATHS.WORKFORCE_DASHBOARD : undefined
      }
    >
      {(() => {
        if (!data) return;

        return metrics;
      })()}
    </CompanyOverviewCard>
  );
};

export default WorkforceMetricsCard;
