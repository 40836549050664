import styles from "./SubmitterTabList.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { TabList, Tab } from "@justworkshr/milo-core";
import { prependBasePath } from "pages/expenses/constants";
import { getSelectedSubmitterTab } from "./SubmitterTabList.utils";

const { SubmitterTabListWrapper } = styles;

export const SubmitterTabList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const selected = getSelectedSubmitterTab(pathname);

  const handleChange = (index: number) => {
    if (index === 0) {
      return navigate(prependBasePath("HOME"));
    } else if (index === 1) {
      return navigate(prependBasePath("EXPENSE_LIBRARY"));
    }
  };

  return (
    <div className={SubmitterTabListWrapper}>
      <TabList selected={selected} onChange={handleChange}>
        <Tab data-testid="request-tab-button">Requests</Tab>
        <Tab data-testid="expense-library-tab-button">Expense Library</Tab>
      </TabList>
    </div>
  );
};
